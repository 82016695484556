import Log from '../../utils/log'
import { useState } from 'react'
import Button from './button'

export default function Modal({
  modalTitle,
  imageSource,
  modalContent,
  isButton = false,
  buttonText,
  buttonLink,
  buttonCallbackFunction = () => {},
  appearance = 'none',
  disabled = false,
  size = 'md',
  listContent = [],
  listButton = [
    {
      text: '',
      href: '',
      btnText: '',
    },
  ],
  children,
  modalOn = false,
  modalLog = 'modal',
  className = '',
}) {
  const [noticeOpened, setNoticeOpened] = useState(false)
  const [showModal, setShowModal] = useState(modalOn)
  return (
    <>
      <>
        <Button
          appearance={appearance}
          type='button'
          size={size}
          disabled={disabled}
          onClick={() => {
            Log.event(modalLog, modalLog, {
              click: modalLog,
            })
            setShowModal(true)
            setNoticeOpened(true)
          }}
          className={className}
        >
          {children}
        </Button>
      </>
      {showModal ? (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
            <div className='relative w-full max-w-xl mx-auto my-6'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <h3 className='text-xl font-semibold text-black'>
                    {modalTitle}
                  </h3>
                </div>
                {/* body */}
                <div className='relative flex-auto px-10 py-10'>
                  {imageSource && (
                    <div className='mb-5 text-lg leading-relaxed text-sub-500'>
                      <img alt='' src={imageSource} />
                    </div>
                  )}
                  <div className='text-base leading-relaxed text-sub-500'>
                    {modalContent}
                  </div>
                  <div className='text-base'>
                    {listContent ? (
                      <ol className='list-decimal'>
                        {listContent.map((list) => {
                          return <li key={list.toString()}>{list}</li>
                        })}
                      </ol>
                    ) : null}
                  </div>
                  <div>
                    {listButton[0].text !== ''
                      ? listButton.map((button) => {
                          return (
                            <div
                              className='flex flex-row items-center text-lg'
                              key={button.href}
                            >
                              <div className='mr-5'>{button.text}</div>
                              <div>
                                <Button href={button.href}>
                                  {button.btnText}
                                </Button>
                              </div>
                            </div>
                          )
                        })
                      : null}
                  </div>
                </div>
                {/* footer */}
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    닫기
                  </button>
                  {isButton ? (
                    <Button
                      href={buttonLink}
                      appearance='positive'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        buttonCallbackFunction()
                        setShowModal(false)
                      }}
                    >
                      {buttonText}
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      ) : null}
    </>
  )
}

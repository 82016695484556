import React from 'react'
import { Bar } from 'react-chartjs-2'

function RankRevenueBarChart({ chartData }) {
  const maxRankRevenue = parseInt(Math.max(...chartData), 10)
  const maxRankRevenueSize = maxRankRevenue.toString().length
  const numerator = 10 ** (maxRankRevenueSize - 2)
  const options = {
    scales: {
      y: {
        max:
          parseInt((maxRankRevenue / numerator) * 1.15, 10) * numerator || 100,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      datalabels: {
        display: true,
        color: 'black',
        anchor: 'end',
        align: 'top',
        font: {
          weight: 'bold',
        },
        formatter: (value) => {
          return `${value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}원`
        },
      },
    },
    tooltips: {
      enabled: false,
    },
  }
  const data = {
    labels: ['S', 'A', 'B', 'C'],
    datasets: [
      {
        label: '',
        backgroundColor: ['#C20F22', '#EF343D', '#129DEF', '#616161'],
        data: chartData,
        barThickness: 24,
      },
    ],
  }
  return <Bar data={data} options={options} width={450} height={200} />
}

export default RankRevenueBarChart

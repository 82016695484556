import React from 'react'
import { useTable, useRowState, useSticky } from 'react-table'
import SubTitle from '../atoms/subTitle'

function deepEqual(obj1, obj2) {
  if (typeof obj1 !== typeof obj2) {
    return false
  }
  if (obj1 === null || typeof obj1 !== 'object') {
    return obj1 === obj2
  }
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
  }
  for (const key in obj1) {
    if (!deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

const OptionSelectTable = ({
  title,
  columns,
  data,
  onRowClick = () => {},
  optionIdx,
  height = 'max-h-80',
}) => {
  if (typeof data !== 'object') return null
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn: {
          width: 'auto',
        },
      },
      useRowState,
      useSticky,
    )

  return (
    <div className='flex flex-1 w-full '>
      {title && (
        <div className='flex flex-row justify-between w-full mb-1 sm:mb-0'>
          <SubTitle text={title} />
        </div>
      )}
      <div
        className={`flex flex-row flex-1 w-full -mx-4 overflow-x-auto ${height} sm:-mx-8 sm:px-8`}
      >
        <div className='w-full'>
          <table
            {...getTableProps()}
            className='w-full overflow-auto leading-normal border-1 border-slate-200 h-max-80'
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      scope='col'
                      className='sticky top-0 px-5 py-3 font-bold text-left uppercase border-b border-gray-200 bg-gray-50 text-nutral-500 text-md'
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row) => {
                  prepareRow(row)
                  const rowOptionIdx = row.original?.optionIndices
                  const selectedOptionIdx = optionIdx
                  const isSelected = deepEqual(rowOptionIdx, selectedOptionIdx)
                  const onClick = () => onRowClick(rowOptionIdx)
                  const rowClassName = isSelected
                    ? 'bg-slate-100 cursor-pointer'
                    : 'hover:bg-slate-200 bg-white cursor-pointer'
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={onClick}
                      className={rowClassName}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              className: cell.column.collapse ? 'collapse' : '',
                            })}
                            className='p-5 text-sm text-left text-gray-900 border-b border-gray-200'
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td
                    colSpan={columns.length}
                    className='p-10 text-lg text-gray-400 bg-white border-gray-200'
                  >
                    데이터 없음
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default OptionSelectTable

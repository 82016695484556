import SellerStore from '../../stores/SellerStore'
import React from 'react'
import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import OrderStore from '../../stores/OrderStore'
import { observer } from 'mobx-react-lite'
import RevokeRefundRequestModal from '../../screens/RevokeRefundRequestModal'

const RevokeCancelOrderButton = observer(({ order, setOrders, status }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <div>
      <Button
        size='sm'
        appearance='neutralSub'
        type='button'
        onClick={async () => {
          if (
            status === 'canceling-pre-shipping' &&
            !order?.excelDownloadedAt
          ) {
            if (SellerStore.isLoading) {
              return
            }
            SellerStore.setIsLoading(true)

            const denyReason = ''
            const additionalDenyNote = ''
            const result = await backendApis.withdrawCancelingOrder(
              order._id,
              order.userId,
              denyReason,
              additionalDenyNote,
            )
            SellerStore.setIsLoading(false)
            if (result?.status === 200) {
              if (order.excelDownloadedAt) {
                OrderStore.changeOrdersNumPerStatus(
                  'pre-shipping-post-excel',
                  1,
                )
              } else {
                OrderStore.changeOrdersNumPerStatus('pre-shipping-pre-excel', 1)
              }
              // OrderStore.setPreShippingOrders([
              //   order,
              //   ...OrderStore.preShippingOrders,
              // ])
              // OrderStore.setCancelingPreShippingOrders(
              //   OrderStore.cancelingPreShippingOrders.filter(
              //     (orderTemp) => orderTemp._id !== order._id,
              //   ),
              // )
              OrderStore.setDisplayedOrders(
                OrderStore.displayedOrders.map((orderTemp) => {
                  if (orderTemp._id === order._id) {
                    return { ...orderTemp, status: 'pre-shipping' }
                  }
                  return orderTemp
                }),
              )
              if (setOrders) {
                OrderStore.setUserOrders(
                  OrderStore.userOrders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: 'pre-shipping',
                      }
                    }
                    return orderTemp
                  }),
                )
              }
              OrderStore.changeOrdersNumPerStatus('canceling-pre-shipping', -1)
              OrderStore.changeOrdersNumPerStatus(
                'canceling-pre-shipping-pre-excel',
                -1,
              )
              OrderStore.changeOrdersNumPerStatus('pre-shipping', 1)
            }
          } else if (
            status === 'canceling-pre-shipping' &&
            order?.excelDownloadedAt
          ) {
            if (SellerStore.isLoading) {
              return
            }
            if (
              window.confirm(
                '취소거부(취소철회)를 위해서는 사전 발송처리 진행이 필요합니다.\n취소요청 건을 거부(철회)하고 발송처리를 진행하시겠습니까?',
              )
            ) {
              OrderStore?.setOpenWithdrawCancelingPreShippingOrderModal(true)
              OrderStore?.setWithdrawingOrderInfo({
                ...OrderStore?.withdrawingOrderInfo,
                orderId: order._id,
              })
            }
          } else if (status === 'canceling-shipping') {
            if (SellerStore.isLoading) {
              return
            }
            if (
              window.confirm(
                '배송중지 요청 거부(취소 철회)를 진행하시겠습니까?',
              )
            ) {
              SellerStore.setIsLoading(true)
              // const result = await backendApis.withdrawCancelingOrder(
              //   order._id,
              //   order.userId,
              // )
              // if (result?.status === 200) {
              //   OrderStore.setDisplayedOrders(
              //     OrderStore.displayedOrders.map((orderTemp) => {
              //       if (orderTemp._id === order._id) {
              //         return { ...orderTemp, status: 'shipping' }
              //       }
              //       return orderTemp
              //     }),
              //   )
              //   if (setOrders) {
              //     OrderStore.setUserOrders(
              //       OrderStore.userOrders.map((orderTemp) => {
              //         if (orderTemp._id === order._id) {
              //           return {
              //             ...orderTemp,
              //             status: 'shipping',
              //           }
              //         }
              //         return orderTemp
              //       }),
              //     )
              //   }
              //   OrderStore.changeOrdersNumPerStatus('canceling-shipping', -1)
              //   OrderStore.changeOrdersNumPerStatus('shipping', 1)
              // }

              openModal()
              SellerStore.setIsLoading(false)
            }
          } else if (status === 'refunding') {
            if (SellerStore.isLoading) {
              return
            }

            if (
              window.confirm(
                '반품 요청 거부(반품 철회)를 진행하시겠습니까?\n정당한 사유 없이 반품 요청을 거부할 경우, 판매자에게 패널티가 부여될 수 있습니다.',
              )
            ) {
              SellerStore.setIsLoading(true)
              // const result = await backendApis.withdrawCancelingOrder(
              //   order._id,
              //   order.userId,
              // )
              // if (
              //   result?.status === 200 &&
              //   ['arrived', 'reviewed'].includes(result.data)
              // ) {
              //   OrderStore.setDisplayedOrders(
              //     OrderStore.displayedOrders.map((orderTemp) => {
              //       if (orderTemp._id === order._id) {
              //         return { ...orderTemp, status: result.data }
              //       }
              //       return orderTemp
              //     }),
              //   )
              //   if (setOrders) {
              //     OrderStore.setUserOrders(
              //       OrderStore.userOrders.map((orderTemp) => {
              //         if (orderTemp._id === order._id) {
              //           return {
              //             ...orderTemp,
              //             status: result.data,
              //           }
              //         }
              //         return orderTemp
              //       }),
              //     )
              //   }
              //   OrderStore.changeOrdersNumPerStatus('refunding', -1)
              //   OrderStore.changeOrdersNumPerStatus(result.data, 1)
              // }
              openModal()
              SellerStore.setIsLoading(false)
            }
          }
        }}
      >
        취소 거부
      </Button>
      {isModalOpen && (
        <RevokeRefundRequestModal
          order={order}
          setOrders={setOrders}
          status={status}
          closeModal={closeModal}
        />
      )}
    </div>
  )
})

export default RevokeCancelOrderButton

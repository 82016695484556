const validateBankAccount = ({
  accountHolderName, // 예금주명
  businessType,
  businessData,
}) => {
  const repName = businessData.repName // 대표자명
  const corpName = businessData.corpName // 법인명

  // 개인사업자인 경우 - 대표자명과 비교
  if (businessType.mainType === '개인') {
    // 대표자명에 영어가 포함되어 있는 경우
    if (/[a-zA-Z]/.test(repName)) {
      // CASE 1
      // 대표자명에서 영어만 남기고 모두 제거, 영어 소문자화
      const repNameCase1 = repName.replace(/[^a-zA-Z]/g, '').toLowerCase()
      // 예금주명에서 공백 모두 제거, 영어 소문자화
      const accountHolderNameCase1 = accountHolderName
        .replace(/\s+/g, '')
        .toLowerCase()

      // 서로 한쪽이라도 포함되면 유효
      const validationCase1 =
        repNameCase1.includes(accountHolderNameCase1) ||
        accountHolderNameCase1.includes(repNameCase1)

      if (!validationCase1) {
        // CASE 2
        // 대표자명에서 한글만 남기고 모두 제거
        const repNameCase2 = repName.replace(/[^가-힣]/g, '')
        // 예금주명에서 공백 모두 제거
        const accountHolderNameCase2 = accountHolderName.replace(/\s+/g, '')

        // 대표자명의 한글 부분이 예금주명에 포함되어 있으면 유효
        const validationCase2 = accountHolderNameCase2.includes(repNameCase2)

        return validationCase2
      }
      return validationCase1
    }
    // 대표자명에 영어가 포함되어 있지 않은 경우
    if (!/[a-zA-Z]/.test(repName)) {
      // CASE 3
      // 대표자명에서 패턴 제거
      const repNameCase3 = repName
        .replace(/\s?외\s?\d+\s?명/, '') // '외 n명' 형식을 찾아 제거
        .replace(/\s[가-힣]+$/, '') // 이름 뒤에 공백+한글 제거
        .replace(/\s+/g, '') // 공백 모두 제거
      // 예금주명에서 공백 모두 제거
      const accountHolderNameCase3 = accountHolderName.replace(/\s+/g, '')

      // 대표자명이 예금주명에 포함되어 있으면 유효
      const validationCase3 = accountHolderNameCase3.includes(repNameCase3)

      return validationCase3
    }
  }

  // 법인사업자인 경우 - 법인명과 비교
  if (businessType.mainType === '법인') {
    // CASE 4
    // 법인명에서 제거할 패턴들
    const patternsToRemove = [
      /주식회사/g,
      /주식회/g,
      /주식/g,
      /주\)/g,
      /\(주\)/g,
      /유한회사/g,
      /유한회/g,
      /유한/g,
      /유\)/g,
      /\(유\)/g,
      /농업회사법인/g,
      /농업회사법/g,
      /농업회사/g,
      /농업회/g,
      /농업/g,
      /농\)/g,
      /\(농\)/g,
      /영농조합법인/g,
      /영농조합법/g,
      /영농조합/g,
      /영농조/g,
      /영농/g,
      /영\)/g,
      /\(영\)/g,
      /농업법인/g,
      /농업법/g,
      /영농법인/g,
      /영농법/g,
      /조합법인/g,
      /조합법/g,
      /재단법인/g,
      /재단법/g,
      /사단법인/g,
      /사단법/g,
      /유한책임회사/g,
      /유한책임회/g,
      /유한책임/g,
      /유한책/g,
      /합명회사/g,
      /합명회/g,
      /합명/g,
      /합자회사/g,
      /합자회/g,
      /합자/g,
      /합\)/g,
      /\(합\)/g,
      /법인/g,
    ]

    // 제거할 패턴들을 하나의 정규식으로 결합
    const combinedPattern = new RegExp(
      patternsToRemove.map((pattern) => pattern.source).join('|'),
      'g',
    )

    // corpName(법인명) 전처리
    const corpNameCase4 = corpName
      .replace(combinedPattern, '')
      .replace(/\s+/g, '')
      .toLowerCase()

    // accountHolderName(예금주명) 전처리
    const accountHolderNameCase4 = accountHolderName
      .replace(combinedPattern, '')
      .replace(/\s+/g, '')
      .toLowerCase()

    // 서로 한쪽이라도 포함되면 유효
    const validationCase4 =
      corpNameCase4.includes(accountHolderNameCase4) ||
      accountHolderNameCase4.includes(corpNameCase4)

    return validationCase4
  }

  return false
}

export default validateBankAccount

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ItemListComponent from './ItemListComponent'

const SmallCategoryItems = observer(({ smallCategoryId }) => {
  const [itemsInfo, setItemsInfo] = useState([])
  useEffect(async () => {
    if (typeof smallCategoryId === 'number') {
      const result = await backendApis.getSmallCategoryTopItems(smallCategoryId)
      if (result?.status === 2000) {
        setItemsInfo(result.data)
      }
    } else {
      setItemsInfo([])
    }
  }, [smallCategoryId])
  if (itemsInfo.length > 0) {
    return (
      <div>
        <div className='mt-5 text-xl'>
          (참고) 아래는 해당 카테고리의 노출량{' '}
          <span className='text-red-500'>TOP 12</span> 제품들이에요.
        </div>
        <div className='mt-10 overflow-x-scroll  grid gap-4 md:grid-cols-4'>
          {itemsInfo.map((itemInfo) => (
            <ItemListComponent key={itemInfo._id} itemInfo={itemInfo} />
          ))}
        </div>
      </div>
    )
  }
  return <></>
})

export default SmallCategoryItems

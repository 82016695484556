import Button from '../components/atoms/button'
import React, { useState, useEffect, useMemo } from 'react'
import { GrClose as CloseIcon } from 'react-icons/gr'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import Table from '../components/ADS/molecules/table'
import Pagination from '../components/molecules/pagination'
import Tooltip from '../components/ADS/atoms/tooltip'
import commaNumber from 'comma-number'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import ListDisCountRateSelector from '../components/atoms/listDiscountRateSelector'
import ListStockSelector from '../components/atoms/listStockSelector'
import TimeDealBulkApplyModal from './TimeDealBulkApplyModal'

const HolidayDealBulkApplyModal = observer(({ showModal, setShowModal }) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [itemsCount, setItemsCount] = useState(0)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [itemsInfo, setItemsInfo] = useState([])
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)
  const [stockNumber, setStockNumber] = useState(1000)
  const [discountRate, setDisCountRate] = useState(20)
  const applyStatusTab = 'candidate'
  const [timeDealShowModal, setTimeDealShowModal] = useState(false)
  const cleanUp = () => {
    setPage(1)
    setLimit(20)
    setItemsInfo([])
    setItemsCount(0)
    setIsAllCheckBoxChecked(false)
    setCheckedItemIds([])
  }

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    itemCondition.dealType = 'newYear24Deal'
    itemCondition.status = 'candidate'
    itemCondition.deletedAt = null
    if (isNewCondition) {
      const itemsCountResult = await backendApis.getHolidayDealItemsCount({
        itemCondition,
      })
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
        setPage(1)
        setItemsInfo([])
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        SellerStore.setIsLoading(false)
        return
      }
    }
    const result = await backendApis.getHolidayDealItems(
      page,
      limit,
      itemCondition,
    )
    if (result?.status === 200) {
      setItemsInfo(result?.data)
    }
    SellerStore.setIsLoading(false)
  }
  const handleCancel = () => {
    setShowModal(false)
  }

  useEffect(() => {
    setCheckedItemIds(itemsInfo?.map((itemInfo) => itemInfo?._id))
    setIsAllCheckBoxChecked(true)
  }, [itemsInfo])

  useEffect(() => {
    if (showModal) {
      fetchSegmentedItemsInfo(true)
    }
  }, [showModal, limit, applyStatusTab, discountRate, stockNumber])

  useEffect(() => {
    if (showModal && itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  const columns = useMemo(
    () => [
      {
        Header: (
          <div className='select-none'>
            <input
              type='checkbox'
              checked={isAllCheckBoxChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedItemIds(itemsInfo?.map((itemInfo) => itemInfo?._id))
                  setIsAllCheckBoxChecked(true)
                } else {
                  setCheckedItemIds([])
                  setIsAllCheckBoxChecked(false)
                }
              }}
            />
          </div>
        ),
        accessor: 'status',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            상품명
          </span>
        ),
        accessor: 'itemTitle',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            최소 할인율
            <Tooltip text='신청이 가능한 타임특가 가격 대비 최소 할인율입니다. 해당 아이템이 최근 한달 동안 팔린 수에 따라 할인율이 달라집니다' />
          </span>
        ),
        accessor: 'minimumDiscountRate',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            팀구매가 가격
          </span>
        ),
        accessor: 'dealTeamPurchasePrice',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            대표 설 특가 가격 (적용된 할인율 {discountRate}%)
            <Tooltip text='전체 옵션 중 대표 팀구매가의 가격입니다. 할인은 할인율에 따라 모든 옵션에 적용됩니다' />
          </span>
        ),
        accessor: 'HolidayDealTeamPurchasePrice',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            재고량
          </span>
        ),
        accessor: 'stockNumber',
      },
    ],
    [isAllCheckBoxChecked, itemsInfo, checkedItemIds],
  )
  const data = useMemo(
    () =>
      itemsInfo
        ?.filter((item) => item?.itemTitle)
        .map((item, idx) => {
          return {
            status: (
              <div className='select-none'>
                <input
                  type='checkbox'
                  checked={checkedItemIds?.includes(item?._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckedItemIds([
                        ...checkedItemIds?.filter(
                          (itemId) => itemId !== item?._id,
                        ),
                        item?._id,
                      ])
                    } else {
                      setCheckedItemIds(
                        checkedItemIds?.filter(
                          (itemId) => itemId !== item?._id,
                        ),
                      )
                    }
                  }}
                />
              </div>
            ),
            itemTitle: (
              <div className='overflow-auto w-80'>{item?.itemTitle}</div>
            ),
            minimumDiscountRate: (
              <div className='flex flex-row items-center justify-center flex-1 font-bold text-blue-500'>
                {(() => {
                  const orderCount =
                    item?.newYear24DealMetaData?.monthlySalesCount
                  const reviewsCount = item?.newYear24DealMetaData?.reviewsCount
                  const weeklySalesCount =
                    item?.newYear24DealMetaData?.weeklySalesCount

                  let discountRate

                  if (orderCount >= 600) {
                    discountRate = 0 // 0%
                  } else if (orderCount >= 300) {
                    discountRate = 0.05 // 5%
                  } else if (reviewsCount >= 1000) {
                    discountRate = 0
                  } else if (reviewsCount < 1000) {
                    discountRate = 0.05
                  } else if (weeklySalesCount >= 10) {
                    discountRate = 0.05
                  } else if (weeklySalesCount < 10) {
                    discountRate = 0.1
                  } else {
                    discountRate = 0.15
                  }

                  return `${discountRate * 100}%`
                })()}
              </div>
            ),
            dealTeamPurchasePrice: (
              <div className='flex flex-row items-center justify-center flex-1'>
                {commaNumber(parseInt(item?.dealTeamPurchasePrice) || 0)}
              </div>
            ),
            HolidayDealTeamPurchasePrice: (
              <div className='flex flex-row items-center justify-center flex-1 font-bold'>
                {commaNumber(
                  parseInt(
                    item?.dealTeamPurchasePrice *
                      (1 - 1 * (discountRate / 100)),
                  ) || 0,
                )}
                원
              </div>
            ),
            stockNumber: (
              <div className='flex flex-row items-center justify-center flex-1'>
                {parseInt(stockNumber) || 100}개
              </div>
            ),
          }
        }),
    [checkedItemIds, itemsInfo, isAllCheckBoxChecked, applyStatusTab],
  )

  return (
    <>
      {showModal && (
        <>
          {itemsInfo.length === 0 ? (
            <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden outline-none'>
              <div className='relative flex-col w-1/2 p-10 bg-white rounded-md shadow-md'>
                <div className='mb-5 text-xl font-bold'>
                  아쉽지만 설 특가 대량등록에 신청 가능한 상품이 없어요
                </div>

                <div>
                  설 특가 대량 등록 상품은 타임특가 신청을 많이 할 수록 기회가
                  부여될 확률이 높아요. 타임특가를 신청해주세요.
                </div>
                <div
                  className={`px-4 py-3 sm:flex sm:px-6 ${'sm:justify-end'}`}
                >
                  <div>
                    <Button
                      size='md'
                      onClick={() => {
                        setTimeDealShowModal(true)
                      }}
                      className='mr-3'
                    >
                      <div className='px-2 py-1'>타임특가 대량등록</div>
                    </Button>
                  </div>
                  <div>
                    <Button
                      size='md'
                      appearance='outline'
                      onClick={handleCancel}
                    >
                      <div className='px-1 py-1 text-slate-500'>취소</div>
                    </Button>
                  </div>
                </div>
              </div>
              <TimeDealBulkApplyModal
                showModal={timeDealShowModal}
                setShowModal={setTimeDealShowModal}
              />
            </div>
          ) : (
            <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden outline-none'>
              <div className='relative flex-col w-3/4 p-10 overflow-y-scroll bg-white rounded-md shadow-md h-3/4'>
                <div className='flex flex-col flex-1'>
                  <div className='flex flex-row items-center justify-between flex-1'>
                    <div className='mb-5 text-xl font-bold'>
                      2024 설 특가 대량등록
                    </div>
                    <Button
                      appearance='transparent'
                      onClick={() => {
                        cleanUp()
                        setShowModal(false)
                      }}
                    >
                      <CloseIcon className='w-4 h-4' />
                    </Button>
                  </div>
                  <div>
                    2024 설 특가 대량등록 신청이 가능한 상품들을 한 번에 신청할
                    수 있습니다.
                  </div>
                  <div>
                    1000만명 대상으로 앱 푸시가 나갈 예정이며{' '}
                    <span className='font-bold text-blue-500'>
                      메인 구좌에 들어갑니다 (배우 이동욱님 활용 광고 예정)
                    </span>
                  </div>
                  <div>
                    행사 진행일은
                    <span className='font-bold text-blue-500'>
                      {' '}
                      1월 18일 ~ 2월 5일 (변동 가능)
                    </span>
                    입니다
                  </div>
                  <div>
                    <span className='font-bold text-blue-500'>
                      올웨이즈 지원금, 쿠폰 및 마케팅비 포함된 기획전 수수료는{' '}
                      15%(부가세 별도, PG수수료/광고 및 마케팅비 포함){' '}
                    </span>
                    입니다.
                  </div>
                  <div>
                    주요 반려 사유 : 경쟁력 없는 가격/상품 구성, 유사 상품 중복
                  </div>
                  <div>가격은 모든 옵션에 대해 할인율만큼 적용됩니다</div>
                  <div className='my-2 text-sm text-gray-500'>
                    * 가격 및 옵션 어뷰징이 적발되는 경우 강한 페널티가 부과될
                    수 있습니다.
                  </div>
                  <div className='flex flex-row justify-end flex-1 mt-4'>
                    <div className='flex flex-1 gap-2'>
                      <Button
                        className='mr-2'
                        onClick={async () => {
                          if (SellerStore.isLoading === true) return
                          SellerStore.setIsLoading(true)
                          const checkedItemInfo = itemsInfo.filter((item) => {
                            return checkedItemIds?.includes(item?._id)
                          })
                          const dealItemsInfo = []

                          checkedItemInfo.forEach((item) =>
                            dealItemsInfo.push({
                              itemId: item?.itemId,
                              dealTeamPurchasePrice:
                                item?.dealTeamPurchasePrice,
                              orderCount:
                                item?.newYear24DealMetaData?.monthlySalesCount,
                              reviewsCount:
                                item?.newYear24DealMetaData?.reviewsCount,
                              weeklySalesCount:
                                item?.newYear24DealMetaData?.weeklySalesCount,
                              salesSource:
                                item?.newYear24DealMetaData?.salesSource,
                              discountRate,
                              stockNumber,
                            }),
                          )
                          // 할인율 검증 로직
                          const isInvalidDiscountRate = dealItemsInfo.some(
                            (item) => {
                              const orderCount = item.orderCount
                              const reviewsCount = item?.reviewsCount
                              const weeklySalesCount = item?.weeklySalesCount
                              const salesSource = item?.salesSource
                              const discountRate = item.discountRate
                              if (
                                (salesSource === 'alwayzTop' &&
                                  orderCount < 600 &&
                                  discountRate < 5) ||
                                (salesSource === 'winner' &&
                                  reviewsCount < 1000 &&
                                  discountRate < 5) ||
                                (salesSource === 'naverBest' &&
                                  weeklySalesCount >= 10 &&
                                  discountRate < 5) ||
                                (salesSource === 'naverBest' &&
                                  weeklySalesCount < 10 &&
                                  discountRate < 10)
                              ) {
                                let message =
                                  '할인율이 너무 낮습니다. 최소 할인율: '

                                if (orderCount < 600) {
                                  message += '5%'
                                } else if (reviewsCount < 1000) {
                                  message += '5%'
                                } else if (weeklySalesCount >= 10) {
                                  message += '5%'
                                } else if (weeklySalesCount < 10) {
                                  message += '10%'
                                } else {
                                  message += '20%'
                                }
                                alert(message)
                                return true
                              }
                              return false
                            },
                          )
                          if (isInvalidDiscountRate) {
                            SellerStore.setIsLoading(false)
                            return false
                          }
                          if (dealItemsInfo?.length === 0) {
                            alert('상품을 선택해주세요')
                            SellerStore.setIsLoading(false)
                            return false
                          }
                          if (
                            dealItemsInfo?.[0]?.discountRate === undefined ||
                            dealItemsInfo?.[0]?.discountRate === null
                          ) {
                            alert('할인률을 입력해주세요')
                            SellerStore.setIsLoading(false)
                            return false
                          }
                          if (!dealItemsInfo?.[0]?.stockNumber) {
                            alert('재고를 입력해주세요')
                            SellerStore.setIsLoading(false)
                            return false
                          }
                          // const userConfirmed = window.confirm(
                          //   `할인율 ${discountRate}%, 기획전 수수료 15%(PG 수수료 포함, 부가세 별도)로 설정하셨습니다. 계속 진행하시겠습니까?`,
                          // )
                          // if (!userConfirmed) {
                          //   SellerStore.setIsLoading(false)
                          //   return false
                          // }
                          const result =
                            await backendApis.registerHolidayDealBulkApplication(
                              dealItemsInfo,
                            )
                          SellerStore.setIsLoading(false)
                          if (result?.status === 200) {
                            fetchSegmentedItemsInfo(true)
                            alert(`설 특가 대량 신청이 완료되었습니다.`)
                          } else {
                            fetchSegmentedItemsInfo(true)
                            alert(
                              '설 특가 대량 신청 실패했습니다. 다시 시도해주세요.',
                            )
                          }
                        }}
                      >
                        선택 상품 설 특가 신청
                      </Button>
                    </div>
                    <div className='ml-2'>
                      <ListDisCountRateSelector
                        discountRate={discountRate}
                        setDiscountRate={setDisCountRate}
                        dealType='tenMinDeal'
                      />
                    </div>
                    <div className='ml-2'>
                      <ListStockSelector
                        stockNumber={stockNumber}
                        setStockNumber={setStockNumber}
                        dealType='tenMinDeal'
                      />
                    </div>
                    <div className='ml-2'>
                      <ListLimitSelector limit={limit} setLimit={setLimit} />
                    </div>
                  </div>
                  <div>
                    <Table columns={columns} data={data} />
                    <Pagination
                      total={itemsCount}
                      limit={limit}
                      page={page}
                      setPage={setPage}
                      size='lg'
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})
export default HolidayDealBulkApplyModal

const ApplyTabHandler = ({
  applyStatusTab,
  setApplyStatusTab,
  setPage,
  btnText1 = '',
  btnText2 = '',
}) => {
  return (
    <div>
      <button
        type='button'
        onClick={() => {
          setApplyStatusTab('candidate')
          setPage(1)

          // checkBoxValueResetter()
        }}
        className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
          applyStatusTab === 'candidate'
            ? 'border-b-4 border-positive-500 text-black'
            : 'text-gray-400'
        }`}
      >
        {btnText1 === '' ? '신청 대상 상품' : btnText1}
      </button>
      <button
        type='button'
        onClick={() => {
          setApplyStatusTab('complete')
          setPage(1)
          // checkBoxValueResetter()
        }}
        className={`select-none text-xl pb-1 font-bold duration-200 ease-in-out ${
          applyStatusTab === 'complete'
            ? 'border-b-4 border-positive-500 text-black'
            : 'text-gray-400'
        } w-max`}
      >
        {btnText2 === '' ? '신청 완료 상품' : btnText2}
      </button>
    </div>
  )
}
export default ApplyTabHandler

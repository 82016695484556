import SellerStore from '../../stores/SellerStore'
import React from 'react'
import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import OrderStore from '../../stores/OrderStore'
import { observer } from 'mobx-react-lite'

const CancelOrderButton = observer(({ order, setOrders, status }) => {
  return (
    <Button
      size='sm'
      appearance='blue'
      type='button'
      onClick={async () => {
        if (status === 'team-gathering') {
          if (SellerStore.isLoading) {
            return
          }
          SellerStore.setIsLoading(true)
          const result = await backendApis.approveOrderCancel(
            order._id,
            order.totalPrice,
            order.shippingFee,
            order.itemInfo.shippingInfo.returnFee,
          )
          SellerStore.setIsLoading(false)
          if (result?.status === 200) {
            OrderStore.setDisplayedOrders(
              OrderStore.displayedOrders
                // .filter((orderTemp) => orderTemp._id !== order._id)
                .map((orderTemp) => {
                  if (orderTemp._id === order._id) {
                    return {
                      ...orderTemp,
                      status: 'canceled-team-gathering',
                    }
                  }
                  if (orderTemp.teamDealId === order.teamDealId) {
                    return {
                      ...orderTemp,
                      teamDealInfo: {
                        ...orderTemp.teamDealInfo,
                        membersInfo:
                          orderTemp.teamDealInfo.membersInfo.slice(1),
                      },
                    }
                  }
                  return orderTemp
                }),
            )
            if (setOrders) {
              OrderStore.setUserOrders(
                OrderStore.userOrders
                  // .filter((orderTemp) => orderTemp._id !== order._id)
                  .map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: 'canceled-team-gathering',
                      }
                    }
                    if (orderTemp.teamDealId === order.teamDealId) {
                      return {
                        ...orderTemp,
                        teamDealInfo: {
                          ...orderTemp.teamDealInfo,
                          membersInfo:
                            orderTemp.teamDealInfo.membersInfo.slice(1),
                        },
                      }
                    }
                    return orderTemp
                  }),
              )
            }
            OrderStore.changeOrdersNumPerStatus('team-gathering', -1)
            OrderStore.changeOrdersNumPerStatus('canceled-team-gathering', 1)
          }
        } else if (status === 'pre-shipping') {
          if (SellerStore.isLoading) {
            return
          }
          if (
            window.confirm(
              "실제 취소사유와 다르게 취소가 되는 경우,\n'고의적 부당행위'로 불이익이 발생할 수 있으므로 주의해주세요.\n주문 취소를 진행하시겠습니까?",
            )
          ) {
            OrderStore?.setCancelOrderInfo({
              ...order,
            })
            if (setOrders) {
              OrderStore?.setIsUserOrder(true)
            } else {
              OrderStore?.setIsUserOrder(false)
            }
            OrderStore?.setOpenCancelOrderModal(true)
          }
        } else if (
          status === 'sending' ||
          status === 'shipping' ||
          status === 'arrived'
        ) {
          if (SellerStore.isLoading) {
            return
          }
          if (
            window.confirm(
              "실제 취소사유와 다르게 취소가 되는 경우,\n'고의적 부당행위'로 불이익이 발생할 수 있으므로 주의해주세요.\n주문 취소를 진행하시겠습니까?",
            )
          ) {
            OrderStore?.setCancelOrderInfo({
              ...order,
            })
            if (setOrders) {
              OrderStore?.setIsUserOrder(true)
            } else {
              OrderStore?.setIsUserOrder(false)
            }
            OrderStore?.setOpenCancelOrderModal(true)
          }
        } else if (
          status === 'canceling-pre-shipping' &&
          !order?.excelDownloadedAt
        ) {
          if (SellerStore.isLoading) {
            return
          }
          SellerStore.setIsLoading(true)
          const result = await backendApis.approveOrderCancel(
            order._id,
            order.totalPrice,
            order.shippingFee,
            order.itemInfo.shippingInfo.returnFee,
            0,
          )
          SellerStore.setIsLoading(false)
          if (result?.status === 200) {
            OrderStore.setDisplayedOrders(
              OrderStore.displayedOrders.map((orderTemp) => {
                if (orderTemp._id === order._id) {
                  return { ...orderTemp, status: 'canceled-pre-shipping' }
                }
                return orderTemp
              }),
            )
            if (setOrders) {
              OrderStore.setUserOrders(
                OrderStore.userOrders.map((orderTemp) => {
                  if (orderTemp._id === order._id) {
                    return {
                      ...orderTemp,
                      status: 'canceled-pre-shipping',
                    }
                  }
                  return orderTemp
                }),
              )
            }
            OrderStore.changeOrdersNumPerStatus('canceling-pre-shipping', -1)
            OrderStore.changeOrdersNumPerStatus(
              'canceling-pre-shipping-pre-excel',
              -1,
            )
            OrderStore.changeOrdersNumPerStatus('canceled-pre-shipping', 1)
          }
        } else if (
          status === 'canceling-pre-shipping' &&
          order?.excelDownloadedAt
        ) {
          if (SellerStore.isLoading) {
            return
          }
          const returnFee = 0

          if (
            window.confirm(
              '배송중지 요청을 승인하시겠어요?\n확인 버튼을 누르면 배송중지 요청 승인 및 환불이 진행됩니다.',
            )
          ) {
            SellerStore.setIsLoading(true)
            const result = await backendApis.approveOrderCancel(
              order._id,
              order.totalPrice,
              order.shippingFee,
              order.itemInfo.shippingInfo.returnFee,
              parseInt(returnFee, 10),
            )
            SellerStore.setIsLoading(false)
            if (result.status === 200) {
              // OrderStore.setCancelingShippingOrders(
              //   OrderStore.cancelingShippingOrders.filter(
              //     (orderTemp) => orderTemp._id !== order._id,
              //   ),
              // )
              OrderStore.setDisplayedOrders(
                OrderStore.displayedOrders.map((orderTemp) => {
                  if (orderTemp._id === order._id) {
                    return { ...orderTemp, status: 'canceled-pre-shipping' }
                  }
                  return orderTemp
                }),
              )
              if (setOrders) {
                OrderStore.setUserOrders(
                  OrderStore.userOrders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: 'canceled-pre-shipping',
                      }
                    }
                    return orderTemp
                  }),
                )
              }
              OrderStore.changeOrdersNumPerStatus('canceling-pre-shipping', -1)
              OrderStore.changeOrdersNumPerStatus(
                'canceling-pre-shipping-post-excel',
                -1,
              )
              OrderStore.changeOrdersNumPerStatus('canceled-pre-shipping', 1)
            }
          }
        } else if (status === 'canceling-shipping') {
          if (SellerStore.isLoading) {
            return
          }

          if (
            window.confirm(
              '배송중지 요청을 승인하시겠어요?\n확인 버튼을 누르면 배송중지 요청 승인 및 환불이 진행됩니다.',
            )
          ) {
            SellerStore.setIsLoading(true)
            let returnFee = order.itemInfo.shippingInfo.returnFee
            if (
              !['단순 변심', '다른 상품 재주문 예정'].includes(
                order.cancelInfo.cancelReason,
              )
            ) {
              returnFee = 0
            }
            const result = await backendApis.approveOrderCancel(
              order._id,
              order.totalPrice,
              order.shippingFee,
              order.itemInfo.shippingInfo.returnFee,
              parseInt(returnFee, 10),
            )
            SellerStore.setIsLoading(false)
            if (result.status === 200) {
              // OrderStore.setCancelingShippingOrders(
              //   OrderStore.cancelingShippingOrders.filter(
              //     (orderTemp) => orderTemp._id !== order._id,
              //   ),
              // )
              OrderStore.setDisplayedOrders(
                OrderStore.displayedOrders.map((orderTemp) => {
                  if (orderTemp._id === order._id) {
                    return { ...orderTemp, status: 'canceled-shipping' }
                  }
                  return orderTemp
                }),
              )
              if (setOrders) {
                OrderStore.setUserOrders(
                  OrderStore.userOrders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: 'canceled-shipping',
                      }
                    }
                    return orderTemp
                  }),
                )
              }
              OrderStore.changeOrdersNumPerStatus('canceling-shipping', -1)
              OrderStore.changeOrdersNumPerStatus('canceled-shipping', 1)
            }
          }
        } else if (status === 'refunding') {
          if (SellerStore.isLoading) {
            return
          }

          if (
            window.confirm(
              '반품 요청을 승인하시겠어요?\n확인 버튼을 누르면 반품 요청 승인 및 환불이 진행됩니다.',
            )
          ) {
            SellerStore.setIsLoading(true)
            let returnFee = order?.itemInfo?.shippingInfo?.returnFee
            if (order?.refundInfo?.refundReason !== '단순 변심') {
              returnFee = 0
            }
            const result = await backendApis.approveOrderCancel(
              order._id,
              order.totalPrice,
              order.shippingFee,
              order.itemInfo.shippingInfo.returnFee,
              parseInt(returnFee, 10),
            )
            SellerStore.setIsLoading(false)
            if (result.status === 200) {
              OrderStore.setDisplayedOrders(
                OrderStore.displayedOrders.map((orderTemp) => {
                  if (orderTemp._id === order._id) {
                    return { ...orderTemp, status: 'refunded' }
                  }
                  return orderTemp
                }),
              )
              if (setOrders) {
                OrderStore.setUserOrders(
                  OrderStore.userOrders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: 'refunded',
                      }
                    }
                    return orderTemp
                  }),
                )
              }
              OrderStore.changeOrdersNumPerStatus('refunding', -1)
              OrderStore.changeOrdersNumPerStatus('refunded', 1)
            }
          }
        }
      }}
    >
      취소 승인
    </Button>
  )
})

export default CancelOrderButton

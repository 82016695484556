import React, { useEffect, useState } from 'react'
import Page from '../components/atoms/page'
import DealRankNotice from '../components/molecules/dealRankNotice'
import ItemComponent from '../components/ItemComponents/ItemComponent'
import Pagination from '../components/molecules/pagination'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import { FcPrivacy as TitleIcon } from 'react-icons/fc'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import DealApplicationModal from '../components/molecules/dealApplicationModal'
import VerificationScreen from './VerificationScreen'
import { observer } from 'mobx-react-lite'

const TreasureDealApplicationScreen = observer(({ sellerInfo }) => {
  const dealType = 'treasureDeal'
  const [selectedItem, setSelectedItem] = useState()
  const [applyStatusTab, setApplyStatusTab] = useState('candidate')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [openDealApplicationModal, setOpenDealApplicationModal] =
    useState(false)
  const [itemsCount, setItemsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [itemsInfo, setItemsInfo] = useState([])
  const [verified, setVerified] = useState(false)

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    SellerStore.setIsLoading(true)
    const itemCondition = { deletedAt: null }
    if (applyStatusTab === 'candidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: {
                $and: [{ key: 'deal', value: dealType }],
              },
            },
          },
        },
      ]
    } else if (applyStatusTab === 'complete') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        {
          itemFilters: {
            $elemMatch: {
              $and: [{ key: 'deal', value: dealType }],
            },
          },
        },
      ]
    }
    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        SellerStore.setIsLoading(false)
        return
      }
    }
    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    setIsLoading(false)
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    if (sellerInfo?.sellerFilters && Array.isArray(sellerInfo?.sellerFilters)) {
      const verificationCondition =
        sellerInfo?.sellerFilters?.filter((filter) => {
          return filter.key === 'auth' && filter.value === dealType
        })?.length > 0
      setVerified(verificationCondition || false)
      if (verificationCondition) {
        fetchSegmentedItemsInfo(true)
      }
    }
  }, [limit, applyStatusTab, sellerInfo])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  useEffect(async () => {
    await backendApis.recordEventLog(
      'TreasureDealApplicationScreen',
      'entered screen',
    )
  }, [])

  return (
    <>
      {verified ? (
        <div className='w-screen max-w-7xl'>
          <Page title='보물특가 신청' Icon={TitleIcon}>
            <DealRankNotice minRank='B' />
            <div className='flex flex-col flex-1 p-10 text-white bg-black shadow-xl rounded-xl'>
              <div className='mb-5 font-bold'>
                보물특가는 특정 유저에게 특정 시간대에만 노출되는 완전 폐쇄
                매대입니다.
              </div>
              <div>
                구매 전환율이 높은 지면인 만큼 초대받은 소수의 셀러분들만 신청
                가능한 매대입니다.
              </div>
              <div>
                온라인 최저가 대비 최소 15% 이상 저렴하게 신청해주셔야 노출이
                가능합니다.
              </div>
              <div>
                신청해주신 상품은 노출 가능 지면이 생길 때마다 랜덤으로
                노출됩니다.
              </div>
            </div>
            <div>
              <div className='flex items-center justify-between flex-1 mt-10 mb-5'>
                <div className='mb-5 '>
                  <button
                    type='button'
                    onClick={() => {
                      setApplyStatusTab('candidate')
                      setPage(1)
                    }}
                    className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
                      applyStatusTab === 'candidate'
                        ? 'border-b-4 border-positive-500 text-black'
                        : 'text-gray-400'
                    }`}
                  >
                    신청 대상 상품
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setApplyStatusTab('complete')
                      setPage(1)
                    }}
                    className={`select-none text-xl pb-1 font-bold duration-200 ease-in-out ${
                      applyStatusTab === 'complete'
                        ? 'border-b-4 border-positive-500 text-black'
                        : 'text-gray-400'
                    } w-max`}
                  >
                    신청 완료 상품
                  </button>
                </div>
                <div className='flex justify-end'>
                  <div>
                    <ListLimitSelector limit={limit} setLimit={setLimit} />
                  </div>
                </div>
              </div>
              <div>
                {applyStatusTab === 'candidate' ? (
                  <>
                    {itemsInfo.map((itemInfo, idx) => (
                      <div key={`${itemInfo._id + idx.toString()}1`}>
                        <ItemComponent
                          itemInfo={itemInfo}
                          dealRequest={() => {
                            backendApis.recordEventLog(
                              'TreasureDealApplicationScreen',
                              'entered application modal',
                            )
                            setSelectedItem(itemInfo)
                            setOpenDealApplicationModal(true)
                          }}
                          dealType='treasureDeal'
                          dealRankLimit='B'
                          isRankItem
                          dealApplyInfo={applyStatusTab === 'complete'}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {itemsInfo.map((itemInfo, idx) => (
                      <div key={`${itemInfo._id + idx.toString()}2`}>
                        <ItemComponent
                          itemInfo={itemInfo}
                          dealRequest={() => {
                            backendApis.recordEventLog(
                              'TreasureDealApplicationScreen',
                              'entered application modal',
                            )
                            setSelectedItem(itemInfo)
                            setOpenDealApplicationModal(true)
                          }}
                          dealCancelRequest={async () => {
                            backendApis.recordEventLog(
                              'TreasureDealApplicationScreen',
                              'canceled treasure deal',
                            )
                            const result =
                              await backendApis.deleteDealOptionsInfo(
                                itemInfo?._id,
                                dealType,
                              )
                            if (result?.status === 200) {
                              alert('보물특가 취소가 완료되었습니다')
                              fetchSegmentedItemsInfo(true)
                            }
                          }}
                          dealType='treasureDeal'
                          dealRankLimit='B'
                          isRankItem
                          dealApplyInfo={applyStatusTab === 'complete'}
                        />
                      </div>
                    ))}
                  </>
                )}
                {itemsInfo?.length === 0 && (
                  <div className='flex items-center justify-center flex-1 px-10 py-10 mb-5 bg-white shadow-lg rounded-xl'>
                    조건에 맞는 상품이 없습니다.
                  </div>
                )}
                <div>
                  <Pagination
                    total={itemsCount}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    size='lg'
                  />
                </div>
              </div>
              {openDealApplicationModal && (
                <DealApplicationModal
                  showModal={openDealApplicationModal}
                  setShowModal={setOpenDealApplicationModal}
                  dealType={dealType}
                  itemInfo={selectedItem}
                  setItemInfo={setSelectedItem}
                  applied={applyStatusTab === 'complete'}
                />
              )}
            </div>
          </Page>
        </div>
      ) : (
        <VerificationScreen type={dealType} setVerified={setVerified} />
      )}
    </>
  )
})

export default TreasureDealApplicationScreen

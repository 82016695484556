import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import moment from 'moment'
import backendApis from '../../utils/backendApis'
import Button from '../atoms/button'
import { Link, useHistory } from 'react-router-dom'
import Ping from '../atoms/ping'
import { QuestionStatus, QuestionType } from '../../data/question'
import AlertModal from '../atoms/alertModal'

const CsInfo = observer(() => {
  const [delayedItemQuestionsCount, setDelayedItemQuestionsCount] = useState(0)
  const [delayedQuestionsFromAdminCount, setDelayedQuestionsFromAdminCount] =
    useState(0)
  const [showAlertModal, setShowAlertModal] = useState(false)

  const history = useHistory()

  const loadDelayedItemQuestions = useCallback(async () => {
    const condition = {
      type: QuestionType.ITEM_QUESTION.description,
      status: QuestionStatus.NOT_ANSWERED.description,
    }
    const result = await backendApis.loadQuestionsByCondition(condition, -1, 0)
    if (result?.status === 200) {
      return result?.data.filter((question) => {
        return (
          moment().diff(moment(question?.questionInfo?.createdAt), 'days') > 1
        )
      }).length
    }
    return 0
  }, [ItemStore.itemQuestions, ItemStore.questionsFromAdmin])

  const loadDelayedQuestionsFromAdmin = useCallback(async () => {
    const condition = {
      type: QuestionType.QUESTION_FROM_ADMIN.description,
      status: QuestionStatus.NOT_ANSWERED.description,
    }
    const result = await backendApis.loadQuestionsByCondition(condition, -1, 0)
    if (result?.status === 200) {
      return result?.data.filter((question) => {
        return (
          moment().diff(moment(question?.questionInfo?.createdAt), 'days') > 1
        )
      }).length
    }
    return 0
  }, [ItemStore.itemQuestions, ItemStore.questionsFromAdmin])

  useEffect(async () => {
    await ItemStore.loadUnansweredQuestions()
  }, [])

  useEffect(async () => {
    const count = await loadDelayedItemQuestions()
    const count2 = await loadDelayedQuestionsFromAdmin()
    setDelayedItemQuestionsCount(count)
    setDelayedQuestionsFromAdminCount(count2)
  }, [ItemStore.itemQuestions, ItemStore.questionsFromAdmin])

  useEffect(async () => {
    const count = await loadDelayedItemQuestions()
    const count2 = await loadDelayedQuestionsFromAdmin()
    setDelayedItemQuestionsCount(count)
    setDelayedQuestionsFromAdminCount(count2)
  }, [ItemStore.itemQuestions, ItemStore.questionsFromAdmin])

  useEffect(() => {
    if (delayedItemQuestionsCount > 0 || delayedQuestionsFromAdminCount > 0) {
      setShowAlertModal(true)
    }
  }, [ItemStore.itemQuestions, ItemStore.questionsFromAdmin])

  return (
    <>
      <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
        <div className='flex justify-between'>
          <div className='text-lg font-bold'>고객문의 및 고객센터 문의</div>
          <div className='flex items-center'>
            {(delayedItemQuestionsCount > 0 ||
              delayedQuestionsFromAdminCount > 0) && (
              <div>
                <Ping />
              </div>
            )}

            <Button
              textColor='black'
              appearance='transparent'
              className='hover:bg-slate-50'
            >
              <Link to='/item-questions'>전체 보기 및 답변 제출</Link>
            </Button>
          </div>
        </div>
        <div className='text-sm'>
          고객문의 및 고객센터 문의는 최대 1 영업일 이내에 모두 답변
          부탁드립니다.
        </div>
        <div className='flex flex-col mt-5 space-y-2'>
          <div className='flex'>
            <div className='w-1/4'>고객문의 답변 대기</div>
            <div className='w-1/6 font-bold text-left'>
              {ItemStore.itemQuestions.length} 건
            </div>
            <div className='w-1/4'>고객문의 지연</div>
            <div className='w-1/6 font-bold text-left text-red-500'>
              {delayedItemQuestionsCount} 건
            </div>
          </div>
          <div className='flex'>
            <div className='w-1/4'>고객센터 문의 답변 대기</div>
            <div className='w-1/6 font-bold text-left'>
              {ItemStore.questionsFromAdmin.length} 건
            </div>
            <div className='w-1/4'>고객센터 문의 지연</div>
            <div className='w-1/6 font-bold text-left text-red-500'>
              {delayedQuestionsFromAdminCount} 건
            </div>
          </div>
        </div>
        <AlertModal
          hideDurationInDays={1}
          isTriggered={showAlertModal}
          handleTrigger={setShowAlertModal}
          onConfirm={() => {
            history.push('/item-questions')
          }}
          title='고객 문의 또는 고객센터 문의 지연'
        >
          <div className='flex flex-col break-all whitespace-normal'>
            <div>
              고객 문의 지연 {delayedItemQuestionsCount}건, 고객센터 문의 지연{' '}
              {delayedQuestionsFromAdminCount}건이 있습니다.
            </div>
            <div>확인 버튼을 누르면 통합 문의 관리 페이지로 이동합니다.</div>
          </div>
        </AlertModal>
      </div>
    </>
  )
})

export default CsInfo

import SellerStore from '../../stores/SellerStore'
import React from 'react'
import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import OrderStore from '../../stores/OrderStore'
import { observer } from 'mobx-react-lite'

const UpdateShippingNumberButton = observer(
  ({ order, setOrders, orders, shippingNumber, shippingCompanyCode }) => {
    return (
      <Button
        size='sm'
        appearance='blue'
        type='button'
        onClick={async () => {
          const correspondingShippingCompanyInfo =
            OrderStore.shippingCompaniesInfo.find(
              (shippingCompanyInfo) =>
                shippingCompanyInfo.shippingCompanyCode === shippingCompanyCode,
            )
          const shippingInfo = {
            ...order.shippingInfo,
            ...correspondingShippingCompanyInfo,
            shippingNumber: shippingNumber?.replace(/-/g, '').trim(),
          }
          if (correspondingShippingCompanyInfo && shippingNumber) {
            if (SellerStore.isLoading) {
              return
            }
            SellerStore.setIsLoading(true)
            const shippingInfos = [
              {
                orderId: order._id,
                shippingInfo,
              },
            ]
            const result = await backendApis.editShippingInfosInBulk(
              shippingInfos,
            )
            SellerStore.setIsLoading(false)
            if (result.status === 2000 && result?.data?.list?.[0]?.success) {
              OrderStore.setDisplayedOrders(
                OrderStore.displayedOrders.map((orderTemp) => {
                  if (orderTemp._id === order._id) {
                    return {
                      ...orderTemp,
                      shippingInfo,
                    }
                  }
                  return orderTemp
                }),
              )
              if (setOrders) {
                OrderStore.setUserOrders(
                  orders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        shippingInfo,
                      }
                    }
                    return orderTemp
                  }),
                )
              }
              alert('송장 업로드가 완료되었습니다.')
            } else if (
              result.status === 2001 &&
              result?.data?.list?.[0]?.e_message
            ) {
              alert(result?.data?.list?.[0]?.e_message)
            } else {
              alert('송장 업로드 과정에서 문제가 발생했습니다.')
            }
          } else {
            alert('택배사와 송장번호를 확인해주세요.')
          }
        }}
      >
        운송장 수정
      </Button>
    )
  },
)

export default UpdateShippingNumberButton

import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import commaNumber from 'comma-number'
import Button from '../atoms/button'
import SellerViralEngine from './sellerViralEngine'

const AdCreditInfo = () => {
  const [credtInfo, setCredtInfo] = useState({})
  const [viralEngineModalOpen, setViralEngineModalOpen] = useState(false)
  const Remark = ({ totalCredit }) => {
    if (totalCredit < 10000) {
      return (
        <div className='text-sm text-red-500'>
          광고 크레딧이 부족합니다. 크레딧을 충전해주세요.
        </div>
      )
    }
    return <></>
  }
  const colorSetter = (totalCredit) => {
    if (totalCredit < 10000) {
      return 'text-red-500'
    }
  }

  useEffect(() => {
    const getAdCreditInfo = async () => {
      const result = await backendApis.getAdCreditInfo()
      if (result?.status === 2000) {
        setCredtInfo(result?.data)
      }
    }
    getAdCreditInfo()
  }, [])
  return (
    <>
      <div className='flex flex-col justify-center flex-1 px-10 py-5 bg-white shadow-md select-none rounded-xl'>
        <div className='flex flex-row items-center justify-between '>
          <div className='flex flex-row items-center'>
            <div className='mr-5 text-lg font-bold'>광고 크레딧 현황</div>
            <Remark
              totalCredit={
                (credtInfo?.adCreditInfo?.paidCredit || 0) +
                (credtInfo?.adCreditInfo?.supportCredit || 0)
              }
            />
          </div>
          <div className='flex flex-row items-center'>
            <div className='mr-5'>
              <span className='mr-2 font-bold'>유상 크레딧</span>
              {commaNumber(credtInfo?.adCreditInfo?.paidCredit || 0)}원
            </div>
            <div className='mr-5'>
              <span className='mr-2 font-bold'>무상 크레딧</span>
              {commaNumber(credtInfo?.adCreditInfo?.supportCredit || 0)}원
            </div>
            <div className='mr-5'>
              <span className='mr-2 font-bold'>총 크레딧</span>
              <span
                className={colorSetter(
                  (credtInfo?.adCreditInfo?.paidCredit || 0) +
                    (credtInfo?.adCreditInfo?.supportCredit || 0),
                )}
              >
                {commaNumber(
                  (credtInfo?.adCreditInfo?.paidCredit || 0) +
                    (credtInfo?.adCreditInfo?.supportCredit || 0),
                )}
                원
              </span>
            </div>
            <Button href='/ad-credit' appearance='positiveSub'>
              충전하기
            </Button>
            {/* <Button
              onClick={() => {
                setViralEngineModalOpen(true)
              }}
              appearance='blue'
            >
              EVENT
            </Button> */}
          </div>
        </div>
      </div>
      <SellerViralEngine
        showModal={viralEngineModalOpen}
        setShowModal={setViralEngineModalOpen}
      />
    </>
  )
}
export default AdCreditInfo

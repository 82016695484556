import React, { useState, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import * as ExcelJS from 'exceljs'
import moment from 'moment'
import { saveAs } from 'file-saver'
import FastShippingOrderMent from './OrderComponents/FastShippingOrderMent'
import Format from '../utils/format'
import tableUtils from '../utils/tableUtils'
import Table from './ADS/molecules/table'
import Pagination from './ADS/atoms/pagination'
import Button from './atoms/button'
import { SiGooglesheets } from 'react-icons/si'
import shortUUID from 'short-uuid'
import { getSettlementAmount } from '../helper/order'
import Modal from 'react-modal'
import ImageModal from './atoms/imageModal'

const PAGE_ORDER_NUM = 10

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

const parseCancelReason = (cancelReason) => {
  if (cancelReason === 'Changed my mind') {
    return '단순 변심'
  }
  return cancelReason
}

const headers = [
  { header: '주문아이디', key: 'orderId', width: 20 },
  { header: '상품아이디', key: 'itemId', width: 20 },
  { header: '합배송아이디', key: 'addressId', width: 20 },
  { header: '판매자 상품코드', key: 'optionCode', width: 20 },
  { header: '상품명', key: 'itemTitle', width: 30 },
  { header: '옵션', key: 'option', width: 40 },
  { header: '수량', key: 'quantity' },
  { header: '상품가격', key: 'totalPrice' },
  { header: '배송비', key: 'shippingFee' },
  { header: '추가지원금', key: 'platformSupportPrice' },
  { header: '쿠폰할인금', key: 'finalDiscountAmount' },
  { header: '정산대상금액(수수료 제외)', key: 'settlementAmount' },
  { header: '주소', key: 'address', width: 50 },
  { header: '우편번호', key: 'postcode' },
  { header: '공동현관 비밀번호', key: 'frontDoorPassword', width: 20 },
  { header: '수령 방법', key: 'detailForReceivingMethod' },
  { header: '수령인', key: 'recipient' },
  { header: '수령인 연락처', key: 'recipientPhoneNumber', width: 20 },
  { header: '주문 시점', key: 'payedAt' },
  { header: '택배사', key: 'shippingCompany' },
  { header: '운송장번호', key: 'shippingNumber', width: 30 },
  { header: '환불 사유', key: 'refundReason', width: 20 },
]

const RefundedOrders = observer(({ orders, setOrders }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const openModal = (imageUri) => {
    setSelectedImage(imageUri)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setSelectedImage(null)
  }
  const translator = shortUUID()

  const columns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '주문아이디', accessor: '_id' },
      { Header: '합배송아이디', accessor: 'addressId' },
      { Header: '상품명', accessor: 'itemTitle' },
      { Header: '옵션', accessor: 'option' },
      { Header: '수량', accessor: 'quantity' },
      { Header: '상품가격', accessor: 'totalPrice' },
      { Header: '추가지원금', accessor: 'platformSupportPrice' },
      { Header: '쿠폰할인금', accessor: 'finalDiscountAmount' },
      { Header: '정산대상금액(수수료 제)', accessor: 'settlementAmount' },
      { Header: '배송비', accessor: 'shippingFee' },
      { Header: '배송지', accessor: 'address' },
      { Header: '상세 주소', accessor: 'detailAddress' },
      { Header: '수령인', accessor: 'recipient' },
      { Header: '수령인 연락처', accessor: 'recipientPhoneNumber' },
      { Header: '주문 시점', accessor: 'timePast' },
      { Header: '반품 사유', accessor: 'refundDetailedReason' },
      { Header: '취소 사유', accessor: 'cancelReason' },
    ],
    [],
  )

  const data = useMemo(() => {
    return orders
      ?.filter((order) => order.status === 'refunded')
      .slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.map((order, index) => {
        let timePast = Math.ceil(
          (new Date().getTime() - new Date(order.payedAt).getTime()) /
            (1000 * 60 * 60),
        )
        const daysPast = Math.floor(timePast / 24)
        timePast %= 24
        if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
          if (order?.teamDealInfo?.subAddressInfo) {
            order.addressInfo = order?.teamDealInfo?.subAddressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          } else {
            order.addressInfo = order?.teamDealInfo?.addressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          }
        }
        return {
          ...order,
          index: index + 1 + PAGE_ORDER_NUM * pageIndex,
          itemTitle: (
            <a
              href={order.url
                ?.replace('m.coupang', 'coupang')
                ?.replace('/vm/', '/vp/')}
            >
              {order.itemInfo?.itemTitle}
            </a>
          ),
          option: tableUtils.parseOptionText(
            order?.itemInfo?.optionsInfo,
            order?.selectedOption,
          ),
          totalPrice: order?.totalPrice,
          itemId: order?.itemInfo?.itemId,
          addressId: `${Format.DateToday(order?.payedAt)}${
            checkIfValidUUID(order?.addressInfo?.addressId) &&
            translator.fromUUID(order?.addressInfo?.addressId || order?._id)
          }`.substring(2),
          optionCode: order?.selectedOption?.optionCode || '',
          // option: optionsText.map((option) => <div key={option}>{option}</div>),
          platformSupportPrice:
            (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1),
          finalDiscountAmount:
            order.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(order),
          shippingFee: order?.shippingFee,
          address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          detailAddress: order.addressInfo?.detailAddress,
          recipient: Format.Recipient(order.addressInfo?.recipient),
          recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          timePast: (
            <>
              <div>{moment(order.payedAt).format('YYYY-MM-DD HH:mm')}</div>
              <div>
                ({daysPast > 0 ? `${daysPast}일 ` : ''} {timePast} 시간 전)
              </div>
            </>
          ),
          refundDetailedReason: (
            <>
              <div className='flex-column'>
                <div className='flex-column'>
                  {`${order?.refundInfo?.refundDetailedReason} `} <br />
                </div>
                <div className='mt-1 font-bold '>
                  {order.refundInfo?.refundReason}
                </div>
                <div className='mt-1'>
                  {order.refundInfo?.refundImageUris?.map((imageUri, index) => (
                    <img
                      key={imageUri + index.toString()}
                      alt={imageUri}
                      src={imageUri}
                      style={{
                        height: 196,
                        width: 196,
                        paddingRight: 16,
                        objectFit: 'contain',
                        cursor: 'pointer',
                      }}
                      onClick={() => openModal(imageUri)}
                    />
                  ))}
                </div>
              </div>
            </>
          ),
          cancelReason: parseCancelReason(order.refundInfo?.refundReason),
        }
      })
  }, [
    orders?.filter((order) => order.status === 'refunded')?.length,
    pageIndex,
  ])

  const fetchNextData = async () => {
    if (SellerStore.isLoading) {
      return
    }
    if (
      pageIndex + 1 >
      Math.ceil(
        orders.filter((order) => order.status === 'refunded').length /
          PAGE_ORDER_NUM,
      )
    ) {
      SellerStore.setIsLoading(true)
      const result = await backendApis.getOrdersInStatusBeforeCertainTimeStamp(
        'refunded',
        orders.slice(-1)?.length > 0 && orders.slice(-1)[0]?.payedAt,
      )
      if (result?.status === 200 && result.data?.length > 0) {
        OrderStore.setDisplayedOrders([
          ...OrderStore.displayedOrders,
          ...result.data,
        ])
      }
      SellerStore.setIsLoading(false)
    }
  }

  useEffect(() => fetchNextData(), [pageIndex])

  return (
    <>
      <h2 className='p-5 text-2xl font-bold'>
        환불 완료(
        {setOrders
          ? orders?.filter((order) => {
              return order.status === 'refunded'
            })?.length
          : OrderStore.ordersNumPerStatus.refunded || 0}
        )
      </h2>
      <Table columns={columns} data={data} />
      <div className='flex items-center'>
        <Button
          appearance='gray'
          className='flex items-center px-2 py-3 my-2'
          type='button'
          onClick={async () => {
            if (SellerStore.isLoading) {
              return
            }
            SellerStore.setIsLoading(true)
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('주문 내역')

            const worksheetData = orders
              .filter(
                (order) =>
                  // validPreShippingOrderIds.includes(order._id) &&
                  order.status === 'refunded',
                //  &&
                // !order.excelDownloadedAt,
              )
              // .filter((order) => {
              //   if (filteredItemId) {
              //     return order.itemInfo.itemId === filteredItemId
              //   }
              //   return true
              // })
              .map((order) => {
                const optionsText = order.itemInfo.optionsInfo.optionNames.map(
                  (value, optionIndex) => {
                    const optionDetail =
                      order.itemInfo.optionsInfo.totalOptions[optionIndex][
                        order.selectedOption[optionIndex]
                      ].name
                    return `${optionIndex}. ${value}: ${optionDetail}`
                  },
                )
                if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
                  if (order?.teamDealInfo?.subAddressInfo) {
                    order.addressInfo = order?.teamDealInfo?.subAddressInfo
                    order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
                  } else {
                    order.addressInfo = order?.teamDealInfo?.addressInfo
                    order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
                  }
                }
                const recipientPhoneNumber =
                  order.addressInfo?.recipientPhoneNumber

                const recipientPhoneNumberProcessed = recipientPhoneNumber

                return {
                  orderId: order._id,
                  itemId: order?.itemInfo?.itemId,
                  addressId: `${Format.DateToday(order?.payedAt)}${
                    checkIfValidUUID(order?.addressInfo?.addressId) &&
                    translator.fromUUID(
                      order?.addressInfo?.addressId || order?._id,
                    )
                  }`.substring(2),
                  optionCode: order?.selectedOption?.optionCode || '',
                  itemTitle: order.itemInfo?.itemTitle,
                  option: optionsText.join('\n'),
                  quantity: order.quantity,
                  address: `${order.addressInfo?.postcodeAddress} ${order.addressInfo?.detailAddress}`,
                  postcode: order.addressInfo?.postcode,
                  frontDoorPassword: order.addressInfo?.frontDoorPassword,
                  detailForReceivingMethod:
                    order.addressInfo?.receivingMethod === 'door'
                      ? '문 앞'
                      : order.addressInfo?.detailForReceivingMethod,
                  recipient: Format.Recipient(order.addressInfo?.recipient),
                  recipientPhoneNumber: recipientPhoneNumberProcessed,
                  payedAt: moment(order.payedAt).format('YYYY-MM-DD HH:mm'),
                  shippingCompany: order.shippingInfo.shippingCompanyName,
                  shippingNumber: order.shippingInfo.shippingNumber,
                  totalPrice: order?.totalPrice,
                  shippingFee: order?.shippingFee,
                  // option: optionsText.map((option) => <div key={option}>{option}</div>),
                  platformSupportPrice:
                    (order.selectedOption?.platformSupportPrice || 0) *
                    (order.quantity || 1),
                  finalDiscountAmount:
                    order.selectedCouponInfo?.finalDiscountAmount || 0,
                  settlementAmount: getSettlementAmount(order),
                  refundReason: order.refundInfo?.refundReason,
                }
              })
            worksheet.columns = headers
            worksheet.addRows(worksheetData)
            worksheet.getColumn(3).alignment = { wrapText: true }
            const headerRow = worksheet.getRow(1)
            headers.forEach((header, index) => {
              headerRow.getCell(index + 1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFEA3F49' },
              }
            })
            worksheet.getRow(1).font = {
              color: { argb: 'FFFFFFFF' },
              bold: true,
            }
            const mimeType = {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], mimeType)
            const saveDate = new Date()
            const year = saveDate.getFullYear()
            const month = saveDate.getMonth() + 1
            const date = saveDate.getDate()
            saveAs(
              blob,
              `${
                SellerStore.sellerInfo.sellerDisplayName
              }_환불 완료 내역_${year}-${month < 10 ? `0${month}` : month}-${
                date < 10 ? `0${date}` : date
              }.xlsx`,
            )

            // backendApis.setSellerPreShippingOrdersExcelDownloadedAt(
            //   worksheetData.map((order) => order.orderId),
            // )

            SellerStore.setIsLoading(false)
          }}
        >
          <SiGooglesheets className='mr-2' />
          엑셀 추출하기
        </Button>
        <Pagination
          page={pageIndex}
          setPage={setPageIndex}
          total={OrderStore.ordersNumPerStatus.refunded || 0}
          limit={PAGE_ORDER_NUM}
        />
        <ImageModal
          showModal={showModal}
          setShowModal={setShowModal}
          image={selectedImage}
        />
      </div>
    </>
  )
})

export default RefundedOrders

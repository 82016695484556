import { observer } from 'mobx-react-lite'
import Table from '../ADS/molecules/table'
import RankIconSetter from '../molecules/rankIconSetter'
import resize from '../../utils/resize'

const ItemRevenueTable = observer(({ itemInfo }) => {
  const tableColumns = [
    { Header: '매출순위', accessor: 'index' },
    { Header: '상품정보', accessor: 'itemTitle' },
    { Header: '등급', accessor: 'itemRank' },
    { Header: '최근 30일간 매출', accessor: 'itemRevenue' },
  ]
  const revenueTableContents = itemInfo.map((item, index) => {
    return {
      index: index + 1,
      itemTitle: (
        <div className='flex flex-row items-center justify-start'>
          <div className='h-40 w-40 mr-2'>
            <img
              alt='noImage'
              src={
                item?.mainImageUris?.at(0)
                  ? resize(item?.mainImageUris[0])
                  : resize(item?.crawledItemTheLowest?.imageUri)
              }
              className='rounded-md border border-[#000000F]'
            />
          </div>
          <p className='whitespace-normal break-words px-2 tracking-tighter text-xl font-bold w-60'>
            {item.itemTitle}
          </p>
        </div>
      ),
      itemRank: (
        <div>
          {item?.rankInfo?.itemRank && (
            <div className='flex items-center bg-gray-100 rounded-md mr-2 '>
              <RankIconSetter itemRank={item?.rankInfo?.itemRank} />
            </div>
          )}
        </div>
      ),
      itemRevenue: (
        <div className='text-xl'>{item?.itemGMV.toLocaleString()}</div>
      ),
    }
  })

  return (
    <div>
      <Table columns={tableColumns} data={revenueTableContents} />
    </div>
  )
})

export default ItemRevenueTable

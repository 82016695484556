import ExtremePriceReverse from '../../images/cheapabiltiyIcon/ExtremePriceReverse.png'

const PhoneMockUp = ({ image }) => {
  return (
    <div className='relative mx-auto border-gray-800  bg-gray-800 border-[14px] rounded-t-[2.5rem] h-[280px] w-[250px]'>
      <div className='h-[32px] w-[3px] bg-gray-800 absolute -left-[17px] top-[72px] rounded-l-lg' />
      <div className='h-[46px] w-[3px] bg-gray-800  absolute -left-[17px] top-[124px] rounded-l-lg' />
      <div className='h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg' />
      <div className='h-[64px] w-[3px] bg-gray-800  absolute -right-[17px] top-[142px] rounded-r-lg' />

      <div className='rounded-t-[2rem] overflow-hidden w-[220px] h-full bg-white'>
        <img src={image} alt='' />
        <div className='flex-row flex items-center flex-1 h-8 px-3 bg-[#FF3E3E]'>
          <img
            src={ExtremePriceReverse}
            className='mr-1 bg-[#FF3E3E] w-10'
            alt=''
          />
        </div>
      </div>
    </div>
  )
}
export default PhoneMockUp

import Button from '../atoms/button'
import { observer } from 'mobx-react-lite'
import TableData from '../atoms/tableData'
import NoticeModal from '../atoms/noticeModal'
import { useEffect, useState } from 'react'
import backendApis from '../../utils/backendApis'
import { nanoid } from 'nanoid'
import Pagination from './pagination'
import moment from 'moment'

const className = 'text-left border-none rounded-md hover:bg-gray-200'
const Notice = observer(() => {
  const [notices, setNotices] = useState([])
  const limit = 5
  const [page, setPage] = useState(1)
  const offset = (page - 1) * limit
  useEffect(() => {
    const fetchData = async () => {
      const result = await backendApis.getNotices()
      if (result?.status === 200) {
        setNotices(result?.data)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md select-none rounded-xl'>
        <div>
          <div className='text-lg font-bold'>공지사항</div>
        </div>
        <div>
          <div className='mb-5 text-sm font-normal text-sub-500'>
            공지사항에는 종종 무료 마케팅 기회가 숨어있어요
          </div>
        </div>
        <div className='flex flex-col flex-1 select-none'>
          <table className='mb-5 whitespace-normal border-transparent border-none'>
            {notices.slice(offset, offset + limit).map((notice, index) => (
              <tr key={nanoid()} className='border-transparent border-none'>
                <td className='text-left border-none'>
                  {notices.indexOf(notice) + 1}
                </td>
                <TableData className={className}>
                  <NoticeModal
                    text={notice?.text}
                    modalTitle={notice?.modalTitle}
                    imageSource={notice?.imageSource}
                    modalContent={notice?.modalContent}
                    isButton={notice?.isButton}
                    buttonText={notice?.buttonText}
                    buttonLink={notice?.buttonLink}
                    date={notice?.date}
                    isExternalButton={notice?.isExternalButton}
                    externalUrl={notice?.externalUrl}
                  />
                </TableData>
                <TableData className='text-right border-none'>
                  {moment(notice?.date).format('YY/MM/DD')}
                </TableData>
              </tr>
            ))}
          </table>
          <div>
            <Pagination
              total={notices.length}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </>
  )
})

export default Notice

import React from 'react'
import ItemTagButton from './ItemTagButton'

const FreeDeliverButton = ({ information = false, small = false }) => {
  return (
    <ItemTagButton text='무료 배송' backgroundColor='#3C3C43' small={small} />
  )
}

export default FreeDeliverButton

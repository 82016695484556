const bucketOld = 'always-bucket-dev'
const matchOld = 'https://always-bucket-dev.s3.us-west-1.amazonaws.com/'
const endpointOld = 'https://d15y6p73qu79m1.cloudfront.net/'

const bucketNew = 'alwayz-assets'
const matchNew = 'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/'
const endpointNew = 'https://resize.ilevit.com/'

const resize = (uri, w = 0, h = 0, fit = 'cover') => {
  try {
    const url = decodeURIComponent(uri)
    let bucket
    let endpoint
    let parsed

    if (url && url.includes(matchOld)) {
      parsed = url.split(matchOld)
      bucket = bucketOld
      endpoint = endpointOld
    } else if (url && url.includes(matchNew)) {
      parsed = url.split(matchNew)
      bucket = bucketNew
      endpoint = endpointNew
    }

    if (parsed) {
      const bucketKey = parsed[1].split('+').join(' ')

      if (
        !bucketKey ||
        bucketKey.toUpperCase().includes('.GIF') ||
        bucketKey.toUpperCase().includes('.HEIC')
      )
        return uri

      const key = encodeURIComponent(bucketKey).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode(`0x${p1}`)
        },
      )
      const metadata =
        w > 0
          ? {
              bucket,
              key,
              edits: {
                resize: {
                  width: w,
                  height: h || w,
                  fit,
                },
              },
            }
          : {
              bucket,
              key,
            }

      const hash = btoa(JSON.stringify(metadata))
      const resizeUri = endpoint + hash
      return resizeUri
    }

    return uri
  } catch (e) {
    return uri
  }
}

export default resize

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation, Link } from 'react-router-dom'
import Logo from '../images/logo.png'
import Notification from './molecules/notification'
import Button from './atoms/button'
import SellerStore from '../stores/SellerStore'
import RegistrationStore from '../stores/RegistrationStore'
import AuthStore from '../stores/AuthStore'

const Header = observer(({ route }) => {
  const location = useLocation()
  const [hasTempStoredData, setHasTempStoredData] = useState(false)

  const handleSave = () => {
    RegistrationStore.setIsClickedTempSaveBtnOnHeader(true)
  }

  const handleLoad = () => {
    RegistrationStore.setIsClickedTempLoadBtnOnHeader(true)
  }

  useEffect(() => {
    if (location.pathname.split('/')[2] !== 'registrations') return
    const path = location.pathname.split('/')[1]
    if (path === 'items') {
      setHasTempStoredData(
        localStorage.getItem('hasStoredDataForRegistration') === 'true',
      )
    } else if (path === 'brandfashion-items') {
      setHasTempStoredData(
        localStorage.getItem('brandHasStoredDataForRegistration') === 'true',
      )
    } else {
      setHasTempStoredData(
        localStorage.getItem('trendHasStoredDataForRegistration') === 'true',
      )
    }
    RegistrationStore.setIsTempStoredDataChanged(false)
  }, [RegistrationStore.isTempStoredDataChanged, location.pathname])

  return (
    <div className='fixed right-0 z-20 flex justify-end p-4 bg-white shadow-md select-none left-60'>
      <Link exact to='/'>
        <div className='flex flex-row items-center w-16 mr-4'>
          <img alt='logo' src={Logo} className='w-8 h-8 mr-4' /> 홈
        </div>
      </Link>
      {location.pathname === '/items/registrations' && (
        <div className='flex flex-row items-center justify-end mr-3 space-x-3'>
          <Button onClick={handleSave}>임시저장</Button>
          {hasTempStoredData && <Button onClick={handleLoad}>불러오기</Button>}
        </div>
      )}
      <div className='flex flex-1' />
      <div className='flex items-center justify-center mr-10'>
        {/* <Notification /> */}
      </div>
      <button
        className='z-50 mr-5 text-base bg-transparent font-gray-400'
        type='button'
        onClick={() => {
          localStorage.removeItem('@alwayz@seller@token@')
          const href = AuthStore.isMasterAccount ? '/admin/login' : '/login'
          window.location.href = href
        }}
      >
        로그아웃
      </button>
    </div>
  )
})
export default Header

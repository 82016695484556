export default function ImageModal({ showModal, setShowModal, image }) {
  return (
    <>
      {showModal ? (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
            <div className='relative w-full max-w-xl mx-auto my-6'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <h3 className='text-xl font-semibold'>이미지 확대 보기</h3>
                </div>
                {/* body */}
                <div className='relative flex-auto px-10 py-10'>
                  <p className='mb-5 text-lg leading-relaxed text-sub-500'>
                    <img alt='' src={image} />
                  </p>
                </div>
                {/* footer */}
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

import backendApis from '../../utils/backendApis'
import React, { useState } from 'react'
import SellerStore from '../../stores/SellerStore'
import { useHistory } from 'react-router-dom'

const AdTicketVerificationScreen = () => {
  const history = useHistory()
  const type = 'adCreditTicket'
  const [verificationCode, setVerificationCode] = useState()

  function handleInputChange(event) {
    const inputValue = event.target.value
    if (inputValue.length > 22) return
    setVerificationCode(inputValue)
  }
  return (
    <div className='flex flex-col flex-1 w-full px-10 py-10 mt-20 ml-10 bg-white shadow-lg rounded-xl'>
      <div className='mb-2 text-xl font-bold'>
        <span className='font-bold text-blue-500'>광고 크레딧</span> 티켓 코드를
        입력해주세요.
      </div>
      <div className='mb-10'>
        <div>티켓 코드를 입력하시면 무료 광고 크레딧을 받으실 수 있습니다.</div>
        <div>
          티켓은 <span className='font-bold text-blue-500'>발급 조건</span>을
          충족한 경우 이메일 또는 문자로 발급됩니다.
        </div>
      </div>
      <div>
        <form className='flex flex-1 w-full'>
          <input
            className='flex flex-1 w-1/2'
            type='text'
            value={verificationCode}
            onChange={(e) => {
              handleInputChange(e)
            }}
          />
          <input
            type='submit'
            value='사용하기'
            className='px-3 py-2 ml-5 font-bold text-white bg-blue-500 shadow-lg cursor-pointer rounded-xl'
            onClick={async (e) => {
              e.preventDefault()
              if (SellerStore.isLoading) return
              SellerStore.setIsLoading(true)
              const result = await backendApis.verifySpecialCode(
                type,
                verificationCode,
              )
              if (result?.status === 2001) {
                console.log(result?.data)
                alert('인증되었습니다.')
                SellerStore.setIsLoading(false)
                return
              }
              if (result?.status === 2002) {
                alert('이미 사용된 티켓입니다. 다른 티켓 번호를 입력해주세요.')
                SellerStore.setIsLoading(false)
                return
              }
              alert('인증코드가 맞지 않습니다.')
              SellerStore.setIsLoading(false)
            }}
          />
        </form>
      </div>
    </div>
  )
}
export default AdTicketVerificationScreen

import React, { useEffect, useState } from 'react'
import ItemStore from '../../stores/ItemStore'
import backendApis from '../../utils/backendApis'
import styled from 'styled-components'
import Modal from 'react-modal'
import SellerStore from '../../stores/SellerStore'
import Log from '../../utils/log'
import { nanoid } from 'nanoid'

const Button = styled.button`
  margin-top: 15px;
  background-color: lightblue;
  padding: 5px 10px;
  font-size: 15px;
  cursor: pointer;
`

const LureDealApplicationModal = ({
  itemInfo,
  data,
  setData,
  setItemInfo,
  showModal,
  setShowModal,
}) => {
  const [TimeDealStatus, setTimeDealStatus] = useState()
  const [optionsInfo, setOptionsInfo] = useState({
    optionNames: [''],
    totalOptions: [
      [
        {
          name: '',
          img: null,
        },
      ],
    ],
    optionPrices: [
      {
        individualPurchasePrice: 0,
        teamPurchasePrice: 0,
        timeDealSelfSupportPrice: 0,
        stockNumber: 10,
        sold_out: false,
        hurry_up: false,
      },
    ],
  })
  const [dealOptionsInfo, setDealOptionsInfo] = useState({
    optionNames: [''],
    totalOptions: [
      [
        {
          name: '',
          img: null,
        },
      ],
    ],
    optionPrices: [
      {
        individualPurchasePrice: 0,
        teamPurchasePrice: 0,
        timeDealSelfSupportPrice: 0,
        stockNumber: 10,
        sold_out: false,
        hurry_up: false,
      },
    ],
  })
  const dealType = 'lureDeal'
  const loadItemDetails = async (itemId) => {
    const result = await backendApis.loadItemDetails(itemId, 'lureDeal')
    if (result?.status === 200) {
      ItemStore.setItemInfoOnEdit(result.data)
      setDealOptionsInfo(
        result.data?.dealOptionsInfo
          ? result.data?.dealOptionsInfo
          : result.data?.optionsInfo,
      )
      setOptionsInfo(result.data?.optionsInfo)
    }
  }
  useEffect(() => {
    if (itemInfo?._id) {
      loadItemDetails(itemInfo._id)
    }
  }, [itemInfo])
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {
        setShowModal(false)
        setItemInfo({})
      }}
      style={{
        overlay: {
          zIndex: 20,
        },
        content: {
          left: '20%',
          right: '20%',
          top: '20%',
          bottom: '20%',
          padding: 30,
        },
      }}
    >
      <h3>상품명: {itemInfo?.itemTitle}</h3>
      <h4 className='font-bold'>
        * 최초로 기입된 순삭 판매가는, 상품 매력도를 고려하여
        <br /> 가장 선정 확률이 높은 가격을 적은 것으로,
        <br /> 해당 가격으로 신청하시는 것을 적극 추천드립니다 <br />
      </h4>
      <h4 className='font-bold'>
        * 순삭특가는 재고량이 최소 수백개이상이어야 진행됩니다.
      </h4>
      <div>
        {optionsInfo?.optionNames.length > 0 &&
          dealOptionsInfo?.optionPrices.length > 0 && (
            <div style={{ overflowX: 'scroll' }}>
              <table>
                <thead>
                  <tr>
                    <th>
                      {optionsInfo.optionNames.length === 1
                        ? optionsInfo.optionNames[0] || '옵션 1'
                        : `${optionsInfo.optionNames[1] || '옵션 2'} / ${
                            optionsInfo.optionNames[0] || '옵션 1'
                          }`}
                    </th>
                    {optionsInfo.totalOptions[0].map((option, index) => (
                      <th key={index.toString()}>
                        {option.name || `옵션 1_세부 옵션 ${index + 1}`}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {optionsInfo?.optionNames.length === 1 ? (
                    <tr>
                      <td>세부 정보</td>
                      {optionsInfo?.totalOptions[0].map(
                        (firstOption, firstOptionIndex) => (
                          <td key={nanoid()}>
                            <div>
                              개인 구매가:
                              <input
                                disabled
                                type='number'
                                value={
                                  optionsInfo.optionPrices[firstOptionIndex]
                                    .individualPurchasePrice
                                }
                              />
                            </div>
                            <div>
                              팀 구매가:
                              <input
                                disabled
                                type='number'
                                value={
                                  optionsInfo.optionPrices[firstOptionIndex]
                                    .teamPurchasePrice
                                }
                              />
                            </div>
                            {/* 지원금 */}
                            <div>
                              지원금:
                              <input
                                disabled
                                type='number'
                                value={
                                  optionsInfo.optionPrices[firstOptionIndex]
                                    .platformSupportPrice || 0
                                }
                              />
                            </div>
                            {/* 지원금 */}
                            <div>
                              순삭 판매가:
                              <input
                                disabled={TimeDealStatus === true}
                                type='number'
                                placeholder='아직 설정하지 않음'
                                value={
                                  dealOptionsInfo?.optionPrices?.[
                                    firstOptionIndex
                                  ]?.teamPurchasePrice || 0
                                }
                                onChange={(e) => {
                                  if (
                                    typeof parseInt(e.target.value, 10) !==
                                    'number'
                                  )
                                    return
                                  if (
                                    parseInt(e.target.value, 10) >=
                                    optionsInfo.optionPrices[firstOptionIndex]
                                      .teamPurchasePrice *
                                      0.96
                                  ) {
                                    window.alert(
                                      '딜 신청을 위해선 기존 팀 구매가 대비 최소 4%이상 할인을 해주셔야합니다.',
                                    )
                                    return
                                  }
                                  setDealOptionsInfo({
                                    ...dealOptionsInfo,
                                    optionPrices:
                                      dealOptionsInfo.optionPrices.map(
                                        (
                                          firstOptionTemp,
                                          firstOptionIndexTemp,
                                        ) => {
                                          if (
                                            firstOptionIndex ===
                                            firstOptionIndexTemp
                                          ) {
                                            return {
                                              ...firstOptionTemp,
                                              teamPurchasePrice:
                                                parseInt(e.target.value, 10) ||
                                                0,
                                            }
                                          }
                                          return firstOptionTemp
                                        },
                                      ),
                                  })
                                }}
                              />
                            </div>
                            <div>
                              순삭 재고량:
                              <input
                                type='number'
                                value={
                                  dealOptionsInfo?.optionPrices?.[
                                    firstOptionIndex
                                  ]?.stockNumber || 0
                                }
                                placeholder='아직 설정하지 않음'
                                disabled={TimeDealStatus === true}
                                onChange={(e) => {
                                  const stockNumber =
                                    parseInt(e.target.value, 10) || 1000
                                  setDealOptionsInfo({
                                    ...dealOptionsInfo,
                                    optionPrices:
                                      dealOptionsInfo.optionPrices.map(
                                        (
                                          firstOptionTemp,
                                          firstOptionIndexTemp,
                                        ) => {
                                          if (
                                            firstOptionIndex ===
                                            firstOptionIndexTemp
                                          ) {
                                            return {
                                              ...firstOptionTemp,
                                              stockNumber,
                                              sold_out: stockNumber === 0,
                                              hurry_up: stockNumber < 10,
                                            }
                                          }
                                          return firstOptionTemp
                                        },
                                      ),
                                  })
                                }}
                              />
                            </div>
                          </td>
                        ),
                      )}
                    </tr>
                  ) : (
                    optionsInfo.totalOptions[1].map(
                      (secondOption, secondOptionIndex) => (
                        <tr key={secondOptionIndex.toString()}>
                          <td>
                            {secondOption.name ||
                              `옵션 2_세부 옵션 ${secondOptionIndex + 1}`}
                          </td>
                          {optionsInfo.totalOptions[0].map(
                            (firstOption, firstOptionIndex) => (
                              <td key={firstOptionIndex.toString()}>
                                <div>
                                  개인 구매가:
                                  <input
                                    disabled
                                    type='number'
                                    value={
                                      optionsInfo.optionPrices[
                                        firstOptionIndex
                                      ][secondOptionIndex]
                                        .individualPurchasePrice
                                    }
                                  />
                                </div>
                                <div>
                                  팀 구매가:
                                  <input
                                    disabled
                                    type='number'
                                    value={
                                      optionsInfo.optionPrices[
                                        firstOptionIndex
                                      ][secondOptionIndex].teamPurchasePrice
                                    }
                                  />
                                </div>
                                {/* 지원금 시작 */}
                                <div>
                                  지원금:
                                  <input
                                    disabled
                                    type='number'
                                    value={
                                      optionsInfo.optionPrices[
                                        firstOptionIndex
                                      ][secondOptionIndex]
                                        .platformSupportPrice || 0
                                    }
                                  />
                                </div>
                                {/* 지원금 끝 */}
                                <div>
                                  순삭 판매가:
                                  <input
                                    disabled={TimeDealStatus === true}
                                    placeholder='아직 설정하지 않음'
                                    type='number'
                                    value={
                                      dealOptionsInfo?.optionPrices?.[
                                        firstOptionIndex
                                      ]?.[secondOptionIndex]
                                        ?.teamPurchasePrice || 0
                                    }
                                    onChange={(e) => {
                                      if (
                                        typeof parseInt(e.target.value, 10) !==
                                        'number'
                                      )
                                        return
                                      if (
                                        parseInt(e.target.value, 10) >=
                                        optionsInfo.optionPrices[
                                          firstOptionIndex
                                        ][secondOptionIndex].teamPurchasePrice *
                                          0.96
                                      ) {
                                        window.alert(
                                          '딜 신청을 위해선 기존 팀 구매가 대비 최소 4%이상 할인을 해주셔야합니다.',
                                        )
                                        return
                                      }
                                      setDealOptionsInfo({
                                        ...dealOptionsInfo,
                                        optionPrices:
                                          dealOptionsInfo.optionPrices.map(
                                            (
                                              firstOptionTemp,
                                              firstOptionIndexTemp,
                                            ) => {
                                              if (
                                                firstOptionIndex ===
                                                firstOptionIndexTemp
                                              ) {
                                                return firstOptionTemp.map(
                                                  (
                                                    secondOptionTemp,
                                                    secondOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      secondOptionIndex ===
                                                      secondOptionIndexTemp
                                                    ) {
                                                      return {
                                                        ...secondOptionTemp,
                                                        teamPurchasePrice:
                                                          parseInt(
                                                            e.target.value,
                                                            10,
                                                          ) || 0,
                                                      }
                                                    }
                                                    return secondOptionTemp
                                                  },
                                                )
                                              }
                                              return firstOptionTemp
                                            },
                                          ),
                                      })
                                    }}
                                  />
                                </div>
                                <div>
                                  순삭 재고량:
                                  <input
                                    type='number'
                                    value={
                                      dealOptionsInfo?.optionPrices?.[
                                        firstOptionIndex
                                      ]?.[secondOptionIndex]?.stockNumber || 0
                                    }
                                    placeholder='아직 설정하지 않음'
                                    disabled={TimeDealStatus === true}
                                    onChange={(e) => {
                                      const stockNumber =
                                        parseInt(e.target.value, 10) || 1000
                                      setDealOptionsInfo({
                                        ...dealOptionsInfo,
                                        optionPrices:
                                          dealOptionsInfo.optionPrices.map(
                                            (
                                              firstOptionTemp,
                                              firstOptionIndexTemp,
                                            ) => {
                                              if (
                                                firstOptionIndex ===
                                                firstOptionIndexTemp
                                              ) {
                                                return firstOptionTemp.map(
                                                  (
                                                    secondOptionTemp,
                                                    secondOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      secondOptionIndex ===
                                                      secondOptionIndexTemp
                                                    ) {
                                                      return {
                                                        ...secondOptionTemp,
                                                        stockNumber,
                                                        sold_out:
                                                          stockNumber === 0,
                                                        hurry_up:
                                                          stockNumber < 10,
                                                      }
                                                    }
                                                    return secondOptionTemp
                                                  },
                                                )
                                              }
                                              return firstOptionTemp
                                            },
                                          ),
                                      })
                                    }}
                                  />
                                </div>
                              </td>
                            ),
                          )}
                        </tr>
                      ),
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
      </div>
      <div style={{ height: 36 }} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          id='upload'
          type='button'
          disabled={itemInfo?.soldOut}
          onClick={async () => {
            if (SellerStore.loadSellerInfo())
              Log.event(
                'lureDealApplicationButton',
                'lureDealApplicationButton',
                {
                  click: 'lureDealApplicationButton',
                },
              )
            if (
              dealOptionsInfo.optionNames.length === 0 ||
              dealOptionsInfo.optionNames.some((name) => !name) ||
              dealOptionsInfo.totalOptions.some((optionsArray) =>
                optionsArray.some((option) => !option.name),
              )
            ) {
              console.log(`missing information!`)
              alert(`옵션 정보를 확인해주세요.`)
              return
            }
            let optionPrices = [...dealOptionsInfo.optionPrices]
            let finalOptionPrices = [...dealOptionsInfo.optionPrices]
            let testOption = optionPrices
            while (testOption.length) {
              finalOptionPrices = []
              optionPrices.forEach((option) => {
                finalOptionPrices = finalOptionPrices.concat(option)
              })
              optionPrices = [...finalOptionPrices]
              testOption = finalOptionPrices[0]
            }
            const secretDealPriceResult = finalOptionPrices.some(
              (option) => !option.teamPurchasePrice,
            )
            const secretStockNumberResult = finalOptionPrices.some(
              (option) => !option.stockNumber,
            )

            if (secretDealPriceResult) {
              alert('순삭 판매가가 입력되지 않은 옵션이 있습니다!')
              return
            }
            if (secretStockNumberResult) {
              alert('순삭 재고량이 입력되지 않은 옵션이 있습니다!')
              return
            }
            SellerStore.setIsLoading(true)
            const result = await backendApis.registerDealOptionsInfo(
              itemInfo._id,
              itemInfo?.itemTitle,
              'lureDeal',
              dealOptionsInfo,
            )
            if (result?.status === 200) {
              alert('순삭 특가 신청이 완료됐습니다.\n감사합니다.')
              setData(
                data.map((each) => {
                  if (each?._id?.toString() === itemInfo?._id?.toString()) {
                    return {
                      ...each,
                      dealType,
                      dealOptionsInfo,
                    }
                  }
                  return each
                }),
              )
              setShowModal(false)
              SellerStore.setIsLoading(false)
            }
          }}
        >
          순삭특가 신청하기
        </Button>
      </div>
    </Modal>
  )
}
export default LureDealApplicationModal

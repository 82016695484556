import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
// import Format from '../../utils/format'
import couponSettingByTab from '../../data/couponSettingByTab'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'

const needExpirationPeriodTab = [1]
const needTotalIssuingCountTab = [1]

const CouponEditModal = observer(
  ({ itemInfo, tab, showModal, setShowModal, callbackFunction = () => {} }) => {
    const itemTitle = itemInfo?.itemTitle
    const teamPurchasePrice = itemInfo?.teamPurchasePrice
    const [couponPrice, setCouponPrice] = useState(1000)
    const [expirationPeriod, setExpirationPeriod] = useState(2)
    const [totalIssuingCoupon, setTotalIssuingCoupon] = useState(20)
    const [isLoading, setIsLoading] = useState(false)
    const calculateDiscountRate = (couponPrice, teamPurchasePrice) => {
      const discountRate = Math.round((couponPrice / teamPurchasePrice) * 100)
      if (discountRate > 100) {
        return 100
      }
      if (discountRate < 0) {
        return 0
      }
      return discountRate
    }

    useEffect(() => {
      async function couponInfoSetter() {
        const data = await backendApis.getCouponInfo(
          itemInfo?._id,
          couponSettingByTab?.[tab].type,
        )
        if (data?.status === 200) {
          setCouponPrice(data?.data[0].couponInfo?.value || 1000)
          setExpirationPeriod(data?.data[0].couponInfo?.expirationPeriod || 2)
          setTotalIssuingCoupon(
            data?.data[0].couponInfo?.totalIssuingCount || 20,
          )
        }
      }
      couponInfoSetter()
    }, [showModal === true])

    return (
      <>
        {showModal ? (
          <>
            <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
              <div className='relative w-full max-w-xl mx-auto my-6'>
                {/* content */}
                <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                  {/* header */}
                  <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                    <h3 className='text-xl font-semibold text-black'>
                      {couponSettingByTab?.[tab].name} 수정
                    </h3>
                  </div>
                  {/* body */}
                  <div className='relative flex-auto px-10 py-10'>
                    <div className='text-base leading-relaxed text-sub-500'>
                      <table className='whitespace-normal'>
                        <thead>
                          <tr>
                            <td className='font-bold text-center text-white bg-slate-500'>
                              항목
                            </td>
                            <td className='font-bold text-center text-white bg-slate-500'>
                              신청내용
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='text-center bg-slate-200'>상품명</td>
                            <td>{itemTitle}</td>
                          </tr>
                          <tr>
                            <td className='text-center bg-slate-200'>
                              팀구매가
                            </td>
                            <td>
                              {teamPurchasePrice?.toLocaleString()}
                              {/* {couponPrice} */}
                            </td>
                          </tr>
                          <tr>
                            <td className='text-center bg-slate-200'>
                              할인 금액
                            </td>
                            <td>
                              {' '}
                              {teamPurchasePrice > 7000 ? (
                                <select
                                  style={{ width: 100 }}
                                  type='number'
                                  value={couponPrice}
                                  onChange={(e) => {
                                    setCouponPrice(e.target.value)
                                  }}
                                >
                                  {[
                                    1000, 1500, 2000, 3000, 5000, 7000, 10000,
                                    15000, 20000, 30000, 50000, 100000,
                                  ].map((each) => (
                                    <option key={each} value={each}>
                                      {each}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <select
                                  style={{ width: 100 }}
                                  type='number'
                                  value={couponPrice}
                                  onChange={(e) => {
                                    setCouponPrice(e.target.value)
                                  }}
                                >
                                  {[
                                    500, 1000, 1500, 2000, 3000, 5000, 7000,
                                  ].map((each) => (
                                    <option key={each} value={each}>
                                      {each}
                                    </option>
                                  ))}
                                </select>
                              )}{' '}
                              원
                            </td>
                          </tr>
                          <tr>
                            <td className='text-center bg-slate-200'>할인율</td>
                            <td className='font-bold text-positive-500'>
                              {calculateDiscountRate(
                                couponPrice,
                                teamPurchasePrice,
                              )}
                              %
                            </td>
                          </tr>
                          {needExpirationPeriodTab.includes(tab) && (
                            <tr>
                              <td className='text-center bg-slate-200'>
                                쿠폰 유효 기간
                              </td>
                              <td>2시간</td>
                            </tr>
                          )}
                          {needTotalIssuingCountTab.includes(tab) && (
                            <tr>
                              <td className='text-center bg-slate-200'>
                                쿠폰 발급 수량
                              </td>
                              <td style={{ fontSize: 14 }}>
                                <select
                                  style={{ width: 100 }}
                                  name='value'
                                  type='number'
                                  value={totalIssuingCoupon}
                                  onChange={(e) => {
                                    setTotalIssuingCoupon(e.target.value)
                                  }}
                                >
                                  {[10, 20, 30, 50, 100, 1000].map((each) => (
                                    <option key={each} value={each}>
                                      {each}
                                    </option>
                                  ))}
                                </select>{' '}
                                매
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* footer */}
                  <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                    <Button
                      appearance='teritiary'
                      size='lg'
                      type='button'
                      onClick={() => setShowModal(false)}
                    >
                      닫기
                    </Button>
                    <Button
                      className='mr-2'
                      appearance='teritiary'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        if (isLoading) {
                          return
                        }
                        setIsLoading(true)
                        SellerStore.setIsLoading(true)
                        const itemId = itemInfo._id
                        const couponType = couponSettingByTab?.[tab].type
                        const result = await backendApis.cancelCouponInfo(
                          itemId,
                          couponType,
                        )
                        setShowModal(false)

                        if (result?.status === 2000) {
                          alert(
                            `${couponSettingByTab?.[tab].name} 취소가 완료되었습니다.`,
                          )
                          callbackFunction()
                          setIsLoading(false)
                          SellerStore.setIsLoading(false)
                          return
                        }
                        alert(
                          '쿠폰 취소에 실패했습니다. 반복적으로 실패할 경우 판매자 지원센터로 문의해주세요.',
                        )
                        callbackFunction()
                        setIsLoading(false)
                        SellerStore.setIsLoading(false)
                      }}
                    >
                      쿠폰 취소
                    </Button>

                    <Button
                      // href={buttonLink}
                      appearance='positive'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        if (isLoading) {
                          return
                        }
                        setIsLoading(true)
                        SellerStore.setIsLoading(true)
                        const itemId = itemInfo._id
                        const couponType = couponSettingByTab?.[tab].type
                        const couponInfo = {
                          discountType: 'amount',
                          value: couponPrice,
                          expirationPeriod,
                          totalIssuingCoupon,
                        }
                        const result = await backendApis.updateCouponInfo(
                          itemId,
                          couponType,
                          couponInfo,
                        )
                        setShowModal(false)
                        if (result?.status === 200) {
                          alert(
                            `${couponSettingByTab?.[tab].name} 수정이 완료되었습니다.`,
                          )
                          setIsLoading(false)
                          SellerStore.setIsLoading(false)
                          return
                        }
                        alert(
                          '쿠폰 수정에 실패했습니다. 반복적으로 실패할 경우 판매자 지원센터로 문의해주세요.',
                        )
                        setIsLoading(false)
                        SellerStore.setIsLoading(false)
                      }}
                    >
                      {couponSettingByTab?.[tab].name} 수정
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
          </>
        ) : (
          <></>
        )}
      </>
    )
  },
)
export default CouponEditModal

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import LineChart from '../molecules/lineChart'
import moment from 'moment'
import resize from '../../utils/resize'
import StarRating from '../atoms/starRating'
import { FcFlashOn } from 'react-icons/fc'

const ItemPriceComparisonComponent = observer(({ itemInfo }) => {
  const ratingScore =
    itemInfo?.totalRatingScore / itemInfo?.totalRatingCount || 0
  const [impChartData, setImpChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: '',
        fillColor: '',
        strokeColor: '',
        pointColor: '',
        pointStrokeColor: '',
        pointHighlightStroke: '',
        borderWidth: 2,
      },
    ],
  })
  const [chartOption, setChartOption] = useState({
    layout: {
      padding: {
        left: 40,
        right: 40,
        top: 20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    cubicInterpolationMode: 'monotone',
    fill: true,
    scaleShowLabels: false,
    scaleShowGridLines: true,
  })
  useEffect(() => {
    async function fetchData() {
      let itemImpData = []
      if (Array.isArray(itemInfo?.impressionTrend?.impressionTrend)) {
        itemImpData = itemInfo?.impressionTrend?.impressionTrend
      } else {
        const itemImpDataRaw = await backendApis.loadItemImpTrend(
          itemInfo.itemId,
        )
        if (itemImpDataRaw?.status === 200) {
          itemImpData = itemImpDataRaw?.data?.impressionTrend
        }
      }
      const dateRange = []
      const impressionData = []
      for (let i = 7; i >= 1; i -= 1) {
        dateRange.push(moment().subtract(i, 'day').format('YYYY-MM-DD'))
      }
      dateRange.forEach((data) => {
        impressionData.push(
          itemImpData?.find((e) => e?._id === data)
            ?.shortImpressionResultIncludingBrowsing ||
            itemImpData?.find((e) => e?._id === data)?.shortImpressionResult ||
            0,
        )
      })
      setImpChartData({
        labels: dateRange,
        datasets: [
          {
            label: '상품노출도',
            data: impressionData,
            backgroundColor: '#f0f9ff',
            borderColor: '#60a5fa',
            hoverBackgroundColor: '#60a5fa',
            hoverBorderColor: '#34d399',
            borderWidth: 2,
          },
        ],
      })
      const maxImp = Math.max(...impressionData)
      setChartOption({
        ...chartOption,
        layout: {
          padding: {
            right: 5,
          },
        },
        scales: {
          y: {
            scaleLabel: {
              display: true,
            },
            max: maxImp > 100 ? parseInt(maxImp * 1.2, 10) : 100,
            ticks: {
              stepSize: maxImp > 100 ? 100 : null,
            },
          },
          x: {
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'chartArea',
            align: 'start',
            labels: {
              textAlign: 'left',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      })
    }
    fetchData()
  }, [itemInfo])
  return (
    <>
      <div className='flex flex-row items-center justify-center flex-1  py-4 mb-5 font-bold bg-white divide-x-2 rounded-lg shadow-md select-none text-neutral-500'>
        <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%] text-[#FF3E3E]'>
          올웨이즈 등록이미지
        </div>
        <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
          등록 상품정보
        </div>
        <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%] text-[#1258A4]'>
          비교 이미지
        </div>
        <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
          비교 상품정보
        </div>
      </div>

      <div className='mb-8 bg-white shadow-md select-none whitespace-nowrap rounded-xl'>
        <div className='flex flex-1 px-8 py-8'>
          {/* Origin Product Image */}
          <div className='flex flex-row items-center min-w-[25%] max-w-[25%]'>
            <img
              alt='noImage'
              src={resize(itemInfo.crawledItemTheLowest.imageUri)}
              className='w-50 h-50 mb-2 mr-2 min-w-[90%] max-w-[90%]'
            />
          </div>
          {/* Origin Product Details */}
          <div className='flex flex-col justify-start flex-1 gap-2 min-w-[25%] max-w-[25%]'>
            <div className='text-xl font-bold select-all whitespace-normal text-black'>
              {itemInfo?.itemTitle}
            </div>
            <div className='text-lg text-black font-semibold underline underline-offset-7 decoration-red-600 decoration-wavy'>
              올웨이즈 상품가: {itemInfo?.teamPurchasePrice.toLocaleString()}원
            </div>
            <div className='flex flex-row items-center text-sm font-normal text-gray-400 select-all'>
              {itemInfo._id}
            </div>
            <div className='flex flex-row items-center'>
              <span className='ml-1 text-base mr-2 font-bold text-gray-600'>
                상품 점수:
              </span>
              <StarRating score={ratingScore} />
              <span className='ml-1 text-sm font-bold text-gray-600'>
                {ratingScore.toFixed(1)}점
              </span>
            </div>
            {/* Impression Trend */}
            <div>
              <LineChart chartData={impChartData} options={chartOption} />
            </div>
          </div>

          {/* Compared Product Image */}
          <div className='flex flex-row items-center min-w-[25%] max-w-[25%]'>
            <img
              alt='noImage'
              src={resize(itemInfo.crawledItemTheLowest.crawledImageUri)}
              className='w-50 h-50 mb-2 ml-2 mr-2 min-w-[90%] max-w-[90%]'
            />
          </div>
          {/* Compared Product Details */}
          <div className='flex flex-col justify-start flex-1 gap-2 min-w-[25%] max-w-[25%]'>
            <div className='text-xl font-bold select-all whitespace-normal text-black'>
              {itemInfo.crawledItemTheLowest?.crawledTitle}
            </div>
            <div className='text-lg text-black font-semibold underline underline-offset-7 decoration-blue-800 decoration-wavy'>
              인터넷 최저가:{' '}
              {itemInfo.crawledItemTheLowest?.price.toLocaleString()}
              원(배송비포함)
            </div>
            <div className='flex flex-row'>
              <>
                <FcFlashOn className='relative w-6 h-6' />
              </>
              AI 비교 상품링크:{' '}
              <a
                className='underline font-bold text-blue-800 hover:text-blue-1000 visited:text-purple-600'
                href={itemInfo.crawledItemTheLowest?.externalLink}
                target='_blank'
                rel='noreferrer'
              >
                클릭
              </a>
            </div>
            {itemInfo.crawledItemTheLowest?.price &&
            itemInfo.crawledItemTheLowest.teamPurchasePrice >
              itemInfo.crawledItemTheLowest.price ? (
              <div className='flex text-2xl mt-10 tracking-tighter font-bold text-[#C20F22]'>
                최저가 대비{' '}
                {(
                  ((itemInfo.crawledItemTheLowest.teamPurchasePrice -
                    itemInfo.crawledItemTheLowest?.price) /
                    itemInfo.crawledItemTheLowest.price) *
                  100
                ).toFixed(1)}
                % 비싸요
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  )
})

export default ItemPriceComparisonComponent

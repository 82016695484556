import React from 'react'
import Tooltip from '../atoms/tooltip'

const buttonCard = ({
  index1,
  tooltip1,
  value1,
  index2,
  value2,
  index3,
  value3,
  index4,
  value4,
  index5,
  value5,
  buttonText,
  href,
  buttonText2,
  href2,
  indexCount,
}) => {
  if (indexCount === 2) {
    return (
      <div className='mx-auto bg-white h-36 shadow-lg rounded-2xl border-indigo-500 text-center p-4 min-w-[405px]'>
        <div className='flex flex-row'>
          <div className=' w-1/2 flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
            <p className=' w-1/4 text-black whitespace-nowrap'>{index1}</p>
            <div className='flex w-1/4 items-start font-semibold text-gray-700 whitespace-nowrap'>
              {value1}점
            </div>
          </div>
          <div className=' w-1/2 flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
            <p className='text-black whitespace-nowrap'>{index2}</p>
            <div className='flex items-end font-semibold text-gray-700 whitespace-nowrap'>
              {value2}%
            </div>
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='flex justify-end pb-2 mb-2 space-x-12 md:space-x-24'>
            <a href={href} target='_blank' rel='noreferrer'>
              <button
                type='button'
                className='w-full px-4 py-2 border rounded-lg hover:bg-gray-100'
              >
                {buttonText}
              </button>
            </a>
          </div>
          <div className='flex justify-end pb-2 mb-2 space-x-12 md:space-x-24 ml-3'>
            <a href={href2} target='_blank' rel='noreferrer'>
              <button
                type='button'
                className='w-full px-4 py-2 border rounded-lg hover:bg-gray-100'
              >
                {buttonText2}
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }
  if (indexCount === 5) {
    return (
      <div className='mx-auto bg-white h-36 shadow-lg rounded-2xl border-indigo-500 text-center p-4 min-w-[405px]'>
        <div className='flex flex-row'>
          <div className=' w-1/2 flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
            <p className=' w-1/4 text-black whitespace-nowrap'>{index1}</p>
            <div className='flex w-1/4 items-start font-semibold text-gray-700 whitespace-nowrap'>
              {value1}점
            </div>
          </div>
          <div className=' w-1/2 flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
            <p className='text-black whitespace-nowrap'>{index2}</p>
            <div className='flex items-end font-semibold text-gray-700 whitespace-nowrap'>
              {value2}점
            </div>
          </div>
        </div>
        <div className='flex flex-row'>
          <div className=' w-1/2 flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
            <p className=' w-1/4 text-black whitespace-nowrap'>{index3}</p>
            <div className='flex w-1/4 items-start font-semibold text-gray-700 whitespace-nowrap'>
              {value3}점
            </div>
          </div>
          <div className=' w-1/2 flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
            <p className='text-black whitespace-nowrap'>{index4}</p>
            <div className='flex items-end font-semibold text-gray-700 whitespace-nowrap'>
              {value4}점
            </div>
          </div>
        </div>
        <div className='flex flex-row'>
          <div className=' w-1/2 flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
            <p className=' w-1/4 text-black whitespace-nowrap'>{index5}</p>
            <div className='flex w-1/4 items-start font-semibold text-gray-700 whitespace-nowrap'>
              {value5}점
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='mx-auto bg-white h-36 shadow-lg rounded-2xl border-indigo-500 text-center p-4 min-w-[405px]'>
      <div className='flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
        <p className='text-black whitespace-nowrap'>
          {index1}
          {tooltip1 && <Tooltip text={tooltip1} />}
        </p>
        <div className='flex items-end font-semibold text-gray-700 whitespace-nowrap'>
          {value1} 원
        </div>
      </div>
      <div className='flex justify-end pb-2 mb-2 space-x-12 md:space-x-24'>
        <a href={href} target='_blank' rel='noreferrer'>
          <button
            type='button'
            className='w-full px-4 py-2 border rounded-lg hover:bg-gray-100'
          >
            {buttonText}
          </button>
        </a>
        {buttonText2 && (
          <a href={href2} target='_blank' rel='noreferrer'>
            <button
              type='button'
              className='w-full px-4 py-2 border rounded-lg hover:bg-gray-100'
            >
              {buttonText2}
            </button>
          </a>
        )}
      </div>
    </div>
  )
}

export default buttonCard

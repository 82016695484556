class BiddingUtils {
  toValidPrice(price) {
    return price === Infinity || Number.isNaN(price) ? null : price
  }

  liveBiddingPriceCalc(topOptions, externalPriceInfo) {
    const internalLowestPrice =
      topOptions?.length > 0
        ? this.toValidPrice(
            Math.min(
              ...topOptions?.map((option) =>
                parseInt(option.optionTeamPurchasePrice, 10),
              ),
            ),
          )
        : null

    const externalLowestPrice =
      externalPriceInfo?.length > 0
        ? this.toValidPrice(
            Math.min(...externalPriceInfo?.map((e) => parseInt(e?.price, 10))),
          )
        : null

    return internalLowestPrice != null && externalLowestPrice != null
      ? this.toValidPrice(Math.min(internalLowestPrice, externalLowestPrice))
      : this.toValidPrice(internalLowestPrice ?? externalLowestPrice ?? null)
  }
}

export default new BiddingUtils()

import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Button from '../atoms/button'
import { GrFormClose as CloseIcon } from 'react-icons/gr'
import SellerStore from '../../stores/SellerStore'
import { Calendar } from 'react-calendar'
import commaNumber from 'comma-number'
import Tooltip from '../ADS/atoms/tooltip'
import AdAnnounce from './adAnnounce'
import AlfarmAdsProgressBadgeSetter from '../atoms/AlfarmAdsProgressBadgeSetter'

const QuizAdStatusBar = () => {
  const [date, setDate] = useState(new Date(moment(new Date()).startOf('day')))
  const [quizInfoArray, setQuizInfoArray] = useState([])

  const fetchAppliedQuizInfo = async () => {
    SellerStore.setIsLoading(true)
    const convertedDate = new Date(date).getTime()
    const result = await backendApis.getQuizAd(convertedDate, 1)

    if (result?.status === 200) {
      SellerStore.setIsLoading(false)
      setQuizInfoArray(result?.data)
    }
  }
  useEffect(() => {
    fetchAppliedQuizInfo()
  }, [date])

  const cancelQuizAdInfo = async (adUnitId) => {
    if (window.confirm('선택하신 광고를 취소하시겠습니까?')) {
      const result = await backendApis.cancelQuizAd(adUnitId)
      if (result?.status === 200) {
        await fetchAppliedQuizInfo()
        alert('광고 취소가 완료되었습니다.')
      }
    }
  }

  const CalendarCard = ({ date }) => {
    return (
      <div className='flex flex-col flex-1 p-10 mb-10 bg-white shadow-lg rounded-xl'>
        <div className='mb-5 text-2xl font-bold'>올팜 퀴즈 신청 조회</div>
        <div className='flex flex-row flex-1 '>
          <div className='flex flex-col'>
            <Calendar
              onChange={setDate}
              value={date}
              timeZone='Asia/Seoul'
              maxDate={
                new Date(moment(new Date()).startOf('day').add(9, 'days'))
              }
              // minDate={
              //   new Date(moment(new Date()).startOf('day').subtract(7, 'days'))
              // }
            />
          </div>
          <div className='flex flex-col items-end justify-end flex-1 ml-5 rounded-xl'>
            <div className='flex flex-col items-end'>
              <div>광고 시작 일자</div>
              <div className='flex flex-row items-end justify-end flex-1 my-3'>
                <div className='mr-2 text-gray-500'>
                  {moment(new Date(date)).format('YYYY년')}
                </div>
                <div className='text-4xl font-bold'>
                  {moment(new Date(date)).format('MM월 DD일')}
                </div>
              </div>
            </div>
            <div className='flex flex-row justify-start text-lg'>
              <div className='mr-5'>
                신청 완료:{' '}
                {quizInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.quizStartedAt).getTime() ===
                    new Date(date).getTime()
                  )
                }).length || 0}
              </div>
              <div className='mr-5'>
                검수 대기:{' '}
                {quizInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.quizStartedAt).getTime() ===
                      new Date(date).getTime() && e.status === 'pending'
                  )
                }).length || 0}
              </div>
              <div className='mr-5'>
                승인:{' '}
                {quizInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.quizStartedAt).getTime() ===
                      new Date(date).getTime() && e.status === 'complete'
                  )
                }).length || 0}
              </div>
              <div className='mr-5'>
                반려:{' '}
                {quizInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.quizStartedAt).getTime() ===
                      new Date(date).getTime() && e.status === 'rejected'
                  )
                }).length || 0}
              </div>
              <div>
                취소:{' '}
                {quizInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.quizStartedAt).getTime() ===
                      new Date(date).getTime() && e.status === 'canceled'
                  )
                }).length || 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const QuizInfoCard = ({ quizInfo = {}, orderInfo = {} }) => {
    let totalGmv = 0
    if (Array.isArray(quizInfo.orderData)) {
      totalGmv = quizInfo?.orderData?.reduce((sum, item) => sum + item.gmv, 0)
    } else if (
      quizInfo.orderData &&
      typeof quizInfo.orderData.gmv === 'number'
    ) {
      totalGmv = quizInfo.orderData.gmv
    }
    return (
      <div>
        <div className='p-10 mb-10 bg-white shadow-lg rounded-xl'>
          <div className='flex flex-row items-center justify-between flex-1'>
            <div className='flex flex-row items-center flex-1'>
              <div className='mr-2 text-lg font-bold'>
                {quizInfo?.itemInfo?.itemTitle}
              </div>
              <AlfarmAdsProgressBadgeSetter
                status={quizInfo?.status}
                adEndsAt={
                  quizInfo?.progressInfo?.endedAt
                    ? quizInfo?.progressInfo?.endedAt
                    : quizInfo?.detailInfo?.quizEndedAt
                }
                exist={!!quizInfo?.progressInfo}
              />
            </div>
            {quizInfo?.status === 'pending' && (
              <Button
                appearance='transparent'
                onClick={async () => {
                  if (SellerStore.isLoading) return
                  SellerStore.setIsLoading(true)
                  if (
                    quizInfo?.status === 'complete' ||
                    quizInfo?.status === 'rejected'
                  ) {
                    alert('검수 완료된 상품은 취소가 불가합니다.')
                    return
                  }
                  await cancelQuizAdInfo(quizInfo?._id)
                  SellerStore.setIsLoading(false)
                }}
              >
                <div
                  style={{
                    color: 'black',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CloseIcon className='w-8 h-8' />
                  퀴즈 취소하기
                </div>
              </Button>
            )}
            {quizInfo?.status === 'complete' &&
              !quizInfo?.progressInfo?.endedAt && (
                <Button
                  appearance='transparent'
                  onClick={async () => {
                    if (SellerStore.isLoading) return
                    SellerStore.setIsLoading(true)
                    if (
                      confirm(
                        '퀴즈를 중단하면 이어서 진행할 수 없어요. 퀴즈를 중단 하시겠어요?',
                      ) === true
                    ) {
                      await cancelQuizAdInfo(quizInfo?._id)
                    }
                    SellerStore.setIsLoading(false)
                  }}
                >
                  <div
                    style={{
                      color: 'black',
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <CloseIcon className='w-8 h-8' />
                    퀴즈 중단하기
                  </div>
                </Button>
              )}
          </div>
          {quizInfo?.progressInfo && (
            <div className='my-5'>
              <div className='flex flex-row items-center justify-between flex-1'>
                <div>퀴즈 진행도</div>
                <div>
                  <span>
                    완료 퀴즈 수량:{' '}
                    <span className='font-bold text-blue-500'>
                      {commaNumber(
                        parseInt(quizInfo?.progressInfo?.solvedCount, 10),
                      )}
                    </span>
                    개
                  </span>
                  <span className='mx-1'>/</span>
                  <span>
                    신청 퀴즈 수량:{' '}
                    {commaNumber(
                      parseInt(quizInfo?.progressInfo?.quizCount, 10),
                    )}
                    개
                  </span>
                </div>
              </div>
              <div className='w-full h-4 bg-gray-100 rounded-xl'>
                <div
                  className={` h-4 bg-positive-500 rounded-xl`}
                  style={{
                    width: `${parseInt(
                      (parseInt(quizInfo?.progressInfo?.solvedCount, 10) /
                        parseInt(quizInfo?.progressInfo?.quizCount, 10)) *
                        100,
                      10,
                    )}%`,
                  }}
                />
              </div>

              {quizInfo?.progressInfo?.endedAt &&
                quizInfo?.progressInfo?.settlementExcepted &&
                !quizInfo?.detailInfo?.isForFreeAdEvent && (
                  <div className='flex flex-row items-center justify-end flex-1 mt-2'>
                    <div>
                      정산 차감 확정액:{' '}
                      <span className='font-bold text-blue-500'>
                        {commaNumber(
                          Math.min(
                            quizInfo?.progressInfo?.solvedCount,
                            quizInfo?.progressInfo?.quizCount,
                          ) * quizInfo?.progressInfo?.costPerQuiz,
                        )}
                      </span>
                      원
                    </div>
                    <Tooltip text='정산 차감액은 퀴즈 진행이 완료된 주간 정산액에서 차감됩니다.' />
                  </div>
                )}
            </div>
          )}
          {quizInfo?.detailInfo?.isForFreeAdEvent ? (
            <div className='font-bold text-blue-500'>무료 광고 이벤트 건</div>
          ) : (
            ''
          )}
          <div>퀴즈 아이디: {quizInfo?._id || ''}</div>
          <div>대표 상품 아이디: {quizInfo.detailInfo?.itemId || ''}</div>
          {quizInfo.detailInfo?.itemIds &&
            quizInfo.detailInfo.itemIds.length > 0 && (
              <div>
                상품 아이디: <br />
                {quizInfo.detailInfo?.itemIds?.map((item) => {
                  let itemId = item
                  if (typeof item === 'object') itemId = item.itemId
                  return (
                    <>
                      -{itemId} <br />
                    </>
                  )
                })}
              </div>
            )}
          <div>퀴즈 내용: {quizInfo?.detailInfo?.quizDescription || ''}</div>
          <div>
            보기:{' '}
            {`${quizInfo?.detailInfo.quizChoice?.one || ''},${
              quizInfo?.detailInfo.quizChoice?.two || ''
            },${quizInfo?.detailInfo.quizChoice?.three || ''}`}
          </div>
          <div>
            퀴즈 시작일:{' '}
            {moment(new Date(quizInfo?.detailInfo?.quizStartedAt || '')).format(
              'YYYY년 MM월 DD일',
            )}
          </div>
          <div>정답 번호: {quizInfo?.detailInfo?.quizAnswer || ''}</div>
          <div>
            {/* 무료 광고의 경우 입찰가 6원이라고 보여주기 */}
            노출당 단가:{' '}
            {quizInfo?.detailInfo?.isForFreeAdEvent
              ? 6
              : quizInfo?.detailInfo?.costPerQuiz ?? ''}
            원
          </div>
          <div>광고 수량: {commaNumber(quizInfo?.detailInfo?.quizCount)}</div>
          {quizInfo?.progressInfo?.solvedCount > 0 &&
            !quizInfo?.detailInfo?.isForFreeAdEvent && (
              <div>
                현재 차감 예정액:{' '}
                {commaNumber(
                  Math.min(
                    quizInfo?.progressInfo?.solvedCount,
                    quizInfo?.progressInfo?.quizCount,
                  ) * quizInfo?.progressInfo?.costPerQuiz,
                )}
                원
              </div>
            )}
          {quizInfo?.orderData?.[0]?.gmv > 0 &&
            quizInfo?.progressInfo?.solvedCount && (
              <>
                <div>
                  총 발생 매출:{' '}
                  {commaNumber(
                    quizInfo?.orderData && quizInfo?.orderData?.length > 0
                      ? totalGmv
                      : 0,
                  )}
                  원
                </div>
                <div>
                  ROAS:
                  {Math.round(
                    ((quizInfo?.orderData && quizInfo?.orderData?.length > 0
                      ? totalGmv
                      : 0) /
                      // 무료 광고의 경우 60000원이라고 보여주기
                      (quizInfo?.detailInfo?.isForFreeAdEvent
                        ? Math.min(
                            quizInfo?.progressInfo?.solvedCount,
                            quizInfo?.progressInfo?.quizCount,
                          ) * 6
                        : Math.min(
                            quizInfo?.progressInfo?.solvedCount,
                            quizInfo?.progressInfo?.quizCount,
                          ) * quizInfo?.progressInfo?.costPerQuiz)) *
                      10000,
                  ) / 100}
                  %
                </div>
              </>
            )}
        </div>
      </div>
    )
  }

  return (
    <div className='flex-col flex-1 w-2/3 h-screen'>
      <CalendarCard date={date} />
      <AdAnnounce />
      {quizInfoArray.length > 0 ? (
        quizInfoArray
          .filter((e) => {
            return (
              new Date(e.detailInfo?.quizStartedAt).getTime() ===
              new Date(date).getTime()
            )
          })
          .map((quizInfo) => (
            <div key={quizInfo?._id}>
              <QuizInfoCard quizInfo={quizInfo} />
            </div>
          ))
      ) : (
        <div>선택하신 날짜로 신청된 퀴즈 광고가 없습니다.</div>
      )}
    </div>
  )
}
export default QuizAdStatusBar

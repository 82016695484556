import React, { useState } from 'react'
import { Modal, Col, Row, Tag, Card, Pagination, Divider } from 'antd'
import BiddingOptionItemCard from '../unitOption/bidding/biddingOptionItemCard'
import ItemSearchBox from '../molecules/itemSearchBox'
import ItemCard from '../unitOption/bidding/itemCard'
import OptionCard from '../unitOption/bidding/optionCard'
import { nanoid } from 'nanoid'

const description = {
  help: (
    <div>
      <div className='font-bold'>상품 연동 안내</div>
      <div className='mb-2'>
        좌측에 있는 카탈로그 상품에 상품을 연동하실 수 있습니다.
      </div>
      <div>1. 검색어 탭에 검색어를 적어 주세요.</div>
      <div>
        2. <Tag className='bg-blue-500 text-white border-none'>검색</Tag>버튼을
        클릭해주세요.
      </div>
      <div>
        3. 검색 결과에서 카탈로그와 완전히 동일한 상품/옵션을 선택해주세요.
      </div>
      <div>4. 연동하기 버튼을 통해 연동하실 수 있습니다.</div>
      <Divider />
      <div className='font-bold'>주의사항</div>
      <div className='mt-2'>*제출해주신 연동 신청은 면밀하게 검수됩니다.</div>
      <div>*다른 상품을 연동할 경우 연동이 취소됩니다.</div>
    </div>
  ),
}

function ItemMappingModal({
  biddingOptionInfo = {},
  isVisible,
  onOk = () => {},
  onClose = () => {},
  liveBiddingPrice,
  type = 'unitOption',
}) {
  const [candidateItems, setCandidateItems] = useState([])
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [selectedOptionId, setSelectedOptionId] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10

  const selectedItem = candidateItems.find(
    (e) => e._id?.toString() === selectedItemId?.toString(),
  )

  const onItemSelect = (itemId) => {
    if (itemId === selectedItemId) return
    setSelectedItemId(itemId)
    setSelectedOptionId(null)
  }
  const onOptionSelect = (optionId) => {
    setSelectedOptionId(optionId)
  }

  const handleOk = () => {
    onOk(selectedItemId, selectedOptionId, selectedItem)
  }
  const handleCancel = () => {
    setCandidateItems([])
    setSelectedItemId(null)
    setSelectedOptionId(null)
    setTotalCount(0)
    setPage(1)
    onClose()
  }

  return (
    <Modal
      title='상품 연동하기'
      open={isVisible}
      width={1200}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='연동하기'
      okButtonProps={{ type: 'primary', className: 'bg-blue-500' }}
      cancelText='닫기'
      cancelButtonProps={{
        type: 'text',
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <div className='flex flex-col flex-1 h-full justify-between'>
            <BiddingOptionItemCard
              biddingOptionInfo={biddingOptionInfo}
              liveBiddingPrice={liveBiddingPrice}
              type={type}
            />
            <div className='mt-5'>
              <ItemSearchBox
                page={page}
                setPage={setPage}
                limit={limit}
                onSearch={(v) => setCandidateItems(v)}
                setTotalCount={setTotalCount}
              />
            </div>
          </div>
        </Col>
        <Col span={12}>
          <Card className='mb-5'>
            <div>상품명: {selectedItem?.itemTitle}</div>
            <div>
              선택된 상품ID:{' '}
              {selectedItemId ? (
                <span className='font-bold text-blue-500'>
                  {selectedItemId}
                </span>
              ) : (
                '상품을 선택해주세요.'
              )}
            </div>
            <div>
              선택된 옵션ID:{' '}
              {selectedOptionId ? (
                <span className='font-bold text-blue-500'>
                  {selectedOptionId}
                </span>
              ) : (
                '옵션을 선택해주세요.'
              )}
            </div>
          </Card>
          <Row className='h-[460px] overflow-auto w-full flex-row flex flex-1'>
            {candidateItems?.length > 0 ? (
              candidateItems.map((item, idx) => {
                return (
                  <div
                    key={nanoid() + idx?.toString()}
                    className='mb-4 flex flex-1 flex-col w-full'
                  >
                    <ItemCard
                      isSelected={
                        selectedItemId?.toString() === item?._id?.toString()
                      }
                      item={item}
                      onSelect={onItemSelect}
                    />

                    <div className='w-max'>
                      {selectedItemId?.toString() === item?._id?.toString() && (
                        <OptionCard
                          optionsInfo={item?.optionsInfo}
                          onSelect={onOptionSelect}
                          selectedOptionId={selectedOptionId}
                        />
                      )}
                    </div>
                  </div>
                )
              })
            ) : (
              <div className='p-8 mb-4 bg-slate-100 rounded-lg flex-1 flex-row w-full'>
                <div>{description?.help}</div>
              </div>
            )}
          </Row>
          <Pagination
            className='mt-2'
            current={page}
            onChange={(v) => setPage(v)}
            total={totalCount}
            pageSize={limit}
            showSizeChanger={false}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default ItemMappingModal

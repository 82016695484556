import React from 'react'

const ItemTagButton = ({ text = '', backgroundColor = '', small = false }) => {
  return (
    <div
      style={{
        display: 'flex',
        height: small ? 20 : 28,
        alignSelf: 'center',
        backgroundColor,
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 8,
        paddingLeft: 8,
      }}
    >
      <div
        style={{
          color: '#FFFFFF',
          fontSize: small ? 10 : 14,
          fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </div>
  )
}

export default ItemTagButton

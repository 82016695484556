import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import AuthStore from '../stores/AuthStore'
import foodicon from '../images/foodicon.png'
import LogoRedLetter from '../images/LogoRedLetter.png'
import LogoRed from '../images/LogoRed.png'
import Modal from 'react-modal'
import Log from '../utils/log'

const MobileLoginScreen = observer(({ route }) => {
  const location = useLocation()
  const history = useHistory()
  const [sellerName, setSellerName] = useState(location.state?.sellerName || '')
  const [password, setPassword] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [PSPclose, setPSPclose] = useState(true)

  const handleSubmit = async (e) => {
    e.preventDefault()
    SellerStore.setSellerName(sellerName)
    // SellerStore.setImageUrl(imageBase64)
    const data = await backendApis.attemptLogin(sellerName, password)

    if (data) {
      AuthStore.setToken(data.token)
      localStorage.setItem('@alwayz@seller@token@', data.token)
      const seller = await SellerStore.loadSellerInfo()
      if (seller)
        if (
          SellerStore?.sellerInfo?.penaltyStatus === 'banned' &&
          !data?.isMasterAccount
        ) {
          alert('올웨이즈 판매정책 위반으로 해당 계정은 정지되었습니다.')
          localStorage.removeItem('@alwayz@seller@token@')
          AuthStore.setToken('')
          return
        }
        Log.event('loginButton', 'LoginPage', {
          click: 'sellerLoggedIn',
        })
      if (data?.isMasterAccount) {
        AuthStore.setIsMasterAccount(true)
      } else {
        AuthStore.setIsMasterAccount(false)
      }
      history.push('/')
    } else {
      alert('아이디 혹은 비밀번호가 잘못되었습니다.')
    }
  }
  return (
    <div className='absolute inset-0 flex flex-col items-center justify-center w-screen h-screen prose text-center bg-white select-none'>
      <img className='w-1/4 select-none' src={LogoRedLetter} alt={LogoRed} />
      <h1>파트너센터 로그인</h1>
      <input
        className='w-3/5 py-2 mx-5 mt-2 mb-1 bg-theme-100 focus:bg-white'
        type='text'
        name='name'
        placeholder='아이디'
        value={sellerName}
        onChange={(e) => {
          setSellerName(e.target.value)
        }}
      />
      <input
        className='w-3/5 py-2 mx-5 my-1 bg-theme-100 focus:bg-white'
        type='password'
        name='password'
        placeholder='비밀번호'
        value={password}
        onChange={(e) => {
          setPassword(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit(e)
          }
        }}
      />
      <button
        className='w-3/5 py-2 mx-5 my-2 text-xl font-extrabold text-white rounded-md border-theme-500 bg-theme-500'
        type='button'
        onClick={handleSubmit}
      >
        로그인
      </button>
      <button
        className='text-base font-extrabold text-theme-500'
        type='button'
        onClick={() => {
          history.push('/login/password-search')
        }}
      >
        <span>비밀번호 찾기</span>
      </button>
      <div className='prose'>아직 올웨이즈 입점사가 아니신가요?</div>
      <button
        className='w-3/5 py-2 mx-5 my-2 text-xl font-extrabold text-white rounded-md border-theme-500 bg-theme-500'
        type='button'
        onClick={() => {
          history.push('/login/register-seller')
        }}
      >
        <span>신규 입점사 신청하기</span>
      </button>
    </div>
  )
})

export default MobileLoginScreen

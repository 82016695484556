import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import React, { useState } from 'react'
import Format from '../../utils/format'
import couponSettingByTab from '../../data/couponSettingByTab'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'

const SellerCouponEditModal = observer(
  ({
    appliedCouponInfo,
    tab,
    showModal,
    setShowModal,
    callbackFunction = () => {},
  }) => {
    const [totalIssuingCoupon, setTotalIssuingCoupon] = useState(
      appliedCouponInfo?.couponInfo?.totalIssuingCoupon || 20,
    )
    const [couponPrice, setCouponPrice] = useState(
      appliedCouponInfo?.couponInfo?.value || 2000,
    )
    const [isLoading, setIsLoading] = useState(false)
    console.log(appliedCouponInfo, 'appliedCouponInfo')

    return (
      <>
        {showModal ? (
          <>
            <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
              <div className='relative w-full max-w-xl mx-auto my-6'>
                {/* content */}
                <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                  {/* header */}
                  <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                    <h3 className='text-xl font-semibold text-black'>
                      {couponSettingByTab?.[tab].name} 수정
                    </h3>
                  </div>
                  {/* body */}
                  <div className='relative flex-auto px-10 py-10'>
                    <div className='text-base leading-relaxed text-sub-500'>
                      <table className='whitespace-normal'>
                        <thead>
                          <tr>
                            <td className='font-bold text-center text-white bg-slate-500'>
                              항목
                            </td>
                            <td className='font-bold text-center text-white bg-slate-500'>
                              신청내용
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='text-center bg-slate-200 whitespace-nowrap'>
                              할인 금액
                            </td>
                            <td>
                              <select
                                style={{ width: 100 }}
                                type='number'
                                value={couponPrice}
                                onChange={(e) => {
                                  setCouponPrice(e.target.value)
                                }}
                              >
                                {[
                                  1000, 1500, 2000, 3000, 5000, 7000, 10000,
                                  15000, 20000, 30000, 50000, 100000,
                                ].map((each) => (
                                  <option key={each} value={each}>
                                    {each}
                                  </option>
                                ))}
                              </select>
                              원
                            </td>
                          </tr>

                          {couponSettingByTab?.[tab].expirationPeriod && (
                            <tr>
                              <td className='text-center bg-slate-200 whitespace-nowrap'>
                                쿠폰 유효 기간
                              </td>
                              <td>2시간</td>
                            </tr>
                          )}
                          {couponSettingByTab?.[tab].totalIssuingCoupon && (
                            <tr>
                              <td className='text-center bg-slate-200 whitespace-nowrap'>
                                쿠폰 발급 수량
                              </td>
                              <td style={{ fontSize: 14 }}>
                                <select
                                  style={{ width: 100 }}
                                  name='value'
                                  type='number'
                                  value={totalIssuingCoupon}
                                  onChange={(e) => {
                                    setTotalIssuingCoupon(e.target.value)
                                  }}
                                >
                                  {[10, 20, 30, 50, 100, 200, 300, 1000].map(
                                    (each) => (
                                      <option key={each} value={each}>
                                        {each}
                                      </option>
                                    ),
                                  )}
                                </select>{' '}
                                매
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* footer */}
                  <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                    <Button
                      className='mr-2'
                      appearance='teritiary'
                      size='lg'
                      type='button'
                      onClick={() => setShowModal(false)}
                    >
                      닫기
                    </Button>
                    <Button
                      className='mr-2'
                      appearance='teritiary'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        if (isLoading) return
                        setIsLoading(true)
                        SellerStore.setIsLoading(true)
                        const couponType = couponSettingByTab?.[tab].type
                        const result = await backendApis.cancelSellerCouponInfo(
                          couponType,
                        )
                        setShowModal(false)
                        console.log(result)
                        if (result?.status === 200) {
                          alert(
                            `${couponSettingByTab?.[tab].name} 취소가 완료되었습니다.`,
                          )
                          callbackFunction(couponSettingByTab?.[tab].type)
                          setIsLoading(false)
                          SellerStore.setIsLoading(false)
                          return
                        }
                        alert(
                          '쿠폰 취소에 실패했습니다. 반복적으로 실패할 경우 판매자 지원센터로 문의해주세요.',
                        )
                        callbackFunction(couponSettingByTab?.[tab].type)
                        setIsLoading(false)
                        SellerStore.setIsLoading(false)
                      }}
                    >
                      쿠폰 취소
                    </Button>
                    <Button
                      appearance='positive'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        if (isLoading) return
                        setIsLoading(true)
                        SellerStore.setIsLoading(true)
                        const couponType = couponSettingByTab?.[tab].type
                        const couponInfo = {
                          discountType: 'amount',
                          value: parseInt(couponPrice, 10),
                          // expirationPeriod,
                          totalIssuingCoupon: parseInt(totalIssuingCoupon, 10),
                        }
                        const result =
                          await backendApis.updateSellerCouponInfoV2(
                            couponType,
                            couponInfo,
                          )
                        setShowModal(false)
                        if (result?.status === 2000) {
                          alert(
                            `${couponSettingByTab?.[tab].name} 수정이 완료되었습니다.`,
                          )
                          callbackFunction(couponSettingByTab?.[tab].type)
                          setIsLoading(false)
                          SellerStore.setIsLoading(false)
                          return
                        }
                        alert('쿠폰 수정에 실패했습니다.')
                        setIsLoading(false)
                        SellerStore.setIsLoading(false)
                      }}
                    >
                      {couponSettingByTab?.[tab].name} 수정
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
          </>
        ) : (
          <></>
        )}
      </>
    )
  },
)
export default SellerCouponEditModal

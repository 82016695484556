import React, { useEffect, useState, useMemo } from 'react'

import { Tooltip as TooltipOrigin } from 'react-tippy'
import { observer } from 'mobx-react-lite'
import ItemTable from '../../components/ADS/molecules/itemTable'
import resize from '../../utils/resize'
import commaNumber from 'comma-number'
import Button from '../../components/atoms/button'
import backendApis from '../../utils/backendApis'
import { Select } from 'antd'
import { update } from '@react-spring/web'
// import { OffDayList } from '../../utils/fastArrivalUtils'

const Header = ({ modalTitle, onClose = () => {} }) => {
  return (
    <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
      <h3 className='text-xl font-bold text-black'>{modalTitle}</h3>
      <div className='flex flex-row items-center justify-end flex-1'>
        <TooltipOrigin
          arrow='true'
          theme='dark'
          title='❎ 키보드 esc로도 종료하실 수 있어요.'
          animation='fade'
          position='top'
        >
          <button
            type='button'
            onClick={onClose}
            className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
          >
            esc
          </button>
        </TooltipOrigin>
      </div>
    </div>
  )
}

const Body = () => {
  return (
    <div className='flex flex-row items-center flex-1 p-5'>
      <ul className='list-disc ml-5'>
        <li>
          바로도착 신청은{' '}
          <span className='text-blue-500 font-bold underline underline-offset-4'>
            '상품단위'
          </span>
          로 가능합니다.
        </li>
        <li className='mt-1'>
          출고지/반품지는 선택된 상품에 일괄 적용됩니다.{' '}
          <span className='text-blue-500 font-bold underline underline-offset-4'>
            동일 출고지/반품지 상품끼리 신청해주세요.
          </span>
        </li>
        <li className='mt-1'>
          <span className='text-blue-500 font-bold underline underline-offset-4'>
            입력된 출고지/반품지 기준으로
          </span>{' '}
          하루 2번 택배사에서 방문합니다. 오입력되지 않도록 주의해주세요.
        </li>
      </ul>
    </div>
  )
}

const BaroArriveItemRegisterModal = observer(
  ({
    showModal,
    setShowModal,
    selectedItemIds,
    selectedItemsInfo,
    onClose = () => {},
    setUpdateLocationInfo = () => {},
    setApplyStatusTab = () => {},
  }) => {
    const [selectedAddress, setSelectedAddress] = useState(null)
    const [selectedFresh, setSelectedFresh] = useState(null)
    // const [selectedDayOff, setSelectedDayOff] = useState(null)
    const [loadAddressInfo, setLoadAddressInfo] = useState(null)
    const [loadFreshItemInfo, setLoadFreshItemInfo] = useState(null)
    const { Option } = Select

    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
          setShowModal(false)
        }
      }
      document.addEventListener('keydown', handleKeyPress)
      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }, [onClose])

    useEffect(() => {
      const fetchStorageLocationInfo = async () => {
        const fetchResult = await backendApis.getStorageLocationInfoBySellerId()
        if (fetchResult?.status === 2000) {
          const storageLocationInfo = fetchResult?.data
          const loadedAddress = storageLocationInfo
            .filter((e) => e?.addressStatus === 'registered')
            .map((item, index) => ({
              ...item,
              selectId: index + 1,
            }))
          setLoadAddressInfo(loadedAddress)
        }
      }
      const fetchFreshItemInfo = async () => {
        const fetchResult = await backendApis.getFreshItemInfoBySellerId()
        if (fetchResult?.status === 2000) {
          const freshItemInfo = fetchResult?.data
          const loadedFreshItem = freshItemInfo.map((item, index) => ({
            ...item,
            id: index + 1,
          }))
          setLoadFreshItemInfo(loadedFreshItem)
        }
      }
      // const fetchDayOffInfo = async () => {
      //   const fetchResult = await backendApis.getDayOffInfoBySellerId()
      //   if (fetchResult?.status === 2000) {
      //     const dayOffInfo = fetchResult?.data
      //     setLoadDayOffInfo(dayOffInfo)
      //   }
      // }
      fetchStorageLocationInfo()
      fetchFreshItemInfo()
    }, [])

    const handleClose = () => {
      setShowModal(false)
      onClose()
    }

    const handleLocationSelectChange = (value) => {
      setSelectedAddress(value)
    }

    const handleFreshSelectChange = (value) => {
      setSelectedFresh(value)
    }

    // const handleDayOffSelectChange = (value) => {
    //   setSelectedDayOff(value)
    // }

    const updateFreshItemInfo = async (freshType) => {
      if (!selectedItemIds) return
      const selectedInfo = selectedItemsInfo.find(
        (e) => e?.itemTitle === String(selectedFresh),
      )
      const targetItemId = selectedInfo?._id
      const updateCondition = {
        targetItemId,
        freshType,
      }
      const updateResult = await backendApis.updateFreshItemInfo(
        updateCondition,
      )
      if (updateResult?.status === 200) {
        setUpdateLocationInfo(true)
        window.alert('신선포장 상품이 업데이트 되었습니다.')
      } else {
        window.alert(
          '신선포장 상품 업데이트에 실패했습니다.\n잠시 후 다시 시도해주세요.',
        )
      }
    }

    // const updateDayOffInfo = async (selectedDayOff, updateType) => {
    //   // selectedDayOff 는 moment.js 형식으로 넘어옴
    //   if (!selectedItemIds) return
    //   const dayOffDay = selectedDayOff?.key
    //   const updateCondition = {
    //     updateType,
    //     selectedItemIds,
    //     dayOffDay,
    //   }
    //   const updateResult = await backendApis.updateDayOffInfo(updateCondition)
    //   if (updateResult?.status === 200) {
    //     // 이건 모달 내 아이템테이블 랜더를 위해서
    //     setUpdateLocationInfo(true)
    //     window.alert('휴무일이 업데이트 되었습니다.')
    //   } else {
    //     window.alert(
    //       '휴무일 업데이트에 실패했습니다.\n잠시 후 다시 시도해주세요.',
    //     )
    //   }
    // }

    const updateStorageLocationInfo = async (storageType) => {
      const selectedInfo = loadAddressInfo.find(
        (e) => e?.addressInfo === String(selectedAddress),
      )
      if (!selectedItemIds) return
      const fullAddress = `${selectedInfo?.addressInfo}`
      const zipCode = `${selectedInfo?.addressZipCode}`
      const address = `${selectedInfo?.address}`
      const addressDetail = `${selectedInfo?.addressDetail}`

      const updateCondition = {
        storageType,
        selectedItemIds,
        fullAddress,
        address,
        addressDetail,
        zipCode,
      }
      const updateResult = await backendApis.updateStorageLocationInfo(
        updateCondition,
      )
      if (updateResult?.status === 200) {
        setUpdateLocationInfo(true)
        storageType === 'departure'
          ? window.alert('출고지 정보가 적용되었습니다.')
          : window.alert('반품지 정보가 적용되었습니다.')
      } else {
        window.alert(
          '주소지 정보 업데이트에 실패했습니다.\n잠시 후 다시 시도해주세요.',
        )
      }
    }

    const registerBaroArriveItems = async () => {
      const consentCondition = {
        type: 'delivery',
        subType: 'baroArrive',
        status: 'registered',
      }
      const fastArrivalItemsCondition = selectedItemIds.map((itemId) => {
        const itemInfo = selectedItemsInfo.find((item) => item._id === itemId)
        return {
          itemId,
          itemTitle: itemInfo?.itemTitle, // 찾은 아이템의 타이틀
          type: 'baroArrive',
          status: 'registered',
        }
      })
      const checkSellerServiceConsent =
        await backendApis.getSellerServiceConsentInfo(consentCondition)
      if (
        checkSellerServiceConsent?.data[0]?.status === 'registered' ||
        checkSellerServiceConsent?.data[0]?.status === 'registering'
      ) {
        const registerBaroArriveItems =
          await backendApis.updateFastArrivalItems(fastArrivalItemsCondition)
        if (registerBaroArriveItems?.status === 200) {
          window.alert('바로도착 신청이 완료되었습니다.')
          setApplyStatusTab('complete')
          setShowModal(false)
        }
      } else {
        window.alert(
          '바로도착 미신청 상태입니다.\n바로도착 서비스 신청을 먼저 진행해주세요.',
        )
        setShowModal(false)
      }
    }

    const columns = useMemo(
      () => [
        {
          Header: '상품 이미지',
          Cell: ({ row }) => {
            const item = row?.original
            return (
              <div className='flex flex-row items-center justify-center flex-1 w-full'>
                <img
                  alt='mainImage'
                  src={
                    item?.mainImageUris ? resize(item?.mainImageUris[0]) : ''
                  }
                  className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
                />
              </div>
            )
          },
        },
        {
          Header: '신선포장(아이스박스, 보냉팩 등) 여부',
          Cell: ({ row }) => {
            const item = row?.original
            return (
              <div className='flex flex-row items-center justify-center flex-1 w-full'>
                {item?.freshType === 'fresh' ? (
                  <span className='font-bold text-blue-500'>신선포장 상품</span>
                ) : (
                  <span className='font-bold text-slate-500'>
                    일반포장 상품
                  </span>
                )}
              </div>
            )
          },
        },
        // {
        //   Header: '휴무일',
        //   Cell: ({ row }) => {
        //     const item = row?.original
        //     const dayOffText = item?.dayOff
        //       ?.map((day) => OffDayList[day])
        //       .join(', ')

        //     return (
        //       <div className='flex flex-row items-center justify-center flex-1 w-full'>
        //         <span className='font-bold text-blue-500'>
        //           {dayOffText || '-'}
        //         </span>
        //       </div>
        //     )
        //   },
        // },
        {
          Header: '상품명',
          Cell: ({ row }) => {
            const item = row?.original
            return (
              <div className='flex flex-col flex-1'>
                <div className='text-slate-500'>{item?.itemId?.toString()}</div>
                <div>{item?.itemTitle}</div>
              </div>
            )
          },
        },
        {
          Header: '팀구매가',
          Cell: ({ row }) => {
            const item = row?.original

            return (
              <div className='flex flex-col flex-1'>
                {commaNumber(item?.teamPurchasePrice)}원
              </div>
            )
          },
        },
        {
          Header: '출고지',
          Cell: ({ row }) => {
            const item = row?.original
            return (
              <>
                <div className='flex flex-col flex-1'>
                  {item?.storageLocation?.departurePostCode
                    ? item?.storageLocation?.departurePostCode
                    : '-'}
                </div>
                <div className='flex flex-col flex-1'>
                  {item?.storageLocation?.departureLocation
                    ? item?.storageLocation?.departureLocation
                    : '-'}
                </div>
              </>
            )
          },
        },
        {
          Header: '반품지',
          Cell: ({ row }) => {
            const item = row?.original
            return (
              <>
                <div className='flex flex-col flex-1'>
                  {item?.storageLocation?.refundPostCode
                    ? item?.storageLocation?.refundPostCode
                    : '-'}
                </div>
                <div className='flex flex-col flex-1'>
                  {item?.storageLocation?.refundLocation
                    ? item?.storageLocation?.refundLocation
                    : '-'}
                </div>
              </>
            )
          },
        },
      ],
      [],
    )
    const modalTitle = '바로도착 상품 등록'
    return (
      <>
        {showModal && (
          <>
            <div className='fixed inset-0 z-50 flex items-center justify-center px-2 outline-none ml-[240px]'>
              <div className='relative w-full max-w-[150vh] mx-auto my-6 shadow-lg overflow-y-auto max-h-[80vh]'>
                <div className='relative flex flex-col w-full bg-white border-0 outline-none'>
                  <div className='sticky top-0 z-10 bg-white'>
                    <Header
                      modalTitle={modalTitle}
                      onClose={() => {
                        handleClose()
                      }}
                    />
                    <Body />
                    <div className='ml-10 mt-5'>
                      <div className='ml-2 mb-4'>
                        <Button
                          appearance='positive'
                          size='md'
                          onClick={() => {
                            const locationInfoItemsLength =
                              selectedItemsInfo.filter(
                                (e) =>
                                  e.storageLocation.departureLocation &&
                                  e.storageLocation.refundLocation,
                              ).length
                            const totalItemsLength = selectedItemsInfo.length
                            if (locationInfoItemsLength !== totalItemsLength) {
                              window.alert(
                                '출고지/반품지가 입력되지 않은 상품이 존재합니다.\n[출고지 입력]과 [반품지 입력]을 모두 완료해주세요.',
                              )
                            } else {
                              const confirmation = window.confirm(
                                '주소지 정보와 신선포장 상품 여부 모두 확인하셨나요?\n오기입에 따른 불이익의 책임은 판매자에게 있습니다.',
                              )
                              if (!confirmation) return
                              registerBaroArriveItems()
                            }
                          }}
                        >
                          바로도착 신청
                        </Button>
                      </div>
                      <div className='ml-2 mt-4 mb-4'>
                        <Select
                          showSearch
                          style={{ width: 500 }}
                          placeholder='신선포장 상품 선택 (미선택으로 발생하는 불이익은 판매자 부담입니다.)'
                          optionFilterProp='children'
                          onChange={handleFreshSelectChange}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {(selectedItemsInfo || []).map((items) => (
                            <Option key={items.id} value={items.itemTitle}>
                              {`${items.itemTitle}`}
                            </Option>
                          ))}
                        </Select>
                        {selectedFresh && (
                          <span className='ml-4'>
                            <Button
                              appearance='positive'
                              size='md'
                              onClick={() => {
                                const freshType = 'fresh'
                                setUpdateLocationInfo(false)
                                updateFreshItemInfo(freshType)
                              }}
                            >
                              신선포장 상품 적용
                            </Button>
                            <span className='ml-4'>
                              <Button
                                appearance='positive'
                                size='md'
                                onClick={() => {
                                  const freshType = 'normal'
                                  setUpdateLocationInfo(false)
                                  updateFreshItemInfo(freshType)
                                }}
                              >
                                신선포장 상품 해제
                              </Button>
                            </span>
                          </span>
                        )}
                      </div>
                      {/* <div className='ml-2 mt-4 mb-4'>
                        <Select
                          showSearch
                          style={{ width: 500 }}
                          placeholder='휴무일 선택 (미선택으로 발생하는 불이익은 판매자 부담입니다.)'
                          optionFilterProp='children'
                          onChange={(value) =>
                            handleDayOffSelectChange(JSON.parse(value))
                          }
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {Object.entries(OffDayList).map(([key, day]) => (
                            <Option
                              key={key}
                              value={JSON.stringify({ key, day })}
                            >
                              {day}
                            </Option>
                          ))}
                        </Select>
                        {selectedDayOff && (
                          <span className='ml-4'>
                            <Button
                              appearance='positive'
                              size='md'
                              onClick={() => {
                                const updateType = 'add'
                                setUpdateLocationInfo(false)
                                updateDayOffInfo(selectedDayOff, updateType)
                              }}
                            >
                              휴무일 일괄 추가
                            </Button>
                            <span className='ml-4'>
                              <Button
                                appearance='positive'
                                size='md'
                                onClick={() => {
                                  const updateType = 'delete'
                                  setUpdateLocationInfo(false)
                                  updateDayOffInfo(selectedDayOff, updateType)
                                }}
                              >
                                휴무일 일괄 해제
                              </Button>
                            </span>
                          </span>
                        )}
                      </div> */}
                      <div className='ml-2'>
                        <Select
                          showSearch
                          style={{ width: 500 }}
                          placeholder='주소지 선택'
                          optionFilterProp='children'
                          onChange={handleLocationSelectChange}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {(loadAddressInfo || []).map((addressInfo) => (
                            <Option
                              key={addressInfo.id}
                              value={addressInfo.addressInfo}
                            >
                              {`${addressInfo.addressInfo} ${addressInfo.addressZipCode}`}
                            </Option>
                          ))}
                        </Select>
                        {selectedAddress && (
                          <span className='ml-4'>
                            <Button
                              appearance='positive'
                              size='md'
                              onClick={() => {
                                const storageType = 'departure'
                                setUpdateLocationInfo(false)
                                updateStorageLocationInfo(storageType)
                              }}
                            >
                              출고지 일괄 적용
                            </Button>
                            <span className='ml-4'>
                              <Button
                                appearance='positive'
                                size='md'
                                onClick={() => {
                                  const storageType = 'refund'
                                  setUpdateLocationInfo(false)
                                  updateStorageLocationInfo(storageType)
                                }}
                              >
                                반품지 일괄 적용
                              </Button>
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col items-center flex-1 bg-white p-10'>
                      <ItemTable
                        columns={columns}
                        data={selectedItemsInfo}
                        rowSelect={false}
                      />
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => {
                      setShowModal(false)
                    }}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
          </>
        )}
      </>
    )
  },
)

export default BaroArriveItemRegisterModal

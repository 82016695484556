import configs from '../../utils/configs'

const getCommissionRate = (order) => {
  let commissionRate = configs.getDefaultCommissionRate()

  if (
    typeof order.itemInfo?.characteristicItem?.commissionRate === 'number' &&
    Number(order?.itemInfo?.characteristicItem?.commissionRate) >= 0 &&
    Number(order?.itemInfo?.characteristicItem?.commissionRate) < 1
  ) {
    commissionRate = Number(order.itemInfo?.characteristicItem?.commissionRate)
  }

  return commissionRate
}

export default getCommissionRate

import { Button, Col, Row, Divider, Modal, message, Tag } from 'antd'
import commaNumber from 'comma-number'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import backendApis from '../../../utils/backendApis'

const confirm = Modal?.confirm

const AppliedBiddingSection = ({
  biddingInfo = {},
  handleDelete = () => {},
  itemTitle,
  optionTitle,
  onReload = () => {},
}) => {
  const [countdown, setCountdown] = useState('')

  const {
    _id: biddingId,
    startDateTime: startDateTimeTemp,
    endDateTime: endDateTimeTemp,
    stockAmount = null,
    biddingType,
    biddingPrice,
    minBiddingPrice,
    guerrillaBiddingInfo = {},
  } = biddingInfo || {}
  const isGuerrillaBidding = !!biddingInfo?.isGuerrilla
  const isOnGoingGuerrillaBidding =
    !!biddingInfo?.guerrillaBiddingInfo?.biddingId
  const {
    startDateTime: guerrillaStartDateTime,
    endDateTime: guerrillaEndDateTime,
  } = guerrillaBiddingInfo || {}
  const onGoingStatus = !isGuerrillaBidding || isOnGoingGuerrillaBidding
  const startDateTime = isOnGoingGuerrillaBidding
    ? guerrillaStartDateTime
    : startDateTimeTemp
  const endDateTime = isOnGoingGuerrillaBidding
    ? guerrillaEndDateTime
    : endDateTimeTemp
  const biddingTypeKor = {
    auto: '자동 입찰',
    fixed: '고정가 입찰',
  }

  const deleteGuerrillaBidding = (guerrillaBiddingId) => {
    confirm({
      title: '예약 정보를 삭제하시겠습니까?',
      content: '삭제된 정보는 복구할 수 없습니다.',
      okButtonProps: { type: 'primary', className: 'bg-blue-500' },
      okText: '삭제하기',
      cancelText: '취소',
      onOk: async () => {
        const response = await backendApis.deleteGuerrillaBiddingInfo(
          guerrillaBiddingId,
        )
        if (response?.status === 200) {
          message.success('예약 정보가 성공적으로 삭제되었습니다.')
        } else {
          message.error('예약 정보 삭제에 실패했습니다. 다시 시도해주세요.')
        }
        onReload()
      },
    })
  }

  const StatusTag = ({ onGoing = false }) => {
    if (onGoing) {
      return <Tag color='blue'>진행중</Tag>
    }
    return <Tag color='green'>대기중</Tag>
  }

  useEffect(() => {
    let timer
    if (startDateTime && moment(startDateTime).isAfter(moment())) {
      const updateCountdown = () => {
        const now = moment()
        const start = moment(startDateTime)
        const duration = moment.duration(start.diff(now))
        const days = Math.floor(duration.asDays())
        const hours = duration.hours()
        const minutes = duration.minutes()
        const seconds = duration.seconds()

        if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
          let countdownText = ''
          if (days > 0) {
            countdownText += `${commaNumber(days)}일 `
          }
          if (hours > 0 || days > 0) {
            countdownText += `${commaNumber(hours)}시간 `
          }
          if (minutes > 0 || (hours === 0 && days === 0)) {
            countdownText += `${commaNumber(minutes)}분 `
          }
          if (seconds > 0 && minutes === 0 && hours === 0 && days === 0) {
            countdownText += `${commaNumber(seconds)}초 `
          }
          setCountdown(`${countdownText}후 시작`)
        } else {
          clearInterval(timer)
          setCountdown('')
        }
      }
      updateCountdown() // 즉시 실행

      // 1분 이상 남았을 때는 1분마다, 1분 미만일 때는 1초마다 업데이트
      const interval =
        moment(startDateTime).diff(moment(), 'minutes') > 0 ? 60000 : 1000
      timer = setInterval(updateCountdown, interval)
    }
    return () => {
      if (timer) clearInterval(timer)
    }
  }, [startDateTime])

  const ReservationStatus = ({
    startDateTime,
    endDateTime,
    isOnGoingGuerrillaBidding = false,
  }) => {
    const now = moment()
    const start = moment(startDateTime)
    const end = endDateTime ? moment(endDateTime) : null

    if (start && end && now.isAfter(end)) {
      return <>예약 시간이 지났습니다. 예약 설정을 갱신해주세요</>
    }
    if (
      (start && now.isAfter(start) && (!end || now.isBefore(end))) ||
      isOnGoingGuerrillaBidding
    ) {
      return <>예약 노출 진행중입니다</>
    }
    if (start && now.isBefore(start)) {
      return <>예약 노출 대기중입니다.</>
    }
    return <>상시 노출 진행 중입니다.</>
  }

  const getTimeColor = (time) => {
    return moment().isAfter(moment(time)) ? 'text-orange-500' : 'text-blue-500'
  }

  return (
    <>
      {biddingInfo && (
        <Row>
          <Col span={24}>
            <Row
              className='mb-4 flex-1 flex w-full font-bold text-gray-600'
              justify='space-between'
              align='middle'
            >
              <Col>
                <StatusTag onGoing={onGoingStatus} />
              </Col>
              <Col>
                <Button
                  onClick={async () => {
                    if (isGuerrillaBidding) {
                      deleteGuerrillaBidding(biddingId)
                    } else {
                      handleDelete({ biddingId })
                    }
                  }}
                  type='text'
                  size='small'
                >
                  삭제
                </Button>
              </Col>
            </Row>
            <div>{itemTitle}</div>
            <div>{optionTitle}</div>
            <div>입찰 유형: {biddingTypeKor?.[biddingType]}</div>
            <div>입찰가: {commaNumber(biddingPrice)}원</div>
            {biddingType === 'auto' && (
              <div>최저 입찰가: {commaNumber(minBiddingPrice)}원</div>
            )}
            <Col span={24} className='p-4 bg-slate-50 rounded-lg my-2'>
              <Row justify='space-between'>
                <div className='font-bold text-blue-500'>
                  <ReservationStatus
                    startDateTime={
                      isOnGoingGuerrillaBidding
                        ? guerrillaStartDateTime
                        : startDateTime
                    }
                    endDateTime={
                      isOnGoingGuerrillaBidding
                        ? guerrillaEndDateTime
                        : endDateTime
                    }
                    isOnGoingGuerrillaBidding={isOnGoingGuerrillaBidding}
                  />
                </div>
                <div>
                  {countdown && (
                    <div className='font-bold text-green-500'>{countdown}</div>
                  )}
                </div>
              </Row>
              <Row>
                {startDateTime && (
                  <div className='flex flex-row flex-1'>
                    <div className='mr-1'>시작:</div>
                    <div className={getTimeColor(startDateTime)}>
                      {moment(startDateTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                  </div>
                )}
              </Row>
              <Row>
                {endDateTime && (
                  <div className='flex flex-row flex-1'>
                    <div className='mr-1'>종료:</div>
                    <div className={getTimeColor(endDateTime)}>
                      {moment(endDateTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>{' '}
                  </div>
                )}
              </Row>
              <Row>재고량: {stockAmount || '기존 상품 재고'}</Row>
            </Col>
          </Col>
          <Divider />
        </Row>
      )}
    </>
  )
}

export default AppliedBiddingSection

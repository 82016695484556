import Button from './button'
import Modal from './modal'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import { GrFormClose as CloseIcon } from 'react-icons/gr'
import { FcComboChart as CoinIcon } from 'react-icons/fc'
import Tooltip from '../ADS/atoms/tooltip'
import SellerQuest from '../../utils/sellerQuest'
import backendApis from '../../utils/backendApis'
import {
  IoIosArrowUp as ArrowUpIcon,
  IoIosArrowDown as ArrowDownIcon,
} from 'react-icons/io'
import { useHistory } from 'react-router-dom'

const BeautyQuest = observer(
  ({ openModal = true, setOpenModal = () => {}, setShowQuest }) => {
    const history = useHistory()

    const beautyQuestStages = ['sampleExperienceModal', 'repurchaseCouponPage']

    return (
      <>
        {openModal ? (
          <div className='px-5 py-3 bg-white shadow-xl select-none text-sub-700 rounded-xl w-80 h-120'>
            <div className='flex flex-row items-center justify-end flex-1 mb-1 '>
              <Button
                appearance='transparent'
                textColor='black'
                onClick={() => setShowQuest(false)}
              >
                <CloseIcon className='w-6 h-6' />
              </Button>
            </div>
            <div className='flex flex-row items-center justify-between flex-1 mb-3 text-lg font-bold'>
              <div className='flex flex-row items-center'>
                <CoinIcon className='mr-1' />
                뷰티 퀘스트
              </div>
              <Button
                appearance='transparent'
                textColor='black'
                onClick={() => setOpenModal(false)}
              >
                <ArrowUpIcon className='w-6 h-6' />
              </Button>
            </div>
            <div className='flex flex-row items-center justify-end flex-1 mb-1 text-sm'>
              <div>퀘스트 달성도</div>
            </div>
            {/* {JSON.stringify(tutorialInfo)} */}
            <div className='w-full h-4 mb-5 bg-gray-100 rounded-xl'>
              <div
                className='h-4 duration-300 ease-in-out bg-positive-500 rounded-xl'
                style={{
                  width: `${SellerQuest.questProgressChecker(
                    'beautyQuest',
                    beautyQuestStages,
                  )}%`,
                }}
              />
            </div>
            {/* <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center text-base font-bold'>
                  뷰티 샘플 체험단 신청하기
                  <Tooltip text='💡뷰티 샘플 체험단을 신청해보세요.' />
                </div>
                <Modal
                  className='shadow-none'
                  modalTitle='뷰티 샘플 체험단이 출시되었습니다.'
                  imageSource='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/057eded5-b7c7-4da7-8731-5ce5479c03f5.png'
                  modalContent={
                    <>
                      <div className='mb-3 text-lg font-bold'>
                        다음 내용을 반드시 확인해주세요!
                      </div>
                      <ol className='ml-5 list-decimal'>
                        <li>
                          <span className='font-bold text-blue-500'>
                            뷰티 카테고리 상품 한정
                          </span>
                          으로 참여 가능합니다.
                        </li>
                        <li>
                          뷰티 샘픔을 고객에게 무료로 제공하고{' '}
                          <span className='font-bold text-blue-500'>
                            사진 후기 작성과 본품 구매
                          </span>
                          를 유도합니다.
                        </li>
                        <li>
                          샘플을 제공한 이후 본품 구매를 위한{' '}
                          <span className='font-bold text-blue-500'>
                            추가 구매 유도 쿠폰
                          </span>
                          이 의무적으로 신청되어야 합니다.
                        </li>
                      </ol>
                    </>
                  }
                  modalLog={beautyQuestStages[0]}
                  isButton
                  buttonText='신청하러가기'
                  buttonCallbackFunction={() => {
                    history.push('/sample-experience')
                  }}
                  appearance={
                    SellerQuest.questChecker(
                      'beautyQuest',
                      beautyQuestStages[0],
                    )
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'beautyQuest',
                    beautyQuestStages[0],
                  )}
                >
                  {SellerQuest.questChecker('beautyQuest', beautyQuestStages[0])
                    ? '완료'
                    : 'GO'}
                </Modal>
              </div>
            </div> */}
            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center'>
                  <div className='text-base font-bold'>
                    추가 구매 유도 쿠폰 신청하기
                    <Tooltip text='💡추가 구매 유도 쿠폰을 신청해보세요.' />
                  </div>
                </div>
                <Button
                  href='/deals'
                  appearance={
                    SellerQuest.questChecker(
                      'beautyQuest',
                      beautyQuestStages[1],
                    )
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'beautyQuest',
                    beautyQuestStages[1],
                  )}
                >
                  {SellerQuest.questChecker('beautyQuest', beautyQuestStages[1])
                    ? '완료'
                    : 'GO'}
                </Button>
              </div>
            </div>
            <div className='flex flex-row justify-center flex-1 w-full'>
              <Modal
                modalTitle='뷰티 퀘스트를 완료하셨습니다'
                imageSource='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/a0005d3d-0035-4b20-a83a-8430227ea5be.jpeg'
                modalContent={
                  <>
                    <div className='items-center mb-3 text-lg font-bold'>
                      축하드립니다!
                    </div>
                    <div className='items-center mb-3 '>
                      뷰티 퀘스트를 완료하신 모든 분들께는{' '}
                      <span className='font-bold text-positive-500'>
                        광고지원금 20,000원
                      </span>
                      을 드리고 있습니다.
                    </div>
                    <div className='items-center mb-3 '>
                      뷰티 셀러분들이 많은 판매량을 만들 수 있도록{' '}
                      <span className='font-bold text-positive-500'>
                        다양한 콘텐츠
                      </span>
                      로 함께하겠습니다. 신청해주셔서 진심으로 감사드립니다.
                    </div>
                  </>
                }
                modalLog='itemRankTutorialButton'
                isButton
                buttonText='광고지원금 20,000원 받기'
                buttonCallbackFunction={async () => {
                  if (
                    SellerQuest.questProgressChecker(
                      'beautyQuest',
                      beautyQuestStages,
                    ) === 100 &&
                    !SellerQuest.rewardChecker('beautyQuest')
                  ) {
                    await backendApis.addFreeAdCreditForQuest('beautyQuest')
                    await SellerStore.loadSellerInfo()
                  }
                }}
                appearance={
                  SellerQuest.questProgressChecker(
                    'beautyQuest',
                    beautyQuestStages,
                  ) === 100 && !SellerQuest.rewardChecker('beautyQuest')
                    ? 'positive'
                    : 'disabled'
                }
                size='flexlg'
                disabled={
                  SellerQuest.questProgressChecker(
                    'beautyQuest',
                    beautyQuestStages,
                  ) !== 100 || SellerQuest.rewardChecker('beautyQuest')
                }
              >
                <span className='flex flex-row items-center justify-center flex-1'>
                  광고 지원금 받기
                </span>
              </Modal>
            </div>
          </div>
        ) : (
          <Button
            appearance='transparent'
            onClick={() => {
              setOpenModal(true)
            }}
          >
            <div className='flex flex-row items-center justify-between flex-1 px-5 py-3 text-black bg-white shadow-xl w-80 rounded-xl'>
              <div className='flex flex-row items-center'>
                <CoinIcon className='mr-2' />
                뷰티 퀘스트 보기
              </div>
              <ArrowDownIcon className='ml-2' />
            </div>
          </Button>
        )}
      </>
    )
  },
)
export default BeautyQuest

import React from 'react'

const ProgressDisplay = ({
  msg,
  doneMsg = '축하드립니다. 상품 등록이 완료되었습니다.',
  total,
  done,
  success,
  fail,
  isComplete,
  onCompleteCallback,
  isAdNudgeOn = false,
  onAdNudgeClick = () => {},
}) => {
  const percentage = total ? (done / total) * 100 : 0

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-70'>
      <div className='w-1/3 min-h-[200px] p-5 rounded-lg'>
        <div className='mb-4 text-center text-white'>
          <div>{msg}</div>
          <div className='font-bold'>{`완료: ${done}/${total}`}</div>
          <div className='font-bold'>{`성공: ${success}건`}</div>
          <div className='font-bold'>{`실패: ${fail}건`}</div>
        </div>
        <div className='h-4 bg-gray-200 rounded-xl'>
          <div
            style={{ width: `${percentage}%` }}
            className='h-full bg-green-500 rounded-xl'
          />
        </div>
        {}
        {isComplete && (
          <div className='mt-4 text-center'>
            <p className='mb-2 text-white'>{doneMsg}</p>

            <div className='flex flex-row space-x-4 justify-center items-center'>
              <button
                className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700'
                onClick={onCompleteCallback}
                type='button'
              >
                {`${isAdNudgeOn ? '닫기' : '확인'}`}
              </button>
              {isAdNudgeOn && (
                <button
                  className='px-4 py-2 font-bold text-white bg-emerald-500 rounded hover:bg-emerald-700'
                  onClick={onAdNudgeClick}
                  type='button'
                >
                  ROAS 보장 광고 원클릭 등록하기
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProgressDisplay

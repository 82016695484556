import React, { useState, useRef, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import ShippingOrder from './OrderComponents/ShippingOrder'
import backendApis from '../utils/backendApis'
import { FaFileExcel } from 'react-icons/fa'
import SellerStore from '../stores/SellerStore'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import readXlsxFile from 'read-excel-file'
import { set } from 'mobx'
import Log from '../utils/log'
import Format from '../utils/format'
import moment from 'moment'
import DirectShippingCompleteButton from './buttons/DirectShippingCompleteButton'
import UpdateShippingNumberButton from './buttons/UpdateShippingNumberButton'
import Table from './atoms/table'
import tableUtils from '../utils/tableUtils'
import CancelOrderButton from './buttons/CancelOrderButton'
import EditableCell from './atoms/editableCell'
import SelectableCell from './atoms/selectableCell'
import FastShippingOrderMent from './OrderComponents/FastShippingOrderMent'
import shortUUID from 'short-uuid'
import {
  getCouponNotSettlementAmount,
  getCouponSettlementAmount,
  getSettlementAmount,
} from '../helper/order'

const PAGE_ORDER_NUM = 10

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

const headers = [
  { header: '주문아이디', key: 'orderId', width: 20 },
  { header: '상품아이디', key: 'itemId', width: 20 },
  { header: '합배송아이디', key: 'addressId', width: 20 },
  { header: '판매자 상품코드', key: 'optionCode', width: 20 },
  { header: '상품명', key: 'itemTitle', width: 30 },
  { header: '옵션', key: 'option', width: 40 },
  { header: '수량', key: 'quantity' },
  { header: '상품가격', key: 'totalPrice' },
  { header: '배송비', key: 'shippingFee' },
  { header: '추가지원금', key: 'platformSupportPrice' },
  {
    header: '올웨이즈 부담 쿠폰 할인금',
    key: 'couponSettlementAmount',
  },
  {
    header: '판매자 부담 쿠폰 할인금',
    key: 'couponNotSettlementAmount',
  },
  { header: '총 쿠폰 할인금', key: 'finalDiscountAmount' },
  { header: '정산대상금액(수수료 제외)', key: 'settlementAmount' },
  { header: '주소', key: 'address', width: 50 },
  { header: '우편번호', key: 'postcode' },
  { header: '공동현관 비밀번호', key: 'frontDoorPassword', width: 20 },
  { header: '수령 방법', key: 'detailForReceivingMethod' },
  { header: '수령인', key: 'recipient' },
  { header: '수령인 연락처', key: 'recipientPhoneNumber', width: 20 },
  { header: '주문 시점', key: 'payedAt', width: 20 },
  { header: '택배사', key: 'shippingCompany' },
  { header: '운송장번호', key: 'shippingNumber', width: 30 },
]

const ShippingOrders = observer(({ orders, setOrders }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [shippingInfo, setShippingInfo] = useState(null)
  const translator = shortUUID()

  const columns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '주문아이디', accessor: '_id' },
      { Header: '상품명', accessor: 'itemTitle' },
      { Header: '옵션', accessor: 'option' },
      { Header: '수량', accessor: 'quantity' },
      {
        Header: '배송명시일로부터 남은 시간',
        accessor: 'remainingTime',
        Cell: ({ value }) => {
          return (
            <div className={value < 0 ? `text-red-500 font-bold` : ''}>
              {value} 시간
            </div>
          )
        },
      },
      {
        Header: '택배사',
        accessor: 'shippingCompanyCode',
        Cell: ({ row }) => {
          return (
            <SelectableCell
              value={row?.state?.shippingCompanyCode}
              options={[
                { value: '', text: '===' },
                ...(OrderStore.shippingCompaniesInfo?.map(
                  (shippingCompanyInfo) => ({
                    value: shippingCompanyInfo.shippingCompanyCode,
                    text: shippingCompanyInfo.shippingCompanyName,
                  }),
                ) || []),
              ]}
              onChange={(e) => {
                row?.setState((prev) => ({
                  ...prev,
                  shippingCompanyCode: e.target.value,
                }))
              }}
            />
          )
        },
      },
      {
        Header: '운송장번호',
        accessor: 'shippingNumber',
        Cell: ({ value, row, column, cell }) => {
          return (
            <EditableCell
              value={row?.state?.shippingNumber}
              onChange={(e) => {
                row?.setState((prev) => ({
                  ...prev,
                  shippingNumber: e.target.value,
                }))
              }}
            />
          )
        },
      },
      {
        Header: '운송장 수정',
        accessor: 'uploadShippingNumber',
        Cell: ({ value, row, column, cell }) => {
          return (
            <UpdateShippingNumberButton
              order={value}
              setOrders={setOrders}
              orders={orders}
              shippingNumber={row?.state?.shippingNumber}
              shippingCompanyCode={row?.state?.shippingCompanyCode}
            />
          )
        },
      },
      { Header: '우편발송 여부', accessor: 'postalShipping' },
      { Header: '직접배송 완료처리', accessor: 'directShipping' },
      { Header: '상품가격', accessor: 'totalPrice' },
      { Header: '추가지원금', accessor: 'platformSupportPrice' },
      {
        Header: '올웨이즈 부담 쿠폰 할인금',
        accessor: 'couponSettlementAmount',
      },
      {
        Header: '판매자 부담 쿠폰 할인금',
        accessor: 'couponNotSettlementAmount',
      },
      { Header: '총 쿠폰 할인금', accessor: 'finalDiscountAmount' },
      { Header: '정산대상금액(수수료 제)', accessor: 'settlementAmount' },
      { Header: '배송비', accessor: 'shippingFee' },
      { Header: '배송지', accessor: 'address' },
      { Header: '상세 주소', accessor: 'detailAddress' },
      { Header: '수령인', accessor: 'recipient' },
      { Header: '수령인 연락처', accessor: 'recipientPhoneNumber' },
      { Header: '주문 시점', accessor: 'timePast' },
      { Header: '주문 취소', accessor: 'cancel' },
    ],
    [],
  )

  const data = useMemo(() => {
    return orders
      ?.filter(
        (order) =>
          order.status === 'shipping' && order?.shippingInfo?.trackingDetail,
      )
      .slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.map((order, index) => {
        let timePast = Math.ceil(
          (new Date().getTime() - new Date(order.payedAt).getTime()) /
            (1000 * 60 * 60),
        )
        const daysPast = Math.floor(timePast / 24)
        timePast %= 24
        if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
          if (order?.teamDealInfo?.subAddressInfo) {
            order.addressInfo = order?.teamDealInfo?.subAddressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          } else {
            order.addressInfo = order?.teamDealInfo?.addressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          }
        }
        const remainingShippingTime =
          order.shippingInfo?.isPostalShipping === true
            ? moment(
                moment(new Date(order?.shippingInfo?.postalShippingStartedAt)) +
                  1000 * 60 * 60 * 24 * 14,
              ) - Date.now()
            : moment(
                moment(new Date(order?.preShippingTimeStamp)).businessAdd(
                  order.itemInfo?.shippingInfo?.shippingDays,
                  'days',
                ),
              ).set({ hour: 23, minute: 59 }) - Date.now()
        return {
          ...order,
          index: index + 1 + PAGE_ORDER_NUM * pageIndex,
          itemTitle: (
            <a
              href={order.url
                ?.replace('m.coupang', 'coupang')
                ?.replace('/vm/', '/vp/')}
            >
              {order.itemInfo?.itemTitle}
            </a>
          ),
          option: tableUtils.parseOptionText(
            order?.itemInfo?.optionsInfo,
            order?.selectedOption,
          ),
          remainingTime: Math.floor(remainingShippingTime / (1000 * 60 * 60)),
          shippingCompanyCode:
            !order?.shippingInfo?.isPostalShipping &&
            !order?.shippingInfo?.isDirectShipping &&
            order?.shippingInfo?.shippingCompanyCode,
          shippingNumber: order?.shippingInfo?.shippingNumber,
          uploadShippingNumber: order,
          postalShipping: order?.shippingInfo?.isPostalShipping ? 'O' : '-',
          directShipping: order?.shippingInfo?.isDirectShipping ? (
            <DirectShippingCompleteButton
              order={order}
              setOrders={setOrders}
              orders={orders}
            />
          ) : (
            '-'
          ),
          totalPrice: order?.totalPrice,
          itemId: order?.itemInfo?.itemId,
          addressId: `${Format.DateToday(order?.payedAt)}${
            checkIfValidUUID(order?.addressInfo?.addressId) &&
            translator.fromUUID(order?.addressInfo?.addressId || order?._id)
          }`.substring(2),
          optionCode: order?.selectedOption?.optionCode || '',
          shippingFee: order?.shippingFee,
          // option: optionsText.map((option) => <div key={option}>{option}</div>),
          platformSupportPrice:
            (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1),
          couponSettlementAmount: getCouponSettlementAmount(order),
          couponNotSettlementAmount: getCouponNotSettlementAmount(order),
          finalDiscountAmount:
            order.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(order),
          address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          detailAddress: order.addressInfo?.detailAddress,
          recipient: Format.Recipient(order.addressInfo?.recipient),
          recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          timePast: (
            <>
              <div>{moment(order.payedAt).format('YYYY-MM-DD HH:mm')}</div>
              <div>
                ({daysPast > 0 ? `${daysPast}일 ` : ''} {timePast} 시간 전)
              </div>
            </>
          ),
          cancel: (
            <CancelOrderButton
              order={order}
              setOrders={setOrders}
              status='shipping'
            />
          ),
        }
      })
  }, [
    orders?.filter(
      (order) =>
        order.status === 'shipping' && order?.shippingInfo?.trackingDetail,
    )?.length,
    pageIndex,
  ])
  // const listItems = orders
  //   .filter(
  //     (order) =>
  //       order.status === 'shipping' && order?.shippingInfo?.trackingDetail,
  //   )
  //   .slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
  //   .map((order, index) => {
  //     // const optionsText = order.itemInfo.optionsInfo.optionNames.map(
  //     //   (value, optionIndex) => {
  //     //     const optionDetail =
  //     //       order.itemInfo.optionsInfo.totalOptions[optionIndex][
  //     //         order.selectedOption[optionIndex]
  //     //       ].name
  //     //     return `${optionIndex}. ${value}: ${optionDetail}`
  //     //   },
  //     // )
  //     // return (
  //     //   // <li key={order._id}>{order._id}</li>
  //     //   <tr key={order._id}>
  //     //     <td>{order._id}</td>
  //     //     <td>
  //     //       <a
  //     //         href={order.url
  //     //           ?.replace('m.coupang', 'coupang')
  //     //           ?.replace('/vm/', '/vp/')}
  //     //       >
  //     //         {order.itemInfo?.itemTitle}
  //     //       </a>
  //     //     </td>
  //     //     {/* <td>{JSON.stringify(order.selectedOption)}</td> */}
  //     //     <td>
  //     //       {optionsText.map((option) => (
  //     //         <div key={option}>{option}</div>
  //     //       ))}
  //     //     </td>
  //     //     <td>{order.quantity}</td>
  //     //     <td>{order.paymentInfo.paymentMethodType}</td>
  //     //     <td>{order.totalPrice}</td>
  //     //     <td>{order.shippingFee}</td>
  //     //     <td>{order.shippingInfo?.shippingNumber}</td>
  //     //     <td>
  //     //       {`${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`}
  //     //     </td>
  //     //     <td>{order.addressInfo?.detailAddress}</td>
  //     //     <td>{order.addressInfo?.recipient}</td>
  //     //     <td>{order.addressInfo?.recipientPhoneNumber}</td>
  //     //     <td>{order.payedAt}</td>
  //     //     <td>{order.preShippingTimeStamp}</td>
  //     //     <td>{order.itemInfo.shippingInfo.shippingDays}</td>
  //     //     <td>
  //     //       {order.shippingInfo.trackingDetail?.length > 0 ? `시간` : `시간`}
  //     //     </td>
  //     //   </tr>
  //     // )
  //     return (
  //       <ShippingOrder
  //         key={order._id}
  //         order={order}
  //         index={index}
  //         shippingCompanyId={shippingInfo?._id}
  //         orders={orders}
  //         setOrders={setOrders}
  //       />
  //     )
  //   })
  const excelInput = document.getElementById('excel-input-shipping')
  const parseAndUploadShippingNumber = async () => {
    SellerStore.setIsLoading(true)
    const rows = await readXlsxFile(excelInput.files[0], { sheet: 1 })
    const headerTemp = rows[0]
    const orderIdIndex = headerTemp.findIndex((title) => title === '주문아이디')
    const shippingCompanyNameIndex = headerTemp.findIndex(
      (title) => title === '택배사',
    )
    const shippingNumberIndex = headerTemp.findIndex(
      (title) => title === '운송장번호',
    )
    const failedRows = []
    const chunkSize = 100
    for (let index = 1; index < rows.length; index += chunkSize) {
      const chunkedRows = rows.slice(index, index + chunkSize)
      const shippingInfos = chunkedRows.map((row) => {
        const orderId = row[orderIdIndex]
        const shippingCompanyName = row[shippingCompanyNameIndex]
        const shippingNumber = row[shippingNumberIndex]
          ?.toString()
          ?.replace(/[-\s]/g, '')
        const correspondingShippingCompanyInfo =
          OrderStore.shippingCompaniesInfo.find(
            (shippingCompanyInfo) =>
              shippingCompanyInfo.shippingCompanyName === shippingCompanyName,
          )
        return {
          orderId,
          shippingInfo: { ...correspondingShippingCompanyInfo, shippingNumber },
        }
      })
      const result = await backendApis.editShippingInfosInBulk(shippingInfos)
      if (result?.status === 2000) {
        for (let index2 = 0; index2 < result?.data?.list.length; index2 += 1) {
          if (result?.data?.list[index2]?.success === true) {
            OrderStore.setDisplayedOrders(
              OrderStore.displayedOrders.map((orderTemp) => {
                if (
                  orderTemp._id === result?.data?.list[index2]?.fid &&
                  result?.data?.list[index2]?.success
                ) {
                  return {
                    ...orderTemp,
                    shippingInfo: shippingInfos.find(
                      (info) =>
                        info?.orderId === result?.data?.list[index2]?.fid,
                    )?.shippingInfo,
                  }
                }
                return orderTemp
              }),
            )
            if (orders && setOrders) {
              setOrders(
                orders.map((orderTemp) => {
                  if (
                    orderTemp._id === result?.data?.list[index2]?.fid &&
                    result?.data?.list[index2]?.success
                  ) {
                    return {
                      ...orderTemp,
                      shippingInfo: shippingInfos.find(
                        (info) =>
                          info?.orderId === result?.data?.list[index2]?.fid,
                      )?.shippingInfo,
                    }
                  }
                  return orderTemp
                }),
              )
            }
          } else {
            failedRows.push(
              rows.find(
                (row) => row[orderIdIndex] === result?.data?.list[index2]?.fid,
              ),
            )
          }
          // OrderStore.changeOrdersNumPerStatus('pre-shipping', -1)
          // OrderStore.changeOrdersNumPerStatus('pre-shipping-pre-excel', -1)
          // OrderStore.changeOrdersNumPerStatus('shipping', 1)
        }
      } else {
        for (let index2 = 0; index2 < result?.data?.list.length; index2 += 1) {
          if (result?.data?.list[index2]?.success === false) {
            failedRows.push({
              ...rows.find(
                (row) => row[orderIdIndex] === result?.data?.list[index2]?.fid,
              ),
              failReason: result?.data?.list[index2]?.e_message,
            })
          }
        }
      }
    }
    if (failedRows.length > 0) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('주문 내역')
      worksheet.columns = [
        ...headers,
        { header: '운송장번호 등록 실패 사유', key: 'failReason', width: 30 },
      ]
      worksheet.addRows(failedRows)
      worksheet.getColumn(3).alignment = { wrapText: true }
      const headerRow = worksheet.getRow(1)
      headers.forEach((header, index) => {
        headerRow.getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFEA3F49' },
        }
      })
      worksheet.getRow(1).font = {
        color: { argb: 'FFFFFFFF' },
        bold: true,
      }
      const mimeType = {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)
      const saveDate = new Date()
      const year = saveDate.getFullYear()
      const month = saveDate.getMonth() + 1
      const date = saveDate.getDate()
      saveAs(
        blob,
        `${SellerStore.sellerInfo.sellerDisplayName}_주문 내역_${year}-${
          month < 10 ? `0${month}` : month
        }-${date < 10 ? `0${date}` : date}_운송장 오류 주문건.xlsx`,
      )
    }
    SellerStore.setIsLoading(false)
    alert(
      '수정되지 않은 주문이 있습니다. 엑셀 파일을 통해 해당 내역을 확인하세요.',
    )
  }
  useEffect(() => {
    // let subscription = () => {}
    if (excelInput) {
      excelInput.addEventListener('change', parseAndUploadShippingNumber)
      return () => {
        // subscription()
        excelInput?.removeEventListener('change', parseAndUploadShippingNumber)
      }
    }
  }, [excelInput, orders.length])

  useEffect(() => {
    if (
      SellerStore.sellerInfo._id &&
      OrderStore.shippingCompaniesInfo.length > 0 &&
      orders.length > 0
    ) {
      if (SellerStore.sellerInfo.defaultShippingCompanyId) {
        const correspondingShippingCompanyInfo =
          OrderStore.shippingCompaniesInfo.find(
            (shippingCompanyInfo) =>
              shippingCompanyInfo._id ===
              SellerStore.sellerInfo.defaultShippingCompanyId,
          )

        setShippingInfo({
          ...shippingInfo,
          ...correspondingShippingCompanyInfo,
        })
        // setFilteredPreShippingOrders(
        //   OrderStore.preShippingOrders.map((orderTemp) => ({
        //     ...orderTemp,
        //     shippingInfo: {
        //       ...orderTemp.shippingInfo,
        //       ...correspondingShippingCompanyInfo,
        //     },
        //   })),
        // )
      }
    }
  }, [
    SellerStore.sellerInfo._id,
    OrderStore.shippingCompaniesInfo.length,
    orders.length,
  ])
  return (
    <div className='flex flex-col flex-1 w-full mb-20'>
      <div>
        <div id='shippingOrders' className='text-lg font-bold'>
          <h2 className='p-5 text-2xl'>
            배송중 (
            {setOrders
              ? orders.filter(
                  (order) =>
                    order.status === 'shipping' &&
                    order?.shippingInfo?.trackingDetail,
                ).length
              : OrderStore.ordersNumPerStatus.shipping || 0}
            )
          </h2>
          <div className='flex mb-5 text-base font-normal'>
            {/* 배송중 리스트 뽑고 싶은 경우 */}
            <button
              className='flex px-3 py-3 mr-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
              type='button'
              onClick={async () => {
                SellerStore.setIsLoading(true)

                const workbook = new ExcelJS.Workbook()
                const worksheet = workbook.addWorksheet('주문 내역')

                const worksheetData = orders
                  .filter(
                    (order) =>
                      order.status === 'shipping' &&
                      order?.shippingInfo?.trackingDetail,
                  )

                  .map((order) => {
                    const optionsText =
                      order.itemInfo.optionsInfo.optionNames.map(
                        (value, optionIndex) => {
                          const optionDetail =
                            order.itemInfo.optionsInfo.totalOptions[
                              optionIndex
                            ][order.selectedOption[optionIndex]].name
                          return `${optionIndex}. ${value}: ${optionDetail}`
                        },
                      )

                    if (
                      order?.itemInfo?.characteristicItem?.isApartmentTeamDeal
                    ) {
                      if (order?.teamDealInfo?.subAddressInfo) {
                        order.addressInfo = order?.teamDealInfo?.subAddressInfo
                        order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
                      } else {
                        order.addressInfo = order?.teamDealInfo?.addressInfo
                        order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
                      }
                    }

                    const recipientPhoneNumber =
                      order.addressInfo?.recipientPhoneNumber

                    const recipientPhoneNumberProcessed = recipientPhoneNumber

                    return {
                      orderId: order._id,
                      itemId: order?.itemInfo?.itemId,
                      itemTitle: order.itemInfo?.itemTitle,
                      addressId: `${Format.DateToday(order?.payedAt)}${
                        checkIfValidUUID(order?.addressInfo?.addressId) &&
                        translator.fromUUID(
                          order?.addressInfo?.addressId || order?._id,
                        )
                      }`.substring(2),
                      optionCode: order?.selectedOption?.optionCode || '',
                      option: optionsText.join('\n'),
                      quantity: order.quantity,
                      address: `${order.addressInfo?.postcodeAddress} ${order.addressInfo?.detailAddress}`,
                      postcode: order.addressInfo?.postcode,
                      frontDoorPassword: order.addressInfo?.frontDoorPassword,
                      detailForReceivingMethod:
                        order.addressInfo?.receivingMethod === 'door'
                          ? '문 앞'
                          : order.addressInfo?.detailForReceivingMethod,
                      recipient: Format.Recipient(order.addressInfo?.recipient),
                      recipientPhoneNumber: recipientPhoneNumberProcessed,
                      payedAt: moment(order.payedAt).format('YYYY-MM-DD HH:mm'),
                      shippingCompany: order.shippingInfo.shippingCompanyName,
                      shippingNumber: order.shippingInfo.shippingNumber,
                      totalPrice: order?.totalPrice,
                      shippingFee: order?.shippingFee,
                      // option: optionsText.map((option) => <div key={option}>{option}</div>),
                      platformSupportPrice:
                        (order.selectedOption?.platformSupportPrice || 0) *
                        (order.quantity || 1),
                      couponSettlementAmount: getCouponSettlementAmount(order),
                      couponNotSettlementAmount:
                        getCouponNotSettlementAmount(order),
                      finalDiscountAmount:
                        order.selectedCouponInfo?.finalDiscountAmount || 0,
                      settlementAmount: getSettlementAmount(order),
                    }
                  })
                worksheet.columns = headers
                worksheet.addRows(worksheetData)
                worksheet.getColumn(3).alignment = { wrapText: true }
                const headerRow = worksheet.getRow(1)
                headers.forEach((header, index) => {
                  headerRow.getCell(index + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFEA3F49' },
                  }
                })
                worksheet.getRow(1).font = {
                  color: { argb: 'FFFFFFFF' },
                  bold: true,
                }
                const mimeType = {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }
                const buffer = await workbook.xlsx.writeBuffer()
                const blob = new Blob([buffer], mimeType)
                const saveDate = new Date()
                const year = saveDate.getFullYear()
                const month = saveDate.getMonth() + 1
                const date = saveDate.getDate()
                saveAs(
                  blob,
                  `${
                    SellerStore.sellerInfo.sellerDisplayName
                  }_배송중 내역_${year}-${month < 10 ? `0${month}` : month}-${
                    date < 10 ? `0${date}` : date
                  }.xlsx`,
                )

                // hangeOrdersNumPerStatus('shipping', worksheetData.length)
                SellerStore.setIsLoading(false)
                alert(
                  '발송중, 배송중, 배송완료 주문의 경우 50개씩 다운로드가 가능합니다.',
                )
              }}
            >
              <FaFileExcel />
              엑셀 추출하기
            </button>
            <button
              className='flex px-3 py-3 mx-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
              type='button'
              onClick={() => {
                if (pageIndex > 0) {
                  setPageIndex(pageIndex - 1)
                }
                if (SellerStore.loadSellerInfo())
                  Log.event(
                    'ShippingOrdersExcelButton',
                    'ShippingOrdersExcelButton',
                    {
                      click: 'ShippingOrdersExcelButton',
                    },
                  )
              }}
            >
              이전
            </button>
            <div
              style={{
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {pageIndex + 1}
            </div>

            <button
              className='flex px-3 py-3 mx-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
              type='button'
              onClick={async () => {
                if (SellerStore.isLoading) {
                  return
                }
                if (
                  pageIndex + 1 <
                  orders.filter(
                    (order) =>
                      order.status === 'shipping' &&
                      order?.shippingInfo?.trackingDetail,
                  ).length /
                    PAGE_ORDER_NUM
                ) {
                  setPageIndex(pageIndex + 1)
                } else if (orders.length > 0 && !setOrders) {
                  SellerStore.setIsLoading(true)

                  // 0502 현일 - 50개씩 가져올 때 발송중과 배송중 구분지어서 데려와야 함
                  const result =
                    await backendApis.getOrdersInStatusBeforeCertainTimeStamp(
                      'shipping',
                      orders.slice(-1)?.length > 0 &&
                        orders.slice(-1)[0]?.payedAt,
                    )
                  if (result?.status === 200 && result.data?.length > 0) {
                    OrderStore.setDisplayedOrders([
                      ...OrderStore.displayedOrders,
                      ...result.data,
                    ])
                    setPageIndex(pageIndex + 1)
                  }
                  SellerStore.setIsLoading(false)
                }
              }}
            >
              다음
            </button>
          </div>
          <div className='flex, flex-row text-lg font-normal my-3'>
            <div className='flex flex-1'>엑셀 송장수정 :</div>
            <input type='file' id='excel-input-shipping' accept='.xlsx' />
          </div>
          {/* 베송중 리스트 뽑고 싶은 경우 끝 */}
        </div>
      </div>
      <Table columns={columns} data={data} />
    </div>
  )
})

export default ShippingOrders

import { IoClose } from 'react-icons/io5'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Tooltip as TooltipOrigin } from 'react-tippy'
import backendApis from '../../utils/backendApis'
import { Input, Pagination, Select, Form, Button } from 'antd'
import ItemTable from '../ADS/molecules/itemTable'
// import Button from './button'
import ListLimitSelector from './listLimitSelector'
import SellerStore from '../../stores/SellerStore'
import Gauge from './gauge'
import commaNumber from 'comma-number'

const platformTraslator = (platform) => {
  if (platform === 'naver') return '네이버'
  if (platform === 'coupang') return '쿠팡'
  return '없음'
}

function extractPathFromUrl(url) {
  try {
    const urlObject = new URL(url)
    return urlObject.pathname.slice(1) // 맨 앞의 '/' 제외
  } catch (error) {
    console.error('Invalid URL:', url)
    return null
  }
}

function isValidUrl(url) {
  try {
    const newUrl = new URL(url)
    if (newUrl) return true
  } catch (error) {
    return false
  }
}

const ExternalUrlBatchUpdateModal = ({ onClose = () => {} }) => {
  const [targetData, setTargetData] = useState([])
  const [targetItemIds, setTargetItemIds] = useState([])
  const [page, setPage] = useState(1)
  const [totalLength, setTotalLength] = useState(0)
  const [chunkSize, setChunkSize] = useState(5)
  const [updateCandidate, setUpdateCandidate] = useState([])
  const loadNum = (page - 1) * chunkSize
  const closeFunction = () => {
    if (updateCandidate?.length !== 0) {
      if (
        window.confirm(
          '아직 등록되지 않은 외부 상품 주소가 있습니다. 종료하시겠습니까?',
        )
      ) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: '대표사진',
        Cell: ({ row }) => {
          return (
            <div className='flex flex-row items-center justify-center'>
              <img
                src={row?.original?.mainImageUris?.[0]}
                alt={row?.original?.itemTitle}
                className='object-contain w-12 h-12'
              />
            </div>
          )
        },
      },
      {
        Header: '상품명',
        Cell: ({ row }) => {
          return (
            <div className=''>
              <div className='text-sm text-slate-500'>
                {row?.original?._id?.toString()}
              </div>
              <div className='w-56 text-sm whitespace-normal text-start line-clamp-2'>
                {row?.original?.itemTitle}
              </div>
            </div>
          )
        },
      },
      {
        Header: '팀구매가',
        accessor: 'teamPurchasePrice',
        Cell: ({ row }) => {
          return (
            <div className='flex flex-row items-center justify-center'>
              <div className='text-sm text-center'>
                {commaNumber(row?.original?.teamPurchasePrice)}원
              </div>
            </div>
          )
        },
      },
      {
        Header: '외부 상품 주소',
        Cell: ({ row }) => {
          const itemId = row?.original?._id?.toString()
          const registeredCandidate = updateCandidate?.filter(
            (item) => item?.itemId?.toString() === itemId,
          )
          return (
            <div key={itemId}>
              {registeredCandidate?.length > 0 ? (
                <div className='flex flex-row items-center justify-center flex-1 gap-2'>
                  <div className='px-2 py-1 text-white bg-blue-400 rounded-lg'>
                    {platformTraslator(registeredCandidate?.[0]?.platform)}
                  </div>
                  <div className='px-2 py-1 text-white rounded-lg bg-slate-400'>
                    {`.../${extractPathFromUrl(
                      registeredCandidate?.[0]?.externalUrl,
                    )}`}
                  </div>
                  <Button
                    type='dashed'
                    onClick={() =>
                      setUpdateCandidate(
                        updateCandidate?.filter(
                          (item) => item?.itemId?.toString() !== itemId,
                        ),
                      )
                    }
                  >
                    수정
                  </Button>
                </div>
              ) : (
                <Form
                  className='flex flex-row items-center justify-center flex-1 w-full gap-2'
                  initialValues={{ platform: 'naver' }}
                  onFinish={(data) => {
                    const { platform, externalUrl } = data
                    if (registeredCandidate?.length > 0)
                      return alert('이미 확정된 상품입니다.')
                    if (!isValidUrl(externalUrl))
                      return alert('올바른 상품 주소가 아닙니다.')
                    setUpdateCandidate([
                      ...updateCandidate,
                      { itemId, platform, externalUrl },
                    ])
                  }}
                >
                  <Form.Item name='platform'>
                    <Select
                      defaultValue='naver'
                      options={[
                        { value: 'naver', label: '네이버' },
                        { value: 'coupang', label: '쿠팡' },
                      ]}
                      className='w-28'
                    />
                  </Form.Item>
                  <Form.Item name='externalUrl'>
                    <Input className='w-48 h-8 border-[1px] rounded-lg border-slate-300' />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType='submit'>확정</Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          )
        },
      },
    ],
    [updateCandidate],
  )

  const initializer = () => {
    setPage(1)
    setUpdateCandidate([])
  }

  const fetchData = async (targetItemIds) => {
    const targetItemIdsChunk = targetItemIds.slice(loadNum, loadNum + chunkSize)
    const result = await backendApis.getItemsInfoByItemIds(targetItemIdsChunk)
    if (result?.status === 2000) {
      setTargetData(result?.data)
    }
  }

  const getTotalTargetItemIds = async (refresh = false) => {
    const result = await backendApis.getTotalExternalReviewTargetItemIds()
    if (result?.status === 2000) {
      setTotalLength(result?.data?.length)
      setTargetItemIds(result?.data)
      if (refresh) {
        initializer()
        fetchData(result?.data?.slice(0, chunkSize))
      }
    }
  }

  const handleApplication = async () => {
    if (
      window.confirm(
        `선택하신 ${updateCandidate?.length}개 상품의 외부 상품 주소를 일괄 등록하시겠습니까?`,
      )
    ) {
      const result = await backendApis.registerExternalUrlChunk(updateCandidate)
      if (result?.status === 200) {
        alert('외부 상품 주소 일괄 등록 완료')
        getTotalTargetItemIds(true)
      } else {
        alert('외부 상품 주소 일괄 등록 실패. 잠시 후 다시 시도해주세요.')
      }
    }
  }

  useEffect(() => {
    if (targetItemIds?.length === 0) {
      getTotalTargetItemIds(true)
    } else {
      fetchData(targetItemIds)
    }
  }, [page])

  useEffect(() => {
    if (targetItemIds?.length === 0) return
    initializer()
    fetchData(targetItemIds)
  }, [chunkSize])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        closeFunction()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [onClose])

  const Header = () => {
    return (
      <div className='flex flex-row items-center justify-between w-full p-5 border-b border-solid border-slate-200'>
        <h3 className='text-xl font-semibold text-black'>
          외부 리뷰 대량 연동
        </h3>
        <TooltipOrigin
          arrow='true'
          theme='dark'
          title='❎ 키보드 esc로도 종료하실 수 있어요'
          animation='fade'
          position='top'
        >
          <button
            type='button'
            onClick={closeFunction}
            className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
          >
            esc
          </button>
        </TooltipOrigin>
      </div>
    )
  }
  const Body = () => {
    return (
      <div className='flex flex-col items-start justify-start flex-1 px-10 py-10 overflow-y-scroll h-4/5'>
        <div className='text-sm text-slate-500'>
          외부 상품 주소를 입력 후 확정 버튼을 눌러주세요. 리스트에 있는 모든
          상품에 대해 주소가 입력된 경우{' '}
          <span className='font-bold text-blue-500'>주소 일괄 등록</span>이
          가능합니다.
        </div>
        <div className='mb-5 text-sm text-slate-500'>
          등록된 주소의 리뷰연동은 심사를 통해 반영되며, 반영 시점은 상품별로
          다를 수 있습니다.
        </div>
        <div className='flex flex-row items-center justify-between flex-1 w-full mb-5'>
          <ListLimitSelector limit={chunkSize} setLimit={setChunkSize} />
          <div className='flex flex-row items-center justify-center flex-1 w-full mx-5'>
            <Gauge
              level={updateCandidate?.length}
              totalLevel={chunkSize}
              greenLev={parseInt(chunkSize / 3, 10)}
              blueLev={2 * parseInt(chunkSize / 3, 10)}
            />
          </div>
          <Button
            onClick={handleApplication}
            type='primary'
            className='bg-blue-500'
            disabled={updateCandidate?.length !== chunkSize}
          >
            주소 일괄 등록
          </Button>
        </div>
        <div className='flex flex-row flex-1 w-full mb-5 overflow-y-auto max-h-[60vh]'>
          {/* {targetData?.map((item) => {
            return <div key={item?._id?.toString()}>{item?.itemTitle}</div>
          })} */}
          <ItemTable columns={columns} data={targetData} rowSelect={false} />
        </div>
        <div className='flex flex-row items-center justify-center flex-1 w-full'>
          <Pagination
            current={page}
            onChange={(e) => setPage(e)}
            pageSize={chunkSize}
            total={totalLength}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='fixed inset-0 z-50 flex px-2 py-10 overflow-y-scroll outline-none'>
        <div className='relative w-full max-w-5xl mx-auto my-auto shadow-lg'>
          <div className='relative flex flex-col flex-1 w-full bg-white border-0 rounded-lg outline-none'>
            <Header />
            <Body />
          </div>
        </div>
      </div>
      <div className='fixed inset-0 z-40 bg-black opacity-25' />
    </>
  )
}
export default ExternalUrlBatchUpdateModal

import backendApis from '../backendApis'

const validateBusinessImage = async (sellerId, businessImage) => {
  try {
    // 이미지 URL 검증
    if (
      !businessImage?.startsWith('https://assets.ilevit.com') &&
      !businessImage?.startsWith(
        'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com',
      )
    ) {
      return {
        status: 400,
        message: '유효하지 않은 URL 입니다. 다시 제출해주세요.',
      }
    }
    // OCR 분석 실행
    const ocrResult = await backendApis.recognizeTextFromImage(businessImage)

    if (
      ocrResult?.data?.status !== 200 ||
      ocrResult?.data?.data?.images[0]?.message !== 'SUCCESS'
    ) {
      await backendApis.updateSellerBusinessRegistrationImageInfo(
        sellerId,
        '',
        'rejected',
        '사업자등록증 이미지 불량',
      )
      return {
        status: 400,
        message:
          '사업자등록증 이미지 불량으로 이미지 인식에 실패했습니다. 재업로드 해주세요.',
      }
    }

    const data = ocrResult?.data?.data?.images[0]?.bizLicense?.result
    if (!data) {
      await backendApis.updateSellerBusinessRegistrationImageInfo(
        sellerId,
        '',
        'rejected',
        '사업자등록증 이미지 불량',
      )
      return {
        status: 400,
        message:
          '사업자등록증 이미지 불량으로 이미지 인식에 실패했습니다. 재업로드 해주세요.',
      }
    }

    // 추출된 데이터 정리
    const extractedData = {
      documentType: data.documentType?.[0]?.text || '',
      taxType: data.taxType?.[0]?.text || '',
      registerNumber: data.registerNumber?.[0]?.text || '',
      companyName: data.companyName?.[0]?.text || '',
      corpName: data.corpName?.[0]?.text || '',
      coRepName: data.coRepName?.[0]?.text || '',
      repName: data.repName?.[0]?.text || '',
      birth: data.birth?.[0]?.text || '',
      openDate: data.openDate?.[0]?.text || '',
      bisArea: data.bisArea?.[0]?.text || '',
      bisAddress: data.bisAddress?.[0]?.text || '',
      bisType: data.bisType?.[0]?.text || '',
      issuanceReason: data.issuanceReason?.[0]?.text || '',
      issuanceDate: data.issuanceDate?.[0]?.text || '',
    }

    // 데이터 유효성 검증
    const missingFields = []
    if (extractedData.documentType.trim() === '') {
      missingFields.push('문서종류')
    }
    if (
      extractedData.companyName.trim() === '' &&
      extractedData.corpName.trim() === ''
    ) {
      missingFields.push('상호/법인명')
    }
    if (extractedData.repName.trim() === '') {
      missingFields.push('대표자명')
    }
    if (extractedData.issuanceDate.trim() === '') {
      missingFields.push('발급일자')
    }

    if (missingFields.length > 0) {
      await backendApis.updateSellerBusinessRegistrationImageInfo(
        sellerId,
        '',
        'rejected',
        '필수정보 누락/유효하지 않은 사업자등록증',
      )
      return {
        status: 400,
        message: `사업자등록증 상 필수정보(${missingFields.join(
          ', ',
        )})가 누락되었습니다. 재업로드 해주세요.`,
      }
    }

    // 검증 성공
    await backendApis.updateSellerBusinessRegistrationImageInfo(
      sellerId,
      extractedData,
      'approved',
      '정상',
    )
    return {
      status: 200,
      message: '사업자등록증 검증이 완료되었습니다.',
      data: extractedData,
    }
  } catch (error) {
    console.error('Business image validation error:', error)
    return {
      status: 400,
      message:
        error.message || '검증 중 오류가 발생했습니다. 다시 시도해주세요.',
      error,
    }
  }
}

export default validateBusinessImage

import { makeObservable, observable, action } from 'mobx'

class UserStore {
  searchedUsers = []
  selectedUserInfo = {}
  constructor() {
    makeObservable(this, {
      searchedUsers: observable,
      selectedUserInfo: observable,
      setSearchedUsers: action,
      setSelectedUserInfo: action,
    })
  }

  setSearchedUsers(searchedUsers) {
    this.searchedUsers = searchedUsers
  }

  setSelectedUserInfo(selectedUserInfo) {
    this.selectedUserInfo = selectedUserInfo
  }

  resetUsers() {
    this.setSearchedUsers([])
    this.setSelectedUserInfo({})
  }
}

export default new UserStore()

import { useEffect, useState } from 'react'
import SellerStore from '../../stores/SellerStore'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import IntroSection from '../businessValidation/introSection'
import StepIndicator from '../businessValidation/stepIndicator'
import BusinessImageSection from '../businessValidation/businessImageSection'
import BusinessNumberSection from '../businessValidation/businessNumberSection'
import MailOrderSalesSection from '../businessValidation/mailOrderSalesSection'
import BankAccountSection from '../businessValidation/bankAccountSection'
import InquiryModal from './BusinessRegistrationInquiryModal'

export default observer(function BusinessRegistrationModal({
  onClose = () => {},
  onComplete = () => {},
}) {
  const [showModal, setShowModal] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)
  const [showInquiryModal, setShowInquiryModal] = useState(false)
  const [isConsentChecked, setIsConsentChecked] = useState(false)

  const handleClose = () => {
    setShowModal(false)
    onClose()
    onComplete()
  }

  const Header = () => {
    return (
      <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
        <h3 className='text-xl font-semibold text-black'>
          <span className='text-yellow-500'>⚠️</span> 사업자 정보 등록
        </h3>

        <button
          type='button'
          onClick={() => setShowInquiryModal(true)}
          className='font-bold text-gray-500 hover:text-gray-900 transition-colors text-base mr-4 mt-1'
        >
          판매자지원센터 문의하기
        </button>
      </div>
    )
  }

  const handleNextStep = () => {
    if (currentStep === 1) {
      const message =
        '검증 시작 전, 아래 내용을 확인해주세요!\n\n' +
        '1. 사업자등록증 스캔 파일을 미리 준비해주세요.\n' +
        '2. 사업자등록번호를 준비해주세요.\n' +
        '3. 정산지급 대상 계좌 정보를 미리 준비해주세요.'

      if (confirm(message)) {
        setCurrentStep((prevStep) => prevStep + 1)
      }
    } else {
      setCurrentStep((prevStep) => prevStep + 1)
    }
  }

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
            <div className='relative w-[1100px] mx-auto my-6'>
              <div
                className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'
                style={{ height: 'calc(100vh - 150px)' }}
              >
                <Header />
                <div className='relative flex-auto overflow-y-auto'>
                  <div className='flex flex-col items-center justify-center p-5'>
                    <StepIndicator currentStep={currentStep} />
                    {currentStep === 1 && (
                      <div className='w-4/5'>
                        <IntroSection
                          isConsentChecked={isConsentChecked}
                          setIsConsentChecked={setIsConsentChecked}
                        />
                        <button
                          type='button'
                          className={`mt-14 px-6 py-3 text-white text-lg font-bold rounded-md w-full
                            ${
                              isConsentChecked
                                ? 'bg-blue-500 hover:bg-blue-600'
                                : 'bg-gray-300 cursor-not-allowed'
                            }`}
                          onClick={handleNextStep}
                          disabled={!isConsentChecked}
                        >
                          시작하기
                        </button>
                      </div>
                    )}
                    {currentStep === 2 && (
                      <div className='w-4/5'>
                        <div className='mb-6 p-4 bg-gray-50 rounded-lg'>
                          <h4 className='font-bold mb-2'>
                            📄 사업자등록증 이미지 첨부
                          </h4>
                          <p className='text-gray-600'>
                            가장 최근 발급받은 사업자등록증 이미지를
                            업로드해주세요. (jpg/jpeg/png, 최대 5MB)
                            <br />
                            사업자등록증 이미지 상 모든 정보가 명확히 보여야
                            합니다.
                            <br />
                            이미지 등록 후에 모든 정보가 명확히 보이는지 검증이
                            이루어집니다.
                          </p>
                        </div>
                        <BusinessImageSection
                          sellerInfo={SellerStore?.sellerInfo}
                          onNextStep={handleNextStep}
                          onPreviousStep={handlePreviousStep}
                        />
                      </div>
                    )}
                    {currentStep === 3 && (
                      <div className='w-4/5'>
                        <div className='mb-6 p-4 bg-gray-50 rounded-lg'>
                          <h4 className='font-bold mb-2'>
                            🔢 사업자등록번호 입력 및 검증
                          </h4>
                          <p className='text-gray-600'>
                            사업자등록증에 기재된 10자리 사업자등록번호를 형식에
                            맞게 정확히 입력해주세요. (예시: 123-45-67890)
                            <br />
                            등록한 사업자등록번호가 첨부한 이미지와 일치하면서,
                            유효한 사업자인지 국세청을 통해 검증합니다.
                          </p>
                        </div>
                        <BusinessNumberSection
                          sellerInfo={SellerStore?.sellerInfo}
                          onNextStep={handleNextStep}
                          onPreviousStep={handlePreviousStep}
                        />
                      </div>
                    )}
                    {currentStep === 4 && (
                      <div className='w-4/5'>
                        <div className='mb-6 p-4 bg-gray-50 rounded-lg'>
                          <h4 className='font-bold mb-2'>
                            🔢 통신판매업신고번호 입력 및 검증
                          </h4>
                          <p className='text-gray-600'>
                            통신판매업신고번호를 형식에 맞게 정확히
                            입력해주세요. (예시: 2023-서울강북-01234, xxx구
                            제0000호 등)
                            <br />
                            등록한 통신판매업신고번호가 공정거래위원회를 통해
                            조회한 번호와 일치하는지 검증합니다.
                          </p>
                        </div>
                        <MailOrderSalesSection
                          sellerInfo={SellerStore?.sellerInfo}
                          onNextStep={handleNextStep}
                          onPreviousStep={handlePreviousStep}
                        />
                      </div>
                    )}
                    {currentStep === 5 && (
                      <div className='w-4/5'>
                        <div className='mb-6 p-4 bg-gray-50 rounded-lg'>
                          <h4 className='font-bold mb-2'>
                            💳 사업자 정산계좌 등록 및 검증
                          </h4>
                          <p className='text-gray-600'>
                            판매대금을 정산받으실 사업자 명의의 계좌번호를
                            입력해주세요.
                            <br />
                            계좌조회는 나이스페이먼츠를 통해 진행되며,
                            개인/법인사업자의 명의와 정산계좌의 명의가
                            일치하는지 검증합니다.
                          </p>
                        </div>
                        <BankAccountSection
                          onPreviousStep={handlePreviousStep}
                          onClose={handleClose}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => {
                      const message =
                        '[안내 사항]\n\n' +
                        '- 24년 12월 6일까지 사업자 정보 및 계좌 적합성 검증을 통과하지 않은 사업주님들은 판매활동이 제한될 수 있습니다.\n\n' +
                        '- 검증 미완료로 인한 판매 및 정산 상의 불이익이 발생하지 않도록 진행 부탁드립니다.'
                      if (confirm(message)) {
                        handleClose()
                      }
                    }}
                  >
                    다음에 하기
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
      {showInquiryModal && (
        <InquiryModal onClose={() => setShowInquiryModal(false)} />
      )}
    </>
  )
})

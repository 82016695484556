import RankStandard from '../../data/rankStandard'
import { RankS, RankA, RankB, RankC } from '../../images/itemRankIcon'

const RankIconSetter = ({ itemRank, itemScore, className, children }) => {
  if (itemRank === 'S' || itemScore >= RankStandard.S) {
    return <RankS className={className}>{children}</RankS>
  }
  if (itemRank === 'A' || itemScore >= RankStandard.A) {
    return <RankA className={className}>{children}</RankA>
  }
  if (itemRank === 'B' || itemScore >= RankStandard.B) {
    return <RankB className={className}>{children}</RankB>
  }
  if (itemRank === 'C' || itemScore < RankStandard.B) {
    return <RankC className={className}>{children}</RankC>
  }
  return <></>
}
export default RankIconSetter

import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../../stores/OrderStore'
import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'
import moment from 'moment'
import FastShippingOrderMent from './FastShippingOrderMent'
import Format from '../../utils/format'

const SendingOrder = observer(({ order, index, orders, setOrders }) => {
  const [remainingShippingTime, setRemainingShippingTime] = useState(0)
  const [cancelDetailedReason, setCancelDetailedReason] = useState('')

  useEffect(() => {
    const newRemainingTime =
      order.shippingInfo?.isPostalShipping === true
        ? moment(
            moment(new Date(order?.shippingInfo?.postalShippingStartedAt)) +
              1000 * 60 * 60 * 24 * 14,
          ) - Date.now()
        : moment(
            moment(new Date(order?.preShippingTimeStamp)).businessAdd(
              order.itemInfo.shippingInfo.shippingDays,
              'days',
            ),
          ).set({ hour: 23, minute: 59 }) - Date.now()
    // const newRemainingTime =
    //   new Date(order.preShippingTimeStamp).getTime() +
    //   1000 * 60 * 60 * 24 * order.itemInfo.shippingInfo.shippingDays -
    //   Date.now()
    setRemainingShippingTime(newRemainingTime)
    OrderStore.setDisplayedOrders(
      OrderStore.displayedOrders.map((orderTemp) => {
        if (orderTemp._id === order._id) {
          return { ...orderTemp, remainingShippingTime: newRemainingTime }
        }
        return orderTemp
      }),
    )
    OrderStore.setUserOrders(
      OrderStore.userOrders.map((orderTemp) => {
        if (orderTemp._id === order._id) {
          return { ...orderTemp, remainingShippingTime: newRemainingTime }
        }
        return orderTemp
      }),
    )
  }, [])
  useEffect(() => {
    let isSubscribed = true
    const remainingTimeInterval = setInterval(() => {
      if (isSubscribed) {
        const newRemainingTime =
          order.shippingInfo?.isPostalShipping === true
            ? moment(
                moment(new Date(order?.shippingInfo?.postalShippingStartedAt)) +
                  1000 * 60 * 60 * 24 * 14,
              ).set({ hour: 23, minute: 59 }) - Date.now()
            : moment(
                moment(new Date(order?.preShippingTimeStamp)).businessAdd(
                  order.itemInfo.shippingInfo.shippingDays,
                  'days',
                ),
              ).set({ hour: 23, minute: 59 }) - Date.now()
        // const newRemainingTime =
        //   new Date(order.preShippingTimeStamp).getTime() +
        //   1000 * 60 * 60 * 24 * order.itemInfo.shippingInfo.shippingDays -
        //   Date.now()
        setRemainingShippingTime(newRemainingTime)
        OrderStore.setDisplayedOrders(
          OrderStore.displayedOrders.map((orderTemp) => {
            if (orderTemp._id === order._id) {
              return { ...orderTemp, remainingShippingTime: newRemainingTime }
            }
            return orderTemp
          }),
        )
        OrderStore.setUserOrders(
          OrderStore.userOrders.map((orderTemp) => {
            if (orderTemp._id === order._id) {
              return { ...orderTemp, remainingShippingTime: newRemainingTime }
            }
            return orderTemp
          }),
        )
      }
    }, 1000 * 60)
    return () => {
      isSubscribed = false
      clearInterval(remainingTimeInterval)
    }
  }, [remainingShippingTime])
  const optionsText = order.itemInfo.optionsInfo.optionNames.map(
    (value, optionIndex) => {
      const optionDetail =
        order.itemInfo.optionsInfo.totalOptions[optionIndex][
          order.selectedOption[optionIndex]
        ].name
      return `${optionIndex}. ${value}: ${optionDetail}`
    },
  )
  // Yechan 우편 배송, 직접 배송인 경우의 택배사 정보
  let shippingCompanyName
  if (order.shippingInfo?.isPostalShipping) {
    shippingCompanyName = '우편\n배송'
  } else if (order.shippingInfo?.isDirectShipping) {
    shippingCompanyName = '직접\n배송'
  } else {
    shippingCompanyName = order.shippingInfo?.shippingCompanyName || ''
  }
  // Yechan 우편 배송, 직접 배송인 경우의 송장번호
  // 직접 배송인 경우 배송 완료 처리 버튼 추가
  let shippingNumber
  if (order.shippingInfo?.isPostalShipping) {
    shippingNumber = ''
  } else if (!order.shippingInfo?.isDirectShipping) {
    shippingNumber = order.shippingInfo?.shippingNumber.trim() || ''
  }
  return (
    <tr
      style={
        Math.floor(remainingShippingTime / (1000 * 60 * 60)) > 0
          ? { color: 'black', fontSize: 14 }
          : { color: 'red', fontSize: 14 }
      }
      key={order._id}
    >
      <td>{order._id}</td>
      <td>
        <div>{order.itemInfo.itemTitle},</div>
        {optionsText.map((option) => (
          <div key={option}>{option},</div>
        ))}
        <div>{order.quantity}개</div>
      </td>
      <td>
        <div>{Format.Recipient(order.addressInfo?.recipient)}</div>
        <div>{order.addressInfo?.recipientPhoneNumber}</div>
      </td>
      <td>
        <div>{order.addressInfo?.postcodeAddress}</div>
        <div>{order.addressInfo?.detailAddress}</div>
        <div>{order.addressInfo?.frontDoorPassword}</div>
      </td>
      <td>{moment(order.preShippingTimeStamp).format('YYYY.MM.DD HH:mm')}</td>
      <td>{shippingCompanyName}</td>
      <td>
        {order.shippingInfo?.isDirectShipping ? (
          // Yechan 직접 배송 완료 처리
          <button
            type='button'
            onClick={async () => {
              if (SellerStore.isLoading) {
                return
              }
              SellerStore.setIsLoading(true)
              const result = await backendApis.setOrderStatusArrived(order._id)
              SellerStore.setIsLoading(false)
              if (result?.status === 200) {
                OrderStore.changeOrdersNumPerStatus('shipping-sending', -1)
                OrderStore.setDisplayedOrders(
                  OrderStore.displayedOrders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: 'arrived',
                      }
                    }
                    return orderTemp
                  }),
                )
                if (setOrders) {
                  OrderStore.setUserOrders(
                    orders.map((orderTemp) => {
                      if (orderTemp._id === order._id) {
                        return {
                          ...orderTemp,
                          status: 'arrived',
                        }
                      }
                      return orderTemp
                    }),
                  )
                }
                OrderStore.changeOrdersNumPerStatus('shipping', -1)
                OrderStore.changeOrdersNumPerStatus('arrived', 1)
              }
            }}
          >
            배송 완료 처리
          </button>
        ) : (
          <div>{shippingNumber}</div>
        )}
      </td>
      {/* <td>{order?.shippingFee}</td> */}
      <td>{Math.floor(remainingShippingTime / (1000 * 60 * 60))} 시간</td>
      <td>
        <div>
          취소 사유(고객님께서 보실 수 있으니 신중히 기입해주세요):{' '}
          <input
            type='text'
            onChange={(e) => {
              setCancelDetailedReason(e.target.value)
            }}
          />
        </div>
        <button
          type='button'
          onClick={async () => {
            if (!cancelDetailedReason) {
              alert('취소 사유를 입력해주세요.')
              return
            }
            if (SellerStore.isLoading) {
              return
            }
            SellerStore.setIsLoading(true)
            const result = await OrderStore.manuallyCancelOrder(
              order,
              '판매자 주문 취소',
              cancelDetailedReason,
            )
            if (result?.status === 2000) {
              OrderStore.setDisplayedOrders(
                OrderStore.displayedOrders.map((orderTemp) => {
                  if (orderTemp._id === order._id) {
                    return {
                      ...orderTemp,
                      status: result.data.changedStatus,
                      cancelInfo: result.cancelInfo,
                    }
                  }
                  return orderTemp
                }),
              )
              if (setOrders) {
                OrderStore.setUserOrders(
                  orders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: result.data.changedStatus,
                        cancelInfo: result.cancelInfo,
                      }
                    }
                    return orderTemp
                  }),
                )
              }

              OrderStore.changeOrdersNumPerStatus('shipping-sending', -1)
              OrderStore.changeOrdersNumPerStatus('canceled-shipping', 1)
            }
            SellerStore.setIsLoading(false)
          }}
        >
          취소 승인
        </button>
      </td>
      {/* 0424 변경 이전 기존 content  */}
      {/* <td>{order._id}</td>
      <td>
        <a
          href={order.url
            ?.replace('m.coupang', 'coupang')
            ?.replace('/vm/', '/vp/')}
        >
          {order.itemInfo.itemTitle}
        </a>
      </td>
      <td>
        {optionsText.map((option) => (
          <div key={option}>{option}</div>
        ))}
      </td>  
      <td>{order.quantity}</td> */}
      <td>{order.totalPrice}</td>
      <td>
        {(order.selectedOption?.platformSupportPrice || 0) *
          (order.quantity || 1)}
      </td>
      <td>{order.selectedCouponInfo?.finalDiscountAmount || 0}</td>
      <td>
        {order.totalPrice +
          (order.selectedCouponInfo?.finalDiscountAmount || 0) +
          (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1)}
      </td>
      <td>{order.shippingFee}</td>
      <td
        className={
          !!order?.itemInfo?.characteristicItem?.fastShippingItem &&
          moment(order.preShippingTimeStamp) > moment('2022-08-30')
            ? 'text-blue-500 font-extrabold bg-emerald-100'
            : ''
        }
      >
        <FastShippingOrderMent
          isFastShippingItem={
            !!order?.itemInfo?.characteristicItem?.fastShippingItem
          }
          preShippingTimeStamp={order.preShippingTimeStamp}
        />
      </td>
    </tr>
  )
})

export default SendingOrder

import React, { useState } from 'react'

const ExpandableText = ({ text }) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded((prev) => !prev)

  const textStyle = {
    maxWidth: '300px',
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: expanded ? 'block' : '-webkit-box',
    WebkitLineClamp: expanded ? 'none' : 2,
    WebkitBoxOrient: 'vertical',
    cursor: 'pointer',
    textAlign: 'left',
  }

  return (
    <div style={textStyle} onClick={toggleExpanded}>
      {text}
    </div>
  )
}

export default ExpandableText

import React from 'react'

function Input({
  min,
  className,
  id = '',
  placeholder = '',
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  value = '',
  type = 'text',
  name = '',
}) {
  const styleByType = {
    text: 'py-2',
    number: 'border-2 border-sub-300 py-1 ',
    submit: 'bg-theme-500 rounded-lg text-white px-2 py-1 font-bold',
  }
  return (
    <input
      min={min}
      className={`${className} ${styleByType[type] || ''}`}
      id={id}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      onFocus={onFocus}
      type={type}
      name={name}
    />
  )
}

export default Input

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import OrderStore from '../stores/OrderStore'
import moment from 'moment'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import TeamGatheringOrders from '../components/TeamGatheringOrders'
import PreShippingOrders from '../components/PreShippingOrders'
import CancelingPreShippingOrders from '../components/CancelingPreShippingOrders'
import CanceledPreShippingOrders from '../components/CanceledPreShippingOrders'
import ShippingOrders from '../components/ShippingOrders'
import SendingOrders from '../components/SendingOrders'
import CancelingShippingOrders from '../components/CancelingShippingOrders'
import CanceledShippingOrders from '../components/CanceledShippingOrders'
import ArrivedOrders from '../components/ArrivedOrders'
import RefundingOrders from '../components/RefundingOrders'
import RefundedOrders from '../components/RefundedOrders'
import UserStore from '../stores/UserStore'
import PreShippingOrdersAfterExcel from '../components/PreShippingOrdersAfterExcel'
import WithdrawCancelingPreShippingOrderModal from './WithdrawCancelingPreShippingOrderModal'
import CancelPreShippingOrderModal from './CancelPreShippingOrderModal'
import Modal from 'react-modal'
import CancelingPreShippingOrdersAfterExcel from '../components/CancelingPreShippingOrdersAfterExcel'
import RefundingApprovedOrders from '../components/RefundingApprovedOrders'

const UserOrdersScreen = observer(() => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('')
  const [searchedRecipient, setSearchedRecipient] = useState(false)
  const [orderId, setOrderId] = useState('')

  return (
    <div className='page'>
      <h1 className='pageTitle'>고객 주문 관리</h1>
      <h2>주문자 전화번호</h2>
      <div>
        전화번호:
        <input
          type='text'
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value)
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              setSearchedRecipient(false)
              if (!phoneNumber) {
                //   setUsers([])
                UserStore.setSearchedUsers([])
                return
              }
              SellerStore.setIsLoading(true)
              // const result = await backendApis.setItemSoldOut(itemId)
              const result = await backendApis.searchUsersByPhoneNumber(
                phoneNumber,
              )
              SellerStore.setIsLoading(false)
              if (result?.status === 200 && result.data) {
                //   setPhoneNumber('')
                //   setUsers(result?.data)
                UserStore.setSearchedUsers(result.data)
              }
            }
          }}
        />
        <button
          className='ml-2 btn'
          type='button'
          onClick={async () => {
            if (phoneNumber.length !== 11) {
              alert('전화번호를 입력해 주세요!\n예시) 01012345678')
            } else {
              setSearchedRecipient(false)
              if (!phoneNumber) {
                //   setUsers([])
                UserStore.setSearchedUsers([])
                return
              }
              SellerStore.setIsLoading(true)
              // const result = await backendApis.setItemSoldOut(itemId)
              const result = await backendApis.searchUsersByPhoneNumber(
                phoneNumber,
              )
              SellerStore.setIsLoading(false)
              if (result?.status === 200 && result.data) {
                //   setPhoneNumber('')
                //   setUsers(result?.data)
                UserStore.setSearchedUsers(result.data)
              }
            }
          }}
        >
          검색
        </button>
        <h2>수령인 전화번호</h2>
        <div>
          전화번호:
          <input
            type='text'
            value={recipientPhoneNumber}
            onChange={(e) => {
              setRecipientPhoneNumber(e.target.value)
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                UserStore.setSelectedUserInfo({})
                if (!recipientPhoneNumber) {
                  //   setUsers([])
                  OrderStore.setRecipientPhoneNumber([])
                  return
                }
                SellerStore.setIsLoading(true)
                // const result = await backendApis.setItemSoldOut(itemId)
                const result =
                  await backendApis.searchOrdersByRecipientPhoneNumber(
                    recipientPhoneNumber,
                  )
                SellerStore.setIsLoading(false)
                if (result?.status === 200 && result.data) {
                  //   setPhoneNumber('')
                  //   setUsers(result?.data)
                  OrderStore.setUserOrders(result.data)
                  setSearchedRecipient(true)
                  // UserStore.setSearchedUsers(result.data)
                }
              }
            }}
          />
          <button
            className='ml-2 btn'
            type='button'
            onClick={async () => {
              if (recipientPhoneNumber.length !== 11) {
                alert('전화번호를 입력해 주세요!\n예시) 01012345678')
              } else {
                UserStore.setSelectedUserInfo({})
                // OrderStore.selectedRecipientInfo(order)
                // if (!recipientPhoneNumber) {
                //   //   setUsers([])
                //   OrderStore.setSearchedRecipients([])
                //   return
                // }
                SellerStore.setIsLoading(true)
                // const result = await backendApis.setItemSoldOut(itemId)
                const result =
                  await backendApis.searchOrdersByRecipientPhoneNumber(
                    recipientPhoneNumber,
                  )
                SellerStore.setIsLoading(false)
                if (result?.status === 200 && result.data) {
                  //   setPhoneNumber('')
                  //   setUsers(result?.data)
                  OrderStore.setUserOrders(result.data)
                  setSearchedRecipient(true)
                }
              }
            }}
          >
            검색
          </button>
        </div>
        <div />
        <h1>주문 아이디</h1>
        <div style={{ paddingBottom: 30 }}>
          주문 아이디:{' '}
          <input
            type='text'
            value={orderId}
            onChange={(e) => {
              setOrderId(e.target.value)
            }}
          />
          <button
            className='ml-2 btn'
            type='button'
            onClick={async () => {
              if (!orderId) {
                alert('주문아이디를 입력해주세요.')
                UserStore.setSearchedUsers([])
                return
              }
              const result = await backendApis.getOrdererInfo(orderId)
              if (result?.status === 2000) {
                UserStore.setSearchedUsers([result.data])
              }
            }}
          >
            검색
          </button>
          {/* {ordererInfo._id && (
          <div>
            <div>주문자 닉네임: "{ordererInfo.userName}"</div>
            <div>주문자 전화번호: "{ordererInfo.phoneNumber}"</div>
          </div>
        )} */}
        </div>
      </div>

      <div>
        {UserStore.searchedUsers.length > 0 && (
          <table>
            <thead style={{ fontSize: 20 }}>
              <tr>
                <th>사용자 아이디</th>
                <th>사용자 이름</th>
                <th>전화번호</th>
                <th>사용자 주문 조회하기</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 20 }}>
              {UserStore.searchedUsers.map((user, index) => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.userName}</td>
                  <td>{user.phoneNumber}</td>
                  <td>
                    <button
                      style={{ fontSize: 20 }}
                      type='button'
                      onClick={async () => {
                        setSearchedRecipient(false)
                        // setSelectedUserInfo(user)
                        UserStore.setSelectedUserInfo(user)
                        SellerStore.setIsLoading(true)
                        const result = await backendApis.getUserOrders(user._id)
                        SellerStore.setIsLoading(false)
                        if (result?.status === 200) {
                          OrderStore.setUserOrders(result.data)
                        }
                      }}
                    >
                      주문 조회하기
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div>
        {UserStore.selectedUserInfo._id && (
          <>
            <h2>
              {UserStore.selectedUserInfo.userName}님 (
              {UserStore.selectedUserInfo.phoneNumber}) 주문 내역
            </h2>
            {/* <div>{JSON.stringify(orders)}</div> */}
            <TeamGatheringOrders orders={OrderStore.userOrders} setOrders />
            <PreShippingOrders orders={OrderStore.userOrders} setOrders />
            <PreShippingOrdersAfterExcel
              orders={OrderStore.userOrders}
              setOrders
            />
            <CancelingPreShippingOrders
              orders={OrderStore.userOrders}
              setOrders
            />
            <CancelingPreShippingOrdersAfterExcel
              orders={OrderStore.userOrders}
              setOrders
            />
            <CanceledPreShippingOrders
              orders={OrderStore.userOrders}
              setOrders
            />
            <SendingOrders orders={OrderStore.userOrders} setOrders />
            <ShippingOrders orders={OrderStore.userOrders} setOrders />
            <CancelingShippingOrders orders={OrderStore.userOrders} setOrders />
            <CanceledShippingOrders orders={OrderStore.userOrders} setOrders />
            <ArrivedOrders orders={OrderStore.userOrders} setOrders />
            <RefundingOrders orders={OrderStore.userOrders} setOrders />
            <RefundingApprovedOrders orders={OrderStore.userOrders} setOrders />
            <RefundedOrders orders={OrderStore.userOrders} setOrders />
          </>
        )}
      </div>

      {searchedRecipient && (
        <div>
          <TeamGatheringOrders orders={OrderStore.userOrders} setOrders />
          <PreShippingOrders orders={OrderStore.userOrders} setOrders />
          <PreShippingOrdersAfterExcel
            orders={OrderStore.userOrders}
            setOrders
          />
          <CancelingPreShippingOrders
            orders={OrderStore.userOrders}
            setOrders
          />
          <CanceledPreShippingOrders orders={OrderStore.userOrders} setOrders />
          <SendingOrders orders={OrderStore.userOrders} setOrders />
          <ShippingOrders orders={OrderStore.userOrders} setOrders />
          <CancelingShippingOrders orders={OrderStore.userOrders} setOrders />
          <CanceledShippingOrders orders={OrderStore.userOrders} setOrders />
          <ArrivedOrders orders={OrderStore.userOrders} setOrders />
          <RefundingOrders orders={OrderStore.userOrders} setOrders />
          <RefundedOrders orders={OrderStore.userOrders} setOrders />
        </div>
      )}

      {/* 수령인 검색 */}
      <Modal
        isOpen={OrderStore?.openWithdrawCancelingPreShippingOrderModal}
        onRequestClose={() => {
          OrderStore?.setOpenWithdrawCancelingPreShippingOrderModal(false)
        }}
        style={{
          overlay: {
            zIndex: 20,
          },
          content: {
            left: '20%',
            right: '20%',
            top: '20%',
            bottom: '20%',
            padding: 0,
            // zIndex: 20,
            // marginRight: '-50%',
          },
        }}
      >
        <WithdrawCancelingPreShippingOrderModal
          id='modal'
          setOpenModal={OrderStore?.openWithdrawCancelingPreShippingOrderModal}
          // phoneNumberForSearch={UserStore?.searchedUsers?.phoneNumber}
          // searchedQuestions={searchedQuestions}
          // users={ItemStore.itemQuestions.find(
          //   (orderTemp) => orderTemp?.orderId === orderId,
          // )}
          // analytics={analytics}
          // logEvent={logEvent}
        />
      </Modal>
      <Modal
        isOpen={OrderStore?.openCancelPreShippingOrderModal}
        onRequestClose={() => {
          OrderStore?.setOpenCancelPreShippingOrderModal(false)
        }}
        style={{
          overlay: {
            zIndex: 20,
          },
          content: {
            left: '20%',
            right: '20%',
            top: '20%',
            bottom: '20%',
            padding: 0,
            // zIndex: 20,
            // marginRight: '-50%',
          },
        }}
      >
        <CancelPreShippingOrderModal
          setOpenModal={OrderStore?.openCancelPreShippingOrderModal}
        />
      </Modal>
    </div>
  )
})

export default UserOrdersScreen

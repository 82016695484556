import ButtonCard from '../atoms/buttonCard'
import SellerStore from '../../stores/SellerStore'

const AdAnnounce = ({ tab, setTab }) => {
  return (
    <div className='p-10 mb-10 bg-white shadow-lg rounded-xl'>
      <div className='flex flex-row items-center justify-between flex-1'>
        <div>
          <div className='mr-2 text-lg font-bold'>안내사항</div>
          <div>
            - 신청한 광고의 진행상황을 확인할 수 있습니다. <br />
            - 광고는 승인 이후 진행 되며 광고 신청 수량이 모두 소진 될 때 까지
            진행됩니다. <br />
            - 최대 3일 동안 진행되며 3일이 지나면 소진 여부와 관계 없이 광고가
            종료됩니다. <br />
            - 광고는 도중에 종료할 수 있습니다. 해당 광고카드의 x 버튼을
            클릭하여 종료할 수 있습니다. <br />
            - 한 번 종료되면 새롭게 광고를 신청해야만 재개할 수 있으니
            참고바랍니다. <br />
            - 발생매출과 ROAS는 해당 광고를 통해서만 발생한 것이며 광고 외
            유입을 통한 매출은 집계에 포함되지 않습니다. <br />- 매출은 소진된
            광고보다 늦게 발생할 수 있어요. ROAS는 시간을 두고 판단하시는 것을
            추천드립니다. <br />- 광고는 신청 수량보다 많이 집행될 수 있습니다.
            신청 수량보다 광고가 더 많이 집행되더라도 광고비는 신청 수량만큼만
            산정됩니다.
          </div>
        </div>
      </div>
    </div>
  )
}
export default AdAnnounce

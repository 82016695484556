import backendApis from '../backendApis'

const matchBusinessNumber = async (businessNumber) => {
  const loadSeller = await backendApis.loadSellerBusinessRegistrationInfo()
  const businessNumberFromImage =
    loadSeller?.data?.businessRegisteredData?.registerNumber

  if (businessNumberFromImage !== businessNumber) {
    return {
      status: 400,
      message:
        '입력하신 사업자번호와 사업자등록증 상 사업자번호가 일치하지 않습니다.',
    }
  }

  const checkResult =
    await backendApis.checkDuplicateBusinessRegistrationNumber(businessNumber)

  if (checkResult?.data !== null) {
    return {
      status: 400,
      message: '이미 등록된 사업자번호입니다. 다시 확인해주세요.',
    }
  }

  return {
    status: 200,
    message: '사업자번호 등록에 성공했습니다.',
  }
}

export default matchBusinessNumber

import resize from '../../utils/resize'
import DensityPlot from '../molecules/densityPlot'
import React, { useState, useEffect } from 'react'
import OptionTableV2 from '../molecules/optionTableV2'
import itemInfoParser from '../../utils/itemInfoParser'
import CheapabilityExplainBox from '../molecules/cheapabilityExplainBox'
import moment from 'moment'
import { FcLike } from 'react-icons/fc'
import ImpCalculator from '../molecules/impCalculator'
import {
  calculateCheapability,
  calculateRefPrice,
  calculateOrderMultiplier,
} from '../../utils/cheapabilityUtils'
import PhoneMockUp from '../molecules/phoneMockUp'
import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import commaNumber from 'comma-number'

function roundDownToNearestTen(number) {
  // Math.floor 함수를 사용하여 10의 배수로 내림
  return Math.floor(number / 10) * 10
}

const CheapabilityNudgeComponent = ({ itemInfo, refetch = () => {} }) => {
  const [optionIdx, setOptionIdx] = useState({ 0: 0, 1: 0 })
  const itemId = itemInfo?._id
  const catalogOptionsInfo = itemInfo?.optionsInfo
  const totalOptions = catalogOptionsInfo?.totalOptions
  const targetOptionName =
    catalogOptionsInfo?.totalOptions?.length === 1
      ? totalOptions?.[0]?.[optionIdx[0]]?.name
      : `${totalOptions?.[0]?.[optionIdx[0]]?.name} / ${
          totalOptions?.[1]?.[optionIdx[1]]?.name
        }`
  const targetOptionPrice =
    catalogOptionsInfo?.totalOptions?.length === 1
      ? catalogOptionsInfo?.optionPrices?.[optionIdx[0]]
      : catalogOptionsInfo?.optionPrices?.[optionIdx[0]]?.[optionIdx[1]]
  const priceList = targetOptionPrice?.priceList
  const teamPurchasePrice = targetOptionPrice?.teamPurchasePrice
  const cheapability = targetOptionPrice?.cheapability
  const refPrice1 = targetOptionPrice?.refPrice1 || null

  const calculateAllRefPrices = (itemInfo) => {
    itemInfo?.optionsInfo?.optionPrices?.flat()?.forEach((e) => {
      const priceList = e?.priceList
      const teamPurchasePrice = e?.teamPurchasePrice
      const cheapability = calculateCheapability(
        priceList || [],
        teamPurchasePrice || 0,
      )
      const refPrice1 =
        parseInt(roundDownToNearestTen(calculateRefPrice(priceList, -1)), 10) ||
        null
      e.cheapability = cheapability
      e.refPrice1 = refPrice1
    })
  }

  const optionChangeCallback = async () => {
    const optionIndices = optionIdx
    const oldTeamPurchasePrice = teamPurchasePrice
    const copyOptionsInfo = JSON.parse(JSON.stringify(catalogOptionsInfo))
    copyOptionsInfo?.optionPrices?.flat()?.forEach((e) => {
      delete e.cheapability
      delete e.priceList
      delete e.refPrice1
    })
    if (
      window.confirm(
        `선택하신 옵션의 가격을 초저가(₩${commaNumber(
          refPrice1,
        )})으로 변경하시겠습니까?`,
      )
    ) {
      if (oldTeamPurchasePrice < refPrice1) {
        alert('이미 초저가를 달성한 옵션입니다.')
        return
      }
      const result = await backendApis?.updateExtremePrice(
        itemId,
        copyOptionsInfo,
        'option',
        optionIndices,
      )
      if (result?.status === 200) {
        alert('초저가 옵션 변경 완료')
        refetch()
      } else {
        alert('초저가 옵션 변경 실패. 잠시 후에 다시 시도해주세요.')
      }
    }
  }

  const itemChangeCallback = async () => {
    const copyOptionsInfo = JSON.parse(JSON.stringify(catalogOptionsInfo))
    copyOptionsInfo?.optionPrices?.flat()?.forEach((e) => {
      delete e.cheapability
      delete e.priceList
      delete e.refPrice1
    })
    const itemId = itemInfo?._id
    if (
      window.confirm(`선택하신 상품의 가격을 초저가 기준으로 변경하시겠습니까?`)
    ) {
      const result = await backendApis.updateExtremePrice(
        itemId,
        copyOptionsInfo,
        'item',
        {},
      )
      if (result?.status === 200) {
        alert('상품 초저가 변경 완료')
        refetch()
      } else {
        alert('상품 초저가 변경 실패. 잠시 후에 다시 시도해주세요.')
      }
    }
  }

  const onRowClick = (optionIdx) => {
    setOptionIdx(optionIdx)
  }

  const columns = [
    { Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 },
    { Header: '옵션2 / 상세옵션', accessor: 'option2', index: 2 },
    { Header: '팀구매가', accessor: 'teamPurchasePrice', index: 3 },
    { Header: '재고량', accessor: 'stockNumber', index: 5 },
    {
      Header: '가격변경',
      Cell: ({ row }) => (
        <Button
          disabled={
            row?.original?.teamPurchasePrice <= row?.original?.refPrice1 ||
            !row?.original?.refPrice1
          }
          onClick={async () => {
            const copyOptionsInfo = JSON.parse(
              JSON.stringify(catalogOptionsInfo),
            )
            copyOptionsInfo?.optionPrices?.flat()?.forEach((e) => {
              delete e.cheapability
              delete e.priceList
              delete e.refPrice1
            })
            const optionIndices = row?.original?.optionIndices
            const oldTeamPurchasePrice = row?.original?.teamPurchasePrice
            const refPrice1 = row?.original?.refPrice1
            if (
              window.confirm(
                `선택하신 옵션의 가격을 초저가(₩${commaNumber(
                  refPrice1,
                )})으로 변경하시겠습니까?`,
              )
            ) {
              if (oldTeamPurchasePrice <= refPrice1) {
                alert('이미 초저가를 달성한 옵션입니다.')
                return
              }
              const result = await backendApis?.updateExtremePrice(
                itemId,
                copyOptionsInfo,
                'option',
                optionIndices,
              )
              if (result?.status === 200) {
                alert('초저가 옵션 변경 완료')
                refetch()
              } else {
                alert('초저가 옵션 변경 실패. 잠시 후에 다시 시도해주세요.')
              }
            }
          }}
          appearance='teritiary'
        >
          {row?.original?.teamPurchasePrice <= row?.original?.refPrice1 ||
          !row?.original?.refPrice1
            ? '---'
            : '초저가 옵션 변경'}
        </Button>
      ),
      index: 6,
    },
  ]

  useEffect(() => {
    calculateAllRefPrices(itemInfo)
    return setOptionIdx({ 0: 0, 1: 0 })
  }, [itemInfo])

  return (
    <div className='flex flex-1 px-8 py-8 mb-8 bg-white shadow-md select-none min-h-80 whitespace-nowrap rounded-xl'>
      <div className='flex flex-col items-start justify-center flex-1'>
        <div className='mb-5 text-xl font-bold'>{`[${itemInfo?.itemTitle}] 상품 가격 경쟁력`}</div>
        <div className='mb-10'>
          선택하신 상품의 동종상품 내{' '}
          <span className='font-bold text-blue-500'>상품 가격 경쟁력</span>은
          다음과 같습니다.
        </div>
        <div className='flex flex-row'>
          {priceList?.length > 0 && (
            <div className='flex flex-col items-baseline justify-between flex-1 mr-12'>
              <>
                <div className='font-bold text-slate-700'>
                  유사 상품 가격분포
                </div>
                <div>
                  <DensityPlot
                    dataArray={priceList}
                    price={teamPurchasePrice}
                    targetPrice={refPrice1}
                    width={430}
                    height={300}
                  />
                </div>
              </>
            </div>
          )}
          <div className='flex flex-col items-baseline justify-between flex-1 gap-5 mr-12'>
            <div className='font-bold text-slate-700'>가격 경쟁력 분석</div>
            <div>
              <div className='mb-5'>
                <CheapabilityExplainBox
                  optionName={targetOptionName}
                  optionPrice={targetOptionPrice}
                  cheapability={cheapability}
                  refPrice={refPrice1}
                />
              </div>
              <ImpCalculator
                oldPrice={teamPurchasePrice}
                priceList={priceList}
                bestPrice={refPrice1}
                optionIndices={optionIdx}
                optionChangeCallback={optionChangeCallback}
                itemChangeCallback={itemChangeCallback}
              />
            </div>
          </div>
          <div>
            {refPrice1 && (
              <div className='flex flex-col justify-between'>
                <div className='flex flex-row items-center mb-1 mr-2'>
                  {teamPurchasePrice > refPrice1 ? (
                    <div className='font-bold text-slate-700'>초저가 혜택</div>
                  ) : (
                    <div className='flex flex-row items-center flex-1 font-bold text-slate-700'>
                      <FcLike className='mr-2' />
                      현재 혜택
                    </div>
                  )}
                </div>
                <div className='mb-3'>
                  <span className='px-2 py-1 mr-2 text-sm text-white bg-red-500 rounded-md'>
                    초저가 뱃지
                  </span>
                  <span className='px-2 py-1 mr-2 text-sm text-white rounded-md bg-slate-400'>
                    특가 딜 진행
                  </span>
                  <span className='px-2 py-1 text-sm text-white rounded-md bg-slate-400'>
                    노출 부스팅
                  </span>
                </div>
                <PhoneMockUp image={itemInfo?.mainImageUris?.[0]} />
              </div>
            )}
          </div>
        </div>
        <div className='flex flex-row flex-1 w-full'>
          {catalogOptionsInfo && (
            <div className='w-full mt-10 h-80'>
              <OptionTableV2
                optionsInfo={catalogOptionsInfo}
                columns={columns}
                onRowClick={onRowClick}
                optionIdx={optionIdx}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default CheapabilityNudgeComponent

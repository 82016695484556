import Button from './button'
import Modal from './modal'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import { GrFormClose as CloseIcon } from 'react-icons/gr'
import { FcAlarmClock as TimeIcon } from 'react-icons/fc'
import Tooltip from '../ADS/atoms/tooltip'
import SellerQuest from '../../utils/sellerQuest'
import backendApis from '../../utils/backendApis'
import {
  IoIosArrowUp as ArrowUpIcon,
  IoIosArrowDown as ArrowDownIcon,
} from 'react-icons/io'

const TimedealQuest = observer(
  ({ openModal = true, setOpenModal = () => {}, setShowQuest }) => {
    const timedealQuestStages = [
      'checkTimedealInfoModal',
      'registerTimedealItem',
      'registerTimedealBatch',
    ]

    return (
      <>
        {openModal ? (
          <div className='px-5 py-3 bg-white shadow-xl select-none text-sub-700 rounded-xl w-80 h-95'>
            <div className='flex flex-row items-center justify-end flex-1 mb-1 '>
              <Button
                appearance='transparent'
                textColor='black'
                onClick={() => setShowQuest(false)}
              >
                <CloseIcon className='w-6 h-6' />
              </Button>
            </div>
            <div className='flex flex-row items-center justify-between flex-1 mb-3 text-lg font-bold'>
              <div className='flex flex-row items-center'>
                <TimeIcon className='mr-1' />
                타임특가 퀘스트
              </div>
              <Button
                appearance='transparent'
                textColor='black'
                onClick={() => setOpenModal(false)}
              >
                <ArrowUpIcon className='w-6 h-6' />
              </Button>
            </div>
            <div className='flex flex-row items-center justify-end flex-1 mb-1 text-sm'>
              <div>퀘스트 달성도</div>
            </div>
            <div className='w-full h-4 mb-5 bg-gray-100 rounded-xl'>
              <div
                className='h-4 bg-positive-500 rounded-xl ease-in-out duration-300'
                style={{
                  width: `${SellerQuest.questProgressChecker(
                    'timedealQuest',
                    timedealQuestStages,
                  )}%`,
                }}
              />
            </div>
            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center text-base font-bold'>
                  타임특가 알아보기
                  <Tooltip text='💡평균 판매량 20% 증가! 타임특가에 대하여 알려드립니다.' />
                </div>
                <Modal
                  className='shadow-none'
                  modalTitle='타임특가 알아보기'
                  modalContent={
                    <>
                      <ol className='ml-5 list-decimal'>
                        <li className='mb-2'>
                          타임특가는 매일 7시부터 11시 사이 시간 중 1시간 동안
                          상품을 할인된 가격에 판매할 수 있는{' '}
                          <span className='font-bold text-blue-500'>
                            시간 한정 딜
                          </span>
                          이에요.
                        </li>
                        <li className='mb-2'>
                          매일 50만명 이상의 유저분들이 타임특가를 찾아주시는
                          만큼, 기존 판매량 대비{' '}
                          <span className='font-bold text-blue-500'>
                            20% 이상 추가적인 매출
                          </span>
                          이 발생할 수 있어요. (광고 효과로 치면 비용 대비
                          매출이{' '}
                          <span className='font-bold text-blue-500'>
                            최소 900%
                          </span>
                          가 보장되는 것과 같아요.)
                        </li>
                        <li className='mb-2'>
                          타임특가 상품은{' '}
                          <span className='font-bold text-blue-500'>
                            B등급 이상
                          </span>
                          의 상품을 기존 팀구매가 대비{' '}
                          <span className='font-bold text-blue-500'>
                            10% 이상 할인된 가격
                          </span>
                          으로 신청할 경우 진행 가능해요.
                        </li>
                      </ol>
                    </>
                  }
                  modalLog='itemRankTutorialButton'
                  isButton
                  buttonText='확인했습니다'
                  buttonCallbackFunction={async () => {
                    await SellerQuest.questClearer(
                      'timedealQuest',
                      'checkTimedealInfoModal',
                    )
                  }}
                  appearance={
                    SellerQuest.questChecker(
                      'timedealQuest',
                      'checkTimedealInfoModal',
                    )
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'timedealQuest',
                    'checkTimedealInfoModal',
                  )}
                >
                  {SellerQuest.questChecker(
                    'timedealQuest',
                    'checkTimedealInfoModal',
                  )
                    ? '완료'
                    : 'GO'}
                </Modal>
              </div>
            </div>
            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center'>
                  <div className='text-base font-bold'>
                    타임특가 한 개 등록하기
                  </div>
                  <Tooltip text='💡타임특가를 하나 이상 신청해주세요.' />
                </div>
                <Button
                  href='/timedeal-apply'
                  appearance={
                    SellerQuest.questChecker(
                      'timedealQuest',
                      'registerTimedealItem',
                    )
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'timedealQuest',
                    'registerTimedealItem',
                  )}
                >
                  {SellerQuest.questChecker(
                    'timedealQuest',
                    'registerTimedealItem',
                  )
                    ? '완료'
                    : 'GO'}
                </Button>
              </div>
            </div>
            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center'>
                  <div className='text-base font-bold'>
                    타임특가 엑셀 대량 등록하기
                    <Tooltip text='💡타임특가 대량등록 기능을 활용해보세요.' />
                  </div>
                </div>
                <Button
                  href='/timedeal-apply'
                  appearance={
                    SellerQuest.questChecker(
                      'timedealQuest',
                      'registerTimedealBatch',
                    )
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'timedealQuest',
                    'registerTimedealBatch',
                  )}
                >
                  {SellerQuest.questChecker(
                    'timedealQuest',
                    'registerTimedealBatch',
                  )
                    ? '완료'
                    : 'GO'}
                </Button>
              </div>
            </div>
            <div className='flex flex-row justify-center flex-1 w-full'>
              <Modal
                modalTitle='타임특가 퀘스트를 완료하셨습니다'
                imageSource='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/a0005d3d-0035-4b20-a83a-8430227ea5be.jpeg'
                modalContent={
                  <>
                    <div className='items-center mb-3 text-lg font-bold'>
                      축하드립니다!
                    </div>
                    <div className='items-center mb-3 '>
                      항상 올웨이즈를 사랑해 주셔서 감사드립니다.
                    </div>
                  </>
                }
                modalLog='itemRankTutorialButton'
                isButton
                buttonText='닫기'
                buttonCallbackFunction={async () => {
                  if (
                    SellerQuest.questProgressChecker(
                      'timedealQuest',
                      timedealQuestStages,
                    ) === 100 &&
                    !SellerQuest.rewardChecker('timedealQuest')
                  ) {
                    const result =
                      await backendApis.updateSellerQuestRewardStatus(
                        'timedealQuest',
                      )
                    if (result?.status === 200) {
                      alert('타임특가 퀘스트를 완료하셨습니다.')
                    }
                    await SellerStore.loadSellerInfo()
                  }
                }}
                appearance={
                  SellerQuest.questProgressChecker(
                    'timedealQuest',
                    timedealQuestStages,
                  ) === 100 && !SellerQuest.rewardChecker('timedealQuest')
                    ? 'positive'
                    : 'disabled'
                }
                size='flexlg'
                disabled={
                  SellerQuest.questProgressChecker(
                    'timedealQuest',
                    timedealQuestStages,
                  ) !== 100 || SellerQuest.rewardChecker('timedealQuest')
                }
              >
                <span className='flex flex-row items-center justify-center flex-1'>
                  퀘스트 완료하기
                </span>
              </Modal>
            </div>
          </div>
        ) : (
          <Button
            appearance='transparent'
            onClick={() => {
              setOpenModal(true)
            }}
          >
            <div className='flex flex-row items-center justify-between flex-1 px-5 py-3 text-black bg-white shadow-xl w-80 rounded-xl'>
              <div className='flex flex-row items-center'>
                <TimeIcon className='mr-2' />
                타임특가 퀘스트 보기
              </div>
              <ArrowDownIcon className='ml-2' />
            </div>
          </Button>
        )}
      </>
    )
  },
)
export default TimedealQuest

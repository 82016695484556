/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-loop-func */
import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import AuthStore from '../stores/AuthStore'
import OrderStore from '../stores/OrderStore'
import ItemStore from '../stores/ItemStore'
import ImageUploading from 'react-images-uploading'
import 'react-image-crop/dist/ReactCrop.css'
import DetailImageUpdateComponent from '../components/ItemComponents/DetailImageUpdateComponent'
import SelectOptionButton from '../components/ItemComponents/SelectOptionButton'
import commaNumber from 'comma-number'
import Tag from '../components/atoms/tag'
import Card from '../components/atoms/card'
import SubTitle from '../components/atoms/subTitle'
import { FcOpenedFolder, FcFolder } from 'react-icons/fc'
import DetailPageExample from '../images/detailPageExample.png'
import TutorialModal from '../components/atoms/tutorialModal'
import Tutorial1 from '../videos/tutorial1.mp4'
import Tutorial2 from '../videos/tutorial2.mp4'
import Tutorial3 from '../videos/tutorial3.mp4'
import Tutorial4 from '../videos/tutorial4.mp4'
import Tutorial5 from '../videos/tutorial5.mp4'
import Tutorial6 from '../videos/tutorial6.mp4'
import Tutorial7 from '../videos/tutorial7.mp4'
import Tutorial8 from '../videos/tutorial8.mp4'
import { BsQuestionSquare as AlwayzQuestion } from 'react-icons/bs'
import Input from '../components/atoms/input'
import Button from '../components/atoms/button'
import Format from '../utils/format'
import Page from '../components/atoms/page'
import Description from '../components/atoms/description'
import Log from '../utils/log'

// ICONS
import PlusComponent from '../components/atoms/icons/Plus'
import RightArrowComponent from '../components/atoms/icons/RightArrow'

// CARDS
import RegisterItemRecommendCard from '../components/molecules/ItemInfoCards/RegisterItemRecommendCard'
import HtmlViewer from '../components/molecules/htmlViewer'
import OptionChangeHistoryModalButton from '../components/organisms/OptionChangeHistoryModalButton'
import { Space } from 'antd'
// fix me
// import DealReApplicationModal from '../components/molecules/DealReApplicationModal'

const ItemDetailScreen = observer(() => {
  const location = useLocation()
  const history = useHistory()
  const data = location.state
  // fix me - 셀러 문의 많을 경우 dealReApply 필요
  // const reApplyDeal = data?.reApplyDeal || false
  // if (reApplyDeal) {
  //   data = data?.itemInfo
  // }

  const [largeCategoryInfo, setLargeCategoryInfo] = useState({})
  const [mediumCategoryInfo, setMediumCategoryInfo] = useState({})
  const [smallCategoryInfo, setSmallCategoryInfo] = useState({})
  const [CommonIndividualPrice, setCommonIndividualPrice] = useState(0)
  const [CommonTeamPurchasePrice, setCommonTeamPurchasePrice] = useState(0)
  const [CommonStockNumber, setCommonStockNumber] = useState(0)
  const [nonJejuFee, setNonJejuFee] = useState(5000)
  const [jejuFee, setJejuFee] = useState(3000)
  const [shippingInfo, setShippingInfo] = useState({
    freeShipping: true,
    shippingMethod: '순차배송',
    shippingFee: 0,
    shippingFeeInfo:
      '무료배송· 도서산간 추가 배송비- 제주 지역: 3,000원- 도서산간 지역: 5,000원',
    returnFee: 5000,
    returnFeeInfo:
      '5,000원- 단, 고객 변심의 경우에만 발생- 도서산간 및 일부 지역 추가비용 발생',
  })
  const [optionsInfo, setOptionsInfo] = useState({
    optionNames: [''],
    totalOptions: [
      [
        {
          name: '',
          img: null,
        },
      ],
    ],
    optionPrices: [
      {
        individualPurchasePrice: 0,
        teamPurchasePrice: 0,
        aiPrice: 0,
        stockNumber: 100,
        sold_out: false,
        hurry_up: false,
        optionMetaDataInfoList: [],
      },
    ],
  })
  const [mainImagesData, setMainImagesData] = useState([])
  const [mainAdditionalImagesData, setMainAdditionalImagesData] = useState([])
  const [detailImagesData, setDetailImagesData] = useState([])

  const [optionIndices, setOptionIndices] = useState({})
  const [displayedPrice, setDisplayedPrice] = useState(0)
  const [specialDealStatus, setSpecialDealStatus] = useState()
  const [detailHTML, setDetailHTML] = useState('')
  const [coupangRocketLink, setCoupangRocketLink] = useState('')
  const [naverLowestLink, setNaverLowestLink] = useState('')
  const [categorySearch, setCategorySearch] = useState('')
  const [commonMetaDataList, setCommonMetaDataList] = useState([])
  const [optionMetaDataList, setOptionMetaDataList] = useState([])
  const [productMetaDataList, setProductMetaDataList] = useState([])

  const [originalCommonMetaDataList, setOriginalCommonMetaDataList] = useState(
    [],
  )
  const [originalProductMetaDataList, setOriginalProductMetaDataList] =
    useState([])

  const [originalTeamPurchasePrice, setOriginalTeamPurchasePrice] = useState(0)
  const [recommendItemCode, setRecommendItemCode] = useState('')
  const [originalOptionsInfo, setOriginalOptionsInfo] = useState({
    optionNames: [''],
    totalOptions: [
      [
        {
          name: '',
          img: null,
        },
      ],
    ],
    optionPrices: [
      {
        individualPurchasePrice: 0,
        teamPurchasePrice: 0,
        aiPrice: 0,
        stockNumber: 100,
        sold_out: false,
        hurry_up: false,
        optionMetaDataInfoList: [],
      },
    ],
  })

  const [categorySelecter, setCategorySelecter] = useState('categorySearch')
  const [categorySearchQuery, setCategorySearchQuery] = useState('')
  const [isFocusOnCategorySearch, setIsFocusOnCategorySearch] = useState(false)
  const [categorySearchResultArray, setCategorySearchResultArray] = useState([])

  const [selectedMinimumCategory, setSelectedMinimumCategory] = useState(null)
  const [parentsOfMinimumCategory, setParentsOfMinimumCategory] = useState([])
  // fix me
  const [openDealReApplicationModal, setOpenDealReApplicationModal] =
    useState(false)
  const [itemInfo, setItemInfo] = useState(null)

  const categorySelectButtonList = [
    { name: '카테고리 검색', id: 'categorySearch' },
    { name: '카테고리 선택', id: 'categorySelect' },
    { name: '이전 카테고리 선택', id: 'prevCategorySelect' },
  ]

  const CategorySelectHandler = () => {
    return (
      <>
        {categorySelectButtonList.map((button) => (
          <button
            key={button.name}
            type='button'
            className={`px-3 py-1 text-sm border border-gray-500 ${
              categorySelecter === button.id
                ? 'bg-blue-500 text-white'
                : 'bg-white'
            }`}
            id={button.id}
            onClick={(e) => {
              setCategorySelecter(e.target.id)
            }}
          >
            {button.name}
          </button>
        ))}
      </>
    )
  }

  // 템플릿을 로드하여 입력한 original 값과 비교해봐야 한다

  const loadCommonMetaDataList = async (originalCommonMetaDataListTemp) => {
    const result = await backendApis.loadCommonMetaDataList()

    if (
      result.status === 2000 &&
      result?.data?.commonMetaDataInfoList?.length > 0
    ) {
      setCommonMetaDataList(
        result?.data?.commonMetaDataInfoList?.map((metaData) => {
          return {
            ...metaData,
            contents: originalCommonMetaDataListTemp?.find((metaDataTemp) => {
              return metaDataTemp?.title === metaData?.title
            })?.contents,
          }
        }) || [],
      )
    }
  }

  const loadMediumCategoryMetaDataList = async (
    mediumCategoryId,
    optionsInfoTemp,
    originalProductMetaDataListTemp,
  ) => {
    const result = await backendApis.loadMediumCategoryMetaDataList(
      mediumCategoryId,
    )
    if (result?.status === 2000) {
      const optionMetaDataInfoListResult =
        result?.data?.optionMetaDataInfoList || []
      setOptionMetaDataList(optionMetaDataInfoListResult)
      setOptionsInfo({
        ...optionsInfoTemp,
        optionPrices:
          optionsInfoTemp?.optionPrices?.map((firstOption) => {
            if (Array.isArray(firstOption)) {
              return firstOption?.map((firstOptionTemp) => {
                return {
                  ...firstOptionTemp,
                  optionMetaDataInfoList: optionMetaDataInfoListResult?.map(
                    (metaData) => {
                      return {
                        ...metaData,
                        contents: firstOptionTemp?.optionMetaDataInfoList?.find(
                          (metaDataTemp) => {
                            return metaData?.title === metaDataTemp?.title
                          },
                        )?.contents,
                        contentsUnit:
                          firstOptionTemp?.optionMetaDataInfoList?.find(
                            (metaDataTemp) => {
                              return metaData?.title === metaDataTemp?.title
                            },
                          )?.contentsUnit,
                        contentsUnitExtra:
                          firstOptionTemp?.optionMetaDataInfoList?.find(
                            (metaDataTemp) => {
                              return metaData?.title === metaDataTemp?.title
                            },
                          )?.contentsUnitExtra,
                        basicOption:
                          firstOptionTemp?.optionMetaDataInfoList?.find(
                            (metaDataTemp) => {
                              return metaData?.title === metaDataTemp?.title
                            },
                          )?.basicOption,
                        basicOptionExtra:
                          firstOptionTemp?.optionMetaDataInfoList?.find(
                            (metaDataTemp) => {
                              return metaData?.title === metaDataTemp?.title
                            },
                          )?.basicOptionExtra,
                      }
                    },
                  ),
                }
              })
            }
            return {
              ...firstOption,
              optionMetaDataInfoList: optionMetaDataInfoListResult?.map(
                (metaData) => {
                  return {
                    ...metaData,
                    contents: firstOption?.optionMetaDataInfoList?.find(
                      (metaDataTemp) => {
                        return metaDataTemp?.title === metaData?.title
                      },
                    )?.contents,
                    contentsUnit: firstOption?.optionMetaDataInfoList?.find(
                      (metaDataTemp) => {
                        return metaData?.title === metaDataTemp?.title
                      },
                    )?.contentsUnit,
                    contentsUnitExtra:
                      firstOption?.optionMetaDataInfoList?.find(
                        (metaDataTemp) => {
                          return metaData?.title === metaDataTemp?.title
                        },
                      )?.contentsUnitExtra,
                    basicOption: firstOption?.optionMetaDataInfoList?.find(
                      (metaDataTemp) => {
                        return metaData?.title === metaDataTemp?.title
                      },
                    )?.basicOption,
                    basicOptionExtra: firstOption?.optionMetaDataInfoList?.find(
                      (metaDataTemp) => {
                        return metaData?.title === metaDataTemp?.title
                      },
                    )?.basicOptionExtra,
                  }
                },
              ),
            }
          }) || [],
      })

      setProductMetaDataList(
        result?.data?.productMetaDataInfoList?.map((metaData) => {
          return {
            ...metaData,
            contents: originalProductMetaDataListTemp?.find((metaDataTemp) => {
              return metaDataTemp?.title === metaData?.title
            })?.contents,
            contentsUnit: originalProductMetaDataListTemp?.find(
              (metaDataTemp) => {
                return metaDataTemp?.title === metaData?.title
              },
            )?.contentsUnit,
            contentsUnitExtra: originalProductMetaDataListTemp?.find(
              (metaDataTemp) => {
                return metaDataTemp?.title === metaData?.title
              },
            )?.contentsUnitExtra,
            basicOption: originalProductMetaDataListTemp?.find(
              (metaDataTemp) => {
                return metaDataTemp?.title === metaData?.title
              },
            )?.basicOption,
            basicOptionExtra: originalProductMetaDataListTemp?.find(
              (metaDataTemp) => {
                return metaDataTemp?.title === metaData?.title
              },
            )?.basicOptionExtra,
          }
        }) || [],
      )
      return result?.data?.optionMetaDataInfoList || []
    }
  }

  const loadCategoryMetaDataList = async (
    smallCategoryId,
    mediumCategoryId,
    optionsInfoTemp,
    originalProductMetaDataListTemp,
  ) => {
    const result = await backendApis.loadSmallCategoryMetaDataList(
      smallCategoryId,
    )
    if (result.status === 2000) {
      const optionMetaDataInfoListResult =
        result?.data?.optionMetaDataInfoList || []
      setOptionMetaDataList(optionMetaDataInfoListResult)
      setOptionsInfo({
        ...optionsInfoTemp,
        optionPrices:
          optionsInfoTemp?.optionPrices?.map((firstOption) => {
            if (Array.isArray(firstOption)) {
              return firstOption?.map((firstOptionTemp) => {
                return {
                  ...firstOptionTemp,
                  optionMetaDataInfoList: optionMetaDataInfoListResult?.map(
                    (metaData) => {
                      return {
                        ...metaData,
                        contents: firstOptionTemp?.optionMetaDataInfoList?.find(
                          (metaDataTemp) => {
                            return metaData?.title === metaDataTemp?.title
                          },
                        )?.contents,
                        contentsUnit:
                          firstOptionTemp?.optionMetaDataInfoList?.find(
                            (metaDataTemp) => {
                              return metaData?.title === metaDataTemp?.title
                            },
                          )?.contentsUnit,
                        contentsUnitExtra:
                          firstOptionTemp?.optionMetaDataInfoList?.find(
                            (metaDataTemp) => {
                              return metaData?.title === metaDataTemp?.title
                            },
                          )?.contentsUnitExtra,
                        basicOption:
                          firstOptionTemp?.optionMetaDataInfoList?.find(
                            (metaDataTemp) => {
                              return metaData?.title === metaDataTemp?.title
                            },
                          )?.basicOption,
                        basicOptionExtra:
                          firstOptionTemp?.optionMetaDataInfoList?.find(
                            (metaDataTemp) => {
                              return metaData?.title === metaDataTemp?.title
                            },
                          )?.basicOptionExtra,
                      }
                    },
                  ),
                }
              })
            }
            return {
              ...firstOption,
              optionMetaDataInfoList: optionMetaDataInfoListResult?.map(
                (metaData) => {
                  return {
                    ...metaData,
                    contents: firstOption?.optionMetaDataInfoList?.find(
                      (metaDataTemp) => {
                        return metaDataTemp?.title === metaData?.title
                      },
                    )?.contents,
                    contentsUnit: firstOption?.optionMetaDataInfoList?.find(
                      (metaDataTemp) => {
                        return metaDataTemp?.title === metaData?.title
                      },
                    )?.contentsUnit,
                    contentsUnitExtra:
                      firstOption?.optionMetaDataInfoList?.find(
                        (metaDataTemp) => {
                          return metaDataTemp?.title === metaData?.title
                        },
                      )?.contentsUnitExtra,
                    basicOption: firstOption?.optionMetaDataInfoList?.find(
                      (metaDataTemp) => {
                        return metaDataTemp?.title === metaData?.title
                      },
                    )?.basicOption,
                    basicOptionExtra: firstOption?.optionMetaDataInfoList?.find(
                      (metaDataTemp) => {
                        return metaDataTemp?.title === metaData?.title
                      },
                    )?.basicOptionExtra,
                  }
                },
              ),
            }
          }) || [],
      })
      setProductMetaDataList(
        result?.data?.productMetaDataInfoList?.map((metaData) => {
          return {
            ...metaData,
            contents: originalProductMetaDataListTemp?.find((metaDataTemp) => {
              return metaDataTemp?.title === metaData?.title
            })?.contents,
            contentsUnit: originalProductMetaDataListTemp?.find(
              (metaDataTemp) => {
                return metaDataTemp?.title === metaData?.title
              },
            )?.contentsUnit,
            contentsUnitExtra: originalProductMetaDataListTemp?.find(
              (metaDataTemp) => {
                return metaDataTemp?.title === metaData?.title
              },
            )?.contentsUnitExtra,

            basicOptionExtra: originalProductMetaDataListTemp?.find(
              (metaDataTemp) => {
                return metaDataTemp?.title === metaData?.title
              },
            )?.basicOptionExtra,
            basicOption: originalProductMetaDataListTemp?.find(
              (metaDataTemp) => {
                return metaDataTemp?.title === metaData?.title
              },
            )?.basicOption,
          }
        }) || [],
      )
      return result?.data?.optionMetaDataInfoList || []
    }
    const optionMetaDataInfoListResult = await loadMediumCategoryMetaDataList(
      mediumCategoryId,
      optionsInfoTemp,
      originalProductMetaDataListTemp,
    )
    return optionMetaDataInfoListResult
  }

  const loadItemDetails = async (itemId) => {
    if (ItemStore.categories.length === 0) {
      await ItemStore.loadCategories()
    }
    const result = await backendApis.loadItemDetails(itemId)
    const isSpecialDeal = await backendApis.loadSpecialDeal(itemId)
    if (result?.status === 200) {
      setOriginalCommonMetaDataList(result?.data?.commonMetaDataInfoList || [])
      setOriginalProductMetaDataList(
        result?.data?.productMetaDataInfoList || [],
      )
      if (result?.data?.categoryInfo?.minimumCategoryId) {
        const minimumCategoryInfo = await backendApis.getCategoryInfoById(
          result?.data?.categoryInfo?.minimumCategoryId,
        )
        await loadCommonMetaDataList(result?.data?.commonMetaDataInfoList || [])
        if (minimumCategoryInfo?.status === 200 && minimumCategoryInfo?.data) {
          setSelectedMinimumCategory(minimumCategoryInfo?.data)
        }
      } else {
        setCategorySelecter('prevCategorySelect')
      }

      setOptionsInfo(result?.data?.optionsInfo)
      setOriginalTeamPurchasePrice(result?.data?.teamPurchasePrice || 0)
      setOriginalOptionsInfo(result?.data?.optionsInfo)
      setRecommendItemCode(result?.data?.recommendItemCode)

      ItemStore.setItemInfoOnEdit(result.data)
      const largeCategoryInfoTemp = ItemStore.categories.find(
        (category) =>
          category._id === result.data?.categoryInfo?.largeCategoryId,
      )

      if (largeCategoryInfoTemp) {
        setLargeCategoryInfo(largeCategoryInfoTemp)
        const mediumCategoryInfoTemp =
          largeCategoryInfoTemp.mediumCategories.find(
            (category) =>
              category._id === result.data?.categoryInfo?.mediumCategoryId,
          )

        if (mediumCategoryInfoTemp) {
          setMediumCategoryInfo(mediumCategoryInfoTemp)
          const smallCategoryInfoTemp =
            mediumCategoryInfoTemp.smallCategories.find(
              (category) =>
                category._id === result.data?.categoryInfo?.smallCategoryId,
            )
          if (smallCategoryInfoTemp) {
            setSmallCategoryInfo(smallCategoryInfoTemp)

            await loadCommonMetaDataList(
              result?.data?.commonMetaDataInfoList || [],
            )
            await loadCategoryMetaDataList(
              result.data.categoryInfo.smallCategoryId,
              mediumCategoryInfoTemp?._id,
              result?.data?.optionsInfo,
              result?.data?.productMetaDataInfoList || [],
            )
          }
        }
      }

      setShippingInfo(result.data.shippingInfo)
      setJejuFee(
        parseInt(
          result?.data?.shippingInfo?.ruralAreaShippingFeeInfo?.[34]
            ?.shippingFee,
          10,
        ) || 0,
      )
      setNonJejuFee(
        parseInt(
          result?.data?.shippingInfo?.ruralAreaShippingFeeInfo?.[0]
            ?.shippingFee,
          10,
        ) || 0,
      )

      setMainImagesData(
        result.data.mainImageUris
          .map((imageUri) => ({
            sourceUri: imageUri,
            isUploaded: true,
          }))
          .slice(0, 1),
      )

      setMainAdditionalImagesData(
        result.data.mainImageUris
          .map((imageUri) => ({
            sourceUri: imageUri,
            isUploaded: true,
          }))
          .slice(1),
      )

      setDetailImagesData(
        result.data.detailImageUris.map((imageUri) => ({
          sourceUri: imageUri,
          isUploaded: true,
        })),
      )
      setCoupangRocketLink(result.data?.coupangRocketLink || '')
      setNaverLowestLink(result?.data?.naverLowestLink || '')
      setDetailHTML(result.data?.detailHTML || '')

      // 타임딜 시간정보 체크
      if (isSpecialDeal?.data?.length > 0) {
        setSpecialDealStatus(true)
      }
    }
  }

  useEffect(() => {
    if (data?.itemInfo?._id) {
      loadItemDetails(data.itemInfo._id)
    }
  }, [])

  // get category info
  useEffect(async () => {
    const initialCategories = await backendApis.getLargeCategoryInfos()
    if (
      initialCategories?.status === 200 &&
      initialCategories?.data?.length > 0
    ) {
      setParentsOfMinimumCategory([
        { init: true, children: initialCategories?.data },
      ])
    }
  }, [])

  // category search
  useEffect(() => {
    let timer

    const delayedCategorySearch = async () => {
      const categorySearchResults =
        await backendApis.getItemCategoryInfoByQuery(categorySearchQuery)
      if (categorySearchResults?.status === 200) {
        setCategorySearchResultArray(categorySearchResults?.data || [])
      }
    }

    if (categorySearchQuery) {
      timer = setTimeout(delayedCategorySearch, 400)
    }

    return () => clearTimeout(timer)
  }, [categorySearchQuery])

  // option 정보 가져오기
  useEffect(() => {
    if (Object.keys(optionIndices).length === optionsInfo.totalOptions.length) {
      let selectedPriceOption = JSON.parse(
        JSON.stringify(optionsInfo?.optionPrices),
      )
      Object.values(optionIndices).forEach((optionIndex, indexTemp) => {
        if (selectedPriceOption[optionIndex]) {
          selectedPriceOption = selectedPriceOption[optionIndex]
        } else {
          selectedPriceOption = selectedPriceOption[0]
          const optionIndicesTemp = { ...optionIndices }
          optionIndicesTemp[indexTemp] = 0
          setOptionIndices(optionIndicesTemp)
        }
      })
      if (selectedPriceOption?.sold_out) {
        setDisplayedPrice(0)
        setOptionIndices({})
      }
      setDisplayedPrice(selectedPriceOption.teamPurchasePrice)
    } else {
      let optionPrices = [...optionsInfo.optionPrices]
      let finalOptionPrices = [...optionsInfo.optionPrices]
      let testOption = optionPrices[0]
      while (testOption.length) {
        finalOptionPrices = []
        optionPrices.forEach((option) => {
          finalOptionPrices = finalOptionPrices.concat(option)
        })
        optionPrices = [...finalOptionPrices]
        testOption = finalOptionPrices[0]
      }
      if (finalOptionPrices.filter((option) => !option.sold_out).length > 0) {
        setDisplayedPrice(
          Math.min(
            ...finalOptionPrices
              .filter((option) => !option.sold_out)
              .map((option) => option.teamPurchasePrice),
          ),
        )
      }
    }
  }, [optionIndices, optionsInfo])
  const keyWords = ItemStore?.itemInfoOnEdit?.textForQuery
    ? ItemStore.itemInfoOnEdit.textForQuery
        .split(',')
        .map((word) => word.trim())
    : []

  return (
    <div className='max-w-7xl'>
      <Page title={`${SellerStore.sellerInfo.sellerDisplayName} 아이템 수정`}>
        <Card title='카테고리'>
          <div className='flex items-center mb-5'>
            <SubTitle>카테고리를 추가해 주세요</SubTitle>
            <TutorialModal modalTitle='카테고리 가이드' videoSource={Tutorial1}>
              <AlwayzQuestion />
            </TutorialModal>
          </div>
          <div className='mb-6'>
            <CategorySelectHandler />
          </div>

          {categorySelecter === 'categorySearch' && (
            <div className='flex flex-col'>
              <div className='relative w-full'>
                <Input
                  className='w-full select-none'
                  type='text'
                  placeholder='카테고리명을 입력해 주세요.'
                  value={categorySearchQuery}
                  onFocus={() => {
                    setIsFocusOnCategorySearch(true)
                  }}
                  onBlur={(e) => {
                    if (e.relatedTarget?.id === 'category-results') {
                      return
                    }
                    setIsFocusOnCategorySearch(false)
                  }}
                  onChange={(e) => {
                    setCategorySearchQuery(e.target.value)
                  }}
                />
                {isFocusOnCategorySearch && (
                  <div className='absolute w-full overflow-y-scroll max-h-[200px] bg-white border rounded-sm'>
                    {categorySearchResultArray.length === 0
                      ? [
                          {
                            text: ` 카테고리가 없습니다.`,
                            style: 'bg-gray-100',
                            showQuery: true,
                          },
                          {
                            text: '- 단어의 철자가 정확한지 확인해보세요.',
                          },
                          {
                            text: '- 한글을 영어로 혹은 영어를 한글로 입력했는지 확인해보세요.',
                          },
                          {
                            text: "- '카테고리 선택'으로 변경해서 정확한 카테고리를 찾아보세요.",
                          },
                        ].map((el) => {
                          return (
                            <li
                              className={`py-2 text-sm text-left list-none w-full ${
                                el?.style || ''
                              }`}
                            >
                              <div className='w-full px-2 text-left'>
                                {el?.showQuery && (
                                  <span className='font-semibold text-blue-700'>
                                    {categorySearchQuery}
                                  </span>
                                )}
                                {el.text}
                              </div>
                            </li>
                          )
                        })
                      : categorySearchResultArray.map((el) => {
                          return (
                            <li className='w-full text-sm text-left list-none hover:bg-gray-100'>
                              <button
                                id='category-results'
                                type='button'
                                className='w-full px-2 py-2 text-left'
                                onClick={async () => {
                                  setIsFocusOnCategorySearch(false)
                                  setCategorySearchResultArray([])
                                  ItemStore.setItemInfoOnEdit({
                                    ...ItemStore.itemInfoOnEdit,
                                    categoryInfo: {
                                      ...ItemStore.itemInfoOnEdit.categoryInfo,
                                      minimumCategoryId: parseInt(el?._id, 10),
                                    },
                                  })
                                  if (el?.endNode) {
                                    setSelectedMinimumCategory(el)
                                    await loadCommonMetaDataList(
                                      originalCommonMetaDataList,
                                    )
                                  }

                                  if (!el?.endNode) {
                                    setSelectedMinimumCategory(null)
                                    // end node가 아닌 경우에 카테고리 검색으로 넘어감
                                    setCategorySelecter('categorySelect')
                                    const parentsData =
                                      await backendApis.getCategoryInfosByIds(
                                        el?.parents?.map((item) => item?._id),
                                      )
                                    if (
                                      parentsData?.status === 200 &&
                                      parentsData?.data
                                    ) {
                                      const tmpParentsOfMinimumCategory =
                                        parentsOfMinimumCategory.slice(0, 1)
                                      setParentsOfMinimumCategory([
                                        ...tmpParentsOfMinimumCategory,
                                        ...parentsData?.data,
                                        el,
                                      ])
                                    }
                                  }
                                }}
                              >
                                {el.displayName}
                              </button>
                            </li>
                          )
                        })}
                  </div>
                )}
              </div>
              {selectedMinimumCategory?.endNode && (
                <div className='mt-4 text-sm text-blue-700'>
                  선택한 카테고리 :
                  <span className='ml-2 mr-4 font-bold'>
                    {selectedMinimumCategory.displayName}
                  </span>
                  <span>
                    <button
                      type='button'
                      className='px-2 py-1 text-sm text-gray-800 border rounded-md'
                      onClick={() => {
                        setSelectedMinimumCategory(null)
                        ItemStore.setItemInfoOnEdit({
                          ...ItemStore.itemInfoOnEdit,
                          categoryInfo: {
                            ...ItemStore.itemInfoOnEdit.categoryInfo,
                            minimumCategoryId: null,
                          },
                        })
                        setCategorySearchQuery('')
                      }}
                    >
                      재선택
                    </button>
                  </span>
                </div>
              )}
              <div className='mt-6 text-sm'>
                상품과 맞지 않는 카테고리에 등록할 경우, 적정 카테고리로 이동될
                수 있습니다.
              </div>
            </div>
          )}
          {categorySelecter === 'categorySelect' && (
            <div>
              <div className='flex flex-row overflow-x-scroll'>
                {parentsOfMinimumCategory
                  ?.filter((item) => item?.endNode !== true)
                  .map((e1, e1Idx) => {
                    return (
                      <div className='h-[360px] w-[180px] overflow-y-scroll border'>
                        <ul className='text-sm'>
                          {e1.children.map((e2, e2Idx) => {
                            return (
                              <li className=''>
                                <button
                                  type='button'
                                  className='flex flex-row flex-1 w-full items-center justify-between py-[6px] px-2'
                                  onClick={async () => {
                                    const parent =
                                      await backendApis.getCategoryInfoById(
                                        e2?.categoryId || e2?._id,
                                      )

                                    if (
                                      parent?.status === 200 &&
                                      parent?.data
                                    ) {
                                      const tmpParentsOfMinimumCategory =
                                        parentsOfMinimumCategory.slice(
                                          0,
                                          (parent?.data?.depth || 0) + 1,
                                        )
                                      setParentsOfMinimumCategory([
                                        ...tmpParentsOfMinimumCategory,
                                        parent?.data,
                                      ])
                                      if (parent?.data?.endNode) {
                                        setSelectedMinimumCategory(parent?.data)
                                        await loadCommonMetaDataList(
                                          originalCommonMetaDataList,
                                        )
                                        ItemStore.setItemInfoOnEdit({
                                          ...ItemStore.itemInfoOnEdit,
                                          categoryInfo: {
                                            ...ItemStore.itemInfoOnEdit
                                              .categoryInfo,
                                            minimumCategoryId: parseInt(
                                              parent?.data?._id,
                                              10,
                                            ),
                                          },
                                        })
                                      } else {
                                        setSelectedMinimumCategory(null)
                                        ItemStore.setItemInfoOnEdit({
                                          ...ItemStore.itemInfoOnEdit,
                                          categoryInfo: {
                                            ...ItemStore.itemInfoOnEdit
                                              .categoryInfo,
                                            minimumCategoryId: null,
                                          },
                                        })
                                      }
                                    }
                                  }}
                                >
                                  <div
                                    className={`truncate w-[140px] text-left ${
                                      parentsOfMinimumCategory?.[e1Idx + 1]
                                        ?._id === e2?._id && 'text-blue-700'
                                    }`}
                                  >
                                    {e2.name}
                                  </div>
                                  {!e2?.endNode && <RightArrowComponent />}
                                </button>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                  })}
              </div>
              {selectedMinimumCategory?.endNode && (
                <div className='mt-4 text-sm text-blue-700'>
                  선택한 카테고리 :
                  <span className='ml-2 mr-4 font-bold'>
                    {selectedMinimumCategory.displayName}
                  </span>
                  <span>
                    <button
                      type='button'
                      className='px-2 py-1 text-sm text-gray-800 border rounded-md'
                      onClick={() => {
                        ItemStore.setItemInfoOnEdit({
                          ...ItemStore.itemInfoOnEdit,
                          categoryInfo: {
                            ...ItemStore.itemInfoOnEdit.categoryInfo,
                            minimumCategoryId: null,
                          },
                        })
                        setSelectedMinimumCategory(null)
                        setCategorySearchQuery('')
                      }}
                    >
                      재선택
                    </button>
                  </span>
                </div>
              )}
              <div className='mt-6 text-sm'>
                상품과 맞지 않는 카테고리에 등록할 경우, 적정 카테고리로 이동될
                수 있습니다.
              </div>
            </div>
          )}
          {categorySelecter === 'prevCategorySelect' && (
            <div className='flex-col flex-1'>
              <div className='flex-row flex-1 w-full'>
                <div className='flex-row'>
                  <Description className='mt-5'>
                    (기존) 카테고리 선택
                  </Description>
                  <div>
                    <select
                      id='large_category'
                      name='large_category'
                      value={largeCategoryInfo._id}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setLargeCategoryInfo({})
                          setMediumCategoryInfo({})
                          setSmallCategoryInfo({})
                          ItemStore.setItemInfoOnEdit({
                            ...ItemStore.itemInfoOnEdit,
                            categoryInfo: {},
                          })
                          return
                        }
                        ItemStore.setItemInfoOnEdit({
                          ...ItemStore.itemInfoOnEdit,
                          categoryInfo: {
                            largeCategoryId: parseInt(e.target.value, 10),
                          },
                        })
                        const largeCategoryInfoTemp = ItemStore.categories.find(
                          (category) =>
                            category._id === parseInt(e.target.value, 10),
                        )
                        if (largeCategoryInfoTemp) {
                          setLargeCategoryInfo(largeCategoryInfoTemp)
                          setMediumCategoryInfo({})
                          setSmallCategoryInfo({})
                        }
                      }}
                    >
                      <option value=''>===</option>
                      {ItemStore.categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.largeCategoryNames.kr}
                        </option>
                      ))}
                    </select>
                    <select
                      id='medium_category'
                      name='medium_category'
                      value={mediumCategoryInfo._id}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setMediumCategoryInfo({})
                          setSmallCategoryInfo({})
                          return
                        }
                        ItemStore.setItemInfoOnEdit({
                          ...ItemStore.itemInfoOnEdit,
                          categoryInfo: {
                            ...ItemStore.itemInfoOnEdit.categoryInfo,
                            mediumCategoryId: parseInt(e.target.value, 10),
                          },
                        })
                        const mediumCategoryInfoTemp =
                          largeCategoryInfo.mediumCategories.find(
                            (category) =>
                              category._id === parseInt(e.target.value, 10),
                          )
                        if (mediumCategoryInfoTemp) {
                          // setLargeCategoryInfo(largeCategoryInfoTemp)
                          setMediumCategoryInfo(mediumCategoryInfoTemp)
                          setSmallCategoryInfo({})
                        }
                      }}
                    >
                      <option value=''>===</option>
                      {largeCategoryInfo.mediumCategories?.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.mediumCategoryNames.kr}
                        </option>
                      ))}
                    </select>
                    <select
                      id='small_category'
                      name='small_category'
                      value={smallCategoryInfo._id}
                      onChange={async (e) => {
                        if (e.target.value === '') {
                          setSmallCategoryInfo({})
                          return
                        }
                        ItemStore.setItemInfoOnEdit({
                          ...ItemStore.itemInfoOnEdit,
                          categoryInfo: {
                            ...ItemStore.itemInfoOnEdit.categoryInfo,
                            smallCategoryId: parseInt(e.target.value, 10),
                          },
                        })
                        const smallCategoryInfoTemp =
                          mediumCategoryInfo.smallCategories.find(
                            (category) =>
                              category._id === parseInt(e.target.value, 10),
                          )
                        if (smallCategoryInfoTemp) {
                          // setLargeCategoryInfo(largeCategoryInfoTemp)
                          setSmallCategoryInfo(smallCategoryInfoTemp)
                          await loadCommonMetaDataList(
                            originalCommonMetaDataList,
                          )
                          await loadCategoryMetaDataList(
                            parseInt(e.target.value, 10),
                            mediumCategoryInfo?._id,
                            optionsInfo,
                            originalProductMetaDataList,
                          )
                        }
                      }}
                    >
                      <option value=''>===</option>
                      {mediumCategoryInfo.smallCategories?.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.smallCategoryNames.kr}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='content-center mt-5'>
                  <span className='mb-2 text-base'>카테고리 검색</span>
                  <div className='flex items-center mb-10'>
                    <Input
                      type='text'
                      value={categorySearch}
                      onChange={(e) => {
                        console.log(`text: ${e.target.value}`)
                        setCategorySearch(e.target.value)
                      }}
                    />
                    <Button
                      className='ml-2'
                      appearance='neutralSub'
                      type='button'
                      onClick={async () => {
                        if (!categorySearch) {
                          alert(`카테고리를 입력해주세요.`)
                        } else {
                          SellerStore.setIsLoading(true)
                          const result1 = ItemStore.categories.filter(
                            (largeCategory) => {
                              return largeCategory.largeCategoryNames.kr.includes(
                                categorySearch,
                              )
                            },
                          )
                          const result2 = ItemStore.categories
                            .map((largeCategory) => {
                              const validMediumCategories =
                                largeCategory.mediumCategories.filter(
                                  (mediumCategory) => {
                                    return mediumCategory.mediumCategoryNames.kr.includes(
                                      categorySearch,
                                    )
                                  },
                                )
                              return {
                                ...largeCategory,
                                mediumCategories: validMediumCategories,
                              }
                            })
                            .filter((largeCategory) => {
                              return largeCategory.mediumCategories.length > 0
                            })

                          const result3 = ItemStore.categories
                            .map((largeCategory) => {
                              const validMediumCategories =
                                largeCategory.mediumCategories
                                  .map((mediumCategories) => {
                                    const validSmallCategories =
                                      mediumCategories.smallCategories.filter(
                                        (smallCategory) => {
                                          return smallCategory.smallCategoryNames.kr.includes(
                                            categorySearch,
                                          )
                                        },
                                      )
                                    return {
                                      ...mediumCategories,
                                      smallCategories: validSmallCategories,
                                    }
                                  })
                                  .filter((mediumCategories) => {
                                    return (
                                      mediumCategories.smallCategories.length >
                                      0
                                    )
                                  })
                              return {
                                ...largeCategory,
                                mediumCategories: validMediumCategories,
                              }
                            })
                            .filter((largeCategory) => {
                              return largeCategory.mediumCategories.length > 0
                            })
                          SellerStore.setIsLoading(false)

                          ItemStore.setSearchedLargeCategories(result1)
                          ItemStore.setSearchedMediumCategories(result2)
                          ItemStore.setSearchedSmallCategories(result3)
                        }
                      }}
                    >
                      검색
                    </Button>
                  </div>
                </div>
              </div>
              <table>
                <thead style={{ fontSize: 15 }}>
                  <tr>
                    <th>카테고리</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: 15 }}>
                  {ItemStore.searchedLargeCategories.map(
                    (categoryResult, index) => (
                      <tr key={categoryResult?._id + index.toString()}>
                        <td>{JSON.stringify(categoryResult)}</td>
                        <td>{categoryResult?.largeCategoryNames.kr} ></td>
                      </tr>
                    ),
                  )}

                  {ItemStore.searchedMediumCategories.map(
                    (categoryResult, index) => {
                      return categoryResult?.mediumCategories.map(
                        (mediumCategories) => {
                          return (
                            <tr key={mediumCategories?._id + index.toString()}>
                              <td>
                                {categoryResult?.largeCategoryNames.kr} >{' '}
                                {mediumCategories?.mediumCategoryNames.kr}
                              </td>
                            </tr>
                          )
                        },
                      )
                    },
                  )}
                  {ItemStore.searchedSmallCategories.map(
                    (categoryResult, index) => {
                      return categoryResult.mediumCategories.map(
                        (mediumCategories) => {
                          return mediumCategories.smallCategories.map(
                            (smallCategories) => {
                              return (
                                <tr
                                  key={smallCategories?._id + index.toString()}
                                >
                                  <td>
                                    {categoryResult?.largeCategoryNames.kr} >{' '}
                                    {mediumCategories?.mediumCategoryNames.kr} >{' '}
                                    {smallCategories?.smallCategoryNames.kr}
                                  </td>
                                </tr>
                              )
                            },
                          )
                        },
                      )
                    },
                  )}
                </tbody>
              </table>
            </div>
          )}
        </Card>
        {/* 상품 주요 정보 */}
        <Card title='상품명'>
          <div className='flex items-center mb-5'>
            <SubTitle>상품명을 입력해 주세요</SubTitle>
            <TutorialModal modalTitle='상품명 가이드' videoSource={Tutorial2}>
              <AlwayzQuestion />
            </TutorialModal>
          </div>
          <Input
            id='register-productName'
            className='w-1/2'
            type='text'
            placeholder='예시)다모농장 해남 햇 꿀고구마 3kg 5kg 특상 한입'
            value={ItemStore.itemInfoOnEdit.itemTitle || ''}
            onChange={(e) => {
              ItemStore.setItemInfoOnEdit({
                ...ItemStore.itemInfoOnEdit,
                itemTitle: Format.Title(e.target.value.normalize('NFC')),
              })
            }}
          />
        </Card>
        {/* 상품 옵션 */}
        <Card title='상품 옵션'>
          <SubTitle className='mb-5'>상품 옵션을 추가해 주세요</SubTitle>
          <Button
            className='mb-3'
            appearance='neutralSub'
            type='button'
            disabled={specialDealStatus === true}
            onClick={() => {
              Log.event('AddOptionButton', 'AddOptionButton', {
                click: 'AddOptionButton1',
              })
              if (optionsInfo.optionNames.length === 2) {
                return
              }
              const optionNames = [...optionsInfo.optionNames]
              const totalOptions = [...optionsInfo.totalOptions]
              optionNames.push('')
              totalOptions.push([
                {
                  name: '',
                  img: null,
                },
              ])
              let optionPrices = [
                {
                  individualPurchasePrice: 0,
                  teamPurchasePrice: 0,
                  aiPrice: 0,
                  stockNumber: 100,
                  sold_out: false,
                  hurry_up: false,
                  optionMetaDataInfoList: optionMetaDataList,
                },
              ]

              if (optionNames.length === 2) {
                optionPrices = optionsInfo.optionPrices.map(
                  (optionPriceInfo) => [optionPriceInfo],
                )
              }
              setOptionsInfo({
                ...optionsInfo,
                optionNames,
                totalOptions,
                optionPrices,
              })
            }}
          >
            옵션 추가하기
          </Button>
          <div
            className='mb-10'
            style={{ flexDirection: 'row', display: 'flex' }}
          >
            <div style={{ width: '40%' }}>
              {optionsInfo.optionNames.map((optionName, index) => (
                <div
                  className='flex flex-col flex-1 px-5 py-5 mb-10 border-2'
                  key={index?.toString()}
                >
                  <div className='flex items-center '>
                    <div>옵션 명 (ex: 중량, 색상, 구성 등)</div>
                    <TutorialModal
                      modalTitle='상품 옵션'
                      videoSource={Tutorial3}
                    >
                      <AlwayzQuestion />
                    </TutorialModal>
                  </div>
                  <div className='flex'>
                    <Input
                      className='flex w-full my-2 '
                      type='text'
                      placeholder={`${index + 1}번째 옵션명을 입력해주세요.`}
                      value={optionName}
                      onChange={(e) => {
                        setOptionsInfo({
                          ...optionsInfo,
                          optionNames: optionsInfo.optionNames.map(
                            (optionNameTemp, indexTemp) => {
                              if (index === indexTemp) {
                                return Format.Title(
                                  e.target.value.normalize('NFC'),
                                )
                              }
                              return optionNameTemp
                            },
                          ),
                        })
                      }}
                    />
                  </div>
                  <div style={{ height: 10 }} />
                  <div className='flex flex-col flex-1'>
                    세부옵션 (ex: 3kg)
                    {optionsInfo.totalOptions[index].map(
                      (option, detailIndex) => (
                        <div key={detailIndex?.toString()}>
                          <Input
                            className='flex w-full my-2'
                            type='text'
                            placeholder={`${
                              detailIndex + 1
                            }번째 세부옵션을 입력해주세요.`}
                            value={option.name}
                            onChange={(e) => {
                              setOptionsInfo({
                                ...optionsInfo,
                                totalOptions: optionsInfo.totalOptions.map(
                                  (totalOption, indexTemp) => {
                                    if (index === indexTemp) {
                                      return totalOption.map(
                                        (detailedOption, detailIndexTemp) => {
                                          if (detailIndex === detailIndexTemp) {
                                            return {
                                              ...detailedOption,
                                              name: Format.Title(
                                                e.target.value.normalize('NFC'),
                                              ),
                                            }
                                          }
                                          return detailedOption
                                        },
                                      )
                                    }
                                    return totalOption
                                  },
                                ),
                              })
                            }}
                          />
                        </div>
                      ),
                    )}
                    <Button
                      type='button'
                      appearance='neutralSub'
                      onClick={() => {
                        const optionPrices = JSON.parse(
                          JSON.stringify(optionsInfo.optionPrices),
                        )

                        const createNewOptionPrice = (originalOptionPrice) => {
                          const {
                            isItemWinner,
                            isItemWinnerApproved,
                            itemWinnerId,
                            itemWinnerPrice,
                            rejectCount,
                            reoffer,
                            ...rest
                          } = originalOptionPrice
                          return {
                            ...rest,
                            optionMetaDataInfoList: optionMetaDataList,
                          }
                        }

                        if (index === 0) {
                          if (Array.isArray(optionPrices[0])) {
                            optionPrices.push(
                              optionPrices[0]?.map((optionPricesTemp) =>
                                createNewOptionPrice(optionPricesTemp),
                              ),
                            )
                          } else
                            optionPrices.push(
                              createNewOptionPrice(optionPrices[0]),
                            )
                        } else if (index === 1) {
                          optionPrices.forEach((firstOption) => {
                            firstOption.push(
                              createNewOptionPrice(firstOption[0]),
                            )
                          })
                        }
                        const detailedOptions = [
                          ...optionsInfo.totalOptions[index],
                        ]
                        detailedOptions.push({
                          name: '',
                          img: null,
                        })
                        setOptionsInfo({
                          ...optionsInfo,
                          totalOptions: optionsInfo.totalOptions.map(
                            (totalOption, indexTemp) => {
                              if (index === indexTemp) {
                                return detailedOptions
                              }
                              return totalOption
                            },
                          ),
                          optionPrices,
                        })
                      }}
                    >
                      세부옵션 추가하기
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'center',
                paddingBottom: 20,
              }}
            >
              <div className='mb-2 text-xl font-bold'>
                팀구매 결제 옵션 미리보기
              </div>
              <div
                style={{
                  width: '50%',
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                  border: '10px solid #999999',
                  paddingTop: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 30,
                }}
              >
                <div
                  style={{
                    height: 56,

                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    // paddingBottom: 12,
                  }}
                >
                  <div style={{ fontSize: 20, color: '#EA3B49' }}>
                    {commaNumber(displayedPrice)}원
                    <div
                      style={{
                        fontSize: 14,
                        color: '#999999',
                        marginTop: 4,
                      }}
                    >
                      팀구매 인원이 모이면 배송이 시작됩니다.
                    </div>
                  </div>
                </div>
                <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
                <div style={{ position: 'relative' }}>
                  {optionsInfo.optionNames?.map((value, optionLargeIndex) => {
                    return (
                      <div
                        key={value.toString() + optionLargeIndex.toString()}
                        style={{ paddingTop: 12, paddingBottom: 12 }}
                      >
                        <div
                          style={{
                            paddingBottom: 6,
                            fontSize: 14,
                            color: value ? 'black' : 'red',
                          }}
                        >
                          {value ||
                            `${optionLargeIndex + 1}번째 옵션명을 입력해주세요`}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                          }}
                        >
                          {optionsInfo?.totalOptions[optionLargeIndex].map(
                            (value, index) => {
                              return (
                                <SelectOptionButton
                                  key={value.toString() + index.toString()}
                                  item={value}
                                  checked={
                                    optionIndices[optionLargeIndex] === index
                                  }
                                  optionLargeIndex={optionLargeIndex}
                                  index={index}
                                  correspondingOptionsLength={
                                    optionsInfo?.totalOptions[optionLargeIndex]
                                      ?.length
                                  }
                                  optionsInfo={optionsInfo}
                                  optionIndices={optionIndices}
                                  setOptionIndices={setOptionIndices}
                                />
                              )
                            },
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className='flex items-center mb-5'>
            <SubTitle>상세 옵션별 가격 및 재고량 설정</SubTitle>
            <TutorialModal modalTitle='개인 /팀구매가' videoSource={Tutorial4}>
              <AlwayzQuestion />
            </TutorialModal>
          </div>
          {data?.itemInfo._id && (
            <div>
              <OptionChangeHistoryModalButton
                title='상세 옵션 변경 이력 보기'
                itemId={data?.itemInfo._id}
              />
              <div className='mb-3' />
            </div>
          )}
          {specialDealStatus && (
            <div className='text-base font-bold'>
              본 상품은 현재 순삭특가 후보이거나, 특가 진행 예정이므로 <br />
              <span className='text-lg text-red-600'>
                옵션을 변경하실 수 없습니다.
              </span>{' '}
              꼭 변경해야할 경우 왼쪽 ['노출 관리' > '순삭특가 신청']에서
              순삭특가 신청을 취소하시거나, 판매자 지원센터로 문의하여 주십시오.{' '}
              <br />
              *재고량은 특가 후보 여부와 상관없이 변경 가능합니다.
            </div>
          )}
          {AuthStore.isMasterAccount && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ paddingBottom: 20 }} className='right-0 mt-3'>
                개인 구매가
                <input
                  className=''
                  min={0}
                  type='number'
                  style={{
                    width: '50%',
                    position: 'relative',
                    left: '5px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  }}
                  value={CommonIndividualPrice}
                  onChange={(e) => {
                    console.log(`text: ${e.target.value}`)
                    setCommonIndividualPrice(Number(e.target.value))
                  }}
                />
              </div>
              <div
                style={{
                  paddingBottom: 20,
                  position: 'relative',
                  right: '15px',
                }}
                className='mt-3'
              >
                팀 구매가
                <input
                  min={0}
                  type='number'
                  style={{
                    width: '50%',
                    position: 'relative',
                    left: '5px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  }}
                  value={CommonTeamPurchasePrice}
                  onChange={(e) => {
                    console.log(`text: ${e.target.value}`)
                    setCommonTeamPurchasePrice(Number(e.target.value))
                  }}
                />
              </div>
              <div
                style={{
                  paddingBottom: 20,
                  position: 'relative',
                  right: '40px',
                }}
                className='mt-3'
              >
                재고량
                <input
                  min={0}
                  type='number'
                  // value='orderId'
                  style={{
                    width: '50%',
                    position: 'relative',
                    left: '5px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  }}
                  value={CommonStockNumber}
                  onChange={(e) => {
                    console.log(`text: ${e.target.value}`)
                    setCommonStockNumber(Number(e.target.value))
                  }}
                  // placeholder='입력해주세요'
                />
              </div>

              <button
                className='px-2 py-2 my-1 font-bold text-white rounded-lg bg-theme-500 hover:bg-sub-500'
                type='button'
                style={{
                  position: 'relative',
                  top: '-12px',
                  right: '70px',
                }}
                onClick={() => {
                  setOptionsInfo({
                    ...optionsInfo,
                    optionPrices: optionsInfo?.optionPrices.map(
                      (firstOption) => {
                        if (Array.isArray(firstOption)) {
                          // 2단 옵션
                          return firstOption?.map((secondOption) => {
                            if (secondOption?.isChecked) {
                              return {
                                ...secondOption,
                                individualPurchasePrice: CommonIndividualPrice,
                                teamPurchasePrice: CommonTeamPurchasePrice,
                                stockNumber: CommonStockNumber,
                                hurry_up: CommonStockNumber < 10,
                                sold_out: CommonStockNumber === 0,
                              }
                            }
                            return secondOption
                          })
                        }
                        // 1단 옵션
                        if (firstOption?.isChecked) {
                          return {
                            ...firstOption,
                            individualPurchasePrice: CommonIndividualPrice,
                            teamPurchasePrice: CommonTeamPurchasePrice,
                            stockNumber: CommonStockNumber,
                            hurry_up: CommonStockNumber < 10,
                            sold_out: CommonStockNumber === 0,
                          }
                        }
                        return firstOption
                      },
                    ),
                  })
                }}
              >
                선택 목록 일괄수정
              </button>
            </div>
          )}
          {optionsInfo.optionNames.length > 0 && (
            <div style={{ overflowX: 'scroll' }}>
              <table>
                <thead className='text-base'>
                  <tr>
                    <th>
                      {optionsInfo.optionNames.length === 1
                        ? optionsInfo.optionNames[0] || '옵션 1'
                        : `${optionsInfo.optionNames[1] || '옵션 2'} / ${
                            optionsInfo.optionNames[0] || '옵션 1'
                          }`}
                    </th>
                    {optionsInfo.totalOptions[0].map((option, index) => (
                      <th key={option.name + index.toString()}>
                        {option.name || `옵션 1_세부 옵션 ${index + 1}`}
                        {index !== 0 && (
                          <button
                            className='px-2 py-1 my-2 ml-2 font-bold text-white rounded-lg bg-theme-500 hover:bg-sub-500'
                            type='button'
                            disabled={specialDealStatus === true}
                            onClick={() => {
                              Log.event(
                                'DeleteOptionButton',
                                'DeleteOptionButton',
                                {
                                  click: 'DeleteOptionButton1',
                                },
                              )
                              if (optionsInfo.totalOptions[0].length !== 1) {
                                setOptionsInfo({
                                  ...optionsInfo,
                                  totalOptions: optionsInfo.totalOptions.map(
                                    (totalOption, indexTemp) => {
                                      if (indexTemp === 0) {
                                        return totalOption.filter(
                                          (detailedOption, indexTemp2) =>
                                            indexTemp2 !== index,
                                        )
                                      }
                                      return totalOption
                                    },
                                  ),
                                  optionPrices: optionsInfo.optionPrices.filter(
                                    (optionPrice, indexTemp2) =>
                                      indexTemp2 !== index,
                                  ),
                                })
                              }
                            }}
                          >
                            삭제
                          </button>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className='text-base '>
                  {optionsInfo.optionNames.length === 1 ? (
                    <tr>
                      <td>세부 정보</td>
                      {optionsInfo.totalOptions[0].map(
                        (firstOption, firstOptionIndex) => (
                          <td
                            key={
                              firstOption?.name + firstOptionIndex.toString()
                            }
                          >
                            {AuthStore.isMasterAccount && (
                              <div>
                                일괄 변경 옵션 선택
                                <input
                                  type='checkbox'
                                  onChange={(e) => {
                                    console.log(e.target.checked)
                                    setOptionsInfo({
                                      ...optionsInfo,
                                      optionPrices:
                                        optionsInfo.optionPrices.map(
                                          (
                                            firstOptionTemp,
                                            firstOptionIndexTemp,
                                          ) => {
                                            if (
                                              firstOptionIndex ===
                                              firstOptionIndexTemp
                                            ) {
                                              return {
                                                ...firstOptionTemp,
                                                isChecked: e.target.checked,
                                              }
                                            }
                                            return firstOptionTemp
                                          },
                                        ),
                                    })
                                  }}
                                  checked={
                                    optionsInfo.optionPrices[firstOptionIndex]
                                      ?.isChecked
                                  }
                                />
                              </div>
                            )}

                            <div className='flex flex-row items-center justify-between mb-2'>
                              <div className='flex flex-col items-start'>
                                <div>개인 구매가 (원)</div>
                                <div className='text-sm text-blue-700 text-semibold'>
                                  온라인 최저가로 설정하는 것을 권장드립니다.
                                </div>
                              </div>
                              <input
                                className='px-2 py-1'
                                type='number'
                                value={
                                  optionsInfo.optionPrices[firstOptionIndex]
                                    .individualPurchasePrice
                                }
                                onChange={(e) => {
                                  setOptionsInfo({
                                    ...optionsInfo,
                                    optionPrices: optionsInfo.optionPrices.map(
                                      (
                                        firstOptionTemp,
                                        firstOptionIndexTemp,
                                      ) => {
                                        if (
                                          firstOptionIndex ===
                                          firstOptionIndexTemp
                                        ) {
                                          return {
                                            ...firstOptionTemp,
                                            individualPurchasePrice:
                                              parseInt(e.target.value, 10) || 0,
                                          }
                                        }
                                        return firstOptionTemp
                                      },
                                    ),
                                  })
                                }}
                              />
                            </div>
                            <div className='flex flex-row items-center justify-between mb-2'>
                              팀 구매가 (원)
                              <br />
                              <input
                                className='px-2 py-1'
                                type='number'
                                value={
                                  optionsInfo.optionPrices[firstOptionIndex]
                                    .teamPurchasePrice
                                }
                                onChange={(e) => {
                                  setOptionsInfo({
                                    ...optionsInfo,
                                    optionPrices: optionsInfo.optionPrices.map(
                                      (
                                        firstOptionTemp,
                                        firstOptionIndexTemp,
                                      ) => {
                                        if (
                                          firstOptionIndex ===
                                          firstOptionIndexTemp
                                        ) {
                                          return {
                                            ...firstOptionTemp,
                                            teamPurchasePrice:
                                              parseInt(e.target.value, 10) || 0,
                                            aiPrice:
                                              parseInt(e.target.value, 10) || 0,
                                          }
                                        }
                                        return firstOptionTemp
                                      },
                                    ),
                                  })
                                }}
                              />
                            </div>

                            <div className='flex flex-row items-center justify-between mb-2'>
                              지원금
                              <br />
                              <input
                                className='px-2 py-1'
                                min={0}
                                disabled={
                                  !AuthStore.isMasterAccount
                                  // TimeDealStatus === true
                                }
                                type='number'
                                value={
                                  optionsInfo.optionPrices[firstOptionIndex]
                                    .platformSupportPrice
                                }
                                onChange={(e) => {
                                  setOptionsInfo({
                                    ...optionsInfo,
                                    optionPrices: optionsInfo.optionPrices.map(
                                      (
                                        firstOptionTemp,
                                        firstOptionIndexTemp,
                                      ) => {
                                        if (
                                          firstOptionIndex ===
                                          firstOptionIndexTemp
                                        ) {
                                          return {
                                            ...firstOptionTemp,
                                            platformSupportPrice:
                                              parseInt(e.target.value, 10) || 0,
                                          }
                                        }
                                        return firstOptionTemp
                                      },
                                    ),
                                  })
                                }}
                              />
                            </div>
                            {/* 지원금 */}

                            <div className='flex flex-row items-center justify-between mb-2'>
                              재고량
                              <br />
                              <input
                                className='px-2 py-1'
                                min={0}
                                type='number'
                                value={
                                  optionsInfo.optionPrices[firstOptionIndex]
                                    .stockNumber
                                }
                                onChange={(e) => {
                                  const stockNumber =
                                    parseInt(e.target.value, 10) || 0
                                  setOptionsInfo({
                                    ...optionsInfo,
                                    optionPrices: optionsInfo.optionPrices.map(
                                      (
                                        firstOptionTemp,
                                        firstOptionIndexTemp,
                                      ) => {
                                        if (
                                          firstOptionIndex ===
                                          firstOptionIndexTemp
                                        ) {
                                          return {
                                            ...firstOptionTemp,
                                            stockNumber,
                                            sold_out: stockNumber <= 0,
                                            hurry_up: stockNumber < 10,
                                          }
                                        }
                                        return firstOptionTemp
                                      },
                                    ),
                                  })
                                }}
                              />
                            </div>
                            <div className='flex flex-row items-center justify-between mb-2'>
                              판매자 상품코드
                              <br />
                              <input
                                className='px-2 py-1'
                                type='text'
                                value={
                                  optionsInfo.optionPrices[firstOptionIndex]
                                    ?.optionCode
                                }
                                onChange={(e) => {
                                  setOptionsInfo({
                                    ...optionsInfo,
                                    optionPrices: optionsInfo.optionPrices.map(
                                      (
                                        firstOptionTemp,
                                        firstOptionIndexTemp,
                                      ) => {
                                        if (
                                          firstOptionIndex ===
                                          firstOptionIndexTemp
                                        ) {
                                          return {
                                            ...firstOptionTemp,
                                            optionCode: e.target.value || '',
                                          }
                                        }
                                        return firstOptionTemp
                                      },
                                    ),
                                  })
                                }}
                              />
                            </div>
                          </td>
                        ),
                      )}
                    </tr>
                  ) : (
                    optionsInfo.totalOptions[1].map(
                      (secondOption, secondOptionIndex) => (
                        <tr key={secondOptionIndex?.toString()}>
                          <td>
                            {secondOption.name ||
                              `옵션 2_세부 옵션 ${secondOptionIndex + 1}`}
                            <button
                              className='px-2 py-1 ml-2 font-bold text-white rounded-lg bg-theme-500 hover:bg-sub-500'
                              type='button'
                              disabled={specialDealStatus === true}
                              onClick={() => {
                                Log.event(
                                  'DeleteOptionButton',
                                  'DeleteOptionButton',
                                  {
                                    click: 'DeleteOptionButton2',
                                  },
                                )
                                if (optionsInfo.totalOptions[1].length !== 1) {
                                  setOptionsInfo({
                                    ...optionsInfo,
                                    totalOptions: optionsInfo.totalOptions.map(
                                      (totalOption, indexTemp) => {
                                        if (indexTemp === 1) {
                                          return totalOption.filter(
                                            (detailedOption, indexTemp2) =>
                                              indexTemp2 !== secondOptionIndex,
                                          )
                                        }
                                        return totalOption
                                      },
                                    ),
                                    optionPrices: optionsInfo.optionPrices.map(
                                      (optionPrice) =>
                                        optionPrice.filter(
                                          (detailOptionPrice, indexTemp2) =>
                                            indexTemp2 !== secondOptionIndex,
                                        ),
                                    ),
                                  })
                                } else {
                                  setOptionsInfo({
                                    ...optionsInfo,
                                    optionNames: [optionsInfo.optionNames[0]],
                                    totalOptions: [optionsInfo.totalOptions[0]],
                                    optionPrices: optionsInfo.optionPrices.map(
                                      (optionPrice) => optionPrice[0],
                                    ),
                                  })
                                }
                              }}
                            >
                              삭제
                            </button>
                          </td>
                          {optionsInfo.totalOptions[0].map(
                            (firstOption, firstOptionIndex) => (
                              <td key={firstOptionIndex?.toString()}>
                                {AuthStore.isMasterAccount && (
                                  <div>
                                    일괄 변경 옵션 선택
                                    <input
                                      type='checkbox'
                                      onChange={(e) => {
                                        console.log(e.target.checked)
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo.optionPrices.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return firstOptionTemp.map(
                                                    (
                                                      secondOptionTemp,
                                                      secondOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        secondOptionIndex ===
                                                        secondOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...secondOptionTemp,
                                                          isChecked:
                                                            e.target.checked,
                                                        }
                                                      }
                                                      return secondOptionTemp
                                                    },
                                                  )
                                                }
                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }}
                                      checked={
                                        optionsInfo.optionPrices[
                                          firstOptionIndex
                                        ][secondOptionIndex]?.isChecked
                                      }
                                    />
                                  </div>
                                )}
                                <div>
                                  <div className='flex flex-row items-center justify-between mb-2'>
                                    <div className='flex flex-col items-start'>
                                      <div>개인 구매가 (원)</div>
                                      <div className='text-sm text-blue-700 text-semibold'>
                                        온라인 최저가로 설정하는 것을
                                        권장드립니다.
                                      </div>
                                    </div>
                                    <input
                                      className='px-2 py-1'
                                      type='number'
                                      value={
                                        optionsInfo.optionPrices[
                                          firstOptionIndex
                                        ][secondOptionIndex]
                                          .individualPurchasePrice
                                      }
                                      onChange={(e) => {
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo.optionPrices.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return firstOptionTemp.map(
                                                    (
                                                      secondOptionTemp,
                                                      secondOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        secondOptionIndex ===
                                                        secondOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...secondOptionTemp,
                                                          individualPurchasePrice:
                                                            parseInt(
                                                              e.target.value,
                                                              10,
                                                            ) || 0,
                                                        }
                                                      }
                                                      return secondOptionTemp
                                                    },
                                                  )
                                                }
                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }}
                                    />
                                  </div>
                                  <div className='flex flex-row items-center justify-between mb-2'>
                                    팀 구매가 (원)
                                    <br />
                                    <input
                                      className='px-2 py-1'
                                      type='number'
                                      value={
                                        optionsInfo.optionPrices[
                                          firstOptionIndex
                                        ][secondOptionIndex].teamPurchasePrice
                                      }
                                      onChange={(e) => {
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo.optionPrices.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return firstOptionTemp.map(
                                                    (
                                                      secondOptionTemp,
                                                      secondOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        secondOptionIndex ===
                                                        secondOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...secondOptionTemp,
                                                          teamPurchasePrice:
                                                            parseInt(
                                                              e.target.value,
                                                              10,
                                                            ) || 0,
                                                          aiPrice:
                                                            parseInt(
                                                              e.target.value,
                                                              10,
                                                            ) || 0,
                                                        }
                                                      }
                                                      return secondOptionTemp
                                                    },
                                                  )
                                                }
                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }}
                                    />
                                  </div>
                                  {/* 지원금 시작 */}
                                  <div className='flex flex-row items-center justify-between mb-2'>
                                    지원금
                                    <br />
                                    <input
                                      className='px-2 py-1'
                                      disabled={
                                        !AuthStore.isMasterAccount
                                        // TimeDealStatus === true
                                      }
                                      type='number'
                                      value={
                                        optionsInfo.optionPrices[
                                          firstOptionIndex
                                        ][secondOptionIndex]
                                          .platformSupportPrice
                                      }
                                      onChange={(e) => {
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo.optionPrices.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return firstOptionTemp.map(
                                                    (
                                                      secondOptionTemp,
                                                      secondOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        secondOptionIndex ===
                                                        secondOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...secondOptionTemp,
                                                          platformSupportPrice:
                                                            parseInt(
                                                              e.target.value,
                                                              10,
                                                            ) || 0,
                                                        }
                                                      }
                                                      return secondOptionTemp
                                                    },
                                                  )
                                                }
                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }}
                                    />
                                  </div>
                                  {/* 지원금 끝 */}
                                  <div className='flex flex-row items-center justify-between mb-2'>
                                    재고량
                                    <br />
                                    <input
                                      className='px-2 py-1'
                                      min={0}
                                      type='number'
                                      value={
                                        optionsInfo.optionPrices[
                                          firstOptionIndex
                                        ][secondOptionIndex].stockNumber
                                      }
                                      onChange={(e) => {
                                        const stockNumber =
                                          parseInt(e.target.value, 10) || 0
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo.optionPrices.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return firstOptionTemp.map(
                                                    (
                                                      secondOptionTemp,
                                                      secondOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        secondOptionIndex ===
                                                        secondOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...secondOptionTemp,
                                                          stockNumber,
                                                          sold_out:
                                                            stockNumber <= 0,
                                                          hurry_up:
                                                            stockNumber < 10,
                                                        }
                                                      }
                                                      return secondOptionTemp
                                                    },
                                                  )
                                                }
                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }}
                                    />
                                  </div>
                                  <div className='flex flex-row items-center justify-between mb-2'>
                                    판매자 상품코드
                                    <br />
                                    <input
                                      className='px-2 py-1'
                                      type='text'
                                      value={
                                        optionsInfo.optionPrices[
                                          firstOptionIndex
                                        ][secondOptionIndex].optionCode
                                      }
                                      onChange={(e) => {
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo.optionPrices.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return firstOptionTemp.map(
                                                    (
                                                      secondOptionTemp,
                                                      secondOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        secondOptionIndex ===
                                                        secondOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...secondOptionTemp,
                                                          optionCode:
                                                            e.target.value ||
                                                            '',
                                                        }
                                                      }
                                                      return secondOptionTemp
                                                    },
                                                  )
                                                }
                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                            ),
                          )}
                        </tr>
                      ),
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
        </Card>
        {/* 상품 주요 정보 */}
        <Card title='상품 주요 정보'>
          <div className='flex items-center mb-5'>
            <SubTitle>상품 주요정보를 입력해 주세요</SubTitle>
            <div className='self-center text-base font-normal text-gray-600'>
              (정보가 많고 구체적일수록 상단 노출에 유리합니다)
            </div>
            <TutorialModal modalTitle='상품 주요정보' videoSource={Tutorial5}>
              <AlwayzQuestion />
            </TutorialModal>
          </div>
          <table>
            <thead>
              <tr>
                <th className='text-lg'>
                  {optionsInfo?.optionNames?.length === 1
                    ? optionsInfo.optionNames[0] || '옵션 1'
                    : `${optionsInfo.optionNames[1] || '옵션 2'} / ${
                        optionsInfo.optionNames[0] || '옵션 1'
                      }`}
                </th>
                {optionsInfo?.totalOptions[0]?.map((option, index) => (
                  <th
                    key={option.name + index.toString()}
                    className='text-base'
                  >
                    {option.name || `옵션 1_세부 옵션 ${index + 1}`}
                    {index !== 0 && (
                      <button
                        className='px-2 py-1 my-2 ml-2 font-bold text-white rounded-lg bg-theme-500 hover:bg-sub-500'
                        type='button'
                        disabled={specialDealStatus === true}
                        onClick={() => {
                          Log.event(
                            'DeleteOptionButton',
                            'DeleteOptionButton',
                            {
                              click: 'DeleteOptionButton3',
                            },
                          )
                          setOptionIndices({})
                          if (optionsInfo.totalOptions[0].length !== 1) {
                            setOptionsInfo({
                              ...optionsInfo,
                              totalOptions: optionsInfo.totalOptions.map(
                                (totalOption, indexTemp) => {
                                  if (indexTemp === 0) {
                                    return totalOption.filter(
                                      (detailedOption, indexTemp2) =>
                                        indexTemp2 !== index,
                                    )
                                  }
                                  return totalOption
                                },
                              ),
                              optionPrices: optionsInfo.optionPrices.filter(
                                (optionPrice, indexTemp2) =>
                                  indexTemp2 !== index,
                              ),
                            })
                          }
                        }}
                      >
                        삭제
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {optionsInfo.optionNames.length === 1 ? (
                <tr>
                  <td className='text-base'>세부 정보</td>
                  {optionsInfo.totalOptions[0].map(
                    (firstOption, firstOptionIndex) => (
                      <td key={firstOption?.name + firstOptionIndex.toString()}>
                        {optionsInfo?.optionPrices[
                          firstOptionIndex
                        ]?.optionMetaDataInfoList?.map(
                          (metaData, metaDataIndex) => (
                            <tr key={metaData.title + metaDataIndex.toString()}>
                              <td style={{ fontSize: 15 }}>
                                <div> {metaData.title} </div>
                              </td>

                              <td>
                                {(!metaData?.basicOptions ||
                                  metaData?.basicOptions?.length === 0) && (
                                  <input
                                    className='my-0.5'
                                    type='text'
                                    placeholder={metaData.placeholder}
                                    width='150%'
                                    value={metaData.contents || ''}
                                    onChange={(e) => {
                                      setOptionsInfo({
                                        ...optionsInfo,
                                        optionPrices:
                                          optionsInfo?.optionPrices?.map(
                                            (
                                              firstOptionTemp,
                                              firstOptionIndexTemp,
                                            ) => {
                                              if (
                                                firstOptionIndex ===
                                                firstOptionIndexTemp
                                              ) {
                                                return {
                                                  ...firstOptionTemp,
                                                  optionMetaDataInfoList:
                                                    firstOptionTemp?.optionMetaDataInfoList?.map(
                                                      (
                                                        metaDataTemp,
                                                        metaDataIndexTemp,
                                                      ) => {
                                                        if (
                                                          metaDataIndex ===
                                                            metaDataIndexTemp &&
                                                          metaData
                                                            ?.contentsUnits
                                                            ?.length > 0
                                                        ) {
                                                          return {
                                                            ...metaDataTemp,
                                                            contents: Number(
                                                              e.target.value,
                                                            ),
                                                          }
                                                        }
                                                        if (
                                                          metaDataIndex ===
                                                            metaDataIndexTemp &&
                                                          (metaData
                                                            ?.contentsUnits
                                                            ?.length === 0 ||
                                                            !metaData?.contentsUnits)
                                                        ) {
                                                          return {
                                                            ...metaDataTemp,
                                                            contents:
                                                              e.target.value,
                                                          }
                                                        }
                                                        return metaDataTemp
                                                      },
                                                    ),
                                                }
                                              }

                                              return firstOptionTemp
                                            },
                                          ),
                                      })
                                      const invalidWeightRegex =
                                        /(\*|x|X|\(|\))/
                                      if (
                                        invalidWeightRegex?.test(e.target.value)
                                      ) {
                                        alert('무게/중량/용량만 입력해주세요')
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo?.optionPrices?.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return {
                                                    ...firstOptionTemp,
                                                    optionMetaDataInfoList:
                                                      firstOptionTemp?.optionMetaDataInfoList?.map(
                                                        (
                                                          metaDataTemp,
                                                          metaDataIndexTemp,
                                                        ) => {
                                                          if (
                                                            metaDataIndex ===
                                                            metaDataIndexTemp
                                                          ) {
                                                            return {
                                                              ...metaDataTemp,
                                                              contents:
                                                                e.target.value?.replace(
                                                                  invalidWeightRegex,
                                                                  '',
                                                                ),
                                                            }
                                                          }
                                                          return metaDataTemp
                                                        },
                                                      ),
                                                  }
                                                }

                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }
                                      const invalidMiddleRegex = /(~|-)/
                                      if (
                                        metaData?.title !== '모델명' &&
                                        invalidMiddleRegex?.test(e.target.value)
                                      ) {
                                        alert('평균값만 입력해주세요')
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo?.optionPrices?.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return {
                                                    ...firstOptionTemp,
                                                    optionMetaDataInfoList:
                                                      firstOptionTemp?.optionMetaDataInfoList?.map(
                                                        (
                                                          metaDataTemp,
                                                          metaDataIndexTemp,
                                                        ) => {
                                                          if (
                                                            metaDataIndex ===
                                                            metaDataIndexTemp
                                                          ) {
                                                            return {
                                                              ...metaDataTemp,
                                                              contents:
                                                                e.target.value?.replace(
                                                                  invalidMiddleRegex,
                                                                  '',
                                                                ),
                                                            }
                                                          }
                                                          return metaDataTemp
                                                        },
                                                      ),
                                                  }
                                                }
                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }
                                      const invalidDetailRegex =
                                        /(상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                                      if (
                                        invalidDetailRegex?.test(e.target.value)
                                      ) {
                                        alert('세부적인 내용을 입력해주세요')
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo?.optionPrices?.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return {
                                                    ...firstOptionTemp,
                                                    optionMetaDataInfoList:
                                                      firstOptionTemp?.optionMetaDataInfoList?.map(
                                                        (
                                                          metaDataTemp,
                                                          metaDataIndexTemp,
                                                        ) => {
                                                          if (
                                                            metaDataIndex ===
                                                            metaDataIndexTemp
                                                          ) {
                                                            return {
                                                              ...metaDataTemp,
                                                              contents:
                                                                e.target.value?.replace(
                                                                  invalidDetailRegex,
                                                                  '',
                                                                ),
                                                            }
                                                          }
                                                          return metaDataTemp
                                                        },
                                                      ),
                                                  }
                                                }

                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }
                                      const invalidUnitRegex = /[^\d]+/
                                      if (
                                        invalidUnitRegex?.test(
                                          e.target.value,
                                        ) &&
                                        metaData?.contentsUnits?.length > 0
                                      ) {
                                        alert('숫자만 입력해주세요')
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo?.optionPrices?.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return {
                                                    ...firstOptionTemp,
                                                    optionMetaDataInfoList:
                                                      firstOptionTemp?.optionMetaDataInfoList?.map(
                                                        (
                                                          metaDataTemp,
                                                          metaDataIndexTemp,
                                                        ) => {
                                                          if (
                                                            metaDataIndex ===
                                                            metaDataIndexTemp
                                                          ) {
                                                            return {
                                                              ...metaDataTemp,
                                                              contents:
                                                                e.target.value?.replace(
                                                                  invalidUnitRegex,
                                                                  '',
                                                                ),
                                                            }
                                                          }
                                                          return metaDataTemp
                                                        },
                                                      ),
                                                  }
                                                }

                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }
                                    }}
                                  />
                                )}
                                <div className='flex flex-row'>
                                  {metaData?.basicOptions?.map(
                                    (basicOption) => {
                                      return (
                                        <div key={basicOption}>
                                          <input
                                            checked={
                                              optionsInfo?.optionPrices?.[
                                                firstOptionIndex
                                              ]?.optionMetaDataInfoList?.[
                                                metaDataIndex
                                              ]?.basicOption === basicOption
                                            }
                                            type='checkbox'
                                            value={metaData?.basicOption}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setOptionsInfo({
                                                  ...optionsInfo,
                                                  optionPrices:
                                                    optionsInfo?.optionPrices?.map(
                                                      (
                                                        firstOptionTemp,
                                                        firstOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          firstOptionIndex ===
                                                          firstOptionIndexTemp
                                                        ) {
                                                          return {
                                                            ...firstOptionTemp,
                                                            optionMetaDataInfoList:
                                                              firstOptionTemp?.optionMetaDataInfoList?.map(
                                                                (
                                                                  metaDataTemp,
                                                                  metaDataIndexTemp,
                                                                ) => {
                                                                  if (
                                                                    metaDataIndex ===
                                                                    metaDataIndexTemp
                                                                  ) {
                                                                    delete metaDataTemp?.basicOptionExtra
                                                                    return {
                                                                      ...metaDataTemp,
                                                                      basicOption,
                                                                    }
                                                                  }
                                                                  return metaDataTemp
                                                                },
                                                              ),
                                                          }
                                                        }

                                                        return firstOptionTemp
                                                      },
                                                    ),
                                                })
                                              }
                                            }}
                                          />{' '}
                                          <span className='mr-2 text-base'>
                                            {basicOption}{' '}
                                          </span>{' '}
                                        </div>
                                      )
                                    },
                                  )}
                                  {metaData?.basicOptions?.length > 0 && (
                                    <div key='기타'>
                                      {/* <label htmlFor={contentsUnit}> */}
                                      <input
                                        checked={
                                          optionsInfo?.optionPrices?.[
                                            firstOptionIndex
                                          ]?.optionMetaDataInfoList?.[
                                            metaDataIndex
                                          ]?.basicOptionExtra === '기타'
                                        }
                                        type='checkbox'
                                        value={metaData?.basicOption}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo?.optionPrices?.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return {
                                                        ...firstOptionTemp,
                                                        optionMetaDataInfoList:
                                                          firstOptionTemp?.optionMetaDataInfoList?.map(
                                                            (
                                                              metaDataTemp,
                                                              metaDataIndexTemp,
                                                            ) => {
                                                              if (
                                                                metaDataIndex ===
                                                                metaDataIndexTemp
                                                              ) {
                                                                return {
                                                                  ...metaDataTemp,
                                                                  basicOptionExtra:
                                                                    '기타',
                                                                  basicOption:
                                                                    '',
                                                                }
                                                              }
                                                              return metaDataTemp
                                                            },
                                                          ),
                                                      }
                                                    }

                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                          }
                                        }}
                                      />{' '}
                                      <span className='text-base'>기타 </span>{' '}
                                    </div>
                                  )}
                                  {metaData?.basicOptionExtra === '기타' && (
                                    <input
                                      type='text'
                                      value={metaData?.basicOption}
                                      onChange={(e) => {
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo?.optionPrices?.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return {
                                                    ...firstOptionTemp,
                                                    optionMetaDataInfoList:
                                                      firstOptionTemp?.optionMetaDataInfoList?.map(
                                                        (
                                                          metaDataTemp,
                                                          metaDataIndexTemp,
                                                        ) => {
                                                          if (
                                                            metaDataIndex ===
                                                            metaDataIndexTemp
                                                          ) {
                                                            return {
                                                              ...metaDataTemp,
                                                              basicOption:
                                                                e.target.value,
                                                            }
                                                          }
                                                          return metaDataTemp
                                                        },
                                                      ),
                                                  }
                                                }

                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }}
                                    />
                                  )}
                                </div>{' '}
                              </td>
                              <td>
                                <div className='flex flex-row'>
                                  {metaData?.contentsUnits?.map(
                                    (contentsUnit) => {
                                      return (
                                        <div key={contentsUnit}>
                                          <input
                                            checked={
                                              optionsInfo?.optionPrices?.[
                                                firstOptionIndex
                                              ]?.optionMetaDataInfoList?.[
                                                metaDataIndex
                                              ]?.contentsUnit === contentsUnit
                                            }
                                            value={metaData?.contentsUnit}
                                            type='checkbox'
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setOptionsInfo({
                                                  ...optionsInfo,
                                                  optionPrices:
                                                    optionsInfo?.optionPrices?.map(
                                                      (
                                                        firstOptionTemp,
                                                        firstOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          firstOptionIndex ===
                                                          firstOptionIndexTemp
                                                        ) {
                                                          return {
                                                            ...firstOptionTemp,
                                                            optionMetaDataInfoList:
                                                              firstOptionTemp?.optionMetaDataInfoList?.map(
                                                                (
                                                                  metaDataTemp,
                                                                  metaDataIndexTemp,
                                                                ) => {
                                                                  if (
                                                                    metaDataIndex ===
                                                                    metaDataIndexTemp
                                                                  ) {
                                                                    delete metaDataTemp?.contentsUnitExtra
                                                                    return {
                                                                      ...metaDataTemp,
                                                                      contentsUnit,
                                                                    }
                                                                  }
                                                                  return metaDataTemp
                                                                },
                                                              ),
                                                          }
                                                        }

                                                        return firstOptionTemp
                                                      },
                                                    ),
                                                })
                                              }
                                            }}
                                          />{' '}
                                          <span className='mr-2 text-base'>
                                            {contentsUnit}{' '}
                                          </span>{' '}
                                        </div>
                                      )
                                    },
                                  )}
                                  {metaData?.contentsUnits?.length > 0 && (
                                    <div key='기타'>
                                      {/* <label htmlFor={contentsUnit}> */}
                                      <input
                                        checked={
                                          optionsInfo?.optionPrices?.[
                                            firstOptionIndex
                                          ]?.optionMetaDataInfoList?.[
                                            metaDataIndex
                                          ]?.contentsUnitExtra === '기타'
                                        }
                                        type='checkbox'
                                        value={metaData?.contentsUnit}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo?.optionPrices?.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return {
                                                        ...firstOptionTemp,
                                                        optionMetaDataInfoList:
                                                          firstOptionTemp?.optionMetaDataInfoList?.map(
                                                            (
                                                              metaDataTemp,
                                                              metaDataIndexTemp,
                                                            ) => {
                                                              if (
                                                                metaDataIndex ===
                                                                metaDataIndexTemp
                                                              ) {
                                                                return {
                                                                  ...metaDataTemp,
                                                                  contentsUnitExtra:
                                                                    '기타',
                                                                  contentsUnit:
                                                                    '',
                                                                }
                                                              }
                                                              return metaDataTemp
                                                            },
                                                          ),
                                                      }
                                                    }

                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                          }
                                        }}
                                      />{' '}
                                      <span className='text-base'>기타 </span>{' '}
                                    </div>
                                  )}
                                  {metaData?.contentsUnitExtra === '기타' && (
                                    <input
                                      type='text'
                                      value={metaData?.contentsUnit}
                                      onChange={(e) => {
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo?.optionPrices?.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return {
                                                    ...firstOptionTemp,
                                                    optionMetaDataInfoList:
                                                      firstOptionTemp?.optionMetaDataInfoList?.map(
                                                        (
                                                          metaDataTemp,
                                                          metaDataIndexTemp,
                                                        ) => {
                                                          if (
                                                            metaDataIndex ===
                                                            metaDataIndexTemp
                                                          ) {
                                                            return {
                                                              ...metaDataTemp,
                                                              contentsUnit:
                                                                e.target.value,
                                                            }
                                                          }
                                                          return metaDataTemp
                                                        },
                                                      ),
                                                  }
                                                }

                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                        const invalidContentsUnitRegex =
                                          /([0-9]|\*|\+|-|~|x|X|\(|\)|상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                                        if (
                                          invalidContentsUnitRegex?.test(
                                            e.target.value,
                                          )
                                        ) {
                                          alert('단위만 입력해주세요')
                                          setOptionsInfo({
                                            ...optionsInfo,
                                            optionPrices:
                                              optionsInfo?.optionPrices?.map(
                                                (
                                                  firstOptionTemp,
                                                  firstOptionIndexTemp,
                                                ) => {
                                                  if (
                                                    firstOptionIndex ===
                                                    firstOptionIndexTemp
                                                  ) {
                                                    return {
                                                      ...firstOptionTemp,
                                                      optionMetaDataInfoList:
                                                        firstOptionTemp?.optionMetaDataInfoList?.map(
                                                          (
                                                            metaDataTemp,
                                                            metaDataIndexTemp,
                                                          ) => {
                                                            if (
                                                              metaDataIndex ===
                                                              metaDataIndexTemp
                                                            ) {
                                                              return {
                                                                ...metaDataTemp,
                                                                contentsUnit:
                                                                  e.target.value?.replace(
                                                                    invalidContentsUnitRegex,
                                                                    '',
                                                                  ),
                                                              }
                                                            }
                                                            return metaDataTemp
                                                          },
                                                        ),
                                                    }
                                                  }

                                                  return firstOptionTemp
                                                },
                                              ),
                                          })
                                        }
                                      }}
                                    />
                                  )}
                                </div>{' '}
                              </td>
                            </tr>
                          ),
                        )}
                      </td>
                    ),
                  )}
                </tr>
              ) : (
                optionsInfo.totalOptions[1].map(
                  (secondOption, secondOptionIndex) => (
                    <tr key={secondOption?.name + secondOptionIndex.toString()}>
                      <td style={{ fontSize: 18 }}>
                        {secondOption.name ||
                          `옵션 2_세부 옵션 ${secondOptionIndex + 1}`}
                        <button
                          className='px-2 py-2 my-2 font-bold text-white rounded-lg bg-theme-500 hover:bg-sub-500'
                          type='button'
                          disabled={specialDealStatus === true}
                          onClick={() => {
                            Log.event(
                              'DeleteOptionButton',
                              'DeleteOptionButton',
                              {
                                click: 'DeleteOptionButton4',
                              },
                            )
                            setOptionIndices({})
                            if (optionsInfo.totalOptions[1].length !== 1) {
                              setOptionsInfo({
                                ...optionsInfo,
                                totalOptions: optionsInfo.totalOptions.map(
                                  (totalOption, indexTemp) => {
                                    if (indexTemp === 1) {
                                      return totalOption.filter(
                                        (detailedOption, indexTemp2) =>
                                          indexTemp2 !== secondOptionIndex,
                                      )
                                    }
                                    return totalOption
                                  },
                                ),
                                optionPrices: optionsInfo.optionPrices.map(
                                  (optionPrice) =>
                                    optionPrice.filter(
                                      (detailOptionPrice, indexTemp2) =>
                                        indexTemp2 !== secondOptionIndex,
                                    ),
                                ),
                              })
                            } else {
                              setOptionsInfo({
                                ...optionsInfo,
                                optionNames: [optionsInfo.optionNames[0]],
                                totalOptions: [optionsInfo.totalOptions[0]],
                                optionPrices: optionsInfo.optionPrices.map(
                                  (optionPrice) => optionPrice[0],
                                ),
                              })
                            }
                          }}
                        >
                          삭제
                        </button>
                      </td>
                      {optionsInfo.totalOptions[0].map(
                        (firstOption, firstOptionIndex) => (
                          <td
                            key={
                              firstOption?.name + firstOptionIndex.toString()
                            }
                          >
                            {optionsInfo?.optionPrices[firstOptionIndex][
                              secondOptionIndex
                            ]?.optionMetaDataInfoList?.map(
                              (metaData, metaDataIndex) => (
                                <tr
                                  key={
                                    metaData.title + metaDataIndex.toString()
                                  }
                                >
                                  <td style={{ fontSize: 15 }}>
                                    <div> {metaData.title} </div>
                                  </td>

                                  <td>
                                    {(!metaData?.basicOptions ||
                                      metaData?.basicOptions?.length === 0) && (
                                      <input
                                        type='text'
                                        placeholder={metaData.placeholder}
                                        width='150%'
                                        value={metaData.contents || ''}
                                        onChange={(e) => {
                                          setOptionsInfo({
                                            ...optionsInfo,
                                            optionPrices:
                                              optionsInfo?.optionPrices?.map(
                                                (
                                                  firstOptionTemp,
                                                  firstOptionIndexTemp,
                                                ) => {
                                                  if (
                                                    firstOptionIndex ===
                                                    firstOptionIndexTemp
                                                  ) {
                                                    return firstOptionTemp.map(
                                                      (
                                                        secondOptionTemp,
                                                        secondOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          secondOptionIndex ===
                                                          secondOptionIndexTemp
                                                        ) {
                                                          return {
                                                            ...secondOptionTemp,
                                                            optionMetaDataInfoList:
                                                              secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                (
                                                                  metaDataTemp,
                                                                  metaDataIndexTemp,
                                                                ) => {
                                                                  if (
                                                                    metaDataIndex ===
                                                                      metaDataIndexTemp &&
                                                                    metaData
                                                                      ?.contentsUnits
                                                                      ?.length >
                                                                      0
                                                                  ) {
                                                                    return {
                                                                      ...metaDataTemp,
                                                                      contents:
                                                                        Number(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                        ),
                                                                    }
                                                                  }
                                                                  if (
                                                                    metaDataIndex ===
                                                                      metaDataIndexTemp &&
                                                                    (metaData
                                                                      ?.contentsUnits
                                                                      ?.length ===
                                                                      0 ||
                                                                      !metaData?.contentsUnits)
                                                                  ) {
                                                                    return {
                                                                      ...metaDataTemp,
                                                                      contents:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  }
                                                                  return metaDataTemp
                                                                },
                                                              ),
                                                          }
                                                        }
                                                        return secondOptionTemp
                                                      },
                                                    )
                                                  }
                                                  return firstOptionTemp
                                                },
                                              ),
                                          })
                                          const invalidWeightRegex =
                                            /(\*|x|X|\(|\))/
                                          if (
                                            invalidWeightRegex?.test(
                                              e.target.value,
                                            )
                                          ) {
                                            alert(
                                              '무게/중량/용량만 입력해주세요',
                                            )
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo?.optionPrices?.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return firstOptionTemp.map(
                                                        (
                                                          secondOptionTemp,
                                                          secondOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            secondOptionIndex ===
                                                            secondOptionIndexTemp
                                                          ) {
                                                            return {
                                                              ...secondOptionTemp,
                                                              optionMetaDataInfoList:
                                                                secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                  (
                                                                    metaDataTemp,
                                                                    metaDataIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      metaDataIndex ===
                                                                      metaDataIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...metaDataTemp,
                                                                        contents:
                                                                          e.target.value?.replace(
                                                                            invalidWeightRegex,
                                                                            '',
                                                                          ),
                                                                      }
                                                                    }
                                                                    return metaDataTemp
                                                                  },
                                                                ),
                                                            }
                                                          }
                                                          return secondOptionTemp
                                                        },
                                                      )
                                                    }
                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                          }
                                          const invalidMiddleRegex = /(~|-)/
                                          if (
                                            metaData?.title !== '모델명' &&
                                            invalidMiddleRegex?.test(
                                              e.target.value,
                                            )
                                          ) {
                                            alert('평균값만 입력해주세요')
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo?.optionPrices?.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return firstOptionTemp.map(
                                                        (
                                                          secondOptionTemp,
                                                          secondOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            secondOptionIndex ===
                                                            secondOptionIndexTemp
                                                          ) {
                                                            return {
                                                              ...secondOptionTemp,
                                                              optionMetaDataInfoList:
                                                                secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                  (
                                                                    metaDataTemp,
                                                                    metaDataIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      metaDataIndex ===
                                                                      metaDataIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...metaDataTemp,
                                                                        contents:
                                                                          e.target.value?.replace(
                                                                            invalidMiddleRegex,
                                                                            '',
                                                                          ),
                                                                      }
                                                                    }
                                                                    return metaDataTemp
                                                                  },
                                                                ),
                                                            }
                                                          }
                                                          return secondOptionTemp
                                                        },
                                                      )
                                                    }
                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                          }
                                          const invalidDetailRegex =
                                            /(상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                                          if (
                                            invalidDetailRegex?.test(
                                              e.target.value,
                                            )
                                          ) {
                                            alert(
                                              '세부적인 내용을 입력해주세요',
                                            )
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo?.optionPrices?.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return firstOptionTemp.map(
                                                        (
                                                          secondOptionTemp,
                                                          secondOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            secondOptionIndex ===
                                                            secondOptionIndexTemp
                                                          ) {
                                                            return {
                                                              ...secondOptionTemp,
                                                              optionMetaDataInfoList:
                                                                secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                  (
                                                                    metaDataTemp,
                                                                    metaDataIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      metaDataIndex ===
                                                                      metaDataIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...metaDataTemp,
                                                                        contents:
                                                                          e.target.value?.replace(
                                                                            invalidDetailRegex,
                                                                            '',
                                                                          ),
                                                                      }
                                                                    }
                                                                    return metaDataTemp
                                                                  },
                                                                ),
                                                            }
                                                          }
                                                          return secondOptionTemp
                                                        },
                                                      )
                                                    }
                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                          }
                                          const invalidUnitRegex = /[^\d]+/
                                          if (
                                            invalidUnitRegex?.test(
                                              e.target.value,
                                            ) &&
                                            metaData?.contentsUnits?.length > 0
                                          ) {
                                            alert('숫자만 입력해주세요')
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo?.optionPrices?.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return firstOptionTemp.map(
                                                        (
                                                          secondOptionTemp,
                                                          secondOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            secondOptionIndex ===
                                                            secondOptionIndexTemp
                                                          ) {
                                                            return {
                                                              ...secondOptionTemp,
                                                              optionMetaDataInfoList:
                                                                secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                  (
                                                                    metaDataTemp,
                                                                    metaDataIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      metaDataIndex ===
                                                                      metaDataIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...metaDataTemp,
                                                                        contents:
                                                                          e.target.value?.replace(
                                                                            invalidUnitRegex,
                                                                            '',
                                                                          ),
                                                                      }
                                                                    }
                                                                    return metaDataTemp
                                                                  },
                                                                ),
                                                            }
                                                          }
                                                          return secondOptionTemp
                                                        },
                                                      )
                                                    }
                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                          }
                                        }}
                                      />
                                    )}
                                    <div className='flex flex-row'>
                                      {metaData?.basicOptions?.map(
                                        (basicOption) => {
                                          return (
                                            <div key={basicOption}>
                                              <input
                                                checked={
                                                  optionsInfo?.optionPrices?.[
                                                    firstOptionIndex
                                                  ][secondOptionIndex]
                                                    ?.optionMetaDataInfoList?.[
                                                    metaDataIndex
                                                  ]?.basicOption === basicOption
                                                }
                                                type='checkbox'
                                                value={metaData?.basicOption}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setOptionsInfo({
                                                      ...optionsInfo,
                                                      optionPrices:
                                                        optionsInfo?.optionPrices?.map(
                                                          (
                                                            firstOptionTemp,
                                                            firstOptionIndexTemp,
                                                          ) => {
                                                            if (
                                                              firstOptionIndex ===
                                                              firstOptionIndexTemp
                                                            ) {
                                                              return firstOptionTemp.map(
                                                                (
                                                                  secondOptionTemp,
                                                                  secondOptionIndexTemp,
                                                                ) => {
                                                                  if (
                                                                    secondOptionIndex ===
                                                                    secondOptionIndexTemp
                                                                  ) {
                                                                    return {
                                                                      ...secondOptionTemp,
                                                                      optionMetaDataInfoList:
                                                                        secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                          (
                                                                            metaDataTemp,
                                                                            metaDataIndexTemp,
                                                                          ) => {
                                                                            if (
                                                                              metaDataIndex ===
                                                                              metaDataIndexTemp
                                                                            ) {
                                                                              delete metaDataTemp?.basicOptionExtra
                                                                              return {
                                                                                ...metaDataTemp,
                                                                                basicOption,
                                                                              }
                                                                            }
                                                                            return metaDataTemp
                                                                          },
                                                                        ),
                                                                    }
                                                                  }
                                                                  return secondOptionTemp
                                                                },
                                                              )
                                                            }
                                                            return firstOptionTemp
                                                          },
                                                        ),
                                                    })
                                                  }
                                                }}
                                              />{' '}
                                              <span className='mr-2 text-base'>
                                                {basicOption}{' '}
                                              </span>{' '}
                                            </div>
                                          )
                                        },
                                      )}
                                      {metaData?.basicOptions?.length > 0 && (
                                        <div key='기타'>
                                          {/* <label htmlFor={contentsUnit}> */}
                                          <input
                                            checked={
                                              optionsInfo?.optionPrices?.[
                                                firstOptionIndex
                                              ][secondOptionIndex]
                                                ?.optionMetaDataInfoList?.[
                                                metaDataIndex
                                              ]?.basicOptionExtra === '기타'
                                            }
                                            type='checkbox'
                                            value={metaData?.basicOption}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setOptionsInfo({
                                                  ...optionsInfo,
                                                  optionPrices:
                                                    optionsInfo?.optionPrices?.map(
                                                      (
                                                        firstOptionTemp,
                                                        firstOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          firstOptionIndex ===
                                                          firstOptionIndexTemp
                                                        ) {
                                                          return firstOptionTemp.map(
                                                            (
                                                              secondOptionTemp,
                                                              secondOptionIndexTemp,
                                                            ) => {
                                                              if (
                                                                secondOptionIndex ===
                                                                secondOptionIndexTemp
                                                              ) {
                                                                return {
                                                                  ...secondOptionTemp,
                                                                  optionMetaDataInfoList:
                                                                    secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                      (
                                                                        metaDataTemp,
                                                                        metaDataIndexTemp,
                                                                      ) => {
                                                                        if (
                                                                          metaDataIndex ===
                                                                          metaDataIndexTemp
                                                                        ) {
                                                                          return {
                                                                            ...metaDataTemp,
                                                                            basicOptionExtra:
                                                                              '기타',
                                                                            basicOption:
                                                                              '',
                                                                          }
                                                                        }
                                                                        return metaDataTemp
                                                                      },
                                                                    ),
                                                                }
                                                              }
                                                              return secondOptionTemp
                                                            },
                                                          )
                                                        }
                                                        return firstOptionTemp
                                                      },
                                                    ),
                                                })
                                              }
                                            }}
                                          />{' '}
                                          <span className='text-base'>
                                            기타{' '}
                                          </span>{' '}
                                        </div>
                                      )}
                                      {metaData?.basicOptionExtra ===
                                        '기타' && (
                                        <input
                                          type='text'
                                          value={metaData?.basicOption}
                                          onChange={(e) => {
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo?.optionPrices?.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return firstOptionTemp.map(
                                                        (
                                                          secondOptionTemp,
                                                          secondOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            secondOptionIndex ===
                                                            secondOptionIndexTemp
                                                          ) {
                                                            return {
                                                              ...secondOptionTemp,
                                                              optionMetaDataInfoList:
                                                                secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                  (
                                                                    metaDataTemp,
                                                                    metaDataIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      metaDataIndex ===
                                                                      metaDataIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...metaDataTemp,
                                                                        basicOption:
                                                                          e
                                                                            .target
                                                                            .value,
                                                                      }
                                                                    }
                                                                    return metaDataTemp
                                                                  },
                                                                ),
                                                            }
                                                          }
                                                          return secondOptionTemp
                                                        },
                                                      )
                                                    }
                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                          }}
                                        />
                                      )}
                                    </div>{' '}
                                  </td>
                                  <td>
                                    <div className='flex flex-row'>
                                      {metaData?.contentsUnits?.map(
                                        (contentsUnit) => {
                                          return (
                                            <div key={contentsUnit}>
                                              <input
                                                checked={
                                                  optionsInfo?.optionPrices?.[
                                                    firstOptionIndex
                                                  ][secondOptionIndex]
                                                    ?.optionMetaDataInfoList?.[
                                                    metaDataIndex
                                                  ]?.contentsUnit ===
                                                  contentsUnit
                                                }
                                                type='checkbox'
                                                value={metaData?.contentsUnit}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setOptionsInfo({
                                                      ...optionsInfo,
                                                      optionPrices:
                                                        optionsInfo?.optionPrices?.map(
                                                          (
                                                            firstOptionTemp,
                                                            firstOptionIndexTemp,
                                                          ) => {
                                                            if (
                                                              firstOptionIndex ===
                                                              firstOptionIndexTemp
                                                            ) {
                                                              return firstOptionTemp.map(
                                                                (
                                                                  secondOptionTemp,
                                                                  secondOptionIndexTemp,
                                                                ) => {
                                                                  if (
                                                                    secondOptionIndex ===
                                                                    secondOptionIndexTemp
                                                                  ) {
                                                                    return {
                                                                      ...secondOptionTemp,
                                                                      optionMetaDataInfoList:
                                                                        secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                          (
                                                                            metaDataTemp,
                                                                            metaDataIndexTemp,
                                                                          ) => {
                                                                            if (
                                                                              metaDataIndex ===
                                                                              metaDataIndexTemp
                                                                            ) {
                                                                              delete metaDataTemp?.contentsUnitExtra
                                                                              return {
                                                                                ...metaDataTemp,
                                                                                contentsUnit,
                                                                              }
                                                                            }
                                                                            return metaDataTemp
                                                                          },
                                                                        ),
                                                                    }
                                                                  }
                                                                  return secondOptionTemp
                                                                },
                                                              )
                                                            }
                                                            return firstOptionTemp
                                                          },
                                                        ),
                                                    })
                                                  }
                                                }}
                                              />{' '}
                                              <span className='mr-2 text-base'>
                                                {contentsUnit}{' '}
                                              </span>{' '}
                                            </div>
                                          )
                                        },
                                      )}
                                      {metaData?.contentsUnits?.length > 0 && (
                                        <div key='기타'>
                                          {/* <label htmlFor={contentsUnit}> */}
                                          <input
                                            checked={
                                              optionsInfo?.optionPrices?.[
                                                firstOptionIndex
                                              ][secondOptionIndex]
                                                ?.optionMetaDataInfoList?.[
                                                metaDataIndex
                                              ]?.contentsUnitExtra === '기타'
                                            }
                                            type='checkbox'
                                            value={metaData?.contentsUnit}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setOptionsInfo({
                                                  ...optionsInfo,
                                                  optionPrices:
                                                    optionsInfo?.optionPrices?.map(
                                                      (
                                                        firstOptionTemp,
                                                        firstOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          firstOptionIndex ===
                                                          firstOptionIndexTemp
                                                        ) {
                                                          return firstOptionTemp.map(
                                                            (
                                                              secondOptionTemp,
                                                              secondOptionIndexTemp,
                                                            ) => {
                                                              if (
                                                                secondOptionIndex ===
                                                                secondOptionIndexTemp
                                                              ) {
                                                                return {
                                                                  ...secondOptionTemp,
                                                                  optionMetaDataInfoList:
                                                                    secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                      (
                                                                        metaDataTemp,
                                                                        metaDataIndexTemp,
                                                                      ) => {
                                                                        if (
                                                                          metaDataIndex ===
                                                                          metaDataIndexTemp
                                                                        ) {
                                                                          return {
                                                                            ...metaDataTemp,
                                                                            contentsUnitExtra:
                                                                              '기타',
                                                                            contentsUnit:
                                                                              '',
                                                                          }
                                                                        }
                                                                        return metaDataTemp
                                                                      },
                                                                    ),
                                                                }
                                                              }
                                                              return secondOptionTemp
                                                            },
                                                          )
                                                        }
                                                        return firstOptionTemp
                                                      },
                                                    ),
                                                })
                                              }
                                            }}
                                          />{' '}
                                          <span className='text-base'>
                                            기타{' '}
                                          </span>{' '}
                                        </div>
                                      )}
                                      {metaData?.contentsUnitExtra ===
                                        '기타' && (
                                        <input
                                          type='text'
                                          value={metaData?.contentsUnit}
                                          onChange={(e) => {
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo?.optionPrices?.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return firstOptionTemp.map(
                                                        (
                                                          secondOptionTemp,
                                                          secondOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            secondOptionIndex ===
                                                            secondOptionIndexTemp
                                                          ) {
                                                            return {
                                                              ...secondOptionTemp,
                                                              optionMetaDataInfoList:
                                                                secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                  (
                                                                    metaDataTemp,
                                                                    metaDataIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      metaDataIndex ===
                                                                      metaDataIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...metaDataTemp,
                                                                        contentsUnit:
                                                                          e
                                                                            .target
                                                                            .value,
                                                                      }
                                                                    }
                                                                    return metaDataTemp
                                                                  },
                                                                ),
                                                            }
                                                          }
                                                          return secondOptionTemp
                                                        },
                                                      )
                                                    }
                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                            const invalidContentsUnitRegex =
                                              /([0-9]|\*|\+|-|~|x|X|\(|\)|상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                                            if (
                                              invalidContentsUnitRegex?.test(
                                                e.target.value,
                                              )
                                            ) {
                                              alert('단위만 입력해주세요')
                                              setOptionsInfo({
                                                ...optionsInfo,
                                                optionPrices:
                                                  optionsInfo?.optionPrices?.map(
                                                    (
                                                      firstOptionTemp,
                                                      firstOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        firstOptionIndex ===
                                                        firstOptionIndexTemp
                                                      ) {
                                                        return firstOptionTemp.map(
                                                          (
                                                            secondOptionTemp,
                                                            secondOptionIndexTemp,
                                                          ) => {
                                                            if (
                                                              secondOptionIndex ===
                                                              secondOptionIndexTemp
                                                            ) {
                                                              return {
                                                                ...secondOptionTemp,
                                                                optionMetaDataInfoList:
                                                                  secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                    (
                                                                      metaDataTemp,
                                                                      metaDataIndexTemp,
                                                                    ) => {
                                                                      if (
                                                                        metaDataIndex ===
                                                                        metaDataIndexTemp
                                                                      ) {
                                                                        return {
                                                                          ...metaDataTemp,
                                                                          contentsUnit:
                                                                            e.target.value?.replace(
                                                                              invalidContentsUnitRegex,
                                                                              '',
                                                                            ),
                                                                        }
                                                                      }
                                                                      return metaDataTemp
                                                                    },
                                                                  ),
                                                              }
                                                            }
                                                            return secondOptionTemp
                                                          },
                                                        )
                                                      }
                                                      return firstOptionTemp
                                                    },
                                                  ),
                                              })
                                            }
                                          }}
                                        />
                                      )}
                                    </div>{' '}
                                  </td>
                                </tr>
                              ),
                            )}
                          </td>
                        ),
                      )}
                    </tr>
                  ),
                )
              )}
            </tbody>
          </table>
          <div style={{ marginTop: 25 }} />

          <table>
            <thead style={{ fontSize: 18 }}>
              <tr>
                <th className='text-lg'>항목</th>
                <th className='text-lg'>상품 기본 정보</th>
              </tr>
            </thead>
            <tbody>
              {productMetaDataList?.map((metaData, index) => (
                <tr key={metaData?.title + index.toString()}>
                  <td>
                    <div className='text-lg'> {metaData.title} </div>
                  </td>

                  <td>
                    {(!metaData?.basicOptions ||
                      metaData?.basicOptions?.length === 0) && (
                      <input
                        className='w-4/5 my-0.5'
                        type='text'
                        placeholder={metaData?.placeholder}
                        width='150%'
                        value={metaData?.contents}
                        onChange={(e) => {
                          setProductMetaDataList(
                            productMetaDataList?.map((el) => {
                              if (metaData?.title === el?.title) {
                                return { ...el, contents: e.target.value }
                              }
                              return { ...el }
                            }),
                          )
                          const invalidDetailRegex =
                            /(상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                          if (invalidDetailRegex?.test(e.target.value)) {
                            alert('세부적인 내용을 입력해주세요')
                            setProductMetaDataList(
                              productMetaDataList?.map((el) => {
                                if (metaData?.title === el?.title) {
                                  return {
                                    ...el,
                                    contents: e.target.value?.replace(
                                      invalidDetailRegex,
                                      '',
                                    ),
                                  }
                                }
                                return { ...el }
                              }),
                            )
                          }
                        }}
                      />
                    )}
                    <div className='flex flex-row'>
                      {metaData?.basicOptions?.map((basicOption) => {
                        return (
                          <div key={basicOption}>
                            <input
                              checked={metaData?.basicOption === basicOption}
                              type='checkbox'
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setProductMetaDataList(
                                    productMetaDataList?.map((el) => {
                                      if (metaData?.title === el?.title) {
                                        delete metaData?.basicOptionExtra
                                        return {
                                          ...el,
                                          basicOption,
                                        }
                                      }
                                      return { ...el }
                                    }),
                                  )
                                }
                              }}
                            />{' '}
                            <span className='mr-2 text-base'>
                              {basicOption}{' '}
                            </span>{' '}
                          </div>
                        )
                      })}
                      {metaData?.basicOptions?.length > 0 && (
                        <div key='기타'>
                          {/* <label htmlFor={contentsUnit}> */}
                          <input
                            checked={metaData?.basicOptionExtra === '기타'}
                            type='checkbox'
                            onChange={(e) => {
                              if (e.target.checked) {
                                setProductMetaDataList(
                                  productMetaDataList?.map((el) => {
                                    if (metaData?.title === el?.title) {
                                      return {
                                        ...el,
                                        basicOptionExtra: '기타',
                                        basicOption: '',
                                      }
                                    }
                                    return { ...el }
                                  }),
                                )
                              }
                            }}
                          />{' '}
                          <span className='text-base'>기타 </span>{' '}
                        </div>
                      )}
                      {metaData?.basicOptionExtra === '기타' && (
                        <input
                          className='w-4/5 my-0.5'
                          type='text'
                          value={metaData?.basicOption}
                          onChange={(e) => {
                            setProductMetaDataList(
                              productMetaDataList?.map((el) => {
                                if (metaData?.title === el?.title) {
                                  return {
                                    ...el,
                                    basicOption: e.target.value,
                                  }
                                }
                                return { ...el }
                              }),
                            )
                          }}
                        />
                      )}
                    </div>{' '}
                  </td>
                </tr>
              ))}

              {commonMetaDataList?.map((metaData, index) => (
                <tr key={metaData?.title + index.toString()}>
                  <td className='w-1/3 text-lg'>
                    <div> {metaData?.title} </div>
                  </td>

                  <td>
                    <input
                      type='text'
                      placeholder={metaData?.placeholder}
                      className='w-4/5 my-0.5'
                      value={metaData?.contents}
                      onChange={(e) => {
                        setCommonMetaDataList(
                          commonMetaDataList?.map((el) => {
                            if (metaData?.title === el?.title) {
                              return { ...el, contents: e.target.value }
                            }
                            return { ...el }
                          }),
                        )
                        const invalidDetailRegex =
                          /(상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                        if (invalidDetailRegex?.test(e.target.value)) {
                          alert('세부적인 내용을 입력해주세요')
                          setCommonMetaDataList(
                            commonMetaDataList?.map((el) => {
                              if (metaData?.title === el?.title) {
                                return {
                                  ...el,
                                  contents: e.target.value?.replace(
                                    invalidDetailRegex,
                                    '',
                                  ),
                                }
                              }
                              return { ...el }
                            }),
                          )
                        }
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* {JSON.stringify(metaDataInfo)} */}
        </Card>
        {/* 상품 상세 정보 */}
        <Card title='택배사 정보'>
          <SubTitle className='mb-5'>택배사 정보를 입력해 주세요</SubTitle>
          <div>
            <Description>택배사</Description>
            <select
              className='px-2 py-1 mb-3 border-2 border-sub-300 w-80'
              id='shipping_company_item'
              name='shipping_company_item'
              onChange={(e) => {
                const correspondingShippingCompanyInfo =
                  OrderStore.shippingCompaniesInfo.find(
                    (shippingCompanyInfo) =>
                      shippingCompanyInfo?.shippingCompanyCode ===
                      e.target.value,
                  )
                if (correspondingShippingCompanyInfo) {
                  setShippingInfo({
                    ...shippingInfo,
                    shippingCompany:
                      correspondingShippingCompanyInfo.shippingCompanyName,
                  })
                }
              }}
              value={
                OrderStore.shippingCompaniesInfo.find(
                  (shippingCompanyInfo) =>
                    shippingCompanyInfo?.shippingCompanyName ===
                    shippingInfo.shippingCompany,
                )?.shippingCompanyCode
              }
            >
              <option value=''>===</option>
              {OrderStore.shippingCompaniesInfo.map((shippingCompanyInfo) => (
                <option
                  key={shippingCompanyInfo?.shippingCompanyCode}
                  value={shippingCompanyInfo?.shippingCompanyCode}
                >
                  {shippingCompanyInfo?.shippingCompanyName}
                </option>
              ))}
            </select>
          </div>
          <div>
            <Description>배송 소요 시일</Description>
            <input
              className='px-2 py-1 mb-3 border-2 border-sub-300 w-80'
              type='number'
              value={shippingInfo.shippingDays?.toString() || ''}
              onChange={(e) => {
                if (e.target.value !== '') {
                  setShippingInfo({
                    ...shippingInfo,
                    shippingDays: parseInt(e.target.value, 10),
                  })
                } else {
                  setShippingInfo({ ...shippingInfo, shippingDays: undefined })
                }
              }}
            />
          </div>
          <div>
            <Description>배송비용 안내 문구</Description>
            <textarea
              type='text'
              className='px-2 py-1 mb-10 border-2 border-sub-300 w-80'
              placeholder='무료배송· 도서산간 추가 배송비- 제주 지역: 3,000원- 도서산간 지역: 5,000원'
              value={shippingInfo.shippingFeeInfo}
              onChange={(e) => {
                if (e.target.value !== '') {
                  setShippingInfo({
                    ...shippingInfo,
                    shippingFeeInfo: Format.Title(
                      e.target.value.normalize('NFC'),
                    ),
                  })
                }
              }}
            />
          </div>
          <div>
            <div>
              <div className='mb-5'>
                <SubTitle className='mb-5 '>배송비를 입력해주세요</SubTitle>
                <span>
                  올웨이즈의 모든 제품은{' '}
                  <b className='mb-5 text-red-500'>기본 배송비 무료</b>로
                  판매됩니다.
                  <br />
                  <b className='mb-5 text-red-500'>
                    도서산간 지역별 추가 배송비
                  </b>
                  를 설정해주세요.
                </span>
              </div>
              <div>
                <div className='mb-5'>
                  <Description>도서산간 배송비(제주 외)</Description>
                  <input
                    className='py-1 border-2 w-80 border-sub-300'
                    type='number'
                    value={nonJejuFee}
                    onChange={(e) => {
                      setNonJejuFee(e.target.value)
                      setShippingInfo({
                        ...shippingInfo,
                        ruralAreaShippingFeeInfo:
                          shippingInfo.ruralAreaShippingFeeInfo?.map(
                            (shippingInfoTemp, index) => {
                              if (index < 34) {
                                return {
                                  ...shippingInfoTemp,
                                  shippingFee: parseInt(e.target.value, 10),
                                }
                              }
                              return shippingInfoTemp
                            },
                          ),
                      })
                    }}
                  />
                </div>
                <div className='mb-5'>
                  <Description>도서산간 배송비(제주)</Description>
                  <input
                    className='py-1 border-2 w-80 border-sub-300'
                    type='number'
                    value={jejuFee}
                    onChange={(e) => {
                      setJejuFee(e.target.value)
                      setShippingInfo({
                        ...shippingInfo,
                        ruralAreaShippingFeeInfo:
                          shippingInfo.ruralAreaShippingFeeInfo?.map(
                            (shippingInfoTemp, index) => {
                              if (index >= 34) {
                                return {
                                  ...shippingInfoTemp,
                                  shippingFee: parseInt(e.target.value, 10),
                                }
                              }
                              return shippingInfoTemp
                            },
                          ),
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='mb-5 '>
              <Description>단순 변심시 반품 비용(일괄 적용)</Description>
              <Input
                className='w-80'
                type='number'
                placeholder={5000}
                value={shippingInfo.returnFee}
                onChange={(e) => {
                  if (e.target.value !== '') {
                    // console.log(`text: ${e.target.value}`)
                    setShippingInfo({
                      ...shippingInfo,
                      returnFee: parseInt(e.target.value, 10) || undefined,
                    })
                  }
                }}
              />
            </div>
            <div className='w-1/2 mb-5'>
              <Description>반품 비용 안내 문구</Description>
              <textarea
                className='py-1 border-2 w-80 border-sub-300'
                type='text'
                placeholder={`${
                  shippingInfo.returnFee || 0
                } -단, 고객 단순 변심의 경우에만 발생 -도서산간 및 일부 지역 추가비용 발생`}
                value={shippingInfo.returnFeeInfo}
                onChange={(e) => {
                  if (e.target.value !== '') {
                    // console.log(`text: ${e.target.value}`)
                    setShippingInfo({
                      ...shippingInfo,
                      returnFeeInfo: Format.Title(
                        e.target.value.normalize('NFC'),
                      ),
                    })
                  }
                }}
              />
            </div>
          </div>
        </Card>
        {/* 상품이미지 */}
        <Card title='상품이미지' style={{ overflow: 'scroll' }}>
          <div className='flex items-center mb-5 '>
            <SubTitle>상품이미지를 등록해 주세요</SubTitle>
            <TutorialModal
              modalTitle='대표이미지'
              videoSource={Tutorial6}
              modalLog='Tutorial6'
            >
              <AlwayzQuestion />
            </TutorialModal>
          </div>
          <Description>
            좋은 상품이미지는 많을수록 매출에 긍정적인 영향을 줍니다. <br />
            상품에 대한 정보가 담긴 이미지를 많이 올려보세요. (최대 10개)
          </Description>
          <div className='flex flex-col flex-1 mt-8'>
            <div className='flex flex-row flex-1 p-4 border'>
              <div className='w-[240px]'>대표이미지 (필수)</div>
              <div>
                <ImageUploading
                  multiple
                  value={mainImagesData}
                  onChange={(imageList, addUpdateIndex) => {
                    console.log(`addupdate index: ${addUpdateIndex}`)
                    setMainImagesData(imageList)
                  }}
                  maxFileSize={5e6}
                  acceptType={['jpg', 'png', 'jpeg']}
                  resolutionType='more'
                  resolutionWidth={400}
                  resolutionHeight={400}
                  onError={(e) => {
                    if (e.maxFileSize) {
                      alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
                    } else if (e.acceptType) {
                      alert(
                        '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/ jpeg/ png)',
                      )
                    } else if (e.resolution) {
                      alert(
                        '고화질 대표이미지로 더 많은 상품 노출을 획득하세요!\n(최소 400px*400px 이상, 권장 이미지 1200px*1200px)',
                      )
                    }
                  }}
                  allowNonImageType='false'
                  maxNumber={1}
                  dataURLKey='sourceUri'
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div {...dragProps}>
                      {imageList?.length ? (
                        <div className='flex mr-10 w-[140px] h-[140px] relative'>
                          <img
                            alt='img'
                            src={imageList?.[0].sourceUri}
                            className='object-cover object-center border-2 shadow-lg h-[140px] w-[140px] border-sub-300'
                          />
                          <div className='absolute bottom-0 w-full text-sm '>
                            <div className='flex flex-row'>
                              <button
                                className='w-full py-2 text-white bg-slate-800/50 hover:bg-blue-700 '
                                type='button'
                                onClick={() => {
                                  onImageUpdate(0)
                                }}
                              >
                                수정
                              </button>
                              <button
                                className='w-full py-2 text-white bg-slate-800/50 hover:bg-blue-700 '
                                type='button'
                                onClick={() => {
                                  onImageRemove(0)
                                }}
                              >
                                삭제
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className='flex cursor-pointer w-[140px] h-[140px] items-center justify-center border-dashed border bg-gray-50'
                          onClick={onImageUpload}
                        >
                          <PlusComponent />
                        </div>
                      )}
                    </div>
                  )}
                </ImageUploading>
                <div className='mt-2 text-sm text-blue-700'>
                  <div>크기: 400px*400px 이상, 1,200px*1,200px 권장</div>
                  <div>용량: 5MB 이하, 파일 형식 JPG, JPEG, PNG</div>
                  <div>*흰색 배경 권장</div>
                </div>
              </div>
            </div>
            <div className='flex flex-row flex-1 p-4 border'>
              <div className='w-[240px]'>
                추가이미지 ({Math.max(mainAdditionalImagesData?.length || 0, 0)}
                /9)
              </div>
              <div>
                <ImageUploading
                  multiple
                  value={mainAdditionalImagesData}
                  onChange={(imageList, addUpdateIndex) => {
                    console.log(`addupdate index: ${addUpdateIndex}`)
                    setMainAdditionalImagesData(imageList)
                  }}
                  maxFileSize={5e6}
                  acceptType={['jpg', 'png', 'jpeg']}
                  resolutionType='more'
                  resolutionWidth={400}
                  resolutionHeight={400}
                  onError={(e) => {
                    if (e.maxFileSize) {
                      alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
                    } else if (e.acceptType) {
                      alert(
                        '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/ jpeg/ png)',
                      )
                    } else if (e.resolution) {
                      alert(
                        '고화질 대표이미지로 더 많은 상품 노출을 획득하세요!\n(최소 400px*400px 이상, 권장 이미지 1200px*1200px)',
                      )
                    }
                  }}
                  allowNonImageType='false'
                  maxNumber={9}
                  dataURLKey='sourceUri'
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div {...dragProps}>
                      <div className='flex flex-row overflow-x-auto max-w-[720px] '>
                        {[...imageList, { PlusComponent: true }].map(
                          (image, index) => {
                            if (image?.PlusComponent) {
                              if (index <= 8) {
                                return (
                                  <div
                                    className='flex cursor-pointer w-[140px] h-[140px] items-center justify-center border-dashed border bg-gray-50'
                                    onClick={onImageUpload}
                                  >
                                    <PlusComponent />
                                  </div>
                                )
                              }
                              return null
                            }
                            return (
                              <div key={index?.toString()}>
                                <div className='flex mr-4 w-[140px] h-[140px] relative'>
                                  <img
                                    alt='img'
                                    src={image.sourceUri}
                                    className='object-cover object-center border-2 shadow-lg h-[140px] w-[140px] border-sub-300'
                                  />
                                  <div className='absolute bottom-0 w-full text-sm '>
                                    <div className='flex flex-row'>
                                      <button
                                        className='w-full py-2 text-white bg-slate-800/50 hover:bg-blue-700 '
                                        type='button'
                                        onClick={() => {
                                          onImageUpdate(index)
                                        }}
                                      >
                                        수정
                                      </button>
                                      <button
                                        className='w-full py-2 text-white bg-slate-800/50 hover:bg-blue-700 '
                                        type='button'
                                        onClick={() => {
                                          onImageRemove(index)
                                        }}
                                      >
                                        삭제
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          },
                        )}
                      </div>
                    </div>
                  )}
                </ImageUploading>
                <div className='mt-2 text-sm text-blue-700'>
                  <div>크기: 400px*400px 이상, 1,200px*1,200px 권장</div>
                  <div>용량: 5MB 이하, 파일 형식 JPG, JPEG, PNG</div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        {/* 상품 상세페이지 */}
        <Card title='상품 상세페이지'>
          <div className='flex items-center mb-5'>
            <SubTitle>상세페이지 이미지를 등록해 주세요</SubTitle>
            <TutorialModal modalTitle='상세페이지' videoSource={Tutorial7}>
              <AlwayzQuestion />
            </TutorialModal>
          </div>
          <div className='ml-3 text-base'>
            <div className='mb-2'>
              1. 단일 이미지 최대 12MB, jpg/ jpeg/ png/ gif 허용
            </div>
            <div className='mb-2'>
              2. (패션)시착, (생활가전)사용 예, (식품)조리 후, (전 품목)포장배송
              샷/품질인증서/성분표/특허사항 등 포함
            </div>
            <div className='mb-2'>
              3. 실 상품과 다르거나, 정확하지 않은 정보로 구매자에게 혼란을 주는
              서술은 금지함
            </div>
            <div className='mb-2'>
              4. 본인에게 저작권이 없는 이미지 사용시 발생하는 모든 책임은
              사용자에게 있음
            </div>
            <img
              alt='Detail'
              src={DetailPageExample}
              className='flex w-4/5 mb-2'
            />
          </div>

          <div style={{ width: 500 }}>
            {detailHTML?.length > 0 ? (
              <div className='flex flex-1 w-full flex-col h-[800px]]'>
                <div className='mb-5'>
                  외부 연동 상품의 경우 상세페이지가 HTML로 등록되어 있습니다.
                  이미지 편집을 희망하실 경우 삭제 후 이미지를 재등록해주셔야
                  합니다.
                </div>
                <Button
                  className='mb-5'
                  onClick={() => {
                    setDetailHTML('')
                  }}
                >
                  이미지 수정하기
                </Button>
                <HtmlViewer html={detailHTML.replace(`br<br`, '<br/><br/>')} />
              </div>
            ) : (
              <ImageUploading
                multiple
                value={detailImagesData}
                onChange={(imageList, addUpdateIndex) => {
                  setDetailImagesData(imageList)
                }}
                maxFileSize={12e6}
                acceptType={['jpg', 'png', 'jpeg', 'gif']}
                maxNumber={200}
                dataURLKey='sourceUri'
                onError={(e) => {
                  if (e.maxFileSize) {
                    alert('이미지 파일 용량을 확인해주세요.(개당 최대 12MB)')
                  } else if (e.acceptType) {
                    alert(
                      '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg /jpeg /png /gif)',
                    )
                  }
                }}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <div className='upload__image-wrapper' {...dragProps}>
                    <Button
                      appearance='neutralSub'
                      type='button'
                      onClick={onImageUpload}
                    >
                      사진 추가하기
                    </Button>
                    <div>
                      <>
                        <div className='flex items-center justify-center h-20 my-5 bg-gray-100 rounded-md shadow-md w-96'>
                          {isDragging ? (
                            <>
                              <FcOpenedFolder className='w-5 h-5 mr-1' />
                              <span>사진을 놓으시면 됩니다.</span>
                            </>
                          ) : (
                            <>
                              <FcFolder className='w-5 h-5 mr-1' />
                              <span>사진을 끌어다 놓으실 수도 있어요.</span>
                            </>
                          )}
                        </div>
                        {detailImagesData.map((image, index) => (
                          <DetailImageUpdateComponent
                            image={image}
                            index={index}
                            detailImagesData={detailImagesData}
                            setDetailImagesData={setDetailImagesData}
                            key={image.sourceUri + index.toString()}
                          />
                        ))}
                      </>
                    </div>
                  </div>
                )}
              </ImageUploading>
            )}
          </div>
        </Card>
        {/* 상품 키워드 */}
        <Card title='상품 키워드'>
          <div className='flex items-center mb-5 '>
            <SubTitle>상품 키워드를 입력해주세요</SubTitle>
            <TutorialModal modalTitle='상품 키워드' videoSource={Tutorial8}>
              <AlwayzQuestion />
            </TutorialModal>
          </div>
          <b style={{ marginTop: -20, color: 'red' }}>
            *검색 결과에 노출되도록 5개 이하 입력해주세요
          </b>
          <div>
            {keyWords.map((each, i) => (
              <Tag
                key={`keyword${encodeURI(each)}${i}`}
                text={each}
                onClick={
                  i < 4
                    ? null
                    : () => {
                        const textForQuery = [
                          ...keyWords.filter((v, idx) => idx !== i),
                        ].join(', ')
                        ItemStore.setItemInfoOnEdit({
                          ...ItemStore.itemInfoOnEdit,
                          textForQuery,
                        })
                      }
                }
              />
            ))}
            {keyWords?.length < 9 && (
              <form
                onSubmit={(e) => {
                  // setKeyWords(e.target.value.normalize('NFC'))
                  e.preventDefault()
                  const keyword = Format.Title(
                    e.target[0].value.normalize('NFC'),
                  )
                  if (!keyword) alert('키워드를 입력해주세요.')
                  if (keyWords.indexOf(keyword) >= 0)
                    alert('이미 추가한 키워드입니다.')
                  else if (keyword) {
                    const textForQuery = [...keyWords, keyword].join(', ')
                    ItemStore.setItemInfoOnEdit({
                      ...ItemStore.itemInfoOnEdit,
                      textForQuery,
                    })
                  }
                  e.target[0].value = ''
                }}
              >
                <input
                  className='w-1/2 py-1 border-2 border-sub-300'
                  name='keyword'
                  type='text'
                />
                <input
                  className='px-2 py-1 ml-2 text-base font-bold text-white border-b-2 border-r-2 rounded-lg shadow-md cursor-pointer border-neutral-700 bg-neutral-500 hover:bg-neutral-700'
                  type='submit'
                  value='추가'
                />
              </form>
            )}
          </div>

          <Description className='mt-5'>1인 최대 구매 제한 개수</Description>
          <Input
            className='w-1/2'
            disabled={!AuthStore.isMasterAccount}
            type='number'
            placeholder='10'
            value={ItemStore.itemInfoOnEdit.purchaseLimits || '10'}
            onChange={(e) => {
              if (AuthStore.isMasterAccount) {
                // console.log(`text: ${e.target.value}`)
                ItemStore.setItemInfoOnEdit({
                  ...ItemStore.itemInfoOnEdit,
                  purchaseLimits: parseInt(e.target.value, 10) || 10,
                })
              }
            }}
          />

          <Description className='mt-5'>팀구매 인원</Description>
          <Input
            className='w-1/2'
            disabled={!AuthStore.isMasterAccount}
            type='number'
            placeholder='2'
            value={ItemStore.itemInfoOnEdit.teamDealThreshold || '2'}
            onChange={(e) => {
              if (AuthStore.isMasterAccount) {
                // console.log(`text: ${e.target.value}`)
                ItemStore.setItemInfoOnEdit({
                  ...ItemStore.itemInfoOnEdit,
                  teamDealThreshold: parseInt(e.target.value, 10) || 2,
                })
              }
            }}
          />
        </Card>
        {/* 상품 추천 코드 */}
        <RegisterItemRecommendCard
          recommendItemCode={recommendItemCode}
          setRecommendItemCode={setRecommendItemCode}
        />
        <div className='flex flex-row items-center justify-center flex-1'>
          <Button
            size='xxl'
            className='mt-5'
            type='button'
            onClick={async () => {
              if (
                data?.itemInfo?.characteristicItem?.isAlFarmItem &&
                !AuthStore?.isMasterAccount
              ) {
                alert(
                  `올팜 상품은 직접 수정할 수 없습니다. 담당 매니저에게 문의바랍니다.`,
                )
                return
              }
              if (data.itemInfo?.categoryInfo?.minimumCategoryId >= 0) {
                if (!selectedMinimumCategory?.endNode) {
                  alert(`카테고리를 더 세밀하게 선택해주세요!`)
                  return
                }
              } else if (
                largeCategoryInfo._id === undefined ||
                mediumCategoryInfo._id === undefined ||
                smallCategoryInfo._id === undefined
              ) {
                alert(`카테고리 설정을 다시 확인해주세요!`)
                return
              }

              if (!ItemStore.itemInfoOnEdit.itemTitle) {
                alert(`상품명을 확인해주세요.`)
                return
              }
              if (
                shippingInfo.returnFee === undefined ||
                !shippingInfo.returnFeeInfo ||
                !shippingInfo.shippingFeeInfo
              ) {
                alert(
                  `배송비용 안내 문구, 반품 비용, 혹은 반품 비용 안내 문구를 확인해주세요.`,
                )
                return
              }
              if (
                shippingInfo.shippingDays === undefined ||
                shippingInfo.shippingCompany === undefined
              ) {
                alert('배송일 혹은 택배사를 확인해주세요.')
                return
              }
              if (shippingInfo?.shippingDays <= 0) {
                alert('배송에 걸리는 시간은 하루보다 짧을 수 없습니다.')
                return
              }
              if (shippingInfo?.shippingDays >= 14) {
                alert('배송에 걸리는 시간은 최대 14일을 넘기지 않아야 합니다.')
                return
              }
              if (
                optionsInfo.optionNames.length === 0 ||
                optionsInfo.optionNames.some((name) => !name) ||
                optionsInfo.totalOptions.some((optionsArray) =>
                  optionsArray.some((option) => !option.name),
                )
              ) {
                alert(`옵션 정보를 확인해주세요.`)
                return
              }
              if (mainImagesData.length === 0) {
                alert('상품 메인 이미지를 업로드해주세요.')
                return
              }
              if (detailImagesData.length === 0 && !detailHTML) {
                alert('상품 상세페이지를 업로드해주세요.')
                return
              }

              let optionPrices = [...optionsInfo.optionPrices]
              let finalOptionPrices = [...optionsInfo.optionPrices]
              let testOption = optionPrices
              while (testOption.length) {
                finalOptionPrices = []

                optionPrices.forEach((option) => {
                  finalOptionPrices = finalOptionPrices.concat(option)
                })
                optionPrices = [...finalOptionPrices]
                testOption = finalOptionPrices[0]
              }
              const soldOutResult = finalOptionPrices.every(
                (option) => option.sold_out,
              )
              const teamPurchasePriceResult = finalOptionPrices.some(
                (option) => !option.teamPurchasePrice,
              )
              const individualPurchasePriceResult = finalOptionPrices.some(
                (option) => !option.individualPurchasePrice,
              )
              if (
                !AuthStore?.isMasterAccount &&
                (soldOutResult ||
                  teamPurchasePriceResult ||
                  individualPurchasePriceResult)
              ) {
                alert(`재고 혹은 판매가격을 다시 확인해주세요.`)
                return
              }
              if (
                finalOptionPrices.some((optionTemp) => {
                  return (
                    optionTemp?.teamPurchasePrice >=
                    0.9 * optionTemp?.individualPurchasePrice
                  )
                })
              ) {
                alert(
                  `팀구매가는 개인구매가보다 저렴해야 합니다. 옵션 가격을 다시 확인해주세요.`,
                )
                return
              }
              const hurryUpResult = finalOptionPrices.some(
                (option) => option.hurry_up,
              )
              const teamPurchasePrice = Math.min(
                ...finalOptionPrices
                  .filter((option) => !option.sold_out)
                  .map((option) => option.teamPurchasePrice),
              )
              const individualPurchasePrice = Math.min(
                ...finalOptionPrices
                  .filter((option) => !option.sold_out)
                  .map((option) => option.individualPurchasePrice),
              )
              const discountRate =
                1 - teamPurchasePrice / individualPurchasePrice

              const itemRankTestingSku = false
              if (discountRate < 0.1) {
                alert(
                  `개인구매가 대비 팀구매가의 할인율을 다시 책정해주세요. 개인구매가에 비해 상대적으로 팀구매가가 저렴할 때 할인율이 높아져서 매력적인 상품으로 보일 수 있습니다.`,
                )
                return
              }
              if (parseInt(teamPurchasePrice, 10) < 2500) {
                window.confirm(
                  '입력해주신 팀구매가가 2500원 이하입니다. 등록하시겠습니까?(올웨이즈의 모든 제품은 무료배송입니다.)',
                )
              }
              if (
                Math.min(
                  ...finalOptionPrices.map(
                    (option) => option.teamPurchasePrice,
                  ),
                ) !==
                Math.min(
                  ...finalOptionPrices
                    .filter((option) => !option.sold_out)
                    .map((option) => option.teamPurchasePrice),
                )
              ) {
                alert(
                  `판매 가능한(품절 아닌) 옵션의 최저가가 판매가로 노출됩니다. 품절 옵션을 확인해주세요`,
                )
                return
              }

              // if (
              //   optionsInfo?.optionPrices?.some(
              //     (firstOption, firstOptionIndex) => {
              //       if (Array.isArray(firstOption)) {
              //         return firstOption?.some((firstOptionTemp) => {
              //           if (
              //             firstOptionTemp?.optionMetaDataInfoList?.some(
              //               (metaDataTemp) => {
              //                 return (
              //                   metaDataTemp?.isNecessary === true &&
              //                   !metaDataTemp?.basicOption &&
              //                   !metaDataTemp?.contents
              //                 )
              //               },
              //             )
              //           ) {
              //             return true
              //           }
              //           return false
              //         })
              //       }
              //       if (
              //         firstOption?.optionMetaDataInfoList?.some(
              //           (metaDataTemp) => {
              //             return (
              //               metaDataTemp?.isNecessary === true &&
              //               !metaDataTemp?.basicOption &&
              //               !metaDataTemp?.contents
              //             )
              //           },
              //         )
              //       ) {
              //         return true
              //       }
              //       return false
              //     },
              //   )
              // ) {
              //   alert(
              //     '옵션 정보를 정확하게 기입해주세요. *8월 9일 이후로 옵션 정보 입력 정책이 변경되었습니다. 기존 등록 상품을 수정하실 경우 옵션 정보를 다시 입력해주세요.',
              //   )
              //   return
              // }

              // if (
              //   optionsInfo?.optionPrices?.some(
              //     (firstOption, firstOptionIndex) => {
              //       if (Array.isArray(firstOption)) {
              //         return firstOption?.some((firstOptionTemp) => {
              //           if (
              //             firstOptionTemp?.optionMetaDataInfoList?.some(
              //               (metaDataTemp) => {
              //                 return (
              //                   metaDataTemp?.contentsUnits?.length > 0 &&
              //                   metaDataTemp?.isNecessary === true &&
              //                   !metaDataTemp?.contentsUnit
              //                 )
              //               },
              //             )
              //           ) {
              //             return true
              //           }
              //           return false
              //         })
              //       }
              //       if (
              //         firstOption?.optionMetaDataInfoList?.some(
              //           (metaDataTemp) => {
              //             return (
              //               metaDataTemp?.contentsUnits?.length > 0 &&
              //               metaDataTemp?.isNecessary === true &&
              //               !metaDataTemp?.contentsUnit
              //             )
              //           },
              //         )
              //       ) {
              //         return true
              //       }
              //       return false
              //     },
              //   )
              // ) {
              //   alert('단위를 선택해주세요.')
              //   return
              // }

              // if (
              //   commonMetaDataList?.some((firstOption, firstOptionIndex) => {
              //     if (
              //       commonMetaDataList?.some((metaDataTemp) => {
              //         return (
              //           metaDataTemp?.isNecessary === true &&
              //           !metaDataTemp?.contents &&
              //           !metaDataTemp?.basicOption
              //         )
              //       })
              //     ) {
              //       return true
              //     }
              //     return false
              //   })
              // ) {
              //   alert('상품 기본 정보를 기입해주세요.')
              //   return
              // }

              // if (
              //   productMetaDataList?.some((firstOption, firstOptionIndex) => {
              //     if (
              //       productMetaDataList?.some((metaDataTemp) => {
              //         return (
              //           metaDataTemp?.isNecessary === true &&
              //           !metaDataTemp?.contents &&
              //           !metaDataTemp?.basicOption
              //         )
              //       })
              //     ) {
              //       return true
              //     }
              //     return false
              //   })
              // ) {
              //   alert('상품 기본 정보를 기입해주세요.')
              //   return
              // }
              // const invalidWeightRegex =
              //   /(\*|x|~|-|상세|페이지|참고|참조|상세페이지참고|상세페이지참조|상세이미지참조|상세이미지참고|X|\(|\))/
              // optionsInfo?.optionPrices?.some(
              //   (firstOption, firstOptionIndex) => {
              //     if (Array.isArray(firstOption)) {
              //       return firstOption?.some((firstOptionTemp) => {
              //         if (
              //           firstOptionTemp?.optionMetaDataInfoList?.some(
              //             (metaDataTemp) => {
              //               return invalidWeightRegex?.test(
              //                 metaDataTemp?.contents &&
              //                   metaDataTemp?.contentsUnit,
              //               )
              //             },
              //           )
              //         ) {
              //           return true
              //         }
              //         return false
              //       })
              //     }

              //     if (
              //       firstOption?.optionMetaDataInfoList?.some(
              //         (metaDataTemp) => {
              //           return invalidWeightRegex?.test(
              //             metaDataTemp?.contents && metaDataTemp?.contentsUnit,
              //           )
              //         },
              //       )
              //     ) {
              //       return true
              //     }
              //     return false
              //   },
              // )
              // if (
              //   optionsInfo?.optionPrices?.some(
              //     (firstOption, firstOptionIndex) => {
              //       if (Array.isArray(firstOption)) {
              //         return firstOption?.some((firstOptionTemp) => {
              //           if (
              //             firstOptionTemp?.optionMetaDataInfoList?.some(
              //               (metaDataTemp) => {
              //                 return (
              //                   metaDataTemp?.title !== '모델명' &&
              //                   invalidWeightRegex?.test(
              //                     metaDataTemp?.contents &&
              //                       metaDataTemp?.contentsUnit,
              //                   )
              //                 )
              //               },
              //             )
              //           ) {
              //             return true
              //           }
              //           return false
              //         })
              //       }

              //       if (
              //         firstOption?.optionMetaDataInfoList?.some(
              //           (metaDataTemp) => {
              //             return (
              //               metaDataTemp?.title !== '모델명' &&
              //               invalidWeightRegex?.test(
              //                 metaDataTemp?.contents &&
              //                   metaDataTemp?.contentsUnit,
              //               )
              //             )
              //           },
              //         )
              //       ) {
              //         return true
              //       }
              //       return false
              //     },
              //   )
              // ) {
              //   alert(
              //     '상품 주요 정보를 수정해주세요 (*, x, ~, (), "상세페이지참고"는 입력 불가합니다)',
              //   )
              //   return
              // }

              // const invalidContentsRegex = /([^\d]+)/
              // optionsInfo?.optionPrices?.some(
              //   (firstOption, firstOptionIndex) => {
              //     if (Array.isArray(firstOption)) {
              //       return firstOption?.some((firstOptionTemp) => {
              //         if (
              //           firstOptionTemp?.optionMetaDataInfoList?.some(
              //             (metaDataTemp) => {
              //               return (
              //                 metaDataTemp?.contentsUnits?.length > 0 &&
              //                 metaDataTemp?.isNecessary === true &&
              //                 invalidContentsRegex?.test(metaDataTemp?.contents)
              //               )
              //             },
              //           )
              //         ) {
              //           return true
              //         }
              //         return false
              //       })
              //     }

              //     if (
              //       firstOption?.optionMetaDataInfoList?.some(
              //         (metaDataTemp) => {
              //           return (
              //             metaDataTemp?.contentsUnits?.length > 0 &&
              //             metaDataTemp?.isNecessary === true &&
              //             invalidContentsRegex?.test(metaDataTemp?.contents)
              //           )
              //         },
              //       )
              //     ) {
              //       return true
              //     }
              //     return false
              //   },
              // )
              // if (
              //   optionsInfo?.optionPrices?.some(
              //     (firstOption, firstOptionIndex) => {
              //       if (Array.isArray(firstOption)) {
              //         return firstOption?.some((firstOptionTemp) => {
              //           if (
              //             firstOptionTemp?.optionMetaDataInfoList?.some(
              //               (metaDataTemp) => {
              //                 return (
              //                   metaDataTemp?.contentsUnits?.length > 0 &&
              //                   metaDataTemp?.isNecessary === true &&
              //                   metaDataTemp?.title !== '모델명' &&
              //                   invalidContentsRegex?.test(
              //                     metaDataTemp?.contents,
              //                   )
              //                 )
              //               },
              //             )
              //           ) {
              //             return true
              //           }
              //           return false
              //         })
              //       }

              //       if (
              //         firstOption?.optionMetaDataInfoList?.some(
              //           (metaDataTemp) => {
              //             return (
              //               metaDataTemp?.contentsUnits?.length > 0 &&
              //               metaDataTemp?.isNecessary === true &&
              //               metaDataTemp?.title !== '모델명' &&
              //               invalidContentsRegex?.test(metaDataTemp?.contents)
              //             )
              //           },
              //         )
              //       ) {
              //         return true
              //       }
              //       return false
              //     },
              //   )
              // ) {
              //   alert('단위가 있는 상품 주요 정보 입력란에는 숫자만 넣어주세요')
              //   return
              // }
              // const invalidContentsUnitRegex = /([0-9])/
              // optionsInfo?.optionPrices?.some(
              //   (firstOption, firstOptionIndex) => {
              //     if (Array.isArray(firstOption)) {
              //       return firstOption?.some((firstOptionTemp) => {
              //         if (
              //           // firstOptionTemp?.contentsUnitExtra === '기타' &&
              //           firstOptionTemp?.optionMetaDataInfoList?.some(
              //             (metaDataTemp) => {
              //               return invalidContentsUnitRegex?.test(
              //                 metaDataTemp?.contentsUnit,
              //               )
              //             },
              //           )
              //         ) {
              //           return true
              //         }
              //         return false
              //       })
              //     }

              //     if (
              //       // firstOption?.contentsUnitExtra === '기타' &&
              //       firstOption?.optionMetaDataInfoList?.some(
              //         (metaDataTemp) => {
              //           return invalidContentsUnitRegex?.test(
              //             metaDataTemp?.contentsUnit,
              //           )
              //         },
              //       )
              //     ) {
              //       return true
              //     }
              //     return false
              //   },
              // )
              // if (
              //   optionsInfo?.optionPrices?.some(
              //     (firstOption, firstOptionIndex) => {
              //       if (Array.isArray(firstOption)) {
              //         return firstOption?.some((firstOptionTemp) => {
              //           if (
              //             // firstOptionTemp?.contentsUnitExtra === '기타' &&
              //             firstOptionTemp?.optionMetaDataInfoList?.some(
              //               (metaDataTemp) => {
              //                 return (
              //                   metaDataTemp?.title !== '모델명' &&
              //                   invalidContentsUnitRegex?.test(
              //                     metaDataTemp?.contentsUnit,
              //                   )
              //                 )
              //               },
              //             )
              //           ) {
              //             return true
              //           }
              //           return false
              //         })
              //       }

              //       if (
              //         // firstOption?.contentsUnitExtra === '기타' &&
              //         firstOption?.optionMetaDataInfoList?.some(
              //           (metaDataTemp) => {
              //             return (
              //               metaDataTemp?.title !== '모델명' &&
              //               invalidContentsUnitRegex?.test(
              //                 metaDataTemp?.contentsUnit,
              //               )
              //             )
              //           },
              //         )
              //       ) {
              //         return true
              //       }
              //       return false
              //     },
              //   )
              // ) {
              //   alert('단위에는 단위 정보를 제외한 숫자 등은 제외해주세요.')
              //   return
              // }
              let itemInfo = {
                ...ItemStore.itemInfoOnEdit,
                optionsInfo,
                mainImageUris: [],
                detailImageUris: [],
                shippingInfo,
                commonMetaDataInfoList: commonMetaDataList,
                productMetaDataInfoList: productMetaDataList,
                soldOut: !!ItemStore.itemInfoOnEdit?.soldOut,
                hurryUp: hurryUpResult,
                totalOrderQuantity: 0,
                totalRatingCount: 0,
                totalRatingScore: 0,
                teamPurchasePrice,
                individualPurchasePrice,
                discountRate,
                purchasingUsersInfo: [],
                hideSellerInfo: [
                  '60eeeef9a833f92af03805e8',
                  '61b89ca6c74c1e3242900151',
                ].includes(SellerStore.sellerInfo._id),
                vectorWeights: 1,
                sellerInfo: {
                  sellerId: SellerStore.sellerInfo._id,
                  address: SellerStore.sellerInfo.address,
                  businessRegistrationNumber:
                    SellerStore.sellerInfo.businessRegistrationNumber,
                  contactNumber: SellerStore.sellerInfo.contactNumber,
                  email: SellerStore.sellerInfo.email,
                  mailOrderBusinessNumber:
                    SellerStore.sellerInfo.mailOrderBusinessNumber,
                  sellerDisplayName: SellerStore.sellerInfo.sellerDisplayName,
                },
                coupangRocketLink,
                naverLowestLink,
                itemRankTestingSku,
                recommendItemCode,
              }

              if (selectedMinimumCategory) {
                itemInfo.categoryInfo.minimumCategoryId =
                  selectedMinimumCategory._id
              }

              if (
                largeCategoryInfo?._id >= 0 &&
                mediumCategoryInfo?._id >= 0 &&
                smallCategoryInfo?._id >= 0
              ) {
                itemInfo.categoryInfo.largeCategoryId = largeCategoryInfo._id
                itemInfo.categoryInfo.mediumCategoryId = mediumCategoryInfo._id
                itemInfo.categoryInfo.smallCategoryId = smallCategoryInfo._id
              }

              if (teamPurchasePrice !== originalTeamPurchasePrice) {
                alert(
                  '대표 가격 변경시 상품 등급 테스트가 시작됩니다. 테스트 중에도 판매는 정상적으로 진행됩니다.',
                )
                itemInfo.itemRankTestingSku = true
                itemInfo = { ...itemInfo, crawledItemTheLowest: {} }
              }
              SellerStore.setIsLoading(true)

              const isOptionChanged =
                optionsInfo?.totalOptions?.length !==
                originalOptionsInfo?.totalOptions?.length
              const isPriceChanged = optionsInfo?.optionPrices?.some(
                (option, i) =>
                  option?.teamPurchasePrice !==
                  originalOptionsInfo?.optionPrices[i]?.teamPurchasePrice,
              )

              // 개수 및 가격 변경 여부 체크하기
              if (isOptionChanged || isPriceChanged) {
                if (
                  !window.confirm(
                    '상품의 가격을 변경하거나 옵션을 변경하는 경우, 10분핫딜/순삭특가/타임특가를 비롯한 모든 특가 신청이 취소됩니다. 특가 가격 어뷰징 적발시 페널티 부과됩니다. 계속 진행하시겠습니까?',
                  )
                ) {
                  return
                }
              }

              await backendApis.recordSellerAutonomousPriceChange(
                itemInfo._id,
                originalOptionsInfo,
                itemInfo.optionsInfo,
                originalTeamPurchasePrice,
                itemInfo.teamPurchasePrice,
                null,
                null,
                'itemDetailScreen',
              )

              const result = await backendApis.updateItemInfo(itemInfo)

              if (result?.status === 2000) {
                const [mainImageUploadResult, detailImageUploadResult] =
                  await Promise.all([
                    backendApis.uploadItemMainImagesForUpdate(
                      ItemStore.itemInfoOnEdit._id,
                      [...mainImagesData, ...mainAdditionalImagesData],
                    ),
                    backendApis.uploadItemDetailImagesForUpdate(
                      ItemStore.itemInfoOnEdit._id,
                      detailImagesData,
                    ),
                  ])
                let dealDeleteResult
                if (isOptionChanged || isPriceChanged) {
                  dealDeleteResult = await backendApis.deletePreviousDeals(
                    itemInfo?._id,
                    'seller changed price or option',
                  )
                }
                SellerStore.setIsLoading(false)
                setLargeCategoryInfo({})
                setMediumCategoryInfo({})
                setSmallCategoryInfo({})
                setShippingInfo({
                  freeShipping: true,
                  shippingMethod: '순차배송',
                  shippingFee: 0,
                  shippingFeeInfo:
                    '무료배송· 도서산간 추가 배송비- 제주 지역: 3,000원- 도서산간 지역: 5,000원',
                  returnFee: 5000,
                  returnFeeInfo:
                    '5,000원- 단, 고객 변심의 경우에만 발생- 도서산간 및 일부 지역 추가비용 발생',
                  ruralAreaShippingFeeInfo: ItemStore.shippingFeesTemplate,
                })
                setOptionsInfo({
                  optionNames: [''],
                  totalOptions: [
                    [
                      {
                        name: '',
                        img: null,
                      },
                    ],
                  ],
                  optionPrices: [
                    {
                      individualPurchasePrice: 0,
                      teamPurchasePrice: 0,
                      aiPrice: 0,
                      stockNumber: 100,
                      sold_out: false,
                      hurry_up: false,
                      optionMetaDataInfoList: [],
                    },
                  ],
                })
                setMainImagesData([])
                setMainAdditionalImagesData([])
                setDetailImagesData([])
                setCommonMetaDataList([])
                setProductMetaDataList([])
                setOptionMetaDataList([])
                setCoupangRocketLink('')
                setNaverLowestLink('')
                ItemStore.setItemInfoOnEdit({})
                SellerStore.setIsLoading(false)
                // if (reApplyDeal && dealDeleteResult?.status === 200) {
                //   setItemInfo(itemInfo)
                //   setOpenDealReApplicationModal(true)
                // } else {
                alert(`상품 수정이 완료되었습니다.`)
                history.push('/items')
                // }
                return
              }
              alert(
                '상품 수정 과정에서 문제가 발생했습니다. 잠시 후 다시 시도해주세요.',
              )
            }}
          >
            상품 등록/수정 완료
          </Button>
        </div>
      </Page>
      {/* fix me */}
      {/* {openDealReApplicationModal && (
        <DealReApplicationModal
          showModal={openDealReApplicationModal}
          setShowModal={setOpenDealReApplicationModal}
          itemInfo={itemInfo}
          applied={false}
          reApplyType='tenMinDealReApply'
        />
      )} */}
    </div>
  )
})

export default ItemDetailScreen

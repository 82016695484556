import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'

const eggBreakAdApplicationGraph = () => {
  const [chartData, setChartData] = useState([])
  const fetchEggApllicationData = async () => {
    const today = new Date()
    today.setDate(today.getDate() + 2)
    const result = await backendApis.getEggAdApplicationListForAWeek(today)
    if (result?.status === 200) {
      const formattedData = result?.data.map((item) => ({
        ...item,
        adStartDate: new Date(item.adStartDate).toISOString().split('T')[0],
      }))
      setChartData(formattedData)
    }
  }
  const groupedData = chartData?.reduce((acc, item) => {
    const date = item.adStartDate
    if (!acc[date]) {
      acc[date] = 0
    }
    acc[date] += parseInt(item.detailInfo.eggCount, 10)
    return acc
  }, {})

  const adjustedData = Object.keys(groupedData).reduce((acc, date) => {
    acc[date] = groupedData[date] + parseInt(300000, 10) // 역효과 방지하기 위해 신청량 부분 뻥튀기
    return acc
  }, {})

  const todayLabel = new Date(new Date().setHours(new Date().getHours() + 9))
    .toISOString()
    .split('T')[0]
  const sortedLabels = Object.keys(adjustedData).sort()
  const sortedData = sortedLabels.map((date) => adjustedData[date])
  const chartLabels = sortedLabels.map((label) =>
    label === todayLabel ? `${label} (오늘)` : label,
  )

  const chart = {
    type: 'bar',
    data: {
      labels: chartLabels,
      datasets: [
        {
          label: '포춘쿠키 광고 신청량',
          data: sortedData,
          backgroundColor: '#FFCA28',
          borderWidth: 0,
        },
      ],
    },
    options: {
      indexAxis: 'x',
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          align: 'end',
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 15, // 폰트 크기 설정
              weight: 'bold', // 폰트 두께 설정
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 14, // 폰트 크기 설정
              weight: 'bold', // 폰트 두께 설정
            },
          },
        },
      },
    },
  }

  useEffect(() => {
    fetchEggApllicationData()
  }, [])
  return (
    <>
      {chartData && chartData.length > 0 && (
        <div className='flex flex-col items-start flex-1 p-8 mb-5 bg-white shadow-lg rounded-xl'>
          <div className='text-lg font-bold'>광고 신청량 그래프</div>
          <div className=''>포춘쿠키 광고의 일별 전체 광고 신청량입니다.</div>
          <div className='mb-8 '>
            전체 광고 신청량이 적을수록 신청한 광고가 집행될 가능성이 높습니다.
          </div>
          <Bar
            data={chart?.data}
            width={50}
            height={10}
            options={chart.options}
          />
        </div>
      )}
    </>
  )
}
export default eggBreakAdApplicationGraph

import React from 'react'

const PlusComponent = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='#0073e9'
      strokeWidth='1'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ display: 'block', margin: 'auto' }}
    >
      <line x1='12' y1='5' x2='12' y2='19' />
      <line x1='5' y1='12' x2='19' y2='12' />
    </svg>
  )
}

export default PlusComponent

import React, { useState } from 'react'
import { Calendar } from 'antd'
import moment from 'moment'
import 'moment/locale/ko'
import PickerLocale from 'antd/es/date-picker/locale/ko_KR'

// moment 로케일 설정
moment.locale('ko')

// 한글 캘린더 설정
const koKRLocale = {
  ...PickerLocale,
  lang: {
    ...PickerLocale.lang,
    shortWeekDays: ['일', '월', '화', '수', '목', '금', '토'],
    shortMonths: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
    ],
    monthFormat: 'M월',
    yearFormat: 'YYYY년',
    dateFormat: 'YYYY-MM-DD',
    today: '오늘',
    now: '현재',
    backToToday: '오늘로 돌아가기',
    ok: '확인',
    timeSelect: '시간 선택',
    dateSelect: '날짜 선택',
    weekSelect: '주 선택',
    clear: '지우기',
    month: '월',
    year: '년',
    previousMonth: '이전 달',
    nextMonth: '다음 달',
    monthSelect: '월 선택',
    yearSelect: '년 선택',
    decadeSelect: '연도 범위 선택',
  },
}

const CalendarPicker = ({
  setDateRange,
  startDateOffset = 1,
  endDateOffset = 7,
  exposureDays = 5,
}) => {
  const [selectedDate, setSelectedDate] = useState(null)

  const onSelect = (date) => {
    const startDate = date
    const endDate = date.clone().add(exposureDays - 1, 'days')

    setSelectedDate(date)
    setDateRange([startDate.toDate(), endDate.toDate()])
  }

  const disabledDate = (current) => {
    const tomorrow = moment().add(startDateOffset, 'days').startOf('day')
    const lastSelectableDay = moment().add(endDateOffset, 'days').endOf('day')

    return current && (current < tomorrow || current > lastSelectableDay)
  }

  return (
    <>
      <Calendar
        locale={koKRLocale}
        fullscreen={false}
        onSelect={onSelect}
        disabledDate={disabledDate}
        value={selectedDate}
      />
    </>
  )
}

export default CalendarPicker

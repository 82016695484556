import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import Button from './button'
import { useState } from 'react'
import { nanoid } from 'nanoid'

export default function PromotionSuggestion({ etc, images }) {
  const [showModal, setShowModal] = useState(false)
  return (
    <div className='grid flex-col items-start justify-start flex-1 grid-cols-2 px-10 py-10 mt-5 bg-white shadow-lg rounded-xl'>
      <div>
        <div className='flex flex-row items-end flex-1 mb-3'>
          순삭특가를 신청하실 수 있는 상품이 더 없습니다. <br />
          매주 올웨이즈의 순삭특가 후보로 선정된 상품만 신청 가능한
          프로모션입니다. <br />
          매주 극소수의 제품만 순삭특가 후보로 선정됩니다.
        </div>
        <div className='text-lg font-bold text-sub-500'>선정기준</div>
        <ol className='ml-5 list-disc'>
          <li className='text-gray-800'>
            매출 - 매일 매출이 일정 수준 이상 나와야 합니다.
          </li>
          <li className='text-gray-800'>
            구매전환률 - 상품을 클릭한 고객이 구매할 확률이 높아야 합니다
          </li>
          <li className='text-gray-800'>
            시즌성 - 현 시점에 판매가 이뤄질 수 있는 제품이어야 합니다
          </li>
          <li className='text-gray-800'>
            후기 - 일정 수준 이상의 별점 수와 후기 점수가 확보되어야 합니다
          </li>
        </ol>
        <div className='mt-3 text-lg font-bold text-sub-500'>
          선정 확률 높이는 법
        </div>
        <div className='text-gray-500'>{etc}</div>
      </div>
      <div className='pl-10'>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop
          pagination={{
            clickable: true,
          }}
          className='rounded-lg shadow-md select-none'
          modules={[Pagination]}
        >
          {images.map((image) => (
            <SwiperSlide key={nanoid()}>
              <div className='flex items-center justify-center w-full h-full text-lg text-black bg-white '>
                <Button
                  size='none'
                  appearance='transparent'
                  onClick={() => setShowModal(true)}
                >
                  <img alt='banner' src={image} />
                </Button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className='grid flex-col items-center justify-center flex-1 p-3 '>
          {' '}
          순삭특가 진행시 매출 그래프 (실제예시)
        </div>
      </div>
    </div>
  )
}

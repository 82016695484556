import backendApis from '../utils/backendApis'
import React, { useState } from 'react'
import SellerStore from '../stores/SellerStore'
import { useHistory } from 'react-router-dom'

const VerificationScreen = ({
  type = 'treasureDeal',
  setVerified = () => {},
}) => {
  const history = useHistory()
  const [verificationCode, setVerificationCode] = useState()

  function handleInputChange(event) {
    const inputValue = event.target.value
    if (inputValue.length > 22) return
    setVerificationCode(inputValue)
  }

  const titleByType = {
    treasureDeal: '보물특가',
    reviewTransfer: '후기 이관',
  }

  return (
    <div className='flex flex-col flex-1 px-10 py-10 mt-20 ml-10 bg-white shadow-lg rounded-xl'>
      <div className='mb-2 text-xl font-bold'>
        <span className='font-bold text-blue-500'>{titleByType?.[type]}</span>{' '}
        인증 코드를 입력해주세요.
      </div>
      <div className='mb-10'>
        본 페이지는 인증코드가 있는 셀러분들만 접근 가능합니다. 인증코드를
        입력해주세요.
      </div>
      <div>
        <form className='flex flex-1 w-full'>
          <input
            className='flex flex-1 w-1/2'
            type='text'
            value={verificationCode}
            onChange={(e) => {
              handleInputChange(e)
            }}
          />
          <input
            type='submit'
            value='인증하기'
            className='px-3 py-2 ml-5 font-bold text-white bg-blue-500 shadow-lg cursor-pointer rounded-xl'
            onClick={async (e) => {
              e.preventDefault()
              if (SellerStore.isLoading) return
              SellerStore.setIsLoading(true)
              const result = await backendApis.verifySpecialCode(
                type === 'reviewTransfer' ? 'reviewTransferTicket' : type,
                verificationCode,
              )
              if (result?.status === 2001) {
                alert('인증되었습니다.')
                SellerStore.setIsLoading(false)
                setVerified(true)
                return
              }
              if (result?.status === 2002) {
                alert('이미 사용된 티켓입니다. 다른 티켓 번호를 입력해주세요.')
                SellerStore.setIsLoading(false)
                return
              }
              alert('인증코드가 맞지 않습니다.')
              SellerStore.setIsLoading(false)
            }}
          />
        </form>
      </div>
    </div>
  )
}
export default VerificationScreen

import React from 'react'
import { Tooltip } from 'react-tippy'

import Card from '../../atoms/card'
import SubTitle from '../../atoms/subTitle'
import TutorialModal from '../../atoms/tutorialModal'
import Description from '../../atoms/description'
import Input from '../../atoms/input'

import Format from '../../../utils/format'

import { BsQuestionSquare as AlwayzQuestion } from 'react-icons/bs'

import Tutorial2 from '../../../videos/tutorial2.mp4'
import ItemStore from '../../../stores/ItemStore'

const ItemTitleCard = ({ itemTitle, setItemTitle }) => {
  return (
    <Card title='상품명' necessary>
      <div>
        <div className='flex items-center mb-5 '>
          <SubTitle>상품명을 입력해 주세요</SubTitle>
          <TutorialModal modalTitle='상품명 가이드' videoSource={Tutorial2}>
            <AlwayzQuestion />
          </TutorialModal>
        </div>
        <Description>입력해주신 상품명은 앱에 노출됩니다.</Description>
      </div>
      <Tooltip
        arrow='true'
        theme='dark'
        title='💡 상품명을 정확하게 작성할 수록 노출도가 상승해요!'
        animation='fade'
        position='right'
      >
        <Input
          id='register-productName'
          className='w-1/2'
          type='text'
          placeholder='예시)다모농장 해남 햇 꿀고구마 3kg 5kg 특상 한입'
          value={ItemStore.itemInfoOnEdit.itemTitle || itemTitle}
          onChange={(e) => {
            ItemStore.setItemInfoOnEdit({
              ...ItemStore.itemInfoOnEdit,
              itemTitle: Format.Title(e.target.value.normalize('NFC')),
            })
            setItemTitle(e.target.value.normalize('NFC'))
          }}
        />
      </Tooltip>
    </Card>
  )
}

export default ItemTitleCard

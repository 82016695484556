import SellerStore from '../../stores/SellerStore'
import Button from '../atoms/button'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import backendApis from '../../utils/backendApis'

const CheckedItemButtonList = observer(
  ({
    checkedItemIds = [],
    resetter,
    onSale = true,
    callbackFunction = () => {},
  }) => {
    return (
      <>
        <Button
          appearance='neutral'
          className='mr-2'
          onClick={async () => {
            const confirmMessage = window.confirm(
              `선택하신 상품을 모두 삭제하시겠습니까? (삭제된 상품은 복구하실 수 없습니다. 신중히 선택해주세요.)`,
            )
            if (!confirmMessage) {
              return
            }
            SellerStore.setIsLoading(true)
            const result = await backendApis.removeManyItemsFromSeller(
              checkedItemIds,
            )
            SellerStore.setIsLoading(false)
            if (result?.status === 200) {
              ItemStore.setItemOrderGuaranteesInfo(
                ItemStore.itemOrderGuaranteesInfo.filter((e) => {
                  return !checkedItemIds.includes(e._id)
                }),
              )

              callbackFunction()
              resetter()
              alert('상품 삭제가 완료되었습니다.')
              return
            }
            alert('상품 삭제 중 문제가 발생했습니다. 나중에 다시 시도해주세요.')
          }}
        >
          선택 삭제
        </Button>
        {onSale ? (
          <>
            <Button
              appearance='neutral'
              className='mr-2'
              onClick={async () => {
                const confirmMessage = window.confirm(
                  `선택하신 상품을 모두 품절 처리하시겠습니까?`,
                )
                if (!confirmMessage) {
                  return
                }
                SellerStore.setIsLoading(true)
                const result = await backendApis.setManyItemsSoldOut(
                  checkedItemIds,
                )
                SellerStore.setIsLoading(false)
                if (result?.status === 200) {
                  ItemStore.setItemOrderGuaranteesInfo(
                    ItemStore.itemOrderGuaranteesInfo.map((e) => {
                      if (checkedItemIds.includes(e._id)) {
                        return { ...e, soldOut: true }
                      }
                      return e
                    }),
                  )
                  callbackFunction()
                  resetter()
                  alert('상품 품절 처리가 완료되었습니다.')
                  return
                }
                alert(
                  '상품 품절 처리 중 문제가 발생했습니다. 나중에 다시 시도해주세요.',
                )
              }}
            >
              선택 품절
            </Button>
            <Button
              appearance='neutral'
              className='mr-2'
              onClick={async () => {
                const confirmMessage = window.confirm(
                  `선택하신 상품을 모두 판매재개 처리하시겠습니까?`,
                )
                if (!confirmMessage) {
                  return
                }
                SellerStore.setIsLoading(true)
                const result = await backendApis.setManyItemSalesResume(
                  checkedItemIds,
                )
                SellerStore.setIsLoading(false)
                if (result?.status === 200) {
                  ItemStore.setItemOrderGuaranteesInfo(
                    ItemStore.itemOrderGuaranteesInfo.map((e) => {
                      if (checkedItemIds.includes(e._id)) {
                        return { ...e, soldOut: true }
                      }
                      return e
                    }),
                  )
                  callbackFunction()
                  resetter()
                  alert('상품 판매재개 처리가 완료되었습니다.')
                  return
                }
                alert(
                  '상품 판매재개 처리 중 문제가 발생했습니다. 나중에 다시 시도해주세요.',
                )
              }}
            >
              선택 판매재개
            </Button>
          </>
        ) : (
          <></>
        )}
      </>
    )
  },
)

export default CheckedItemButtonList

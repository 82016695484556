import A from './A.png'
import B from './B.png'
import C from './C.png'
import D from './D.png'
import S from './S.png'

const RankA = ({ className = 'w-20 h-20', children }) => {
  return (
    <div>
      <img className={className} alt='rankA' src={A} />
      <div className='text-md text-sub-200'>{children}</div>
    </div>
  )
}
const RankB = ({ className = 'w-20 h-20', children }) => {
  return (
    <div>
      <img className={className} alt='rankB' src={B} />
      <div className='text-md text-sub-200'>{children}</div>
    </div>
  )
}
const RankC = ({ className = 'w-20 h-20', children }) => {
  return (
    <div>
      <img className={className} alt='rankC' src={C} />
      <div className='text-md text-sub-200'>{children}</div>
    </div>
  )
}
const RankD = ({ className = 'w-20 h-20', children }) => {
  return (
    <div>
      <img className={className} alt='rankD' src={D} />
      <div className='text-md text-sub-200'>{children}</div>
    </div>
  )
}
const RankS = ({ className = 'w-20 h-20', children }) => {
  return (
    <div>
      <img className={className} alt='rankS' src={S} />
      <div className='text-md text-sub-200'>{children}</div>
    </div>
  )
}
const QuestionMark = ({ className = 'w-20 h-20', children }) => {
  return (
    <div>
      <img className={className} alt='QuestionMark' src={S} />
      <div className='text-md text-sub-200'>{children}</div>
    </div>
  )
}
export { RankA, RankB, RankC, RankD, RankS, QuestionMark }

import React, { useEffect, useRef, useState } from 'react'
import { Col, Tooltip, Row, Tabs, Radio, Input, Button } from 'antd'
import { QuestionCircleTwoTone } from '@ant-design/icons'
import backendApis from '../utils/backendApis'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip as ChartTooltip,
  CategoryScale,
  Legend,
} from 'chart.js'
import ItemQualityDetailGraph from '../components/molecules/ItemQualityDetailGraph'
import ItemQualityDashBoard from '../components/molecules/ItemQualityDashboard'
import ItemQualityDataCollapse from '../components/molecules/ItemQualityDataCollapse'
import ItemQualityStatistics from '../components/molecules/ItemQualityStatistics'

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  ChartTooltip,
  CategoryScale,
  Legend,
)

const { TabPane } = Tabs

const Explanation = () => (
  <Col span={24}>
    <Row justify='space-between'>
      <Col span={12}>
        <div>
          판매중인 상품의{' '}
          <span className='font-bold text-blue-500'>품질지표</span>
          <Tooltip
            className='mx-1'
            title='품질관련 후기, 반품건수, 고객문의 및 판매량을 종합적으로 판단하여 계산된 지표입니다.'
          >
            <QuestionCircleTwoTone />
          </Tooltip>
          에 따라 노출도가 조정됩니다.
        </div>
        <div>
          노출이 제한된 상품의 경우{' '}
          <span className='font-bold text-blue-500'>품질관리 소명서 제출 </span>
          을 통해서만 노출량 복구가 가능합니다.
        </div>
      </Col>
    </Row>
  </Col>
)

const ItemQualityManagementScreen = () => {
  const [itemId, setItemId] = useState('')
  const [selectedOption, setSelectedOption] = useState('itemId')
  const [totalStats, setTotalStats] = useState([])
  const [resetCollapse, setResetCollapse] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const [inputValue, setInputValue] = useState('') // Input 값을 관리하는 상태
  const inputRef = useRef()

  const handleInputChange = (e) => {
    setInputValue(e.target.value) // Input 변경 시 상태 업데이트
  }

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value)
  }

  const handleSearch = () => {
    setItemId(inputRef.current?.input?.value)
  }

  const handleClickDetail = (itemId) => {
    setActiveTab('2')
    setItemId(itemId)
    setInputValue(itemId)
  }

  useEffect(() => {
    if (!itemId) return
    setResetCollapse((prev) => !prev)
    setInputValue(itemId)
    backendApis
      .loadTotalItemQualityStats(itemId)
      .then((totalStatsResponse) => {
        if (totalStatsResponse.status === 200 && totalStatsResponse.data) {
          const sortedTotalStats = [...totalStatsResponse.data].sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
          )
          setTotalStats(sortedTotalStats)
          return
        }
        window.alert('상품ID를 확인해주세요')
      })
      .catch(() => {
        window.alert('상품ID를 확인해주세요')
      })
  }, [itemId])

  return (
    <Row className='w-full mt-10 mb-20'>
      <div className='flex flex-1 w-full flex-col'>
        <Row className='font-bold text-xl mb-5'>판매상품 품질지표 모니터링</Row>
        <div className='flex flex-1 flex-col w-full p-6 rounded-md bg-slate-200 mb-5 max-w-7xl'>
          <Explanation />
        </div>
      </div>
      <Col span={24}>
        <Tabs
          activeKey={activeTab}
          className='max-w-7xl w-full'
          onChange={setActiveTab}
        >
          <TabPane tab='상품목록' key='1'>
            <ItemQualityDashBoard onClickDetail={handleClickDetail} />
          </TabPane>

          <TabPane tab='상세품질지표' key='2'>
            <div className='flex flex-1 flex-col w-full p-6 rounded-md'>
              <Col>
                <Radio.Group
                  onChange={handleRadioChange}
                  value={selectedOption}
                >
                  <Radio value='itemId'>상품ID</Radio>
                </Radio.Group>
                <Input
                  ref={inputRef}
                  placeholder='상품ID를 입력하세요'
                  value={inputValue}
                  onChange={handleInputChange}
                  className='mr-2 custom-input'
                  style={{
                    width: '250px',
                    borderRadius: '8px',
                    padding: '4px 8px',
                    height: '32px',
                    fontSize: '14px',
                  }}
                />
                <Button onClick={handleSearch}>검색</Button>
                <div style={{ marginTop: '20px' }}>
                  <ItemQualityStatistics data={totalStats} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginTop: '20px',
                  }}
                >
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <ItemQualityDetailGraph data={totalStats} type='detail' />
                  </div>
                  <div style={{ flex: 1, paddingLeft: '10px' }}>
                    <ItemQualityDetailGraph data={totalStats} type='order' />
                  </div>
                </div>
                <ItemQualityDataCollapse
                  itemId={itemId}
                  resetCollapse={resetCollapse}
                />
              </Col>
            </div>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  )
}

export default ItemQualityManagementScreen

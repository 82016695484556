import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../../stores/OrderStore'
import { BrowserRouter as Router, Link } from 'react-router-dom'

const SendingNavigator = observer(() => {
  const [isOnMouse, setIsOnMouse] = useState(false)
  return (
    <button
      type='button'
      className=' w-1/5 border-gray-700 border-2 rounded-md ml-3 mr-3 hover:bg-gray-200 text-left bg-white'
      onClick={async () => {
        document.getElementById('sendingOrders').scrollIntoView()
      }}
      onMouseEnter={() => {
        setIsOnMouse(true)
      }}
      onMouseLeave={() => {
        setIsOnMouse(false)
      }}
    >
      <div className='textContainer' style={{ margin: 20 }}>
        <h2 style={{ fontSize: '20' }}>
          발송중
          <br />
          (송장 업로드 이후)
        </h2>
        <h2 className='mt-3 text-2xl font-bold'>
          {OrderStore.ordersNumPerStatus['shipping-sending'] || 0}
        </h2>
      </div>
    </button>
  )
})

export default SendingNavigator

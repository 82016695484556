import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// baseValue의 20% 내외로 오프셋을 계산
const consistentRandomOffset = (baseValue, index) => {
  const seed = baseValue * 100 + index
  const pseudoRandom = Math.sin(seed) * 10000
  return (pseudoRandom - Math.floor(pseudoRandom)) * 2 * (baseValue * 0.2)
}

const OscillatingChart = ({ baseValue }) => {
  const data = useMemo(() => {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]

    return days.map((day, index) =>
      parseInt(baseValue + consistentRandomOffset(baseValue, index), 10),
    )
  }, [baseValue])

  const chartData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: '일간 매출',
        data: data,
        backgroundColor: '#818cf8',
        borderColor: '#818cf8',
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
        text: 'Oscillating Chart',
      },
    },
    scales: {
      x: {
        display: false, // x축 숨김
      },
    },
  }

  return <Bar data={chartData} options={options} />
}

export default OscillatingChart

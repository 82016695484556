import moment from 'moment'
import Button from '../../components/atoms/button'
import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'

const AlfarmFreeAdModalForMinigame = ({
  startDate,
  checkedItemsTitle,
  checkedItemIds,
  cleanUp,
  setAdStatusTab,
  setFreeAdTab,
}) => {
  return (
    <div className='fixed right-0 z-50 flex flex-col items-start flex-1 w-1/4 p-10 mr-20 bg-white shadow-xl rounded-xl bottom-10'>
      <div className='flex-algin'>
        <Button
          className='mb-6 mr-4'
          appearance='neutral'
          onClick={() => setFreeAdTab(false)}
        >
          일반 광고 신청
        </Button>
        <Button appearance='positive'>무료 광고 신청 (이벤트)</Button>
      </div>
      <div className='mb-5'>
        <div className='mb-3 text-lg text-amber-500 font-bold'>
          무료 광고 신청 (이벤트)
        </div>
        <div className='mb-2'>
          신청일자:{' '}
          <span className='font-bold text-blue-500'>
            {moment(new Date(startDate)).format('MM월 DD일')}
          </span>
        </div>
        <div className='mb-2'>
          신청 상품명(노출 순서) <br />
          {checkedItemsTitle?.map((item, index) => {
            return (
              <div key={item._id}>
                {index + 1}.{' '}
                <span className='font-bold text-blue-500'>
                  {item} <br />{' '}
                </span>
              </div>
            )
          })}
        </div>

        <div className='mb-2'>
          신청 광고 수: <span className='font-bold text-blue-500'>10,000 </span>
          회 (고정)
        </div>
        <div className='mb-2'>
          <span className='font-bold text-blue-500'>광고비 무료</span>
        </div>
      </div>
      <Button
        onClick={async () => {
          const adUnit = {
            itemIds: checkedItemIds,
            maxExposureCount: 10000, // 값 고정
            revenuePerExposure: 20, // 값 고정 (어차피 정산 안됨, 광고 우선 소진 시켜야 함)
            adExposureFrom: new Date(
              moment(new Date(startDate)).startOf('day'),
            ),
            adExposureTo: new Date(
              moment(new Date(startDate)).startOf('day').add(3, 'days'),
            ),
            isForFreeAdEvent: true,
          }
          if (adUnit.itemIds?.length < 5) {
            alert('상품은 최소 5개 이상 신청해야 해요.')
            return
          }
          if (!adUnit.adExposureFrom) {
            alert('광고 시작일을 입력해주세요.')
            return
          }
          if (SellerStore.isLoading) return
          SellerStore.setIsLoading(true)
          const result = await backendApis.registerAlfarmMinigameAdForFreeEvent(
            adUnit,
          )
          if (result?.status === 200) {
            if (result?.data?.status === 'success') {
              alert(
                '무료 광고 신청이 완료되었습니다. 입력해주신 광고 시작일자 전일에 광고 진행이 확정되며 승인된 건에 한하여 광고가 시작됩니다.',
              )
              cleanUp()
              setAdStatusTab('complete')
            } else if (result?.data?.status === 'already-exists') {
              alert('이미 신청한 무료 광고가 있습니다.')
              cleanUp()
              setAdStatusTab('complete')
            } else if (result?.data?.status === 'full') {
              alert(
                '선택하신 날짜의 무료 광고 신청이 마감되었습니다. 광고 시작일을 다른 날짜로 선택해주세요.',
              )
            }
          } else {
            alert('광고 신청에 실패했습니다. 다시 시도해주세요.')
          }
          SellerStore.setIsLoading(false)
        }}
        appearance='positive'
        size='flexlg'
      >
        무료 광고 신청하기
      </Button>
    </div>
  )
}
export default AlfarmFreeAdModalForMinigame

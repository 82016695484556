import Table from '../atoms/table'
import React, { useMemo, useEffect } from 'react'
import ItemInfoParser from '../../utils/itemInfoParser'
import EditableCell from '../atoms/editableCell'
import OptionSelectTable from '../ADS/molecules/optionSelectTable'

const OptionTableV2 = ({
  optionsInfo,
  discountRate,
  setOptionsInfo = () => {},
  columns = [],
  classStyle = '',
  oldOptionsInfo = '',
  optionIdx,
  onRowClick = () => {},
}) => {
  useEffect(() => {
    const optionsInfoTemp = JSON.parse(JSON.stringify(optionsInfo))
    const copyFieldColumns = columns?.filter(
      (column) => column?.copyField && !column?.isEditableData,
    )
    optionsInfoTemp?.optionPrices?.flat()?.forEach((option, index) => {
      copyFieldColumns?.forEach((column) => {
        if (!option?.[column?.accessor]) {
          // option[column.accessor] = option?.[column?.copyField]
          const oldOption = oldOptionsInfo?.optionPrices?.flat()?.[index]
          option[column.accessor] = oldOption?.[column?.copyField]
        }
      })
    })
    setOptionsInfo(optionsInfoTemp)
  }, [])

  const mergedColumns = useMemo(() => {
    let columnsTemp = [...columns] || [
      { Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 },
      { Header: '옵션2 / 상세옵션', accessor: 'option2', index: 2 },
      { Header: '팀구매가', accessor: 'teamPurchasePrice' },
      { Header: '재고량', accessor: 'stockNumber' },
    ]
    if (optionsInfo?.optionNames?.length === 1) {
      columnsTemp.splice(1, 1)
    }
    columnsTemp = [
      ...columnsTemp?.map((column) => {
        const correspondingColumn = columns?.find(
          (columnTemp) => columnTemp?.accessor === column?.accessor,
        )
        return correspondingColumn || column
      }),
      ...columns.filter((column) => {
        return !columnsTemp
          ?.map((column2) => column2?.accessor)
          ?.includes(column?.accessor)
      }),
    ]
    return columnsTemp.sort((a, b) => {
      return a.index - b.index
    })
  }, [optionsInfo, columns])

  const data = useMemo(() => {
    const optionRows = ItemInfoParser.optionsInfoParser(optionsInfo)
    const editableColumns = mergedColumns?.filter(
      (column) => column?.isEditableData,
    )
    const copyFieldColumns = mergedColumns?.filter(
      (column) => column?.copyField && !column?.isEditableData,
    )
    // const optionsInfoTemp = JSON.parse(JSON.stringify(optionsInfo))
    const newOptions = optionRows.map((optionRow, idx) => {
      const editableOptionRow = {}
      const copyFieldOptionRow = {}

      copyFieldColumns?.forEach((column) => {
        if (!optionRow?.[column?.accessor]) {
          copyFieldOptionRow[column?.accessor] = optionRow?.[column?.copyField]
        }
      })

      editableColumns?.forEach((column) => {
        editableOptionRow[column.accessor] = (
          <div className='w-max'>
            <EditableCell
              value={optionRow?.[column?.accessor]}
              onChange={(e) => {
                setOptionsInfo({
                  ...optionsInfo,
                  optionPrices: optionsInfo?.optionPrices?.map(
                    (firstOption, firstOptionIndex) => {
                      if (Array.isArray(firstOption)) {
                        // 2단
                        return firstOption?.map(
                          (secondOption, secondOptionIndex) => {
                            if (
                              optionRow?.optionIndices?.[0] ===
                                firstOptionIndex &&
                              optionRow?.optionIndices?.[1] ===
                                secondOptionIndex
                            ) {
                              if (
                                typeof secondOption[column?.accessor] ===
                                'number'
                              ) {
                                secondOption[column?.accessor] = Number(
                                  e.target.value,
                                )
                              } else {
                                secondOption[column?.accessor] = e.target.value
                              }
                            }
                            return secondOption
                          },
                        )
                      }
                      // 1단
                      if (optionRow?.optionIndices?.[0] === firstOptionIndex) {
                        if (typeof firstOption[column?.accessor] === 'number') {
                          firstOption[column?.accessor] = Number(e.target.value)
                        } else {
                          firstOption[column?.accessor] = e.target.value
                        }
                      }
                      return firstOption
                    },
                  ),
                })
              }}
            />
          </div>
        )
      })

      return {
        ...optionRow,
        ...editableOptionRow,
        ...copyFieldOptionRow,
      }
    })

    return newOptions
  }, [optionsInfo, mergedColumns])

  if (discountRate >= 0 && columns) {
    const dataTemp = data.map((option) => {
      option.teamPurchasePrice = Math.floor(
        option?.teamPurchasePrice * (1 - discountRate),
      )
      option.discountRate = `${discountRate * 100}%`

      return option // Ensure to return the modified option
    })
  }

  return (
    <OptionSelectTable
      columns={mergedColumns}
      data={data}
      className={classStyle}
      onRowClick={onRowClick}
      optionIdx={optionIdx}
    />
  )
}
export default OptionTableV2

import backendApis from '../../utils/backendApis'
import { useState } from 'react'
import { FcBarChart as PendingIcon } from 'react-icons/fc'
import Button from '../atoms/button'
import ItemRankReportModal from './itemRankReportModal'
import RankIconSetter from './rankIconSetter'

const DealButtonHandler = ({
  itemInfo,
  itemRankData,
  data,
  setData,
  dealType,
  dealRankLimit,
  dealRequest,
  dealCancelRequest,
  dealApplyInfo,
  isRankItem,
  isReApply,
}) => {
  const [buttonDisplayCondition, setButtonDisplayCondition] = useState('')
  const dealDisableCondition = {
    S: itemInfo.itemRankTestingSku || itemRankData?.itemRank !== 'S',
    A:
      itemInfo.itemRankTestingSku ||
      itemRankData?.itemRank === 'C' ||
      itemRankData?.itemRank === 'B',
    B: itemInfo.itemRankTestingSku || itemRankData?.itemRank === 'C',
    C: false,
    none: false,
  }
  const dealNameByDealType = {
    timeDeal: '타임특가',
    overProducedDeal: '특가',
    itemCoupon: '베이직 쿠폰',
    multiPurchase: '다다익선 쿠폰',
    followCoupon: '팔로우 쿠폰',
    apartment: '동네 공동구매',
    lureDeal: '순삭특가',
    seasonDeal: '기획전',
    searchAd: '검색광고',
    treasureDeal: '보물특가',
    tenMinDeal: '10분 핫딜',
    newYear24Deal: '설 특가',
  }

  const ButtonDisplay = () => {
    if (dealDisableCondition[dealRankLimit]) {
      return '등급이 낮습니다'
    }
    if (dealApplyInfo === 'candidate') {
      return `${dealNameByDealType[dealType]} 신청하기`
    }
    if (dealApplyInfo === 'complete') {
      return '중단하기'
    }
  }

  const isTenMinDeal = dealType === 'tenMinDeal'
  const isNewSeasonDeal = dealType === 'newYear24Deal'
  if (
    isRankItem &&
    itemInfo.itemRankTestingSku &&
    !isTenMinDeal &&
    !isNewSeasonDeal
  ) {
    return (
      <div className='flex flex-row items-start justify-end flex-1'>
        <PendingIcon className='mr-2' />
        <div className='flex flex-col text-lg font-bold'>
          <div>아이템 등급 테스트 진행 중입니다.</div>
          <div className='text-sm font-normal text-gray-500'>
            테스트 중에도 정상적으로 판매가 진행됩니다.
          </div>
          <div className='text-sm font-normal text-gray-500'>
            테스트는 평균적으로 24시간 이내에 완료됩니다.
          </div>
        </div>
      </div>
    )
  }

  if (
    (!dealApplyInfo && !itemInfo.itemRankTestingSku) ||
    buttonDisplayCondition === 'apply' ||
    (!dealApplyInfo && isTenMinDeal) ||
    (!dealApplyInfo && isNewSeasonDeal)
  ) {
    return (
      <div className='flex flex-row items-center justify-end flex-1'>
        <div className='flex flex-col'>
          {isRankItem ? (
            <>
              <RankIconSetter
                itemRank={itemRankData?.itemRank}
                className='w-12 h-12 mb-1'
              />
              <span className='text-sm text-neutral-500'>상품등급</span>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='flex flex-col flex-1 pl-5'>
          <Button
            appearance={
              dealDisableCondition[dealRankLimit] ? 'disabled' : 'positive'
            }
            size='lg'
            onClick={() => {
              dealRequest(itemInfo)
            }}
            disabled={dealDisableCondition[dealRankLimit]}
            className='mb-2'
          >
            {dealDisableCondition[dealRankLimit]
              ? '등급이 낮습니다'
              : `${dealNameByDealType[dealType]} 신청하기`}
          </Button>
        </div>
      </div>
    )
  }

  if (dealType === 'tenMinDeal') {
    if (dealApplyInfo || buttonDisplayCondition === 'edit') {
      return (
        <div className='flex flex-row items-center justify-end flex-1'>
          <div className='flex flex-col flex-1 pl-5'>
            <Button
              onClick={() => {
                dealRequest(itemInfo)
              }}
              appearance='positiveSub'
              size='lg'
              className='mb-2'
            >
              수정하기
            </Button>
            {dealApplyInfo ? (
              <Button
                appearance='teritiary'
                size='none'
                onClick={async () => {
                  const result = await dealCancelRequest(data)
                  if (result?.status === 200) {
                    alert('취소가 완료되었습니다.')
                    setButtonDisplayCondition('apply')
                  }
                }}
              >
                취소하기
              </Button>
            ) : (
              <></>
              // <ItemRankReportModal
              //   text='등급보고서 확인'
              //   itemInfo={itemInfo}
              //   itemRankData={itemRankData}
              // />
            )}
          </div>
        </div>
      )
    }
  }

  if (
    (dealApplyInfo && !itemInfo.itemRankTestingSku) ||
    buttonDisplayCondition === 'edit'
  ) {
    return (
      <div className='flex flex-row items-center justify-end flex-1'>
        <div className='flex flex-col'>
          {isRankItem ? (
            <>
              <RankIconSetter
                itemRank={itemRankData?.itemRank}
                className='w-12 h-12 mb-1'
              />
              <span className='text-sm text-neutral-500'>상품등급</span>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='flex flex-col flex-1 pl-5'>
          <Button
            onClick={() => {
              dealRequest(itemInfo)
            }}
            appearance='positiveSub'
            size='lg'
            className='mb-2'
          >
            {isReApply ? '재신청하기' : '수정하기'}
          </Button>
          {[
            'timeDeal',
            'apartment',
            'overProducedDeal',
            'treasureDeal',
            'newYear24Deal',
          ].includes(dealType) && dealApplyInfo ? (
            <Button
              appearance='teritiary'
              size='none'
              onClick={async () => {
                const result = await dealCancelRequest(data)
                if (result?.status === 200) {
                  alert('취소가 완료되었습니다.')
                  setButtonDisplayCondition('apply')
                }
              }}
            >
              취소하기
            </Button>
          ) : (
            <></>
            // <ItemRankReportModal
            //   text='등급보고서 확인'
            //   itemInfo={itemInfo}
            //   itemRankData={itemRankData}
            // />
          )}
        </div>
      </div>
    )
  }
  return <></>
}
export default DealButtonHandler

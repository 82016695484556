import SubTitle from '../components/atoms/subTitle'
import Page from '../components/atoms/page'
import { FcShipped as ShippingIcon } from 'react-icons/fc'
import { useForm } from 'react-hook-form'
import { Tooltip } from '../components/ADS/atoms'
import Button from '../components/atoms/button'
import backendApis from '../utils/backendApis'

const DirectPurchaseScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = async (data) => {
    const result = await backendApis.applyDirectPurchase(data)
    if (result?.status === 200) {
      alert('신청이 완료되었습니다.')
    } else {
      alert('신청에 실패하였습니다. 신청 내용을 확인해주세요.')
    }
  }
  return (
    <>
      <Page title='직매입 신청' Icon={ShippingIcon}>
        <div className='p-10 mb-10 bg-white shadow-md rounded-xl min-w-[600px] w-1/2'>
          <SubTitle className='mb-5'>직매입 신청 페이지입니다</SubTitle>
          <div>
            올웨이즈에서는 현재 온라인 최저가를 유지할 수 있는 상품들에 대해
            직매입 신청을 받고 있습니다.
          </div>
          <div>
            본 페이지에서 신청해주시면 담당자 확인 후 선정 건에 대해
            연락드리겠습니다.
          </div>
          <div className='mt-5 text-gray-500'>
            *신청 상품에 대해서는 매입계약 체결 후 매입이 진행됩니다.
          </div>
        </div>
        <div className='flex flex-row items-start justify-between flex-1 w-1/2 p-10 bg-white shadow-xl min-w-[600px] rounded-xl'>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col items-start justify-center flex-1 gap-5'
          >
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>상품ID</div>
              <input
                type='text'
                className='h-8 rounded-lg w-80'
                placeholder='ex) 64d46574d0fbb2b018f52ac8'
                {...register('itemId', { required: true })}
              />
              <Tooltip text='해당 상품의 올웨이즈 상품 아이디를 적어주세요.' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>최저가 링크</div>
              <input
                type='url'
                className='h-8 rounded-lg w-80'
                placeholder='ex) https://www.naver.com'
                {...register('externalLink', { required: true })}
              />
              <Tooltip text='해당 상품의 온라인 최저가 링크를 적어주세요.' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>직매입가</div>
              <input
                type='number'
                className='h-8 rounded-lg w-80'
                placeholder='ex) 10,000원'
                {...register('directPurchasePrice', { required: true })}
              />
              <Tooltip text='직매입 희망 가격을 적어주세요. 온라인 최저가 대비 최소 10%이상 할인된 가격이어야 하며, 가격이 낮을 수록 올웨이즈 핵심 상품이 될 확률이 높아집니다.' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>MOQ</div>
              <select {...register('MOQ')}>
                <option value='10'>10</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='200'>200</option>
                <option value='300'>300</option>
                <option value='400'>400</option>
                <option value='500'>500</option>
              </select>
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>본사 여부</div>
              <input
                type='checkbox'
                placeholder='isProducer'
                {...register('isProducer', {})}
              />
              <Tooltip text='본사 여부를 체크해주세요. 본사가 아니더라도 제공 가능한 가격이 낮다면 매입이 가능합니다.' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>지속 공급 여부</div>
              <input
                type='checkbox'
                placeholder='isSteady'
                {...register('isSteady', {})}
              />
              <Tooltip text='지속 공급이 가능한 상품인지 체크해주세요. 지속 공급이 불가하더라도 가격이 낮다면 매입이 가능합니다.' />
            </div>
            <div className='flex flex-col flex-1 w-full'>
              <span className='mb-3 mr-2 font-bold'>상품 관련 설명</span>
              <textarea {...register('description', {})} />
            </div>
            <input
              type='submit'
              value='제출하기'
              className='flex flex-row items-center justify-center flex-1 w-full px-5 py-2 font-bold text-white bg-blue-500 rounded-xl'
            />
          </form>
        </div>
      </Page>
    </>
  )
}
export default DirectPurchaseScreen

import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import ImageUploading from 'react-images-uploading'
import backendApis from '../utils/backendApis'
import OrderStore from '../stores/OrderStore'
import SellerStore from '../stores/SellerStore'
import AuthStore from '../stores/AuthStore'
import socket from '../utils/socket'
import ItemStore from '../stores/ItemStore'
import Button from '../components/atoms/button'
import { FcOk as OkIcon } from 'react-icons/fc'
import Input from '../components/atoms/input'
import Log from '../utils/log'
import BusinessRegistrationModal from './Modals/BusinessRegistrationModal'
import businessInfoParser from '../utils/businessRegistrationUtils/businessInfoParser'
import ReactDOM from 'react-dom'

const RegisterSellerBasicsScreen = observer(() => {
  const history = useHistory()

  // 사업자 검증 정보 (사업자등록증, 통신판매업신고번호, 정산계좌 정보 등)
  const [repName, setRepName] = useState('')
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState('')
  const [
    mailOrderSalesRegistrationNumber,
    setMailOrderSalesRegistrationNumber,
  ] = useState('')
  const [corpName, setCorpName] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [depositorName, setDepositorName] = useState('')
  const [bankAccountNumber, setBankAccountNumber] = useState('')
  const [bankInfo, setBankInfo] = useState([])
  const [businessRegistrationNumberImage, setBusinessRegistrationNumberImage] =
    useState('')
  const [showBusinessRegistrationModal, setShowBusinessRegistrationModal] =
    useState(false)
  const [disabledApproveButton, setDisabledApproveButton] = useState(false)
  const [isReload, setIsReload] = useState(false)

  const [contactNumber, setContactNumber] = useState('') // 고객 문의전화 수신 대표번호
  const [description, setDescription] = useState('') // 판매처 설명
  const [address, setAddress] = useState('') // 주소
  const [defaultShippingCompanyId, setDefaultShippingCompanyId] = useState('') // 대표 택배사
  const [shippingOrderCapa, setShippingOrderCapa] = useState('') // 하루 평균 일 택배건수
  const [sellerDistributionType, setSellerDistributionType] = useState('') // 셀러 유형
  const [categoryId, setCategoryId] = useState(null) // 대표 카테고리
  const [urlLink, setUrlLink] = useState('') // 대표 판매 채널의 URL

  const regex = /[^0-9]/g

  useEffect(() => {
    if (ItemStore.categories.length === 0) {
      ItemStore.loadCategories()
    }
  }, [])

  useEffect(() => {
    const fetchSellerBusinessInfo = async () => {
      const sellerBusinessInfo =
        await backendApis.loadSellerBusinessRegistrationInfo()
      if (
        sellerBusinessInfo?.data?.isValidBusinessRegistrationNumberImage ===
        'approved'
      ) {
        setRepName(
          businessInfoParser.parseBusinessRegistrationRepName(
            sellerBusinessInfo?.data?.businessRegisteredData?.repName,
          ),
        )
      }
      if (
        sellerBusinessInfo?.data?.isValidBusinessRegistrationNumber ===
        'approved'
      ) {
        setBusinessRegistrationNumber(
          sellerBusinessInfo?.data?.businessRegistrationNumber,
        )
      }
      if (
        sellerBusinessInfo?.data?.isValidMailOrderSalesRegistrationNumber ===
        'approved'
      ) {
        if (sellerBusinessInfo?.data?.mailOrderSalesRegistrationNumber) {
          setMailOrderSalesRegistrationNumber(
            sellerBusinessInfo?.data?.mailOrderSalesRegistrationNumber,
          )
        }
        setCorpName(sellerBusinessInfo?.data?.mailOrderSalesData?.bzmnNm)
      }
      if (sellerBusinessInfo?.data?.isValidBankAccount === 'approved') {
        setBankCode(sellerBusinessInfo?.data?.bankAccountInfo?.bankCode)
        setDepositorName(
          sellerBusinessInfo?.data?.bankAccountInfo?.depositorName,
        )
        setBankAccountNumber(
          sellerBusinessInfo?.data?.bankAccountInfo?.bankAccountNumber,
        )
      }
      setDisabledApproveButton(
        sellerBusinessInfo?.data?.isValidBusinessRegistrationNumberImage ===
          'approved' &&
          sellerBusinessInfo?.data?.isValidBusinessRegistrationNumber ===
            'approved' &&
          sellerBusinessInfo?.data?.isValidMailOrderSalesRegistrationNumber ===
            'approved' &&
          sellerBusinessInfo?.data?.isValidBankAccount === 'approved',
      )
      setBusinessRegistrationNumberImage(
        sellerBusinessInfo?.data?.businessRegistrationNumberImage,
      )
    }
    fetchSellerBusinessInfo()
  }, [isReload])

  useEffect(async () => {
    try {
      const response = await backendApis.loadBanksInfo()
      setBankInfo(response?.data || [])
    } catch (error) {
      console.error('Error loading bank info:', error)
    }
  }, [])

  return (
    <>
      <div className='flex flex-col items-center flex-1 w-screen max-w-4xl '>
        <div className='flex flex-col items-center justify-center flex-1 mt-10 '>
          <div className='flex flex-row items-center justify-center flex-1 text-2xl font-bold mb-2'>
            <OkIcon className='mr-2' />
            추가 정보 입력을 부탁드려요!
          </div>
          <div>추가 정보를 입력한 이후 상품 등록이 가능합니다</div>
        </div>

        {/* 정보 */}
        <div className='flex flex-col items-center justify-center flex-1 w-screen max-w-4xl mt-5'>
          {/* 사업자 정보 섹션 */}
          <div className='flex flex-col w-1/2 mb-6 mt-5'>
            <div className='flex justify-between items-center mb-4'>
              <span className='text-xl font-bold'>사업자 정보 입력하기</span>
              <button
                className={`px-4 py-2 text-white bg-theme-500 rounded-md text-sm font-bold shadow-lg hover:bg-theme-600 ${
                  disabledApproveButton ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                onClick={() => setShowBusinessRegistrationModal(true)}
                disabled={disabledApproveButton}
              >
                {disabledApproveButton ? '검증완료' : '사업자 검증'}
              </button>
            </div>

            {/* 안내 사항 */}
            <div className='text-sm text-gray-600 mb-4'>
              <p>
                - 아래 사업자 정보는 '사업자 검증' 버튼을 눌러 등록해주세요.
              </p>
              <p>
                - 안정적인 정산 진행을 위해 사업자 정보 검증 절차를
                요청드립니다.
              </p>
            </div>

            {/* 사업자 정보 그리드 */}
            <div className='grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg border border-solid border-slate-400'>
              <div className='flex flex-col'>
                <span className='text-sm text-gray-600 mb-1'>대표자명</span>
                <div className='bg-gray-200 p-3 rounded'>{repName || '-'}</div>
              </div>

              <div className='flex flex-col'>
                <span className='text-sm text-gray-600 mb-1'>상호/법인명</span>
                <div className='bg-gray-200 p-3 rounded'>{corpName || '-'}</div>
              </div>

              <div className='flex flex-col col-span-2'>
                <span className='text-sm text-gray-600 mb-1'>
                  사업자등록번호
                </span>
                <div className='bg-gray-200 p-3 rounded'>
                  {businessRegistrationNumber || '-'}
                </div>
              </div>

              <div className='flex flex-col col-span-2'>
                <span className='text-sm text-gray-600 mb-1'>
                  통신판매업번호
                </span>
                <div className='bg-gray-200 p-3 rounded'>
                  {mailOrderSalesRegistrationNumber || '-'}
                </div>
              </div>

              <div className='flex flex-col'>
                <span className='text-sm text-gray-600 mb-1'>은행명</span>
                <div className='bg-gray-200 p-3 rounded'>
                  {bankInfo.find((bank) => bank.bankCode === bankCode)
                    ?.bankName || '-'}
                </div>
              </div>

              <div className='flex flex-col'>
                <span className='text-sm text-gray-600 mb-1'>예금주명</span>
                <div className='bg-gray-200 p-3 rounded'>
                  {depositorName || '-'}
                </div>
              </div>

              <div className='flex flex-col col-span-2'>
                <span className='text-sm text-gray-600 mb-1'>계좌번호</span>
                <div className='bg-gray-200 p-3 rounded'>
                  {bankAccountNumber || '-'}
                </div>
              </div>
            </div>
          </div>

          {/* 사업자 검증 모달 컴포넌트 */}
          {showBusinessRegistrationModal &&
            ReactDOM.createPortal(
              <BusinessRegistrationModal
                onClose={() => setShowBusinessRegistrationModal(false)}
                onComplete={() => {
                  setIsReload((prev) => !prev)
                  setShowBusinessRegistrationModal(false)
                }}
              />,
              document.body,
            )}

          <div className='flex flex-col items-start w-1/2 mb-4'>
            <span className='text-xl font-bold'>추가 정보 입력하기</span>
          </div>
          {/* 고객 문의전화 수신 대표번호  */}
          <input
            type='text'
            value={contactNumber}
            placeholder='대표번호 (예: 07012345678) (고객 문의전화를 수신할 번호)'
            className='w-1/2 mb-3'
            onChange={(e) => {
              setContactNumber(e.target.value)
            }}
          />

          {/* 판매처 설명 */}
          <input
            type='text'
            value={description}
            placeholder='판매처 설명 (예: 맛 좋은 고구마를 합리적인 가격에 판매합니다)'
            className='w-1/2 mb-3'
            onChange={(e) => {
              setDescription(e.target.value)
            }}
          />

          {/* 주소 */}
          <input
            type='text'
            value={address}
            placeholder='주소 (예: 서울시 관악구 남부순환로 99, 1801호)'
            className='w-1/2 mb-3'
            onChange={(e) => {
              setAddress(e.target.value)
            }}
          />

          {/* 대표 택배사 선택 */}
          <select
            placeholder='대표 택배사'
            className='w-1/2 mb-3'
            id='shipping_company'
            name='shipping_company'
            onChange={(e) => {
              const correspondingShippingCompanyInfo =
                OrderStore.shippingCompaniesInfo.find(
                  (shippingCompanyInfo) =>
                    shippingCompanyInfo.shippingCompanyCode === e.target.value,
                )
              if (correspondingShippingCompanyInfo) {
                // setShippingInfo({
                //   ...shippingInfo,
                //   ...correspondingShippingCompanyInfo,
                // })
                setDefaultShippingCompanyId(
                  correspondingShippingCompanyInfo._id,
                )
              } else {
                setDefaultShippingCompanyId('')
              }
            }}
            value={
              OrderStore.shippingCompaniesInfo.find(
                (shippingCompany) =>
                  shippingCompany._id === defaultShippingCompanyId,
              )?.shippingCompanyCode || ''
            }
          >
            <option value=''>대표 택배사</option>
            {/* <option value=''>===</option>
          <option value='2021'>2021</option>
          <option value='2020'>2020</option>
          <option value='2019'>2019</option> */}
            {OrderStore.shippingCompaniesInfo.map((shippingCompanyInfo) => (
              <option
                key={shippingCompanyInfo.shippingCompanyCode}
                value={shippingCompanyInfo.shippingCompanyCode}
                //   onChange={(e) => {

                //   }}
              >
                {shippingCompanyInfo.shippingCompanyName}
              </option>
            ))}
          </select>

          {/* 하루 평균 일 택배건수 */}
          <input
            type='number'
            value={shippingOrderCapa}
            placeholder='하루 평균 일 택배건수 (예: 500)'
            className='w-1/2 mb-3'
            onChange={(e) => {
              setShippingOrderCapa(parseInt(e.target.value, 10) || 0)
            }}
          />

          {/* 셀러 유형 선택 */}
          <select
            placeholder='셀러 유형'
            className='w-1/2 mb-3'
            value={sellerDistributionType}
            onChange={(e) => {
              setSellerDistributionType(e.target.value)
            }}
          >
            <option value=''>셀러 유형을 선택해주세요</option>
            <option value='producer'>생산</option>
            <option value='distribution'>유통</option>
          </select>

          {/* 대표 카테고리 선택 */}
          <select
            className='w-1/2 mb-3'
            value={categoryId}
            id='large_category'
            name='large_category'
            onChange={(e) => {
              setCategoryId(parseInt(e.target.value, 10))
            }}
          >
            <option value=''>대표 카테고리를 선택해주세요</option>
            {ItemStore.categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.largeCategoryNames.kr}
              </option>
            ))}
          </select>

          {/* 대표 판매 채널의 URL */}
          <Input
            className='w-1/2 mb-3'
            type='text'
            placeholder='대표 판매 채널의 URL을 입력해주세요'
            value={urlLink}
            onChange={(e) => {
              setUrlLink(e.target.value)
            }}
          />

          {/* 하단 안내사항 */}
          <div className='mb-5 mt-2'>
            * 가입하신 이메일로 상품 등록 방법 및 매출 활성화 가이드를
            보내드렸으니 확인해주세요!
          </div>

          {/* 제출 버튼 */}
          <Button
            appearance='positive'
            size='xl'
            type='button'
            onClick={async () => {
              if (
                !repName || // 따로 repName 필드 생성하기
                !corpName || // sellerDisplayName 필드에 들어감
                !businessRegistrationNumber ||
                // !mailOrderSalesRegistrationNumber || 통신판매업신고 면제 대상자가 있어서, 제외
                !bankCode ||
                !depositorName ||
                !bankAccountNumber ||
                !contactNumber ||
                !description ||
                !address ||
                !defaultShippingCompanyId ||
                !shippingOrderCapa ||
                !sellerDistributionType ||
                categoryId === null ||
                urlLink.length === 0
              ) {
                alert(`입력하지 않은 정보가 없는지 확인해주세요.`)
                return
              }
              if (!bankAccountNumber) {
                alert('사업자 검증 버튼을 눌러 정보 입력을 완료해주세요.')
                return
              }
              // seller 콜렉션에서 중복 체크
              // const registerCheck = await backendApis.checkRegisteredBizNum(
              //   businessRegistrationNumber,
              // )
              // if (registerCheck?.status === 200) {
              //   if (registerCheck?.data?.isRegistered === true) {
              //     alert(
              //       '이미 등록된 사업자 번호입니다.\n하나의 사업자 번호로 한 번만 등록이 가능합니다.',
              //     )
              //     SellerStore.setIsLoading(false)
              //     return
              //   }
              // }
              if (SellerStore.isLoading) {
                return
              }
              SellerStore.setIsLoading(true)
              // if (SellerStore.sellerInfo.isTestingSeller) {
              //   SellerStore.sellerInfo.kakaoChannelUrl =
              //     'https://open.kakao.com/o/s7IUusWd'
              // }

              const bankAccountInfo = {
                bankCode: bankCode,
                depositorName: depositorName,
                bankAccountNumber: bankAccountNumber,
              }

              const result = await backendApis.registerSellerDetail(
                SellerStore.sellerInfo.sellerName, // 아이디
                corpName, // 상호/법인명 (sellerDisplayName 필드)
                description,
                SellerStore.sellerInfo.phoneNumber,
                contactNumber,
                SellerStore.sellerInfo.email,
                '82',
                address,
                mailOrderSalesRegistrationNumber, // 통신판매업번호 (mailOrderBusinessNumber 필드)
                businessRegistrationNumber,
                // kakaoChannelUrl,
                defaultShippingCompanyId,
                bankAccountInfo,
                SellerStore.sellerInfo.representorName,
                'inboundSeller',
                shippingOrderCapa,
                sellerDistributionType,
                categoryId,
                urlLink,
                repName, // 따로 validRepName 필드 추가
                corpName, // 따로 validCorpName 필드 추가
                businessRegistrationNumberImage, // 여기서 이미 검증완료된 이미지 링크 추가
              )

              if (result?.status === 200) {
                alert('추가 정보 제출이 완료되었습니다.')
                // await backendApis.uploadBusinessNumberImage(
                //   businessRegistrationNumberImage,
                //   SellerStore.sellerInfo._id,
                // )
                SellerStore.setIsLoading(false)

                setRepName('')
                setCorpName('')
                setBusinessRegistrationNumber('')
                setMailOrderSalesRegistrationNumber('')
                setBankCode('')
                setDepositorName('')
                setBankAccountNumber('')
                setContactNumber('')
                setDescription('')
                setAddress('')
                setDefaultShippingCompanyId('')
                setShippingOrderCapa('')
                setSellerDistributionType('')
                setCategoryId(null)
                setUrlLink('')
                AuthStore.setToken(result.data.token)
                localStorage.setItem('@alwayz@seller@token@', result.data.token)
                const loadSellerResult = await SellerStore.loadSellerInfo()
                if (loadSellerResult) {
                  history.replace('/')
                } else {
                  alert('입력하지 않은 정보가 없는지 확인해주세요.')
                }
              }

              SellerStore.setIsLoading(false)
            }}
          >
            추가 정보 제출하기
          </Button>
        </div>

        {/* 카카오톡 1:1 문의하기 버튼 */}
        <div className='flex items-center justify-center mt-5'>
          <Button
            size='lg'
            onClick={(e) => {
              e.preventDefault()
              window.open('http://pf.kakao.com/_vxefts', '_blank')
              if (SellerStore.loadSellerInfo())
                Log.event(
                  'SellerSupportScreenButton',
                  'SellerSupportScreenButton',
                  {
                    click: 'SellerSupportScreenButton',
                  },
                )
            }}
          >
            카카오톡 1:1 문의하기 →
          </Button>
        </div>
      </div>
    </>
  )
})

export default RegisterSellerBasicsScreen

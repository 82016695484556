import DetailedInfo from '../atoms/detailedInfo'

const CouponDetailedInfoHandler = ({ tab }) => {
  if (tab === 1) {
    return (
      <DetailedInfo
        ranklimit='B'
        name='베이직 쿠폰'
        purpose='일반 상품 할인으로 상품 전환율 향상'
        space='모든 상품페이지'
        time='상시'
        settlement='고객이 쿠폰 사용시 정산금 차감'
        minDiscountInfo='최소 500원'
        etc={
          <ol className='ml-5 list-disc'>
            <li>
              최소 500원부터 최대 10,000원 쿠폰이 발급 가능하며 고객이 다운로드
              받은 이후
              <br />
              <span className='font-bold text-positive-500'>
                2시간 이내 소멸
              </span>
              되는 쿠폰이 제공됩니다.
            </li>
            <li>
              <span className='font-bold text-positive-500'>
                7,000원 이상인 상품
              </span>
              의 경우 1,000원 부터 발급이 가능합니다.
            </li>
          </ol>
        }
        images={[
          'https://d22yqrpf3lokox.cloudfront.net/14514695-8d6f-4040-bcd3-19ca2d49be90.png',
        ]}
      />
    )
  }
  if (tab === 2) {
    return (
      <DetailedInfo
        ranklimit='B'
        name='다다익선 쿠폰'
        purpose='일반 상품 할인으로 상품 전환율 향상'
        space='모든 상품페이지'
        time='상시'
        settlement='고객이 쿠폰 사용시 정산금 차감'
        minDiscountInfo='최소 500원'
        etc={
          <ol className='ml-5 list-disc'>
            <li>
              다다익선 쿠폰을 신청하면 고객이 2개 이상 선택했을 시에 쿠폰이
              제공되며, 상품 1개를 구매하려던 고객이 2개 이상 구매하도록
              유도합니다.
            </li>
            <li>
              최소 500원부터 쿠폰 신청이 가능하며,{' '}
              <span className='font-bold text-positive-500'>
                7,000원 이상인 상품
              </span>
              의 경우 1,000원 부터 발급이 가능합니다.
            </li>
          </ol>
        }
        images={[
          'https://d22yqrpf3lokox.cloudfront.net/21b4eb1f-f8ca-4a51-a8be-0208222c40a3.png',
        ]}
      />
    )
  }
  if (tab === 3) {
    return (
      <DetailedInfo
        ranklimit='B'
        name='팔로우 쿠폰'
        purpose='팔로우 유도를 통한 단골 확보'
        space='모든 상품페이지'
        time='상시'
        settlement='고객이 쿠폰 사용시 정산금 차감'
        minDiscountInfo='최소 500원'
        etc={
          <ol className='ml-5 list-disc'>
            <li>
              팔로우 쿠폰을 신청하면 고객이 셀러를 팔로우하고 알림 설정에
              동의하였을 경우 쿠폰이 제공됩니다
            </li>
            <li>
              최소 500원부터 쿠폰 신청이 가능하며,{' '}
              <span className='font-bold text-positive-500'>
                7,000원 이상인 상품
              </span>
              의 경우 1,000원 부터 발급이 가능합니다.
            </li>
          </ol>
        }
        images={[
          'https://d22yqrpf3lokox.cloudfront.net/ac1f3fc2-79a9-4d6a-9665-3d7820a6ba9d.png',
        ]}
      />
    )
  }
  if (tab === 4) {
    return (
      <DetailedInfo
        // ranklimit='B'
        name='사진후기 쿠폰'
        purpose='사진후기 쿠폰으로 상품 전환율 향상'
        space='모든 상품페이지'
        time='상시'
        settlement='고객이 쿠폰 사용시 정산금 차감'
        minDiscountInfo='최소 500원'
        etc={
          <ol className='ml-5 list-disc'>
            <li>
              상품 구매 이후 사진 후기를 작성하면 판매자님의 상품에만 적용
              가능한 사진 후기 쿠폰이 발급됩니다.
            </li>
            <li>
              사진 후기 개수를 늘리고{' '}
              <span className='font-bold text-positive-500'>재구매 유도</span>
              까지 할 수 있습니다.
            </li>
          </ol>
        }
        images={[
          'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/7cf67f37-c030-4ae5-ba04-a4444ad749c6.png',
        ]}
      />
    )
  }
  if (tab === 5) {
    return (
      <DetailedInfo
        ranklimit='B'
        name='판매자 쿠폰'
        purpose='판매자 상품에만 사용 가능한 쿠폰으로 단골 확보'
        space='모든 상품페이지'
        time='상시'
        settlement='고객이 쿠폰 사용시 정산금 차감'
        minDiscountInfo='최소 1000원'
        etc={
          <ol className='ml-5 list-disc'>
            <li>
              고객이 판매자 쿠폰은 신청한 셀러님의 상품을 구매하면, 판매자님의
              상품에만 적용 가능한 쿠폰이 발급됩니다.
            </li>
            <li>해당 쿠폰은 신청 즉시 발급이 시작됩니다.</li>
            <li>취소는 판매자 운영 지원팀을 통해 가능합니다.</li>
          </ol>
        }
        images={[
          'https://d22yqrpf3lokox.cloudfront.net/156aec85-a471-4d01-8adf-e992b3301b6a.png',
        ]}
      />
    )
  }
  return <></>
}

export default CouponDetailedInfoHandler

import Button from '../components/atoms/button'
import React, { useState, useEffect, useMemo } from 'react'
import { GrClose as CloseIcon } from 'react-icons/gr'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import adSettingByTab from '../data/adSettingByTab'
import backendApis from '../utils/backendApis'
import Table from '../components/ADS/molecules/table'
import Pagination from '../components/molecules/pagination'
import RankIconSetter from '../components/molecules/rankIconSetter'
import Tooltip from '../components/ADS/atoms/tooltip'
import commaNumber from 'comma-number'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import ListDisCountRateSelector from '../components/atoms/listDiscountRateSelector'
import ListStockSelector from '../components/atoms/listStockSelector'

const conditionSetter = (rankStatusTab) => {
  if (rankStatusTab === 'All') return []
  if (rankStatusTab === 'B') return rankBcondition
  if (rankStatusTab === 'A') return rankAcondition
  if (rankStatusTab === 'S') return rankScondition
  return []
}

const rankScondition = [
  { key: 'itemRank', value: 'B' },
  { key: 'itemRank', value: 'A' },
]
const rankAcondition = [
  { key: 'itemRank', value: 'B' },
  { key: 'itemRank', value: 'S' },
]
const rankBcondition = [
  { key: 'itemRank', value: 'A' },
  { key: 'itemRank', value: 'S' },
]

const TimeDealBulkApplyModal = observer(({ showModal, setShowModal }) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [itemsCount, setItemsCount] = useState(0)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [itemsInfo, setItemsInfo] = useState([])
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)
  const [stockNumber, setStockNumber] = useState(1000)
  const [discountRate, setDisCountRate] = useState(15)
  const adStatusTab = 'candidate'
  const tab = 1
  const [rankStatusTab, setRankStatusTab] = useState('All')
  const [rankCondition, setRankCondition] = useState([])
  const cleanUp = () => {
    setPage(1)
    setLimit(20)
    setItemsInfo([])
    setItemsCount(0)
    setIsAllCheckBoxChecked(false)
    setCheckedItemIds([])
    setRankStatusTab('All')
  }

  const fetchSegmentedItemsInfo = async (
    isNewCondition,
    rankCondition = [],
  ) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    if (adStatusTab === 'candidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: true }
      itemCondition.itemRankTestingSku = { $ne: true }
      itemCondition.rankInfo = { $ne: null }
      itemCondition.teamPurchasePrice = { $gt: 100 }
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: {
                $or: [
                  { key: 'deal', value: 'timeDeal' },
                  { key: 'itemRank', value: 'C' },
                  ...rankCondition,
                ],
              },
            },
          },
        },
      ]
    } else if (adStatusTab === 'complete') {
      itemCondition.soldOut = false
      itemCondition.$or = [
        {
          outOfStock: false,
        },
        {
          outOfStock: null,
        },
      ]
      itemCondition.itemFilters = {
        $ne: null,
        $elemMatch: {
          $and: [{ key: 'ad', value: adSettingByTab?.[tab].type }],
        },
      }
    }

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
        setPage(1)
        setItemsInfo([])
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)

      if (adStatusTab === 'complete') {
        const adResult = await backendApis.getAdItemCreditUsage(
          'searchAd',
          true,
        )
        if (adResult?.status === 200) {
          const allResult = new Map()
          result?.data?.itemsInfo.forEach((item) =>
            allResult.set(String(item._id), item),
          )
          adResult?.data
            .filter((item) => !item?.deletedAt)
            .forEach((item) =>
              allResult.set(String(item.itemId), {
                ...allResult.get(String(item.itemId)),
                adVisits: item?.adVisits,
                adCost: item?.adCost,
                roas:
                  item?.adCost > 0
                    ? Math.round(
                        (1000 * Number(item?.revenueWithAd)) /
                          (Number(item?.adCost) / 1.1),
                      ) / 10
                    : '-',
                purcahse: item?.purcahse,
                keywords: item?.detailInfo?.keywords,
                adOngoing: item?.adOngoing,
                adCreatedAt: item?.createdAt,
                dailyCostLimit: item?.detailInfo?.dailyCostLimit,
                revenueWithAd: item?.revenueWithAd,
                status: item?.status,
              }),
            )
          setItemsInfo(Array.from(allResult.values()))
        }
      }
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    if (showModal) {
      fetchSegmentedItemsInfo(true, rankCondition)
    }
  }, [showModal, limit, adStatusTab, discountRate, stockNumber])

  useEffect(() => {
    if (showModal && itemsInfo?.length) {
      fetchSegmentedItemsInfo(false, rankCondition)
    }
  }, [page])

  const columns = useMemo(
    () => [
      {
        Header: (
          <div className='select-none'>
            <input
              type='checkbox'
              checked={isAllCheckBoxChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedItemIds(itemsInfo?.map((itemInfo) => itemInfo?._id))
                  setIsAllCheckBoxChecked(true)
                } else {
                  setCheckedItemIds([])
                  setIsAllCheckBoxChecked(false)
                }
              }}
            />
          </div>
        ),
        accessor: 'status',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            상품명
          </span>
        ),
        accessor: 'itemTitle',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            등급
          </span>
        ),
        accessor: 'itemRank',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            추천 대표 행사가
            <Tooltip text='가장 매출이 폭발적으로 성장하는 대표 팀구매가의 가격입니다. 할인은 할인율에 따라 모든 옵션에 적용됩니다' />
          </span>
        ),
        accessor: 'recommendedDealTeamPurchasePrice',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            팀구매가
          </span>
        ),
        accessor: 'teamPurchasePrice',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            대표 행사가
            <Tooltip text='전체 옵션 중 대표 팀구매가의 가격입니다. 할인은 할인율에 따라 모든 옵션에 적용됩니다' />
          </span>
        ),
        accessor: 'dealTeamPurchasePrice',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            재고량
          </span>
        ),
        accessor: 'stockNumber',
      },
      // {
      //   Header: '광고비 비중',
      //   accessor: 'adCostRatio',
      // },
    ],
    [isAllCheckBoxChecked, itemsInfo, checkedItemIds],
  )
  const adData = useMemo(
    () =>
      itemsInfo
        ?.filter((item) => item?.itemTitle)
        .map((item, idx) => {
          return {
            onOff: (
              <div className='ml-2'>
                <div className='relative inline-block w-10 mr-2 align-middle select-none'>
                  <label
                    htmlFor={`onOff_${idx}`}
                    className='block h-6 overflow-hidden bg-gray-300 rounded-full outline-none cursor-pointer checked:bg-blue-500'
                  >
                    <input
                      type='checkbox'
                      name='toggle'
                      id={`onOff_${idx}`}
                      checked={item?.adOngoing}
                      className='absolute block w-4 h-4 duration-200 ease-in bg-white border-0 rounded-full appearance-none cursor-pointer ring-transparent ring-offset-0 focus:ring-offset-0 focus:ring-transparent outline-0 top-1 checked:h-6 checked:w-6 checked:bg-blue-500 right-5 checked:top-0 checked:right-0'
                    />
                  </label>
                </div>
              </div>
            ),
            status: (
              <div className='select-none'>
                <input
                  type='checkbox'
                  checked={checkedItemIds?.includes(item?._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckedItemIds([
                        ...checkedItemIds?.filter(
                          (itemId) => itemId !== item?._id,
                        ),
                        item?._id,
                      ])
                    } else {
                      setCheckedItemIds(
                        checkedItemIds?.filter(
                          (itemId) => itemId !== item?._id,
                        ),
                      )
                    }
                  }}
                />
              </div>
            ),
            itemTitle: (
              <div>
                {item?.itemTitle.slice(0, 24)}
                {item?.itemTitle.length > 24 && '...'}
              </div>
            ),
            itemRank: (
              <div className='flex items-center justify-center flex-1'>
                {item?.rankInfo && (
                  <RankIconSetter
                    itemRank={item?.rankInfo?.itemRank}
                    className='w-4 h-4'
                  />
                )}
              </div>
            ),
            itemPrice: (
              <div className='flex flex-row items-center justify-center flex-1'>
                {commaNumber(item?.teamPurchasePrice)}원
              </div>
            ),
            recommendedDealTeamPurchasePrice: (
              <div className='flex flex-row items-center justify-center flex-1 font-bold'>
                {commaNumber(parseInt(item?.teamPurchasePrice * 0.85) || 0)}원
              </div>
            ),
            teamPurchasePrice: (
              <div>{commaNumber(item?.teamPurchasePrice)}원</div>
            ),
            dealTeamPurchasePrice: (
              <div className='flex flex-row items-center justify-center flex-1'>
                {commaNumber(
                  parseInt(
                    item?.teamPurchasePrice * (1 - 1 * (discountRate / 100)),
                  ) || 0,
                )}
                원
              </div>
            ),
            stockNumber: (
              <div className='flex flex-row items-center justify-center flex-1'>
                {parseInt(stockNumber) || 100}개
              </div>
            ),
            // adCostRatio: (
            //   <div>
            //     {parseInt(
            //       (parseInt(
            //         item?.teamPurchasePrice /
            //           (roasSetter(item?.rankInfo?.itemRank) / 100),
            //         10,
            //       ) /
            //         item?.teamPurchasePrice) *
            //         100,
            //       10,
            //     )}
            //     %
            //   </div>
            // ),
          }
        }),
    [checkedItemIds, itemsInfo, isAllCheckBoxChecked, adStatusTab],
  )

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden outline-none'>
            <div className='relative flex-col w-1/2 p-10 overflow-y-scroll bg-white rounded-md shadow-md h-3/4'>
              <div className='flex flex-col flex-1'>
                <div className='flex flex-row items-center justify-between flex-1'>
                  <div className='mb-5 text-xl font-bold'>
                    타임특가 대량등록
                  </div>
                  <Button
                    appearance='transparent'
                    onClick={() => {
                      cleanUp()
                      setShowModal(false)
                    }}
                  >
                    <CloseIcon className='w-4 h-4' />
                  </Button>
                </div>
                <div>
                  타임특가 신청이 가능한 상품들을 한 번에 신청할 수 있습니다.
                </div>
                <div>
                  신청된 상품은{' '}
                  <span className='font-bold text-blue-500'>
                    대표 행사가 / 재고량
                  </span>
                  으로 설정되며 선택하여 행사가 및 재고량을 조절할 수 있습니다.
                </div>

                <div>
                  타임특가 신청된 상품은 평균적으로
                  <span className='font-bold text-blue-500'>
                    {' '}
                    2배 이상 매출
                  </span>
                  이 더 증가합니다
                </div>
                <div>
                  가격은 모든 옵션에 대해 할인율만큼 적용되며 옵션 별로 할인율을
                  다르게 하고 싶으신 경우 타임특가 페이지에서 수정이 가능합니다.
                </div>
                <div className='my-2 text-sm text-gray-500'>
                  * 개별 조정이 필요한 상품의 경우 '2.노출관리 > 타임특가 신청'
                  메뉴에서 상세 옵션을 조정하여 등록 및 수정 하실 수 있습니다.
                </div>
                <div className='flex flex-row justify-end flex-1 mt-4'>
                  <div className='flex flex-1 gap-2'>
                    <Button
                      className='mr-2'
                      onClick={async () => {
                        if (SellerStore.isLoading === true) return
                        SellerStore.setIsLoading(true)
                        const checkedItemInfo = itemsInfo.filter((item) => {
                          return checkedItemIds?.includes(item?._id)
                        })
                        const dealItemsInfo = []
                        checkedItemInfo.forEach((item) =>
                          dealItemsInfo.push({
                            itemId: item?._id,
                            discountRate,
                            stockNumber,
                          }),
                        )
                        const result =
                          await backendApis.registerTimeDealBulkApplication(
                            dealItemsInfo,
                          )
                        SellerStore.setIsLoading(false)
                        if (result?.status === 200) {
                          fetchSegmentedItemsInfo(
                            true,
                            conditionSetter(rankStatusTab),
                          )
                          alert(`타임특가 대량 신청이 완료되었습니다.`)
                        } else {
                          fetchSegmentedItemsInfo(
                            true,
                            conditionSetter(rankStatusTab),
                          )
                          alert(
                            '타임특가 대량 신청 실패했습니다. 다시 시도해주세요.',
                          )
                        }
                      }}
                    >
                      선택 상품 타임특가 신청
                    </Button>
                  </div>
                  <ListDisCountRateSelector
                    discountRate={discountRate}
                    setDiscountRate={setDisCountRate}
                    dealType='timeDeal'
                  />
                  <div className='ml-2'>
                    <ListStockSelector
                      stockNumber={stockNumber}
                      setStockNumber={setStockNumber}
                      dealType='timeDeal'
                    />
                  </div>
                  <div className='ml-2'>
                    <ListLimitSelector limit={limit} setLimit={setLimit} />
                  </div>
                </div>
                <div>
                  <Table columns={columns} data={adData} />
                  <Pagination
                    total={itemsCount}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    size='lg'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})
export default TimeDealBulkApplyModal

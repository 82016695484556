import moment from 'moment'
import StarRating from '../atoms/starRating'

const OneClickReviewComponent = ({ reviewInfo }) => {
  return (
    <div>
      <div className='flex flex-row items-center justify-between flex-1 mb-3'>
        <div>
          <StarRating score={parseInt(reviewInfo?.reviewScore, 10)} />
        </div>
        <div className='text-gray-500'>
          {moment(reviewInfo?.createDate).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      </div>
      <div>{reviewInfo?.reviewContent}</div>
    </div>
  )
}
export default OneClickReviewComponent

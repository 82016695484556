import React, { useEffect, useMemo, useState } from 'react'
import Page from '../components/atoms/page'
import DealRankNotice from '../components/molecules/dealRankNotice'
import ItemComponent from '../components/ItemComponents/ItemComponent'
import Pagination from '../components/molecules/pagination'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import { FcHighPriority, FcIdea, FcPrivacy as TitleIcon } from 'react-icons/fc'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import { observer } from 'mobx-react-lite'
import Button from '../components/atoms/button'
import Input from '../components/atoms/input'
import Table from '../components/ADS/molecules/table'
import resize from '../utils/resize'
import ExcelFileUploadModal from '../components/molecules/excelFileUploadModal'
import VerificationScreen from './VerificationScreen'

const ReviewTransferApplication = observer(() => {
  const [selectedItem, setSelectedItem] = useState()
  const [selectedSearchField, setSelectedSearchField] = useState('itemTitle')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [itemsCount, setItemsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemQuery, setItemQuery] = useState('')
  const [verified, setVerified] = useState(false)
  const type = 'reviewTransfer'
  const sellerInfo = SellerStore?.sellerInfo
  const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    if (itemQuery) {
      if (selectedSearchField === 'itemTitle') {
        itemCondition.itemTitle = { $regex: escapeRegExp(itemQuery) }
      } else if (selectedSearchField === 'itemId') {
        itemCondition._id = {
          $in: [itemQuery],
        }
      }
    } else {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
    }
    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        SellerStore.setIsLoading(false)
        return
      }
    }
    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    setIsLoading(false)
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [limit])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])
  useEffect(async () => {
    await backendApis.recordEventLog(
      'ReviewTransferApplicationScreen',
      'entered screen',
    )
  }, [])

  useEffect(() => {
    if (sellerInfo?.sellerFilters && Array.isArray(sellerInfo?.sellerFilters)) {
      const verificationCondition =
        sellerInfo?.sellerFilters?.filter((filter) => {
          return filter.key === 'reviewTransfer' && filter.value === true
        })?.length > 0
      setVerified(verificationCondition || false)
    }
  }, [sellerInfo])

  const columns = useMemo(() => {
    const result = [
      { Header: '선택 상품', accessor: 'itemInfo' },
      { Header: '요청하기', accessor: 'buttonStatus' },
    ]
    return result
  }, [])

  const exportReviewTransferExcelInfo = async () => {
    const url =
      'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/review%20transfer%20file.xlsx'
    try {
      const res = await fetch(url)
      if (res.status === 200) {
        const xlsxArrayBuffer = await res.arrayBuffer()
        const data = new Uint8Array(xlsxArrayBuffer)
        const arr = []
        for (let i = 0; i < data.length; i += 1) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')
        // Assuming that you're using the 'xlsx' library:
        const { read, utils } = window.XLSX
        const workbook = read(bstr, {
          type: 'binary',
        })
        utils.writeFile(workbook, 'Review Transfer.xlsx')
      }
    } catch (error) {
      window.location.href = url
    }
  }

  const itemData = useMemo(() => {
    return itemsInfo?.map((item, index) => {
      return {
        itemInfo: (
          <div
            key={item?._id}
            style={{ width: '300px', height: '150px', overflow: 'auto' }}
          >
            <div className='flex flex-row justify-between flex-1'>
              <div className='flex flex-row flex-1 w-max'>
                <img
                  alt='mainImage'
                  src={
                    item?.mainImageUris ? resize(item?.mainImageUris[0]) : ''
                  }
                  className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
                />
                <div className='flex flex-col'>
                  <div className='mr-3'>
                    <div className='mb-1 text-lg font-bold whitespace-normal'>
                      {item?.itemTitle}
                    </div>
                  </div>
                  <div className='mb-1 text-sm font-normal text-gray-400'>
                    {item?._id}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),

        buttonStatus: (
          <div className='flex flex-row justify-between flex-1 max-w-1xl'>
            <ExcelFileUploadModal
              modalTitle='후기 이관'
              filename='후기 이관'
              infoType='reviewTransfer'
              itemId={item?._id}
            >
              후기 이관 신청하기
            </ExcelFileUploadModal>
          </div>
        ),
      }
    })
  }, [itemsInfo, selectedItem])

  return (
    <>
      {verified ? (
        <>
          <div className='w-screen max-w-7xl'>
            <Page title='후기 이관 신청' Icon={TitleIcon}>
              <div className='flex flex-wrap justify-between'>
                <div className='shadow-lg rounded-2xl p-8 bg-white min-w-[405px] flex-auto mr-4 mb-4'>
                  <div>
                    이 페이지에서는 자사몰 후기를 최대 50개까지 올웨이즈로
                    이전할 수 있습니다. <br />
                  </div>
                  <div className='flex flex-col flex-1 p-5 mt-4 bg-gray-100 rounded-xl'>
                    <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
                      <FcIdea className='mr-2' />
                      대상 후기
                    </span>
                    <ul className='mx-4 list-disc'>
                      <li>
                        연동 대상 후기는{' '}
                        <span className='font-bold text-blue-500'>
                          {' '}
                          고객이 구매 후 작성한 자사몰 후기를 원칙으로 합니다.
                        </span>
                      </li>
                    </ul>
                    <span className='flex flex-row items-center flex-1 mt-6 mb-1 font-bold'>
                      <FcHighPriority className='mr-2' />
                      주의사항
                    </span>
                    <ul className='mx-4 list-disc'>
                      <li>
                        형식을 준수하지 않은 후기, 대상 후기 조건에 맞지 않는
                        후기 등은{' '}
                        <span className='font-bold text-blue-500'>
                          등록 거부될 수 있습니다.
                        </span>
                      </li>
                      <li>
                        <span className='font-bold text-blue-500'>
                          엑셀 형식에 맞게 정확히 기입해주세요.
                        </span>
                      </li>
                      <li>
                        고객이 직접 작성한 자사몰 후기가 아닌 경우{' '}
                        <span className='font-bold text-blue-500'>
                          법적인 책임을 질 수 있습니다.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <div className='flex items-center justify-between flex-1 mt-10 mb-5'>
                  <div className='flex justify-between'>
                    <div>
                      <select
                        value={selectedSearchField}
                        className='border-r-0 border-gray-300 shadow-sm select-none rounded-l-md focus:border-indigo-500 focus:ring-indigo-500'
                        onChange={(e) => setSelectedSearchField(e.target.value)}
                      >
                        <option value='itemTitle'>상품명</option>
                        <option value='itemId'>상품아이디</option>
                      </select>
                      <Input
                        className='text-left border-gray-300 shadow-sm select-none w-60 rounded-r-md focus:border-indigo-500 focus:ring-indigo-500'
                        type='text'
                        placeholder={`${
                          selectedSearchField === 'itemTitle'
                            ? '상품 키워드'
                            : '상품 아이디'
                        }를 입력해 주세요.`}
                        value={itemQuery}
                        onChange={(e) => {
                          setItemQuery(e.target.value)
                        }}
                      />
                      <Button
                        onClick={() => fetchSegmentedItemsInfo(true)}
                        appearance='positive'
                        size='md'
                        className='w-20 mx-3'
                      >
                        검색
                      </Button>
                      <ListLimitSelector limit={limit} setLimit={setLimit} />
                      <Button
                        className='ml-3'
                        onClick={exportReviewTransferExcelInfo}
                      >
                        엑셀 양식 다운로드
                      </Button>
                    </div>
                  </div>
                </div>
                <div>
                  <Table columns={columns} data={itemData} />
                  <div>
                    <Pagination
                      total={itemsCount}
                      limit={limit}
                      page={page}
                      setPage={setPage}
                      size='lg'
                    />
                  </div>
                </div>
              </div>
            </Page>
          </div>
        </>
      ) : (
        <VerificationScreen type={type} setVerified={setVerified} />
      )}
    </>
  )
})

export default ReviewTransferApplication

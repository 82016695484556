import ImageUploading from 'react-images-uploading'
import backendApis from '../../utils/backendApis'
import { useEffect, useState } from 'react'
import validateBusinessImage from '../../utils/businessRegistrationUtils/businessImageValidation'
import BusinessInfoParser from '../../utils/businessRegistrationUtils/businessInfoParser'

const BusinessImageSection = ({ sellerInfo, onNextStep, onPreviousStep }) => {
  const [businessImage, setBusinessImage] = useState(null)
  const [isValidBusinessImage, setIsValidBusinessImage] = useState(null) // 첫번째 approved 필드
  const [isValidBusinessNumber, setIsValidBusinessNumber] = useState(false) // 두번째 approved 필드, disabled의 조건 t/f
  const [isReLoad, setIsReLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [validationComplete, setValidationComplete] = useState(false)
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [extractedData, setExtractedData] = useState({
    repName: '',
    openDate: '',
    registerNumber: '',
  })
  const [error, setError] = useState('')

  useEffect(async () => {
    const sellerBusinessInfo =
      await backendApis.loadSellerBusinessRegistrationInfo()
    setBusinessImage(sellerBusinessInfo?.data?.businessRegistrationNumberImage)
    setIsValidBusinessImage(
      sellerBusinessInfo?.data?.isValidBusinessRegistrationNumberImage,
    )
    if (
      sellerBusinessInfo?.data?.isValidBusinessRegistrationNumberImage ===
      'approved'
    ) {
      setValidationComplete(true)
      setExtractedData({
        repName: BusinessInfoParser.parseBusinessRegistrationRepName(
          sellerBusinessInfo?.data?.businessRegisteredData?.repName,
        ),
        openDate: BusinessInfoParser.parseBusinessRegistrationOpenDate(
          sellerBusinessInfo?.data?.businessRegisteredData?.openDate,
        ),
        registerNumber:
          sellerBusinessInfo?.data?.businessRegisteredData?.registerNumber,
      })
      setIsValidBusinessNumber(
        sellerBusinessInfo?.data?.isValidBusinessRegistrationNumber ===
          'approved',
      )
    }
  }, [isReLoad])

  const handleImageUpload = async (imageList) => {
    setIsLoading(true)
    try {
      const response =
        await backendApis.registerBusinessRegistrationNumberImage(
          imageList,
          sellerInfo._id,
        )
      if (response?.status === 200) {
        setBusinessImage(imageList)
        setError('')
        alert('이미지 업로드가 완료되었습니다.')
        setIsReLoad((prev) => !prev)
      } else {
        setError('이미지 업로드에 실패했습니다. 다시 시도해주세요.')
        alert('이미지 업로드에 실패했습니다. 다시 시도해주세요.')
        setIsReLoad((prev) => !prev)
      }
    } catch (error) {
      setError('이미지 업로드 중 오류가 발생했습니다.')
      alert('이미지 업로드 중 오류가 발생했습니다.')
      setIsReLoad((prev) => !prev)
    } finally {
      setIsLoading(false)
    }
  }

  const handleStartValidation = async () => {
    setIsValidating(true)
    try {
      const result = await validateBusinessImage(sellerInfo._id, businessImage)
      if (result.status === 200) {
        setError('')
        alert('사업자등록증 검증이 완료되었습니다.')
        setValidationComplete(true)
        setExtractedData({
          repName: BusinessInfoParser.parseBusinessRegistrationRepName(
            result?.data?.repName,
          ),
          openDate: BusinessInfoParser.parseBusinessRegistrationOpenDate(
            result?.data?.openDate,
          ),
          registerNumber: result?.data?.registerNumber,
        })
        setIsReLoad((prev) => !prev)
      } else {
        setError(result.message)
        alert(result.message)
      }
    } catch (error) {
      setError('검증 중 오류가 발생했습니다. 다시 시도해주세요.')
      alert('검증 중 오류가 발생했습니다. 다시 시도해주세요.')
    } finally {
      setIsValidating(false)
    }
  }

  const handleChangeImage = async () => {
    const resetResult = await backendApis.resetBusinessRegistrationNumberImage(
      sellerInfo._id,
    )
    if (resetResult?.status === 200) {
      setIsValidating(false)
      setValidationComplete(false)
      setExtractedData({
        repName: '',
        openDate: '',
        registerNumber: '',
      })
      setIsReLoad((prev) => !prev)
    } else {
      alert('이미지 변경에 실패했습니다. 다시 시도해주세요.')
    }
  }

  const Loader = () => (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white'></div>
    </div>
  )

  return (
    <div className='p-5'>
      {isLoading && <Loader />}
      <div className='flex flex-col space-y-4'>
        <div className='border rounded-lg p-6'>
          {!businessImage ? (
            // 사업자등록증 이미지 업로드 전
            <div className='flex flex-col items-center space-y-4'>
              <div className='text-gray-400 mb-4'>
                <svg
                  className='w-16 h-16'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z'
                  />
                </svg>
              </div>
              <ImageUploading
                multiple={false}
                value={[]}
                onChange={(imageList) => {
                  const isConfirmed =
                    confirm('사업자등록증을 업로드하시겠습니까?')
                  if (isConfirmed) {
                    handleImageUpload(imageList)
                  }
                }}
                maxFileSize={5e6}
                acceptType={['jpg', 'png', 'jpeg']}
                resolutionType='more'
                resolutionWidth={800}
                resolutionHeight={800}
                onError={(e) => {
                  if (e.maxFileSize) {
                    alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
                  } else if (e.acceptType) {
                    alert(
                      '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/jpeg/png)',
                    )
                  } else if (e.resolution) {
                    alert(
                      '선명한 이미지를 올려주세요.\n(최소 800px*800px 이상)',
                    )
                  }
                }}
                allowNonImageType={false}
                maxNumber={1}
                dataURLKey='sourceUri'
              >
                {({ onImageUpload }) => (
                  <button
                    type='button'
                    onClick={onImageUpload}
                    disabled={isLoading}
                    className={`px-6 py-3 bg-blue-500 text-white rounded-md 
                      transition-colors duration-200 flex items-center space-x-2
                      ${
                        isLoading
                          ? 'opacity-50 cursor-not-allowed'
                          : 'hover:bg-blue-600'
                      }`}
                  >
                    <svg
                      className='w-5 h-5'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
                      />
                    </svg>
                    <span>
                      {isLoading ? '업로드 중...' : '사업자등록증 업로드'}
                    </span>
                  </button>
                )}
              </ImageUploading>
              <p className='text-sm text-gray-500 text-center'>
                JPG, JPEG, PNG 파일 (최대 5MB)
                <br />
                최소 해상도 800x800px
              </p>
              <button
                type='button'
                onClick={() => setIsReLoad((prev) => !prev)}
                className='font-semibold text-gray-500 hover:text-gray-900 transition-colors text-base'
              >
                새로고침
              </button>
              <button
                type='button'
                onClick={onPreviousStep}
                className='px-6 py-3 bg-gray-600 text-white rounded-md 
                          transition-colors duration-200 w-full hover:bg-gray-700
                          font-semibold text-lg shadow-md'
              >
                이전 단계로
              </button>
            </div>
          ) : (
            // 사업자등록증 이미지 업로드 후
            <div className='flex flex-col items-center space-y-4'>
              <div className='relative w-5/6 max-w-md'>
                <button
                  type='button'
                  className='bg-transparent border-0'
                  onClick={() => setIsImageModalOpen(true)}
                >
                  <img
                    src={businessImage}
                    alt='사업자등록증'
                    className='w-5/6 h-auto rounded-lg shadow-md mx-auto'
                  />
                  <p className='text-sm text-gray-600 text-center mt-2'>
                    🔍 이미지를 클릭하면 확대할 수 있어요.
                  </p>
                </button>
                <div className='flex flex-col space-y-2 mt-2'>
                  <button
                    type='button'
                    onClick={handleChangeImage}
                    disabled={isValidBusinessNumber} // 사업자등록번호의 계속사업자 검증(두번째 approved) 완료 시, 이미지 변경 비활성화
                    className={`px-4 py-2 bg-blue-600 text-white rounded-md
                      transition-colors duration-200 flex items-center justify-center space-x-2
                      ${
                        isValidBusinessNumber
                          ? 'opacity-50 cursor-not-allowed'
                          : 'hover:bg-blue-700'
                      }`}
                  >
                    <svg
                      className='w-4 h-4'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
                      />
                    </svg>
                    <span>이미지 변경</span>
                  </button>

                  {isValidBusinessImage === 'pending' &&
                    !validationComplete && (
                      <button
                        type='button'
                        onClick={handleStartValidation}
                        disabled={isValidating}
                        className={`px-6 py-3 bg-green-500 text-white rounded-md 
                        transition-colors duration-200 w-full
                        ${
                          isValidating
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:bg-green-600'
                        }`}
                      >
                        {isValidating ? '검증 중...' : '검증 시작하기'}
                      </button>
                    )}

                  {error && (
                    <span className='text-red-500 font-semibold text-center text-sm mt-2 block'>
                      {error}
                    </span>
                  )}

                  {validationComplete && (
                    <>
                      <div className='text-green-600 font-semibold text-center py-2 bg-green-100 rounded-md'>
                        ✓ 사업자등록증 검증 완료
                      </div>
                      <div className='mt-4 p-3 bg-gray-100 rounded-lg border border-gray-200'>
                        <div className='flex flex-col justify-between items-start mb-4'>
                          <h3 className='text-base font-semibold mb-1'>
                            이미지에서 다음 정보들을 추출했어요. <br />
                            사업자번호, 이름 등의 정보가 정확한지 꼭
                            확인해주세요. <br />
                          </h3>
                          <span className='text-sm text-gray-500'>
                            <span className='text-red-500 mr-1'>!</span>
                            앞으로의 검증에 활용됩니다.
                          </span>
                        </div>

                        <div className='space-y-2 mb-3 text-sm'>
                          <div className='flex'>
                            <span className='text-gray-600 w-24 mr-2'>
                              사업자등록번호:
                            </span>
                            <span className='text-gray-800'>
                              {extractedData.registerNumber}
                            </span>
                          </div>
                          <div className='flex'>
                            <span className='text-gray-600 w-24 mr-2'>
                              대표자명:
                            </span>
                            <span className='text-gray-800'>
                              {extractedData.repName}
                            </span>
                          </div>
                          <div className='flex'>
                            <span className='text-gray-600 w-24 mr-2'>
                              개업일시:
                            </span>
                            <span className='text-gray-800'>
                              {extractedData.openDate}
                            </span>
                          </div>
                        </div>

                        <div className='text-sm text-gray-500 space-y-1 mt-4'>
                          <p className='flex items-center'>
                            <span className='text-red-500 mr-1'>*</span>
                            정보가 부정확할 경우, 첨부한 이미지를 변경하고 다시
                            검증할 수 있어요.
                          </p>
                          <p className='flex items-center'>
                            <span className='text-red-500 mr-1'>*</span>
                            첨부 및 검증 과정에서 문제가 있을 경우,
                            판매자지원센터로 문의해주세요.
                          </p>
                        </div>
                      </div>
                      <button
                        type='button'
                        onClick={onNextStep}
                        className='px-6 py-3 bg-blue-600 text-white rounded-md 
                          transition-colors duration-200 w-full hover:bg-blue-700
                          font-semibold text-lg shadow-md'
                      >
                        다음 단계로 →
                      </button>
                    </>
                  )}
                  <button
                    type='button'
                    onClick={onPreviousStep}
                    className='px-6 py-3 bg-gray-600 text-white rounded-md 
                          transition-colors duration-200 w-full hover:bg-gray-700
                          font-semibold text-lg shadow-md'
                  >
                    이전 단계로
                  </button>

                  {/* 이미지 클릭 시, 확대된 이미지 모달 */}
                  {isImageModalOpen && (
                    <div
                      role='button'
                      tabIndex='0'
                      className='fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4'
                      onClick={() => setIsImageModalOpen(false)}
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                          setIsImageModalOpen(false)
                        }
                      }}
                    >
                      <button
                        type='button'
                        className='bg-transparent border-0'
                        onClick={() => setIsImageModalOpen(false)}
                      >
                        <img
                          src={businessImage}
                          alt='사업자등록증 이미지 확대'
                          className='max-w-full max-h-[70vh] object-contain'
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BusinessImageSection

/* eslint-disable no-nested-ternary */
import SellerStore from '../stores/SellerStore'
import React, { useState, useEffect } from 'react'
import Button from '../components/atoms/button'
import backendApis from '../utils/backendApis'
import moment from 'moment'

const AgreeToPolicyScreen = () => {
  const [alreadyAgreed, setAlreadyAgreed] = useState(false)
  const [option, setOption] = useState(0)
  const [blocked, setBlocked] = useState(false)
  const [afterDue, setAfterDue] = useState(false)

  useEffect(() => {
    // 동의한 이력 있으면 setAlreadyAgreed(true), option을 기존에 설정했던 것으로 넣기
    if (SellerStore?.sellerInfo) {
      if (
        SellerStore.sellerInfo?.agreeToSpecficPolicy?.chuseokShippingImage_1 ||
        SellerStore.sellerInfo?.agreeToSpecficPolicy?.chuseokShippingImage_2
      ) {
        setAlreadyAgreed(true)
        if (
          SellerStore.sellerInfo?.agreeToSpecficPolicy?.chuseokShippingImage_1
        ) {
          setOption(1)
        } else setOption(2)
      } else if (afterDue) {
        // 기준 시간 지났는데도 선택한 것 없다면 옵션 1로 선택되게 하기
        setOption(1)
      }
    }
  }, [SellerStore?.sellerInfo, afterDue])

  useEffect(() => {
    const getTime = async () => {
      const result = await backendApis.getCurrentDate()
      if (moment(result?.data) >= moment('2022-09-02 21:00:00')) {
        setAfterDue(true)
      } else setAfterDue(false)
    }
    return getTime()
  }, [])

  const fetchData = async () => {
    setBlocked(true)
    if (!alreadyAgreed && !option) {
      setBlocked(false)
      return alert(`이미지를 선택해주세요.`)
    }
    if (!alreadyAgreed && option) {
      const { status } = await backendApis.uploadAgreeToSpecificPolicy(
        `chuseokShippingImage_${option}`,
      )
      if (status === 2000) {
        setAlreadyAgreed(true)
        alert('신청이 완료되었습니다')
      } else {
        alert('서버 오류가 발생했습니다')
      }
    }
    setBlocked(false)
  }

  const Option = ({ imageSource, text, optionNumber }) => {
    return (
      <div className='p-4 bg-stone-100 mr-10 rounded-xl'>
        <text className='font-bold text-2xl'>
          {optionNumber}번 이미지 선택{' '}
          {optionNumber === 1 ? '(미선택 시 기본 옵션)' : ''}
        </text>
        <div className='flex flex-row items-center my-4'>
          <input
            type='checkbox'
            disabled={alreadyAgreed || afterDue}
            onChange={() => {
              setOption(optionNumber)
            }}
            checked={option === optionNumber}
            className={
              alreadyAgreed || afterDue
                ? 'mr-1 p-4 mr-4 bg-zinc-200'
                : 'mr-1 p-4 mr-4'
            }
          />
          <div>
            <text className='text-lg font-medium'>{text}</text>
            <text className='text-lg font-medium'>
              <br />
              이후 주문 건은 9월 13일부터 순차적 발송처리
            </text>
          </div>
        </div>
        <img src={imageSource} alt={imageSource} />
      </div>
    )
  }

  return (
    <div className='page'>
      <div className='pt-4'>
        {(alreadyAgreed || afterDue) && (
          <>
            <div className='text-3xl font-bold bg-amber-100 py-2'>
              {afterDue
                ? '선택 기한이 종료되었습니다.'
                : '이미 선택 완료 하셨습니다.'}
            </div>
            <div className='flex flex-row pt-6'>
              <Option
                imageSource='https://d22yqrpf3lokox.cloudfront.net/957b30a3-4c28-4c5a-b9b0-f21ef4bc2173.png'
                text='9월 5일 월요일 오후 1시 이전 주문까지만 출고'
                optionNumber={1}
              />
              <Option
                imageSource='https://d22yqrpf3lokox.cloudfront.net/c8f9b241-e46f-4435-970c-4dcbb06cdd3c.png'
                text='9월 6일 화요일 오후 1시 이전 주문까지만 출고'
                optionNumber={2}
              />
            </div>
          </>
        )}
        {!alreadyAgreed && !afterDue && (
          <>
            <div className='text-3xl font-bold bg-amber-100 py-2'>
              추석 연휴 배송 기한 안내 이미지를 선정해주세요.
              <br />
              (~9/2 금요일 오후 9시까지로 연장되었습니다.)
            </div>
            <div className='text-lg px-4 py-4'>
              안녕하세요 판매자 여러분.
              <br />
              올웨이즈가 추석 연휴 배송 처리 기간 사전 안내를 위한 이미지를 일괄
              설정해 드립니다. <br />
              아래 두 가지 옵션 중 택 1 하시면, 모든 상품의 상세 이미지 최상단에
              선택하신 이미지가 노출됩니다.
              <br />
              기간 내 선택을 완료하지 못하실 경우 일괄 1번 이미지로 노출되며, 그
              외의 옵션(다른 이미지, 이미지 미기재 등)은 제공하지 않습니다.
              <br /> 문의사항이 있을 경우 판매자 지원센터를 통해 연락바랍니다.
            </div>
            <div className='flex flex-row pt-6'>
              <Option
                imageSource='https://d22yqrpf3lokox.cloudfront.net/957b30a3-4c28-4c5a-b9b0-f21ef4bc2173.png'
                text='9월 5일 월요일 오후 1시 이전 주문까지만 출고'
                optionNumber={1}
              />
              <Option
                imageSource='https://d22yqrpf3lokox.cloudfront.net/5adf15bb-8590-425e-b772-41bddb9ba12a.png                '
                text='9월 6일 화요일 오후 1시 이전 주문까지만 출고'
                optionNumber={2}
              />
            </div>
          </>
        )}
      </div>
      <div className='flex items-center justify-center mt-12'>
        <Button
          size='xxl'
          onClick={fetchData}
          disabled={blocked || alreadyAgreed || afterDue}
          appearance={alreadyAgreed || afterDue ? 'gray' : 'red'}
        >
          {afterDue
            ? '신청 기한이 완료되었습니다.'
            : alreadyAgreed
            ? '이미 신청하셨습니다.'
            : '신청하기'}
        </Button>
      </div>
    </div>
  )
}

export default AgreeToPolicyScreen

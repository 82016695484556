import ButtonCard from '../atoms/buttonCard'

const CouponCardList = ({ tab, setTab }) => {
  return (
    <>
      <ButtonCard
        // href='/coupon-marketing'
        image='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/a02b335a-4f2f-45fe-ac41-00f44384c920.png'
        text='베이직 쿠폰 신청'
        subtext1='기본 상품 쿠폰을 제공해서'
        subtext2='상품 전환율을 높여요!'
        width={60}
        height={70}
        idx={1}
        tab={tab}
        setTab={setTab}
      />
      <ButtonCard
        // href='/multipurchase-coupon'
        image='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/f17ee5f2-a765-465d-92c9-e25239130846.png'
        text='다다익선 쿠폰 신청'
        subtext1='2개 이상 구매를 유도해서'
        subtext2='배송비를 줄여요!'
        width={60}
        height={70}
        idx={2}
        tab={tab}
        setTab={setTab}
      />
      <ButtonCard
        // href='/follow-coupon'
        image='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/a6361d4f-aacd-49bc-97a0-7f6a2ac9cb5c.png'
        text='팔로우 쿠폰 신청'
        subtext1='팔로우 쿠폰을 통해,'
        subtext2='재구매율을 높여요!'
        width={60}
        height={70}
        idx={3}
        tab={tab}
        setTab={setTab}
      />
      {/* <ButtonCard
        // href='/follow-coupon'
        image='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/a02b335a-4f2f-45fe-ac41-00f44384c920.png'
        text='사진후기 쿠폰 신청'
        subtext1='사진후기 쿠폰을 통해,'
        subtext2='전환율을 높여요!'
        width={60}
        height={70}
        idx={4}
        tab={tab}
        setTab={setTab}
      /> */}
      {/* <ButtonCard
        // href='/follow-coupon'
        image='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/a02b335a-4f2f-45fe-ac41-00f44384c920.png'
        text='판매자 쿠폰 신청'
        subtext1='판매자 쿠폰을 통해,'
        subtext2='재구매율을 높여요!'
        width={60}
        height={70}
        idx={5}
        tab={tab}
        setTab={setTab}
      /> */}
    </>
  )
}
export default CouponCardList

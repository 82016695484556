import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import Page from '../components/atoms/page'
import { FcBarChart as TitleIcon, FcLock as LockIcon } from 'react-icons/fc'
import backendApis from '../utils/backendApis'
import ItemSearchSection from '../components/molecules/itemSearchSection'

const ItemsScreen = observer(() => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [salesStatusTab, setSalesStatusTap] = useState('onSale')
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const checkBoxValueResetter = () => {
    setIsAllCheckBoxChecked(false)
    setCheckedItemIds([])
  }

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (isLoading) {
      return
    }
    checkBoxValueResetter()
    setIsLoading(true)
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    if (salesStatusTab === 'onSale') {
      itemCondition.soldOut = false
      itemCondition.$or = [
        {
          outOfStock: false,
        },
        {
          outOfStock: null,
        },
      ]
    } else if (salesStatusTab === 'soldOut') {
      itemCondition.$or = [
        {
          soldOut: true,
        },
        {
          outOfStock: true,
        },
      ]
    }
    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        SellerStore.setIsLoading(false)
        return
      }
    }
    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        // console.log(`${JSON.stringify(retryResult)}`)
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    setIsLoading(false)
    SellerStore.setIsLoading(false)
  }
  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [limit, salesStatusTab])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  return (
    <div className='max-w-7xl'>
      <Page title='상품 조회/수정' Icon={TitleIcon}>
        <ItemSearchSection />
      </Page>
    </div>
  )
})

export default ItemsScreen

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import SellerStore from '../stores/SellerStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'
import socket from '../utils/socket'

const CancelOrderModal = observer(
  // order 단위 옆에서 바로 문의할 것 같으면(주문문의) itemInfo - 이름, 옵션, 수량, orderid, userInfo - 고객명 등의 내용들을 보내야 함
  () => {
    const [text, setText] = useState('')
    const [cancelReason, setCancelReason] = useState('')
    const [returnFee, setReturnFee] = useState(
      OrderStore?.cancelOrderInfo?.shippingFee +
        OrderStore?.cancelOrderInfo?.itemInfo?.shippingInfo?.returnFee,
    )

    useEffect(() => {
      if (
        ![
          '구매의사취소',
          '색상 및 사이즈 변경',
          '다른 상품 잘못 주문',
        ].includes(cancelReason)
      ) {
        setReturnFee(0)
      }
    }, [])

    return (
      <div
        style={{
          height: 100,
          marginTop: 20,
          marginLeft: 40,
          marginRight: 40,
          flexDirection: 'column',
        }}
      >
        <div style={{ width: '100%' }}>
          <h2>주문취소 처리</h2>
        </div>
        <hr />
        <div
          style={{
            fontSize: 16,
            height: 140,
            paddingTop: 0,
            color: 'black',
          }}
        >
          - 주문취소는 상품 품절 및 구매자의 요청 등에 의해 주문 건의 판매를
          취소하는 기능입니다.
          <br />- 상품 품절 사유로 판매 취소 시 판매관리 페널티가 부과되며, 해당
          주문 상품도 함께 품절 처리됩니다. (옵션이 있는 상품은 해당 주문 옵션만
          품절 차리)
          <br />- 주문취소 사유를 실제 사유와 무관하게 임의 설정할 경우 고의적
          부당행위로 간주, 판매관리 프로그램에 의해 조치될 수 있으니 정확한
          사유를 선택해 주세요.
          <br />- 입력하신 판매불가 사유는 구매자에게 안내됩니다.
        </div>
        <div
          style={{
            // width: '100%',
            height: 100,
            // backgroundColor: 'red',
            // justifyContent: 'center',
            // alignItems: 'center',
          }}
        >
          <div
            style={{
              // width: '100%',
              // height: 80,
              // backgroundColor: 'red',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <table
              style={{
                height: 100,
                borderWidth: 1.5,
                borderColor: '#edf0f5',
                // borderCollapse: 'collapse',
              }}
            >
              <tr>
                <th
                  style={{
                    backgroundColor: '#f0f0f0',
                    borderWidth: 1.5,
                    borderColor: '#edf0f5',
                  }}
                >
                  주문취소 사유
                </th>
                <td>
                  {/* 사유 넣을 곳 */}
                  <select
                    style={{ width: 120 }}
                    onChange={(e) => {
                      setCancelReason(e.target.value)
                      if (
                        ![
                          '구매의사취소',
                          '색상 및 사이즈 변경',
                          '다른 상품 잘못 주문',
                        ].includes(e.target.value)
                      ) {
                        setReturnFee(0)
                      } else {
                        setReturnFee(
                          OrderStore?.cancelOrderInfo?.itemInfo?.shippingInfo
                            ?.returnFee,
                        )
                      }
                      // setMessage(textarea?.value)
                    }}
                    value={cancelReason || '선택'}
                  >
                    <option value=''>===</option>
                    {[
                      '상품품절',
                      '배송지연',
                      '서비스 불만족',
                      '상품정보 상이',
                      '구매의사취소',
                      '색상 및 사이즈 변경',
                      '다른 상품 잘못 주문',
                    ].map((reason) => (
                      <option key={reason} value={reason}>
                        {reason}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  구매자에게 전하실 말씀
                </th>
                <td>
                  <textarea
                    style={{ width: '80%', height: 40 }}
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value)
                    }}
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          style={{
            fontSize: 16,
            height: 60,
            paddingTop: 0,
            color: 'black',
          }}
        >
          <br />※ 입력하신 내용은 처리 후 수정이 불가합니다. 취소사유를 정확하게
          선택해 주세요.
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              marginRight: 16,
            }}
          >
            <button
              type='button'
              style={{
                height: 40,
                width: 160,
              }}
              // 주문 취소 로직에 대한 설명
              // - 원래는 상품준비중 취소 요청을 하면서, backend에서 canceled-pre-shipping으로 넘기고 환불처리가 진행되는게 동시에 이뤄져야 함
              // - 하지만 기존 로직이 주문의 상태를 canceling으로 변경하며 셀러화면의 '출고중지요청'으로 넘어가고, 환불처리 및 취소 완료를 위해 '출고중지 요청'에서 '카드사 최종 승인'을 눌러줘야하는 순서를 겪도록 되어있음
              // - 가장 이상적인 리팩토링은 두 로직을 합쳐서 하나의 코드로 짜는 것이나, 결제를 건드는 로직이다보니 조심스러워 두 과정을 한 번에 실시하는 로직으로 대체하고자 함. 아래 주석에 주문상태를 '취소중'으로 바꾸는 로직을 타는 코드를 1), '취소중'인 주문을 환불프로세스를 밟고 '취소완료'로 주문상태를 변경하는 로직을 2) 로 표기하겠음 + 3) 고객에게 푸시알림 나가는 로직 추가
              onClick={async () => {
                if (cancelReason?.length < 1) {
                  alert('취소사유를 선택해 주세요.')
                  return
                }
                if (text?.length < 1) {
                  alert('구매자에게 전하실 말씀을 입력해 주세요. ')
                  return
                }
                if (SellerStore.isLoading) {
                  return
                }
                SellerStore.setIsLoading(true)
                let status = OrderStore?.cancelOrderInfo?.status
                if (
                  OrderStore?.cancelOrderInfo?.status === 'pre-shipping' &&
                  !OrderStore?.cancelOrderInfo?.excelDownloadedAt
                ) {
                  status = 'pre-shipping-pre-excel'
                } else if (
                  OrderStore?.cancelOrderInfo?.status === 'pre-shipping' &&
                  OrderStore?.cancelOrderInfo?.excelDownloadedAt
                ) {
                  status = 'pre-shipping-post-excel'
                } else if (
                  OrderStore?.cancelOrderInfo?.status === 'shipping' &&
                  !OrderStore?.cancelOrderInfo?.shippingInfo?.trackingDetail
                ) {
                  status = 'sending'
                }
                // 1) 시작
                const result = await OrderStore.manuallyCancelOrder(
                  OrderStore?.cancelOrderInfo,
                  cancelReason,
                  text,
                )

                if (result?.status === 2000) {
                  // 2) 시작
                  const result2 = await backendApis.approveOrderCancel(
                    OrderStore?.cancelOrderInfo?._id,
                    OrderStore?.cancelOrderInfo?.totalPrice,
                    OrderStore?.cancelOrderInfo?.shippingFee,
                    OrderStore?.cancelOrderInfo?.itemInfo?.shippingInfo
                      ?.returnFee,
                    parseInt(returnFee, 10),
                  )

                  SellerStore.setIsLoading(false)
                  if (result2?.status === 200) {
                    await backendApis.notifyUserCancelEvent(
                      OrderStore?.cancelOrderInfo?._id,
                      cancelReason,
                    )
                    if (status.startsWith('pre-shipping')) {
                      OrderStore.setDisplayedOrders(
                        OrderStore.displayedOrders.map((orderTemp) => {
                          if (
                            orderTemp._id === OrderStore?.cancelOrderInfo?._id
                          ) {
                            return {
                              ...orderTemp,
                              status: 'canceled-pre-shipping',
                              cancelInfo: {
                                cancelReason,
                              },
                            }
                          }
                          return orderTemp
                        }),
                      )
                      if (OrderStore.isUserOrder) {
                        OrderStore.setUserOrders(
                          OrderStore.userOrders.map((orderTemp) => {
                            if (
                              orderTemp._id === OrderStore?.cancelOrderInfo?._id
                            ) {
                              return {
                                ...orderTemp,
                                status: 'canceled-pre-shipping',
                                cancelInfo: {
                                  cancelReason,
                                },
                              }
                            }
                            return orderTemp
                          }),
                        )
                      }
                      // OrderStore.changeOrdersNumPerStatus('pre-shipping', -1)
                      // Order에 ExcelDownloadedAt에 따라 changeOrdersNumPerStatus를 분기쳐주는 것
                      if (OrderStore?.cancelOrderInfo?.excelDownloadedAt) {
                        OrderStore.changeOrdersNumPerStatus(
                          'pre-shipping-post-excel',
                          -1,
                        )
                      } else {
                        OrderStore.changeOrdersNumPerStatus(
                          'pre-shipping-pre-excel',
                          -1,
                        )
                        // delayed 에 대한 처리 추후 추가 필요
                      }
                      OrderStore.changeOrdersNumPerStatus(
                        'canceled-pre-shipping',
                        1,
                      )
                    } else if (
                      status.startsWith('shipping') ||
                      status?.startsWith('sending')
                    ) {
                      OrderStore.setDisplayedOrders(
                        OrderStore.displayedOrders.map((orderTemp) => {
                          if (
                            orderTemp._id === OrderStore?.cancelOrderInfo?._id
                          ) {
                            return {
                              ...orderTemp,
                              status: 'canceled-shipping',
                              cancelInfo: {
                                cancelReason,
                              },
                            }
                          }
                          return orderTemp
                        }),
                      )
                      if (OrderStore.isUserOrder) {
                        OrderStore.setUserOrders(
                          OrderStore.userOrders.map((orderTemp) => {
                            if (
                              orderTemp._id === OrderStore?.cancelOrderInfo?._id
                            ) {
                              return {
                                ...orderTemp,
                                status: 'canceled-shipping',
                                cancelInfo: {
                                  cancelReason,
                                },
                              }
                            }
                            return orderTemp
                          }),
                        )
                      }
                      if (status?.includes('sending')) {
                        OrderStore.changeOrdersNumPerStatus(
                          'shipping-sending',
                          -1,
                        )
                      } else {
                        OrderStore.changeOrdersNumPerStatus('shipping', -1)
                      }

                      OrderStore.changeOrdersNumPerStatus(
                        'canceled-shipping',
                        1,
                      )
                    } else if (status === 'arrived' || status === 'reviewed') {
                      OrderStore.setDisplayedOrders(
                        OrderStore.displayedOrders.map((orderTemp) => {
                          if (
                            orderTemp._id === OrderStore?.cancelOrderInfo?._id
                          ) {
                            return {
                              ...orderTemp,
                              status: 'refunded',
                              refundInfo: {
                                refundReason: cancelReason,
                                refundDetailedReason: text,
                              },
                            }
                          }
                          return orderTemp
                        }),
                      )
                      if (OrderStore.isUserOrder) {
                        OrderStore.setUserOrders(
                          OrderStore.userOrders.map((orderTemp) => {
                            if (
                              orderTemp._id === OrderStore?.cancelOrderInfo?._id
                            ) {
                              return {
                                ...orderTemp,
                                status: 'refunded',
                              }
                            }
                            return orderTemp
                          }),
                        )
                      }

                      OrderStore.changeOrdersNumPerStatus('arrived', -1)

                      OrderStore.changeOrdersNumPerStatus('refunded', 1)
                    }

                    alert(
                      '취소 접수가 완료되었습니다. 접수 안내가 고객에게 자동으로 전송됩니다.',
                    )
                    setText('')
                    OrderStore?.setCancelOrderInfo([])
                    OrderStore?.setOpenCancelOrderModal(false)
                    setCancelReason('')
                  } else {
                    SellerStore.setIsLoading(false)
                    alert(
                      '취소에 실패하였습니다. 자세한 사항은 판매자지원센터로 문의 부탁드리겠습니다.',
                    )
                  }
                } else {
                  SellerStore.setIsLoading(false)
                  alert(
                    '취소에 실패하였습니다. 자세한 사항은 판매자지원센터로 문의 부탁드리겠습니다.',
                  )
                }
              }}
            >
              주문취소 처리
            </button>
          </div>
          <div
            style={{
              marginleft: 10,
              paddingleft: 10,
            }}
          >
            <button
              type='button'
              style={{
                height: 40,
                width: 60,
              }}
              onClick={async () => {
                OrderStore?.setOpenCancelOrderModal(false)
              }}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    )
  },
)

export default CancelOrderModal

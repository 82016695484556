import React from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import moment from 'moment-timezone'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import Log from '../utils/log'
import Title from '../components/ADS/atoms/title'
import Button from '../components/atoms/button'
import { SiGooglesheets } from 'react-icons/si'
import Format from '../utils/format'
import shortUUID from 'short-uuid'

const headers = Format.SettlementExcelHeaders

const SalesScreen = observer(() => {
  return (
    <>
      <Title text='매출현황(정산 예정)' />
      <Button
        className='flex items-center px-2 py-3 my-2 w-60'
        appearance='gray'
        type='button'
        size='lg'
        onClick={async () => {
          if (SellerStore.isLoading) {
            return
          }

          const confirmResult = window.confirm(
            '정말 매출현황을 다운로드 하시겠습니까? 오랜 시간이 걸릴 수 있습니다.',
          )

          if (confirmResult === true) {
            SellerStore.setIsLoading(true)
            const loadedOrders = []
            let loadOrders = true
            let index = 0
            while (loadOrders && index < 2000) {
              index += 1
              const loadResult =
                await backendApis.loadSegmentedRevenuesBeforeSettlements(
                  loadedOrders.slice(-1)?.length > 0 &&
                    loadedOrders.slice(-1)[0]?.payedAt,
                )

              if (loadResult?.status === 200 && loadResult.data?.length > 0) {
                loadedOrders.push(...loadResult.data)
              } else {
                loadOrders = false
              }
            }
            // const revenues = await backendApis.loadRevenuesBeforeSettlemnts()
            // if (revenues?.status === 200) {
            //   console.log(JSON.stringify(revenues.data))
            // }
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('매출 내역')
            const worksheetData = loadedOrders.map((order) => {
              return Format.formatOrderForSettlementExcel(order)
            })

            // if (
            //   // isGettingPlatformSupportPrice &&
            //   !headers.find((header) => header.key === 'platformSupportPrice')
            // ) {
            //   headers.splice(6, 0, {
            //     header: '추가 지원금액',
            //     key: 'platformSupportPrice',
            //     width: 15,
            //   })
            // }
            worksheet.columns = headers
            worksheet.addRows(worksheetData)
            const headerRow = worksheet.getRow(1)
            headers.forEach((header, index) => {
              headerRow.getCell(index + 1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFEA3F49' },
              }
            })
            worksheet.getRow(1).font = {
              color: { argb: 'FFFFFFFF' },
              bold: true,
            }
            const mimeType = {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], mimeType)
            const saveDate = new Date()
            const year = saveDate.getFullYear()
            const month = saveDate.getMonth() + 1
            const date = saveDate.getDate()
            saveAs(
              blob,
              `${
                SellerStore.sellerInfo.sellerDisplayName
              }_정산 예정 매출 내역_${moment().format('YYYY-MM-DD')}.xlsx`,
            )
            SellerStore.setIsLoading(false)
          }
          if (SellerStore.loadSellerInfo())
            Log.event('SalesDownloadButton', 'SalesDownloadButton', {
              click: 'SalesDownloadButton',
            })
        }}
      >
        <SiGooglesheets className='mr-2' />
        엑셀 다운로드
      </Button>
    </>
  )
})

export default SalesScreen

import { observer } from 'mobx-react-lite'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import ItemStore from '../stores/ItemStore'
import moment from 'moment'
import Button from './atoms/button'
import Title from './ADS/atoms/title'
import Table from './ADS/molecules/table'
// import Pagination from './ADS/atoms/pagination'
import ModalCS from './atoms/modalCS'
import ItemQuestionSearchSection from './ItemQuestionSearchSection'
import Pagination from './molecules/pagination'
import {
  QuestionContentType,
  QuestionStatus,
  QuestionType,
} from '../data/question'
import { DateTimeSortingType, DateTimeSortingComparator } from '../data/sorting'
import Divider from './atoms/divider'
import backendApis from '../utils/backendApis'

const typeFilter = {
  [QuestionContentType.ALL]: () => true,
  [QuestionContentType.ORDER]: (question) =>
    question?.type === QuestionContentType.ORDER.description.toLowerCase(),
  [QuestionContentType.ITEM]: (question) =>
    question?.type === QuestionContentType.ITEM.description.toLowerCase(),
}

const ItemQuestions = observer(() => {
  const [showModal, setShowModal] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [userName, setUserName] = useState('')
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [sortingOrder, setSortingOrder] = useState(
    DateTimeSortingType.NEWEST_CREATED_AT,
  )
  const [contentType, setContentType] = useState(QuestionContentType.ALL)
  const [contentTypeOfSelectedQuestion, setContentTypeOfSelectedQuestion] =
    useState(null)
  const [selectedQuestionInfo, setSelectedQuestionInfo] = useState(null)
  const [type, setType] = useState(QuestionType.ITEM_QUESTION)
  const [delayedItemQuestionsCount, setDelayedItemQuestionsCount] = useState(0)
  const [delayedQuestionsFromAdminCount, setDelayedQuestionsFromAdminCount] =
    useState(0)
  const handleResetAfterSearch = useCallback(() => {
    setLimit(20)
    setPage(1)
    setSortingOrder(DateTimeSortingType.NEWEST_CREATED_AT)
    setContentType(QuestionContentType.ALL)
    const searchedTableEl = document.getElementById(
      'cs-searched-question-dashboard',
    )
    if (searchedTableEl) {
      searchedTableEl.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [])

  const columns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '등록일시', accessor: 'createdAt' },
      { Header: '고객명', accessor: 'userName' },
      {
        Header: '문의 상세',
        accessor: 'questionDetail',
        Cell: ({ row }) => {
          const contentTypeOfSelectedQuestion =
            row?.values?.questionContentType === '주문 문의'
              ? QuestionContentType.ORDER
              : QuestionContentType.ITEM
          const orderId = row?.values?.orderId || {}
          const userName = row?.values?.userName || ''
          return (
            <div>
              <Button
                type='button'
                onClick={() => {
                  setSelectedQuestionInfo(
                    row?.values?.answerButton?.questionInfo,
                  )
                  setContentTypeOfSelectedQuestion(
                    contentTypeOfSelectedQuestion,
                  )
                  setOrderId(orderId)
                  setUserName(userName)
                  setShowModal(true)
                }}
              >
                문의 상세
              </Button>
            </div>
          )
        },
      },
      { Header: '주문아이디', accessor: 'orderId' },
      { Header: '문의 주체', accessor: 'questionType' },
      { Header: '문의 유형', accessor: 'questionContentType' },
      {
        Header: '답변여부',
        accessor: 'answerButton',
        Cell: ({ value, row, column, cell }) => {
          if (cell?.value?.answerInfo) {
            return '답변 완료'
          }
          return <span className='font-bold text-red-500'>미답변</span>
        },
      },
    ],
    [type, contentType],
  )

  const questionData = useMemo(() => {
    if (type === QuestionType.ITEM_QUESTION) {
      return ItemStore.itemQuestions
        .filter(typeFilter[contentType])
        .sort((a, b) => {
          return DateTimeSortingComparator[sortingOrder](
            a.questionInfo?.createdAt,
            b.questionInfo?.createdAt,
          )
        })
        .slice((page - 1) * limit, page * limit)
        ?.map((question, index) => {
          return {
            index: index + 1 + (page - 1) * limit,
            createdAt: (
              <>
                {moment(question.questionInfo?.createdAt).format('YYYY.MM.DD')}
                <br />
                {moment(question.questionInfo?.createdAt).format('HH:mm')}
              </>
            ),
            userName: question?.questionInfo?.userName || '',
            itemTitle: question?.itemInfo,
            orderId: question?.orderId,
            questionContentType:
              question?.type ===
              QuestionContentType.ITEM.description.toLowerCase()
                ? '상품 문의'
                : '주문 문의',
            questionType: '고객 문의',
            answerButton: {
              _id: question?._id,
              answerInfo: question?.answerInfo,
              questionInfo: question?.questionInfo,
            },
          }
        })
    }
    return ItemStore.questionsFromAdmin
      .filter(typeFilter[contentType])
      .sort((a, b) => {
        return DateTimeSortingComparator[sortingOrder](
          a.questionInfo?.createdAt,
          b.questionInfo?.createdAt,
        )
      })
      .slice((page - 1) * limit, page * limit)
      ?.map((question, index) => {
        return {
          index: index + 1 + (page - 1) * limit,
          createdAt: (
            <>
              <div>
                {moment(question.questionInfo?.createdAt).format('YYYY.MM.DD')}
              </div>
              <div>
                {moment(question.questionInfo?.createdAt).format('HH:mm')}
              </div>
            </>
          ),
          userName: question?.userInfo?.userName || '',
          itemTitle: question?.itemInfo?.itemTitle,
          orderId: question?.orderId,
          questionContentType:
            question?.type ===
            QuestionContentType.ITEM.description.toLowerCase()
              ? '상품 문의'
              : '주문 문의',
          questionType: '고객센터 문의',
          answerButton: {
            _id: question?._id,
            answerInfo: question?.answerInfo,
            questionInfo: question?.questionInfo,
          },
        }
      })
  }, [
    type,
    contentType,
    ItemStore.itemQuestions,
    ItemStore.questionsFromAdmin,
    limit,
    page,
    sortingOrder,
  ])
  const loadDelayedItemQuestions = useCallback(async () => {
    const condition = {
      type: QuestionType.ITEM_QUESTION.description,
      status: QuestionStatus.NOT_ANSWERED.description,
    }
    const result = await backendApis.loadQuestionsByCondition(condition, -1, 0)
    if (result?.status === 200) {
      return result?.data.filter((question) => {
        return (
          moment().diff(moment(question?.questionInfo?.createdAt), 'days') > 1
        )
      }).length
    }
    return 0
  }, [type, contentType, ItemStore.itemQuestions, ItemStore.questionsFromAdmin])

  const loadDelayedQuestionsFromAdmin = useCallback(async () => {
    const condition = {
      type: QuestionType.QUESTION_FROM_ADMIN.description,
      status: QuestionStatus.NOT_ANSWERED.description,
    }
    const result = await backendApis.loadQuestionsByCondition(condition, -1, 0)
    if (result?.status === 200) {
      return result?.data.filter((question) => {
        return (
          moment().diff(moment(question?.questionInfo?.createdAt), 'days') > 1
        )
      }).length
    }
    return 0
  }, [type, contentType, ItemStore.itemQuestions, ItemStore.questionsFromAdmin])

  useEffect(async () => {
    await ItemStore.loadUnansweredQuestions()
  }, [])

  useEffect(async () => {
    const count = await loadDelayedItemQuestions()
    const count2 = await loadDelayedQuestionsFromAdmin()
    setDelayedItemQuestionsCount(count)
    setDelayedQuestionsFromAdminCount(count2)
  }, [type, contentType, ItemStore.itemQuestions, ItemStore.questionsFromAdmin])

  return (
    <div>
      <Title text='문의 관리' />
      <div className='item-question-section-wrapper'>
        <ItemQuestionSearchSection
          setType={setType}
          searchCallbackFunc={handleResetAfterSearch}
        />
        <div
          id='cs-searched-question-dashboard'
          className='flex flex-col bg-slate-50 rounded-xl p-8 shadow-xl space-y-2'
        >
          {delayedItemQuestionsCount > 0 && (
            <Button
              size='lg'
              appearance='outline'
              textColor='black'
              onClick={async () => {
                await ItemStore.loadUnansweredQuestions()
                setType(QuestionType.ITEM_QUESTION)
                ItemStore.setQuestionSearchCondition({
                  type: QuestionType.ITEM_QUESTION.description,
                  status: QuestionStatus.NOT_ANSWERED.description,
                })
              }}
              className='w-full'
            >
              <div className='flex items-baseline p-2 space-x-1'>
                <div>⚠ 주의: 현재 1일 이상 지연된 </div>
                <div className='text-red-500'>
                  {delayedItemQuestionsCount}건의 고객 문의
                </div>
                <div>
                  {' '}
                  가 존재합니다. (클릭하면 지연건이 나옵니다. 우선적으로
                  처리해주세요.)
                </div>
              </div>
            </Button>
          )}
          {delayedQuestionsFromAdminCount > 0 && (
            <Button
              size='lg'
              appearance='outline'
              textColor='black'
              onClick={async () => {
                await ItemStore.loadUnansweredQuestions()
                setType(QuestionType.QUESTION_FROM_ADMIN)
                ItemStore.setQuestionSearchCondition({
                  type: QuestionType.QUESTION_FROM_ADMIN.description,
                  status: QuestionStatus.NOT_ANSWERED.description,
                })
              }}
              className='w-full'
            >
              <div className='flex items-baseline p-2 space-x-1'>
                <div>⚠ 주의: 현재 1일 이상 지연된 </div>
                <div className='text-red-500'>
                  {delayedQuestionsFromAdminCount}건의 고객센터 문의
                </div>
                <div>
                  가 존재합니다. (클릭하면 지연건이 나옵니다. 우선적으로
                  처리해주세요.)
                </div>
              </div>
            </Button>
          )}
          {(delayedItemQuestionsCount > 0 ||
            delayedQuestionsFromAdminCount > 0) && <Divider />}
          <div className='flex font-bold text-lg mt-6 mb-4 p-2 space-x-1'>
            <div>
              {`검색 결과 총 ${
                type === QuestionType.ITEM_QUESTION
                  ? ItemStore.itemQuestions?.length
                  : ItemStore.questionsFromAdmin?.length
              }개`}
            </div>
            {type === QuestionType.ITEM_QUESTION &&
              contentType !== QuestionContentType.ALL && (
                <div>
                  {contentType === QuestionContentType.ORDER
                    ? `중 ${
                        ItemStore.itemQuestions?.filter(typeFilter[contentType])
                          .length
                      }개`
                    : `중 ${
                        ItemStore.itemQuestions?.filter(typeFilter[contentType])
                          .length
                      }개`}
                </div>
              )}
            {type === QuestionType.QUESTION_FROM_ADMIN &&
              contentType !== QuestionContentType.ALL && (
                <div>
                  {contentType === QuestionContentType.ORDER
                    ? `중 ${
                        ItemStore.questionsFromAdmin?.filter(
                          typeFilter[contentType],
                        ).length
                      }개`
                    : `중 ${
                        ItemStore.questionsFromAdmin?.filter(
                          typeFilter[contentType],
                        ).length
                      }개`}
                </div>
              )}
          </div>
          <div>
            • 미답변 문의건은{' '}
            <span className='text-red-500'>최대 1 영업일 </span>
            이내에 모두 답변 부탁 드립니다.
          </div>
          <div>
            • <span className='text-red-500'>[상품문의]</span>는 해당 상품을
            조회하는 <span className='text-red-500'>모든 고객에게 </span>
            공개됩니다. 가급적 빠른 시일 내 신중하게 답변 부탁 드립니다.
          </div>
          <div>
            • <span className='text-red-500'>[주문문의]</span>는 상품을 주문한
            고객의 1:1 문의로,{' '}
            <span className='text-red-500'>질문자에게만 공개 </span>
            됩니다.
          </div>
          <div className='ml-3'>
            주문 정보 조회를 위해서는 [주문/배송 관리] {`->`} [고객 주문 관리]
            에서 주문 아이디를 기입해 주세요.
          </div>
          <div className='mb-40' />
          <div className='flex flex-row items-center justify-between'>
            <div className='space-x-4'>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value)
                  setPage(1)
                }}
              >
                <option value={10}>10개씩</option>
                <option value={20}>20개씩</option>
                <option value={50}>50개씩</option>
                <option value={100}>100개씩</option>
              </select>
              <select
                value={sortingOrder}
                onChange={(e) => {
                  setSortingOrder(e.target.value)
                  setPage(1)
                }}
              >
                <option value={DateTimeSortingType.NEWEST_CREATED_AT}>
                  최신 순
                </option>
                <option value={DateTimeSortingType.OLDEST_CREATED_AT}>
                  오래된 순
                </option>
              </select>
              <select
                value={contentType.description}
                onChange={(e) => {
                  setContentType(QuestionContentType[e.target.value])
                  setPage(1)
                }}
              >
                <option value={QuestionContentType.ALL.description}>
                  전체 문의
                </option>
                <option value={QuestionContentType.ORDER.description}>
                  주문 문의
                </option>
                <option value={QuestionContentType.ITEM.description}>
                  상품 문의
                </option>
              </select>
            </div>
            <div className='flex flex-row space-x-2 items-center'>
              <input
                type='text'
                className='w-16 h-10'
                onChange={(e) => {
                  if (e.target.value === '') return
                  const value = parseInt(e.target.value, 10)
                  if (Number.isNaN(value)) {
                    alert('숫자만 입력 가능합니다.')
                    e.target.value = ''
                  }
                  if (
                    value > Math.ceil(ItemStore.itemQuestions?.length / limit)
                  ) {
                    alert(
                      `최대 ${
                        ItemStore.itemQuestions.length === 0
                          ? 1
                          : Math.ceil(ItemStore.itemQuestions?.length / limit)
                      }페이지까지 가능합니다.`,
                    )
                    e.target.value = ''
                  }
                }}
                onKeyDown={(e) => {
                  if (e.target.value === '') return
                  const value = parseInt(e.target.value, 10)
                  if (Number.isNaN(value)) return
                  if (value <= 0) {
                    setPage(1)
                    return
                  }
                  if (
                    value > Math.ceil(ItemStore.itemQuestions?.length / limit)
                  )
                    return
                  if (e.code === 'Enter') {
                    setPage(value)
                  }
                }}
              />
              <div>페이지로</div>
            </div>
          </div>
          <Table data={questionData} columns={columns} />
          {type === QuestionType.ITEM_QUESTION ? (
            <Pagination
              total={
                contentType === QuestionContentType.ALL
                  ? ItemStore.itemQuestions?.length
                  : ItemStore.itemQuestions?.filter(typeFilter[contentType])
                      .length
              }
              limit={limit}
              page={page}
              setPage={setPage}
            />
          ) : (
            <Pagination
              total={
                contentType === QuestionContentType.ALL
                  ? ItemStore.questionsFromAdmin?.length
                  : ItemStore.questionsFromAdmin?.filter(
                      typeFilter[contentType],
                    ).length
              }
              limit={limit}
              page={page}
              setPage={setPage}
            />
          )}
        </div>
        <ModalCS
          questionType={type}
          questionContentType={contentTypeOfSelectedQuestion}
          questionId={selectedQuestionInfo?.questionId}
          questionInfo={selectedQuestionInfo}
          orderId={orderId}
          showModal={showModal}
          setShowModal={setShowModal}
          userName={userName}
          cancelCallbackFunction={() => {
            ItemStore.setQuestionReloadToggle(!ItemStore.questionReloadToggle)
          }}
        />
      </div>
    </div>
  )
})

export default ItemQuestions

import SellerStore from '../../stores/SellerStore'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import BarChart from './barChart'
import Button from '../atoms/button'
import AdBatchApplyModal from '../../screens/adBatchApplyModal'
import Ping from '../atoms/ping'
import TimeDealBulkApplyModal from '../../screens/TimeDealBulkApplyModal'
import HolidayDealBulkApplyModal from '../../screens/HolidayDealBulkApplyModal'
import ExternalUrlBatchUpdateModal from '../atoms/externalUrlBatchUpdateModal'

const RevenueInfo = observer(() => {
  const [showModal, setShowModal] = useState(false)
  const [timeDealShowModal, setTimeDealShowModal] = useState(false)
  const [holidayDealShowModal, setHolidayDealShowModal] = useState(false)
  const [externalUrlUpdateModal, setExternalUrlUpdateModal] = useState(false)

  const [ping, setPing] = useState(true)
  const graphOptions = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    cubicInterpolationMode: 'monotone',
  }
  const [revenueData, setRevenueData] = useState({
    labels: [],
    datasets: [
      {
        label: '일별 매출',
        data: [],
        backgroundColor: ['black'],
        borderColor: 'black',
        borderWidth: 2,
      },
    ],
  })

  useEffect(() => {
    const dateRange = []
    const revenueTemp = []
    const adRevenueTemp = []
    const timeDealRevenueTemp = []
    for (let i = 13; i >= 1; i -= 1) {
      dateRange.push(moment().subtract(i, 'day').format('YYYY-MM-DD'))
    }
    dateRange.forEach((date) => {
      revenueTemp.push(
        (SellerStore.dashboardInfo?.twoWeeksRevenue?.find(
          (revenueElement) => revenueElement?._id === date,
        )?.totalAmount || 0) -
          (SellerStore.dashboardInfo?.twoWeeksRevenue?.find(
            (revenueElement) => revenueElement?._id === date,
          )?.totalAdRevenue || 0) -
          (SellerStore.dashboardInfo?.twoWeeksRevenue?.find(
            (revenueElement) => revenueElement?._id === date,
          )?.totalTimeDealRevenue || 0),
      )
      adRevenueTemp.push(
        SellerStore.dashboardInfo?.twoWeeksRevenue?.find(
          (revenueElement) => revenueElement?._id === date,
        )?.totalAdRevenue || 0,
      )
      timeDealRevenueTemp.push(
        SellerStore.dashboardInfo?.twoWeeksRevenue?.find(
          (revenueElement) => revenueElement?._id === date,
        )?.totalTimeDealRevenue || 0,
      )
    })
    setRevenueData({
      labels: dateRange,
      datasets: [
        {
          label: '광고 매출',
          data: adRevenueTemp,
          borderColor: 'transparent',
          backgroundColor: '#34495e',
          borderWidth: 2,
        },
        {
          label: '타임특가 매출',
          data: timeDealRevenueTemp,
          borderColor: 'transparent',
          backgroundColor: '#ea580c',
          borderWidth: 2,
        },
        {
          label: '일반 매출',
          data: revenueTemp,
          borderColor: 'transparent',
          backgroundColor: '#27ae60',
          borderWidth: 2,
        },
      ],
    })
  }, [SellerStore.dashboardInfo])

  return SellerStore.loadingDashboardInfo ? (
    <>
      <div className='flex flex-1 px-10 py-10 text-lg font-bold bg-white shadow-md select-none rounded-xl'>
        매출 정보를 불러오고 있습니다.
      </div>
    </>
  ) : (
    <>
      <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md select-none rounded-xl'>
        <div className='flex flex-row items-start justify-between flex-1'>
          <div className='flex flex-col items-start text-lg font-bold'>
            <div className='flex flex-row'>
              <div className='flex items-center mr-2'>매출 현황</div>
            </div>
            <div className='text-sm font-normal text-sub-500'>
              매일 새벽 5시~7시 업데이트
            </div>
          </div>
          <div className='flex flex-row items-center justify-end flex-1'>
            <span className='mr-1'>{ping && <Ping />}</span>
            <Button
              appearance='positiveSub'
              onClick={() => {
                setPing(false)
                setTimeDealShowModal(true)
              }}
              className='mr-2'
            >
              타임특가 대량등록
            </Button>
            {/* <Button
              appearance='positiveSub'
              onClick={() => setExternalUrlUpdateModal(true)}
            >
              리뷰 대량 연동
            </Button> */}
          </div>
        </div>
        <div className='flex items-end flex-1'>
          <BarChart chartData={revenueData} options={graphOptions} />
        </div>
      </div>
      <AdBatchApplyModal showModal={showModal} setShowModal={setShowModal} />
      <TimeDealBulkApplyModal
        showModal={timeDealShowModal}
        setShowModal={setTimeDealShowModal}
      />
      <HolidayDealBulkApplyModal
        showModal={holidayDealShowModal}
        setShowModal={setHolidayDealShowModal}
      />
      {/* {externalUrlUpdateModal && (
        <ExternalUrlBatchUpdateModal
          onClose={() => {
            setExternalUrlUpdateModal(false)
          }}
        />
      )} */}
    </>
  )
})
export default RevenueInfo

import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import SubTitle from '../components/atoms/subTitle'

const MobileSearchPasswordScreen = () => {
  const history = useHistory()
  const [sellerName, setSellerName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [sentVerificationNumber, setSentVerificationNumber] = useState(false)
  const [verificationNumber, setVerificationNumber] = useState('')
  const [actualVerificationNumber, setActualVerificationNumber] = useState('')
  const handleSubmit = async (e) => {
    e.preventDefault()
    await backendApis.reportPasswordMissing(sellerName, phoneNumber)
    history.push('/login')
  }
  return (
    <div className='select-none w-screen h-screen absolute inset-0 flex flex-col justify-center items-center bg-white'>
      <div className='flex flex-col justify-center items-center'>
        <SubTitle>비밀번호 찾기</SubTitle>
        {!sentVerificationNumber && (
          <div className='w-screen flex flex-col justify-center items-center'>
            <input
              className='w-2/3 h-1/10 my-2 rounded-md bg-red-100'
              type='text'
              name='name'
              placeholder='  아이디'
              value={sellerName}
              onChange={(e) => {
                setSellerName(e.target.value)
              }}
            />
            <input
              className='w-2/3 h-1/10 my-2 rounded-md bg-red-100'
              type='number'
              name='phoneNumber'
              placeholder='  전화번호(- 없이 입력해주세요)'
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
              }}
            />
          </div>
        )}
        {sentVerificationNumber && (
          <input
            className='w-2/3 h-1/10 my-2 rounded-md bg-red-100'
            type='number'
            name='verificationNumber'
            placeholder='  문자로 전송된 인증번호를 입력해주세요.'
            value={verificationNumber}
            onChange={(e) => {
              setVerificationNumber(e.target.value)
            }}
          />
        )}
      </div>
      {!sentVerificationNumber && (
        <button
          className='w-2/3 my-2 py-4 mb-10 rounded-md bg-theme-500 text-lg text-white font-black'
          type='button'
          onClick={async (e) => {
            e.preventDefault()
            if (!sellerName) {
              alert('올웨이즈 셀러 아이디를 입력해주세요.')
              return
            }
            if (!phoneNumber) {
              alert('가입 시 등록하신 휴대폰 번호를 입력해주세요.')
              return
            }
            const result = await backendApis.requestVerificationNumber(
              phoneNumber,
              sellerName,
            )
            if (result.status === 200) {
              setActualVerificationNumber(result.data)
              setSentVerificationNumber(true)
            } else {
              alert(
                '올웨이즈 셀러 아이디가 정확하지 않거나, 해당 계정으로 등록된 연락처 번호가 아닙니다. 자세한 문의는 판매자 지원센터로 연락 부탁드립니다.',
              )
            }
          }}
        >
          <span>인증번호 받기</span>
        </button>
      )}
      {sentVerificationNumber && (
        <button
          className='w-2/3 py-4 mb-10 rounded-md bg-theme-500 text-lg text-white font-black'
          type='button'
          onClick={async (e) => {
            e.preventDefault()
            if (
              verificationNumber === actualVerificationNumber &&
              actualVerificationNumber
            ) {
              await backendApis.reportPasswordMissing(sellerName, phoneNumber)
            }
            history.push('/login', { sellerName })
          }}
        >
          <span>문자로 비밀번호 발급받기</span>
        </button>
      )}
    </div>
  )
}

export default MobileSearchPasswordScreen

import React, { useEffect, useState } from 'react'
import Button from './button'
import useKeyEscClose from '../../hooks/useKeyEscClose'

const AlertModal = ({
  isTriggered = false,
  handleTrigger = () => {},
  title,
  children,
  onConfirm = () => {},
  onCancel = () => {},
  hasOnlyConfirm = false,
  hideDurationInDays = 0,
}) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (isTriggered) {
      setShowModal(isTriggered)
      handleTrigger(false)
    }

    const hideModalUntil =
      parseInt(localStorage.getItem(`hideModalUntil_${title}`), 10) || 0
    if (hideModalUntil > Date.now()) {
      setShowModal(false)
    }
  }, [isTriggered, title])

  const handleConfirm = () => {
    onConfirm()
    setShowModal(false)
  }

  const handleCancel = () => {
    onCancel()
    setShowModal(false)
  }

  const handleHideNDays = () => {
    const DAY = 24 * 60 * 60 * 1000
    const hideModalUntil = Date.now() + DAY * hideDurationInDays
    localStorage.setItem(`hideModalUntil_${title}`, hideModalUntil.toString())
    onCancel()
    setShowModal(false)
  }

  useKeyEscClose(() => {
    setShowModal(false)
  })

  return (
    <>
      {showModal && (
        <div
          className='relative z-50'
          aria-labelledby='alert-modal-title'
          role='dialog'
          aria-modal='true'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                      <svg
                        className='h-6 w-6 text-red-600'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        aria-hidden='true'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
                        />
                      </svg>
                    </div>
                    {/* Alert Title */}
                    <div className='mt-3 sm:ml-4 sm:mt-0 sm:text-left'>
                      <div className='flex flex-col justify-center h-10 '>
                        <div
                          className='text-xl font-semibold leading-6
                          text-gray-900'
                          id='alert-modal-title'
                        >
                          {title}
                        </div>
                      </div>
                      {/* Alert Body(Children) */}
                      <div className='mt-2 pr-3 text-md text-gray-500'>
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-gray-50 px-4 py-3 sm:flex sm:px-6 justify-between'>
                  {hideDurationInDays > 0 ? (
                    <button type='button' onClick={handleHideNDays}>
                      {hideDurationInDays === 1
                        ? '오늘 하루'
                        : `${hideDurationInDays}일`}
                      동안 보지 않기
                    </button>
                  ) : (
                    <div />
                  )}

                  <div className='flex'>
                    {!hasOnlyConfirm && (
                      <div>
                        <Button
                          size='md'
                          appearance='outline'
                          onClick={handleCancel}
                        >
                          <div className='text-slate-500 px-1 py-1'>취소</div>
                        </Button>
                      </div>
                    )}
                    <div className='sm:ml-4'>
                      <Button
                        size={hasOnlyConfirm ? 'flexlg' : 'md'}
                        onClick={handleConfirm}
                      >
                        <div className={!hasOnlyConfirm && 'px-2 py-1'}>
                          확인
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AlertModal

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import Button from '../components/atoms/button'
import CongratIcon from '../images/congrat.png'

const FirstItemRegisterNudgeScreen = observer(() => {
  const history = useHistory()

  return (
    <div className='flex flex-col items-center justify-center w-full p-20 mt-8 bg-white rounded-lg'>
      <div className='flex flex-row items-center justify-center flex-1 mb-20 select-none'>
        <img alt='congraturations' src={CongratIcon} className='w-40 h-40' />
      </div>
      <div className='text-2xl font-bold'>상품을 등록해 주세요.</div>
      <br />
      <div className='text-lg'>
        <div className='flex flex-col items-center justify-center flex-1'>
          <p>
            등록된 상품이 {SellerStore.sellerInfo?.itemId?.length || 0}건
            있습니다.
          </p>
          <p>지금 바로, 판매 상품을 등록해 주세요.</p>
          <Button
            className='px-12 py-2 mt-10'
            type='button'
            appearance='positive'
            size='lg'
            onClick={() => {
              history.push('/items-type/registerations')
            }}
          >
            상품등록 하기
          </Button>
        </div>
      </div>
    </div>
  )
})

export default FirstItemRegisterNudgeScreen

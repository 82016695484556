import { makeObservable, observable, action, runInAction } from 'mobx'
import backendApis from '../utils/backendApis'
import socket from '../utils/socket'
import SellerStore from './SellerStore'
import dayjs from 'dayjs'

class OrderStore {
  // 현식
  searchedRecipients = []
  selectedSearchedRecipients = {}
  displayedOrders = []
  userOrders = []
  preShippingItemInfo = []
  openWithdrawCancelingPreShippingOrderModal = false
  openCancelPreShippingOrderModal = false
  openCancelOrderModal = false
  openRevokeRefundModal = false
  isUserOrder = false
  // errorOrders = []
  // teamGatheringOrders = []
  // canceledTeamGatheringOrders = []
  // preShippingOrders = []
  // cancelingPreShippingOrders = []
  // canceledPreShippingOrders = []
  // shippingOrders = []
  // cancelingShippingOrders = []
  // canceledShippingOrders = []
  // arrivedOrders = []
  // reviewedOrders = []
  // refundingOrders = []
  // refundedOrders = []
  shippingCompaniesInfo = []
  withdrawingOrderInfo = []
  cancelOrderInfo = []
  ordersNumPerStatus = {
    'pre-shipping': 0,
  }

  constructor() {
    makeObservable(this, {
      // errorOrders: observable,
      // teamGatheringOrders: observable,
      // canceledTeamGatheringOrders: observable,
      // preShippingOrders: observable,
      // cancelingPreShippingOrders: observable,
      // canceledPreShippingOrders: observable,
      // shippingOrders: observable,
      // cancelingShippingOrders: observable,
      // canceledShippingOrders: observable,
      // arrivedOrders: observable,
      // reviewedOrders: observable,
      // refundingOrders: observable,
      // refundedOrders: observable,
      displayedOrders: observable,
      shippingCompaniesInfo: observable,
      ordersNumPerStatus: observable,
      userOrders: observable,
      preShippingItemInfo: observable,
      withdrawingOrderInfo: observable,
      cancelOrderInfo: observable,
      openWithdrawCancelingPreShippingOrderModal: observable,
      openCancelPreShippingOrderModal: observable,
      openCancelOrderModal: observable,
      openRevokeRefundModal: observable,
      // 현식
      searchedRecipients: observable,

      // setErrorOrders: action,
      // setTeamGatheringOrders: action,
      // setCanceledTeamGatheringOrders: action,
      // setPreShippingOrders: action,
      // setCancelingPreShippingOrders: action,
      // setCanceledPreShippingOrders: action,
      // setShippingOrders: action,
      // setCancelingShippingOrders: action,
      // setCanceledShippingOrders: action,
      // setArrivedOrders: action,
      // setReviewedOrders: action,
      // setRefundingOrders: action,
      // setRefundedOrders: action,
      setDisplayedOrders: action,
      setShippingCompaniesInfo: action,
      setOrdersNumPerStatus: action,
      changeOrdersNumPerStatus: action,
      setUserOrders: action,
      setPreShippingItemInfo: action,
      setWithdrawingOrderInfo: action,
      setCancelOrderInfo: action,
      setOpenWithdrawCancelingPreShippingOrderModal: action,
      setOpenCancelPreShippingOrderModal: action,
      setOpenCancelOrderModal: action,
      setOpenRevokeRefundModal: action,
      setIsUserOrder: action,
      // 현식
      setSearchedRecipients: action,
    })
  }

  resetOrders() {
    runInAction(() => {
      // this.errorOrders = []
      // this.teamGatheringOrders = []
      // this.canceledTeamGatheringOrders = []
      // this.preShippingOrders = []
      // this.cancelingPreShippingOrders = []
      // this.canceledPreShippingOrders = []
      // this.shippingOrders = []
      // this.cancelingShippingOrders = []
      // this.canceledShippingOrders = []
      // this.arrivedOrders = []
      // this.reviewedOrders = []
      // this.refundingOrders = []
      // this.refundedOrders = []
      this.displayedOrders = []
      this.ordersNumPerStatus = {}
      this.userOrders = []

      this.shippingCompaniesInfo = []
      this.preShippingItemInfo = []
    })
  }

  setIsUserOrder(isUserOrder) {
    this.isUserOrder = isUserOrder
  }

  setOpenCancelOrderModal(openCancelOrderModal) {
    this.openCancelOrderModal = openCancelOrderModal
  }

  setOpenRevokeRefundModal(openRevokeRefundModal) {
    this.openRevokeRefundModal = openRevokeRefundModal
  }

  setPreShippingItemInfo(preShippingItemInfo) {
    this.preShippingItemInfo = preShippingItemInfo
  }

  setShippingCompaniesInfo(shippingCompaniesInfo) {
    this.shippingCompaniesInfo = shippingCompaniesInfo
  }

  setDisplayedOrders(displayedOrders) {
    this.displayedOrders = displayedOrders
  }

  setOrdersNumPerStatus(ordersNumPerStatus) {
    this.ordersNumPerStatus = ordersNumPerStatus
  }

  setUserOrders(userOrders) {
    this.userOrders = userOrders
  }

  setWithdrawingOrderInfo(withdrawingOrderInfo) {
    this.withdrawingOrderInfo = withdrawingOrderInfo
  }

  setCancelOrderInfo(cancelOrderInfo) {
    this.cancelOrderInfo = cancelOrderInfo
  }

  setOpenWithdrawCancelingPreShippingOrderModal(
    openWithdrawCancelingPreShippingOrderModal,
  ) {
    this.openWithdrawCancelingPreShippingOrderModal =
      openWithdrawCancelingPreShippingOrderModal
  }

  setOpenCancelPreShippingOrderModal(openCancelPreShippingOrderModal) {
    this.openCancelPreShippingOrderModal = openCancelPreShippingOrderModal
  }

  // 현식 수령인
  setSearchedRecipients(searchedRecipients) {
    this.searchedRecipients = searchedRecipients
  }

  resetRecipient() {
    this.setSearchedRecipients([])
    this.setSelectedRecipientsInfo({})
  }

  // 현식 수령인 끝

  // setErrorOrders(errorOrders) {
  //   this.errorOrders = errorOrders
  // }

  // setTeamGatheringOrders(teamGatheringOrders) {
  //   this.teamGatheringOrders = teamGatheringOrders
  // }

  // setCanceledTeamGatheringOrders(canceledTeamGatheringOrders) {
  //   this.canceledTeamGatheringOrders = canceledTeamGatheringOrders
  // }

  // setPreShippingOrders(preShippingOrders) {
  //   this.preShippingOrders = preShippingOrders
  // }

  // setCancelingPreShippingOrders(cancelingPreShippingOrders) {
  //   this.cancelingPreShippingOrders = cancelingPreShippingOrders
  // }

  // setCanceledPreShippingOrders(canceledPreShippingOrders) {
  //   this.canceledPreShippingOrders = canceledPreShippingOrders
  // }

  // setShippingOrders(shippingOrders) {
  //   this.shippingOrders = shippingOrders
  // }

  // setCancelingShippingOrders(cancelingShippingOrders) {
  //   this.cancelingShippingOrders = cancelingShippingOrders
  // }

  // setCanceledShippingOrders(canceledShippingOrders) {
  //   this.canceledShippingOrders = canceledShippingOrders
  // }

  // setArrivedOrders(arrivedOrders) {
  //   this.arrivedOrders = arrivedOrders
  // }

  // setReviewedOrders(reviewedOrders) {
  //   this.reviewedOrders = reviewedOrders
  // }

  // setRefundingOrders(refundingOrders) {
  //   this.refundingOrders = refundingOrders
  // }

  // setRefundedOrders(refundedOrders) {
  //   this.refundedOrders = refundedOrders
  // }

  async loadExtraPreExcelOrders(status, payedAt, itemIds, isNewLoading) {
    const result =
      await backendApis.getPreExcelOrdersInStatusBeforeCertainTimeStamp(
        status,
        payedAt,
        itemIds,
      )
    if (result?.status === 200 && result.data?.length > 0) {
      if (isNewLoading) {
        this.setDisplayedOrders([
          ...this.displayedOrders?.filter(
            (order) =>
              order.status !== 'pre-shipping' || order.excelDownloadedAt,
          ),
        ])
      }
      this.setDisplayedOrders([...this.displayedOrders, ...result.data])

      // runInAction(() => {
      //   console.log(
      //     `load extra orders pre shipping orders num: ${
      //       result.data.filter((order) => order.addressInfo).length
      //     }`,
      //   )
      //   this.ordersNumPerStatus['pre-shipping'] += result.data.filter(
      //     (order) => order.addressInfo,
      //   ).length
      //   this.ordersNumPerStatus['pre-shipping-pre-excel'] += result.data.filter(
      //     (order) => order.addressInfo && !order.excelDownloadedAt,
      //   ).length

      //   this.ordersNumPerStatus['pre-shipping-post-excel'] +=
      //     result.data.filter(
      //       (order) => order.addressInfo && order.excelDownloadedAt,
      //     ).length
      // })
      return true
    }
  }

  async loadExtraPostExcelOrders(status, payedAt) {
    const result =
      await backendApis.getPostExcelOrdersInStatusBeforeCertainTimeStamp(
        status,
        payedAt,
      )
    if (result?.status === 200 && result.data?.length > 0) {
      this.setDisplayedOrders([...this.displayedOrders, ...result.data])
      // runInAction(() => {
      //   console.log(
      //     `load extra orders pre shipping orders num: ${
      //       result.data.filter((order) => order.addressInfo).length
      //     }`,
      //   )
      //   this.ordersNumPerStatus['pre-shipping'] += result.data.filter(
      //     (order) => order.addressInfo,
      //   ).length
      //   this.ordersNumPerStatus['pre-shipping-pre-excel'] += result.data.filter(
      //     (order) => order.addressInfo && !order.excelDownloadedAt,
      //   ).length

      //   this.ordersNumPerStatus['pre-shipping-post-excel'] +=
      //     result.data.filter(
      //       (order) => order.addressInfo && order.excelDownloadedAt,
      //     ).length
      // })
      return true
    }
  }

  // 전체 order들을 displayedOrders에 넣어주는 바로 그 구간
  async loadOrdersPerStatus() {
    // console.log(`loading orders per status`)
    const result = await backendApis.getRecentOrdersPerStatus()
    if (result?.status === 200) {
      result.data.forEach((ordersByStatus) => {
        runInAction(() => {
          if (ordersByStatus.status === 'team-gathering') {
            this.ordersNumPerStatus[ordersByStatus.status] =
              ordersByStatus.topKOrders?.filter((order) =>
                dayjs(order.payedAt).isAfter(dayjs().add(-365, 'day')),
              )?.length || 0
          } else {
            this.ordersNumPerStatus[ordersByStatus.status] =
              ordersByStatus.totalOrderNum
          }

          // if (ordersByStatus.status.startsWith('pre-shipping')) {
          //   this.ordersNumPerStatus['pre-shipping'] +=
          //     ordersByStatus.totalOrderNum
          // }
        })
        // console.log(
        //   `orders num per status: ${JSON.stringify(this.ordersNumPerStatus)}`,
        // )
        // console.log(
        //   `orders by status: ${JSON.stringify(ordersByStatus.status)}, ${
        //     ordersByStatus.topKOrders.length
        //   }`,
        // )
        if (ordersByStatus)
          this.setDisplayedOrders([
            ...this.displayedOrders.filter((order) => {
              if (
                ordersByStatus.status === 'canceling-pre-shipping-pre-excel'
              ) {
                return (
                  order.status !== 'canceling-pre-shipping' ||
                  order.excelDownloadedAt
                )
              }
              if (
                ordersByStatus.status === 'canceling-pre-shipping-post-excel'
              ) {
                return (
                  order.status !== 'canceling-pre-shipping' ||
                  !order.excelDownloadedAt
                )
              }
              if (ordersByStatus.status === 'pre-shipping-pre-excel') {
                return (
                  order.status !== 'pre-shipping' || order.excelDownloadedAt
                )
              }
              if (ordersByStatus.status === 'pre-shipping-post-excel') {
                return (
                  order.status !== 'pre-shipping' || !order.excelDownloadedAt
                )
              }
              if (ordersByStatus.status === 'shipping-sending') {
                return (
                  order.status !== 'shipping' ||
                  order?.shippingInfo?.trackingDetail
                )
              }
              if (ordersByStatus.status === 'shipping') {
                return (
                  order.status !== 'shipping' ||
                  !order?.shippingInfo?.trackingDetail
                )
              }
              if (ordersByStatus?.status === 'arrived') {
                return order.status !== 'arrived' || order.status !== 'reviewed'
              }
              if (ordersByStatus.status === 'pre-shipping-fast-arrival') {
                return order.status !== 'pre-shipping-fast-arrival'
              }
              if (ordersByStatus.status === 'shipping-fast-arrival') {
                return order.status !== 'shipping-fast-arrival'
              }
              if (ordersByStatus.status === 'arrived-fast-arrival') {
                return order.status !== 'arrived-fast-arrival'
              }
              if (ordersByStatus?.status === 'reviewed') {
                return true
              }
              return order.status !== ordersByStatus.status
            }),
            ...ordersByStatus.topKOrders,
          ])
        if (ordersByStatus?.status?.startsWith('pre-shipping')) {
          ordersByStatus.topKOrders.forEach((order) => {
            if (
              !this.preShippingItemInfo
                .map((item) => item._id)
                .includes(order.itemInfo.itemId)
            ) {
              this.setPreShippingItemInfo([
                ...this.preShippingItemInfo,
                {
                  _id: order.itemInfo.itemId,
                  itemTitle: order.itemInfo?.itemTitle,
                  // pickupHour: order.pickupHour,
                },
              ])
            }
          })
        }
      })
      // this.setTeamGatheringOrders()
    }
  }

  changeOrdersNumPerStatus(status, change) {
    this.ordersNumPerStatus[status] =
      (this.ordersNumPerStatus[status] || 0) + change
  }

  // 현식 시작

  async manuallyCancelOrder(
    order,
    cancelReason,
    cancelDetailedReason,
    refundSolution,
    refundMethod,
  ) {
    let cancelInfo = {}
    if (
      order.status === 'team-gathering' ||
      (order.status === 'pre-shipping' && !order.excelDownloadedAt)
    ) {
      cancelInfo = {
        cancelReason,
        cancelRequestedAt: new Date(),
      }
    } else if (order.status === 'pre-shipping' && order?.excelDownloadedAt) {
      cancelInfo = {
        cancelReason,
        cancelRequestedAt: new Date(),
        cancelDetailedReason,
      }
    } else if (order.status === 'shipping') {
      cancelInfo = {
        cancelReason,
        cancelRequestedAt: new Date(),
        cancelDetailedReason,
        refundSolution,
        refundMethod,
      }
    } else if (order.status === 'arrived' || order.status === 'reviewed') {
      cancelInfo = {
        refundReason: cancelReason,
        refundRequestedAt: new Date(),
        refundDetailedReason: cancelDetailedReason,
        refundSolution,
        refundMethod,
      }
    } else {
      return
    }
    const result = await backendApis.initiateCancelOrderBySeller(
      order.userId,
      order._id,
      cancelInfo,
    )
    // console.log(
    //   'cancelInfo?.cancelDetailedReason',
    //   cancelInfo?.cancelDetailedReason,
    // )
    if (result?.status === 2000) {
      this.setDisplayedOrders(
        this.displayedOrders.map((orderTemp) => {
          if (orderTemp._id === order._id) {
            return {
              ...orderTemp,
              status: result.data.changedStatus,
              cancelInfo,
            }
          }
          return orderTemp
        }),
      )
    }
    return { ...result, cancelInfo }
  }

  // 현식 끝

  async loadOrders() {
    const result = await this.loadOrdersPerStatus()
    return result
  }

  async loadShippingCompanies() {
    const result = await backendApis.loadShippingCompanies()
    if (result?.status === 200 && result?.data) {
      this.setShippingCompaniesInfo([
        ...result?.data?.slice(0, 5),
        ...result?.data?.slice(5)?.sort((a, b) => {
          if (a?.shippingCompanyName > b?.shippingCompanyName) {
            return 1
          }
          if (a?.shippingCompanyName < b?.shippingCompanyName) {
            return -1
          }
          return 0
        }),
      ])
    } else {
      SellerStore.setIsLoading(true)
    }
  }
}

const container = {}
container.instance = new OrderStore()

export default container.instance

socket?.on('uploadShippingInfo', (orderId, shippingInfo, excelDownloaded) => {
  container.instance.setDisplayedOrders(
    container.instance.displayedOrders.map((order) => {
      if (order._id === orderId && order.status === 'pre-shipping') {
        container.instance.changeOrdersNumPerStatus('pre-shipping', -1)
        if (excelDownloaded) {
          container.instance.changeOrdersNumPerStatus(
            'pre-shipping-post-excel',
            -1,
          )
        } else {
          container.instance.changeOrdersNumPerStatus(
            'pre-shipping-pre-excel',
            -1,
          )
        }
        container.instance.changeOrdersNumPerStatus('shipping', 1)
        return { ...order, status: 'shipping', shippingInfo }
      }
      return order
    }),
  )

  container.instance.setUserOrders(
    container.instance.userOrders.map((order) => {
      if (order._id === orderId) {
        return { ...order, status: 'shipping', shippingInfo }
      }
      return order
    }),
  )
})

import SellerStore from '../stores/SellerStore'
import backendApis from './backendApis'

const alfarmFreeAdSellerFilteringManager = () => {
  const SELLER_RANK = {
    ROOKIE: 'rookie',
    SILVER: 'silver',
    GOLD: 'gold',
    PLATINUM: 'platinum',
    DIAMOND: 'diamond',
  }

  const checkFreeAdAvailable = async () => {
    // 1번 - 셀러의 랭크로 1차 필터링
    const sellerRank = SellerStore?.sellerInfo?.sellerRank
    const FilteringWithSellerContactNumber =
      SellerStore?.sellerInfo?.contactNumber?.endsWith('4')
    if (
      sellerRank === SELLER_RANK.GOLD ||
      sellerRank === SELLER_RANK.PLATINUM ||
      sellerRank === SELLER_RANK.DIAMOND
    ) {
      // 2번 - 셀러의 전화번호 끝자리 4로 확인 (모수 10% 이하로 줄이기 위해)
      if (FilteringWithSellerContactNumber) {
        // 3번 - 셀러의 과거 무료 광고 신청 내역 확인
        const result = await backendApis.getFreeAdAvailableCheck()
        if (result?.status === 200) {
          return result?.data?.freeAdAvailable
        }
      }
    }
    return false
  }

  return {
    checkFreeAdAvailable,
  }
}

export default alfarmFreeAdSellerFilteringManager

import React, { useEffect, useState, useMemo } from 'react'

import { Tooltip as TooltipOrigin } from 'react-tippy'
import { observer } from 'mobx-react-lite'
// import ItemTable from '../../components/ADS/molecules/itemTable'
import Button from '../../components/atoms/button'
import backendApis from '../../utils/backendApis'
import tableUtils from '../../utils/tableUtils'
import ItemTable from '../../components/ADS/molecules/itemTable'
import { loadFastArrivalProgressingOrders } from '../../utils/fastArrivalUtils'
import { uuid } from 'short-uuid'
import { checkPreShipping } from '../../helper/order'

const Header = ({ modalTitle, onClose }) => {
  return (
    <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
      <h3 className='text-xl font-bold text-black'>{modalTitle}</h3>
      <div className='flex flex-row items-center justify-end flex-1'>
        <TooltipOrigin
          arrow='true'
          theme='dark'
          title='❎ 키보드 esc로도 종료하실 수 있어요.'
          animation='fade'
          position='top'
        >
          <button
            type='button'
            onClick={onClose}
            className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
          >
            esc
          </button>
        </TooltipOrigin>
      </div>
    </div>
  )
}

const Body = () => {
  return (
    <div className='flex flex-row items-center flex-1 p-5'>
      <ul className='list-disc ml-5'>
        <li className='mt-1'>
          합포장 출고 이후 개별 수정이 불가합니다.{' '}
          <span className='text-blue-500 font-bold underline underline-offset-4'>
            오발송 되지 않도록 주의해주세요.
          </span>
        </li>
        <li className='mt-1'>
          <span className='text-blue-500 font-bold underline underline-offset-4'>
            동일한 고객, 주소지의 상품만 합포장 출고 가능합니다.
          </span>{' '}
          여러 고객의 상품이 섞이지 않도록 주의해주세요.
        </li>
      </ul>
    </div>
  )
}

const CombinedShippingModal = observer(
  ({
    showModal,
    setShowModal,
    selectedOrderData,
    deliveryType,
    setLoadingProgress = () => {},
    setLoading = () => {},
    setPreShippingOrders = () => {},
    setPreShippingOrdersPostCode = () => {},
    setShippingOrders = () => {},
    setCurrentOrderStatus = () => {},
    onClose = () => {},
  }) => {
    const [selectedItems, setSelectedItems] = useState({})

    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
          setShowModal(false)
        }
      }
      document.addEventListener('keydown', handleKeyPress)
      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }, [onClose, setShowModal])

    useEffect(() => {
      setCurrentOrderStatus('combinedShipping')
    }, [])

    const handleClose = () => {
      setCurrentOrderStatus('pre-shipping')
      setShowModal(false)
      onClose()
    }

    const groupBy = (array, key) => {
      return array.reduce((result, currentValue) => {
        const {
          recipient,
          postcode,
          postcodeAddress,
          detailAddress,
          recipientPhoneNumber,
        } = currentValue.addressInfo
        const groupKey = `${recipient}-${postcode}-${postcodeAddress}-${detailAddress}-${recipientPhoneNumber}`

        if (!result[groupKey]) {
          result[groupKey] = []
        }

        result[groupKey].push(currentValue)

        return result
      }, {})
    }
    const groupedData = useMemo(() => {
      if (!selectedOrderData || selectedOrderData.length === 0) {
        return []
      }

      const grouped = groupBy(selectedOrderData, (item) => {
        const {
          recipient,
          postcode,
          postcodeAddress,
          detailAddress,
          recipientPhoneNumber,
        } = item.addressInfo
        return `${recipient}-${postcode}-${postcodeAddress}-${detailAddress}-${recipientPhoneNumber}`
      })

      // 필터링 추가: 그룹에 2개 이상의 아이템이 포함된 그룹만 반환
      return Object.entries(grouped)
        .filter(([groupKey, groupItems]) => groupItems.length >= 2)
        .map(([groupKey, groupItems], index) => ({
          groupKey,
          groupItems,
          index,
        }))
    }, [selectedOrderData])

    const handleItemSelection = (itemId) => {
      setSelectedItems((prevSelected) => ({
        ...prevSelected,
        [itemId]: !prevSelected[itemId],
      }))
    }

    const handleSelectAll = () => {
      const newSelectedItems = {}
      groupedData.forEach((group) => {
        group.groupItems.forEach((item) => {
          newSelectedItems[item._id] = true
        })
      })
      setSelectedItems(newSelectedItems)
    }

    const handleCancelAllSelect = () => {
      setSelectedItems({})
    }

    const createCombinedOrderDataforDelivus = async (groupedSelectedItems) => {
      setShowModal(false)
      setLoadingProgress('creating')
      setLoading(true)

      const orderDataWithCombinedOrderId = groupedSelectedItems.map((group) => {
        const combinedOrderId = uuid()
        return {
          ...group,
          items: group.items.map((item) => ({
            ...item,
            combinedOrderId,
          })),
        }
      })
      const allCombinedIds = orderDataWithCombinedOrderId.flatMap((group) =>
        group?.items.map((item) => item?.combinedOrderId),
      )
      const updateAlreadyProcessed =
        await backendApis.updateDeliveryStatusForDelivus(allCombinedIds)
      if (
        updateAlreadyProcessed?.status === 200 &&
        updateAlreadyProcessed?.data?.success
      ) {
        const createdOrders =
          await backendApis.createCombinedOrderDataForShipping(
            orderDataWithCombinedOrderId,
          )
        if (createdOrders?.status !== 200) {
          setCurrentOrderStatus('pre-shipping')
          setLoading(false)
          window.alert('주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.')
        }
        if (createdOrders?.status === 200) {
          setLoadingProgress('shipping')
          const requestCombinedShipping =
            await backendApis.requestCombinedShippingForDelivus(
              orderDataWithCombinedOrderId,
            )
          if (requestCombinedShipping?.status !== 200) {
            setCurrentOrderStatus('pre-shipping')
            setLoading(false)
            window.alert('택배사 출고요청에 실패했습니다. 다시 시도해 주세요.')
          }
          if (requestCombinedShipping?.status === 200) {
            setLoadingProgress('requesting')
            const preparedTrackingNumber = requestCombinedShipping?.data?.map(
              (e) => e?.tracking_number,
            )
            const preparedShipping =
              await backendApis.prepareShippingForDelivus(
                preparedTrackingNumber,
              )
            if (preparedShipping?.status !== 200) {
              setCurrentOrderStatus('pre-shipping')
              setLoading(false)
              window.alert(
                '데이터 업데이트에 실패했습니다. 다시 시도해 주세요.',
              )
            }
            if (preparedShipping?.status === 200) {
              const doneForShipping =
                await backendApis.updateCombinedDeliveryStatusForDelivus(
                  orderDataWithCombinedOrderId,
                )
              if (
                doneForShipping?.status === 200 &&
                doneForShipping?.data?.success
              ) {
                const [
                  preShippingOrders = null,
                  preShippingOrdersPostCode = null,
                  shippingOrders = null,
                ] = (await loadFastArrivalProgressingOrders()) || []
                setPreShippingOrders(preShippingOrders)
                setPreShippingOrdersPostCode(preShippingOrdersPostCode)
                setShippingOrders(shippingOrders)
                setCurrentOrderStatus('pre-shipping')
                setLoading(false)
                window.alert('출고지시가 완료되었습니다.')
              } else {
                setCurrentOrderStatus('pre-shipping')
                setLoading(false)
                window.alert(
                  '주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.',
                )
              }
            }
          }
        }
      } else {
        setCurrentOrderStatus('pre-shipping')
        setLoading(false)
        window.alert('주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.')
      }
    }

    const createCombinedOrderDataforCjLogistics = async (
      groupedSelectedItems,
    ) => {
      setShowModal(false)
      setLoadingProgress('creating')
      setLoading(true)
      const combinedShippingNum =
        await backendApis.createShippingNumberForCjLogistics(
          groupedSelectedItems,
        ) // 합포장 묶음 그룹 수 만큼 송장 채번

      if (combinedShippingNum?.length > 0) {
        const mappedData = groupedSelectedItems.map((group, index) => {
          const shippingNumber = combinedShippingNum[index]
          return group.items.map((item) => ({
            orderId: item._id,
            shippingNumber,
          }))
        })
        setLoadingProgress('requesting')
        const requestShippingData =
          await backendApis.requestCombinedShippingForCjLogistics(mappedData)
        if (
          requestShippingData?.success &&
          requestShippingData?.list?.length > 0
        ) {
          const [
            preShippingOrders = null,
            preShippingOrdersPostCode = null,
            shippingOrders = null,
          ] = (await loadFastArrivalProgressingOrders()) || []
          setPreShippingOrders(preShippingOrders)
          setPreShippingOrdersPostCode(preShippingOrdersPostCode)
          setShippingOrders(shippingOrders)
          setCurrentOrderStatus('pre-shipping')
          setLoading(false)
          window.alert('출고지시가 완료되었습니다.')
        } else {
          setCurrentOrderStatus('pre-shipping')
          setLoading(false)
          window.alert('주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.')
        }
      } else {
        setCurrentOrderStatus('pre-shipping')
        setLoading(false)
        window.alert('주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.')
      }
    }

    const handleCombinedShipping = async () => {
      // 그룹별로 선택된 주문을 추출
      const groupedSelectedItems = groupedData.reduce((result, group) => {
        const selectedInGroup = group.groupItems.filter(
          (item) => selectedItems[item._id],
        )
        if (selectedInGroup.length > 0) {
          result.push({ groupKey: group.groupKey, items: selectedInGroup })
        }
        return result
      }, [])
      const totalItems = groupedSelectedItems.reduce(
        (total, group) => total + group.items.length,
        0,
      )
      if (groupedSelectedItems.length === 0) {
        window.confirm(`선택된 상품이 없습니다.`)
      } else {
        const confirmation = window.confirm(
          `총 ${totalItems}개의 상품을 ${groupedSelectedItems.length}개의 합포장 주문으로 출고 처리를 진행합니다.`,
        )
        if (confirmation) {
          const selectedOrderIds = groupedSelectedItems.flatMap((group) =>
            group.items.map((item) => item._id),
          )
          const isAllPreShipping = await checkPreShipping(selectedOrderIds)
          if (!isAllPreShipping) {
            window.alert(
              '출고 지시가 불가능한 주문이 포함되어 있습니다. 새로고침 후 다시 시도해주세요.',
            )
            return
          }
          if (deliveryType === 'tomorrow') {
            await createCombinedOrderDataforCjLogistics(groupedSelectedItems)
          } else if (deliveryType === 'today') {
            await createCombinedOrderDataforDelivus(groupedSelectedItems)
          }
        }
      }
    }

    const columns = useMemo(
      () => [
        {
          Header: '선택',
          Cell: ({ row }) => {
            const item = row.original
            const itemSelected = selectedItems[item._id]
            return (
              <input
                type='checkbox'
                checked={itemSelected || false}
                onChange={() => handleItemSelection(item._id)}
              />
            )
          },
        },
        {
          Header: '배송형태',
          Cell: () => {
            if (deliveryType === 'today') {
              return '당일도착'
            }
            if (deliveryType === 'tomorrow') {
              return '익일도착'
            }
            return '배송정보 없음'
          },
        },
        {
          Header: '담당택배사',
          Cell: () => {
            if (deliveryType === 'today') {
              return '딜리래빗'
            }
            if (deliveryType === 'tomorrow') {
              return 'CJ대한통운'
            }
            return '배송정보 없음'
          },
        },
        {
          Header: '수령인',
          Cell: ({ row }) => {
            const item = row?.original
            return item?.addressInfo?.recipient
          },
        },
        {
          Header: '배송지',
          Cell: ({ row }) => {
            const item = row?.original
            return `${item?.addressInfo?.postcode} ${item?.addressInfo?.postcodeAddress}`
          },
        },
        {
          Header: '상세 주소',
          Cell: ({ row }) => {
            const item = row?.original
            return item?.addressInfo?.detailAddress
          },
        },
        {
          Header: '수령인 연락처',
          Cell: ({ row }) => {
            const item = row?.original
            return item?.addressInfo?.recipientPhoneNumber
          },
        },
        {
          Header: '주문 아이디',
          Cell: ({ row }) => {
            const item = row?.original
            return item?._id
          },
        },
        {
          Header: '상품명',
          Cell: ({ row }) => {
            const item = row?.original
            return item?.itemInfo?.itemTitle
          },
        },
        {
          Header: '옵션',
          Cell: ({ row }) => {
            const item = row?.original
            return tableUtils.parseOptionText(
              item?.itemInfo?.optionsInfo,
              item?.selectedOption,
            )
          },
        },
        {
          Header: '수량',
          Cell: ({ row }) => {
            const item = row?.original
            return item?.quantity
          },
        },
        {
          Header: '상품가격',
          Cell: ({ row }) => {
            const item = row?.original
            return item?.totalPrice
          },
        },
      ],
      [deliveryType, selectedItems],
    )
    const modalTitle = '바로도착 합포장 출고'

    return (
      <>
        {showModal && (
          <>
            <div className='fixed inset-0 z-50 flex items-center justify-center px-2 outline-none ml-[240px]'>
              <div className='relative w-full max-w-[150vh] mx-auto my-6 shadow-lg overflow-y-auto max-h-[80vh]'>
                <div className='relative flex flex-col w-full bg-white border-0 outline-none'>
                  <div className='sticky top-0 z-10 bg-white'>
                    <Header
                      modalTitle={modalTitle}
                      onClose={() => {
                        handleClose()
                      }}
                    />
                    <Body />
                    <div className='flex flex-row'>
                      <div className='ml-8 mt-4'>
                        <Button
                          appearance='positive'
                          size='md'
                          onClick={handleSelectAll}
                        >
                          전체상품 선택
                        </Button>
                      </div>
                      <div className='ml-2 mt-4'>
                        <Button
                          appearance='positive'
                          size='md'
                          onClick={handleCancelAllSelect}
                        >
                          전체상품 선택 해제
                        </Button>
                      </div>
                      <div className='ml-2 mt-4'>
                        <Button
                          appearance='positive'
                          size='md'
                          onClick={handleCombinedShipping}
                        >
                          선택상품 합포장 출고
                        </Button>
                      </div>
                    </div>
                    <div className='flex flex-col items-center flex-1 bg-white p-10'>
                      {groupedData.length > 0 ? (
                        groupedData.map((group) => (
                          <div key={group.groupKey} className='mb-12 w-full'>
                            {' '}
                            <ItemTable
                              columns={columns}
                              data={group.groupItems.map((item, index) => ({
                                ...item,
                                groupKey: group.groupKey,
                                index,
                              }))}
                              rowSelect={false}
                            />
                          </div>
                        ))
                      ) : (
                        <div>합포장 가능한 주문이 없습니다.</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => {
                      setCurrentOrderStatus('pre-shipping')
                      setShowModal(false)
                    }}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
          </>
        )}
      </>
    )
  },
)

export default CombinedShippingModal

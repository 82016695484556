import React, { useEffect, useState } from 'react'

// import IsCheapestButtonModal from '../Modal/IsCheapestButtonModal'
import ItemTagButton from './ItemTagButton'

const IsCheapestButton = ({ information = false, small = false }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <div style={{ flexDirection: 'row', alignItems: 'center' }}>
      {/* <IsCheapestButtonModal
        showModal={showModal}
        setShowModal={setShowModal}
      /> */}
      <ItemTagButton
        text='국내 최저가'
        backgroundColor='#3C3C43'
        small={small}
      />
      {/* {information && (
        <button
          style={{
            paddingHorizontal: 6,
            paddingVertical: 4,
          }}
          onClick={() => {
            setShowModal(true)
            UserStore.uploadUserUsage('CheapestModal')
          }}
        >
          <div
            style={{
              width: 14,
              height: 14,
              borderRadius: 10,
              borderColor: '#BDBDBD',
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ color: '#999999', fontSize: 10 }}>i</div>
          </div>
        </button>
      )} */}
    </div>
  )
}

export default IsCheapestButton

import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

const DensityPlot = ({
  dataArray = [],
  price = 8800,
  targetPrice = 8400,
  width = 500,
  height = 300,
}) => {
  const chartRef = useRef()

  useEffect(() => {
    const drawDensityPlot = (dataArray) => {
      const margin = { top: 10, right: 30, bottom: 30, left: 0 }
      const width =
        chartRef.current.width.baseVal.value - margin.left - margin.right
      const height =
        chartRef.current.height.baseVal.value - margin.top - margin.bottom

      const svg = d3
        .select(chartRef.current)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      const x = d3
        .scaleLinear()
        .domain([d3.min(dataArray), d3.max(dataArray)])
        .range([0, width])

      const histogram = d3
        .histogram()
        .domain(x.domain())
        .thresholds(x.ticks(40))
        .value((d) => d)

      const bins = histogram(dataArray)

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(bins, (d) => d.length)])
        .range([height, 0])

      const area = d3
        .area()
        .x((d) => x(d.x0))
        .y0(height)
        .y1((d) => y(d.length))
        .curve(d3.curveBasis)

      svg
        .append('path')
        .data([bins])
        .attr('d', area)
        .attr('fill', '#a5b4fc')
        .attr('stroke', 'black')

      // 특정 price의 위치를 표시
      if (price !== undefined) {
        const pricePosition = x(price)
        svg
          .append('line')
          .attr('x1', pricePosition)
          .attr('y1', 0)
          .attr('x2', pricePosition)
          .attr('y2', height)
          .attr('stroke', '#f43f5e')
          .attr('stroke-width', 2)
          .attr('stroke-dasharray', '5,5')
          .attr('legend')
      }

      if (targetPrice !== undefined) {
        const targetPricePosition = x(targetPrice)
        svg
          .append('line')
          .attr('x1', targetPricePosition)
          .attr('y1', 0)
          .attr('x2', targetPricePosition)
          .attr('y2', height)
          .attr('stroke', '#6366f1')
          .attr('stroke-width', 2)
          .attr('stroke-dasharray', '5,5')
      }

      svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x))

      svg.append('g').call(d3.axisLeft(y))
      svg
        .append('circle')
        .attr('cx', 290)
        .attr('cy', 30)
        .attr('r', 6)
        .style('fill', '#f43f5e')
      svg
        .append('circle')
        .attr('cx', 290)
        .attr('cy', 60)
        .attr('r', 6)
        .style('fill', '#6366f1')
      svg
        .append('text')
        .attr('x', 300)
        .attr('y', 30)
        .text('현재가')
        .style('font-size', '12px')
        .attr('alignment-baseline', 'middle')
      svg
        .append('text')
        .attr('x', 300)
        .attr('y', 60)
        .text('초저가')
        .style('font-size', '12px')
        .attr('alignment-baseline', 'middle')
    }

    // 기존에 그려진 내용을 지우고 다시 그림
    d3.select(chartRef.current).selectAll('*').remove()

    // Density Plot 그리기
    drawDensityPlot(dataArray)
  }, [dataArray, price, targetPrice])

  return (
    <div>
      {dataArray?.length > 0 ? (
        <svg ref={chartRef} width={width} height={height} />
      ) : (
        <>데이터가 없습니다.</>
      )}
    </div>
  )
}

export default DensityPlot

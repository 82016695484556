import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import SKURegister from '../images/SKURegister.png'
import Log from '../utils/log'
import Page from '../components/atoms/page'
import {
  FcAddDatabase as TitleIcon,
  FcFolder,
  FcOpenedFolder,
} from 'react-icons/fc'
import Button from '../components/atoms/button'
import SellerQuest from '../utils/sellerQuest'
import Card from '../components/atoms/card'
import SubTitle from '../components/atoms/subTitle'
import TutorialModal from '../components/atoms/tutorialModal'
import ImageUploading from 'react-images-uploading'
import backendApis from '../utils/backendApis'

const SellerNoticeScreen = observer(() => {
  const history = useHistory()
  const [mainImagesData, setMainImagesData] = useState([])
  const [croppedMainImagesData, setCroppedMainImagesData] = useState([])
  const [showNotice, setShowNotice] = useState(false)

  useEffect(async () => {
    const result = await backendApis.checkNoticeImageUpload()
    if (result?.status === 200) {
      setShowNotice(true)
      setMainImagesData(
        result?.data?.noticeImageUris?.map((imageUri) => ({
          sourceUri: imageUri,
          isUploaded: true,
        })),
      )
    }
  }, [])

  const handleRegister = async () => {
    if (mainImagesData?.length === 0 || !croppedMainImagesData) {
      alert('이미지를 등록해주세요')
      return
    }
    console.log(mainImagesData?.length, 'mainImagesData?.length')
    if (mainImagesData?.length > 1) {
      alert('이미지는 1장만 등록이 가능합니다')
      return
    }
    const result = await backendApis.uploadSellerNoticeImage(mainImagesData)
    if (result?.status === 200) {
      alert('등록이 완료되었습니다')
      setMainImagesData(
        result?.mainImageUris?.map((imageUri) => ({
          sourceUri: imageUri,
          isUploaded: true,
        })),
      )
      setShowNotice(true)
      setCroppedMainImagesData([])
    } else {
      alert('등록 과정에 문제가 생겼습니다')
      setMainImagesData([])
      setCroppedMainImagesData([])
    }
  }

  const handleChangeImage = async () => {
    if (mainImagesData?.length === 0 || !croppedMainImagesData) {
      alert('이미지를 등록해주세요')
      return
    }
    console.log(mainImagesData?.length, 'mainImagesData?.length')
    if (mainImagesData?.length > 1) {
      alert('이미지는 1장만 등록이 가능합니다')
      return
    }
    const result = await backendApis.uploadSellerNoticeImage(mainImagesData)
    if (result?.status === 200) {
      alert('수정이 완료되었습니다')
      setCroppedMainImagesData([])
    } else {
      alert('수정 과정에 문제가 생겼습니다')
      setMainImagesData([])
      setCroppedMainImagesData([])
    }
  }

  const handleDelete = async () => {
    const result = await backendApis.deleteSellerNoticeImage()
    if (result?.status === 200) {
      alert('삭제가 완료되었습니다')
      setShowNotice(false)
      setMainImagesData([])
      setCroppedMainImagesData([])
    } else {
      alert('삭제 과정에 문제가 생겼습니다')
      setMainImagesData([])
      setCroppedMainImagesData([])
    }
  }

  return (
    <>
      <div className='max-w-7xl'>
        <Page title='공지 사항 일괄 등록' Icon={TitleIcon}>
          <div className='grid flex-col items-start justify-start flex-1  px-10 py-10 mt-5 bg-white shadow-lg rounded-xl'>
            <div>
              <div className='flex flex-col items-start justify-center flex-1 mb-10'>
                <div className='mb-3'>
                  <div className='flex flex-row flex-1 mb-5 text-xl font-bold '>
                    공지사항 이미지는 판매중인 모든 상품 상세페이지의 가장
                    상단에 위치됩니다.
                  </div>
                  <div className='text-gray-500'>
                    <div>
                      개별 상품 공지사항 등록은 개발 진행 중에 있습니다.
                    </div>
                  </div>
                  <div className='text-gray-500 mt-1'>
                    <div>
                      공지사항 이미지는 자유롭게 등록 및 삭제하실 수 있습니다.
                    </div>
                  </div>
                </div>
              </div>
              <Card title='공지사항 이미지' necessary>
                <div>
                  <div className='flex items-center mb-5 '>
                    <SubTitle>공지사항 이미지를 등록해 주세요</SubTitle>
                    <TutorialModal modalTitle='대표이미지' />
                  </div>
                  <div className='mt-5 text-base'>
                    <span style={{ color: 'red' }} className='font-bold'>
                      공지사항 이미지
                    </span>
                    는 매출에 많은 영향을 미칩니다. 아래 예시를 잘 확인하시어,
                    매출을 향상해 보세요!
                  </div>
                  <div className='ml-3'>
                    <div className='mt-2'>
                      1. 800px*800px 이상, 1200px*1200px 권장
                    </div>
                    <div className='mt-2'>
                      2. 단일 이미지 최대 5MB, jpg/ jpeg/ png 허용
                    </div>
                    <div className='mt-2'>
                      3. 흰색(RGB 255,255,255) 배경 권장
                    </div>
                    <div className='mt-2'>
                      4. 과도한 글귀, 도형, 워터마크, 배경 권장하지 않음
                    </div>
                  </div>
                </div>
                <div className='flex flex-col flex-1 mt-10'>
                  <div>
                    <ImageUploading
                      multiple
                      value={mainImagesData}
                      onChange={(imageList, addUpdateIndex) => {
                        console.log(`addupdate index: ${addUpdateIndex}`)
                        setMainImagesData(imageList)
                      }}
                      maxFileSize={5e6}
                      acceptType={['jpg', 'png', 'jpeg']}
                      resolutionType='more'
                      resolutionWidth={800}
                      resolutionHeight={800}
                      onError={(e) => {
                        if (e.maxFileSize) {
                          alert(
                            '이미지 파일 용량을 확인해주세요.(개당 최대 5MB)',
                          )
                        } else if (e.acceptType) {
                          alert(
                            '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/ jpeg/ png)',
                          )
                        } else if (e.resolution) {
                          alert(
                            '고화질 대표이미지로 더 많은 상품 노출을 획득하세요!\n(최소 800px*800px 이상, 권장 이미지 1200px*1200px)',
                          )
                        }
                      }}
                      allowNonImageType='false'
                      maxNumber={10}
                      dataURLKey='sourceUri'
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <div {...dragProps}>
                          <Button
                            type='button'
                            onClick={onImageUpload}
                            appearance='neutralSub'
                          >
                            사진 추가하기
                          </Button>
                          <div>
                            <div className='flex items-center justify-center h-20 my-5 bg-gray-100 rounded-md shadow-md w-96'>
                              {isDragging ? (
                                <>
                                  <FcOpenedFolder className='w-5 h-5 mr-1' />
                                  <span>사진을 놓으시면 됩니다.</span>
                                </>
                              ) : (
                                <>
                                  <FcFolder className='w-5 h-5 mr-1' />
                                  <span>
                                    전체컷 총 1장의 이미지를 올려주세요.
                                  </span>
                                </>
                              )}
                            </div>
                            <div className='flex flex-row overflow-x-auto '>
                              {mainImagesData?.map((image, index) => (
                                <div key={index.toString()}>
                                  <div className='flex mr-10 w-80 h-80'>
                                    <img
                                      alt='img'
                                      src={image.sourceUri}
                                      className='object-cover object-center border-2 shadow-lg h-80 w-80 border-sub-300'
                                    />
                                  </div>
                                  <div>
                                    <button
                                      className='px-2 py-2 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                      type='button'
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      사진변경
                                    </button>
                                    <button
                                      className='px-2 py-2 mx-10 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                      type='button'
                                      onClick={() => {
                                        onImageRemove(index)
                                      }}
                                    >
                                      삭제
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </Card>
            </div>
            <>
              {showNotice ? (
                <div className='flex flex-row items-center justify-center'>
                  <Button
                    size='xxl'
                    className='m-6 w-50'
                    type='button'
                    disabled={SellerStore?.isLoading}
                    onClick={handleChangeImage}
                  >
                    공지사항 일괄 수정하기
                  </Button>
                  <Button
                    size='xxl'
                    className='m-6 w-50'
                    type='button'
                    disabled={SellerStore?.isLoading}
                    onClick={handleDelete}
                  >
                    공지사항 일괄 삭제하기
                  </Button>
                </div>
              ) : (
                <div className='flex flex-row items-center justify-center'>
                  <Button
                    size='xxl'
                    className='m-6 w-50'
                    type='button'
                    disabled={SellerStore?.isLoading}
                    onClick={handleRegister}
                  >
                    공지사항 일괄 등록하기
                  </Button>
                </div>
              )}
            </>
          </div>
        </Page>
      </div>
    </>
  )
})

export default SellerNoticeScreen

import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../../utils/backendApis'
import ItemStore from '../../stores/ItemStore'
import SellerStore from '../../stores/SellerStore'
import ReactCrop from 'react-image-crop'
import ImageUploading from 'react-images-uploading'
import 'react-image-crop/dist/ReactCrop.css'
import Button from '../atoms/button'
import resize from '../../utils/resize'

const DetailImageUpdateComponent = ({
  image,
  index,
  //   onImageUpdate,
  //   onImageRemove,
  detailImagesData,
  setDetailImagesData,
}) => {
  return (
    <div>
      <img
        // key={index.toString()}
        src={resize(image.sourceUri)}
        alt=''
        width='100%'
        // height='100'
      />
      <div className='flex flex-row'>
        <ImageUploading
          value={[image]}
          onChange={(imageList, addUpdateIndex) => {
            if (addUpdateIndex.toString() === '0') {
              setDetailImagesData(
                detailImagesData.map((imageTemp, indexTemp) => {
                  if (indexTemp === index) {
                    //   return imageList[0]
                    return { sourceUri: imageList[0].sourceUri }
                  }
                  return imageTemp
                }),
              )
            }
          }}
          dataURLKey='sourceUri'
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div className='flex flex-row items-center gap-2 mt-2 mb-5 select-none'>
              <Button
                type='button'
                onClick={() => onImageUpdate(0)}
                textColor='gray'
                appearance='teritiary'
              >
                사진 수정하기
              </Button>
              <div className='text-sub-300'>|</div>
              <Button
                appearance='teritiary'
                type='button'
                textColor='gray'
                onClick={() => {
                  // onImageRemove(index)

                  setDetailImagesData(
                    detailImagesData.filter(
                      (imageTemp, indexTemp) => indexTemp !== index,
                    ),
                  )
                }}
              >
                삭제하기
              </Button>
              <div className='text-sub-300'>|</div>
            </div>
          )}
        </ImageUploading>
        <ImageUploading
          value={[]}
          onChange={(imageList, addUpdateIndex) => {
            if (addUpdateIndex.toString() === '0') {
              const detailImagesDataTemp = JSON.parse(
                JSON.stringify(detailImagesData),
              )
              detailImagesDataTemp.splice(index + 1, 0, imageList[0])
              setDetailImagesData(detailImagesDataTemp)
            }
          }}
          dataURLKey='sourceUri'
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div>
              <Button
                className='mt-2 mb-5 ml-2'
                type='button'
                textColor='gray'
                onClick={() => onImageUpload()}
                appearance='teritiary'
              >
                여기에 추가하기
              </Button>
            </div>
          )}
        </ImageUploading>
      </div>
    </div>
  )
}

export default DetailImageUpdateComponent

import ItemTable from '../../components/ADS/molecules/itemTable'
import backendApis from '../../utils/backendApis'
import React, { useState, useEffect, useMemo } from 'react'
import resize from '../../utils/resize'
import { getDefaultBigDealPageInfo } from '../../utils/dealUtils'
import { Button, Pagination } from 'antd'
import OptionsInfoModal from '../../components/ADS/molecules/optionsInfoModal'
import commaNumber from 'comma-number'
import BigDealApplicationModal from '../../components/molecules/BigDealApplicationModal'
import ItemSearchComponent from '../../components/ItemComponents/ItemSearchComponent'
import Space from '../../components/atoms/Space'
import BigDealIntroduceBanner from './BigDealIntroduceBanner'
import BigDealTab from './BigDealTab'
import useBigDealApplicationScreen from './useBigDealApplicationScreen'
import Tooltip from './Tooltip'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const BigDealApplicationScreen = ({
  mdManagedBrandName,
  dealType,
  fixedMinimumDiscountRate,
  commissionRate,
}) => {
  const history = useHistory()
  if (dealType === 'chuseokDeal24' && !mdManagedBrandName) {
    alert('추석 특가전 신청 대상 셀러가 아닙니다.')
    history.goBack()
    return null
  }
  const [selectedItem, setSelectedItem] = useState()
  const [selectedData, setSelectedData] = useState(null)
  const [acceptedStatusTab, setAcceptedStatusTab] = useState('candidate')
  const {
    handleSearch,
    fetchData,
    acceptedData,
    getAcceptedTotalLength,
    getMinimumDiscountRate,
    isLoading,
    page,
    setPage,
    itemsCount,
    setItemsCount,
    itemsInfo,
    filteredData,
    applyTotalLength,
  } = useBigDealApplicationScreen({
    dealType,
    acceptedStatusTab,
    fixedMinimumDiscountRate,
  })

  const [openDealApplicationModal, setOpenDealApplicationModal] =
    useState(false)
  const [discountRates, setDiscountRates] = useState({})

  const chunkSize = 50

  const colorCode = '#1258A4'

  const columns = useMemo(
    () => [
      {
        Header: '메인이미지',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div className='flex flex-row items-center justify-center flex-1 w-full'>
              <img
                alt='mainImage'
                src={item?.mainImageUris ? resize(item?.mainImageUris[0]) : ''}
                className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
              />
            </div>
          )
        },
      },
      {
        Header: '상품명',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div className='flex flex-col flex-1 w-[220px]'>
              <div className='text-slate-500'>
                {item?.itemId?.toString() || item?._id?.toString()}
              </div>
              <div className='flex-wrap'>
                <span
                  style={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {item?.itemTitle}
                </span>
              </div>
            </div>
          )
        },
      },
      {
        Header: '등급',
        accessor: 'rankInfo.itemRank',
      },
      {
        Header: () => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>상시 팀구매가</span>
              <div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  marginLeft: '5px',
                }}
              >
                <Tooltip
                  descriptions={[
                    '상시 팀구매가는 올웨이즈 AI가 해당 상품의 최근 팀구매가 변동을 분석하여 계산한 상시가입니다.',
                    '만약 최근 잦은 가격 변동이 있었다면 현재 팀구매가와 상시 팀구매가가 다를 수 있습니다.',
                  ]}
                />
              </div>
            </div>
          )
        },
        id: 'teamPurchasePrice',
        Cell: ({ row }) => {
          const item = row?.original

          return (
            <div className='flex flex-col flex-1 w-[50px]'>
              {commaNumber(item?.wasTeamPurchasePrice)}원
            </div>
          )
        },
      },
      {
        Header: () => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>최소 할인율</span>
              <div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  marginLeft: '5px',
                }}
              >
                <Tooltip
                  descriptions={[
                    '최소 할인율은 해당 상품의 절대적 상시가 매력도를 기반으로 측정됩니다.',
                    '상시 가격이 매력적인 상품일수록, 최소 할인율이 낮아집니다.',
                  ]}
                />
              </div>
            </div>
          )
        },
        id: '최소 할인율',
        Cell: ({ row }) => {
          const item = row?.original
          const minimumDiscountRate = getMinimumDiscountRate(item)

          return (
            <div
              className='flex flex-col flex-1 font-bold'
              style={{ color: colorCode }}
            >
              <>{Math.round(commaNumber(minimumDiscountRate * 100))}%</>
            </div>
          )
        },
      },
      {
        Header: '상시 팀구매가',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div
              className='flex flex-col flex-1 font-bold'
              style={{ color: colorCode }}
            >
              <>{commaNumber(Math.floor(item?.originalTeamPurchasePrice))}원</>
            </div>
          )
        },
      },
      {
        Header: '신청 할인율',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div
              className='flex flex-col flex-1 font-bold'
              style={{ color: colorCode }}
            >
              <>{commaNumber(Math.round(item?.dealDiscountRate * 100))}%</>
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>할인율 수정</span>
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
                marginLeft: '5px',
              }}
            >
              <Tooltip
                descriptions={[
                  '기획전 성과는 상품의 절대적 가격의 매력도에 따라 결정됩니다.',
                  '비슷한 상품군 중 가격이 낮은 상품에게 노출량과 혜택이몰아지는 구조입니다.',
                  '절대적 가격이 낮을수록 높은 매출을 기대할 수 있습니다.',
                ]}
              />
            </div>
          </div>
        ),

        id: '할인율 증가 (%)',
        Cell: ({ row }) => {
          const item = row?.original
          const minimumDiscountRate = getMinimumDiscountRate(item)
          const minRate = Math.round(minimumDiscountRate * 100) / 100

          const handleDropdownChange = (event) => {
            const value = Number(event.target.value)
            if (value >= minRate && value <= 0.9) {
              setDiscountRates((prevRates) => ({
                ...prevRates,
                [item?._id]: value,
              }))
            }
          }

          const dropdownOptions = []
          for (let rate = minRate; rate <= 0.9; rate += 0.05) {
            rate = Math.round(rate * 100) / 100
            dropdownOptions.push(
              <option key={rate} value={rate}>
                {Math.round(rate * 100)}%
              </option>,
            )
          }

          return (
            <div className='flex flex-row flex-1'>
              <select
                value={discountRates[item?._id] || minRate}
                onChange={handleDropdownChange}
                className='form-select'
              >
                <option value='' disabled>
                  선택하세요
                </option>
                {dropdownOptions}
              </select>
            </div>
          )
        },
      },

      {
        Header: '기획전 제안가',
        Cell: ({ row }) => {
          const item = row?.original
          const discountRate =
            discountRates[item._id] || getMinimumDiscountRate(item)
          const discountedPrice =
            item?.dealTeamPurchasePrice ||
            Math.floor(item?.wasTeamPurchasePrice * (1 - discountRate))
          return (
            <div
              className='flex flex-col flex-1 font-bold'
              style={{ color: colorCode }}
            >
              <>{commaNumber(discountedPrice)}원</>
            </div>
          )
        },
      },
      {
        Header: '기획전 신청가',
        Cell: ({ row }) => {
          const item = row?.original
          const discountRate =
            discountRates[item._id] || getMinimumDiscountRate(item)

          const discountedPrice =
            item?.dealTeamPurchasePrice ||
            Math.floor(item?.originalTeamPurchasePrice * (1 - discountRate))
          return (
            <div
              className='flex flex-col flex-1 font-bold'
              style={{ color: colorCode }}
            >
              <>{commaNumber(discountedPrice)}원</>
            </div>
          )
        },
      },
      {
        Header: '수수료',
        Cell: () => {
          const roundedRate = (commissionRate * 100).toFixed(1)
          return (
            <div
              className='flex flex-col flex-1 font-bold'
              style={{ color: colorCode }}
            >
              <>{roundedRate}%</>
            </div>
          )
        },
      },
      {
        Header: '기획전 진행 동의',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div className='flex flex-col flex-1'>
              <Button
                onClick={async () => {
                  const minimumDiscountRate = getMinimumDiscountRate(item)
                  const discountRate =
                    discountRates[item?._id] || minimumDiscountRate
                  if (discountRate >= minimumDiscountRate) {
                    const result = await backendApis.updateDealStatus({
                      dealType,
                      itemId: item?._id,
                      wasTeamPurchasePrice: item?.wasTeamPurchasePrice,
                      wasOptionsInfo: item?.wasOptionsInfo,
                      status: 'accepted',
                      commissionRate,
                      discountRate,
                      mdManagedBrandName,
                    })
                    if (result?.status === 2000 || result?.status === 200) {
                      alert('성공적으로 신청되었습니다.')
                      setItemsCount(itemsCount - 1)
                      await fetchData(false)
                    }
                  } else {
                    alert('최소 할인율 이상으로 설정해주세요.')
                  }
                }}
                type='primary'
                style={{ backgroundColor: colorCode, color: '#FFF' }}
              >
                신청 동의
              </Button>
            </div>
          )
        },
      },
      {
        Header: '옵션별 가격 수정하기',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div className='flex flex-col flex-1'>
              <Button
                onClick={() => {
                  setSelectedItem(item)
                  setOpenDealApplicationModal(true)
                }}
                type='primary'
                style={{ backgroundColor: colorCode, color: '#FFF' }}
              >
                수정하기
              </Button>
            </div>
          )
        },
      },
      {
        Header: '기획전 진행 철회',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div className='flex flex-col flex-1'>
              <Button
                onClick={async () => {
                  const result = await backendApis.deleteDealStatus(
                    dealType,
                    item?.itemId,
                  )
                  if (result?.status === 2000 || result?.status === 200) {
                    alert('성공적으로 취소되었습니다.')
                    await getAcceptedTotalLength()
                    await acceptedData()
                  }
                }}
                type='secondary'
                style={{ backgroundColor: colorCode, color: '#FFF' }}
              >
                특가 취소
              </Button>
            </div>
          )
        },
      },
    ],
    [itemsInfo, filteredData, discountRates],
  )

  useEffect(() => {
    if (acceptedStatusTab === 'candidate') {
      fetchData(true, null, null)
    } else {
      getAcceptedTotalLength()
      acceptedData()
    }
  }, [page, acceptedStatusTab, openDealApplicationModal])

  const { displayDealType, bigDealBannerSrc } =
    getDefaultBigDealPageInfo(dealType)

  const brandSellerPrefix = mdManagedBrandName
    ? `브랜드 셀러(${mdManagedBrandName || ''})`
    : ''
  const title = `${brandSellerPrefix} ${displayDealType} 신청하기`

  return (
    <div>
      <div className='w-[80vw]'>
        <Space height={20} />
        <div className='text-2xl font-bold select-none'>{title}</div>
        <Space height={10} />
        {/* <BigDealIntroduceBanner src={bigDealBannerSrc} /> */}
        <Space height={20} />

        <BigDealTab
          acceptedStatusTab={acceptedStatusTab}
          setAcceptedStatusTab={setAcceptedStatusTab}
          setPage={setPage}
        >
          <>
            <ItemSearchComponent onSearch={handleSearch} />
            <Space height={20} />
            <div>
              {acceptedStatusTab === 'candidate'
                ? !isLoading && (
                    <>
                      <div className='flex flex-col items-center flex-1 p-10 bg-white shadow-xl rounded-xl'>
                        <ItemTable
                          columns={columns.filter(
                            (e) =>
                              e.Header !== '기획전 진행 철회' &&
                              e.Header !== '할인율' &&
                              e.Header !== '기획전 신청가' &&
                              e.Header !== '옵션별 기획전 신청가' &&
                              e.Header !== '옵션별 가격 수정하기' &&
                              e.Header !== '신청 할인율' &&
                              e.Header !== '상시 팀구매가',
                          )}
                          data={itemsInfo}
                          rowSelect={false}
                        />
                        <Pagination
                          pageSize={chunkSize}
                          total={itemsCount}
                          current={page}
                          className='mt-5'
                          onChange={(e) => setPage(e)}
                        />
                      </div>
                    </>
                  )
                : !isLoading && (
                    <>
                      <div className='flex flex-col items-center flex-1 p-10 bg-white shadow-xl rounded-xl'>
                        <ItemTable
                          columns={columns.filter(
                            (e) =>
                              e.Header !== '기획전 진행 동의' &&
                              e.id !== '최소 할인율' &&
                              e.id !== 'teamPurchasePrice' &&
                              e.Header !== '기획전 제안가' &&
                              e.Header !== '옵션별 기획전 제안가' &&
                              e.id !== '할인율 증가 (%)',
                          )}
                          data={filteredData}
                          rowSelect={false}
                        />
                        <Pagination
                          pageSize={chunkSize}
                          total={applyTotalLength}
                          className='mt-5'
                          current={page}
                          onChange={(e) => {
                            setPage(e)
                          }}
                        />
                      </div>
                    </>
                  )}
            </div>
            {openDealApplicationModal && (
              <BigDealApplicationModal
                showModal={openDealApplicationModal}
                setShowModal={setOpenDealApplicationModal}
                dealType={dealType}
                itemInfo={selectedItem}
                setItemInfo={setSelectedItem}
                minPriceRatio={getMinimumDiscountRate(selectedItem)}
                applied={acceptedStatusTab !== 'candidate'}
              />
            )}
            {selectedData && (
              <OptionsInfoModal
                optionsInfo={selectedData?.wasOptionsInfo}
                discountRate={selectedData?.minimumDiscountRate}
                columns={[
                  {
                    Header: '옵션1 / 상세옵션',
                    accessor: 'option1',
                    index: 1,
                  },
                  {
                    Header: '옵션2 / 상세옵션',
                    accessor: 'option2',
                    index: 2,
                  },
                  { Header: '딜 진행가', accessor: 'teamPurchasePrice' },
                ]}
                closeCallback={() => {
                  setSelectedData(null)
                }}
              />
            )}
          </>
        </BigDealTab>
      </div>
    </div>
  )
}

export default BigDealApplicationScreen

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import ImageUploading from 'react-images-uploading'
import backendApis from '../utils/backendApis'
import OrderStore from '../stores/OrderStore'
import SellerStore from '../stores/SellerStore'
import AuthStore from '../stores/AuthStore'
import Button from '../components/atoms/button'
import { Tooltip } from 'react-tippy'

const TIME_TO_VERIFY = 180 // 인증만료시간 3분
const TIME_TO_RETRY = 30 // 인증 재시도 대기시간 30초

const EditSellerInfoScreen = observer(() => {
  const history = useHistory()

  // 판매자 정보
  const [imageData, setImageData] = useState([])
  const [sellerDisplayName, setSellerDisplayName] = useState(
    SellerStore.sellerInfo.sellerDisplayName || '',
  )
  const [repName, setRepName] = useState(
    SellerStore.sellerInfo?.validRepName ||
      SellerStore.sellerInfo?.dataForValidation?.businessRegisteredData
        ?.repName ||
      '',
  )
  const [corpName, setCorpName] = useState(
    SellerStore.sellerInfo?.validCorpName ||
      SellerStore.sellerInfo?.dataForValidation?.businessRegisteredData
        ?.companyName ||
      SellerStore.sellerInfo?.dataForValidation?.businessRegisteredData
        ?.corpName ||
      '',
  )
  const [representorName, setRepresentorName] = useState(
    SellerStore.sellerInfo.representorName || '',
  )
  const [phoneNumber, setPhoneNumber] = useState(
    SellerStore.sellerInfo.phoneNumber || '',
  )
  const [contactNumber, setContactNumber] = useState(
    SellerStore.sellerInfo.contactNumber || '',
  )
  const [email, setEmail] = useState(SellerStore.sellerInfo.email || '')
  const [description, setDescription] = useState(
    SellerStore.sellerInfo.description || '',
  )
  const [defaultShippingCompanyId, setDefaultShippingCompanyId] = useState(
    SellerStore.sellerInfo.defaultShippingCompanyId || '',
  )
  const [address, setAddress] = useState(SellerStore.sellerInfo.address || '')
  const [bankAccountInfo, setBankAccountInfo] = useState({
    ...(SellerStore.sellerInfo?.dataForValidation?.bankAccountInfo || {}),
  })
  const [receivingAccountNumber, setReceivingAccountNumber] = useState(
    SellerStore.sellerInfo?.dataForValidation?.bankAccountInfo
      ?.bankAccountNumber || '',
  )
  const [mailOrderBusinessNumber, setMailOrderBusinessNumber] = useState(
    SellerStore.sellerInfo?.dataForValidation
      ?.mailOrderSalesRegistrationNumber || '',
  )
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState(
    SellerStore.sellerInfo?.dataForValidation?.businessRegistrationNumber || '',
  )

  // 휴대폰 인증
  const [isPhoneVerified, setIsPhoneVerified] = useState(true) // 기존 번호는 이미 인증된 상태
  const [showVerificationInput, setShowVerificationInput] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [inputVerificationCode, setInputVerificationCode] = useState('')
  const [isVerificationNumberSent, setIsVerificationNumberSent] =
    useState(false)
  const [leftTimeToVerify, setLeftTimeToVerify] = useState(TIME_TO_VERIFY)
  const [leftTimeToRetry, setLeftTimeToRetry] = useState(TIME_TO_RETRY)

  useEffect(() => {
    if (SellerStore.sellerInfo._id) {
      if (SellerStore.sellerInfo.sellerImageUri) {
        setImageData([
          {
            sourceUri: SellerStore.sellerInfo.sellerImageUri,
          },
        ])
      }
      setSellerDisplayName(SellerStore.sellerInfo?.sellerDisplayName || '')
      setRepName(
        SellerStore.sellerInfo?.validRepName ||
          SellerStore.sellerInfo?.dataForValidation?.businessRegisteredData
            ?.repName ||
          '',
      )
      setCorpName(
        SellerStore.sellerInfo?.validCorpName ||
          SellerStore.sellerInfo?.dataForValidation?.businessRegisteredData
            ?.companyName ||
          SellerStore.sellerInfo?.dataForValidation?.businessRegisteredData
            ?.corpName ||
          '',
      )
      setRepresentorName(SellerStore.sellerInfo.representorName || '')
      setPhoneNumber(SellerStore.sellerInfo.phoneNumber || '')
      setContactNumber(SellerStore.sellerInfo.contactNumber || '')
      setEmail(SellerStore.sellerInfo.email || '')
      setDescription(SellerStore.sellerInfo.description || '')
      setDefaultShippingCompanyId(
        SellerStore.sellerInfo.defaultShippingCompanyId || '',
      )

      setAddress(SellerStore.sellerInfo.address || '')
      setBankAccountInfo({
        ...(SellerStore.sellerInfo?.dataForValidation?.bankAccountInfo || {}),
      })
      setReceivingAccountNumber(
        SellerStore.sellerInfo?.dataForValidation?.bankAccountInfo
          ?.bankAccountNumber || '',
      )
      setMailOrderBusinessNumber(
        SellerStore.sellerInfo?.dataForValidation
          ?.mailOrderSalesRegistrationNumber || '',
      )
      setBusinessRegistrationNumber(
        SellerStore.sellerInfo?.dataForValidation?.businessRegistrationNumber ||
          '',
      )
    }
  }, [SellerStore.sellerInfo._id])

  // 휴대폰 인증 타이머 (인증 유효 시간)
  useEffect(() => {
    let timer
    if (leftTimeToVerify > 0 && showVerificationInput) {
      timer = setInterval(() => {
        setLeftTimeToVerify((prev) => prev - 1)
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [leftTimeToVerify, showVerificationInput])

  // 휴대폰 인증 타이머 (인증 재시도 대기 시간)
  useEffect(() => {
    let timer
    if (leftTimeToRetry > 0) {
      timer = setInterval(() => {
        setLeftTimeToRetry((prev) => prev - 1)
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [leftTimeToRetry])

  // 휴대폰 전화번호로 인증번호 문자 발송
  const handleSendVerificationCode = async () => {
    if (!phoneNumber) {
      alert('휴대폰 번호를 입력해주세요.')
      return
    }
    if (phoneNumber?.length > 8 && phoneNumber.slice(0, 2) === '01') {
      const result = await backendApis.sendVerificationCode(phoneNumber)
      if (result?.status === 200 && result?.data?.verificationCode) {
        alert('인증번호가 문자로 발송되었습니다.')
        setIsVerificationNumberSent(true)
        setLeftTimeToVerify(TIME_TO_VERIFY)
        setLeftTimeToRetry(TIME_TO_RETRY)
        setShowVerificationInput(true)
        setVerificationCode(result.data.verificationCode)
      } else {
        alert('인증번호 전송에 실패했습니다.\n잠시 후 다시 시도해주세요.')
      }
    } else {
      alert('정확한 휴대전화 번호를 입력해주세요.')
    }
  }

  return (
    <div className='flex flex-col items-center justify-center flex-1 w-full px-10 py-10 mt-10 w-min-7xl'>
      <div className='flex flex-col items-center flex-1 mb-5'>
        <div className='text-2xl font-bold select-none'>판매자 정보 관리</div>
        <div className='text-md mt-4 font-md select-none'>
          * 수정불가 항목은 보안정책 상 판매자지원센터로 문의하여 변경
          가능합니다.
        </div>
        {AuthStore.isMasterAccount && (
          <div className='text-xl mt-4 font-bold select-none text-red-500'>
            마스터 계정으로 로그인하셨습니다. 정보를 변경할 수 없습니다.
          </div>
        )}
      </div>
      <div className='my-6 overflow-hidden shadow sm:rounded-md'>
        <div className='px-4 py-5 bg-white sm:p-6'>
          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-6'>
              <span className='block text-sm font-medium text-gray-700'>
                프로필 사진
              </span>
              <div className='flex items-center mt-1'>
                <span className='inline-block w-12 h-12 overflow-hidden bg-gray-100 border rounded-full'>
                  {imageData[0]?.sourceUri && (
                    <img
                      src={imageData[0]?.sourceUri}
                      alt=''
                      style={{ height: 46, width: 46, objectFit: 'cover' }}
                    />
                  )}
                  <svg
                    className='w-full h-full text-gray-300'
                    fill='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                  </svg>
                </span>
                <ImageUploading
                  value={imageData}
                  onChange={(imageList) => {
                    setImageData(imageList)
                  }}
                  dataURLKey='sourceUri'
                >
                  {({ onImageUpload }) => (
                    <button
                      type='button'
                      onClick={() => {
                        onImageUpload()
                      }}
                      className='px-3 py-2 ml-5 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    >
                      수정
                    </button>
                  )}
                </ImageUploading>
              </div>
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <span
                htmlFor='seller-display-name'
                className='block text-sm font-medium text-gray-700'
              >
                올웨이즈 마켓명
              </span>
              <input
                type='text'
                value={sellerDisplayName}
                placeholder='올웨이즈 마켓명'
                id='seller-display-name'
                onChange={(e) => {
                  setSellerDisplayName(e.target.value)
                }}
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>
            {/* grid 칸 채우는 용 */}
            <div className='col-span-6 sm:col-span-3'></div>

            <div className='col-span-6 sm:col-span-3'>
              <span
                htmlFor='representor-name'
                className='block text-sm font-medium text-red-500'
              >
                대표자명 (수정불가)
              </span>
              <input
                type='text'
                id='representor-name'
                placeholder='대표자명'
                value={repName}
                onChange={(e) => {
                  setRepName(e.target.value)
                }}
                disabled
                className='block w-full mt-1 border-red-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <span
                htmlFor='seller-display-name'
                className='block text-sm font-medium text-red-500'
              >
                법인/상호명 (수정불가)
              </span>
              <input
                type='text'
                value={corpName}
                placeholder='업체명'
                id='seller-company-name'
                disabled
                onChange={(e) => {
                  setCorpName(e.target.value)
                }}
                className='block w-full mt-1 border-red-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <span
                htmlFor='representor-name'
                className='block text-sm font-medium text-gray-700'
              >
                담당자명
              </span>
              <input
                type='text'
                id='representor-name'
                placeholder='담당자명'
                value={representorName}
                onChange={(e) => {
                  setRepresentorName(e.target.value)
                }}
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <span className='block text-sm font-medium text-gray-700'>
                담당자 휴대폰 번호 (변경 시, 인증 필요)
                <Tooltip
                  arrow='true'
                  theme='light'
                  title='알림톡을 수신할 번호를 기재해 주세요.(앱 노출되지 않음) '
                  animation='fade'
                  position='bottom'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='gray'
                    className='inline-block w-4 h-4 mx-1'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                    />
                  </svg>
                </Tooltip>
              </span>
              <div className='flex gap-2 mt-1'>
                <input
                  type='text'
                  value={phoneNumber}
                  placeholder='ex) 01012345678'
                  onChange={(e) => {
                    setPhoneNumber(e.target.value)
                    setIsPhoneVerified(false) // 번호 변경 시 인증 상태 초기화
                  }}
                  className='block flex-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
                {isPhoneVerified ? (
                  <button
                    className='w-24 px-4 py-2 whitespace-nowrap flex justify-center items-center text-white bg-theme-500 rounded-md opacity-60 text-[13px]'
                    disabled
                  >
                    인증완료
                  </button>
                ) : isVerificationNumberSent && leftTimeToRetry > 0 ? (
                  <button
                    className='w-24 px-4 py-2 whitespace-nowrap flex justify-center items-center text-white bg-theme-500 rounded-md opacity-60 text-[13px]'
                    disabled
                  >
                    {`전송됨 (${
                      leftTimeToRetry < 10
                        ? `0${leftTimeToRetry}`
                        : leftTimeToRetry
                    }초)`}
                  </button>
                ) : (
                  <button
                    className={`w-24 px-4 py-2 whitespace-nowrap flex justify-center items-center text-white bg-theme-500 rounded-md text-[13px] ${
                      !phoneNumber ? 'opacity-60' : ''
                    }`}
                    onClick={handleSendVerificationCode}
                    disabled={!phoneNumber}
                  >
                    {isVerificationNumberSent ? '재전송' : '인증요청'}
                  </button>
                )}
              </div>

              {/* 인증번호 입력 */}
              {showVerificationInput && !isPhoneVerified && (
                <div className='flex gap-2 mt-2'>
                  <input
                    className='flex-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    type='text'
                    value={inputVerificationCode}
                    placeholder='인증번호 입력'
                    onChange={(e) => setInputVerificationCode(e.target.value)}
                  />
                  <button
                    className='w-24 px-auto py-2 flex justify-center items-center text-white bg-theme-500 rounded-md text-[13px] whitespace-nowrap'
                    onClick={() => {
                      if (leftTimeToVerify <= 0) {
                        alert('인증 시간이 만료되었습니다. 다시 시도해주세요.')
                        setInputVerificationCode('')
                        setVerificationCode('')
                        return
                      }
                      if (inputVerificationCode === verificationCode) {
                        setIsPhoneVerified(true)
                        setShowVerificationInput(false)
                        alert('인증이 완료되었습니다.')
                      } else {
                        alert('인증번호가 일치하지 않습니다.')
                      }
                    }}
                  >
                    {(() => {
                      if (leftTimeToVerify <= 0) return '인증 만료'
                      const minutes = Math.floor(leftTimeToVerify / 60)
                      const seconds = leftTimeToVerify % 60
                      return `인증하기 (${minutes}:${
                        seconds < 10 ? `0${seconds}` : seconds
                      })`
                    })()}
                  </button>
                </div>
              )}

              {isPhoneVerified && (
                <span className='text-blue-500 text-sm mt-1 block'>
                  휴대폰 인증이 완료되었습니다
                </span>
              )}
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <span
                htmlFor='contact-number'
                className='block text-sm font-medium text-gray-700'
              >
                고객문의 대표 전화번호
                <Tooltip
                  arrow='true'
                  theme='light'
                  title='고객 문의전화를 수신할 번호를 기재해 주세요. (앱 노출됨)'
                  animation='fade'
                  position='bottom'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='gray'
                    className='inline-block w-4 h-4 mx-1'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                    />
                  </svg>
                </Tooltip>
              </span>
              <input
                type='text'
                id='contact-number'
                value={contactNumber}
                placeholder='ex) 07012345678'
                onChange={(e) => {
                  setContactNumber(e.target.value)
                }}
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <span
                htmlFor='email'
                className='block text-sm font-medium text-gray-700'
              >
                판매자 Email
              </span>
              <input
                type='email'
                value={email}
                id='email'
                placeholder='ex) alwayz@alwayz.com'
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6'>
              <span
                htmlFor='description'
                className='block text-sm font-medium text-gray-700'
              >
                판매처 설명
              </span>
              <input
                type='text'
                value={description}
                id='description'
                placeholder='ex) 맛 좋은 고구마를 합리적인 가격에 판매합니다'
                onChange={(e) => {
                  setDescription(e.target.value)
                }}
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <span
                htmlFor='shipping_company'
                className='block text-sm font-medium text-gray-700'
              >
                대표 택배사
              </span>
              <select
                id='shipping_company'
                onChange={(e) => {
                  const correspondingShippingCompanyInfo =
                    OrderStore.shippingCompaniesInfo.find(
                      (shippingCompanyInfo) =>
                        shippingCompanyInfo.shippingCompanyCode ===
                        e.target.value,
                    )
                  if (correspondingShippingCompanyInfo) {
                    setDefaultShippingCompanyId(
                      correspondingShippingCompanyInfo._id,
                    )
                  } else {
                    setDefaultShippingCompanyId('')
                  }
                }}
                value={
                  OrderStore.shippingCompaniesInfo.find(
                    (shippingCompany) =>
                      shippingCompany._id === defaultShippingCompanyId,
                  )?.shippingCompanyCode || ''
                }
                className='block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
              >
                <option value=''>대표 택배사</option>
                {OrderStore.shippingCompaniesInfo.map((shippingCompanyInfo) => (
                  <option
                    key={shippingCompanyInfo.shippingCompanyCode}
                    value={shippingCompanyInfo.shippingCompanyCode}
                  >
                    {shippingCompanyInfo.shippingCompanyName}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-span-6'>
              <span
                htmlFor='address'
                className='block text-sm font-medium text-gray-700'
              >
                주소
              </span>
              <input
                type='text'
                id='address'
                value={address}
                placeholder='ex) 서울특별시 관악구 남부순환로 99, 1801호'
                onChange={(e) => {
                  setAddress(e.target.value)
                }}
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
              <span
                htmlFor='bank-code'
                className='block text-sm font-medium text-red-500'
              >
                입금은행 (수정불가)
              </span>
              <select
                id='bank-code'
                value={bankAccountInfo?.bankCode}
                disabled
                onChange={(e) => {
                  const correspondingBankInfo = SellerStore.banksInfo.find(
                    (bankInfo) => bankInfo.bankCode === e.target.value,
                  )
                  if (correspondingBankInfo) {
                    setBankAccountInfo({
                      ...bankAccountInfo,
                      ...correspondingBankInfo,
                    })
                  } else {
                    setBankAccountInfo({})
                  }
                }}
                className='block w-full px-3 py-2 mt-1 bg-white border border-red-300 rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
              >
                <option value=''>입금 은행</option>
                {SellerStore.banksInfo.map((bankInfo) => (
                  <option key={bankInfo.bankCode} value={bankInfo.bankCode}>
                    {bankInfo.bankName}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
              <span
                htmlFor='depositor-name'
                className='block text-sm font-medium text-red-500'
              >
                예금주명 (수정불가)
              </span>
              <input
                type='text'
                id='depositor-name'
                placeholder='예금주명'
                disabled
                value={bankAccountInfo?.depositorName || ''}
                onChange={(e) => {
                  setBankAccountInfo({
                    ...bankAccountInfo,
                    depositorName: e.target.value,
                  })
                }}
                className='block w-full mt-1 border-red-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
              <span
                htmlFor='receiving-account-number'
                className='block text-sm font-medium text-red-500'
              >
                입금 계좌번호 (수정불가)
              </span>
              <input
                type='text'
                id='receiving-account-number'
                placeholder='ex) 1006429234853'
                disabled
                onChange={(e) => {
                  setReceivingAccountNumber(e.target.value)
                }}
                value={receivingAccountNumber}
                className='block w-full mt-1 border-red-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <span
                htmlFor='mail-order-business-number'
                className='block text-sm font-medium text-red-500'
              >
                통신판매업 신고번호 (수정불가)
              </span>
              <input
                type='text'
                id='mail-order-business-number'
                value={mailOrderBusinessNumber}
                disabled
                placeholder='ex) 2023-서울강북-9999'
                onChange={(e) => {
                  setMailOrderBusinessNumber(e.target.value)
                }}
                className='block w-full mt-1 border-red-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <span
                htmlFor='business-registration-number'
                className='block text-sm font-medium text-red-500'
              >
                사업자번호 (수정불가)
              </span>
              <input
                type='text'
                id='business-registration-number'
                maxLength={12}
                value={businessRegistrationNumber}
                disabled
                placeholder='  사업자 등록번호 (예: 123-45-67890)'
                onChange={(e) => {
                  setBusinessRegistrationNumber(e.target.value)
                }}
                className='block w-full mt-1 border-red-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>
          </div>
        </div>
        <div className='px-4 py-3 text-right bg-gray-50 sm:px-6'>
          <Button
            type='submit'
            size='lg'
            onClick={async () => {
              if (
                !sellerDisplayName ||
                !representorName ||
                !phoneNumber ||
                !contactNumber ||
                !email ||
                !description ||
                !defaultShippingCompanyId ||
                !address
              ) {
                alert(`입력하지 않은 정보가 없는지 확인해주세요`)
                return
              }
              if (imageData?.length !== 1) {
                alert('프로필 사진을 등록해주세요.')
                return
              }
              if (!isPhoneVerified) {
                alert(
                  '휴대폰 번호 변경이 감지되었습니다.\n번호 인증을 완료해주세요.',
                )
                return
              }

              if (SellerStore.isLoading) {
                return
              }

              SellerStore.setIsLoading(true)

              const result = await backendApis.modifySellerDetailInfo(
                sellerDisplayName,
                representorName,
                phoneNumber,
                contactNumber,
                email,
                description,
                defaultShippingCompanyId,
                address,
                '82',
              )
              if (result?.status === 200) {
                if (imageData.length === 1) {
                  await backendApis.setSellerImage(
                    SellerStore.sellerInfo._id,
                    imageData,
                  )
                }

                SellerStore.setIsLoading(false)
                setSellerDisplayName('')
                setRepresentorName('')
                setPhoneNumber('')
                setContactNumber('')
                setEmail('')
                setDescription('')
                setDefaultShippingCompanyId('')
                setAddress('')
                setBankAccountInfo({})
                setReceivingAccountNumber('')
                setMailOrderBusinessNumber('')
                setBusinessRegistrationNumber('')
                AuthStore.setToken(result.data.token)
                localStorage.setItem('@alwayz@seller@token@', result.data.token)
                const loadSellerResult = await SellerStore.loadSellerInfo()
                if (loadSellerResult) {
                  history.replace('/')
                } else {
                  alert('입력하지 않은 정보가 없는지 확인해주세요')
                }
              } else {
                if (AuthStore.isMasterAccount) {
                  alert(
                    '마스터 비밀번호로 로그인 시, 정보를 변경할 수 없습니다.',
                  )
                } else {
                  alert(
                    '정보 업데이트 과정에서 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.',
                  )
                }
              }

              SellerStore.setIsLoading(false)
            }}
          >
            정보 수정하기
          </Button>
        </div>
      </div>
    </div>
  )
})

export default EditSellerInfoScreen

import SellerStore from '../../stores/SellerStore'
import React from 'react'
import Button from '../atoms/button'
import Tooltip from '../ADS/atoms/tooltip'

const SellerViralEngine = ({ showModal, setShowModal }) => {
  const sellerInfo = SellerStore?.sellerInfo
  const sellerViralUrl = `https://alwayzseller.ilevit.com/login/register-seller?recommender=${sellerInfo?.sellerName}`
  const handleCopyClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text)
      alert(`${text} 복사 완료!`)
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }
  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
            <div className='relative w-full max-w-xl mx-auto my-6'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <h3 className='text-xl font-semibold text-black'>
                    무료 광고크레딧 이벤트
                  </h3>
                </div>
                {/* body */}
                <div className='relative flex-auto px-10 py-10'>
                  <div className='mb-5 text-lg leading-relaxed text-sub-500'>
                    <img
                      alt=''
                      src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/72d3dfe3-0fb1-4f7e-8199-36609533983c.png'
                    />
                  </div>
                  <p>
                    주변에 올웨이즈를 알려주시면{' '}
                    <span className='font-bold text-blue-500'>광고 크레딧</span>
                    을 드려요.
                  </p>
                  <p className='mb-5'>
                    다음 방법으로 간단하게 참여하실 수 있어요!
                  </p>
                  <p>
                    1.{' '}
                    <span className='font-bold text-blue-500'>
                      홍보 주소 복사하기
                    </span>
                    버튼을 클릭해요.
                  </p>
                  <p>
                    2. 홍보 주소로{' '}
                    <span className='font-bold text-blue-500'>유효 셀러</span>가
                    가입해요.
                    <Tooltip
                      text={`
                    💡유효 셀러는 1) 정상적인 사업자 번호를 가진 셀러면서<br/>
                    2) 가입 후 1주 이상 판매 활동을 지속하는 셀러를 말해요!`}
                    />
                  </p>
                  <p>
                    3. 셀러 한 명 당{' '}
                    <span className='font-bold text-blue-500'>50,000원</span>의
                    광고 크레딧을 지급받아요.
                  </p>
                  <p className='mt-5 text-sm'>
                    * 무료 광고 크레딧 지급은 매주 금요일에 진행될 예정입니다.
                  </p>
                  <p className='text-sm'>
                    * 지급액은 유효셀러 기준으로 산정됩니다.
                  </p>
                </div>
                {/* footer */}
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <Button
                    appearance='blue'
                    onClick={() => {
                      handleCopyClick(sellerViralUrl)
                    }}
                  >
                    홍보 주소 복사하기
                  </Button>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}
export default SellerViralEngine

import React from 'react'

const Gauge = ({
  level = 3,
  totalLevel = 3,
  color,
  greenLev = 2,
  blueLev = 3,
}) => {
  const getGaugeColor = (level, greenLev = 2, blueLev = 3) => {
    if (level >= blueLev) {
      return 'bg-blue-500'
    }
    if (level >= greenLev) {
      return 'bg-green-500'
    }
    return 'bg-yellow-500'
  }

  return (
    <div className='flex flex-1 w-40 h-5 bg-gray-200 rounded-md'>
      <div
        className={`relative flex h-5 text-xs text-center text-white duration-300 ease-in-out ${
          color || getGaugeColor(level, greenLev, blueLev)
        } rounded-md`}
        style={{
          width: `${(level / totalLevel) * 100}%`,
        }}
      />
    </div>
  )
}

export default Gauge

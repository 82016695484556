import React, { useState } from 'react'

const Tabs = ({ tabContArr, callbackFunction = () => {} }) => {
  const [openTab, setOpenTab] = useState(0)
  return (
    <>
      <ul className='flex flex-wrap py-5 mb-2 text-lg font-medium text-center text-gray-500 border-b'>
        {tabContArr.map((tab, index) => (
          <li className='mr-2' key={tab?.title + index?.toString()}>
            <button
              type='button'
              className={`inline-block py-3 px-4 rounded-lg ${
                openTab === index
                  ? 'text-white bg-blue-600 active'
                  : 'hover:text-gray-900 hover:bg-gray-200'
              }`}
              onClick={() => {
                setOpenTab(index)
                callbackFunction(index)
              }}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
      <div>{tabContArr[openTab].content}</div>
    </>
  )
}

export default Tabs

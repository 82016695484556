import React from 'react'
import {
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsRight,
} from 'react-icons/fi'

const Pagination = ({ total, limit, page, setPage, size = 'md' }) => {
  const numPages = Math.ceil(total / limit) || 1
  const styleBySize = {
    md: 'mx-1 text-md font-normal',
    lg: 'mx-1 text-lg font-normal',
  }
  const firstNum = Math.floor(page / 5) * 5 + 1
  const lastNum = Math.floor(page / 5) * 5 + 5

  return (
    <>
      <div className='px-5 py-5 flex flex-col xs:flex-row items-start xs:justify-between'>
        <div className='flex overflow-x-auto'>
          <button
            type='button'
            onClick={() => {
              setPage(page - 5)
            }}
            disabled={page < 5}
            className={[
              page < 5
                ? 'hover:none bg-gray-50 text-gray-300'
                : 'hover:bg-gray-100 text-gray-600',
              'w-full p-4 border text-base rounded-l-xl  bg-white',
            ].join(' ')}
          >
            <FiChevronsLeft />
          </button>
          <button
            type='button'
            onClick={() => {
              setPage(page - 1)
            }}
            disabled={page === 0}
            className={[
              page === 0
                ? 'hover:none bg-gray-50 text-gray-300'
                : 'hover:bg-gray-100 text-gray-600',
              'w-full p-4 border-r border-y text-base bg-white',
            ].join(' ')}
          >
            <FiChevronLeft />
          </button>

          {Array(numPages)
            .fill()
            .map(
              (_, i) =>
                i + 1 >= firstNum &&
                i + 1 <= lastNum && (
                  <button
                    type='button'
                    key={_ + i.toString()}
                    onClick={() => setPage(i)}
                    className={[
                      page === i ? 'text-indigo-500' : '',
                      'w-full px-4 py-2 border-t border-b text-base text-gray-600 bg-white hover:bg-gray-100 ',
                    ].join(' ')}
                  >
                    <div className={`${styleBySize[size]}`}>{i + 1}</div>
                  </button>
                ),
            )}

          <button
            type='button'
            onClick={() => {
              setPage(page + 1)
            }}
            disabled={page === numPages - 1}
            className={[
              page === numPages - 1
                ? 'hover:none bg-gray-50 text-gray-300'
                : 'hover:bg-gray-100 text-gray-600',
              'w-full p-4 border-l border-y text-base bg-white',
            ].join(' ')}
          >
            <FiChevronRight />
          </button>
          <button
            type='button'
            onClick={() => {
              setPage(page + 5)
            }}
            disabled={page >= numPages - 5}
            className={[
              page >= numPages - 5
                ? 'hover:none bg-gray-50 text-gray-300'
                : 'hover:bg-gray-100 text-gray-600',
              'w-full p-4 border text-base  rounded-r-xl bg-white',
            ].join(' ')}
          >
            <FiChevronsRight />
          </button>
        </div>
      </div>
    </>
  )
}

export default Pagination

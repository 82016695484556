import { useState } from 'react'
import { Route, Switch, Link } from 'react-router-dom'
import Button from './button'
import New from '../../images/new.png'
import Ping from './ping'
import Log from '../../utils/log'

export default function TutorialModal({
  text,
  modalTitle,
  imageSource = false,
  videoSource,
  modalContent,
  isButton = false,
  buttonText,
  buttonLink,
  children,
  modalLog,
}) {
  const [noticeOpened, setNoticeOpened] = useState(false)
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div className='ml-2'>
        <button
          className={noticeOpened ? ' text-gray-500' : null}
          type='button'
          onClick={() => {
            Log.event(modalLog, modalLog, {
              click: modalLog,
            })
            setShowModal(true)
            setNoticeOpened(true)
          }}
        >
          {children}
        </button>
      </div>
      {showModal ? (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none '>
            <div className='relative w-full max-w-xl mx-auto my-6'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-4 border-b border-solid rounded-t border-slate-200'>
                  <h3 className='text-xl font-semibold'>{modalTitle}</h3>
                </div>
                {/* body */}
                <div className='relative flex-auto p-6'>
                  <p className='my-4 text-lg leading-relaxed text-slate-500'>
                    <video autoPlay muted loop src={videoSource} />
                  </p>
                  {imageSource ? (
                    <p className='my-4 text-lg leading-relaxed text-slate-500'>
                      <img src={imageSource} alt='tutorial explanation' />
                    </p>
                  ) : (
                    <></>
                  )}

                  <p className='my-4 text-lg leading-relaxed text-slate-500'>
                    {modalContent}
                  </p>
                </div>

                {/* footer */}
                <div className='flex items-center justify-end px-4 py-1.5 border-t border-solid rounded-b border-slate-200'>
                  {isButton ? (
                    <Link exact to={buttonLink}>
                      <button
                        className='px-6 py-2 mb-1 mr-1 text-lg font-bold text-white uppercase transition-all duration-150 ease-linear rounded-lg shadow-sm bg-theme-500 border-r-xl'
                        type='button'
                      >
                        {buttonText}
                      </button>
                    </Link>
                  ) : (
                    <></>
                  )}
                  <button
                    className='px-6 py-2 mb-1 mr-1 text-lg font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      ) : null}
    </>
  )
}

import React, { useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import Button from '../atoms/button'
import SellerStore from '../../stores/SellerStore'
import { GrClose as CloseIcon } from 'react-icons/gr'
import Table from '../ADS/molecules/table'
import { FcSurvey } from 'react-icons/fc'

const TrendKeywordModal = observer(({ showModal, setShowModal }) => {
  const [keywords, setKeywords] = useState([])

  const cleanUp = () => {
    setKeywords([])
  }

  const columns = useMemo(
    () => [
      { Header: '키워드', accessor: 'keyword' },
      { Header: '검색량', accessor: 'searchCount' },
    ],
    [],
  )

  const keywordData = useMemo(
    () =>
      keywords.map((item, idx) => {
        return {
          keyword: (
            <div className='flex text-left'>
              <div className='inline-block w-6 mr-10 font-bold text-right contents-start'>
                {idx + 1}
              </div>
              <div className='flex flex-1 whitespace-normal'>
                {item?.keyword}
              </div>
            </div>
          ),
          searchCount: (
            <div className='w-40 h-4 bg-gray-300 rounded-lg'>
              <div
                className={`w-${item?.searchCount}/5 h-full rounded-lg
                  bg-gradient-to-r from-emerald-500 from-10% via-sky-500 via-30% to-indigo-500 to-90%`}
              />
            </div>
          ),
        }
      }),
    [keywords],
  )

  const getKeywords = async () => {
    const result = await backendApis.getTrendKeywords()
    if (result?.status === 200) {
      setKeywords([...result?.data])
    } else {
      setKeywords([])
    }
  }

  useEffect(() => {
    if (!showModal || SellerStore?.isLoading) return
    SellerStore.setIsLoading(true)
    getKeywords()
    SellerStore.setIsLoading(false)
  }, [showModal])

  return (
    <>
      {showModal ? (
        <>
          <div className='fixed inset-0 z-50 flex flex-col w-full max-w-xl max-h-screen mx-auto my-auto overflow-y-scroll bg-white border-0 rounded-lg shadow-lg outline-none h-fit'>
            {/* header */}
            <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
              <h3 className='text-xl font-semibold text-black'>
                올웨이즈 검색어 트렌드
              </h3>
              <Button
                appearance='transparent'
                className='py-3 px-3 my-[-6px] rounded-md hover:bg-gray-100'
                onClick={() => {
                  cleanUp()
                  setShowModal(false)
                  SellerStore.setIsLoading(false)
                }}
              >
                <CloseIcon className='w-4 h-4 ' />
              </Button>
            </div>

            {/* body */}
            <div className='flex flex-col px-10 py-5'>
              <div className='flex flex-row items-center justify-start flex-1 p-5 mb-2 bg-slate-100 rounded-xl'>
                <FcSurvey className='mr-3 w-7 h-7' />
                <div className='flex flex-col items-start'>
                  올웨이즈의 최근 검색어 트렌드와 순위입니다.
                </div>
              </div>
              <div className='p-0.5 overflow-y-scroll overflow-x-hidden max-h-[42rem]'>
                <Table columns={columns} data={keywordData} />
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      ) : (
        <></>
      )}
    </>
  )
})
export default TrendKeywordModal

class BusinessInfoParser {
  parseBusinessRegistrationRepName = (repName) => {
    if (!repName) return ''

    let parsedRepName = repName.trim()

    // 대표자가 여러명인 경우: "홍길동외 2명" -> "홍길동"
    if (parsedRepName.includes('외')) {
      parsedRepName = parsedRepName.replace(/\s?외\s?\d\s?명/, '').trim()
    }

    // 괄호가 포함된 경우: "Apple House(애플 하우스)" -> "Apple House"
    if (parsedRepName.includes('(')) {
      parsedRepName = parsedRepName.split('(')[0].trim()
    }

    // 영어와 한글이 섞여있는 경우: "HUAN MEY 대표" -> "HUAN MEY"
    if (/[a-zA-Z]/.test(parsedRepName) && /[가-힣]/.test(parsedRepName)) {
      parsedRepName = parsedRepName.replace(/[가-힣]+/g, '').trim()
    }

    // 이름 뒤에 공백+한글문자열 패턴 제거: "홍길동 대표", "홍길동 사업자" -> "홍길동"
    if (/\s[가-힣]+$/.test(parsedRepName)) {
      parsedRepName = parsedRepName.replace(/\s[가-힣]+$/, '').trim()
    }

    return parsedRepName
  }

  parseBusinessRegistrationOpenDate = (openDate) => {
    if (!openDate) return ''

    const parsedOpenDate = openDate.replace(/[^0-9]/g, '').trim()

    return parsedOpenDate
  }

  parseBusinessRegistrationNumber = (businessRegistrationNumber) => {
    if (!businessRegistrationNumber) return ''

    const parsedBusinessRegistrationNumber = businessRegistrationNumber
      .replace(/[^0-9]/g, '')
      .trim()

    return parsedBusinessRegistrationNumber
  }

  // 개인사업자, 법인사업자 분류하는 함수
  parseBusinessType = (businessRegistrationNumber) => {
    if (!businessRegistrationNumber) return ''

    const businessTypeCode = businessRegistrationNumber.match(
      /^\d{3}-(\d{2})-\d{5}$/,
    )[1]

    // 사업자 유형 분류
    if (businessTypeCode >= '01' && businessTypeCode <= '79') {
      return {
        mainType: '개인',
        subType: '개인과세사업자',
      }
    }

    if (businessTypeCode >= '90' && businessTypeCode <= '99') {
      return {
        mainType: '개인',
        subType: '개인면세사업자',
      }
    }

    switch (businessTypeCode) {
      case '89':
        return {
          mainType: '개인',
          subType: '법인이 아닌 종교단체',
        }
      case '80':
        return {
          mainType: '개인',
          subType: '아파트관리사무소/다단계판매원',
        }
      case '81':
      case '86':
      case '87':
      case '88':
        return {
          mainType: '법인',
          subType: '영리법인본점',
        }
      case '85':
        return {
          mainType: '법인',
          subType: '영리법인지점',
        }
      case '82':
        return {
          mainType: '법인',
          subType: '비영리법인본점/지점',
        }
      case '83':
        return {
          mainType: '법인',
          subType: '국가/지자체/지자체조합',
        }
      case '84':
        return {
          mainType: '법인',
          subType: '외국법인본점/지점/연락사무소',
        }
      default:
        return {
          mainType: '',
          subType: '',
        }
    }
  }
}

export default new BusinessInfoParser()

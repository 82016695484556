import React from 'react'
import { Radio } from 'antd'
import styled from 'styled-components'

const StyledRadioGroup = styled(Radio.Group)`
  position: relative;
  background-color: #f6f6fa;
  border-radius: 0.75rem;
  border: 1px solid #e2e2e7;

  .ant-radio-button-wrapper {
    border: none !important;
    background: transparent !important;
    color: #888;
    transition: color 0.3s ease;
    z-index: 1;

    &:before {
      display: none;
    }

    &-checked {
      color: #fff;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc((100% - 8px) / ${(props) => props.optionCount});
    height: calc(100% - 8px);
    background-color: #3b82f6;
    border-radius: 0.5rem;
    transition: 0.3s ease;
    transform: translateX(${(props) => props.selectedIndex * 100}%);
  }
`

const RadioGroup = ({ options = [], value, onChange }) => {
  const selectedIndex = options.findIndex((option) => option.value === value)

  return (
    <StyledRadioGroup
      value={value}
      onChange={(e) => onChange(e)}
      optionType='button'
      buttonStyle='solid'
      className='flex h-12 w-full flex-1 items-center justify-center'
      optionCount={options.length}
      selectedIndex={selectedIndex}
    >
      {options.map((option) => (
        <Radio.Button
          key={option.value}
          value={option.value}
          className='flex-1 flex items-center justify-center px-12'
        >
          {option.label}
        </Radio.Button>
      ))}
    </StyledRadioGroup>
  )
}

export default RadioGroup

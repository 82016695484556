import React, { useState } from 'react'
import commaNumber from 'comma-number'
import Modal from 'react-modal'
import Button from '../components/atoms/button'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import Log from '../utils/log'
import ItemStore from '../stores/ItemStore'

const ReviewCouponRegisterModal = ({
  showModal,
  setShowModal,
  item,
  itemId,
  setItem,
  data,
  setData,
}) => {
  const [value, setValue] = useState(5000)
  const [stockNumber, setStockNumber] = useState(10)
  const onClick = async () => {
    if (window.confirm(`${commaNumber(value)}원으로 신청하시겠어요?`)) {
      const result = await backendApis.setReviewEventRegistered(
        item._id,
        stockNumber,
        `${value}`,
      )
      if (result.status === 200) {
        alert(
          '신청 완료되었습니다. 단순 변심으로 인한 취소는 불가하며, 기타 문의 사항은 판매자 지원센터로 해주세요.',
        )
        setShowModal(false)
        setItem({})
        setValue(5000)
        const dataTemp = data?.participated || []
        dataTemp.push({
          ...item,
          reviewEventStockNumber: stockNumber,
          reviewEventCouponValue: value,
        })
        setData({
          available: data?.available.filter((each) => each._id !== item._id),
          participated: dataTemp,
        })
      }
      if (SellerStore.loadSellerInfo())
        Log.event('ReviewEventRegisterButton', 'ReviewEventRegisterButton', {
          click: 'ReviewEventRegisterButton',
        })
    }
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          setItem({})
          setValue(5000)
          setShowModal(false)
        }}
        style={{
          overlay: {
            zIndex: 20,
          },
          content: {
            left: '20%',
            right: '20%',
            top: '20%',
            bottom: '20%',
            padding: 40,
          },
        }}
      >
        <div className='text-lg'>
          * 후기 10개가 쌓이면{' '}
          <span className='text-theme-500'>판매량이 52% 증가</span>해요.
        </div>
        <div className='text-lg'>
          * 금액을 바꿔서 재신청하시는 경우 기존에 구매하셨던 유저들에게도 해당
          금액이 적용될 수 있습니다.
        </div>

        <div className='h-10' />
        <div className='flex flex-col items-center'>
          <div className='text-xl font-bold'>상품명: {item.itemTitle}</div>
          <div className='h-10' />
          <div>
            <select
              className='w-60 h-16 text-xl'
              name='value'
              type='number'
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
            >
              {[5000, 10000].map((each) => (
                <option key={each} value={each}>
                  {commaNumber(each)}원
                </option>
              ))}
            </select>
            <Button className='ml-10 w-30 h-16' onClick={onClick}>
              신청하기
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default ReviewCouponRegisterModal

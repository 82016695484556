import React from 'react'
import Card from '../../atoms/card'
import SubTitle from '../../atoms/subTitle'

const RegisterItemRecommendCard = ({
  recommendItemCode,
  setRecommendItemCode,
}) => {
  return (
    <Card title='상품 추천 코드'>
      <div>
        <div className='flex items-center mb-5 '>
          <SubTitle>상품 추천 코드를 입력해주세요</SubTitle>
        </div>

        <div className='mt-5 '>상품 추천 코드</div>
        <input
          className='w-full px-2 py-2 my-3 bg-white border-2 rounded-md border-sub-500'
          placeholder='상품 추천 코드를 입력해주세요'
          value={recommendItemCode}
          onChange={(e) => {
            console.log('e.target.value in Register: ', e.target.value)
            setRecommendItemCode(e.target.value)
          }}
        />
      </div>
    </Card>
  )
}

export default RegisterItemRecommendCard

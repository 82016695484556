import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import moment from 'moment'
import Button from '../atoms/button'

const ShippingSystemChangeAlert = observer(() => {
  const [showAlertModal, setShowAlertModal] = useState(false)
  const SELLER_PENALTY_URL =
    'https://levitinc.notion.site/54e4c8088c644b39b491502aebc450c8'

  useEffect(() => {
    const opendAt = window.localStorage.getItem('ShippingSystemOpenedAt')
    const now = moment()
    if (now.isBefore(moment('2024-02-01')) && !opendAt) {
      window.localStorage.setItem(
        'ShippingSystemOpenedAt',
        new Date().toISOString(),
      )
      setShowAlertModal(true)
    }
  }, [SellerStore?.sellerInfo?._id])

  return (
    <>
      {showAlertModal && (
        <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
          <div
            className='relative z-50'
            aria-labelledby='alert-modal-title'
            role='dialog'
            aria-modal='true'
          >
            <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
                <div className='relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-2/3 sm:max-w-4x1'>
                  <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                    <div className='sm:flex sm:items-start'>
                      {/* Alert Title */}
                      <div className='flex-1 mt-3 sm:ml-4 sm:mt-0 sm:text-left'>
                        <div className='flex flex-row items-center justify-between h-10'>
                          <div
                            className='text-xl font-semibold leading-6 text-gray-900'
                            id='alert-modal-title'
                          >
                            배송 페널티 정책 변경 안내
                          </div>
                        </div>
                        {/* Alert Body(Children) */}

                        <div className='pr-3 mt-2 mb-8 text-gray-500 text-md'>
                          고객들에게 좋은 배송 서비스를 제공해주셔서 감사합니다.
                          <br />
                          2024년 2월 1일부터 배송관련 페널티 정책 및 시스템이
                          변경됩니다.
                          <br />
                          <br />
                          <br />
                          [배송 페널티 변경 사항]
                          <br />
                          <br />
                          1. 발송처리기한 변경
                          <br />
                          - 주문성사일로부터 3영업일 내 발송처리
                          <br />
                          - 3영업일 내 발송처리하지 않을 경우 발송지연 페널티
                          부과
                          <br />
                          - 2월 1일부터 '배송예정일'은 페널티에 반영되지
                          않습니다
                          <br />
                          <br />
                          2. 발송지연처리 기능 도입
                          <br />
                          - 발송지연처리 기능이 도입 되었습니다
                          <br />
                          - 3영업일 내 발송처리가 어려운 주문건의 경우, 발송지연
                          기능을 통해 구매자에게 미리 안내해주세요.
                          <br />- 발송지연처리된 주문건의 경우 [지연발송일]
                          기준으로 페널티 정책이 적용됩니다.
                          <br />
                          <br />
                          <br />
                          [배송 만족도 변경 사항]
                          <br />
                          <br />
                          (1) 발송처리기한 내 발송 여부
                          <br />
                          (2) 1영업일 내 발송 여부
                          <br />
                          (3) 당일 발송 여부
                          <br />
                          <br />
                          위 세 가지 지표를 기준으로 배송 만족도가 계산됩니다.
                          <br />
                          배송 만족도는 판매자분의 상품 노출도에 반영됩니다.
                          <br />
                          정시에 발송할수록, 보다 빠르게 발송할수록 배송 점수가
                          향상됩니다.
                          <br />
                          <br />
                          <br />
                          [배송 및 페널티 제도 변경 적용]
                          <br />
                          <br />
                          - 2024년 1월 22일 ~ 2024년 1월 31일까지 계도기간이
                          적용됩니다.
                          <br />
                          - 2024년 2월 1일부터 기존 페널티는 모두 초기화되며,
                          새로운 정책이 적용됩니다.
                          <br />- 계도기간 동안 새로운 발송정책을 충분히
                          숙지해주시고, 고객에게 보다 나은 배송 경험을
                          제공해주세요.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='px-4 py-3 bg-gray-50 sm:flex sm:px-6 sm:justify-between'>
                    <div>
                      <Button
                        type='button'
                        appearance='outline'
                        onClick={() => {
                          setShowAlertModal(false)
                        }}
                      >
                        <div className='px-4 py-1 text-slate-500'>닫기</div>
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={async () => {
                          window.open(SELLER_PENALTY_URL, '_blank')
                          setShowAlertModal(false)
                        }}
                      >
                        <div className='px-4 py-1'>
                          변경된 제도 상세내용 확인하기
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
})

export default ShippingSystemChangeAlert

import React, { useState, useEffect } from 'react'
import { Modal, List, Button, Pagination } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

const SelectedItemsModal = ({
  visible,
  onCancel,
  selectedItems,
  onItemRemove,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 5 // 한 페이지에 표시할 아이템 수

  useEffect(() => {
    if (!visible) {
      setCurrentPage(1)
    }
  }, [visible])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const paginatedItems = selectedItems.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  )

  return (
    <Modal
      title='선택된 상품 목록'
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={600}
    >
      <List
        itemLayout='horizontal'
        dataSource={paginatedItems}
        renderItem={(item) => (
          <List.Item
            actions={[
              selectedItems.length > 0 && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => onItemRemove(item._id)}
                  type='text'
                  style={{ color: '#9ca3af' }}
                />
              ),
            ]}
          >
            <List.Item.Meta
              avatar={
                <img
                  src={item.mainImageUri}
                  alt={item.title}
                  style={{ width: 50, height: 50, objectFit: 'cover' }}
                />
              }
              title={item.title}
              description={`${item.teamPurchasePrice.toLocaleString()}원`}
            />
          </List.Item>
        )}
      />
      <Pagination
        current={currentPage}
        total={selectedItems.length}
        pageSize={pageSize}
        onChange={handlePageChange}
        style={{ marginTop: '20px', textAlign: 'center' }}
        showSizeChanger={false}
      />
    </Modal>
  )
}

export default SelectedItemsModal

import moment from 'moment'

const FastShippingOrderMent = ({
  isFastShippingItem = false,
  preShippingTimeStamp = false,
}) => {
  let ment = '-'
  if (
    !(
      isFastShippingItem && moment(preShippingTimeStamp) > moment('2022-08-30')
    ) ||
    moment(preShippingTimeStamp) > moment('2023-01-08')
  ) {
    ment = '-'
  } else if (moment(preShippingTimeStamp).hour() < 16) {
    ment = `${moment(preShippingTimeStamp).format('MM/DD')} 24시 전 발송 필수`
  } else {
    ment = `${moment(preShippingTimeStamp)
      .add('days', 1)
      .format('MM/DD')} 16시 전 발송 필수`
  }
  return ment
}

export default FastShippingOrderMent

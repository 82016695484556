import React, { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Row,
  Tag,
  Modal,
  Divider,
  Button,
  Tooltip,
  message,
  Typography,
  Checkbox,
} from 'antd'
import backendApis from '../../../utils/backendApis'
import { debounce } from 'lodash'
import OneClickBiddingModal from './oneClickBiddingModal'

const { confirm } = Modal
const { Text } = Typography

const tagColors = {
  '브랜드 카탈로그': 'blue',
  '원물 카탈로그': 'blue',
  고효율: 'green',
  노출제한: 'red',
  default: 'blue', // Fallback color for any tags not listed
}

const tagDescription = {
  '브랜드 카탈로그': '브랜드 카탈로그 연동 옵션입니다.',
  '원물 카탈로그': '원물 카탈로그 연동 옵션입니다.',
  고효율: '적은 할인폭으로 많은 매출을 낼 수 있는 옵션입니다.',
  노출제한:
    '1위 옵션이지만, 외부 최저가 대비 입찰가가 높아 노출이 제한된 옵션입니다.',
}

const filterUnique = (options, keys) => {
  return options.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) =>
        keys.every((key) => t[key]?.toString() === value[key]?.toString()),
      ),
  )
}

const OptionBiddingDataPanelSection = ({ condition = {} }) => {
  const [submitToggle, setSubmitToggle] = useState(false)
  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [modalState, setModalState] = useState({
    oneClickBiddingModalVisible: false,
  })
  const status = condition?.tab // 'losingOption', 'winningOption', 'inspectingOption'

  const apiGroups = {
    unitOption: {
      updateBiddingInfo: backendApis.updateUnitOptionBiddingInfo,
    },
    generalOption: {
      updateBiddingInfo: backendApis.updateGeneralOptionBiddingInfo,
    },
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const unitOptionResponse =
          await backendApis.getCurrentUnitOptionBiddingData({ status })
        const generalOptionResponse =
          await backendApis.getCurrentGeneralOptionBiddingData({ status })

        const unitOptions = Array.isArray(unitOptionResponse?.data)
          ? unitOptionResponse.data
          : []
        const generalOptions = Array.isArray(generalOptionResponse?.data)
          ? generalOptionResponse.data
          : []
        if (unitOptions.length === 0 && generalOptions.length === 0) {
          setItems([])
        } else {
          const uniqueUnitOptions = filterUnique(unitOptions, [
            'unitOptionId',
            'catalogTitle',
            'itemId',
            'optionId',
          ])
          const uniqueGeneralOptions = filterUnique(generalOptions, [
            'generalOptionId',
            'catalogTitle',
            'itemId',
            'optionId',
          ])

          const finArr = [...uniqueUnitOptions, ...uniqueGeneralOptions]

          setItems(
            finArr?.filter(
              (item) =>
                item?.minimumBiddingPrice && item?.currentLowestBiddingPrice,
            ),
          )
        }
      } catch (error) {
        setItems([])
      }
    }
    fetchData()
  }, [submitToggle])

  const onCheckboxChange = debounce((item) => {
    setSelectedItems((prevItems) => {
      if (prevItems.includes(item)) {
        return prevItems.filter((i) => i !== item)
      }
      return [...prevItems, item]
    })
  }, 10)

  const onBulkSubmit = () => {
    setSubmitToggle(!submitToggle)
  }

  const showModal = () => {
    setModalState((prevState) => ({
      ...prevState,
      oneClickBiddingModalVisible: true,
    }))
  }

  const onAgreeButtonClick = (item) => {
    const biddingPrice = item?.minimumBiddingPrice
    const projectedGMV = item?.projectedGMV
    const itemId = item?.itemId
    const optionId = item?.optionId
    const biddingOptionId = item?.unitOptionId || item?.generalOptionId
    const values = {
      biddingType: 'fixed',
      biddingPrice,
      minBiddingPrice: biddingPrice,
    }

    confirm({
      title: '입찰가 설정',
      content: (
        <div>
          <p>
            <strong>고정 입찰가</strong>를{' '}
            <strong>{biddingPrice?.toLocaleString()}원</strong>으로
            설정하시겠습니까?
          </p>
          <p>
            입찰 시 예상 매출은{' '}
            <strong>{projectedGMV?.toLocaleString()}원</strong>입니다.
          </p>
          <p>설정 후에는 변경이 불가능합니다.</p>
        </div>
      ),
      onOk: async () => {
        const response = await apiGroups[item?.type].updateBiddingInfo({
          biddingOptionId,
          itemId,
          optionId,
          values,
          isReclaimed: true,
        })
        if (response?.status === 200) {
          setSubmitToggle(!submitToggle)
          message.success('입찰이 성공적으로 완료되었습니다.')
        } else {
          message.error('입찰에 실패했습니다. 다시 시도해주세요.')
        }
      },
      okButtonProps: { className: 'bg-blue-500' },
      okText: '입찰하기',
      onCancel() {
        console.log('Cancel')
      },
      cancelText: '취소',
    })
  }

  return (
    <>
      {items.length > 0 ? (
        <>
          <Button
            type='default'
            onClick={() => {
              if (selectedItems.length === items.length) {
                setSelectedItems([]) // deselect all items
              } else {
                setSelectedItems(items) // select all items
              }
            }}
            className='bg-white mb-5 mr-2'
            disabled={items.length === 0} // Disable the button when there are no items
          >
            {selectedItems.length === items.length
              ? '전체 해제하기'
              : '전체 선택하기'}
          </Button>

          {selectedItems?.length > 0 && (
            <Button
              type='primary'
              onClick={showModal}
              style={{ marginBottom: '16px', backgroundColor: '#52c41a' }}
            >
              선택한 상품 모두 입찰하기
            </Button>
          )}

          <Row gutter={[16, 16]}>
            {items?.map((item, idx) => (
              <Col
                key={item?.itemId?.toString() + idx?.toString()}
                xs={24}
                sm={12}
                md={12}
                lg={6}
              >
                <Card style={{ position: 'relative', height: '280px' }}>
                  <Row
                    gutter={[8, 8]}
                    style={{ height: '80px' }} // Set a fixed height for the part above the divider
                  >
                    <Checkbox
                      checked={selectedItems.includes(item)}
                      onChange={(e) => {
                        e.stopPropagation()
                        onCheckboxChange(item)
                      }}
                      style={{
                        position: 'absolute',
                        top: 12,
                        left: 12,
                        zIndex: 1,
                      }}
                    />
                    <Col
                      span={6}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', // Ensure image stays centered
                        height: '100%', // Make the image container fill the height
                      }}
                    >
                      <img
                        alt={item?.itemTitle}
                        src={item?.imageUrl}
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: '4px',
                          aspectRatio: '1/1',
                          objectFit: 'cover',
                        }}
                      />
                    </Col>
                    <Col
                      span={18}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center', // Vertically center the content
                        height: '100%',
                      }}
                    >
                      <h4
                        style={{
                          fontSize: '0.9rem',
                          marginBottom: '4px',
                          height: '2.4em',
                          lineHeight: '1.2em',
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {item?.itemTitle}
                      </h4>
                      <h5
                        style={{
                          color: 'gray',
                          margin: 0,
                          fontSize: '0.7rem',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {item?.catalogTitle}
                      </h5>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <div>
                        {item?.tags?.map((tag) => (
                          <Tooltip key={tag} title={tagDescription[tag]}>
                            <Tag
                              color={tagColors[tag] || tagColors.default}
                              style={{ marginTop: '8px' }}
                            >
                              {tag}
                            </Tag>
                          </Tooltip>
                        ))}
                      </div>
                      <Divider style={{ margin: '12px 0' }} />
                    </Col>
                  </Row>
                  <Row justify='space-between'>
                    <Col span={16}>
                      <div>
                        <Row className='text-slate-600'>
                          <Col span={11}>
                            <Text strong className='text-slate-500'>
                              최저입찰가
                            </Text>
                          </Col>
                          <Col span={13} style={{ textAlign: 'right' }}>
                            <Text className='text-slate-500'>
                              {item?.currentLowestBiddingPrice?.toLocaleString() ||
                                '-'}
                              원
                            </Text>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={11}>
                            <Text strong className='text-slate-500'>
                              외부최저가
                            </Text>
                          </Col>
                          <Col span={13} style={{ textAlign: 'right' }}>
                            <Text className='text-slate-500'>
                              {item?.externalPrice?.toLocaleString() ||
                                // item?.currentLowestBiddingPrice?.toLocaleString() ||
                                '-'}
                              원
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={11}>
                            <Text strong className='text-slate-500'>
                              현재 입찰가
                            </Text>
                          </Col>
                          <Col span={13} style={{ textAlign: 'right' }}>
                            <Text className='text-slate-500'>
                              {item?.biddingPrice?.toLocaleString() || '-'}원
                            </Text>
                          </Col>
                        </Row>
                        <Row className='mt-5'>
                          <Col span={12}>
                            <Text strong className='text-blue-500'>
                              추가 일매출
                            </Text>
                          </Col>
                          <Col span={12} style={{ textAlign: 'right' }}>
                            <Text className='text-blue-500 font-bold'>
                              {item?.projectedGMV?.toLocaleString() || '-'}원
                            </Text>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col span={4}>
                      <div className='flex flex-col flex-1 items-end justify-end h-full w-full'>
                        {/* <Tooltip title='데이터 상세보기'>
                      <Button
                        type='default'
                        shape='circle'
                        icon={<BarChartOutlined />}
                        style={{ marginBottom: '12px' }}
                      />
                    </Tooltip> */}
                        <Tooltip
                          title={`${
                            item?.minimumBiddingPrice?.toLocaleString() || '-'
                          }원으로 1위 탈환하기`}
                        >
                          <Button
                            // className='bg-blue-500'
                            // type='primary'

                            onClick={() => {
                              onAgreeButtonClick(item)
                            }}
                          >
                            1위 탈환
                          </Button>
                        </Tooltip>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            1위 탈환할 수 있는 상품이 없습니다.
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            1위 탈환에서는 현재 입찰가가 1위가 아닌 상품들을 한번에 확인하고
            입찰할 수 있어요.
          </div>
        </>
      )}

      {modalState.oneClickBiddingModalVisible && (
        <OneClickBiddingModal
          selectedItems={selectedItems}
          setIsModalVisible={setModalState}
          isVisible={modalState.oneClickBiddingModalVisible}
          onSubmit={onBulkSubmit}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              oneClickBiddingModalVisible: false,
            }))
          }
        />
      )}
    </>
  )
}

export default OptionBiddingDataPanelSection

import { useEffect } from 'react'

const useKeyEscClose = (closeFunction) => {
  useEffect(() => {
    const escKeyModalClose = (e) => {
      if (e.keyCode === 27) {
        closeFunction()
      }
    }
    window.addEventListener('keydown', escKeyModalClose)
    return () => window.removeEventListener('keydown', escKeyModalClose)
  }, [])
}

export default useKeyEscClose

import React from 'react'
import { Card, Image, Typography, Badge } from 'antd'
import styled from 'styled-components'

const { Title, Text } = Typography

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding: ${(props) => (props.isSelected ? '0' : '1px')};
  border: ${(props) =>
    props.isSelected ? '2px solid #1890ff' : '1px solid #e8e8e8'};
`

const ImageContainer = styled.div`
  height: 200px;
  overflow: hidden;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ContentContainer = styled.div`
  padding: 12px;
`

const ItemTitle = styled(Title)`
  font-size: 16px !important;
  margin-bottom: 12px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
`

const SquareItemCard = ({ itemInfo, isSelected, onSelect }) => {
  const {
    _id,
    mainImageUris,
    itemTitle,
    teamPurchasePrice,
    individualPurchasePrice,
  } = itemInfo

  const itemImage = mainImageUris[0]

  return (
    <Badge.Ribbon
      text='선택됨'
      color='#1890ff'
      style={{ display: isSelected ? 'block' : 'none' }}
    >
      <StyledCard
        className='cursor-pointer flex flex-col flex-1'
        onClick={onSelect}
        isSelected={isSelected}
      >
        <ImageContainer>
          <StyledImage preview={false} src={itemImage} alt={itemTitle} />
        </ImageContainer>
        <ContentContainer>
          <ItemTitle level={4}>{itemTitle}</ItemTitle>
          <PriceContainer>
            <Text type='secondary'>개별 구매가</Text>
            <Text strong>{individualPurchasePrice.toLocaleString()}원</Text>
          </PriceContainer>
          <PriceContainer>
            <Text type='secondary'>팀 구매가</Text>
            <Text strong style={{ color: '#1890ff' }}>
              {teamPurchasePrice.toLocaleString()}원
            </Text>
          </PriceContainer>
        </ContentContainer>
      </StyledCard>
    </Badge.Ribbon>
  )
}

export default SquareItemCard

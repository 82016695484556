import { useForm } from 'react-hook-form'
import React, { useState } from 'react'
import backendApis from '../../utils/backendApis'
import Button from '../atoms/button'

const CreditCardRegister = ({
  setBasicInfo = () => {},
  setIsRegister = () => {},
}) => {
  const getAdCreditInfo = async () => {
    const result = await backendApis.getAdCreditInfo()
    if (result?.status === 2000) {
      setBasicInfo(result?.data)
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isBusinessCard, setIsBusinessCard] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  return (
    <>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center justify-center flex-1 mb-5'>
          <label htmlFor='business'>
            <input
              type='radio'
              id='business'
              checked={isBusinessCard}
              onChange={() => {
                setIsBusinessCard(true)
              }}
            />
            <span className='ml-2 mr-5'>사업자</span>
          </label>

          <label htmlFor='personal'>
            <input
              type='radio'
              id='personal'
              checked={!isBusinessCard}
              onChange={() => {
                setIsBusinessCard(false)
              }}
            />
            <span className='ml-2'>개인</span>
          </label>
        </div>
        <form
          onSubmit={handleSubmit(async (e) => {
            if (isLoading === true) return
            setIsLoading(true)
            const cardInfo = {
              cardNumber:
                e.cardNumberOne +
                e.cardNumberTwo +
                e.cardNumberThree +
                e.cardNumberFour,
              expYear: e.cardExpireYear,
              expMonth: e.cardExpireMonth,
              idNumber: e.idNumber,
              cardPassword: e.cardPassword,
            }
            const result = await backendApis.registerAdCreditCardInfo(cardInfo)
            if (result?.status === 2000 && result?.data?.AuthDate) {
              await getAdCreditInfo()
              setIsRegister(false)
            } else if (result?.data?.msg) {
              alert(`${result?.data?.msg}`)
            } else {
              alert('등록 실패. 카드 정보를 확인해주세요!')
            }
            setIsLoading(false)
          })}
          className='flex flex-col'
          autoComplete='off'
        >
          <div className='flex flex-row flex-1 mb-3'>
            <div className='flex items-center justify-center w-1/4'>
              카드번호
            </div>
            <div className='flex flex-row items-center justify-center w-2/3 gap-2'>
              <input
                type='text'
                className='w-[25%] text-center  border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                placeholder='0000'
                autoComplete='new-password'
                maxLength={4}
                minLength={4}
                pattern='[0-9]*'
                required
                {...register('cardNumberOne')}
              />
              <input
                type='password'
                className='w-[25%] text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                maxLength={4}
                minLength={4}
                placeholder='0000'
                pattern='[0-9]*'
                required
                autoComplete='new-password'
                {...register('cardNumberTwo')}
              />
              <input
                type='password'
                className='w-[25%] text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                maxLength={4}
                placeholder='0000'
                pattern='[0-9]*'
                required
                autoComplete='new-password'
                {...register('cardNumberThree')}
              />
              <input
                type='text'
                className='w-[25%] text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                maxLength={4}
                minLength={3}
                placeholder='0000'
                pattern='[0-9]*'
                required
                autoComplete='new-password'
                {...register('cardNumberFour')}
              />
            </div>
          </div>

          <div className='flex flex-row items-center justify-end flex-1 mb-3'>
            <div className='flex items-center justify-center w-1/4'>
              사용기한
            </div>
            <div className='flex flex-row items-center flex-1 w-2/3'>
              <input
                type='text'
                className='w-[18%] text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                placeholder='mm'
                maxLength={2}
                minLength={2}
                pattern='[0-9]*'
                required
                {...register('cardExpireMonth')}
              />
              <div className='mx-2'>/</div>
              <input
                type='text'
                className='w-[18%] text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                placeholder='yy'
                maxLength={2}
                minLength={2}
                pattern='[0-9]*'
                required
                {...register('cardExpireYear')}
              />
            </div>
          </div>
          <div className='flex flex-row items-center justify-end flex-1 mb-3'>
            <div className='flex items-center justify-center w-1/4'>
              {isBusinessCard ? '사업자번호' : '생년월일'}
            </div>

            <div className='flex flex-row items-center flex-1 w-2/3'>
              <input
                type='text'
                maxLength={isBusinessCard ? 10 : 6}
                minLength={isBusinessCard ? 10 : 6}
                pattern='[0-9]*'
                className='w-[40%] text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                placeholder={isBusinessCard ? 'ex. 1234567890' : 'ex. 910205'}
                {...register('idNumber')}
              />
            </div>
          </div>
          <div className='flex flex-row items-center justify-end flex-1 mb-3'>
            <div className='flex items-center justify-center w-1/4'>
              비밀번호
            </div>
            <div className='flex flex-row items-center justify-between flex-1 w-2/3'>
              <div className='flex flex-row items-center'>
                <input
                  type='password'
                  placeholder='00'
                  className='w-[30%] text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  maxLength={2}
                  minLength={2}
                  pattern='[0-9]*'
                  {...register('cardPassword')}
                />
                <div className='ml-2 font-bold'>**</div>
              </div>

              <Button
                type='submit'
                className='items-center justify-center px-4 py-2 font-bold text-white border-b-2 border-r-2 shadow-md rounded-xl border-positive-700 bg-gradient-to-r from-positive-400 to-positive-600 hover:bg-gradient-to-r hover:from-positive-500 hover:to-positive-800'
              >
                카드등록
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default CreditCardRegister

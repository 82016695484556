import moment from 'moment'

const DealTimePanel = ({ dealType, startDate, endDate }) => {
  const dealNameByDealType = {
    timeDeal: '타임특가',
    lureDeal: '순삭특가',
    overProducedDeal: '재고처분특가',
    tenMinDeal: '10분 핫딜',
  }
  if (dealType) {
    return (
      <div className='flex flex-col text-sm text-neutral-500'>
        <div>
          {startDate && endDate !== null ? (
            <>{`${dealNameByDealType[dealType]} 진행 시간`}</>
          ) : (
            <></>
          )}
        </div>
        <div>
          {startDate && endDate !== null ? (
            <>
              {moment(startDate).format('YYYY.MM.DD/H:mm')} -{' '}
              {moment(endDate).format('YYYY.MM.DD/H:mm')}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  }
  return <></>
}
export default DealTimePanel

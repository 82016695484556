import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import AuthStore from '../stores/AuthStore'
import LogoRedLetter from '../images/LogoRedLetter.png'
import LogoRed from '../images/LogoRed.png'
import Log from '../utils/log'

const LoginScreen = observer(() => {
  const location = useLocation()
  const history = useHistory()
  const [sellerName, setSellerName] = useState(location.state?.sellerName || '')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    SellerStore.setSellerName(sellerName)

    const data = await backendApis.attemptLogin(sellerName, password)
    if (!data) {
      alert('아이디 혹은 비밀번호가 잘못되었습니다.')
      return
    }
    
    if (data) {
      AuthStore.setToken(data.token)
      localStorage.setItem('@alwayz@seller@token@', data.token)
      const seller = await SellerStore.loadSellerInfo()
      if (seller) {
        if (
          SellerStore?.sellerInfo?.penaltyStatus === 'banned' &&
          !data?.isMasterAccount
        ) {
          alert('올웨이즈 판매정책 위반으로 해당 계정은 정지되었습니다.')
          localStorage.removeItem('@alwayz@seller@token@')
          AuthStore.setToken('')
          return
        }
        Log.event('loginButton', 'LoginPage', {
          click: 'sellerLoggedIn',
        })
      }
      if (data?.isMasterAccount) {
        AuthStore.setIsMasterAccount(true)
      } else {
        AuthStore.setIsMasterAccount(false)
      }
      history.push('/')
    } else {
      alert('아이디 혹은 비밀번호가 잘못되었습니다.')
    }
  }
  return (
    <div className='flex flex-col items-center w-screen h-screen py-24 bg-gradient-to-r from-theme-500 to-theme-400'>
      <div className='flex flex-col items-center w-[480px] pt-16 pb-12 px-12 text-center bg-white rounded-md'>
        <img className='w-1/4' src={LogoRedLetter} alt={LogoRed} />
        <div className='mt-5 text-3xl font-black'>판매자 센터</div>
        <div className='mt-5 mb-2 text-md'>
          올웨이즈 <span className='font-bold '>셀러 아이디</span>로
          로그인해주세요
        </div>
        <input
          className='w-full h-[48px] py-2 mt-2 mb-1 bg-theme-100 focus:bg-white'
          type='text'
          name='name'
          placeholder='아이디'
          value={sellerName}
          onChange={(e) => {
            setSellerName(e.target.value)
          }}
        />
        <input
          className='w-full h-[48px] py-2 my-1 bg-theme-100 focus:bg-white'
          type='password'
          name='password'
          placeholder='비밀번호'
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(e)
            }
          }}
        />
        <button
          className='w-full py-4 mt-4 text-xl font-extrabold text-white rounded-md border-theme-500 bg-theme-500'
          type='button'
          onClick={handleSubmit}
        >
          로그인
        </button>
        <button
          className='mt-3 text-base font-medium text-theme-500'
          type='button'
          onClick={() => {
            history.push('/login/password-search')
          }}
        >
          <span>비밀번호 찾기</span>
        </button>
        <div className='mt-8 mb-2 prose text-gray-600'>
          아직 올웨이즈 입점사가 아니신가요?
        </div>
        <button
          className='w-full py-4 my-2 text-xl border rounded-md text-theme-500 border-theme-500'
          type='button'
          onClick={() => {
            history.push('/login/register-seller')
          }}
        >
          <span>신규 입점사 신청하기</span>
        </button>
      </div>
    </div>
  )
})

export default LoginScreen

import React, { useState } from 'react'
import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'

const AdPolicyAgreementModal = ({ showModal, setShowModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [checkedItems, setCheckedItems] = useState({
    termsOfUse: false,
    policy: false,
  })

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none '>
            <div className='relative w-full max-w-4xl mx-auto my-6'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <div className='text-xl font-semibold text-black'>
                    올웨이즈 광고 이용약관 및 운영정책
                  </div>
                </div>
                {/* body */}
                <div className='relative flex-auto p-10'>
                  <div className='pb-5 text-xl font-semibold select-none'>
                    <input
                      checked={checkedItems.termsOfUse}
                      type='checkbox'
                      onChange={(e) => {
                        setCheckedItems({
                          ...checkedItems,
                          termsOfUse: e.target.checked,
                        })
                      }}
                      className='w-6 h-6 mb-1 mr-3 bg-white border border-gray-300 rounded-md appearance-none form-tick bg-check checked:bg-blue-500 checked:border-transparent focus:outline-none'
                    />
                    광고 이용약관 동의
                    <span className='text-gray-400 font-base'> (필수)</span>
                  </div>
                  <div className='border rounded-md'>
                    <iframe
                      title='policy'
                      width='100%'
                      height='250'
                      src='https://docs.google.com/document/d/e/2PACX-1vSqyj5uzGEX3Ne0b19gIrdBgd7S9BfOuLc7G91cmI1F84LLAxZNqMkZ8gwwXuhfJ1gXvyAblSH4us9Z/pub?embedded=true'
                    />
                  </div>

                  <div className='pt-10 pb-5 text-xl font-semibold select-none'>
                    <input
                      checked={checkedItems.policy}
                      type='checkbox'
                      onChange={(e) => {
                        setCheckedItems({
                          ...checkedItems,
                          policy: e.target.checked,
                        })
                      }}
                      className='w-6 h-6 mb-1 mr-3 bg-white border border-gray-300 rounded-md appearance-none form-tick bg-check checked:bg-blue-500 checked:border-transparent focus:outline-none'
                    />
                    광고 운영정책 동의
                    <span className='text-gray-400 font-base'> (필수)</span>
                  </div>
                  <div className='border rounded-md'>
                    <iframe
                      title='policy'
                      width='100%'
                      height='250'
                      src='https://docs.google.com/document/d/e/2PACX-1vQS2w7aDtCliluNBpsnvpTWO4ClMYJQXPUtOvy_brA1xDhtV5WddN-0TLB8iJVFIXpBOqBI7trjWFAR/pub?embedded=true'
                    />
                  </div>

                  <div className='pt-10 pb-5 text-xl font-semibold select-none'>
                    <input
                      checked={checkedItems.termsOfUse && checkedItems.policy}
                      type='checkbox'
                      onChange={() => {
                        if (checkedItems.termsOfUse && checkedItems.policy) {
                          setCheckedItems({ termsOfUse: false, policy: false })
                        } else {
                          setCheckedItems({ termsOfUse: true, policy: true })
                        }
                      }}
                      className='w-6 h-6 mb-1 mr-3 bg-white border border-gray-300 rounded-md appearance-none form-tick bg-check checked:bg-blue-500 checked:border-transparent focus:outline-none'
                    />
                    광고 이용약관 및 운영정책에 모두 동의합니다.
                  </div>
                </div>

                {/* footer */}
                <div className='flex items-center justify-center gap-3 p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <Button
                    appearance='gray'
                    className='w-32'
                    size='lg'
                    onClick={() => setShowModal(false)}
                  >
                    취소
                  </Button>
                  <Button
                    size='lg'
                    disabled={!checkedItems.termsOfUse || !checkedItems.policy}
                    appearance={
                      checkedItems.termsOfUse && checkedItems.policy
                        ? 'positive'
                        : 'disabled'
                    }
                    className='w-32'
                    onClick={async () => {
                      if (isLoading === true) return
                      setIsLoading(true)
                      const { status } =
                        await backendApis.uploadAgreeToSpecificPolicy(
                          'adPolicy',
                        )
                      if (status !== 2000) {
                        alert(
                          '잠시후 다시 시도하거나 판매자 지원센터로 문의해주세요.',
                        )
                      }
                      await SellerStore?.loadSellerInfo()
                      setShowModal(false)
                      setIsLoading(false)
                    }}
                  >
                    동의
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}
export default AdPolicyAgreementModal

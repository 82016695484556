import getCommissionRate from './getCommissionRate'
import getCouponSettlementAmount from './getCouponSettlementAmount'

const multiplyFloats = (...args) => {
  const precision = Math.max(
    ...args.map((arg) => {
      const decimalPart = arg.toString().split('.')[1]
      return decimalPart ? decimalPart.length : 0
    }),
  )

  const result = args
    .map((arg) => arg * 10 ** precision)
    .reduce((acc, val) => acc * val, 1)

  return result / 10 ** (precision * args.length)
}

const getSettlementAmount = (order) => {
  const couponSettlementAmount = getCouponSettlementAmount(order)

  const originalPayedAmount =
    typeof order.totalReturnFee === 'number'
      ? order.totalReturnFee
      : order.totalPrice +
        order.shippingFee -
        (order.refundAmount || 0) +
        (couponSettlementAmount || 0) +
        (order.selectedOption?.platformSupportPrice || 0) * order.quantity

  const commissionRate = getCommissionRate(order)

  const settlementAmount =
    originalPayedAmount -
    Math.floor(
      multiplyFloats(multiplyFloats(commissionRate, originalPayedAmount), 1.1),
    ) -
    (order.settlementLogs
      ?.map(
        (settlementLog) =>
          settlementLog.settlementAmount && order.settlementStatus === 'final',
      )
      .reduce((a, b) => a + b, 0) || 0)

  return settlementAmount
}

export default getSettlementAmount

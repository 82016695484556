import RankStandard from '../../data/rankStandard'

const ItemRankExpBar = ({ score }) => {
  const rankMaxExpSetter = (itemScore) => {
    if (itemScore >= RankStandard.S) {
      return RankStandard.S
    }
    if (itemScore >= RankStandard.A) {
      return RankStandard.S
    }
    if (itemScore >= RankStandard.B) {
      return RankStandard.A
    }
    if (itemScore < RankStandard.B) {
      return RankStandard.B
    }
    return ''
  }

  const rankMinExpSetter = (itemScore) => {
    if (itemScore >= RankStandard.S) {
      return RankStandard.S - 1
    }
    if (itemScore >= RankStandard.A) {
      return RankStandard.A - 1
    }
    if (itemScore >= RankStandard.B) {
      return RankStandard.B - 1
    }
    if (itemScore < RankStandard.B) {
      return 0
    }
    return ''
  }

  const getBarColor = (itemScore) => {
    if (itemScore >= RankStandard.S) {
      return 'bg-yellow-500'
    }
    if (itemScore >= RankStandard.A) {
      return 'bg-orange-500'
    }
    if (itemScore >= RankStandard.B) {
      return 'bg-blue-500'
    }
    if (itemScore < RankStandard.B) {
      return 'bg-green-500'
    }
    return ''
  }
  return (
    <div className='flex flex-1 h-5 bg-gray-200 rounded-md'>
      <div
        className={`relative flex h-5 text-xs text-center text-white duration-300 ease-in-out ${getBarColor(
          score,
        )} rounded-md`}
        style={{
          width: `${
            (Math.floor(score - rankMinExpSetter(score)) /
              (rankMaxExpSetter(score) - rankMinExpSetter(score))) *
            100
          }%`,
        }}
      />
    </div>
  )
}
export default ItemRankExpBar

import backendApis from '../../utils/backendApis'
import React, { useState, useEffect, useMemo } from 'react'
import Button from '../atoms/button'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import ListLimitSelector from '../atoms/listLimitSelector'
import Table from '../atoms/table'
import Format from '../../utils/format'
import { BsArrowRightShort as ArrowRightIcon } from 'react-icons/bs'
import Pagination from './pagination'
import { GrClose as CloseIcon, GrRefresh as RefreshIcon } from 'react-icons/gr'
import SellerQuest from '../../utils/sellerQuest'

const ItemRankBatchUpdateModal = ({ showModal, setShowModal }) => {
  const priceQuestChecker = async () => {
    if (!SellerQuest.questChecker('priceQuest', 'itemRankBatchModal')) {
      await SellerQuest.questClearer('priceQuest', 'itemRankBatchModal')
    }
  }
  const [rankStatusTab, setRankStatusTab] = useState('rankBCandidate')
  const [isLoading, setIsLoading] = useState(false)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)
  const expectedRankTextSetter = (rankStatusTab) => {
    switch (rankStatusTab) {
      case 'rankBCandidate':
        return (
          <div className='flex flex-row items-center justify-center'>
            <span>C</span>
            <ArrowRightIcon />
            <span className='font-bold text-positive-500'>B</span>
          </div>
        )
      case 'rankACandidate':
        return (
          <div className='flex flex-row items-center justify-center'>
            <span>B</span>
            <ArrowRightIcon />
            <span className='font-bold text-positive-500'>A</span>
          </div>
        )
      case 'rankSCandidate':
        return (
          <div className='flex flex-row items-center justify-center'>
            <span>A</span>
            <ArrowRightIcon />
            <span className='font-bold text-positive-500'>S</span>
          </div>
        )
      default:
        return ''
    }
  }
  const textSetter = (rankStatusTab) => {
    if (rankStatusTab === 'rankBCandidate') {
      return 'B'
    }
    if (rankStatusTab === 'rankACandidate') {
      return 'A'
    }
    if (rankStatusTab === 'rankSCandidate') {
      return 'S'
    }
    return ''
  }
  const checkBoxValueResetter = () => {
    setIsAllCheckBoxChecked(false)
    setCheckedItemIds([])
  }

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (isLoading) {
      return
    }
    checkBoxValueResetter()
    setIsLoading(true)
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    if (rankStatusTab === 'rankBCandidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: true }
      itemCondition.itemRankTestingSku = { $ne: true }
      itemCondition.$expr = {
        $and: [
          {
            $gte: ['$rankInfo.totalScore', 179],
          },
          {
            $lt: ['$rankInfo.totalScore', 185],
          },
        ],
      }
    } else if (rankStatusTab === 'rankACandidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: true }
      itemCondition.itemRankTestingSku = { $ne: true }
      itemCondition.$expr = {
        $and: [
          {
            $gte: ['$rankInfo.totalScore', 204],
          },
          {
            $lt: ['$rankInfo.totalScore', 210],
          },
        ],
      }
    } else if (rankStatusTab === 'rankSCandidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: true }
      itemCondition.itemRankTestingSku = { $ne: true }
      itemCondition.$expr = {
        $and: [
          {
            $gte: ['$rankInfo.totalScore', 229],
          },
          {
            $lt: ['$rankInfo.totalScore', 235],
          },
        ],
      }
    }
    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        SellerStore.setIsLoading(false)
        return
      }
    }
    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      console.log(`countcount: ${JSON.stringify(result)}`)
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        console.log(`${JSON.stringify(retryResult)}`)
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    setIsLoading(false)
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [limit, rankStatusTab])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden outline-none'>
            <div className='relative flex-col w-1/2 p-10 overflow-y-scroll bg-white rounded-md shadow-md h-3/4'>
              <div className='flex flex-col flex-1'>
                <div className='flex flex-row items-center justify-between flex-1'>
                  <div className='text-xl font-bold'>대량 등급 재배정</div>
                  <Button
                    appearance='transparent'
                    onClick={() => {
                      setShowModal(false)
                    }}
                  >
                    <CloseIcon className='w-4 h-4' />
                  </Button>
                </div>
                <div>
                  등급 상승이 임박한 상품들에 대해 일괄 등급 상승 신청을 하실 수
                  있습니다.
                </div>
                <div className='flex justify-between flex-1 my-5'>
                  <div className='flex flex-1 gap-2'>
                    <Button
                      onClick={() => {
                        setRankStatusTab('rankBCandidate')
                      }}
                      appearance={
                        rankStatusTab !== 'rankBCandidate'
                          ? 'positiveSub'
                          : 'disabled'
                      }
                    >
                      B등급 후보
                    </Button>
                    <Button
                      onClick={() => {
                        setRankStatusTab('rankACandidate')
                      }}
                      appearance={
                        rankStatusTab !== 'rankACandidate'
                          ? 'positiveSub'
                          : 'disabled'
                      }
                    >
                      A등급 후보
                    </Button>
                    <Button
                      onClick={() => {
                        setRankStatusTab('rankSCandidate')
                      }}
                      appearance={
                        rankStatusTab !== 'rankSCandidate'
                          ? 'positiveSub'
                          : 'disabled'
                      }
                    >
                      S등급 후보
                    </Button>
                  </div>
                  <div>
                    <ListLimitSelector limit={limit} setLimit={setLimit} />
                  </div>
                </div>
                <div>
                  등급 상승을 희망하시는 상품들을 체크하신 후 선택{' '}
                  <span className='font-bold text-positive-500'>
                    선택 상품 일괄 등급 재배정
                  </span>{' '}
                  버튼을 눌러주시면 등급 상승이 가능한 가격으로 설정되며, 등급
                  재배정이 진행됩니다.
                </div>
                <div className='text-sm text-red-500'>
                  *등급 재배정은 고객 반응도에 따라 진행되기 때문에 예상 등급
                  대비 다소간의 오차 범위가 있을 수 있습니다.
                </div>
                <div className='my-5'>
                  <Button
                    onClick={async () => {
                      const result = await backendApis.updateManyItemsRankTest(
                        checkedItemIds,
                      )
                      if (result?.status === 2000) {
                        alert('성공적으로 등급 재배정 신청이 완료되었습니다.')
                        setCheckedItemIds([])
                        setIsAllCheckBoxChecked(false)
                        setPage(1)
                        fetchSegmentedItemsInfo(true)
                        await priceQuestChecker()
                        return
                      }
                      alert(
                        '등급 재배정 신청 중 문제가 발생했습니다. 문제가 지속될 경우 판매자 지원센터로 문의해주세요.',
                      )
                      setCheckedItemIds([])
                      setIsAllCheckBoxChecked(false)
                      setPage(1)
                      fetchSegmentedItemsInfo(true)
                    }}
                  >
                    선택 상품 일괄 등급 재배정
                  </Button>
                </div>
                <div className='flex flex-col flex-1 overflow-y-scroll h-max'>
                  <table>
                    <th className='bg-slate-200'>
                      <input
                        type='checkbox'
                        checked={isAllCheckBoxChecked}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCheckedItemIds(
                              itemsInfo?.map((itemInfo) => itemInfo?._id),
                            )
                            setIsAllCheckBoxChecked(true)
                          } else {
                            setCheckedItemIds([])
                            setIsAllCheckBoxChecked(false)
                          }
                        }}
                      />
                    </th>
                    <th className='bg-slate-200'>상품명</th>
                    <th className='bg-slate-200'>예상 등급</th>
                    <th className='bg-slate-200'>현재 가격</th>
                    <th className='bg-slate-200'>변경 가격</th>
                    {itemsInfo?.map((itemInfo) => {
                      return (
                        <tr>
                          <td>
                            <input
                              type='checkbox'
                              checked={checkedItemIds?.includes(itemInfo?._id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setCheckedItemIds([
                                    ...checkedItemIds?.filter(
                                      (itemId) => itemId !== itemInfo?._id,
                                    ),
                                    itemInfo?._id,
                                  ])
                                } else {
                                  setCheckedItemIds(
                                    checkedItemIds?.filter(
                                      (itemId) => itemId !== itemInfo?._id,
                                    ),
                                  )
                                }
                              }}
                            />
                          </td>
                          <td className='whitespace-normal'>
                            {itemInfo?.itemTitle}
                          </td>
                          <td>{expectedRankTextSetter(rankStatusTab)}</td>
                          <td>{Format.Price(itemInfo?.teamPurchasePrice)}</td>
                          <td className='font-bold text-positive-500'>
                            {Format.Price(
                              parseInt(itemInfo?.teamPurchasePrice * 0.9, 10),
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </table>
                  {itemsInfo?.length === 0 && (
                    <div className='flex flex-row items-center justify-center flex-1 pt-10 pb-5'>
                      <span className='font-bold'>
                        {textSetter(rankStatusTab)}등급 상승
                      </span>
                      이 임박한 후보 상품이 아직 없습니다.
                    </div>
                  )}
                </div>
                <div className='mt-5'>
                  <Pagination
                    total={itemsCount}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    size='lg'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}
export default ItemRankBatchUpdateModal

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Card, Col, Row, Statistic } from 'antd'

const ItemQualityStatistics = ({ data }) => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)
  const lastWeek = new Date(today)
  lastWeek.setDate(today.getDate() - 7)
  const twoWeeksAgo = new Date(today)
  twoWeeksAgo.setDate(today.getDate() - 14)

  const currentWeekNegReviews = data
    .filter((item) => new Date(item.createdAt) > lastWeek)
    .reduce((sum, item) => sum + item.weeklyNegReviewCounts, 0)

  const previousWeekNegReviews = data
    .filter(
      (item) =>
        new Date(item.createdAt) > twoWeeksAgo &&
        new Date(item.createdAt) <= lastWeek,
    )
    .reduce((sum, item) => sum + item.weeklyNegReviewCounts, 0)

  const currentWeekRefunds = data
    .filter((item) => new Date(item.createdAt) > lastWeek)
    .reduce((sum, item) => sum + item.weeklyRefundCounts, 0)

  const previousWeekRefunds = data
    .filter(
      (item) =>
        new Date(item.createdAt) > twoWeeksAgo &&
        new Date(item.createdAt) <= lastWeek,
    )
    .reduce((sum, item) => sum + item.weeklyRefundCounts, 0)

  const todayQuestions = data
    .filter(
      (item) =>
        new Date(item.createdAt).toDateString() === today.toDateString(),
    )
    .reduce((sum, item) => sum + item.dailyQuestionCounts, 0)

  const yesterdayQuestions = data
    .filter(
      (item) =>
        new Date(item.createdAt).toDateString() === yesterday.toDateString(),
    )
    .reduce((sum, item) => sum + item.dailyQuestionCounts, 0)

  const weeklyNegReviewIncreaseRate = previousWeekNegReviews
    ? (
        ((currentWeekNegReviews - previousWeekNegReviews) /
          previousWeekNegReviews) *
        100
      ).toFixed(2)
    : 0

  const weeklyRefundIncreaseRate = previousWeekRefunds
    ? (
        ((currentWeekRefunds - previousWeekRefunds) / previousWeekRefunds) *
        100
      ).toFixed(2)
    : 0

  const dailyQuestionIncreaseRate = yesterdayQuestions
    ? (
        ((todayQuestions - yesterdayQuestions) / yesterdayQuestions) *
        100
      ).toFixed(2)
    : 0

  const todayNegativeQualityRatios = data
    .filter(
      (item) =>
        new Date(item.createdAt).toDateString() === today.toDateString(),
    )
    .map((item) => item.negativeQualityRatio)

  const yesterdayNegativeQualityRatios = data
    .filter(
      (item) =>
        new Date(item.createdAt).toDateString() === yesterday.toDateString(),
    )
    .map((item) => item.negativeQualityRatio)

  const avgNegativeQualityRatioToday = todayNegativeQualityRatios.length
    ? (
        todayNegativeQualityRatios.reduce((sum, ratio) => sum + ratio, 0) /
        todayNegativeQualityRatios.length
      ).toFixed(2)
    : 0

  const avgNegativeQualityRatioYesterday = yesterdayNegativeQualityRatios.length
    ? (
        yesterdayNegativeQualityRatios.reduce((sum, ratio) => sum + ratio, 0) /
        yesterdayNegativeQualityRatios.length
      ).toFixed(2)
    : 0
  const dailyNegativeQualityRatioIncreaseRate = avgNegativeQualityRatioYesterday
    ? (
        ((avgNegativeQualityRatioToday - avgNegativeQualityRatioYesterday) /
          avgNegativeQualityRatioYesterday) *
        100
      ).toFixed(2)
    : 0

  const getValueStyle = (value) => ({
    color: value >= 0 ? '#cf1322' : '#3f8600',
  })

  const getPrefixIcon = (value) =>
    value >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />

  return (
    <Row gutter={16} justify='space-between'>
      <Col xs={24} sm={12} md={6} lg={6}>
        <Card bordered={false}>
          <Statistic
            title='부정품질 비율 전일비'
            value={dailyNegativeQualityRatioIncreaseRate}
            precision={2}
            valueStyle={getValueStyle(dailyNegativeQualityRatioIncreaseRate)}
            prefix={getPrefixIcon(dailyNegativeQualityRatioIncreaseRate)}
            suffix='%'
          />
        </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
        <Card bordered={false}>
          <Statistic
            title='주간 부정후기 증가율'
            value={weeklyNegReviewIncreaseRate}
            precision={2}
            valueStyle={getValueStyle(weeklyNegReviewIncreaseRate)}
            prefix={getPrefixIcon(weeklyNegReviewIncreaseRate)}
            suffix='%'
          />
        </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
        <Card bordered={false}>
          <Statistic
            title='주간 품질반품 증가율'
            value={weeklyRefundIncreaseRate}
            precision={2}
            valueStyle={getValueStyle(weeklyRefundIncreaseRate)}
            prefix={getPrefixIcon(weeklyRefundIncreaseRate)}
            suffix='%'
          />
        </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
        <Card bordered={false}>
          <Statistic
            title='일간 품질문의 증가율'
            value={dailyQuestionIncreaseRate}
            precision={2}
            valueStyle={getValueStyle(dailyQuestionIncreaseRate)}
            prefix={getPrefixIcon(dailyQuestionIncreaseRate)}
            suffix='%'
          />
        </Card>
      </Col>
    </Row>
  )
}

export default ItemQualityStatistics

import { useState, useEffect } from 'react'
import Button from './button'
import backendApis from '../../utils/backendApis'
import Format from '../../utils/format'
import shortUUID from 'short-uuid'

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

export default function ModalRefund({
  orderId,
  imageSource,
  isButton = false,
  buttonText,
  userName,
  buttonLink,
  buttonCallbackFunction = () => {},

  listContent = [],
  listButton = [
    {
      text: '',
      href: '',
      btnText: '',
    },
  ],
  modalOn = false,
  setShowModal,
}) {
  const [orderData, setOrderData] = useState({})
  const [addressId, setAddressId] = useState('')
  const translator = shortUUID()

  useEffect(async () => {
    const result = await backendApis.loadOrderData(orderId)
    if (result.status === 200) {
      setOrderData(result.data[0])
      setAddressId(
        `${Format.DateToday(result.data[0]?.payedAt)}${
          checkIfValidUUID(result.data[0]?.addressInfo?.addressId) &&
          translator.fromUUID(
            result.data[0]?.addressInfo?.addressId || result.data[0]?._id,
          )
        }`.substring(2),
      )
    }
  }, [orderId])
  const fistOption = orderData?.optionIndices?.['0']
  const secondOption = orderData?.optionIndices?.['1']
  const firstOptionNameInfo =
    orderData?.itemInfo?.optionsInfo?.totalOptions['0']?.[Number(fistOption)]
      ?.name
  const secondOptionNameInfo =
    orderData?.itemInfo?.optionsInfo?.totalOptions['1']?.[Number(secondOption)]
      ?.name

  const statusName = (status) => {
    if (status === 'team-gathering') {
      return '팀 모집 중'
    }
    if (status === 'canceling-team-gathering') {
      return '팀 모집 중 취소 요청 중'
    }
    if (status === 'canceled-team-gathering') {
      return '팀 모집 중 취소 완료'
    }
    if (status === 'pre-shipping') {
      return '상품 준비 중'
    }
    if (status === 'canceling-pre-shipping') {
      return '출고 중지 요청 중'
    }
    if (status === 'canceling-shipping') {
      return '배송 중지 요청 중'
    }
    if (status === 'arrived') {
      return '배송완료'
    }
    if (status === 'reviewed') {
      return '리뷰완료'
    }
    if (status === 'refunding') {
      return '반품 접수'
    }
    if (status === 'refunding-retrieving') {
      return '반품 수거 중'
    }
    if (status === 'refunding-retrieved') {
      return '반품 수거 중'
    }
    if (status === 'refunding-arrived') {
      return '반품 수거완료'
    }
    if (status === 'refunded') {
      return '반품 완료'
    }
    if (status === 'canceled-pre-shipping') {
      return '출고 중지 중 취소완료'
    }
    if (status === 'canceled-shipping') {
      return '배송 중 취소완료'
    }
  }

  const orderStatus = (status) => {
    if (status === 'team-gathering') {
      return '팀 모집 중'
    }
    if (status === 'canceling-team-gathering') {
      return '팀 모집 중 취소 요청 중'
    }
    if (status === 'canceled-team-gathering') {
      return '팀 모집 중 취소 완료'
    }
    if (status === 'pre-shipping') {
      return '상품 준비 중'
    }
    if (status === 'canceling-pre-shipping') {
      return '출고 중지 요청 중'
    }
    if (status === 'canceled-pre-shipping') {
      return '배송 취소됨'
    }
    if (status === 'canceled-shipping') {
      return '배송 취소됨'
    }
    if (status === 'canceling-shipping') {
      return '배송 중지 요청 중'
    }
    if (status === 'shipping') {
      return '배송 중'
    }
    return '배송완료'
  }

  return (
    <>
      {modalOn ? (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
            <div className='relative w-full max-w-3xl mx-auto my-6'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <h3 className='text-xl font-semibold text-black'>
                    문의 상세
                  </h3>
                </div>
                {/* body */}
                <div className='relative flex-auto px-10 py-10'>
                  {imageSource && (
                    <div className='mb-5 text-lg leading-relaxed text-sub-500'>
                      <img alt='' src={imageSource} />
                    </div>
                  )}
                  {/* <Card title='고객 주문 정보'> */}
                  <table className='mb-50'>
                    <thead>
                      <tr>
                        <th scope='col' colSpan={2} className='text-base'>
                          고객 주문 정보
                        </th>
                      </tr>
                    </thead>
                    <tbody className='items-center mt-3'>
                      <tr>
                        <td className='text-lg text-center'>작성자</td>
                        <td className='text-lg text-center'>{userName}</td>
                      </tr>
                      <tr>
                        <td className='text-lg text-center'>결제일시</td>
                        <td className='text-lg text-center'>
                          {Format.DateString(orderData?.payedAt)}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-lg text-center'>배송정보</td>
                        <td className='text-lg text-start text-bold'>
                          수령인 :
                          <span className='text-lg'>
                            {orderData?.addressInfo?.recipient}
                          </span>
                          <br />
                          전화번호 :
                          <span className='text-lg'>
                            {' '}
                            {orderData?.addressInfo?.recipientPhoneNumber}
                          </span>
                          <br />
                          우편주소 :
                          <span className='text-lg'>
                            {' '}
                            {orderData?.addressInfo?.postcode}
                          </span>
                          <br />
                          주소 :
                          <span className='text-lg'>
                            {' '}
                            {orderData?.addressInfo?.postcodeAddress}
                          </span>
                          <br />
                          상세 주소 :
                          <span className='text-lg'>
                            {' '}
                            {orderData?.addressInfo?.detailAddress}
                          </span>
                          <br />
                          배송 메세지 :
                          <span className='text-lg'>
                            {' '}
                            {orderData?.addressInfo?.recipient}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='mt-10'>
                    <table className='mb-50'>
                      <thead>
                        <tr>
                          <th scope='col' colSpan={2} className='text-base'>
                            주문 상세
                          </th>
                        </tr>
                      </thead>
                      <tbody className='items-center mt-3'>
                        <tr>
                          <td className='text-lg text-center'>총 배송비</td>
                          <td className='text-lg text-start'>
                            {orderData?.shippingFee}원
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>합배송 아이디</td>
                          <td className='text-lg text-start'>
                            {addressId || ''}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>상품 정보</td>
                          <td className='text-lg text-start text-bold'>
                            상품명 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.itemInfo?.itemTitle}
                            </span>
                            <br />
                            옵션명 :
                            <span className='text-lg'>
                              {' '}
                              {`${firstOptionNameInfo || ''} ${
                                secondOptionNameInfo || ''
                              } `}
                            </span>
                            <br />총 판매가 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.totalPrice}원
                            </span>
                            <br />
                            수량 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.quantity}개
                            </span>
                            <br />
                            쿠폰 할인액 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.selectedCouponInfo
                                ?.finalDiscountAmount || 0}
                              원
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>배송상태</td>
                          <td className='text-lg text-start'>
                            <span className='text-lg'>
                              {' '}
                              {orderStatus(orderData?.status)}
                            </span>
                            <br />
                            출고 일시 :
                            <span className='text-lg'>
                              {' '}
                              {Format.DateString(
                                orderData?.shippingInfo?.trackingDetail?.['0']
                                  ?.timeString,
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>처리상태</td>
                          <td className='text-lg text-start'>
                            <span className='text-lg'>
                              {' '}
                              {orderData?.refundInfo?.refundStatus
                                ? statusName(
                                    orderData?.refundInfo?.refundStatus,
                                  )
                                : statusName(orderData?.status)}
                            </span>
                            <br />
                            <span className='text-lg'>
                              {Format.DateString(
                                orderData?.history?.findLast(
                                  (e) => e.status === orderData?.status,
                                )?.recordedAt,
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* </Card> */}
                  <div className='text-base'>
                    {listContent ? (
                      <ol className='list-decimal'>
                        {listContent.map((list) => {
                          return <li key={list.toString()}>{list}</li>
                        })}
                      </ol>
                    ) : null}
                  </div>
                  <div>
                    {listButton[0].text !== ''
                      ? listButton.map((button) => {
                          return (
                            <div
                              className='flex flex-row items-center text-lg'
                              key={button.href}
                            >
                              <div className='mr-5'>{button.text}</div>
                              <div>
                                <Button href={button.href}>
                                  {button.btnText}
                                </Button>
                              </div>
                            </div>
                          )
                        })
                      : null}
                  </div>
                </div>
                {/* footer */}
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    닫기
                  </button>
                  {isButton ? (
                    <Button
                      href={buttonLink}
                      appearance='positive'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        buttonCallbackFunction()
                        setShowModal(false)
                      }}
                    >
                      {buttonText}
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      ) : null}
    </>
  )
}

import backendApis from '../backendApis'

const requestBankAccountInfo = async (selectedBank, accountNumber) => {
  try {
    const findResult = await backendApis.requestAccountHolderInfo(
      selectedBank,
      accountNumber,
    )
    if (findResult?.status === 200) {
      return {
        status: 200,
        AccountHolderName: findResult?.data?.AccountName,
        message: '계좌조회가 완료되었습니다.',
      }
    }
    return {
      status: 400,
      message: '계좌조회에 실패했습니다. 다시 시도해주세요.',
    }
  } catch (error) {
    console.error('Business image validation error:', error)
    return {
      status: 400,
      message: error.message || '검증 중 오류가 발생했습니다.',
      error,
    }
  }
}

export default requestBankAccountInfo

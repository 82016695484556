import commaNumber from 'comma-number'
import React from 'react'
import {
  cheapabilityTranslator,
  colorSetter,
} from '../../utils/cheapabilityUtils'
import ExtremePrice from '../../images/cheapabiltiyIcon/ExtremePrice.png'
import ExtremePriceReverse from '../../images/cheapabiltiyIcon/ExtremePriceReverse.png'

const CheapabilityExplainBox = ({
  optionPrice,
  cheapability,
  refPrice,
  optionName,
}) => {
  const { teamPurchasePrice } = optionPrice || {}

  return (
    <div className='ease-in-out py-4 px-6 min-w-[400px] bg-slate-100 rounded-2xl shadow-md'>
      <div className='mb-3 font-bold text-slate-500'>
        {optionName?.length > 20 ? optionName?.slice(0, 20) : optionName}
      </div>
      <div>
        선택하신 옵션의 현재 팀구매가{' '}
        <span className={`${colorSetter(cheapability)} font-bold`}>
          ₩{commaNumber(teamPurchasePrice)}
        </span>
      </div>
      <div>
        해당 옵션의 시장 내 가격은{' '}
        <span className={`${colorSetter(cheapability)} font-bold`}>
          {cheapabilityTranslator(cheapability)}
        </span>
        입니다.
      </div>
      {/* {refPrice && (
        <div className='flex flex-row items-center'>
          <div>
            <img
              src={ExtremePriceReverse}
              className='px-2 py-1 mr-1 bg-red-500 w-14'
              alt=''
            />
          </div>
          기준은{' '}
          <span className='font-bold text-blue-500'>
            ₩{commaNumber(refPrice)}
          </span>
          입니다.
        </div>
      )} */}
    </div>
  )
}
export default CheapabilityExplainBox

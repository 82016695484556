import React, { useEffect, useState } from 'react'

import { Tooltip as TooltipOrigin } from 'react-tippy'
import { observer } from 'mobx-react-lite'
import Button from '../../components/atoms/button'
import backendApis from '../../utils/backendApis'

const Header = ({ modalTitle, onClose }) => {
  return (
    <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
      <h3 className='text-xl font-bold text-black'>{modalTitle}</h3>
      <div className='flex flex-row items-center justify-end flex-1'>
        <TooltipOrigin
          arrow='true'
          theme='dark'
          title='❎ 키보드 esc로도 종료하실 수 있어요.'
          animation='fade'
          position='top'
        >
          <button
            type='button'
            onClick={onClose}
            className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
          >
            esc
          </button>
        </TooltipOrigin>
      </div>
    </div>
  )
}

const Body = () => {
  return (
    <div className='flex flex-row items-center flex-1 p-5'>
      <ul className='list-disc ml-5'>
        <li>
          바로도착 서비스는 올웨이즈가 제휴 물류사와의 계약을 통해 운영되는
          물류서비스 입니다.
        </li>
        <li>
          본 서비스는 올웨이즈와 판매자 간 이용약관을 기반으로 운영됩니다.
        </li>
        <li>
          약관에 동의하지 않으시면 바로도착 서비스를 이용하실 수 없습니다.
        </li>
      </ul>
    </div>
  )
}

const modalTitle = '바로도착 서비스 이용약관 동의'

const ServiceConsentModal = observer(
  ({
    setServiceConsentModal,
    setBaroServiceConsentStatus,
    setApplyStatusTab,
    onClose = () => {},
  }) => {
    const [isAgreed, setIsAgreed] = useState(false)

    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
          setServiceConsentModal(false)
        }
      }
      document.addEventListener('keydown', handleKeyPress)
      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }, [onClose])

    const registerBaroService = async () => {
      const consentCondition = {
        type: 'delivery',
        subType: 'baroArrive',
        status: 'registered',
      }
      const updateSellerServiceConsent =
        await backendApis.updateSellerServiceConsent(consentCondition)
      if (updateSellerServiceConsent?.status === 200) {
        const indexCondition = {
          type: 'seller',
        }
        const checkSellerIndexForCj = await backendApis.checkIndexForCj(
          indexCondition,
        )
        if (checkSellerIndexForCj?.data?.length === 0) {
          const updateSellerIndexForCj = await backendApis.updateIndexForCj(
            indexCondition,
          )
          if (updateSellerIndexForCj?.status !== 200) {
            window.alert(
              '바로도착 서비스 신청에 실패했습니다.\n잠시 후 다시 시도해주세요.',
            )
          }
          setBaroServiceConsentStatus(2)
          setApplyStatusTab('candidate')
          window.alert('바로도착 신청이 완료되었습니다.')
        } else {
          setBaroServiceConsentStatus(2)
          setApplyStatusTab('candidate')
          window.alert('바로도착 신청이 완료되었습니다.')
        }
      }
      window.alert(
        '바로도착 서비스 신청에 실패했습니다.\n잠시 후 다시 시도해주세요.',
      )
    }
    return (
      <div className='fixed inset-0 z-50 flex items-center justify-center px-2 outline-none ml-[240px] bg-gray-500 bg-opacity-70'>
        <div className='relative w-3/5 max-w-[150vh] mx-auto my-6 shadow-lg overflow-y-auto max-h-[80vh]'>
          <div className='relative flex flex-col w-full bg-white border-0 outline-none'>
            <div className='sticky top-0 z-10 bg-white'>
              <Header
                modalTitle={modalTitle}
                onClose={() => {
                  setServiceConsentModal(false)
                }}
              />
              <Body />
              <div className='p-5'>
                <div className='flex items-center ml-1'>
                  <input
                    type='checkbox'
                    checked={isAgreed}
                    onChange={(e) => setIsAgreed(e.target.checked)}
                  />
                  <span className='ml-2'>서비스 이용약관에 동의합니다.</span>
                  <a
                    href='https://levitinc.notion.site/4c6579832e2840f2b161e3762c85daff?pvs=4'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='ml-2 text-blue-600 hover:text-blue-800 visited:text-purple-600'
                  >
                    [약관 전문보기]
                  </a>
                </div>
              </div>
            </div>
            <span className='ml-5 mb-5'>
              {isAgreed ? (
                <Button
                  appearance='positive'
                  size='md'
                  onClick={() => {
                    registerBaroService()
                    setServiceConsentModal(false)
                  }}
                >
                  바로도착 신청
                </Button>
              ) : (
                <Button
                  appearance='neutral'
                  size='md'
                  onClick={() => {
                    window.alert('서비스 이용약관에 동의해주세요.')
                  }}
                >
                  바로도착 신청
                </Button>
              )}
            </span>
          </div>
        </div>
      </div>
    )
  },
)

export default ServiceConsentModal

/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FcIdea, FcMoneyTransfer } from 'react-icons/fc'
import Page from '../components/atoms/page'
import Button from '../components/atoms/button'
import { observer } from 'mobx-react-lite'
import backendApis from '../utils/backendApis'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import { Pagination } from 'antd'
import resize from '../utils/resize'
import ItemTable from '../components/ADS/molecules/itemTable'
import commaNumber from 'comma-number'
import BaroArriveItemRegisterModal from './Modals/BaroArriveItemRegisterModal'
import BaroArriveItemModifyModal from './Modals/BaroArriveItemModifyModal'
import ServiceConsentModal from './Modals/ServiceConsentModal'
import { possiblePickUpPostCode } from '../utils/fastArrivalPostCode'
import StorageRegisterModal from './Modals/StorageRegisterModal'
// import { OffDayList } from '../utils/fastArrivalUtils'

const BaroArriveInfo = ({
  baroServiceConsentStatus,
  setServiceConsentModal,
  setBaroServiceConsentStatus = () => {},
}) => {
  const withdrawBaroService = async () => {
    const withdrawCondition = {
      type: 'delivery',
      subType: 'baroArrive',
      status: 'withdraw',
    }
    const itemWithdrawCondition = {
      type: 'baroArrive',
      status: 'withdraw',
    }
    const updateSellerServiceConsent =
      await backendApis.updateSellerServiceConsent(withdrawCondition)
    if (updateSellerServiceConsent?.status === 200) {
      setBaroServiceConsentStatus(3)
      await backendApis.withdrawFastArrivalItemsBySeller(itemWithdrawCondition)
      window.alert('바로도착 가입이 철회되었습니다.')
    } else {
      window.alert(
        '바로도착 서비스 가입 철회에 실패했습니다.\n잠시 후 다시 시도해주세요.',
      )
    }
  }

  return (
    <div className='flex flex-row justify-between'>
      <div className='shadow-lg rounded-2xl p-8 bg-white flex-auto flex flex-row'>
        <div className='flex-1'>
          <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
            <FcIdea className='mr-2 text-lg' />
            <span className='text-lg'>바로도착 서비스란?</span>
          </span>
          <ul className='mx-4 list-disc'>
            <li>
              바로도착은 판매자 및 구매자의 주소지에 따라
              <br />
              <span className='text-blue-500 font-bold'>
                당일~익일 도착을 보장
              </span>
              하는 올웨이즈의 배송 서비스입니다.
            </li>
          </ul>
          <span className='flex flex-row items-center flex-1 mt-6 mb-1 font-bold'>
            <FcMoneyTransfer className='mr-2 text-lg' />
            <span className='text-lg'>
              왜 바로도착 서비스를 등록해야 하나요?
            </span>
          </span>
          <ul className='mx-4 list-disc'>
            <li className='mt-1'>
              <span className='text-blue-500 font-bold'>택배사 계약 없이</span>{' '}
              신청만으로 이용 가능!
            </li>
            <li className='mt-1'>
              <span className='text-blue-500 font-bold'>
                하루 두 번! 정해진 시간에
              </span>{' '}
              택배기사가 상품 수거!
            </li>
            <li className='mt-1'>
              <span className='text-blue-500 font-bold'>추가 수수료 없이</span>{' '}
              효과적인 노출/판매 부스팅!
            </li>
            <li className='mt-1'>
              <span className='text-blue-500 font-bold'>배송 CS 대응은</span>{' '}
              올웨이즈에서!
            </li>
          </ul>
          <div className='mt-8'>
            {baroServiceConsentStatus !== 2 ? (
              <Button
                appearance='positive'
                size='md'
                onClick={() => {
                  setServiceConsentModal(true)
                }}
              >
                바로도착 신청
              </Button>
            ) : (
              <Button
                appearance={
                  // baroServiceConsentStatus === 1 ||
                  baroServiceConsentStatus === 2 ? 'neutral' : 'positive'
                }
                size='md'
                onClick={() => {
                  if (
                    window.confirm(
                      '가입 철회 시 등록된 모든 상품의 바로도착 서비스가 해지됩니다.\n정말로 가입을 철회하시겠습니까?',
                    )
                  ) {
                    withdrawBaroService()
                  }
                }}
              >
                바로도착 가입 철회
              </Button>
            )}
            <span className='ml-3'>
              <Button
                appearance='positive'
                size='md'
                onClick={() =>
                  window.open(
                    'https://levitinc.notion.site/688c67221f464b78a6a5e6f298ff0774?pvs=4',
                    '_blank',
                  )
                }
              >
                바로도착 상세내용 확인하기
              </Button>
            </span>
          </div>
        </div>
        <img
          className='w-3/5 pl-2'
          alt='banner'
          src='https://assets.ilevit.com/fd1cf1ef-c50a-4612-a7ed-f628bd7e9cc6.png'
        />
      </div>
    </div>
  )
}

const BaroArriveScreen = observer(() => {
  const [itemRegisterModal, setItemRegisterModal] = useState(false)
  const [itemModifyModal, setItemModifyModal] = useState(false)
  const [storageRegisterModal, setStorageRegisterModal] = useState(false)
  const [isNewLocation, setIsNewLocation] = useState('exists')
  const [baroServiceConsentStatus, setBaroServiceConsentStatus] = useState(0) // 0: nothing 1: registering 2: registered 3: withdraw
  const [targetItemIds, setTargetItemIds] = useState([])
  const [applyStatusTab, setApplyStatusTab] = useState('candidate')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [totalLength, setTotalLength] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [itemData, setItemData] = useState([])
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)
  const [updateLocationInfo, setUpdateLocationInfo] = useState(false)
  const [serviceConsentModal, setServiceConsentModal] = useState(false)
  // const limit = 15
  const loadNum = (page - 1) * limit

  useEffect(() => {
    const validateSellerBaroServiceConsent = async () => {
      const consentCondition = {
        type: 'delivery',
        subType: 'baroArrive',
        status: 'registered',
      }
      const consentResults = await backendApis.getSellerServiceConsentInfo(
        consentCondition,
      )
      if (consentResults?.status === 200) {
        const baroConsentStatus = consentResults?.data[0]?.status
        if (baroConsentStatus === 'registering') {
          setBaroServiceConsentStatus(1)
        } else if (baroConsentStatus === 'registered') {
          setBaroServiceConsentStatus(2)
        } else if (baroConsentStatus === 'withdraw') {
          setBaroServiceConsentStatus(3)
        } else {
          window.alert(
            '셀러 정보를 가져오는 중 문제가 발생했습니다. 나중에 다시 시도해 주세요.',
          )
        }
      } else {
        setBaroServiceConsentStatus(0)
      }
    }
    validateSellerBaroServiceConsent()
  }, [])

  useEffect(() => {
    setCheckedItemIds([])
    setIsAllCheckBoxChecked(false)
  }, [page])

  useEffect(() => {
    setPage(1)
    setCheckedItemIds([])
    setIsAllCheckBoxChecked(false)
  }, [limit, applyStatusTab])

  const fastArrivalCondition = {
    status: 'registered',
    type: 'baroArrive',
  }

  const fetchTargetItemIds = async (applyStatusTab) => {
    const sellerItemIdsResult = await backendApis.getValidItemIds()
    if (sellerItemIdsResult?.status !== 200) return
    const sellerItemIds = sellerItemIdsResult?.data
    const result = await backendApis.getFastArrivalItemsIds(
      fastArrivalCondition,
    )
    let filteredItemIds = []
    if (result?.status === 200) {
      const appliedItemIds = result?.data?.map((e) => {
        return e?.itemId
      })

      if (applyStatusTab === 'candidate') {
        filteredItemIds = sellerItemIds?.filter(
          (e) => !appliedItemIds?.includes(e),
        )
      }
      if (applyStatusTab === 'complete') {
        filteredItemIds = appliedItemIds
      }
      setTargetItemIds(filteredItemIds)
      setTotalLength(filteredItemIds?.length)
      return filteredItemIds
    }
    if (applyStatusTab === 'candidate') {
      filteredItemIds = sellerItemIds
    }
    if (applyStatusTab === 'complete') {
      filteredItemIds = []
    }
    setTargetItemIds(filteredItemIds)
    setTotalLength(filteredItemIds?.length)
    return filteredItemIds
  }

  const checkStorageRegistered = async () => {
    const storageInfoBySellerId =
      await backendApis.getStorageLocationInfoBySellerId()
    const isStorageRegistered = storageInfoBySellerId?.data?.filter(
      (e) => e?.addressStatus === 'registered',
    )
    if (isStorageRegistered?.length === 0) {
      window.alert(
        '등록 완료된 출고지, 반품지 정보가 없습니다.\n출고지, 반품지 정보를 먼저 등록해주세요.',
      )
      setStorageRegisterModal(true)
    } else {
      setItemRegisterModal(true)
    }
  }

  const fetchItemsData = async (applyStatusTab) => {
    let targetItemIds = []
    if (targetItemIds?.length === 0) {
      const targetItemIdsTemp = await fetchTargetItemIds(applyStatusTab)
      targetItemIds = targetItemIdsTemp
    }
    const itemIdsSegment = targetItemIds.slice(loadNum, loadNum + limit)
    const result = await backendApis.getItemsInfoWithStorageByItemIds(
      itemIdsSegment,
    )
    if (result?.status === 2000) {
      setItemData(result?.data)
    }
  }

  const SelectFunction = useCallback(() => {
    const itemsToToggle = itemData
    if (isAllCheckBoxChecked) {
      setCheckedItemIds([])
      setIsAllCheckBoxChecked(false)
    } else {
      const allItemIds = itemsToToggle.map((item) => item._id)
      setCheckedItemIds(allItemIds)
      setIsAllCheckBoxChecked(true)
    }
  }, [isAllCheckBoxChecked, applyStatusTab, itemData])

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: () => (
          <div>
            <input
              type='checkbox'
              checked={isAllCheckBoxChecked}
              onChange={() => SelectFunction()} // 전체 선택/해제를 처리하는 함수 호출
            />
          </div>
        ),
        id: 'selection',
        Cell: ({ row }) => {
          const item = row?.original
          const itemId = item?._id
          return (
            <input
              type='checkbox'
              checked={checkedItemIds.includes(itemId)}
              onChange={(e) => {
                setCheckedItemIds((prev) => {
                  const newCheckedItemIds = e.target.checked
                    ? [...prev, itemId]
                    : prev.filter((id) => id !== itemId)
                  const totalItemsLength = totalLength
                  setIsAllCheckBoxChecked(
                    newCheckedItemIds.length === totalItemsLength,
                  )
                  return newCheckedItemIds
                })
              }}
            />
          )
        },
        SelectFunction: () => {
          const itemsToToggle = itemData
          if (isAllCheckBoxChecked) {
            setCheckedItemIds([])
            setIsAllCheckBoxChecked(false)
          } else {
            const allItemIds = itemsToToggle.map((item) => item._id)
            setCheckedItemIds(allItemIds)
            setIsAllCheckBoxChecked(true)
          }
        },
      },
      {
        Header: '상품 이미지',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div className='flex flex-row items-center justify-center flex-1 w-full'>
              <img
                alt='mainImage'
                src={item?.mainImageUris ? resize(item?.mainImageUris[0]) : ''}
                className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
              />
            </div>
          )
        },
      },
      {
        Header: '신선포장 여부',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div className='flex flex-row items-center justify-center flex-1 w-full'>
              {item?.freshType === 'fresh' ? (
                <span className='font-bold text-blue-500'>신선포장 상품</span>
              ) : (
                <span className='font-bold text-slate-500'>일반포장 상품</span>
              )}
            </div>
          )
        },
      },
      // {
      //   Header: '휴무일',
      //   Cell: ({ row }) => {
      //     const item = row?.original
      //     const dayOffText = item?.dayOff
      //       ?.map((day) => OffDayList[day])
      //       .join(', ')
      //     return (
      //       <div className='flex flex-row items-center justify-center flex-1 w-full'>
      //         <span className='font-bold text-blue-500'>
      //           {dayOffText || '-'}
      //         </span>
      //       </div>
      //     )
      //   },
      // },
      {
        Header: '상품명',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <div className='flex flex-col flex-1'>
              <div>{item?.itemTitle}</div>
            </div>
          )
        },
      },
      {
        Header: '팀구매가',
        Cell: ({ row }) => {
          const item = row?.original

          return (
            <div className='flex flex-col flex-1'>
              {commaNumber(item?.teamPurchasePrice)}원
            </div>
          )
        },
      },
      {
        Header: '출고지',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <>
              <div className='flex flex-col flex-1'>
                {item?.storageLocation?.departurePostCode
                  ? item?.storageLocation?.departurePostCode
                  : '-'}
              </div>
              <div className='flex flex-col flex-1'>
                {item?.storageLocation?.departureLocation
                  ? item?.storageLocation?.departureLocation
                  : '-'}
              </div>
            </>
          )
        },
      },
      {
        Header: '반품지',
        Cell: ({ row }) => {
          const item = row?.original
          return (
            <>
              <div className='flex flex-col flex-1'>
                {item?.storageLocation?.refundPostCode
                  ? item?.storageLocation?.refundPostCode
                  : '-'}
              </div>
              <div className='flex flex-col flex-1'>
                {item?.storageLocation?.refundLocation
                  ? item?.storageLocation?.refundLocation
                  : '-'}
              </div>
            </>
          )
        },
      },
    ]

    if (applyStatusTab === 'complete') {
      const processingStatusIndex = baseColumns.findIndex(
        (column) => column.Header === '처리상태',
      )
      if (processingStatusIndex > -1) {
        baseColumns.splice(processingStatusIndex, 1)
      }

      baseColumns.splice(
        4,
        0,
        {
          Header: '배송형태',
          Cell: ({ row }) => {
            const item = row?.original
            const isTodayPickUp = possiblePickUpPostCode.includes(
              item?.storageLocation?.departurePostCode,
            )
            return (
              <>
                {isTodayPickUp ? (
                  <>
                    <div className='flex flex-col flex-1 text-blue-500 font-bold'>
                      당일도착
                    </div>
                    <div className='flex flex-col flex-1 text-green-500 font-bold'>
                      익일도착
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex flex-col flex-1 text-slate-500 font-bold'>
                      당일도착 불가
                    </div>
                    <div className='flex flex-col flex-1 text-green-500 font-bold'>
                      익일도착
                    </div>
                  </>
                )}
              </>
            )
          },
        },
        {
          Header: '예상 수거 시간',
          Cell: ({ row }) => {
            const item = row?.original
            const isTodayPickUp = possiblePickUpPostCode.includes(
              item?.storageLocation?.departurePostCode,
            )
            return (
              <>
                {isTodayPickUp ? (
                  <>
                    <div className='flex flex-col flex-1 text-blue-500 font-bold'>
                      13시 이후
                    </div>
                    <div className='flex flex-col flex-1 text-green-500 font-bold'>
                      17시 이후
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex flex-col flex-1 text-slate-500 font-bold'>
                      당일도착 불가
                    </div>
                    <div className='flex flex-col flex-1 text-green-500 font-bold'>
                      17시 이후
                    </div>
                  </>
                )}
              </>
            )
          },
        },
      )
    }
    return baseColumns
  }, [
    itemData,
    checkedItemIds,
    isAllCheckBoxChecked,
    SelectFunction,
    applyStatusTab,
  ])

  useEffect(() => {
    fetchItemsData(applyStatusTab)
  }, [applyStatusTab, page, limit, updateLocationInfo])

  useEffect(() => {}, [page, limit])

  return (
    <>
      <div className='max-w-6xl mx-auto'>
        <Page title='📦 바로도착 서비스 안내'>
          <BaroArriveInfo
            baroServiceConsentStatus={baroServiceConsentStatus}
            setBaroServiceConsentStatus={setBaroServiceConsentStatus}
            setApplyStatusTab={setApplyStatusTab}
            setServiceConsentModal={setServiceConsentModal}
          />
        </Page>
        {baroServiceConsentStatus === 2 && (
          <>
            <div className='justify-between shadow-lg rounded-2xl p-8 bg-white flex flex-col ml-10 mr-9'>
              <span className='text-lg text-slate-500 font-bold'>
                [바로도착 프로세스]
              </span>
              <span className='text-lg text-slate-500 font-bold mb-4'>
                1. 바로도착 가입 신청 - 2. 출고지 / 반품지 등록 - 3. 택배사 사전
                준비 - 4. 상품 등록 - 5. 판매 개시
              </span>
              <li className='text-lg text-blue-500 font-bold'>
                출고지 / 반품지가 등록되면, 담당 택배사에서 온보딩을 위해
                1~2영업일 내 연락드립니다.
              </li>
              <li className='text-lg text-blue-500 font-bold'>
                상품 등록, 물류사 어드민 계정 가입 등 택배사 준비작업이 완료되면
                1~2 영업일 내 판매 개시 됩니다.
              </li>
            </div>
            <div className='flex flex-row items-center justify-between flex-1 mt-10 mb-5 ml-10'>
              <div>
                <button
                  type='button'
                  onClick={() => {
                    setApplyStatusTab('candidate')
                    setTargetItemIds([])
                    setPage(1)
                  }}
                  className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
                    applyStatusTab === 'candidate'
                      ? 'border-b-4 border-positive-500 text-black'
                      : 'text-gray-400'
                  }`}
                >
                  신청 대상 상품
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setApplyStatusTab('complete')
                    setTargetItemIds([])
                    setPage(1)
                  }}
                  className={`select-none text-xl pb-1 font-bold duration-200 ease-in-out ${
                    applyStatusTab === 'complete'
                      ? 'border-b-4 border-positive-500 text-black'
                      : 'text-gray-400'
                  } w-max`}
                >
                  신청 완료 상품
                </button>
              </div>
            </div>
            <div className='flex flex-row items-center justify-between flex-1 ml-10'>
              <div className='mb-4'>
                <span className='mr-4'>
                  <Button
                    appearance='positive'
                    size='md'
                    onClick={() => {
                      setStorageRegisterModal(true)
                    }}
                  >
                    출고지 / 반품지 등록
                  </Button>
                </span>
                <Button
                  appearance='positive'
                  size='md'
                  onClick={() => {
                    if (checkedItemIds.length === 0) {
                      window.alert('선택된 상품이 없습니다.')
                      return
                    }
                    if (applyStatusTab === 'candidate') {
                      checkStorageRegistered()
                    } else {
                      setItemModifyModal(true)
                    }
                  }}
                >
                  {applyStatusTab === 'candidate'
                    ? '선택 상품 신청'
                    : '선택 상품 수정'}
                </Button>
              </div>
              <div className='mb-4 flex justify-between'>
                {/* <ItemSearchComponent onSearch={handleSearch} /> */}
                <ListLimitSelector limit={limit} setLimit={setLimit} />
              </div>
            </div>
            <div>
              {!isLoading && (
                <>
                  <div className='flex flex-col items-center flex-1 p-10 bg-white shadow-xl rounded-xl ml-10'>
                    <ItemTable
                      columns={columns}
                      data={itemData}
                      rowSelect={false}
                    />
                    <Pagination
                      pageSize={limit}
                      total={totalLength}
                      className='mt-5'
                      current={page}
                      onChange={(e) => {
                        setPage(e)
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {/* {baroServiceConsentStatus === 2 && (
          <>
            <div className='justify-between shadow-lg rounded-2xl p-8 bg-white flex flex-col ml-10 mr-9'>
              <li className='text-lg ml-10 text-blue-500 font-bold'>
                현재 정상 판매 상태입니다.
              </li>
            </div>
            <div className='flex flex-row items-center justify-between flex-1 mt-10 mb-5 ml-10'>
              <div>
                <button
                  type='button'
                  onClick={() => {
                    setApplyStatusTab('candidate')
                    setTargetItemIds([])
                    setPage(1)
                  }}
                  className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
                    applyStatusTab === 'candidate'
                      ? 'border-b-4 border-positive-500 text-black'
                      : 'text-gray-400'
                  }`}
                >
                  신청 대상 상품
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setApplyStatusTab('complete')
                    setTargetItemIds([])
                    setPage(1)
                  }}
                  className={`select-none text-xl pb-1 font-bold duration-200 ease-in-out ${
                    applyStatusTab === 'complete'
                      ? 'border-b-4 border-positive-500 text-black'
                      : 'text-gray-400'
                  } w-max`}
                >
                  신청 완료 상품
                </button>
              </div>
            </div>
            <div className='flex flex-row items-center justify-between flex-1 ml-10'>
              <div className='mb-4'>
                <span className='mr-4'>
                  <Button
                    appearance='positive'
                    size='md'
                    onClick={() => {
                      setStorageRegisterModal(true)
                    }}
                  >
                    출고지 / 반품지 등록
                  </Button>
                </span>
                <Button
                  appearance='positive'
                  size='md'
                  onClick={() => {
                    if (checkedItemIds.length === 0) {
                      window.alert('선택된 상품이 없습니다.')
                      return
                    }
                    if (applyStatusTab === 'candidate') {
                      checkStorageRegistered()
                    } else {
                      setItemModifyModal(true)
                    }
                  }}
                >
                  {applyStatusTab === 'candidate'
                    ? '선택 상품 신청'
                    : '선택 상품 수정'}
                </Button>
              </div>
              <div className='mb-4 flex justify-between'>
                <ListLimitSelector limit={limit} setLimit={setLimit} />
              </div>
            </div>
            <div>
              {!isLoading && (
                <>
                  <div className='flex flex-col items-center flex-1 p-10 bg-white shadow-xl rounded-xl ml-10'>
                    <ItemTable
                      columns={columns}
                      data={itemData}
                      rowSelect={false}
                    />
                    <Pagination
                      pageSize={limit}
                      total={totalLength}
                      className='mt-5'
                      current={page}
                      onChange={(e) => {
                        setPage(e)
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )} */}
      </div>
      {itemRegisterModal && (
        <BaroArriveItemRegisterModal
          showModal={itemRegisterModal}
          setShowModal={setItemRegisterModal}
          setIsNewLocation={setIsNewLocation}
          selectedItemIds={checkedItemIds}
          selectedItemsInfo={itemData.filter((item) =>
            checkedItemIds.includes(item._id),
          )}
          setUpdateLocationInfo={setUpdateLocationInfo}
          setApplyStatusTab={setApplyStatusTab}
        />
      )}
      {itemModifyModal && (
        <BaroArriveItemModifyModal
          showModal={itemModifyModal}
          setShowModal={setItemModifyModal}
          setIsNewLocation={setIsNewLocation}
          selectedItemIds={checkedItemIds}
          selectedItemsInfo={itemData.filter((item) =>
            checkedItemIds.includes(item._id),
          )}
          setUpdateLocationInfo={setUpdateLocationInfo}
          setApplyStatusTab={setApplyStatusTab}
        />
      )}
      {serviceConsentModal && (
        <ServiceConsentModal
          setServiceConsentModal={setServiceConsentModal}
          setBaroServiceConsentStatus={setBaroServiceConsentStatus}
          setApplyStatusTab={setApplyStatusTab}
        />
      )}
      {storageRegisterModal && (
        <StorageRegisterModal
          showModal={storageRegisterModal}
          setShowModal={setStorageRegisterModal}
          setUpdateLocationInfo={setUpdateLocationInfo}
          setApplyStatusTab={setApplyStatusTab}
        />
      )}
    </>
  )
})

export default BaroArriveScreen

import React, { useState, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import moment from 'moment'
import FastShippingOrderMent from './OrderComponents/FastShippingOrderMent'
import Format from '../utils/format'
import tableUtils from '../utils/tableUtils'
import CancelOrderButton from './buttons/CancelOrderButton'
import RevokeCancelOrderButton from './buttons/RevokeCancelOrderButton'
import Table from './ADS/molecules/table'
import Pagination from './ADS/atoms/pagination'
import shortUUID from 'short-uuid'
import { getSettlementAmount } from '../helper/order'

const PAGE_ORDER_NUM = 10

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

const CancelingPreShippingOrders = observer(({ orders, setOrders }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const translator = shortUUID()

  const columns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '주문아이디', accessor: '_id' },
      { Header: '상품아이디', accessor: 'itemId' },
      { Header: '합배송 아이디', accessor: 'addressId' },
      { Header: '상품명', accessor: 'itemTitle' },
      { Header: '옵션', accessor: 'option' },
      { Header: '수량', accessor: 'quantity' },
      { Header: '상품가격', accessor: 'totalPrice' },
      { Header: '추가지원금', accessor: 'platformSupportPrice' },
      { Header: '쿠폰할인금', accessor: 'finalDiscountAmount' },
      { Header: '정산대상금액(수수료 제)', accessor: 'settlementAmount' },
      { Header: '배송비', accessor: 'shippingFee' },
      { Header: '배송지', accessor: 'address' },
      { Header: '상세 주소', accessor: 'detailAddress' },
      { Header: '수령인', accessor: 'recipient' },
      { Header: '수령인 연락처', accessor: 'recipientPhoneNumber' },
      { Header: '주문 시점', accessor: 'timePast' },
      { Header: '취소 거부', accessor: 'revokeCancel' },
      { Header: '주문 취소', accessor: 'cancel' },
    ],
    [],
  )

  const data = useMemo(() => {
    return orders
      ?.filter(
        (order) =>
          order.status === 'canceling-pre-shipping' && !order.excelDownloadedAt,
      )
      .slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.map((order, index) => {
        let timePast = Math.ceil(
          (new Date().getTime() - new Date(order.payedAt).getTime()) /
            (1000 * 60 * 60),
        )
        const daysPast = Math.floor(timePast / 24)
        timePast %= 24
        if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
          if (order?.teamDealInfo?.subAddressInfo) {
            order.addressInfo = order?.teamDealInfo?.subAddressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          } else {
            order.addressInfo = order?.teamDealInfo?.addressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          }
        }
        return {
          ...order,
          index: index + 1 + PAGE_ORDER_NUM * pageIndex,
          itemTitle: (
            <a
              href={order.url
                ?.replace('m.coupang', 'coupang')
                ?.replace('/vm/', '/vp/')}
            >
              {order.itemInfo?.itemTitle}
            </a>
          ),
          option: tableUtils.parseOptionText(
            order?.itemInfo?.optionsInfo,
            order?.selectedOption,
          ),
          totalPrice: order?.totalPrice,
          // option: optionsText.map((option) => <div key={option}>{option}</div>),
          platformSupportPrice:
            (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1),
          finalDiscountAmount:
            order.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(order),
          itemId: order?.itemInfo?.itemId,
          addressId: `${Format.DateToday(order?.payedAt)}${
            checkIfValidUUID(order?.addressInfo?.addressId) &&
            translator.fromUUID(order?.addressInfo?.addressId || order?._id)
          }`.substring(2),
          shippingFee: order?.shippingFee,
          address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          detailAddress: order.addressInfo?.detailAddress,
          recipient: Format.Recipient(order.addressInfo?.recipient),
          recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          timePast: (
            <>
              <div>{moment(order.payedAt).format('YYYY-MM-DD HH:mm')}</div>
              <div>
                ({daysPast > 0 ? `${daysPast}일 ` : ''} {timePast} 시간 전)
              </div>
            </>
          ),
          cancel: (
            <CancelOrderButton
              order={order}
              setOrders={setOrders}
              status='canceling-pre-shipping'
            />
          ),
          revokeCancel: (
            <RevokeCancelOrderButton
              order={order}
              setOrders={setOrders}
              status='canceling-pre-shipping'
            />
          ),
        }
      })
  }, [
    orders?.filter(
      (order) =>
        order.status === 'canceling-pre-shipping' && !order.excelDownloadedAt,
    )?.length,
    pageIndex,
  ])

  const fetchNextData = async () => {
    if (SellerStore.isLoading) {
      return
    }
    if (
      pageIndex + 1 >
        Math.ceil(
          orders.filter(
            (order) =>
              order.status === 'canceling-pre-shipping' &&
              !order?.excelDownloadedAt,
          ).length / PAGE_ORDER_NUM,
        ) &&
      !setOrders
    ) {
      SellerStore.setIsLoading(true)
      const result = await backendApis.getOrdersInStatusBeforeCertainTimeStamp(
        'canceling-pre-shipping',
        orders.slice(-1)?.length > 0 && orders.slice(-1)[0]?.payedAt,
        true,
      )
      if (result?.status === 200 && result.data?.length > 0) {
        OrderStore.setDisplayedOrders([
          ...OrderStore.displayedOrders,
          ...result.data,
        ])
        setPageIndex(pageIndex + 1)
      }
      SellerStore.setIsLoading(false)
    }
  }

  useEffect(() => fetchNextData(), [pageIndex])

  return (
    <>
      <h2 className='p-5 text-2xl font-bold'>
        출고 중지 요청 중 (
        {setOrders
          ? orders?.filter((order) => {
              return (
                order.status === 'canceling-pre-shipping' &&
                !order.excelDownloadedAt
              )
            })?.length
          : OrderStore.ordersNumPerStatus['canceling-pre-shipping-pre-excel'] ||
            0}
        )
      </h2>
      <Table columns={columns} data={data} />
      <Pagination
        page={pageIndex}
        setPage={setPageIndex}
        total={
          OrderStore.ordersNumPerStatus['canceling-pre-shipping-pre-excel'] || 0
        }
        limit={PAGE_ORDER_NUM}
      />
    </>
  )
})

export default CancelingPreShippingOrders

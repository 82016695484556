import React from 'react'

const Page = ({
  children,
  title = '타이틀 prop은 필수 입력 요소입니다.',
  Icon,
  py = 10,
  mt = 10,
}) => {
  return (
    <div className={`flex flex-col flex-1 w-full px-10 py-${py} mt-${mt}`}>
      <div className='flex flex-row items-center flex-1 mb-5'>
        {Icon && <Icon className='w-8 h-8 mr-2' />}
        <div className='text-2xl font-bold select-none'>{title}</div>
      </div>
      {children}
    </div>
  )
}
export default Page

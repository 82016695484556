import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import ImageUploading from 'react-images-uploading'
import backendApis from '../utils/backendApis'
import OrderStore from '../stores/OrderStore'
import SellerStore from '../stores/SellerStore'
import AuthStore from '../stores/AuthStore'
import socket from '../utils/socket'
import ItemStore from '../stores/ItemStore'
import Log from '../utils/log'
import Button from '../components/atoms/button'
import Ping from '../components/atoms/ping'
import commaNumber from 'comma-number'
import SellerQuest from '../utils/sellerQuest'

const DealApplicationScreen = observer(() => {
  const [couponSeller, setCouponSeller] = useState(
    SellerStore.sellerInfo?.permanentMarks?.didAgreeIssuingCoupon,
  )
  const [couponAmount, setCouponAmount] = useState(
    SellerStore.sellerInfo?.permanentMarks?.repurchaseCouponAmount,
  )
  const [newCouponAmount, setNewCouponAmount] = useState()

  const select = (num, index) => {
    let upgrade
    if (index === 0) upgrade = 18
    if (index === 1) upgrade = 26
    if (index === 2) upgrade = 42
    if (index === 3) upgrade = 50
    return (
      <div className='flex flex-col items-center mx-8'>
        <input
          id={`amount_${index}`}
          name='couponAmount'
          type='radio'
          value={num}
          onChange={(e) => {
            setNewCouponAmount(e.target.value)
          }}
        />
        <div className='text-xl'>{commaNumber(num)}원</div>
        <div className='text-theme-500'>
          판매량 <span className='font-bold'>{upgrade}%</span> 증진
        </div>
        {index === 2 && (
          <div className='flex flex-row'>
            <div className='mt-2 mr-2'>
              <Ping />
            </div>
            <div className='font-bold text-theme-900'>올웨이즈 추천</div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='page max-w-7xl'>
      <div className='pageTitle'>추가 구매 유도 쿠폰</div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          marginRight: 10,
          marginBottom: 20,
          backgroudColor: 'green',
          marginLeft: 20,
          fontSize: 24,
          fontWeight: 800,
          lineHeight: 1.6,
        }}
      >
        * 비정상적인 이용 (쿠폰 사용 후 구매 취소 등)에 대해서는
        <span style={{ color: 'red', marginLeft: 6 }}>
          올웨이즈 측에서 비용을 부담합니다.
        </span>
      </div>
      <div className='flex justify-center'>
        <img
          alt=''
          src='https://d22yqrpf3lokox.cloudfront.net/156aec85-a471-4d01-8adf-e992b3301b6a.png'
          style={{ width: 1466 / 1.8, height: 624 / 1.8 }}
        />
      </div>
      <div className='p-10 leading-8 rounded-lg bg-theme-100'>
        <h3>
          1. 고객이 판매자님의 상품을 구매하면,{' '}
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            판매자님의 상품
          </span>
          에만 적용 가능한{' '}
          <span style={{ color: 'red', fontWeight: 'bold' }}>쿠폰</span>이
          발급됩니다.
        </h3>
        <h3>
          2. 고객에게는{' '}
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            재미 요소, 상품 추가 구매 요인
          </span>
          을 제공할 수 있습니다.
        </h3>
        <h3>
          3. 판매자 분께서는{' '}
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            판매량 증대와 배송비 절감을 통한 이익 증대
          </span>
          가 가능합니다.
        </h3>
        <h3>4. 해당 쿠폰 비용은 정산가에서 차감됩니다.</h3>
        <h3>5. 해당 쿠폰은 신청 즉시 발급이 시작됩니다.</h3>
        <h3>6. 원하시는 경우 신청 페이지에서 취소할 수 있습니다.</h3>
      </div>
      <div style={{ height: 48 }} />
      <div className='flex flex-col items-center'>
        {couponAmount && couponSeller && (
          <>
            <div className='px-8 py-4 text-2xl text-white rounded-lg bg-sub-300'>
              현재 신청 금액: {commaNumber(couponAmount)}원
            </div>
            <div className='h-6' />
          </>
        )}
        <div style={{ height: 20 }} />
        <div className='flex flex-row'>
          {[1000, 1500, 2000, 3000].map((each, index) => select(each, index))}
        </div>
        <div style={{ height: 32 }} />
        <div className='flex flex-row'>
          {' '}
          <Button
            className='py-4 w-60 mr-4'
            size='xxl'
            onClick={async () => {
              if (!newCouponAmount) {
                alert('신청하실 쿠폰 금액을 선택해 주세요!')
                return
              }
              const confirm = window.confirm(
                `${commaNumber(
                  newCouponAmount,
                )}원으로 재구매 쿠폰을 신청하시겠습니까?`,
              )
              if (confirm) {
                const result = await backendApis.recordRepurchaseCoupon(
                  newCouponAmount,
                )
                if (result?.status === 200) {
                  alert(
                    '신청해 주셔서 감사합니다.\n더 많은 판매량을 만들어 드리기 위해 노력하겠습니다.',
                  )
                  setCouponAmount(newCouponAmount)
                  setCouponSeller(true)
                  if (
                    !SellerQuest.questChecker(
                      'beautyQuest',
                      'repurchaseCouponPage',
                    )
                  ) {
                    await SellerQuest.questClearer(
                      'beautyQuest',
                      'repurchaseCouponPage',
                    )
                  }
                }
              }
            }}
          >
            {couponSeller ? '수정하기' : '신청하기'}
          </Button>
          <Button
            className='py-4 w-60 ml-4'
            size='xxl'
            appearance='neutral'
            onClick={async () => {
              const confirm =
                window.confirm(`재구매 쿠폰 신청을 취소하시겠습니까?`)
              if (confirm) {
                const result = await backendApis.cancelRepurchaseCoupon()
                if (result?.status === 200) {
                  alert(
                    '응해주셔서 감사합니다.\n더 많은 판매량을 만들어 드리기 위해 노력하겠습니다.',
                  )
                  setCouponAmount(null)
                  setCouponSeller(false)
                }
              }
            }}
          >
            {couponSeller ? '취소하기' : '신청하지 않기'}
          </Button>
        </div>
      </div>
      <div style={{ height: 20 }} />
    </div>
  )
})

export default DealApplicationScreen

import Page from '../../components/atoms/page'
import backendApis from '../../utils/backendApis'
import { FcPrivacy as TitleIcon } from 'react-icons/fc'
import { useEffect, useState } from 'react'
import dealConfig from '../../utils/dealConfig'
import DealItemList from './DealItemList'

const BannerComponent = ({ dealType }) => {
  if (dealType === 'tenMinDeal')
    return (
      <div className='flex flex-col flex-1 w-full p-10 mb-5 bg-white shadow-xl rounded-xl'>
        {/* <div>
          올웨이즈에서 신청하신{' '}
          <span className='font-bold text-blue-500'>딜(행사) </span>
          상품들을 확인하실 수 있습니다
        </div> */}
        <div className='mb-5 font-bold'>
          10분 핫딜은 오전/오후 8시 단 10분만 진행하는
          <span className='font-bold text-blue-500'>완전 폐쇄 매대</span>입니다.
        </div>
        <div>
          가격 경쟁력이 높을수록 더 많은 노출 기회를 획득하실 수 있습니다.
        </div>
      </div>
    )
  return <></>
}

const DealManagementScreen = () => {
  const [dealType, setDealType] = useState('tenMinDeal')
  const [dealItems, setDealItems] = useState([])

  useEffect(() => {
    const fetchDealItem = async () => {
      const extraCondition = dealConfig?.[dealType]

      const result = await backendApis.getDealItemByDealType(
        dealType,
        extraCondition,
      )
      if (result?.status === 200) {
        setDealItems(result?.data)
      } else {
        setDealItems(null)
      }
    }
    fetchDealItem()
  }, [dealType])

  return (
    <div className='bg-yellow-100 w-full'>
      {/* fix me - 통합 딜 신청 현황으로 변경 */}
      <Page title='10분 핫딜 신청 상품' Icon={TitleIcon}>
        <BannerComponent dealType={dealType} />
        <DealItemList dealType={dealType} />
      </Page>
    </div>
  )
}

export default DealManagementScreen

import Button from '../atoms/button'
import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'react-tippy'
import backendApis from '../../utils/backendApis'
import { FcNext as RightArrow, FcBarChart as PendingIcon } from 'react-icons/fc'
import RankIconSetter from './rankIconSetter'
import Format from '../../utils/format'
import ItemRankExpBar from './itemRankExpBar'
import { GrRefresh as RefreshIcon } from 'react-icons/gr'
import SellerQuest from '../../utils/sellerQuest'
import { BsQuestionSquare as AlwayzQuestion } from 'react-icons/bs'
import Tooltip2 from '../ADS/atoms/tooltip'

const itemRankReportMini = observer(
  ({ itemInfo, itemRankData, callbackFunction = () => {} }) => {
    const [testApplied, setTestApplied] = useState(false)
    const [itemRank, setItemRank] = useState(itemRankData?.itemRank)
    const [bonusScore, setBonusScore] = useState(itemRankData?.bonusScore)
    const [individualItemScore, setIndividualItemScore] = useState(
      itemRankData?.rankScore,
    )
    const isItemWinner = itemInfo?.itemWinnerInfo?.some((e) => e?.isItemWinner)
    const rankedAt = itemRankData?.rankedAt
    const pricedAt = itemInfo?.crawledItemTheLowest?.pricedAt
    const bonusScoreCondition =
      new Date(rankedAt).getTime() > new Date(pricedAt).getTime()
    useEffect(() => {
      setItemRank(itemRankData?.itemRank)
      setBonusScore(itemRankData?.bonusScore)
      setIndividualItemScore(itemRankData?.rankScore)
    }, [itemRankData])
    const [teamPurchasePriceTemp, setTeamPurchasePriceTemp] = useState(
      itemInfo.teamPurchasePrice,
    )
    const crawledPrice = itemInfo?.crawledItemTheLowest?.price || 0
    const teamPrice = itemInfo?.teamPurchasePrice
    const plusScore = () => {
      const salesRatio = (1 - teamPurchasePriceTemp / teamPrice) * 100
      const impToVisitDelta = 0.0003319728 * salesRatio
      return 3 * impToVisitDelta * 700
    }
    const estimatedIndividualItemScore = parseInt(
      individualItemScore + plusScore(),
      10,
    )
    const actualDiscountRateCalculate = (crawledPrice, teamPurchasePrice) => {
      if (!crawledPrice || parseInt(crawledPrice, 10) === 0) {
        return 0
      }
      const rate = Math.floor(
        ((crawledPrice - teamPurchasePrice) / crawledPrice) * 100,
      )
      return rate
    }
    const actualDiscountRate = actualDiscountRateCalculate(
      crawledPrice,
      teamPurchasePriceTemp,
    )
    const bonusScoreTempSetter = (
      defaultBonusScore,
      bonusScoreCondition,
      actualDiscountRate,
    ) => {
      if (!bonusScoreCondition) return defaultBonusScore
      if (actualDiscountRate <= 20) {
        return actualDiscountRate
      }
      if (actualDiscountRate > 20) {
        return 20
      }
      return 0
    }

    const estimatedTotalScore =
      estimatedIndividualItemScore +
      bonusScoreTempSetter(bonusScore, bonusScoreCondition, actualDiscountRate)
    const totalScore = individualItemScore + bonusScore

    if (
      !itemInfo.itemRankTestingSku &&
      itemRank &&
      !itemInfo.soldOut &&
      !testApplied
    ) {
      return (
        <div className='flex flex-col flex-1 select-none'>
          <div className='flex flex-row items-end mb-3 select-none'>
            <RankIconSetter
              itemRank={itemRank}
              className='w-12 h-12 mr-1 select-none'
            />
            <div className='flex flex-col'>
              <div className='flex flex-row items-center mr-5 '>
                <div>
                  {/* <div>현재</div> */}
                  <div>{totalScore}점</div>
                  <div>{Format.Price(teamPrice)}</div>
                </div>

                <div
                  className={`flex flex-row items-center ${
                    teamPrice !== teamPurchasePriceTemp ? '' : 'opacity-10'
                  }`}
                >
                  <RightArrow className='mx-1 w-7 h-7' />
                  <RankIconSetter
                    itemScore={estimatedTotalScore}
                    className='w-12 h-12 mr-1 select-none'
                  />
                  <div>
                    {/* <div className='bg'>예상</div> */}
                    <div className='font-bold text-green-600'>
                      {estimatedTotalScore}점
                    </div>
                    <div className='font-bold text-green-600'>
                      {Format.Price(teamPurchasePriceTemp)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-col items-end flex-1'>
              <div>
                <Tooltip
                  arrow='true'
                  theme='dark'
                  title='💡등급이 높아지면 노출도가 올라가요!'
                  animation='fade'
                  position='top'
                >
                  <Button
                    appearance='positiveSub'
                    size='lg'
                    onClick={async () => {
                      setTeamPurchasePriceTemp(
                        teamPurchasePriceTemp * (100 / 105),
                      )
                    }}
                  >
                    점수UP
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center flex-1'>
            <ItemRankExpBar score={estimatedTotalScore} />
            <Button
              className='mb-2'
              appearance='transparent'
              onClick={() => {
                setTeamPurchasePriceTemp(itemInfo.teamPurchasePrice)
              }}
            >
              <RefreshIcon />
            </Button>
          </div>
          {/* {teamPrice !== teamPurchasePriceTemp ? ( */}
          <Button
            className='mt-3 shadow-md '
            appearance={`${
              teamPrice !== teamPurchasePriceTemp ? 'positive' : 'disabled'
            }`}
            disabled={teamPrice === teamPurchasePriceTemp}
            size='lg'
            onClick={async () => {
              const newOptionsInfo = JSON.parse(
                JSON.stringify(itemInfo.optionsInfo),
              )
              newOptionsInfo?.optionPrices?.flat()?.forEach((option) => {
                if (option?.teamPurchasePrice === teamPrice) {
                  option.teamPurchasePrice = teamPurchasePriceTemp
                }
              })
              const newTeamPurchasePrice = Math.min(
                ...newOptionsInfo?.optionPrices
                  ?.flat()
                  ?.map((option) => option?.teamPurchasePrice),
              )

              if (
                newTeamPurchasePrice &&
                Number.isFinite(newTeamPurchasePrice) &&
                parseInt(newTeamPurchasePrice, 10) > 0
              ) {
                if (
                  newTeamPurchasePrice < 2500 &&
                  !window.confirm('판매가가 2500원 미만이에요. 진행하시겠어요?')
                ) {
                  return
                }
                const result1 = await backendApis.updateItemRankTest(
                  itemInfo,
                  parseInt(teamPurchasePriceTemp, 10),
                )
                const result2 =
                  await backendApis.recordSellerAutonomousPriceChange(
                    itemInfo._id,
                    itemInfo.optionsInfo,
                    newOptionsInfo,
                    teamPrice,
                    parseInt(newTeamPurchasePrice, 10),
                    totalScore,
                    estimatedTotalScore,
                    'rankReportMini',
                  )
                if (result1?.status === 2000 && result2?.status === 2000) {
                  callbackFunction()
                  setTestApplied(true)
                  alert(
                    `[${itemInfo.itemTitle}] 등급 재배정 신청 완료되었습니다.`,
                  )
                  if (
                    !SellerQuest.questChecker(
                      'priceQuest',
                      'itemRankReportMini',
                    )
                  ) {
                    await SellerQuest.questClearer(
                      'priceQuest',
                      'itemRankReportMini',
                    )
                  }
                } else {
                  alert(
                    '입력값에 오류가 있습니다. 판매자 지원센터로 문의주세요.',
                  )
                }
              } else {
                alert('입력값에 오류가 있습니다. 판매자 지원센터로 문의주세요.')
              }
            }}
          >
            등급 재배정 신청
          </Button>
          {/* ) : (
          <></>
        )} */}
        </div>
      )
    }
    if ((itemInfo.itemRankTestingSku && !itemInfo.soldOut) || testApplied) {
      return (
        <div className='flex flex-row'>
          <PendingIcon className='mr-2' />
          <div className='flex flex-col font-bold'>
            <div>아이템 등급 테스트 진행 중입니다.</div>
            <div className='text-sm font-normal text-gray-500'>
              테스트 중에도 정상적으로 판매가 진행됩니다.
            </div>
            <div className='text-sm font-normal text-gray-500'>
              테스트는 평균적으로 24시간 이내에 완료됩니다.
            </div>
          </div>
        </div>
      )
    }
    return <></>
  },
)

export default itemRankReportMini

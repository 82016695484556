const getCouponSettlementAmount = (order) => {
  const selectedCouponFinalDiscountAmount =
    order?.selectedCouponInfo?.finalDiscountAmount || 0

  const couponSettlementAmountWithoutNewYear24Deal =
    (order?.selectedCouponInfo?.couponInfo?.type === 'bySeller' ||
      order?.selectedCouponInfo?.condition?.type === 'bySeller') &&
    !order?.selectedCouponInfo?.condition?.noCalculate
      ? 0
      : Number(selectedCouponFinalDiscountAmount)
  const couponSettlementAmountForNewYear24Deal =
    order?.selectedCouponInfoForNewYear24Deal?.finalDiscountAmount || 0

  const couponSettlementAmount =
    couponSettlementAmountWithoutNewYear24Deal +
    couponSettlementAmountForNewYear24Deal

  return couponSettlementAmount
}

export default getCouponSettlementAmount

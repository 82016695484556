import React, { useState, useEffect } from 'react'
import { Tooltip as TooltipOrigin } from 'react-tippy'
import { Button, Divider } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Cookies from 'universal-cookie'
import { CloseOutlined } from '@ant-design/icons'

const CommisionChangeNoticeModal = () => {
  // 쿠키 & 날짜로 체크
  const [showModal, setShowModal] = useState(false)
  const onClose = () => {
    setShowModal(false)

    const cookies = new Cookies()
    // 만료 시간을 오늘 자정으로 설정
    const tomorrow = new Date()
    tomorrow.setHours(23, 59, 59, 999) // 오늘 23:59:59로 설정

    cookies.set('commisionChangeNoticeModal', 'closed', {
      path: '/',
      expires: tomorrow,
    })
  }

  useEffect(() => {
    const cookies = new Cookies()
    const startDate = new Date('2024-11-28') // 공지 시작일
    const endDate = new Date('2024-12-05') // 공지 종료일 (7일간)
    const today = new Date()

    // 공지 기간 내이고, 오늘 쿠키가 없을 때만 모달 표시
    if (
      today >= startDate &&
      today <= endDate &&
      !cookies.get('commisionChangeNoticeModal')
    ) {
      setShowModal(true)
    }
  }, [])

  const Header = () => {
    return (
      <div className='flex flex-row items-center justify-between w-full p-4'>
        <div className='text-2xl font-bold flex-1 w-full flex-row text-center'>
          [공지] 올웨이즈 수수료 개편 공지
        </div>
        <div>
          <CloseOutlined
            className='cursor-pointer opacity-50 text-2xl hover:opacity-30'
            style={{ fontSize: '24px', strokeWidth: '1' }}
            onClick={onClose}
          />
        </div>
      </div>
    )
  }

  const Body = () => {
    const content = (
      <>
        <div className='text-sm'>
          <p>안녕하세요, 올웨이즈 판매자 여러분.</p>
          <div className='mb-6' />
          <p>항상 올웨이즈 플랫폼을 믿고 함께해 주셔서 감사합니다.</p>
          <p>
            올웨이즈는 판매자분들께 더 나은 판매 환경과 서비스를 제공하고,
            나아가 플랫폼의 지속 가능한 성장을 이루기 위해 오는 2025년 1월
            1일부터 판매수수료 체계 개편을 시행합니다.
          </p>
          <div className='mb-6' />
          <div className='px-8 py-5 bg-gray-50 rounded-xl'>
            <div className='mb-5'>
              <span className='px-2 py-1 rounded-lg bg-blue-100 text-blue-500 font-bold'>
                수수료 체계 개편안
              </span>
            </div>
            <div className='flex flex-row items-center flex-1 w-full mb-2'>
              <span className='bg-blue-500 rounded-full mr-2 px-2 py-0.5 text-white font-bold'>
                1
              </span>
              <div>변경 전:</div>
              <div className='ml-8'>결제수수료 3.5% (VAT 미포함)</div>
            </div>
            <Divider className='my-' />
            <div className='flex flex-row items-center flex-1 w-full'>
              <span className='bg-blue-500 rounded-full mr-2 px-2 py-0.5 text-white font-bold'>
                2
              </span>
              <div>변경 후:</div>
              <div className='ml-8'>
                <ul className='list-disc space-y-1'>
                  <li>결제수수료 3.5% + 판매수수료 1.5% (VAT 미포함)</li>
                  <li>
                    서버 이용료: 49,000원 (VAT 미포함)
                    <ul className='list-disc ml-4 mt-1'>
                      <li>부과 단위: 판매자 단위</li>
                      <li>
                        매출 기준: 월 매출 100만원 이상
                        <ul className='list-disc ml-4 mt-1'>
                          *매출: 월 단위 정산 대상 금액의 합계
                        </ul>
                      </li>
                      <li>정산 시기: 매월 둘째주 정산 시 차감</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className='mt-6'>
            이번 판매수수료 조정은 판매자분들의 정산 안정성 강화와 성장 발판
            마련을 위한 결정이며, 앞으로 올웨이즈는 장기적인 플랫폼 안정성
            확보와 더불어 판매자분들과의 동반성장을 위해 최선의 노력을
            다하겠습니다. 감사합니다.
          </p>
          <div className='mt-6 flex flex-row items-center justify-between w-full flex-1'>
            <div
              className='px-4 py-2 bg-gray-50 rounded-xl font-bold underline text-gray-500 cursor-pointer'
              onClick={() => {
                window.open(
                  'https://alwayz-seller.gitbook.io/sellerguide/dmhN91nCiBbbGpstPtx6',
                  '_blank',
                )
              }}
              aria-hidden='true'
            >
              FAQ 살펴보기
            </div>
            <div
              className='px-4 py-2 bg-gray-50 rounded-xl font-bold underline text-gray-500 cursor-pointer'
              onClick={() => {
                window.open(
                  'https://drive.google.com/file/d/1xJTBRiaVMbIKJ7mlfRO5yM30ZkNoJFlZ/view?usp=sharing',
                  '_blank',
                )
              }}
              aria-hidden='true'
            >
              수수료 체계 개편안 및 FAQ 다운로드
            </div>
          </div>
        </div>
      </>
    )

    return <div className='p-5'>{content}</div>
  }

  const Footer = () => {
    return (
      <div className='p-4 flex-1 flex w-full items-center justify-center rounded-b-lg'>
        <div className='w-60'>
          <Button
            type='primary'
            size='large'
            className='flex items-center justify-center w-full'
            style={{
              backgroundColor: '#1258A4',
              color: 'white',
              borderColor: '#1258A4',
              borderWidth: '2px',
              fontWeight: 'bold',
            }}
            onClick={onClose}
          >
            확인
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex px-2 py-10 overflow-y-scroll outline-none'>
            <div className='relative w-full max-w-3xl mx-auto my-auto shadow-lg'>
              <div className='relative flex p-8 flex-col justify-between flex-1 w-full bg-white border-0 rounded-lg outline-none'>
                <Header />
                <Body />
                <Footer />
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}

export default CommisionChangeNoticeModal

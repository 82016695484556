import React, { useEffect, useState, useMemo } from 'react'

import { Tooltip as TooltipOrigin } from 'react-tippy'
import { observer } from 'mobx-react-lite'
import ItemTable from '../../components/ADS/molecules/itemTable'
import Button from '../../components/atoms/button'
import PostCodeInput from '../../components/atoms/postCodeInput'
import backendApis from '../../utils/backendApis'

const Header = ({ modalTitle, onClose }) => {
  return (
    <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
      <h3 className='text-xl font-bold text-black'>{modalTitle}</h3>
      <div className='flex flex-row items-center justify-end flex-1'>
        <TooltipOrigin
          arrow='true'
          theme='dark'
          title='❎ 키보드 esc로도 종료하실 수 있어요.'
          animation='fade'
          position='top'
        >
          <button
            type='button'
            onClick={onClose}
            className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
          >
            esc
          </button>
        </TooltipOrigin>
      </div>
    </div>
  )
}

const Body = () => {
  return (
    <div className='flex flex-row items-center flex-1 p-5'>
      <ul className='list-disc ml-5'>
        <li>
          바로도착 상품을 등록하기 위해서{' '}
          <span className='text-blue-500 font-bold underline underline-offset-4'>
            주소지 등록
          </span>
          이 필요합니다.
        </li>
        <li className='mt-1'>
          출고지, 반품지 구분 없이{' '}
          <span className='text-blue-500 font-bold underline underline-offset-4'>
            바로도착 상품 배송 시 사용될 모든 주소지
          </span>
          를 등록해주세요
        </li>
        <li className='mt-1'>
          출고지/반품지 등록 시{' '}
          <span className='text-blue-500 font-bold underline underline-offset-4'>
            택배사 등록을 위해
          </span>{' '}
          일정 기간이 소요됩니다. 등록 완료 시 문자메시지가 전송됩니다.
        </li>
      </ul>
    </div>
  )
}

const StorageRegisterModal = observer(
  ({
    showModal,
    setShowModal,
    onClose = () => {},
    setUpdateLocationInfo = () => {},
  }) => {
    const [addressList, setAddressList] = useState([]) // 주소 정보를 저장할 배열 상태
    const [address, setAddress] = useState('')
    const [detailAddress, setDetailAddress] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [updateStorageModal, setUpdateStorageModal] = useState(false)
    const [isLocationRegistered, setIsLocationRegistered] = useState(false)

    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
          setShowModal(false)
        }
      }
      document.addEventListener('keydown', handleKeyPress)
      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }, [onClose])

    const handleClose = () => {
      setShowModal(false)
      onClose()
    }

    // 그냥 패치해서 가져오는 수밖에....
    useEffect(() => {
      const fetchStorageLocationInfo = async () => {
        const fetchResult = await backendApis.getStorageLocationInfoBySellerId()
        if (fetchResult?.status === 2000) {
          setAddressList(fetchResult?.data)
        }
      }
      fetchStorageLocationInfo()
    }, [isLocationRegistered])

    const updateStorageLocationInfo = async () => {
      const fullAddress = `${address} ${detailAddress}`
      // 이미 등록된 주소인지 인덱스 체크
      const indexCondition = {
        type: 'address',
        addressInfo: fullAddress,
        address,
        addressDetail: detailAddress,
        addressStatus: 'registering',
        addressZipCode: zipCode,
      }
      // status : 200 은 이미 등록된 인덱스가 있음
      // status : 400 은 등록된 인덱스가 없음 >> 신규 인덱스 생성 필요
      const checkAddressIndexForCj = await backendApis.checkIndexForCj(
        indexCondition,
      )

      if (checkAddressIndexForCj?.data?.length === 0) {
        const updateAddressIndexForCj = await backendApis.updateIndexForCj(
          indexCondition,
        )
        if (updateAddressIndexForCj?.status !== 200) {
          window.alert(
            '주소지 정보 업데이트에 실패했습니다.\n잠시 후 다시 시도해주세요.',
          )
        }
        if (updateAddressIndexForCj?.status === 200) {
          setIsLocationRegistered(true)
          window.alert('신규 주소지 정보가 등록되었습니다.')
        }
      } else {
        setIsLocationRegistered(true)
        window.alert('신규 주소지 정보가 등록되었습니다.')
      }
    }

    const columns = useMemo(
      () => [
        {
          Header: '등록상태',
          Cell: ({ row }) => {
            const item = row.original
            return (
              <>
                <div className='flex flex-col flex-1'>
                  {item?.addressStatus === 'registered' ? (
                    <div className='font-bold text-blue-500 p-1 rounded-md'>
                      등록완료
                    </div>
                  ) : (
                    <div className='text-slate-500 font-bold p-1 rounded-md'>
                      등록대기
                    </div>
                  )}
                </div>
              </>
            )
          },
        },
        {
          Header: '우편번호',
          Cell: ({ row }) => {
            const item = row.original
            return (
              <div className='flex flex-col flex-1'>
                <div>{item?.addressZipCode}</div>
              </div>
            )
          },
        },
        {
          Header: '주소지',
          Cell: ({ row }) => {
            const item = row.original
            return (
              <div className='flex flex-col flex-1'>{item?.addressInfo}</div>
            )
          },
        },
      ],
      [],
    )
    const modalTitle = '출고지 / 반품지 정보'
    return (
      <>
        {showModal && (
          <>
            <div className='fixed inset-0 z-50 flex items-center justify-center px-2 outline-none ml-[240px]'>
              <div className='relative w-full max-w-[150vh] mx-auto my-6 shadow-lg overflow-y-auto max-h-[80vh]'>
                <div className='relative flex flex-col w-full bg-white border-0 outline-none'>
                  <div className='sticky top-0 z-10 bg-white'>
                    <Header
                      modalTitle={modalTitle}
                      onClose={() => {
                        handleClose()
                      }}
                    />
                    <Body />
                    <div className='ml-8 mt-5'>
                      <span>
                        <Button
                          appearance={updateStorageModal ? 'gray' : 'positive'}
                          size='md'
                          onClick={() => {
                            setAddress('')
                            setZipCode('')
                            setDetailAddress('')
                            setIsLocationRegistered(false)
                            setUpdateLocationInfo(false)
                            {
                              updateStorageModal === true
                                ? setUpdateStorageModal(false)
                                : setUpdateStorageModal(true)
                            }
                          }}
                        >
                          {updateStorageModal ? '입력취소' : '출고/반품지 신청'}
                        </Button>
                      </span>
                    </div>
                    {updateStorageModal && (
                      <div>
                        <div className='flex flex-col w-3/4 items-center flex-1 bg-white p-10'>
                          <PostCodeInput
                            setAddress={setAddress}
                            setZipCode={setZipCode}
                          />
                        </div>
                        {address && zipCode && (
                          <div className='ml-10'>
                            <div className='flex flex-row items-center flex-1'>
                              <div className='mr-10 font-bold text-blue-500'>
                                우편번호
                              </div>
                              <div>{zipCode}</div>
                            </div>
                            <div className='flex flex-row items-center flex-1'>
                              <div className='mr-10 mt-3 font-bold text-blue-500'>
                                주소지
                              </div>
                              <div className='ml-3 mt-3'>{address}</div>
                            </div>
                            <div className='flex flex-row items-center flex-1'>
                              <div className='mr-10 mt-3 font-bold text-blue-500'>
                                상세주소
                              </div>
                              <input
                                type='text'
                                className='mt-4 p-2 border rounded'
                                placeholder='추가 정보를 입력하세요'
                                value={detailAddress}
                                onChange={(e) =>
                                  setDetailAddress(e.target.value)
                                }
                              />
                              <span className='ml-4 mt-3'>
                                <Button
                                  appearance='positive'
                                  size='md'
                                  onClick={() => {
                                    if (detailAddress) {
                                      window.confirm(
                                        '입력하신 주소지 정보를 등록하시겠습니까?\n등록까지 약 3영업일 정도 소요될 수 있습니다.',
                                      )
                                        ? updateStorageLocationInfo()
                                        : window.alert(
                                            '주소지 등록이 취소되었습니다.',
                                          )
                                      setUpdateStorageModal(false)
                                      setUpdateLocationInfo(true)
                                    } else {
                                      alert('상세주소를 입력해주세요')
                                    }
                                  }}
                                >
                                  신규 주소지 신청
                                </Button>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className='flex flex-col items-center flex-1 bg-white p-10'>
                      <ItemTable
                        columns={columns}
                        data={addressList}
                        rowSelect={false}
                      />
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => {
                      setShowModal(false)
                    }}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
          </>
        )}
      </>
    )
  },
)

export default StorageRegisterModal

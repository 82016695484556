import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MainScreen from '../../screens/MainScreen'
import RegisterItemScreen from '../../screens/RegisterItemScreen'
import ItemsScreen from '../../screens/ItemsScreen'
import TodayDeparturesScreen from '../../screens/TodayDeparturesScreen'
import OrdersShippingScreen from '../../screens/OrdersShippingScreen'
import CancelingPreShippingsScreen from '../../screens/CancelingPreShippingsScreen'
import RefundsScreen from '../../screens/RefundsScreen'
import OrdersScreen from '../../screens/OrdersScreen'
import SettlementsScreen from '../../screens/SettlementsScreen'
import SalesScreen from '../../screens/SalesScreen'
import TaxInvoiceScreen from '../../screens/TaxInvoiceScreen'
import ItemQuestionsScreen from '../../screens/ItemQuestionsScreen'
import ItemDetailScreen from '../../screens/ItemDetailScreen'
import ResetPasswordScreen from '../../screens/ResetPasswordScreen'
import CancelDetailScreen from '../../screens/CancelDetailScreen'
import UserOrdersScreen from '../../screens/UserOrdersScreen'
import CustomerMessageScreen from '../../screens/CustomerMessageScreen'
import RegisterSellerBasicsScreen from '../../screens/RegisterSellerBasicsScreen'
import EditSellerInfoScreen from '../../screens/EditSellerInfoScreen'
import DealApplicationScreen from '../../screens/DealApplicationScreen'
import SellerSupportScreen from '../../screens/SellerSupportScreen'
import ReviewCouponRegisterScreen from '../../screens/ReviewCouponRegisterScreen'
import ItemReviewsScreen from '../../screens/ItemReviewsScreen'
import ItemPenaltyScreen from '../../screens/ItemPenaltyScreen'
import PlayAutoScreen from '../../screens/PlayAutoScreen'
import ItemPenaltyExcuseScreen from '../../screens/ItemPenaltyExcuseScreen'
import WelcomeScreen from '../../screens/WelcomeScreen'
import SellerRankScreen from '../../screens/SellerRankScreen'
import TimeDealApplicationScreen from '../../screens/TimeDealApplicationScreen'
import ReviewEventExplainScreen from '../../screens/ReviewEventExplainScreen'
import AgreeToPolicyScreen from '../../screens/AgreeToPolicyScreen'

import AdCreditScreen from '../../screens/ad/AdCreditScreen'
import LureDealApplicationScreen from '../../screens/LureDealApplicationScreen'
import CouponScreen from '../../screens/CouponScreen'
import AdScreen from '../../screens/ad/AdScreen'
import AdMonitoringScreen from '../../screens/ad/AdMonitoringScreen'
import BrandSellerApplication from '../../screens/BrandSellerApplication'
import SellerRevenueDetailReportScreen from '../../screens/SellerRevenueDetailReportScreen'
import RefundingOrders from '../RefundingOrders'

import TreasureDealApplicationScreen from '../../screens/TreasureDealApplicationScreen'
import TenMinDealApplicationScreen from '../../screens/TenMinDealApplicationScreen'
import AdTicketVerificationScreen from '../../screens/ad/AdTicketVerificationScreen'
import SellerNoticeScreen from '../../screens/SellerNoticeScreen'
import ItemWinnerScreen from '../../screens/ItemWinnerScreen'
import FirstItemRegisterNudgeScreen from '../../screens/FirstItemRegisterNudgeScreen'
import DirectPurchaseScreen from '../../screens/DirectPurchaseScreen'
import CooperativeCompanyApplciation from '../../screens/CooperativeCompanyApplication'
import ReviewTransferApplication from '../../screens/ReviewTransferApplication'
import OneClickItemRegisterScreen from '../../screens/OneClickItemRegisterScreen'
import DealManagementScreen from '../../screens/deal/DealManagementScreen'
import CheapabilityNudgeScreen from '../../screens/CheapabilityNudgeScreen'
import BaroArriveScreen from '../../screens/BaroArriveScreen'
import OrdersShippingTypeScreen from '../../screens/OrdersShippingTypeScreen'
import BaroArriveOrdersShippingScreen from '../../screens/BaroArriveOrdersShippingScreen'
import BigDealApplicationScreen from '../../screens/BigDealApplicationScreen'
import OptionBiddingScreen from '../../screens/OptionBiddingScreen'
import { getDefaultBigDealPageInfo } from '../../utils/dealUtils'
import ItemQualityManagementScreen from '../../screens/ItemQualityManagementScreen'

const MainPageRenderer = ({ sellerInfo }) => {
  if (sellerInfo?.businessRegistrationNumber) {
    return <WelcomeScreen />
  }
  return <MainScreen />
}

const AdminRouter = ({ sellerInfo }) => {
  const sellerFilters = sellerInfo?.sellerFilters
  const getValueFromFilters = (sellerFilters, key) => {
    if (!sellerFilters) return null
    for (const filter of sellerFilters) {
      if (filter.key === key) {
        return filter.value
      }
    }
    return null
  }

  const mdManagedBrandName = getValueFromFilters(
    sellerFilters,
    'mdManagedBrandName',
  )
  const hasChuseokDeal24Filter = getValueFromFilters(
    sellerFilters,
    'chuseokDeal24',
  )
  const dealMinimumDiscountRate = getValueFromFilters(
    sellerFilters,
    'dealMinimumDiscountRate',
  )

  const { fixedMinimumDiscountRate, commissionRate } =
    getDefaultBigDealPageInfo('chuseokDeal24')

  const isChuseokDeal24BrandSeller =
    mdManagedBrandName && hasChuseokDeal24Filter

  return (
    <Switch>
      {/* 판매자센터 홈 */}
      <Route exact path='/'>
        <MainPageRenderer sellerInfo={sellerInfo} />
      </Route>
      <Route path='/items/registrations'>
        <RegisterItemScreen />
      </Route>
      <Route path='/items/details'>
        <ItemDetailScreen />
      </Route>
      <Route path='/items'>
        <ItemsScreen />
      </Route>
      <Route path='/third-party/play-auto'>
        <PlayAutoScreen />
      </Route>
      <Route path='/seller/notice'>
        <SellerNoticeScreen />
      </Route>
      <Route path='/ad-apply'>
        <AdScreen type='normal' />
      </Route>
      <Route path='/ad-cpm-apply'>
        <AdScreen type='cpm' />
      </Route>
      <Route path='/alfarm-ad-apply'>
        <AdScreen type='alfarm' />
      </Route>
      <Route path='/timedeal-apply'>
        <TimeDealApplicationScreen />
      </Route>
      <Route path='/cheapability'>
        <CheapabilityNudgeScreen />
      </Route>
      <Route path='/item-winner'>
        <ItemWinnerScreen />
      </Route>
      <Route path='/soonsak-apply'>
        <LureDealApplicationScreen />
      </Route>
      <Route path='/treasuredeal-apply'>
        <TreasureDealApplicationScreen sellerInfo={sellerInfo} />
      </Route>
      <Route path='/deal-management'>
        <DealManagementScreen sellerInfo={sellerInfo} />
      </Route>
      <Route path='/tenmindeal-apply'>
        <TenMinDealApplicationScreen />
      </Route>
      <Route path='/review-transfer-apply'>
        <ReviewTransferApplication />
      </Route>
      <Route path='/coupon-explain'>
        <CouponScreen />
      </Route>
      <Route path='/refunding-orders'>
        <RefundingOrders />
      </Route>
      {/* <Route path='/unit-option'>
        <OptionBiddingScreen />
      </Route> */}
      <Route path='/item-quality-management'>
        <ItemQualityManagementScreen />
      </Route>
      <Route path='/deals'>
        <DealApplicationScreen />
      </Route>
      <Route path='/chuseokdeal24-apply'>
        <BigDealApplicationScreen
          mdManagedBrandName={
            isChuseokDeal24BrandSeller ? mdManagedBrandName : undefined
          }
          fixedMinimumDiscountRate={
            isChuseokDeal24BrandSeller
              ? dealMinimumDiscountRate
              : fixedMinimumDiscountRate
          }
          dealType='chuseokDeal24'
          commissionRate={isChuseokDeal24BrandSeller ? 0.0727 : commissionRate}
        />
      </Route>

      {/* 고객 관리 */}
      <Route path='/review-event'>
        <ReviewEventExplainScreen />
      </Route>
      <Route path='/review-coupon'>
        <ReviewCouponRegisterScreen />
      </Route>
      {/* <Route path='/review-experience-v3'>
        <ReviewExperienceReigsterScreenV3 />
      </Route> */}
      <Route path='/customers'>
        <CustomerMessageScreen />
      </Route>
      <Route path='/item-questions'>
        <ItemQuestionsScreen />
      </Route>
      <Route path='/item-reviews'>
        <ItemReviewsScreen />
      </Route>

      {/* 주문/배송 관리 */}
      <Route path='/shipping-type'>
        <OrdersShippingTypeScreen />
      </Route>
      <Route path='/baro-arrive'>
        <BaroArriveScreen />
      </Route>
      <Route path='/today-departures'>
        <TodayDeparturesScreen />
      </Route>
      <Route path='/baro-arrive-shippings'>
        <BaroArriveOrdersShippingScreen />
      </Route>
      <Route path='/shippings'>
        <OrdersShippingScreen />
      </Route>
      <Route path='/ad-credit'>
        <AdCreditScreen />
      </Route>
      <Route path='/canceling-shippings'>
        <CancelingPreShippingsScreen />
      </Route>
      <Route path='/ad-credit'>
        <AdCreditScreen />
      </Route>
      <Route path='/refunds'>
        <RefundsScreen />
      </Route>
      <Route path='/orders'>
        <OrdersScreen />
      </Route>
      <Route path='/users'>
        <UserOrdersScreen />
      </Route>
      <Route path='/canceling-detail'>
        <CancelDetailScreen />
      </Route>

      {/* 정산 관리 */}
      <Route path='/settlements'>
        <SettlementsScreen />
      </Route>
      <Route path='/sales'>
        <SalesScreen />
      </Route>

      {/* 세금계산서 관리 */}
      <Route path='/tax-invoice'>
        <TaxInvoiceScreen />
      </Route>

      {/* 판매자 정보 관리 */}
      <Route path='/seller-rank'>
        <SellerRankScreen />
      </Route>
      <Route path='/seller-info'>
        <EditSellerInfoScreen />
      </Route>
      <Route path='/password-reset'>
        <ResetPasswordScreen />
      </Route>
      <Route path='/item-penalty'>
        <ItemPenaltyScreen />
      </Route>
      <Route path='/seller-brand'>
        <BrandSellerApplication />
      </Route>
      <Route path='/penalty-excuse-write'>
        <ItemPenaltyExcuseScreen />
      </Route>

      {/* 매출 관리 */}
      <Route path='/seller-revenue/details'>
        <SellerRevenueDetailReportScreen />
      </Route>

      {/* 기타 */}
      <Route path='/seller-support'>
        <SellerSupportScreen />
      </Route>
      <Route path='/agree-to-policy'>
        <AgreeToPolicyScreen />
      </Route>
      <Route path='/register-basics'>
        <RegisterSellerBasicsScreen />
      </Route>
      <Route path='/ad-monitoring'>
        <AdMonitoringScreen />
      </Route>
      <Route path='/ad-ticket'>
        <AdTicketVerificationScreen />
      </Route>
      <Route path='/direct-purchase'>
        <DirectPurchaseScreen />
      </Route>
      <Route path='/cooperative-company'>
        <CooperativeCompanyApplciation />
      </Route>
    </Switch>
  )
}
export default AdminRouter

const OptionChangeHistoryTable = ({ optionsInfo }) => {
  return (
    <table>
      <thead className='text-base'>
        <tr>
          <th>
            {optionsInfo.optionNames.length === 1
              ? optionsInfo.optionNames[0] || '옵션 1'
              : `${optionsInfo.optionNames[1] || '옵션 2'} / ${
                  optionsInfo.optionNames[0] || '옵션 1'
                }`}
          </th>
          {optionsInfo.totalOptions[0].map((option, index) => (
            <th key={option.name + index.toString()}>
              {option.name || `옵션 1_세부 옵션 ${index + 1}`}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className='text-base '>
        {optionsInfo.optionNames.length === 1 ? (
          <tr>
            <td>세부 정보</td>
            {optionsInfo.totalOptions[0].map(
              (firstOption, firstOptionIndex) => (
                <td key={firstOption?.name + firstOptionIndex.toString()}>
                  <div className='flex flex-row items-center justify-between mb-2'>
                    <div>개인 구매가 (원)</div>
                    <TableValue
                      value={
                        optionsInfo.optionPrices[firstOptionIndex]
                          .individualPurchasePrice
                      }
                    />
                  </div>
                  <div className='flex flex-row items-center justify-between mb-2'>
                    팀 구매가 (원)
                    <TableValue
                      value={
                        optionsInfo.optionPrices[firstOptionIndex]
                          .teamPurchasePrice
                      }
                    />
                  </div>

                  <div className='flex flex-row items-center justify-between mb-2'>
                    지원금
                    <TableValue
                      value={
                        optionsInfo.optionPrices[firstOptionIndex]
                          .platformSupportPrice
                      }
                    />
                  </div>

                  <div className='flex flex-row items-center justify-between mb-2'>
                    재고량
                    <TableValue
                      value={
                        optionsInfo.optionPrices[firstOptionIndex].stockNumber
                      }
                    />
                  </div>
                  <div className='flex flex-row items-center justify-between mb-2'>
                    판매자 상품코드
                    <TableValue
                      value={
                        optionsInfo.optionPrices[firstOptionIndex]?.optionCode
                      }
                    />
                  </div>
                </td>
              ),
            )}
          </tr>
        ) : (
          optionsInfo.totalOptions[1].map((secondOption, secondOptionIndex) => (
            <tr key={secondOptionIndex?.toString()}>
              <td>
                {secondOption.name ||
                  `옵션 2_세부 옵션 ${secondOptionIndex + 1}`}
              </td>
              {optionsInfo.totalOptions[0].map(
                (firstOption, firstOptionIndex) => (
                  <td key={firstOptionIndex?.toString()}>
                    <div>
                      <div className='flex flex-row items-center justify-between mb-2'>
                        개인 구매가 (원)
                        <TableValue
                          value={
                            optionsInfo.optionPrices[firstOptionIndex][
                              secondOptionIndex
                            ].individualPurchasePrice
                          }
                        />
                      </div>
                      <div className='flex flex-row items-center justify-between mb-2'>
                        팀 구매가 (원)
                        <TableValue
                          value={
                            optionsInfo.optionPrices[firstOptionIndex][
                              secondOptionIndex
                            ].teamPurchasePrice
                          }
                        />
                      </div>
                      <div className='flex flex-row items-center justify-between mb-2'>
                        재고량
                        <br />
                        <TableValue
                          value={
                            optionsInfo.optionPrices[firstOptionIndex][
                              secondOptionIndex
                            ].stockNumber
                          }
                        />
                      </div>
                      <div className='flex flex-row items-center justify-between mb-2'>
                        판매자 상품코드
                        <TableValue
                          value={
                            optionsInfo.optionPrices[firstOptionIndex][
                              secondOptionIndex
                            ].optionCode
                          }
                        />
                      </div>
                    </div>
                  </td>
                ),
              )}
            </tr>
          ))
        )}
      </tbody>
    </table>
  )
}

const TableValue = ({ value }) => <p className='px-2 py-1'>{value}</p>

export default OptionChangeHistoryTable

import React from 'react'

const SubTitle = ({ children, className }) => {
  return (
    <>
      <div
        className={`select-none flex flex-row items-center text-xl font-bold ${className}`}
      >
        <div className='h-6 mr-2 border-2 select-none border-positive-500' />
        {children}
      </div>
    </>
  )
}

export default SubTitle

import AuthStore from '../stores/AuthStore'

const updateHeader = (options) => {
  const updatedOptions = { ...options }
  if (AuthStore.token !== '') {
    updatedOptions.headers = {
      ...updatedOptions.headers,
      'x-access-token': AuthStore.token,
    }
  } else {
    const token = AuthStore.loadToken()
    if (token) {
      updatedOptions.headers = {
        ...updatedOptions.headers,
        'x-access-token': token,
      }
    }
  }
  return updatedOptions
}

export default function fetchWrapper(url, options) {
  return fetch(url, updateHeader(options))
}

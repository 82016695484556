import React, { useState, useRef, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
// import * as ExcelJS from 'exceljs'
import { FaBlackberry } from 'react-icons/fa'
import Format from '../utils/format'
// import { saveAs } from 'file-saver'
import { useTable, usePagination } from 'react-table'
import Button from './atoms/button'
import Table from './atoms/table'
import tableUtils from '../utils/tableUtils'
import CancelOrderButton from './buttons/CancelOrderButton'
import moment from 'moment'
import shortUUID from 'short-uuid'
import dayjs from 'dayjs'
import { getSettlementAmount } from '../helper/order'

const PAGE_ORDER_NUM = 10

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

const TeamGatheringOrders = observer(({ orders, setOrders }) => {
  const translator = shortUUID()

  const [pageIndex, setPageIndex] = useState(0)
  const columns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '주문아이디', accessor: '_id' },
      { Header: '상품아이디', accessor: 'itemId' },
      { Header: '합배송 아이디', accessor: 'addressId' },
      { Header: '상품명', accessor: 'itemTitle' },
      { Header: '옵션', accessor: 'option' },
      { Header: '수량', accessor: 'quantity' },
      { Header: '상품가격', accessor: 'totalPrice' },
      { Header: '추가지원금', accessor: 'platformSupportPrice' },
      { Header: '쿠폰할인금', accessor: 'finalDiscountAmount' },
      { Header: '정산대상금액(수수료 제)', accessor: 'settlementAmount' },
      { Header: '배송비', accessor: 'shippingFee' },
      // { Header: '배송지', accessor: 'address' },
      // { Header: '상세 주소', accessor: 'detailAddress' },
      { Header: '수령인', accessor: 'recipient' },
      // { Header: '수령인 연락처', accessor: 'recipientPhoneNumber' },
      { Header: '주문 시점', accessor: 'timePast' },
      { Header: '모집인원 현황', accessor: 'membersStatus' },
      // { Header: '팀구매 종료 임박 안내', accessor: 'teamGatherAlert' },
      { Header: '주문 취소', accessor: 'cancel' },
    ],
    [],
  )
  const data = useMemo(() => {
    return orders
      ?.filter(
        (order) =>
          order?.status === 'team-gathering' &&
          dayjs(order.payedAt).isAfter(dayjs().add(-365, 'day')),
      )
      .slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.map((order, index) => {
        let timePast = Math.ceil(
          (new Date().getTime() - new Date(order.payedAt).getTime()) /
            (1000 * 60 * 60),
        )
        const daysPast = Math.floor(timePast / 24)
        timePast %= 24
        if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
          if (order?.teamDealInfo?.subAddressInfo) {
            order.addressInfo = order?.teamDealInfo?.subAddressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          } else {
            order.addressInfo = order?.teamDealInfo?.addressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          }
        }
        return {
          ...order,
          index: index + 1 + PAGE_ORDER_NUM * pageIndex,
          itemTitle: (
            <a
              href={order.url
                ?.replace('m.coupang', 'coupang')
                ?.replace('/vm/', '/vp/')}
            >
              {order.itemInfo?.itemTitle}
            </a>
          ),
          option: tableUtils.parseOptionText(
            order?.itemInfo?.optionsInfo,
            order?.selectedOption,
          ),
          // option: optionsText.map((option) => <div key={option}>{option}</div>),
          platformSupportPrice:
            (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1),
          finalDiscountAmount:
            order.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(order),
          itemId: order?.itemInfo?.itemId,
          addressId: `${Format.DateToday(order?.payedAt)}${
            checkIfValidUUID(order?.addressInfo?.addressId) &&
            translator.fromUUID(order?.addressInfo?.addressId || order?._id)
          }`.substring(2),
          address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          detailAddress: order.addressInfo?.detailAddress,
          recipient: Format.Recipient(order.addressInfo?.recipient),
          recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          timePast: (
            <>
              <div>{moment(order.payedAt).format('YYYY-MM-DD HH:mm')}</div>
              <div>
                ({daysPast > 0 ? `${daysPast}일 ` : ''} {timePast} 시간 전)
              </div>
            </>
          ),
          membersStatus: `${order.teamDealInfo?.membersInfo.length} / ${order.teamDealInfo?.teamDealThreshold}`,
          cancel: (
            <CancelOrderButton
              order={order}
              setOrders={setOrders}
              status='team-gathering'
            />
          ),
        }
      })
  }, [
    orders?.filter(
      (order) =>
        order?.status === 'team-gathering' &&
        dayjs(order.payedAt).isAfter(dayjs().add(-365, 'day')),
    )?.length,
    pageIndex,
  ])
  return (
    <div className='flex flex-col items-start w-full mb-20'>
      <h2 className='p-5 text-2xl font-bold'>
        팀모집중 (
        {setOrders
          ? orders.filter(
              (order) =>
                order.status === 'team-gathering' &&
                dayjs(order.payedAt).isAfter(dayjs().add(-365, 'day')),
            ).length
          : OrderStore.ordersNumPerStatus['team-gathering'] || 0}
        )
      </h2>
      <div
        className='export'
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: 5,
        }}
      >
        <button
          className='flex px-2 py-2 mr-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
          type='button'
          onClick={() => {
            if (pageIndex > 0) {
              setPageIndex(pageIndex - 1)
            }
          }}
        >
          이전
        </button>
        <div
          style={{
            width: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {pageIndex + 1} /{' '}
          {Math.ceil(
            (setOrders
              ? orders.filter(
                  (order) =>
                    order.status === 'team-gathering' &&
                    dayjs(order.payedAt).isAfter(dayjs().add(-365, 'day')),
                ).length
              : OrderStore.ordersNumPerStatus['team-gathering'] || 0) /
              PAGE_ORDER_NUM,
          )}
        </div>
        <button
          className='flex px-2 py-2 mx-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
          type='button'
          onClick={async () => {
            if (SellerStore.isLoading) {
              return
            }
            if (
              pageIndex + 1 <
              orders.filter(
                (order) =>
                  order.status === 'team-gathering' &&
                  dayjs(order.payedAt).isAfter(dayjs().add(-365, 'day')),
              ).length /
                PAGE_ORDER_NUM
            ) {
              setPageIndex(pageIndex + 1)
            } else if (orders.length > 0 && !setOrders) {
              SellerStore.setIsLoading(true)

              const result =
                await backendApis.getOrdersInStatusBeforeCertainTimeStamp(
                  'team-gathering',
                  orders.slice(-1)?.length > 0 && orders.slice(-1)[0]?.payedAt,
                )
              if (result?.status === 200 && result.data?.length > 0) {
                OrderStore.setDisplayedOrders([
                  ...OrderStore.displayedOrders,
                  ...result.data,
                ])
                setPageIndex(pageIndex + 1)
              }
              SellerStore.setIsLoading(false)
            }
          }}
        >
          다음
        </button>
      </div>
      <Table columns={columns} data={data} />
    </div>
  )
})

export default TeamGatheringOrders

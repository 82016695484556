import Log from '../../utils/log'
import { useState, useRef, useEffect } from 'react'
import Button from '../atoms/button'
import { read, utils } from 'xlsx-js-style'
import backendApis from '../../utils/backendApis'
import UploadFileIcon from '../../images/UploadFile.svg'
import SellerStore from '../../stores/SellerStore'
import {
  itemsExcelFileDefaultData,
  NUM_OF_NON_JEJU_AREA,
  shippingExcelFileDefaultData,
  timeDealItemsExcelFileDefaultData,
  TOTAL_NUM_OF_RURAL_AREA,
  reviewTransferExcelFileDefaultData,
} from '../../data/excelDefaultData'
import AlertModal from '../atoms/alertModal'
import useKeyEscClose from '../../hooks/useKeyEscClose'
import SellerQuest from '../../utils/sellerQuest'
import convertStringToNumber from '../../utils/convertStringToNumber'

const validColNumOfItemsInfo = itemsExcelFileDefaultData?.[0]?.length
const validRowNumOfItemsInfo = itemsExcelFileDefaultData?.length
const validColNumofTimeDealItemsInfo =
  timeDealItemsExcelFileDefaultData?.[0]?.length
const vaildRowNumOfTranferReviewInfo =
  reviewTransferExcelFileDefaultData?.length
const vaildColNumOfTransferReveiwInfo =
  reviewTransferExcelFileDefaultData?.[0]?.length
const validRowNumofTimeDealItemsInfo = timeDealItemsExcelFileDefaultData?.length
const validColNumOfShippingInfo = shippingExcelFileDefaultData?.[0]?.length
const validRowNumOfShippingInfo = shippingExcelFileDefaultData?.length
const ERROR_GUIDE_URL =
  'https://levitinc.notion.site/c5fcfed962ea4163b847e2b94aa647cc'
const customErrorMessageFormat = {
  locationType: '', // 'text'or 'itemId'
  location: '', // ex) '마지막 행'
  errorType: '', // ex) 'A-1', 'B-2', ...
  message: '', // ex) '판매자 아이디가 일치하지 않습니다.'
}

const hasItems = async (loadedItemsInfo) => {
  if (!loadedItemsInfo || loadedItemsInfo === []) return false
  loadedItemsInfo.forEach((i) => {
    if (i.sellerInfo._id !== SellerStore.sellerInfo._id) return false
  })
  return true
}

const isItemTitlesValid = (itemTitles, loadedItemsInfo) => {
  if (!loadedItemsInfo || loadedItemsInfo === []) return false
  if (itemTitles?.length !== loadedItemsInfo?.length) return false
  loadedItemsInfo.forEach((i, idx) => {
    if (i.itemTitle !== itemTitles[idx]) return false
  })
  return true
}

const isParsedIntValidFromStr = (str, lowerBound, upperBound, isEmptyValid) => {
  if (isEmptyValid && str === '') return true
  const num = convertStringToNumber(str)
  if (Number.isNaN(num)) return false
  if (lowerBound !== undefined && num <= lowerBound) return false
  if (upperBound !== undefined && num >= upperBound) return false
  return true
}

const isPricePairValid = (individualPrice, teamPrice) => {
  if (
    isParsedIntValidFromStr(individualPrice, -1) &&
    isParsedIntValidFromStr(teamPrice, -1)
  ) {
    const ip = convertStringToNumber(individualPrice)
    const tp = convertStringToNumber(teamPrice)
    if (Number.isNaN(ip) || Number.isNaN(tp)) return false
    if (tp > (ip * 9) / 10) return false
    return true
  }
  return false
}

const isTimeDealPricePairValid = (teamPurchasePrice, timeDealPurchasePrice) => {
  if (
    isParsedIntValidFromStr(teamPurchasePrice, -1) &&
    isParsedIntValidFromStr(timeDealPurchasePrice, -1)
  ) {
    const tp = convertStringToNumber(teamPurchasePrice)
    const dp = convertStringToNumber(timeDealPurchasePrice)
    if (Number.isNaN(tp) || Number.isNaN(dp)) return false
    if (dp > (tp * 9) / 10) return false
    return true
  }
  return false
}

const loadItemsInfoByItemIds = async (itemIds) => {
  if (!itemIds || itemIds === []) return []
  const result = await backendApis.getItemsInfoForOwnerValidation(itemIds)
  if (result?.status === 200) {
    return result.data
  }
  return []
}

const getMinimumCategoryInfo = async () => {
  const result = await backendApis.getMinimumCategoryInfo()
  if (result?.status === 200) {
    return result.data
  }
  return null
}

const getShippingCompanyNameList = async () => {
  const result = await backendApis.loadShippingCompanies()
  if (result?.status === 200) {
    return result.data.map((c) => c.shippingCompanyName)
  }
  return []
}

const ExcelFileUploadModal = ({
  modalTitle,
  filename,
  infoType,
  applyStatus,
  appearance = 'positive',
  submitCallbackFunction = () => {},
  disabled = false,
  size = 'md',
  children,
  modalOn = false,
  modalLog = 'ExcelFileUp =loadModal',
  className = '',
  itemId,
}) => {
  const [showModal, setShowModal] = useState(modalOn)
  const [file, setFile] = useState(null)
  const [shippingCompanySet, setShippingCompanySet] = useState(null)
  const [isUploadLoading, setIsUploadLoading] = useState(false)
  const [isAlertTriggered, setIsAlertTriggered] = useState(false)
  const [alertContent, setAlertContent] = useState(customErrorMessageFormat)
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false)
  const inputRef = useRef()
  const handleCopyToClipboard = async () => {
    if (alertContent.locationType === 'itemId') {
      const DELAY_TIME = 500
      await navigator.clipboard.writeText(alertContent.location)
      setIsCopiedToClipboard(true)
      const timer = setTimeout(() => {
        setIsCopiedToClipboard(false)
        clearTimeout(timer)
      }, DELAY_TIME)
    }
  }

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (e) => {
        const bufferArray = e.target.result
        try {
          if (file.name.split('.').pop() !== 'xlsx')
            throw new Error(
              JSON.stringify({
                locationType: 'text',
                location: '파일 확장명',
                errorType: 'A-1',
                message: '확장명(.xlsx) 또는 포맷을 확인해주세요.',
              }),
            )
          const workbook = read(bufferArray, { type: 'bufffer' })
          const worksheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[worksheetName]
          const data = utils.sheet_to_csv(worksheet, { FS: ';', RS: '^' })
          resolve(data)
        } catch (error) {
          setAlertContent(JSON.parse(error.message))
          setIsAlertTriggered(true)
          setShowModal(false)
          setFile(null)
          resolve(null)
        }
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
    return promise
  }

  const submitReviewTransferItemInfo = async (file, numOfDefaultDataRows) => {
    const reviews = []
    const checkAndProcessRows = (data) => {
      const rows = data.split('^').slice(numOfDefaultDataRows)
      rows.forEach((row, index) => {
        const columns = row.split(';').filter(Boolean)
        if (columns.length !== 2) {
          console.log(
            `Row ${numOfDefaultDataRows + index + 1} has ${
              columns.length
            } columns.`,
          )
        } else {
          const reviewScore = columns[0].trim()
          const reviewContent = columns[1].trim()
          if (reviewScore && reviewContent) {
            reviews.push({ reviewScore, reviewContent }) // reviewScore와 reviewContent를 배열에 저장합니다.
          } else {
            console.log(
              `Row ${numOfDefaultDataRows + index + 1} is missing data.`,
            )
          }
        }
      })
    }
    const validateReviews = (reviews) => {
      const validScores = [
        '5',
        '4.5',
        '4',
        '3.5',
        '3',
        '2.5',
        '2',
        '1.5',
        '1',
        '0.5',
      ]

      for (const review of reviews) {
        if (!validScores.includes(review.reviewScore)) {
          alert('리뷰 점수는 5점, 4.5점, 4점 ... 0.5점까지만 가능합니다')
          return false
        }
        if (!review.reviewContent) {
          alert('엑셀 중 리뷰 내용이 없는 곳이 존재합니다')
          return false
        }
      }
      if (reviews.length > 50) {
        alert('리뷰 수는 최대 50개까지 가능합니다')
        return false
      }
      return true
    }
    const readData = await readExcel(file)
    checkAndProcessRows(readData)
    const isValid = validateReviews(reviews)
    if (isValid) {
      const result = await backendApis.postItemRandomReview(reviews, itemId)
      if (result?.status === 200) {
        alert('등록이 완료되었습니다')
      } else {
        alert(
          '등록 중 오류가 발생했습니다. 리뷰 수는 한 아이템 당 최대 50개까지 가능합니다.',
        )
      }
    } else {
      alert(
        '등록 중 오류가 발생했습니다. 리뷰 수는 한 아이템 당 최대 50개까지 가능합니다.',
      )
    }
  }

  const submitModifiedItemsInfo = async (file, numOfDefaultDataRows) => {
    SellerStore.setIsLoading(true)
    try {
      const minimumCategoryInfo = await getMinimumCategoryInfo()
      const readData = await readExcel(file)
      const emptyRowTrimmed = []
      const itemIds = []
      readData
        .split('^')
        .slice(numOfDefaultDataRows)
        .some((r, idx) => {
          const split = r.split(';')
          if (split.length !== validColNumOfItemsInfo)
            throw new Error(
              JSON.stringify({
                locationType: 'text',
                location: `${numOfDefaultDataRows + idx + 1}번째 행`,
                errorType: 'A-2',
                message:
                  '엑셀 파일 열(column)수가 올바르지 않습니다. 올바른 양식의 엑셀 파일인지 확인해주세요.',
              }),
            )
          if (r.length === validColNumOfItemsInfo - 1) return true
          emptyRowTrimmed.push(split)
          return false
        })
      let idIdenticalCommonData = null
      let tempModifiedItemInfo = null
      const tempPrevDataForIdenticalFirstOptionValue = {
        firstOptionName: '',
        firstOptionValue: '',
        secondOptionName: '',
      }
      const tempTotalOptions = {
        first: [],
        second: [],
      }
      const modifiedItemsInfoList = []
      let opt1Idx = 0
      let opt2Idx = 0
      let totalNumOfOptions = 0
      const firstOptionValueSet = new Set()
      const secondOptionValueSet = new Set()
      let hasSecondOption = false
      emptyRowTrimmed.forEach((r, idx) => {
        let isIdIdentical = false
        let isFirstOptionValueIdentical = false
        let [
          itemId,
          itemTitle,
          minimumCategoryId,
          soldOut,
          optionCode,
          firstOptionName,
          firstOptionValue,
          secondOptionName,
          secondOptionValue,
          individualPurchasePrice,
          teamPurchasePrice,
          stockNumber,
        ] = r.map((c) => c.trim())
        if (
          (idx > 0 && itemId === '') ||
          (idx > 0 && itemId === emptyRowTrimmed[idx - 1][0])
        ) {
          isIdIdentical = true
          if (firstOptionValue === '') {
            isFirstOptionValueIdentical = true
          } else if (
            tempPrevDataForIdenticalFirstOptionValue.firstOptionValue ===
            firstOptionValue
          ) {
            isFirstOptionValueIdentical = true
          }
        } else {
          if (secondOptionName !== '' && secondOptionValue !== '') {
            hasSecondOption = true
          } else if (secondOptionName !== '' || secondOptionValue !== '') {
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-2',
                message:
                  '하위 옵션명이나 하위 옵션의 세부 옵션 값이 둘 중 하나만 빈 칸입니다. 같은 상품 내 맨 윗 행의 두 값은 모두 빈 칸이거나 모두 빈 칸이 아니어야 합니다.',
              }),
            )
          } else {
            hasSecondOption = false
          }
          idIdenticalCommonData = {
            itemId,
            itemTitle,
            minimumCategoryId: parseInt(minimumCategoryId, 10),
            soldOut: soldOut === '품절',
          }
          itemIds.push(itemId)
        }
        if (itemTitle === '' && !isIdIdentical)
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: itemId,
              errorType: 'B-2',
              message:
                '상품명을 확인해주세요. 특히 빈 칸이 있는지 확인해주세요.',
            }),
          )
        if (soldOut !== '품절' && soldOut !== '판매중' && !isIdIdentical)
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: itemId,
              errorType: 'B-3',
              message: '판매 상태를 올바르게 기입해주세요. (품절 또는 판매중)',
            }),
          )
        if (!isPricePairValid(individualPurchasePrice, teamPurchasePrice))
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: itemId,
              errorType: 'B-5',
              message:
                '개인 구매가/팀 구매가가 유효하지 않습니다. 팀 구매가는 개인 구매가의 10% 초과 할인된 가격이어야합니다.',
            }),
          )
        if (!isParsedIntValidFromStr(stockNumber, -1))
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: itemId,
              errorType: 'B-6',
              message:
                '재고량 데이터를 확인해주세요. 특히 빈 칸이 있는지 확인해주세요.',
            }),
          )
        if (isIdIdentical) {
          if (itemTitle !== idIdenticalCommonData.itemTitle)
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'B-2',
                message: '상품명은 같은 상품내에서 통일되어야합니다.',
              }),
            )
          if ((soldOut === '품절') !== idIdenticalCommonData.soldOut)
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'B-3',
                message: '판매 상태는 같은 상품내에서 통일되어야합니다.',
              }),
            )
          if (
            parseInt(minimumCategoryId, 10) !==
            idIdenticalCommonData.minimumCategoryId
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'B-4',
                message: '소분류 카테고리는 같은 상품내에서 통일되어야합니다.',
              }),
            )
          if (
            firstOptionName !== '' &&
            tempPrevDataForIdenticalFirstOptionValue.firstOptionName !==
              firstOptionName
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-3',
                message: '같은 상품내 상위 옵션명은 통일된 값을 가져야합니다.',
              }),
            )
          if (hasSecondOption) {
            if (
              secondOptionName !== '' &&
              tempPrevDataForIdenticalFirstOptionValue.secondOptionName !==
                secondOptionName
            )
              throw new Error(
                JSON.stringify({
                  locationType: 'itemId',
                  location: itemId,
                  errorType: 'C-3',
                  message:
                    '같은 상품내 하위 옵션명은 통일된 값을 가져야합니다.',
                }),
              )
          }
          if (isFirstOptionValueIdentical) {
            if (hasSecondOption && secondOptionValueSet.has(secondOptionValue))
              throw new Error(
                JSON.stringify({
                  locationType: 'itemId',
                  location: itemId,
                  errorType: 'C-6',
                  message: '하위 옵션에 중복된 세부 옵션값이 있습니다.',
                }),
              )
          } else {
            if (firstOptionValueSet.has(firstOptionValue))
              throw new Error(
                JSON.stringify({
                  locationType: 'itemId',
                  location: itemId,
                  errorType: 'C-4',
                  message:
                    '상위 옵션의 세부옵션 값의 순서에 유의해주세요. 한번 나온 세부 옵션값은 다시 나올 수 없습니다.',
                }),
              )
            tempPrevDataForIdenticalFirstOptionValue.firstOptionValue =
              firstOptionValue
            firstOptionValueSet.add(firstOptionValue)
            secondOptionValueSet.add(secondOptionValue)
          }
        } else {
          tempPrevDataForIdenticalFirstOptionValue.firstOptionName = ''
          tempPrevDataForIdenticalFirstOptionValue.firstOptionValue = ''
          tempPrevDataForIdenticalFirstOptionValue.secondOptionName = ''
          firstOptionValueSet.clear()
          secondOptionValueSet.clear()
          if (
            firstOptionName === '' &&
            tempPrevDataForIdenticalFirstOptionValue.firstOptionName === ''
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-1',
                message:
                  '상위 옵션명이 빈 칸입니다. 셀이 병합된 경우 같은 상품내 맨 위의 셀에는 값이 존재해야합니다.',
              }),
            )
          if (
            firstOptionValue === '' &&
            tempPrevDataForIdenticalFirstOptionValue.firstOptionValue === ''
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-1',
                message:
                  '상위 옵션의 세부 옵션 값이 빈 칸입니다. 셀이 병합된 경우 같은 상품내 맨 위의 셀에는 값이 존재해야합니다.',
              }),
            )
          firstOptionValueSet.add(firstOptionValue)
          secondOptionValueSet.add(secondOptionValue)
          tempPrevDataForIdenticalFirstOptionValue.firstOptionName =
            firstOptionName
          tempPrevDataForIdenticalFirstOptionValue.firstOptionValue =
            firstOptionValue
          tempPrevDataForIdenticalFirstOptionValue.secondOptionName =
            hasSecondOption ? secondOptionName : ''
        }
        ;[
          itemId,
          itemTitle,
          minimumCategoryId,
          soldOut,
          optionCode,
          firstOptionName,
          firstOptionValue,
          secondOptionName,
          secondOptionValue,
          individualPurchasePrice,
          teamPurchasePrice,
          stockNumber,
        ] = isIdIdentical
          ? [
              idIdenticalCommonData.itemId,
              idIdenticalCommonData.itemTitle,
              idIdenticalCommonData.minimumCategoryId,
              idIdenticalCommonData.soldOut,
              optionCode,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.firstOptionName
                : firstOptionName,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.firstOptionValue
                : firstOptionValue,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.secondOptionName
                : secondOptionName,
              secondOptionValue,
              convertStringToNumber(individualPurchasePrice),
              convertStringToNumber(teamPurchasePrice),
              convertStringToNumber(stockNumber),
            ]
          : [
              ...r.slice(0, 2),
              parseInt(minimumCategoryId, 10),
              soldOut === '품절',
              optionCode,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.firstOptionName
                : firstOptionName,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.firstOptionValue
                : firstOptionValue,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.secondOptionName
                : secondOptionName,
              secondOptionValue,
              convertStringToNumber(individualPurchasePrice),
              convertStringToNumber(teamPurchasePrice),
              convertStringToNumber(stockNumber),
            ]
        if (!minimumCategoryInfo.includes(minimumCategoryId))
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: itemId,
              errorType: 'B-4',
              message:
                '소분류 카테고리가 유효하지 않습니다. 숫자 코드를 확인해주세요.',
            }),
          )

        const option = {
          optionCode,
          firstOptionValue:
            firstOptionValue === ''
              ? tempPrevDataForIdenticalFirstOptionValue.firstOptionValue
              : firstOptionValue,
          secondOptionValue,
          individualPurchasePrice,
          teamPurchasePrice,
          stockNumber,
        }
        if (isIdIdentical) {
          if (!isFirstOptionValueIdentical) {
            opt2Idx = 0
          }
          totalNumOfOptions += 1
          if (hasSecondOption && secondOptionValue === '')
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-2',
                message:
                  '하위 옵션이 존재할 경우 하위 옵션값이 빈 칸이어서는 안됩니다.',
              }),
            )
          if (
            hasSecondOption &&
            tempTotalOptions.second[opt2Idx] !== undefined &&
            secondOptionValue !== tempTotalOptions.second[opt2Idx]
          ) {
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-5',
                message: '하위 옵션의 세부옵션 값의 순서가 올바르지 않습니다.',
              }),
            )
          }
          if (
            isFirstOptionValueIdentical &&
            hasSecondOption &&
            tempTotalOptions.first[tempTotalOptions.first.length - 1] !==
              undefined &&
            firstOptionValue !==
              tempTotalOptions.first[tempTotalOptions.first.length - 1]
          ) {
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-5',
                message: '상위 옵션의 세부옵션 값의 순서가 올바르지 않습니다.',
              }),
            )
          }
          const idxToPush = opt1Idx - 1
          if (isFirstOptionValueIdentical) {
            tempModifiedItemInfo.optionsInfo.optionlist[idxToPush].push(option)
          } else {
            tempModifiedItemInfo.optionsInfo.optionlist.push([option])
          }
          if (
            !tempTotalOptions.first.includes(firstOptionValue) &&
            firstOptionValue !== ''
          ) {
            tempTotalOptions.first.push(firstOptionValue)
            opt1Idx += 1
          }
          if (
            !tempTotalOptions.second.includes(secondOptionValue) &&
            secondOptionValue !== ''
          ) {
            tempTotalOptions.second.push(secondOptionValue)
          }
          opt2Idx += 1
        } else {
          if (
            opt2Idx === 0 &&
            totalNumOfOptions !== opt1Idx &&
            opt2Idx !== 0 &&
            totalNumOfOptions !== opt1Idx * opt2Idx
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-0',
                message:
                  '상위 옵션 수와 하위 옵션 수의 곱만큼 엑셀의 줄 개수(행의 개수)가 순서대로 맞게 입력되었는지 확인해주세요. 아래는 예시입니다.\n 상위 옵션 1 - 하위옵션 1\n 상위 옵션 1 - 하위옵션 2\n 상위 옵션 2 - 하위옵션 1\n 상위 옵션 2 - 하위옵션 2\n 총 4개(2x2)',
              }),
            )
          if (firstOptionValue === '')
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-1',
                message: '상위 옵션의 세부 옵션 값은 빈 칸이 될 수 없습니다.',
              }),
            )
          tempTotalOptions.first = []
          tempTotalOptions.second = []
          opt1Idx = 0
          opt2Idx = 0
          totalNumOfOptions = 1
          if (tempModifiedItemInfo === null) {
            tempModifiedItemInfo = {
              itemId,
              itemTitle,
              minimumCategoryId,
              individualPurchasePrice,
              soldOut,
              optionsInfo: {
                firstOptionName,
                secondOptionName,
                optionlist: [[option]],
              },
            }
          } else {
            modifiedItemsInfoList.push(tempModifiedItemInfo)
            tempModifiedItemInfo = {
              itemId,
              itemTitle,
              individualPurchasePrice,
              minimumCategoryId,
              soldOut,
              optionsInfo: {
                firstOptionName,
                secondOptionName,
                optionlist: [[option]],
              },
            }
          }
          tempTotalOptions.first = [firstOptionValue]
          opt1Idx += 1
          if (hasSecondOption) {
            tempTotalOptions.second = [secondOptionValue]
            opt2Idx += 1
          }
        }
      })
      modifiedItemsInfoList.push(tempModifiedItemInfo)
      modifiedItemsInfoList.forEach((itemInfo) => {
        const itemId = itemInfo.itemId
        let numOfSecondOption = 0
        let hasSecondOption = false
        itemInfo.optionsInfo.optionlist.forEach((row, idx) => {
          if (numOfSecondOption === 0) numOfSecondOption = row.length
          if (numOfSecondOption !== row.length)
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-0',
                message: '하위 옵션의 개수가 일치하지 않습니다.',
              }),
            )
          if (idx === 0 && idx === 0 && row[0]?.secondOptionValue !== '') {
            hasSecondOption = true
          }
          if (hasSecondOption) {
            row.forEach((option) => {
              if (option.secondOptionValue === '')
                throw new Error(
                  JSON.stringify({
                    locationType: 'itemId',
                    location: itemId,
                    errorType: 'C-2',
                    message:
                      '하위 옵션이 존재합니다. 하위 옵션값이 빈 칸이면 안됩니다.',
                  }),
                )
            })
          } else {
            row.forEach((option) => {
              if (option.secondOptionValue !== '')
                throw new Error(
                  JSON.stringify({
                    locationType: 'itemId',
                    location: itemId,
                    errorType: 'C-2',
                    message:
                      '하위 옵션이 존재하지 않습니다. 하위 옵션값은 빈 칸이어야 합니다.',
                  }),
                )
            })
          }
          if (
            idx === 0 &&
            row[0].optionsInfo?.optionlist[0]?.firstOptionName === ''
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-1',
                message: '상위 옵션명은 적어도 맨 윗 행에는 기입해주세요.',
              }),
            )
        })
      })
      if (
        opt2Idx === 0 &&
        totalNumOfOptions !== opt1Idx &&
        opt2Idx !== 0 &&
        totalNumOfOptions !== opt1Idx * opt2Idx
      ) {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '마지막 상품 또는 행',
            errorType: 'C-0',
            message:
              '상위 옵션 수와 하위 옵션 수의 곱만큼 엑셀의 줄 개수(행의 개수)가 순서대로 맞게 입력되었는지 확인해주세요. 아래는 예시입니다.\n 상위 옵션 1 - 하위옵션 1\n 상위 옵션 1 - 하위옵션 2\n 상위 옵션 2 - 하위옵션 1\n 상위 옵션 2 - 하위옵션 2\n 총 4개(2x2)',
          }),
        )
      }
      if (itemIds.length !== modifiedItemsInfoList.length) {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '엑셀 파일 전체',
            errorType: 'B-1',
            message:
              '제출되는 상품 개수와 엑셀 파일에 기입된 상품 개수가 다릅니다. 엑셀 파일을 확인해주세요.',
          }),
        )
      }
      const loadedItemsInfo = await loadItemsInfoByItemIds(itemIds)
      if (!hasItems(loadedItemsInfo))
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '수정 불가 영역(상품 ID)',
            errorType: 'B-1',
            message:
              '상품 아이디 데이터에 문제가 있습니다. 본인의 상품이 아니면 수정할 수 없습니다.',
          }),
        )
      const result = await backendApis.updateItemsInfoInBulkByExcel(
        modifiedItemsInfoList,
      )
      if (result?.status === 2000) {
        alert(`상품 리스트를 정상적으로 수정하였습니다.`)
      } else if (result?.status === 2001) {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '서버',
            errorType: 'serverError',
            message: result?.msg,
          }),
        )
      } else {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '서버 응답 에러',
            errorType: 'A-3',
            message:
              '상품 리스트를 수정하는 중 문제가 생겼습니다. 판매자 문의센터로 문의 바랍니다.',
          }),
        )
      }
      SellerStore.setIsLoading(false)
    } catch (error) {
      console.log(error)
      SellerStore.setIsLoading(false)
      setAlertContent(JSON.parse(error.message))
      setIsAlertTriggered(true)
    }
  }

  const submitModifiedTimeDealItemsInfo = async (
    file,
    numOfDefaultDataRows,
  ) => {
    SellerStore.setIsLoading(true)
    try {
      const readData = await readExcel(file)
      const emptyRowTrimmed = []
      const itemIds = []
      readData
        .split('^')
        .slice(numOfDefaultDataRows)
        .some((r, idx) => {
          const split = r.split(';')
          if (split.length !== validColNumofTimeDealItemsInfo)
            throw new Error(
              JSON.stringify({
                locationType: 'text',
                location: `${numOfDefaultDataRows + idx + 1}번째 행`,
                errorType: 'A-2',
                message: '엑셀 파일 열(column)수가 올바르지 않습니다.',
              }),
            )
          if (r.length === validColNumofTimeDealItemsInfo - 1) return true
          emptyRowTrimmed.push(split)
          return false
        })
      let idIdenticalCommonData = null
      let tempModifiedItemInfo = null
      const tempPrevDataForIdenticalFirstOptionValue = {
        firstOptionName: '',
        firstOptionValue: '',
        secondOptionName: '',
      }
      const tempTotalOptions = {
        first: [],
        second: [],
      }
      const modifiedItemsInfoList = []
      let opt1Idx = 0
      let opt2Idx = 0
      let totalNumOfOptions = 0
      const firstOptionValueSet = new Set()
      const secondOptionValueSet = new Set()
      let hasSecondOption = false
      emptyRowTrimmed.forEach((r, idx) => {
        let isIdIdentical = false
        let isFirstOptionValueIdentical = false
        let [
          itemId,
          itemTitle,
          soldOut,
          firstOptionName,
          firstOptionValue,
          secondOptionName,
          secondOptionValue,
          individualPurchasePrice,
          teamPurchasePrice,
          timeDealPurchasePrice,
          stockNumber,
        ] = r.map((c) => c.trim())
        if (
          (idx > 0 && itemId === '') ||
          (idx > 0 && itemId === emptyRowTrimmed[idx - 1][0])
        ) {
          isIdIdentical = true
          if (firstOptionValue === '') {
            isFirstOptionValueIdentical = true
          } else if (
            tempPrevDataForIdenticalFirstOptionValue.firstOptionValue ===
            firstOptionValue
          ) {
            isFirstOptionValueIdentical = true
          }
        } else {
          if (secondOptionName !== '' && secondOptionValue !== '') {
            hasSecondOption = true
          } else if (secondOptionName !== '' || secondOptionValue !== '') {
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-2',
                message:
                  '하위 옵션명이나 하위 옵션의 세부 옵션 값이 둘 중 하나만 빈 칸입니다. 같은 상품 내 맨 윗 행의 두 값은 모두 빈 칸이거나 모두 빈 칸이 아니어야 합니다.',
              }),
            )
          } else {
            hasSecondOption = false
          }
          idIdenticalCommonData = {
            itemId,
            itemTitle,
            soldOut: soldOut === '품절',
          }
          itemIds.push(itemId)
        }
        if (itemTitle === '' && !isIdIdentical)
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: itemId,
              errorType: 'B-2',
              message:
                '상품명을 확인해주세요. 특히 빈 칸이 있는지 확인해주세요.',
            }),
          )
        if (soldOut !== '품절' && soldOut !== '판매중' && !isIdIdentical)
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: itemId,
              errorType: 'B-3',
              message: '판매 상태를 올바르게 기입해주세요. (품절 또는 판매중)',
            }),
          )
        if (!isTimeDealPricePairValid(teamPurchasePrice, timeDealPurchasePrice))
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: itemId,
              errorType: 'B-5',
              message:
                '개인 구매가/팀 구매가/타임딜 가격이 유효하지 않습니다. 팀 구매가는 개인구매가의, 타임딜 가격은 팀 구매가의 10% 초과 할인된 가격이어야합니다.',
            }),
          )
        if (!isParsedIntValidFromStr(stockNumber, -1)) {
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: itemId,
              errorType: 'B-6',
              message:
                '재고량 데이터를 확인해주세요. 특히 빈 칸이 있는지 확인해주세요.',
            }),
          )
        }
        if (isIdIdentical) {
          if (itemTitle !== idIdenticalCommonData.itemTitle)
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'B-2',
                message: '상품명은 같은 상품내에서 통일되어야합니다.',
              }),
            )
          if ((soldOut === '품절') !== idIdenticalCommonData.soldOut)
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'B-3',
                message: '판매 상태는 같은 상품내에서 통일되어야합니다.',
              }),
            )
          if (
            firstOptionName !== '' &&
            tempPrevDataForIdenticalFirstOptionValue.firstOptionName !==
              firstOptionName
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-3',
                message: '같은 상품내 상위 옵션명은 통일된 값을 가져야합니다.',
              }),
            )
          if (hasSecondOption) {
            if (
              secondOptionName !== '' &&
              tempPrevDataForIdenticalFirstOptionValue.secondOptionName !==
                secondOptionName
            )
              throw new Error(
                JSON.stringify({
                  locationType: 'itemId',
                  location: itemId,
                  errorType: 'C-3',
                  message:
                    '같은 상품내 하위 옵션명은 통일된 값을 가져야합니다.',
                }),
              )
          }
          if (isFirstOptionValueIdentical) {
            if (hasSecondOption && secondOptionValueSet.has(secondOptionValue))
              throw new Error(
                JSON.stringify({
                  locationType: 'itemId',
                  location: itemId,
                  errorType: 'C-6',
                  message: '하위 옵션에 중복된 세부 옵션값이 있습니다.',
                }),
              )
          } else {
            if (firstOptionValueSet.has(firstOptionValue))
              throw new Error(
                JSON.stringify({
                  locationType: 'itemId',
                  location: itemId,
                  errorType: 'C-4',
                  message:
                    '상위 옵션의 세부옵션 값의 순서에 유의해주세요. 한번 나온 세부 옵션값은 다시 나올 수 없습니다.',
                }),
              )
            tempPrevDataForIdenticalFirstOptionValue.firstOptionValue =
              firstOptionValue
            firstOptionValueSet.add(firstOptionValue)
            secondOptionValueSet.add(secondOptionValue)
          }
        } else {
          tempPrevDataForIdenticalFirstOptionValue.firstOptionName = ''
          tempPrevDataForIdenticalFirstOptionValue.firstOptionValue = ''
          tempPrevDataForIdenticalFirstOptionValue.secondOptionName = ''
          firstOptionValueSet.clear()
          secondOptionValueSet.clear()
          if (
            firstOptionName === '' &&
            tempPrevDataForIdenticalFirstOptionValue.firstOptionName === ''
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-1',
                message:
                  '상위 옵션명이 빈 칸입니다. 셀이 병합된 경우 같은 상품내 맨 위의 셀에는 값이 존재해야합니다.',
              }),
            )
          if (
            firstOptionValue === '' &&
            tempPrevDataForIdenticalFirstOptionValue.firstOptionValue === ''
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-1',
                message:
                  '상위 옵션의 세부 옵션 값이 빈 칸입니다. 셀이 병합된 경우 같은 상품내 맨 위의 셀에는 값이 존재해야합니다.',
              }),
            )
          firstOptionValueSet.add(firstOptionValue)
          secondOptionValueSet.add(secondOptionValue)
          tempPrevDataForIdenticalFirstOptionValue.firstOptionName =
            firstOptionName
          tempPrevDataForIdenticalFirstOptionValue.firstOptionValue =
            firstOptionValue
          tempPrevDataForIdenticalFirstOptionValue.secondOptionName =
            hasSecondOption ? secondOptionName : ''
        }
        ;[
          itemId,
          itemTitle,
          soldOut,
          firstOptionName,
          firstOptionValue,
          secondOptionName,
          secondOptionValue,
          individualPurchasePrice,
          teamPurchasePrice,
          timeDealPurchasePrice,
          stockNumber,
        ] = isIdIdentical
          ? [
              idIdenticalCommonData.itemId,
              idIdenticalCommonData.itemTitle,
              idIdenticalCommonData.soldOut,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.firstOptionName
                : firstOptionName,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.firstOptionValue
                : firstOptionValue,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.secondOptionName
                : secondOptionName,
              secondOptionValue,
              convertStringToNumber(individualPurchasePrice),
              convertStringToNumber(teamPurchasePrice),
              convertStringToNumber(timeDealPurchasePrice),
              convertStringToNumber(stockNumber),
            ]
          : [
              ...r.slice(0, 2),
              soldOut === '품절',
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.firstOptionName
                : firstOptionName,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.firstOptionValue
                : firstOptionValue,
              isFirstOptionValueIdentical
                ? tempPrevDataForIdenticalFirstOptionValue.secondOptionName
                : secondOptionName,
              secondOptionValue,
              convertStringToNumber(individualPurchasePrice),
              convertStringToNumber(teamPurchasePrice),
              convertStringToNumber(timeDealPurchasePrice),
              convertStringToNumber(stockNumber),
            ]
        const option = {
          firstOptionValue:
            firstOptionValue === ''
              ? tempPrevDataForIdenticalFirstOptionValue.firstOptionValue
              : firstOptionValue,
          secondOptionValue,
          individualPurchasePrice,
          teamPurchasePrice,
          timeDealPurchasePrice,
          stockNumber,
        }
        if (isIdIdentical) {
          if (!isFirstOptionValueIdentical) {
            opt2Idx = 0
          }
          totalNumOfOptions += 1
          if (hasSecondOption && secondOptionValue === '') {
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-2',
                message:
                  '하위 옵션이 존재할 경우 하위 옵션값이 빈 칸이어서는 안됩니다.',
              }),
            )
          }
          if (
            hasSecondOption &&
            tempTotalOptions.second[opt2Idx] !== undefined &&
            secondOptionValue !== tempTotalOptions.second[opt2Idx]
          ) {
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-5',
                message: '하위 옵션의 세부옵션 값의 순서가 올바르지 않습니다.',
              }),
            )
          }
          if (
            isFirstOptionValueIdentical &&
            hasSecondOption &&
            tempTotalOptions.first[tempTotalOptions.first.length - 1] !==
              undefined &&
            firstOptionValue !==
              tempTotalOptions.first[tempTotalOptions.first.length - 1]
          ) {
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-5',
                message: '상위 옵션의 세부옵션 값의 순서가 올바르지 않습니다.',
              }),
            )
          }
          const idxToPush = opt1Idx - 1
          if (isFirstOptionValueIdentical) {
            tempModifiedItemInfo.optionsInfo.optionlist[idxToPush].push(option)
          } else {
            tempModifiedItemInfo.optionsInfo.optionlist.push([option])
          }
          if (
            !tempTotalOptions.first.includes(firstOptionValue) &&
            firstOptionValue !== ''
          ) {
            tempTotalOptions.first.push(firstOptionValue)
            opt1Idx += 1
          }
          if (
            !tempTotalOptions.second.includes(secondOptionValue) &&
            secondOptionValue !== ''
          ) {
            tempTotalOptions.second.push(secondOptionValue)
          }
          opt2Idx += 1
        } else {
          if (
            opt2Idx === 0 &&
            totalNumOfOptions !== opt1Idx &&
            opt2Idx !== 0 &&
            totalNumOfOptions !== opt1Idx * opt2Idx
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-0',
                message:
                  '상위 옵션 수와 하위 옵션 수의 곱만큼 엑셀의 줄 개수(행의 개수)가 순서대로 맞게 입력되었는지 확인해주세요. 아래는 예시입니다.\n 상위 옵션 1 - 하위옵션 1\n 상위 옵션 1 - 하위옵션 2\n 상위 옵션 2 - 하위옵션 1\n 상위 옵션 2 - 하위옵션 2\n 총 4개(2x2)',
              }),
            )
          if (firstOptionValue === '')
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-1',
                message:
                  '상위 옵션의 세부 옵션 값은 빈 칸이 될 수 없습니다. 또는 셀 병합된 경우 맨 위의 데이터만 빈 칸이 될 수 없습니다.',
              }),
            )
          tempTotalOptions.first = []
          tempTotalOptions.second = []
          opt1Idx = 0
          opt2Idx = 0
          totalNumOfOptions = 1
          if (tempModifiedItemInfo === null) {
            tempModifiedItemInfo = {
              applyStatus,
              itemId,
              itemTitle,
              soldOut,
              optionsInfo: {
                firstOptionName,
                secondOptionName,
                optionlist: [[option]],
              },
            }
          } else {
            modifiedItemsInfoList.push(tempModifiedItemInfo)
            tempModifiedItemInfo = {
              applyStatus,
              itemId,
              itemTitle,
              soldOut,
              optionsInfo: {
                firstOptionName,
                secondOptionName,
                optionlist: [[option]],
              },
            }
          }
          tempTotalOptions.first = [firstOptionValue]
          opt1Idx += 1
          if (hasSecondOption) {
            tempTotalOptions.second = [secondOptionValue]
            opt2Idx += 1
          }
        }
      })
      modifiedItemsInfoList.push(tempModifiedItemInfo)
      modifiedItemsInfoList.forEach((itemInfo) => {
        const itemId = itemInfo.itemId
        let numOfSecondOption = 0
        let hasSecondOption = false
        itemInfo.optionsInfo.optionlist.forEach((row, idx) => {
          if (numOfSecondOption === 0) numOfSecondOption = row.length
          if (numOfSecondOption !== row.length)
            throw new Error('C-0. 하위 옵션의 개수가 일치하지 않습니다.')
          if (idx === 0 && idx === 0 && row[0]?.secondOptionValue !== '') {
            hasSecondOption = true
          }
          if (hasSecondOption) {
            row.forEach((option) => {
              if (option.secondOptionValue === '')
                throw new Error(
                  JSON.stringify({
                    locationType: 'itemId',
                    location: itemId,
                    errorType: 'C-2',
                    message:
                      '하위 옵션이 존재합니다. 하위 옵션값이 빈 칸이면 안됩니다.',
                  }),
                )
            })
          } else {
            row.forEach((option) => {
              if (option.secondOptionValue !== '')
                throw new Error(
                  JSON.stringify({
                    locationType: 'itemId',
                    location: itemId,
                    errorType: 'C-2',
                    message:
                      '하위 옵션이 존재하지 않습니다. 하위 옵션값은 빈 칸이어야 합니다.',
                  }),
                )
            })
          }
          if (
            idx === 0 &&
            row[0].optionsInfo?.optionlist[0]?.firstOptionName === ''
          )
            throw new Error(
              JSON.stringify({
                locationType: 'itemId',
                location: itemId,
                errorType: 'C-1',
                message: '상위 옵션명은 적어도 맨 윗 행에는 기입해주세요.',
              }),
            )
        })
      })
      if (
        opt2Idx === 0 &&
        totalNumOfOptions !== opt1Idx &&
        opt2Idx !== 0 &&
        totalNumOfOptions !== opt1Idx * opt2Idx
      ) {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '마지막 상품 또는 행',
            errorType: 'C-0',
            message:
              '상위 옵션 수와 하위 옵션 수의 곱만큼 엑셀의 줄 개수(행의 개수)가 순서대로 맞게 입력되었는지 확인해주세요. 아래는 예시입니다.\n 상위 옵션 1 - 하위옵션 1\n 상위 옵션 1 - 하위옵션 2\n 상위 옵션 2 - 하위옵션 1\n 상위 옵션 2 - 하위옵션 2\n 총 4개(2x2)',
          }),
        )
      }
      if (itemIds.length !== modifiedItemsInfoList.length) {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '엑셀 파일 전체',
            errorType: 'B-1',
            message:
              '제출되는 상품 개수와 엑셀 파일에 기입된 상품 개수가 다릅니다. 상품 ID를 수정하지 않았는지 확인해주세요.',
          }),
        )
      }
      const loadedItemsInfo = await loadItemsInfoByItemIds(itemIds)
      if (!hasItems(loadedItemsInfo))
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '수정 불가 영역(상품 ID)',
            errorType: 'B-1',
            message:
              '상품 아이디 데이터에 문제가 있습니다. 본인이 등록한 상품인지 확인해주세요. 상품 아이디를 수정하지 않았는지 확인해주세요.',
          }),
        )
      const result = await backendApis.updateTimeDealItemsInfoInBulkByExcel(
        modifiedItemsInfoList,
      )
      if (result?.status === 2000) {
        if (applyStatus === 'timedeal-apply') {
          alert(`타임특가 대량 신청이 완료되었습니다.`)
        } else if (applyStatus === 'timedeal-modification') {
          alert(`타임특가 대량 수정이 완료되었습니다.`)
        }
        if (
          !SellerQuest.questChecker('timedealQuest', 'registerTimedealBatch')
        ) {
          await SellerQuest.questClearer(
            'timedealQuest',
            'registerTimedealBatch',
          )
        }
      } else if (result?.status === 2001) {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '서버',
            errorType: 'serverError',
            message: result?.msg,
          }),
        )
      } else {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '서버 응답 에러',
            errorType: 'A-3',
            message:
              '상품 리스트를 수정하는 중 문제가 생겼습니다. 판매자 문의센터로 문의 바랍니다.',
          }),
        )
      }
      SellerStore.setIsLoading(false)
    } catch (error) {
      console.log(error)
      SellerStore.setIsLoading(false)
      setAlertContent(JSON.parse(error.message))
      setIsAlertTriggered(true)
    }
  }

  const submitModifiedShippingInfo = async (file, numOfDefaultDataRows) => {
    SellerStore.setIsLoading(true)
    try {
      const readData = await readExcel(file)
      const itemIds = []
      const itemTitles = []
      const emptyRowTrimmed = []
      const modifiedShippingInfoList = []
      readData
        .split('^')
        .slice(numOfDefaultDataRows)
        .some((r) => {
          const split = r.split(';')
          if (split.length !== validColNumOfShippingInfo)
            throw new Error(
              JSON.stringify({
                locationType: 'text',
                location: '엑셀 열의 개수',
                errorType: '',
                message: '엑셀 파일 필드 수가 올바르지 않습니다.',
              }),
            )
          if (r.length === validColNumOfShippingInfo - 1) return true
          emptyRowTrimmed.push(split)
          return false
        })
      emptyRowTrimmed.map((r) => r.map((c) => c.trim()))
      emptyRowTrimmed.forEach((r) => {
        const [
          uncheckedItemId,
          uncheckedItemTitle,
          uncheckedShippingCompany,
          uncheckedShippingDays,
          uncheckedShippingFeeInfo,
          uncheckedShippingFee,
          uncheckedNonJejuFee,
          uncheckedJejuFee,
          uncheckedReturnFee,
          uncheckedReturnFeeInfo,
        ] = [...r]
        if (!uncheckedItemId)
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: uncheckedItemId,
              errorType: '',
              message:
                '중간에 빈 줄이 있는지 확인하십시오. 또는 상품 아이디를 확인해주세요.',
            }),
          )
        if (!uncheckedItemTitle)
          throw new Error(
            JSON.stringify({
              locationType: 'itemId',
              location: uncheckedItemId,
              errorType: '',
              message:
                '중간에 빈 줄이 있는지 확인하십시오. 또는 상품명을 확인해주세요.',
            }),
          )
        if (!shippingCompanySet.has(uncheckedShippingCompany)) {
          throw Error(
            JSON.stringify({
              locationType: 'itemId',
              location: uncheckedItemId,
              errorType: '',
              message: '택배사에 데이터가 올바르지 않습니다.',
            }),
          )
        }
        if (!isParsedIntValidFromStr(uncheckedShippingDays, -1))
          throw Error(
            JSON.stringify({
              locationType: 'itemId',
              location: uncheckedItemId,
              errorType: '',
              message:
                '소요시일에 데이터가 올바르지 않습니다.(빈칸 또는 잘못된 값)',
            }),
          )
        if (uncheckedShippingFeeInfo === '' || uncheckedReturnFeeInfo === '')
          throw Error(
            JSON.stringify({
              locationType: 'itemId',
              location: uncheckedItemId,
              errorType: '',
              message:
                '배송비용 안내문구 및 반품 비용 안내 문구에 빈칸이 존재합니다.',
            }),
          )
        if (
          !isParsedIntValidFromStr(uncheckedShippingFee, -1) ||
          convertStringToNumber(uncheckedShippingFee) !== 0
        ) {
          throw Error(
            JSON.stringify({
              locationType: 'itemId',
              location: uncheckedItemId,
              errorType: '',
              message:
                '기본 배송비 값이 올바르지 않습니다. 올웨이즈는 현재 무료 배송을 기본 정책으로 운영하고 있습니다.',
            }),
          )
        }
        if (
          !isParsedIntValidFromStr(uncheckedJejuFee, -1, undefined, true) ||
          !isParsedIntValidFromStr(uncheckedNonJejuFee, -1, undefined, true) ||
          !isParsedIntValidFromStr(uncheckedReturnFee, -1)
        )
          throw Error(
            JSON.stringify({
              locationType: 'itemId',
              location: uncheckedItemId,
              errorType: '',
              message:
                '도서산간 배송비 또는 단순 변심시 반품비용에 빈 칸이 존재하거나 해당 값이 유효하지 않습니다.',
            }),
          )
        itemIds.push(r[0])
        itemTitles.push(r[1])
        const [
          itemId,
          shippingCompany,
          shippingDays,
          shippingFeeInfo,
          shippingFee,
          nonJejuFee,
          jejuFee,
          returnFee,
          returnFeeInfo,
        ] = [
          r[0],
          r[2].trim(),
          parseInt(r[3], 10),
          r[4],
          0,
          r[6] === '' ? '' : parseInt(r[6], 10).toString(), // 나중에 DB 업데이트로 rural shipping fee를 Number 타입으로 변경시 .toString() 제거
          r[7] === '' ? '' : parseInt(r[7], 10).toString(), // 나중에 DB 업데이트로 rural shipping fee를 Number 타입으로 변경시 .toString() 제거
          parseInt(r[8], 10),
          r[9],
        ]
        modifiedShippingInfoList.push({
          itemId,
          modifiedShippingInfo: {
            mainShippingInfo: {
              'shippingInfo.shippingCompany': shippingCompany,
              'shippingInfo.shippingDays': shippingDays,
              'shippingInfo.shippingFeeInfo': shippingFeeInfo,
              'shippingInfo.shippingFee': shippingFee,
              'shippingInfo.returnFee': returnFee,
              'shippingInfo.returnFeeInfo': returnFeeInfo,
              'shippingInfo.freeShipping': shippingFee === 0,
            },
            ruralAreaShippingFeeInfo: {
              jejuFee,
              nonJejuFee,
              totalNumOfRuralArea: TOTAL_NUM_OF_RURAL_AREA,
              numOfNonJejuArea: NUM_OF_NON_JEJU_AREA,
            },
          },
        })
      })

      const loadedItemsInfo = await loadItemsInfoByItemIds(itemIds)
      if (!hasItems(loadedItemsInfo))
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '수정 불가 영역(상품 ID)',
            errorType: '',
            message:
              '상품 아이디 데이터에 문제가 있습니다. 아이디를 수정하지 않았는지 확인하십시오.',
          }),
        )
      if (!isItemTitlesValid(itemTitles, loadedItemsInfo))
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '수정 불가 영역(상품명)',
            errorType: '',
            message:
              '상품명 또는 상품 아이디를 수정하지는 않았는지 확인하십시오.',
          }),
        )
      const result = await backendApis.updateShippingInfoInBulkByExcel(
        modifiedShippingInfoList,
      )
      if (result?.status === 2000) {
        alert(`배송 정보를 정상적으로 수정하였습니다.`)
      } else if (result?.status === 2001) {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '서버',
            errorType: 'serverError',
            message: `${result?.data?.msg}`,
          }),
        )
      } else {
        throw new Error(
          JSON.stringify({
            locationType: 'text',
            location: '서버 응답 에러',
            errorType: 'serverError',
            message:
              '서버 문제로 배송 정보 수정 중 문제가 생겼습니다. 판매자 문의센터로 문의해주세요.',
          }),
        )
      }
      SellerStore.setIsLoading(false)
    } catch (error) {
      console.log(error)
      SellerStore.setIsLoading(false)
      setAlertContent(JSON.parse(error.message))
      setIsAlertTriggered(true)
    }
  }

  const submitModifiedData = async (file) => {
    switch (infoType) {
      case 'item':
        await submitModifiedItemsInfo(file, validRowNumOfItemsInfo)
        return
      case 'shipping':
        await submitModifiedShippingInfo(file, validRowNumOfShippingInfo)
        return
      case 'timeDealItem':
        await submitModifiedTimeDealItemsInfo(
          file,
          validRowNumofTimeDealItemsInfo,
        )
        return
      case 'reviewTransfer':
        await submitReviewTransferItemInfo(file, vaildRowNumOfTranferReviewInfo)
        return
      default:
        return null
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    const dropzone = document.getElementsByClassName('dropzone')[0] ?? null
    if (!dropzone) return
    dropzone.classList.add('border-8')
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    const dropzone = document.getElementsByClassName('dropzone')[0] ?? null
    if (!dropzone) return
    dropzone.classList.remove('border-8')
  }

  const handleDrop = (e) => {
    e.preventDefault()
    if (e.dataTransfer.files.length > 1) {
      alert('하나의 파일만 업로드해주세요.')
      return
    }
    setFile(e.dataTransfer.files[0])
  }

  const handleSubmit = async () => {
    if (!file) {
      alert(
        '파일이 업로드되지 않았거나 올바른 포맷이 아닙니다. 다시 시도해주세요.',
      )
      return
    }
    setIsUploadLoading(true)
    await submitModifiedData(file)
    setIsUploadLoading(false)
    setFile(null)
    submitCallbackFunction()
    setShowModal(false)
  }

  useKeyEscClose(() => {
    setShowModal(false)
  })

  useEffect(async () => {
    if (infoType === 'shipping') {
      const companySet = new Set()
      const list = await getShippingCompanyNameList()
      if (!list) return
      list.forEach((name) => {
        companySet.add(name)
      })
      setShippingCompanySet(companySet)
    }
  }, [])

  return (
    <>
      <>
        <Button
          appearance={appearance}
          type='button'
          size={size}
          disabled={disabled}
          onClick={() => {
            Log.event(modalLog, modalLog, {
              click: modalLog,
            })
            setShowModal(true)
          }}
          className={`${className} pl-4 pr-4`}
        >
          {children}
        </Button>
      </>
      {showModal ? (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
            <div className='relative w-full max-w-2xl mx-auto my-6'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <h3 className='text-xl font-semibold text-black'>
                    {modalTitle}
                  </h3>
                </div>
                {/* body */}
                <div className='relative flex-auto px-10 py-10'>
                  <div className='text-base leading-relaxed text-sub-500'>
                    <div className='flex flex-col space-y-5'>
                      <div className='flex flex-col'>
                        <div className='text-base mb-1'>
                          {`${filename} 엑셀 파일을 업로드해주세요.`}
                        </div>
                        <div className='text-base mb-4'>
                          <ol>
                            <li>
                              1. 엑셀 파일은 검색 결과 다운로드 버튼을 눌러
                              내려받아 수정한 후 업로드해주세요.
                            </li>
                            <li>
                              2. 특수문자 ;(세미 콜론), ^(shift +6), ,(콤마)는
                              기입하지 마세요.
                            </li>
                            <li>3. 상품 ID는 수정이 불가능합니다.</li>
                            {infoType === 'shipping' && (
                              <li>
                                4. 배송 정보내 기본 배송비는 0으로 수정이
                                불가능합니다.
                              </li>
                            )}
                            {infoType === 'item' && (
                              <li>
                                <div className='mb-3'>
                                  4. 엑셀 파일 수정시 어려움을 겪으시거나 에러가
                                  발생할 경우 다음 링크를 참고해보세요.
                                </div>
                                <div>
                                  <Button appearance='positiveSub'>
                                    <a
                                      href={ERROR_GUIDE_URL}
                                      target='_blank'
                                      rel='noreferrer'
                                    >
                                      엑셀 파일 수정 가이드
                                    </a>
                                  </Button>
                                </div>
                              </li>
                            )}
                          </ol>
                        </div>
                        <div>
                          <div className='display-none'>
                            <input type='file' name='file' id='excel_file' />
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col bg-slate-100 p-4 rounded'>
                        {file === null ? (
                          <div
                            className='flex flex-col dropzone font-semibold text-lg text-center p-14'
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                          >
                            <div className='relative'>
                              <div className='mb-1'>
                                이 영역에 파일을 놓아주세요.
                              </div>
                              <div className='mb-2'>또는</div>
                              <div>
                                <input
                                  type='file'
                                  onChange={(e) => {
                                    if (e.target.files.length > 1) {
                                      alert('하나의 파일만 업로드해주세요.')
                                      return
                                    }
                                    const excelFile = e.target.files[0]
                                    setFile(excelFile)
                                  }}
                                  hidden
                                  ref={inputRef}
                                />
                                <Button
                                  appearance='neutral'
                                  onClick={() => {
                                    inputRef.current.click()
                                  }}
                                >
                                  파일 선택(.xlsx)
                                </Button>
                              </div>
                              <div className='absolute w-1/2 -z-99 left-1/2 top-1/2 opacity-5 -translate-x-1/2 -translate-y-1/2 pointer-events-none'>
                                <img
                                  src={UploadFileIcon}
                                  alt=''
                                  className='w-full'
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='flex flex-col dropzone-uploaded font-semibold text-lg text-center p-14'>
                            <div className='relative'>
                              <div className='mb-5'>업로드한 파일</div>
                              <div className='mb-2 font-bold'>{file.name}</div>
                              <div className='absolute w-1/2 -z-99 left-1/2 top-1/2 opacity-5 -translate-x-1/2 -translate-y-1/2 pointer-events-none'>
                                <img
                                  src={UploadFileIcon}
                                  alt=''
                                  className='w-full'
                                />
                              </div>
                              {isUploadLoading && (
                                <div className='flex justify-center font-bold text-rose-400'>
                                  업로드 중입니다. 잠시만 기다려주세요...
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* footer */}
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={async () => {
                      setFile(null)
                      setShowModal(false)
                    }}
                  >
                    닫기
                  </button>
                  <Button size='lg' onClick={handleSubmit}>
                    제출
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      ) : null}
      <>
        <AlertModal
          isTriggered={isAlertTriggered}
          handleTrigger={setIsAlertTriggered}
          title='엑셀 파일 업로드 실패'
          hasOnlyConfirm
        >
          <div className='flex flex-col'>
            <>
              <div className='flex flex-col space-y-2 mb-2'>
                <ul>
                  {alertContent.locationType && (
                    <li>
                      {`오류 발생 위치${
                        alertContent.locationType === 'itemId'
                          ? '(상품 ID)'
                          : ''
                      }: ${alertContent.location}`}
                    </li>
                  )}
                  <li>
                    <>
                      {alertContent.errorType &&
                      alertContent.errorType !== 'serverError'
                        ? `${alertContent.errorType}. `
                        : ''}
                      {alertContent.errorType &&
                      alertContent.errorType === 'serverError'
                        ? '서버 에러: '
                        : ''}
                    </>
                    {`${alertContent.message}`}
                  </li>
                </ul>
              </div>
            </>
            <>
              <div className='flex flex-col space-y-2'>
                <ul>
                  <li>
                    오류 발생 위치를{' '}
                    <span className='text-red-500 text-bold'>[상품 ID]</span>를
                    통해 확인해주세요.
                  </li>
                  <li>
                    오류 확인 및 수정시{' '}
                    <span className='text-red-500'>
                      [엑셀 파일 수정 가이드]
                    </span>
                    를 참고해주세요.
                  </li>
                </ul>
                <div className='flex flex-row space-x-2 my-4'>
                  {alertContent.locationType === 'itemId' && (
                    <Button
                      onClick={handleCopyToClipboard}
                      appearance={isCopiedToClipboard ? 'red' : 'neutral'}
                      className='w-1/2'
                      size='md'
                    >
                      {isCopiedToClipboard
                        ? '복사 되었습니다!!!'
                        : '상품ID 클립보드에 복사'}
                    </Button>
                  )}
                  {infoType !== 'shipping' && (
                    <Button
                      appearance='positiveSub'
                      className='w-1/2'
                      size='md'
                    >
                      <a
                        href={ERROR_GUIDE_URL}
                        target='_blank'
                        rel='noreferrer'
                      >
                        엑셀 파일 수정 가이드
                      </a>
                    </Button>
                  )}
                </div>
              </div>
            </>
          </div>
        </AlertModal>
      </>
    </>
  )
}

export default ExcelFileUploadModal

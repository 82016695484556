import React, { useEffect, useState } from 'react'
import DatePicker from 'react-tailwindcss-datepicker'
import Button from './atoms/button'
import backendApis from '../utils/backendApis'
import ItemStore from '../stores/ItemStore'
import { QuestionType, QuestionStatus } from '../data/question'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'

const ItemQuestionSearchSection = observer(
  ({ setType, searchCallbackFunc }) => {
    const [questionType, setQuestionType] = useState(QuestionType.ITEM_QUESTION)
    const [itemId, setItemId] = useState('')
    const [orderId, setOrderId] = useState('')
    const [questionStatusField, setQuestionStatusField] = useState(
      QuestionStatus.ALL,
    )
    const [date, setDate] = useState({
      startDate: null,
      endDate: null,
    })

    const handleSearch = async (isReload = false) => {
      // ## CJ만 최대 1,500개까지 우선 limit
      let LIMIT_FOR_LOADING_ON_STORE
      if (
        SellerStore?.sellerInfo?._id?.toString() === '639acc64df721d5130eb5b82'
      ) {
        LIMIT_FOR_LOADING_ON_STORE = 1500
      } else {
        LIMIT_FOR_LOADING_ON_STORE = 100
      }
      let searchCondition = {}
      if (isReload && ItemStore.questionSearchCondition.type) {
        searchCondition = ItemStore.questionSearchCondition
      } else if (isReload && !ItemStore.questionSearchCondition.type) {
        await ItemStore.loadUnansweredQuestions()
        return
      } else {
        searchCondition.type = questionType.description
        if (itemId !== '') searchCondition.itemId = itemId
        if (orderId !== '') searchCondition.orderId = orderId
        if (questionStatusField !== QuestionStatus.ALL)
          searchCondition.status = questionStatusField.description
        if (date.startDate !== null && date.endDate !== null) {
          searchCondition.dateRange = {
            start: date.startDate,
            end: date.endDate,
          }
        }
      }
      const result = await backendApis.loadQuestionsByCondition(
        searchCondition,
        LIMIT_FOR_LOADING_ON_STORE,
        0,
      )
      if (result?.status === 200) {
        if (searchCondition.type === QuestionType.ITEM_QUESTION.description) {
          ItemStore.setItemQuestions(result.data)
          setType(QuestionType.ITEM_QUESTION)
        } else {
          ItemStore.setQuestionsFromAdmin(result.data)
          setType(QuestionType.QUESTION_FROM_ADMIN)
        }
        if (!isReload) {
          ItemStore.setQuestionSearchCondition(searchCondition)
          searchCallbackFunc()
        }
      } else {
        alert(
          '문의 내용을 불러오는데 실패했습니다. 판매자 문의센터로 문의해주세요.',
        )
      }
    }

    const handleReset = () => {
      setQuestionType(QuestionType.ITEM_QUESTION)
      setOrderId('')
      setQuestionStatusField(QuestionStatus.ALL)
      setDate({
        startDate: null,
        endDate: null,
      })
    }

    useEffect(async () => {
      await handleSearch(true)
    }, [ItemStore.questionReloadToggle])

    return (
      <div className='flex flex-row items-start pb-5 space-x-4'>
        <div className='flex flex-col w-full p-8 space-y-6 bg-white shadow-lg rounded-2xl'>
          <h2 className='text-2xl font-bold'>문의 내역 검색</h2>
          <div>
            구매자가 상품 또는 주문에 대해 문의한 내역을 확인하고 답변을 작성할
            수 있습니다. 또는 고객 센터에 문의한 내역을 확인할 수 있습니다.
          </div>
          <div className='flex flex-row items-center'>
            <div className='w-1/4 text-lg font-bold'>문의 주체</div>
            <div className='flex flex-row w-3/4 space-x-2'>
              <div className='flex flex-row items-center space-x-1'>
                <div>
                  <input
                    className='w-6 h-6'
                    type='checkbox'
                    value={QuestionType.ITEM_QUESTION.description}
                    checked={
                      questionType.description ===
                      QuestionType.ITEM_QUESTION.description
                    }
                    onChange={(e) => {
                      setQuestionType(QuestionType[e.target.value])
                    }}
                  />
                </div>
                <div>고객 문의</div>
              </div>
              <div className='flex flex-row items-center space-x-1'>
                <div>
                  <input
                    className='w-6 h-6'
                    type='checkbox'
                    value={QuestionType.QUESTION_FROM_ADMIN.description}
                    checked={questionType === QuestionType.QUESTION_FROM_ADMIN}
                    onChange={(e) => {
                      setQuestionType(QuestionType[e.target.value])
                    }}
                  />
                </div>
                <div>고객센터 문의</div>
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <div className='w-1/4 text-lg font-bold'>상품 ID</div>
            <div className='w-3/4'>
              <input
                className='w-full'
                type='text'
                placeholder='상품 ID를 입력해주세요.'
                value={itemId}
                onChange={(e) => setItemId(e.target.value.trim())}
              />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <div className='w-1/4 text-lg font-bold'>주문 번호</div>
            <div className='w-3/4'>
              <input
                className='w-full'
                type='text'
                placeholder='주문 번호를 입력해주세요.'
                value={orderId}
                onChange={(e) => setOrderId(e.target.value.trim())}
              />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <div className='w-1/4 text-lg font-bold'>조회 기간</div>
            <div className='w-3/4'>
              <DatePicker
                displayFormat='YYYY년 MM월 DD일'
                value={date}
                onChange={(d) => setDate(d)}
                i18n='ko'
                showShortcuts
                configs={{
                  shortcuts: {
                    today: '오늘',
                    yesterday: '어제',
                    currentMonth: '이번 달',
                    pastMonth: '지난 달',
                    past: (period) => `지난 ${period}일`,
                  },
                  footer: {
                    cancel: '취소',
                    apply: '적용',
                  },
                }}
              />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <div className='w-1/4 text-lg font-bold'>처리 상태</div>
            <div className='flex flex-row items-center w-1/4 space-x-2'>
              <select
                value={questionStatusField.description}
                onChange={(e) =>
                  setQuestionStatusField(QuestionStatus[e.target.value])
                }
              >
                <option value={QuestionStatus.ALL.description}>전체</option>
                <option value={QuestionStatus.NOT_ANSWERED.description}>
                  미답변
                </option>
                <option value={QuestionStatus.ANSWERED.description}>
                  답변 완료
                </option>
              </select>
            </div>
          </div>
          <div className='flex flex-row items-center justify-end space-x-4'>
            <Button
              className='w-20'
              appearance='positive'
              size='lg'
              onClick={async () => {
                await handleSearch(false)
              }}
            >
              검색
            </Button>
            <Button
              className='w-20'
              appearance='neutral'
              size='lg'
              onClick={handleReset}
            >
              초기화
            </Button>
          </div>
        </div>
      </div>
    )
  },
)

export default ItemQuestionSearchSection

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../../utils/backendApis'
import ItemStore from '../../stores/ItemStore'
import SellerStore from '../../stores/SellerStore'
import AuthStore from '../../stores/AuthStore'
import UseWindowDimension from '../../common/UseWindowDimension'
import ImageManager from '../../images/ImageManager'
import commaNumber from 'comma-number'
import TeamDealThresholdButton from '../buttons/TeamDealThresholdButton'
import IsCheapestButton from '../buttons/IsCheapestButton'
import FreeDeliverButton from '../buttons/FreeDeliverButton'
import LinesEllipsis from 'react-lines-ellipsis'
import RenderRating from '../rating/RenderRating'
import GetRatingCount from '../texts/GetRatingCount'

const ItemListComponent = ({ itemInfo }) => {
  const { width } = UseWindowDimension()
  return (
    <a
      href={`https://alwayzweb.ilevit.com/?alwayzItemId=${itemInfo?._id}`}
      target='_blank'
      className='w-full h-full'
      rel='noreferrer'
    >
      <div className='mr-5'>
        <img
          alt={itemInfo?.mainImageUris[0]}
          // key={mainImageUri}
          src={itemInfo?.mainImageUris[0]}
          className='rounded-md'
        />
        {itemInfo?.isCheapest && <ImageManager source='IsCheapestImage' />}
        <div>
          <div className='flex flex-row pt-3'>
            <div className=' mr-1'>
              <TeamDealThresholdButton
                small
                teamDealThreshold={itemInfo.teamDealThreshold}
              />
            </div>
            <div>
              {itemInfo.isCheapest ? (
                <IsCheapestButton information small />
              ) : (
                <FreeDeliverButton small />
              )}
            </div>
          </div>
          <LinesEllipsis
            style={{ fontSize: 16 }}
            text={itemInfo?.itemTitle}
            maxLine='2'
            ellipsis='..'
            basedOn='letters'
          />
          <div className='flex flex-row '>
            <div style={{ color: '#666666', fontSize: 14, fontWeight: 'bold' }}>
              {Math.ceil(itemInfo?.discountRate * 100)}%
            </div>
            <div
              style={{
                fontSize: 14,
                marginLeft: 2,
                color: '#999999',
                textDecorationLine: 'line-through',
              }}
            >
              {commaNumber(itemInfo?.individualPurchasePrice)}원
            </div>
          </div>
          <div className='text-theme-500 text-lg font-bold'>
            {commaNumber(itemInfo?.teamPurchasePrice)}원
          </div>

          <div className='flex flex-col flex-start'>
            <div className=' flex flex-row'>
              <RenderRating
                size={18}
                rate={GetRatingCount(itemInfo)?.getRating}
              />
            </div>
            <div className='pb-4 text-10 font-bold text-gray-500'>
              ({GetRatingCount(itemInfo)?.getRatingCount})
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default ItemListComponent

import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useLayoutEffect,
} from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import SellerStore from '../stores/SellerStore'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import readXlsxFile from 'read-excel-file'
import { FaFileExcel } from 'react-icons/fa'
import backendApis from '../utils/backendApis'
import ItemStore from '../stores/ItemStore'
import socket from '../utils/socket'
import Select, { default as ReactSelect, components } from 'react-select'
import Log from '../utils/log'
import Format from '../utils/format'
// import Select, { components } from 'react-select'
import { useTable, usePagination } from 'react-table'
import Button from './atoms/button'
import Table from './atoms/table'
import tableUtils from '../utils/tableUtils'
import CancelOrderButton from './buttons/CancelOrderButton'
import EditableCell from './atoms/editableCell'
import SelectableCell from './atoms/selectableCell'
import FastShippingOrderMent from './OrderComponents/FastShippingOrderMent'
import DirectShippingButton from './buttons/DirectShippingButton'
import PostalShippingButton from './buttons/PostalShippingButton'
import UploadShippingNumberButton from './buttons/UploadShippingNumberButton'
import moment from 'moment'
import Multiselect from 'multiselect-react-dropdown'
import shortUUID from 'short-uuid'
import {
  getCouponNotSettlementAmount,
  getCouponSettlementAmount,
  getSettlementAmount,
} from '../helper/order'

const PAGE_ORDER_NUM = 10

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

const comparePreshippingTimeStamp = (obj1, obj2) => {
  const createdAt1 = new Date(obj1.preShippingTimeStamp)
  const createdAt2 = new Date(obj2.preShippingTimeStamp)
  let comparison = 0
  if (createdAt1 > createdAt2) {
    comparison = 1
  } else if (createdAt1 < createdAt2) {
    comparison = -1
  }
  return comparison
}

const headers = [
  { header: '주문아이디', key: 'orderId', width: 20 },
  { header: '상품아이디', key: 'itemId', width: 20 },
  { header: '합배송아이디', key: 'addressId', width: 20 },
  { header: '판매자 상품코드', key: 'optionCode', width: 20 },
  { header: '상품명', key: 'itemTitle', width: 30 },
  { header: '옵션', key: 'option', width: 40 },
  { header: '수량', key: 'quantity' },
  { header: '상품가격', key: 'totalPrice' },
  { header: '배송비', key: 'shippingFee' },
  { header: '추가지원금', key: 'platformSupportPrice' },
  {
    header: '올웨이즈 부담 쿠폰할인금',
    key: 'couponSettlementAmount',
  },
  {
    header: '판매자 부담 쿠폰할인금',
    key: 'couponNotSettlementAmount',
  },
  { header: '쿠폰할인금', key: 'finalDiscountAmount' },
  { header: '정산대상금액(수수료 제외)', key: 'settlementAmount' },
  { header: '주소', key: 'address', width: 50 },
  { header: '우편번호', key: 'postcode' },
  { header: '공동현관 비밀번호', key: 'frontDoorPassword', width: 20 },
  { header: '수령 방법', key: 'detailForReceivingMethod' },
  { header: '수령인', key: 'recipient' },
  { header: '수령인 연락처', key: 'recipientPhoneNumber', width: 20 },
  { header: '택배사', key: 'shippingCompany' },
  { header: '주문 시점', key: 'payedAt' },
  { header: '운송장번호', key: 'shippingNumber', width: 30 },
  { header: '고유 코드', key: 'manualItemCode' },
]

// const xlsxHeader = [
//   '주문아이디',
//   '상품명',
//   '옵션',
//   '수량',
//   '주소',
//   '우편번호',
//   '공동현관 비밀번호',
//   '수령 방법',
//   '수령인',
//   '수령인 연락처',
//   '택배사',
//   '운송장 번호',
// ]

const PreShippingOrders = observer(({ orders, setOrders }) => {
  const [preShippingExcelLimit, setPreShippingExcelLimit] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [shippingInfo, setShippingInfo] = useState(null)
  const [selectedOption, setSelectedOption] = useState([])
  const [selectedItemIds, setSelectedItemIds] = useState([])
  const [registerDelayModal, setRegisterDelayModal] = useState(false)
  const translator = shortUUID()

  // const [filteredPreShippingOrders, setFilteredPreShippingOrders] = useState([])
  // useEffect(() => {
  //   if (OrderStore.preShippingOrders.length > 0)
  //     setFilteredPreShippingOrders(
  //       OrderStore.preShippingOrders.map((orderTemp) => ({
  //         ...orderTemp,
  //         shippingInfo: {
  //           ...orderTemp.shippingInfo,
  //           ...correspondingShippingCompanyInfo,
  //         },
  //       })),
  //     )
  // }, [OrderStore.preShippingOrders.length])
  const columns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '주문아이디', accessor: '_id' },
      { Header: '합포장아이디', accessor: 'addressId' },
      { Header: '상품명', accessor: 'itemTitle' },
      { Header: '옵션', accessor: 'option' },
      { Header: '수량', accessor: 'quantity' },
      {
        Header: '택배사',
        accessor: 'shippingCompanyCode',
        Cell: ({ row }) => {
          return (
            <SelectableCell
              value={row?.state?.shippingCompanyCode}
              options={[
                { value: '', text: '===' },
                ...(OrderStore.shippingCompaniesInfo?.map(
                  (shippingCompanyInfo) => ({
                    value: shippingCompanyInfo.shippingCompanyCode,
                    text: shippingCompanyInfo.shippingCompanyName,
                  }),
                ) || []),
              ]}
              onChange={(e) => {
                row?.setState((prev) => ({
                  ...prev,
                  shippingCompanyCode: e.target.value,
                }))
              }}
            />
          )
        },
      },
      {
        Header: '운송장번호',
        accessor: 'shippingNumber',
        Cell: ({ value, row, column, cell }) => {
          // row.setState({ shippingNumber: '123' })
          // // const shippingNumber = row.cells?.find(
          // //   (cell) => cell?.column?.id === 'shippingNumber',
          // // )?.value
          // return row.state?.shippingNumber
          return (
            <EditableCell
              value={row?.state?.shippingNumber}
              onChange={(e) => {
                row?.setState((prev) => ({
                  ...prev,
                  shippingNumber: e.target.value,
                }))
              }}
            />
          )
        },
      },
      {
        Header: '운송장 업로드',
        accessor: 'uploadShippingNumber',
        Cell: ({ value, row, column, cell }) => {
          return (
            <UploadShippingNumberButton
              order={value}
              setOrders={setOrders}
              orders={orders}
              shippingNumber={row?.state?.shippingNumber}
              shippingCompanyCode={row?.state?.shippingCompanyCode}
            />
          )
        },
      },
      { Header: '우편발송', accessor: 'postalShipping' },
      { Header: '직접배송', accessor: 'directShipping' },
      { Header: '상품가격', accessor: 'totalPrice' },
      { Header: '추가지원금', accessor: 'platformSupportPrice' },
      {
        Header: '올웨이즈 부담 쿠폰 할인금',
        accessor: 'couponSettlementAmount',
      },
      {
        Header: '판매자 부담 쿠폰 할인금',
        accessor: 'couponNotSettlementAmount',
      },
      { Header: '총 쿠폰 할인금', accessor: 'finalDiscountAmount' },
      { Header: '정산대상금액(수수료 제)', accessor: 'settlementAmount' },
      { Header: '배송비', accessor: 'shippingFee' },
      // { Header: '배송지', accessor: 'address' },
      // { Header: '상세 주소', accessor: 'detailAddress' },
      { Header: '수령인', accessor: 'recipient' },
      // { Header: '수령인 연락처', accessor: 'recipientPhoneNumber' },
      { Header: '주문 시점', accessor: 'timePast' },
      { Header: '주문 취소', accessor: 'cancel' },
    ],
    [],
  )

  const data = useMemo(() => {
    return orders
      ?.filter(
        (order) =>
          order.status === 'pre-shipping' &&
          !order.excelDownloadedAt &&
          !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
      )
      .slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.map((order, index) => {
        let timePast = Math.ceil(
          (new Date().getTime() - new Date(order.payedAt).getTime()) /
            (1000 * 60 * 60),
        )
        const daysPast = Math.floor(timePast / 24)
        timePast %= 24
        if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
          if (order?.teamDealInfo?.subAddressInfo) {
            order.addressInfo = order?.teamDealInfo?.subAddressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          } else {
            order.addressInfo = order?.teamDealInfo?.addressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          }
        }
        return {
          ...order,
          index: index + 1 + PAGE_ORDER_NUM * pageIndex,
          itemTitle: (
            <a
              href={order.url
                ?.replace('m.coupang', 'coupang')
                ?.replace('/vm/', '/vp/')}
            >
              {order.itemInfo?.itemTitle}
            </a>
          ),
          option: tableUtils.parseOptionText(
            order?.itemInfo?.optionsInfo,
            order?.selectedOption,
          ),
          shippingCompanyCode: '',
          shippingNumber: '',
          uploadShippingNumber: order,
          postalShipping: (
            <PostalShippingButton
              order={order}
              setOrders={setOrders}
              orders={orders}
            />
          ),
          directShipping: (
            <DirectShippingButton
              order={order}
              setOrders={setOrders}
              orders={orders}
            />
          ),
          totalPrice: order?.totalPrice,
          addressId: `${Format.DateToday(order?.payedAt)}${
            checkIfValidUUID(order?.addressInfo?.addressId) &&
            translator.fromUUID(order?.addressInfo?.addressId || order?._id)
          }`.substring(2),
          optionCode: order?.selectedOption?.optionCode || '',
          itemId: order?.itemInfo?.itemId,
          // option: optionsText.map((option) => <div key={option}>{option}</div>),
          platformSupportPrice:
            (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1),
          couponSettlementAmount: getCouponSettlementAmount(order),
          couponNotSettlementAmount: getCouponNotSettlementAmount(order),
          finalDiscountAmount:
            order.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(order),
          shippingFee: order?.shippingFee,
          address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          detailAddress: order.addressInfo?.detailAddress,
          recipient: Format.Recipient(order.addressInfo?.recipient),
          payedAt: moment(order.payedAt).format('YYYY-MM-DD HH:mm'),
          recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          timePast: (
            <>
              <div>{moment(order.payedAt).format('YYYY-MM-DD HH:mm')}</div>
              <div>
                ({daysPast > 0 ? `${daysPast}일 ` : ''} {timePast} 시간 전)
              </div>
            </>
          ),
          cancel: (
            <CancelOrderButton
              order={order}
              setOrders={setOrders}
              status='pre-shipping'
            />
          ),
        }
      })
  }, [
    orders?.filter(
      (order) =>
        order.status === 'pre-shipping' &&
        !order.excelDownloadedAt &&
        !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
    )?.length,
    pageIndex,
  ])

  const handleExcelExport = async () => {
    if (SellerStore.isLoading) {
      alert('주문건을 다시 불러오고 있습니다.')
      return
    }
    if (!shippingInfo?._id) {
      alert('택배사를 선택해주세요!')
      return
    }
    SellerStore.setIsLoading(true)
    let loadedOrders = []
    if (
      orders.filter(
        (order) =>
          order.status === 'pre-shipping' &&
          !order.excelDownloadedAt &&
          !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
      ).length < 50 ||
      (preShippingExcelLimit &&
        orders
          .filter(
            (order) =>
              order.status === 'pre-shipping' &&
              !order.excelDownloadedAt &&
              !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
          )
          .filter((order) => {
            if (selectedOption.length > 0) {
              return selectedOption
                .map((option) => option.value)
                .includes(order.itemInfo.itemId)
            }
            return true
          }).length > parseInt(preShippingExcelLimit, 10))
    ) {
      loadedOrders = orders.filter(
        (order) =>
          order.status === 'pre-shipping' &&
          !order.excelDownloadedAt &&
          !order.itemInfo.characteristicItem?.isBaroArriveRegistered,
      )
    } else {
      let loadOrders = true
      let index = 0
      while (loadOrders && index < 2000) {
        index += 1
        const loadResult = await backendApis.getSegmentedOrdersInStatus(
          'pre-shipping',
          loadedOrders.slice(-1)?.length > 0 &&
            loadedOrders.slice(-1)[0]?.payedAt,
          true,
          selectedItemIds,
        )
        if (loadResult?.status === 200 && loadResult.data?.length > 0) {
          loadedOrders.push(...loadResult.data)
        } else {
          loadOrders = false
        }
      }
    }
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('주문 내역')
    const fetchItemsData = async () => {
      const itemIds = SellerStore?.sellerInfo?.itemIds || []
      const projectCondition = {
        _id: 1,
        manualItemCode: 1,
      }
      const result = await backendApis.loadItemSpecificInfo(
        itemIds,
        projectCondition,
      )
      if (result?.status === 200) {
        return result?.data
      }
      return []
    }
    let totalItemInfo = []
    if (SellerStore.sellerInfo?._id === '6238198180322c376f1ebece') {
      totalItemInfo = (await fetchItemsData()) || []
    }
    const worksheetData = loadedOrders
      .filter((order) => {
        const optionsText = order.itemInfo.optionsInfo.optionNames.map(
          (value, optionIndex) => {
            const optionDetail =
              order.itemInfo.optionsInfo.totalOptions[optionIndex][
                order.selectedOption[optionIndex]
              ]?.name
            if (optionDetail === undefined) {
              return
            }
            return `${optionIndex}. ${value}: ${optionDetail}`
          },
        )
        return (
          order.status === 'pre-shipping' &&
          !order.excelDownloadedAt &&
          order.addressInfo &&
          order.itemInfo?.optionsInfo &&
          Number.isInteger(order?.selectedOption?.teamPurchasePrice) &&
          optionsText.every((element) => element)
        )
      })
      .slice(
        0,
        parseInt(preShippingExcelLimit, 10) ||
          OrderStore.ordersNumPerStatus['pre-shipping-pre-excel'],
      )
      .map((order) => {
        const optionsText = order.itemInfo.optionsInfo.optionNames.map(
          (value, optionIndex) => {
            const optionDetail =
              order.itemInfo.optionsInfo.totalOptions[optionIndex][
                order.selectedOption[optionIndex]
              ]?.name
            if (optionDetail === undefined) {
              return ''
            }
            return `${optionIndex}. ${value}: ${optionDetail}`
          },
        )
        if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
          if (order?.teamDealInfo?.subAddressInfo) {
            order.addressInfo = order?.teamDealInfo?.subAddressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          } else {
            order.addressInfo = order?.teamDealInfo?.addressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          }
        }
        const recipientPhoneNumber = order.addressInfo?.recipientPhoneNumber
        let manualItemCode = ''
        const recipientPhoneNumberProcessed = recipientPhoneNumber
        if (SellerStore.sellerInfo?._id === '6238198180322c376f1ebece') {
          manualItemCode = totalItemInfo?.find(
            (itemTemp) =>
              itemTemp?._id?.toString() === order?.itemInfo?.itemId?.toString(),
          )?.manualItemCode
        }

        return {
          orderId: order._id,
          itemId: order?.itemInfo?.itemId,
          itemTitle: order.itemInfo?.itemTitle,
          addressId: `${Format.DateToday(order?.payedAt)}${
            checkIfValidUUID(order?.addressInfo?.addressId) &&
            translator.fromUUID(order?.addressInfo?.addressId || order?._id)
          }`.substring(2),
          optionCode: order?.selectedOption?.optionCode || '',
          option: optionsText.join('\n'),
          quantity: order.quantity,
          address: `${order.addressInfo?.postcodeAddress} ${order.addressInfo?.detailAddress}`,
          postcode: order.addressInfo?.postcode,
          frontDoorPassword: order.addressInfo?.frontDoorPassword,
          detailForReceivingMethod:
            order.addressInfo?.receivingMethod === 'door'
              ? '문 앞'
              : order.addressInfo?.detailForReceivingMethod,
          recipient: Format.Recipient(order.addressInfo?.recipient),
          recipientPhoneNumber: recipientPhoneNumberProcessed,
          shippingCompany: shippingInfo.shippingCompanyName,
          shippingNumber: '',
          payedAt: moment(order.payedAt).format('YYYY-MM-DD HH:mm'),
          manualItemCode,
          totalPrice: order?.totalPrice,
          shippingFee: order?.shippingFee,
          couponSettlementAmount: getCouponSettlementAmount(order),
          couponNotSettlementAmount: getCouponNotSettlementAmount(order),
          platformSupportPrice:
            (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1),
          finalDiscountAmount:
            order.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(order),
        }
      })
    worksheet.columns = headers
    worksheet.addRows(worksheetData)
    worksheet.getColumn(3).alignment = { wrapText: true }
    const headerRow = worksheet.getRow(1)
    headers.forEach((header, index) => {
      headerRow.getCell(index + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFEA3F49' },
      }
    })
    worksheet.getRow(1).font = {
      color: { argb: 'FFFFFFFF' },
      bold: true,
    }
    const mimeType = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType)
    const saveDate = new Date()
    const year = saveDate.getFullYear()
    const month = saveDate.getMonth() + 1
    const date = saveDate.getDate()
    const result =
      await backendApis.setSellerPreShippingOrdersExcelDownloadedAt(
        worksheetData.map((order) => order.orderId),
      )
    if (result.status === 200) {
      saveAs(
        blob,
        `${SellerStore.sellerInfo.sellerDisplayName}_주문 내역_${year}-${
          month < 10 ? `0${month}` : month
        }-${date < 10 ? `0${date}` : date}.xlsx`,
      )

      OrderStore.setDisplayedOrders(
        OrderStore.displayedOrders.map((orderTemp) => {
          if (
            worksheetData.map((order) => order.orderId).includes(orderTemp._id)
          ) {
            return { ...orderTemp, excelDownloadedAt: new Date() }
          }
          return orderTemp
        }),
      )
      OrderStore.changeOrdersNumPerStatus(
        'pre-shipping-pre-excel',
        -worksheetData.length,
      )
      OrderStore.changeOrdersNumPerStatus(
        'pre-shipping-post-excel',
        worksheetData.length,
      )
    } else {
      alert('주문 상태 변경에 실패했습니다. 다시 시도해주세요')
      return
    }
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    if (
      SellerStore.sellerInfo._id &&
      OrderStore.shippingCompaniesInfo.length > 0 &&
      orders.length > 0
    ) {
      if (SellerStore.sellerInfo.defaultShippingCompanyId) {
        const correspondingShippingCompanyInfo =
          OrderStore.shippingCompaniesInfo.find(
            (shippingCompanyInfo) =>
              shippingCompanyInfo._id ===
              SellerStore.sellerInfo.defaultShippingCompanyId,
          )

        setShippingInfo({
          ...shippingInfo,
          ...correspondingShippingCompanyInfo,
        })
        // setFilteredPreShippingOrders(
        //   OrderStore.preShippingOrders.map((orderTemp) => ({
        //     ...orderTemp,
        //     shippingInfo: {
        //       ...orderTemp.shippingInfo,
        //       ...correspondingShippingCompanyInfo,
        //     },
        //   })),
        // )
      }
    }
  }, [
    SellerStore.sellerInfo._id,
    OrderStore.shippingCompaniesInfo.length,
    orders.length,
  ])

  useEffect(() => {
    ItemStore.loadPreShippingPreExcelItems()
  }, [])

  useEffect(() => {
    if (setOrders) {
      setPageIndex(0)
      OrderStore.loadExtraPreExcelOrders(
        'pre-shipping',
        false,
        selectedItemIds,
        true,
      )
    }
  }, [selectedItemIds])

  return (
    <div className='flex-1 w-full mb-20' id='preShippingPreExcelOrders'>
      <h2 className='p-5 text-2xl font-bold'>
        팀모집완료 (엑셀추출 이전) (
        {setOrders
          ? orders.filter(
              (order) =>
                order.status === 'pre-shipping' && !order.excelDownloadedAt,
            ).length
          : OrderStore.ordersNumPerStatus['pre-shipping-pre-excel'] || 0}
        )
      </h2>
      <div
        className='preShippingOrdersFunction'
        style={{
          flex: 1,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div className='flex flex-row items-center mb-5'>
            <button
              className='flex px-2 py-2 mr-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
              type='button'
              onClick={handleExcelExport}
            >
              <FaFileExcel />
              엑셀 추출하기
            </button>
            <button
              className='flex px-2 py-2 mx-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
              type='button'
              onClick={() => {
                if (pageIndex > 0) {
                  setPageIndex(pageIndex - 1)
                }
                if (SellerStore.loadSellerInfo())
                  Log.event(
                    'PreShippingOrdersExcelButton',
                    'PreShippingOrdersExcelButton',
                    {
                      click: 'PreShippingOrdersExcelButton',
                    },
                  )
              }}
            >
              이전
            </button>
            <div
              style={{
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {pageIndex + 1}
            </div>
            <button
              className='flex px-2 py-2 mx-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
              type='button'
              onClick={async () => {
                if (SellerStore.isLoading) {
                  return
                }
                if (
                  pageIndex + 1 <
                  orders.filter(
                    (order) =>
                      order.status === 'pre-shipping' &&
                      !order.excelDownloadedAt,
                  ).length /
                    PAGE_ORDER_NUM
                ) {
                  setPageIndex(pageIndex + 1)
                } else if (orders.length > 0 && !setOrders) {
                  SellerStore.setIsLoading(true)
                  const result = await OrderStore.loadExtraPreExcelOrders(
                    'pre-shipping',
                    orders.slice(-1)?.length > 0 &&
                      orders.slice(-1)[0]?.payedAt,
                    selectedItemIds,
                  )
                  if (result) {
                    setPageIndex(pageIndex + 1)
                  }

                  SellerStore.setIsLoading(false)
                }
              }}
            >
              다음
            </button>
          </div>

          <div>
            <div className='flex items-center justify-start flex-1 mb-5'>
              <div className='flex items-center flex:1 w-200'>
                <div className='mr-3 text-lg'>
                  다운로드 할 주문 건 수(50건 이상인 경우 적용)
                </div>
                <div>
                  <input
                    type='number'
                    value={preShippingExcelLimit || ''}
                    onChange={(e) => {
                      setPreShippingExcelLimit(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='flex justify-start flex-1 mb-5'>
              <div className='flex items-center flex:1 w-200'>
                <div className='mr-3 text-lg'>택배사 일괄 변경 적용하기</div>
                <div>
                  {SellerStore.sellerInfo._id && (
                    <select
                      className='justify-end w-80'
                      id='shipping_company'
                      name='shipping_company'
                      onChange={(e) => {
                        const correspondingShippingCompanyInfo =
                          OrderStore.shippingCompaniesInfo.find(
                            (shippingCompanyInfo) =>
                              shippingCompanyInfo.shippingCompanyCode ===
                              e.target.value,
                          )
                        if (correspondingShippingCompanyInfo) {
                          setShippingInfo({
                            ...shippingInfo,
                            ...correspondingShippingCompanyInfo,
                          })
                          OrderStore.setDisplayedOrders(
                            OrderStore.displayedOrders.map((orderTemp) => {
                              if (
                                (!selectedOption?.length ||
                                  selectedOption
                                    .map((option) => option.value)
                                    .includes(orderTemp.itemInfo.itemId)) &&
                                orderTemp.status === 'pre-shipping' &&
                                !orderTemp.excelDownloadedAt
                              ) {
                                return {
                                  ...orderTemp,
                                  shippingInfo: {
                                    ...orderTemp.shippingInfo,
                                    ...correspondingShippingCompanyInfo,
                                  },
                                }
                              }
                              return orderTemp
                              // if (orderTemp._id === order._id) {

                              // }
                              // return orderTemp
                            }),
                          )
                          if (orders && setOrders) {
                            setOrders(
                              orders.map((orderTemp) => {
                                if (
                                  (!selectedOption?.length ||
                                    selectedOption
                                      .map((option) => option.value)
                                      .includes(orderTemp.itemInfo.itemId)) &&
                                  orderTemp.status === 'pre-shipping'
                                ) {
                                  return {
                                    ...orderTemp,
                                    shippingInfo: {
                                      ...orderTemp.shippingInfo,
                                      ...correspondingShippingCompanyInfo,
                                    },
                                  }
                                }
                                return orderTemp
                              }),
                            )
                          }
                        } else {
                          setShippingInfo(null)
                          // setFilteredPreShippingOrders(
                          //   filteredPreShippingOrders.map((orderTemp) => {
                          //     // if (orderTemp._id === order._id) {
                          //     return {
                          //       ...orderTemp,
                          //       shippingInfo: null,
                          //     }
                          //     // }
                          //     // return orderTemp
                          //   }),
                          // )
                          OrderStore.setDisplayedOrders(
                            OrderStore.displayedOrders.map((orderTemp) => {
                              if (
                                (!selectedOption?.length ||
                                  selectedOption
                                    .map((option) => option.value)
                                    .includes(orderTemp.itemInfo.itemId)) &&
                                orderTemp.status === 'pre-shipping'
                              ) {
                                return {
                                  ...orderTemp,
                                  shippingInfo: {
                                    ...orderTemp.shippingInfo,
                                    ...correspondingShippingCompanyInfo,
                                  },
                                }
                              }
                              return orderTemp
                              // if (orderTemp._id === order._id) {

                              // }
                              // return orderTemp
                            }),
                          )
                          if (orders && setOrders) {
                            setOrders(
                              orders.map((orderTemp) => {
                                if (
                                  (!selectedOption?.length ||
                                    selectedOption
                                      .map((option) => option.value)
                                      .includes(orderTemp.itemInfo.itemId)) &&
                                  orderTemp.status === 'pre-shipping'
                                ) {
                                  return {
                                    ...orderTemp,
                                    shippingInfo: {
                                      ...orderTemp.shippingInfo,
                                      ...correspondingShippingCompanyInfo,
                                    },
                                  }
                                }
                                return orderTemp
                              }),
                            )
                          }
                        }
                      }}
                      // defaultValue={
                      //   SellerStore.sellerInfo.defaultShippingCompanyId
                      //     ? OrderStore.shippingCompaniesInfo.find(
                      //         (shippingCompanyInfo) =>
                      //           shippingCompanyInfo._id ===
                      //           SellerStore.sellerInfo.defaultShippingCompanyId,
                      //       ).shippingCompanyCode
                      //     : ''
                      // }
                      value={
                        // OrderStore.preShippingOrders.find(
                        //   (orderTemp) => orderTemp._id === order._id,
                        // ).shippingInfo?.shippingCompanyCode || ''
                        shippingInfo?.shippingCompanyCode || ''
                      }
                    >
                      <option value=''>===</option>
                      {/* <option value=''>===</option>
          <option value='2021'>2021</option>
          <option value='2020'>2020</option>
          <option value='2019'>2019</option> */}
                      {OrderStore.shippingCompaniesInfo.map(
                        (shippingCompanyInfo) => (
                          <option
                            key={shippingCompanyInfo.shippingCompanyCode}
                            value={shippingCompanyInfo.shippingCompanyCode}
                          >
                            {shippingCompanyInfo.shippingCompanyName}
                          </option>
                        ),
                      )}
                    </select>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!setOrders && (
        <div className='flex flex-row items-center flex-1 mb-5'>
          <div>상품 선택:</div>
          <div className='flex justify-between flex-1'>
            <Multiselect
              options={ItemStore.preShippingPreExcelItems} // Options to display in the dropdown
              // selectedValues={selectedItemIds} // Preselected value to persist in dropdown
              onSelect={(selectedList) => {
                setSelectedItemIds(selectedList?.map((item) => item?._id))
              }} // Function will trigger on select event
              onRemove={(selectedList) => {
                setSelectedItemIds(selectedList?.map((item) => item?._id))
              }} // Function will trigger on remove event
              displayValue='itemTitle' // Property name to display in the dropdown options
            />
          </div>
        </div>
      )}
      <Table
        columns={columns}
        data={data}
        shippingCompanyCode={shippingInfo?.shippingCompanyCode}
      />
    </div>
  )
})

export default PreShippingOrders

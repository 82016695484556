import { Collapse, Spin, Table } from 'antd'
import { useEffect, useState } from 'react'
import backendApis from '../../utils/backendApis'
import ImageGallery from './ImageGallery'
import ExpandableText from './ExpandableText'

const ItemQualityDataCollapse = ({ itemId, resetCollapse }) => {
  const [reviewsData, setReviewsData] = useState([])
  const [refundsData, setRefundsData] = useState([])
  const [questionsData, setQuestionsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeKeys, setActiveKeys] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page)
    setPageSize(size)
  }

  const { Panel } = Collapse

  useEffect(() => {
    setActiveKeys([])
    setReviewsData([])
    setRefundsData([])
    setQuestionsData([])
    setCurrentPage(1)
    setPageSize(10)
  }, [resetCollapse])

  const fetchReviewsData = async () => {
    setLoading(true)
    try {
      const result = await backendApis.getRecentLowReview(itemId)
      if (result?.status === 200 && result?.data) {
        setReviewsData(result.data)
      } else {
        setReviewsData([])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchRefundsData = async () => {
    setLoading(true)
    try {
      const result = await backendApis.getRecentRefundsByItem(itemId)
      if (result?.status === 200 && result?.data) {
        setRefundsData(result.data)
      } else {
        setRefundsData([])
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchQuestionData = async () => {
    setLoading(true)
    try {
      const result = await backendApis.getRecentQuestionsByItem(itemId)
      if (result?.status === 200 && result?.data) {
        setQuestionsData(result.data)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleCollapseChange = (keys) => {
    setActiveKeys(keys)
    if (keys.includes('1')) {
      fetchReviewsData()
    } else if (keys.includes('2')) {
      fetchRefundsData()
    } else if (keys.includes('3')) {
      fetchQuestionData()
    }
  }

  const reviewColumns = [
    {
      title: '고객명',
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
    },
    {
      title: '작성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: '후기',
      dataIndex: 'text',
      key: 'text',
      align: 'center',
      render: (text) => <ExpandableText text={text} />,
    },
    {
      title: '평점',
      dataIndex: 'ratingScore',
      key: 'ratingScore',
      align: 'center',
    },
    {
      title: '후기이미지',
      dataIndex: 'reviewImageUris',
      key: 'reviewImageUris',
      align: 'center',
      render: (reviewImageUris) => <ImageGallery urls={reviewImageUris} />,
    },
  ]

  const refundColumns = [
    {
      title: '고객명',
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
    },
    {
      title: '구매일',
      dataIndex: 'payedAt',
      key: 'payedAt',
      align: 'center',
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: '반품사유',
      dataIndex: 'refundReason',
      key: 'refundReason',
      align: 'center',
    },
    {
      title: '상세사유',
      dataIndex: 'refundDetail',
      key: 'refundDetail',
      align: 'center',
      render: (text) => <ExpandableText text={text} />,
    },
    {
      title: '반품이미지',
      dataIndex: 'refundImageUris',
      key: 'refundImageUris',
      align: 'center',
      render: (refundImageUris) => <ImageGallery urls={refundImageUris} />,
    },
  ]

  const questionColumns = [
    {
      title: '고객명',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '문의구분',
      dataIndex: 'mainReason',
      key: 'mainReason',
    },
    {
      title: '문의일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: '문의제목',
      dataIndex: 'title',
      key: 'title',
      render: (title) => <ExpandableText text={title} />,
    },
    {
      title: '문의상세',
      dataIndex: 'text',
      key: 'text',
      render: (text) => <ExpandableText text={text} />,
    },
    {
      title: '문의이미지',
      dataIndex: 'questionImageUris',
      key: 'questionImageUris',
      render: (questionImageUris) => <ImageGallery urls={questionImageUris} />,
    },
  ]

  return (
    <Collapse
      onChange={handleCollapseChange}
      accordion
      className='mt-4'
      activeKey={activeKeys}
    >
      <Panel header='최근 1개월 저평가 후기 상세' key='1'>
        {loading && activeKeys.includes('1') ? (
          <Spin />
        ) : (
          <Table
            columns={reviewColumns}
            dataSource={reviewsData.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize,
            )}
            pagination={{
              current: currentPage,
              pageSize,
              total: reviewsData.length,
              onChange: handlePaginationChange,
              showSizeChanger: true,
            }}
          />
        )}
      </Panel>
      <Panel header='최근 1개월 반품 요청 상세' key='2'>
        {loading && activeKeys.includes('2') ? (
          <Spin />
        ) : (
          <Table
            columns={refundColumns}
            dataSource={refundsData.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize,
            )}
            pagination={{
              current: currentPage,
              pageSize,
              total: refundsData.length,
              onChange: handlePaginationChange,
              showSizeChanger: true,
            }}
          />
        )}
      </Panel>
      <Panel header='최근 1개월 상품관련 문의 상세' key='3'>
        {loading && activeKeys.includes('3') ? (
          <Spin />
        ) : (
          <Table
            columns={questionColumns}
            dataSource={questionsData.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize,
            )}
            pagination={{
              current: currentPage,
              pageSize,
              total: questionsData.length,
              onChange: handlePaginationChange,
              showSizeChanger: true,
            }}
          />
        )}
      </Panel>
    </Collapse>
  )
}

export default ItemQualityDataCollapse

class ItemInfoParser {
  optionsInfoParser(optionsInfo) {
    let newOptionsInfo = []
    if (
      optionsInfo?.optionNames?.length === optionsInfo?.totalOptions?.length &&
      optionsInfo?.totalOptions?.[0]?.length *
        (optionsInfo?.totalOptions?.[1]?.length || 1) ===
        optionsInfo?.optionPrices?.flat()?.length &&
      optionsInfo?.optionPrices?.flat()?.length
    ) {
      newOptionsInfo =
        optionsInfo?.optionPrices
          ?.map((firstOption, firstOptionIndex) => {
            if (Array.isArray(firstOption)) {
              // 2단 옵션
              return firstOption?.map((secondOption, secondOptionIndex) => {
                return {
                  ...secondOption,
                  optionIndices: { 0: firstOptionIndex, 1: secondOptionIndex },
                  optionName1: optionsInfo?.optionNames?.[0],
                  optionName2: optionsInfo?.optionNames?.[1],
                  optionValue1:
                    optionsInfo?.totalOptions?.[0]?.[firstOptionIndex],
                  optionValue2:
                    optionsInfo?.totalOptions?.[1]?.[secondOptionIndex],
                  option1: `${optionsInfo?.optionNames?.[0]} / ${optionsInfo?.totalOptions?.[0]?.[firstOptionIndex]?.name}`,
                  option2: `${optionsInfo?.optionNames?.[1] || ''} / ${
                    optionsInfo?.totalOptions?.[1]?.[secondOptionIndex]?.name ||
                    ''
                  }`,
                }
              })
            }
            return {
              ...firstOption,
              optionIndices: { 0: firstOptionIndex },
              optionName1: optionsInfo?.optionNames?.[0],
              // optionName2: '',
              optionValue1: optionsInfo?.totalOptions?.[0]?.[firstOptionIndex],
              // optionValue2: '',
              option1: `${optionsInfo?.optionNames?.[0]} / ${optionsInfo?.totalOptions?.[0]?.[firstOptionIndex]?.name}`,
            }
            // 1단
          })
          ?.flat() || []
    }
    return newOptionsInfo
  }
}

export default new ItemInfoParser()

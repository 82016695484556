import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import moment from 'moment'
import Button from '../atoms/button'

const TodayDepartureAlert = observer(() => {
  const [showAlertModal, setShowAlertModal] = useState(false)
  const TODAY_DEPARTURE_URL = 'https://alwayzseller.ilevit.com/today-departures'

  useEffect(() => {
    const opendAt = window.localStorage.getItem('TodayDepartureOpenedAt')
    const now = moment()
    if (now.isBefore(moment('2024-02-10')) && !opendAt) {
      window.localStorage.setItem(
        'TodayDepartureOpenedAt',
        new Date().toISOString(),
      )
      setShowAlertModal(true)
    }
  }, [SellerStore?.sellerInfo?._id])

  return (
    <>
      {showAlertModal && (
        <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
          <div
            className='relative z-50'
            aria-labelledby='alert-modal-title'
            role='dialog'
            aria-modal='true'
          >
            <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
                <div className='relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-2/3 sm:max-w-4x1'>
                  <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                    <div className='sm:flex sm:items-start'>
                      {/* Alert Title */}
                      <div className='flex-1 mt-3 sm:ml-4 sm:mt-0 sm:text-left'>
                        <div className='flex flex-row items-center justify-between h-10'>
                          <div
                            className='text-xl font-semibold leading-6 text-gray-900'
                            id='alert-modal-title'
                          >
                            오늘출발 서비스 시작
                          </div>
                        </div>
                        {/* Alert Body(Children) */}

                        <div className='pr-3 mt-2 mb-8 text-gray-500 text-md'>
                          안녕하세요.
                          <br />
                          판매자분들의 동반성장 파트너 올웨이즈입니다.
                          <br />
                          <br />
                          판매자분들의 다양한 판매활동을 지원하기위해
                          올웨이즈에서 ‘오늘출발’ 서비스를 시작합니다.
                          <br />
                          <br />
                          ‘오늘출발’ 상품의 당일 발송은 구매자와의 약속인 만큼
                          반드시 지켜주시길 당부 드립니다.
                          <br />
                          ‘오늘출발’의 신뢰도 향상과 구매자분들의 구매경험
                          증진을 위해 올웨이즈에서는 오늘출발 주문건에 대해 상시
                          모니터링 및 전수 검수를 진행합니다.
                          <br />
                          ‘오늘출발’ 판매정책 위반 건은 올웨이즈 내부 모니터링
                          시스템을 통해 강력하게 제재하고 있으니, 판매활동에
                          유의하여 주시기 부탁드립니다.
                          <br />
                          <br />
                          <br />
                          [ 대표적인 판매정책 위반 사례 ]
                          <br />
                          <br />
                          1. 오늘출발 설정했으나 당일발송을 지키지 않은 경우
                          <br />
                          2. 가송장을 입력한 경우
                          <br />
                          3. 발송정보는 입력했으나 실제로 보내지 않은 경우
                          <br />
                          <br />
                          * 현재 베타 테스트 기간으로, 일부 유저에게만 노출될 수
                          있으며 노출범위는 지속적으로 확대될 예정입니다.
                          <br />
                          <br />
                          <br />
                          오늘출발 주문은 당일 발송을 준수해주시기를 거듭
                          당부드립니다.
                          <br />
                          앞으로도 판매자분들에게 도움이 되는 올웨이즈가 되기
                          위해 노력하겠습니다.
                          <br />
                          <br />
                          <br />
                          감사합니다.
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='px-4 py-3 bg-gray-50 sm:flex sm:px-6 sm:justify-between'>
                    <div>
                      <Button
                        type='button'
                        appearance='outline'
                        onClick={() => {
                          setShowAlertModal(false)
                        }}
                      >
                        <div className='px-4 py-1 text-slate-500'>닫기</div>
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={async () => {
                          window.open(TODAY_DEPARTURE_URL, '_blank')
                          setShowAlertModal(false)
                        }}
                      >
                        <div className='px-4 py-1'>오늘출발 신청하기</div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
})

export default TodayDepartureAlert

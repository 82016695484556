const shippingExcelFileDefaultData = [
  [
    {
      v: '상품 ID(수정 불가)',
      t: 's',
      s: { font: { bold: true } },
    },
    '상품명(수정 불가)',
    '택배사',
    '소요시일',
    '배송비용 안내문구',
    '기본 배송비(수정 불가)',
    '도서산간 배송비(제주 외)',
    '도서산간 배송비(제주)',
    '단순 변심시 반품비용',
    '반품 비용 안내 문구',
  ],
  [
    '수정 불가',
    '수정 불가',
    '(필수) 택배 회사를 적어주세요(오타 주의)',
    '(필수) 배송시 소요일을 숫자로 적어주시면 됩니다.(2일 -> 2)',
    '(필수) 배송비용 안내문구를 적어주세요. 기본 비용과 도서산간(제주/제주 외) 비용을 적어주시면 됩니다.',
    '수정 불가',
    '(선택, 빈 칸 가능) 도서산간 배송비(제주 외) 지역별로 기입해주세요.',
    '(선택, 빈 칸 가능) 제주지역 배송비를 기입해주세요',
    '(필수) 단순 변심에 의한 반품시 고객이 부담해야하는 비용을 기입해주세요.',
    '(필수) 그 외 반품시 비용에 대한 안내 문구를 기입해주세요.',
  ],
  [
    '(수정 불가/예시) 12abcdefghijklmnop34',
    '(수정 불가/예시) 볼캡',
    '(예시) 우체국택배',
    '(예시) 3',
    '(예시) 무료배송, 도서산간 추가 배송비 -제주 지역: 3,000원 -도서산간 지역: 5,000원',
    '(수정 불가/예시) 0',
    '(예시) 5000',
    '(예시) 3000',
    '(예시) 5000',
    '(예시) 5,000원 -단, 고객 단순 변심의 경우에만 발생 -도서산간 및 일부 지역 추가비용 발생',
  ],
]

const itemsExcelFileDefaultData = [
  [
    '상품 ID(수정 불가)',
    '상품명',
    '카테고리 소분류 코드',
    '상품 전체 판매 상태',
    '판매자 상품코드',
    '1번째 옵션 명',
    '세부옵션 값',
    '2번째 옵션 명',
    '세부옵션 값',
    '개인 구매가',
    '팀 구매가',
    '재고 수량',
  ],
  [
    '수정 불가능합니다',
    '(필수)상품명을 기입하는 곳입니다',
    '(필수)카테고리 소분류 코드 엑셀 파일을 다운로드하여 확인 후 반드시 해당하는 숫자 코드로 기입바랍니다.',
    '(필수)[판매중] 또는 [품절]로 기입해주세요.(대괄호 제외) 전체 판매 상태를 나타냅니다. 품절로 기입시 재고 수량에 상관없이 모든 옵션이 품절처리됩니다.',
    '(선택, 빈 칸 가능)판매자님께서 부여한 고유한 상품 (옵션) 코드를 적어주시면 됩니다.',
    '(필수)상위 옵션 분류 명을 적어주시면 됩니다.',
    '(필수)상위 옵션의 옵션 값을 적어주시면 됩니다.',
    '(선택, 빈 칸 가능)하위 옵션 분류 명을 적어주시면 됩니다. 없을 경우 공란으로 두시면 됩니다.',
    '(선택, 빈 칸 가능)하위 옵션의 옵션 값을 적어주시면 됩니다. 없을 경우 공란으로 두시면 됩니다.',
    '(필수)개인 구매가',
    '(필수)팀 구매가',
    '(필수)옵션별 재고 수량을 적어주시면 됩니다.',
  ],
  [
    '(예시) 12abcdefghijklmnop34',
    '(예시) 볼캡',
    '(예시) 368',
    '(예시) 품절',
    '(예시) code123',
    '(예시) 종류',
    '(예시) 무지 볼캡 군모',
    '(예시) 색상',
    '(예시) 블랙',
    '(예시) 25000',
    '(예시) 19000',
    '(예시) 58',
  ],
]

const timeDealItemsExcelFileDefaultData = [
  [
    '상품 ID',
    '상품명',
    '상품 전체 판매 상태',
    '1번째 옵션 명',
    '세부옵션 값',
    '2번째 옵션 명',
    '세부옵션 값',
    '개인 구매가',
    '기존 팀구매가',
    '타임특가 희망가',
    '재고 수량',
  ],
  [
    '수정 불가능합니다',
    '수정 불가능합니다',
    '(수정용만 해당) 품절로 변경 시 타임특가를 중단합니다',
    '수정 불가능합니다',
    '수정 불가능합니다',
    '수정 불가능합니다',
    '수정 불가능합니다',
    '수정 불가능합니다',
    '수정 불가능합니다',
    '(선택)등록 전인 상품은 기존 팀구매가가 고정값입니다. 등록 이후 상품은 기존 타임특가 가격이 고정값입니다',
    '(선택)옵션별 재고 수량을 적어주시면 됩니다.',
  ],
  [
    '(예시) 12abcdefghijklmnop34',
    '(예시) 볼캡',
    '(예시) 품절',
    '(예시) 종류',
    '(예시) 무지 볼캡 군모',
    '(예시) 색상',
    '(예시) 블랙',
    '(예시) 25000',
    '(예시) 19000',
    '(예시) 15000',
    '(예시) 58',
  ],
]

const reviewTransferExcelFileDefaultData = [
  ['별점', '리뷰내용'],
  ['(예시) 5점', '(예시) 가격대비 너무 잘샀어요'],
]

const TOTAL_NUM_OF_RURAL_AREA = 37
const NUM_OF_NON_JEJU_AREA = 34

const ItemModificationMergeColNums = Object.freeze({
  FIRST_OPTION_NAME: 5,
  FIRST_OPTION_VALUE: 6,
  SECOND_OPTION_NAME: 7,
})

const TimeDealMergeColNums = Object.freeze({
  FIRST_OPTION_NAME: 3,
  FIRST_OPTION_VALUE: 4,
  SECOND_OPTION_NAME: 5,
})

export {
  shippingExcelFileDefaultData,
  itemsExcelFileDefaultData,
  TOTAL_NUM_OF_RURAL_AREA,
  NUM_OF_NON_JEJU_AREA,
  timeDealItemsExcelFileDefaultData,
  ItemModificationMergeColNums,
  TimeDealMergeColNums,
  reviewTransferExcelFileDefaultData,
}

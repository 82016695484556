import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import RevenueInfo from '../components/molecules/revenueInfo'
import RevenueByRank from '../components/molecules/revenueByRank'
import SellerStore from '../stores/SellerStore'
import Page from '../components/atoms/page'
import backendApis from '../utils/backendApis'
import { FcBarChart, FcBullish, FcGenericSortingDesc } from 'react-icons/fc'
import ItemPriceComparisonComponent from '../components/ItemComponents/ItemPriceComparisonComponent'
import ItemRevenueTable from '../components/ItemComponents/ItemRevenueTable'
import Button from '../components/atoms/button'
import SellerQuest from '../utils/sellerQuest'

const SellerRevenueDetailReportScreen = observer(() => {
  const [activeItems, setActiveItems] = useState([])
  const [itemsRevenue, setItemsRevenue] = useState([])
  const [statusTab, setStatusTab] = useState('priceComparisonTab')

  const fecthData = async () => {
    SellerStore.setIsLoading(true)
    const itemIds = [...SellerStore.sellerInfo.itemIds]
    const result = await backendApis.loadSellerItems(itemIds)
    if (result?.status === 200) {
      const sellerItems = result?.data
        .filter(
          (item) =>
            item.soldOut === false &&
            item?.rankInfo?.totalScore >= 0 &&
            item?.crawledItemTheLowest &&
            item.crawledItemTheLowest.verifiedResult !== 'no' &&
            item?.crawledItemTheLowest.verifiedResult !== 'n/a',
        )
        .sort((a, b) => {
          return a?.rankInfo?.totalScore - b?.rankInfo?.totalScore
        })
        .slice(0, 100)
        .map((item) => ({
          diff:
            item.crawledItemTheLowest?.price -
            item.crawledItemTheLowest?.teamPurchasePrice,
          ...item,
        }))
        .filter((item) => item.diff < 0)
        .sort((a, b) => {
          return (
            b.crawledItemTheLowest?.verified -
              a.crawledItemTheLowest?.verified || a.diff - b.diff
          )
        })

      setActiveItems(sellerItems)

      // Logging Sellers
      await SellerStore.tracePriceNudgeLog(
        'SellerRevenueDetailsAnalysis',
        'VisitRevenueDetailsAnalysis',
      )

      // Product Revenue Ranking
      const sellerRevenueByItemResult =
        await backendApis.getSellerRevenueByItemIdsDuringNDays(itemIds, 30)
      if (sellerRevenueByItemResult?.status === 200) {
        const sellerRevenueByItem = sellerRevenueByItemResult.data
        setItemsRevenue(
          result?.data
            .map((item) => ({
              itemGMV:
                sellerRevenueByItem
                  .filter((ele) => ele._id.itemId === item._id)
                  .at(0)?.revenueByItemId || 0,
              ...item,
            }))
            .sort((a, b) => {
              return b.itemGMV - a.itemGMV
            })
            .slice(0, 100),
        )
      }
    }
    SellerStore.setIsLoading(false)
  }

  const priceQuestChecker = async () => {
    if (!SellerQuest.questChecker('priceQuest', 'sellerRevenueDetails')) {
      await SellerQuest.questClearer('priceQuest', 'sellerRevenueDetails')
    }
  }

  useEffect(() => {
    fecthData()
    priceQuestChecker()
  }, [])

  // When Message is Received From New Window
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'ThisIsfromSEJINIUS') {
        fecthData()
      }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <div className='w-full h-full'>
      <Page title='최근 매출 추이' Icon={FcBarChart}>
        <div className='grid grid-flow-row grid-cols-2 gap-4'>
          <div>
            <RevenueInfo />
          </div>
          <div>
            <RevenueByRank button={false} />
          </div>
        </div>
      </Page>

      <div className='flex flex-row items-center justify-around w-full px-10 pt-20 '>
        <button
          type='button'
          onClick={() => {
            setStatusTab('priceComparisonTab')
          }}
          className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
            statusTab === 'priceComparisonTab'
              ? 'border-b-4 border-green-800 text-black'
              : 'text-gray-400'
          }`}
        >
          <div className='flex flex-row items-center flex-1'>
            <FcBullish className='w-8 h-8 mb-2 mr-2' />
            <div className='text-2xl font-bold select-none'>
              AI가 제안하는 매출이 더 오를만한 상품들
            </div>
          </div>
        </button>
        <button
          type='button'
          onClick={() => {
            setStatusTab('RevenueListingbyItems')
          }}
          className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
            statusTab === 'RevenueListingbyItems'
              ? 'border-b-4 border-blue-800 text-black'
              : 'text-gray-400'
          }`}
        >
          <div className='flex flex-row items-center flex-1'>
            <FcGenericSortingDesc className='w-8 h-8 mb-2 mr-2' />
            <div className='text-2xl font-bold select-none'>
              최근 30일간 상품 별 매출순위
            </div>
          </div>
        </button>
      </div>
      {statusTab === 'priceComparisonTab' ? (
        <Page title=' ' py={5} mt={0}>
          <div className='text-xl tracking-tighter'>
            인터넷 최저가와 비교해 가격을 내리면 매출이 올라갈 여지가 많은
            상품들만 추천해줘요
          </div>
          {activeItems &&
            activeItems.map((item) => {
              return (
                <>
                  <div className='flex flex-row justify-end mb-2'>
                    <Button
                      appearance='positiveSub'
                      type='button'
                      href='/items'
                      onClick={async () => {
                        await SellerStore.tracePriceNudgeLog(
                          'SellerRevenueDetailsAnalysis',
                          'ClickPriceNudgeAtRevenueDetailsAnalysis',
                        )
                      }}
                    >
                      가격 조절하기
                    </Button>
                  </div>
                  <ItemPriceComparisonComponent itemInfo={item} />
                </>
              )
            })}
        </Page>
      ) : (
        <Page title=' ' py={5} mt={0}>
          <div className='text-xl tracking-tighter'>
            최근 30일 간의 상품 별 매출순위를 알 수 있어요
          </div>
          {itemsRevenue && <ItemRevenueTable itemInfo={itemsRevenue} />}
        </Page>
      )}
    </div>
  )
})

export default SellerRevenueDetailReportScreen

import React, { useEffect, useState } from 'react'
import Button from '../atoms/button'
import { GrClose as CloseIcon } from 'react-icons/gr'
import commaNumber from 'comma-number'
import Cards from 'react-credit-cards'
import backendApis from '../../utils/backendApis'
import CreditCardRegister from './creditCardRegister'
import 'react-credit-cards/es/styles-compiled.css'
import { FcPrevious, FcPlus as PlusIcon, FcAbout } from 'react-icons/fc'
import { FaMinusCircle as MinusIcon } from 'react-icons/fa'
import Format from '../../utils/format'
import SellerQuest from '../../utils/sellerQuest'
import ToggleInput from '../atoms/toggleInput'
import { observer } from 'mobx-react-lite'
import { Formik, Field, Form } from 'formik'

const AdCreditPayModal = observer(
  ({ showModal, setShowModal, basicInfo, setBasicInfo = () => {} }) => {
    const [cIndex, setCIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isRegister, setIsRegister] = useState(false)
    const [creditAmount, setCreditAmount] = useState(0)
    const [autoChargeMode, setAutoChargeMode] = useState(
      basicInfo?.autoChargeMode || false,
    )
    const [autoChargeAmount, setAutoChargeAmount] = useState(0)
    const getAdCreditInfo = async () => {
      const result = await backendApis.getAdCreditInfo()
      if (result?.status === 2000) {
        setBasicInfo(result?.data)
        setAutoChargeMode(result?.data?.autoChargeMode || false)
        setAutoChargeAmount(result?.data?.autoChargeAmount || 0)
      }
    }

    useEffect(() => {
      getAdCreditInfo()
    }, [])

    return (
      <>
        {showModal && (
          <>
            <div className='fixed inset-0 z-50 flex flex-col w-full max-w-xl mx-auto my-auto bg-white border-0 rounded-lg shadow-lg outline-none h-min'>
              {/* header */}
              <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                <div className='text-xl font-semibold text-black'>
                  {isRegister
                    ? '결제 수단 등록 (신용/체크카드 추가)'
                    : '광고 크레딧 충전'}
                </div>
                <Button
                  appearance='transparent'
                  className='py-3 px-3 my-[-6px] rounded-md hover:bg-gray-100'
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  <CloseIcon className='w-4 h-4 ' />
                </Button>
              </div>

              {/* body */}
              <div className='relative flex-auto px-10 py-10'>
                {isRegister ? (
                  <>
                    <button
                      type='button'
                      onClick={() => setIsRegister(!isRegister)}
                      className='flex flex-row items-center justify-start px-4 py-2 mb-4 rounded-lg hover:bg-gray-100 mt-[-20px]'
                    >
                      <FcPrevious className='mr-2' />
                      광고 크레딧 충전
                    </button>

                    <div className='flex flex-row items-center justify-start flex-1 p-5 mb-8 bg-slate-100 rounded-xl'>
                      <FcAbout className='mr-3 w-7 h-7' />
                      <div className='flex flex-col items-start'>
                        <div className='flex flex-row items-center flex-1'>
                          카드 번호를 입력하고 결제 수단으로 등록하세요
                        </div>
                        <div className='text-sm text-gray-500'>
                          1번만 등록하면 충전 금액만 입력해 결제할 수 있어요
                        </div>
                      </div>
                    </div>

                    <CreditCardRegister
                      setBasicInfo={setBasicInfo}
                      setIsRegister={setIsRegister}
                    />
                  </>
                ) : (
                  <>
                    <div className='flex flex-row items-center flex-1 mb-5'>
                      <div className='w-1/3 font-bold'>결제 수단</div>
                      {(!basicInfo?.cardsInfo ||
                        basicInfo?.cardsInfo?.length === 0) && (
                        <button
                          type='button'
                          onClick={() => setIsRegister(!isRegister)}
                          className='flex flex-row items-center justify-start px-5 py-2 border border-gray-200 rounded-lg hover:bg-gray-200'
                        >
                          <PlusIcon className='mr-2' /> 신용/체크카드 추가
                        </button>
                      )}
                    </div>

                    {basicInfo?.cardsInfo?.length > 0 && (
                      <div className='flex flex-row flex-1 mb-8'>
                        <Cards
                          name={`${basicInfo?.cardsInfo?.[cIndex]?.cardName}카드`}
                          number={`${basicInfo?.cardsInfo?.[cIndex]?.cardFirstNumbers}********${basicInfo?.cardsInfo?.[cIndex]?.cardLastNumbers}`}
                        />
                        <div className='flex flex-col items-start justify-start ml-5'>
                          {basicInfo?.cardsInfo?.map((card, index) => (
                            <button
                              key={
                                card?.cardFirstNumbers + card?.cardLastNumbers
                              }
                              type='button'
                              className={`${
                                cIndex === index ? 'bg-blue-100' : 'bg-gray-200'
                              } flex relative flex-row items-center justify-between w-full px-5 py-2 mb-3  rounded-lg hover:opacity-90`}
                              onClick={() => setCIndex(index)}
                            >
                              {index === 0 && (
                                <div className='absolute px-1 py-1 text-xs font-bold text-white bg-red-500 rounded-lg -left-2 -top-2'>
                                  대표
                                </div>
                              )}
                              {card?.cardName} {card?.cardFirstNumbers}
                              <button
                                type='button'
                                className='hover:opacity-50'
                                onClick={async () => {
                                  const result =
                                    await backendApis.deleteAdCreditCardInfo(
                                      card,
                                    )
                                  if (result?.status === 2000) {
                                    alert('카드 삭제가 완료되었습니다.')
                                    getAdCreditInfo()
                                    setIsLoading(false)
                                  }
                                }}
                              >
                                <MinusIcon className='ml-2 opacity-50' />
                              </button>
                            </button>
                          ))}
                          {(basicInfo?.cardsInfo?.length === 1 ||
                            basicInfo?.cardsInfo?.length === 2) && (
                            <button
                              type='button'
                              onClick={() => setIsRegister(!isRegister)}
                              className='flex flex-row items-center justify-start w-full px-5 py-2 mb-3 border rounded-lg border-slate-100 hover:bg-gray-100'
                            >
                              <PlusIcon className='mr-2' /> 카드 추가
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    <div className='flex flex-row flex-1 mb-8'>
                      <div className='w-1/3 font-bold'>총 크레딧 잔액</div>
                      <div className='w-2/3'>
                        ￦
                        {commaNumber(
                          (basicInfo?.adCreditInfo?.paidCredit || 0) +
                            (basicInfo?.adCreditInfo?.supportCredit || 0),
                        )}
                      </div>
                    </div>

                    <div className='flex flex-row flex-1 mb-8'>
                      <div className='w-1/3 font-bold'>충전 금액</div>
                      <div className='w-2/3'>
                        <div>
                          <input
                            disabled={basicInfo?.cardsInfo?.length === 0}
                            type='number'
                            className={`mt-1 w-1/2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mr-2 text-right ${
                              basicInfo?.cardsInfo?.length === 0
                                ? 'bg-gray-200 border-none'
                                : ''
                            }`}
                            value={creditAmount}
                            min={0}
                            step={10000}
                            onChange={(e) => {
                              setCreditAmount(parseInt(e.target.value, 10))
                            }}
                          />
                          원
                        </div>
                        <span className='text-sm text-gray-500'>
                          * 최소 충전 금액 30,000원
                        </span>
                      </div>
                    </div>
                    <div className='flex flex-row flex-1 '>
                      <div className='w-1/3 font-bold'>자동 충전 설정</div>
                      <div>
                        <ToggleInput
                          state={autoChargeMode}
                          setState={setAutoChargeMode}
                          className='mr-2'
                          color='positiveSub'
                          turnOffMessage='자동충전을 취소하시는 경우 잔액 부족으로 광고가 꺼질 수 있습니다. 자동충전을 취소하시겠습니까?'
                          callbackFunction={async (autoChargeModeTemp) => {
                            await backendApis.updateAutoChargeMode(
                              autoChargeModeTemp,
                            )
                          }}
                        />
                        <div>
                          {autoChargeMode && (
                            <>
                              <div className='my-3'>
                                1회 충전금액 :{' '}
                                <span className='font-bold text-blue-500'>
                                  {commaNumber(autoChargeAmount)}
                                </span>{' '}
                                원
                              </div>
                              <div>
                                <Formik
                                  initialValues={{
                                    autoChargeAmount: 0,
                                  }}
                                  onSubmit={async (values) => {
                                    setIsLoading(true)
                                    console.log(values, values.autoChargeAmount)
                                    const result =
                                      await backendApis.updateAutoChargeAmount(
                                        values.autoChargeAmount,
                                      )
                                    if (result?.status === 2000) {
                                      alert('자동 충전 금액이 설정되었습니다.')
                                      getAdCreditInfo()
                                    }
                                    setIsLoading(false)
                                  }}
                                >
                                  <Form className='flex flex-row items-center flex-1'>
                                    <Field
                                      className='w-1/2 mr-2 text-right border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                      id='autoChargeAmount'
                                      name='autoChargeAmount'
                                      placeholder={0}
                                      step={10000}
                                      type='number'
                                      min={30000}
                                    />
                                    <Button type='submit'>설정</Button>
                                  </Form>
                                </Formik>
                              </div>
                              <div className='mt-1 text-sm text-gray-500'>
                                * 자동 충전 설정을 켜주시면, 광고 크레딧이 1만원
                                이하일 때 <br />
                                설정된 금액만큼 자동으로 충전됩니다.
                              </div>
                              <div className='mt-1 text-sm text-gray-500'>
                                * 결제는 대표카드로 진행됩니다.
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* footer */}
              {!isRegister && (
                <div className='flex items-center justify-end gap-3 p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <Button
                    size='lg'
                    appearance={
                      isRegister ||
                      basicInfo?.cardsInfo?.length === 0 ||
                      creditAmount < 30000
                        ? 'disabled'
                        : 'positive'
                    }
                    disabled={
                      isRegister ||
                      basicInfo?.cardsInfo?.length === 0 ||
                      creditAmount < 30000
                    }
                    onClick={async () => {
                      if (isLoading === true) return
                      setIsLoading(true)

                      if (creditAmount < 30000) {
                        setCreditAmount(30000)
                        return
                      }
                      const result = await backendApis.buyAdCredit(
                        basicInfo?.cardsInfo?.[cIndex]?.paymentMethodId,
                        creditAmount,
                        new Date(),
                      )
                      if (result?.status === 2000) {
                        alert(
                          `${Format.Price(creditAmount)} ${
                            result?.data?.data?.ResultMsg ?? ''
                          }`,
                        )
                        if (
                          !SellerQuest.questChecker('adQuest', 'chargeCredit')
                        ) {
                          await SellerQuest.questClearer(
                            'adQuest',
                            'chargeCredit',
                          )
                        }
                      }
                      if (result?.status === 2001) {
                        alert(
                          '결제에 실패했습니다. 잠시후 다시 시도하거나, 판매자 지원센터로 문의해주세요.',
                        )
                      }
                      getAdCreditInfo()
                      setShowModal(false)
                      setIsLoading(false)
                    }}
                  >
                    충전금 결제
                  </Button>
                </div>
              )}
            </div>
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
          </>
        )}
      </>
    )
  },
)
export default AdCreditPayModal

import React, { useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../stores/ItemStore'
import moment from 'moment'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import Log from '../utils/log'
import SubTitle from './atoms/subTitle'
import Button from './atoms/button'
import SearchReviewReplyModal from '../screens/SearchReviewReplyModal'
import Modal from 'react-modal'
import Title from './ADS/atoms/title'
import Table from './ADS/molecules/table'

const PAGE_REVIEW_NUM = 50

const ItemReviews = observer(() => {
  const [pageIndex, setPageIndex] = useState(0)
  const [loadedItemReviewInfo, setLoadedItemReviewInfo] = useState(false)
  const [sortedItemReviewInfo, setSortedItemReviewInfo] = useState([])
  const [sortType, setSortType] = useState('recent')
  const [newSortType, setNewSortType] = useState('recent')
  const [focusItemId, setFocusItemId] = useState('')
  const [focusOrderId, setFocusOrderId] = useState('')
  const [ordererInfo, setOrdererInfo] = useState({})
  const [openReviewReplyModal, setOpenReviewReplyModal] = useState(false)
  const [searchedReviews, setSearchedReviews] = useState([])
  const itemIds =
    SellerStore?.sellerInfo?.itemIds?.map((item) => item?.toString()) || []

  useEffect(async () => {
    if (sortedItemReviewInfo?.length === 0) {
      const result = await backendApis.getItemReviewInfo(
        0,
        PAGE_REVIEW_NUM,
        sortType,
        focusItemId,
      )
      setSortedItemReviewInfo(result.data)
      setPageIndex(0)
    }
    setLoadedItemReviewInfo(true)
  }, [])

  // sortType 바꿀 때 또 호출
  useEffect(() => {
    if (sortType !== newSortType) {
      setSortType(newSortType)
    }
  }, [newSortType])

  useEffect(async () => {
    setSortedItemReviewInfo([])
    if (sortType !== 'reviewEvent') {
      const result = await backendApis.getItemReviewInfo(
        0,
        PAGE_REVIEW_NUM,
        sortType,
        focusItemId,
      )
      setSortedItemReviewInfo(result.data)
      if (result?.data?.length > 0) {
        setLoadedItemReviewInfo(true)
      }
    } else {
      setSortedItemReviewInfo(
        sortedItemReviewInfo.filter((review) => review.isReviewEvent),
      )
      setLoadedItemReviewInfo(true)
    }
    setPageIndex(0)
  }, [sortType])

  const columns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '등록일시', accessor: 'createdAt' },
      { Header: '상품ID 및 상품명', accessor: 'itemTitle' },
      { Header: '주문ID 및 고객 정보', accessor: 'orderId' },
      { Header: '별점', accessor: 'reviewRatingScore' },
      { Header: '후기 내용', accessor: 'reviewText' },
      { Header: '후기 사진', accessor: 'reviewImage' },
    ],
    [],
  )

  const itemReviewsList = useMemo(() => {
    return sortedItemReviewInfo
      ?.slice(pageIndex * PAGE_REVIEW_NUM, (pageIndex + 1) * PAGE_REVIEW_NUM)
      .map((review, index) => {
        return {
          index: index + 1 + PAGE_REVIEW_NUM * pageIndex,
          createdAt: (
            <>
              {moment(review?.createdAt).format('YYYY.MM.DD')}
              <br />
              {moment(review?.createdAt).format('HH:mm')}
            </>
          ),
          itemTitle: (
            <>
              <span className='text-gray-400'>{review?.itemId}</span>
              <br />
              {review?.itemTitle}
            </>
          ),
          orderId: (
            <>
              <span
                className={`${
                  review?.isReviewEvent ? 'text-yellow-500' : 'text-gray-400'
                }`}
              >
                {review?.orderId} <br />
              </span>
              {ordererInfo._id === review?.orderId ? (
                <div>
                  이름: {ordererInfo.userName}
                  <br />
                  연락처: {ordererInfo.phoneNumber}
                </div>
              ) : (
                <button
                  size='sm'
                  type='button'
                  className='px-2 py-1 mt-1 rounded-lg bg-slate-200'
                  onClick={async () => {
                    const result = await backendApis.getOrdererInfo(
                      review?.orderId,
                    )
                    if (result?.status === 2000) {
                      setOrdererInfo(result?.data)
                    }
                  }}
                >
                  고객 정보 불러오기
                </button>
              )}
            </>
          ),
          reviewRatingScore: (
            <span className={`${review?.ratingScore < 4 && 'text-red-500'}`}>
              {review?.ratingScore}점
            </span>
          ),
          reviewText: (
            <div className='whitespace-normal min-w-[300px]'>
              {review?.text}
            </div>
          ),
          reviewImage: (
            <div className='flex flex-row overflow-x-scroll'>
              {review?.reviewImageUris?.length > 0 &&
                review?.reviewImageUris.map((item, index) => {
                  return (
                    <img
                      src={review?.reviewImageUris[index]}
                      alt=''
                      className='max-w-[150px]'
                    />
                  )
                })}
            </div>
          ),
        }
      })
  })

  return (
    <>
      <Title text='후기 확인' />
      {!loadedItemReviewInfo ? (
        <div className='text-lg'>후기 로드 중입니다</div>
      ) : (
        <>
          <Modal
            isOpen={openReviewReplyModal}
            onRequestClose={() => {
              setOpenReviewReplyModal(false)
            }}
            style={{
              overlay: {
                zIndex: 20,
              },
              content: {
                left: '10%',
                right: '10%',
                top: '10%',
                bottom: '10%',
                padding: 0,
              },
            }}
          >
            <SearchReviewReplyModal
              id='modal'
              setOpenModal={setOpenReviewReplyModal}
              focusOrderId={focusOrderId}
              searchedReviews={searchedReviews}
            />
          </Modal>
          {sortedItemReviewInfo?.length > 0 ? (
            <>
              <div className='flex flex-col items-start w-full pb-5 space-x-4 '>
                <div className='shadow-lg rounded-2xl p-8 bg-white min-w-[405px] mb-2'>
                  <div>• 지난 100일간 쌓인 후기를 조회할 수 있습니다.</div>
                  <div>
                    • 판매자에 대한 서비스 만족도를 고객에게 1~5개의 별점으로
                    확인하고 반영합니다.
                  </div>
                  <div>
                    • 1~3점의 낮은 후기가 지속적으로 쌓일 경우{' '}
                    <span className='text-red-500'>
                      해당 상품에 대하여 노출 감소/중지 조치
                    </span>
                    를 취할 수 있습니다.
                  </div>
                  <div>
                    • 간혹, 정보 확인 (배송누락, 시정이 필요하다고 불만을
                    표시하신 경우)이 필요해 고객분의 연락처를 조회하고 싶으신
                    경우, <br />
                    주문아이디를 복사해서 좌측메뉴의 '주문/배송>고객 주문
                    관리>주문 아이디' 에 입력하여 검색해주세요. <br />
                    (부정 후기를 남기신 고객님께 연락하실 경우, 최대한 자제 된
                    소통을 하시어 고객님과 원활히 문제가 해결 될 수 있도록
                    주의부탁드립니다.)
                  </div>
                  <div>
                    • 고객 주문 아이디 중{' '}
                    <span className='text-yellow-500'>노란색</span>은 후기
                    이벤트 참여한 후기입니다.
                  </div>
                  <div>
                    • 고객 주문 아이디를 클릭하면 고객 이름과 연락처를 조회하실
                    수 있습니다.
                  </div>
                  <div>
                    • 부정 후기를 빠르게 확인하고, 교환/반품 등의 적절한 조치를
                    적시에 취해주시는 편이 좋습니다.
                  </div>
                  <div className='col-span-6 mt-10'>
                    <span className='block text-sm font-medium text-gray-700'>
                      상품ID로 고객 문의 조회
                    </span>
                    <div className='flex flex-row whitespace-nowrap'>
                      <input
                        type='text'
                        placeholder='상품ID를 입력해주세요.'
                        value={focusItemId || ''}
                        onChange={(e) => {
                          setFocusItemId(e.target.value)
                        }}
                        className='block w-full mt-1 mr-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                      />
                      <Button
                        type='button'
                        className='px-4'
                        onClick={async () => {
                          if (itemIds.includes(focusItemId)) {
                            setSortedItemReviewInfo([])
                            setLoadedItemReviewInfo(false)
                            const result = await backendApis.getItemReviewInfo(
                              0,
                              PAGE_REVIEW_NUM,
                              sortType,
                              focusItemId,
                            )
                            setSortedItemReviewInfo(result.data)
                            setLoadedItemReviewInfo(true)
                            setPageIndex(0)
                          } else {
                            alert(`상품ID를 확인해주세요.`)
                          }
                        }}
                      >
                        검색
                      </Button>
                    </div>
                    <div className='col-span-6 mt-10'>
                      <span className='block text-sm font-medium text-gray-700'>
                        주문번호로 후기 조회 및 답변 달기
                      </span>
                      <div className='flex flex-row whitespace-nowrap'>
                        <input
                          type='text'
                          placeholder='주문번호를 입력해주세요.'
                          value={focusOrderId || ''}
                          onChange={(e) => {
                            setFocusOrderId(e.target.value)
                          }}
                          className='block w-full mt-1 mr-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                        />
                        <Button
                          type='button'
                          className='px-4'
                          onClick={async () => {
                            if (!focusOrderId) {
                              setOpenReviewReplyModal(false)
                            } else {
                              SellerStore.setIsLoading(true)
                              const result =
                                await backendApis.searchReviewByOrderId(
                                  focusOrderId,
                                )
                              SellerStore.setIsLoading(false)
                              if (result?.status === 200 && result.data) {
                                setSearchedReviews(result?.data)
                              }
                              setOpenReviewReplyModal(true)
                            }
                          }}
                        >
                          검색
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-row gap-4'>
                <Button
                  appearance='neutralSub'
                  type='button'
                  onClick={async () => {
                    if (newSortType !== 'recent') {
                      setLoadedItemReviewInfo(false)
                      setNewSortType('recent')
                    }
                  }}
                >
                  최신순 정렬
                </Button>
                <Button
                  appearance='neutralSub'
                  type='button'
                  onClick={async () => {
                    if (newSortType !== 'badReview') {
                      setLoadedItemReviewInfo(false)
                      setNewSortType('badReview')
                    }
                  }}
                >
                  별점 낮은 순 정렬
                </Button>
                {sortedItemReviewInfo.filter((review) => review.isReviewEvent)
                  .length > 0 && (
                  <>
                    {newSortType !== 'reviewEvent' ? (
                      <Button
                        appearance='neutralSub'
                        type='button'
                        onClick={async () => {
                          if (newSortType !== 'reviewEvent') {
                            setLoadedItemReviewInfo(false)
                            setNewSortType('reviewEvent')
                          }
                        }}
                      >
                        후기 이벤트만 보기
                      </Button>
                    ) : (
                      <Button
                        appearance='neutralSub'
                        type='button'
                        onClick={async () => {
                          if (newSortType !== 'recent') {
                            setLoadedItemReviewInfo(false)
                            setNewSortType('recent')
                          }
                        }}
                      >
                        전체보기
                      </Button>
                    )}
                  </>
                )}
                <div className='flex felx-col'>
                  <Button
                    appearance='black'
                    type='button'
                    onClick={() => {
                      if (pageIndex > 0) {
                        setPageIndex(pageIndex - 1)
                      }
                    }}
                  >
                    이전
                  </Button>
                  <div
                    style={{
                      width: 50,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {pageIndex + 1}
                  </div>
                  <Button
                    appearance='black'
                    type='button'
                    onClick={async () => {
                      if (
                        pageIndex + 1 <
                        sortedItemReviewInfo.length / PAGE_REVIEW_NUM
                      ) {
                        setPageIndex(pageIndex + 1)
                      } else {
                        setLoadedItemReviewInfo(false)
                        let reviewsRaw = []
                        const result = await backendApis.getItemReviewInfo(
                          (pageIndex + 1) * PAGE_REVIEW_NUM,
                          PAGE_REVIEW_NUM,
                          sortType,
                          focusItemId,
                        )
                        if (sortType === 'recent') {
                          reviewsRaw = sortedItemReviewInfo
                            .concat(result?.data)
                            .sort(function (a, b) {
                              return (
                                new Date(b?.createdAt) - new Date(a?.createdAt)
                              )
                            })
                        } else if (sortType === 'badReview') {
                          reviewsRaw = sortedItemReviewInfo
                            .concat(result?.data)
                            .sort(function (a, b) {
                              return a?.ratingScore - b?.ratingScore
                            })
                        }
                        setSortedItemReviewInfo(reviewsRaw)
                        setPageIndex(pageIndex + 1)
                        setLoadedItemReviewInfo(true)
                      }
                    }}
                  >
                    다음
                  </Button>
                </div>
              </div>
              <Table data={itemReviewsList} columns={columns} />
            </>
          ) : (
            <div className='shadow-lg rounded-2xl p-8 bg-white min-w-[35%] '>
              지난 100일 간 후기가 존재하지 않아요.
            </div>
          )}
        </>
      )}
    </>
  )
})

export default ItemReviews

import React, { useEffect, useState } from 'react'
import Button from '../atoms/button'
import { GrClose as CloseIcon } from 'react-icons/gr'
import OptionChangeHistoryTable from './OptionChangeHistoryTable'
import backendApis from '../../utils/backendApis'

const OptionChangeHistoryModalButton = ({ itemId, title }) => {
  const [showModal, setShowModal] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [changeHistory, setChangeHistory] = useState([])

  const getFormattedDate = (createdAt) =>
    new Date(createdAt).toLocaleString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

  useEffect(() => {
    const fetchOptionsInfo = async () => {
      setIsLoading(true)
      try {
        const res = await backendApis.getAutonomousPriceChangeListByItemId({
          itemId,
        })
        setChangeHistory(res?.data?.data)
      } catch (error) {
        console.error(error)
      }
      setIsLoading(false)
    }

    if (showModal) {
      fetchOptionsInfo()
    }
  }, [itemId, showModal])

  const handleButtonClick = () => {
    setShowModal(true)
  }
  return (
    <>
      <Button appearance='neutralSub' onClick={handleButtonClick}>
        {title}
      </Button>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden outline-none'>
            <div className='relative flex flex-col w-9/12 bg-white rounded-md shadow-md h-3/4'>
              <div className='absolute top-0 right-0 mt-2 mr-2'>
                <Button
                  appearance='transparent'
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  <CloseIcon className='w-4 h-4' />
                </Button>
              </div>
              <div className='flex flex-col flex-1 p-10 overflow-y-auto'>
                <div className='mb-5 text-3xl font-bold'>
                  상세 옵션 변경 이력
                </div>
                <div style={{ overflowX: 'scroll' }}>
                  {!isLoading &&
                    changeHistory?.map((history) => (
                      <div key={history._id} className='my-4'>
                        <h2 className='text-2xl'>
                          변경일자: {getFormattedDate(history?.createdAt)}
                        </h2>
                        <p className='text-xl'>변경 전</p>
                        <OptionChangeHistoryTable
                          optionsInfo={history?.oldOptionsInfo}
                        />
                        <p className='text-xl'>변경 후</p>
                        <OptionChangeHistoryTable
                          optionsInfo={history?.newOptionsInfo}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}

export default OptionChangeHistoryModalButton

import React, { useEffect, useState } from 'react'
import Page from '../components/atoms/page'
import ItemComponent from '../components/ItemComponents/ItemComponent'
import Pagination from '../components/molecules/pagination'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import { FcPrivacy as TitleIcon } from 'react-icons/fc'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import DealApplicationModal from '../components/molecules/dealApplicationModal'
import { observer } from 'mobx-react-lite'
import moment from 'moment'

const under300OrderCountExcept = async (dealOptionsInfoArr) => {
  const filteredDealOptionsInfoArr = dealOptionsInfoArr?.filter(
    (dealOptionsInfo) => {
      const under300orderCount =
        dealOptionsInfo?.tenMinDealMetaData?.recentMonthOrderCount >= 300
      const notExistOrderCount =
        dealOptionsInfo?.tenMinDealMetaData?.recentMonthOrderCount === undefined

      return !under300orderCount || notExistOrderCount
    },
  )
  const filteredItemIdArr = filteredDealOptionsInfoArr?.map(
    (dealOptionsInfo) => dealOptionsInfo?.itemId,
  )
  return filteredItemIdArr
}
// accepted 인데, sellerConfirmed가 아니면 딜 진행날짜 빼기

const rejectionReasonTranslator = (rejectionReason) => {
  // fix me - 바뀐 딜 status에 맞게 변경 필요
  if (rejectionReason === 'fakeItem') {
    return '가품 의심'
  }
  if (rejectionReason === 'priceInappropriate') {
    return '가격 경쟁력이 낮습니다.'
  }
  if (rejectionReason === 'optionFragmentation') {
    return '옵션들을 하나의 상품으로 합쳐주세요'
  }
  if (rejectionReason === 'tooManyOptions') {
    return '옵션이 많습니다. 인기 옵션으로 추려주세요.'
  }
  if (rejectionReason === 'externalOptionOrImageIncluded') {
    return '외부 상표권/로고 등을 수정해주세요'
  }
  return `가격경쟁력이 낮습니다.`
}

const tenMinDealStatusInfoSetter = (itemInfo) => {
  const dealDate =
    itemInfo?.dealStandInfo?.dealDate ||
    itemInfo?.dealOptionsInfoObj?.tenMinDealMetaData?.tenMinDealDate
  const dealDateKST = dealDate ? moment(dealDate).format('YYYY-MM-DD') : '미정'
  const rejectionReason =
    rejectionReasonTranslator(
      itemInfo?.dealOptionsInfoObj?.tenMinDealMetaData?.recentRejectionReason,
    ) || null

  if (itemInfo?.dealOptionsInfoObj?.status === 'denied') {
    return {
      level: 1,
      message: `거절 사유 : ${rejectionReason}`,
      status: '거절됨',
    }
  }
  // 구조상 apply 는 존재할 수 없음.

  return {
    level: 3,
    message: `딜 진행일 : ${dealDateKST}`,
    status: '',
  }
}

const BannerComponent = () => {
  return (
    <div className='flex flex-col flex-1 p-10 text-white bg-black shadow-xl rounded-xl'>
      <div className='mb-5 font-bold'>
        10분 핫딜은 오전/오후 단 10분만 진행하는 완전 폐쇄 매대입니다.
      </div>
      <div>
        구매 전환율이 매우 높은 지면인 만큼 초대받은 소수의 셀러분들만 신청
        가능한 매대입니다.
      </div>
      <div>
        경쟁력있는 가격인지 확인하는 검수 과정에서 상품이 거절될 수 있습니다.
        최근 1달 내 진행된 상품은 즉시 재진행되기 어렵습니다.
      </div>
      <div className='mt-4 text-L font-bold'>
        {' '}
        주요 반려 사유 : 가격 경쟁력 부족, 중복 상품 신청, 가격 어뷰징 적발 등{' '}
      </div>
      <button
        type='button'
        className='inline-block px-4 py-2 mt-5 mr-5 text-black rounded-lg bg-sky-400 w-fit hover:bg-gray-100'
        onClick={() =>
          window.open(
            'https://levitinc.notion.site/10-bf6ab26070ea41fab8980a4889427838?pvs=4',
            '_blank',
          )
        }
      >
        10분 핫딜 가이드라인 (Q&A)
      </button>
    </div>
  )
}

const addDealStatus = async (filteredItem, dealType) => {
  const result = await backendApis.getDealStandItemBySellerId(dealType)
  const dealStandInfo = result?.data
  const sellerItemList = []
  dealStandInfo?.forEach((elem) => {
    const { dealItemList = [], dealDate } = elem
    for (const dealItem of dealItemList) {
      if (
        dealItem?.sellerId?.toString() ===
        SellerStore?.sellerInfo?._id?.toString()
      ) {
        sellerItemList.push({
          ...dealItem,
          dealDate,
        })
      }
    }
  })
  return sellerItemList
}

const checkArrangedDealItem = async (itemIdArr, dealOptionsInfoArray) => {
  // 딜 원클릭 상품은 바로 dealoptionsInfo에 박히므로, 검수 이후 상품만 노출
  const arrangedItemIdArr = []
  if (dealOptionsInfoArray.length === 0) return
  const afterInspectionDealItems = await dealOptionsInfoArray.filter(
    (dealDoc) => {
      return dealDoc?.status !== 'apply'
    },
  )

  itemIdArr.forEach((itemId) => {
    afterInspectionDealItems.forEach((dealOptionsInfo) => {
      if (itemId === dealOptionsInfo?.itemId) {
        arrangedItemIdArr.push(itemId)
      }
    })
  })
  return arrangedItemIdArr
}

const TenMinDealApplicationScreen = observer(({ sellerInfo }) => {
  const dealType = 'tenMinDeal'
  const [selectedItem, setSelectedItem] = useState()
  // const [applyStatusTab, setApplyStatusTab] = useState('candidate')
  const [applyStatusTab, setApplyStatusTab] = useState('complete') // 타임특가 인지 이슈로 취소시키기 위해 임시 수정
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [openDealApplicationModal, setOpenDealApplicationModal] =
    useState(false)
  const [itemsCount, setItemsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [tempItemsInfo, setTempItemsInfo] = useState([])
  const [itemsInfo, setItemsInfo] = useState([])
  const itemIds = tempItemsInfo?.map((itemInfo) => itemInfo?._id)

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    // itemCondition.thirdParty = { $ne: 'dealOneClick' }
    if (applyStatusTab === 'candidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              // dealType 이 없으면 노출
              $elemMatch: {
                $and: [{ key: 'deal', value: dealType }],
              },
            },
          },
        },
      ]
    } else if (applyStatusTab === 'complete') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        {
          itemFilters: {
            $elemMatch: {
              $and: [{ key: 'deal', value: dealType }],
            },
          },
        },
      ]
    }

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert(
          '상품 목록을 불러오는 중 문제가 발생했습니다. 다시 시도해주세요.',
        )
        setIsLoading(false)
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )

    if (result?.status === 2000) {
      // setTempItemsInfo(result?.data?.itemsInfo)
      const tempItemsInfo = result?.data?.itemsInfo
      renderItemReturnFunc(tempItemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setTempItemsInfo(retryResult?.data?.tempItemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert(
            '상품 목록을 불러오는 중 문제가 발생했습니다. 다시 시도해주세요.',
          )
        }
      }
    }
    setIsLoading(false)
    SellerStore.setIsLoading(false)
  }

  const renderItemReturnFunc = async (itemCollectionItem) => {
    const itemIdArr = itemCollectionItem?.map((item) => item?._id)
    const dealOptionsInfoResult = await backendApis.getDealOptionsInfoArray(
      itemIdArr,
      dealType,
    )
    if (dealOptionsInfoResult?.status === 200) {
      const dealOptionsInfoArr = dealOptionsInfoResult?.data
      const afterInspectionItemIdArr = await checkArrangedDealItem(
        itemIdArr,
        dealOptionsInfoArr,
      )
      const filteredItemInfoArr = itemCollectionItem?.filter((itemInfo) => {
        return afterInspectionItemIdArr?.includes(itemInfo?._id)
      })
      const dealStandInfo = await addDealStatus(filteredItemInfoArr, dealType)
      filteredItemInfoArr?.forEach((itemInfo) => {
        dealStandInfo?.forEach((standElem) => {
          if (standElem?.itemId?.toString() === itemInfo?._id?.toString()) {
            itemInfo.dealStandInfo = standElem
          }
        })
      })
      filteredItemInfoArr?.forEach((itemInfo) => {
        dealOptionsInfoArr?.forEach((dealOptionsInfo) => {
          if (
            dealOptionsInfo?.itemId?.toString() === itemInfo?._id?.toString()
          ) {
            itemInfo.dealOptionsInfoObj = dealOptionsInfo
          }
        })
      })
      setItemsInfo(filteredItemInfoArr)
      const maxPage = Math.ceil(filteredItemInfoArr.length / limit) || 1
      setPage(maxPage)
    }
  }

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [limit, applyStatusTab, sellerInfo])

  useEffect(() => {
    if (tempItemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  return (
    <>
      <div className='w-screen max-w-7xl'>
        <Page title='10분 핫딜 신청' Icon={TitleIcon}>
          <BannerComponent />
          <div>
            <div className='flex items-center justify-between flex-1 mt-10 mb-5'>
              <div className='mb-5 '>
                {/* <button
                  type='button'
                  onClick={() => {
                    setApplyStatusTab('candidate')
                    setPage(1)
                  }}
                  className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
                    applyStatusTab === 'candidate'
                      ? 'border-b-4 border-positive-500 text-black'
                      : 'text-gray-400'
                  }`}
                >
                  전체 상품
                </button> */}
                <button
                  type='button'
                  onClick={() => {
                    setApplyStatusTab('complete')
                    setPage(1)
                  }}
                  className={`select-none text-xl pb-1 font-bold duration-200 ease-in-out ${
                    applyStatusTab === 'complete'
                      ? 'border-b-4 border-positive-500 text-black'
                      : 'text-gray-400'
                  } w-max`}
                >
                  신청 완료 상품
                </button>
              </div>
              <div className='flex justify-end'>
                <ListLimitSelector limit={limit} setLimit={setLimit} />
              </div>
            </div>
            <div>
              {applyStatusTab === 'candidate'
                ? !isLoading && (
                    <>
                      {itemsInfo.map((itemInfo, idx) => {
                        return (
                          <div key={`${itemInfo._id + idx.toString()}1`}>
                            <ItemComponent
                              itemInfo={itemInfo}
                              dealRequest={() => {
                                setSelectedItem(itemInfo)
                                setOpenDealApplicationModal(true)
                              }}
                              dealType='tenMinDeal'
                              dealRankLimit='none' // non
                              isRankItem
                              dealApplyInfo={applyStatusTab === 'complete'}
                            />
                          </div>
                        )
                      })}
                    </>
                  )
                : !isLoading && (
                    // 신청 완료 상품
                    <>
                      {itemsInfo.map((itemInfo, idx) => {
                        const dealStatusInfo =
                          tenMinDealStatusInfoSetter(itemInfo)
                        return (
                          <div key={`${itemInfo._id + idx.toString()}2`}>
                            <ItemComponent
                              itemInfo={itemInfo}
                              dealRequest={() => {
                                setSelectedItem(itemInfo)
                                setOpenDealApplicationModal(true)
                              }}
                              dealCancelRequest={async () => {
                                const result =
                                  await backendApis.deleteDealOptionsInfo(
                                    itemInfo?._id,
                                    dealType,
                                  )
                                if (result?.status === 200) {
                                  alert('10분 핫딜 취소가 완료되었습니다')
                                  fetchSegmentedItemsInfo(true)
                                }
                              }}
                              dealType='tenMinDeal'
                              dealRankLimit='none'
                              isRankItem
                              dealApplyInfo={applyStatusTab === 'complete'}
                              dealStatusInfo={dealStatusInfo}
                            />
                          </div>
                        )
                      })}
                    </>
                  )}
              {itemsInfo?.length === 0 && (
                <div className='flex items-center justify-center flex-1 px-10 py-10 mb-5 bg-white shadow-lg rounded-xl'>
                  조건에 맞는 상품이 없습니다. 만약 상품 등록을 하지 않으신경우,
                  먼저 평시가로 상품을 등록해주세요.
                </div>
              )}
              <div>
                <Pagination
                  total={itemsInfo.length || 1}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                  size='lg'
                />
              </div>
            </div>
            {openDealApplicationModal && (
              <DealApplicationModal
                showModal={openDealApplicationModal}
                setShowModal={setOpenDealApplicationModal}
                dealType={dealType}
                itemInfo={selectedItem}
                setItemInfo={setSelectedItem}
                applied={applyStatusTab === 'complete'} // 이게 뭐냐
              />
            )}
          </div>
        </Page>
      </div>
    </>
  )
})

export default TenMinDealApplicationScreen

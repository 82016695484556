const ListDisCountRateSelector = ({
  discountRate,
  setDiscountRate,
  dealType,
}) => {
  const optionsToRender =
    dealType === 'timeDeal'
      ? [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
      : [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]

  return (
    <>
      <select
        className='border-gray-300 rounded-md shadow-sm select-none focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
        onChange={(e) => {
          setDiscountRate(Number(e.target.value))
        }}
        value={discountRate}
      >
        {optionsToRender.map((optionValue) => (
          <option key={optionValue} value={optionValue}>
            {optionValue}%(할인율)
          </option>
        ))}
      </select>
    </>
  )
}

export default ListDisCountRateSelector

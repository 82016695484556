import React, { useRef, useState, useEffect } from 'react'
import { Modal, Button, Carousel } from 'antd'
import styled from 'styled-components'

const StyledCarousel = styled(Carousel)`
  .slick-dots {
    position: relative;
    bottom: 0;
  }

  .slick-dots li {
    margin: 0 4px;
  }

  .slick-dots li button {
    height: 4px;
    background: #eeeff3;
    opacity: 1;
  }

  .slick-dots li.slick-active button {
    background: #1890ff;
    opacity: 1;
  }

  .slick-dots li button:before {
    display: none;
  }
`

const CarouselModal = ({ visible, onClose, slides }) => {
  const carouselRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const goToNextSlide = () => {
    const nextSlide = currentSlide + 1
    if (nextSlide < slides.length) {
      setCurrentSlide(nextSlide)
      carouselRef.current.goTo(nextSlide)
    }
  }

  const goToPrevSlide = () => {
    const prevSlide = currentSlide - 1
    if (prevSlide >= 0) {
      setCurrentSlide(prevSlide)
      carouselRef.current.goTo(prevSlide)
    }
  }

  const handleSlideChange = (from, to) => {
    setCurrentSlide(to)
  }

  const handleClose = () => {
    setCurrentSlide(0)
    onClose()
  }

  useEffect(() => {
    if (!visible) {
      setCurrentSlide(0)
      if (carouselRef.current) {
        carouselRef.current.goTo(0)
      }
    }
  }, [visible])

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      footer={null}
      centered
      width={600}
    >
      <div className='flex-1 flex w-full items-center justify-center'>
        <div className='w-[500px]'>
          <StyledCarousel
            ref={carouselRef}
            beforeChange={handleSlideChange}
            dots={{ className: 'custom-dots' }}
          >
            {slides.map((slide, index) => (
              <div key={index?.toString()} className='modal-container'>
                <div className='items-center justify-center flex flex-1 w-full'>
                  <img
                    src={slide.imageSrc}
                    alt={`슬라이드 ${index + 1}`}
                    className='w-[460px]'
                  />
                </div>
                <div className='my-5 items-center justify-center flex flex-1 flex-col w-full text-center'>
                  <div className='font-bold text-gray-800 text-[16px] whitespace-pre-wrap mb-2'>
                    {slide.title}
                  </div>
                  <div className='whitespace-pre-wrap text-[16px] text-slate-600'>
                    {slide.description}
                  </div>
                </div>
              </div>
            ))}
          </StyledCarousel>
          <div className='gap-2 items-center justify-center mt-8 flex flex-1 w-full'>
            {currentSlide > 0 && (
              <Button
                type='default'
                className='w-[100px]'
                size='large'
                onClick={goToPrevSlide}
              >
                이전
              </Button>
            )}
            {currentSlide < slides.length - 1 ? (
              <Button
                type='primary'
                size='large'
                className='w-[250px]'
                onClick={goToNextSlide}
              >
                다음
              </Button>
            ) : (
              <Button
                type='primary'
                size='large'
                className='w-[250px]'
                onClick={onClose}
              >
                완료
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CarouselModal

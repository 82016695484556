const couponSettingByTab = {
  1: {
    type: 'itemCoupon',
    name: '베이직 쿠폰',
    category: 'item',
    log: 'BasicCouponRegisterButton',
    totalIssuingCoupon: true,
    expirationPeriod: true,
  },
  2: {
    type: 'multiPurchase',
    name: '다다익선 쿠폰',
    category: 'item',
    log: 'DoubleUpCouponRegisterButton',
    totalIssuingCoupon: false,
    expirationPeriod: false,
  },
  3: {
    type: 'followCoupon',
    name: '팔로우 쿠폰',
    category: 'item',
    log: 'FollowCouponRegisterButton',
    totalIssuingCoupon: false,
    expirationPeriod: false,
  },
  4: {
    type: 'photoReview',
    name: '포토리뷰 쿠폰',
    category: 'seller',
    log: 'PhotoReviewCouponRegisterButton',
    totalIssuingCoupon: true,
    expirationPeriod: false,
  },
}
export default couponSettingByTab

import Button from '../atoms/button'
import {
  FiChevronLeft as LeftIcon,
  FiChevronRight as RightIcon,
} from 'react-icons/fi'
import { useEffect, useState } from 'react'

const Pagination = ({ total, limit, page, setPage, size = 'md' }) => {
  const numPages = Math.ceil(total / limit) || 1
  const [currentListFirstIndex, setCurrentListFirstIndex] = useState(1)

  useEffect(() => {
    if (page / 10 > 0) setCurrentListFirstIndex(Math.floor(page / 10) * 10 + 1)
  }, [page])

  const rightButtonFunction = () => {
    setCurrentListFirstIndex(Math.min(currentListFirstIndex + 10, numPages))
    setPage(Math.min(currentListFirstIndex + 10, numPages))
  }
  const leftButtonFunction = () => {
    setCurrentListFirstIndex(Math.max(currentListFirstIndex - 10, 1))
    setPage(Math.max(currentListFirstIndex - 10, 1))
  }
  const listArraySetter = () => {
    if (numPages - currentListFirstIndex > 10) {
      const listArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      return listArray
    }

    const listArray = []
    for (let i = currentListFirstIndex; i <= numPages; i++) {
      listArray.push(i)
    }
    return listArray
  }

  const styleBySize = {
    md: 'mx-1 text-md font-normal',
    lg: 'mx-1 text-lg font-normal',
  }

  return (
    <div>
      <nav className='flex items-center justify-center'>
        {currentListFirstIndex >= 10 ? (
          <Button
            appearance='transparent'
            textColor='black'
            onClick={() => {
              leftButtonFunction()
            }}
          >
            <LeftIcon />
          </Button>
        ) : (
          <></>
        )}
        {listArraySetter().map((_, i) => (
          <Button
            color='sub-500'
            appearance='transparent'
            textColor='black'
            key={_ + i.toString()}
            onClick={() => setPage(currentListFirstIndex + i)}
            className={page === currentListFirstIndex + i ? 'bg-sub-100' : ''}
          >
            <div className={`${styleBySize[size]}`}>
              {currentListFirstIndex + i}
            </div>
          </Button>
        ))}
        {numPages - currentListFirstIndex < 10 ? (
          <></>
        ) : (
          <Button
            appearance='transparent'
            textColor='black'
            onClick={() => rightButtonFunction()}
            disabled={numPages - currentListFirstIndex < 10}
          >
            <RightIcon />
          </Button>
        )}
      </nav>
    </div>
  )
}

export default Pagination

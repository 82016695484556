import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'

const ShippingDelays = observer(() => {
  const [isOnMouse, setIsOnMouse] = useState(false)
  return (
    <button
      className='CancelingPreShippingOrders'
      style={{
        // width: '24%',
        height: 78,
        borderColor: 'black',
        border: 'solid',
        display: 'flex',
        borderWidth: 1,
        borderRadius: 5,
        marginRight: 11,
        paddingLeft: 10,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        color: 'inherit',
        textDecoration: 'inherit',
        backgroundColor: isOnMouse ? '#F5F5F5' : '#FFF',
      }}
      type='button'
      onClick={async () => {
        document.getElementById('shippingOrders').scrollIntoView()
      }}
      onMouseEnter={() => {
        setIsOnMouse(true)
      }}
      onMouseLeave={() => {
        setIsOnMouse(false)
      }}
    >
      <div
        style={{
          // flex: 1,
          alignItems: 'right',
          justifyContent: 'center',
          width: '84%',
          fontSize: 16,
          fontWeight: 'bold',
          paddingleft: 8,
          textAlign: 'left',
        }}
      >
        <div>배송중 지연</div>
        <div>(배송흐름값 감지 이후)</div>
      </div>
      <div
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          // backgroundColor: 'red',
          width: '16%',
          color: 'red',
          textAlign: 'left',
          fontSize: 18,
        }}
      >
        <h2>{OrderStore.ordersNumPerStatus['shipping-delayed'] || 0}</h2>
      </div>
    </button>
  )
})

export default ShippingDelays

import React from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import Log from '../utils/log'
import AskDevs from '../images/askDevs.png'
import Button from '../components/atoms/button'

const SellerSupportScreen = observer(() => {
  return (
    <div className='max-w-7xl'>
      <img alt='askDevs' src={AskDevs} />
      <div className='bg-gray-50'>
        {/* 
        채널톡 버튼 붙이면서 카카오톡 문의버튼 삭제
        <div className='mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8'>
          <div>
            <h3 className='text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
              판매자 지원센터 문의
            </h3>
            <p className='block mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-md md:mt-5 md:text-xl lg:mx-0'>
              궁금한 점이 있거나, 버그를 발견한 경우 카카오톡{' '}
              <b>[올웨이즈 판매자지원센터]</b>로 문의해 주세요. 상담 시간은{' '}
              <b>평일 오전 9시~오후 6시</b>이며, 전화 상담은 어려운 점 양해
              부탁드리겠습니다. 모든 셀러님들이 성공하시는 그날이 올 때까지,
              저희 올웨이즈 개발팀은 함께 발맞춰 나아가겠습니다.
            </p>
          </div>
          <div className='mt-8 flex lg:mt-0 lg:flex-shrink-0'>
            <Button
              size='lg'
              onClick={(e) => {
                e.preventDefault()
                window.open('http://pf.kakao.com/_vxefts', '_blank')
                if (SellerStore.loadSellerInfo())
                  Log.event(
                    'SellerSupportScreenButton',
                    'SellerSupportScreenButton',
                    {
                      click: 'SellerSupportScreenButton',
                    },
                  )
              }}
            >
              카카오톡 1:1 문의하기 →
            </Button>
          </div>
        </div> */}
        <div className='mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8'>
          <div>
            <h3 className='text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
              올웨이즈 개발팀 건의
            </h3>
            <p className='block mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0'>
              셀러페이지 혹은 올웨이즈 앱을 사용하다가
              <b>&quot;이런 기능이 필요해요&quot;</b> 혹은{' '}
              <b>&quot;이런 점이 개선되면 좋겠어요&quot;</b> 한 점을 의견으로
              남겨주세요. 남겨주신 모든 의견은 셀러관리팀이{' '}
              <b>하나하나 읽어볼 것을 약속</b>
              드리며, 우선순위에 따라 하나씩 개선해 나가도록 하겠습니다.
            </p>
          </div>
          <div className='mt-8 flex lg:mt-0 lg:flex-shrink-0'>
            <Button
              size='lg'
              onClick={(e) => {
                e.preventDefault()
                window.open('https://forms.gle/L1oDPSg86qNWzwSv6', '_blank')
                if (SellerStore.loadSellerInfo())
                  Log.event(
                    'SellerSupportScreenButton',
                    'SellerSupportScreenButton',
                    {
                      click: 'SellerSupportScreenButton',
                    },
                  )
              }}
            >
              건의하기(구글폼) →
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default SellerSupportScreen

import RankIconSetter from '../molecules/rankIconSetter'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import Button from './button'
import ImageModal from './imageModal'
import { useState } from 'react'

export default function DetailedInfo({
  ranklimit,
  name,
  purpose,
  space,
  time,
  settlement,
  minDiscountInfo,
  etc,
  images,
}) {
  const [showModal, setShowModal] = useState(false)
  return (
    <div className='grid flex-col items-start justify-start flex-1 grid-cols-2 px-10 py-10 mt-5 bg-white shadow-lg rounded-xl'>
      <div>
        {ranklimit && (
          <div className='flex flex-row items-end flex-1 mb-3'>
            <RankIconSetter itemRank={ranklimit} className='w-8 h-8 mr-2' />
            등급 이상 상품만 신청 가능한 프로모션입니다.
          </div>
        )}
        <div className='text-lg font-bold text-sub-500'>기본정보</div>
        <ol className='ml-5 list-disc'>
          <li className='text-gray-500'>이름 - {name}</li>
          <li className='text-gray-500'>목적 - {purpose}</li>
          <li className='text-gray-500'>지면 - {space}</li>
          <li className='text-gray-500'>기간 - {time}</li>
          <li className='text-gray-500'>정산 - {settlement}</li>
          <li className='text-gray-500'>
            최소 할인율 - {minDiscountInfo || '없음'}
          </li>
        </ol>
        <div className='mt-3 text-lg font-bold text-sub-500'>상세정보</div>
        <div className='text-gray-500'>{etc}</div>
      </div>
      <div className='pl-10'>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop
          pagination={{
            clickable: true,
          }}
          className='rounded-lg shadow-md select-none'
          modules={[Pagination]}
        >
          {images &&
            images.map((image) => (
              <div key={image}>
                <SwiperSlide>
                  <div className='flex items-center justify-center w-full h-full text-lg text-black bg-white '>
                    <Button
                      size='none'
                      appearance='transparent'
                      onClick={() => setShowModal(true)}
                    >
                      <img alt='banner' src={image} />
                    </Button>
                  </div>
                </SwiperSlide>
                <ImageModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  image={image}
                />
              </div>
            ))}
        </Swiper>
      </div>
    </div>
  )
}

import Button from './button'
import Modal from './modal'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import { GrFormClose as CloseIcon } from 'react-icons/gr'
import { FcSalesPerformance as CoinIcon } from 'react-icons/fc'
import Tooltip from '../ADS/atoms/tooltip'
import SellerQuest from '../../utils/sellerQuest'
import backendApis from '../../utils/backendApis'
import {
  IoIosArrowUp as ArrowUpIcon,
  IoIosArrowDown as ArrowDownIcon,
} from 'react-icons/io'

const PriceQuest = observer(
  ({ openModal = true, setOpenModal = () => {}, setShowQuest }) => {
    const priceQuestStages = [
      'checkPriceTutorialModal',
      'playAutoCheck',
      'itemRankReport',
      'itemRankBatchModal',
    ]

    return (
      <>
        {openModal ? (
          <div className='px-5 py-3 bg-white shadow-xl select-none text-sub-700 rounded-xl w-80 h-120'>
            <div className='flex flex-row items-center justify-end flex-1 mb-1 '>
              <Button
                appearance='transparent'
                textColor='black'
                onClick={() => setShowQuest(false)}
              >
                <CloseIcon className='w-6 h-6' />
              </Button>
            </div>
            <div className='flex flex-row items-center justify-between flex-1 mb-3 text-lg font-bold'>
              <div className='flex flex-row items-center'>
                <CoinIcon className='mr-1' />
                가격 퀘스트
              </div>
              <Button
                appearance='transparent'
                textColor='black'
                onClick={() => setOpenModal(false)}
              >
                <ArrowUpIcon className='w-6 h-6' />
              </Button>
            </div>
            <div className='flex flex-row items-center justify-end flex-1 mb-1 text-sm'>
              <div>퀘스트 달성도</div>
            </div>
            {/* {JSON.stringify(tutorialInfo)} */}
            <div className='w-full h-4 mb-5 bg-gray-100 rounded-xl'>
              <div
                className='h-4 duration-300 ease-in-out bg-positive-500 rounded-xl'
                style={{
                  width: `${SellerQuest.questProgressChecker(
                    'priceQuest',
                    priceQuestStages,
                  )}%`,
                }}
              />
            </div>
            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center text-base font-bold'>
                  올웨이즈 가격정책 알아보기
                  <Tooltip text='💡올웨이즈 가격정책을 확인해보세요.' />
                </div>
                <Modal
                  className='shadow-none'
                  modalTitle='올웨이즈 입점을 환영합니다!'
                  imageSource='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/45b35d2c-1ac9-4e18-94d7-b42f9c21cdae.png'
                  modalContent={
                    <>
                      <div className='mb-3 text-lg font-bold'>
                        다음 내용을 반드시 확인해주세요!
                      </div>
                      <ol className='ml-5 list-decimal'>
                        <li>
                          올웨이즈는 수수료가 쌉니다.{' '}
                          <span className='font-bold text-blue-500'>
                            실질수수료 0%
                          </span>
                          (PG사 수수료 3.5%)로 국내 최저 수준입니다.
                        </li>
                        <li>
                          올웨이즈는 폐쇄몰입니다. 업로드 해주신 모든 제품은
                          네이버 쇼핑을 비롯한 오픈마켓 가격비교 채널에{' '}
                          <span className='font-bold text-blue-500'>
                            노출되지 않습니다.
                          </span>
                        </li>
                        <li>
                          올웨이즈는 최저가 플랫폼입니다. 온라인 최저가 대비
                          비싼 제품은{' '}
                          <span className='font-bold text-blue-500'>
                            노출도에 불이익을 받습니다.
                          </span>
                        </li>
                      </ol>
                    </>
                  }
                  modalLog={priceQuestStages[0]}
                  isButton
                  buttonText='확인했습니다'
                  buttonCallbackFunction={async () => {
                    await SellerQuest.questClearer(
                      'priceQuest',
                      priceQuestStages[0],
                    )
                  }}
                  appearance={
                    SellerQuest.questChecker('priceQuest', priceQuestStages[0])
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'priceQuest',
                    priceQuestStages[0],
                  )}
                >
                  {SellerQuest.questChecker('priceQuest', priceQuestStages[0])
                    ? '완료'
                    : 'GO'}
                </Modal>
              </div>
            </div>
            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center'>
                  <div className='text-base font-bold'>
                    상품 대량 등록 확인하기
                  </div>
                  <Tooltip text='💡상품 대량 등록 페이지에서 </br> <대량 등록 바로가기> 버튼을 눌러보세요.' />
                </div>
                <Button
                  href='/third-party/play-auto'
                  appearance={
                    SellerQuest.questChecker('priceQuest', priceQuestStages[1])
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'priceQuest',
                    priceQuestStages[1],
                  )}
                >
                  {SellerQuest.questChecker('priceQuest', priceQuestStages[1])
                    ? '완료'
                    : 'GO'}
                </Button>
              </div>
            </div>
            {/* <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center'>
                  <div className='text-base font-bold'>등급 재배정 해보기</div>
                  <Tooltip text='💡점수UP 기능을 사용해보세요.' />
                </div>
                <Button
                  href='/items'
                  appearance={
                    SellerQuest.questChecker('priceQuest', priceQuestStages[2])
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'priceQuest',
                    priceQuestStages[2],
                  )}
                >
                  {SellerQuest.questChecker('priceQuest', priceQuestStages[2])
                    ? '완료'
                    : 'GO'}
                </Button>
              </div>
            </div> */}
            {/* <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center'>
                  <div className='text-base font-bold'>
                    대량 등급 재배정 해보기
                    <Tooltip text='💡대량 등급 재배정을 해보세요.' />
                  </div>
                </div>
                <Button
                  href='/items'
                  appearance={
                    SellerQuest.questChecker('priceQuest', priceQuestStages[4])
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'priceQuest',
                    priceQuestStages[3],
                  )}
                >
                  {SellerQuest.questChecker('priceQuest', priceQuestStages[4])
                    ? '완료'
                    : 'GO'}
                </Button>
              </div>
            </div> */}
            <div className='flex flex-row justify-center flex-1 w-full'>
              <Modal
                modalTitle='가격 퀘스트를 완료하셨습니다'
                imageSource='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/2f6a7484-df4d-443f-b719-d182314b4352.jpeg'
                modalContent={
                  <>
                    <div className='items-center mb-3 text-lg font-bold'>
                      축하드립니다!
                    </div>
                    <div className='items-center mb-3 '>
                      가격 퀘스트를 완료하신 모든 분들께는{' '}
                      <span className='font-bold text-positive-500'>
                        일주일 노출도 부스팅 효과
                      </span>
                      를 적용해드리고 있습니다. 판매에 도움을 드리기 위해 최선을
                      다하겠습니다.
                    </div>
                    <div className='items-center mb-3 '>
                      올웨이즈는{' '}
                      <span className='font-bold text-positive-500'>
                        가격이 저렴한 상품에 수요를 몰아주는 플랫폼
                      </span>
                      입니다. 모든 상품에는 가격을 반영한 상품 등급이 부여되며
                      상품 등급이 높을 수록 높은 노출도와 판매량을 얻어가실 수
                      있습니다.
                    </div>
                    <div className='items-center mb-3 '>
                      셀러분들이 낮은 가격에 공급하실 수 있도록{' '}
                      <span className='font-bold text-positive-500'>
                        국내 최저 수수료
                      </span>
                      로 함께하겠습니다. 입점해주셔서 진심으로 감사드립니다.
                    </div>
                  </>
                }
                modalLog='itemRankTutorialButton'
                isButton
                buttonText='노출도 부스팅 받기'
                buttonCallbackFunction={async () => {
                  if (
                    SellerQuest.questProgressChecker(
                      'priceQuest',
                      priceQuestStages,
                    ) === 100 &&
                    !SellerQuest.rewardChecker('priceQuest')
                  ) {
                    const result =
                      await backendApis.updateSellerQuestRewardStatus(
                        'priceQuest',
                      )
                    if (result?.status === 200) {
                      alert(
                        '가격 퀘스트를 완료하셨습니다. 부스팅 효과를 받으실 수 있습니다.',
                      )
                    }
                    await SellerStore.loadSellerInfo()
                  }
                }}
                appearance={
                  SellerQuest.questProgressChecker(
                    'priceQuest',
                    priceQuestStages,
                  ) === 100 && !SellerQuest.rewardChecker('priceQuest')
                    ? 'positive'
                    : 'disabled'
                }
                size='flexlg'
                disabled={
                  SellerQuest.questProgressChecker(
                    'priceQuest',
                    priceQuestStages,
                  ) !== 100 || SellerQuest.rewardChecker('priceQuest')
                }
              >
                <span className='flex flex-row items-center justify-center flex-1'>
                  일주일 노출도 부스팅 받기
                </span>
              </Modal>
            </div>
          </div>
        ) : (
          <Button
            appearance='transparent'
            onClick={() => {
              setOpenModal(true)
            }}
          >
            <div className='flex flex-row items-center justify-between flex-1 px-5 py-3 text-black bg-white shadow-xl w-80 rounded-xl'>
              <div className='flex flex-row items-center'>
                <CoinIcon className='mr-2' />
                가격 퀘스트 보기
              </div>
              <ArrowDownIcon className='ml-2' />
            </div>
          </Button>
        )}
      </>
    )
  },
)
export default PriceQuest

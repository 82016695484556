const ListStockSelector = ({ stockNumber, setStockNumber, dealType }) => {
  return (
    <>
      <select
        className='border-gray-300 rounded-md shadow-sm select-none focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
        onChange={(e) => {
          setStockNumber(Number(e.target.value))
        }}
        value={stockNumber}
      >
        {dealType === 'timeDeal' && (
          <>
            <option value={10}>10개(재고량)</option>
            <option value={50}>50개(재고량)</option>
            <option value={100}>100개(재고량)</option>
          </>
        )}
        <option value={200}>200개(재고량)</option>
        <option value={500}>500개(재고량)</option>
        <option value={1000}>1000개(재고량)</option>
        <option value={3000}>3000개(재고량)</option>
        <option value={5000}>5000개(재고량)</option>
      </select>
    </>
  )
}
export default ListStockSelector

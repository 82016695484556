import { Button } from 'antd'
import OptionTable from '../molecules/optionTable'
import React, { useState, useEffect } from 'react'
import OneClickReviewComponent from './OneClickReviewComponent'
import properPriceSetter from '../../utils/properPriceSetter'

const OneClickItemDetailInfo = ({
  itemInfo,
  salesRatio = 0.9,
  isDealRegister = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {
    bestItemId,
    detailHTML = '',
    detailImageUris = [],
    externalCategoryInfo = {},
    externalLink = '',
    externalMainPrice = null,
    externalReviewContents = [],
    mainImageUris = [],
    shippingInfo = {},
    optionsInfo = {},
    itemTitle = '',
    externalDeliveryFee = 0,
  } = itemInfo || {}

  const parsedOptionsInfo = JSON.parse(JSON.stringify(optionsInfo))
  parsedOptionsInfo?.optionPrices?.flat()?.forEach((option) => {
    option.teamPurchasePrice = properPriceSetter(
      option?.teamPurchasePrice + externalDeliveryFee,
      salesRatio,
    )
    if (isDealRegister) {
      option.externalDealPrice = properPriceSetter(
        option?.externalDealPrice + externalDeliveryFee,
        salesRatio,
      )
    }
  })

  const columns = [
    { Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 },
    { Header: '옵션2 / 상세옵션', accessor: 'option2', index: 2 },
  ]

  if (isDealRegister) {
    columns.push({
      Header: '딜진행가',
      accessor: 'externalDealPrice',
      copyField: 'externalDealPrice',
      index: 3,
    })
  } else {
    columns.push({
      Header: '팀구매가',
      accessor: 'teamPurchasePriceTemp',
      copyField: 'teamPurchasePrice',
      index: 3,
    })
  }

  return (
    <div className='flex flex-col w-[40vw] h-[1780px] p-10 bg-white shadow-xl rounded-xl'>
      <div className='mb-10 text-2xl font-bold text-blue-500'>{itemTitle}</div>
      <div className='flex flex-col justify-start w-full mb-10'>
        <div className='mb-5'>
          <div className='text-lg font-bold'>상품 옵션정보</div>
          <div>옵션 정보와 가격을 확인해보세요!</div>
        </div>
        <div className='max-h-[250px] overflow-y-auto'>
          <OptionTable optionsInfo={parsedOptionsInfo} columns={columns} />
        </div>
      </div>
      <div className='flex flex-col justify-start mb-10'>
        <div className='mb-5'>
          <div className='text-lg font-bold'>외부 리뷰정보</div>
          <div>바로 연동될 리뷰 리스트를 확인하실 수 있습니다.</div>
        </div>
        <div className='max-h-[250px] overflow-y-auto'>
          {externalReviewContents.map((review) => {
            return (
              <div key={review?._id} className='mb-5'>
                <OneClickReviewComponent reviewInfo={review} />
              </div>
            )
          })}
        </div>
      </div>
      <div className='flex flex-col justify-start'>
        <div className='mb-5'>
          <div className='text-lg font-bold'>상품 상세정보</div>
          <div>상세페이지를 확인하실 수 있습니다. 스크롤 해보세요!</div>
        </div>
        <div className='max-h-[800px] overflow-y-auto'>
          <div className='relative border-2 border-gray-500'>
            {detailHTML === '' ? (
              <>
                {detailImageUris.map((uri) => {
                  return <img key={uri.toString()} src={uri} alt='detail' />
                })}
              </>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: detailHTML }} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default OneClickItemDetailInfo

import { makeObservable, observable, action } from 'mobx'

class RegistrationStore {
  isTempStoredDataChanged = false
  isClickedTempSaveBtnOnHeader = false
  isClickedTempLoadBtnOnHeader = false

  constructor() {
    makeObservable(this, {
      isTempStoredDataChanged: observable,
      isClickedTempSaveBtnOnHeader: observable,
      isClickedTempLoadBtnOnHeader: observable,

      setIsTempStoredDataChanged: action,
      setIsClickedTempSaveBtnOnHeader: action,
      setIsClickedTempLoadBtnOnHeader: action,
    })
  }

  setIsTempStoredDataChanged(isTempStoredDataChanged) {
    this.isTempStoredDataChanged = isTempStoredDataChanged
  }

  setIsClickedTempSaveBtnOnHeader(isClickedTempSaveBtnOnHeader) {
    this.isClickedTempSaveBtnOnHeader = isClickedTempSaveBtnOnHeader
  }

  setIsClickedTempLoadBtnOnHeader(isClickedTempLoadBtnOnHeader) {
    this.isClickedTempLoadBtnOnHeader = isClickedTempLoadBtnOnHeader
  }
}

export default new RegistrationStore()

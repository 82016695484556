import React, { useState, useEffect } from 'react'
import SellerStore from '../../stores/SellerStore'
import ImageUploading from 'react-images-uploading'
import backendApis from '../../utils/backendApis'
import { observer } from 'mobx-react-lite'

const InquiryModal = observer(({ onClose }) => {
  useEffect(async () => {
    const loadSellerInfo = async () => {
      await SellerStore.loadSellerInfo()
      setSellerName(SellerStore.sellerInfo?.sellerName || '미입력')
      setSellerDisplayName(
        SellerStore.sellerInfo?.sellerDisplayName || '미입력',
      )
      setPhoneNumber(SellerStore.sellerInfo?.phoneNumber || '미입력')
    }
    loadSellerInfo()
  }, [])

  const [showInquiryHistory, setShowInquiryHistory] = useState(false)
  const [sellerName, setSellerName] = useState('')
  const [sellerDisplayName, setSellerDisplayName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [type, setType] = useState('')
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [files, setFiles] = useState([])
  const [fileError, setFileError] = useState(false)

  const notice = [
    '검증 과정에서 문제가 있는 경우, 아래 내용을 작성해주세요.',
    '문의 내용을 작성하실 때, 필요시 증빙자료를 함께 첨부하여 제출해주세요.',
    '확인 과정에서 판매자센터 담당직원이 별도로 연락을 드릴 수 있습니다.',
  ]

  const inquiryTypes = [
    '사업자등록증 이미지 검증 오류',
    '사업자등록번호 검증 오류',
    '통신판매업 미신고',
    '통신판매업신고번호 검증 오류',
    '정산계좌 명의 검증 오류',
    '채권포기 확약서 제출',
    '기타',
  ]

  if (showInquiryHistory) {
    return (
      <InquiryHistory
        onClose={onClose}
        onBack={() => setShowInquiryHistory(false)}
      />
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault() // 새로고침 방지

    // 파일 검증 - 최소 1개 이상
    if (files.filter(Boolean).length === 0) {
      setFileError(true)
      alert('최소 1개 이상의 파일을 첨부해주세요.')
      return
    }

    // 나머지 필수 필드 검증
    if (!type || !title || !content) {
      alert('모든 필수 항목을 입력해주세요.')
      return
    }

    try {
      const inquiryData = {
        sellerName,
        sellerDisplayName,
        phoneNumber,
        type,
        title,
        content,
        files,
      }

      const result = await backendApis.updateBusinessRegistrationInquiry(
        inquiryData,
      )

      if (result?.status === 200) {
        alert('문의가 정상적으로 제출되었습니다.')
        onClose()
      } else {
        throw new Error('문의 제출에 실패하였습니다. 다시 시도해주세요.')
      }
    } catch (error) {
      alert('문의 제출 과정에서 오류가 발생하였습니다. 다시 시도해주세요.')
    }
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center'>
      <div className='absolute inset-0 bg-black opacity-50' />
      <div className='relative bg-white rounded-lg w-[800px] max-h-[80vh] overflow-y-auto'>
        <div className='p-6'>
          <div className='flex justify-between items-center mb-6'>
            <h2 className='text-xl font-bold'>문의하기</h2>
            <button
              type='button'
              onClick={() => setShowInquiryHistory(true)}
              className='font-bold text-blue-500 hover:text-blue-700'
            >
              문의 목록
            </button>
          </div>

          <div className='bg-blue-50 p-4 rounded-md mb-6'>
            {notice.map((item, index) => (
              <p key={index} className='text-blue-600 mb-1'>
                • {item}
              </p>
            ))}
          </div>

          <form onSubmit={handleSubmit} className='space-y-4'>
            <div className='grid grid-cols-2 gap-4'>
              <div>
                <label className='block mb-2'>판매자명</label>
                <input
                  type='text'
                  value={`${sellerDisplayName} (ID: ${sellerName})`}
                  readOnly
                  disabled
                  className='w-full border rounded-md p-2 bg-gray-50'
                />
              </div>
              <div>
                <label className='block mb-2'>연락처</label>
                <input
                  type='text'
                  value={phoneNumber}
                  readOnly
                  disabled
                  className='w-full border rounded-md p-2 bg-gray-50'
                />
              </div>
            </div>

            <div>
              <label className='block mb-2'>문의 유형*</label>
              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                className='w-full border rounded-md p-2'
                required
              >
                <option value=''>선택해주세요</option>
                {inquiryTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className='block mb-2'>제목*</label>
              <input
                type='text'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className='w-full border rounded-md p-2'
                minLength={5}
                maxLength={50}
                placeholder='제목을 입력해주세요 (5-50자)'
                required
              />
            </div>

            <div>
              <label className='block mb-2'>내용*</label>
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className='w-full border rounded-md p-2 h-32'
                minLength={10}
                maxLength={500}
                placeholder='내용을 입력해주세요 (10-500자)'
                required
              />
            </div>

            <div>
              <label className='block mb-2'>
                첨부파일*
                {fileError && (
                  <span className='text-red-500 text-sm ml-2'>
                    (최소 1개 이상의 파일을 첨부해주세요)
                  </span>
                )}
              </label>
              {[1, 2, 3, 4, 5].map(
                (
                  num, // 5개로 증가
                ) => (
                  <div key={num} className='mb-3'>
                    <ImageUploading
                      multiple={false}
                      value={files[num - 1] ? [files[num - 1]] : []}
                      onChange={(fileList) => {
                        const newFiles = [...files]
                        newFiles[num - 1] = fileList[0]

                        // 파일 배열 재정렬
                        const reorderedFiles = newFiles.filter(Boolean)

                        // 첨부 파일을 files state에 저장
                        setFiles(reorderedFiles)
                        setFileError(false)
                      }}
                      maxFileSize={5e6}
                      acceptType={['jpg', 'png', 'jpeg', 'pdf']}
                      onError={(e) => {
                        if (e.maxFileSize) {
                          alert('파일 용량을 확인해주세요. (최대 5MB)')
                        } else if (e.acceptType) {
                          alert(
                            '지원 가능한 파일 형식을 확인해주세요.(jpg/jpeg/png/pdf)',
                          )
                        } else {
                          alert('지원되지 않는 파일 형식입니다.')
                        }
                      }}
                      allowNonImageType={true}
                      maxNumber={1}
                      dataURLKey='sourceUri'
                    >
                      {({ onImageUpload, dragProps }) => (
                        <div className='flex items-center space-x-2'>
                          <button
                            type='button'
                            onClick={onImageUpload}
                            {...dragProps}
                            disabled={
                              (num > 1 && !files[num - 2]) || // 이전 파일이 없으면 비활성화
                              files[num - 1] !== undefined // 현재 슬롯에 파일이 있으면 비활성화
                            }
                            className={`px-3 py-1 rounded-md transition-colors duration-200 
                            flex items-center space-x-2 border
                            ${(() => {
                              if (num > 1 && !files[num - 2]) {
                                return 'bg-gray-100 text-gray-400 cursor-not-allowed border-gray-200'
                              }
                              if (files[num - 1] !== undefined) {
                                return 'bg-gray-100 text-gray-700 border-gray-300'
                              }
                              return 'bg-gray-100 text-gray-700 hover:bg-gray-200 border-gray-300' // 기본값
                            })()}
                            ${fileError ? 'border-red-500' : ''}`}
                          >
                            <svg
                              className='w-5 h-5'
                              fill='none'
                              stroke='currentColor'
                              viewBox='0 0 24 24'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
                              />
                            </svg>
                            <span>파일{num} 선택</span>
                          </button>
                          {files[num - 1] && (
                            <div className='flex items-center space-x-2'>
                              <span className='text-sm text-gray-600'>
                                {files[num - 1].file?.name}
                              </span>
                              <button
                                type='button'
                                aria-label='삭제'
                                onClick={() => {
                                  const newFiles = [...files]
                                  newFiles[num - 1] = null

                                  // 파일 배열 재정렬
                                  const reorderedFiles =
                                    newFiles.filter(Boolean)

                                  setFiles(reorderedFiles)
                                }}
                                className='text-red-500 hover:text-red-700'
                              >
                                <svg
                                  className='w-5 h-5'
                                  fill='none'
                                  stroke='currentColor'
                                  viewBox='0 0 24 24'
                                >
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M6 18L18 6M6 6l12 12'
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                ),
              )}
              <p className='text-sm text-gray-500 mt-1'>
                JPG, PNG, JPEG, PDF 지원 (최대 5MB)
              </p>
            </div>

            <div className='flex justify-end space-x-2 mt-6'>
              <button
                type='button'
                onClick={onClose}
                className='px-4 py-2 text-gray-500 hover:text-gray-700'
              >
                닫기
              </button>
              <button
                type='submit'
                className='px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600'
              >
                제출하기
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
})

const InquiryHistory = observer(({ onClose, onBack }) => {
  const [inquiries, setInquiries] = useState([])
  const [selectedInquiry, setSelectedInquiry] = useState(null)

  useEffect(() => {
    const fetchInquiries = async () => {
      const result = await backendApis.getBusinessRegistrationInquiryHistory()

      if (result?.data) {
        const formattedInquiries = result.data.map((inquiry, index) => ({
          id: index + 1,
          title: inquiry.title,
          content: inquiry.content,
          type: inquiry.type,
          files: inquiry.files,
          status: inquiry.status,
          createdAt: new Date(inquiry.createdAt).toLocaleDateString(),
          answeredAt: inquiry.answeredAt
            ? new Date(inquiry.answeredAt).toLocaleDateString()
            : null,
          answer: inquiry.answer || null,
        }))

        setInquiries(formattedInquiries)
      }
    }

    fetchInquiries()
  }, [])

  return selectedInquiry ? (
    // 특정 문의 목록을 선택했을 때 스크린
    <div className='fixed inset-0 z-50 flex items-center justify-center'>
      <div className='absolute inset-0 bg-black opacity-50' />
      <div className='relative bg-white rounded-lg w-[800px] h-[60vh] flex flex-col'>
        <div className='flex justify-between items-center p-6'>
          <h2 className='text-xl font-bold'>문의 상세</h2>
          <button
            type='button'
            onClick={() => setSelectedInquiry(null)}
            className='font-semibold text-gray-500 hover:text-gray-700'
          >
            ← 목록으로
          </button>
        </div>

        <div className='flex-1 overflow-y-auto px-6'>
          <div className='space-y-8'>
            {/* 기본 정보 영역 */}
            <div className='border-b pb-4'>
              <h3 className='text-xl font-bold mb-2'>
                제목: {selectedInquiry.title}
              </h3>
              <div className='flex space-x-4 text-gray-500'>
                <p>
                  문의일자:{' '}
                  {new Date(selectedInquiry.createdAt).toLocaleDateString()}
                </p>
                <p>문의유형: {selectedInquiry.type}</p>
              </div>
            </div>

            {/* 문의 내용 */}
            <div>
              <h4 className='text-lg font-semibold mb-3'>문의 내용</h4>
              <div className='whitespace-pre-wrap p-4 bg-white rounded-lg border border-gray-200'>
                {selectedInquiry.content}
              </div>
            </div>

            {/* 첨부 파일 */}
            {selectedInquiry.files?.length > 0 && (
              <div>
                <h4 className='text-lg font-semibold mb-3'>첨부 파일</h4>
                <div className='flex flex-wrap gap-3'>
                  {selectedInquiry.files.map((file, index) => (
                    <a
                      key={file.fileUrl}
                      href={file.fileUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='inline-flex items-center px-3 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100'
                    >
                      {file.originalName || `첨부파일 ${index + 1}`}
                    </a>
                  ))}
                </div>
              </div>
            )}

            {/* 답변 내용 */}
            <div>
              <h4 className='text-lg font-semibold mb-3'>답변 내용</h4>
              <div className='whitespace-pre-wrap p-4 bg-white rounded-lg border border-gray-200'>
                {selectedInquiry.answer
                  ? selectedInquiry.answer
                  : '답변 대기 중입니다.'}
              </div>
            </div>
          </div>
        </div>

        <div className='p-6 flex justify-end'>
          <button
            type='button'
            onClick={onClose}
            className='px-4 py-2 text-gray-500 hover:text-gray-700'
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  ) : (
    // 문의 목록을 보여주는 스크린
    <div className='fixed inset-0 z-50 flex items-center justify-center'>
      <div className='absolute inset-0 bg-black opacity-50' />
      <div className='relative bg-white rounded-lg w-[800px] h-[60vh] flex flex-col'>
        <div className='flex justify-between items-center p-6'>
          <h2 className='text-xl font-bold'>문의 목록</h2>
          <button
            type='button'
            onClick={onBack}
            className='font-semibold text-gray-500 hover:text-gray-700'
          >
            ← 돌아가기
          </button>
        </div>

        <div className='flex-1 overflow-y-auto px-6'>
          <table className='w-full border-separate'>
            <thead className='bg-white sticky top-0 z-50 border-t-4'>
              <tr className='text-center border-b-4'>
                <th className='py-2 w-16'>번호</th>
                <th className='py-2'>제목</th>
                <th className='py-2 w-40'>상태</th>
                <th className='py-2 w-28'>문의날짜</th>
              </tr>
            </thead>
            <tbody>
              {inquiries.map((inquiry) => (
                <tr
                  key={inquiry.id}
                  className='border-b text-center hover:bg-gray-50 cursor-pointer'
                  onClick={() => setSelectedInquiry(inquiry)}
                >
                  <td className='py-3 w-20'>{inquiry.id}</td>
                  <td className='text-left'>{inquiry.title}</td>
                  <td className='w-32'>
                    {inquiry.status !== 'pending' ? (
                      <span className='text-green-600'>
                        답변완료 ({inquiry.answeredAt})
                      </span>
                    ) : (
                      <span>대기 중</span>
                    )}
                  </td>
                  <td className='w-28'>
                    {new Date(inquiry.createdAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='p-6 flex justify-end'>
          <button
            type='button'
            onClick={onClose}
            className='px-4 py-2 text-gray-500 hover:text-gray-700'
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  )
})

export default InquiryModal

import {
  FcLock as LockIcon,
  FcBullish as TrendIcon,
  FcElectricalThreshold as AiIcon,
} from 'react-icons/fc'
import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay, Pagination } from 'swiper'

const TipBox = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Autoplay, Pagination]}
        className='text-black select-none'
      >
        <SwiperSlide>
          <div className='flex flex-col items-start justify-center justify-items-start flex-1 h-full pr-10 text-base mb-3'>
            <div className='flex flex-row items-center mb-3 text-xl font-bold'>
              <AiIcon className='mr-2' />
              리셀러 제한 안내
            </div>
            <div>
              <span className='font-bold text-theme-500'>올웨이즈</span>에서는
              자체적으로 리셀러를 <span className='font-bold'>제한</span>하고
              있습니다.
            </div>
            <div>리셀러 걱정 없이 고객에게 최상의 상품을 제공해주세요.</div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex flex-col items-start justify-center flex-1 h-full pr-10 text-base'>
            <div className='flex flex-row items-center mb-3 text-xl font-bold'>
              <LockIcon className='mr-2' />
              올웨이즈는 폐쇄몰입니다
            </div>
            <div>
              <span className='font-bold text-theme-500'>올웨이즈</span>의 모든
              제품은 가격비교 서비스에 노출되지 않습니다.
            </div>
            <div className='mb-3'>
              완전 폐쇄 형태로{' '}
              <span className='font-bold text-blue-500'>
                가격 노출 걱정 없이
              </span>{' '}
              판매해보세요.
            </div>
            <div className='mb-10 text-sm text-red-500'>
              *플랫폼수수료 0%로 2주 안에 정산받으실 수 있습니다.(PG수수료 3.5%
              별도)
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex flex-col items-start justify-center flex-1 h-full pr-10 text-base'>
            <div className='flex flex-row items-center mb-3 text-xl font-bold'>
              <TrendIcon className='mr-2' />
              상품 등급을 올려보세요
            </div>
            <div>
              <span className='font-bold text-blue-500'>상품 등급</span>은
              서비스 내 상품 노출도에 큰 영향을 줍니다.
            </div>
            <div className='mb-3 inline-block text-left'>
              가격이 낮거나 고객 반응이 좋은 제품들은{' '}
              <span className='font-bold text-blue-500'>높은 등급 점수</span>를
              받으실 수 있습니다.
            </div>
            <div className='mb-10 text-sm text-red-500 text-left'>
              *상품 등급 평가는 매주 월요일 실시되는 정기 평가와 상시 신청을
              통한 상시 평가로 구성됩니다.
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}
export default TipBox

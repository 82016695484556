import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import Button from '../atoms/button'
import { Link, useHistory } from 'react-router-dom'
import Ping from '../atoms/ping'
import AlertModal from '../atoms/alertModal'

const SellerPenaltyNotice = observer(() => {
  const [recentPenaltyPointInfo, setRecentPenaltyPointInfo] = useState([])
  const [showAlertModal, setShowAlertModal] = useState(false)
  const history = useHistory()

  const penalty = {
    review: {
      name: '리뷰 점수 하락',
    },
    delivery: {
      name: '배송지연',
    },
    refundAndExchange: {
      name: '교환반품 지연',
    },
    cancellation: {
      name: '판매자 귀책으로 인한 상품 및 배송 취소',
    },
    itemQuestion: {
      name: '고객&고객센터 문의지연',
    },
    illegalAct: {
      name: '고의적 부당행위',
    },
  }

  useEffect(async () => {
    const result = await backendApis.getPenaltyPointRecent()
    if (result?.status === 200) {
      setRecentPenaltyPointInfo(result?.data)
    }
  }, [])
  const penaltyType = recentPenaltyPointInfo?.[0]?.penaltyType
  const penaltyTypeCoverted = []
  for (let i = 0; i < penaltyType?.length; i += 1) {
    penaltyTypeCoverted.push(penalty?.[penaltyType[i]]?.name)
  }
  const totalPoint = recentPenaltyPointInfo?.[0]?.totalPoint

  useEffect(() => {
    if (totalPoint > 0 || penaltyTypeCoverted?.length > 0) {
      setShowAlertModal(true)
    }
  }, [penaltyTypeCoverted, totalPoint])

  return (
    <>
      <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
        <div className='flex justify-between'>
          <div className='text-lg font-bold'>패널티 현황</div>
          <div className='flex items-center'>
            {totalPoint > 0 && penaltyTypeCoverted?.length > 0 && (
              <div>
                <Ping />
              </div>
            )}

            <Button
              textColor='black'
              appearance='transparent'
              className='hover:bg-slate-50'
            >
              <Link to='/item-penalty'>전체 보기 및 답변 제출</Link>
            </Button>
          </div>
        </div>
        <div className='text-sm'>
          페널티가 많이 부과되면 판매 활동이 중지됩니다. 페널티를 관리해주세요.
        </div>
        <div className='flex flex-col justify-end mt-5 space-y-2'>
          <div className='flex'>
            <div className='w-1/4'>최근 3일간 부여된 총 페널티 점수</div>
            <div className='w-1/6 font-bold text-left text-red-500'>
              {totalPoint || 0}점
            </div>
          </div>
        </div>
        <AlertModal
          hideDurationInDays={1}
          isTriggered={showAlertModal}
          handleTrigger={setShowAlertModal}
          onConfirm={() => {
            history.push('/item-penalty')
          }}
          title='최근 3일 내 페널티가 부과되었습니다'
        >
          <div className='flex flex-col break-all whitespace-normal'>
            <div>
              {penaltyTypeCoverted.join(' / ')} 건에 대해 최근 3일 동안 페널티
              점수 {totalPoint}점이 부과되었습니다.
            </div>
            <div>확인 버튼을 누르면 페널티 내역 확인 페이지로 이동합니다.</div>
          </div>
        </AlertModal>
      </div>
    </>
  )
})

export default SellerPenaltyNotice

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'
import Button from '../atoms/button'

const ReviewReplyComponent = observer(
  ({ reviewInfo, replyInfo, setOpenModal }) => {
    const [text, setText] = useState('')
    if (!replyInfo?.reply || replyInfo?.deletedAt) {
      return (
        <>
          <td>
            <textarea
              style={{ width: '100%', height: 400 }}
              value={text}
              onChange={(e) => {
                setText(e.target.value)
              }}
              placeholder='답변 내용이 곧 판매자님의 얼굴이라고 생각하시고 신중하게 입력해주세요. 부적절한 답변으로 신고되는 경우 답변이 삭제될 수 있으며, 기능이 제한될 수 있습니다.'
            />
          </td>
          <td>
            <Button
              type='button'
              onClick={async () => {
                if (text) {
                  SellerStore.setIsLoading(true)
                  const result = await backendApis.addReviewReply(
                    reviewInfo?._id,
                    text,
                  )
                  SellerStore.setIsLoading(false)
                  if (result.status === 200) {
                    alert('답변이 완료되었습니다.')
                    setOpenModal(false)
                  }
                }
              }}
            >
              답변하기
            </Button>
          </td>
        </>
      )
    }
    return (
      <>
        <td>
          <div style={{ whiteSpace: 'pre-line' }}>
            {JSON.stringify(replyInfo.reply)}
          </div>
        </td>
        <td>
          <Button type='button' appearance='disabled' disabled>
            답변완료
          </Button>
          <Button
            type='button'
            appearance='red'
            onClick={async () => {
              SellerStore.setIsLoading(true)
              const result = await backendApis.deleteReviewReply(
                reviewInfo?._id,
              )
              SellerStore.setIsLoading(false)
              if (result.status === 200) {
                alert('답변이 삭제되었습니다.')
                setOpenModal(false)
              }
            }}
          >
            삭제하기
          </Button>
        </td>
      </>
    )
  },
)

export default ReviewReplyComponent

import React from 'react'

const RightArrowComponent = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      stroke='#aaa'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <polyline points='8 2 14 8 8 14' />
    </svg>
  )
}

export default RightArrowComponent

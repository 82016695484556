import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, Link, useLocation } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import AuthStore from '../stores/AuthStore'
import { FaBoxOpen, AiOutlineLogout, FaArrowRight } from 'react-icons/fa'
import OrderStore from '../stores/OrderStore'
import PreShippingPreExcelNavigator from './OrdersStatusComponents/PreShippingPreExcelNavigator'
import PreShippingPostExcelNavigator from './OrdersStatusComponents/PreShippingPostExcelNavigator'
import SendingNavigator from './OrdersStatusComponents/SendingNavigator'
import ShippingNavigator from './OrdersStatusComponents/ShippingNavigator'
import ArrivedNavigator from './OrdersStatusComponents/ArrivedNavigator'

const ShippingsNavigator = observer(({ route }) => {
  return (
    <div
      className='h-150 flex-1 bg-transparent flex flex-col items-start'
      // style={{
      //   height: 150,
      //   flex: 1,
      //   // width: '100%',
      //   backgroundColor: 'transparent',
      //   display: 'flex',
      //   // margin: 'auto',
      //   flexDirection: 'column',
      //   alignItems: 'flex-start',
      //   paddingBottom: 30,
      // }}
    >
      <div className='flex text-2xl font-bold mb-5'>
        배송처리를 진행해주세요
      </div>
      <div className=' flex flex-row w-full items-start'>
        <PreShippingPreExcelNavigator />
        <PreShippingPostExcelNavigator />
        <SendingNavigator />
        <ShippingNavigator />
        <ArrivedNavigator />
      </div>
    </div>
  )
})
export default ShippingsNavigator

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import ItemStore from '../stores/ItemStore'
import moment from 'moment'

const ApproveOrdersCancelDelays = observer(() => {
  const [isOnMouse, setIsOnMouse] = useState(false)
  return (
    <Link
      to='/item-questions'
      className='CancelingPreShippingOrders'
      style={{
        // width: '24%',
        height: 78,
        borderColor: '#adadad',
        border: 'solid',
        display: 'flex',
        borderWidth: 1,
        borderRadius: 5,
        marginRight: 11,
        paddingLeft: 10,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        color: 'inherit',
        textDecoration: 'inherit',
        backgroundColor: isOnMouse ? '#F5F5F5' : '#FFF',
      }}
      onMouseEnter={() => {
        setIsOnMouse(true)
      }}
      onMouseLeave={() => {
        setIsOnMouse(false)
      }}
    >
      <div
        style={{
          // flex: 1,
          alignItems: 'right',
          justifyContent: 'center',
          width: '84%',
          fontSize: 16,
          fontWeight: 'bold',
          paadingleft: 8,
        }}
      >
        고객 문의 응답 지연
      </div>
      <div
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          color: 'red',
          width: '16%',
        }}
      >
        <h2>
          {ItemStore.itemQuestions.filter(
            (question) =>
              !question.answerInfo &&
              // new Date(question?.questionInfo?.createdAt).getTime() +
              //   1000 * 60 * 60 * 24 -
              //   Date.now() <
              //   0,
              moment(question?.questionInfo?.createdAt).businessDiff(
                moment(Date.now()),
              ) > 1,
          ).length || 0}
        </h2>
      </div>
    </Link>
  )
})

export default ApproveOrdersCancelDelays

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import ItemStore from '../stores/ItemStore'
import backendApis from '../utils/backendApis'
import AuthStore from '../stores/AuthStore'
import { saveAs } from 'file-saver'
import readXlsxFile from 'read-excel-file'
import * as ExcelJS from 'exceljs'
import moment from 'moment'
import { set } from 'mobx'
import Log from '../utils/log'
import Button from '../components/atoms/button'

const headers = [{ header: '휴대폰번호', key: 'phoneNumber' }]

const CustomerMessageScreen = observer(() => {
  const history = useHistory()
  const [phoneNumbers, setPhoneNumbers] = useState('')
  const [totalItemArrray, setTotalItemArray] = useState([])
  const [message, setMessage] = useState('')
  const [selectedItemOption, setSelectedItemOption] = useState([])
  const [selectedLeadTimeOption, setSelectedLeadTimeOption] = useState([])
  const itemIds = SellerStore?.sellerInfo?.itemIds || []

  useEffect(() => {
    setMessage(
      `올웨이즈에서 주문하신 상품(${
        selectedItemOption?.length > 10
          ? `${selectedItemOption?.slice(0, 10)}...`
          : selectedItemOption
      })의 판매자 ${
        SellerStore?.sellerInfo?.sellerDisplayName
      }입니다\n배송지연으로 불편을 드려 죄송합니다. 고객님의 상품을 곧 택배사로 전달할 예정입니다.\n\n출고예정일: ${moment(
        new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * selectedLeadTimeOption,
        ),
      ).format(
        'YYYY-MM-DD',
      )}\n\n배송이 시작되면, 안내와 함께 운송장 번호를 메시지로 확인하실 수 있습니다.\n배송이 늦어져 취소를 원하시는 경우, 판매자 전화(${
        SellerStore?.sellerInfo?.contactNumber
      })로 연락해주시면 빠르게 처리 가능합니다.\n\n[올웨이즈] 바로가기: https://alwayzshop.ilevit.com/app`,
    )
  }, [selectedItemOption, selectedLeadTimeOption])

  useEffect(() => {
    // let subscription = () => {}
    // if (excelInput) {
    const excelInput = document.getElementById('excel-input-message-user')
    const parseAndUploadShippingNumber = async () => {
      SellerStore.setIsLoading(true)
      const rows = await readXlsxFile(excelInput.files[0], { sheet: 1 })

      const headerTemp = rows[0]
      const phoneNumberIndex = headerTemp.findIndex(
        (title) => title === '휴대폰번호' || title === '휴대폰 번호',
      )
      // const phoneNumberIndex = headerTemp.findIndex(
      //   (title) => title === '휴대폰번호',
      // )
      // const couponTitleIndex = headerTemp.findIndex(
      //   (title) => title === '쿠폰 제목',
      // )
      // const couponValueIndex = headerTemp.findIndex(
      //   (title) => title === '쿠폰 금액',
      // )
      // const expireDaysIndex = headerTemp.findIndex(
      //   (title) => title === '쿠폰 유효일',
      // )
      // console.log(
      //   `user id index: ${userIdIndex}, coupon title index: ${couponTitleIndex}, coupon value index: ${couponValueIndex}`,
      // )
      const failedRows = []
      const phoneNumbersTemp = rows
        .slice(1)
        .map((row) => row[phoneNumberIndex])
        .filter((userId) => userId)
        .join(',')
      setPhoneNumbers(phoneNumbersTemp)
      // console.log(`receiving user ids: ${JSON.stringify(receivingUserIdsTemp)}`)
      // for (let index = 1; index < rows.length; index += 1) {
      //   console.log(`coupon index: ${index}`)
      //   const row = rows[index]
      //   const userId = row[userIdIndex]
      //   // const phoneNumber = row[phoneNumberIndex]
      //   // const couponValue = row[couponValueIndex]
      //   // // console.log(`shipping company name: ${shippingCompanyName}`)
      //   // const couponTitle = row[couponTitleIndex]
      //   // const expireDays = row[expireDaysIndex]
      //   if (
      //     (userId || phoneNumber) &&
      //     couponValue &&
      //     couponTitle &&
      //     expireDays &&
      //     parseInt(couponValue, 10) > 0 &&
      //     parseInt(expireDays, 10) > 0
      //   ) {
      //     const couponInfo = {
      //       couponTitle,
      //       discountType: 'amount',
      //       value: parseInt(couponValue, 10),
      //       origin: 'special',
      //     }
      //     const expiresAt = new Date(
      //       new Date().getTime() + expireDays * 24 * 60 * 60 * 1000,
      //     )
      //     const result = await backendApis.giveUserCoupon(
      //       userId,
      //       couponInfo,
      //       expiresAt,
      //       phoneNumber,
      //       // message,
      //     )
      //     if (result?.status !== 200) {
      //       failedRows.push(row)
      //     }
      //   } else {
      //     failedRows.push(row)
      //   }
      // }
      console.log(`failed rows: ${JSON.stringify(failedRows)}`)
      if (failedRows.length > 0) {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('푸시 대상')
        worksheet.columns = headers
        worksheet.addRows(failedRows)
        // worksheet.getColumn(3).alignment = { wrapText: true }
        const headerRow = worksheet.getRow(1)
        headers.forEach((header, index) => {
          headerRow.getCell(index + 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFEA3F49' },
          }
        })
        worksheet.getRow(1).font = {
          color: { argb: 'FFFFFFFF' },
          bold: true,
        }
        const mimeType = {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], mimeType)
        const saveDate = new Date()
        const year = saveDate.getFullYear()
        const month = saveDate.getMonth() + 1
        const date = saveDate.getDate()
        saveAs(
          blob,
          `문자 대량 발송_${year}-${month < 10 ? `0${month}` : month}-${
            date < 10 ? `0${date}` : date
          }_오류 건.xlsx`,
        )
      }
      SellerStore.setIsLoading(false)
    }
    const fetchData = async () => {
      const projectCondition = {
        _id: 1,
        itemTitle: 1,
      }
      const result = await backendApis.loadItemSpecificInfo(
        itemIds,
        projectCondition,
      )
      if (result?.status === 200) {
        setTotalItemArray(result?.data)
      }
    }
    fetchData()
    console.log(`excel input detected!`)
    excelInput.addEventListener('change', parseAndUploadShippingNumber)
    return () => {
      // subscription()
      excelInput?.removeEventListener('change', parseAndUploadShippingNumber)
    }
    // }
  }, [])
  return (
    <>
      <div className='flex flex-col items-center justify-center flex-1 w-full p-10 mt-10'>
        <div className='flex flex-row items-center flex-1 mb-5 text-2xl font-bold select-none'>
          고객 관리 (문자) - 배송지연 안내 발송
        </div>
        <div className='max-w-screen-md my-6 rounded-md shadow'>
          <div className='p-6 bg-white rounded-md'>
            <div className='p-4 mt-4 mb-10 text-gray-700 bg-gray-100 rounded-lg'>
              * 배송지연 안내는 주문번호당 1회만 가능하며, 구매자와 수취인이
              다를 경우 구매자의 연락처로 문자가 안내됩니다.
              <br />
              상품의 배송 상태를 고려하여 적절한 템플릿을 선택해주세요. <br />
              배송지연 안내 외에 다른 목적으로 고객 정보를 사용할 경우
              '정보통신망 이용 촉진 및 정보보호'관련 법률에 따라 처벌될 수
              있습니다.
            </div>
            <div className='grid grid-cols-6 gap-12'>
              <div className='col-span-6'>
                <span className='block text-sm font-medium text-gray-700'>
                  고객 번호 엑셀 업로드
                </span>
                <input
                  className='block px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  type='file'
                  id='excel-input-message-user'
                  accept='.xlsx'
                />
              </div>
              <div className='col-span-3'>
                <span className='block text-sm font-medium text-gray-700'>
                  상품명
                </span>
                <select
                  id='shipping_company'
                  onChange={(e) => {
                    setSelectedItemOption(e.target.value)
                  }}
                  value={selectedItemOption || ''}
                  className='block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                >
                  <option value=''> - </option>
                  {totalItemArrray?.map((item) => (
                    <option key={item?._id} value={item?.itemTitle}>
                      {item?.itemTitle}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-span-3'>
                <span className='block text-sm font-medium text-gray-700'>
                  출고 시작까지 걸리는 시간
                </span>
                <div className='flex items-center justify-start'>
                  <select
                    id='shipping_company'
                    onChange={(e) => {
                      setSelectedLeadTimeOption(e.target.value)
                    }}
                    value={selectedLeadTimeOption || ''}
                    className='block w-32 px-3 py-2 mt-1 mr-2 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  >
                    <option value=''> - </option>
                    {[...Array(9).keys()].map((leadTime) => (
                      <option key={leadTime} value={leadTime}>
                        {leadTime}
                      </option>
                    ))}
                  </select>
                  일
                </div>
              </div>
              <div className='col-span-6'>
                <span className='block text-sm font-medium text-gray-700'>
                  안내 내용 미리보기
                </span>
                <textarea
                  value={message}
                  disabled
                  className='block w-full p-4 mt-1 mb-10 text-gray-700 bg-gray-100 border-gray-300 rounded-lg shadow-sm h-80 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className='px-4 py-3 text-right rounded-md bg-gray-50 sm:px-6'>
            <Button
              type='submit'
              size='lg'
              onClick={async () => {
                console.log(JSON.stringify(SellerStore?.sellerInfo))
                const phoneNumbersTemp = phoneNumbers
                  .split(',')
                  .map((phoneNumber) =>
                    phoneNumber.replace(/ /g, '').replace(/-/g, ''),
                  )
                  .filter((phoneNumber) => phoneNumber)
                if (!phoneNumbersTemp?.length > 0) {
                  alert(
                    '문자를 받으실 고객분들 휴대폰 번호를 엑셀로 업로드해주세요.',
                  )
                } else if (!selectedItemOption?.length > 0) {
                  alert('문자 내용에 들어갈 상품을 선택해주세요!')
                } else if (!selectedLeadTimeOption?.length > 0) {
                  alert('배송 시작까지 걸릴 기간을 입력해주세요!')
                } else {
                  await backendApis.sendBulkSms(
                    phoneNumbersTemp,
                    `${message}`,
                    '올웨이즈 배송지연안내',
                  )
                  setSelectedItemOption([])
                  setSelectedLeadTimeOption([])
                  setPhoneNumbers('')
                  alert('문자를 전송했습니다.')
                }
                if (SellerStore.loadSellerInfo())
                  Log.event(
                    'CustomerMessageSendButton',
                    'CustomerMessageSendButton',
                    {
                      click: 'CustomerMessageSendButton',
                    },
                  )
              }}
            >
              {phoneNumbers &&
              phoneNumbers.split(',').filter((phoneNumber) => phoneNumber)
                .length > 0
                ? `${
                    phoneNumbers.split(',').filter((phoneNumber) => phoneNumber)
                      .length
                  }명에게 `
                : ''}
              문자 발송
            </Button>
          </div>
        </div>
      </div>
    </>
  )
})

export default CustomerMessageScreen

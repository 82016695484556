import React from 'react'
import ButtonCard from '../components/atoms/buttonCard'

const ReviewEventExplainScreen = () => {
  return (
    <div className='page max-w-7xl'>
      <div className='pageTitle'>상품 후기 체험단 신청</div>
      <div className='flex flex-row'>
        {/* <ButtonCard
          href='/review-experience-v3'
          image='https://always-bucket-dev.s3.us-west-1.amazonaws.com/d5198d72-57a1-476d-82d4-315952c5bb57.png'
          text='올웨이즈 무료 체험단'
          subtext1='엄선한 체험단에게 상품 보내고,'
          subtext2='빠르게 양질의 후기 쌓기!'
          width={80}
          height={70}
        /> */}
        <ButtonCard
          href='/review-coupon'
          image='https://d22yqrpf3lokox.cloudfront.net/ec5a6ef1-520e-4e15-abd1-86f89362c96d.png'
          text='신상품 매대 체험단'
          subtext1='쿠폰 증정을 통해,'
          subtext2='매대 노출과 후기 쌓기를 동시에!'
          width={80}
          height={60}
        />
      </div>
      <img
        alt='intro'
        src='https://d22yqrpf3lokox.cloudfront.net/febb0298-ac90-43e9-8e01-17533fa4e250.png'
        className='mt-12 mb-24'
        style={{ width: '70vw' }}
      />
    </div>
  )
}

export default ReviewEventExplainScreen

import React from 'react'
import ItemTagButton from './ItemTagButton'

const TeamDealThresholdButton = ({
  information = false,
  teamDealThreshold = 2,
  small = false,
}) => {
  return (
    <ItemTagButton
      small={small}
      text={`${teamDealThreshold || 2}인 팀구매`}
      backgroundColor='#DD524C'
    />
  )
}

export default TeamDealThresholdButton

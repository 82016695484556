import React, { useEffect, useState } from 'react'
import Page from '../components/atoms/page'
import DealRankNotice from '../components/molecules/dealRankNotice'
import ItemComponent from '../components/ItemComponents/ItemComponent'
import Pagination from '../components/molecules/pagination'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import { FcAlarmClock as TitleIcon } from 'react-icons/fc'
import backendApis from '../utils/backendApis'
import ItemSearchResultDownloadModal from '../components/molecules/itemSearchResultDownloadModal'
import ExcelFileUploadModal from '../components/molecules/excelFileUploadModal'
import DealApplicationModal from '../components/molecules/dealApplicationModal'
import ItemSearchComponent from '../components/ItemComponents/ItemSearchComponent'

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const BannerComponent = () => {
  return (
    <div className='px-10 py-10 bg-white shadow-md rounded-xl'>
      <div className='flex flex-row justify-between'>
        <div className='mb-10 text-xl font-bold'>
          타임특가 판매 제안 및 등록관리
        </div>
      </div>
      <div style={{ height: 12 }} />
      <div style={{ backgroundColor: '#FFF5F5', padding: 30 }}>
        <div style={{ marginBottom: 10, fontWeight: 'bold', color: 'red' }}>
          타임특가란?
        </div>
        <div style={{ marginBottom: 10 }}>
          • 매일 아침 7시 부터 저녁 11시까지,{' '}
          <span style={{ fontWeight: 'bold' }}>
            1시간 단위로 상품이 변경되어 타임특가 매대에 노출되는 시간 한정 딜
          </span>
          입니다.
        </div>
        <div style={{ marginBottom: 10 }}>
          • 모든 옵션이 기존 올웨이즈{' '}
          <span style={{ fontWeight: 'bold' }}>
            팀구매가 대비 최소 10% 이상 저렴
          </span>
          해야 합니다.
        </div>
        <div style={{ marginBottom: 20 }}>
          • 기존 팀구매가가 함께 노출되므로,{' '}
          <span style={{ fontWeight: 'bold' }}>
            신청 이후 팀구매가를 인하하시는 경우에는 타임특가에 재신청해주셔야
            합니다.{' '}
          </span>
          기존 팀구매가보다 10% 이상 저렴한 가격이 아니라면{' '}
          <span style={{ fontWeight: 'bold' }}>
            타임특가 매대에서 비노출처리됩니다.
          </span>
        </div>

        <div style={{ marginBottom: 10, fontWeight: 'bold', color: 'red' }}>
          왜 참여해야하나요?
        </div>

        <div style={{ marginBottom: 10 }}>
          • 매일 1시간 동안만 노출되어 고객에게 인지되는{' '}
          <span style={{ fontWeight: 'bold' }}>
            가격선의 하락 우려 없이 일 판매량을 증진
          </span>
          시킬 수 있어요.
        </div>
        <div style={{ marginBottom: 10 }}>
          • 짧은 딜 진행 시간으로{' '}
          <span style={{ fontWeight: 'bold' }}>
            행사 물량 준비에 대한 부담 없이
          </span>
          , 일상적으로 참여 가능한 딜이예요.
        </div>
        <div style={{ marginBottom: 10 }}>
          • 낮은 할인율로도 타임특가 매대에 상품을 노출시킬 수 있어요.
        </div>
        <div style={{ marginBottom: 10 }}>
          • 상품의 매력도와 할인폭에 따라 노출도가 결정되므로{' '}
          <span style={{ fontWeight: 'bold' }}>
            직접 할인폭을 조절하여 노출도를 관리
          </span>
          할 수 있어요
        </div>
      </div>
    </div>
  )
}

const TimeDealApplicationScreen = () => {
  const [selectedItem, setSelectedItem] = useState()
  const dealType = 'timeDeal'
  const [isLoading, setIsLoading] = useState(false)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [applyStatusTab, setApplyStatusTab] = useState('candidate')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [openTimeDealApplicationModal, setOpenTimeDealApplicationModal] =
    useState(false)
  const [itemCondition, setItemCondition] = useState({})

  const handleSearch = (selectedSearchField, itemQuery) => {
    fetchSegmentedItemsInfo(true, selectedSearchField, itemQuery) // 검색 정보 넘겨주기
  }

  const fetchSegmentedItemsInfo = async (
    isNewCondition,
    selectedSearchField,
    itemQuery,
  ) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    const itemCondition = {}
    if (itemQuery) {
      if (selectedSearchField === 'itemTitle') {
        itemCondition.itemTitle = { $regex: escapeRegExp(itemQuery) }
      } else if (selectedSearchField === 'itemId') {
        itemCondition._id = {
          $in: [itemQuery],
        }
      }
    }
    if (applyStatusTab === 'candidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: {
                $and: [{ key: 'deal', value: dealType }],
              },
            },
          },
        },
      ]
    } else if (applyStatusTab === 'complete') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        {
          itemFilters: {
            $elemMatch: {
              $and: [{ key: 'deal', value: dealType }],
            },
          },
        },
      ]
    }
    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        return
      }
    }
    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
      setItemCondition(itemCondition)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
        setItemCondition(itemCondition)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [limit, applyStatusTab])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  useEffect(async () => {
    await backendApis.recordEventLog(
      'TimeDealApplicationScreen',
      'entered screen',
    )
  }, [])

  return (
    <div className='max-w-7xl'>
      <Page title='타임특가 신청' Icon={TitleIcon}>
        <DealRankNotice minRank='B' />
        <BannerComponent />
        <div>
          <div className='flex items-center justify-between flex-1 mt-10 mb-5'>
            <div className='mb-5 '>
              <button
                type='button'
                onClick={() => {
                  setApplyStatusTab('candidate')
                  setPage(1)
                }}
                className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
                  applyStatusTab === 'candidate'
                    ? 'border-b-4 border-positive-500 text-black'
                    : 'text-gray-400'
                }`}
              >
                신청 대상 상품
              </button>
              <button
                type='button'
                onClick={() => {
                  setApplyStatusTab('complete')
                  setPage(1)
                }}
                className={`select-none text-xl pb-1 font-bold duration-200 ease-in-out ${
                  applyStatusTab === 'complete'
                    ? 'border-b-4 border-positive-500 text-black'
                    : 'text-gray-400'
                } w-max`}
              >
                신청 완료 상품
              </button>
            </div>

            <div className='flex justify-between items-center'>
              <div className='mb-1 mr-3'>
                <ItemSearchResultDownloadModal
                  type={dealType}
                  dealItemsInfo={itemsInfo}
                  itemsCount={itemsCount}
                  itemCondition={itemCondition}
                  // isButton={1}
                  buttonText='신청 가능 상품 다운로드'
                >
                  상품 다운로드
                </ItemSearchResultDownloadModal>
              </div>
              <div className='mb-1 mr-3'>
                <ExcelFileUploadModal
                  modalTitle='타임특가 상품 정보 변경'
                  filename='타임특가 상품 정보 등록용'
                  infoType='timeDealItem'
                  applyStatus='timedeal-apply'
                >
                  상품 신청
                </ExcelFileUploadModal>
              </div>
              <div className='mb-1 mr-3'>
                <ExcelFileUploadModal
                  modalTitle='타임특가 상품 정보 변경'
                  filename='타임특가 상품 정보 수정용'
                  infoType='timeDealItem'
                  applyStatus='timedeal-modification'
                >
                  신청 완료 상품 수정
                </ExcelFileUploadModal>
              </div>
              <ItemSearchComponent onSearch={handleSearch} />

              {/* <div>
                <ListLimitSelector limit={limit} setLimit={setLimit} />
              </div> */}
            </div>
          </div>
          <div>
            {applyStatusTab === 'candidate'
              ? !isLoading && (
                  <>
                    {itemsInfo.map((itemInfo, idx) => (
                      <div key={`${itemInfo._id + idx.toString()}1`}>
                        <ItemComponent
                          itemInfo={itemInfo}
                          dealRequest={() => {
                            backendApis.recordEventLog(
                              'TimeDealApplicationScreen',
                              'entered application modal',
                            )
                            setSelectedItem(itemInfo)
                            setOpenTimeDealApplicationModal(true)
                          }}
                          dealType={dealType}
                          dealRankLimit='B'
                          isRankItem
                          dealApplyInfo={applyStatusTab === 'complete'}
                        />
                      </div>
                    ))}
                  </>
                )
              : !isLoading && (
                  <>
                    {itemsInfo.map((itemInfo, idx) => (
                      <div key={`${itemInfo._id + idx.toString()}2`}>
                        <ItemComponent
                          itemInfo={itemInfo}
                          dealRequest={() => {
                            backendApis.recordEventLog(
                              'TimeDealApplicationScreen',
                              'entered application modal',
                            )
                            setSelectedItem(itemInfo)
                            setOpenTimeDealApplicationModal(true)
                          }}
                          dealCancelRequest={async () => {
                            backendApis.recordEventLog(
                              'TimeDealApplicationScreen',
                              'canceled time deal',
                            )
                            const result =
                              await backendApis.deleteDealOptionsInfo(
                                itemInfo?._id,
                                dealType,
                              )
                            if (result?.status === 200) {
                              alert('타임특가 취소가 완료되었습니다')
                              fetchSegmentedItemsInfo(true)
                            }
                          }}
                          dealType={dealType}
                          dealRankLimit='B'
                          isRankItem
                          dealApplyInfo={applyStatusTab === 'complete'}
                        />
                      </div>
                    ))}
                  </>
                )}
            {itemsInfo?.length === 0 && (
              <div className='flex items-center justify-center flex-1 px-10 py-10 mb-5 bg-white shadow-lg rounded-xl'>
                조건에 맞는 상품이 없습니다.
              </div>
            )}
            <div>
              <Pagination
                total={itemsCount}
                limit={limit}
                page={page}
                setPage={setPage}
                size='lg'
              />
            </div>
          </div>
          {openTimeDealApplicationModal && (
            <DealApplicationModal
              showModal={openTimeDealApplicationModal}
              setShowModal={setOpenTimeDealApplicationModal}
              dealType={dealType}
              itemInfo={selectedItem}
              setItemInfo={setSelectedItem}
              minPriceRatio={0.9}
              applied={applyStatusTab === 'complete'}
            />
          )}
        </div>
      </Page>
    </div>
  )
}

export default TimeDealApplicationScreen

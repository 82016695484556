import backendApis from '../backendApis'
import BusinessInfoParser from './businessInfoParser'

const validateBusinessNumber = async () => {
  try {
    const loadSeller = await backendApis.loadSellerBusinessRegistrationInfo()
    const sellerInfos = {
      sellerId: loadSeller?.data?.sellerId,
      inputBusinessNumber: BusinessInfoParser.parseBusinessRegistrationNumber(
        loadSeller?.data?.businessRegistrationNumber,
      ),
      businessNumberFromImage:
        BusinessInfoParser.parseBusinessRegistrationNumber(
          loadSeller?.data?.businessRegisteredData?.registerNumber,
        ),
      openDate: BusinessInfoParser.parseBusinessRegistrationOpenDate(
        loadSeller?.data?.businessRegisteredData?.openDate,
      ),
      repName: BusinessInfoParser.parseBusinessRegistrationRepName(
        loadSeller?.data?.businessRegisteredData?.repName,
      ),
    }

    const checkResult = await backendApis.validateBusinessRegistrationNumber(
      sellerInfos?.sellerId,
      sellerInfos?.inputBusinessNumber,
      sellerInfos?.repName,
      sellerInfos?.openDate,
    )
    if (checkResult?.status === 200) {
      if (
        checkResult?.data?.status_code === 'OK' &&
        checkResult?.data?.data?.[0]?.valid === '01' &&
        checkResult?.data?.data?.[0]?.status?.b_stt === '계속사업자'
      ) {
        const updateResult =
          await backendApis.updateSellerBusinessNumberValidity(
            sellerInfos?.sellerId,
          )
        if (updateResult?.status === 200) {
          return {
            status: 200,
            message: '계속사업자로 조회되었습니다. 검증에 성공했습니다.',
          }
        }
        return {
          status: 400,
          message:
            '계속사업자 조회에 성공했으나 업데이트 과정에서 오류가 발생했습니다. 재시도 부탁드립니다.',
        }
      }
      return {
        status: 400,
        failFlag: true,
        message: '사업자번호 검증에 실패했습니다. 다시 시도해 주세요.',
      }
    }
    return {
      status: 400,
      message:
        '사업자번호 검증 시도 중 오류가 발생했습니다. 다시 시도해 주세요.',
    }
  } catch (error) {
    console.error('Business number validation error:', error)
    return {
      status: 400,
      message: error.message || '검증 시도 중 오류가 발생했습니다.',
      error,
    }
  }
}

export default validateBusinessNumber

import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import { FcSalesPerformance as TreasureIcon } from 'react-icons/fc'
import Button from '../atoms/button'
import OptionTable from './optionTable'
import { getDefaultBigDealPageInfo } from '../../utils/dealUtils'

const BigDealApplicationModal = ({
  showModal,
  setShowModal,
  dealType,
  itemInfo,
  minPriceRatio = 0.85,
  applied = false,
}) => {
  const nonEditableDealTypeList = ['newYear24DealFinal']
  const { displayDealType } = getDefaultBigDealPageInfo(dealType)
  const oldOptionsInfo = { ...itemInfo?.dealOptionsInfo } // dealOptionsInfo.optionsPrice에 oldTeamPurchasePrice 필드 있는 경우
  const [dealOptionsInfo, setDealOptionsInfo] = useState({
    ...itemInfo?.dealOptionsInfo,
  })

  const [dealItemInfo, setDealItemInfo] = useState()
  const [dealItemInfoSetFlag, setDealItemInfoSetFlag] = useState(false)

  const dealOptionsInfoChecker = (
    oldOptionsInfo,
    dealOptionsInfo,
    minPriceRatio,
  ) => {
    const oldOptionsPricesAll = [...oldOptionsInfo?.optionPrices?.flat()]
    const dealOptionsPricesAll = [...dealOptionsInfo?.optionPrices?.flat()]
    const checkedArray = oldOptionsPricesAll.map((oldInfo, index) => {
      const oldPrice = oldInfo?.teamPurchasePrice
      const dealPrice = dealOptionsPricesAll[index]?.teamPurchasePrice
      const validPrice = parseInt(oldPrice * minPriceRatio, 10)
      if (validPrice >= dealPrice) {
        return true
      }
      return false
    })
    return checkedArray.every((checked) => checked)
  }

  useEffect(() => {
    if (applied) {
      const dealOptionsInfoSetter = async () => {
        const appliedDealOptionsInfo = await backendApis.getDealOptionsInfo(
          itemInfo?.itemId || itemInfo?._id,
          dealType,
        )
        if (appliedDealOptionsInfo?.status === 200) {
          setDealOptionsInfo(appliedDealOptionsInfo?.data?.dealOptionsInfo)
          setDealItemInfo(appliedDealOptionsInfo?.data)
          setDealItemInfoSetFlag(true)
        }
      }
      dealOptionsInfoSetter(setDealOptionsInfo)
    }
  }, [applied])

  const itemTitle = itemInfo?.itemTitle || ''
  const optionHeader =
    oldOptionsInfo?.totalOptions?.length >= 2
      ? [
          { Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 },
          { Header: '옵션2 / 상세옵션', accessor: 'option2', index: 2 },
        ]
      : [{ Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 }]

  const columns = [
    ...optionHeader,
    {
      Header: '상시 팀구매가',
      accessor: 'wasTeamPurchasePrice',
      isEditableData: false,
      index: 3,
    },
    {
      Header: '기획전 수정가',
      accessor: 'teamPurchasePrice',
      isEditableData: !nonEditableDealTypeList.includes(dealType),
      index: 4,
    },
  ]

  const Header = ({ type }) => {
    const textType = `${displayDealType} 확인`

    return (
      <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200'>
        <div className='flex flex-row items-center flex-1'>
          <TreasureIcon className='w-8 h-8 mr-2' />
          <h3 className='text-xl font-semibold text-black'>{textType}</h3>
        </div>
        <button
          className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
          type='button'
          onClick={() => {
            setShowModal(false)
          }}
        >
          X
        </button>
      </div>
    )
  }
  const Body = ({ type }) => {
    return (
      <div className='relative flex flex-col items-center justify-center px-10 pt-10'>
        <div>상품명</div>
        <div>
          <span className='font-bold'>{itemTitle}</span>
        </div>
        <div className='mt-5'>
          해당 상품은 {displayDealType}에 다음 가격으로 신청되었습니다.
        </div>
        <div className='mt-5'>
          해당 상품의 최소 할인율은 {Math.round(minPriceRatio * 100)}% 입니다.
        </div>
      </div>
    )
  }

  const Footer = ({ type }) => {
    return (
      <div className='flex items-center justify-end p-8 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
        <Button
          size='xl'
          appearance={applied ? 'positiveSub' : 'positive'}
          onClick={async () => {
            if (!dealOptionsInfoChecker(oldOptionsInfo, dealOptionsInfo, 1)) {
              alert(
                `모든 옵션에 대해 수정가는 기존 기획전 신청가 (제안가)보다 낮아야 수정이 가능합니다.`,
              )
              return
            }

            if (applied) {
              const result = await backendApis.updateDealOptionsInfo(
                itemInfo?.itemId || itemInfo?._id,
                type,
                dealOptionsInfo,
              )
              if (result?.status === 200) {
                alert('정상적으로 수정되었습니다.')
                setShowModal(false)
              } else {
                alert(
                  '777 기획전 상품 등록에 실패했습니다. 문제가 반복될 경우 판매자 지원센터로 문의주세요.',
                )
              }
            }
          }}
        >
          {applied ? '수정하기' : '행사 신청하기'}
        </Button>
        <button
          className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
          type='button'
          onClick={() => {
            setShowModal(false)
          }}
        >
          닫기
        </button>
      </div>
    )
  }

  const shouldLoadComponent = applied ? dealItemInfoSetFlag : true

  return (
    <>
      {showModal && dealType && shouldLoadComponent && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 py-10 overflow-x-hidden overflow-y-scroll outline-none'>
            <div className='relative w-full max-w-4xl mx-auto my-auto shadow-lg'>
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg outline-none'>
                <Header type={dealType} />
                <Body type={dealType} />
                <div className='relative flex flex-col items-center justify-center max-h-full px-10 py-10 overflow-y-scroll'>
                  <OptionTable
                    optionsInfo={dealOptionsInfo}
                    setOptionsInfo={setDealOptionsInfo}
                    oldOptionsInfo={oldOptionsInfo}
                    columns={columns}
                    dealType={dealType}
                  />
                </div>
                <Footer type={dealType} />
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}

export default BigDealApplicationModal

/**
 * @param {string} str
 * @returns {number}
 * @description string을 변수로 넣어주면 trim하고 ,를 제거한 뒤 숫자로 변환하여 반환합니다.
 */
const convertStringToNumber = (str) => {
  if (typeof str === 'number') return str
  return parseInt(str.trim().replaceAll(',', ''), 10)
}

export default convertStringToNumber

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'

const SendingDelays = observer(() => {
  const [isOnMouse, setIsOnMouse] = useState(false)
  return (
    <button
      className='CancelingPreShippingOrders'
      style={{
        // width: '24%',
        height: 78,
        borderColor: 'black',
        border: 'solid',
        display: 'flex',
        borderWidth: 1,
        borderRadius: 5,
        marginRight: 11,
        paddingLeft: 10,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        color: 'inherit',
        textDecoration: 'inherit',
        backgroundColor: isOnMouse ? '#F5F5F5' : '#FFF',
      }}
      type='button'
      onClick={async () => {
        document.getElementById('sendingOrders').scrollIntoView()
      }}
      onMouseEnter={() => {
        setIsOnMouse(true)
      }}
      onMouseLeave={() => {
        setIsOnMouse(false)
      }}
    >
      <div
        style={{
          // flex: 1,
          alignItems: 'right',
          justifyContent: 'center',
          width: '84%',
          fontSize: 16,
          fontWeight: 'bold',
          paddingleft: 8,
          textAlign: 'left',
        }}
      >
        <div>발송중 지연</div>
        <div>(송장업로드 이후)</div>
      </div>
      <div
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          color: 'red',
          width: '16%',
          fontSize: 18,
          textAlign: 'left',
        }}
      >
        <h2>
          {OrderStore.ordersNumPerStatus['shipping-sending-delayed'] || 0}
        </h2>
      </div>
    </button>
  )
})

export default SendingDelays

import React, { useState, useEffect } from 'react'

const CountdownTimer = ({ endTime, maxUnit = 'hour' }) => {
  // maxUnit 으로 남은 시간을 표시할 단위를 설정하도록!
  // day : 일, 시간, 분, 초 반환, hour : 시간, 분, 초 반환, minute : 분, 초 반환

  // 남은 시간 상태
  const [timeLeft, setTimeLeft] = useState(endTime - new Date())

  useEffect(() => {
    // 타이머 업데이트 함수
    const timer = setInterval(() => {
      const newTimeLeft = endTime - new Date()
      if (newTimeLeft <= 0) {
        clearInterval(timer)
        setTimeLeft(0)
      } else {
        setTimeLeft(newTimeLeft)
      }
    }, 1000)

    // 컴포넌트 언마운트 시 인터벌 정리
    return () => clearInterval(timer)
  }, [endTime])

  // 남은 시간을 시:분:초 형식으로 변환
  const formatTimeLeft = (time) => {
    if (time <= 0) {
      switch (maxUnit) {
        case 'day':
          return '0일 0시간 0분 0초'
        case 'hour':
          return '0시간 0분 0초'
        case 'minute':
          return '0분 0초'
        default:
          return '시간 계산 오류'
      }
    }

    let seconds = Math.floor(time / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    seconds %= 60
    minutes %= 60
    hours %= 24
    if (maxUnit === 'day') {
      return `${days}일 ${hours}시간 ${minutes}분 ${seconds}초`
    }
    if (maxUnit === 'minute') {
      minutes += hours * 60 + days * 1440
      return `${minutes}분 ${seconds}초`
    }
    // 기본값은 시간, 분, 초 반환
    hours += days * 24
    return `${hours}시간 ${minutes}분 ${seconds}초`
  }

  return (
    <div>
      <h1>남은 시간: {formatTimeLeft(timeLeft)}</h1>
    </div>
  )
}

export default CountdownTimer

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import Button from '../components/atoms/button'

const ResetPasswordScreen = observer(() => {
  const history = useHistory()
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')

  return (
    <div className='flex flex-col items-center justify-center flex-1 w-full px-10 py-10 mt-10 w-min-7xl'>
      <div className='flex flex-row items-center flex-1 mb-5'>
        <div className='text-2xl font-bold select-none'>비밀번호 변경</div>
      </div>
      <div className='overflow-hidden shadow sm:rounded-md my-6 w-96'>
        <div className='bg-white px-4 py-5 sm:p-6'>
          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-6'>
              <span className='block text-sm font-medium text-gray-700'>
                새 비밀번호
              </span>
              <input
                type='password'
                value={newPassword}
                id='newPassword'
                placeholder='새 비밀번호'
                onChange={(e) => {
                  setNewPassword(e.target.value)
                }}
                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>

            <div className='col-span-6'>
              <span className='block text-sm font-medium text-gray-700'>
                새 비밀번호 확인
              </span>
              <input
                type='password'
                value={newPassword2}
                id='newPassword2'
                placeholder='새 비밀번호 확인'
                onChange={(e) => {
                  setNewPassword2(e.target.value)
                }}
                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              />
            </div>
          </div>
        </div>
        <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
          <Button
            type='submit'
            size='lg'
            onClick={async () => {
              if (newPassword !== newPassword2) {
                alert('입력하신 비밀번호가 정확한지 확인해주세요')
                return
              }
              if (!newPassword || newPassword.length < 5) {
                alert('5자 이상의 영문, 숫자 조합의 비밀번호를 설정해주세요.')
                return
              }
              const result = await backendApis.changePassword(newPassword)
              if (result?.status === 200) {
                history.push('/')
              } else {
                alert(
                  '비밀번호 변경에 실패했습니다. 잠시 후 다시 시도해주세요.',
                )
                history.push('/')
              }
            }}
          >
            비밀번호 변경
          </Button>
        </div>
      </div>
    </div>
  )
})

export default ResetPasswordScreen

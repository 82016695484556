import { useState } from 'react'
import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'

const useBigDealApplicationScreen = ({
  dealType,
  acceptedStatusTab,
  fixedMinimumDiscountRate,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [itemsCount, setItemsCount] = useState(0)
  const [page, setPage] = useState(1)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemCondition, setItemCondition] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const [applyTotalLength, setApplyTotalLength] = useState(0)
  const LIMIT = 50

  const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  const fetchSegmentedItemsInfo = async (
    isNewCondition,
    selectedSearchField,
    itemQuery,
  ) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    const itemCondition = {}
    if (itemQuery) {
      if (selectedSearchField === 'itemTitle') {
        itemCondition.itemTitle = { $regex: escapeRegExp(itemQuery) }
      } else if (selectedSearchField === 'itemId') {
        itemCondition._id = {
          $in: [itemQuery],
        }
      }
    }
    if (acceptedStatusTab === 'candidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: {
                $and: [{ key: 'deal', value: dealType }],
              },
            },
          },
        },
      ]
    } else if (acceptedStatusTab === 'complete') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        {
          itemFilters: {
            $elemMatch: {
              $and: [{ key: 'deal', value: dealType }],
            },
          },
        },
      ]
    }
    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        return
      }
    }
    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      LIMIT,
    )
    if (result?.status === 2000) {
      // wasPrice와 과거 딜 진행 여부 가져오는 로직.
      const appendedResult =
        await backendApis.appendMinimumDiscountRateAndWasPrice(
          result?.data?.itemsInfo?.map((item) => item._id),
          dealType,
        )

      const itemInfo = result?.data?.itemsInfo?.map((item) => {
        const appendedItem = appendedResult?.data?.find(
          (appendedItem) =>
            appendedItem?.itemId?.toString() === item?._id?.toString(),
        )

        return {
          ...item,
          wasTeamPurchasePrice:
            appendedItem?.teamPurchasePrice || item?.teamPurchasePrice,
          wasOptionsInfo: appendedItem?.optionsInfo || item?.optionsInfo,
          dealHistory: appendedItem?.dealHistory || false,
        }
      })
      // 최소 할인율 게산하는 로직.
      // 기존 딜 신청 대상: 최소 할인율 10%
      // 기존 딜 우수 상품: 최소 할인율 5%
      // 최최우수 상품: 최소 할인율 0-5%. 정책 필요
      // 판매기록 있는 상품: 최소 할인율 15%
      // 판매기록 없는 상품: 최소 할인율 20%

      const itemInfoWithMinimumDiscountRate = itemInfo?.map((item) => {
        if (item?.isTop) {
          return {
            ...item,
            minimumDiscountRate: 0.05,
          }
        }
        if (item?.rankInfo?.itemRank === 'S') {
          return {
            ...item,
            minimumDiscountRate: 0.09,
          }
        }
        if (item?.totalOrderQuantity > 1000) {
          return {
            ...item,
            minimumDiscountRate: 0.1,
          }
        }
        if (item?.dealHistory) {
          return {
            ...item,
            minimumDiscountRate: 0.14,
          }
        }
        if (item?.totalOrderQuantity > 10) {
          return {
            ...item,
            minimumDiscountRate: 0.2,
          }
        }
        return {
          ...item,
          minimumDiscountRate: 0.25,
        }
      })

      setItemsInfo(
        itemInfoWithMinimumDiscountRate.sort(
          (a, b) => a?.minimumDiscountRate - b?.minimumDiscountRate,
        ),
      )
      setItemCondition(itemCondition)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / LIMIT) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          LIMIT,
        )

      const appendedResult =
        await backendApis.appendMinimumDiscountRateAndWasPrice(
          retryResult?.data?.itemsInfo?.map((item) => item._id),
          dealType,
        )

      const itemInfo = retryResult?.data?.itemsInfo?.map((item) => {
        const appendedItem = appendedResult?.data?.find(
          (appendedItem) =>
            appendedItem?.itemId?.toString() === item?._id?.toString(),
        )
        return {
          ...item,
          wasTeamPurchasePrice:
            appendedItem?.teamPurchasePrice || item?.teamPurchasePrice,
          wasOptionsInfo: appendedItem?.optionsInfo || item?.optionsInfo,
          dealHistory: appendedItem?.dealHistory || false,
        }
      })
      // 최소 할인율 게산하는 로직.
      // 기존 딜 신청 대상: 최소 할인율 10%
      // 기존 딜 우수 상품: 최소 할인율 5%
      // 최최우수 상품: 최소 할인율 0-5%. 정책 필요
      // 판매기록 있는 상품: 최소 할인율 15%
      // 판매기록 없는 상품: 최소 할인율 20%

      const itemInfoWithMinimumDiscountRate = itemInfo?.map((item) => {
        if (item?.isTop) {
          return {
            ...item,
            minimumDiscountRate: 0.05,
          }
        }
        if (item?.rankInfo?.itemRank === 'S') {
          return {
            ...item,
            minimumDiscountRate: 0.09,
          }
        }
        if (item?.totalOrderQuantity > 1000) {
          return {
            ...item,
            minimumDiscountRate: 0.1,
          }
        }
        if (item?.dealHistory) {
          return {
            ...item,
            minimumDiscountRate: 0.14,
          }
        }
        if (item?.totalOrderQuantity > 10) {
          return {
            ...item,
            minimumDiscountRate: 0.2,
          }
        }
        return {
          ...item,
          minimumDiscountRate: 0.25,
        }
      })
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(
          itemInfoWithMinimumDiscountRate.sort(
            (a, b) => a?.minimumDiscountRate - b?.minimumDiscountRate,
          ),
        )
        setItemCondition(itemCondition)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    setIsLoading(false)
  }

  const handleSearch = (selectedSearchField, itemQuery) => {
    fetchSegmentedItemsInfo(true, selectedSearchField, itemQuery) // 검색 정보 넘겨주기
  }

  const fetchData = async (isNewCondition, selectedSearchField, itemQuery) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)

    const itemCondition = {}
    if (itemQuery) {
      if (selectedSearchField === 'itemTitle') {
        itemCondition.itemTitle = { $regex: escapeRegExp(itemQuery) }
      } else if (selectedSearchField === 'itemId') {
        itemCondition._id = {
          $in: [itemQuery],
        }
      }
    }

    if (acceptedStatusTab === 'candidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: {
                $and: [{ key: 'deal', value: dealType }],
              },
            },
          },
        },
      ]

      if (isNewCondition) {
        const itemsCountResult =
          await backendApis.getSellerItemsCountByCondition({
            type: 'item',
            itemCondition,
          })
        if (itemsCountResult?.status === 200) {
          setItemsCount(itemsCountResult?.data)
        } else {
          setItemsCount(0)
          setPage(1)
          setItemsInfo([])
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
          setIsLoading(false)
          return
        }
      }

      const result = await backendApis.getSellerSegementedItemsInfoByCondition(
        {
          type: 'item',
          itemCondition,
        },
        page,
        LIMIT,
      )

      if (result?.status === 2000) {
        // wasPrice와 과거 딜 진행 여부 가져오는 로직.
        const appendedResult =
          await backendApis.appendMinimumDiscountRateAndWasPrice(
            result?.data?.itemsInfo?.map((item) => item._id),
            dealType,
          )

        const itemInfo = result?.data?.itemsInfo?.map((item) => {
          const appendedItem = appendedResult?.data?.find(
            (appendedItem) =>
              appendedItem?.itemId?.toString() === item?._id?.toString(),
          )
          return {
            ...item,
            wasTeamPurchasePrice:
              appendedItem?.teamPurchasePrice || item?.teamPurchasePrice,
            wasOptionsInfo: appendedItem?.optionsInfo || item?.optionsInfo,
            dealHistory: appendedItem?.dealHistory || false,
          }
        })
        // 최소 할인율 게산하는 로직.
        // 기존 딜 신청 대상: 최소 할인율 10%
        // 기존 딜 우수 상품: 최소 할인율 5%
        // 최최우수 상품: 최소 할인율 0-5%. 정책 필요
        // 판매기록 있는 상품: 최소 할인율 15%
        // 판매기록 없는 상품: 최소 할인율 20%

        const itemInfoWithMinimumDiscountRate = itemInfo?.map((item) => {
          if (item?.isTop) {
            return {
              ...item,
              minimumDiscountRate: 0.05,
            }
          }
          if (item?.rankInfo?.itemRank === 'S') {
            return {
              ...item,
              minimumDiscountRate: 0.09,
            }
          }
          if (item?.totalOrderQuantity > 1000) {
            return {
              ...item,
              minimumDiscountRate: 0.1,
            }
          }
          if (item?.dealHistory) {
            return {
              ...item,
              minimumDiscountRate: 0.14,
            }
          }

          if (item?.totalOrderQuantity > 10) {
            return {
              ...item,
              minimumDiscountRate: 0.2,
            }
          }
          return {
            ...item,
            minimumDiscountRate: 0.25,
          }
        })

        setItemsInfo(
          itemInfoWithMinimumDiscountRate.sort(
            (a, b) => a?.minimumDiscountRate - b?.minimumDiscountRate,
          ),
        )
        setItemCondition(itemCondition)
      } else if (result?.status === 2001) {
        const maxPage = Math.ceil(result.data?.itemsCount / LIMIT) || 1
        const retryResult =
          await backendApis.getSellerSegementedItemsInfoByCondition(
            {
              type: 'item',
              itemCondition,
            },
            maxPage,
            LIMIT,
          )

        const appendedResult =
          await backendApis.appendMinimumDiscountRateAndWasPrice(
            retryResult?.data?.itemsInfo?.map((item) => item._id),
            dealType,
          )

        const itemInfo = retryResult?.data?.itemsInfo?.map((item) => {
          const appendedItem = appendedResult?.data?.find(
            (appendedItem) =>
              appendedItem?.itemId?.toString() === item?._id?.toString(),
          )
          return {
            ...item,
            wasTeamPurchasePrice:
              appendedItem?.teamPurchasePrice || item?.teamPurchasePrice,
            wasOptionsInfo: appendedItem?.optionsInfo || item?.optionsInfo,
            dealHistory: appendedItem?.dealHistory || false,
          }
        })
        // 최소 할인율 게산하는 로직.
        // 기존 딜 신청 대상: 최소 할인율 10%
        // 기존 딜 우수 상품: 최소 할인율 5%
        // 최최우수 상품: 최소 할인율 0-5%. 정책 필요
        // 판매기록 있는 상품: 최소 할인율 15%
        // 판매기록 없는 상품: 최소 할인율 20%

        const itemInfoWithMinimumDiscountRate = itemInfo?.map((item) => {
          if (item?.isTop) {
            return {
              ...item,
              minimumDiscountRate: 0.05,
            }
          }
          if (item?.rankInfo?.itemRank === 'S') {
            return {
              ...item,
              minimumDiscountRate: 0.09,
            }
          }
          if (item?.totalOrderQuantity > 1000) {
            return {
              ...item,
              minimumDiscountRate: 0.1,
            }
          }
          if (item?.dealHistory) {
            return {
              ...item,
              minimumDiscountRate: 0.14,
            }
          }
          if (item?.totalOrderQuantity > 10) {
            return {
              ...item,
              minimumDiscountRate: 0.2,
            }
          }
          return {
            ...item,
            minimumDiscountRate: 0.25,
          }
        })
        if (retryResult?.status === 2000) {
          setPage(maxPage)
          setItemsInfo(
            itemInfoWithMinimumDiscountRate.sort(
              (a, b) => a?.minimumDiscountRate - b?.minimumDiscountRate,
            ),
          )
          setItemCondition(itemCondition)
        } else {
          setPage(1)
          setItemsInfo([])
          if (retryResult?.status !== 2001) {
            window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
          }
        }
      }

      SellerStore.setIsLoading(false)
    }
  }

  const chunkSize = 50
  const loadNum = (page - 1) * chunkSize

  const acceptedData = async () => {
    if (acceptedStatusTab !== 'complete') {
      return
    }
    SellerStore.setIsLoading(true)
    const result = await backendApis.getDealOptionsInfoStatus(
      dealType,
      'accepted',
      loadNum,
      chunkSize,
    )
    if (result?.status === 200) {
      setFilteredData(result?.data)
      SellerStore.setIsLoading(false)
    }
  }

  // 총 신청 상품 개수 불러오는 함수
  const getAcceptedTotalLength = async () => {
    const result = await backendApis.getAcceptedTotalLengthByDealType(
      dealType,
      'accepted',
    )
    if (result?.status === 200) setApplyTotalLength(result?.data)
  }

  const getMinimumDiscountRate = (item) => {
    const minimumDiscountRate =
      fixedMinimumDiscountRate ||
      item?.minimumDiscountRate ||
      item?.dealDiscountRate ||
      0.15

    return minimumDiscountRate
  }

  return {
    handleSearch,
    fetchData,
    acceptedData,
    getAcceptedTotalLength,
    getMinimumDiscountRate,
    isLoading,
    page,
    setPage,
    itemsCount,
    setItemsCount,
    itemsInfo,
    filteredData,
    applyTotalLength,
  }
}

export default useBigDealApplicationScreen

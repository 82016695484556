const getTotalRefundPrice = (order) => {
  // MEMO(민국): 배송중인 상품 배송중지 요청의 경우, 아직 refundInfo가 없기 때문에 cancelInfo를 사용한다.
  const isCancelingShipping = order.status === 'canceling-shipping'

  const isReturnFree = order?.itemInfo?.shippingInfo?.isReturnFree
  const paidCancelResonsAtCancelingShipping = [
    '단순 변심',
    '다른 상품 재주문 예정',
  ]
  const freeReturnReasonsAtRefunding = ['상품 문제', '배송 문제']

  const cancelReason = order?.cancelInfo?.cancelReason
  const refundReason = order?.refundInfo?.refundReason

  const isFreeRefundReason = isCancelingShipping
    ? !paidCancelResonsAtCancelingShipping.includes(cancelReason)
    : freeReturnReasonsAtRefunding.includes(refundReason)

  const isRefundFree = isReturnFree || isFreeRefundReason

  const totalPrice = order?.totalPrice
  const returnFee = order?.itemInfo?.shippingInfo?.returnFee

  const totalRefundPrice = isRefundFree ? totalPrice : totalPrice - returnFee

  return totalRefundPrice
}

export default getTotalRefundPrice

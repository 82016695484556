import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import OrderStore from '../stores/OrderStore'
import moment from 'moment'
import SellerStore from '../stores/SellerStore'

const CustomerServiceExplanationModal = observer(({ setOpenModal }) => {
  //   const history = useHistory()
  //   const [pageIndex, setPageIndex] = useState(0)

  return (
    <div
      style={{
        height: 100,
        marginTop: 20,
        marginLeft: 40,
        marginRight: 40,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <h3>
          <strong>• 고객 만족도 점수란 무엇인가요?</strong>
        </h3>
        고객 만족과 직결되는 판매관리 서비스 품질을 점수화한 것으로, 1) 배송 2)
        취소/환불 3) CS 세 가지 항목에 대해 점수를 산출하고 있습니다. 최근 1~2주
        간의 실적을 토대로 매일 업데이트되며, 산출된 점수는 올웨이즈 내 상품
        노출 알고리즘에 반영하고 있습니다.
        <br />
        <br />
        <h3>
          <strong>• 고객 만족도 등급이란 무엇인가요?</strong>
        </h3>
        보다 직관적인 판매관리 품질 현황 파악을 위해, 고객 만족도를 바탕으로
        [우수], [부족], [주의] 세 등급으로 구분하고 있습니다.
        <br />
        상위권 올웨이즈 판매자 분들은 [우수] 등급을 유지하고 있으며, [우수] 등급
        내에서도 점수가 높을수록 상품 노출에 유리합니다.
        <br />
        [부족] 또는 [주의] 단계인 경우 해당 항목에 대해 각별한 관리가 필요하며,
        유의미한 수준의 고객 불만이 발생할 수 있습니다. <br />
        항목별로 집계된 데이터가 적어 등급을 구분하기 어려운 경우 [집계중]으로
        표기되며, 기본 점수가 부여됩니다.
        <br />
        <br />
        <h3>
          <strong>• 고객 만족도를 높이려면 어떻게 해야 하나요?</strong>
        </h3>
        항목별로 기재된 설명을 참고 부탁 드리며, 지속적으로 현황을 모니터링하고
        서비스를 개선하는 작업이 필요합니다.
        <br />
        점수 및 등급 알고리즘은 지속적으로 업데이트 및 최적화 작업 진행
        예정입니다.
      </div>
      <div style={{ height: 20, backgroundColor: 'red' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          type='button'
          onClick={() => {
            setOpenModal(false)
          }}
          style={{
            width: 50,
          }}
        >
          <strong>닫기</strong>
        </button>
      </div>
    </div>
  )
})

export default CustomerServiceExplanationModal

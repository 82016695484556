import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import OrderStore from '../stores/OrderStore'
import moment from 'moment'
import Button from '../components/atoms/button'

const CancelDetailScreen = observer(() => {
  const history = useHistory()
  const location = useLocation()
  const orderStatus = location.state?.orderStatus
  const orderId = location.state?.orderId
  const [correspondingOrder, setCorrespondingOrder] = useState({})
  const [optionsText, setOptionsText] = useState([])
  const [parsedText, setParsedText] = useState('배송중지')

  useEffect(() => {
    if (
      orderStatus === 'canceling-shipping' ||
      orderStatus === 'canceling-pre-shipping'
    ) {
      const correspondingOrderTemp = OrderStore.displayedOrders.find(
        (orderTemp) => orderTemp._id === orderId,
      )
      if (correspondingOrderTemp) {
        const optionsTextTemp =
          correspondingOrderTemp.itemInfo.optionsInfo.optionNames.map(
            (value, optionIndex) => {
              const optionDetail =
                correspondingOrderTemp.itemInfo.optionsInfo.totalOptions[
                  optionIndex
                ][correspondingOrderTemp.selectedOption[optionIndex]].name
              return `${optionIndex}. ${value}: ${optionDetail}`
            },
          )
        setOptionsText(optionsTextTemp)
        setCorrespondingOrder(correspondingOrderTemp)
      }
    } else if (orderStatus === 'refunding') {
      const correspondingOrderTemp = OrderStore.displayedOrders.find(
        (orderTemp) => orderTemp._id === orderId,
      )
      if (correspondingOrderTemp) {
        const optionsTextTemp =
          correspondingOrderTemp.itemInfo.optionsInfo.optionNames.map(
            (value, optionIndex) => {
              const optionDetail =
                correspondingOrderTemp.itemInfo.optionsInfo.totalOptions[
                  optionIndex
                ][correspondingOrderTemp.selectedOption[optionIndex]].name
              return `${optionIndex}. ${value}: ${optionDetail}`
            },
          )
        setParsedText('환불')
        setOptionsText(optionsTextTemp)
        setCorrespondingOrder(correspondingOrderTemp)
      }
    }
  }, [])

  return (
    <>
      <Button
        className='my-6'
        size='lg'
        appearance='neutralSub'
        type='button'
        onClick={() => history.goBack()}
      >
        ← 뒤로가기
      </Button>
      <div className='overflow-hidden bg-white shadow sm:rounded-lg w-[960px]'>
        <div className='px-4 py-5 sm:px-6'>
          <h2 className='text-lg font-medium leading-6 text-gray-900'>
            {parsedText} 요청 상세내역
          </h2>
        </div>
        <div className='border-t border-gray-200'>
          <dl>
            <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                {parsedText} 요청 시점
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                {moment(
                  correspondingOrder.refundInfo?.refundRequestedAt ||
                    correspondingOrder.cancelInfo?.cancelRequestedAt,
                ).format('YYYY-MM-DD HH:mm')}
              </dd>
            </div>
            <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>옵션</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                {optionsText.map((option) => (
                  <div className='inline-block flex-row pr-20' key={option}>
                    {option}
                  </div>
                ))}
              </dd>
            </div>
            <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                {parsedText} 사유 구분
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                {correspondingOrder.refundInfo?.refundReason ||
                  correspondingOrder.cancelInfo?.cancelReason}
              </dd>
            </div>
            <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                {parsedText} 사유 상세 내용
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                {correspondingOrder.refundInfo?.refundDetailedReason ||
                  correspondingOrder.cancelInfo?.cancelDetailedReason}
              </dd>
            </div>
            <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                원하는 해결 방법
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                {correspondingOrder.refundInfo?.refundSolution ||
                  correspondingOrder.cancelInfo?.refundSolution}
              </dd>
            </div>
            <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>회수 방법</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                {correspondingOrder.refundInfo?.refundMethod ||
                  correspondingOrder.cancelInfo?.refundMethod}
                {correspondingOrder.refundInfo?.refundImageUris && (
                  <div
                    style={{
                      width: 'auto',
                      overflowX: 'scroll',
                      overflowY: 'hidden',
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingLeft: 16,
                    }}
                  >
                    {correspondingOrder.refundInfo?.refundImageUris?.map(
                      (imageUri, index) => (
                        <img
                          key={imageUri + index.toString()}
                          alt={imageUri}
                          src={imageUri}
                          style={{
                            height: 196,
                            width: 196,
                            paddingRight: 16,
                            objectFit: 'contain',
                          }}
                        />
                      ),
                    )}
                  </div>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  )
})

export default CancelDetailScreen

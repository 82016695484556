import React, { useEffect, useState } from 'react'

const EditableCell = ({
  value,
  row,
  column,
  onChange = () => {}, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  //   const [value, setValue] = useState(initialValue)

  // If the initialValue is changed external, sync it up with our state
  //   useEffect(() => {
  //     setValue(initialValue)
  //   }, [initialValue])

  return (
    <input
      value={value}
      onChange={onChange}
      className='px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
    />
  )
}

export default EditableCell

import React from 'react'
import { useTable, useRowState } from 'react-table'
import SubTitle from '../atoms/subTitle'

const ItemTable = ({
  title,
  columns,
  data,
  rowSelect = true,
  onRowClick = () => {},
  selectedRowItemId = null,
}) => {
  if (typeof data !== 'object') return null
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn: {
          width: 'auto',
        },
      },
      useRowState,
    )

  return (
    <>
      {title && (
        <div className='flex flex-row justify-between w-full mb-1 sm:mb-0'>
          <SubTitle text={title} />
        </div>
      )}
      <div className='w-full'>
        <div className='inline-block w-full overflow-x-auto rounded-lg shadow'>
          <table
            {...getTableProps()}
            className='w-full leading-normal border-none'
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      scope='col'
                      className='px-5 py-3 font-bold text-left uppercase border-b border-gray-200 bg-gray-50 text-nutral-500'
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row) => {
                  prepareRow(row)
                  const onClick = () => onRowClick(row.original)
                  const rowClassName =
                    selectedRowItemId?.toString() ===
                    row?.original?._id?.toString()
                      ? 'bg-blue-100'
                      : 'hover:bg-slate-200 bg-white cursor-pointer'
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={onClick}
                      className={rowSelect ? rowClassName : ''}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              className: cell.column.collapse ? 'collapse' : '',
                            })}
                            className='p-5 text-sm text-left text-gray-900 border-b border-gray-200'
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td
                    colSpan={columns.length}
                    className='p-10 text-lg text-gray-400 bg-white border-gray-200'
                  >
                    데이터 없음
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default ItemTable

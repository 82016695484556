import React from 'react'
import alertIcon from './tickAndCrossIcon/alertIcon.png'
import crossIcon from './tickAndCrossIcon/crossIcon.png'
import questionMarkIcon from './tickAndCrossIcon/questionMarkIcon.png'
import tickIcon from './tickAndCrossIcon/tickIcon.png'
import StarFull from './StarFull.png'
import StarHalf from './StarHalf.png'
import StarNone from './StarNone.png'
import SellerRecommendation from './SellerRecommendation.png'
import qualityNotice from './qualityNotice.png'
import SKURegister from './SKURegister.png'
import CheckSmall from './CheckSmall.png'
import NewYear24DealTopBanner from './newYear24DealBannerImg.png'

const ImageManager = ({ source, size = 24, style }) => {
  let IMAGE_URI
  if (source === 'alertIcon') {
    return <img src={alertIcon} alt='' width={size} height={size} />
  }
  if (source === 'crossIcon') {
    return <img src={crossIcon} alt='' width={size} height={size} />
  }
  if (source === 'questionMarkIcon') {
    return <img src={questionMarkIcon} alt='' width={size} height={size} />
  }
  if (source === 'tickIcon') {
    return <img src={tickIcon} alt='' width={size} height={size} />
  }
  if (source === 'StarFull') {
    return <img src={StarFull} alt='' width={size} height={size} />
  }
  if (source === 'StarHalf') {
    return <img src={StarHalf} alt='' width={size} height={size} />
  }
  if (source === 'StarNone') {
    return <img src={StarNone} alt='' width={size} height={size} />
  }
  if (source === 'SellerRecommendation') {
    return <img src={SellerRecommendation} alt='' width={size} height={size} />
  }
  if (source === 'qualityNotice') {
    return <img src={qualityNotice} alt='' width={size} height={size} />
  }
  if (source === 'SKURegister') {
    return <img src={SKURegister} alt='' width={size} height={size} />
  }
  if (source === 'CheckSmall') {
    return <img src={CheckSmall} alt='' width={size} height={size} />
  }
  if (source === 'NewYear24DealTopBanner') {
    return <img src={NewYear24DealTopBanner} alt='' style={style} />
  }
  if (!IMAGE_URI) {
    IMAGE_URI = source
  }
  return <div />
}

export default ImageManager

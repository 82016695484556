import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import PreShippingOrders from '../components/PreShippingOrders'
import ShippingOrders from '../components/ShippingOrders'
import SendingOrders from '../components/SendingOrders'
import ArrivedOrders from '../components/ArrivedOrders'
import ShippingsNavigator from '../components/ShippingsNavigator'
import CancelNavigator from '../components/CancelNavigator'
import OrderStore from '../stores/OrderStore'
import PreShippingOrdersAfterExcel from '../components/PreShippingOrdersAfterExcel'
import ImageManager from '../images/ImageManager'
import Modal from 'react-modal'
import CustomerServiceExplanationModal from './CustomerServiceScoreExplanationModal'
import Button from '../components/atoms/button'

const SHIPPING_SCORE_BASE_LINE = 68
const SHIPPING_SCORE_LOWER_LINE = 81
const SHIPPING_SCORE_UPPER_LINE = 90
const CANCEL_SCORE_BASE_LINE = 74
const CANCEL_SCORE_LOWER_LINE = 75
const CANCEL_SCORE_UPPER_LINE = 85
const CS_SCORE_BASE_LINE = 80
const CS_SCORE_LOWER_LINE = 81
const CS_SCORE_UPPER_LINE = 90

const NotiIcons = ({
  score = 0,
  baseScore = 0,
  lowerScore = 0,
  upperScore = 0,
}) => {
  if (score === baseScore) {
    return <ImageManager source='questionMarkIcon' size={55} />
  }
  if (score >= upperScore) {
    return <ImageManager source='tickIcon' size={55} />
  }
  if (score < lowerScore) {
    return <ImageManager source='crossIcon' size={55} />
  }
  return <ImageManager source='alertIcon' size={55} />
}

const OrdersShippingScreen = observer(() => {
  const history = useHistory()
  const ShippingScoreIcon = () => {
    if (
      (SellerStore.sellerScores.shippingScore || 0) === SHIPPING_SCORE_BASE_LINE
    ) {
      return <ImageManager source='questionMarkIcon' size={55} />
    }
    if (
      (SellerStore.sellerScores.shippingScore || 0) >= SHIPPING_SCORE_UPPER_LINE
    ) {
      return <ImageManager source='tickIcon' size={55} />
    }
    if (
      (SellerStore.sellerScores.shippingScore || 0) < SHIPPING_SCORE_LOWER_LINE
    ) {
      return <ImageManager source='crossIcon' size={55} />
    }
    return <ImageManager source='alertIcon' size={55} />
  }
  const CancelScoreIcon = () => {
    if (
      (SellerStore.sellerScores.cancelScore || 0) === CANCEL_SCORE_BASE_LINE
    ) {
      return <ImageManager source='questionMarkIcon' size={55} />
    }
    if (
      (SellerStore.sellerScores.cancelScore || 0) >= CANCEL_SCORE_UPPER_LINE
    ) {
      return <ImageManager source='tickIcon' size={55} />
    }
    if ((SellerStore.sellerScores.cancelScore || 0) < CANCEL_SCORE_LOWER_LINE) {
      return <ImageManager source='crossIcon' size={55} />
    }
    return <ImageManager source='alertIcon' size={55} />
  }
  const CustomerServiceScoreIcon = () => {
    if (
      (SellerStore.sellerScores.customerServiceScore || 0) ===
      CS_SCORE_BASE_LINE
    ) {
      return <ImageManager source='questionMarkIcon' size={55} />
    }
    if (
      (SellerStore.sellerScores.customerServiceScore || 0) >=
      CS_SCORE_UPPER_LINE
    ) {
      return <ImageManager source='tickIcon' size={55} />
    }
    if (
      (SellerStore.sellerScores.customerServiceScore || 0) < CS_SCORE_LOWER_LINE
    ) {
      return <ImageManager source='crossIcon' size={55} />
    }
    return <ImageManager source='alertIcon' size={55} />
  }
  const ScoreLegendComponent = ({ imageSource, text }) => {
    return (
      <>
        <ImageManager source={imageSource} size={30} />
        <div style={{ width: 4 }} />
        {text}
        <div style={{ width: 12 }} />
      </>
    )
  }

  const [fullyRendered, setFullyRendered] = useState(false)
  const [
    OpenCustomerServiceExplanationModal,
    setOpenCustomerServiceExplanationModal,
  ] = useState(false)

  useEffect(() => {
    setFullyRendered(true)
  }, [])

  // const [openShippingInfo, setOpenShippingInfo] = useState(true)
  // const [openShippingInfo, setOpenShippingInfo] = useState(false)

  // useEffect(function checkSellerShippingScore() {
  //   if (SellerStore.sellerScores.shippingScore < 90) {
  //     setOpenShippingInfo(true)
  //   }
  // }, [])
  return (
    <div className='max-w-7xl px-20 py-20 bg-white'>
      <div>
        <div className='flex w-full text-center'>
          <h2 className='text-3xl font-bold '>
            {SellerStore.sellerInfo.sellerDisplayName}님의 고객 만족도 분석
          </h2>
        </div>
        <div
          className='mt-8'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <ScoreLegendComponent imageSource='tickIcon' text='우수' />
          <ScoreLegendComponent imageSource='alertIcon' text='부족' />
          <ScoreLegendComponent imageSource='crossIcon' text='주의' />
          <ScoreLegendComponent imageSource='questionMarkIcon' text='집계중' />

          <Button
            onClick={() => {
              setOpenCustomerServiceExplanationModal(true)
            }}
          >
            고객 만족도란 무엇인가요?
          </Button>
        </div>
        <div className='flex justify-between flex-1 mt-4 mb-5'>
          <button
            type='button'
            // onClick={() => {
            //   setOpenShippingInfo(!openShippingInfo)
            // }}
            style={{
              flex: 1,
              backgroundColor: '#FAFAFA',
              borderRadius: 5,
              marginRight: 11,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h3 className='my-5'>배송 만족도</h3>
            <div
              style={{
                color: '#8D8D8D',
                marginTop: -10,
                marginBottom: 5,
                marginLeft: 30,
                marginRight: 30,
                fontWeight: 600,
              }}
            >
              주문 성사일로부터 +3 영업일 내 발송을 완료해주세요. 발송이
              지연되는 경우 발송지연 기능을 통해 고객에게 미리 안내해주세요.
              빠르고 일관된 배송품질은 고객의 재구매를 유도하는 중요한
              요소입니다.
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{
                  alignItems: 'flex-end',
                  alignContent: 'flex-end',
                  marginTop: 30,
                  marginRight: 10,
                  marginBottom: 40,
                }}
              >
                <ShippingScoreIcon />
              </div>
              <div
                style={{
                  fontSize: 80,
                  marginBottom: 30,
                  marginTop: 10,
                  alignItems: 'flex-end',
                  alignContent: 'flex-end',
                }}
              >
                {SellerStore.sellerScores.shippingScore || 0}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  paddingLeft: 5,
                  marginBottom: 40,
                  marginTop: 10,
                  fontSize: 20,
                  color: '#888888',
                }}
              >
                / 100
              </div>
            </div>
            {/* <div className='mb-2'>
              {openShippingInfo ? '닫기' : '자세히보기'}
            </div> */}
          </button>
          <button
            type='button'
            style={{
              flex: 1,
              // height: '90%',
              // width: '24%',
              backgroundColor: '#FAFAFA',
              border: 'none',
              borderWidth: 1,
              borderRadius: 5,
              marginRight: 11,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h3 className='my-5'>취소/환불 만족도</h3>
            <div
              style={{
                color: '#8D8D8D',
                marginTop: -10,
                marginBottom: 5,
                marginLeft: 30,
                marginRight: 30,
                fontWeight: 600,
              }}
            >
              판매자 사정으로 주문 취소가 발생하지 않도록 재고 수량을 철저히
              관리해 주세요. 고객의 주문 취소 요청을 매일 확인하고, 신속히 승인
              절차를 진행해 주세요.
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                // backgroundColor: 'red',
                flex: 1,
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{
                  alignItems: 'flex-end',
                  alignContent: 'flex-end',
                  marginTop: 30,
                  marginRight: 10,
                  marginBottom: 40,
                }}
              >
                <CancelScoreIcon />
              </div>
              <div
                style={{
                  fontSize: 80,
                  marginBottom: 30,
                  marginTop: 10,
                  // backgroundColor: 'green',
                }}
              >
                {SellerStore.sellerScores.cancelScore || 0}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  // backgroundColor: 'blue',
                  paddingLeft: 5,
                  marginBottom: 40,
                  marginTop: 10,
                  fontSize: 20,
                  color: '#888888',
                }}
              >
                / 100
              </div>
            </div>
          </button>
          {/* 3번째 점수상자 시작 */}
          <button
            type='button'
            style={{
              flex: 1,
              // height: '90%',
              // width: '24%',
              backgroundColor: '#FAFAFA',
              border: 'none',
              borderWidth: 1,
              borderRadius: 5,
              // marginRight: 11,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h3 className='my-5'>CS 만족도</h3>
            <div
              style={{
                color: '#8D8D8D',
                marginTop: -10,
                marginBottom: 5,
                marginLeft: 30,
                marginRight: 30,
                fontWeight: 600,
              }}
            >
              고객 문의를 매일 확인하고 24시간 내 신속하게 응대해 주세요. 문의는
              정확한 정보를 바탕으로 답변해 주시고, 불만 사항은 친절하게 대응해
              고객 만족도를 제고해 주세요.
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                // backgroundColor: 'red',
                flex: 1,
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{
                  alignItems: 'flex-end',
                  alignContent: 'flex-end',
                  marginTop: 30,
                  marginRight: 10,
                  marginBottom: 40,
                }}
              >
                <CustomerServiceScoreIcon />
              </div>
              <div
                style={{
                  fontSize: 80,
                  marginBottom: 30,
                  marginTop: 10,
                  // backgroundColor: 'green',
                }}
              >
                {SellerStore.sellerScores.customerServiceScore || 0}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  // backgroundColor: 'blue',
                  paddingLeft: 5,
                  marginBottom: 40,
                  marginTop: 10,
                  fontSize: 20,
                  color: '#888888',
                }}
              >
                / 100
              </div>
            </div>
            {/* <div style={{ fontSize: 80, marginBottom: 30, marginTop: 10 }}>
            {SellerStore.sellerScores.cancelScore || 0}
          </div> */}
          </button>
          {/* 3번째 점수상자 끝 */}
        </div>
        <div className='mt-10'>배송 3가지 안내사항</div>
        <div style={{ borderBottomWidth: 2, borderBottomColor: '#FCEEEE' }}>
          <div className='text-2xl font-bold mb-4'>배송 만족도 올리기</div>
          <div>배송 만족도는 노출에 중요한 영향을 끼쳐요.</div>
          <div>배송 만족도는 실제 발송 기록을 기준으로 산출됩니다. </div>
          <div>
            발송 처리 기한 내 발송을 완료하고, 더욱 빠르게 발송할수록 점수가
            올라갑니다.
          </div>
          <div className='flex justify-between flex-1 mt-4 mb-5'>
            <div
              style={{
                flex: 1,
                backgroundColor: '#FAFAFA',
                border: 'none',
                borderRadius: 5,
                borderWidth: 1,
                marginRight: 11,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h3 className='my-5'>발송 처리 기한 준수</h3>
              <div
                style={{
                  color: '#8D8D8D',
                  marginTop: -10,
                  marginBottom: 5,
                  marginLeft: 30,
                  marginRight: 30,
                  fontWeight: 600,
                }}
              >
                발송 처리 기한(+3 영업일) 내 발송을 완료해주세요.
              </div>
              <h3 className='mt-10'>[정시 발송 완료 비율 (7일 간)]</h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginTop:
                    typeof SellerStore.sellerScores?.shippingDetailStats
                      ?.withinDeadlineOrderNum === 'number'
                      ? null
                      : 40,
                }}
              >
                <div
                  style={{
                    marginRight: 10,
                    marginBottom: 40,
                  }}
                >
                  <NotiIcons
                    score={(
                      (SellerStore.sellerScores?.shippingDetailStats
                        ?.withinDeadlineOrderNum /
                        SellerStore.sellerScores?.shippingDetailStats
                          ?.totalOrderNum) *
                        100 || 0
                    ).toFixed(1)}
                    baseScore={null}
                    lowerScore={97}
                    upperScore={98}
                  />
                </div>
                {typeof SellerStore.sellerScores?.shippingDetailStats
                  ?.withinDeadlineOrderNum === 'number' ? (
                  <>
                    <div
                      style={{
                        fontSize: 80,
                        marginBottom: 30,
                      }}
                    >
                      {`${(
                        (SellerStore.sellerScores?.shippingDetailStats
                          ?.withinDeadlineOrderNum /
                          SellerStore.sellerScores?.shippingDetailStats
                            ?.totalOrderNum) *
                          100 || 0
                      ).toFixed(1)}`}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        paddingLeft: 5,
                        marginBottom: 50,
                        fontSize: 20,
                        color: '#888888',
                      }}
                    >
                      %
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      fontSize: 40,
                      marginBottom: 40,
                    }}
                  >
                    집계중
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                backgroundColor: '#FAFAFA',
                border: 'none',
                borderWidth: 1,
                borderRadius: 5,
                marginRight: 11,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h3 className='my-5'>+1 영업일 발송</h3>
              <div
                style={{
                  color: '#8D8D8D',
                  marginTop: -10,
                  marginBottom: 5,
                  marginLeft: 30,
                  marginRight: 30,
                  fontWeight: 600,
                }}
              >
                주문일 다음 영업일에 발송을 완료하면 구매자 만족도와 노출량이
                향상됩니다.
              </div>
              <h3 className='mt-10'>[+1 영업일 발송 완료 비율 (7일 간)]</h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginTop:
                    typeof SellerStore.sellerScores?.shippingDetailStats
                      ?.oneDayShippingNum === 'number'
                      ? null
                      : 40,
                }}
              >
                <div
                  style={{
                    marginRight: 10,
                    marginBottom: 40,
                  }}
                >
                  <NotiIcons
                    score={(
                      (SellerStore.sellerScores?.shippingDetailStats
                        ?.oneDayShippingNum /
                        SellerStore.sellerScores?.shippingDetailStats
                          ?.totalOrderNum) *
                        100 || 0
                    ).toFixed(1)}
                    baseScore={null}
                    lowerScore={97}
                    upperScore={98}
                  />
                </div>
                {typeof SellerStore.sellerScores?.shippingDetailStats
                  ?.oneDayShippingNum === 'number' ? (
                  <>
                    <div
                      style={{
                        fontSize: 80,
                        marginBottom: 30,
                      }}
                    >
                      {`${(
                        (SellerStore.sellerScores?.shippingDetailStats
                          ?.oneDayShippingNum /
                          SellerStore.sellerScores?.shippingDetailStats
                            ?.totalOrderNum) *
                          100 || 0
                      ).toFixed(1)}`}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        paddingLeft: 5,
                        marginBottom: 50,
                        fontSize: 20,
                        color: '#888888',
                      }}
                    >
                      %
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      fontSize: 40,
                      marginBottom: 40,
                    }}
                  >
                    집계중
                  </div>
                )}
              </div>
            </div>
            {/* 3번째 점수상자 시작 */}
            <div
              style={{
                flex: 1,
                backgroundColor: '#FAFAFA',
                border: 'none',
                borderWidth: 1,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h3 className='my-5'>당일 발송</h3>
              <div
                style={{
                  color: '#8D8D8D',
                  marginTop: -10,
                  marginBottom: 5,
                  marginLeft: 30,
                  marginRight: 30,
                  fontWeight: 600,
                }}
              >
                주문일 당일 발송은 노출량과 판매량이 효과적으로 개선합니다.
              </div>
              <h3 className='mt-10'>[당일 발송 완료 비율 (7일 간)]</h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  style={{
                    marginRight: 10,
                    marginBottom: 40,
                  }}
                >
                  <NotiIcons
                    score={(
                      (SellerStore.sellerScores?.shippingDetailStats
                        ?.onDayShippingNum /
                        SellerStore.sellerScores?.shippingDetailStats
                          ?.totalOrderNum) *
                        100 || 0
                    ).toFixed(1)}
                    baseScore={null}
                    lowerScore={97}
                    upperScore={98}
                  />
                </div>
                <div
                  style={{
                    fontSize: 80,
                    marginBottom: 30,
                  }}
                >
                  {`${(
                    (SellerStore.sellerScores?.shippingDetailStats
                      ?.onDayShippingNum /
                      SellerStore.sellerScores?.shippingDetailStats
                        ?.totalOrderNum) *
                      100 || 0
                  ).toFixed(1)}`}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    paddingLeft: 5,
                    marginBottom: 50,
                    marginTop: 10,
                    fontSize: 20,
                    color: '#888888',
                  }}
                >
                  %
                </div>
              </div>
            </div>
            {/* 3번째 점수상자 끝 */}
          </div>
        </div>
      </div>
      <div>{fullyRendered && <CancelNavigator />}</div>
      <div className='my-10'>{fullyRendered && <ShippingsNavigator />}</div>
      <div
        className='container'
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          // margin: 'auto',
        }}
      >
        <PreShippingOrders
          orders={OrderStore.displayedOrders.filter(
            (order) =>
              order.status === 'pre-shipping' && !order.excelDownloadedAt,
          )}
        />

        <PreShippingOrdersAfterExcel
          orders={OrderStore.displayedOrders.filter(
            (order) =>
              order.status === 'pre-shipping' && order.excelDownloadedAt,
          )}
        />
        <SendingOrders
          orders={OrderStore.displayedOrders.filter(
            (order) =>
              order.status === 'shipping' &&
              !order?.shippingInfo?.trackingDetail,
          )}
        />
        <ShippingOrders
          orders={OrderStore.displayedOrders.filter(
            (order) =>
              order.status === 'shipping' &&
              order?.shippingInfo?.trackingDetail,
          )}
        />
        <ArrivedOrders
          orders={OrderStore.displayedOrders.filter(
            (order) =>
              order.status === 'arrived' || order.status === 'reviewed',
          )}
        />
      </div>
      <Modal
        isOpen={OpenCustomerServiceExplanationModal}
        onRequestClose={() => {
          setOpenCustomerServiceExplanationModal(false)
        }}
        style={{
          overlay: {
            zIndex: 20,
          },
          content: {
            left: '20%',
            right: '20%',
            top: '20%',
            bottom: '20%',
            padding: 0,
            // zIndex: 20,
            // marginRight: '-50%',
          },
        }}
      >
        <CustomerServiceExplanationModal
          id='modal'
          setOpenModal={setOpenCustomerServiceExplanationModal}
          // analytics={analytics}
          // logEvent={logEvent}
        />
      </Modal>
      {/* <Modal
        isOpen={OrderStore?.openCancelPreShippingOrderModal}
        onRequestClose={() => {
          OrderStore?.setOpenCancelPreShippingOrderModal(false)
        }}
        style={{
          overlay: {
            zIndex: 20,
          },
          content: {
            left: '20%',
            right: '20%',
            top: '20%',
            bottom: '20%',
            padding: 0,
            // zIndex: 20,
            // marginRight: '-50%',
          },
        }}
      >
        <CancelPreShippingOrderModal
          setOpenModal={OrderStore?.openCancelPreShippingOrderModal}
        />
      </Modal> */}
    </div>
  )
})

export default OrdersShippingScreen

import SellerStore from '../../stores/SellerStore'
import React from 'react'
import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import OrderStore from '../../stores/OrderStore'

const DirectShippingButton = ({ order, setOrders, orders }) => {
  return (
    <Button
      size='sm'
      appearance='blue'
      type='button'
      onClick={async () => {
        if (SellerStore.isLoading) {
          return
        }
        const confirmedDirectShipping = window.confirm(
          '정말 직접 배송 처리를 하시겠어요? 직접 배송 처리를 하신 뒤에는 주문 건 상태 수정이 어렵습니다.',
        )
        if (!confirmedDirectShipping) {
          return
        }
        SellerStore.setIsLoading(true)
        const result = await backendApis.postOrderDirectShippingInfo(
          order._id,
          {
            ...order.shippingInfo,
            // shippingNumber: shippingNumber.replace(/-/g, ''),
            isDirectShipping: true,
          },
        )

        SellerStore.setIsLoading(false)
        if (result.status === 200) {
          const excelDownloadedAt = order.excelDownloadedAt
          if (order.excelDownloadedAt) {
            OrderStore.changeOrdersNumPerStatus('pre-shipping-post-excel', -1)
          } else {
            OrderStore.changeOrdersNumPerStatus('pre-shipping-pre-excel', -1)
          }
          OrderStore.setDisplayedOrders(
            OrderStore.displayedOrders.map((orderTemp) => {
              if (orderTemp._id === order._id) {
                return {
                  ...orderTemp,
                  status: 'shipping',
                  shippingInfo: {
                    ...orderTemp.shippingInfo,
                    isDirectShipping: true,
                    // shippingNumber: shippingNumber.replace(/-/g, ''),
                  },
                }
              }
              return orderTemp
            }),
          )
          if (setOrders) {
            OrderStore.setUserOrders(
              orders.map((orderTemp) => {
                if (orderTemp._id === order._id) {
                  return {
                    ...orderTemp,
                    status: 'shipping',
                    shippingInfo: {
                      ...orderTemp.shippingInfo,
                      isDirectShipping: true,
                      // shippingNumber: shippingNumber.replace(/-/g, ''),
                    },
                  }
                }
                return orderTemp
              }),
            )
          }

          OrderStore.changeOrdersNumPerStatus('pre-shipping', -1)
          OrderStore.changeOrdersNumPerStatus('shipping', 1)
        }
      }}
    >
      직접 배송
    </Button>
  )
}

export default DirectShippingButton

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../../stores/OrderStore'
import { BrowserRouter as Router, Link } from 'react-router-dom'

const PreShippingPreExcelNavigator = observer(() => {
  const [isOnMouse, setIsOnMouse] = useState(false)
  return (
    <button
      className=' w-1/5 border-gray-700 border-2 rounded-md mr-3 hover:bg-gray-200 text-left bg-white'
      type='button'
      onClick={async () => {
        document.getElementById('preShippingPreExcelOrders').scrollIntoView()
      }}
      onMouseEnter={() => {
        setIsOnMouse(true)
      }}
      onMouseLeave={() => {
        setIsOnMouse(false)
      }}
    >
      <div className='textContainer' style={{ margin: 20 }}>
        <h2 className='text-base whitespace-pre-wrap'>
          팀모집완료
          <br />
          (엑셀추출 이전)
        </h2>
        <h2 className='mt-3 text-2xl font-bold'>
          {OrderStore.ordersNumPerStatus['pre-shipping-pre-excel'] || 0}
        </h2>
      </div>
    </button>
  )
})

export default PreShippingPreExcelNavigator

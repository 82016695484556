import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import {
  BrowserRouter as Router,
  useHistory,
  Route,
  Switch,
  Link,
} from 'react-router-dom'
import OrderStore from '../stores/OrderStore'
import ItemStore from '../stores/ItemStore'
import CancelingPreShippingsScreen from '../screens/CancelingPreShippingsScreen'
import RefundsScreen from '../screens/RefundsScreen'
import ItemQuestionsScreen from '../screens/ItemQuestionsScreen'
import PreShippingPreExcelDelays from './PreShippingPreExcelDelays'
import PreShippingPostExcelDelays from './PreShippingPostExcelDelays'
import SendingDelays from './SendingDelays'
import ShippingDelays from './ShippingDelays'
import ApproveOrdersCancelDelays from './ApproveOrdersCancelDelays'
import ApproveRefundsDelays from './ApproveRefundsDelays'
import AnswerQuestionsDelays from './AnswerQuestionsDelays'
import AnswerQuestionsFromAdminDelays from './AnswerQuestionsFromAdminDelays'

const CancelNavigator = observer(({ route }) => {
  const history = useHistory()
  const [isOnMouse, setIsOnMouse] = useState(false)
  // function showCancelingPreShipping() {
  //   if ($('#canceling-pre-shipping').css('display') == 'none') {
  //     $('#canceling-pre-shipping').show()
  //   } else {
  //     $('#canceling-pre-shipping').hide()
  //   }
  // }
  // const changeCursor = () => {
  //   setCursor(pointer)
  // }
  return (
    <div className='h-100 w-full flex-1 m-auto flex-col flex mb-30 bg-transparent mt-10'>
      <div className='text-2xl font-bold mb-5'>
        고객 만족도 향상을 위해 아래 지연건을 확인하고 먼저 처리해 주세요!
      </div>
      <div className='flex flex-row w-full items-center justify-center mb-3'>
        <PreShippingPreExcelDelays />
        {/* <button
          className='CancelingPreShippingOrders'
          style={{
            // width: '24%',
            borderColor: '#adadad',
            border: 'solid',
            display: 'flex',
            borderWidth: 1,
            borderRadius: 5,
            marginRight: 11,
            paddingLeft: 10,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: isOnMouse ? '#F5F5F5' : '#FFF',
          }}
          type='button'
          onClick={async () => {
            document
              .getElementById('preShippingPreExcelOrders')
              .scrollIntoView()
          }}
          onMouseEnter={() => {
            setIsOnMouse(true)
          }}
          onMouseLeave={() => {
            setIsOnMouse(false)
          }}
        >
          <div
            style={{
              // flex: 1,
              alignItems: 'right',
              justifyContent: 'center',
              width: '84%',
              fontSize: 16,
              fontWeight: 'bold',
              paddingleft: 8,
              textAlign: 'left',
            }}
          >
            <div>팀모집완료 지연</div>
            <div>(액셀추출 이전)</div>
          </div>
          <div
            style={{
              flex: 1,
              alignItems: 'center',
              textAlign: 'left',
              justifyContent: 'center',
              // backgroundColor: 'red',
              width: '16%',
              color: 'red',
              fontSize: 18,
            }}
          >
            <h2>
              {OrderStore.ordersNumPerStatus[
                'pre-shipping-pre-excel-delayed'
              ] || 0}
            </h2>
          </div>
        </button> */}
        <ApproveOrdersCancelDelays />
        <AnswerQuestionsDelays />
      </div>
      <div
        className='Content'
        style={{
          display: 'flex',
          height: 80,
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start',
          // backgroundColor: 'red',
          // marginLeft: 8,
        }}
      >
        <PreShippingPostExcelDelays />
        <ApproveRefundsDelays />
        <AnswerQuestionsFromAdminDelays />
        {/* <div
          className='CancelingPreShippingOrders'
          style={{
            // width: '24%',
            borderColor: '#adadad',
            // border: 'solid',
            display: 'flex',
            borderWidth: 1,
            borderRadius: 5,
            marginRight: 11,
            paddingLeft: 10,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        /> */}
      </div>
      <div
        className='w-2/3'
        style={{
          display: 'flex',
          height: 80,
          flexDirection: 'row',

          alignItems: 'center',
          justifyContent: 'flex-start',
          // backgroundColor: 'red',
          // marginLeft: 8,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <SendingDelays />
        <ShippingDelays />

        <div>
          <Router>
            <Switch>
              <Route path='/canceling-shippings'>
                <CancelingPreShippingsScreen />
              </Route>
            </Switch>
            <Switch>
              <Route path='/refunds'>
                <RefundsScreen />
              </Route>
            </Switch>
            <Switch>
              <Route path='/item-questions'>
                <ItemQuestionsScreen />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  )
})
export default CancelNavigator

class SellerInfoParser {
  bizNumParser = (bizNum) => {
    if (!bizNum) return
    if (Number.isSafeInteger(bizNum)) {
      return bizNum
    }
    return parseInt(bizNum.replace(/-/g, ''), 10)
  }
}

export default new SellerInfoParser()

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import SellerStore from '../../stores/SellerStore'
import backendApis from '../../utils/backendApis'
import Button from '../atoms/button'
import Input from '../atoms/input'
import Pagination from './pagination'
import ItemComponent from '../ItemComponents/ItemComponent'
import ListLimitSelector from '../atoms/listLimitSelector'
import TipBox from '../atoms/tipBox'
import ItemSearchResultDownloadModal from './itemSearchResultDownloadModal'
import Selector from '../atoms/selector'
import CheckedItemButtonList from './checkedItemButtonList'
import ItemRankBatchUpdateModal from './ItemRankBatchUpdateModal'
import ExcelFileUploadModal from './excelFileUploadModal'
import PhotoReviewSettingModal from '../../screens/Modals/PhotoReviewSettingModal'
import ExternalUrlBatchUpdateModal from '../atoms/externalUrlBatchUpdateModal'

const periodButtonList = [
  { name: '전체', id: 0 },
  { name: '1년', id: 365 },
  { name: '1달', id: 30 },
  { name: '1주', id: 7 },
  { name: '1일', id: 1 },
]

const dateTimeSortingTypeList = [
  { name: '생성일시 최신 순', value: 'newestCreatedAt' },
  { name: '생성일시 오래된 순', value: 'oldestCreatedAt' },
  { name: '수정일시 최신 순', value: 'newestUpdatedAt' },
  { name: '수정일시 오래된 순', value: 'oldestUpdatedAt' },
]

const searchFieldList = [
  { name: '상품 키워드', value: 'itemTitle' },
  { name: '상품 아이디', value: 'itemId' },
  { name: '판매자 상품 코드', value: 'optionCode' },
  { name: '상품 아이디 대량입력', value: 'itemIdList' },
]

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const SearchFiledToKR = Object.freeze({
  itemTitle: '상품 키워드',
  itemId: '상품 아이디',
  optionCode: '판매자 상품 코드',
  itemIdList: '상품 아이디,상품 아이디..',
})

const ItemSearchSection = observer(() => {
  const [itemQuery, setItemQuery] = useState('')
  const [registerItemWindow, setRegisterItemWindow] = useState(0) // from togglePeriod
  const [itemSellingStatus, setItemSellingStatus] = useState('all') // enum(['onSale', 'soldOut', 'all']) from toggleStateChange
  const [selectedSearchField, setSelectedSearchField] = useState('itemTitle') // from selected
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [largeCategoryInfo, setLargeCategoryInfo] = useState({})
  const [mediumCategoryInfo, setMediumCategoryInfo] = useState({})
  const [smallCategoryInfo, setSmallCategoryInfo] = useState({})
  const [dateTimeSortingType, setDateTimeSortingType] =
    useState('newestCreatedAt')
  const [itemCondition, setItemCondition] = useState({})
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [openRankDigestModal, setOpenRankDigestModal] = useState(false)
  const [photoReviewUpdateModal, setPhotoReviewUpdateModal] = useState(false)
  const [externalUrlUpdateModal, setExternalUrlUpdateModal] = useState(false)
  const checkBoxValueResetter = () => {
    setIsAllCheckBoxChecked(false)
    setCheckedItemIds([])
  }

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (isLoading) return
    if (!itemQuery && itemQuery !== '') return
    setIsLoading(true)
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    let query
    if (itemQuery !== '') {
      switch (selectedSearchField) {
        case 'itemTitle':
          query = escapeRegExp(itemQuery)
          break
        case 'itemId':
          itemCondition._id = {
            $in: [itemQuery],
          }
          break
        case 'optionCode':
          itemCondition['optionsInfo.optionPrices.optionCode'] = itemQuery
          break
        case 'itemIdList':
          itemCondition._id = {
            $in: [itemQuery],
          }
          break
        default:
      }
    }

    if (registerItemWindow && Number.isSafeInteger(registerItemWindow)) {
      itemCondition.createdAt = {
        $gt: new Date(
          new Date().getTime() - 1000 * 60 * 60 * 24 * registerItemWindow,
        ),
      }
    }
    if (itemSellingStatus === 'onSale') {
      itemCondition.soldOut = false
      itemCondition.$or = [
        {
          outOfStock: false,
        },
        {
          outOfStock: null,
        },
      ]
    } else if (itemSellingStatus === 'soldOut') {
      itemCondition.$or = [
        {
          soldOut: true,
        },
        {
          outOfStock: true,
        },
      ]
    }
    if (Object.keys(largeCategoryInfo).length !== 0)
      itemCondition['categoryInfo.largeCategoryId'] = largeCategoryInfo._id
    if (Object.keys(mediumCategoryInfo).length !== 0)
      itemCondition['categoryInfo.mediumCategoryId'] = mediumCategoryInfo._id
    if (Object.keys(smallCategoryInfo).length !== 0)
      itemCondition['categoryInfo.smallCategoryId'] = smallCategoryInfo._id
    let itemSortCondition = null
    switch (dateTimeSortingType) {
      case 'oldestCreatedAt':
        itemSortCondition = { createdAt: 1 }
        break
      case 'newestUpdatedAt':
        itemSortCondition = { updatedAt: -1 }
        break
      case 'oldestUpdatedAt':
        itemSortCondition = { updatedAt: 1 }
        break
      default:
        itemSortCondition = { createdAt: -1 }
    }
    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
          itemSortCondition,
          query,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
        itemSortCondition,
        query,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      const filteredItemInfo = result?.data?.itemsInfo?.filter((itemInfo) => {
        // characteristicItem이 없거나, isNewYear24DealCoreItem이 true가 아닌 경우 필터링
        return !(
          itemInfo.characteristicItem &&
          itemInfo.characteristicItem.isGroceryDealCoreItem === true
        )
      })
      setItemsInfo(filteredItemInfo)
      setItemCondition(itemCondition)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
            itemSortCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        const filteredItemInfo = result?.data?.itemsInfo?.filter(
          (itemInfo) => itemInfo.characteristicItem.isNewYear24DealCoreItem,
        )
        console.log('filteredItemInfo', filteredItemInfo)
        setItemsInfo(filteredItemInfo)
        // setItemsInfo(retryResult?.data?.itemsInfo)
        setItemCondition(itemCondition)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    setIsLoading(false)
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [limit, dateTimeSortingType])

  useEffect(() => {
    if (ItemStore.categories.length === 0) {
      ItemStore.loadCategories()
    }
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  const defaultValueResetter = () => {
    setItemQuery('')
    setRegisterItemWindow(0)
    setItemSellingStatus('all')
    setSelectedSearchField('itemTitle')
  }

  const PeriodHandler = () => {
    return (
      <>
        {periodButtonList.map((button) => (
          <button
            key={button.name}
            type='button'
            className={`w-full border border-gray-500 ${
              registerItemWindow === button.id
                ? 'bg-blue-500 text-white'
                : 'bg-white'
            }`}
            id={button.id}
            onClick={(e) => {
              setRegisterItemWindow(Number(e.target.id) || 0)
            }}
          >
            {button.name}
          </button>
        ))}
      </>
    )
  }

  return (
    <>
      <div className='grid grid-cols-2 px-10 py-10 text-lg bg-white divide-x-2 shadow-lg rounded-2xl'>
        <div className='flex flex-col flex-1'>
          <div className='mb-2 text-sm font-bold text-gray-500'>Tips</div>
          <TipBox />
        </div>

        <div className='pl-10 '>
          <div className='flex flex-row items-center mb-3'>
            <Selector
              optionNameValueList={searchFieldList}
              selectedOption={selectedSearchField}
              setOption={setSelectedSearchField}
            />
            <Input
              className='w-2/3 ml-5 select-none'
              type='text'
              placeholder={`${SearchFiledToKR[selectedSearchField]}를 입력해 주세요.`}
              value={itemQuery}
              onChange={(e) => {
                setItemQuery(e.target.value)
              }}
            />
          </div>
          <div className='flex items-center my-3'>
            <div className='pr-1 mr-2 text-lg font-bold'>카테고리</div>
            <div className='max-xl:flex-col xl:flex xl:flex-wrap'>
              <div>
                <select
                  id='large_category'
                  name='large_category'
                  className='text-sm pr-7'
                  value={
                    typeof largeCategoryInfo._id === 'number'
                      ? largeCategoryInfo._id
                      : ''
                  }
                  onChange={(e) => {
                    setMediumCategoryInfo({})
                    setSmallCategoryInfo({})
                    if (e.target.value === '') {
                      setLargeCategoryInfo({})
                      return
                    }
                    const largeCategoryInfoTemp = ItemStore.categories.find(
                      (category) =>
                        category._id === parseInt(e.target.value, 10),
                    )
                    if (largeCategoryInfoTemp) {
                      setLargeCategoryInfo(largeCategoryInfoTemp)
                      setMediumCategoryInfo({})
                      setSmallCategoryInfo({})
                    }
                  }}
                >
                  <option value=''>==</option>
                  {ItemStore.categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.largeCategoryNames.kr}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  id='medium_category'
                  name='medium_category'
                  className='text-sm pr-7'
                  value={mediumCategoryInfo._id}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setMediumCategoryInfo({})
                      setSmallCategoryInfo({})
                      return
                    }
                    const mediumCategoryInfoTemp =
                      largeCategoryInfo.mediumCategories.find(
                        (category) =>
                          category._id === parseInt(e.target.value, 10),
                      )
                    if (mediumCategoryInfoTemp) {
                      setMediumCategoryInfo(mediumCategoryInfoTemp)
                      setSmallCategoryInfo({})
                    }
                  }}
                >
                  <option value=''>==</option>
                  {largeCategoryInfo.mediumCategories?.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.mediumCategoryNames.kr}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  id='small_category'
                  name='small_category'
                  className='text-sm pr-7'
                  value={smallCategoryInfo._id}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setSmallCategoryInfo({})
                      return
                    }
                    const smallCategoryInfoTemp =
                      mediumCategoryInfo.smallCategories.find(
                        (category) =>
                          category._id === parseInt(e.target.value, 10),
                      )
                    if (smallCategoryInfoTemp) {
                      setSmallCategoryInfo(smallCategoryInfoTemp)
                    }
                  }}
                >
                  <option value=''>==</option>
                  {mediumCategoryInfo.smallCategories?.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.smallCategoryNames.kr}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='flex items-center my-3'>
            <div className='flex items-center'>
              <div className='mr-4 text-lg font-bold'>판매</div>
              <input
                type='checkbox'
                id='all'
                onClick={(e) => {
                  setItemSellingStatus(e.target.id)
                }}
                checked={itemSellingStatus === 'all'}
                className='mr-1'
              />
              <div className='mr-3'>전체</div>
              <input
                type='checkbox'
                id='onSale'
                onClick={(e) => {
                  setItemSellingStatus(e.target.id)
                }}
                className='mr-1'
                checked={itemSellingStatus === 'onSale'}
              />
              <div className='mr-3'>판매중</div>
              <input
                type='checkbox'
                id='soldOut'
                onClick={(e) => {
                  setItemSellingStatus(e.target.id)
                }}
                className='mr-1'
                checked={itemSellingStatus === 'soldOut'}
              />
              <div className='mr-3'>품절</div>
            </div>
          </div>
          <div className='flex items-center my-3'>
            <div className='mr-4 text-lg font-bold'>기간</div>
            <div className='flex w-2/3'>
              <PeriodHandler buttonList={periodButtonList} />
            </div>
          </div>
          <div className='flex flex-1'>
            <Button
              className='w-1/2 mr-3'
              onClick={defaultValueResetter}
              appearance='neutral'
              size='lg'
            >
              초기화
            </Button>
            <Button
              onClick={async () => {
                fetchSegmentedItemsInfo(true)
              }}
              appearance='positive'
              size='lg'
              className='w-1/2'
            >
              검색
            </Button>
          </div>
        </div>
      </div>
      <div>
        <>
          <div className='px-10 py-3 my-10 shadow-md bg-slate-50 rounded-xl'>
            <div className='flex flex-row items-center justify-between flex-1 mt-10 mb-3 '>
              <div className='mb-2'>검색결과 총 {itemsCount}개</div>
              {itemsInfo?.length > 0 && (
                <div className='flex flex-row items-baseline space-x-3'>
                  <div>
                    <Selector
                      optionNameValueList={dateTimeSortingTypeList}
                      selectedOption={dateTimeSortingType}
                      setOption={setDateTimeSortingType}
                    />
                  </div>
                  <div>
                    <ListLimitSelector limit={limit} setLimit={setLimit} />
                  </div>
                </div>
              )}
            </div>
            <div className='mb-4 '>
              <div className='flex justify-between'>
                <div className='flex'>
                  <CheckedItemButtonList
                    checkedItemIds={checkedItemIds}
                    resetter={checkBoxValueResetter}
                    callbackFunction={() => fetchSegmentedItemsInfo(true)}
                  />
                  <div>
                    <Button
                      appearance='positiveSub'
                      onClick={() => setOpenRankDigestModal(true)}
                    >
                      대량 등급 재배정
                    </Button>
                  </div>
                  <div className='ml-2'>
                    <Button
                      appearance='positiveSub'
                      onClick={() => setPhotoReviewUpdateModal(true)}
                    >
                      사진 후기 대량 수정
                    </Button>
                  </div>
                  {/* <div className='ml-2'>
                    <Button
                      appearance='positiveSub'
                      onClick={() => setExternalUrlUpdateModal(true)}
                    >
                      외부 리뷰 대량 연동
                    </Button>
                  </div> */}
                </div>
                <div className='flex justify-between'>
                  <div className='ml-3'>
                    <ItemSearchResultDownloadModal
                      itemsInfo={itemsInfo}
                      itemsCount={itemsCount}
                      itemCondition={itemCondition}
                      dateTimeSortingType={dateTimeSortingType}
                      itemSellingStatus={itemSellingStatus}
                      itemQuery={itemQuery}
                      isButton={1}
                      buttonText='검색결과 다운로드'
                    >
                      검색결과 다운로드
                    </ItemSearchResultDownloadModal>
                  </div>
                  <div className='ml-3'>
                    <ExcelFileUploadModal
                      modalTitle='상품 정보 변경'
                      filename='상품 정보 수정용'
                      infoType='item'
                      submitCallbackFunction={() => {
                        console.log('excel file sumitted')
                        fetchSegmentedItemsInfo(true)
                      }}
                    >
                      상품 정보 변경
                    </ExcelFileUploadModal>
                  </div>
                  <div className='ml-3'>
                    <ExcelFileUploadModal
                      modalTitle='배송 정보 변경'
                      filename='배송 정보 수정용'
                      infoType='shipping'
                      submitCallbackFunction={() => {
                        console.log('excel file sumitted')
                        fetchSegmentedItemsInfo(true)
                      }}
                    >
                      배송 정보 변경
                    </ExcelFileUploadModal>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-row items-center justify-center flex-1 px-8 py-4 mb-5 font-bold bg-white divide-x-2 rounded-lg shadow-md select-none text-neutral-500'>
              <div className='flex flex-row flex-1 items-center justify-start min-w-[3%] max-w-[3%]'>
                <input
                  type='checkbox'
                  checked={isAllCheckBoxChecked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckedItemIds(
                        itemsInfo?.map((itemInfo) => itemInfo?._id),
                      )
                      setIsAllCheckBoxChecked(true)
                    } else {
                      setCheckedItemIds([])
                      setIsAllCheckBoxChecked(false)
                    }
                  }}
                />
              </div>
              <div className='flex flex-row items-center justify-center flex-1 min-w-[12%] max-w-[12%]'>
                메인이미지
              </div>
              <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
                상품정보
              </div>
              <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
                노출트렌드
              </div>
              <div className='flex flex-row items-center justify-center flex-1 min-w-[35%] max-w-[35%]'>
                등급 시뮬레이터
              </div>
            </div>
            {itemsInfo.map((itemInfo, idx) => (
              <div key={itemInfo._id.toString() + idx.toString()}>
                <ItemComponent
                  itemInfo={itemInfo}
                  itemsInfo={itemsInfo}
                  setItemsInfo={setItemsInfo}
                  checkBox
                  checkedItemIds={checkedItemIds}
                  setCheckedItemIds={setCheckedItemIds}
                  modalType='alfarmPhotoReview'
                />
              </div>
            ))}
          </div>
        </>
        <div>
          <Pagination
            total={itemsCount}
            limit={limit}
            page={page}
            setPage={setPage}
            size='lg'
          />
        </div>
      </div>
      <ItemRankBatchUpdateModal
        showModal={openRankDigestModal}
        setShowModal={setOpenRankDigestModal}
      />
      <PhotoReviewSettingModal
        showModal={photoReviewUpdateModal}
        setShowModal={setPhotoReviewUpdateModal}
      />
      {externalUrlUpdateModal && (
        <ExternalUrlBatchUpdateModal
          onClose={() => {
            setExternalUrlUpdateModal(false)
          }}
        />
      )}
    </>
  )
})

export default ItemSearchSection

import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({
  children,
  href = '',
  onClick,
  className = '',
  appearance = 'positive',
  size = 'md',
  placeholder,
  type = 'button',
  disabled = false,
  textColor = 'white',
}) => {
  const styleByType = {
    none: '',
    red: 'font-bold border-b-2 border-r-2 border-theme-700 shadow-md bg-gradient-to-r from-theme-400 via-theme-500 to-theme-600 hover:bg-gradient-to-r hover:from-theme-600 hover:via-theme-500 hover:to-theme-400',
    red3D:
      'font-bold bg-gradient-to-r from-theme-400 via-theme-500 to-theme-600 border-b-2 border-r-2 border-theme-700 shadow-md',
    gray: 'font-bold border-b-2 border-r-2 border-sub-500 shadow-md bg-gradient-to-r from-sub-400 via-sub-500 to-sub-600 hover:bg-gradient-to-r hover:from-sub-600 hover:via-sub-500 hover:to-sub-400',
    transparent: 'font-bold bg-transparent',
    disabled: 'font-bold bg-sub-100 border-l-2 border-r-2 border-sub-100',
    black: 'font-bold bg-black hover:bg-sub-500 ',
    white: 'font-bold bg-white border-2 border-sub-500 hover:bg-sub-500',
    blue: 'font-bold shadow-md border-b-2 border-r-2 border-blue-700 bg-gradient-to-r from-blue-600 to-blue-600 hover:bg-gradient-to-r hover:from-blue-800 hover:to-blue-800',
    teritiary: 'text-gray-500',
    positive:
      'font-bold shadow-md border-b-2 border-r-2 border-positive-700 bg-gradient-to-r from-positive-400 to-positive-600 hover:bg-gradient-to-r hover:from-positive-500 hover:to-positive-800',
    positiveSub:
      'font-bold shadow-md border-b-2 border-r-2 border-emerald-700 bg-gradient-to-r from-emerald-600 to-emerald-600 hover:bg-gradient-to-r hover:from-green-800 hover:to-green-800',
    neutral:
      'font-bold shadow-md border-b-2 border-r-2 border-neutral-700 bg-neutral-400 hover:bg-neutral-600',
    neutralSub:
      'font-bold shadow-md border-b-2 border-r-2 border-neutral-700 bg-neutral-500 hover:bg-neutral-700',
    outline: 'font-bold border border-slate-700 bg-transparent',
  }

  const sizeByType = {
    sm: 'text-sm px-2 py-1 rounded-md',
    md: 'text-base px-2 py-1 rounded-lg',
    lg: 'text-lg px-3 py-2 rounded-lg',
    xl: 'text-xl px-3 py-2 rounded-lg',
    xxl: 'text-2xl px-3 py-2 rounded-xl',
    tonglg:
      'text-lg px-5 py-5 rounded-2xl transition duration-100 ease-out hover:ease-in hover:scale-105',
    flexlg:
      'flex flex-1 items-center justify-center flex-row text-lg px-3 py-2 rounded-lg w-full',
    none: '',
  }

  const Btn = () => (
    <button
      placeholder={placeholder}
      onClick={onClick}
      className={`${className} ${sizeByType[size]} ${
        !disabled ? 'cursor-pointer' : 'cursor-not-allowed'
      } select-none ${
        styleByType?.[appearance]?.includes('text') ? '' : `text-${textColor}`
      } ${styleByType[appearance]}`}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
    >
      {children}
    </button>
  )
  if (href && !disabled)
    return (
      <Link to={href} className='cursor-pointer'>
        <Btn />
      </Link>
    )

  return <Btn />
}

export default Button

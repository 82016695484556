import ItemComponent from '../../components/ItemComponents/ItemComponent'
import backendApis from '../../utils/backendApis'
import dealConfig from '../../utils/dealConfig'
import { useEffect, useState } from 'react'
import DealApplicationModal from '../../components/molecules/dealApplicationModal'

const DealItemList = ({ dealType }) => {
  const extraCondition = dealConfig?.dealManagementCondition?.[dealType]
  const [normalItemInfo, setNormalItemInfo] = useState([])
  const [dealStandItemInfo, setDealStandItemInfo] = useState([])
  const [selectedItem, setSelectedItem] = useState()
  const [openDealApplicationModal, setOpenDealApplicationModal] =
    useState(false)
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)

  useEffect(() => {
    const fetchNormalItem = async () => {
      const itemCondition = {}

      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        {
          itemFilters: {
            $elemMatch: {
              $and: [{ key: 'deal', value: dealType }],
            },
          },
        },
      ]
      const result = await backendApis.getSellerSegementedItemsInfoByCondition(
        {
          type: 'item',
          itemCondition,
        },
        page,
        limit,
      )
      if (result?.status === 2000) {
        setNormalItemInfo(result?.data?.itemsInfo)
      } else if (result?.status === 2001) {
        const maxPage = Math.ceil(result?.data?.itemsCount / limit) || 1
        const retryResult =
          await backendApis.getSellerSegementedItemsInfoByCondition(
            {
              type: 'item',
              itemCondition,
            },
            maxPage,
            limit,
          )
        if (retryResult?.status === 2000) {
          setNormalItemInfo(retryResult?.data?.itemsInfo)
        } else {
          setNormalItemInfo([])
          if (retryResult?.status !== 2001) {
            window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
          }
        }
      }
    }
    const fetchDealStand = async () => {
      const result = await backendApis.getDealStandItemBySellerId(dealType)
      setDealStandItemInfo(result?.data)
      if (result === 200) {
        setDealStandItemInfo(result?.data)
      } else {
        const retryResult = await backendApis.getDealStandItemBySellerId(
          dealType,
        )
        if (retryResult === 200) {
          setDealStandItemInfo(retryResult?.data)
        } else {
          setDealStandItemInfo([])
          if (retryResult?.status !== 200) {
            window.alert(
              '상품 목록을 불러오는 중 문제가 발생했습니다. 새로고침 해주세요',
            )
          }
        }
      }
    }
    fetchNormalItem()
    fetchDealStand()
  }, [dealType])

  let dealApplyInfo
  if (dealType === 'tenMinDeal') {
    dealApplyInfo = 'complete'
  }

  return (
    <div className='w-screen max-w-7xl'>
      <div className='flex flex-1 mb-8 bg-white shadow-md select-none whitespace-nowrap rounded-xl'>
        <div className='flex flex-col justify-between px-4 py-8 bg-red-200'>
          {normalItemInfo.map((itemInfo, idx) => (
            <div key={`${itemInfo._id + idx.toString()}2`}>
              <ItemComponent
                itemInfo={itemInfo}
                isRankItem={false}
                dealType={dealType}
                dealRequest={() => {
                  setSelectedItem(itemInfo)
                  setOpenDealApplicationModal(true)
                }}
                dealCancelRequest={async () => {
                  const result = await backendApis.deleteDealOptionsInfo(
                    itemInfo?._id,
                    dealType,
                  )
                  if (result?.status === 200) {
                    alert('취소가 완료되었습니다')
                    // fetchSegmentedItemsInfo(true) 이거뭐여
                  }
                }}
                //  itemInfo에는 상시 아이템 아님?
                dealApplyInfo={dealApplyInfo}
                // dealStandItemInfo={}
              />
            </div>
          ))}
          {openDealApplicationModal && (
            <DealApplicationModal
              showModal={openDealApplicationModal}
              setShowModal={setOpenDealApplicationModal}
              dealType={dealType}
              itemInfo={selectedItem}
              setItemInfo={setSelectedItem}
              applied // fix me -> 신청하기도 들어가면 applyStatusTab에 따라 boolean 구분 필요
              normalItemInfo={normalItemInfo.find(
                (obj) => obj?._id === selectedItem?._id,
              )}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DealItemList

import Button from './button'
import Modal from './modal'
import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import { GrFormClose as CloseIcon } from 'react-icons/gr'
import {
  FcSalesPerformance as CoinIcon,
  FcDoughnutChart as AdIcon,
} from 'react-icons/fc'
import Tooltip from '../ADS/atoms/tooltip'
import Table from '../ADS/molecules/table'
import RankIconSetter from '../molecules/rankIconSetter'
import SellerQuest from '../../utils/sellerQuest'
import backendApis from '../../utils/backendApis'
import {
  IoIosArrowUp as ArrowUpIcon,
  IoIosArrowDown as ArrowDownIcon,
} from 'react-icons/io'

const AdQuest = observer(
  ({ openModal = true, setOpenModal = () => {}, setShowQuest }) => {
    const columns = useMemo(
      () => [
        { Header: '등급', accessor: 'itemRank' },
        { Header: '추천 ROAS', accessor: 'recommendedRoas' },
        { Header: '최대 ROAS', accessor: 'maxRoas' },
        { Header: '광고비(1만원 당)', accessor: 'adCost' },
      ],
      [],
    )
    const data = useMemo(
      () => [
        {
          itemRank: (
            <div className='flex flex-row items-center justify-center flex-1'>
              <RankIconSetter className='w-8 h-8' itemRank='S' />
            </div>
          ),
          recommendedRoas: (
            <span className='flex flex-row items-center justify-center flex-1 font-bold text-blue-500'>
              1,000%
            </span>
          ),
          maxRoas: (
            <span className='flex flex-row items-center justify-center flex-1'>
              2,000%
            </span>
          ),
          adCost: (
            <div>
              <span className='font-bold text-blue-500'>1,000원</span> / 500원
            </div>
          ),
        },
        {
          itemRank: (
            <div className='flex flex-row items-center justify-center flex-1'>
              <RankIconSetter className='w-8 h-8' itemRank='A' />
            </div>
          ),
          recommendedRoas: (
            <span className='flex flex-row items-center justify-center flex-1 font-bold text-blue-500'>
              600%
            </span>
          ),
          maxRoas: (
            <span className='flex flex-row items-center justify-center flex-1'>
              1,000%
            </span>
          ),
          adCost: (
            <div>
              <span className='font-bold text-blue-500'>1,660원</span> / 1,000원
            </div>
          ),
        },
        {
          itemRank: (
            <div className='flex flex-row items-center justify-center flex-1'>
              <RankIconSetter className='w-8 h-8' itemRank='B' />
            </div>
          ),
          recommendedRoas: (
            <span className='flex flex-row items-center justify-center flex-1 font-bold text-blue-500'>
              400%
            </span>
          ),
          maxRoas: (
            <span className='flex flex-row items-center justify-center flex-1'>
              600%
            </span>
          ),
          adCost: (
            <div>
              <span className='font-bold text-blue-500'>2,500원</span> / 1,660원
            </div>
          ),
        },
      ],
      [],
    )

    const adQuestStages = [
      'checkAdInfoModal',
      'registerAdItem',
      'chargeCredit',
      'adBatchModal',
    ]

    return (
      <>
        {openModal ? (
          <div className='px-5 py-3 bg-white shadow-xl select-none text-sub-700 rounded-xl w-80 h-96'>
            <div className='flex flex-row items-center justify-end flex-1 mb-1 '>
              <Button
                appearance='transparent'
                textColor='black'
                onClick={() => setShowQuest(false)}
              >
                <CloseIcon className='w-6 h-6' />
              </Button>
            </div>
            <div className='flex flex-row items-center justify-between flex-1 mb-3 text-lg font-bold'>
              <div className='flex flex-row items-center'>
                <AdIcon className='mr-1' />
                광고 퀘스트
              </div>
              <Button
                appearance='transparent'
                textColor='black'
                onClick={() => setOpenModal(false)}
              >
                <ArrowUpIcon className='w-6 h-6' />
              </Button>
            </div>
            <div className='flex flex-row items-center justify-end flex-1 mb-1 text-sm'>
              <div>퀘스트 달성도</div>
            </div>
            {/* {JSON.stringify(tutorialInfo)} */}
            <div className='w-full h-4 mb-5 bg-gray-100 rounded-xl'>
              <div
                className={` h-4 bg-positive-500 rounded-xl ease-in-out duration-300`}
                style={{
                  width: `${SellerQuest.questProgressChecker(
                    'adQuest',
                    adQuestStages,
                  )}%`,
                }}
              />
            </div>
            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center text-base font-bold'>
                  10배 보장 광고 알아보기
                  <Tooltip text='💡올웨이즈 광고의 특성을 알려드립니다.' />
                </div>
                <Modal
                  className='shadow-none'
                  modalTitle='10배 보장 광고 알아보기'
                  modalContent={
                    <>
                      <ol className='ml-5 list-decimal'>
                        <li className='mb-2'>
                          낮은 광고비로 광고를 진행하실 수 있는{' '}
                          <span className='font-bold text-blue-500'>
                            CPS 광고
                          </span>
                          가 출시되었습니다.
                        </li>
                        <li className='mb-2'>
                          CPS 광고는 상품 노출 횟수나 클릭 횟수가 아닌,{' '}
                          <span className='font-bold text-blue-500'>
                            판매시에만 광고비가 청구
                          </span>
                          되는 광고입니다.
                        </li>
                        <li className='mb-2'>
                          현재 광고비 설정은 다음과 같이 가능합니다.
                          <Table columns={columns} data={data} />
                        </li>
                      </ol>
                    </>
                  }
                  modalLog='itemRankTutorialButton'
                  isButton
                  buttonText='확인했습니다'
                  buttonCallbackFunction={async () => {
                    await SellerQuest.questClearer(
                      'adQuest',
                      'checkAdInfoModal',
                    )
                  }}
                  appearance={
                    SellerQuest.questChecker('adQuest', 'checkAdInfoModal')
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'adQuest',
                    'checkAdInfoModal',
                  )}
                >
                  {SellerQuest.questChecker('adQuest', 'checkAdInfoModal')
                    ? '완료'
                    : 'GO'}
                </Modal>
              </div>
            </div>
            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center'>
                  <div className='text-base font-bold'>
                    광고 상품 한 개 등록하기
                  </div>
                  <Tooltip text='💡광고 상품을 하나 이상 신청해주세요.' />
                </div>
                <Button
                  href='/ad-apply'
                  appearance={
                    SellerQuest.questChecker('adQuest', 'registerAdItem')
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker(
                    'adQuest',
                    'registerAdItem',
                  )}
                >
                  {SellerQuest.questChecker('adQuest', 'registerAdItem')
                    ? '완료'
                    : 'GO'}
                </Button>
              </div>
            </div>
            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center'>
                  <div className='text-base font-bold'>
                    광고 크레딧 충전하기
                    <Tooltip text='💡광고 크레딧을 충전하시면 신청해주신 광고가 진행됩니다.' />
                  </div>
                </div>
                <Button
                  href='/ad-credit'
                  appearance={
                    SellerQuest.questChecker('adQuest', 'chargeCredit')
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker('adQuest', 'chargeCredit')}
                >
                  {SellerQuest.questChecker('adQuest', 'chargeCredit')
                    ? '완료'
                    : 'GO'}
                </Button>
              </div>
            </div>

            <div>
              <div className='flex flex-row items-center justify-between flex-1 mb-3'>
                <div className='flex flex-row items-center'>
                  <div className='text-base font-bold'>
                    광고 상품 대량등록하기
                    <Tooltip text='💡광고 대량등록 기능을 활용해보세요.' />
                  </div>
                </div>
                <Button
                  href='/ad-apply'
                  appearance={
                    SellerQuest.questChecker('adQuest', 'adBatchModal')
                      ? 'disabled'
                      : 'positiveSub'
                  }
                  disabled={SellerQuest.questChecker('adQuest', 'adBatchModal')}
                >
                  {SellerQuest.questChecker('adQuest', 'adBatchModal')
                    ? '완료'
                    : 'GO'}
                </Button>
              </div>
            </div>
            <div className='flex flex-row justify-center flex-1 w-full'>
              <Modal
                modalTitle='광고 퀘스트를 완료하셨습니다'
                imageSource='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/a0005d3d-0035-4b20-a83a-8430227ea5be.jpeg'
                modalContent={
                  <>
                    <div className='items-center mb-3 text-lg font-bold'>
                      축하드립니다!
                    </div>
                    <div className='items-center mb-3 '>
                      올웨이즈에서는 적은 마진폭으로도 진행 가능한{' '}
                      <span className='font-bold text-positive-500'>
                        효율 높은 광고
                      </span>
                      를 제공드리고자 합니다. 신청된 광고는 검색 지면 외에도
                      여러 지면에 자동으로 노출되며, 광고비는 판매시에만
                      청구됩니다.
                    </div>
                    <div className='items-center mb-3 '>
                      현재 진행되고 있는 CPS 광고는{' '}
                      <span className='font-bold text-positive-500'>
                        ROAS 보장형 광고
                      </span>
                      입니다. 판매시에만 광고비가 청구되며, 광고비가 미리 정해져
                      있는 방식이기 때문에 안전하게 광고를 집행하실 수 있으며
                      높은 등급의 상품은 더 낮은 가격으로 광고를 집행하실 수
                      있습니다.
                    </div>
                    <div className='items-center mb-3 '>
                      올웨이즈를 이용해 주셔서 감사드립니다.
                    </div>
                  </>
                }
                modalLog='itemRankTutorialButton'
                isButton
                buttonText='광고지원금 30,000원 받기'
                buttonCallbackFunction={async () => {
                  if (
                    SellerQuest.questProgressChecker(
                      'adQuest',
                      adQuestStages,
                    ) === 100 &&
                    !SellerQuest.rewardChecker('adQuest')
                  ) {
                    await backendApis.addFreeAdCreditForQuest('adQuest')
                    await SellerStore.loadSellerInfo()
                  }
                }}
                appearance={
                  SellerQuest.questProgressChecker('adQuest', adQuestStages) ===
                    100 && !SellerQuest.rewardChecker('adQuest')
                    ? 'positive'
                    : 'disabled'
                }
                size='flexlg'
                disabled={
                  SellerQuest.questProgressChecker('adQuest', adQuestStages) !==
                    100 || SellerQuest.rewardChecker('adQuest')
                }
              >
                <span className='flex flex-row items-center justify-center flex-1'>
                  광고 지원금 받기
                </span>
              </Modal>
            </div>
          </div>
        ) : (
          <Button
            appearance='transparent'
            onClick={() => {
              setOpenModal(true)
            }}
          >
            <div className='flex flex-row items-center justify-between flex-1 px-5 py-3 text-black bg-white shadow-xl w-80 rounded-xl'>
              <div className='flex flex-row items-center'>
                <AdIcon className='mr-2' />
                광고 퀘스트 보기
              </div>
              <ArrowDownIcon className='ml-2' />
            </div>
          </Button>
        )}
      </>
    )
  },
)
export default AdQuest

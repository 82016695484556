import React, { useEffect, useState } from 'react'
import commaNumber from 'comma-number'
import backendApis from '../../utils/backendApis'
import Table from '../ADS/molecules/table'
import Pagination from './pagination'
import Badge from '../ADS/atoms/badge'

const AdConsumeLogTable = ({ dateFilter }) => {
  const limit = 10
  const [page, setPage] = useState(1)
  const [logData, setLogData] = useState([])
  const [logsCount, setLogsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const typeTranslator = (type) => {
    if (type === 'purchase') {
      return (
        <Badge
          text='판매'
          color='indigo'
          isTooltip
          tooltipText='판매 보장 광고의 주문으로 차감된 내역입니다.<br/>광고 기여도가 낮으면 크레딧이 차감되지 않으며 소진금액이 0원으로 기록됩니다.'
        />
      )
    }
    if (type === 'cancel') {
      return (
        <Badge
          text='취소'
          color='gray'
          isTooltip
          tooltipText='판매 보장 광고의 주문이 취소되면, 차감되었던 크레딧을 재지급드리며 마이너스 소진금액으로 기록됩니다.'
        />
      )
    }
    if (type === 'click') {
      return (
        <Badge
          text='클릭'
          color='purple'
          isTooltip
          tooltipText='파워 퍼포먼스 광고의 클릭으로 차감된 내역입니다.'
        />
      )
    }
  }

  const getAdCreditUsageLogInfo = async (limit, page, dateFilter) => {
    const count = await backendApis.getAdUsageLogCount(
      dateFilter?.startDate || '',
      dateFilter?.endDate || '',
    )
    if (count?.status === 200) {
      setLogsCount(count?.data)
    } else setLogsCount(0)

    const result = await backendApis.getAdUsageLogInfo(
      limit,
      page,
      dateFilter?.startDate || '',
      dateFilter?.endDate || '',
    )
    if (result?.status === 200) {
      const creditLog = result?.data.map((e, idx) => {
        return {
          index: (page - 1) * limit + idx + 1,
          itemId: e?.itemId,
          itemPrice: e?.itemPrice ? `${commaNumber(e.itemPrice)}` : '-',
          adCost: `${commaNumber(
            (e?.detailInfo?.paidCredit || 0) +
              (e?.detailInfo?.supportCredit || 0),
          )}`,
          paidAdCost: `${commaNumber(e?.detailInfo?.paidCredit || 0)}`,
          freeAdCost: `${commaNumber(e?.detailInfo?.supportCredit || 0)}`,
          type: typeTranslator(e?.type),
          createdAt: new Date(e?.createdAt).toLocaleString(),
        }
      })
      setLogData(creditLog)
    } else {
      setLogData([])
      setPage(1)
      window.alert(
        '소진 내역을 불러오는 중 문제가 발생했습니다. 새로고침 후 다시 시도해주세요.',
      )
    }
  }

  useEffect(() => {
    if (isLoading) return
    setIsLoading(true)
    getAdCreditUsageLogInfo(limit, page, dateFilter)
    setIsLoading(false)
  }, [page, dateFilter])

  const columns = [
    { Header: '번호', accessor: 'index' },
    { Header: '상품ID', accessor: 'itemId' },
    { Header: '판매가', accessor: 'itemPrice' },
    { Header: '소진(유상)', accessor: 'paidAdCost' },
    { Header: '소진(무상)', accessor: 'freeAdCost' },
    { Header: '소진금액', accessor: 'adCost' },
    { Header: '유형', accessor: 'type' },
    { Header: '소진일시', accessor: 'createdAt' },
  ]

  return (
    <div className='flex flex-col flex-1 w-full'>
      <Table columns={columns} data={logData} />
      <Pagination
        total={logsCount}
        limit={limit}
        page={page}
        setPage={setPage}
        size='md'
      />
    </div>
  )
}
export default AdConsumeLogTable

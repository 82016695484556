import { useState } from 'react'
import Button from '../atoms/button'
import moment from 'moment'
import backendApis from '../../utils/backendApis'

const ItemQuestionAnswer = ({ answerInfo, questionId }) => {
  const [isOpenModifyAnswer, setIsOpenModifyAnswer] = useState(false)
  const [textForModify, setTextForModify] = useState(answerInfo?.text || '')
  const [answer, setAnswer] = useState(answerInfo?.text || '')

  const modifyAnswer = async () => {
    try {
      const result = await backendApis.modifyItemQuestion({
        questionId,
        text: textForModify,
      })

      if (!result.data.success) {
        alert(result.data.msg)
        return
      }

      setAnswer(textForModify)
      setIsOpenModifyAnswer(false)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <div className='rounded-b-md text-base p-2 bg-slate-50'>
        <div className='flex flex-col px-1 space-y-1.5'>
          <div className='flex justify-start font-bold'>
            {answerInfo.sellerName ? answerInfo.sellerName : '판매자 답변'}
          </div>
          <div className='flex justify-start text-left whitespace-normal'>
            {answer}
          </div>
          <div className='flex items-end text-sm text-slate-400'>
            <div className='w-5/6 text-left'>
              {`${moment(answerInfo?.createdAt)?.format(
                'YYYY-MM-DD',
              )}  ${moment(answerInfo?.createdAt)?.format('HH:mm:ss')}`}
            </div>
            <div className='flex justify-end w-1/6'>
              <button
                type='button'
                className='w-5/6'
                onClick={async () => {
                  setIsOpenModifyAnswer((prev) => !prev)
                }}
              >
                <div className='p-1.5 font-bold text-xs rounded-sm border border-solid border-slate-600  text-slate-600 hover:bg-neutral-100'>
                  {isOpenModifyAnswer ? '답변 수정 중' : '답변 수정하기'}
                </div>
              </button>
              <button
                type='button'
                className='w-5/6'
                onClick={async () => {
                  await navigator.clipboard.writeText(`${answerInfo?.text}`)
                  alert(`답변내용이 복사되었습니다.`)
                }}
              >
                <div className='p-1.5 font-bold text-xs rounded-sm border border-solid border-slate-600  text-slate-600 hover:bg-neutral-100'>
                  답변내용 복사
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpenModifyAnswer && (
        <div>
          <div className='answerTextArea'>
            <textarea
              className='w-full h-40 border border-slate-200 rounded-lg'
              placeholder='수정할 답변을 입력해주세요.'
              value={textForModify}
              onChange={(e) => {
                setTextForModify(e.target.value)
              }}
            />
          </div>
          <div className='flex justify-end'>
            <Button appearance='positive' size='lg' onClick={modifyAnswer}>
              답변 수정
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ItemQuestionAnswer

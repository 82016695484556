import backendApis from '../backendApis'
import BusinessInfoParser from './businessInfoParser'

const validateMailOrderSalesNumber = async () => {
  try {
    const loadSeller = await backendApis.loadSellerBusinessRegistrationInfo()
    const sellerInfos = {
      sellerId: loadSeller?.data?.sellerId,
      inputMailOrderSalesRegistrationNumber:
        loadSeller?.data?.mailOrderSalesRegistrationNumber,
      businessRegistrationNumber:
        BusinessInfoParser.parseBusinessRegistrationNumber(
          loadSeller?.data?.businessRegistrationNumber,
        ),
    }

    const checkResult =
      await backendApis.validateMailOrderSalesRegistrationNumber(
        sellerInfos?.businessRegistrationNumber,
      )

    if (checkResult?.status === 200) {
      if (
        checkResult?.data?.success &&
        checkResult?.data?.resultCode === '00' &&
        checkResult?.data?.totalCount === 1 &&
        checkResult?.data?.items &&
        checkResult?.data?.items?.operSttusCdNm === '정상영업' && // operation status 운영 상태
        checkResult?.data?.items?.prmmiMnno // 통신판매업신고번호
      ) {
        const cleandBusinessNumber =
          sellerInfos?.inputMailOrderSalesRegistrationNumber
            .replace(/[^0-9a-zA-Z가-힣]/g, '')
            .replace(/[제호]/g, '')
        const cleandPrmmiMnno = checkResult?.data?.items?.prmmiMnno
          .replace(/[^0-9a-zA-Z가-힣]/g, '')
          .replace(/[제호]/g, '')

        if (cleandBusinessNumber !== cleandPrmmiMnno) {
          return {
            status: 400,
            failFlag: true,
            message:
              '등록하신 통신판매업신고번호가 공정거래위원회 통신판매사업자 등록현황과 일치하지 않습니다. 다시 확인해 주세요.',
          }
        }

        // 입력한 통신판매업신고번호 === 공정거래위원회 통신판매업신고번호가 일치하는 경우
        const updateMailOrderSalesData = {
          rprsvNm: checkResult?.data?.items?.rprsvNm || '', // 대표자명
          rprsvEmladr: checkResult?.data?.items?.rprsvEmladr || '', // 대표자 이메일
          rnAddr: checkResult?.data?.items?.rnAddr || '', // 상호/법인 도로명 주소
          lctnAddr: checkResult?.data?.items?.lctnAddr || '', // 상호(법인) 소재지 주소
          prmmiMnno: checkResult?.data?.items?.prmmiMnno || '', // 통신판매업신고번호(인허가관리번호)
          prmmiYr: checkResult?.data?.items?.prmmiYr || '', // 통신판매업신고년도(인허가년도)
          brno: checkResult?.data?.items?.brno || '', // 사업자등록번호
          bzmnNm: checkResult?.data?.items?.bzmnNm || '', // 상호(법인)명
          crno: checkResult?.data?.items?.crno || '', // 법인등록번호
          operSttusCdNm: checkResult?.data?.items?.operSttusCdNm || '', // 운영 상태
          dclrDate: checkResult?.data?.items?.dclrDate || '', // 신고일자
        }

        const updateResult =
          await backendApis.updateSellerMailOrderSalesNumberValidity(
            sellerInfos?.sellerId,
            updateMailOrderSalesData,
          )

        if (updateResult?.status === 200) {
          return {
            status: 200,
            message: '통신판매업신고번호 검증에 성공했습니다.',
            data: updateMailOrderSalesData,
          }
        }
        return {
          status: 400,
          message:
            '통신판매검증에 성공했으나 업데이트 과정에서 오류가 발생했습니다. 재시도 부탁드립니다.',
        }
      }
      return {
        status: 400,
        failFlag: true,
        message: '통신판매업신고번호 검증에 실패했습니다. 다시 시도해 주세요.',
      }
    }
    return {
      status: 400,
      message:
        '통신판매업신고번호 검증 시도 중 오류가 발생했습니다. 다시 시도해 주세요.',
    }
  } catch (error) {
    console.error('Mail order sales number validation error:', error)
    return {
      status: 400,
      message: error.message || '검증 시도 중 오류가 발생했습니다.',
      error,
    }
  }
}

export default validateMailOrderSalesNumber

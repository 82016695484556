import React from 'react'
import HTMLRenderer from 'react-html-renderer'

const HtmlViewer = ({ html }) => {
  return (
    <div className='w-full'>
      <HTMLRenderer html={html} />
    </div>
  )
}
export default HtmlViewer

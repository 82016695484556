import React, { useEffect, useState } from 'react'

const SelectableCell = ({
  value,
  options = [], // {value: ###, text: ###}
  row,
  column,
  onChange = () => {}, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  return (
    <select
      value={value}
      onChange={onChange}
      className='border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1'
    >
      {options?.map((option) => (
        <option key={option?.value + option?.text} value={option?.value}>
          {option?.text}
        </option>
      ))}
    </select>
  )
}

export default SelectableCell

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../../stores/OrderStore'
import { BrowserRouter as Router, Link } from 'react-router-dom'

const ArrivedNavigator = observer(() => {
  const [isOnMouse, setIsOnMouse] = useState(false)
  return (
    <button
      className=' w-1/5 border-gray-700 border-2 rounded-md ml-3 hover:bg-gray-200 text-left bg-white'
      type='button'
      onClick={async () => {
        document.getElementById('arrivedOrders').scrollIntoView()
      }}
      onMouseEnter={() => {
        setIsOnMouse(true)
      }}
      onMouseLeave={() => {
        setIsOnMouse(false)
      }}
    >
      <div style={{ margin: 20 }}>
        <h2 style={{ fontSize: '20' }}>
          배송완료
          <br />
          (고객 수령 완료)
        </h2>
        <h2 className='mt-3 text-2xl font-bold'>
          {OrderStore.ordersNumPerStatus.arrived || 0}
        </h2>
      </div>
    </button>
  )
})

export default ArrivedNavigator

export default function ToggleInput({
  state,
  setState,
  className,
  turnOffMessage = false,
  turnOnMessage = false,
  callbackFunction = async () => {},
  color = 'positive',
}) {
  const colorByType = {
    positiveSub: 'peer-checked:bg-emerald-600',
    positive: 'peer-checked:bg-positive-600',
  }
  return (
    <div className={`${className} flex flex-row items-center select-none`}>
      <label className='relative inline-flex items-center cursor-pointer'>
        <input
          type='checkbox'
          onChange={async (e) => {
            if (!e.target.checked) {
              if (turnOffMessage) {
                if (window.confirm(turnOffMessage)) {
                  setState(e.target.checked)
                  await callbackFunction(e.target.checked)
                }
                return
              }
              setState(!state)
              await callbackFunction(e.target.checked)
              return
            }
            if (e.target.checked) {
              if (turnOnMessage) {
                if (window.confirm(turnOnMessage)) {
                  setState(e.target.checked)
                  await callbackFunction(e.target.checked)
                }
                return
              }
              setState(e.target.checked)
              await callbackFunction(e.target.checked)
              return
            }

            setState(e.target.checked)
            await callbackFunction(e.target.checked)
          }}
          // id='red-toggle'
          className='sr-only peer'
          checked={state}
        />
        <div
          className={`w-11 h-6 bg-gray-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${colorByType[color]}`}
        />
      </label>
    </div>
  )
}

import { useState } from 'react'
import Button from './button'
import Format from '../../utils/format'
import shortUUID from 'short-uuid'
import SelectableCell from './selectableCell'
import OrderStore from '../../stores/OrderStore'
import UploadRefundingNumberButton from '../buttons/UploadRefundingNumberButton'

export default function ModalUploadShippingInfo({
  orderData,
  orders,
  setOrders,
  orderId,
  imageSource,
  isButton = false,
  buttonText,
  buttonLink,
  buttonCallbackFunction = () => {},
  modalOn = false,
  setShowModal,
}) {
  const [shippingCompanyCode, setShippingCompanyCode] = useState('')
  const [shippingNumber, setShippingNumber] = useState('')
  return (
    <>
      {modalOn ? (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
            <div className='relative w-full max-w-3xl mx-auto my-6'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <h3 className='text-xl font-semibold text-black'>
                    운송장 업로드
                  </h3>
                </div>
                {/* body */}
                <div className='relative flex-auto px-10 py-10'>
                  {imageSource && (
                    <div className='mb-5 text-lg leading-relaxed text-sub-500'>
                      <img alt='' src={imageSource} />
                    </div>
                  )}
                  {/* <Card title='고객 주문 정보'> */}
                  <table className='mb-50'>
                    <thead>
                      <tr>
                        <th scope='col' colSpan={2} className='text-base'>
                          운송장 정보 입력
                        </th>
                      </tr>
                    </thead>
                    <tbody className='items-center mt-3'>
                      <tr>
                        <td className='text-lg text-center'>택배사</td>
                        <td className='text-lg text-center'>
                          {' '}
                          <SelectableCell
                            value={shippingCompanyCode}
                            options={[
                              { value: '', text: '===' },
                              ...(OrderStore.shippingCompaniesInfo?.map(
                                (shippingCompanyInfo) => ({
                                  value:
                                    shippingCompanyInfo.shippingCompanyCode,
                                  text: shippingCompanyInfo.shippingCompanyName,
                                }),
                              ) || []),
                            ]}
                            onChange={(e) => {
                              setShippingCompanyCode(e.target.value)
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='text-lg text-center'>운송장 번호</td>
                        <td className='text-lg text-start'>
                          <input
                            type='text'
                            onChange={(e) => {
                              setShippingNumber(e.target.value)
                            }}
                            placeholder='운송장 번호'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='text-lg text-center'>운송장 업로드</td>
                        <td className='text-lg text-start'>
                          <UploadRefundingNumberButton
                            order={orderData}
                            setOrders={setOrders}
                            orders={orders}
                            shippingNumber={shippingNumber}
                            shippingCompanyCode={shippingCompanyCode}
                            setShowModal={setShowModal}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* footer */}
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    닫기
                  </button>
                  {isButton ? (
                    <Button
                      href={buttonLink}
                      appearance='positive'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        buttonCallbackFunction()
                        setShowModal(false)
                      }}
                    >
                      {buttonText}
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      ) : null}
    </>
  )
}

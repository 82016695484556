import React, { useState, useEffect } from 'react'
import SellerStore from '../stores/SellerStore'
import LureDealApplicationModal from '../components/ItemComponents/LureDealApplicationModal'
import useLureDeal from '../hooks/useLureDeal'
import ItemComponent from '../components/ItemComponents/ItemComponent'
import Page from '../components/atoms/page'
import Pagination from '../components/molecules/pagination'
import ItemStore from '../stores/ItemStore'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import { FcPrivacy as TitleIcon } from 'react-icons/fc'
import PromotionSuggestion from '../components/atoms/promotionSuggestion'
import { useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'

function LureDealApplicationScreen() {
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [itemInfo, setItemInfo] = useState({})
  const dealType = 'lureDeal'
  const [itemIdsArray, setItemIdsArray] = useState([])
  const [applyStatusTab, setApplyStatusTab] = useState('candidate')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const instantApplyItemInfo = location.state
  const offset = (page - 1) * limit
  const itemIds = SellerStore?.sellerInfo?.itemIds
  const { data, setData } = useLureDeal({
    type: dealType,
    itemsInfo: itemIdsArray,
  })

  useEffect(() => {
    const fetchData = async () => {
      const projectCondition = {
        _id: 1,
      }
      const result = await backendApis.loadItemSpecificInfo(
        itemIds,
        projectCondition,
      )
      if (result?.status === 200) {
        setItemIdsArray(result?.data)
      }
    }
    fetchData()
  }, [applyStatusTab])

  useEffect(() => {
    if (
      instantApplyItemInfo &&
      data
        ?.filter(
          (item) =>
            !item?.soldOut &&
            item?.dealType === dealType &&
            item?.status === 'candidate',
        )
        ?.some((item) => item?._id === instantApplyItemInfo?._id)
    ) {
      setItemInfo(instantApplyItemInfo)
      setShowModal(true)
    }
  }, [data])

  //
  const CandidateItemComponent = ({ data, offset, limit, dealType }) => {
    return (
      <>
        <div>
          {data
            .filter(
              (item) =>
                !item?.soldOut &&
                item?.dealType === dealType &&
                item?.status === 'candidate',
            )
            .slice(offset, offset + limit)
            .map((itemInfo, index) => (
              <ItemComponent
                key={itemInfo?._id + index.toString()}
                itemInfo={itemInfo}
                dealRequest={(itemInfo) => {
                  if (itemInfo?.status === 'accepted') {
                    alert(
                      '순삭특가는 모든유저에게 앱푸시가 나가기에, 임의로 수정할 수 없습니다. \n 담당자에게 연락주세요 010-2293-3803',
                    )
                  } else {
                    setItemInfo(itemInfo)
                    setShowModal(true)
                  }
                }}
                dealCancelRequest={async (itemInfo) => {
                  if (itemInfo.status === 'accepted') {
                    alert(
                      '순삭특가는 모든유저에게 앱푸시가 나가기에, 임의로 수정할 수 없습니다. \n 담당자에게 연락주세요 010-2293-3803',
                    )
                  }
                }}
                isRankItem
                dealType='lureDeal'
                dealApplyInfo={
                  ['lureDeal'].includes(itemInfo?.dealType) &&
                  itemInfo?.status !== 'candidate'
                }
              />
            ))}
        </div>
        <div>
          {data.filter(
            (item) =>
              !item?.testingSkuGroup &&
              !item?.soldOut &&
              ['lureDeal'].includes(item?.dealType) &&
              item?.status === 'candidate',
          ) ? (
            <>
              <div>
                <PromotionSuggestion
                  ranklimit='B'
                  name='베이직 쿠폰'
                  purpose='일반 상품 할인으로 상품 전환율 향상'
                  space='모든 상품페이지'
                  time='상시'
                  settlement='고객이 쿠폰 사용시 정산금 차감'
                  minDiscountInfo='최소 500원'
                  etc={
                    <ol className='ml-5 list-disc'>
                      <li>
                        쿠폰 마케팅, 타임특가 등
                        <br />
                        <span className='font-bold text-positive-500'>
                          프로모션 적극 신청
                        </span>
                        을 추천드립니다.
                      </li>
                      <li>
                        <span className='font-bold text-positive-500'>
                          안정적인 매출이 중요합니다.
                        </span>
                        상시 판매가 및 후기관리도 중요합니다.
                      </li>
                    </ol>
                  }
                  images={[
                    'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/4a0444c4-66fd-4c6a-beef-82c19e61d6e0.png',
                    'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d4fa194c-c2dd-4f62-8af4-2b71f9dd38a6.png',
                  ]}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          <Pagination
            total={
              data.filter(
                (item) =>
                  !item?.testingSkuGroup &&
                  !item?.soldOut &&
                  ['lureDeal'].includes(item?.dealType) &&
                  item?.status === 'candidate',
              ).length
            }
            limit={limit}
            page={page}
            setPage={setPage}
            size='lg'
          />
        </div>
      </>
    )
  }

  const CompleteItemComponent = ({ data, offset, limit }) => {
    return (
      <>
        <div>
          {data
            .filter(
              (item) =>
                !item?.testingSkuGroup &&
                !item?.soldOut &&
                ['lureDeal'].includes(item?.dealType) &&
                item?.status !== 'candidate',
            )
            .slice(offset, offset + limit)
            .map((itemInfo, index) => (
              <ItemComponent
                key={itemInfo?._id + index.toString() + 2}
                itemInfo={itemInfo}
                dealRequest={(itemInfo) => {
                  if (itemInfo.status === 'accepted') {
                    alert(
                      '순삭특가는 모든유저에게 앱푸시가 나가기에, 임의로 수정할 수 없습니다. \n 담당자에게 연락주세요 010-2293-3803',
                    )
                  } else {
                    setItemInfo(itemInfo)
                    setShowModal(true)
                  }
                }}
                dealCancelRequest={async (itemInfo) => {
                  if (itemInfo.status === 'accepted') {
                    alert(
                      '순삭특가는 모든유저에게 앱푸시가 나가기에, 임의로 수정할 수 없습니다. \n 담당자에게 연락주세요 010-2293-3803',
                    )
                  }
                }}
                isRankItem
                dealType={itemInfo?.dealType}
                dealRankLimit='B'
                dealApplyInfo={
                  ['lureDeal'].includes(itemInfo?.dealType) &&
                  itemInfo?.status !== 'candidate'
                }
              />
            ))}
        </div>
        <div>
          <Pagination
            total={
              data.filter(
                (item) =>
                  !item?.testingSkuGroup &&
                  !item?.soldOut &&
                  item?.dealOptionsInfo,
              ).length
            }
            limit={limit}
            page={page}
            setPage={setPage}
            size='lg'
          />
        </div>
      </>
    )
  }

  const RenderTabContent = ({ tab, data, offset, limit, dealType }) => {
    const tabComponents = {
      candidate: (
        <CandidateItemComponent
          data={data}
          offset={offset}
          limit={limit}
          dealType={dealType}
        />
      ),
      complete: (
        <CompleteItemComponent
          data={data}
          offset={offset}
          limit={limit}
          dealType={dealType}
        />
      ),
    }
    return tabComponents[tab] || null
  }

  return (
    <div className='max-w-7xl'>
      <Page title='순삭특가 신청' Icon={TitleIcon}>
        <div className='px-10 py-10 bg-white shadow-md rounded-xl'>
          <div className='flex flex-row justify-between w-full'>
            <div className='mb-5 text-2xl font-bold '>순삭 특가 신청 안내</div>
          </div>
          <div style={{ height: 10 }} />
          <li className='mt-5 text-base'>
            순삭특가는 올웨이즈의 대표 매대입니다. 500만 유저에게 일괄 앱푸시가
            나갑니다.
          </li>
          <li className='text-base'>
            매주 수~목요일에만 신청가능하며,
            <span className='font-bold'>
              올웨이즈가 판매량, 리뷰 등 내부 데이터를 통하여 매주 후보로 선정한
              제품에 한하여 연락을 드립니다.
            </span>
          </li>
          <li className='text-base'>
            매주 선정기준이 바뀌기 때문에, 한 주에 후보로 지정되어도
            <span className='font-bold'>
              {' '}
              다음주엔 후보가 아닐 수 있습니다.
            </span>
          </li>
          <li className='text-base'>
            순삭특가 선정 시, 판매자 센터에 등록된 담당자 번호로 문자 안내하고
            있습니다. 문자 수신 및 담당자 번호로 정보 수정 부탁드립니다.
          </li>
        </div>

        <div>
          <div className='flex items-center justify-between flex-1 mt-10 mb-5'>
            <div className='mb-5 '>
              <button
                type='button'
                onClick={() => {
                  setApplyStatusTab('candidate')
                  setPage(1)
                }}
                className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
                  applyStatusTab === 'candidate'
                    ? 'border-b-4 border-positive-500 text-black'
                    : 'text-gray-400'
                }`}
              >
                신청 대상 상품
              </button>
              <button
                type='button'
                onClick={() => {
                  setApplyStatusTab('complete')
                  setPage(1)
                }}
                className={`select-none text-xl pb-1 mr-5 font-bold duration-200 ease-in-out ${
                  applyStatusTab === 'complete'
                    ? 'border-b-4 border-positive-500 text-black'
                    : 'text-gray-400'
                } w-max`}
              >
                신청 완료 상품
              </button>
            </div>
            <div>
              <ListLimitSelector limit={limit} setLimit={setLimit} />
            </div>
          </div>
          <RenderTabContent
            tab={applyStatusTab}
            data={data}
            offset={offset}
            limit={limit}
            dealType={dealType}
          />
        </div>
        <LureDealApplicationModal
          data={data}
          setData={setData}
          showModal={showModal}
          setShowModal={setShowModal}
          itemInfo={itemInfo}
          setItemInfo={setItemInfo}
        />
      </Page>
    </div>
  )
}

export default LureDealApplicationScreen

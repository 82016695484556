import React from 'react'
import { observer } from 'mobx-react-lite'
import TeamGatheringOrders from '../components/TeamGatheringOrders'
import PreShippingOrders from '../components/PreShippingOrders'
import CancelingShippingOrders from '../components/CancelingShippingOrders'
import CancelingPreShippingOrders from '../components/CancelingPreShippingOrders'
import ShippingOrders from '../components/ShippingOrders'
import SendingOrders from '../components/SendingOrders'
import ArrivedOrders from '../components/ArrivedOrders'
import CanceledShippingOrders from '../components/CanceledShippingOrders'
import CanceledPreShippingOrders from '../components/CanceledPreShippingOrders'
import OrderStore from '../stores/OrderStore'
import PreShippingOrdersAfterExcel from '../components/PreShippingOrdersAfterExcel'
import CancelingPreShippingOrdersAfterExcel from '../components/CancelingPreShippingOrdersAfterExcel'
import QualityNoticeComponent from '../components/QualityNoticeComponent'
import Title from '../components/ADS/atoms/title'
import Tabs from '../components/ADS/molecules/tabs'
import SubTitle from '../components/ADS/atoms/subTitle'
import Divider from '../components/ADS/atoms/divider'
import dayjs from 'dayjs'

const OrdersScreen = observer(() => {
  const tabContArr = [
    {
      title: `팀 모집중 (${(
        OrderStore.ordersNumPerStatus['team-gathering'] || 0
      ).toLocaleString()})`,
      content: (
        <>
          <div className='w-[80vw] p-5 mb-5'>
            <TeamGatheringOrders
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'team-gathering' &&
                  dayjs(order.payedAt).isAfter(dayjs().add(-365, 'day')),
              )}
            />
          </div>
        </>
      ),
    },
    {
      title: `결제 완료 & 출고 대기 (${(
        (OrderStore.ordersNumPerStatus['pre-shipping-pre-excel'] || 0) +
        (OrderStore.ordersNumPerStatus['pre-shipping-post-excel'] || 0)
      ).toLocaleString()})`,
      content: (
        <>
          <div className='w-[80vw] p-5 mb-5'>
            <PreShippingOrders
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'pre-shipping' &&
                  !order.excelDownloadedAt &&
                  !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
              )}
            />
          </div>
          <Divider />
          <div className='w-[80vw] p-5 mb-5'>
            <PreShippingOrdersAfterExcel
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'pre-shipping' &&
                  order.excelDownloadedAt &&
                  !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
              )}
            />
          </div>
        </>
      ),
    },
    {
      title: `배송중 (${(
        (OrderStore.ordersNumPerStatus.shipping || 0) +
        (OrderStore.ordersNumPerStatus['shipping-sending'] || 0)
      ).toLocaleString()})`,
      content: (
        <>
          <div className='w-[80vw] p-5 mb-5'>
            <SendingOrders
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'shipping' &&
                  !order?.shippingInfo?.trackingDetail &&
                  !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
              )}
            />
          </div>
          <Divider />
          <div className='w-[80vw] p-5 mb-5'>
            <ShippingOrders
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'shipping' &&
                  order?.shippingInfo?.trackingDetail &&
                  !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
              )}
            />
          </div>
        </>
      ),
    },
    {
      title: `배송 완료 (${(
        OrderStore.ordersNumPerStatus.arrived || 0
      ).toLocaleString()})`,
      content: (
        <>
          <div className='w-[80vw] p-5 mb-5'>
            <ArrivedOrders
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'arrived' || order.status === 'reviewed',
              )}
            />
          </div>
        </>
      ),
    },
    {
      title: `취소 (${(
        (OrderStore.ordersNumPerStatus['canceling-pre-shipping-pre-excel'] ||
          0) +
        (OrderStore.ordersNumPerStatus['canceling-pre-shipping-post-excel'] ||
          0) +
        (OrderStore.ordersNumPerStatus['canceled-pre-shipping'] || 0) +
        (OrderStore.ordersNumPerStatus['canceling-shipping'] || 0) +
        (OrderStore.ordersNumPerStatus['canceled-shipping'] || 0)
      ).toLocaleString()})`,
      content: (
        <>
          <div className='w-[80vw] p-5 mb-5'>
            <SubTitle
              text={`상품준비중 취소 요청 (${(
                OrderStore.ordersNumPerStatus[
                  'canceling-pre-shipping-pre-excel'
                ] || 0
              ).toLocaleString()})`}
            />
            <CancelingPreShippingOrders
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'canceling-pre-shipping' &&
                  !order?.excelDownloadedAt &&
                  !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
              )}
            />
          </div>
          <Divider />
          <div className='w-[80vw] p-5 mb-5'>
            <SubTitle
              text={`출고중지 요청 (${(
                OrderStore.ordersNumPerStatus[
                  'canceling-pre-shipping-post-excel'
                ] || 0
              ).toLocaleString()})`}
            />
            <CancelingPreShippingOrdersAfterExcel
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'canceling-pre-shipping' &&
                  order?.excelDownloadedAt &&
                  !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
              )}
            />
          </div>
          <Divider />
          <div className='w-[80vw] p-5 mb-5'>
            <SubTitle
              text={`배송 이전 출고 중지 완료 (${(
                OrderStore.ordersNumPerStatus['canceled-pre-shipping'] || 0
              ).toLocaleString()})`}
            />
            <CanceledPreShippingOrders
              orders={OrderStore.displayedOrders.filter(
                (order) => order.status === 'canceled-pre-shipping',
              )}
            />
          </div>
          <Divider />
          <div className='w-[80vw] p-5 mb-5'>
            <SubTitle
              text={`배송중인 상품 배송중지 요청 (${(
                OrderStore.ordersNumPerStatus['canceling-shipping'] || 0
              ).toLocaleString()})`}
            />
            <CancelingShippingOrders
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'canceling-shipping' &&
                  !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
              )}
            />
          </div>
          <Divider />
          <div className='w-[80vw] p-5 mb-5'>
            <SubTitle
              text={`배송중인 상품 배송중지 완료 (${(
                OrderStore.ordersNumPerStatus['canceled-shipping'] || 0
              ).toLocaleString()})`}
            />
            <CanceledShippingOrders
              orders={OrderStore.displayedOrders.filter(
                (order) =>
                  order.status === 'canceled-shipping' &&
                  !order.itemInfo?.characteristicItem?.isBaroArriveRegistered,
              )}
            />
          </div>
        </>
      ),
    },
  ]

  return (
    <>
      <Title
        text={`주문 조회 (${(
          (OrderStore.ordersNumPerStatus['team-gathering'] || 0) +
          (OrderStore.ordersNumPerStatus['pre-shipping-pre-excel'] || 0) +
          (OrderStore.ordersNumPerStatus['pre-shipping-post-excel'] || 0) +
          (OrderStore.ordersNumPerStatus['shipping-sending'] || 0) +
          (OrderStore.ordersNumPerStatus.shipping || 0) +
          (OrderStore.ordersNumPerStatus.arrived || 0) +
          (OrderStore.ordersNumPerStatus['canceling-pre-shipping-pre-excel'] ||
            0) +
          (OrderStore.ordersNumPerStatus['canceling-pre-shipping-post-excel'] ||
            0) +
          (OrderStore.ordersNumPerStatus['canceled-pre-shipping'] || 0) +
          (OrderStore.ordersNumPerStatus['canceling-shipping'] || 0) +
          (OrderStore.ordersNumPerStatus['canceled-shipping'] || 0) +
          (OrderStore.ordersNumPerStatus.refunding || 0) +
          (OrderStore.ordersNumPerStatus.refunded || 0)
        ).toLocaleString()})`}
      />
      <div className='shadow-lg rounded-2xl p-8 bg-white'>
        <QualityNoticeComponent />
      </div>
      <Tabs tabContArr={tabContArr} />
    </>
  )
})

export default OrdersScreen

import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'

export default observer(function PhotoReviewSettingModal({
  showModal,
  setShowModal,
}) {
  const setPhotoReviewUpdate = async (photoReviewCondition) => {
    const result = await backendApis.setPhotoReviewUpdate(photoReviewCondition)
    if (result.status === 200) {
      alert('사진 후기 수정이 완료되었습니다')
    } else {
      alert(
        '사진 후기 수정 과정에 문제가 생겼습니다. 판매자 지원센터에 문의해주세요',
      )
    }
  }
  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex px-2 overflow-x-hidden overflow-y-auto items-center justify-center outline-none'>
            <div className='flex-col relative  w-full max-w-xl mx-auto my-6 shadow-lg'>
              <div className='flex flex-col flex-1'>
                {/* content */}
                <div className='relative flex flex-col w-full bg-white border-0 outline-none'>
                  {/* header */}
                  <div className='flex  p-5 border-b border-solid border-slate-200'>
                    <h3 className='text-xl font-semibold text-black'>
                      사진 후기 전 상품 수정
                    </h3>
                  </div>
                  {/* body */}
                  <div className='relative flex flex-col  px-10 py-10'>
                    <div />
                    <div className='mb-5 text-center'>
                      <ol className='font-normal whitespace-normal list-decimal'>
                        <li>
                          상품의 빠른 판매량 증진을 위해서는 사진후기의 양이
                          많은 것이 중요하다는 사실을 파악했습니다.
                          <span className='font-bold text-emerald-500'>
                            {' '}
                            이를 위해 올웨이즈가 도와드립니다.
                          </span>
                        </li>
                        <li>
                          이벤트는 올팜 내에서 진행되며 고객님께서{' '}
                          <span className='font-bold text-emerald-500'>
                            상품 후기가 40개 이하인 제품
                          </span>{' '}
                          에 대해 사진 후기를 남기면 일정양의 재화를 받습니다.
                        </li>
                        <li>
                          사진후기 1개 당 정산에서 300원이 차감되며{' '}
                          <span className='font-bold text-emerald-500'>
                            그 외 금액은 올웨이즈가 부담합니다
                          </span>
                        </li>
                        <li>
                          <span className='font-bold text-emerald-500'>
                            상품 후기가 40개를 넘어가면{' '}
                          </span>
                          신청이 되어 있더라도 자동으로 이벤트 대상에서
                          제외됩니다.{' '}
                        </li>
                      </ol>
                      <button
                        className='px-3 py-2 w-48 mt-10 mb-3 text-base font-bold text-white bg-blue-500 rounded-md w-1/7'
                        type='button'
                        onClick={() => setPhotoReviewUpdate('apply')}
                      >
                        전 상품 신청
                      </button>
                      <button
                        className='px-3 py-2 ml-2 w-48 mt-10 mb-3 text-base font-bold text-white bg-blue-500 rounded-md w-1/7'
                        type='button'
                        onClick={() => setPhotoReviewUpdate('stop')}
                      >
                        전 상품 해제
                      </button>
                    </div>
                  </div>
                </div>
                {/* footer */}
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})

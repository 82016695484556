import React from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import ReviewReplyComponent from '../components/ItemComponents/ReviewReplyComponent'

const SearchReviewReplyModal = observer(({ searchedReviews, setOpenModal }) => {
  const orderReviewLists = searchedReviews.map((review, index) => {
    return (
      <tr
        key={review._id + index.toString()}
        style={{ width: '100%', fontsize: 16 }}
      >
        <td>
          <div style={{ width: 8 }}>
            {moment(review?.createdAt).format('YYYY.MM.DD')}
          </div>
          <div style={{ width: 8 }}>
            {moment(review?.createdAt).format('HH:mm')}
          </div>
        </td>
        <td>
          <div
            style={{
              width: 120,
              whiteSpace: 'pre-line',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            주문번호: <br />
            {review?.orderId}
          </div>
          <div>
            -------
            <br /> 닉네임:
          </div>
          <div
            style={{
              width: 120,
              whiteSpace: 'pre-line',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {review?.userName}
          </div>
        </td>
        <td>
          <div
            style={{
              width: 100,
              whiteSpace: 'pre-line',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {review?.itemId}
          </div>
        </td>
        <td>
          <div style={{ whiteSpace: 'pre-line' }}>{review?.text}</div>
        </td>
        <ReviewReplyComponent
          key={review?._id}
          reviewInfo={review}
          index={index}
          setOpenModal={setOpenModal}
          replyInfo={review?.replyInfo}
        />
      </tr>
    )
  })
  return (
    <div style={{ width: '100%' }}>
      <table style={{ width: '100%' }}>
        <thead>
          <tr style={{ fontSize: 15 }}>
            <th style={{ width: 8 }}>후기 작성일시</th>
            <th style={{ width: 8 }}>주문번호/닉네임</th>
            <th style={{ width: 8 }}>아이템아이디</th>
            <th style={{ width: '50%' }}>후기내용</th>
            <th style={{ width: '40%' }}>답변내용</th>
            <th style={{ width: 16 }}>답변하기</th>
          </tr>
        </thead>
        {searchedReviews.length === 0 && (
          <tbody>
            <div
              style={{
                fontSize: 15,
                paddingLeft: 24,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              조회된 주문이 없습니다.
            </div>
          </tbody>
        )}
        {searchedReviews.length > 0 && (
          <tbody style={{ fontSize: 15 }}>{orderReviewLists}</tbody>
        )}
      </table>
    </div>
  )
})

export default SearchReviewReplyModal

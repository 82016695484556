import moment from 'moment'

const DateTimeSortingType = Object.freeze({
  NEWEST_CREATED_AT: 'newestCreatedAt',
  OLDEST_CREATED_AT: 'oldestCreatedAt',
  NEWEST_UPDATED_AT: 'newestUpdatedAt',
  OLDEST_UPDATED_AT: 'oldestUpdatedAt',
})

const DateTimeSortingComparator = Object.freeze({
  [DateTimeSortingType.NEWEST_CREATED_AT]: (a, b) => moment(b).diff(moment(a)),
  [DateTimeSortingType.OLDEST_CREATED_AT]: (a, b) => moment(a).diff(moment(b)),
  [DateTimeSortingType.NEWEST_UPDATED_AT]: (a, b) => moment(b).diff(moment(a)),
  [DateTimeSortingType.OLDEST_UPDATED_AT]: (a, b) => moment(a).diff(moment(b)),
})

export { DateTimeSortingType, DateTimeSortingComparator }

import React from 'react'
import { mean, median } from 'mathjs'

const cheapabilityTranslator = (cheapability) => {
  if (cheapability >= 1) return '비쌈'
  if (cheapability >= 0) return '조금 비쌈'
  if (cheapability <= -1) return '저렴'
  if (cheapability < 0) return '보통'
  return '정보없음'
}

const colorSetter = (cheapability) => {
  if (cheapability >= 1) return 'text-red-500'
  if (cheapability >= 0) return 'text-orange-500'
  if (cheapability <= -1) return 'text-blue-500'
  if (cheapability < 0) return 'text-green-500'
  return 'text-gray-500'
}

function modifiedZ(x, median, MAD, meanAD) {
  if (x === null) {
    return null
  }

  if (MAD === 0) {
    return (x - median) / (1.253314 * meanAD)
  }
  return (x - median) / (1.486 * MAD)
}

function calculateCheapability(priceList, teamPurchasePrice) {
  if (Array.isArray(priceList) && priceList.length > 0) {
    const meanPrice = mean(priceList)
    const medianVal = median(priceList)
    const meanAD = mean(priceList.map((x) => Math.abs(x - meanPrice)))
    const MAD = median(priceList.map((x) => Math.abs(x - medianVal)))
    const cheapability = modifiedZ(teamPurchasePrice, medianVal, MAD, meanAD)
    return cheapability
  }
  return null
}

function calculateRefPrice(priceList, cheapability = 0) {
  if (Array?.isArray(priceList) && priceList?.length > 0) {
    const medianVal = median(priceList)
    const MAD = median(priceList.map((x) => Math.abs(x - medianVal)))
    const coefficient = MAD === 0 ? 1.253314 : 1.486
    const refPrice = medianVal + cheapability * coefficient * MAD
    return refPrice
  }
  return null
}

function calculateOrderMultiplier(priceList, initialPrice, finalPrice) {
  if (
    Array?.isArray(priceList) &&
    priceList?.length > 0 &&
    Number.isSafeInteger(initialPrice) &&
    Number.isSafeInteger(finalPrice)
  ) {
    const ic = calculateCheapability(priceList, initialPrice)
    const fc = calculateCheapability(priceList, finalPrice)

    const impMultiplier =
      (fc * -413.98444957 + 2283.18) / (ic * -413.98444957 + 2283.18)
    let impOrderMultiplier =
      (fc * -1.91306858e-5 + 9.23442562e-5) /
      (ic * -1.91306858e-5 + 9.23442562e-5)

    if (fc <= -1 && ic > -1) {
      impOrderMultiplier *= 1.1
    }

    const multiplier = impMultiplier * impOrderMultiplier

    if (multiplier < 1) {
      return 1.27
    }
    return multiplier
  }
  return null
}

export {
  cheapabilityTranslator,
  colorSetter,
  calculateCheapability,
  calculateRefPrice,
  calculateOrderMultiplier,
}

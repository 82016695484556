import { Button, Modal, Table } from 'antd'
import { useEffect, useState } from 'react'
import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'

const AppealModal = ({ item, sellerName, isOpen, setIsOpen }) => {
  const nth = (item?.systemApprovedLogs?.length || 0) + 1

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      title={`품질관리 미흡에 따른 소명자료 제출 ${nth}차 제한`}
      cancelText='닫기'
      okButtonProps={{ hidden: true }}
      style={{ top: '50px' }}
    >
      <p>
        현재 <strong>{sellerName}</strong>님께서 판매 중인{' '}
        <strong>{item?.itemTitle}</strong>의 품질 관리 미흡이 재차 확인되어
        노출제한 조치가 진행되었습니다.
        <br />
        문제 해결을 위해 아래 양식에 맞추어 소명서를 제출해주시기 바랍니다.
        소명서 미제출 및 문제 지속 발생의 경우 전체 상품의 판매가 제한될 수
        있음을 안내 드립니다.
        <br />
        <br />
        문제 상품을 삭제하시는 경우, 삭제한 상품에 대해서는 소명자료 제출이
        불필요 합니다.
        <br />
        단, 상품 삭제 후 재등록 시 해당 상품의 후기, 평점, 노출도 향상을 위한
        연관 데이터 등은 모두 초기화됩니다.
        <br />
        <br />
        품질관리 미흡에 따른 소명은 각 상품 별 총 3회로 제한하고 있으며,
        품질관리 이슈로 3회 노출제한 처리되는 경우 판매자님의 판매활동 제한 등의
        불이익이 생길 수 있으니 품질관리에 각별히 신경써주시기 바랍니다.
        <br />
        <br />
        <strong>{sellerName}</strong>님의 <strong>{item?.itemTitle}</strong>의
        경우 <strong>{nth}번째 소명자료를 제출</strong>하고 계심을 안내드리며,
        이후 세 번째 제한 시 판매자님의 판매활동이 제한됩니다.
        <br />
        <br />
        문제 해결이 어려우시다면, 판매자지원센터에 문의하여 도움을 받으실 수
        있습니다.
      </p>
      <br />
      <ul className='list-disc ml-8'>
        <li>판매자센터 아이디</li>
        <li>해당 상품 아이디</li>
        <li>문제 발생 원인</li>
        <li>개선 예정 기한</li>
        <li>조치 방법</li>
        <li>관련자료 첨부</li>
      </ul>
      <br />
      위 항목들을 포함해서 아래 이메일로 소명자료를 제출해주시기 바랍니다.
      <br />
      제출 후 검토까지 일주일 정도 소요될 수 있습니다.
      <br />
      {/* TODO: fix mail address */}
      <a
        href='mailto:alwayz_quality@ilevit.com'
        className='text-blue-500 underline'
      >
        alwayz_quality@ilevit.com
      </a>
    </Modal>
  )
}

const ItemQualityDashBoard = ({ onClickDetail }) => {
  const [itemQualityData, setItemQualityData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [qualityManagementTotalCount, setQualityManagementTotalCount] =
    useState(0)
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  })
  const handleChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      setPagination({
        page: pagination.current,
        limit: pagination.pageSize,
      })
    }
  }

  const fetchItemQualityData = async () => {
    const itemQualityManagement = await backendApis.loadItemQualityManagement(
      pagination.page,
      pagination.limit,
    )
    if (itemQualityManagement.status === 200 && itemQualityManagement.data) {
      const itemIds = itemQualityManagement.data.map((item) => item.itemId)
      const loadItemsInfo = await backendApis.getItemsInfoByItemIds(itemIds)
      if (loadItemsInfo.status === 2000 && loadItemsInfo.data) {
        const itemsInfo = loadItemsInfo.data
        const qualityData = itemQualityManagement.data.map((item) => {
          const itemInfo = itemsInfo.find(
            (info) => info._id.toString() === item.itemId.toString(),
          )
          return {
            ...item,
            image: itemInfo.mainImageUris[0],
          }
        })
        setItemQualityData(qualityData)
      }
      // 데이터가 없는 경우 보여주는 defalut page
    }
    // 데이터가 없는 경우 보여주는 defalut page
  }

  useEffect(() => {
    fetchItemQualityData()
  }, [pagination])

  useEffect(() => {
    backendApis.loadItemQualityManagementCount().then((res) => {
      if (res.status === 200) {
        setQualityManagementTotalCount(res.data)
      }
    })
  }, [])

  const qualityStatusMapping = {
    alert: '주의',
    emergency: '심각',
    normal: '정상',
    lackOfOrders: '판매량미달',
    unknown: '정상',
  }

  const renderImage = (url) => (
    <img
      src={url}
      alt='Product'
      className='w-20 h-20 object-cover ml-auto mr-auto'
    />
  )

  const renderItemTitle = (text, record) => (
    <div className='text-sm'>
      <div>{record.itemTitle}</div>
      <div className='text-gray-500'>{record.itemId}</div>{' '}
    </div>
  )

  const renderQuality = (status) => {
    const statusText = qualityStatusMapping[status] || '정상'
    let color = 'text-black'

    switch (status) {
      case 'alert':
        color = 'text-orange-500'
        break
      case 'emergency':
        color = 'text-red-500'
        break
      case 'lackOfOrders':
        color = 'text-gray-500'
        break
      default:
        color = 'text-green-500'
    }

    return (
      <div className='text-center'>
        <span className={`block mb-1 ${color} text-sm`}>{statusText}</span>
      </div>
    )
  }

  const renderExposure = (text, record) => {
    const handleClickVerification = () => {
      setSelectedItem(record)
      setIsModalOpen(true)
    }
    if (record.qualityManagementStatus === 'restrained') {
      return (
        <div className='flex flex-col'>
          {record.currentStatus === 'emergency' ? '90' : '50'}% 제한
          <Button onClick={handleClickVerification}>소명자료 제출</Button>
        </div>
      )
    }
    return <div>100% 노출</div>
  }

  const renderDetailQuality = (text, record) => (
    <Button onClick={() => onClickDetail(record.itemId)}>상세확인</Button>
  )

  const columns = [
    {
      title: '이미지',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      render: (url) => renderImage(url),
    },
    {
      title: '상품명',
      dataIndex: 'itemTitle',
      key: 'itemTitle',
      align: 'center',
      render: renderItemTitle,
    },
    {
      title: '품질상태',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      align: 'center',
      render: renderQuality,
    },
    {
      title: '노출도',
      key: 'exposure',
      align: 'center',
      render: renderExposure,
    },
    {
      title: '상세품질지표',
      dataIndex: 'detailQuality',
      key: 'detailQuality',
      align: 'center',
      render: renderDetailQuality,
    },
  ]

  return (
    <div>
      <Table
        columns={columns}
        dataSource={itemQualityData}
        pagination={{
          pageSize: pagination.limit,
          total: qualityManagementTotalCount,
        }}
        onChange={handleChange}
        className='max-w-7xl w-full'
      />
      <AppealModal
        item={selectedItem}
        sellerName={SellerStore.sellerInfo.sellerName}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </div>
  )
}

export default ItemQualityDashBoard

import SellerStore from '../../stores/SellerStore'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import backendApis from '../../utils/backendApis'

const IntroSection = observer(({ isConsentChecked, setIsConsentChecked }) => {
  const [
    isValidBusinessRegistrationNumberImage,
    setIsValidBusinessRegistrationNumberImage,
  ] = useState('')
  const [
    isValidBusinessRegistrationNumber,
    setIsValidBusinessRegistrationNumber,
  ] = useState('')
  const [
    isValidMailOrderSalesRegistrationNumber,
    setIsValidMailOrderSalesRegistrationNumber,
  ] = useState('')
  const [isValidBankAccount, setIsValidBankAccount] = useState('')

  useEffect(() => {
    const loadSellerBusinessInfo = async () => {
      const sellerBusinessInfo =
        await backendApis.loadSellerBusinessRegistrationInfo()

      setIsValidBusinessRegistrationNumberImage(
        sellerBusinessInfo?.data?.isValidBusinessRegistrationNumberImage,
      )
      setIsValidBusinessRegistrationNumber(
        sellerBusinessInfo?.data?.isValidBusinessRegistrationNumber,
      )
      setIsValidMailOrderSalesRegistrationNumber(
        sellerBusinessInfo?.data?.isValidMailOrderSalesRegistrationNumber,
      )
      setIsValidBankAccount(sellerBusinessInfo?.data?.isValidBankAccount)
    }
    loadSellerBusinessInfo()
  }, [])

  const Explain = () => {
    return (
      <div className='mt-4 mb-5 text-left'>
        <p className='font-bold text-2xl'>
          🚨 정산 시행을 위한 사업자 정보 요청 (중요)
        </p>
        <div className='flex gap-3 mt-6 text-base'>
          <div className='flex-1 bg-gray-100 rounded px-6 py-8 text-center shadow-md'>
            <p className='text-black font-bold mb-2'>
              <p>
                사업자등록증 <br /> 이미지 첨부
              </p>
            </p>
            <p
              className={`text-2xl mt-10 ${
                isValidBusinessRegistrationNumberImage === 'approved'
                  ? 'text-blue-500 font-extrabold'
                  : 'text-red-500 font-extrabold'
              }`}
            >
              {isValidBusinessRegistrationNumberImage === 'approved'
                ? '완료'
                : '미완료'}
            </p>
          </div>

          <div className='flex-1 bg-gray-100 rounded px-6 py-8 text-center shadow-md'>
            <p className='text-black font-bold mb-2'>
              <p>
                사업자등록번호 <br /> 입력
              </p>
            </p>
            <p
              className={`text-2xl mt-10 ${
                isValidBusinessRegistrationNumber === 'approved'
                  ? 'text-blue-500 font-extrabold'
                  : 'text-red-500 font-extrabold'
              }`}
            >
              {isValidBusinessRegistrationNumber === 'approved'
                ? '완료'
                : '미완료'}
            </p>
          </div>

          <div className='flex-1 bg-gray-100 rounded px-6 py-8 text-center shadow-md'>
            <p className='text-black font-bold mb-2'>
              <p>
                통신판매업신고번호 <br /> 입력
              </p>
            </p>
            <p
              className={`text-2xl mt-10 ${
                isValidMailOrderSalesRegistrationNumber === 'approved'
                  ? 'text-blue-500 font-extrabold'
                  : 'text-red-500 font-extrabold'
              }`}
            >
              {isValidMailOrderSalesRegistrationNumber === 'approved'
                ? '완료'
                : '미완료'}
            </p>
          </div>

          <div className='flex-1 bg-gray-100 rounded px-6 py-8 text-center shadow-md'>
            <p className='text-black font-bold mb-2'>
              <p>
                사업자 정산계좌 <br /> 등록
              </p>
            </p>
            <p
              className={`text-2xl mt-10 ${
                isValidBankAccount === 'approved'
                  ? 'text-blue-500 font-extrabold'
                  : 'text-red-500 font-extrabold'
              }`}
            >
              {isValidBankAccount === 'approved' ? '완료' : '미완료'}
            </p>
          </div>
        </div>
        <br />
        <p className='text-lg font-semibold mb-2'>
          올웨이즈는 신뢰도 있는 판매금 정산 진행을 위해 (주)나이스페이먼츠와
          정산대행 서비스를 진행합니다.
        </p>
        <p className='text-lg font-semibold'>
          안정적인 정산 진행을 위해 '사업자 정보 및 계좌 적합성 검증'을
          요청드립니다.
        </p>
        <br />
        <span className='font-semibold text-lg text-red-500'>
          - <b className='underline'>24년 12월 6일</b>까지 '사업자 정보 및 계좌
          검증'을 모두 완료하지 않은 사업주님들은{' '}
          <b className='underline'>판매활동이 제한</b>될 수 있습니다. 검증
          미완료로 인한 판매 및 정산 상의 불이익이 발생하지 않도록 진행
          부탁드립니다.
        </span>
      </div>
    )
  }
  return (
    <div className='p-5 mt-2'>
      <Explain />
      <div className='mt-10 space-y-4'>
        <div className='mt-6 p-4 bg-gray-100 rounded-lg'>
          <div className='flex items-center space-x-2'>
            <input
              type='checkbox'
              id='privacyConsent'
              checked={isConsentChecked}
              onChange={(e) => setIsConsentChecked(e.target.checked)}
              className='w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500'
            />
            <label htmlFor='privacyConsent' className='text-lg font-semibold '>
              개인정보 수집 및 이용 동의
            </label>
            <a
              href='https://www.notion.so/80a85d0e23904e159b47c8cddbee2dac?pvs=4'
              target='_blank'
              rel='noopener noreferrer'
              className='text-blue-600 hover:text-blue-800 underline'
            >
              전체보기
            </a>
          </div>
        </div>
      </div>
    </div>
  )
})

export default IntroSection

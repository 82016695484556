import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../../stores/OrderStore'
import backendApis from '../../utils/backendApis'
import ItemStore from '../../stores/ItemStore'
import SellerStore from '../../stores/SellerStore'
import moment from 'moment'
import { set } from 'mobx'
import styled from 'styled-components'

const Box = styled.div`
  border-color: white;
  // background-color: red;
  border: solid #edf0f5;
  border-radius: 2px;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  // width: 100%;
  margin-bottom: 15px;
`

const SearchPriceButton = ({
  optionsInfo,
  itemTitle,
  searchedPrice,
  setSearchedPrice,
  buttonAvailable,
  setButtonAvailable,
  shippingCompany,
}) => {
  const [teamPurchasePrice, setTeamPurchasePrice] = useState(1000)
  useEffect(() => {
    setSearchedPrice(false)
    if (!itemTitle) {
      setButtonAvailable(false)
      return
    }
    let optionPrices = [...optionsInfo.optionPrices]
    let finalOptionPrices = [...optionsInfo.optionPrices]
    let testOption = optionPrices

    while (testOption.length) {
      finalOptionPrices = []
      // for (const option in optionPrices) {
      //   if (option) {
      //     finalOptionPrices = finalOptionPrices.concat(option)
      //   }
      // }
      optionPrices.forEach((option) => {
        finalOptionPrices = finalOptionPrices.concat(option)
      })
      optionPrices = [...finalOptionPrices]
      testOption = finalOptionPrices[0]
    }
    const soldOutResult = finalOptionPrices.every((option) => option.sold_out)
    const teamPurchasePriceResult = finalOptionPrices.some(
      (option) => !option.teamPurchasePrice,
    )
    const individualPurchasePriceResult = finalOptionPrices.some(
      (option) => !option.individualPurchasePrice,
    )
    if (
      soldOutResult ||
      teamPurchasePriceResult ||
      individualPurchasePriceResult
    ) {
      setButtonAvailable(false)
      return
    }
    const hurryUpResult = finalOptionPrices.some((option) => option.hurry_up)
    const teamPurchasePriceTemp = Math.min(
      ...finalOptionPrices.map((option) => option.teamPurchasePrice),
    )
    setTeamPurchasePrice(teamPurchasePriceTemp)
    if (
      optionsInfo.optionNames.every((name) => name) &&
      optionsInfo.totalOptions.every((optionsArray) =>
        optionsArray.every((option) => option.name),
      ) &&
      shippingCompany
    ) {
      setButtonAvailable(true)
    }
  }, [optionsInfo, itemTitle])
  if (buttonAvailable) {
    return (
      <div style={{ justifyContent: 'center', display: 'flex', flex: 1 }}>
        <button
          type='button'
          style={{
            backgroundColor: '#EB3F49',
            color: 'white',
            border: 'none',
            fontWeight: 800,
            padding: 10,
            borderRadius: 5,
            width: 500,
            fontSize: 20,
            marginTop: 10,
          }}
          onClick={() => {
            window.open(
              window.open(
                `https://search.shopping.naver.com/search/all?query=${itemTitle}&sort=price_asc&cat_id=&frm=NVSHATC&maxPrice=${teamPurchasePrice}&minPrice=${Math.round(
                  teamPurchasePrice * 0.7,
                )}`,
                '_blank',
              ),
            )
          }}
        >
          타 상품 가격 참고하기
        </button>
      </div>
    )
  }
  return <></>
}

export default SearchPriceButton

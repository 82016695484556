import Button from '../atoms/button'
import { observer } from 'mobx-react-lite'
import OrderStore from '../../stores/OrderStore'
import { useEffect } from 'react'
import SellerStore from '../../stores/SellerStore'
import commaNumber from 'comma-number'
import moment from 'moment-timezone'
import TableData from '../atoms/tableData'
import { Link } from 'react-router-dom'

const SettlementInfo = observer(() => {
  const className = 'border-2 border-transparent'
  const className2 = 'border-2 border-transparent font-bold'
  const daysFromSaturday = (moment().days() + 1) % 7
  const startTimeStamp = new Date(
    moment()
      .subtract(14 + daysFromSaturday - 2, 'days') // 2주전 월요일
      .startOf('day'),
  )

  const endTimeStamp = new Date(
    moment()
      .subtract(7 + daysFromSaturday - 1, 'days') // 2주전 일요일
      .startOf('day'),
  )
  return (
    <>
      <div className='flex flex-col flex-1 h-full select-none'>
        <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
          {SellerStore.loadingDashboardInfo ? (
            <div className='text-lg font-bold'>
              정산 내역을 불러오는 중입니다.
            </div>
          ) : (
            <div>
              <table className='relative border-transparent'>
                <Link exact to='/settlements' key='정산현황'>
                  <div className='absolute top-0 left-0 w-full h-full flex flex-col items-center justify-around bg-[#000000aa] text-white rounded-lg'>
                    <div className='flex items-center justify-center text-center text-[20px] font-bold pt-[1vw]'>
                      임시 점검중입니다. <br />
                      정산 관리 탭 내 정산현황 메뉴를 이용해주세요.
                    </div>
                    <Button appearance='positive' type='button'>
                      정산현황 바로가기
                    </Button>
                  </div>
                </Link>

                <div className='flex flex-col items-baseline pb-2 text-lg font-bold rounded-xl'>
                  <div>정산 현황</div>
                  <div className='text-sm font-normal text-sub-500'>
                    {startTimeStamp.getFullYear()}.
                    {startTimeStamp.getMonth() + 1}.{startTimeStamp.getDate()} ~{' '}
                    {endTimeStamp.getFullYear()}.{endTimeStamp.getMonth() + 1}.
                    {endTimeStamp.getDate()}
                  </div>
                </div>
                <tr>
                  <TableData className={className}>정산 대상액</TableData>
                  <TableData className={className2}>- 원</TableData>
                </tr>
                <tr>
                  <TableData className={className}>정산 제외액</TableData>
                  <TableData className={className2}>- 원</TableData>
                </tr>
                <tr>
                  <TableData className={`${className}`}>정산 확정액</TableData>
                  <TableData className={`${className2} text-theme-500`}>
                    - 원
                  </TableData>
                </tr>
                {/* <tr>
                  <TableData className={className}>정산 대상액</TableData>
                  <TableData className={className2}>
                    {commaNumber(
                      SellerStore.dashboardInfo?.settlementInfo
                        ?.settlementAmount || 0,
                    )}
                    원
                  </TableData>
                </tr>
                <tr>
                  <TableData className={className}>정산 제외액</TableData>
                  <TableData className={className2}>
                    {commaNumber(
                      SellerStore.dashboardInfo?.settlementInfo
                        ?.settlementExceptAmount || 0,
                    )}
                    원
                  </TableData>
                </tr>
                <tr>
                  <TableData className={`${className}`}>정산 확정액</TableData>
                  <TableData className={`${className2} text-theme-500`}>
                    {commaNumber(
                      SellerStore.dashboardInfo?.settlementInfo
                        ?.settlementConfirmAmount || 0,
                    )}
                    원
                  </TableData>
                </tr> */}
              </table>
              <div className='text-sm text-sub-300'>
                매출액 일괄 계산 데이터입니다. 특가 진행 수수료 등 반영되지 않을
                수 있으므로 정확한 금액은 정산관리 메뉴에서 확인해주세요.
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
})

export default SettlementInfo

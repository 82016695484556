import React, { useEffect, useState } from 'react'
import ListLimitSelector from '../atoms/listLimitSelector'
import { Pagination } from 'antd'
import { Table } from '../ADS/molecules'

const OrderSection = ({
  orderData = [],
  columns = [],
  selectColumn = true,
  currentOrderStatus,
  headerSection: HeaderSection = () => {
    return <></>
  },
}) => {
  const [page, setPage] = useState(1)
  const [chunkSize, setChunkSize] = useState(20)
  const [selectedOrderIds, setSelectedOrderIds] = useState([])
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)

  const currentOrderData = orderData.slice(
    (page - 1) * chunkSize,
    page * chunkSize,
  )
  useEffect(() => {
    setPage(1)
  }, [])

  useEffect(() => {
    setSelectedOrderIds([])
    setIsAllCheckBoxChecked(false)
  }, [currentOrderStatus, chunkSize, page])

  const modifiedColumns = selectColumn
    ? [
        {
          Header: () => {
            return (
              <input
                type='checkbox'
                checked={isAllCheckBoxChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedOrderIds(
                      currentOrderData.map((item) => item._id),
                    )
                    setIsAllCheckBoxChecked(true)
                  } else {
                    setSelectedOrderIds([])
                    setIsAllCheckBoxChecked(false)
                  }
                }}
              />
            )
          },
          id: 'selection',
          Cell: ({ row }) => {
            const item = row?.original
            const orderId = item?._id
            return (
              <input
                type='checkbox'
                checked={selectedOrderIds.includes(orderId)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedOrderIds([...selectedOrderIds, orderId])
                  } else {
                    setSelectedOrderIds(
                      selectedOrderIds.filter((id) => id !== orderId),
                    )
                  }
                }}
              />
            )
          },
        },
        ...columns,
      ]
    : columns

  return (
    <div className='min-w-[80vw] max-w-[80vw] min-h-[60vh] mx-auto'>
      <div className='flex flex-col justify-center items-start'>
        <HeaderSection
          selectedOrderIds={selectedOrderIds}
          setSelectedOrderIds={setSelectedOrderIds}
        />
        <div className='mb-2 flex flex-row flex-1 items-center'>
          <ListLimitSelector
            limit={chunkSize}
            setLimit={setChunkSize}
            setPage={setPage}
          />
          <Pagination
            pageSize={chunkSize}
            current={page}
            total={orderData.length}
            onChange={(e) => setPage(e)}
          />
        </div>
      </div>
      <div>
        <Table columns={modifiedColumns} data={currentOrderData} />
      </div>
    </div>
  )
}
export default OrderSection

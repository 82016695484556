const BigDealTab = ({
  children,
  acceptedStatusTab,
  setAcceptedStatusTab,
  setPage,
}) => {
  return (
    <div>
      <div className='mb-5 '>
        <button
          type='button'
          onClick={() => {
            setAcceptedStatusTab('candidate')
            setPage(1)
          }}
          className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
            acceptedStatusTab === 'candidate'
              ? 'border-b-4 border-[#1258A4] text-black'
              : 'text-gray-400'
          }`}
        >
          신청 대상 상품
        </button>
        <button
          type='button'
          onClick={() => {
            setAcceptedStatusTab('complete')
            setPage(1)
          }}
          className={`select-none text-xl pb-1 font-bold duration-200 ease-in-out ${
            acceptedStatusTab === 'complete'
              ? 'border-b-4 border-[#1258A4] text-black'
              : 'text-gray-400'
          } w-max`}
        >
          신청 완료 상품
        </button>
      </div>
      {children}
    </div>
  )
}
export default BigDealTab

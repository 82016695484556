import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Button from '../atoms/button'
import { GrFormClose as CloseIcon } from 'react-icons/gr'
import SellerStore from '../../stores/SellerStore'
import { Calendar } from 'react-calendar'
import commaNumber from 'comma-number'
import Tooltip from '../ADS/atoms/tooltip'
import AdAnnounce from './adAnnounce'
import AlfarmAdsProgressBadgeSetter from '../atoms/AlfarmAdsProgressBadgeSetter'

const AlfarmLeavesAdStatusBar = () => {
  const [date, setDate] = useState(new Date(moment(new Date()).startOf('day')))
  const [adInfoArray, setAdInfoArray] = useState([])

  const fetchAppliedAdInfo = async () => {
    SellerStore.setIsLoading(true)
    const convertedDate = new Date(date).getTime()
    const result = await backendApis.getAlfarmLeavesAds(convertedDate, 1)

    if (result?.status === 200) {
      SellerStore.setIsLoading(false)
      setAdInfoArray(result?.data)
    }
  }

  useEffect(() => {
    fetchAppliedAdInfo()
  }, [date])

  const cancelAdInfo = async (adUnitId) => {
    if (window.confirm('선택하신 광고를 취소하시겠습니까?')) {
      const result = await backendApis.cancelAlfarmLeavesAd(adUnitId)
      if (result?.status === 200) {
        await fetchAppliedAdInfo()
        alert('광고 취소가 완료되었습니다.')
      }
    }
  }

  const CalendarCard = ({ date }) => {
    return (
      <div className='flex flex-col flex-1 p-10 mb-10 bg-white shadow-lg rounded-xl'>
        <div className='mb-5 text-2xl font-bold'>올팜 낙엽 광고 신청 조회</div>
        <div className='flex flex-row flex-1 '>
          <div className='flex flex-col'>
            <Calendar
              onChange={setDate}
              value={date}
              timeZone='Asia/Seoul'
              maxDate={
                new Date(moment(new Date()).startOf('day').add(9, 'days'))
              }
              // minDate={
              //   new Date(moment(new Date()).startOf('day').subtract(7, 'days'))
              // }
            />
          </div>
          <div className='flex flex-col items-end justify-end flex-1 ml-5 rounded-xl'>
            <div className='flex flex-col items-end'>
              <div>광고 시작 일자</div>
              <div className='flex flex-row items-end justify-end flex-1 my-3'>
                <div className='mr-2 text-gray-500'>
                  {moment(new Date(date)).format('YYYY년')}
                </div>
                <div className='text-4xl font-bold'>
                  {moment(new Date(date)).format('MM월 DD일')}
                </div>
              </div>
            </div>
            <div className='flex flex-row justify-start text-lg'>
              <div className='mr-5'>
                신청 완료:{' '}
                {adInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.adStartedAt).getTime() ===
                    new Date(date).getTime()
                  )
                }).length || 0}
              </div>
              <div className='mr-5'>
                검수 대기:{' '}
                {adInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.adStartedAt).getTime() ===
                      new Date(date).getTime() && e.status === 'pending'
                  )
                }).length || 0}
              </div>
              <div className='mr-5'>
                승인:{' '}
                {adInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.adStartedAt).getTime() ===
                      new Date(date).getTime() && e.status === 'complete'
                  )
                }).length || 0}
              </div>
              <div className='mr-5'>
                반려:{' '}
                {adInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.adStartedAt).getTime() ===
                      new Date(date).getTime() && e.status === 'rejected'
                  )
                }).length || 0}
              </div>

              <div>
                취소:{' '}
                {adInfoArray?.filter((e) => {
                  return (
                    new Date(e.detailInfo?.adStartedAt).getTime() ===
                      new Date(date).getTime() && e.status === 'canceled'
                  )
                }).length || 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const AdInfoCard = ({ adInfo = {} }) => {
    return (
      <div>
        <div className='p-10 mb-10 bg-white shadow-lg rounded-xl'>
          <div className='flex flex-row items-center justify-between flex-1'>
            <div className='flex flex-row items-center flex-1'>
              <div className='mr-2 text-lg font-bold'>
                {adInfo?.itemInfo?.itemTitle}
              </div>
              <AlfarmAdsProgressBadgeSetter
                status={adInfo?.status}
                progressData={adInfo?.progressInfo}
                adEndsAt={
                  adInfo?.progressInfo?.endedAt
                    ? adInfo?.progressInfo?.endedAt
                    : adInfo?.progressInfo?.adEndedAt
                }
                exist={!!adInfo?.progressInfo}
              />
            </div>
            {adInfo?.status === 'pending' && (
              <Button
                appearance='transparent'
                onClick={async () => {
                  if (SellerStore.isLoading) return
                  SellerStore.setIsLoading(true)
                  if (
                    adInfo?.status === 'complete' ||
                    adInfo?.status === 'rejected'
                  ) {
                    alert('검수 완료된 상품은 취소가 불가합니다.')
                    return
                  }
                  await cancelAdInfo(adInfo?._id)
                  SellerStore.setIsLoading(false)
                }}
              >
                <div
                  style={{
                    color: 'black',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CloseIcon className='w-8 h-8' />
                  광고 취소하기
                </div>
              </Button>
            )}
            {adInfo?.status === 'complete' &&
              !adInfo?.progressInfo?.endedAt && (
                <Button
                  appearance='transparent'
                  onClick={async () => {
                    if (SellerStore.isLoading) return
                    SellerStore.setIsLoading(true)
                    if (
                      confirm(
                        '광고를 중단하면 이어서 진행할 수 없어요. 광고를 중단 하시겠어요?',
                      ) === true
                    ) {
                      await cancelAdInfo(adInfo?._id)
                    }
                    SellerStore.setIsLoading(false)
                  }}
                >
                  <div
                    style={{
                      color: 'black',
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <CloseIcon className='w-8 h-8' />
                    광고 중단하기
                  </div>
                </Button>
              )}
          </div>
          {adInfo?.progressInfo && (
            <div className='my-5'>
              <div className='flex flex-row items-center justify-between flex-1'>
                <div>광고 진행도</div>
                <div>
                  <span>
                    완료 광고 수량:{' '}
                    <span className='font-bold text-blue-500'>
                      {commaNumber(
                        parseInt(adInfo?.progressInfo?.clearedCount, 10),
                      )}
                    </span>
                    개
                  </span>
                  <span className='mx-1'>/</span>
                  <span>
                    신청 광고 수량:{' '}
                    {commaNumber(parseInt(adInfo?.progressInfo?.adCount, 10))}개
                  </span>
                </div>
              </div>
              <div className='w-full h-4 bg-gray-100 rounded-xl'>
                <div
                  className={` h-4 bg-positive-500 rounded-xl`}
                  style={{
                    width: `${parseInt(
                      (parseInt(adInfo?.progressInfo?.clearedCount, 10) /
                        parseInt(adInfo?.progressInfo?.adCount, 10)) *
                        100,
                      10,
                    )}%`,
                  }}
                />
              </div>
              {adInfo?.progressInfo?.endedAt &&
                adInfo?.progressInfo?.settlementExcepted &&
                !adInfo?.detailInfo?.isForFreeAdEvent && (
                  <div className='flex flex-row items-center justify-end flex-1 mt-2'>
                    <div>
                      정산 차감 확정액:{' '}
                      <span className='font-bold text-blue-500'>
                        {commaNumber(
                          Math.min(
                            adInfo?.progressInfo?.clearedCount,
                            adInfo?.progressInfo?.adCount,
                          ) * adInfo?.progressInfo?.costPerAd,
                        )}
                      </span>
                      원
                    </div>
                    <Tooltip text='정산 차감액은 광고 진행이 완료된 주간 정산액에서 차감됩니다.' />
                  </div>
                )}
            </div>
          )}
          {adInfo?.detailInfo?.isForFreeAdEvent ? (
            <div className='font-bold text-blue-500'>무료 광고 이벤트 건</div>
          ) : (
            ''
          )}
          <div>광고 아이디: {adInfo?._id}</div>
          <div>
            상품 아이디: <br />
            {adInfo.detailInfo?.itemIds?.map((item) => {
              let itemId = item
              if (typeof item === 'object') {
                itemId = item.itemId
              }
              return (
                <>
                  -{itemId} <br />
                </>
              )
            })}
          </div>

          <div>
            {/* 무료 광고의 경우 입찰가 6원이라고 보여주기 */}
            노출당 단가:{' '}
            {adInfo?.detailInfo?.isForFreeAdEvent
              ? 6
              : adInfo?.detailInfo?.costPerAd ?? ''}
            원
          </div>
          {adInfo?.progressInfo?.clearedCount > 0 &&
            !adInfo?.detailInfo?.isForFreeAdEvent && (
              <div>
                현재 차감 예정액:{' '}
                {commaNumber(
                  Math.min(
                    adInfo?.progressInfo?.clearedCount,
                    adInfo?.progressInfo?.adCount,
                  ) * adInfo?.progressInfo?.costPerAd,
                )}
                원
              </div>
            )}
          {adInfo?.orderData?.[0]?.gmv > 0 &&
            adInfo?.progressInfo?.clearedCount && (
              <>
                <div>
                  발생 매출:{' '}
                  {commaNumber(
                    adInfo?.orderData && adInfo?.orderData.length > 0
                      ? adInfo.orderData[0].gmv
                      : 0,
                  )}
                  원
                </div>
                <div>
                  ROAS:
                  {Math.round(
                    ((adInfo?.orderData && adInfo.orderData.length > 0
                      ? adInfo.orderData[0].gmv
                      : 0) /
                      // 무료 광고의 경우 60000원이라고 보여주기
                      (adInfo?.detailInfo?.isForFreeAdEvent
                        ? Math.min(
                            adInfo?.progressInfo?.clearedCount,
                            adInfo?.progressInfo?.adCount,
                          ) * 6
                        : Math.min(
                            adInfo?.progressInfo?.clearedCount,
                            adInfo?.progressInfo?.adCount,
                          ) * adInfo?.progressInfo?.costPerAd)) *
                      10000,
                  ) / 100}
                  %
                </div>
              </>
            )}
        </div>
      </div>
    )
  }

  return (
    <div className='flex-col flex-1 w-2/3 h-screen'>
      <CalendarCard date={date} />
      <AdAnnounce />
      {adInfoArray.length > 0 ? (
        adInfoArray
          .filter((e) => {
            return (
              new Date(e.detailInfo?.adStartedAt).getTime() ===
              new Date(date).getTime()
            )
          })
          .map((adInfo) => (
            <div key={adInfo?._id}>
              <AdInfoCard adInfo={adInfo} />
            </div>
          ))
      ) : (
        <div>선택하신 날짜로 신청된 광고가 없습니다.</div>
      )}
    </div>
  )
}
export default AlfarmLeavesAdStatusBar

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  LogarithmicScale,
} from 'chart.js'
import Button from '../components/atoms/button'
import Input from '../components/atoms/input'
import backendApis from '../utils/backendApis'
import { FcPuzzle } from 'react-icons/fc'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Legend,
)

const ItemWinnerLinkApplicationModal = observer(
  ({ showModal, setShowModal }) => {
    const [urlLink, setUrlLink] = useState('')

    const handleCancel = () => {
      setShowModal(false)
    }

    return (
      <>
        <>
          {showModal && (
            <>
              <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none'>
                <div className='relative w-full max-w-xl mx-auto my-6 shadow-lg'>
                  <div className='relative flex flex-col w-full bg-white border-0 rounded-lg outline-none'>
                    <div className='flex items-center justify-start p-5 border-b border-solid border-slate-200'>
                      <FcPuzzle className='w-8 h-8 mr-2' />
                      <h3 className='text-xl font-semibold text-black'>
                        매칭 상품 제안하기
                      </h3>
                    </div>
                    <div className='relative flex flex-col items-center justify-center px-10 py-10'>
                      <div>
                        현재 매칭 상품군은 로켓배송 제품들입니다.{' '}
                        <span className='font-bold text-blue-500'>
                          로켓배송 링크
                        </span>
                        <br />를 입력해주세요. 향후 상품군은 확대 예정입니다
                        <br /> 상품 등록까지는 하루 정도 소요됩니다
                      </div>
                      <div className='mt-5'>
                        <form
                          className='flex flex-row flex-1 items-center justify-center'
                          onSubmit={async (e) => {
                            e.preventDefault() // 페이지 새로고침 방지
                            if (!urlLink.includes('coupang')) {
                              alert(
                                '현재는 쿠팡 로켓배송 상품만 신청이 가능합니다. 향후 상품이 확대될 예정입니다',
                              )
                              return // Exit the function after showing the alert.
                            }
                            const regex = /itemId|ItemId/
                            if (!regex.test(urlLink)) {
                              alert(
                                '상품 링크가 아닙니다. 상품 링크를 정확히 첨부해주세요',
                              )
                              return // 함수를 종료하고 alert 이후의 코드를 실행하지 않습니다.
                            }
                            const registerCheck =
                              await backendApis.itemWinnerLinkApplication(
                                urlLink,
                              )
                            if (registerCheck?.status === 200) {
                              alert(
                                '신청이 완료되었습니다. 시간은 하루정도 소요되며 하루 후 매칭해주셔야 신청이 최종 완료됩니다',
                              )
                              setUrlLink('')
                              setShowModal(false)
                            } else {
                              alert(
                                '신청 과정에 문제가 생겼습니다. 판매자 지원센터로 문의해주세요',
                              )
                            }
                          }}
                        >
                          <Input
                            className='w-3/5 ml-5 select-none'
                            type='text'
                            placeholder='로켓배송 상품링크'
                            value={urlLink}
                            onChange={(e) => {
                              setUrlLink(e.target.value)
                            }}
                          />
                          <Button className='ml-2' type='submit'>
                            상품 제안하기
                          </Button>
                        </form>
                      </div>
                    </div>
                    <div
                      className={`bg-gray-50 px-4 py-3 sm:flex sm:px-6 ${'sm:flex-row-reverse'}`}
                    >
                      <div>
                        <Button
                          size='md'
                          appearance='outline'
                          onClick={handleCancel}
                        >
                          <div className='text-slate-500 px-1 py-1'>취소</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='fixed inset-0 z-40 bg-black opacity-25' />
            </>
          )}
        </>
      </>
    )
  },
)

export default ItemWinnerLinkApplicationModal

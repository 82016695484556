import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import CarouselModal from '../../../components/molecules/carouselModal'
import CpmAdApplicationSection from './CpmAdApplicationSection'
import CpmAdModificationSection from './CpmAdModificationSection' // 이 컴포넌트는 새로 만들어야 합니다
import CpmAdAnalyticsSection from './CpmAdAnalyticsSection'
import { useLocation, useHistory } from 'react-router-dom'

const CpmAdScreen = () => {
  const location = useLocation()
  const history = useHistory()
  const [modalStatus, setModalStatus] = useState({
    adExplanationModal: location.state?.showIntroModal || false,
  })
  const [activeTab, setActiveTab] = useState('1')
  const [refreshTrigger, setRefreshTrigger] = useState(0)

  const handleTabChange = (key) => {
    setActiveTab(key)
    setRefreshTrigger((prev) => prev + 1)
  }

  const CpmExplanationSlides = [
    {
      imageSrc:
        'https://assets.ilevit.com/2423d341-e6ce-40f2-bfde-47226669dcaa.png', // Replace with your image URL
      title: '광고 예산을 미리 충전해서 광고를 진행해요',
      description:
        '이제는 정산 예정금이 없어도 괜찮아요.\n광고 금액을 미리 충전해서 원하는 만큼 광고를 진행할 수 있어요.',
      buttonText: '완료',
    },
    {
      imageSrc:
        'https://assets.ilevit.com/89351bce-2c61-44bd-8b4f-e2292cac3d17.png', // Replace with your image URL
      title: 'CPM광고는 고객이 상품을 구경했을 때 비용이 청구돼요',
      description: `광고 종료 후 소진되지 않은 크레딧은 환급돼요.`,
      additionalInfo: '추가 정보가 여기에 표시됩니다.',
      buttonText: '다음',
    },
    {
      imageSrc:
        'https://assets.ilevit.com/05feff7d-5d70-4061-a0f5-67ae3109e4c8.png', // Replace with your image URL
      title: 'AI 맞춤으로 광고가 노출돼요',
      description:
        '소비자의 성별, 연령, 구매 기록, 상품의 특성 등에 따라\n광고 적합성을 판별하여 맞춤형으로 광고가 진행돼요.',
      buttonText: '다음',
    },
    {
      imageSrc:
        'https://assets.ilevit.com/c46a83dd-5996-4758-805c-becd8a94ee34.png', // Replace with your image URL
      title: '구매 가능성이 높은 고객들만 CPM 광고를 볼 수 있어요',
      description:
        '구매 기록이 없거나 구매 빈도가 낮은 고객들에게는\nCPM 광고가 노출되지 않아요.',
      buttonText: '다음',
    },
    {
      imageSrc:
        'https://assets.ilevit.com/64eeac5a-0571-4a09-b715-3d20df08ce49.png', // Replace with your image URL
      title:
        '입찰가, 상품의 매력도 등을 종합 평가하여 노출 우선 순위를 평가해요',
      description:
        '고객 만족도와 구매 빈도가 높은 상품은\n입찰가가 낮아도 우선 노출될 수 있어요.',
      buttonText: '다음',
    },
  ]

  useEffect(() => {
    if (location.state?.showIntroModal) {
      setModalStatus((prev) => ({
        ...prev,
        adExplanationModal: true,
      }))
      // state 초기화 (뒤로가기 시 모달이 다시 뜨는 것을 방지)
      history.replace(location.pathname, {})
    }
  }, [])

  return (
    <div className='max-w-7xl'>
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        size='large'
        className='mb-5'
        items={[
          {
            label: (
              <span className='px-[2vw] py-[2vw] text-[1vw] font-bold'>
                광고 신청
              </span>
            ),
            key: '1',
            children: (
              <CpmAdApplicationSection
                setModalStatus={setModalStatus}
                setTabStatus={setActiveTab}
                refreshTrigger={refreshTrigger}
              />
            ),
          },
          {
            label: (
              <span className='px-[2vw] py-[2vw] text-[1vw] font-bold'>
                상태 관리
              </span>
            ),
            key: '2',
            children: (
              <CpmAdModificationSection refreshTrigger={refreshTrigger} />
            ),
          },
          {
            label: (
              <span className='px-[2vw] py-[2vw] text-[1vw] font-bold'>
                성과 분석
              </span>
            ),
            key: '3',
            children: <CpmAdAnalyticsSection refreshTrigger={refreshTrigger} />,
          },
        ]}
      />

      <CarouselModal
        onClose={() =>
          setModalStatus((prev) => ({
            ...prev,
            adExplanationModal: false,
          }))
        }
        onComplete={() =>
          setModalStatus((prev) => ({
            ...prev,
            adExplanationModal: false,
          }))
        }
        visible={modalStatus.adExplanationModal}
        slides={CpmExplanationSlides}
      />
    </div>
  )
}

export default CpmAdScreen

import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import ItemStore from '../stores/ItemStore'
import backendApis from '../utils/backendApis'
import { observer } from 'mobx-react-lite'
import commaNumber from 'comma-number'
import SellerStore from '../stores/SellerStore'
import { GrClose as CloseIcon } from 'react-icons/gr'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  LogarithmicScale,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
)

const findOptionName = (i, j, totalOptions) => {
  if (j >= 0) return `${totalOptions[0][i]?.name} / ${totalOptions[1][j]?.name}`
  return `${totalOptions[0][i]?.name}`
}

const ItemWinnerApplicationModal = observer(
  ({
    showModal,
    setTargetItemInfo,
    setShowModal,
    itemInfo,
    itemWinnerPrice,
    minimumMappingPrice,
    itemWinnerId,
  }) => {
    const [oldOptionsInfo, setOldOptionsInfo] = useState({})
    const [dealOptionsInfo, setDealOptionsInfo] = useState({})
    const { optionPrices, optionNames, totalOptions } = dealOptionsInfo
    const [selectedCheckboxId, setSelectedCheckboxId] = useState(null)

    useEffect(() => {
      if (itemInfo?._id) {
        loadItemDetails(itemInfo._id)
      }
    }, [itemInfo])

    const loadItemDetails = async (itemId) => {
      const result = await backendApis.loadItemDetails(itemId)
      const {
        data: { optionsInfo },
      } = result
      if (result?.status === 200 && optionsInfo) {
        ItemStore.setItemInfoOnEdit(result.data)
        setOldOptionsInfo(optionsInfo)
        const dealOptionPrices = optionsInfo?.optionPrices?.map(
          (group, index) => {
            if (Array.isArray(group)) {
              return group.map((option, subIndex) => {
                // 선택된 옵션만 변경
                if (selectedCheckboxId === `opCheck${index},${subIndex}`) {
                  return {
                    ...option,
                    teamPurchasePrice: Math.floor(
                      option?.teamPurchasePrice * 0.65,
                    ),
                  }
                }
                return option // 그 외의 옵션은 그대로 반환
              })
            }
            // 선택된 옵션만 변경
            if (selectedCheckboxId === `opCheck${index}`) {
              return {
                ...group,
                teamPurchasePrice: Math.floor(group?.teamPurchasePrice * 0.65),
              }
            }
            return group // 그 외의 옵션은 그대로 반환
          },
        )
        setDealOptionsInfo({ ...optionsInfo, optionPrices: dealOptionPrices })
      }
    }

    const OptionListItem = ({ newOption, i, j, oldOption }) => {
      const checkboxId = `opCheck${i},${j}`
      let statusText
      if (!oldOption?.itemWinnerId) {
        statusText = '미신청'
      } else if (oldOption?.rejectCount) {
        statusText = `반려 ${oldOption?.rejectCount}회`
      } else if (oldOption?.isApproved === false) {
        statusText = '검수중'
      } else if (oldOption?.isItemWinner) {
        statusText = '노출량 파워 UP'
      } else {
        statusText = '매칭중'
      }
      const selected = selectedCheckboxId === checkboxId
      const handleCheckboxChange = () => {
        if (statusText === '매칭중' || statusText === '노출량 파워 UP') {
          // 매칭중인 상태를 확인
          alert(
            '해당 옵션은 매칭중입니다. 신청완료상품란에서 가격을 변경해주세요',
          )
          return
        }
        setSelectedCheckboxId(checkboxId)
      }
      return (
        <tr>
          <td>{statusText}</td>
          <td>{findOptionName(i, j, totalOptions)}</td>
          <td>{commaNumber(oldOption?.teamPurchasePrice)}</td>
          <td>
            <input
              id={`opPrice${i}${j}`}
              value={
                selected
                  ? newOption?.teamPurchasePrice
                  : oldOption?.teamPurchasePrice
              }
              disabled={!selected}
              min={0}
              style={{ width: '150px' }}
              onChange={(e) => {
                if (!selected) {
                  alert('옵션을 선택해주세요')
                  return
                }
                if (typeof parseInt(e.target.value, 10) !== 'number') return
                setDealOptionsInfo((prev) => {
                  const newOptionPrices = JSON.parse(
                    JSON.stringify(optionPrices),
                  ) // deep copy
                  const targetOption =
                    j >= 0 ? newOptionPrices[i][j] : newOptionPrices[i]
                  targetOption.teamPurchasePrice = parseInt(e.target.value, 10)
                  return {
                    optionPrices: newOptionPrices,
                    optionNames,
                    totalOptions,
                  }
                })
                setTimeout(() => {
                  document.getElementById(`opPrice${i}${j}`).focus()
                }, 50)
              }}
              type='number'
            />
          </td>
          <td>{itemWinnerPrice}</td>
          <td>{commaNumber(oldOption?.stockNumber)}</td>
          <td>
            <input
              id={`opStock${i}${j}`}
              min={0}
              value={selected ? newOption?.stockNumber : oldOption?.stockNumber}
              disabled={!selected}
              onChange={(e) => {
                if (!selected) {
                  alert('옵션을 선택해주세요')
                  return
                }
                if (typeof parseInt(e.target.value, 10) !== 'number') return
                setDealOptionsInfo((prev) => {
                  const { optionPrices, optionNames, totalOptions } = prev
                  const targetOption =
                    j >= 0 ? optionPrices[i][j] : optionPrices[i]
                  targetOption.stockNumber = parseInt(e.target.value, 10)
                  return { optionPrices, optionNames, totalOptions }
                })
                setTimeout(() => {
                  document.getElementById(`opStock${i}${j}`).focus()
                }, 50)
              }}
              type='number'
              style={{ width: 120 }}
            />
          </td>
          <td>
            <input
              type='checkbox'
              id={checkboxId}
              checked={selectedCheckboxId === checkboxId}
              onChange={handleCheckboxChange}
            />
          </td>
        </tr>
      )
    }

    return (
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          backendApis.recordEventLog(
            'TimeDealApplicationScreen',
            'closed application modal',
          )
          setShowModal(false)
        }}
        style={{
          overlay: {
            zIndex: 20,
          },
          content: {
            left: '20%',
            right: '15%',
            top: '10%',
            bottom: '10%',
            padding: 40,
            textAlign: 'center',
          },
        }}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            const isEmptyTeamPurchaesPrice = dealOptionsInfo?.optionPrices
              ?.flat()
              .some((each) => !each.teamPurchasePrice)
            const individualPriceError = dealOptionsInfo?.optionPrices
              ?.flat()
              .some(
                (each) =>
                  each.teamPurchasePrice * 0.9 > each.individualPurchasePrice,
              )
            if (selectedCheckboxId == null) {
              alert('옵션을 선택해주세요')
              return
            }
            if (isEmptyTeamPurchaesPrice) {
              alert('옵션에 대해 할인가를 입력해주세요.')
              return
            }

            if (individualPriceError) {
              alert('팀구매가는 개인구매가보다 10% 더 저렴해야 합니다')
              return
            }
            if (
              !window.confirm(
                '선택을 완료하시겠습니까? 매칭 상품과 동일한 제품만 신청이 가능합니다. 상품 매창하기를 눌러야 최종 매칭이 완료됩니다',
              )
            )
              return
            const option = selectedCheckboxId?.substring(7).split(',')
            const i = option?.[0] // "0"
            const j = option?.[1] // "1"
            const targetOption =
              j >= 0
                ? dealOptionsInfo.optionPrices[i][j]
                : dealOptionsInfo.optionPrices[i]
            if (selectedCheckboxId) {
              targetOption.teamPurchasePrice = parseInt(
                document.getElementById(`opPrice${i}${j}`).value,
                10,
              )
              targetOption.stockNumber = parseInt(
                document.getElementById(`opStock${i}${j}`).value,
                10,
              )
            }
            if (targetOption?.rejectCount !== undefined) {
              if (targetOption.rejectCount > 2) {
                alert('2회 반려된 옵션입니다. 신청이 불가합니다')
                return
              }
            }
            if (targetOption?.stockNumber < 10) {
              alert('옵션에 대해 재고를 10개 이상 입력해주세요.')
              return
            }
            targetOption.isItemWinner = false
            targetOption.isItemWinnerApproved = false
            targetOption.itemWinnerId = itemWinnerId
            targetOption.itemWinnerPrice = itemWinnerPrice
            if (targetOption.rejectCount === 1) {
              targetOption.reoffer = true
            }
            const newItemInfo = {
              _id: itemInfo._id,
              optionsInfo: dealOptionsInfo,
              targetOptionsInfo: targetOption,
              teamPurchasePrice: itemInfo.teamPurchasePrice,
              isItemWinner: false,
              itemWinnerPrice,
              optionName: `${optionNames?.join('/')} / ${findOptionName(
                i,
                j,
                dealOptionsInfo.totalOptions,
              )}`,
            }
            setTargetItemInfo(newItemInfo)
            setShowModal(false)
          }}
        >
          <div className='py-8 text-2xl'>상품명: {itemInfo?.itemTitle}</div>
          {/* 취소버튼 */}
          <button
            type='button'
            onClick={() => {
              backendApis.recordEventLog(
                'TimeDealApplicationScreen',
                'closed application modal',
              )
              setShowModal(false)
            }}
            className='absolute top-0 right-0 mt-10 mr-10 border-0 bg-none '
          >
            <CloseIcon className='w-4 h-4' />
          </button>
          <table>
            <thead style={{ fontSize: 14 }}>
              <tr>
                <th>상태</th>
                <th>{optionNames?.join('/')}</th>
                <th>현재 팀 구매가</th>
                <th>변경 팀 구매가</th>
                <th>위너 상품 가격</th>
                <th>현재 재고량</th>
                <th>변경 재고량</th>
                <th>옵션 선택</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 14 }}>
              {optionPrices?.map((group, i) =>
                Array.isArray(group) ? (
                  <>
                    {group.map((option, j) => (
                      <OptionListItem
                        key={`option${i}${j}`}
                        i={i}
                        j={j}
                        newOption={option}
                        oldOption={oldOptionsInfo?.optionPrices?.[i][j]}
                      />
                    ))}
                  </>
                ) : (
                  <OptionListItem
                    key={`option${i}`}
                    i={i}
                    j={-1}
                    newOption={group}
                    oldOption={oldOptionsInfo?.optionPrices?.[i]}
                  />
                ),
              )}
            </tbody>
          </table>

          <div style={{ height: 40 }} />

          <input
            type='submit'
            style={{
              width: 300,
              fontSize: 20,
              fontWeight: 'bold',
              padding: 6,
              border: 0,
              borderRadius: 20,
              backgroundColor: 'skyblue',
            }}
            value='선택하기'
            onClick={async () => {
              SellerStore.loadSellerInfo()
            }}
          />
        </form>
      </Modal>
    )
  },
)

export default ItemWinnerApplicationModal

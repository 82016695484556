import React, { useState } from 'react'
import { Modal, Carousel } from 'antd'

const ImageGallery = ({ urls }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentImage, setCurrentImage] = useState('')

  const handleImageClick = (url) => {
    setCurrentImage(url)
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setCurrentImage('')
  }

  if (Array.isArray(urls) && urls.length > 0) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '200px',
          }}
        >
          <Carousel
            arrows={true}
            dots={true}
            style={{
              maxWidth: '120px',
            }}
          >
            {urls.map((url, index) => (
              <div
                key={index}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <img
                  src={url}
                  alt={`Review Image ${index + 1}`}
                  style={{
                    width: '120px',
                    height: '120px',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleImageClick(url)}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <Modal
          open={isModalVisible}
          footer={null}
          onCancel={handleModalClose}
          centered
        >
          <img
            src={currentImage}
            alt='Enlarged Review'
            style={{ width: '100%' }}
          />
        </Modal>
      </>
    )
  }

  return <span>-</span>
}

export default ImageGallery

import SellerStore from '../stores/SellerStore'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import { FcBookmark as TitleIcon } from 'react-icons/fc'
import Page from '../components/atoms/page'
import CouponCardList from '../components/molecules/couponCardList'
import SubTitle from '../components/atoms/subTitle'
import CouponDetailedInfoHandler from '../components/molecules/couponDetailedInfoHandler'
import ItemComponent from '../components/ItemComponents/ItemComponent'
import Pagination from '../components/molecules/pagination'
import ApplyTabHandler from '../components/molecules/applyTabHandler'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import CouponApplyModal from '../components/molecules/couponApplyModal'
import CouponEditModal from '../components/molecules/couponEditModal'
import Button from '../components/atoms/button'
import couponSettingByTab from '../data/couponSettingByTab'
import SellerCouponApplyModal from '../components/molecules/sellerCouponApplyModal'
import SellerCouponEditModal from '../components/molecules/sellerCouponEditModal'
import ItemSearchComponent from '../components/ItemComponents/ItemSearchComponent'

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const CouponScreen = observer(() => {
  const [page, setPage] = useState(1)
  const [tab, setTab] = useState(1)
  const [couponStatusTab, setCouponStatusTab] = useState('candidate')
  const [limit, setLimit] = useState(20)
  const [itemsInfo, setItemsInfo] = useState([])
  const [selectedItemInfo, setSelectedItemInfo] = useState({})
  const [itemsCount, setItemsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [showCouponModal, setShowCouponModal] = useState(false)
  const [sellerCouponInfo, setSellerCouponInfo] = useState({})
  const [showSellerCouponModal, setShowSellerCouponModal] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const defaultTab = Number(queryParams.get('tab'))
    setTab(defaultTab || 1)
  }, [])

  async function sellerCouponInfoHandler(couponType) {
    const result = await backendApis.getSellerCouponInfo(couponType)
    if (result?.status === 2000) {
      setSellerCouponInfo(result?.data)
      return
    }
    setSellerCouponInfo({})
  }

  const handleSearch = (selectedSearchField, itemQuery) => {
    fetchSegmentedItemsInfo(true, selectedSearchField, itemQuery) // 검색 정보 넘겨주기
  }

  const fetchSegmentedItemsInfo = async (
    isNewCondition,
    selectedSearchField,
    itemQuery,
  ) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    if (itemQuery) {
      if (selectedSearchField === 'itemTitle') {
        itemCondition.itemTitle = { $regex: escapeRegExp(itemQuery) }
      } else if (selectedSearchField === 'itemId') {
        itemCondition._id = {
          $in: [itemQuery],
        }
      }
    }
    if (couponStatusTab === 'candidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: true }
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: {
                $and: [
                  { key: 'coupon', value: couponSettingByTab?.[tab].type },
                ],
              },
            },
          },
        },
      ]
    } else if (couponStatusTab === 'complete') {
      itemCondition.soldOut = false
      itemCondition.$or = [
        {
          outOfStock: false,
        },
        {
          outOfStock: null,
        },
      ]
      itemCondition.itemFilters = {
        $ne: null,
        $elemMatch: {
          $and: [{ key: 'coupon', value: couponSettingByTab?.[tab].type }],
        },
      }
    }
    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        SellerStore.setIsLoading(false)
        return
      }
    }
    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    setIsLoading(false)
    SellerStore.setIsLoading(false)
  }

  // useEffect(() => {
  //   fetchSegmentedItemsInfo(true)
  // }, [])

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [limit, couponStatusTab])

  useEffect(() => {
    setShowCouponModal(false)
    setCouponStatusTab('candidate')
    setSellerCouponInfo({})
    setPage(1)
    async function loadSellerCouponInfo(couponType) {
      const result = await backendApis.getSellerCouponInfo(couponType)
      if (result?.status === 2000) {
        setSellerCouponInfo(result?.data)
      }
    }
    if (couponSettingByTab?.[tab].category === 'seller') {
      loadSellerCouponInfo(couponSettingByTab?.[tab].type)
    }
  }, [tab])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  return (
    <div className='max-w-7xl'>
      <Page title='쿠폰 신청 페이지' Icon={TitleIcon}>
        <div className='flex flex-row flex-1 w-full'>
          <CouponCardList tab={tab} setTab={setTab} />
        </div>
        <SubTitle className='mt-10'>{couponSettingByTab?.[tab].name}</SubTitle>
        <CouponDetailedInfoHandler tab={tab} />
        {couponSettingByTab?.[tab].category === 'seller' ? (
          <>
            <>
              {sellerCouponInfo?._id ? (
                <>
                  <Button
                    className='mt-5'
                    size='xl'
                    onClick={() => {
                      setShowSellerCouponModal(true)
                    }}
                  >
                    조회/수정
                  </Button>
                  <SellerCouponEditModal
                    tab={tab}
                    showModal={
                      showSellerCouponModal &&
                      sellerCouponInfo !== null &&
                      couponSettingByTab?.[tab].category === 'seller'
                    }
                    setShowModal={setShowSellerCouponModal}
                    callbackFunction={(couponType) =>
                      sellerCouponInfoHandler(couponType)
                    }
                    appliedCouponInfo={sellerCouponInfo}
                  />
                </>
              ) : (
                <>
                  <Button
                    className='mt-5'
                    size='xl'
                    onClick={() => {
                      setShowSellerCouponModal(true)
                    }}
                  >
                    신청하기
                  </Button>
                  <SellerCouponApplyModal
                    tab={tab}
                    showModal={
                      showSellerCouponModal &&
                      sellerCouponInfo !== null &&
                      couponSettingByTab?.[tab].category === 'seller'
                    }
                    setShowModal={setShowSellerCouponModal}
                    callbackFunction={(couponType) =>
                      sellerCouponInfoHandler(couponType)
                    }
                  />
                </>
              )}
            </>
          </>
        ) : (
          <>
            <div className='flex flex-row items-center justify-between flex-1 mt-10 mb-5 '>
              <ApplyTabHandler
                applyStatusTab={couponStatusTab}
                setApplyStatusTab={setCouponStatusTab}
                setPage={setPage}
              />
              <div className='flex justify-between'>
                <ItemSearchComponent onSearch={handleSearch} />
                <ListLimitSelector limit={limit} setLimit={setLimit} />
              </div>
            </div>
            <div>
              <div className='flex flex-row items-center justify-center flex-1 px-8 py-4 mb-5 font-bold bg-white divide-x-2 rounded-lg shadow-md select-none text-neutral-500'>
                <div className='flex flex-row items-center justify-center flex-1 min-w-[12%] max-w-[12%]'>
                  메인이미지
                </div>
                <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
                  상품정보
                </div>
                <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
                  노출트렌드
                </div>
                <div className='flex flex-row items-center justify-center flex-1 min-w-[38%] max-w-[38%]'>
                  등급 시뮬레이터
                </div>
              </div>
              <div>
                {itemsInfo &&
                  Array.isArray(itemsInfo) &&
                  itemsInfo.map((itemInfo, index) => {
                    return (
                      <ItemComponent
                        key={itemInfo._id + index.toString()}
                        itemInfo={itemInfo}
                        callbackFunction={() => fetchSegmentedItemsInfo(true)}
                        dealRequest={(itemInfo) => {
                          setSelectedItemInfo(itemInfo)
                          setShowCouponModal(true)
                        }}
                        dealType={couponSettingByTab[tab].type}
                        dealApplyInfo={couponStatusTab !== 'candidate'}
                        dealRankLimit='B'
                      />
                    )
                  })}
              </div>
              <div>
                <Pagination
                  total={itemsCount}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                  size='lg'
                />
              </div>
              <>
                <CouponApplyModal
                  itemInfo={selectedItemInfo}
                  tab={tab}
                  showModal={showCouponModal && couponStatusTab === 'candidate'}
                  setShowModal={setShowCouponModal}
                  callbackFunction={() => fetchSegmentedItemsInfo(true)}
                />
                <CouponEditModal
                  itemInfo={selectedItemInfo}
                  tab={tab}
                  showModal={showCouponModal && couponStatusTab === 'complete'}
                  setShowModal={setShowCouponModal}
                  callbackFunction={() => fetchSegmentedItemsInfo(true)}
                />
              </>
            </div>
          </>
        )}
      </Page>
    </div>
  )
})
export default CouponScreen

import React from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import Loader from 'react-loader-spinner'

const LoadingIndicator = observer(() => {
  return (
    SellerStore.isLoading && (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          bottom: '0',
          zIndex: 100,
        }}
      >
        <Loader type='Oval' color='#EA3F49' height='100' width='100' />
      </div>
    )
  )
})

export default LoadingIndicator

import { useEffect, useState } from 'react'
import backendApis from '../../utils/backendApis'
import validateMailOrderSalesNumber from '../../utils/businessRegistrationUtils/mailOrderSalesNumberValidation'

const MailOrderSalesSection = ({ sellerInfo, onPreviousStep, onNextStep }) => {
  const [mailOrderSalesNumber, setMailOrderSalesNumber] = useState('')
  const [isValidMailOrderSalesNumber, setIsValidMailOrderSalesNumber] =
    useState(null)
  const [isReLoad, setIsReLoad] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [validationComplete, setValidationComplete] = useState(false)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [validationFail, setValidationFail] = useState({
    flag: false,
    message: '',
  })
  const [confirmedData, setConfirmedData] = useState({
    prmmiMnno: '', // 통신판매업신고번호(인허가관리번호)
    rprsvNm: '', // 대표자명
    bzmnNm: '', // 상호(법인)명
  })

  useEffect(async () => {
    const sellerBusinessInfo =
      await backendApis.loadSellerBusinessRegistrationInfo()

    setMailOrderSalesNumber(
      sellerBusinessInfo?.data?.mailOrderSalesRegistrationNumber,
    )
    setIsValidMailOrderSalesNumber(
      sellerBusinessInfo?.data?.isValidMailOrderSalesRegistrationNumber,
    )

    if (
      sellerBusinessInfo?.data?.isValidMailOrderSalesRegistrationNumber ===
      'approved'
    ) {
      setValidationComplete(true)
      setConfirmedData({
        prmmiMnno: sellerBusinessInfo?.data?.mailOrderSalesData?.prmmiMnno,
        rprsvNm: sellerBusinessInfo?.data?.mailOrderSalesData?.rprsvNm,
        bzmnNm: sellerBusinessInfo?.data?.mailOrderSalesData?.bzmnNm,
      })
    }
  }, [isReLoad])

  const validateInput = (value) => {
    if (!value) {
      setError('통신판매업신고번호를 입력해주세요.')
      return false
    }
    setError('')
    return true
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setMailOrderSalesNumber(value)
    validateInput(value)
  }

  const handleRegisterClick = async () => {
    if (!validateInput(mailOrderSalesNumber)) {
      alert('통신판매업신고번호 형식을 올바르게 입력해주세요.')
      return
    }
    const isConfirmed = confirm(
      `입력하신 통신판매업신고번호 ${mailOrderSalesNumber}를 등록하시겠습니까?`,
    )
    if (isConfirmed) {
      try {
        setIsLoading(true)
        const updateResult =
          await backendApis.registerMailOrderSalesRegistrationNumber(
            mailOrderSalesNumber,
          )
        // 3초 딜레이 추가
        await new Promise((resolve) => setTimeout(resolve, 3000))
        if (updateResult?.status === 200) {
          alert('등록이 완료되었습니다.')
          setIsReLoad((prev) => !prev)
        } else {
          alert('등록 중 오류가 발생했습니다. 다시 시도해주세요.')
        }
        setIsLoading(false)
      } catch (error) {
        alert('등록 중 오류가 발생했습니다.')
      }
    }
  }

  const handleReset = async () => {
    const isConfirmed = confirm('통신판매업신고번호를 다시 입력하시겠습니까?')
    if (isConfirmed) {
      try {
        const resetResult =
          await backendApis.resetMailOrderSalesRegistrationNumber(
            sellerInfo._id,
          )
        if (resetResult?.status === 200) {
          setValidationComplete(false)
          setValidationFail({
            flag: false,
            message: '',
          })
          setError('')
          setMailOrderSalesNumber('')
          setConfirmedData({
            prmmiMnno: '',
            rprsvNm: '',
            bzmnNm: '',
          })
          setIsReLoad((prev) => !prev)
        } else {
          alert('초기화에 실패했습니다. 다시 시도해주세요.')
        }
      } catch (error) {
        alert('초기화 중 오류가 발생했습니다.')
      }
    }
  }

  const handleStartValidation = async () => {
    setIsValidating(true)
    setIsLoading(true)
    try {
      const result = await validateMailOrderSalesNumber()
      if (result.status === 200) {
        alert('통신판매업신고번호 검증이 완료되었습니다.')
        setValidationComplete(true)
        setValidationFail({
          flag: false,
          message: '',
        })
        setConfirmedData({
          prmmiMnno: result?.data?.prmmiMnno,
          rprsvNm: result?.data?.rprsvNm,
          bzmnNm: result?.data?.bzmnNm,
        })
        setIsReLoad((prev) => !prev)
      } else {
        setValidationFail({
          flag: result?.failFlag || false,
          message: result.message || '',
        })
        alert(result.message)
      }
    } catch (error) {
      alert('검증 중 오류가 발생했습니다.')
    } finally {
      setIsValidating(false)
      setIsLoading(false)
    }
  }

  const isDisabled = isValidMailOrderSalesNumber === 'approved'
  const showRegisterButton =
    mailOrderSalesNumber &&
    !isDisabled &&
    isValidMailOrderSalesNumber !== 'pending'

  const Loader = () => (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white'></div>
    </div>
  )

  return (
    <div className='p-5'>
      {isLoading && <Loader />}
      <div className='flex flex-col space-y-4'>
        {validationComplete ? (
          <div className='p-3 bg-gray-100 rounded-lg border border-gray-200'>
            <div className='flex justify-between items-center mb-3'>
              <h3 className='text-base font-semibold'>
                공정거래위원회에 등록된 통신판매사업자 정보는 다음과 같습니다.
              </h3>
            </div>

            <div className='space-y-2 mb-3 text-sm'>
              <div className='flex'>
                <span className='text-gray-600 w-32 mr-2'>
                  통신판매업신고번호:
                </span>
                <span className='text-gray-800'>{confirmedData.prmmiMnno}</span>
              </div>
              <div className='flex'>
                <span className='text-gray-600 w-32 mr-2'>대표자명:</span>
                <span className='text-gray-800'>{confirmedData.rprsvNm}</span>
              </div>
              <div className='flex'>
                <span className='text-gray-600 w-32 mr-2'>상호(법인)명:</span>
                <span className='text-gray-800'>{confirmedData.bzmnNm}</span>
              </div>
            </div>

            <div className='text-sm text-gray-500 space-y-1'>
              <div className='flex flex-col'>
                <p className='flex items-center mt-4'>
                  <span className='text-red-500 mr-1'>*</span>
                  <span>
                    등록 및 검증 과정에서 문제가 있을 경우, 판매자지원센터로
                    문의해주세요.
                  </span>
                </p>
                <p className='mt-4 mb-1'>개인사업자</p>
                <p className='flex items-center'>
                  <span className='text-red-500 mr-1'>*</span>
                  <span>
                    사업자등록증과 통신판매신고증의 대표자명이 다른 경우,
                    정산계좌 검증은 사업자등록증의 대표자명으로 진행됩니다.
                  </span>
                </p>
                <p className='flex items-center'>
                  <span className='text-red-500 mr-1'>*</span>
                  <span>
                    사업자등록증의 대표자명과 정산계좌의 명의가 다른 공동대표인
                    경우, 채권포기 확약서 제출과 별도 절차가 요구됩니다.
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='p-3 bg-gray-100 rounded-lg border border-gray-200'>
            <div className='flex justify-between items-center'>
              <h3 className='text-base font-semibold'>안내 사항</h3>
            </div>

            <div className='text-sm text-gray-500 space-y-1'>
              <div className='flex flex-col'>
                <p className='flex items-center mt-4'>
                  <span className='text-red-500 mr-1'>*</span>
                  <span>
                    등록 및 검증 과정에서 문제가 있을 경우, 판매자지원센터로
                    문의해주세요.
                  </span>
                </p>
                <p className='flex items-center'>
                  <span className='text-red-500 mr-1'>*</span>
                  <span>
                    통신판매업 신고 면제 기준에 해당하는 경우, 판매자지원센터로
                    문의해주세요. -{' '}
                    <a
                      href='https://www.law.go.kr/%ED%96%89%EC%A0%95%EA%B7%9C%EC%B9%99/%ED%86%B5%EC%8B%A0%ED%8C%90%EB%A7%A4%EC%97%85%EC%8B%A0%EA%B3%A0%EB%A9%B4%EC%A0%9C%EA%B8%B0%EC%A4%80%EC%97%90%EB%8C%80%ED%95%9C%EA%B3%A0%EC%8B%9C'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-blue-500'
                    >
                      면제 기준 확인하기
                    </a>
                  </span>
                </p>
                <p className='flex items-center mt-4'>
                  <span className='text-red-500 mr-1'>! </span>
                  <span className='text-black'>
                    사업자등록번호를 통해 유효한 통신판매업신고번호를 직접
                    조회해볼 수 있어요. -{' '}
                    <a
                      href='https://www.ftc.go.kr/www/biz/bizCommList.do?key=5375'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-blue-500'
                    >
                      통신판매업신고번호 조회 바로가기
                    </a>
                  </span>
                </p>
                <p className='flex items-center'>
                  <span className='text-red-500 mr-1'>! </span>
                  <span className='text-black'>
                    해당 사이트에서 확인한 통신판매업신고번호로 등록해보세요.
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}

        <div className='border rounded-lg p-6'>
          <div className='flex items-center justify-between mb-4'>
            <div className='flex flex-col'>
              <div className='flex items-center'>
                <span className='font-bold mr-3'>통신판매업신고번호</span>
                <input
                  type='text'
                  placeholder='예: 2023-서울강북-01234 등'
                  className={`px-3 py-2 border rounded-md w-64 ${
                    error
                      ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                      : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                  }`}
                  value={mailOrderSalesNumber}
                  onChange={handleInputChange}
                  disabled={
                    isDisabled || isValidMailOrderSalesNumber == 'pending'
                  }
                  required
                />
              </div>
              {error && (
                <span className='text-red-500 text-sm mt-1 ml-[138px]'>
                  {error}
                </span>
              )}
            </div>
            {showRegisterButton && !error && (
              <button
                type='button'
                className='ml-3 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600'
                onClick={handleRegisterClick}
              >
                등록하기
              </button>
            )}
          </div>

          {mailOrderSalesNumber &&
            isValidMailOrderSalesNumber === 'pending' && (
              <div className='flex flex-col space-y-2 mb-4'>
                <button
                  type='button'
                  onClick={handleReset}
                  disabled={isValidating}
                  className='px-4 py-2 bg-gray-500 text-white rounded-md
                  transition-colors duration-200 flex items-center justify-center space-x-2
                  hover:bg-gray-600'
                >
                  <svg
                    className='w-4 h-4'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
                    />
                  </svg>
                  <span>다시 입력하기</span>
                </button>
                <button
                  type='button'
                  onClick={handleStartValidation}
                  disabled={isValidating}
                  className={`px-6 py-3 bg-green-500 text-white rounded-md 
                  transition-colors duration-200 w-full
                  ${
                    isValidating
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-green-600'
                  }`}
                >
                  {isValidating ? '검증 중...' : '검증 시작하기'}
                </button>
              </div>
            )}

          {validationComplete && (
            <div className='text-green-600 font-semibold text-center py-2 bg-green-50 rounded-md mb-4'>
              ✓ 통신판매업신고번호 검증 완료
            </div>
          )}

          {validationFail.flag && (
            <div className='mb-4'>
              <div className='text-red-600 font-semibold text-center py-2 bg-red-50 rounded-md'>
                ✕ 통신판매업신고번호 검증 실패
              </div>
              <p className='text-red-500 text-sm text-center mt-1'>
                {validationFail.message}
              </p>
            </div>
          )}

          <div className='flex flex-col space-y-2'>
            {validationComplete && (
              <button
                type='button'
                onClick={onNextStep}
                className='px-6 py-3 bg-blue-600 text-white rounded-md 
                  transition-colors duration-200 w-full hover:bg-blue-700
                  font-semibold text-lg shadow-md'
              >
                다음 단계로 →
              </button>
            )}
            <button
              type='button'
              onClick={onPreviousStep}
              className='px-6 py-3 bg-gray-600 text-white rounded-md 
                transition-colors duration-200 w-full hover:bg-gray-700
                font-semibold text-lg shadow-md'
            >
              이전 단계로
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MailOrderSalesSection

import { useEffect, useState } from 'react'
import backendApis from '../../utils/backendApis'
import requestBankAccountInfo from '../../utils/businessRegistrationUtils/bankAccountRequest'
import BusinessInfoParser from '../../utils/businessRegistrationUtils/businessInfoParser'
import validateBankAccount from '../../utils/businessRegistrationUtils/bankAccountValidation'

const BankAccountSection = ({ onPreviousStep, onClose }) => {
  const [bankInfo, setBankInfo] = useState([])
  const [selectedBank, setSelectedBank] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [accountHolderName, setAccountHolderName] = useState('')
  const [isRegistered, setIsRegistered] = useState(false)
  const [validationComplete, setValidationComplete] = useState(false)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [agreements, setAgreements] = useState({
    infoConfirm: false,
    privacyConsent: false,
    legalResponsibility: false,
  })
  const [businessType, setBusinessType] = useState({
    mainType: '',
    subType: '',
  })
  const [businessData, setBusinessData] = useState({
    repName: '', // 대표자명
    corpName: '', // 상호명(companyName), 법인명(corpName) 포함
    registerNumber: '', // 사업자등록번호
  })
  const [validationFail, setValidationFail] = useState(false)

  useEffect(async () => {
    try {
      const response = await backendApis.loadBanksInfo()
      setBankInfo(response?.data || [])
    } catch (error) {
      console.error('Error loading bank info:', error)
    }
  }, [])

  useEffect(async () => {
    const sellerBusinessInfo =
      await backendApis.loadSellerBusinessRegistrationInfo()
    setBusinessType(
      BusinessInfoParser.parseBusinessType(
        sellerBusinessInfo?.data?.businessRegistrationNumber,
      ),
    )
    setBusinessData({
      repName: sellerBusinessInfo?.data?.businessRegisteredData?.repName,
      corpName:
        sellerBusinessInfo?.data?.mailOrderSalesData?.bzmnNm ||
        sellerBusinessInfo?.data?.businessRegisteredData?.corpName ||
        sellerBusinessInfo?.data?.businessRegisteredData?.companyName,
      registerNumber: sellerBusinessInfo?.data?.businessRegistrationNumber,
    })
    if (sellerBusinessInfo?.data?.bankAccountInfo) {
      setSelectedBank(sellerBusinessInfo.data.bankAccountInfo.bankCode)
      setAccountNumber(
        sellerBusinessInfo.data.bankAccountInfo.bankAccountNumber,
      )
      setAccountHolderName(
        sellerBusinessInfo.data.bankAccountInfo.depositorName,
      )
      setIsRegistered(true)
      if (sellerBusinessInfo.data.isValidBankAccount === 'approved') {
        setValidationComplete(true)
      }
    }
  }, [])

  const validateInput = () => {
    if (!selectedBank) {
      setError('은행을 선택해주세요.')
      return false
    }
    if (!accountNumber) {
      setError('계좌번호를 입력해주세요.')
      return false
    }
    setError('')
    return true
  }

  const handleVerifyAccount = async () => {
    if (!validateInput()) return

    const isConfirmed = confirm('입력하신 계좌정보를 조회하시겠습니까?')
    if (isConfirmed) {
      try {
        setIsVerifying(true)
        setIsLoading(true)
        const result = await requestBankAccountInfo(selectedBank, accountNumber)
        if (result?.status === 200) {
          if (!result?.AccountHolderName || result?.AccountHolderName === '') {
            alert('예금주 조회에 실패했습니다. 다시 시도해주세요.')
          }
          setAccountHolderName(result.AccountHolderName)
        } else {
          alert(result?.message)
        }
      } catch (error) {
        console.error('Error during verification:', error)
        alert('오류가 발생했습니다. 다시 시도해주세요.')
      } finally {
        setIsVerifying(false)
        setIsLoading(false)
      }
    }
  }

  const handleReset = () => {
    setSelectedBank('')
    setAccountNumber('')
    setAccountHolderName('')
    setIsRegistered(false)
    setValidationFail(false)
    setValidationComplete(false)
    setAgreements({
      infoConfirm: false,
      privacyConsent: false,
      legalResponsibility: false,
    })
    setError('')
  }

  const handleRegister = async () => {
    const isConfirmed = confirm('해당 계좌를 정산계좌로 등록하시겠습니까?')
    if (isConfirmed) {
      try {
        setIsLoading(true)

        const bankId = bankInfo.find(
          (bank) => bank.bankCode === selectedBank,
        )?._id
        const bankAccountInfo = {
          bankId,
          bankCode: selectedBank,
          bankAccountNumber: accountNumber,
          depositorName: accountHolderName,
        }

        const registerResult = await backendApis.registerBankAccountInfoV2(
          bankAccountInfo,
        )
        if (registerResult?.status === 200) {
          alert('계좌 등록이 완료되었습니다. 검증을 시작해주세요.')
          setIsRegistered(true)
        } else {
          alert('등록에 실패했습니다. 다시 시도해주세요.')
        }
      } catch (error) {
        console.error('Error during registration:', error)
        alert('오류가 발생했습니다. 다시 시도해주세요.')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleValidateAndUpdate = async () => {
    try {
      setIsLoading(true)
      // 1. 계좌 검증
      const checkResult = validateBankAccount({
        accountHolderName,
        businessType,
        businessData,
      })

      if (!checkResult) {
        setValidationFail(true)
        alert('사업자와 계좌의 명의가 일치하지 않습니다. 다시 시도해주세요.')
        return
      }

      const bankId = bankInfo.find(
        (bank) => bank.bankCode === selectedBank,
      )?._id
      const bankAccountInfo = {
        bankId,
        bankCode: selectedBank,
        bankAccountNumber: accountNumber,
        depositorName: accountHolderName,
      }

      // 2. 검증 성공 시 최종 업데이트
      const updateResult = await backendApis.updateBankAccountInfo(
        bankAccountInfo,
      )

      if (updateResult?.status === 200) {
        setValidationComplete(true)
        setValidationFail(false)
        alert('계좌 검증 및 최종 등록이 완료되었습니다.')
      } else {
        alert('최종 등록에 실패했습니다. 다시 시도해주세요.')
      }
    } catch (error) {
      console.error('Error during validation and update:', error)
      alert('오류가 발생했습니다. 다시 시도해주세요.')
    } finally {
      setIsLoading(false)
    }
  }

  const areAllAgreementsChecked = () => {
    return Object.values(agreements).every((value) => value === true)
  }

  const handleAgreementChange = (key) => {
    setAgreements((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
  }

  const Loader = () => (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white'></div>
    </div>
  )

  return (
    <div className='p-5'>
      {isLoading && <Loader />}
      <div className='flex flex-col space-y-4'>
        <div className='p-3 bg-gray-100 rounded-lg border border-gray-200'>
          <div className='flex justify-between items-center mb-3'>
            <h3 className='text-base font-semibold'>
              등록된 사업자 정보는 다음과 같습니다.
            </h3>
          </div>

          <div className='space-y-2 mb-3 text-sm'>
            <div className='flex'>
              <span className='text-gray-600 w-24 mr-3'>사업자 성격:</span>
              <span className='text-gray-800'>{businessType.subType}</span>
            </div>
            {(() => {
              if (businessType.mainType === '개인') {
                return (
                  <div className='flex'>
                    <span className='text-gray-600 w-24 mr-3'>
                      명의(대표자명):
                    </span>
                    <span className='text-gray-800'>
                      {BusinessInfoParser.parseBusinessRegistrationRepName(
                        businessData.repName,
                      )}
                    </span>
                  </div>
                )
              }
              if (businessType.mainType === '법인') {
                return (
                  <div className='flex'>
                    <span className='text-gray-600 w-24 mr-3'>
                      명의(법인명):
                    </span>
                    <span className='text-gray-800'>
                      {businessData.corpName}
                    </span>
                  </div>
                )
              }
            })()}
          </div>

          <div className='text-sm text-gray-500 space-y-1'>
            <div className='flex flex-col'>
              <p className='flex items-center mt-4'>
                <span className='text-red-500 mr-1'>*</span>
                <span>
                  등록 및 검증 과정에서 문제가 있을 경우, 판매자지원센터로
                  문의해주세요.
                </span>
              </p>
              <p className='mt-4 mb-1'>개인사업자</p>
              <p className='flex items-center'>
                <span className='text-red-500 mr-1'>*</span>
                <span>
                  개인사업자의 경우, 정산계좌의 명의 검증은 사업자등록증의
                  대표자명으로 진행됩니다.
                </span>
              </p>
              <p className='flex items-center'>
                <span className='text-red-500 mr-1'>*</span>
                <span>
                  사업자등록증의 대표자명과 정산계좌의 명의가 다른 공동대표인
                  경우, 채권포기 확약서 제출과 별도 절차가 요구됩니다.
                </span>
              </p>
              <p className='flex items-center'>
                <span className='text-red-500 mr-1'>*</span>
                <span>
                  채권포기 확약서 파일을 작성하고 별첨 파일을 함께 첨부하여
                  문의로 제출해주세요.
                </span>
                <a
                  href='https://assets.ilevit.com/a734b397-73af-4baf-b61d-947ac36c08b4_inquiry_original.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='px-1 py-1 text-sm text-blue-600 hover:text-blue-800 underline'
                >
                  양식 다운로드
                </a>
              </p>
              <p className='mt-4 mb-1'>법인사업자</p>
              <p className='flex items-center'>
                <span className='text-red-500 mr-1'>*</span>
                <span>
                  법인사업자의 경우, 정산계좌의 명의 검증은 통신판매신고증의
                  법인명으로 진행됩니다.
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className='border rounded-lg p-6'>
          <div className='flex flex-col space-y-4'>
            <div className='flex items-center'>
              <span className='font-bold mr-3'>은행선택</span>
              <select
                className={`px-3 py-2 border rounded-md w-64 ${
                  error && !selectedBank ? 'border-red-500' : 'border-gray-300'
                }`}
                value={selectedBank}
                onChange={(e) => setSelectedBank(e.target.value)}
                disabled={accountHolderName || validationComplete}
              >
                <option value=''>은행을 선택하세요</option>
                {bankInfo.map((bank) => (
                  <option key={bank._id} value={bank.bankCode}>
                    {bank.bankName}
                  </option>
                ))}
              </select>
            </div>

            <div className='flex items-center'>
              <span className='font-bold mr-3'>계좌번호</span>
              <input
                type='text'
                placeholder='계좌번호 입력 (- 제외)'
                className={`px-3 py-2 border rounded-md w-64 ${
                  error && !accountNumber ? 'border-red-500' : 'border-gray-300'
                }`}
                value={accountNumber}
                onChange={(e) =>
                  setAccountNumber(e.target.value.replace(/[^0-9]/g, ''))
                }
                disabled={accountHolderName || validationComplete}
              />
            </div>

            {accountHolderName && (
              <div className='flex items-center'>
                <span className='font-bold mr-3'>예금주명</span>
                <span className='px-3 py-2 border border-gray-300 rounded-md w-64 bg-gray-50'>
                  {accountHolderName}
                </span>
              </div>
            )}

            {error && (
              <span className='text-red-500 text-sm mt-1 ml-[70px]'>
                {error}
              </span>
            )}
          </div>

          {!validationComplete && (
            <div className='flex flex-col space-y-2 mt-4'>
              {!accountHolderName && selectedBank && accountNumber && (
                <button
                  type='button'
                  onClick={handleVerifyAccount}
                  disabled={isVerifying}
                  className={`px-6 py-3 bg-blue-500 text-white rounded-md 
                    transition-colors duration-200 w-full
                    ${
                      isVerifying
                        ? 'opacity-50 cursor-not-allowed'
                        : 'hover:bg-blue-600'
                    }`}
                >
                  {isVerifying ? '조회 중...' : '계좌조회'}
                </button>
              )}

              {accountHolderName && (
                <div className='space-y-4'>
                  <div className='bg-gray-50 p-4 rounded-md'>
                    <p className='text-center font-semibold mb-2'>
                      조회된 계좌정보가 본인의 계좌가 맞으신가요?
                    </p>
                    <div className='w-fit mx-auto text-gray-600 text-sm'>
                      <div className='flex gap-2'>
                        <span>은행:</span>
                        <span>
                          {
                            bankInfo.find(
                              (bank) => bank.bankCode === selectedBank,
                            )?.bankName
                          }
                        </span>
                      </div>
                      <div className='flex gap-2'>
                        <span>계좌번호:</span>
                        <span>{accountNumber}</span>
                      </div>
                      <div className='flex gap-2'>
                        <span>예금주:</span>
                        <span>{accountHolderName}</span>
                      </div>
                    </div>
                  </div>

                  <div className='bg-gray-50 p-4 rounded-md space-y-3'>
                    <div className='flex items-start space-x-2'>
                      <input
                        type='checkbox'
                        id='infoConfirm'
                        checked={agreements.infoConfirm}
                        onChange={() => handleAgreementChange('infoConfirm')}
                        className='mt-1'
                      />
                      <label
                        htmlFor='infoConfirm'
                        className='text-sm text-gray-700'
                      >
                        제출한 사업자 등록증 및 계좌 정보가 사실임을 확인하며,
                        등록한 정보에 오류가 없음을 확인합니다.
                      </label>
                    </div>

                    <div className='flex items-start space-x-2'>
                      <input
                        type='checkbox'
                        id='privacyConsent'
                        checked={agreements.privacyConsent}
                        onChange={() => handleAgreementChange('privacyConsent')}
                        className='mt-1'
                      />
                      <label
                        htmlFor='privacyConsent'
                        className='text-sm text-gray-700'
                      >
                        본인의 동의 하에 제공한 개인정보는 계좌 소유자 확인 및
                        사업자 등록 검증 목적으로만 사용됩니다.
                      </label>
                    </div>

                    <div className='flex items-start space-x-2'>
                      <input
                        type='checkbox'
                        id='legalResponsibility'
                        checked={agreements.legalResponsibility}
                        onChange={() =>
                          handleAgreementChange('legalResponsibility')
                        }
                        className='mt-1'
                      />
                      <label
                        htmlFor='legalResponsibility'
                        className='text-sm text-gray-700'
                      >
                        본 확인 내용이 사실과 다를 경우 발생할 수 있는 법적 책임
                        및 불이익은 본인에게 있음을 인정합니다.
                      </label>
                    </div>
                  </div>

                  <div className='flex space-x-2'>
                    <button
                      type='button'
                      onClick={handleReset}
                      className='flex-1 px-6 py-3 bg-gray-500 text-white rounded-md 
                        transition-colors duration-200 hover:bg-gray-600
                        font-semibold text-lg shadow-md'
                    >
                      다시 입력하기
                    </button>
                    {!isRegistered ? (
                      <button
                        type='button'
                        onClick={handleRegister}
                        disabled={!areAllAgreementsChecked()}
                        className={`flex-1 px-6 py-3 text-white rounded-md 
                          transition-colors duration-200 font-semibold text-lg shadow-md
                          ${
                            areAllAgreementsChecked()
                              ? 'bg-blue-500 hover:bg-blue-600'
                              : 'bg-gray-300 cursor-not-allowed'
                          }`}
                      >
                        등록하기
                      </button>
                    ) : (
                      <button
                        type='button'
                        onClick={handleValidateAndUpdate}
                        disabled={!areAllAgreementsChecked()}
                        className={`flex-1 px-6 py-3 text-white rounded-md 
                          transition-colors duration-200 font-semibold text-lg shadow-md
                          ${
                            areAllAgreementsChecked()
                              ? 'bg-green-500 hover:bg-green-600'
                              : 'bg-gray-300 cursor-not-allowed'
                          }`}
                      >
                        검증 시작하기
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {validationFail && (
            <span className='text-red-500 font-semibold text-center text-base mt-2 block'>
              사업자와 계좌의 명의가 일치하지 않습니다. 다시 시도해주세요.
            </span>
          )}

          {validationComplete ? (
            <div className='space-y-4 mt-4'>
              <div className='text-green-600 font-semibold text-center py-2 bg-green-50 rounded-md'>
                ✓ 정산계좌 등록 완료
              </div>

              <div className='bg-blue-50 p-6 rounded-lg text-center'>
                <h3 className='text-xl font-bold text-blue-800 mb-2'>
                  감사합니다
                </h3>
                <p className='text-blue-600'>
                  판매자님의 폭발적인 매출성장에 올웨이즈가 함께하겠습니다.
                </p>
              </div>
              <div className='text-center mt-4'>
                <button
                  type='button'
                  onClick={onClose}
                  className='font-semibold text-gray-500 hover:text-gray-900 transition-colors'
                >
                  닫기
                </button>
              </div>
            </div>
          ) : (
            <div className='flex flex-col space-y-2 mt-4'>
              <button
                type='button'
                onClick={onPreviousStep}
                className='px-6 py-3 bg-gray-600 text-white rounded-md 
                  transition-colors duration-200 w-full hover:bg-gray-700
                  font-semibold text-lg shadow-md'
              >
                이전 단계로
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BankAccountSection

import configs from './configs'
import AuthStore from '../stores/AuthStore'

if (configs.nodeEnv === 'development') {
  console.log(`socket backendurl: ${configs.backendUrl}`)
}
// const socket = io(configs.backendUrl, {
//   autoConnect: false,
//   transports: ['websocket'],
// })
const socket = null

socket?.on('connect', () => {
  if (configs.nodeEnv === 'development') {
    console.log(`socket connected: ${socket?.id}`)
  }
  //   AuthStore.setNeedToConnectSocket(false)
  AuthStore.setNeedToConnectSocket(true)
})

// socket?.on('loadMembersInfo', () => {
//   roundTableStore.loadMembersInfo()
// })

// socket?.on('setUser', () => {
//   userStore.setUser()
// })

export default socket

import React, { memo, useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { Spin } from 'antd'

const CpmAdAnalyticsGraph = memo(({ data, loading, period = 'daily' }) => {
  const processedData = useMemo(() => {
    if (!data || !Array.isArray(data)) return []

    const flatData = data.flat().filter(Boolean)

    // 먼저 일별로 그룹화
    const dailyData = flatData.reduce((acc, curr) => {
      const dateKey = curr.date

      if (!acc[dateKey]) {
        acc[dateKey] = {
          date: dateKey,
          impressions: 0,
          spentBudget: 0,
          revenue: curr.revenue || 0, // 해당 날짜의 첫 번째 revenue
        }
      }

      acc[dateKey].impressions += curr.impressions || 0
      acc[dateKey].spentBudget += curr.spentBudget || 0

      return acc
    }, {})

    // 일별 데이터를 주간/월간으로 그룹화
    const groupedData = Object.values(dailyData).reduce((acc, curr) => {
      let dateKey = curr.date

      if (period === 'weekly') {
        const date = new Date(curr.date)
        const weekStart = new Date(date)
        weekStart.setDate(date.getDate() - date.getDay())
        dateKey = weekStart.toISOString().split('T')[0]
      } else if (period === 'monthly') {
        dateKey = curr.date.substring(0, 7)
      }

      if (!acc[dateKey]) {
        acc[dateKey] = {
          date: dateKey,
          impressions: 0,
          spentBudget: 0,
          revenue: curr.revenue || 0,
        }
      }

      acc[dateKey].impressions += curr.impressions || 0
      acc[dateKey].spentBudget += curr.spentBudget || 0

      return acc
    }, {})

    return Object.values(groupedData).sort(
      (a, b) => new Date(a.date) - new Date(b.date),
    )
  }, [data, period])

  if (loading) {
    return (
      <div className='flex justify-center items-center h-48'>
        <Spin tip='데이터를 불러오는 중...' />
      </div>
    )
  }

  if (!processedData.length) {
    return (
      <div className='flex justify-center items-center h-48'>
        데이터가 없습니다.
      </div>
    )
  }

  const chartConfig = {
    data: {
      labels: processedData.map((item) => {
        if (period === 'monthly') {
          return `${item.date.replace('-', '년 ')}월`
        }
        if (period === 'weekly') {
          const date = new Date(item.date)
          return `${date.getMonth() + 1}월 ${date.getDate()}일 주`
        }
        return item.date
      }),
      datasets: [
        {
          label: '매출',
          data: processedData.map((item) => item.revenue),
          backgroundColor: '#29a652',
          borderRadius: 8,
          maxBarThickness: 50,
          yAxisID: 'y',
        },
        {
          label: '소진액',
          data: processedData.map((item) => item.spentBudget),
          backgroundColor: '#ea5e1c',
          borderRadius: 8,
          maxBarThickness: 50,
          yAxisID: 'y',
        },
        {
          label: '노출 횟수',
          data: processedData.map((item) => item.impressions),
          backgroundColor: '#6e93f1',
          borderRadius: 8,
          maxBarThickness: 50,
          yAxisID: 'y2',
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          backgroundColor: '#303030',
          padding: 12,
          titleFont: {
            size: 14,
            weight: 'bold',
          },
          bodyFont: {
            size: 13,
          },
          callbacks: {
            label: (context) => {
              const value = context.raw
              const label = context.dataset.label

              if (label === '노출 횟수') {
                return `${label}: ${value.toLocaleString()}회`
              }
              return `${label}: ₩${value.toLocaleString()}`
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 12,
            },
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          grid: {
            color: '#E5E7EB',
          },
          ticks: {
            font: {
              size: 12,
            },
            callback: (value) => `₩${value.toLocaleString()}`,
          },
        },
        y2: {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 12,
            },
            callback: (value) => `${value.toLocaleString()}회`,
          },
        },
      },
    },
  }

  return (
    <div className='h-[400px]'>
      <Bar data={chartConfig.data} options={chartConfig.options} />
    </div>
  )
})

const CpmAdAnalyticsGraphSecondary = memo(
  ({ data, loading, selectedMetrics = ['impressions', 'revenue'] }) => {
    const processedData = useMemo(() => {
      if (!data || !Array.isArray(data)) return []

      const flatData = data.flat().filter(Boolean)

      console.log(flatData)

      // 일별로만 그룹화
      const dailyData = flatData.reduce((acc, curr) => {
        const dateKey = curr.date

        if (!acc[dateKey]) {
          acc[dateKey] = {
            date: dateKey,
            impressions: 0,
            spentBudget: 0,
            revenue: curr.revenue || 0,
          }
        }

        acc[dateKey].impressions += curr.impressions || 0
        acc[dateKey].spentBudget += curr.spentBudget || 0

        return acc
      }, {})

      return Object.values(dailyData)
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map((item) => ({
          ...item,
          roas:
            item.spentBudget > 0 ? (item.revenue / item.spentBudget) * 100 : 0,
        }))
    }, [data])

    if (loading) {
      return (
        <div className='flex justify-center items-center h-48'>
          <Spin tip='데이터를 불러오는 중...' />
        </div>
      )
    }

    if (!processedData.length) {
      return (
        <div className='flex justify-center items-center h-48'>
          데이터가 없습니다.
        </div>
      )
    }

    const metricsConfig = {
      impressions: {
        label: '노출 횟수',
        backgroundColor: '#6e93f1',
        yAxisID: 'y2',
        formatter: (value) => `${value.toLocaleString()}회`,
      },
      spentBudget: {
        label: '소진액',
        backgroundColor: '#ea5e1c',
        yAxisID: 'y',
        formatter: (value) => `₩${value.toLocaleString()}`,
      },
      revenue: {
        label: '매출',
        backgroundColor: '#29a652',
        yAxisID: 'y',
        formatter: (value) => `₩${value.toLocaleString()}`,
      },
      roas: {
        label: 'ROAS',
        backgroundColor: '#9061F9',
        yAxisID: 'y3',
        formatter: (value) => `${value.toFixed(0)}%`,
      },
    }

    const chartConfig = {
      data: {
        labels: processedData.map((item) => item.date),
        datasets: selectedMetrics.map((metric) => ({
          label: metricsConfig[metric].label,
          data: processedData.map((item) => item[metric]),
          backgroundColor: metricsConfig[metric].backgroundColor,
          borderRadius: 8,
          maxBarThickness: 50,
          yAxisID: metricsConfig[metric].yAxisID,
        })),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            backgroundColor: '#303030',
            padding: 12,
            titleFont: {
              size: 14,
              weight: 'bold',
            },
            bodyFont: {
              size: 13,
            },
            callbacks: {
              label: (context) => {
                const metric = selectedMetrics[context.datasetIndex]
                const value = context.raw
                return `${context.dataset.label}: ${metricsConfig[
                  metric
                ].formatter(value)}`
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 12,
              },
            },
          },
          y: {
            type: 'linear',
            position: 'left',
            beginAtZero: true,
            display: selectedMetrics.some((m) =>
              ['revenue', 'spentBudget'].includes(m),
            ),
            grid: {
              color: '#E5E7EB',
            },
            ticks: {
              font: {
                size: 12,
              },
              callback: (value) => `₩${value.toLocaleString()}`,
            },
          },
          y2: {
            type: 'linear',
            position: 'right',
            beginAtZero: true,
            display: selectedMetrics.includes('impressions'),
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 12,
              },
              callback: (value) => `${value.toLocaleString()}회`,
            },
          },
          y3: {
            type: 'linear',
            position: 'right',
            beginAtZero: true,
            display: selectedMetrics.includes('roas'),
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 12,
              },
              callback: (value) => `${value.toFixed(0)}%`,
            },
          },
        },
      },
    }

    return (
      <div className='h-[400px]'>
        <Bar data={chartConfig.data} options={chartConfig.options} />
      </div>
    )
  },
)

export { CpmAdAnalyticsGraph, CpmAdAnalyticsGraphSecondary }

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import OrderStore from '../stores/OrderStore'
import Button from '../components/atoms/button'
import backendApis from '../utils/backendApis'

const RevokeRefundRequestModal = observer(
  ({ order, setOrders, status, closeModal }) => {
    const [denyReason, setDenyReason] = useState('')
    const [additionalDenyNote, setAdditionalDenyNote] = useState('')
    // const [options, setOptions] = useState([])

    const handleRevokeRefundRequest = async () => {
      if (denyReason === '') {
        alert('거부 사유를 선택해주세요.')
        return
      }
      if (additionalDenyNote === '') {
        alert('추가 메모를 입력해주세요.')
        return
      }
      if (status === 'canceling-shipping') {
        SellerStore.setIsLoading(true)
        const result = await backendApis.withdrawCancelingOrder(
          order._id,
          order.userId,
          denyReason,
          additionalDenyNote,
        )
        if (result?.status === 200) {
          OrderStore.setDisplayedOrders(
            OrderStore.displayedOrders.map((orderTemp) => {
              if (orderTemp._id === order._id) {
                return { ...orderTemp, status: 'shipping' }
              }
              return orderTemp
            }),
          )
          if (setOrders) {
            OrderStore.setUserOrders(
              OrderStore.userOrders.map((orderTemp) => {
                if (orderTemp._id === order._id) {
                  return {
                    ...orderTemp,
                    status: 'shipping',
                  }
                }
                return orderTemp
              }),
            )
          }
          OrderStore.changeOrdersNumPerStatus('canceling-shipping', -1)
          OrderStore.changeOrdersNumPerStatus('shipping', 1)
          alert('반품 신청 철회가 완료되었습니다.')
        } else {
          alert('반품 신청 철회에 실패했습니다.')
        }
        SellerStore.setIsLoading(false)
        closeModal()
      } else if (status === 'refunding') {
        SellerStore.setIsLoading(true)
        const result = await backendApis.withdrawCancelingOrder(
          order._id,
          order.userId,
          denyReason,
          additionalDenyNote,
        )
        if (
          result?.status === 200 &&
          ['arrived', 'reviewed'].includes(result.data)
        ) {
          OrderStore.setDisplayedOrders(
            OrderStore.displayedOrders.map((orderTemp) => {
              if (orderTemp._id === order._id) {
                return { ...orderTemp, status: result.data }
              }
              return orderTemp
            }),
          )
          if (setOrders) {
            OrderStore.setUserOrders(
              OrderStore.userOrders.map((orderTemp) => {
                if (orderTemp._id === order._id) {
                  return {
                    ...orderTemp,
                    status: result.data,
                  }
                }
                return orderTemp
              }),
            )
          }
          OrderStore.changeOrdersNumPerStatus('refunding', -1)
          OrderStore.changeOrdersNumPerStatus(result.data, 1)
        }
        closeModal()
        SellerStore.setIsLoading(false)
      }
    }

    return (
      <div className='fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center'>
        <div className='bg-white rounded-lg shadow-lg mx-auto p-4 max-w-lg w-full'>
          <div className='mb-4'>
            <h2 className='text-xl font-semibold text-gray-800'>
              반품 신청 거부
            </h2>
            <hr className='mt-2' />
          </div>
          <div>
            <div className='mb-4'>
              <label
                htmlFor='denyReason'
                className='block text-gray-700 text-sm font-bold mb-2'
              >
                거부 사유
              </label>
              <select
                id='denyReason'
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                value={denyReason}
                onChange={(e) => setDenyReason(e.target.value)}
              >
                <option value=''>선택...</option>
                <option value='불분명한 반품 사유'>불분명한 반품 사유</option>
                <option value='상품 문제 없음'>상품 문제 없음</option>
                <option value='기한 초과'>택배사 배송 중</option>
                <option value='교환 및 재배송'>교환 및 재배송 처리</option>
                <option value='기타'>기타</option>
                {/* 추가 사유 옵션 */}
              </select>
            </div>
            <div className='mb-4'>
              <label
                htmlFor='additionalDenyNote'
                className='block text-gray-700 text-sm font-bold mb-2'
              >
                추가 메모
              </label>
              <textarea
                id='additionalDenyNote'
                className='shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                value={additionalDenyNote}
                onChange={(e) => setAdditionalDenyNote(e.target.value)}
              />
            </div>
            <div className='flex items-center justify-between'>
              <Button
                onClick={handleRevokeRefundRequest}
                className='w-full mr-2'
                appearance='red'
                size='md'
              >
                거부 처리
              </Button>
              <Button
                onClick={() =>
                  // OrderStore?.setOpenRevokeRefundModal(false)
                  closeModal()
                }
                className='w-full ml-2'
                appearance='gray'
                size='md'
              >
                닫기
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default RevokeRefundRequestModal

import { DatePicker, Form, Input, Modal, Select } from 'antd'
import React, { useState, useEffect, useMemo, useRef } from 'react'

import moment from 'moment'
import tableUtils from '../../utils/tableUtils'
import Button from '../../components/atoms/button'
import Table from '../../components/atoms/table'
import { Tooltip as TooltipOrigin } from 'react-tippy'
import backendApis from '../../utils/backendApis'
import { observer } from 'mobx-react-lite'

const RegisterDelayModal = observer(
  ({ showModal, setShowModal, orders, setOrders, onClose = () => {} }) => {
    const [selectedOrderIds, setSelectedOrderIds] = useState([])
    const [delayedReason, setDelayedReason] = useState({ value: '', label: '' })
    const [selectedDelayDate, setSelectedDelayDate] = useState(null)
    const [selectedDateOptions, setSelectedDateOptions] = useState([])
    const [isDetailedReasonModalVisible, setIsDetailedReasonModalVisible] =
      useState(false)
    const [detailedReasonText, setDetailedReasonText] = useState('')

    const modalTitle = '발송지연 처리 및 안내'
    const [form] = Form.useForm()
    const orderData = orders?.map((e) => {
      const orderId = e?._id
      const itemTitle = e?.itemInfo?.itemTitle
      const selectedOption = tableUtils.parseOptionText(
        e?.itemInfo?.optionsInfo,
        e?.selectedOption,
      )
      const quantity = e?.quantity
      const orderDate = moment(e?.preShippingTimeStamp).format(
        'YYYY-MM-DD HH:mm',
      )
      const totalPrice = e?.totalPrice
      const addressInfo = e?.addressInfo?.postcodeAddress
      const detailAddress = e?.addressInfo?.detailAddress
      const recipientName = e?.addressInfo?.recipient
      const recipientPhoneNumber = e?.addressInfo?.recipientPhoneNumber
      const alreadyDelayed = e?.deadline?.delayedDepartureDeadline
      return {
        orderId,
        itemTitle,
        selectedOption,
        quantity,
        orderDate,
        totalPrice,
        addressInfo,
        detailAddress,
        recipientName,
        recipientPhoneNumber,
        alreadyDelayed,
      }
    })
    const filteredOrderData = useMemo(() => {
      const selectedOrders = []
      const unselectedOrders = []
      for (const order of orderData) {
        if (selectedOrderIds.includes(order.orderId)) {
          selectedOrders.push(order)
        } else {
          unselectedOrders.push(order)
        }
      }
      return [...selectedOrders, ...unselectedOrders]
    }, [orderData, selectedOrderIds])

    const updateDepartureDeadlineBySellerDelay = async (
      selectedOrderIds,
      delayedReason,
      selectedDelayDate,
      detailedReasonText,
    ) => {
      const delayDateForSms = selectedDelayDate
        .endOf('day')
        .format('YYYY-MM-DD')
      const ordersGroupedByTitle = orderData
        .filter((order) => selectedOrderIds.includes(order.orderId))
        .reduce((acc, order) => {
          ;(acc[order.itemTitle] = acc[order.itemTitle] || []).push(order)
          return acc
        }, {})

      for (const [itemTitle, orders] of Object.entries(ordersGroupedByTitle)) {
        const orderIds = orders.map((order) => order.orderId)
        const phones = orders.map((order) => order.recipientPhoneNumber)
        const message = `\n[상품명]\n${itemTitle}\n\n[지연발송 기한]\n${delayDateForSms}\n\n[지연사유]\n${delayedReason}\n\n[판매자 안내]\n${detailedReasonText}\n`

        const result = await backendApis.updateDepartureDeadlineBySellerDelay(
          orderIds,
          selectedDelayDate,
        )
        if (result?.status === 200) {
          const parsedOrders = orders.map((order) => {
            if (selectedOrderIds.includes(order._id)) {
              order.deadline.delayedDepartureDeadline = selectedDelayDate
            }
            return order
          })
          setOrders = { parsedOrders }
          const smsResult = await backendApis.sendBulkSms(
            phones,
            message,
            '올웨이즈 배송지연안내',
          )
          if (!smsResult) {
            window.alert(
              '발송지연 처리가 완료되었으나, SMS 발송에 실패했습니다. 고객분들에게 SMS를 발송해주세요.',
            )
          }
        } else {
          window.alert('발송지연 처리에 실패했습니다. 다시 시도해주세요.')
        }
      }
      setSelectedOrderIds([])
      setDelayedReason({ value: '', label: '' })
      setSelectedDelayDate(null)
      setSelectedDateOptions([])
      setDetailedReasonText('')
      window.alert(
        '발송지연 처리가 완료되었습니다. 발송지연 주문은 별도 관리해주세요.',
      )
      window.location.reload()
    }

    const validityCheck = async () => {
      if (
        selectedOrderIds.length > 0 &&
        delayedReason?.value &&
        selectedDelayDate &&
        detailedReasonText
      ) {
        const formattedDelayDate = selectedDelayDate.format(
          'YYYY-MM-DD 23:59:59',
        )
        const delayedReasonForSms = delayedReason?.label
        const confirmMessage = `선택된 주문 '${selectedOrderIds.length}건'에 대해 지연발송 기한을 '${formattedDelayDate}'까지로 설정합니다. 지연처리를 완료하시겠습니까?`
        const isConfirmed = window.confirm(confirmMessage)

        if (isConfirmed) {
          updateDepartureDeadlineBySellerDelay(
            selectedOrderIds,
            delayedReasonForSms,
            selectedDelayDate,
            detailedReasonText,
          )
        }
      } else {
        window.alert('지연사유, 상세사유, 지연발송 기한을 모두 입력해주세요.')
      }
    }

    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
          setShowModal(false)
        }
      }
      document.addEventListener('keydown', handleKeyPress)
      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }, [onClose])

    const handleClose = () => {
      setShowModal(false)
      onClose() // 추가적인 onClose 처리가 필요한 경우 호출
    }

    const showDetailedReasonModal = () => {
      setIsDetailedReasonModalVisible(true)
    }

    const hideDetailedReasonModal = () => {
      setIsDetailedReasonModalVisible(false)
    }
    const handleDetailedReasonSubmit = (detailedReason) => {
      // 여기에서 detailedReason을 처리합니다.
      setDetailedReasonText(detailedReason)
      hideDetailedReasonModal()
    }
    const columns = useMemo(
      () => [
        {
          Header: '선택',
          id: 'selection',
          Cell: ({ row }) => {
            return (
              <input
                value={row?.original?.orderId}
                checked={selectedOrderIds.includes(row?.original?.orderId)}
                type='checkbox'
                onChange={(e) => {
                  setScrollPosition(elementRef.current.scrollTop)
                  const selectedId = row?.original?.orderId
                  if (e?.target?.checked) {
                    // 체크될 때: ID를 배열에 추가
                    setSelectedOrderIds([
                      ...new Set([...selectedOrderIds, selectedId]),
                    ])
                  } else {
                    // 체크 해제될 때: ID를 배열에서 제거
                    setSelectedOrderIds(
                      selectedOrderIds.filter((id) => id !== selectedId),
                    )
                  }
                }}
              />
            )
          },

          isAllSelected: selectedOrderIds.length === orderData?.length,
          SelectFunction: () => {
            if (selectedOrderIds.length === orderData?.length) {
              setSelectedOrderIds([])
            } else {
              setSelectedOrderIds(orderData?.map((order) => order?.orderId))
            }
          },
        },
        { Header: '주문아이디', accessor: 'orderId' },
        { Header: '상품명', accessor: 'itemTitle' },
        { Header: '옵션', accessor: 'selectedOption' },
        { Header: '수량', accessor: 'quantity' },
        { Header: '가격', accessor: 'totalPrice' },
        { Header: '주문성사일', accessor: 'orderDate' },
        { Header: '수령지', accessor: 'addressInfo' },
        { Header: '상세주소', accessor: 'detailAddress' },
        { Header: '수령인', accessor: 'recipientName' },
        { Header: '연락처', accessor: 'recipientPhoneNumber' },
      ],
      [selectedOrderIds],
    )

    const DetailedReasonModal = ({ isVisible, onOk, onCancel, form }) => {
      return (
        <Modal
          title='상세사유 입력'
          open={isVisible}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onOk(values.detailedReason) // 폼 데이터를 onOk 핸들러로 전달
              })
              .catch((info) => {
                console.log('Validate Failed:', info)
              })
          }}
          onCancel={onCancel}
        >
          <Form form={form} layout='vertical'>
            <div className='description mb-3 text-slate-500'>
              *상세 지연사유는 고객에게 문자 및 이메일로 안내됩니다. 상세하게
              작성해주세요.
            </div>
            <Form.Item
              name='detailedReason'
              rules={[{ required: true, message: '상세 사유를 입력해주세요.' }]}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </Form>
        </Modal>
      )
    }

    const Header = ({ modalTitle }) => {
      return (
        <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
          <h3 className='text-xl font-semibold text-black'>{modalTitle}</h3>
          <div className='flex flex-row items-center justify-end flex-1'>
            <TooltipOrigin
              arrow='true'
              theme='dark'
              title='❎ 키보드 esc로도 종료하실 수 있어요.'
              animation='fade'
              position='top'
            >
              <button
                type='button'
                onClick={handleClose}
                className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
              >
                esc
              </button>
            </TooltipOrigin>
          </div>
        </div>
      )
    }

    const elementRef = useRef(null)
    const [scrollPosition, setScrollPosition] = useState(0)

    const Body = ({ columns }) => {
      useEffect(() => {
        elementRef.current.scrollTop = scrollPosition
      }, [scrollPosition])
      return (
        <>
          <div className='relative flex flex-col items-center justify-center px-10 py-10 overflow-y-auto'>
            <div
              className='w-full max-h-[300px] overflow-x-auto overflow-y-auto'
              ref={elementRef}
            >
              <Table
                columns={columns}
                data={filteredOrderData}
                isParentXScroll
              />
            </div>
          </div>
        </>
      )
    }

    const Topline = () => {
      const formatDetailedReasonText = (text) => {
        const words = text.split(' ') // 공백을 기준으로 단어를 분리합니다.
        if (words.length > 15) {
          return words.slice(0, 10).join(' ') + '...' // 처음 20단어만 사용하고, 나머지는 '...'으로 대체합니다.
        }
        return text // 20단어 이하인 경우 그대로 반환합니다.
      }
      return (
        <div>
          <div className='mt-5 ml-5 text-sm text-slate-500'>
            *발송지연 처리는 '1회'만 가능하며, 발송 기한은 '구매성사일로부터
            최대 90일'까지 설정 가능합니다.
          </div>
          <div className='mt-1 ml-5 text-sm text-slate-500'>
            *수정한 발송 기한 내 발송 처리가 되지 않으면 배송 지연 패널티가
            부과되며, 판매 활동에 불이익이 있을 수 있습니다.
          </div>
          <div className='mt-1 ml-5 text-sm text-slate-500'>
            *입력하신 발송지연 사유 및 발송기한은 구매자에게 문자 및 이메일로
            안내됩니다.
          </div>
          <div className='mt-1 ml-5 text-sm text-slate-500'>
            *배송관리 화면에서 조회한 주문건만 발송지연 처리가 가능합니다.
            지연처리 전 먼저 꼼꼼하게 주문을 확인해주세요
          </div>
          <div className='ml-5 mt-5 text-red-500'>
            *지연처리 대상 주문: {selectedOrderIds?.length}건
          </div>
          <div className='ml-5 text-red-500'>
            *지연 사유:{' '}
            {delayedReason?.label
              ? delayedReason?.label
              : '(사유를 선택해주세요)'}
          </div>
          <div className='ml-5 text-red-500'>
            *상세사유:{' '}
            {detailedReasonText
              ? formatDetailedReasonText(detailedReasonText)
              : '(상세사유를 입력해주세요)'}
          </div>
          <div className='ml-5 text-red-500'>
            *지연발송 기한:{' '}
            {selectedDelayDate
              ? selectedDelayDate.format('YYYY-MM-DD 23:59')
              : '(기한을 선택해주세요)'}
          </div>
        </div>
      )
    }

    const Selector = ({ orderData }) => {
      const [selectedItemTitle, setSelectedItemTitle] = useState([])
      const orderItemTitle = Array.from(
        new Set(orderData.map((order) => order?.itemTitle)),
      )
      const selectOptions = orderItemTitle.map((title) => ({
        value: title,
        label: title,
      }))

      const orderSuccessDate = Array.from(
        new Set(
          orderData.map((order) =>
            moment(order?.orderDate).format('YYYY-MM-DD'),
          ),
        ),
      )
      const selectDateOptions = orderSuccessDate.map((date) => ({
        value: date,
        label: date,
      }))
      const delayedReasonOptions = [
        { value: 'preparing', label: '상품준비중' },
        { value: 'customerRequest', label: '고객요청' },
        { value: 'customizing', label: '주문제작' },
        { value: 'oversea', label: '해외배송' },
        { value: 'etc', label: '기타' },
      ]

      // 선택된 주문 ID에 해당하는 주문들의 orderDate 중 가장 빠른 날짜를 찾는 함수
      const findEarliestOrderDate = (selectedOrderIds, orderData) => {
        const dates = selectedOrderIds
          .map((orderId) => {
            const order = orderData.find((o) => o.orderId === orderId)
            return order ? moment(order.orderDate) : null
          })
          .filter((date) => date !== null)

        if (dates.length === 0) return null

        return moment.min(dates)
      }

      // DatePicker의 disabledDate 함수
      const disabledDate = (current) => {
        const earliestOrderDate = findEarliestOrderDate(
          selectedOrderIds,
          orderData,
        )
        if (!earliestOrderDate) return false

        const maxDate = earliestOrderDate.clone().add(90, 'days')
        return (
          current && (current < moment().startOf('day') || current > maxDate)
        )
      }

      const handleDateSelection = (selectedDates) => {
        setSelectedDateOptions(selectedDates)
        const selectedIds = orderData
          .filter((order) =>
            selectedDates.includes(
              moment(order.orderDate).format('YYYY-MM-DD'),
            ),
          )
          .map((order) => order.orderId)
        setSelectedOrderIds(selectedIds)
      }

      const handleItemSelection = (selectedTitles) => {
        setSelectedItemTitle(selectedTitles)
        const selectedIds = orderData
          .filter((order) => selectedTitles.includes(order.itemTitle))
          .map((order) => order.orderId)
        setSelectedOrderIds(selectedIds)
        setSelectedDateOptions([])
      }

      return (
        <div className='mt-5 mb-[-10px] ml-5 flex flex-row items-center'>
          <div className='mr-5 font-bold text-blue-500'>일괄선택</div>
          <Select
            value={selectedItemTitle}
            options={selectOptions}
            placeholder='상품별'
            onChange={(value) => {
              setScrollPosition(elementRef.current.scrollTop)
              handleItemSelection(value)
            }}
            dropdownStyle={{ width: 300 }}
            className='mr-5 w-28'
          />
          <Select
            mode='multiple'
            value={selectedDateOptions}
            options={selectDateOptions}
            placeholder='주문일별'
            onChange={(value) => {
              setScrollPosition(elementRef.current.scrollTop)
              handleDateSelection(value)
            }}
            className='mr-5 w-40'
          />
          <div className='ml-3 mr-5 font-bold text-blue-500'>사유</div>
          <Select
            value={delayedReason}
            options={delayedReasonOptions}
            defaultValue='preparing'
            // placeholder='지연사유'
            onChange={(value) => {
              setScrollPosition(elementRef.current.scrollTop)
              const selectedDelayedOption = delayedReasonOptions.find(
                (option) => option.value === value,
              )
              setDelayedReason(
                selectedDelayedOption || { value: '', label: '' },
              )
            }}
            className='mr-3 w-28'
          />
          <Button
            className='mr-5 w-25'
            onClick={() => {
              setScrollPosition(elementRef.current.scrollTop)
              if (delayedReason?.value === '') {
                window.alert('지연사유를 먼저 선택해주세요')
              } else {
                showDetailedReasonModal()
              }
            }}
          >
            상세사유 입력
          </Button>
          <div className='ml-3 mr-5 font-bold text-blue-500'>지연발송 기한</div>
          <DatePicker
            value={selectedDelayDate}
            onChange={(date) => {
              setScrollPosition(elementRef.current.scrollTop)
              setSelectedDelayDate(date)
            }}
            disabledDate={disabledDate}
            className='mr-5 w-30'
          />
          <Button
            className='mr-5 w-20'
            onClick={() => {
              setScrollPosition(elementRef.current.scrollTop)
              setSelectedOrderIds([])
              setSelectedItemTitle([])
              setSelectedDelayDate(null)
              setSelectedDateOptions([])
              setDelayedReason({ value: '', label: '' })
              setDetailedReasonText('')
            }}
          >
            초기화
          </Button>
          <Button
            className='mr-5 w-25'
            onClick={() => {
              setScrollPosition(elementRef.current.scrollTop)
              validityCheck()
            }}
          >
            발송지연 등록
          </Button>
        </div>
      )
    }
    return (
      <>
        {showModal && (
          <>
            <div className='fixed inset-0 z-50 ml-60 flex flex-1 items-center justify-center px-2 outline-none'>
              <div className='relative w-full max-w-[150vh] mx-auto my-6 shadow-lg max-h-[80vh]'>
                <div className='relative flex flex-col w-full bg-white border-0 outline-none'>
                  <Header modalTitle={modalTitle} />
                  <Topline />
                  <Selector orderData={orderData} />
                  <Body columns={columns} orderData={filteredOrderData} />
                </div>
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => {
                      setShowModal(false)
                    }}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
            <DetailedReasonModal
              isVisible={isDetailedReasonModalVisible}
              onOk={handleDetailedReasonSubmit}
              onCancel={hideDetailedReasonModal}
              form={form}
            />
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
          </>
        )}
      </>
    )
  },
)

export default RegisterDelayModal

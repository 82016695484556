import React from 'react'
import { observer } from 'mobx-react-lite'
import ItemReviews from '../components/ItemReviews'

const ItemReviewsScreen = observer(() => {
  return (
    <>
      <ItemReviews />
    </>
  )
})

export default ItemReviewsScreen

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

const QualityNoticeComponent = observer(() => {
  return (
    <>
      <div style={{}}>
        <h3>
          <span style={{ color: 'red' }}>상품 품질 관리</span>에 신경써주세요!
          올웨이즈는 후기, 취소환불률 등 상품 품질에 따라 상품 노출도를 결정하는
          시스템을 운영중입니다.
        </h3>
        <div>
          1. 상품 설명을 정확히 해주시고, 실제로 설명된 것과 같은 상태의 상품만
          보내주세요.
        </div>
        <div>
          2. 배송 중 손상/변질/부패 되기 쉬운 상품은, 포장 및 배송 과정에도
          신경을 써주세요!
        </div>
        <div>
          3. 혹시나 상품 품질에 이상이 생긴 경우, 고객분들께 사후 조치를 빠르게
          잘 취해주세요.
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <h3>품질 우수 상품 A 판매량 추이</h3>
            <img
              src='https://d22yqrpf3lokox.cloudfront.net/7dae1494-e77d-4a81-9c6a-70461ceef99a.png'
              style={{
                width: 562 / 1.7,
                height: 421 / 1.7,
                border: '3px solid red',
              }}
            />
          </div>
          <div style={{ width: 20 }} />
          <div>
            <h3>품질 문제 상품 B 판매량 추이</h3>
            <img
              src='https://d22yqrpf3lokox.cloudfront.net/4ce17fcc-186f-45b6-9de4-897d9427d862.png'
              style={{
                width: 562 / 1.7,
                height: 421 / 1.7,
                border: '3px solid blue',
              }}
            />
          </div>
        </div>
        <div className='my-5'>
          - 품질 관리가 우수한 상품에 대해서는
          <font color='#ff0000'>
            <b> 이벤트 참여권, 노출도 상향 조절</b>
          </font>{' '}
          등 파격적 혜택을 제공해드리며, 품질 문제가 발생한 상품에 대해서는{' '}
          <font color='blue'>
            <b> 상품 노출도 하향 및 판매 금지 </b>
          </font>{' '}
          조치를 취합니다.
        </div>
      </div>
    </>
  )
})

export default QualityNoticeComponent

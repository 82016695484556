import SellerStore from '../stores/SellerStore'
import backendApis from './backendApis'

class SellerQuest {
  async questClearer(questName, stageName) {
    const questInfo = SellerStore?.sellerInfo?.questInfo
    const questType = {
      adQuest: 'adQuest',
      timedealQuest: 'timedealQuest',
      priceQuest: 'priceQuest',
      beautyQuest: 'beautyQuest',
    }

    if (!Object.values(questType).includes(questName) || !stageName) {
      console.log('Invalid questName or stageName')
      return
    }

    const targetQuestInfo = questInfo?.find((quest) => quest.name === questName)

    const targetStageInfo = targetQuestInfo?.stageInfo?.find(
      (stage) => stage.stageName === stageName && stage.isCleared === true,
    )

    if (targetStageInfo) {
      alert('이미 완료한 퀘스트입니다.')
      return
    }

    const result = await backendApis.clearSellerQuest(questName, stageName)

    if (result?.status === 200) {
      await SellerStore.loadSellerInfo()
      return alert(result?.data?.message)
    }
    if (result?.status === 400) {
      return alert(result?.data?.message)
    }
    console.log(result?.data?.message)
  }

  questChecker(questName, stageName) {
    const questInfo = SellerStore?.sellerInfo?.questInfo
    const [targetQuestInfo] =
      questInfo?.filter((quest) => quest.name === questName) || []
    const questChecker = (stageName) => {
      if (stageName === 'itemRankReport') return true
      if (
        targetQuestInfo?.stageInfo?.filter(
          (stage) => stage.stageName === stageName && stage.isCleared === true,
        ).length > 0
      ) {
        return true
      }
      return false
    }
    return questChecker(stageName)
  }

  questProgressChecker(questName, stageNames) {
    const questInfo = SellerStore?.sellerInfo?.questInfo
    const [targetQuestInfo] =
      questInfo?.filter((quest) => quest.name === questName) || []
    const questProgressChecker = (stageNames) => {
      let progress = 0
      stageNames.forEach((stageName) => {
          if (
            targetQuestInfo?.stageInfo?.filter(
              (stage) =>
                stage.stageName === stageName && stage.isCleared === true,
            ).length > 0 ||
            ['itemRankReport', 'itemRankBatchModal'].includes(stageName)
          ) {
            progress += 1
          }
      })
      return progress
    }

    return (questProgressChecker(stageNames) / stageNames.length) * 100
  }

  rewardChecker(questName) {
    const questInfo = SellerStore?.sellerInfo?.questInfo
    const [targetQuestInfo] =
      questInfo?.filter((quest) => quest.name === questName) || []
    if (targetQuestInfo?.isRewarded === true) {
      return true
    }
    return false
  }
}

export default new SellerQuest()

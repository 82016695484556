import ButtonCard from '../atoms/buttonCard'

const AdCardList = ({ tab, setTab, type }) => {
  // 공지 표시 기간 설정
  const NOTICE_START_DATE = new Date('2024-12-06')
  const NOTICE_END_DATE = new Date('2025-01-08') // 종료일 23:59:59 까지로 설정
  NOTICE_END_DATE.setHours(23, 59, 59, 999)

  const isNoticeEnabled = () => {
    const now = new Date()
    return now >= NOTICE_START_DATE && now <= NOTICE_END_DATE
  }

  const renderAdCards = () => {
    switch (type) {
      case 'normal':
        return (
          <div className='flex flex-row items-center flex-1'>
            <ButtonCard
              image='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/bcefba66-0093-46b9-abec-1b756899c420.png'
              text='파워 퍼포먼스 광고 (CPC)'
              subtext1='상품 노출도 100배'
              subtext2='최상단에 지속 노출돼요'
              width={60}
              height={70}
              idx={0}
              tab={tab}
              setTab={setTab}
            />
            <ButtonCard
              image='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/215f0227-23af-4f04-b9ea-4e45062b880e.png'
              text='판매 보장 광고 (CPS)'
              subtext1='ROAS 800% 보장?!'
              subtext2='판매 안 되면 광고비 0원!'
              width={60}
              height={70}
              idx={1}
              tab={tab}
              setTab={setTab}
            />
          </div>
        )
      case 'alfarm':
        return (
          <div className='flex flex-row items-center flex-1'>
            <>
              <div className='relative'>
                <ButtonCard
                  image='https://assets.ilevit.com/1e1571a6-ab2d-49bb-98a7-f94b940f86f7.png'
                  text='올팜 상점 광고'
                  subtext1='최다 노출'
                  subtext2='상점 광고를 만나보세요'
                  width={60}
                  height={70}
                  idx={5}
                  tab={tab}
                  setTab={setTab}
                />
              </div>
              <div className='relative'>
                <ButtonCard
                  image='https://assets.ilevit.com/4d15106d-ad1a-462e-8975-a70498704b21.png'
                  text='올팜 우주 최저가 광고'
                  subtext1='하루 온종일 노출 되는'
                  subtext2='광고를 만나보세요'
                  width={60}
                  height={70}
                  idx={7}
                  tab={tab}
                  setTab={setTab}
                />
              </div>
              <div className='relative'>
                {isNoticeEnabled() && (
                  <span className='absolute -top-3 left-1/2 transform -translate-x-1/2 bg-[#F8323E] text-white px-3 py-1 rounded-lg text-sm z-10'>
                    CPM 통폐합 예정
                  </span>
                )}
                <ButtonCard
                  image='https://assets.ilevit.com/d16984bd-aafa-4194-9a20-03275731830a.png'
                  text='올팜 오늘의특가 광고'
                  subtext1='120만 고객들이'
                  subtext2='하루 30초씩 구경해요!'
                  width={60}
                  height={70}
                  idx={8}
                  tab={tab}
                  setTab={setTab}
                />
              </div>
              <div className='relative'>
                {isNoticeEnabled() && (
                  <span className='absolute -top-3 left-1/2 transform -translate-x-1/2 bg-[#F8323E] text-white px-3 py-1 rounded-lg text-sm z-10'>
                    CPM 통폐합 예정
                  </span>
                )}
                <ButtonCard
                  image='https://assets.ilevit.com/5d38375a-0379-4caa-bb4d-d60ae4b789ae.png'
                  text='올팜 낙엽 광고'
                  subtext1='고객이 무조건 보는'
                  subtext2='광고 등장!'
                  width={60}
                  height={70}
                  idx={2}
                  tab={tab}
                  setTab={setTab}
                />
              </div>
              <div className='relative'>
                {isNoticeEnabled() && (
                  <span className='absolute -top-3 left-1/2 transform -translate-x-1/2 bg-[#F8323E] text-white px-3 py-1 rounded-lg text-sm z-10'>
                    CPM 통폐합 예정
                  </span>
                )}
                <ButtonCard
                  image='https://assets.ilevit.com/9cc6d57f-5414-427e-9fb7-f7dd528ce27e.png'
                  text='올팜 미니게임 광고'
                  subtext1='다양한 상품 '
                  subtext2='15초 노출 보장'
                  width={60}
                  height={70}
                  idx={3}
                  tab={tab}
                  setTab={setTab}
                />
              </div>
              <div className='relative'>
                {isNoticeEnabled() && (
                  <span className='absolute -top-3 left-1/2 transform -translate-x-1/2 bg-[#F8323E] text-white px-3 py-1 rounded-lg text-sm z-10'>
                    CPM 통폐합 예정
                  </span>
                )}
                <ButtonCard
                  image='https://assets.ilevit.com/d8850951-f48c-4bda-bba7-5486b882a211.png'
                  text='올팜 퀴즈 광고'
                  subtext1='매일 120만 명 이상에게'
                  subtext2='상품을 보여주세요'
                  width={60}
                  height={70}
                  idx={4}
                  tab={tab}
                  setTab={setTab}
                />
              </div>
              <div className='relative'>
                {isNoticeEnabled() && (
                  <span className='absolute -top-3 left-1/2 transform -translate-x-1/2 bg-[#F8323E] text-white px-3 py-1 rounded-lg text-sm z-10'>
                    CPM 통폐합 예정
                  </span>
                )}
                <ButtonCard
                  image='https://assets.ilevit.com/ec096555-9486-4950-8a9c-f01fb9f5b33a.png'
                  text='올팜 포춘쿠키 광고'
                  subtext1='15초 노출 보장'
                  subtext2='광고를 만나보세요'
                  width={60}
                  height={70}
                  idx={6}
                  tab={tab}
                  setTab={setTab}
                />
              </div>
            </>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='flex flex-row items-center flex-1'>{renderAdCards()}</div>
  )
}
export default AdCardList

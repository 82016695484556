import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useTable, usePagination, useRowState } from 'react-table'

function Table({
  columns,
  data,
  shippingCompanyCode,
  className = 'w-full',
  isParentXScroll,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setRowState,
    setCellState,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {
        width: 'auto',
      },
      initialRowStateAccessor: (row) => {
        return {
          shippingNumber: row.cells?.find(
            (cell) => cell?.column?.id === 'shippingNumber',
          )?.value,
          shippingCompanyCode:
            shippingCompanyCode ||
            row.cells?.find(
              (cell) => cell?.column?.id === 'shippingCompanyCode',
            )?.value,
          answerText: row.cells?.find(
            (cell) => cell?.column?.id === 'answerText',
          )?.value,
        }
      },
    },
    useRowState,
  )

  // Render the UI for your table
  return (
    <div className={className}>
      <div className={isParentXScroll ? '' : 'overflow-x-scroll'}>
        <table {...getTableProps()}>
          <thead className='whitespace-nowrap'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.id === 'selection' && (
                      <span className='mr-2'>
                        <input
                          checked={column?.isAllSelected}
                          onChange={() => column?.SelectFunction()}
                          type='checkbox'
                        />
                      </span>
                    )}
                    <span>{column.render('Header')}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              //   row.setState({
              //     shippingNumber: row.cells?.find(
              //       (cell) => cell?.column?.id === 'shippingNumber',
              //     )?.value,
              //   })
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.collapse ? 'collapse' : '',
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table

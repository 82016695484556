/* eslint-disable import/prefer-default-export */
export const getDefaultBigDealPageInfo = (dealType) => {
  switch (dealType) {
    case 'tripleSevenDeal':
      return {
        displayDealType: '777 페스티벌',
        bigDealBannerSrc:
          'https://assets.ilevit.com/d529069b-97fd-4a41-a035-3dce376c860f.png',
      }
    case 'chuseokDeal24':
      return {
        fixedMinimumDiscountRate: 0.15,
        commissionRate: 0.035,
        displayDealType: '신선마켓',
        bigDealBannerSrc:
          'https://assets.ilevit.com/25129587-1a57-477f-99ca-901fc6cae2a6.png',
      }

    default:
      return {
        displayDealType: '',
        bigDealBannerSrc: '',
      }
  }
}

import React, { useState, useEffect } from 'react'
import { Table, Tag } from 'antd'
import moment from 'moment'
import backendApis from '../../utils/backendApis'
import commaNumber from 'comma-number'

const AdCpmConsumeLogTable = ({ dateFilter }) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  const fetchData = async (page = 1) => {
    setIsLoading(true)
    try {
      const result = await backendApis.getCpmAdUsageLogInfo(
        page,
        pagination.pageSize,
        dateFilter?.startDate || '',
        dateFilter?.endDate || '',
      )
      if (result?.status === 200) {
        setData(result.data.logs || [])
        setPagination((prev) => ({
          ...prev,
          current: page,
          total: result.data.total || 0,
        }))
      }
    } catch (error) {
      console.error('Error fetching CPM ad usage logs:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData(1)
  }, [dateFilter])

  const columns = [
    {
      title: '날짜',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '광고 세트 ID',
      dataIndex: 'adSetId',
      key: 'adSetId',
      width: 200,
    },
    {
      title: '유형',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (type) => {
        const typeConfig = {
          settlement: { color: 'green', text: '정산완료/환불' },
          refund: { color: 'red', text: '중단/환불' },
          register: { color: 'blue', text: '등록' },
        }
        const { color, text } = typeConfig[type] || {}
        return <Tag color={color}>{text}</Tag>
      },
    },
    {
      title: '금액',
      dataIndex: 'info',
      key: 'amount',
      width: 150,
      render: (info, record) => {
        if (record.type === 'refund' || record.type === 'settlement') {
          return `${commaNumber(info.refundTotalAmount || 0)}원`
        }
        return `${commaNumber(Math.abs(info.adTotalCost) || 0)}원`
      },
    },
    {
      title: '무상 크레딧',
      dataIndex: 'info',
      key: 'supportCredit',
      width: 150,
      render: (info, record) => {
        if (record.type === 'refund' || record.type === 'settlement') {
          return `${commaNumber(info.supportCredit || 0)}원`
        }
        return `${commaNumber(Math.abs(info.usedSupportCredit) || 0)}원`
      },
    },
    {
      title: '유상 크레딧',
      dataIndex: 'info',
      key: 'paidCredit',
      width: 150,
      render: (info, record) => {
        if (record.type === 'refund' || record.type === 'settlement') {
          return `${commaNumber(info.paidCredit || 0)}원`
        }
        return `${commaNumber(Math.abs(info.usedPaidCredit) || 0)}원`
      },
    },
    {
      title: '소진된 예산',
      dataIndex: 'info',
      key: 'consumedBudget',
      width: 150,
      render: (info, record) => {
        if (record.type === 'register') {
          return '-'
        }
        if (record.type === 'refund' || record.type === 'settlement') {
          const totalRefund = info.refundTotalAmount || 0
          const originalAmount =
            info.maxImpression * info.costPerImpression || 0
          return `${commaNumber(Math.abs(originalAmount - totalRefund))}원`
        }
        return '-'
      },
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record._id}
      pagination={{
        ...pagination,
        onChange: (page) => {
          fetchData(page)
        },
        position: ['bottomCenter'],
        showSizeChanger: false,
        showTotal: (total) => `총 ${total}개`,
      }}
      loading={isLoading}
    />
  )
}

export default AdCpmConsumeLogTable

const { default: backendApis } = require('../../utils/backendApis')

async function checkPreShipping(orderIds) {
  const orderStatuses = await backendApis.getOrderStatusesByIds(orderIds)
  const isAllPreShipping = orderStatuses?.data.every(
    (order) => order.status === 'pre-shipping',
  )
  return isAllPreShipping
}

export default checkPreShipping

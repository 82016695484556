import React from 'react'
import { observer } from 'mobx-react-lite'
import ItemQuestions from '../components/ItemQuestions'

const ItemQuestionsScreen = observer(() => {
  return (
    <>
      <ItemQuestions />
    </>
  )
})

export default ItemQuestionsScreen

import React, { useState, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import Format from '../utils/format'
import tableUtils from '../utils/tableUtils'
import CancelOrderButton from './buttons/CancelOrderButton'
import moment from 'moment'
import Button from './atoms/button'
import Table from './ADS/molecules/table'
import Pagination from './ADS/atoms/pagination'
import { SiGooglesheets } from 'react-icons/si'
import shortUUID from 'short-uuid'
import ModalRefund from './atoms/ModalRefund'
import ModalUploadShippingInfo from './atoms/ModalUploadShippingInfo'
import { getTotalRefundPrice, getTotalReturnPrice } from '../helper/refund'
import { getSettlementAmount } from '../helper/order'
import RevokeRefundRequestModal from '../screens/RevokeRefundRequestModal'

const PAGE_ORDER_NUM = 10

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

const parseCancelReason = (cancelReason) => {
  if (cancelReason === 'Changed my mind') {
    return '단순 변심'
  }
  return cancelReason
}

const headers = [
  { header: '주문아이디', key: 'orderId', width: 20 },
  { header: '상품아이디', key: 'itemId', width: 20 },
  { header: '합배송아이디', key: 'addressId', width: 20 },
  { header: '판매자 상품코드', key: 'optionCode', width: 20 },
  { header: '상품명', key: 'itemTitle', width: 30 },
  { header: '옵션', key: 'option', width: 40 },
  { header: '수량', key: 'quantity' },
  { header: '상품가격', key: 'totalPrice' },
  { header: '배송비', key: 'shippingFee' },
  { header: '추가지원금', key: 'platformSupportPrice' },
  { header: '쿠폰할인금', key: 'finalDiscountAmount' },
  { header: '정산대상금액(수수료 제외)', key: 'settlementAmount' },
  { header: '주소', key: 'address', width: 50 },
  { header: '우편번호', key: 'postcode' },
  { header: '공동현관 비밀번호', key: 'frontDoorPassword', width: 20 },
  { header: '수령 방법', key: 'detailForReceivingMethod' },
  { header: '수령인', key: 'recipient' },
  { header: '수령인 연락처', key: 'recipientPhoneNumber', width: 20 },
  { header: '주문 시점', key: 'payedAt' },
  { header: '환불 신청 시점', key: 'refundDate' },
  { header: '택배사', key: 'shippingCompany' },
  { header: '운송장번호', key: 'shippingNumber' },
]

const RefundingOrders = observer(({ orders, setOrders }) => {
  const translator = shortUUID()
  const [pageIndex, setPageIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [orderId, setOrderId] = useState(false)
  const [userName, setUserName] = useState('')
  const [showShippginUploadModal, setShowShippingUploadModal] = useState(false)
  const [orderData, setOrderData] = useState([])
  const [selectedOrderIds, setSelectedOrderIds] = useState([])
  const [selectedOrderData, setSelectedOrderData] = useState({})
  const [revokeModalOpen, setRevokeModalOpen] = useState(false)

  const closeModal = () => setRevokeModalOpen(false)

  const toggleRow = (orderId) => {
    setSelectedOrderIds((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId],
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: '선택',
        accessor: 'selection',
        Cell: ({ row }) => (
          <input
            type='checkbox'
            checked={selectedOrderIds.includes(row.original._id)}
            onChange={() => toggleRow(row.original._id)}
          />
        ),
      },
      { Header: '접수일시', accessor: 'refundDate' },
      { Header: '반품품명', accessor: 'itemTitle' },
      { Header: '반품사유', accessor: 'refundDetailedReason', width: 10 },
      {
        Header: '주문 상세',
        accessor: 'detailedCancelReason',
        Cell: ({ row }) => {
          const orderId = row?.values?._id || {}
          const userName = row?.values?.recipient || ''
          return (
            <div>
              <Button
                type='button'
                onClick={() => {
                  setOrderId(orderId)
                  setShowModal(true)
                  setUserName(userName)
                }}
              >
                문의 상세
              </Button>
            </div>
          )
        },
      },
      { Header: '무료반품', accessor: 'returnFree' },
      { Header: '환불정보', accessor: 'refundInfo' },
      { Header: '주문아이디', accessor: '_id' },
    ],
    [selectedOrderIds],
  )

  const data = useMemo(() => {
    return orders
      ?.filter(
        (order) =>
          order.status === 'refunding' &&
          !(
            order?.refundInfo?.refundStatus === 'refunding-retrieving' ||
            order?.refundInfo?.refundStatus === 'refunding-retrieved' ||
            order?.refundInfo?.refundStatus === 'refunding-arrived'
          ),
      )
      .slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.map((order, index) => {
        let timePast = Math.ceil(
          (new Date().getTime() - new Date(order.payedAt).getTime()) /
            (1000 * 60 * 60),
        )
        const daysPast = Math.floor(timePast / 24)
        timePast %= 24
        if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
          if (order?.teamDealInfo?.subAddressInfo) {
            order.addressInfo = order?.teamDealInfo?.subAddressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          } else {
            order.addressInfo = order?.teamDealInfo?.addressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          }
        }
        return {
          ...order,
          index: index + 1 + PAGE_ORDER_NUM * pageIndex,
          itemTitle: (
            <a
              href={order.url
                ?.replace('m.coupang', 'coupang')
                ?.replace('/vm/', '/vp/')}
            >
              {order.itemInfo?.itemTitle}
            </a>
          ),
          option: tableUtils.parseOptionText(
            order?.itemInfo?.optionsInfo,
            order?.selectedOption,
          ),
          returnFree: (
            <>
              <div>
                {order?.itemInfo?.shippingInfo?.isReturnFree ? '신청 상품' : ''}{' '}
              </div>
            </>
          ),
          totalPrice: order?.totalPrice,
          addressId: `${Format.DateToday(order?.payedAt)}${
            checkIfValidUUID(order?.addressInfo?.addressId) &&
            translator.fromUUID(order?.addressInfo?.addressId || order?._id)
          }`.substring(2),
          optionCode: order?.selectedOption?.optionCode || '',
          itemId: order?.itemInfo?.itemId,
          refundDate: moment(order?.refundInfo?.refundRequestedAt).format(
            'YYYY-MM-DD HH:mm',
          ),
          platformSupportPrice:
            (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1),
          finalDiscountAmount:
            order.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(order),
          shippingFee: order?.shippingFee,
          address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          detailAddress: order.addressInfo?.detailAddress,
          recipient: Format.Recipient(order.addressInfo?.recipient),
          recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          timePast: (
            <>
              <div>{moment(order.payedAt).format('YYYY-MM-DD HH:mm')}</div>
              <div>
                ({daysPast > 0 ? `${daysPast}일 ` : ''} {timePast} 시간 전)
              </div>
            </>
          ),
          cancelReason: parseCancelReason(order.refundInfo?.refundReason),
          refundDetailedReason: (
            <>
              <div className='flex-column'>
                <div className='flex-column'>
                  {`${order?.refundInfo?.refundDetailedReason} `} <br />
                </div>
                <div className='mt-1 font-bold '>
                  {order.refundInfo?.refundReason}
                </div>
                <div
                  className='mt-1'
                  style={{ display: 'flex', overflowX: 'auto' }}
                >
                  {order.refundInfo?.refundImageUris?.map((imageUri, index) => (
                    <img
                      key={imageUri + index.toString()}
                      alt={imageUri}
                      src={imageUri}
                      style={{
                        height: 196,
                        width: 196,
                        paddingRight: 16,
                        objectFit: 'contain',
                      }}
                    />
                  ))}
                </div>
              </div>
            </>
          ),
          refundInfo: (
            <div>
              <div className='font-bold text-lg'>총 환불 금액</div>
              <div className='mt-2 text-lg font-bold'>
                {getTotalRefundPrice(order)}
              </div>
              <div className='mt-2'>상품 금액</div>
              <div className='mt-2'>{order?.totalPrice}</div>
              <div className='mt-2'>총 반품비</div>
              <div className='mt-2'>{getTotalReturnPrice(order)}</div>
            </div>
          ),
          status: (
            <div>
              <div>
                <Button
                  type='button'
                  onClick={() => {
                    setShowShippingUploadModal(true)
                    setOrderData(order)
                  }}
                >
                  운송장 업로드
                </Button>
              </div>
              <div className='mt-3'>
                <CancelOrderButton
                  order={order}
                  setOrders={setOrders}
                  status='refunding'
                />
              </div>
              <div />
            </div>
          ),
        }
      })
  }, [
    orders?.filter((order) => order.status === 'refunding')?.length,
    pageIndex,
  ])

  const fetchNextData = async () => {
    if (SellerStore.isLoading) {
      return
    }
    if (
      pageIndex + 1 >
      Math.ceil(
        orders.filter((order) => order.status === 'refunding').length /
          PAGE_ORDER_NUM,
      )
    ) {
      SellerStore.setIsLoading(true)
      const result = await backendApis.getOrdersInStatusBeforeCertainTimeStamp(
        'refunding',
        orders.slice(-1)?.length > 0 && orders.slice(-1)[0]?.payedAt,
      )
      if (result?.status === 200 && result.data?.length > 0) {
        OrderStore.setDisplayedOrders([
          ...OrderStore.displayedOrders,
          ...result.data,
        ])
      }
      SellerStore.setIsLoading(false)
    }
  }

  useEffect(() => fetchNextData(), [pageIndex])

  return (
    <>
      <h2 className='mt-4 mb-4 text-2xl font-bold'>
        반품 접수(
        {
          orders?.filter((order) => {
            return (
              order.status === 'refunding' &&
              !(
                order?.refundInfo?.refundStatus === 'refunding-retrieving' ||
                order?.refundInfo?.refundStatus === 'refunding-retrieved' ||
                order?.refundInfo?.refundStatus === 'refunding-arrived'
              )
            )
          })?.length
        }
        )
      </h2>
      <div className='flex'>
        <div>
          <Button
            appearance='positive'
            size='md'
            onClick={() => {
              if (selectedOrderIds?.length > 1) {
                window.alert('수거 처리는 하나의 상품만 선택해주세요.')
              } else if (selectedOrderIds?.length === 0) {
                window.alert('선택된 주문이 없습니다.')
              } else {
                const selectedData = data?.filter(
                  (e) => e?._id === selectedOrderIds[0],
                )
                setShowShippingUploadModal(true)
                setOrderData(selectedData[0])
              }
            }}
          >
            선택상품 수거 진행
          </Button>
        </div>
        <div className='ml-4'>
          <Button
            appearance='positive'
            size='md'
            onClick={async () => {
              if (selectedOrderIds?.length === 0) {
                window.alert('선택된 주문이 없습니다.')
              } else {
                const selectedDataArray = data?.filter((e) =>
                  selectedOrderIds.includes(e._id),
                )
                if (
                  window.confirm(
                    '반품 요청을 승인하시겠어요?\n확인 버튼을 누르면 반품 요청 승인 및 환불이 진행됩니다.',
                  )
                ) {
                  SellerStore.setIsLoading(true)

                  const updateOrderStatus = async (selectedData) => {
                    let returnFee =
                      selectedData?.itemInfo?.shippingInfo?.returnFee
                    if (selectedData?.cancelReason !== '단순 변심') {
                      returnFee = 0
                    }
                    const result = await backendApis.approveOrderCancel(
                      selectedData._id,
                      selectedData.totalPrice,
                      selectedData.shippingFee,
                      selectedData.itemInfo.shippingInfo.returnFee,
                      parseInt(returnFee, 10),
                    )
                    if (result.status === 200) {
                      OrderStore.setDisplayedOrders(
                        OrderStore.displayedOrders.map((orderTemp) => {
                          if (orderTemp._id === selectedData._id) {
                            return { ...orderTemp, status: 'refunded' }
                          }
                          return orderTemp
                        }),
                      )

                      if (setOrders) {
                        OrderStore.setUserOrders(
                          OrderStore.userOrders.map((orderTemp) => {
                            if (orderTemp._id === selectedData._id) {
                              return {
                                ...orderTemp,
                                status: 'refunded',
                              }
                            }
                            return orderTemp
                          }),
                        )
                      }
                      OrderStore.changeOrdersNumPerStatus('refunding', -1)
                      OrderStore.changeOrdersNumPerStatus('refunded', 1)
                    }
                    return result
                  }

                  try {
                    const promises = selectedDataArray.map((selectedData) =>
                      updateOrderStatus(selectedData),
                    )
                    await Promise.all(promises)
                    SellerStore.setIsLoading(false)
                  } catch (error) {
                    console.error('Error processing refund orders:', error)
                    SellerStore.setIsLoading(false)
                  }
                }
              }
            }}
          >
            선택상품 반품 승인
          </Button>
        </div>
        <div className='ml-4'>
          <Button
            appearance='positive'
            size='md'
            onClick={() => {
              if (selectedOrderIds?.length > 1) {
                window.alert('반품거부 처리는 하나의 상품만 선택해주세요.')
              } else if (selectedOrderIds?.length === 0) {
                window.alert('선택된 주문이 없습니다.')
              } else {
                const selectedData = data?.filter(
                  (e) => e?._id === selectedOrderIds[0],
                )
                if (
                  window.confirm(
                    '반품 요청 거부(반품 철회)를 진행하시겠습니까?\n정당한 사유 없이 반품 요청을 거부할 경우, 판매자에게 패널티가 부여될 수 있습니다.',
                  )
                ) {
                  SellerStore.setIsLoading(true)
                  setSelectedOrderData(selectedData[0])
                  setRevokeModalOpen(true)
                  SellerStore.setIsLoading(false)
                }
              }
            }}
          >
            선택상품 반품 거부
          </Button>
        </div>
      </div>
      <Table columns={columns} data={data} />
      <div className='flex items-center'>
        <Button
          appearance='gray'
          className='flex px-2 py-3 my-2 items-center'
          type='button'
          onClick={async () => {
            SellerStore.setIsLoading(true)
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('주문 내역')
            const worksheetData = orders
              .filter(
                (order) =>
                  order.status === 'refunding' &&
                  !(
                    order?.refundInfo?.refundStatus ===
                      'refunding-retrieving' ||
                    order?.refundInfo?.refundStatus === 'refunding-retrieved' ||
                    order?.refundInfo?.refundStatus === 'refunding-arrived'
                  ),
              )
              .map((order) => {
                const optionsText = order.itemInfo.optionsInfo.optionNames.map(
                  (value, optionIndex) => {
                    const optionDetail =
                      order.itemInfo.optionsInfo.totalOptions[optionIndex][
                        order.selectedOption[optionIndex]
                      ].name
                    return `${optionIndex}. ${value}: ${optionDetail}`
                  },
                )
                const recipientPhoneNumber =
                  order.addressInfo?.recipientPhoneNumber

                const recipientPhoneNumberProcessed = recipientPhoneNumber

                return {
                  orderId: order._id,
                  itemId: order?.itemInfo?.itemId,
                  itemTitle: order.itemInfo?.itemTitle,
                  addressId: `${Format.DateToday(order?.payedAt)}${
                    checkIfValidUUID(order?.addressInfo?.addressId) &&
                    translator.fromUUID(
                      order?.addressInfo?.addressId || order?._id,
                    )
                  }`.substring(2),
                  optionCode: order?.selectedOption?.optionCode || '',
                  option: optionsText.join('\n'),
                  quantity: order.quantity,
                  address: `${order.addressInfo?.postcodeAddress} ${order.addressInfo?.detailAddress}`,
                  postcode: order.addressInfo?.postcode,
                  frontDoorPassword: order.addressInfo?.frontDoorPassword,
                  detailForReceivingMethod:
                    order.addressInfo?.receivingMethod === 'door'
                      ? '문 앞'
                      : order.addressInfo?.detailForReceivingMethod,
                  recipient: Format.Recipient(order.addressInfo?.recipient),
                  recipientPhoneNumber: recipientPhoneNumberProcessed,
                  payedAt: moment(order.payedAt).format('YYYY-MM-DD HH:mm'),
                  refundDate: moment(
                    order?.refundInfo?.refundRequestedAt,
                  ).format('YYYY-MM-DD HH:mm'),
                  shippingCompany: order.shippingInfo.shippingCompanyName,
                  shippingNumber: order.shippingInfo.shippingNumber,
                  totalPrice: order?.totalPrice,
                  shippingFee: order?.shippingFee,
                  platformSupportPrice:
                    (order.selectedOption?.platformSupportPrice || 0) *
                    (order.quantity || 1),
                  finalDiscountAmount:
                    order.selectedCouponInfo?.finalDiscountAmount || 0,
                  settlementAmount: getSettlementAmount(order),
                }
              })
            worksheet.columns = headers
            worksheet.addRows(worksheetData)
            worksheet.getColumn(3).alignment = { wrapText: true }
            const headerRow = worksheet.getRow(1)
            headers.forEach((header, index) => {
              headerRow.getCell(index + 1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFEA3F49' },
              }
            })
            worksheet.getRow(1).font = {
              color: { argb: 'FFFFFFFF' },
              bold: true,
            }
            const mimeType = {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], mimeType)
            const saveDate = new Date()
            const year = saveDate.getFullYear()
            const month = saveDate.getMonth() + 1
            const date = saveDate.getDate()
            saveAs(
              blob,
              `${
                SellerStore.sellerInfo.sellerDisplayName
              }_환불 신청내역_${year}-${month < 10 ? `0${month}` : month}-${
                date < 10 ? `0${date}` : date
              }.xlsx`,
            )
            SellerStore.setIsLoading(false)
          }}
        >
          <SiGooglesheets className='mr-2' />
          엑셀 추출하기
        </Button>
        <Pagination
          page={pageIndex}
          setPage={setPageIndex}
          total={OrderStore.ordersNumPerStatus.refunding || 0}
          limit={PAGE_ORDER_NUM}
        />
        <ModalRefund
          orderId={orderId}
          modalOn={showModal}
          setShowModal={setShowModal}
          userName={userName}
        />
        <ModalUploadShippingInfo
          orders={orders}
          setOrders={setOrders}
          orderData={orderData}
          modalOn={showShippginUploadModal}
          setShowModal={setShowShippingUploadModal}
        />
        {revokeModalOpen && (
          <RevokeRefundRequestModal
            order={selectedOrderData}
            setOrders={setOrders}
            status='refunding'
            closeModal={closeModal}
          />
        )}
      </div>
    </>
  )
})

export default RefundingOrders

import React from 'react'
import Tooltip from '../atoms/tooltip'

const Statistics = ({ unit, index, number, description, tooltip }) => {
  return (
    <div className='shadow-lg rounded-2xl p-4 bg-white h-36 min-w-[405px]'>
      <div className='flex items-center'>
        <p className='text-md text-black'>
          {index}
          {tooltip && <Tooltip text={tooltip} />}
        </p>
      </div>
      <div className='flex flex-col justify-start'>
        <p className='text-gray-700 text-3xl text-left font-bold my-4'>
          {number}
          <span className='text-sm'>{unit}</span>
        </p>
        <div className='flex items-center text-3xl'>
          <span className='text-red-500'>{description}</span>
        </div>
      </div>
    </div>
  )
}

export default Statistics

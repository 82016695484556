const dealConfig = {
  dealManagementCondition: {
    lureDeal: {
      matchCondition: {
        deletedAt: null,
      },
      projectCondition: {
        itemId: 1,
        itemTitle: 1,
        dealOptionsInfo: 1,
        status: 1,
        tenMinDealMetaData: 1,
        createdAt: 1,
      },
    },
    tenMinDeal: {
      matchCondition: {
        deletedAt: null,
      },
      projectCondition: {
        itemId: 1,
        itemTitle: 1,
        dealOptionsInfo: 1,
        status: 1,
        tenMinDealMetaData: 1,
        createdAt: 1,
      },
    },
    timeDeal: {},
  },
}

export default dealConfig

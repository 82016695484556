const Tag = ({ text = '', onClick, isRed = false }) => {
  if (!text) return null
  return (
    <span
      style={{
        display: 'inline-block',
        position: 'relative',
        padding: '3px 9px',
        background: isRed ? '#EF4444' : '#eee',
        margin: '8px 8px 8px 0px',
        borderRadius: 4,
        marginRight: onClick ? 16 : 8,
        color: isRed ? 'white' : 'black',
        textAlign: 'center',
      }}
    >
      {text}{' '}
      {typeof onClick === 'function' && (
        <button
          type='button'
          onClick={onClick}
          style={{
            background: '#3F3F46',
            color: 'white',
            border: 0,
            fontSize: 9,
            fontWeight: 'bold',
            position: 'absolute',
            zIndex: 3,
            width: 15,
            height: 15,
            borderRadius: 10,
            top: -6,
            right: -6,
          }}
        >
          X
        </button>
      )}
    </span>
  )
}

export default Tag

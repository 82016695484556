import { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'

const useLureDeal = ({ type, itemsInfo = [] }) => {
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const itemIds = itemsInfo.map((each) => each._id)

      const { data } = await backendApis.loadSellerDeals(itemIds, 'lureDeal')
      const candidatesIdArray = data.map((elem) => elem.itemId.toString())
      const projectCondition = {}
      let targetItemsInfo = []
      const result = await backendApis.loadItemSpecificInfo(
        candidatesIdArray,
        projectCondition,
      )
      if (result?.status === 200) {
        targetItemsInfo = result?.data
      }
      if (data?.length) {
        const result = targetItemsInfo.map((item) => {
          if (
            data
              .map((elem) => elem.itemId.toString())
              .includes(item._id.toString())
          ) {
            const matchDealInfo = data.find(
              (elem) => elem.itemId.toString() === item._id.toString(),
            )
            return {
              ...item,
              ...matchDealInfo,
              _id: item._id,
              dealOptionsInfo: matchDealInfo.dealOptionsInfo,
            }
          }
          return item
        })
        setData(result)
      } else {
        setData([])
      }
    }
    if (type && itemsInfo?.length) fetchData()
  }, [type, itemsInfo])
  return { data, setData }
}

export default useLureDeal

import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { Spin } from 'antd'

const CpmAdApplicationGraph = () => {
  const [chartData, setChartData] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchCpmApplicationData = async () => {
    try {
      const result = await backendApis.getRecentCpmAdSetList({ days: 7 })

      if (result?.status === 200) {
        console.log(result?.data)
        const formattedData = result?.data.adCPMSetList.map((item) => ({
          ...item,
          startDate: new Date(item.startDate).toISOString().split('T')[0],
        }))
        setChartData(formattedData)
      }
    } catch (error) {
      console.error('데이터 로딩 중 오류 발생:', error)
    } finally {
      setLoading(false)
    }
  }

  const processChartData = () => {
    // 오늘 날짜 기준으로 7일 날짜 배열 생성 (오늘이 3번째 날이 되도록)
    const dates = Array.from({ length: 7 }, (_, i) => {
      const date = new Date()
      date.setHours(date.getHours() + 9) // KST 기준
      date.setDate(date.getDate() - i + 4) // +2를 하여 오늘을 3번째로 이동
      return date.toISOString().split('T')[0]
    }).reverse()

    // 날짜별 데이터 초기화
    const groupedData = dates.reduce((acc, date) => {
      acc[date] = 0
      return acc
    }, {})

    // 실제 데이터 집계
    chartData?.forEach((item) => {
      const startDate = new Date(item.startDate)
      const endDate = new Date(item.endDate)

      // startDate부터 endDate까지 순회
      const currentDate = new Date(startDate)
      while (currentDate <= endDate) {
        const dateStr = currentDate.toISOString().split('T')[0]
        if (dateStr in groupedData) {
          groupedData[dateStr] += item.maxImpressions // 전체 노출량을 각 날짜에 더함
        }
        currentDate.setDate(currentDate.getDate() + 1)
      }
    })

    const todayLabel = new Date(new Date().setHours(new Date().getHours() + 9))
      .toISOString()
      .split('T')[0]

    const chartLabels = dates.map((date) =>
      date === todayLabel ? '오늘' : date.slice(5),
    )

    return {
      labels: chartLabels,
      data: dates.map((date) => groupedData[date]),
    }
  }

  const { labels, data } = processChartData()

  const chartConfig = {
    data: {
      labels,
      datasets: [
        {
          label: '일별 전체 CPM 광고 신청 수',
          data,
          backgroundColor: '#F9C0AB',
          borderRadius: 8,
          maxBarThickness: 100,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: '#1F2937',
          padding: 12,
          titleFont: {
            size: 14,
            weight: 'bold',
          },
          bodyFont: {
            size: 13,
          },
          callbacks: {
            title: (items) => {
              return `${items[0].label} 신청 현황`
            },
            label: (item) => {
              return `${item.formattedValue.toLocaleString()}회 노출`
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 12,
            },
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: '#E5E7EB',
          },
          ticks: {
            font: {
              size: 12,
            },
            stepSize: 1,
          },
        },
      },
    },
  }

  useEffect(() => {
    fetchCpmApplicationData()
  }, [])

  if (loading) {
    return (
      <div className='flex justify-center items-center h-48 bg-white rounded-lg shadow'>
        <Spin tip='데이터를 불러오는 중...' />
      </div>
    )
  }

  return (
    <div className='bg-white p-6 rounded-b-xl shadow'>
      <div className='mb-4'>
        <h3 className='text-lg font-semibold text-gray-900'>
          전체 CPM 광고 신청 일별 현황
        </h3>
        <p className='text-sm text-gray-500'>
          신청이 적은 날짜에 광고를 등록하면 더 높은 노출 기회를 얻을 수
          있습니다
        </p>
      </div>
      <div className='h-64'>
        <Bar data={chartConfig.data} options={chartConfig.options} />
      </div>
    </div>
  )
}

export default CpmAdApplicationGraph

import { makeObservable, observable, action, runInAction } from 'mobx'
import backendApis from '../utils/backendApis'
import SellerStore from './SellerStore'
import { QuestionType } from '../data/question'

class ItemStore {
  itemsInfo = []
  preShippingPreExcelItems = []
  itemQuestions = []
  answeredQuestions = []
  questionsFromAdmin = []
  answeredQuestionsFromAdmin = []
  searchedLargeCategories = []
  searchedMediumCategories = []
  searchedSmallCategories = []
  categories = []
  itemInfoOnEdit = {}
  shippingFeesTemplate = []
  itemScores = {}
  questionSearchCondition = {}
  questionReloadToggle = false
  // itemInfoInAnalysis = {}
  itemScoreDetailInfo = {
    itemInfo: {},
    oldOptionsInfo: {
      optionNames: [''],
      totalOptions: [
        [
          {
            name: '',
            img: null,
          },
        ],
      ],
      optionPrices: [
        {
          individualPurchasePrice: 100,
          teamPurchasePrice: 100,
          timeDealSelfSupportPrice: 0,
          stockNumber: 10,
          sold_out: false,
          hurry_up: false,
          platformSupportPrice: 0,
          selfSupportPrice: 0,
          alwayzAdditionalSupportPrice: 0,
        },
      ],
    },
    optionsInfo: {
      optionNames: [''],
      totalOptions: [
        [
          {
            name: '',
            img: null,
          },
        ],
      ],
      optionPrices: [
        {
          individualPurchasePrice: 100,
          teamPurchasePrice: 100,
          timeDealSelfSupportPrice: 0,
          stockNumber: 10,
          sold_out: false,
          hurry_up: false,
          platformSupportPrice: 0,
          selfSupportPrice: 0,
          alwayzAdditionalSupportPrice: 0,
        },
      ],
    },
    oldItemScore: 0,
    newItemScore: 0,
    oldRepresentingTeamPurchasePrice: 100,
    newRepresentingTeamPurchasePrice: 100,
    representingPriceChange: 0,
    oldFinalOptionPrices: [],
    newFinalOptionPrices: [],
    averageChangePercentage: 0,
    newRepresentingIndividualPurchasePrice: 100,
    discountRate: 0,
    orderPumpingRate: 1,
    isGuaranteed: false,
    isFromAdimin: false,
  }

  itemOrderGuaranteesInfo = []
  loadedItemOrderGuarantees = false

  constructor() {
    makeObservable(this, {
      itemsInfo: observable,
      preShippingPreExcelItems: observable,
      itemQuestions: observable,
      answeredQuestions: observable,
      questionsFromAdmin: observable,
      answeredQuestionsFromAdmin: observable,
      categories: observable,
      itemInfoOnEdit: observable,
      shippingFeesTemplate: observable,
      itemScores: observable,
      questionSearchCondition: observable,
      questionReloadToggle: observable,
      // itemInfoInAnalysis: observable,
      itemScoreDetailInfo: observable,
      itemOrderGuaranteesInfo: observable,
      loadedItemOrderGuarantees: observable,
      searchedLargeCategories: observable,
      searchedMediumCategories: observable,
      searchedSmallCategories: observable,
      /// /
      setItemsInfo: action,
      setPreShippingPreExcelItems: action,
      setItemQuestions: action,
      setAnsweredQuestions: action,
      setQuestionsFromAdmin: action,
      setAnsweredQuestionsFromAdmin: action,
      setCategories: action,
      setItemInfoOnEdit: action,
      setShippingFeesTemplate: action,
      setItemScores: action,
      setSearchedLargeCategories: action,
      setSearchedMediumCategories: action,
      setSearchedSmallCategories: action,
      setQuestionSearchCondition: action,
      setQuestionReloadToggle: action,
      // setItemInfoInAnalysis: action,
      setItemScoreDetailInfo: action,
      setItemScoreDetailOptionInfo: action,
      setItemOrderGuaranteesInfo: action,
      setLoadedItemOrderGuarantees: action,
    })
  }

  setPreShippingPreExcelItems(preShippingPreExcelItems) {
    this.preShippingPreExcelItems = preShippingPreExcelItems
  }

  setAnsweredQuestionsFromAdmin(answeredQuestionsFromAdmin) {
    this.answeredQuestionsFromAdmin = answeredQuestionsFromAdmin
  }

  setLoadedItemOrderGuarantees(loadedItemOrderGuarantees) {
    this.loadedItemOrderGuarantees = loadedItemOrderGuarantees
  }

  setItemOrderGuaranteesInfo(itemOrderGuaranteesInfo) {
    this.itemOrderGuaranteesInfo = itemOrderGuaranteesInfo
  }

  setSearchedLargeCategories(searchedLargeCategories) {
    this.searchedLargeCategories = searchedLargeCategories
  }

  setSearchedMediumCategories(searchedMediumCategories) {
    this.searchedMediumCategories = searchedMediumCategories
  }

  setSearchedSmallCategories(searchedSmallCategories) {
    this.searchedSmallCategories = searchedSmallCategories
  }

  setQuestionSearchCondition(questionSearchCondition) {
    this.questionSearchCondition = questionSearchCondition
  }

  setQuestionReloadToggle(questionReloadToggle) {
    this.questionReloadToggle = questionReloadToggle
  }

  setItemScoreDetailInfo(itemScoreDetailInfo) {
    this.itemScoreDetailInfo = itemScoreDetailInfo
  }

  setItemScoreDetailOptionInfo(optionsInfo) {
    runInAction(() => {
      this.itemScoreDetailInfo.optionsInfo = optionsInfo
    })
  }

  setItemScoreDetailResults(
    newRepresentingTeamPurchasePrice,
    representingPriceChange,
    newFinalOptionPrices,
    averageChangePercentage,
    newRepresentingIndividualPurchasePrice,
    discountRate,
    newItemScore,
    orderPumpingRate,
    isGuaranteed,
  ) {
    runInAction(() => {
      this.itemScoreDetailInfo.newRepresentingTeamPurchasePrice =
        newRepresentingTeamPurchasePrice
      this.itemScoreDetailInfo.representingPriceChange = representingPriceChange
      this.itemScoreDetailInfo.newFinalOptionPrices = newFinalOptionPrices
      this.itemScoreDetailInfo.averageChangePercentage = averageChangePercentage
      this.itemScoreDetailInfo.newRepresentingIndividualPurchasePrice =
        newRepresentingIndividualPurchasePrice
      this.itemScoreDetailInfo.discountRate = discountRate
      this.itemScoreDetailInfo.newItemScore = newItemScore
      this.itemScoreDetailInfo.orderPumpingRate = orderPumpingRate
      this.itemScoreDetailInfo.isGuaranteed = isGuaranteed
    })
  }

  // setItemInfoInAnalysis(itemInfoInAnalysis) {
  //   this.itemInfoInAnalysis = itemInfoInAnalysis
  // }

  setItemScores(itemScores) {
    this.itemScores = itemScores
  }

  setShippingFeesTemplate(shippingFeesTemplate) {
    this.shippingFeesTemplate = shippingFeesTemplate
  }

  setItemInfoOnEdit(itemInfoOnEdit) {
    this.itemInfoOnEdit = itemInfoOnEdit
  }

  setCategories(categories) {
    this.categories = categories
  }

  setItemsInfo(itemsInfo) {
    this.itemsInfo = itemsInfo
  }

  setItemQuestions(itemQuestions) {
    this.itemQuestions = itemQuestions
  }

  setAnsweredQuestions(answeredQuestions) {
    this.answeredQuestions = answeredQuestions
  }

  setQuestionsFromAdmin(questionsFromAdmin) {
    this.questionsFromAdmin = questionsFromAdmin
  }

  async loadPreShippingPreExcelItems() {
    const result = await backendApis.getPreShippingPreExcelItems()

    if (result?.status === 200 && Array.isArray(result?.data)) {
      this.setPreShippingPreExcelItems(result.data)
    }
  }

  async loadUnansweredQuestions(limit = -1, skip = 0) {
    const result = await backendApis.loadUnansweredQuestions(
      QuestionType.ITEM_QUESTION.description,
      limit,
      skip,
    )
    const resultFromAdmin = await backendApis.loadUnansweredQuestions(
      QuestionType.QUESTION_FROM_ADMIN.description,
      limit,
      skip,
    )
    if (result?.status === 200 && result?.data)
      this.setItemQuestions(result?.data)
    if (resultFromAdmin?.status === 200 && resultFromAdmin?.data)
      this.setQuestionsFromAdmin(resultFromAdmin?.data)
  }

  async loadQuestionsFromAdmin() {
    const result = await backendApis.loadQuestionsFromAdmin('')
    const result2 = await backendApis.loadAnsweredQuestionsFromAdmin('')
    if (result?.status === 200 && result?.data) {
      this.setQuestionsFromAdmin(result?.data)
    }
    if (result2?.status === 200 && result2?.data) {
      this.setAnsweredQuestionsFromAdmin(result2?.data)
    }
  }

  async loadItemsInfo(itemIds) {
    const itemsInfoTemp = []
    const chunkSize = 100
    for (let i = 0; i < itemIds?.length; i += chunkSize) {
      // console.log('itemids', itemIds.slice(i, i + chunkSize))
      const result = await backendApis.loadSellerItems(
        itemIds?.slice(i, i + chunkSize),
      )

      if (result?.status === 200 && result.data) {
        itemsInfoTemp.push(...result.data)
      }
    }
    this.setItemsInfo(itemsInfoTemp)
  }

  async loadCategories() {
    const result = await backendApis.loadCategories()
    if (result?.status === 200 && result.data) {
      this.setCategories(result.data)
    }
  }

  async loadShippingFeesTemplate() {
    const result = await backendApis.loadShippingFeesTemplate()
    if (result?.status === 200 && result.data) {
      this.setShippingFeesTemplate(result.data)
      return result
    }
    return result
  }

  async loadItemScore(itemId) {
    const result = await backendApis.loadSellerItemScores(itemId, 1, 3)
    // console.log(`item score for ${itemId}: `, result)
    if (result?.status === 2000) {
      // runInAction(() => {
      //   this.itemScores[itemId] = result.data
      // })
      SellerStore.setItemStatsByItemId(itemId, result.data)
    }
  }

  async loadItemOrderGuarantees() {
    const result = await backendApis.getSellerOrderGuarantees()
    if (result?.status === 200) {
      // console.log(`item order guarantees: ${JSON.stringify(result.data)}`)
      this.setItemOrderGuaranteesInfo(
        result.data.sort(
          (a, b) => (b.timeProgressDays || 0) - (a.timeProgressDays || 0),
        ),
      )
      this.setLoadedItemOrderGuarantees(true)
    }
  }
}

export default new ItemStore()

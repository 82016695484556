import backendApis from '../../utils/backendApis'
import newbieGuideStage from '../../data/newbieGuideStage'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'

export default async function newbieGuideClearInfoRegister(stage) {
  const tutorialInfo = SellerStore.sellerInfo.tutorialInfo
  if (
    !SellerStore.sellerInfo.tutorialInfo?.filter(
      (e) => e.stageName === newbieGuideStage[stage] && e.cleared === true,
    ).length > 0
  ) {
    const result = await backendApis.updateSellerTutorialInfo(
      newbieGuideStage[stage],
    )
    if (result?.status === 200) {
      const tutorialInfoTemp = [
        {
          stageName: newbieGuideStage[stage],
          cleared: true,
        },
      ]
      if (tutorialInfo) {
        tutorialInfoTemp.push(...tutorialInfo)
      }
      const newTutorialInfo = Array.from(new Set(tutorialInfoTemp))
      SellerStore.setSellerInfo({
        ...SellerStore.sellerInfo,
        tutorialInfo: [...newTutorialInfo],
      })
    }
    if (newbieGuideStage[stage] === 'reward') {
      alert('부스팅 효과가 적용되었습니다!')
    } else {
      alert('퀘스트를 완료하셨습니다.')
    }
  }
}

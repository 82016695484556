import Button from '../atoms/button'
import { observer } from 'mobx-react-lite'
import OrderStore from '../../stores/OrderStore'
import TableData from '../atoms/tableData'
import commaNumber from 'comma-number'
import { useHistory } from 'react-router-dom'

const SalesInfo = observer(() => {
  const className = 'items-start w-1/4 border-2 border-transparent'
  const className2 = 'border-2 border-transparent items-start font-bold w-1/4'
  const history = useHistory()

  return (
    <>
      <div className='flex flex-col flex-1 select-none'>
        <div className='flex px-10 py-10 bg-white shadow-md rounded-xl'>
          <table className='w-full border-2 border-transparent'>
            <div className='w-full text-lg font-bold rounded-xl'>주문 현황</div>
            <div className='w-full pb-2 text-sm font-normal text-sub-500 '>
              실시간 주문 정보
            </div>
            <tr>
              <TableData className={className}>팀 모집 완료</TableData>
              <TableData className='items-start w-1/4 font-bold border-2 border-transparent text-theme-500'>
                <button
                  type='button'
                  onClick={() => {
                    history.push({
                      pathname: '/shippings',
                    })
                  }}
                >
                  {commaNumber(
                    OrderStore.ordersNumPerStatus['pre-shipping-pre-excel'] ||
                      0,
                  )}
                  건
                </button>
              </TableData>
              <TableData className={className}>상품 준비</TableData>
              <TableData className={className2}>
                <button
                  type='button'
                  onClick={() => {
                    history.push({
                      pathname: '/shippings',
                    })
                  }}
                >
                  {commaNumber(
                    OrderStore.ordersNumPerStatus['pre-shipping-post-excel'] ||
                      0,
                  )}
                  건
                </button>
              </TableData>
            </tr>
            <tr>
              <TableData className={className}>발송중</TableData>
              <TableData className={className2}>
                <button
                  type='button'
                  onClick={() => {
                    history.push({
                      pathname: '/shippings',
                    })
                  }}
                >
                  {commaNumber(
                    OrderStore.ordersNumPerStatus['shipping-sending'] || 0,
                  )}
                  건
                </button>
              </TableData>
              <TableData className={className}>배송중</TableData>
              <TableData className={className2}>
                <button
                  type='button'
                  onClick={() => {
                    history.push({
                      pathname: '/shippings',
                    })
                  }}
                >
                  {commaNumber(OrderStore.ordersNumPerStatus.shipping || 0)}건
                </button>{' '}
              </TableData>
            </tr>
            <tr>
              <TableData className={className}>배송완료</TableData>
              <TableData className={className2}>
                <button
                  type='button'
                  onClick={() => {
                    history.push({
                      pathname: '/shippings',
                    })
                  }}
                >
                  {commaNumber(OrderStore.ordersNumPerStatus.arrived || 0)}건
                </button>
              </TableData>
              <TableData className={className}>
                배송중인 상품 배송중지 요청
              </TableData>
              <TableData className='items-start w-1/4 font-bold border-2 border-transparent text-theme-500'>
                <button
                  type='button'
                  onClick={() => {
                    history.push({
                      pathname: '/refunds',
                    })
                  }}
                >
                  {commaNumber(
                    OrderStore.ordersNumPerStatus['canceling-shipping'] || 0,
                  )}
                  건
                </button>
              </TableData>
            </tr>
            <tr>
              <TableData className={className}>출고중지요청</TableData>
              <TableData className='items-start w-1/4 font-bold border-2 border-transparent text-theme-500'>
                <button
                  type='button'
                  onClick={() => {
                    history.push({
                      pathname: '/canceling-shippings',
                    })
                  }}
                >
                  {commaNumber(
                    OrderStore.ordersNumPerStatus[
                      'canceling-pre-shipping-post-excel'
                    ] || 0,
                  )}
                  건
                </button>
              </TableData>
              <TableData className={className}>반품 접수</TableData>
              <TableData className='items-start w-1/4 font-bold border-2 border-transparent text-theme-500'>
                <button
                  type='button'
                  onClick={() => {
                    history.push({
                      pathname: '/refunds',
                    })
                  }}
                >
                  {commaNumber(OrderStore.ordersNumPerStatus.refunding || 0)}건{' '}
                </button>{' '}
              </TableData>
            </tr>
          </table>
        </div>
      </div>
    </>
  )
})

export default SalesInfo

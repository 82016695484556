const adSettingByTab = {
  0: {
    type: 'searchAd',
    name: '판매 보장 광고',
    category: 'item',
    log: 'searchAdRegisterButton',
    roas: true,
  },
  1: {
    type: 'cpcAd',
    name: '파워 퍼포먼스 광고',
    category: 'item',
    log: 'cpcAdRegisterButton',
    roas: true,
  },
  2: {
    type: 'quizAd',
    name: '퀴즈 광고',
    category: 'item',
    log: 'quizAdRegisterButton',
    roas: true,
  },
  3: {
    type: 'sellerStoreAd',
    name: '상점 광고',
    category: 'item',
    log: 'storeAdRegisterButton',
    roas: true,
  },
  4: {
    type: 'eggBreakAd',
    name: '포춘쿠키 광고',
    category: 'item',
    log: 'eggAdRegisterButton',
    roas: true,
  },
  5: {
    type: 'trendQuizAd',
    name: '우주 최저가 광고',
    category: 'item',
    log: 'trendQuizAdRegisterButton',
    roas: true,
  },
}
export default adSettingByTab

import { observer } from 'mobx-react-lite'
import Button from '../atoms/button'
import SellerStore from '../../stores/SellerStore'
import { useState, useEffect } from 'react'
import { FcUp } from 'react-icons/fc'
import RankRevenueBarChart from '../atoms/rankRevenueBarChart'

const RevenueByRank = observer(({ button = true }) => {
  const [data, setData] = useState([])
  useEffect(() => {
    const retrieveSellerRevenueByRank = async () => {
      const {
        status, // 200 is ok
        data, // { S: { sum : 19200, count: 3}, ... }
      } = await SellerStore.loadSellerRevenueByItemRank()
      if (status === 200) setData(data)
    }
    retrieveSellerRevenueByRank()
  }, [])

  const [S, A, B, C] = data
  const displayNudgePrice = S && A && B && (S.avg > B.avg || A.avg > B.avg)

  return (
    <>
      <div className='flex flex-col flex-1 h-full px-10 py-10 bg-white shadow-md select-none rounded-xl'>
        <div className='flex flex-row items-center justify-between w-full mb-5'>
          <div className='text-lg font-bold'>
            <div>등급 별 평균 매출 현황</div>
            <div className='text-sm font-normal text-sub-500'>
              최근 30일 기준(단위: 원/등급)
            </div>
          </div>
          {displayNudgePrice && (
            <div className='grid align-middle justify-items-end'>
              <div className='flex flex-row'>
                <>
                  <FcUp className='relative w-6 h-6' />
                </>
                <div className='row-span-2 mb-1 font-bold tracking-tighter text-gray-600 underline text-m underline-offset-4 decoration-cyan-700'>
                  B, C 등급의 매출을 올리고 싶다면?
                </div>
              </div>
              {button && (
                <Button
                  appearance='positiveSub'
                  type='button'
                  href='/items'
                  onClick={async () => {
                    await SellerStore.tracePriceNudgeOfRankRevenueLog()
                  }}
                >
                  매출 올리러가기
                </Button>
              )}
            </div>
          )}
        </div>
        <div className='flex items-end flex-1'>
          <RankRevenueBarChart chartData={data.map(({ avg }) => avg)} />
        </div>
      </div>
    </>
  )
})

export default RevenueByRank

import React, { useState } from 'react'
import { Modal, message, Table } from 'antd'
import backendApis from '../../../utils/backendApis'

const apiGroups = {
  unitOption: {
    getBiddingInfo: backendApis.getUnitOptionBiddingInfo,
    updateBiddingInfo: backendApis.updateUnitOptionBiddingInfo,
  },
  generalOption: {
    getBiddingInfo: backendApis.getGeneralOptionBiddingInfo,
    updateBiddingInfo: backendApis.updateGeneralOptionBiddingInfo,
  },
}

const columns = [
  {
    title: '옵션명',
    dataIndex: 'itemTitle',
    key: 'itemTitle',
    align: 'center',
  },
  {
    title: '타입',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    render: (text) => (text === 'unitOption' ? '원물' : '브랜드'),
  },
  {
    title: '현재 입찰가',
    dataIndex: 'optionTeamPurchasePrice',
    key: 'optionTeamPurchasePrice',
    align: 'center',
    render: (text) => `${text?.toLocaleString()}원`,
  },
  {
    title: '1위 입찰가',
    dataIndex: 'currentLowestBiddingPrice',
    key: 'currentLowestBiddingPrice',
    align: 'center',
    render: (text) => `${text?.toLocaleString()}원`,
  },
  {
    title: '변경 입찰가',
    dataIndex: 'minimumBiddingPrice',
    key: 'minimumBiddingPrice',
    align: 'center',
    render: (text) => `${text?.toLocaleString()}원`,
  },
  {
    title: '기획전 예상 매출',
    dataIndex: 'projectedGMV',
    key: 'projectedGMV',
    align: 'center',
    render: (text) => `${text?.toLocaleString()}원`,
  },
]

const updateBiddingForItem = async (item) => {
  const biddingPrice = item?.minimumBiddingPrice
  const itemId = item?.itemId
  const optionId = item?.optionId
  const biddingOptionId = item?.unitOptionId || item?.generalOptionId
  const values = {
    biddingType: 'fixed',
    biddingPrice,
    minBiddingPrice: biddingPrice,
  }

  try {
    const response = await apiGroups[item.type].updateBiddingInfo({
      biddingOptionId,
      itemId,
      optionId,
      values,
      isReclaimed: true,
    })
    return response?.status === 200
  } catch (error) {
    console.error('Bidding API failed', error)
    return false
  }
}

function chunkArray(array, size) {
  const chunkedArray = []
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size))
  }
  return chunkedArray
}

const bidForSelectedItems = async (
  selectedRowItems,
  setSelectedRowItems,
  setIsModalVisible,
  onSubmit,
) => {
  const concurrencyLimit = 10
  const chunks = chunkArray(selectedRowItems, concurrencyLimit)

  for (const chunk of chunks) {
    const results = await Promise.all(
      chunk.map(async (currentItem) => {
        // 'item' 대신 'currentItem' 사용
        try {
          await updateBiddingForItem(currentItem)
          return { success: true, item: currentItem }
        } catch (error) {
          return { success: false, item: currentItem, error }
        }
      }),
    )

    const allSuccess = results.every((result) => result.success)

    if (!allSuccess) {
      message.error(
        '일부 옵션에 대한 입찰에 실패했습니다. 새로고침 후 다시 진행해주세요.',
      )
    }
    onSubmit(results)
  }

  message.success('입찰에 성공했습니다.')
  setIsModalVisible(false)
}

function OneClickBiddingModal({
  selectedItems,
  setIsModalVisible,
  isVisible,
  onClose = () => {
    console.log('onClose')
  },
  onSubmit = () => {
    console.log('onSubmit')
  },
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState(
    selectedItems.map((item) => item.itemId),
  )
  const [selectedRowItems, setSelectedRowItems] = useState(selectedItems)

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys)
      const newSelectedItems = selectedItems?.filter((item) =>
        newSelectedRowKeys?.includes(item.itemId?.toString()),
      )
      setSelectedRowItems(newSelectedItems)
    },
  }

  const handleOk = (selectedRowItems, setSelectedRowItems, onSubmit) => {
    // 일괄 입찰 api 호출
    bidForSelectedItems(
      selectedRowItems,
      setSelectedRowItems,
      setIsModalVisible,
      onSubmit,
    )
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Modal
      title='원클릭으로 1위 탈환하기'
      width={1200}
      open={isVisible}
      onOk={() => handleOk(selectedRowItems, setSelectedRowItems, onSubmit)}
      onCancel={handleCancel}
      okText='원클릭 입찰하기'
      okButtonProps={{ type: 'primary', className: 'bg-blue-500' }}
      cancelText='닫기'
      cancelButtonProps={{
        type: 'text',
      }}
    >
      <Table
        dataSource={selectedItems}
        columns={columns}
        rowKey='itemId'
        pagination={false}
        rowSelection={rowSelection}
      />
    </Modal>
  )
}

export default OneClickBiddingModal

import { Button, Input, Select, Space, Switch } from 'antd'
import React, { useState } from 'react'
import backendApis from '../../../utils/backendApis'
import { BeatLoader } from 'react-spinners'
import { IoIosCloseCircle } from 'react-icons/io'
import { CiCirclePlus } from 'react-icons/ci'

const StoreCrawlStatus = ({ crawlQueueInfo, registerCallback = () => {} }) => {
  const [generalUrl, setGeneralUrl] = useState('https://smartstore.naver.com/')
  const [uniqueUrl, setUniqueUrl] = useState('')
  const regex = /[^A-Za-z0-9\s\-_]/

  return (
    <div className='flex flex-col items-center justify-end flex-1 h-full rounded-xl'>
      <div className='flex flex-col items-center justify-start w-full overflow-y-scroll max-h-54'>
        {crawlQueueInfo?.length < 2 && crawlQueueInfo?.length !== 0 && (
          <div className='flex flex-col items-center justify-center flex-1 w-full h-full p-5 mb-2 text-sm border-2 border-dashed text-slate-700 rounded-xl'>
            <div className='flex flex-row items-center justify-center flex-1'>
              <CiCirclePlus className='mr-2' size={16} />
              복수 스토어 연동도 가능합니다.
            </div>
          </div>
        )}
        {crawlQueueInfo?.length > 0 ? (
          <>
            {crawlQueueInfo?.map((queue, idx) => {
              const crawlStatus = (queue) => {
                if (queue?.sellerUpdatedAt < queue?.updatedAt)
                  return (
                    <span className='font-bold text-blue-500'>연동 완료</span>
                  )
                if (queue?.needCrawl)
                  return (
                    <div className='font-bold text-blue-500'>
                      <BeatLoader
                        size={6}
                        speedMultiplier={0.2}
                        color='#14b8a6'
                      />
                    </div>
                  )
                return (
                  <span className='font-bold text-slate-500'>연동 중단</span>
                )
              }
              return (
                <div
                  key={queue?._id?.toString()}
                  className='flex flex-col justify-start flex-1 w-full p-5 mb-2 bg-slate-200 rounded-xl'
                >
                  <div className='flex flex-row items-center justify-between flex-1 text-sm'>
                    <div className='flex items-center mr-5 font-bold text-blue-500 whitespace-nowrap'>
                      {`스토어 ${idx + 1}`}
                    </div>
                    <div className='mr-3'>{queue?.crawlLink}</div>

                    <div className='flex flex-row items-center justify-end flex-1 w-full'>
                      <Switch
                        className={`col-start-1 col-end-2 mr-3 ${
                          queue?.needCrawl ? 'bg-blue-500' : 'bg-slate-500'
                        }`}
                        checked={queue?.needCrawl || false}
                        checkedChildren='ON'
                        unCheckedChildren='OFF'
                        onChange={async () => {
                          if (
                            window.confirm(
                              `스토어 ${idx + 1}의 크롤링을 ${
                                queue?.needCrawl ? '중단' : '시작'
                              }하시겠습니까? 
주소: ${queue?.crawlLink}`,
                            )
                          ) {
                            await backendApis.turnCrawlQueueStatus(
                              queue?._id,
                              !queue?.needCrawl,
                            )
                            registerCallback()
                          }
                        }}
                      />
                      <div className='w-16 col-start-2 col-end-3 mr-3 text-center'>
                        {crawlStatus(queue)}
                      </div>
                      <div
                        onClick={async () => {
                          if (
                            window.confirm(
                              `스토어 ${idx + 1} 연동 신청을 삭제하시겠습니까?
주소: ${queue?.crawlLink}`,
                            )
                          ) {
                            const result = await backendApis.deleteCrawlQueue(
                              queue?._id,
                            )
                            if (result?.status === 200) alert('삭제되었습니다.')
                            else
                              alert(
                                '삭제에 실패했습니다. 잠시 후 다시 시도해주세요.',
                              )
                            registerCallback()
                          }
                        }}
                        aria-hidden
                        className='cursor-pointer'
                      >
                        <IoIosCloseCircle size={18} color='#6b7280' />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <div className='flex flex-col items-center justify-center flex-1 w-full h-full p-5 mb-2 text-sm border-2 border-dashed text-slate-700 rounded-xl'>
            연동된 스토어가 없습니다. 스마트스토어를 연동해주세요.
          </div>
        )}
      </div>
      <Space className='pt-3'>
        <div className='flex flex-row h-8 text-sm'>
          <div className='flex items-center mr-5 font-bold text-blue-500 whitespace-nowrap'>
            스마트스토어 주소
          </div>
          <Select
            defaultValue='https://smartstore.naver.com/'
            style={{ width: 260 }}
            size='normal'
            options={[
              {
                value: 'https://smartstore.naver.com/',
                label: 'https://smartstore.naver.com/',
              },
              {
                value: 'https://brand.naver.com/',
                label: 'https://brand.naver.com/',
              },
            ]}
            onChange={(e) => setGeneralUrl(e)}
          />
          <Input
            type='input'
            size='normal'
            className='mr-2'
            placeholder='고유주소'
            style={{ width: 80 }}
            onChange={(e) => {
              if (regex.test(e.target.value)) {
                window.alert(
                  '특수문자는 입력하실 수 없습니다. 고유주소 부분만 적어주세요.',
                )
                return
              }
              setUniqueUrl(e.target.value)
            }}
          />
          <Button
            type='primary'
            className='bg-blue-500'
            onClick={async () => {
              if (!uniqueUrl || uniqueUrl.length < 2)
                return window.alert('올바른 고유주소를 입력해주세요.')
              const storeUrl = `${generalUrl}${uniqueUrl}`
              console.log(`${generalUrl}${uniqueUrl}`, 'storeUrl')
              if (
                window.confirm(`
${storeUrl}로 원클릭 연동이 신청됩니다.
연동 준비가 되면 셀러페이지에서 원클릭 상품 등록이 가능합니다.

*해당 스토어 운영 셀러가 아닐 경우 원클릭 연동이 제한됩니다.
`)
              ) {
                await backendApis.registerCrawlQueue(storeUrl)
                registerCallback()
              }
            }}
          >
            연동
          </Button>
        </div>
      </Space>
    </div>
  )
}
export default StoreCrawlStatus

import moment from 'moment'
import React from 'react'
import shortUUID from 'short-uuid'

class TableUtils {
  parseOptionText(optionsInfo, selectedOption) {
    const optionsText = optionsInfo?.optionNames?.map((value, optionIndex) => {
      const optionDetail =
        optionsInfo?.totalOptions?.[optionIndex]?.[
          selectedOption?.[optionIndex]
        ]?.name
      return `${optionIndex}. ${value}: ${optionDetail}`
    })
    return optionsText.map((option) => <div key={option}>{option}</div>)
  }

  checkIfValidUUID(str) {
    // Regular expression to check if string is a valid UUID
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

    return regexExp.test(str)
  }

  parseAddressId(payedAt, addressId, _id) {
    const translator = shortUUID()
    const saveDate = new Date(payedAt)
    return `${moment(saveDate).format('YYYYMMDD')}${
      this.checkIfValidUUID(addressId) && translator.fromUUID(addressId || _id)
    }`.substring(2)
  }

  orderStatusName(status) {
    if (status === 'team-gathering') {
      return '팀 모집 중'
    }
    if (status === 'canceling-team-gathering') {
      return '팀 모집 중 취소 요청 중'
    }
    if (status === 'canceled-team-gathering') {
      return '팀 모집 중 취소 완료'
    }
    if (status === 'pre-shipping') {
      return '상품 준비 중'
    }
    if (status === 'canceling-pre-shipping') {
      return '출고 중지 요청 중'
    }
    if (status === 'canceling-shipping') {
      return '배송 중지 요청 중'
    }
    if (status === 'arrived') {
      return '배송완료'
    }
    if (status === 'reviewed') {
      return '리뷰완료'
    }
    if (status === 'refunding') {
      return '반품 접수'
    }
    if (status === 'refunding-retrieving') {
      return '반품 수거 중'
    }
    if (status === 'refunding-retrieved') {
      return '반품 수거 중'
    }
    if (status === 'refunding-arrived') {
      return '반품 수거완료'
    }
    if (status === 'refunded') {
      return '반품 완료'
    }
    if (status === 'canceled-pre-shipping') {
      return '출고 중지 중 취소완료'
    }
    if (status === 'canceled-shipping') {
      return '배송 중 취소완료'
    }
  }
}

export default new TableUtils()

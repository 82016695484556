import React, { useState, useEffect } from 'react'
import Page from '../components/atoms/page'
import { FcApproval as TitleIcon } from 'react-icons/fc'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import sellerInfoParser from '../utils/sellerInfoParser'
import OneClickItemComponent from '../components/ItemComponents/OneClickItemComponent'
import OneClickItemDetailInfo from '../components/ItemComponents/OneClickItemDetailInfo'
import { Input, Pagination, Select, Space } from 'antd'
import Button from '../components/atoms/button'
import { observer } from 'mobx-react-lite'
import AuthStore from '../stores/AuthStore'
import StoreCrawlStatus from '../components/ADS/atoms/storeCrawlStatus'

const OneClickItemRegisterScreen = observer(() => {
  const defaultSalesRatio = 10
  const [loadComplete, setLoadComplete] = useState(false)
  const [externalItemData, setExternalItemData] = useState([])
  const [totalLength, setTotalLength] = useState(0)
  const [selectedItemInfo, setSelectedItemInfo] = useState(null)
  const [salesRatio, setSalesRatio] = useState(defaultSalesRatio)
  const [crawlQueueInfo, setCrawlQueueInfo] = useState(null)
  const [generalUrl, setGeneralUrl] = useState('https://smartstore.naver.com/')
  const [uniqueUrl, setUniqueUrl] = useState('')
  const [soldOut, setSoldOut] = useState(false)
  const [chunkSize, setChunkSize] = useState(10)
  const [page, setPage] = useState(1)
  const realSalesRatio = (100 - parseInt(salesRatio, 10)) / 100
  const sellerInfo = SellerStore?.sellerInfo
  const bizNumber = sellerInfoParser.bizNumParser(
    sellerInfo?.businessRegistrationNumber,
  )
  const regex = /[^A-Za-z0-9\s\-_]/
  const selectedItemId = selectedItemInfo?._id?.toString()
  const loadNum = externalItemData?.length
  const condition = {
    bizNumber,
    oneClickRegisteredAt: null,
    oneClickFailedAt: null,
  }
  const fetchItemData = async (
    renew = false,
    condition,
    loadNum,
    chunkSize,
  ) => {
    if (!condition?.bizNumber) return setLoadComplete(true)
    const result = await backendApis.getSellerExternalItemData(
      condition,
      loadNum,
      chunkSize,
    )
    if (result?.status === 200) {
      if (renew) {
        setExternalItemData(result?.data)
        setSelectedItemInfo(result?.data[0])
      } else setExternalItemData([...externalItemData, ...result?.data])
    }
    setLoadComplete(true)
  }

  const checkCrawlQueue = async () => {
    const result = await backendApis.getCrawlQueue()
    if (result?.status === 200) {
      const data = result?.data || null
      if (data) {
        setCrawlQueueInfo(data)
      }
    }
  }

  const checkTotalLength = async (condition) => {
    if (!condition?.bizNumber) return
    const result = await backendApis.getSellerExternalItemDataTotalLength(
      condition,
    )
    if (result?.status === 200) setTotalLength(result?.data)
  }

  const changePage = async (page) => {
    setPage(page)
    await fetchItemData(true, condition, (page - 1) * chunkSize, chunkSize)
  }

  const registerItem = async (totalLength, chunkSize, salesRatio, soldOut) => {
    if (
      window.confirm(
        `원클릭 등록을 시작하시겠습니까?
        
확인 버튼을 누르실 경우 등록이 시작되며,
등록 후에는 개별 상품에 대한 세부 수정이 가능합니다.

*원클릭 등록시 외부 판매가보다 10% 인하되어 등록됩니다.`,
      ) === false
    )
      return
    SellerStore.setIsUploading(true)
    SellerStore.setUploadingStatus({
      type: 'oneClick',
      msg: '원클릭 등록 중입니다. 화면을 종료하지 마세요.',
      doneMsg: '축하드립니다. 상품 등록이 완료되었습니다.',
      total: totalLength,
      success: 0,
      done: 0,
      fail: 0,
    })
    let successCount = 0
    let doneCount = 0
    let failCount = 0
    backendApis.recordEventLog('OneClickNudge', 'one click register start')
    for (let i = 0; i < totalLength; i += chunkSize) {
      const result = await backendApis.oneClickRegisterAllExternalItems(
        chunkSize,
        salesRatio,
        soldOut,
      )
      if (result?.status === 200) {
        const { success, fail } = result?.data
        doneCount += success + fail
        successCount += success
        failCount += fail
        SellerStore?.setUploadingStatus({
          ...SellerStore?.uploadingStatus,
          done: parseInt(doneCount, 10),
          success: parseInt(successCount, 10),
          fail: parseInt(failCount, 10),
        })
        if (totalLength - i < chunkSize)
          SellerStore?.setUploadingStatus({
            ...SellerStore?.uploadingStatus,
            isComplete: true,
            isAdNudgeOn: true,
          })
      } else {
        window.alert('원클릭 등록 중 문제가 발생했습니다. 다시 시도해주세요.')
        SellerStore.setIsUploading(false)
        SellerStore.setUploadingStatus({
          type: '',
          msg: '',
          doneMsg: '',
          total: 0,
          done: 0,
          success: 0,
          fail: 0,
        })
      }
    }
    await checkTotalLength(condition)
    await fetchItemData(true, condition, loadNum, chunkSize)
  }

  useEffect(() => {
    checkCrawlQueue()
    checkTotalLength(condition)
    fetchItemData(true, condition, loadNum, chunkSize)
  }, [bizNumber])

  return (
    <div>
      <Page title='원클릭 등록' Icon={TitleIcon}>
        <div>
          <div className='flex flex-col flex-1 w-full p-10 mb-5 bg-white shadow-xl rounded-xl'>
            <div>셀러님께서 판매하고 계시는 우수 상품 데이터를 모았습니다.</div>
            <div>
              클릭 한 번에 외부 상품을 모두 등록해보세요! 원클릭 기능을 통해
              등록된 상품은{' '}
              {/* <span className='font-bold text-blue-500'>리뷰 연동</span>과 함께{' '} */}
              <span className='font-bold text-blue-500'>노출량 확대</span>{' '}
              혜택을 드립니다.
            </div>
            <div className='font-bold text-red-500'>
              * 어뷰징 문제로 인해 연동 후 상품을 삭제하신 경우, 재연동은
              불가합니다.
            </div>
            <div className='flex flex-col flex-1 p-5 mt-4 bg-gray-100 rounded-xl'>
              <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
                원클릭 등록이란?
              </span>
              <ul className='mx-4 list-disc'>
                <li>
                  외부 쇼핑몰에서 판매 중인 우수 상품 데이터를 통해 최소한의
                  노력으로 한 번에 등록하실 수 있는 기능입니다.
                </li>
                {/* <li>
                  등록된 상품은 기존 리뷰를 최대 20개까지 연동시켜드리며, 노출량
                  확대 혜택 또한 제공해드립니다.
                </li> */}
                <li>
                  수정이 필요한 경우, 원클릭 등록 후 상품 수정 페이지에서 수정
                  가능합니다.
                </li>
              </ul>
            </div>
          </div>
          {loadComplete && (
            <>
              <div>
                {externalItemData?.length ? (
                  <>
                    {AuthStore?.isMasterAccount && (
                      <div className='flex flex-row items-center flex-1 mb-5'>
                        <div className='mr-2 font-bold'>할인율</div>
                        <div className='mr-5'>
                          <Select
                            defaultValue={defaultSalesRatio}
                            style={{ width: 120 }}
                            onChange={(e) => setSalesRatio(e)}
                            options={[
                              { value: 5, label: '5%' },
                              { value: 10, label: '10%' },
                              { value: 15, label: '15%' },
                              { value: 20, label: '20%' },
                            ]}
                          />
                        </div>
                        <div className='mr-2 font-bold'>초기 상태</div>
                        <div>
                          <Select
                            defaultValue={soldOut}
                            style={{ width: 120 }}
                            onChange={(e) => setSoldOut(e)}
                            options={[
                              {
                                value: false,
                                label: '판매중',
                              },
                              {
                                value: true,
                                label: '품절',
                              },
                            ]}
                          />
                        </div>
                      </div>
                    )}
                    <div className='flex flex-row justify-center flex-1 mb-5'>
                      <Button
                        onClick={() =>
                          registerItem(
                            totalLength,
                            chunkSize,
                            salesRatio,
                            soldOut,
                          )
                        }
                        size='flexlg'
                      >
                        원클릭 등록하기
                      </Button>
                    </div>
                    <div className='flex flex-row flex-1 whitespace-normal'>
                      <div className='w-[30vw] mr-10'>
                        {externalItemData.map((item) => (
                          <div
                            key={item?.id}
                            className='mb-5 cursor-pointer'
                            onClick={() => setSelectedItemInfo(item)}
                            aria-hidden
                          >
                            <OneClickItemComponent
                              itemInfo={item}
                              selected={selectedItemId === item?._id.toString()}
                              salesRatio={realSalesRatio}
                            />
                          </div>
                        ))}
                      </div>
                      <OneClickItemDetailInfo itemInfo={selectedItemInfo} />
                    </div>
                  </>
                ) : (
                  <div className='flex flex-col items-center justify-center flex-1 p-10 mb-5 bg-white shadow-xl rounded-xl'>
                    <div className='mb-5 text-lg font-bold'>
                      등록 가능한 상품이 없습니다.
                    </div>
                    <div className='mb-5 text-center'>
                      <div>
                        스토어 주소를 입력해주시면{' '}
                        <span className='font-bold text-blue-500'>
                          원클릭 연동
                        </span>
                        을 진행해드립니다.
                      </div>
                      <div>
                        상품 준비까지 시간이 소요되며, 완료시 판매자센터 내에서
                        확인하실 수 있습니다.
                      </div>
                      <div>
                        네이버 브랜드 스토어 / 스마트 스토어 모두 가능합니다.
                      </div>
                      <div className='font-bold text-red-500'>
                        *동일 사업자 스토어가 아닌 경우 반영 대상에서
                        제외됩니다.
                      </div>
                    </div>
                    <StoreCrawlStatus
                      crawlQueueInfo={crawlQueueInfo}
                      registerCallback={() => checkCrawlQueue()}
                    />
                  </div>
                )}
              </div>

              <Pagination
                defaultCurrent={1}
                current={page}
                onChange={changePage}
                showSizeChanger={false}
                total={totalLength}
              />
            </>
          )}
        </div>
      </Page>
    </div>
  )
})
export default OneClickItemRegisterScreen

const Selector = ({ optionNameValueList, selectedOption, setOption }) => {
  return (
    <>
      <select
        onChange={(e) => {
          setOption(e.target.value)
        }}
        value={selectedOption}
      >
        {optionNameValueList.map((l) => (
          <option value={l.value}>{l.name}</option>
        ))}
      </select>
    </>
  )
}
export default Selector

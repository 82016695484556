import { Input, Select } from 'antd'
import { IoClose } from 'react-icons/io5'
import Button from './button'
import { Tooltip } from '../ADS/atoms'
import backendApis from '../../utils/backendApis'
import { FaArrowRight } from 'react-icons/fa'
import React, { useState, useEffect } from 'react'
import { Tooltip as TooltipOrigin } from 'react-tippy'

let url = ''
const externalReviewStatusText = (catalogId, externalUrlInfo) => {
  if (catalogId) return '*원클릭 자동 리뷰 연동 상품입니다'
  if (externalUrlInfo?.status === 'applied') return '리뷰 연동 심사 중'
  if (externalUrlInfo?.status === 'verified') return '리뷰 주소 확인 완료'
  if (externalUrlInfo?.status === 'rejected') return '리뷰 주소 거절됨'
  if (externalUrlInfo?.status === 'crawled') return '리뷰 크롤링 완료'
  if (externalUrlInfo?.status === 'complete') return '리뷰 연동 완료'
  return '외부 리뷰 연동하기'
}

const boxStyleByStatus = (currentStatus, targetStatus) => {
  if (currentStatus === targetStatus) return 'bg-blue-500 font-bold text-white'
  return 'bg-slate-200'
}

function isValidUrl(url) {
  // URL 정규표현식
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

  // 입력된 URL이 정규표현식과 일치하는지 확인
  return urlRegex.test(url)
}

const ExternalUrlModal = ({
  modalTitle,
  itemInfo,
  onClose = () => {},
  itemsInfo,
  setItemsInfo,
}) => {
  const [platform, setPlatform] = useState('naver')
  const externalUrlInfo = itemInfo?.externalUrlInfo

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [onClose])

  const Header = ({ modalTitle }) => {
    return (
      <div className='flex flex-row items-center justify-between w-full p-5 border-b border-solid border-slate-200'>
        <h3 className='text-xl font-semibold text-black'>{modalTitle}</h3>
        <div className='flex flex-row items-center justify-end flex-1'>
          <TooltipOrigin
            arrow='true'
            theme='dark'
            title='❎ 키보드 esc로도 종료하실 수 있어요'
            animation='fade'
            position='top'
          >
            <button
              type='button'
              onClick={onClose}
              className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
            >
              esc
            </button>
          </TooltipOrigin>
        </div>
      </div>
    )
  }
  const Body = () => {
    return (
      <div className='flex flex-col items-start justify-start flex-1 px-10 py-10 overflow-y-scroll h-4/5'>
        <div className='font-bold'>{itemInfo?.itemTitle}</div>
        {externalUrlInfo?.status ? (
          <>
            <div className='mt-5 text-sm text-slate-500'>
              *동일 상품이 아닌 상품에 대해서는 외부 리뷰 연동이 불가합니다.
            </div>
            <div className='mt-2'>
              <span className='mr-3'>
                신청주소: {externalUrlInfo?.externalUrl}
              </span>
              <Button
                size='sm'
                appearance='teritiary'
                onClick={async () => {
                  if (
                    window.confirm(
                      '연동을 취소하시겠습니까? 취소 후 재신청 가능합니다.',
                    )
                  ) {
                    const result = await backendApis.deleteExternalUrl(
                      itemInfo?._id,
                    )
                    if (result?.status === 200) {
                      alert('성공적으로 취소되었습니다.')

                      const indexToModify = itemsInfo.findIndex(
                        (obj) =>
                          obj._id?.toString() === itemInfo?._id?.toString(),
                      )
                      const newItemsInfo = JSON.parse(JSON.stringify(itemsInfo))
                      newItemsInfo[indexToModify].externalUrlInfo = null
                      setItemsInfo(newItemsInfo)
                      onClose()
                    } else {
                      alert('오류가 발생했습니다. 다시 시도해주세요.')
                    }
                  }
                }}
              >
                연동 취소
              </Button>
            </div>
            <div className=''>
              진행상태:{' '}
              <span className='font-bold text-blue-500'>
                {externalReviewStatusText(itemInfo?.catalogId, externalUrlInfo)}
              </span>
            </div>
            <div className='mt-2 text-sm text-slate-500'>
              리뷰 연동 신청은 다음 순서로 진행됩니다.
            </div>
            <div className='flex flex-row items-center flex-1 mt-2'>
              <div
                className={`px-8 py-2 mr-2 rounded-xl whitespace-nowrap ${boxStyleByStatus(
                  externalUrlInfo?.status,
                  'applied',
                )}`}
              >
                연동 심사
              </div>
              <div className='mr-2'>
                <FaArrowRight />
              </div>
              <div
                className={`px-8 py-2 mr-2 rounded-xl whitespace-nowrap ${boxStyleByStatus(
                  externalUrlInfo?.status,
                  'verified',
                )}`}
              >
                주소 확인
              </div>
              <div className='mr-2'>
                <FaArrowRight />
              </div>
              <div
                className={`px-8 py-2 mr-2 rounded-xl whitespace-nowrap ${boxStyleByStatus(
                  externalUrlInfo?.status,
                  'crawled',
                )}`}
              >
                크롤링 완료
              </div>
              <div className='mr-2'>
                <FaArrowRight />
              </div>
              <div
                className={`px-8 py-2 mr-2 rounded-xl whitespace-nowrap ${boxStyleByStatus(
                  externalUrlInfo?.status,
                  'reviewRegistered',
                )}`}
              >
                연동 완료
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='mt-5 text-sm text-slate-500'>
              *현재 연동된 상품 외부 주소가 없습니다. 상품의 외부 주소를
              입력해주세요.
            </div>
            <div className='text-sm text-slate-500'>
              *동일 상품이 아닌 상품에 대해서는 외부 리뷰 연동이 불가합니다.
            </div>
            <div />
            <div className='mt-5 whitespace-nowrap'>
              상품 외부 주소
              <Tooltip text='스마트스토어 상품 주소를 입력해주시면<br/>빠른 리뷰 연동이 가능합니다.' />
            </div>
            <div className='flex flex-row items-center flex-1 w-full mt-2'>
              <Select
                value={platform}
                options={[
                  { value: 'naver', label: '네이버' },
                  { value: 'coupang', label: '쿠팡' },
                ]}
                onChange={(e) => {
                  setPlatform(e)
                }}
                className='mr-5 w-28'
              />
              <Input
                // addonBefore='https://'
                placeholder='https://를 포함한 상품 주소를 입력해주세요.'
                className='mr-5'
                onChange={(e) => {
                  url = e.target.value
                }}
              />
              <Button
                className='whitespace-nowrap'
                onClick={async () => {
                  if (
                    window.confirm(
                      '연동 신청을 하시겠습니까? 연동 신청 후 실제 리뷰 연동은 순차적으로 진행됩니다. 네이버 상품의 경우 더 빠르게 진행됩니다.',
                    )
                  ) {
                    if (isValidUrl(url) === false)
                      return alert('url 형식이 올바르지 않습니다.')
                    const result = await backendApis.registerExternalUrl(
                      itemInfo?._id,
                      platform,
                      url,
                    )
                    if (result?.status === 200) {
                      alert('성공적으로 등록되었습니다.')
                      const externalUrlInfoResult =
                        await backendApis.getExternalUrl(itemInfo?._id)
                      if (externalUrlInfoResult?.status === 2000) {
                        const indexToModify = itemsInfo.findIndex(
                          (obj) =>
                            obj._id?.toString() === itemInfo?._id?.toString(),
                        )
                        const newItemsInfo = JSON.parse(
                          JSON.stringify(itemsInfo),
                        )
                        newItemsInfo[indexToModify].externalUrlInfo =
                          externalUrlInfoResult?.data
                        setItemsInfo(newItemsInfo)
                        onClose()
                      }
                    } else {
                      alert(
                        '오류가 발생했습니다. 주소 형식 확인 후 새로고침하여 다시 시도해주세요.',
                      )
                    }
                  }
                }}
              >
                연동 신청
              </Button>
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <div className='fixed inset-0 z-50 flex px-2 py-10 overflow-y-scroll outline-none'>
        <div className='relative w-full max-w-2xl mx-auto my-auto shadow-lg'>
          <div className='relative flex flex-col flex-1 w-full bg-white border-0 rounded-lg outline-none'>
            <Header modalTitle={modalTitle} />
            <Body />
          </div>
        </div>
      </div>
      <div className='fixed inset-0 z-40 bg-black opacity-25' />
    </>
  )
}
export default ExternalUrlModal

import React, { useState } from 'react'
import Modal from 'react-modal'
import backendApis from '../utils/backendApis'
import { observer } from 'mobx-react-lite'
import { GrClose as CloseIcon } from 'react-icons/gr'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  LogarithmicScale,
} from 'chart.js'
import Button from '../components/atoms/button'
import Input from '../components/atoms/input'
import resize from '../utils/resize'
import { Tooltip } from '../components/ADS/atoms'
import ItemWinnerLinkApplicationModal from './ItemWinnerLinkApplicationModal'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Legend,
)

const ItemWinnerSearchModal = observer(
  ({ showModal, setShowModal, selectedItemInfo }) => {
    const [itemQuery, setItemQuery] = useState('')
    const [itemsInfo, setItemsInfo] = useState([])
    const [applicationButtonShow, setApplicationButtonShow] = useState(false)
    const [showLinkModal, setShowLinkModal] = useState(false)
    const [urlLink, setUrlLink] = useState('')

    const handleSearch = async () => {
      if (itemQuery?.length === 0) {
        alert('상품명을 입력해주세요.')
        return
      }

      const LIMIT_FOR_LOADING_ON_STORE = 50
      const result = await backendApis.loadItemWinnerItemsByCondition(
        itemQuery,
        LIMIT_FOR_LOADING_ON_STORE,
        0,
      )
      setApplicationButtonShow(false)

      if (result?.status === 200) {
        setItemsInfo(result?.data)
        if (result?.data?.length === 0) {
          alert(
            '검색하신 상품이 존재하지 않습니다. 검색어를 다르게 입력해보세요. 위너 상품은 한정적으로 요청하신 상품이 위너 상품 대상군에 없을 수 있습니다',
          )
          setApplicationButtonShow(true)
        }
      } else {
        setItemsInfo([])
        alert(
          '검색하신 상품이 존재하지 않습니다. 검색어를 다르게 입력해보세요. 위너 상품은 한정적으로 요청하신 상품이 위너 상품 대상군에 없을 수 있습니다',
        )
        setApplicationButtonShow(true)
      }
    }

    const handelApplication = async () => {
      if (itemQuery?.length === 0) {
        alert('상품명을 입력해주세요.')
        return
      }

      const LIMIT_FOR_LOADING_ON_STORE = 50
      const result = await backendApis.loadItemWinnerItemsByCondition(
        itemQuery,
        LIMIT_FOR_LOADING_ON_STORE,
        0,
      )

      if (result?.status === 200) {
        setItemsInfo(result?.data)
        if (result?.data?.length === 0) {
          alert(
            '검색하신 상품이 존재하지 않습니다. 검색어를 다르게 입력해보세요. 위너 상품은 한정적으로 요청하신 상품이 위너 상품 대상군에 없을 수 있습니다',
          )
          setApplicationButtonShow(true)
        }
      } else {
        setItemsInfo([])
        alert(
          '검색하신 상품이 존재하지 않습니다. 검색어를 다르게 입력해보세요. 위너 상품은 한정적으로 요청하신 상품이 위너 상품 대상군에 없을 수 있습니다',
        )
        setApplicationButtonShow(true)
      }
    }

    return (
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          backendApis.recordEventLog(
            'ItemWinnerSearchScreen',
            'closed application modal',
          )
          setShowModal(false)
        }}
        style={{
          overlay: {
            zIndex: 20,
          },
          content: {
            left: '20%',
            right: '15%',
            top: '10%',
            bottom: '10%',
            padding: 40,
            textAlign: 'center',
          },
        }}
      >
        {/* 취소버튼 */}
        <button
          type='button'
          onClick={() => {
            backendApis.recordEventLog(
              'ItemWinnerSearchScreen',
              'closed application modal',
            )
            setShowModal(false)
          }}
          className='absolute top-0 right-0 mt-12 mr-12 border-0 bg-none '
        >
          <CloseIcon className='w-6 h-6' />
        </button>
        <table>
          <thead className='text-base'>
            <tr>
              <th className='py-1'>
                판매할 상품의 상품명을 검색 키워드로 검색해보세요.
              </th>
            </tr>
          </thead>
          <tbody className='text-base border-none'>
            <tr className='font-bold border-none'>
              <div className='mt-3 mb-3'>
                <div className='flex flex-row flex-1 items-center justify-center'>
                  <form
                    className='flex flex-row flex-1 items-center justify-center'
                    onSubmit={async (e) => {
                      e.preventDefault() // 페이지 새로고침 방지
                      await handleSearch(false)
                    }}
                  >
                    <Input
                      className='w-3/5 ml-5 select-none'
                      type='text'
                      placeholder='상품명을 입력해 주세요. 동일한 제품이 아니면 등록이 안됩니다'
                      value={itemQuery}
                      onChange={(e) => {
                        setItemQuery(e.target.value)
                      }}
                    />
                    <Button className='ml-2' type='submit'>
                      상품 검색
                    </Button>
                  </form>
                </div>
                <div className='mt-5 flex flex-col justify-center items-center w-full'>
                  {applicationButtonShow ? (
                    <>
                      <div>
                        현재 위너 상품 대상군은 로켓 배송 상품입니다. 로켓 배송
                        상품임에도 불구하고 상품에 나오지 않는 경우 아래 링크로
                        추가 신청해주세요
                        <form
                          className='flex flex-row flex-1 items-center justify-center mt-3'
                          onSubmit={async (e) => {
                            e.preventDefault() // 페이지 새로고침 방지
                            if (!urlLink.includes('coupang')) {
                              alert(
                                '현재는 쿠팡 로켓배송 상품만 신청이 가능합니다. 향후 상품이 확대될 예정입니다',
                              )
                              return // Exit the function after showing the alert.
                            }
                            const regex = /itemId|ItemId/
                            if (!regex.test(urlLink)) {
                              alert(
                                '상품 링크가 아닙니다. 상품 링크를 정확히 첨부해주세요',
                              )
                              return // 함수를 종료하고 alert 이후의 코드를 실행하지 않습니다.
                            }
                            const registerCheck =
                              await backendApis.itemWinnerLinkApplication(
                                urlLink,
                              )
                            if (registerCheck?.status === 200) {
                              alert(
                                '신청이 완료되었습니다. 시간은 하루정도 소요되며 하루 후 매칭해주셔야 신청이 최종 완료됩니다',
                              )
                              setUrlLink('')
                            } else {
                              alert(
                                '신청 과정에 문제가 생겼습니다. 판매자 지원센터로 문의해주세요',
                              )
                            }
                          }}
                        >
                          <Input
                            className='w-3/5 ml-5 select-none'
                            type='text'
                            placeholder='로켓배송 상품링크'
                            value={urlLink}
                            onChange={(e) => {
                              setUrlLink(e.target.value)
                            }}
                          />
                          <Button className='ml-2' type='submit'>
                            상품 제안하기
                          </Button>
                        </form>
                      </div>
                    </>
                  ) : (
                    <>
                      {' '}
                      {itemsInfo.map((itemInfo) => (
                        <div
                          key={itemInfo._id}
                          className='flex flex-row w-full justify-center items-center'
                        >
                          {/* Image Section with fixed flex */}
                          <div className='flex-1 flex justify-center'>
                            <img
                              alt='mainImage'
                              src={resize(itemInfo?.imageUri)}
                              className='w-40 h-40 mb-2 rounded-md'
                            />
                          </div>

                          {/* Title and Description Section with fixed flex */}
                          <div className='flex-2 flex justify-center items-center'>
                            <div className='text-base text-start text-bold'>
                              <span className='text-lg font-bold'>
                                {itemInfo?.title}
                              </span>
                              <br />
                              위너 상품 가격 :
                              <span className='text-base'>
                                {itemInfo?.itemWinnerPrice || itemInfo?.price}
                              </span>
                              <br />
                              {itemInfo?.isCoupangPB && (
                                <>
                                  협업 제품
                                  <Tooltip text='노출량 혜택을 더 받게되며 제품 특성상 완전히 동일한 제품일 필요가 없습니다. 해당 옵션보다 용량이 적으면 가격이 용량에 비례해 저렴해야 매칭이 승인됩니다. 최근 2달 동안의 평균 리뷰가 4.5를 넘지 않으면 자동으로 매칭이 해제됩니다' />
                                </>
                              )}
                            </div>
                          </div>

                          {/* Select Button Section with fixed flex */}
                          <div className='flex-1 flex justify-center items-center'>
                            <Button
                              size='md'
                              onClick={async () => {
                                setShowModal(false)
                                selectedItemInfo(itemInfo)
                              }}
                            >
                              선택하기
                            </Button>
                          </div>
                        </div>
                      ))}
                      {itemQuery && (
                        <div className='flex flex-col items-center justify-center'>
                          현재 매칭 상품군은 로켓배송 제품들입니다. 향후
                          상품군은 확대될 예정입니다.
                          <button
                            type='button'
                            className='inline-block px-4 py-2 mr-5 border rounded-lg w-fit hover:bg-gray-100 mt-3 '
                            onClick={() => setShowLinkModal(true)}
                          >
                            매칭상품이 없다면? ↗
                          </button>
                        </div>
                      )}
                      {showLinkModal && (
                        <ItemWinnerLinkApplicationModal
                          showModal={showLinkModal}
                          setShowModal={(bool) => {
                            setShowLinkModal(bool)
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </Modal>
    )
  },
)

export default ItemWinnerSearchModal

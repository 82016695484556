import commaNumber from 'comma-number'
import React from 'react'
import { calculateOrderMultiplier } from '../../utils/cheapabilityUtils'
import ExtremePriceReverse from '../../images/cheapabiltiyIcon/ExtremePriceReverse.png'
import Button from '../atoms/button'
import {
  AiFillCaretUp as ArrowUp,
  AiFillCaretDown as ArrowDown,
} from 'react-icons/ai'

const ImpCalculator = ({
  oldPrice,
  bestPrice,
  priceList,
  optionChangeCallback = () => {},
  itemChangeCallback = () => {},
}) => {
  const impRatio = calculateOrderMultiplier(priceList, oldPrice, bestPrice)
  const multiplier = parseInt(impRatio * 117, 10)

  return (
    <div className='w-full mr-5'>
      {bestPrice ? (
        <>
          {oldPrice > bestPrice ? (
            <div className='flex flex-row justify-between flex-1'>
              <div className='flex flex-col justify-center text-slate-600'>
                <div className='flex flex-row items-center flex-1'>
                  <div>
                    <img
                      src={ExtremePriceReverse}
                      className='px-2 py-1 mr-1 bg-red-500 w-14'
                      alt=''
                    />
                  </div>
                  <div className='font-bold text-blue-500'>
                    ₩{commaNumber(bestPrice)}
                  </div>
                </div>
                <div className='flex flex-row items-center flex-1'>
                  예상 매출 상승량
                  <span className='ml-1 font-bold text-blue-500'>
                    {multiplier}%
                  </span>
                  <div className='ml-1 text-white bg-blue-500 rounded-sm'>
                    <ArrowUp />
                  </div>
                </div>
              </div>
              <div className='flex flex-col'>
                <Button className='mb-2' onClick={itemChangeCallback}>
                  초저가 일괄 변경
                </Button>
                <Button onClick={optionChangeCallback} appearance='teritiary'>
                  초저가 옵션 변경
                </Button>
              </div>
            </div>
          ) : (
            <div className='ml-3 text-slate-500'>*초저가 달성 상품입니다.</div>
          )}
        </>
      ) : (
        <>
          <div className='ml-3 text-slate-500'>
            *초저가 정보가 없는 상품입니다.
          </div>
        </>
      )}
    </div>
  )
}
export default ImpCalculator

const GetRatingCount = (itemInfo) => {
  return {
    getRating:
      itemInfo.totalRatingCount + (itemInfo.totalRatingCountRandom || 0)
        ? (itemInfo.totalRatingScore + (itemInfo.totalRatingScoreRandom || 0)) /
          (itemInfo.totalRatingCount + (itemInfo.totalRatingCountRandom || 0))
        : 0,
    getRatingCount:
      (itemInfo.totalRatingCount || 0) + (itemInfo.totalRatingCountRandom || 0),
  }
}

export default GetRatingCount

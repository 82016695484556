import { useState } from 'react'
import Button from './button'
import Ping from './ping'
import { isMobile } from 'react-device-detect'
import { FcIdea } from 'react-icons/fc'
import moment from 'moment'

export default function NoticeModal({
  text,
  modalTitle,
  imageSource,
  modalContent,
  isButton = false,
  buttonText,
  buttonLink,
  date,
  isExternalButton,
  externalUrl,
}) {
  const newDate = moment(new Date() - 7 * 1000 * 60 * 60 * 24)
  const [noticeOpened, setNoticeOpened] = useState(sessionStorage.getItem(text))
  const [showModal, setShowModal] = useState(false)
  const IconSetter = () => {
    if (!isMobile && moment(date) > newDate) {
      return <FcIdea className='ml-3' />
    }
    if (!isMobile && !noticeOpened) {
      return <Ping />
    }
  }

  return (
    <>
      <div className='flex flex-row items-center pl-2'>
        <button
          className={noticeOpened ? ' text-gray-500' : null}
          type='button'
          onClick={() => {
            setShowModal(true)
            setNoticeOpened(true)
            sessionStorage.setItem(text, true)
          }}
        >
          {text}
        </button>
        {IconSetter()}
      </div>
      {showModal ? (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
            <div className='relative w-full max-w-xl mx-auto my-6'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <h3 className='text-xl font-semibold'>{modalTitle}</h3>
                </div>
                {/* body */}
                <div className='relative flex-auto px-10 py-10'>
                  <p className='mb-5 text-lg leading-relaxed text-sub-500'>
                    <img alt='' src={imageSource} />
                  </p>
                  {modalContent?.split('\n').map((line) => (
                    <p className='mb-1 text-base leading-relaxed text-sub-500'>
                      {line}
                    </p>
                  ))}
                </div>
                {/* footer */}
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 mb-1 mr-1 text-lg font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    닫기
                  </button>
                  {isButton ? (
                    <Button
                      appearance='positive'
                      type='button'
                      href={buttonLink}
                      size='lg'
                    >
                      {buttonText}
                    </Button>
                  ) : (
                    // </Link>
                    <></>
                  )}
                  {isExternalButton && (
                    <a href={externalUrl}>
                      <Button appearance='positive' type='button' size='lg'>
                        {buttonText}
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      ) : null}
    </>
  )
}

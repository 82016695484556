import { useState, useEffect } from 'react'
import { FaBox as HomeIcon } from 'react-icons/fa'
import { FiHash as CheckIcon } from 'react-icons/fi'
import {
  MdOutlineKeyboardArrowDown as ArrowDown,
  MdKeyboardArrowUp as ArrowUp,
} from 'react-icons/md'
import {
  IoIosLock as LockIcon,
  IoIosRocket as RocketIcon,
} from 'react-icons/io'
import { IoThumbsUpSharp as ThumbsUpIcon } from 'react-icons/io5'
import { useLocation, Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import Ping from './atoms/ping'
import backendApis from '../utils/backendApis'
import AuthStore from '../stores/AuthStore'

const menus = [
  {
    title: '1ㅤ상품 관리',
    icon: <HomeIcon size={18} className='mr-3' />,
    submenus: [
      {
        name: '상품 등록',
        url: '/items/registrations',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '상품 조회/수정',
        url: '/items',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '상품 대량 등록',
        url: '/third-party/play-auto',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '공지 사항 일괄 등록',
        url: '/seller/notice',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
    ],
  },
  {
    title: '2ㅤ노출 관리',
    icon: <HomeIcon size={18} className='mr-3' />,
    submenus: [
      // {
      //   name: '신선마켓 카탈로그 입찰',
      //   url: '/unit-option',
      //   icon: <CheckIcon size={18} className='mr-2' />,
      // },
      {
        name: '품질지표 관리',
        url: '/item-quality-management',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      // {
      //   name: '초저가 관리',
      //   url: '/cheapability',
      //   icon: <CheckIcon size={18} className='mr-2' />,
      // },
      // {
      //   name: '가정의달 기획전 신청',
      //   url: '/family-deal',
      //   icon: <CheckIcon size={18} className='mr-2' />,
      // },
      // {
      //   name: '777 페스티벌 신청',
      //   url: '/triple-seven-deal',
      //   icon: <CheckIcon size={18} className='mr-2' />,
      // },
      // {
      //   name: '여름 블프딜 타임특가 신청',
      //   url: '/summer-deal-24-time-deal',
      //   icon: <CheckIcon size={18} className='mr-2' />,
      // },
      // {
      //   name: '가정의달 노출 부스팅 신청',
      //   url: '/family-deal-boosting',
      //   icon: <CheckIcon size={18} className='mr-2' />,
      // },
      // {
      //   name: '위너 상품 관리',
      //   url: '/item-winner',
      //   icon: <CheckIcon size={18} className='mr-2' />,
      // },
      {
        name: '타임특가 신청',
        url: '/timedeal-apply',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '10분핫딜 신청',
        url: '/tenmindeal-apply',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '쿠폰 마케팅 신청',
        url: '/coupon-explain',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '추가 구매 쿠폰 신청',
        url: '/deals',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '후기 이관 신청',
        url: '/review-transfer-apply',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
    ],
  },
  {
    title: '3ㅤ고객 관리',
    icon: <HomeIcon size={18} className='mr-3' />,
    submenus: [
      {
        name: '신상품 매대 체험단 신청',
        url: '/review-coupon',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '고객 관리 (문자)',
        url: '/customers',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '통합 문의 관리',
        url: '/item-questions',
        icon: <CheckIcon size={18} className='mr-2' />,
      },

      {
        name: '후기 확인',
        url: '/item-reviews',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
    ],
  },
  {
    title: '4ㅤ주문/배송 관리',
    icon: <HomeIcon size={18} className='mr-3' />,
    submenus: [
      {
        name: '바로도착',
        url: '/baro-arrive',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '오늘출발',
        url: '/today-departures',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '배송관리',
        url: '/shipping-type',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '출고 중지 요청',
        url: '/canceling-shippings',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '반품관리',
        url: '/refunds',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '주문조회',
        url: '/orders',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '고객 주문 관리',
        url: '/users',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
    ],
  },
  {
    title: '5ㅤ광고 관리',
    icon: <HomeIcon size={18} className='mr-3' />,
    submenus: [
      {
        name: 'CPM 광고 신청',
        url: '/ad-cpm-apply',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: 'CPC/CPS 광고 신청',
        url: '/ad-apply',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: 'CPC/CPS 광고 성과 분석',
        url: '/ad-monitoring',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '올팜 광고 신청',
        url: '/alfarm-ad-apply',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '광고 크레딧 관리',
        url: '/ad-credit',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '크레딧 티켓 등록',
        url: '/ad-ticket',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
    ],
  },
  {
    title: '6ㅤ정산 관리',
    icon: <HomeIcon size={18} className='mr-3' />,
    submenus: [
      {
        name: '정산현황',
        url: '/settlements',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '매출내역',
        url: '/sales',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
    ],
  },
  {
    title: (
      <span>
        7ㅤ<sup className='text-[11px] text-yellow-400'>NEW</sup> 세금계산서
        관리
      </span>
    ),
    icon: <HomeIcon size={18} className='mr-3' />,
    submenus: [
      {
        name: '세금계산서 조회',
        url: '/tax-invoice',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
    ],
    onlyMinJung: true,
  },
  {
    title: '8ㅤ판매자 정보 관리',
    icon: <HomeIcon size={18} className='mr-3' />,
    submenus: [
      {
        name: '판매자 등급',
        url: '/seller-rank',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '판매자 정보 확인 및 수정',
        url: '/seller-info',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '비밀번호 변경',
        url: '/password-reset',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '페널티 내역 확인',
        url: '/item-penalty',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '브랜드 판매자 신청',
        url: '/seller-brand',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
    ],
  },
  {
    title: (
      <span>
        9ㅤ<sup className='text-[11px] text-yellow-400'>NEW</sup> 매출 관리
      </span>
    ),
    icon: <HomeIcon size={18} className='mr-3' />,
    submenus: [
      // {
      //   name: '매출 상세분석',
      //   url: '/seller-revenue/details',
      //   icon: <CheckIcon size={18} className='mr-2' />,
      // },
      {
        name: '직매입 신청',
        url: '/direct-purchase',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
      {
        name: '올웨이즈 협력사 선정',
        url: '/cooperative-company',
        icon: <CheckIcon size={18} className='mr-2' />,
      },
    ],
  },
]

const Menus = observer((props) => {
  const currentUrl = useLocation()
  const [isShowDetails, setIsShowDetails] = useState(false)
  const [isWinnerOneClickSeller, setIsWinnerOneClickSeller] = useState(false)
  const [isDealOneClickSeller, setIsDealOneClickSeller] = useState(false)
  const subMenusData = menus[props.arrayNum].submenus
  const foundUrl = subMenusData.find((el) => el?.url === currentUrl.pathname)

  useEffect(() => {
    if (props?.arrayNum === 0) {
      const winnerOneClickChecker = async () => {
        const result = await backendApis.checkWinnerOneClickSeller()
        if (result?.status === 200) {
          setIsWinnerOneClickSeller(result?.data)
        }
      }
      const dealOneClickChecker = async () => {
        const result = await backendApis.checkDealOneClickSeller()
        if (result?.status === 200) {
          setIsDealOneClickSeller(result?.data)
          SellerStore.setNudgeModalStatus({
            ...SellerStore.nudgeModalStatus,
            dealOneClickModal: result?.data || false,
          })
        }
      }
      winnerOneClickChecker()
      dealOneClickChecker()
    }
    if (foundUrl) {
      setIsShowDetails(true)
    }
  }, [])

  return (
    <>
      <button
        type='button'
        className='flex items-center justify-between p-2 px-5 text-base font-bold hover:cursor-pointer'
        onClick={() => setIsShowDetails(!isShowDetails)}
      >
        {menus[props.arrayNum].title}
        {isShowDetails ? <ArrowUp /> : <ArrowDown />}
      </button>
      {isShowDetails && (
        <div>
          {subMenusData.map((menu) => {
            if (menu?.url === '/items/winner-one-click-register') {
              if (!isWinnerOneClickSeller) {
                return <></>
              }
            }
            if (menu?.url === '/items/deal-one-click-register') {
              if (!isDealOneClickSeller) {
                return <></>
              }
            }
            if (menu?.url === '/baro-arrive') {
              if (
                !SellerStore?.sellerInfo?.permanentMarks?.fastArrivalCandidate
              ) {
                return <></>
              }
            }
            return (
              <div
                key={menu?.url}
                className={`text-sm pl-8 hover:bg-gray-500
                  ${
                    currentUrl.pathname === menu.url &&
                    ' bg-gray-600 border-r-2 border-white'
                  }`}
              >
                <Link
                  exact
                  to={
                    SellerStore.sellerInfo?.businessRegistrationNumber ||
                    AuthStore.isMasterAccount
                      ? menu.url
                      : '/register-basics'
                  }
                  key={menu.name}
                >
                  <div className='flex flex-row items-center py-3'>
                    <div>{menu.name}</div>
                    <div>
                      {new Date(SellerStore.sellerInfo?.createdAt) >=
                        new Date() - 1000 * 60 * 60 * 24 * 7 &&
                        menu.name === '후기 체험단 신청' && <Ping />}
                      {(menu.name === '보물특가 신청' ||
                        menu.name === '후기 이관 신청') && (
                        <LockIcon className='ml-2' />
                      )}
                      {/* {menu.url === '/third-party/play-auto' && (
                        <RocketIcon className='ml-2' />
                      )} */}
                      {menu.url === '/items/one-click-register' && (
                        <ThumbsUpIcon className='ml-2' />
                      )}
                      {menu.url === '/ad-cpm-apply' && (
                        <img
                          src='/hoticon.png'
                          alt='hot'
                          className='ml-2 w-11 h-auto'
                        />
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
})

export default Menus

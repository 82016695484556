import { useEffect, useState } from 'react'
import backendApis from '../../utils/backendApis'
import validateBusinessNumber from '../../utils/businessRegistrationUtils/businessNumberValidation'
import BusinessInfoParser from '../../utils/businessRegistrationUtils/businessInfoParser'
import matchBusinessNumber from '../../utils/businessRegistrationUtils/businessNumberMatching'

const BusinessNumberSection = ({ sellerInfo, onNextStep, onPreviousStep }) => {
  const [businessNumber, setBusinessNumber] = useState('')
  const [isValidBusinessNumber, setIsValidBusinessNumber] = useState(null)
  const [isReLoad, setIsReLoad] = useState(false)
  const [validationComplete, setValidationComplete] = useState(false)
  const [error, setError] = useState('')
  const [isValidating, setIsValidating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [extractedData, setExtractedData] = useState({
    repName: '',
    openDate: '',
    registerNumber: '',
  })
  const [validationFail, setValidationFail] = useState(false)

  useEffect(async () => {
    const sellerBusinessInfo =
      await backendApis.loadSellerBusinessRegistrationInfo()

    if (sellerBusinessInfo?.data?.businessRegistrationNumber) {
      setBusinessNumber(sellerBusinessInfo?.data?.businessRegistrationNumber)
    } else {
      // 처음에는 사업자등록증에서 OCR 추출한 사업자번호를 input 칸에 넣어놓음
      setBusinessNumber(
        sellerBusinessInfo?.data?.businessRegisteredData?.registerNumber,
      )
    }

    setIsValidBusinessNumber(
      sellerBusinessInfo?.data?.isValidBusinessRegistrationNumber,
    )
    setExtractedData({
      repName: BusinessInfoParser.parseBusinessRegistrationRepName(
        sellerBusinessInfo?.data?.businessRegisteredData?.repName,
      ),
      openDate: BusinessInfoParser.parseBusinessRegistrationOpenDate(
        sellerBusinessInfo?.data?.businessRegisteredData?.openDate,
      ),
      registerNumber:
        sellerBusinessInfo?.data?.businessRegisteredData?.registerNumber,
    })

    if (
      sellerBusinessInfo?.data?.isValidBusinessRegistrationNumber === 'approved'
    ) {
      setValidationComplete(true)
    }
  }, [isReLoad])

  const validateInput = (value) => {
    const regex = /^\d{3}-\d{2}-\d{5}$/
    if (!value) {
      setError('사업자등록번호를 입력해주세요.')
      return false
    }
    if (!regex.test(value)) {
      setError('올바른 형식이 아닙니다. (예: 123-45-67890)')
      return false
    }
    setError('')
    return true
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setBusinessNumber(value)
    validateInput(value)
  }

  const isBusinessNumberValid = (number) => {
    const regex = /^\d{3}-\d{2}-\d{5}$/
    return regex.test(number)
  }

  const handleRegisterClick = async () => {
    if (!isBusinessNumberValid(businessNumber)) {
      alert('사업자등록번호는 000-00-00000 형식이어야 합니다.')
      return
    }
    const isConfirmed = confirm(
      `입력하신 사업자등록번호 ${businessNumber}를 등록하시겠습니까?`,
    )
    if (isConfirmed) {
      const matchResult = await matchBusinessNumber(businessNumber) // OCR과 일치하는지 && 이미 등록되지 않은 사업자번호인지 확인
      if (matchResult?.status === 400) {
        setError(matchResult?.message)
        alert(matchResult?.message)
        return
      }
      setError('')
      try {
        setIsLoading(true)
        const updateResult =
          await backendApis.registerBusinessRegistrationNumber(businessNumber)
        // 3초 딜레이 추가
        await new Promise((resolve) => setTimeout(resolve, 3000))
        if (updateResult?.status === 200) {
          alert('등록이 완료되었습니다.')
          setIsReLoad((prev) => !prev)
        } else {
          alert('등록 중 오류가 발생했습니다. 다시 시도해주세요.')
        }
        setIsLoading(false)
      } catch (error) {
        alert('등록 중 오류가 발생했습니다. 다시 시도해주세요.')
      }
    }
  }

  const handleReset = async () => {
    const isConfirmed = confirm('사업자등록번호를 다시 입력하시겠습니까?')
    if (isConfirmed) {
      try {
        const resetResult = await backendApis.resetBusinessRegistrationNumber(
          sellerInfo._id,
        )
        if (resetResult?.status === 200) {
          setValidationComplete(false)
          setValidationFail(false)
          setError('')
          setBusinessNumber('')
          setIsReLoad((prev) => !prev)
        } else {
          alert('초기화에 실패했습니다. 다시 시도해주세요.')
        }
      } catch (error) {
        alert('초기화 중 오류가 발생했습니다.')
      }
    }
  }

  const handleStartValidation = async () => {
    setIsValidating(true)
    setIsLoading(true)
    try {
      const result = await validateBusinessNumber()
      if (result.status === 200) {
        alert('사업자등록번호 검증이 완료되었습니다.')
        setValidationComplete(true)
        setValidationFail(false)
        setIsReLoad((prev) => !prev)
      } else {
        setValidationFail(result?.failFlag || false)
        alert(result.message)
      }
    } catch (error) {
      alert('검증 중 오류가 발생했습니다.')
    } finally {
      setIsValidating(false)
      setIsLoading(false)
    }
  }

  const isDisabled = isValidBusinessNumber === 'approved'
  const showRegisterButton =
    businessNumber?.length === 12 &&
    !isDisabled &&
    isValidBusinessNumber !== 'pending'

  const Loader = () => (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white'></div>
    </div>
  )

  return (
    <div className='p-5'>
      {isLoading && <Loader />}
      <div className='flex flex-col space-y-4'>
        <div className='p-3 bg-gray-100 rounded-lg border border-gray-200'>
          <div className='flex justify-between items-center mb-3'>
            <h3 className='text-base font-semibold'>
              다음의 정보를 통해 사업자등록증의 유효성을 검증합니다.
              <br />
              정확한 정보인지 확인해주세요.
            </h3>
          </div>

          <div className='space-y-2 mb-3 text-sm'>
            <div className='flex'>
              <span className='text-gray-600 w-24 mr-2'>사업자등록번호:</span>
              <span className='text-gray-800'>
                {extractedData.registerNumber}
              </span>
            </div>
            <div className='flex'>
              <span className='text-gray-600 w-24 mr-2'>대표자명:</span>
              <span className='text-gray-800'>{extractedData.repName}</span>
            </div>
            <div className='flex'>
              <span className='text-gray-600 w-24 mr-2'>개업일시:</span>
              <span className='text-gray-800'>{extractedData.openDate}</span>
            </div>
          </div>

          <div className='text-sm text-gray-500 space-y-1'>
            <p className='flex items-center'>
              <span className='text-red-500 mr-1'>*</span>
              정보가 부정확할 경우, 이전 단계에서 첨부한 사업자등록증 이미지를
              변경하고 다시 검증할 수 있어요.
            </p>
            <p className='flex items-center'>
              <span className='text-red-500 mr-1'>*</span>
              등록 및 검증 과정에서 문제가 있을 경우, 판매자지원센터로
              문의해주세요.
            </p>
          </div>
        </div>
        <div className='border rounded-lg p-6'>
          <div className='flex items-center justify-between mb-4'>
            <div className='flex flex-col'>
              <div className='flex items-center'>
                <span className='font-bold mr-3'>사업자등록번호</span>
                <input
                  type='text'
                  placeholder='예: 123-45-67890 (- 포함)'
                  className={`px-3 py-2 border rounded-md w-64 ${
                    error
                      ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                      : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                  }`}
                  value={businessNumber}
                  onChange={handleInputChange}
                  maxLength='12'
                  disabled={isDisabled || isValidBusinessNumber === 'pending'}
                  pattern='\d{3}-\d{2}-\d{5}'
                  required
                />
              </div>
              {error && (
                <span className='text-red-500 text-sm mt-1 ml-28'>{error}</span>
              )}
            </div>
            {showRegisterButton && !error && (
              <button
                type='button'
                className='ml-3 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600'
                onClick={handleRegisterClick}
              >
                등록하기
              </button>
            )}
          </div>

          {businessNumber &&
            isValidBusinessNumber === 'pending' &&
            !validationComplete && (
              <div className='flex flex-col space-y-2 mb-4'>
                <button
                  type='button'
                  onClick={handleReset}
                  disabled={isValidating}
                  className='px-4 py-2 bg-gray-500 text-white rounded-md
                  transition-colors duration-200 flex items-center justify-center space-x-2
                  hover:bg-gray-600'
                >
                  <svg
                    className='w-4 h-4'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
                    />
                  </svg>
                  <span>다시 입력하기</span>
                </button>
                <button
                  type='button'
                  onClick={handleStartValidation}
                  disabled={isValidating}
                  className={`px-6 py-3 bg-green-500 text-white rounded-md 
                  transition-colors duration-200 w-full
                  ${
                    isValidating
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-green-600'
                  }`}
                >
                  {isValidating ? '검증 중...' : '검증 시작하기'}
                </button>
              </div>
            )}

          {validationComplete && (
            <div className='text-green-600 font-semibold text-center py-2 bg-green-50 rounded-md mb-4'>
              ✓ 사업자등록번호 검증 완료
            </div>
          )}

          {validationFail && (
            <div className='mb-4'>
              <div className='text-red-600 font-semibold text-center py-2 bg-red-50 rounded-md'>
                ✕ 사업자등록번호 검증 실패
              </div>
              <p className='text-red-500 text-sm text-center mt-1'>
                해당 사업자등록정보는 계속사업자 상태가 아니며 유효하지
                않습니다. 다시 확인해주세요.
              </p>
            </div>
          )}

          <div className='flex flex-col space-y-2'>
            {validationComplete && (
              <button
                type='button'
                onClick={onNextStep}
                className='px-6 py-3 bg-blue-600 text-white rounded-md 
                  transition-colors duration-200 w-full hover:bg-blue-700
                  font-semibold text-lg shadow-md'
              >
                다음 단계로 →
              </button>
            )}
            <button
              type='button'
              onClick={onPreviousStep}
              className='px-6 py-3 bg-gray-600 text-white rounded-md 
                transition-colors duration-200 w-full hover:bg-gray-700
                font-semibold text-lg shadow-md'
            >
              이전 단계로
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BusinessNumberSection

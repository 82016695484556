import React, { useState } from 'react'
import Button from '../atoms/button'
import getDayOfWeek from '../../utils/getDayofWeek'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FcCalendar } from 'react-icons/fc'
import ko from 'date-fns/locale/ko'
import moment from 'moment'
import backendApis from '../../utils/backendApis'

const IrregularClosedDayComponent = ({
  irregularClosedDay,
  removeIrregularClosedDay,
}) => {
  const date = new Date(irregularClosedDay)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  // YYYY-MM-DD as white text in grey container
  return (
    <>
      <div className='bg-slate-400 text-white text-sm rounded-sm px-1 min-w-[24px] h-6 ml-2 my-1'>
        {year}-{month}-{day}
        <button
          type='button'
          className='ml-1'
          onClick={(event) => {
            event.preventDefault()
            removeIrregularClosedDay(irregularClosedDay)
          }}
        >
          x
        </button>
      </div>
    </>
  )
}

const TodayDepartureRegisterModal = ({
  showModal,
  setShowModal,
  checkedItemIds,
  isRegistered,
  isAllItemsUpdate,
}) => {
  const [hour, setHour] = useState(11)
  const [minute, setMinute] = useState(0)
  const [regularClosedDays, setRegularClosedDays] = useState(null)
  const [irregularClosedDays, setIrregularClosedDays] = useState([])
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const removeIrregularClosedDay = (irregularClosedDay) => {
    setIrregularClosedDays(
      irregularClosedDays.filter((day) => day !== irregularClosedDay),
    )
  }

  const validateSellerTodayDepartureStatus = async () => {
    const getSellerTodayDepartureStatusResult =
      await backendApis.getSellerTodayDepartureStatus()
    if (getSellerTodayDepartureStatusResult?.status === 200) {
      if (getSellerTodayDepartureStatusResult?.data?.todayDeparture) {
        // 이미 등록된 경우 -> 정지됬는지 체크
        if (getSellerTodayDepartureStatusResult?.data?.todayDeparture?.status) {
          registerTodayDepartureInfo(isRegistered, isAllItemsUpdate)
        } else {
          const suspensionDate = moment(
            getSellerTodayDepartureStatusResult.data.todayDeparture.updatedAt,
          )
          const today = moment()
          const diff = today.diff(suspensionDate, 'days')
          if (diff < 30) {
            // threshold: 30 days
            window.alert(
              `최근 7일간 당일배송 패널티가 10점을 넘어 이용이 정지되었습니다. ${
                30 - diff
              }일 후에 정지가 해제됩니다.`,
            )
          } else {
            // 정지 해제 및 등록 허용
            const stopSuspensionResult =
              await backendApis.createSellerTodayDepartureStatus()
            if (stopSuspensionResult?.status === 200) {
              registerTodayDepartureInfo(isRegistered, isAllItemsUpdate)
            } else {
              window.alert(
                '셀러 정보를 가져오는 중 문제가 발생했습니다. 나중에 다시 시도해 주세요.',
              )
            }
          }
        }
      } else {
        // null -> 최초 등록
        const createSellerTodayDepartureStatusResult =
          await backendApis.createSellerTodayDepartureStatus()
        if (createSellerTodayDepartureStatusResult?.status === 200) {
          registerTodayDepartureInfo(isRegistered, isAllItemsUpdate)
        } else {
          window.alert(
            '셀러 정보를 가져오는 중 문제가 발생했습니다. 나중에 다시 시도해 주세요.',
          )
        }
      }
    } else {
      window.alert(
        '셀러 정보를 가져오는 중 문제가 발생했습니다. 나중에 다시 시도해 주세요.',
      )
    }
  }

  const registerTodayDepartureInfo = async (isRegistered, isAllItemsUpdate) => {
    const result = isAllItemsUpdate
      ? await backendApis.registerTodayDepartureInfoAllItems({
          isRegistered,
          irregularClosedDays,
          regularClosedDays:
            regularClosedDays === null ? null : parseInt(regularClosedDays, 10),
          orderFinishDeadline: {
            hour: parseInt(hour, 10),
            minute: parseInt(minute, 10),
          },
        })
      : await backendApis.registerTodayDepartureInfo({
          itemIds: checkedItemIds,
          irregularClosedDays,
          regularClosedDays:
            regularClosedDays === null ? null : parseInt(regularClosedDays, 10),
          orderFinishDeadline: {
            hour: parseInt(hour, 10),
            minute: parseInt(minute, 10),
          },
        })

    if (result?.status === 200) {
      window.alert(
        isRegistered
          ? '오늘출발 정보가 수정되었습니다.'
          : '오늘출발 정보가 등록되었습니다.',
      )
      window.location.reload()
    } else {
      window.alert(
        isRegistered
          ? '오늘출발 정보를 수정하는 중 문제가 발생했습니다.'
          : '오늘출발 정보를 등록하는 중 문제가 발생했습니다.',
      )
    }
  }

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex px-2 overflow-x-hidden overflow-y-auto items-center justify-center outline-none'>
            <div className='flex-col relative  w-full max-w-xl mx-auto my-6 shadow-lg'>
              <div className='flex flex-col flex-1'>
                {/* content */}
                <div className='relative flex flex-col w-full bg-white border-0 outline-none'>
                  {/* header */}
                  <div className='flex  p-5 border-b border-solid border-slate-200'>
                    <h3 className='text-xl font-semibold text-black'>
                      {isRegistered ? '오늘출발 수정하기' : '오늘출발 등록하기'}
                    </h3>
                  </div>
                  {/* body */}
                  <div className='text-blue-400 text-sm mx-10 my-5'>
                    {!isAllItemsUpdate ? '선택된' : '모든'} 상품에 일괄
                    적용됩니다!
                  </div>
                  <div className='relative flex flex-col  px-10 pb-10'>
                    <div className='flex flex-row justify-between items-center'>
                      <div>오늘출발 기준시간</div>
                      <div className='text-blue-400'>
                        평일
                        <select
                          className='ml-2 mr-2'
                          value={hour}
                          onChange={(e) => setHour(e.target.value)}
                        >
                          {
                            // 6시부터 17시 30분까지
                            [...Array(18)].map((_, i) => (
                              <option value={6 + i}>{6 + i}</option>
                            ))
                          }
                        </select>
                        :
                        <select
                          className='ml-2 mr-2'
                          value={minute}
                          onChange={(e) => setMinute(e.target.value)}
                        >
                          <option value={0}>00</option>
                          <option value={30}>30</option>
                        </select>
                        <span className='ml-2'>이전 결제완료 주문건</span>
                      </div>
                    </div>
                    <div className='flex flex-row justify-between items-center'>
                      <div className='mt-2'>휴무요일</div>
                      <select
                        className='mt-2 text-blue-400 min-w-[350px] max-w-[350px]'
                        value={regularClosedDays}
                        onChange={(e) => setRegularClosedDays(e.target.value)}
                      >
                        <option value={null}>없음</option>
                        {
                          // 월 (1) ~ 금 (5)
                          [...Array(5)].map((_, i) => (
                            <option value={1 + i}>{getDayOfWeek(1 + i)}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div className='flex flex-row justify-between items-center'>
                      <div className='mt-2'>휴무일</div>
                      <div className='flex flex-row justify-end mt-2'>
                        <div className='flex flex-row flex-wrap justify-start items-center border border-black min-w-[326px] max-w-[326px] min-h-[40px]'>
                          {irregularClosedDays.map(
                            (irregularClosedDay, idx) => (
                              <IrregularClosedDayComponent
                                key={
                                  irregularClosedDay.toString() + idx.toString()
                                }
                                irregularClosedDay={irregularClosedDay}
                                removeIrregularClosedDay={
                                  removeIrregularClosedDay
                                }
                              />
                            ),
                          )}
                        </div>
                        <button
                          className='ml-2'
                          type='button'
                          onClick={(event) => {
                            event.preventDefault()
                            setIsDatePickerOpen(!isDatePickerOpen)
                          }}
                        >
                          <FcCalendar />
                        </button>
                      </div>
                    </div>
                    <div className='flex flex-row justify-end mt-2'>
                      {isDatePickerOpen && (
                        <DatePicker
                          locale={ko}
                          minDate={moment().toDate()}
                          onChange={(date) => {
                            setIsDatePickerOpen(!isDatePickerOpen)
                            setIrregularClosedDays([
                              ...irregularClosedDays,
                              date,
                            ])
                          }}
                          inline
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* footer */}
                <div className='flex items-center justify-evenly p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <Button
                    appearance='gray'
                    className='px-3 py-2 ml-2 w-48 mt-3 mb-3'
                    onClick={() => {
                      setShowModal(false)
                    }}
                  >
                    닫기
                  </Button>
                  <Button
                    appearance='blue'
                    className='px-3 py-2 ml-2 w-48 mt-3 mb-3'
                    onClick={() => {
                      if (!isRegistered) {
                        validateSellerTodayDepartureStatus()
                      } else {
                        registerTodayDepartureInfo(
                          isRegistered,
                          isAllItemsUpdate,
                        )
                      }
                    }}
                  >
                    {isRegistered ? '수정하기' : '등록하기'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}

export default TodayDepartureRegisterModal

import Button from '../components/atoms/button'
import React, { useState, useEffect, useMemo } from 'react'
import { GrClose as CloseIcon } from 'react-icons/gr'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import adSettingByTab from '../data/adSettingByTab'
import backendApis from '../utils/backendApis'
import Table from '../components/ADS/molecules/table'
import Pagination from '../components/molecules/pagination'
import RankIconSetter from '../components/molecules/rankIconSetter'
import Tooltip from '../components/ADS/atoms/tooltip'
import commaNumber from 'comma-number'
import ListLimitSelector from '../components/atoms/listLimitSelector'
import SellerQuest from '../utils/sellerQuest'

const roasSetter = (itemRank) => {
  if (itemRank === 'S') {
    return 700
  }
  if (itemRank === 'A') {
    return 400
  }
  if (itemRank === 'B') {
    return 300
  }
  return 300
}

const colorByRoas = (roas) => {
  if (roas >= 700) {
    return 'text-positive-500'
  }
  if (roas >= 400) {
    return 'text-positive-400'
  }
  if (roas >= 300) {
    return 'text-positive-300'
  }
  if (roas >= 200) {
    return 'text-positive-200'
  }
  return 'text-positive-100'
}
const rankScondition = [
  { key: 'itemRank', value: 'B' },
  { key: 'itemRank', value: 'A' },
]
const rankAcondition = [
  { key: 'itemRank', value: 'B' },
  { key: 'itemRank', value: 'S' },
]
const rankBcondition = [
  { key: 'itemRank', value: 'A' },
  { key: 'itemRank', value: 'S' },
]
const conditionSetter = (rankStatusTab) => {
  if (rankStatusTab === 'All') return []
  if (rankStatusTab === 'B') return rankBcondition
  if (rankStatusTab === 'A') return rankAcondition
  if (rankStatusTab === 'S') return rankScondition
  return []
}

const AdBatchApplyModal = observer(({ showModal, setShowModal }) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [itemsCount, setItemsCount] = useState(0)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [itemsInfo, setItemsInfo] = useState([])
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)
  const adStatusTab = 'candidate'
  const tab = 0
  const [rankStatusTab, setRankStatusTab] = useState('All')
  const [rankCondition, setRankCondition] = useState([])
  const cleanUp = () => {
    setPage(1)
    setLimit(20)
    setItemsInfo([])
    setItemsCount(0)
    setIsAllCheckBoxChecked(false)
    setCheckedItemIds([])
    setRankStatusTab('All')
  }

  const fetchSegmentedItemsInfo = async (
    isNewCondition,
    rankCondition = [],
  ) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)

    const itemCondition = {}
    if (adStatusTab === 'candidate') {
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: true }
      itemCondition.itemRankTestingSku = { $ne: true }
      itemCondition.rankInfo = { $ne: null }
      itemCondition.teamPurchasePrice = { $gt: 100 }
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: {
                $or: [
                  { key: 'ad', value: adSettingByTab?.[tab].type },
                  { key: 'itemRank', value: 'C' },
                  ...rankCondition,
                ],
              },
            },
          },
        },
      ]
    } else if (adStatusTab === 'complete') {
      itemCondition.soldOut = false
      itemCondition.$or = [
        {
          outOfStock: false,
        },
        {
          outOfStock: null,
        },
      ]
      itemCondition.itemFilters = {
        $ne: null,
        $elemMatch: {
          $and: [{ key: 'ad', value: adSettingByTab?.[tab].type }],
        },
      }
    }

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
        setPage(1)
        setItemsInfo([])
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)

      if (adStatusTab === 'complete') {
        const adResult = await backendApis.getAdItemCreditUsage(
          'searchAd',
          true,
        )
        if (adResult?.status === 200) {
          const allResult = new Map()
          result?.data?.itemsInfo.forEach((item) =>
            allResult.set(String(item._id), item),
          )
          adResult?.data
            .filter((item) => !item?.deletedAt)
            .forEach((item) =>
              allResult.set(String(item.itemId), {
                ...allResult.get(String(item.itemId)),
                adVisits: item?.adVisits,
                adCost: item?.adCost,
                roas:
                  item?.adCost > 0
                    ? Math.round(
                        (1000 * Number(item?.revenueWithAd)) /
                          (Number(item?.adCost) / 1.1),
                      ) / 10
                    : '-',
                purcahse: item?.purcahse,
                keywords: item?.detailInfo?.keywords,
                adOngoing: item?.adOngoing,
                adCreatedAt: item?.createdAt,
                dailyCostLimit: item?.detailInfo?.dailyCostLimit,
                revenueWithAd: item?.revenueWithAd,
                status: item?.status,
              }),
            )
          setItemsInfo(Array.from(allResult.values()))
        }
      }
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    if (showModal) {
      fetchSegmentedItemsInfo(true, rankCondition)
    }
  }, [showModal, limit, adStatusTab])

  useEffect(() => {
    if (showModal && itemsInfo?.length) {
      fetchSegmentedItemsInfo(false, rankCondition)
    }
  }, [page])

  const columns = useMemo(
    () => [
      {
        Header: (
          <div className='select-none'>
            <input
              type='checkbox'
              checked={isAllCheckBoxChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedItemIds(itemsInfo?.map((itemInfo) => itemInfo?._id))
                  setIsAllCheckBoxChecked(true)
                } else {
                  setCheckedItemIds([])
                  setIsAllCheckBoxChecked(false)
                }
              }}
            />
          </div>
        ),
        accessor: 'status',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            상품명
          </span>
        ),
        accessor: 'itemTitle',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            등급
          </span>
        ),
        accessor: 'itemRank',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            추천 ROAS
            <Tooltip text='상품 등급별로 추천 ROAS로 자동 설정합니다. 개별 신청시에는 세부 조정도 가능합니다.' />
          </span>
        ),
        accessor: 'recommendedRoas',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            팀구매가
          </span>
        ),
        accessor: 'teamPurchasePrice',
      },
      {
        Header: (
          <span className='flex flex-row items-center justify-center felx-1'>
            판매당 광고비
            <Tooltip text='CPS 광고는 제품이 판매된 경우에만 광고비가 청구되는 안전한 광고입니다.' />
          </span>
        ),
        accessor: 'adCost',
      },
      // {
      //   Header: '광고비 비중',
      //   accessor: 'adCostRatio',
      // },
    ],
    [isAllCheckBoxChecked, itemsInfo, checkedItemIds],
  )
  const adData = useMemo(
    () =>
      itemsInfo
        ?.filter((item) => item?.itemTitle)
        .map((item, idx) => {
          return {
            onOff: (
              <div className='ml-2'>
                <div className='relative inline-block w-10 mr-2 align-middle select-none'>
                  <label
                    htmlFor={`onOff_${idx}`}
                    className='block h-6 overflow-hidden bg-gray-300 rounded-full outline-none cursor-pointer checked:bg-blue-500'
                  >
                    <input
                      type='checkbox'
                      name='toggle'
                      id={`onOff_${idx}`}
                      checked={item?.adOngoing}
                      className='absolute block w-4 h-4 duration-200 ease-in bg-white border-0 rounded-full appearance-none cursor-pointer ring-transparent ring-offset-0 focus:ring-offset-0 focus:ring-transparent outline-0 top-1 checked:h-6 checked:w-6 checked:bg-blue-500 right-5 checked:top-0 checked:right-0'
                    />
                  </label>
                </div>
              </div>
            ),
            status: (
              <div className='select-none'>
                <input
                  type='checkbox'
                  checked={checkedItemIds?.includes(item?._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckedItemIds([
                        ...checkedItemIds?.filter(
                          (itemId) => itemId !== item?._id,
                        ),
                        item?._id,
                      ])
                    } else {
                      setCheckedItemIds(
                        checkedItemIds?.filter(
                          (itemId) => itemId !== item?._id,
                        ),
                      )
                    }
                  }}
                />
              </div>
            ),
            itemTitle: (
              <div>
                {item?.itemTitle.slice(0, 24)}
                {item?.itemTitle.length > 24 && '...'}
              </div>
            ),
            itemRank: (
              <div className='flex items-center justify-center flex-1'>
                {item?.rankInfo && (
                  <RankIconSetter
                    itemRank={item?.rankInfo?.itemRank}
                    className='w-4 h-4'
                  />
                )}
              </div>
            ),
            itemPrice: (
              <div className='flex flex-row items-center justify-center flex-1'>
                {commaNumber(item?.teamPurchasePrice)}원
              </div>
            ),
            recommendedRoas: (
              <div
                className={`${colorByRoas(
                  roasSetter(item?.rankInfo?.itemRank),
                )} flex flex-row items-center justify-center flex-1 font-bold`}
              >
                {item?.rankInfo &&
                  commaNumber(roasSetter(item?.rankInfo?.itemRank))}
              </div>
            ),
            teamPurchasePrice: (
              <div>{commaNumber(item?.teamPurchasePrice)}원</div>
            ),
            adCost: (
              <div className='flex flex-row items-center justify-center flex-1'>
                {commaNumber(
                  parseInt(
                    item?.teamPurchasePrice /
                      (roasSetter(item?.rankInfo?.itemRank) / 100),
                    10,
                  ) || 0,
                )}
                원
              </div>
            ),
            // adCostRatio: (
            //   <div>
            //     {parseInt(
            //       (parseInt(
            //         item?.teamPurchasePrice /
            //           (roasSetter(item?.rankInfo?.itemRank) / 100),
            //         10,
            //       ) /
            //         item?.teamPurchasePrice) *
            //         100,
            //       10,
            //     )}
            //     %
            //   </div>
            // ),
          }
        }),
    [checkedItemIds, itemsInfo, isAllCheckBoxChecked, adStatusTab],
  )

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden outline-none'>
            <div className='relative flex-col w-1/2 p-10 overflow-y-scroll bg-white rounded-md shadow-md h-3/4'>
              <div className='flex flex-col flex-1'>
                <div className='flex flex-row items-center justify-between flex-1'>
                  <div className='mb-5 text-xl font-bold'>
                    판매 보장 광고 대량등록 (CPS)
                  </div>
                  <Button
                    appearance='transparent'
                    onClick={() => {
                      cleanUp()
                      setShowModal(false)
                    }}
                  >
                    <CloseIcon className='w-4 h-4' />
                  </Button>
                </div>
                <div>
                  ROAS가 보장되는{' '}
                  <span className='font-bold text-blue-500'>
                    100% 보장 수익형
                  </span>{' '}
                  광고입니다.
                </div>
                <div>
                  신청된 상품은 앱 내 다양한 지면에 노출되며 판매가{' '}
                  <span className='font-bold text-blue-500'>
                    실제로 일어난 경우
                  </span>
                  에만 광고비가 청구됩니다.
                </div>
                <div>
                  광고 신청이 가능한 상품들을{' '}
                  <span className='font-bold text-blue-500'>한 번에</span>{' '}
                  신청할 수 있습니다.
                </div>
                <div>
                  신청된 상품은{' '}
                  <span className='font-bold text-blue-500'>
                    추천 ROAS / 추천 키워드
                  </span>
                  로 자동 설정되며 신청 후 수정도 가능합니다.
                </div>
                <div>
                  등급 배정 중인 상품은 등급 배정 후 광고 등록 가능합니다.
                </div>

                <div className='my-2 text-sm text-gray-500'>
                  * 개별 조정이 필요한 상품의 경우 '5.광고관리 > 광고 신청'
                  메뉴에서 상세 옵션을 조정하여 등록하실 수 있습니다.
                </div>
                <div className='flex flex-row justify-end flex-1 mt-4'>
                  <div className='flex flex-1 gap-2'>
                    <Button
                      onClick={() => {
                        setRankStatusTab('All')
                        setIsAllCheckBoxChecked(false)
                        setCheckedItemIds([])
                        setRankCondition([])
                        fetchSegmentedItemsInfo(true)
                      }}
                      appearance={
                        rankStatusTab !== 'All' ? 'positiveSub' : 'disabled'
                      }
                    >
                      전체 보기
                    </Button>
                    <Button
                      onClick={() => {
                        setRankStatusTab('B')
                        setIsAllCheckBoxChecked(false)
                        setCheckedItemIds([])
                        setRankCondition(rankBcondition)
                        fetchSegmentedItemsInfo(true, rankBcondition)
                      }}
                      appearance={
                        rankStatusTab !== 'B' ? 'positiveSub' : 'disabled'
                      }
                    >
                      B등급 후보
                    </Button>
                    <Button
                      onClick={() => {
                        setRankStatusTab('A')
                        setIsAllCheckBoxChecked(false)
                        setCheckedItemIds([])
                        setRankCondition(rankAcondition)
                        fetchSegmentedItemsInfo(true, rankAcondition)
                      }}
                      appearance={
                        rankStatusTab !== 'A' ? 'positiveSub' : 'disabled'
                      }
                    >
                      A등급 후보
                    </Button>
                    <Button
                      onClick={() => {
                        setRankStatusTab('S')
                        setIsAllCheckBoxChecked(false)
                        setCheckedItemIds([])
                        setRankCondition(rankScondition)
                        fetchSegmentedItemsInfo(true, rankScondition)
                      }}
                      appearance={
                        rankStatusTab !== 'S' ? 'positiveSub' : 'disabled'
                      }
                    >
                      S등급 후보
                    </Button>
                  </div>
                  <Button
                    className='mr-2'
                    onClick={async () => {
                      if (SellerStore.isLoading === true) return
                      SellerStore.setIsLoading(true)
                      const adType = 'searchAd'
                      const checkedItemInfo = itemsInfo.filter((item) => {
                        return checkedItemIds?.includes(item?._id)
                      })
                      const adItemsInfo = []
                      checkedItemInfo.forEach((item) =>
                        adItemsInfo.push({
                          adItemId: item?._id,
                          adItemTitle: item?.itemTitle,
                          adCategoryId: item?.categoryInfo?.smallCategoryId,
                          rankInfo: item?.rankInfo,
                          itemIndex: item?.itemIndex,
                          itemTotalRankScore: item?.rankInfo?.totalScore,
                          roas: parseInt(
                            roasSetter(item?.rankInfo?.itemRank),
                            10,
                          ),
                        }),
                      )
                      const result = await backendApis.registerItemAdBatch(
                        adItemsInfo,
                        adType,
                      )
                      SellerStore.setIsLoading(false)
                      if (result?.status === 200) {
                        fetchSegmentedItemsInfo(
                          true,
                          conditionSetter(rankStatusTab),
                        )
                        alert(
                          `${result?.data?.length}개 상품 대량 광고 신청이 완료되었습니다.`,
                        )
                        if (
                          !SellerQuest.questChecker('adQuest', 'adBatchModal')
                        ) {
                          await SellerQuest.questClearer(
                            'adQuest',
                            'adBatchModal',
                          )
                        }
                      } else {
                        fetchSegmentedItemsInfo(
                          true,
                          conditionSetter(rankStatusTab),
                        )
                        alert('광고 신청에 실패했습니다. 다시 시도해주세요.')
                      }
                    }}
                  >
                    선택 상품 광고 신청
                  </Button>
                  <ListLimitSelector limit={limit} setLimit={setLimit} />
                </div>
                <div>
                  <Table columns={columns} data={adData} />
                  <Pagination
                    total={itemsCount}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    size='lg'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})
export default AdBatchApplyModal

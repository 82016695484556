import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'
import React, { useState, useEffect, useMemo } from 'react'
import { Tooltip as TooltipOrigin } from 'react-tippy'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import alfarmFreeAdSellerFilteringManager from '../../utils/alfarmFreeAdSellerFilteringManager'

const alfarmFreeAdNoticeModal = () => {
  const [freeAdAvailable, setFreeAdAvailable] = useState(false)
  const onClose = () => setFreeAdAvailable(false)

  const { checkFreeAdAvailable } = alfarmFreeAdSellerFilteringManager()
  const checkFreeAdAvailableAndSet = async () => {
    const result = await checkFreeAdAvailable()
    setFreeAdAvailable(result)
  }

  useEffect(() => {
    checkFreeAdAvailableAndSet()
  }, [])

  const Header = () => {
    return (
      <div className='flex flex-row items-center justify-between w-full p-4 border-b border-solid border-slate-200'>
        <h3 className='text-lg font-semibold text-red-500'>[이벤트]</h3>
        <h3 className='text-lg font-semibold text-blue-500'>
          &nbsp; '{SellerStore.sellerInfo.sellerName}' 님은 올팜 무료 광고 신청
          이벤트 대상입니다
        </h3>
        <div className='flex flex-row items-center justify-end flex-1'>
          <TooltipOrigin
            arrow='true'
            theme='dark'
            title='❎ 키보드 esc로도 종료하실 수 있어요'
            animation='fade'
            position='top'
          >
            <button
              type='button'
              onClick={onClose}
              className='px-2 py-1 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
            >
              esc
            </button>
          </TooltipOrigin>
        </div>
      </div>
    )
  }

  const Body = () => {
    const content = (
      <>
        <div className='mb-2'>
          *소수의 셀러 분들을 대상으로 진행되는 이벤트입니다.
        </div>
        <div className='font-bold'>
          올팜 내 원하시는 광고 영역에서 무료 광고를 진행하고 올팜 내 광고
          효율을 미리 확인해볼 수 있습니다.
        </div>
        <div>
          좌측 메뉴 바의{' '}
          <span className='font-bold text-blue-500'>
            [5. 광고 관리 - 올팜 광고 신청]
          </span>
          에서 원하시는 광고 영역을 선택하신 후
        </div>
        <div className='font-bold mb-1'>
          <span className='font-bold text-blue-500'>
            [무료 광고 신청(이벤트)]{' '}
          </span>
          버튼을 통해 무료 광고를 신청할 수 있습니다.
        </div>
        <br />
      </>
    )

    return <div className='p-5'>{content}</div>
  }

  const Footer = () => {
    const history = useHistory()
    return (
      <div className='p-4 rounded-b-lg'>
        <Button
          type='primary'
          size='large'
          className='flex items-center justify-center w-full'
          style={{
            backgroundColor: '#1258A4',
            color: 'white',
            borderColor: '#1258A4',
            borderWidth: '2px',
          }}
          onClick={async () => {
            history.push('/alfarm-ad-apply')
          }}
        >
          무료 광고 신청하러 가기
        </Button>
      </div>
    )
  }

  return (
    <>
      {freeAdAvailable && (
        <>
          <div className='fixed inset-0 z-50 flex px-2 py-10 overflow-y-scroll outline-none'>
            <div className='relative w-full max-w-2xl mx-auto my-auto shadow-lg'>
              <div className='relative flex flex-col justify-between flex-1 w-full bg-white border-0 rounded-lg outline-none'>
                <Header />
                <Body />
                <Footer />
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}

export default alfarmFreeAdNoticeModal

import SubTitle from '../components/atoms/subTitle'
import Page from '../components/atoms/page'
import { FcShipped as ShippingIcon } from 'react-icons/fc'
import { useForm } from 'react-hook-form'
import { Tooltip } from '../components/ADS/atoms'
import Button from '../components/atoms/button'
import backendApis from '../utils/backendApis'

const CooperativeCompanyApplciation = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = async (data) => {
    const result = await backendApis.applyAlwayzCooperativeSeller(data)
    if (result?.status === 200) {
      alert('신청이 완료되었습니다.')
    } else {
      alert('신청에 실패하였습니다. 신청 내용을 확인해주세요.')
    }
  }
  return (
    <>
      <Page title='올웨이즈 협력사 신청' Icon={ShippingIcon}>
        <div className='p-10 mb-10 bg-white shadow-md rounded-xl min-w-[600px] w-1/2'>
          <SubTitle className='mb-5'>
            올웨이즈 협력사 신청 페이지입니다
          </SubTitle>
          <div>
            올웨이즈에서는 카테고리 별로 시장 최저가를 만들 수 있는 판매자
            분들과 협력을 강화하고자 합니다.
          </div>
          <div>
            본 페이지에서 신청해주시면 담당자 확인 후 선정 건에 대해
            연락드리겠습니다.
          </div>
          <div className='mt-5 text-gray-500'>
            *신청 상품에 대해서는 노출량 증가, 올웨이즈 추천 뱃지, 계약, 직매입,
            행사 등 여러가지 방법을 통해 올웨이즈와 협력할 수 있습니다.
          </div>
        </div>
        <div className='p-10 mb-10 bg-white shadow-md rounded-xl min-w-[600px] w-1/2'>
          <SubTitle className='mb-5'>시장 최저가란?</SubTitle>
          <div>
            - 타 커머스의 PB 제품보다 더 저렴한 가격으로 판매가 가능해야 합니다.
          </div>
          <div>
            - 비슷한 제품의 최저가보다 최소 10% 이상 저렴해 가격 자체로 Wow를
            주어야 합니다.
          </div>
        </div>
        <div className='flex flex-row items-start justify-between flex-1 w-1/2 p-10 bg-white shadow-xl min-w-[600px] rounded-xl'>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col items-start justify-center flex-1 gap-5'
          >
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>핵심 상품 ID</div>
              <input
                type='text'
                className='h-8 rounded-lg w-80'
                placeholder='ex) 가장 주력하는 상품 아이디를 적어주세요'
                {...register('itemId', { required: true })}
              />
              <Tooltip text='가장 주력하는 상품의 올웨이즈 상품 아이디를 적어주세요.' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>주력 상품군</div>
              <input
                type='text'
                className='h-8 rounded-lg w-80'
                placeholder='ex)화장지,물티슈,주방티슈'
                {...register('categoryList', { required: true })}
              />
              <Tooltip text='상세히 주력 상품군을 입력해주세요.' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>차별화 포인트</div>
              <input
                type='text'
                className='h-8 rounded-lg w-80'
                placeholder='ex)국내 유일 총판, 창고 평수 1,000평, 일 택배건수 3,000건 등'
                {...register('differentiatingPoint', { required: true })}
              />
              <Tooltip text='시장 최저가를 만들 수 있는 이유에 대해 기술해주세요' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>일 택배건수</div>
              <input
                type='number'
                className='h-8 rounded-lg w-80'
                placeholder='ex) 2,000건'
                {...register('shippingOrder', { required: true })}
              />
              <Tooltip text='일 택배건수' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>창고 평수</div>
              <input
                type='number'
                className='h-8 rounded-lg w-80'
                placeholder='ex) 2,000평'
                {...register('warehouseArea', { required: true })}
              />
              <Tooltip text='창고 평수' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>본사/제조사/생산자 여부</div>
              <input
                type='checkbox'
                placeholder='isProducer'
                {...register('isProducer', {})}
              />
              <Tooltip text='본사/제조사/생산자 여부를 체크해주세요.' />
            </div>
            <div className='flex flex-row items-center justify-start flex-1 w-full'>
              <div className='mr-2 font-bold w-28'>직영 창고 여부</div>
              <input
                type='checkbox'
                placeholder='isProducer'
                {...register('isWarehouseOwner', {})}
              />
              <Tooltip text='직영 창고 여부 체크해주세요.' />
            </div>
            <div className='flex flex-col flex-1 w-full'>
              <span className='mb-3 mr-2 font-bold'>
                시장 최저가를 만들 수 있는 이유
              </span>
              <textarea {...register('description', {})} />
            </div>
            <input
              type='submit'
              value='제출하기'
              className='flex flex-row items-center justify-center flex-1 w-full px-5 py-2 font-bold text-white bg-blue-500 rounded-xl'
            />
          </form>
        </div>
      </Page>
    </>
  )
}
export default CooperativeCompanyApplciation

import React, { useState } from 'react'
import Input from '../atoms/input'
import Button from '../atoms/button'

function ItemSearchComponent({ onSearch }) {
  const [selectedSearchField, setSelectedSearchField] = useState('itemTitle')
  const [itemQuery, setItemQuery] = useState('')

  const handleSearch = () => {
    // 검색 버튼 클릭 시, 검색 조건을 사용하여 검색을 수행합니다.
    onSearch(selectedSearchField, itemQuery)
  }

  return (
    <div>
      <select
        value={selectedSearchField}
        className='border-r-0 border-gray-300 shadow-sm select-none rounded-l-md focus:border-indigo-500 focus:ring-indigo-500'
        onChange={(e) => setSelectedSearchField(e.target.value)}
      >
        <option value='itemTitle'>상품명</option>
        <option value='itemId'>상품아이디</option>
      </select>
      <Input
        className='text-left border-gray-300 shadow-sm select-none w-60 rounded-r-md focus:border-indigo-500 focus:ring-indigo-500'
        type='text'
        placeholder={`${
          selectedSearchField === 'itemTitle' ? '상품 키워드' : '상품 아이디'
        }를 입력해 주세요.`}
        value={itemQuery}
        onChange={(e) => {
          setItemQuery(e.target.value)
        }}
      />
      <Button
        onClick={handleSearch}
        appearance='positive'
        size='md'
        className='w-20 mx-3'
      >
        검색
      </Button>
    </div>
  )
}

export default ItemSearchComponent

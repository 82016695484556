import React, { useState, useEffect } from 'react'
import ReviewCouponRegisterModal from './ReviewCouponRegisterModal'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import Button from '../components/atoms/button'
import Divider from '../components/atoms/divider'
import Log from '../utils/log'
import Title from '../components/atoms/title'
import SubTitle from '../components/atoms/subTitle'
import DealRankNotice from '../components/molecules/dealRankNotice'

const ReviewCouponRegisterScreen = () => {
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [item, setItem] = useState({})
  const [updated, setUpdated] = useState(false)
  const fetchData = async () => {
    const result = await backendApis.getReviewEventAvailableSku()
    if (result?.status === 200 && result?.data) {
      setData(result.data)
    }
  }

  useEffect(() => {
    if (!data?.length) fetchData()
  }, [])
  useEffect(() => {
    fetchData()
  }, [updated])

  // const Available = ({ each }) => {
  //   const RegisterButton = () => {
  //     return (
  //       <Button
  //         onClick={() => {
  //           setShowModal(true)
  //           setItem(each)
  //         }}
  //         appearance={
  //           each.itemRankTestingSku || each.rankInfo?.itemRank === 'C'
  //             ? 'disabled'
  //             : 'red'
  //         }
  //         disabled
  //       >
  //         신청하기
  //       </Button>
  //     )
  //   }
  //   return (
  //     <>
  //       <tr
  //         style={{
  //           fontSize: 14,
  //         }}
  //       >
  //         <td className='font-bold'>
  //           {!each.itemRankTestingSku
  //             ? each.rankInfo?.itemRank || '-'
  //             : '테스트 중'}
  //         </td>
  //         <td>{each.itemTitle}</td>
  //         <td>{each._id}</td>
  //         <td>
  //           <RegisterButton itemId={each._id} />
  //         </td>
  //       </tr>
  //     </>
  //   )
  // }

  const Participated = ({ each }) => {
    const ReRegisterButton = () => {
      return (
        <Button
          onClick={async () => {
            setUpdated(false)
            if (
              window.confirm(
                `쿠폰금액을 10,000원으로 상향하시겠습니까? 
                금액 재설정은 남은 상품이 모두 판매된 후에 가능합니다.`,
              )
            ) {
              const result = await backendApis.setReviewEventUpdated(
                each._id,
                10000,
              )
              if (result?.status === 200) {
                alert(
                  '상향 완료되었습니다. 취소 및 금액 하향조정은 불가하며, 기타 문의 사항은 판매자 지원센터로 해주세요.',
                )
              }
              setUpdated(true)
              if (SellerStore.loadSellerInfo())
                Log.event(
                  'ReviewEventRegisterButton',
                  'ReviewEventRegisterButton',
                  {
                    click: 'ReviewEventRegisterButton',
                  },
                )
            }
          }}
          appearance={
            each.itemRankTestingSku || each.rankInfo?.itemRank === 'C'
              ? 'disabled'
              : 'red'
          }
          disabled={each.itemRankTestingSku || each.rankInfo?.itemRank === 'C'}
        >
          금액 올리기
        </Button>
      )
    }
    return (
      <>
        <tr
          style={{
            fontSize: 14,
          }}
        >
          <td className='font-bold'>
            {!each.itemRankTestingSku
              ? each.rankInfo?.itemRank || '-'
              : '테스트 중'}
          </td>
          <td>{each.itemTitle}</td>
          <td>{each._id}</td>
          <td>{each.reviewEventCouponValue}</td>
          <td>{each.reviewEventStockNumber}</td>
          <td>
            <ReRegisterButton itemId={each._id} />
          </td>
        </tr>
      </>
    )
  }

  return (
    <>
      <div className='page max-w-7xl'>
        <Title>[신청 마감] 신상품 체험단 이벤트 신청 페이지</Title>
        <SubTitle>
          [마감된 이벤트입니다] 적은 비용으로 정성들인 사진 후기를 빠르게 모아
          초기 판매량을 높여보세요!
        </SubTitle>
        <DealRankNotice minRank='B' />
        <img
          alt='intro'
          src='https://d22yqrpf3lokox.cloudfront.net/4b1d2e59-106a-4728-beb9-7e3507327b73.png'
          style={{ width: '70vw' }}
        />
        <div className='h-4' />
        {/* {data?.available?.length ? (
          <>
            <div className='h-4' />

            <div className='leading-6'>
              <div style={{ height: 12 }} />
              <div style={{ lineHeight: 1.8 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'left',
                    fontSize: 18,
                    fontWeight: 'bold',
                    lineHeight: 1.8,
                  }}
                >
                  진행 안내
                </div>
                <h3>
                  1. 신청 시 신상품 매대에 추가 노출이 되며, 해당 매대에서
                  제품을 구매한 고객들은 배송 완료 후 7일 이내로 사진 후기를
                  남기고 쿠폰을 받습니다.
                  <br />• 후기 작성 기준:{' '}
                  <span style={{ color: 'red' }}>
                    사진 필수, 100자 이상의 자세한 후기
                  </span>
                </h3>
                <h3>
                  2. 후기가 작성되는 경우에만 정산액에서 쿠폰 비용이 차감되며,
                  상품당 10개의 후기가 기본으로 진행됩니다.
                </h3>
                <h4>
                  • 기한 내 후기가 작성되지 않는 경우{' '}
                  <span style={{ color: 'red' }}>
                    비용이 차감되지 않습니다.
                  </span>
                </h4>
                <h4>
                  • 두 명 이상의 고객이 동시에 제품을 구매하시는 경우에는 10개
                  이상의 후기가 달리고 비용이 차감될 수 있습니다.
                </h4>
                <h3>
                  3. 단순 변심으로 인한 이벤트 참여 취소는 불가하며, 기타 문의는
                  판매자 지원센터에서 도와드리겠습니다.
                </h3>
                <h3>
                  4. 본 이벤트 참여를 통해 양질의 구매 후기를 모아{' '}
                  <span style={{ color: 'red' }}>판매량을 극대화</span>할 수
                  있습니다.
                </h3>
              </div>{' '}
              <div className='h-4' />
              <Divider />
              <div className='h-4' />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left',
                  fontSize: 25,
                  fontWeight: 'bold',
                  lineHeight: 1.8,
                }}
              >
                후기 이벤트 신청 가능한 상품 ({data?.available?.length || 0}개)
              </div>
            </div>
            <table>
              <thead className='text-lg'>
                <th>상품등급</th>
                <th>상품명</th>
                <th>상품아이디</th>
                <th>신청하기</th>
              </thead>
              <tbody>
                {data?.available.map((each) => (
                  <Available each={each} key={each._id} />
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <>
            <div className='mb-2 text-lg'>상품을 먼저 등록해 주세요!</div>
            <Button href='/items/registrations'>등록하러 가기</Button>
          </>
        )} */}

        {data?.participated?.length ? (
          <>
            <div className='mt-12 leading-6'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left',
                  fontSize: 25,
                  fontWeight: 'bold',
                  lineHeight: 1.8,
                }}
              >
                후기 이벤트 신청 완료된 상품 ({data?.participated?.length || 0}
                개)
              </div>
              <h4>[고객 관리 > 후기 확인]에서 후기를 확인하세요!</h4>
              <h5>
                2명 이상의 고객이 동시에 주문하시는 경우 남은 상품수가 0개
                이하로 내려갈 수도 있습니다.
              </h5>
              <div style={{ height: 10 }} />
            </div>
            <table>
              <thead className='text-lg'>
                <th style={{ width: 80 }}>상품등급</th>
                <th style={{ width: 200 }}>상품명</th>
                <th style={{ width: 150 }}>상품아이디</th>
                <th style={{ width: 100 }}>신청 쿠폰가</th>
                <th style={{ width: 100 }}>남은 상품수</th>
                <th style={{ width: 120 }}>쿠폰가 상향하기</th>
              </thead>
              <tbody>
                {data?.participated.map((each) => (
                  <Participated each={each} key={each._id} />
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}
      </div>
      <ReviewCouponRegisterModal
        showModal={showModal}
        setShowModal={setShowModal}
        item={item}
        setItem={setItem}
        data={data}
        setData={setData}
      />
    </>
  )
}

export default ReviewCouponRegisterScreen

import React from 'react'

const TodayDepartureStats = ({
  title,
  explanation,
  semiTitle,
  icon,
  stat,
  unit,
}) => {
  return (
    <div
      style={{
        flex: 1,
        backgroundColor: '#FAFAFA',
        border: 'none',
        borderWidth: 1,
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: 11,
      }}
    >
      <h3 className='my-5'>{title}</h3>
      <div
        style={{
          color: '#8D8D8D',
          marginTop: -10,
          marginBottom: 5,
          marginLeft: 30,
          marginRight: 30,
          fontWeight: 600,
        }}
      >
        {explanation}
      </div>
      <h3 className='mt-10'>{semiTitle}</h3>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <div
          style={{
            marginRight: 10,
            marginBottom: 40,
          }}
        >
          {icon}
        </div>
        <div
          style={{
            fontSize: 80,
            marginBottom: 30,
          }}
        >
          {stat}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            paddingLeft: 5,
            marginBottom: 50,
            marginTop: 10,
            fontSize: 20,
            color: '#888888',
          }}
        >
          {unit}
        </div>
      </div>
    </div>
  )
}

export default TodayDepartureStats

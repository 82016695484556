import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import AuthStore from '../stores/AuthStore'
import LoginScreen from './LoginScreen'
import { isMobile } from 'react-device-detect'
import MobileLoginScreen from './MobileLoginScreen'

const PrivateRoute = observer(({ component: Component, path }) => {
  // useEffect(() => {
  //   AuthStore.loadToken()
  // }, [])

  return (
    <Route
      //   {...rest}
      path={path}
      render={() =>
        !AuthStore.loadToken() ? (
          isMobile ? (
            <MobileLoginScreen />
          ) : (
            <LoginScreen />
          )
        ) : (
          <Component />
        )
      }
    />
  )
})

export default PrivateRoute

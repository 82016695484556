import { useState, useEffect } from 'react'
import Button from './button'
import backendApis from '../../utils/backendApis'
import Format from '../../utils/format'
import shortUUID from 'short-uuid'
import Card from './card'
import useKeyEscClose from '../../hooks/useKeyEscClose'
import { QuestionContentType, QuestionType } from '../../data/question'
import SellerStore from '../../stores/SellerStore'
import Divider from './divider'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import moment from 'moment'
import { GrClose as CloseIcon } from 'react-icons/gr'
import ItemQuestionAnswer from '../organisms/ItemQuestionAnswer'

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

const refundStatusName = {
  'refunding-arrived': '반품 수거 완료',
  'refunding-retrieving': '반품 수거 중',
  'refunding-retrieved': '반품 수거 중',
}

const statusName = {
  'team-gathering': '팀 모집 중',
  'canceling-team-gathering': '팀 모집 중 취소 요청 중',
  'canceled-team-gathering': '팀 모집 중 취소 완료',
  'pre-shipping': '상품 준비 중',
  'canceling-pre-shipping': '출고 중지 요청 중',
  'canceling-shipping': '배송 중지 요청 중',
  arrived: '배송완료',
  reviewed: '리뷰완료',
  refunding: '반품 접수',
  refunded: '반품 완료',
  'canceled-pre-shipping': '출고 중지 중 취소완료',
  'canceled-shipping': '배송 중 취소완료',
}

const orderStatus = {
  'team-gathering': '팀 모집 중',
  'canceling-team-gathering': '팀 모집 중 취소 요청 중',
  'canceled-team-gathering': '팀 모집 중 취소 완료',
  'pre-shipping': '상품 준비 중',
  'canceling-pre-shipping': '출고 중지 요청 중',
  'canceled-pre-shipping': '배송 취소됨',
  'canceled-shipping': '배송 취소됨',
  'canceling-shipping': '배송 중지 요청 중',
  shipping: '배송 중',
  arrived: '배송완료',
}

export default function ModalCS({
  questionType,
  questionContentType,
  questionId,
  questionInfo,
  orderId,
  imageSource,
  isButton = false,
  buttonText,
  userName = '',
  buttonLink,
  buttonCallbackFunction = () => {},
  cancelCallbackFunction = () => {},
  listContent = [],
  listButton = [
    {
      text: '',
      href: '',
      btnText: '',
    },
  ],
  showModal = false,
  setShowModal,
}) {
  const [type, setType] = useState(QuestionType.ITEM_QUESTION)
  const [orderData, setOrderData] = useState({})
  const [addressId, setAddressId] = useState('')
  const [text, setText] = useState('')
  const [textForSellectedQuestion, setTextForSellectedQuestion] = useState('')
  const [questionBundle, setQuestionBundle] = useState([])
  const [contentType, setContentType] = useState(questionContentType)
  const [isBundleListFolded, setIsBundleListFolded] = useState(true)
  const [selectedQuestionIdx, setSelectedQuestionIdx] = useState(-1)
  const [selectedQuestionId, setSelectedQuestionId] = useState(questionId)
  const [selectedImage, setSelectedImage] = useState(null)
  const translator = shortUUID()
  const [isOpenItemQuestionModify, setIsOpenItemQuestionModify] =
    useState(false)
  const [mainQuestion, setMainQuestion] = useState('')
  const [textForModify, setTextForModify] = useState('')

  useEffect(async () => {
    const result = await backendApis.loadOrderData(orderId)
    if (result.status === 200) {
      setOrderData(result.data[0])
      setAddressId(
        `${Format.DateToday(result.data[0]?.payedAt)}${
          checkIfValidUUID(result.data[0]?.addressInfo?.addressId) &&
          translator.fromUUID(
            result.data[0]?.addressInfo?.addressId || result.data[0]?._id,
          )
        }`.substring(2),
      )
    }
  }, [orderId])
  const fistOption = orderData?.optionIndices?.['0']
  const secondOption = orderData?.optionIndices?.['1']
  const firstOptionNameInfo =
    orderData?.itemInfo?.optionsInfo?.totalOptions['0']?.[Number(fistOption)]
      ?.name
  const secondOptionNameInfo =
    orderData?.itemInfo?.optionsInfo?.totalOptions['1']?.[Number(secondOption)]
      ?.name

  const fetchQuestionBundle = async () => {
    SellerStore.setIsLoading(true)
    const orderId = questionInfo?.orderId
    let result
    if (contentType === QuestionContentType.ORDER) {
      result = await backendApis.loadQuestionBundleByOrderId(
        questionType.description,
        orderId,
      )
    } else {
      result = await backendApis.loadQuestionsByCondition(
        { type: questionType.description, questionId },
        1,
        0,
      )
    }
    if (result?.status === 200) {
      setQuestionBundle(result?.data)
      setMainQuestion(result?.data[0]?.answerInfo?.text || '')
      setTextForModify(result?.data[0]?.answerInfo?.text || '')
      setSelectedQuestionId(questionId)
      result?.data.forEach((question, idx) => {
        if (question?._id === questionId) {
          setSelectedQuestionIdx(idx)
        }
      })
    } else {
      setQuestionBundle([])
    }
    SellerStore.setIsLoading(false)
  }
  const openImageModal = (image) => {
    setSelectedImage(image)
  }

  const closeImageModal = () => {
    setSelectedImage(null)
  }

  const handleSubmit = async () => {
    try {
      // 1. submit
      SellerStore.setIsLoading(true)
      if (questionType === QuestionType.ITEM_QUESTION) {
        if (contentType === QuestionContentType.ORDER) {
          await backendApis.answerItemQuestion(
            selectedQuestionId,
            textForSellectedQuestion,
          )
        } else {
          await backendApis.answerItemQuestion(selectedQuestionId, text)
        }
      } else if (contentType === QuestionContentType.ORDER) {
        await backendApis.answerQuestionFromAdmin(
          selectedQuestionId,
          textForSellectedQuestion,
        )
      } else {
        await backendApis.answerQuestionFromAdmin(selectedQuestionId, text)
      }
      // 2. fetch
      await fetchQuestionBundle()
      // 3. reset
      setText('')
      setTextForSellectedQuestion('')
      setTextForSellectedQuestion('')
      setSelectedQuestionId('')
      setSelectedQuestionIdx(-1)
    } catch (error) {
      alert(
        `답변을 제출하는 도중 오류가 발생했습니다. 판매자 문의센터로 문의 부탁드립니다.\n${error}`,
      )
    } finally {
      SellerStore.setIsLoading(false)
    }
  }

  const handleBatchSubmit = async () => {
    try {
      if (
        window.confirm(
          '일괄 제출은 가장 최근 문의에 대해 기입한 답변을 제출하고 나머지는 ["같은 주문번호의 최신 문의의 답변을 확인해주세요."]로 제출됩니다. 계속 진행하시겠습니까?',
        )
      ) {
        const TEXT_FOR_BATCH =
          '같은 주문번호의 최신 문의의 답변을 확인해주세요.'
        SellerStore.setIsLoading(true)
        // 0. reduce
        const batch = questionBundle.reduce((acc, cur) => {
          if (!cur.questionInfo?.answerInfo) {
            return [...acc, cur._id]
          }
          return [...acc]
        }, [])
        if (batch.length === 0) throw new Error('답변할 문의가 없습니다.')
        // 1. submit
        const latest = batch.pop()
        if (questionType === QuestionType.ITEM_QUESTION) {
          await backendApis.answerItemQuestion(latest, text)
          for await (const questionId of batch) {
            await backendApis.answerItemQuestion(questionId, TEXT_FOR_BATCH)
          }
        } else {
          await backendApis.answerQuestionFromAdmin(latest, text)
          for await (const questionId of batch) {
            await backendApis.answerQuestionFromAdmin(
              questionId,
              TEXT_FOR_BATCH,
            )
          }
        }
        // 2. fetch
        await fetchQuestionBundle()
        // 3. reset
        setText('')
        setTextForSellectedQuestion('')
        setSelectedQuestionId('')
        setSelectedQuestionIdx(-1)
      }
    } catch (error) {
      alert(
        `답변을 일괄 제출하는 도중 오류가 발생했습니다. 판매자 문의센터로 문의 부탁드립니다.\n${error}`,
      )
    } finally {
      SellerStore.setIsLoading(false)
    }
  }

  const handleSelectQuestion = (id, idx) => {
    setSelectedQuestionId(id)
    setSelectedQuestionIdx(idx)
    setTextForSellectedQuestion('')
  }

  useKeyEscClose(() => {
    setShowModal(false)
    cancelCallbackFunction()
  })

  useEffect(() => {
    setType(questionType)
  }, [questionType])

  useEffect(async () => {
    if (
      questionInfo?.type === QuestionContentType.ORDER.description.toLowerCase()
    ) {
      setContentType(QuestionContentType.ORDER)
    } else {
      setContentType(QuestionContentType.ITEM)
    }
  }, [questionInfo])

  useEffect(async () => {
    if (showModal) {
      await fetchQuestionBundle()
    } else {
      setQuestionBundle([])
    }
  }, [showModal, orderId, questionId, contentType, type])

  useEffect(() => {
    const target = document.getElementById(
      `modalcs-${selectedQuestionId}-${selectedQuestionIdx}`,
    )
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    } else {
      const sectionTarget = document.getElementById('question-details-section')
      if (sectionTarget)
        sectionTarget.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
    }
  }, [selectedQuestionIdx, showModal, questionBundle, contentType, type])

  return (
    <>
      {showModal ? (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none'>
            <div className='relative w-full max-w-3xl mx-auto my-auto'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <h3 className='text-xl font-semibold text-black'>
                    문의 상세(
                    {contentType === QuestionContentType.ORDER
                      ? '주문 문의'
                      : '상품 문의'}
                    )
                  </h3>
                </div>
                {/* body */}
                <div className='relative flex-auto px-10 py-10'>
                  {imageSource && (
                    <div className='mb-5 text-lg leading-relaxed text-sub-500'>
                      <img alt='' src={imageSource} />
                    </div>
                  )}
                  <Card
                    title='고객 주문 정보'
                    appearance='tableStyle'
                    size='xl'
                  >
                    <table>
                      <tbody className='items-center mt-3'>
                        <tr>
                          <td className='text-lg text-center'>작성자</td>
                          <td className='text-lg text-center'>
                            {userName || ''}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>결제일시</td>
                          <td className='text-lg text-center'>
                            {Format.DateString(orderData?.payedAt)}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>배송정보</td>
                          <td className='text-lg text-start text-bold'>
                            수령인 :
                            <span className='text-lg'>
                              {orderData?.addressInfo?.recipient}
                            </span>
                            <br />
                            전화번호 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.addressInfo?.recipientPhoneNumber}
                            </span>
                            <br />
                            우편주소 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.addressInfo?.postcode}
                            </span>
                            <br />
                            주소 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.addressInfo?.postcodeAddress}
                            </span>
                            <br />
                            상세 주소 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.addressInfo?.detailAddress}
                            </span>
                            <br />
                            배송 메세지 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.addressInfo?.recipient}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                  <Card
                    title='주문 상세'
                    appearance='tableStyle'
                    size='xl'
                    className='mt-10'
                  >
                    <table>
                      <tbody className='items-center mt-3'>
                        <tr>
                          <td className='text-lg text-center'>총 배송비</td>
                          <td className='text-lg text-start'>
                            {orderData?.shippingFee}원
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>합배송 아이디</td>
                          <td className='text-lg text-start'>
                            {addressId || ''}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>상품 정보</td>
                          <td className='text-lg text-start text-bold'>
                            상품명 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.itemInfo?.itemTitle}
                            </span>
                            <br />
                            옵션명 :
                            <span className='text-lg'>
                              {' '}
                              {`${firstOptionNameInfo || ''} ${
                                secondOptionNameInfo || ''
                              } `}
                            </span>
                            <br />총 판매가 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.totalPrice}원
                            </span>
                            <br />
                            수량 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.quantity}개
                            </span>
                            <br />
                            쿠폰 할인액 :
                            <span className='text-lg'>
                              {' '}
                              {orderData?.selectedCouponInfo
                                ?.finalDiscountAmount || 0}
                              원
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>배송상태</td>
                          <td className='text-lg text-start'>
                            <span className='text-lg'>
                              {' '}
                              {orderData?.refundInfo?.refundStatus
                                ? refundStatusName[
                                    orderData?.refundInfo?.refundStatus
                                  ]
                                : orderStatus[orderData?.status]}
                            </span>
                            <br />
                            출고 일시 :
                            <span className='text-lg'>
                              {' '}
                              {Format.DateString(
                                orderData?.shippingInfo?.trackingDetail?.['0']
                                  ?.timeString,
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg text-center'>처리상태</td>
                          <td className='text-lg text-start'>
                            <span className='text-lg'>
                              {statusName[orderData?.status]}
                            </span>
                            <br />
                            <span className='text-lg'>
                              {Format.DateString(
                                orderData?.history?.findLast(
                                  (e) => e.status === orderData?.status,
                                )?.recordedAt,
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                  <Card
                    title={`문의 내용 및 답변 - ${
                      type === QuestionType.ITEM_QUESTION
                        ? '고객 문의'
                        : '고객센터 문의'
                    }`}
                    appearance='tableStyle'
                    size='xl'
                    className='mt-10'
                  >
                    <table>
                      <tbody className='items-center mt-3'>
                        <tr>
                          <td>
                            <div className='question-writer flex flex-col space-y-2 text-lg'>
                              <section id='question-details-section'>
                                <div className='flex font-bold justify-between p-2 text-xl text-slate-400 mb-2'>
                                  <div>
                                    {contentType === QuestionContentType.ORDER
                                      ? '대표 문의 내역(주문 문의) '
                                      : '문의 내역(상품 문의)'}
                                  </div>
                                  <div className='flex space-x-2 items-center'>
                                    {selectedQuestionIdx === 0 && (
                                      <div className='flex text-xs my-auto'>
                                        <div className='w-14  p-1 text-center rounded-sm bg-emerald-400 text-white'>
                                          현재 선택
                                        </div>
                                      </div>
                                    )}
                                    {questionBundle[0]?.answerInfo ? (
                                      <div className='flex text-xs my-auto'>
                                        <div className='w-14  p-1 text-center rounded-sm bg-positive-400 text-white'>
                                          답변 완료
                                        </div>
                                      </div>
                                    ) : (
                                      <div className='flex text-xs my-auto'>
                                        <div className='w-12  p-1 text-center rounded-sm bg-red-400 text-white'>
                                          미답변
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='w-full justify-start p-2'>
                                  <div
                                    id={`modalcs-${
                                      questionBundle[0]?._id
                                    }-${0}`}
                                    onClick={() => {
                                      if (!questionBundle[0]?.answerInfo)
                                        handleSelectQuestion(
                                          questionBundle[0]?._id,
                                          0,
                                        )
                                    }}
                                    role='presentation'
                                    className={` question-detils-body ${
                                      contentType ===
                                        QuestionContentType.ORDER &&
                                      !questionBundle[0]?.answerInfo
                                        ? 'hover:bg-pink-50 hover:cursor-pointer'
                                        : ''
                                    } w-full bg-transparent text-left whitespace-normal break-all`}
                                  >
                                    <div className='question-type flex w-full'>
                                      <div className='w-1/6 font-bold'>
                                        {contentType ===
                                        QuestionContentType.ORDER
                                          ? '주문 번호'
                                          : '상품명'}
                                      </div>
                                      <div className='flex w-5/6'>
                                        <div className='w-5/6 break-all whitespace-normal'>
                                          {contentType ===
                                          QuestionContentType.ORDER
                                            ? questionBundle[0]?.orderId
                                            : questionInfo?.itemTitle}
                                        </div>
                                        <div className='flex flex-col justify-center w-1/6 ml-1 pl-1'>
                                          <button
                                            type='button'
                                            className='w-full'
                                            onClick={async () => {
                                              if (
                                                contentType ===
                                                QuestionContentType.ORDER
                                              ) {
                                                await navigator.clipboard.writeText(
                                                  `${questionBundle[0]?.orderId}`,
                                                )
                                              } else {
                                                await navigator.clipboard.writeText(
                                                  `${questionInfo?.itemTitle}`,
                                                )
                                              }
                                              alert(
                                                `${
                                                  contentType ===
                                                  QuestionContentType.ORDER
                                                    ? '주문번호가'
                                                    : `상품명이`
                                                } 복사되었습니다.`,
                                              )
                                            }}
                                          >
                                            <div className='p-1.5 font-bold text-xs border border-solid border-black rounded-sm text-black bg-neutral-50 hover:bg-neutral-100'>
                                              {contentType ===
                                              QuestionContentType.ORDER
                                                ? '주문번호 복사'
                                                : `상품명 복사`}
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='flex'>
                                      <div className='w-1/6 font-bold'>
                                        작성자
                                      </div>
                                      <div className='w-1/3'>
                                        {contentType ===
                                        QuestionContentType.ORDER
                                          ? questionBundle[0]?.questionInfo
                                              ?.userName || ''
                                          : questionInfo?.userName || ''}
                                      </div>
                                    </div>
                                    <div className='flex'>
                                      <div className='w-1/6 font-bold'>
                                        작성일시
                                      </div>
                                      <div className='w-1/3'>
                                        {`${moment(
                                          questionBundle[0]?.questionInfo
                                            ?.createdAt,
                                        ).format('YYYY-MM-DD')}  ${moment(
                                          questionBundle[0]?.questionInfo
                                            ?.createdAt,
                                        ).format('HH:mm:ss')}`}
                                      </div>
                                    </div>
                                    <div className='flex'>
                                      <div className='w-1/6 font-bold'>
                                        문의 분류
                                      </div>
                                      <div className='w-1/3'>
                                        {contentType ===
                                        QuestionContentType.ORDER
                                          ? questionBundle[0]?.questionInfo
                                              ?.mainReason
                                          : questionInfo?.mainReason}
                                      </div>
                                    </div>
                                    <div className='flex'>
                                      <div className='w-1/6 font-bold'>
                                        문의 제목
                                      </div>
                                      <div className='w-1/3'>
                                        {contentType ===
                                        QuestionContentType.ORDER
                                          ? questionBundle[0]?.questionInfo
                                              ?.title
                                          : questionInfo?.title}
                                      </div>
                                    </div>
                                    <div className='question-content flex'>
                                      <div className='w-1/6 font-bold'>
                                        문의 내용
                                      </div>
                                      <div className='flex w-5/6'>
                                        <div className='w-5/6 text-left break-all whitespace-normal'>
                                          {contentType ===
                                          QuestionContentType.ORDER
                                            ? questionBundle[0]?.questionInfo
                                                ?.text
                                            : questionInfo?.text}
                                        </div>
                                        <div className='flex flex-col w-1/6 justify-center ml-1 pl-1'>
                                          <button
                                            type='button'
                                            className='w-full'
                                            onClick={async () => {
                                              await navigator.clipboard.writeText(
                                                `${
                                                  contentType ===
                                                  QuestionContentType.ORDER
                                                    ? questionBundle[0]
                                                        ?.questionInfo?.text
                                                    : questionInfo?.text
                                                }`,
                                              )
                                              alert(
                                                `문의내용이 복사되었습니다.`,
                                              )
                                            }}
                                          >
                                            <div className='p-1.5 font-bold text-xs border border-solid border-black rounded-sm text-black bg-neutral-50 hover:bg-neutral-100'>
                                              문의내용 복사
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    {questionBundle[0]?.questionInfo
                                      ?.questionImageUris && (
                                      <div className='question-img-area flex'>
                                        <div className='w-1/6 font-bold'>
                                          문의 이미지
                                        </div>
                                        <div className='flex flex-col w-5/6'>
                                          {questionBundle[0]?.questionInfo
                                            ?.questionImageUris?.length > 0
                                            ? questionBundle[0]?.questionInfo?.questionImageUris?.map(
                                                (uri) => (
                                                  <div>
                                                    <img
                                                      src={uri}
                                                      alt='문의 이미지'
                                                      width={300}
                                                    />
                                                  </div>
                                                ),
                                              )
                                            : null}
                                        </div>
                                      </div>
                                    )}
                                    {questionBundle[0]?.answerInfo && (
                                      <div className='answer-content flex mt-2'>
                                        <div className='w-1/6 font-bold'>
                                          답변 작성 일시
                                        </div>
                                        <div className='w-5/6'>
                                          {`${moment(
                                            questionBundle[0]?.answerInfo
                                              ?.createdAt,
                                          ).format('YYYY-MM-DD')}  ${moment(
                                            questionBundle[0]?.answerInfo
                                              ?.createdAt,
                                          ).format('HH:mm:ss')}`}
                                        </div>
                                      </div>
                                    )}
                                    {questionBundle[0]?.answerInfo && (
                                      <div className='answer-content flex'>
                                        <div className='w-1/6 font-bold'>
                                          답변 내용
                                        </div>
                                        <div className='flex w-5/6 '>
                                          <div className='w-5/6 text-left whitespace-normal break-all'>
                                            {mainQuestion}
                                          </div>
                                          <div className='flex flex-col w-1/6 justify-center ml-1 pl-1'>
                                            <button
                                              type='button'
                                              className='w-full'
                                              onClick={async () => {
                                                await navigator.clipboard.writeText(
                                                  { mainQuestion },
                                                )
                                                alert(
                                                  `답변내용이 복사되었습니다.`,
                                                )
                                              }}
                                            >
                                              <div className='p-1.5 font-bold text-xs border border-solid border-black rounded-sm text-black bg-neutral-50 hover:bg-neutral-100'>
                                                답변내용 복사
                                              </div>
                                            </button>
                                            <button
                                              type='button'
                                              className='w-full'
                                              onClick={async () => {
                                                setIsOpenItemQuestionModify(
                                                  (prev) => !prev,
                                                )
                                              }}
                                            >
                                              <div className='p-1.5 font-bold text-xs border border-solid border-black rounded-sm text-black bg-neutral-50 hover:bg-neutral-100'>
                                                {isOpenItemQuestionModify
                                                  ? '답변 수정중'
                                                  : '답변내용 수정'}
                                              </div>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {isOpenItemQuestionModify && (
                                      <div>
                                        <div className='answerTextArea'>
                                          <textarea
                                            className='w-full h-40 border border-slate-200 rounded-lg'
                                            placeholder='수정할 답변을 입력해주세요.'
                                            value={textForModify}
                                            onChange={(e) => {
                                              setTextForModify(e.target.value)
                                            }}
                                          />
                                        </div>
                                        <div className='flex justify-end'>
                                          <Button
                                            appearance='positive'
                                            size='lg'
                                            onClick={async () => {
                                              const typeString =
                                                questionType ===
                                                QuestionType.QUESTION_FROM_ADMIN
                                                  ? 'QUESTION_FROM_ADMIN'
                                                  : 'ITEM_QUESTION'
                                              try {
                                                const result =
                                                  await backendApis.modifyItemQuestion(
                                                    {
                                                      questionId,
                                                      text: textForModify,
                                                      type: typeString,
                                                    },
                                                  )

                                                if (!result.data.success) {
                                                  alert(result.data.msg)
                                                  return
                                                }

                                                setIsOpenItemQuestionModify(
                                                  false,
                                                )
                                                setMainQuestion(textForModify)
                                              } catch (e) {
                                                console.log(e)
                                              }
                                            }}
                                          >
                                            답변 수정
                                          </Button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {contentType === QuestionContentType.ORDER &&
                                    selectedQuestionIdx === 0 &&
                                    !questionBundle[0]?.answerInfo && (
                                      <>
                                        <div className='answerTextArea mt-2'>
                                          <textarea
                                            className='w-full h-40 border border-slate-200 rounded-lg'
                                            placeholder='답변을 입력해주세요.'
                                            value={textForSellectedQuestion}
                                            onChange={(e) => {
                                              setTextForSellectedQuestion(
                                                e.target.value,
                                              )
                                            }}
                                          />
                                        </div>
                                        <div className='flex justify-end'>
                                          <Button
                                            appearance='positive'
                                            size='lg'
                                            onClick={handleSubmit}
                                          >
                                            답변 제출
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                </div>
                              </section>
                              <Divider />
                              {contentType === QuestionContentType.ORDER &&
                                questionBundle.length > 1 && (
                                  <>
                                    <section
                                      id='re-question-list-section'
                                      className='w-full'
                                    >
                                      <div className='flex flex-col space-y-2 p-2'>
                                        <Button
                                          appearance='transparent'
                                          textColor='black'
                                          className='flex text-left text-lg mt-2 hover:bg-slate-50 hover:rounded-sm'
                                          onClick={() => {
                                            setIsBundleListFolded(
                                              !isBundleListFolded,
                                            )
                                          }}
                                        >
                                          <div className='flex font-bold w-full text-slate-400 text-xl justify-between items-center'>
                                            <div>재문의 목록</div>
                                            <div className='text-slate-500'>
                                              {isBundleListFolded ? (
                                                <MdExpandLess size='36' />
                                              ) : (
                                                <MdExpandMore size='36' />
                                              )}
                                            </div>
                                          </div>
                                        </Button>
                                        {isBundleListFolded && (
                                          <>
                                            <div className='re-question-container flex-col'>
                                              {questionBundle
                                                .slice(1)
                                                .map((question, index) => (
                                                  <div>
                                                    <div
                                                      id={`modalcs-${
                                                        question._id
                                                      }-${index + 1}`}
                                                      onClick={() => {
                                                        if (
                                                          !question?.answerInfo
                                                        )
                                                          handleSelectQuestion(
                                                            question._id,
                                                            index + 1,
                                                          )
                                                      }}
                                                      role='presentation'
                                                      className={`bg-transparent ${
                                                        question.answerInfo
                                                          ? 'hover:cursor-default my-6'
                                                          : 'hover:cursor-pointer mt-6'
                                                      } ${
                                                        selectedQuestionIdx ===
                                                        index + 1
                                                          ? ''
                                                          : 'mb-6'
                                                      } rounded-lg w-full break-all`}
                                                    >
                                                      <div
                                                        className={`${
                                                          question.answerInfo
                                                            ? 'rounded-t-md bg-slate-200 opacity-90'
                                                            : 'rounded-md bg-pink-100 opacity-90 hover:bg-pink-200'
                                                        } ${
                                                          selectedQuestionIdx ===
                                                          index + 1
                                                            ? 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white'
                                                            : ''
                                                        } text-base p-3`}
                                                      >
                                                        <div className='flex flex-col space-y-1.5'>
                                                          <div className='font-bold flex justify-between'>
                                                            <div>
                                                              {question
                                                                .questionInfo
                                                                ?.userName ||
                                                                ''}
                                                            </div>
                                                            <div className='flex space-x-2'>
                                                              {index + 1 ===
                                                                selectedQuestionIdx && (
                                                                <div className='flex text-xs'>
                                                                  <div className='w-14  p-1 text-center rounded-sm bg-emerald-400 text-white'>
                                                                    현재 선택
                                                                  </div>
                                                                </div>
                                                              )}
                                                              {question.answerInfo ? (
                                                                <div className='flex text-xs'>
                                                                  <div className='w-14  p-1 text-center rounded-sm bg-positive-400 text-white'>
                                                                    답변 완료
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <div className='flex text-xs'>
                                                                  <div className='w-12  p-1 text-center rounded-sm bg-red-400 text-white'>
                                                                    미답변
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                          <div className='flex justify-start text-left break-all whitespace-normal'>
                                                            {
                                                              question
                                                                .questionInfo
                                                                ?.text
                                                            }
                                                          </div>
                                                          <div>
                                                            {question
                                                              ?.questionInfo
                                                              ?.questionImageUris
                                                              ?.length > 0 && (
                                                              <div className='flex justify-start text-left break-all whitespace-normal'>
                                                                {question.questionInfo.questionImageUris.map(
                                                                  (
                                                                    imageUri,
                                                                    index,
                                                                  ) => (
                                                                    <div
                                                                      key={
                                                                        index
                                                                      }
                                                                      onClick={() =>
                                                                        openImageModal(
                                                                          imageUri,
                                                                        )
                                                                      }
                                                                      role='button' // 클릭 가능한 버튼 역할
                                                                      tabIndex={
                                                                        0
                                                                      } // 포커스 가능하도록 tabindex 추가
                                                                      onKeyPress={(
                                                                        e,
                                                                      ) => {
                                                                        if (
                                                                          e.key ===
                                                                            'Enter' ||
                                                                          e.key ===
                                                                            ' '
                                                                        ) {
                                                                          openImageModal(
                                                                            imageUri,
                                                                          )
                                                                        }
                                                                      }}
                                                                    >
                                                                      <img
                                                                        key={
                                                                          index
                                                                        }
                                                                        alt={`image_${index}`}
                                                                        src={
                                                                          imageUri
                                                                        }
                                                                        className='w-40 h-40 mr-3 rounded-md border border-[#000000F]'
                                                                      />
                                                                    </div>
                                                                  ),
                                                                )}
                                                              </div>
                                                            )}
                                                            {selectedImage && (
                                                              <div className='modal-content flex relative'>
                                                                <img
                                                                  src={
                                                                    selectedImage
                                                                  }
                                                                  alt='Surfer in the ocean'
                                                                />
                                                                <div
                                                                  className='absolute top-0 right-0 p-2'
                                                                  style={{
                                                                    backgroundColor:
                                                                      'white',
                                                                  }}
                                                                >
                                                                  <CloseIcon
                                                                    className='w-8 h-8'
                                                                    onClick={
                                                                      closeImageModal
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            )}
                                                          </div>
                                                          <div className='flex items-end text-sm text-slate-400'>
                                                            <div className='w-5/6 text-left'>
                                                              {`${moment(
                                                                question
                                                                  .questionInfo
                                                                  ?.createdAt,
                                                              )?.format(
                                                                'YYYY-MM-DD',
                                                              )}  ${moment(
                                                                question
                                                                  .questionInfo
                                                                  ?.createdAt,
                                                              )?.format(
                                                                'HH:mm:ss',
                                                              )}`}
                                                            </div>
                                                            <div className='flex justify-end w-1/6'>
                                                              <button
                                                                type='button'
                                                                className='w-5/6'
                                                                onClick={async () => {
                                                                  await navigator.clipboard.writeText(
                                                                    `${question.questionInfo?.text}`,
                                                                  )
                                                                  alert(
                                                                    `문의내용이 복사되었습니다.`,
                                                                  )
                                                                }}
                                                              >
                                                                <div
                                                                  className={`p-1.5 font-bold text-xs rounded-sm border border-solid ${
                                                                    index +
                                                                      1 ===
                                                                    selectedQuestionIdx
                                                                      ? 'border-white  text-white hover:bg-opacity-20 hover:bg-white'
                                                                      : 'border-black  text-black hover:bg-neutral-300'
                                                                  } bg-transparent`}
                                                                >
                                                                  문의내용 복사
                                                                </div>
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {question.answerInfo && (
                                                        <ItemQuestionAnswer
                                                          answerInfo={
                                                            question?.answerInfo
                                                          }
                                                          questionId={
                                                            question._id
                                                          }
                                                          type={type}
                                                        />
                                                      )}
                                                    </div>
                                                    {index + 1 ===
                                                      selectedQuestionIdx &&
                                                      !question?.answerInfo && (
                                                        <div className='my-1'>
                                                          <div className='answerTextArea'>
                                                            <textarea
                                                              className='w-full h-40 border border-slate-200 rounded-lg'
                                                              placeholder='답변을 입력해주세요.'
                                                              value={
                                                                textForSellectedQuestion
                                                              }
                                                              onChange={(e) => {
                                                                setTextForSellectedQuestion(
                                                                  e.target
                                                                    .value,
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                          <div className='flex justify-end'>
                                                            <Button
                                                              appearance='positive'
                                                              size='lg'
                                                              onClick={
                                                                handleSubmit
                                                              }
                                                            >
                                                              답변 제출
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      )}
                                                  </div>
                                                ))}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </section>
                                    <Divider />
                                  </>
                                )}
                              {((questionContentType ===
                                QuestionContentType.ORDER &&
                                questionBundle.filter((question) => {
                                  if (!question?.answerInfo) return true
                                  return false
                                }).length > 1) ||
                                (questionContentType ===
                                  QuestionContentType.ITEM &&
                                  !questionBundle[0]?.answerInfo)) && (
                                <>
                                  <section id='submit-answer-section'>
                                    <div className='p-2'>
                                      <div className='font-bold text-xl text-left text-slate-400 mb-2'>
                                        {contentType ===
                                        QuestionContentType.ORDER
                                          ? '일괄 답변 제출'
                                          : '답변 제출'}
                                      </div>
                                      <div className='answerTextArea mt-2'>
                                        <textarea
                                          className='w-full h-40 border border-slate-200 rounded-lg'
                                          placeholder='답변을 입력해주세요.'
                                          value={text}
                                          onChange={(e) => {
                                            setText(e.target.value)
                                          }}
                                        />
                                      </div>
                                      <div className='flex justify-end space-x-4'>
                                        {contentType ===
                                        QuestionContentType.ORDER ? (
                                          <Button
                                            appearance='positiveSub'
                                            size='lg'
                                            onClick={handleBatchSubmit}
                                          >
                                            일괄 제출
                                          </Button>
                                        ) : (
                                          <Button
                                            appearance='positive'
                                            size='lg'
                                            onClick={handleSubmit}
                                          >
                                            답변 제출
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </section>
                                  <Divider />
                                </>
                              )}
                              <div className='whitespace-normal text-left warning p-2 text-lg'>
                                <ul>
                                  {contentType === QuestionContentType.ORDER ? (
                                    <>
                                      <li>
                                        ✅ 주문 문의의 경우{' '}
                                        <span className='font-bold'>
                                          재문의 목록
                                        </span>
                                        에서 같은 주문 번호에 대한 문의들을 확인
                                        가능합니다.
                                      </li>
                                      <li>
                                        ✅{' '}
                                        <span className='font-bold'>
                                          재문의 목록
                                        </span>{' '}
                                        중 미답변 문의만 클릭하여 선택
                                        가능합니다.
                                      </li>
                                      <li>
                                        ✅{' '}
                                        <span className='font-bold text-positive-500'>
                                          [답변 제출]
                                        </span>
                                        시 선택된 문의만 답변 완료 처리됩니다.
                                      </li>
                                      <li>
                                        ✅{' '}
                                        <span className='font-bold text-green-700'>
                                          [일괄 제출]
                                        </span>
                                        시 최신 문의에 기입한 답변을 제출하고
                                        나머지는 자동 답변 완료 처리됩니다.
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      <li>
                                        ✅ 상품 문의는 단건 처리만 가능합니다.
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                  {/* </Card> */}
                  <div className='text-base'>
                    {listContent ? (
                      <ol className='list-decimal'>
                        {listContent.map((list) => {
                          return <li key={list.toString()}>{list}</li>
                        })}
                      </ol>
                    ) : null}
                  </div>
                  <div>
                    {listButton[0].text !== ''
                      ? listButton.map((button) => {
                          return (
                            <div
                              className='flex flex-row items-center text-lg'
                              key={button.href}
                            >
                              <div className='mr-5'>{button.text}</div>
                              <div>
                                <Button href={button.href}>
                                  {button.btnText}
                                </Button>
                              </div>
                            </div>
                          )
                        })
                      : null}
                  </div>
                </div>
                {/* footer */}
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => {
                      setShowModal(false)
                      cancelCallbackFunction()
                    }}
                  >
                    닫기
                  </button>
                  {isButton ? (
                    <Button
                      href={buttonLink}
                      appearance='positive'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        setShowModal(false)
                        buttonCallbackFunction()
                      }}
                    >
                      {buttonText}
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      ) : null}
    </>
  )
}

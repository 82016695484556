import { useState } from 'react'

const Tooltip = ({ descriptions }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const colorCode = '#1258A4'

  return (
    <>
      <button
        style={{
          cursor: 'pointer',
          color: colorCode,
          fontWeight: 'bold',
          fontSize: 16,
          width: 20,
          height: 20,
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 8,
          border: `2px solid ${colorCode}`,
          backgroundColor: 'white',
        }}
        type='button'
        onClick={() => setShowTooltip(!showTooltip)}
      >
        ?
      </button>
      {showTooltip && (
        <div
          style={{
            visibility: 'visible',
            backgroundColor: 'black',
            color: '#fff',
            textAlign: 'center',
            borderRadius: '6px',
            padding: '10px',
            position: 'absolute',
            zIndex: 100,
            top: '125%',
            left: '50%',
            transform: 'translateX(-50%)',
            opacity: 1,
            transition: 'opacity 0.3s',
          }}
        >
          {descriptions.map((description) => (
            <div key={description}>{description}</div>
          ))}
        </div>
      )}
    </>
  )
}
export default Tooltip

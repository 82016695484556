import SellerStore from '../../stores/SellerStore'
import React from 'react'
import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import OrderStore from '../../stores/OrderStore'

const DirectShippingCompleteButton = ({ order, setOrders, orders }) => {
  return (
    <Button
      size='sm'
      appearance='blue'
      type='button'
      onClick={async () => {
        if (SellerStore.isLoading) {
          return
        }
        const confirmedDirectShippingComplete = window.confirm(
          '정말 직접 배송 완료 처리를 하시겠어요? 구매하신 고객님께 배송 완료 알림이 갑니다.',
        )
        if (!confirmedDirectShippingComplete) {
          return
        }
        SellerStore.setIsLoading(true)
        const result = await backendApis.setOrderStatusArrived(order._id)
        SellerStore.setIsLoading(false)
        if (result?.status === 200) {
          let status = 'shipping'
          if (!order?.shippingInfo?.trackingDetail) {
            status = 'shipping-sending'
          }
          OrderStore.setDisplayedOrders(
            OrderStore.displayedOrders.map((orderTemp) => {
              if (orderTemp._id === order._id) {
                return {
                  ...orderTemp,
                  status: 'arrived',
                }
              }
              return orderTemp
            }),
          )
          if (setOrders) {
            OrderStore.setUserOrders(
              orders.map((orderTemp) => {
                if (orderTemp._id === order._id) {
                  return {
                    ...orderTemp,
                    status: 'arrived',
                  }
                }
                return orderTemp
              }),
            )
          }
          // TODO: delay 관련 로직 추가
          OrderStore.changeOrdersNumPerStatus(status, -1)
          OrderStore.changeOrdersNumPerStatus('arrived', 1)
        }
      }}
    >
      직접배송 완료처리
    </Button>
  )
}

export default DirectShippingCompleteButton

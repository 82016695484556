const getCouponSettlementAmountForRevenueDiscount = (order) => {
  const selectedCouponFinalDiscountAmount =
    order?.selectedCouponInfo?.finalDiscountAmount || 0

  let couponSettlementAmountWithoutNewYear24Deal =
    (order?.selectedCouponInfo?.couponInfo?.type === 'bySeller' ||
      order?.selectedCouponInfo?.condition?.type === 'bySeller') &&
    !order?.selectedCouponInfo?.condition?.noCalculate
      ? 0
      : Number(selectedCouponFinalDiscountAmount)

  if (
    order?.selectedCouponInfo?.couponInfo?.origin === 'reviewEventParticipate'
  ) {
    couponSettlementAmountWithoutNewYear24Deal = 0
  }

  const couponSettlementAmountForNewYear24Deal =
    order?.selectedCouponInfoForNewYear24Deal?.finalDiscountAmount || 0

  const couponSettlementAmount =
    couponSettlementAmountWithoutNewYear24Deal +
    couponSettlementAmountForNewYear24Deal

  return couponSettlementAmount
}

export default getCouponSettlementAmountForRevenueDiscount

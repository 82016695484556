import React, { useCallback, useEffect, useState } from 'react'
import { Col, message, Modal, Pagination, Row, Spin } from 'antd'
import OptionBiddingModal from './optionBiddingModal'
import ItemMappingModal from '../../atoms/itemMappingModal'
import AttributeModal from './attributeModal'
import backendApis from '../../../utils/backendApis'
import BiddingUtils from './biddingUtils'
import StickyHeader from '../../molecules/stickyHeader'
import BiddingOptionCard from './biddingOptionCard'
import ExplainModal from './explainModal'

const { confirm } = Modal

const headerColumn = [
  { span: 3, text: '이미지' },
  { span: 5, text: '카탈로그 정보' },
  {
    span: 3,
    text: '속성 정보',
    tooltip:
      '핵심 카탈로그 상품에 연동되기 위해 충족해야 하는 속성 정보입니다.',
  },
  {
    span: 5,
    text: '예상 매출',
    tooltip:
      '올웨이즈 데이터랩이 분석한 신선마켓 실시간 최저가 상품의 일 단위 예상 매출입니다.',
  },
  {
    span: 4,
    text: '상품 연동 설정',
    tooltip:
      '핵심 카탈로그와 셀러님의 상품(옵션 단위)을 연동하실 수 있습니다. 연동 신청 후 입찰가를 설정하실 수 있습니다.',
  },
  {
    span: 4,
    text: '입찰가 설정',
    tooltip:
      '핵심 카탈로그 상품에 연동된 상품에 대한 입찰가를 설정하실 수 있습니다.',
  },
]

const explanation = {
  unitOption: {
    title: '신선마켓딜 안내',
    content: (
      <div>
        <div>9/24(화)부터 신선마켓딜이 시작됩니다!</div>
        <div>
          추석 기획전과 마찬가지로, 신선마켓딜은 100% 핵심 카탈로그 상품을
          중심으로 운영될 예정입니다.
        </div>
        <div>
          핵심 카탈로그 상품에 연동된 상품 중 실시간 최저가 상품만 노출될
          예정입니다.
        </div>
        <br />
        <div>
          <span className='font-bold text-blue-500'>
            추석기획전과 판매수수료는 동일합니다. (3.5%)
          </span>
        </div>
        <br />
        <div>
          추석 기획전 카탈로그 입찰 현황은 유지될 예정이며, 판매를 원치 않으시는
          분들께서는 입찰 취소 부탁드립니다.
        </div>
      </div>
    ),
  },
  generalOption: {
    title: '브랜드 카탈로그 상품 연동 안내',
    content: (
      <div>
        <div>
          브랜드 카탈로그는{' '}
          <span className='font-bold text-blue-500'>100% 동일한</span> 상품을
          연동할 수 있습니다.
        </div>
        <div>
          카탈로그 상품과{' '}
          <span className='font-bold text-blue-500'>완전히 일치하는 상품</span>
          을 연동 신청해주세요.
        </div>
        <div>신청된 상품은 검수 후 연동됩니다.</div>
      </div>
    ),
  },
}

const apiGroups = {
  unitOption: {
    getTotalCount: backendApis.getUnitOptionTotalCount,
    getData: backendApis.getUnitOption,
    mapItem: backendApis.mappingItemToUnitOption,
  },
  generalOption: {
    getTotalCount: backendApis.getGeneralOptionTotalCount,
    getData: backendApis.getGeneralOption,
    mapItem: backendApis.mappingItemToGeneralOption,
  },
}

const OptionBiddingSection = ({
  condition = {},
  isLoading,
  setIsLoading = () => {},
}) => {
  const { tab: type, viewMode = 'all', keyword = null } = condition
  const [submitToggle, setSubmitToggle] = useState(false)
  const [modalState, setModalState] = useState({
    biddingModalVisible: false,
    itemMappingModalVisible: false,
    attributeModalVisible: false,
    explainModalVisible: true,
    biddingOptionExplainModalVisible: true,
  })
  const [selectionState, setSelectionState] = useState({
    selectedBiddingOptionInfo: null,
    selectedItemInfo: null,
    selectedItemId: null,
    selectedOptionId: null,
  })
  const [paginationState, setPaginationState] = useState({
    totalCount: 0,
    page: 1,
  })

  const [biddingOptionInfo, setBiddingOptionInfo] = useState(null)
  const biddingOptionCondition = {
    ...condition,
    selectedImage: { $ne: null },
    deletedAt: null,
  }

  const {
    selectedBiddingOptionInfo,
    selectedItemInfo,
    selectedItemId,
    selectedOptionId,
  } = selectionState
  const { totalCount, page } = paginationState
  const { tab } = biddingOptionCondition
  const limit = 20
  const selectedBiddingOptionId = selectedBiddingOptionInfo?._id
  const selectedMappingStatus =
    selectedBiddingOptionInfo?.applicationInfo?.inspectionStatus
  const selectedItemtitle = selectedItemInfo?.itemTitle
  const selectedOptionTitle = selectedItemInfo?.optionsInfo?.totalOptions
    ?.flat()
    .find((e) => e?._id?.toString() === selectedOptionId?.toString())?.name
  const attribute = selectedBiddingOptionInfo?.checkedAttributes || {}
  const { topOptions = [], externalPriceInfo = [] } =
    selectedBiddingOptionInfo || {}
  const selectedLiveBiddingPrice = BiddingUtils.liveBiddingPriceCalc(
    topOptions,
    externalPriceInfo,
  )
  const modalKey = `${type}_modalShown`

  const attributeModalContent = {
    unitOption: (
      <div className='mb-5'>
        <div>상품명: {selectedItemtitle}</div>
        <div className='mb-5'>옵션명: {selectedOptionTitle}</div>
        <div>
          <span className='font-bold text-blue-500'>핵심 카탈로그</span> 상품
          연동을 위해 다음 항목을 체크해주세요.
        </div>
        <div className='mb-5'>다음 모든 속성이 동일해야 연동이 가능합니다.</div>
      </div>
    ),
    generalOption: (
      <div className='mb-5'>
        <div>상품명: {selectedItemtitle}</div>
        <div className='mb-5'>옵션명: {selectedOptionTitle}</div>
        <div>
          <span className='font-bold text-blue-500'>상품 연동</span>을
          진행하시겠습니까?
        </div>
      </div>
    ),
  }

  const handlePageChange = useCallback((page) => {
    setPaginationState((prevState) => ({
      ...prevState,
      page,
    }))
  }, [])

  const handleMappingConfirm = (itemId, optionId, itemInfo) => {
    if (!itemId || !optionId || !itemInfo) {
      return message.error('연동을 희망하는 상품 정보를 입력해주세요.')
    }
    setSelectionState((prevState) => ({
      ...prevState,
      selectedItemId: itemId,
      selectedOptionId: optionId,
      selectedItemInfo: itemInfo,
    }))
    setModalState((prevState) => ({
      ...prevState,
      attributeModalVisible: true,
    }))
  }

  const handleMappingButtonClick = (unitOption) => {
    setSelectionState((prevState) => ({
      ...prevState,
      selectedBiddingOptionInfo: unitOption,
    }))
    setModalState((prevState) => ({
      ...prevState,
      itemMappingModalVisible: true,
    }))
  }

  const handleBiddingButtonClick = (unitOption, applicationInfo) => {
    const { itemId, optionId } = applicationInfo || {}
    if (!itemId || !optionId) {
      return message.error('연동된 상품 정보가 없습니다.')
    }
    setSelectionState((prevState) => ({
      ...prevState,
      selectedBiddingOptionInfo: unitOption,
      selectedItemId: itemId,
      selectedOptionId: optionId,
    }))
    setModalState((prevState) => ({
      ...prevState,
      biddingModalVisible: true,
    }))
  }

  const handleMapping = async () => {
    const result = await apiGroups[type].mapItem(
      selectedBiddingOptionInfo?._id,
      selectedItemId,
      selectedOptionId,
    )
    if (result?.status === 200) {
      confirm({
        title: '연동 성공',
        content: '상품이 연동되었습니다.',
        okButtonProps: { type: 'primary', className: 'bg-blue-500' },
        okText: '확인',
        onOk() {
          setModalState((prevState) => ({
            ...prevState,
            itemMappingModalVisible: false,
            attributeModalVisible: false,
            biddingModalVisible: true,
            biddingOptionExplainModalVisible: false,
          }))
          setSubmitToggle((prevState) => !prevState)
        },
        cancelButtonProps: { style: { display: 'none' } },
      })
    }
  }

  useEffect(() => {
    const getTotalCount = async () => {
      try {
        const result = await apiGroups[type].getTotalCount(
          biddingOptionCondition,
        )
        if (result?.status === 200) {
          setPaginationState((prevState) => ({
            ...prevState,
            totalCount: result?.data,
          }))
        }
      } catch (error) {
        console.error('Failed to fetch total count:', error)
      }
    }
    const fetchBiddingOptionData = async () => {
      try {
        setIsLoading(true)
        const result = await apiGroups[type].getData(
          biddingOptionCondition,
          page,
          limit,
        )
        if (result?.status === 200) {
          setBiddingOptionInfo(result.data)
        }
      } catch (error) {
        console.error('Failed to fetch unit option data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (!isLoading) {
      getTotalCount()
      fetchBiddingOptionData()
    }
  }, [page, tab, submitToggle, keyword, condition])

  useEffect(() => {
    setPaginationState((prevState) => ({
      ...prevState,
      page: 1,
    }))
  }, [keyword])

  const RenderContent = () => {
    if (isLoading) {
      return (
        <Row
          justify='center'
          align='center'
          className='flex flex-1 w-full my-3 bg-white rounded-lg py-10 text-slate-500'
        >
          <Spin tip='로딩 중...' />
        </Row>
      )
    }

    if (biddingOptionInfo?.length > 0) {
      return biddingOptionInfo?.map((biddingOption) => (
        <div key={biddingOption?._id?.toString()} className='mb-5'>
          <BiddingOptionCard
            type={tab}
            data={biddingOption}
            onMappingButtonClick={handleMappingButtonClick}
            onBiddingButtonClick={handleBiddingButtonClick}
          />
        </div>
      ))
    }

    return (
      <Row
        justify='center'
        align='center'
        className='flex flex-1 w-full my-3 bg-white rounded-lg py-10 text-slate-500'
      >
        카탈로그 신청 정보가 없습니다. 카탈로그에 연동된 상품은 우선 노출됩니다.
      </Row>
    )
  }

  return (
    <Col>
      <StickyHeader columns={headerColumn} />
      <RenderContent />
      <Pagination
        current={page}
        onChange={handlePageChange}
        total={totalCount}
        showSizeChanger={false}
        pageSize={limit}
        showQuickJumper
      />
      {modalState.itemMappingModalVisible && ( // for perfect reloading
        <ItemMappingModal
          biddingOptionInfo={selectedBiddingOptionInfo}
          isVisible={modalState.itemMappingModalVisible}
          onOk={handleMappingConfirm}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              itemMappingModalVisible: false,
            }))
          }
          liveBiddingPrice={selectedLiveBiddingPrice}
          type={tab}
        />
      )}
      {modalState.biddingModalVisible && (
        <OptionBiddingModal
          type={type}
          isVisible={modalState.biddingModalVisible}
          onClose={() => {
            setModalState((prevState) => ({
              ...prevState,
              biddingModalVisible: false,
            }))
          }}
          onReload={() => setSubmitToggle(!submitToggle)}
          biddingOptionId={selectedBiddingOptionId}
          itemId={selectedItemId}
          optionId={selectedOptionId}
          itemTitle={selectedItemtitle}
          optionTItle={selectedOptionTitle}
          liveBiddingPrice={selectedLiveBiddingPrice}
          scheduleBidding={selectedMappingStatus === 'approved'}
        />
      )}
      {modalState.attributeModalVisible && (
        <AttributeModal
          title='핵심 카탈로그 연동하기'
          isVisible={modalState.attributeModalVisible}
          attributes={attribute}
          content={attributeModalContent[type]}
          onConfirm={handleMapping}
          onCancel={() =>
            setModalState((prevState) => ({
              ...prevState,
              attributeModalVisible: false,
            }))
          }
        />
      )}
      <ExplainModal
        isVisible={modalState.biddingOptionExplainModalVisible}
        onCancel={() =>
          setModalState((prevState) => ({
            ...prevState,
            biddingOptionExplainModalVisible: false,
          }))
        }
        ignoreCookie={false}
        title={explanation[type].title}
        content={explanation[type].content}
        width={500}
        modalKey={modalKey}
      />
    </Col>
  )
}

export default OptionBiddingSection

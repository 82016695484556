import getCouponSettlementAmount from './getCouponSettlementAmount'
import getCouponSettlementAmountForRevenueDiscount from './getCouponSettlementAmountForRevenueDiscount'

/**
 * @description order의 platformSupportPrice를 기반으로 platformSupportPrice와 platformSupportPriceRevenue를 나눠줍니다.
 * - 음수의 psp인 경우
 *  - 단순이동 거래일 경우에 매출 에누리에 잡지 않는다.
 * - 양수의 psp인 경우
 *  - 올팜, 0원상점과 같은 현물 지원성 거래일 경우에 매출 에누리에 잡지 않는다.
 * 음수 psp와 양수 psp가 합쳐져서 부호가 결정되는 경우가 있을텐데 지금은 고려하지 않는다.
 *
 * TODO: 음수 psp와 양수 psp가 합쳐져서 부호가 결정되는 경우에 대한 처리를 추가한다.
 */
const getPlatformSupportPriceInfo = (order) => {
  const originalPlatFormSupportPrice =
    typeof order?.totalReturnFee === 'number'
      ? 0
      : Number(order?.selectedOption?.platformSupportPrice || 0) *
        Number(order?.quantity || 1)

  const couponSettlementAmount = getCouponSettlementAmount(order)
  const couponSettlementAmountForRevenueDiscount =
    getCouponSettlementAmountForRevenueDiscount(order)
  const otherSettlementAmount =
    couponSettlementAmount - couponSettlementAmountForRevenueDiscount

  if (originalPlatFormSupportPrice <= 0) {
    const platformSupportPrice = order?.itemInfo?.enteringComponent?.includes(
      'CashbackItemScreen',
    )
      ? originalPlatFormSupportPrice + otherSettlementAmount
      : Math.max(originalPlatFormSupportPrice, 0) + otherSettlementAmount

    const platformSupportPriceRevenue =
      order?.itemInfo?.enteringComponent?.includes('CashbackItemScreen')
        ? 0
        : Math.max(-originalPlatFormSupportPrice, 0)

    return {
      originalPlatFormSupportPrice,
      platformSupportPrice,
      platformSupportPriceRevenue,
      platformSupportPriceRevenueDiscount: 0,
    }
  }

  // MEMO: 0원으로 거래되는 상품의 경우에는 할인성 거래가 아닌 현물 지급성 거래이기 때문에 매출 에누리로 보지 않는다.
  if (
    order?.selectedOption?.teamPurchasePrice === 0 ||
    order?.itemInfo?.characteristicItem?.isAlfarmItem
  ) {
    return {
      originalPlatFormSupportPrice,
      platformSupportPrice: originalPlatFormSupportPrice,
      platformSupportPriceRevenue: 0,
      platformSupportPriceRevenueDiscount: 0,
    }
  }

  return {
    originalPlatFormSupportPrice,
    platformSupportPrice: 0,
    platformSupportPriceRevenue: 0,
    platformSupportPriceRevenueDiscount: originalPlatFormSupportPrice,
  }
}
export default getPlatformSupportPriceInfo

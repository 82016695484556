import React, { useState } from 'react'
import DaumPostcodeEmbed from 'react-daum-postcode'

const PostCodeInput = ({ setAddress, setZipCode }) => {
  const handleComplete = (data) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    setAddress(fullAddress)
    setZipCode(data.zonecode)
  }

  return <DaumPostcodeEmbed onComplete={handleComplete} />
}
export default PostCodeInput

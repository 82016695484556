import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import Slider from './slider'

const WelcomeBanner = () => {
  const [banner, setBanner] = useState([])
  // seller DB의 seller_banner collection에 등록된 banner를 가져온다.
  useEffect(() => {
    const fetchData = async () => {
      const result = await backendApis.getBanner()
      if (result?.status === 200) {
        setBanner(result?.data)
      }
    }
    fetchData()
  }, [])

  return <Slider banners={banner} />
}
export default WelcomeBanner

/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Title } from '../components/ADS/atoms'
import { Tabs } from '../components/ADS/molecules'
import backendApis from '../utils/backendApis'
import Button from '../components/atoms/button'
import moment from 'moment'
import OrderSection from '../components/molecules/orderSection'
import CountdownTimer from '../components/ADS/atoms/countDownTimer'
import LoadingStatusModal from './Modals/LoadingStatusModal'
import Modal from 'react-modal'
import {
  approvingRefunding,
  approvingRefundingWithOutPickup,
  checkOrderCutOff,
  createColumnsTable,
  explainTextMap,
  handleExcelExport,
  loadCompensationCouponRegisteredOrders,
  loadFastArrivalOrdersByStatus,
  loadFastArrivalProgressingOrders,
  loadSegmentedFastArrivalArrivedOrders,
  registerRefundPickUp,
  validateAddress,
} from '../utils/fastArrivalUtils'
import { DatePicker } from 'antd'
import CombinedShippingModal from './Modals/CombinedShippingModal'
import SellerStore from '../stores/SellerStore'
import RevokeRefundRequestModal from './RevokeRefundRequestModal'
import { checkPreShipping } from '../helper/order'

const { RangePicker } = DatePicker

const orderStatusArr = [
  'pre-shipping',
  'shipping',
  'arrived',
  'canceled-shipping',
  'refunding',
  'refunded',
  'compensation',
]

const BaroArriveOrdersShippingScreen = observer(() => {
  const [currentOrderStatus, setCurrentOrderStatus] = useState('pre-shipping')
  const [finalTodayDeliveryOrders, setFinalTodayDeliveryOrders] = useState([])
  const [finalTomorrowDeliveryOrders, setFinalTomorrowDeliveryOrders] =
    useState([])
  const [
    finalTomorrowDeliveryTomorrowDepartureOrders,
    setFinalTomorrowDeliveryTomorrowDepartureOrders,
  ] = useState([])
  const [
    finalTodayDeliveryTomorrowDepartureOrders,
    setFinalTodayDeliveryTomorrowDepartureOrders,
  ] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState('creating')
  const [preShippingOrdersPostCode, setPreShippingOrdersPostCode] = useState([])
  const [preShippingOrders, setPreShippingOrders] = useState([])
  const [shippingOrders, setShippingOrders] = useState([])
  const [arrivedOrders, setArrivedOrders] = useState([])
  const [canceledShippingOrders, setCanceledShippingOrders] = useState([])
  const [refundingOrders, setRefundingOrders] = useState([])
  const [refundedOrders, setRefundedOrders] = useState([])
  const [couponOrders, setCouponOrders] = useState([])
  const [dates, setDates] = useState([null, null])
  const [combinedModal, setCombinedModal] = useState(false)
  const [deliveryType, setDeliveryType] = useState(null)
  const [combinedIds, setCombinedIds] = useState([])
  const [revokeModalOpen, setRevokeModalOpen] = useState(false)
  const [selectedOrderData, setSelectedOrderData] = useState({})
  const closeModal = () => setRevokeModalOpen(false)

  // 날짜 변경 핸들러
  const onChange = (dates) => {
    setDates(dates)
  }

  const loadingTextByProgress = {
    creating: 'Step 1. 주문 데이터 생성 중입니다',
    shipping: 'Step 2. 배송 데이터 생성 중입니다',
    requesting: 'Step 3. 택배사 출고요청 중입니다',
    downloading: 'Step 4. 엑셀파일 다운로드 중입니다',
  }

  const classifyOrders = async (preShippingOrders) => {
    const [todayDeliveryPossibleOrderData, tomorrowDeliveryPossibleOrderData] =
      await validateAddress(preShippingOrders)
    const [
      todayDeliveryTargetOrders,
      tomorrowDeliveryTargetOrders,
      todayDeliveryTomorrowDepartureOrders,
      tomorrowDeliveryTomorrowDepartureOrders,
    ] = await checkOrderCutOff(
      todayDeliveryPossibleOrderData,
      tomorrowDeliveryPossibleOrderData,
    )
    setFinalTodayDeliveryOrders(todayDeliveryTargetOrders)
    setFinalTodayDeliveryTomorrowDepartureOrders(
      todayDeliveryTomorrowDepartureOrders,
    )
    setFinalTomorrowDeliveryOrders(tomorrowDeliveryTargetOrders)
    setFinalTomorrowDeliveryTomorrowDepartureOrders(
      tomorrowDeliveryTomorrowDepartureOrders,
    )
  }

  const createOrderDataForCjLogistics = async (selectedOrderIds) => {
    setLoadingProgress('creating')
    setLoading(true)
    const isAllPreShipping = await checkPreShipping(selectedOrderIds)
    if (!isAllPreShipping) {
      setLoading(false)
      window.alert(
        '출고 지시가 불가능한 주문이 포함되어 있습니다. 새로고침 후 다시 시도해주세요.',
      )
      return
    }
    const shippingNums = await backendApis.createShippingNumberForCjLogistics(
      selectedOrderIds,
    )
    // 생성된 송장번호를 각 주문의 shippingInfo에 매핑
    if (shippingNums?.length > 0) {
      const mappedOrderIds = selectedOrderIds.slice(0, shippingNums.length)
      const mappedData = mappedOrderIds.map((order) => {
        return {
          orderId: order,
          shippingNumber: shippingNums[mappedOrderIds.indexOf(order)],
        }
      })
      setLoadingProgress('requesting')
      const requestShippingData =
        await backendApis.requestShippingForCjLogistics(mappedData)
      if (
        requestShippingData?.success &&
        requestShippingData?.list?.length > 0
      ) {
        const [
          preShippingOrders = null,
          preShippingOrdersPostCode = null,
          shippingOrders = null,
        ] = (await loadFastArrivalProgressingOrders()) || []
        setPreShippingOrders(preShippingOrders)
        setPreShippingOrdersPostCode(preShippingOrdersPostCode)
        setShippingOrders(shippingOrders)
        setLoading(false)
        window.alert('출고지시가 완료되었습니다.')
      } else {
        setLoading(false)
        window.alert('주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.')
      }
    } else {
      setLoading(false)
      window.alert('주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.')
    }
  }

  const createOrderDataForDelivus = async (selectedOrderIds) => {
    setLoadingProgress('creating')
    setLoading(true)
    const isAllPreShipping = await checkPreShipping(selectedOrderIds)
    if (!isAllPreShipping) {
      setLoading(false)
      window.alert(
        '출고 지시가 불가능한 주문이 포함되어 있습니다. 새로고침 후 다시 시도해주세요.',
      )
      return
    }
    const updateAlreadyProcessed =
      await backendApis.updateDeliveryStatusForDelivus(selectedOrderIds)
    if (
      updateAlreadyProcessed?.status === 200 &&
      updateAlreadyProcessed?.data?.success
    ) {
      const createdOrders = await backendApis.createOrderDataForShipping(
        selectedOrderIds,
      )
      if (createdOrders?.status !== 200) {
        setLoading(false)
        if (createdOrders?.errors && createdOrders?.errors.length > 0) {
          const errorMessages = createdOrders?.errors
            .map(
              (error) =>
                `[${error.orderId} 주문 발송 오류]\n${error.message}\n`,
            )
            .join('\n')
          window.alert(
            `주문 데이터 생성에 실패했습니다. 다음 주문에서 오류가 발생했습니다:\n${errorMessages}`,
          )
        } else {
          window.alert('주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.')
        }
      }
      if (createdOrders?.status === 200) {
        setLoadingProgress('shipping')
        const createdOrderIds = createdOrders?.data?.map(
          (order) => order.order_number,
        )
        const requestShipping = await backendApis.requestShippingForDelivus(
          createdOrderIds,
        )
        if (requestShipping?.status !== 200) {
          setLoading(false)
          window.alert('택배사 출고요청에 실패했습니다. 다시 시도해 주세요.')
        }
        if (requestShipping?.status === 200) {
          setLoadingProgress('requesting')
          const preparedTrackingNumber = requestShipping?.data?.map(
            (e) => e?.tracking_number,
          )
          const preparedShipping = await backendApis.prepareShippingForDelivus(
            preparedTrackingNumber,
          )
          if (preparedShipping?.status !== 200) {
            setLoading(false)
            window.alert('데이터 업데이트에 실패했습니다. 다시 시도해 주세요.')
          }
          if (preparedShipping?.status === 200) {
            const doneForShipping =
              await backendApis.updateDeliveryStatusForDelivus(selectedOrderIds)
            if (
              doneForShipping?.status === 200 &&
              doneForShipping?.data?.success
            ) {
              const [
                preShippingOrders = null,
                preShippingOrdersPostCode = null,
                shippingOrders = null,
              ] = (await loadFastArrivalProgressingOrders()) || []
              setPreShippingOrders(preShippingOrders)
              setPreShippingOrdersPostCode(preShippingOrdersPostCode)
              setShippingOrders(shippingOrders)
              setLoading(false)
              window.alert('출고지시가 완료되었습니다.')
            } else {
              setLoading(false)
              window.alert(
                '주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.',
              )
            }
          }
        }
      }
    } else {
      setLoading(false)
      window.alert('주문 데이터 생성에 실패했습니다. 다시 시도해 주세요.')
    }
  }

  useEffect(() => {
    const fetchOrders = async () => {
      const [
        preShippingOrders = [],
        preShippingOrdersPostCode = [],
        shippingOrders = [],
      ] = (await loadFastArrivalProgressingOrders()) || []
      setPreShippingOrders(preShippingOrders)
      setPreShippingOrdersPostCode(preShippingOrdersPostCode)
      setShippingOrders(shippingOrders)
    }
    setCurrentOrderStatus('pre-shipping')
    fetchOrders()
  }, [])

  useEffect(() => {
    const fetchFastArrivalOrdersByStatus = async (status) => {
      const fastArrivalOrdersByStatus = await loadFastArrivalOrdersByStatus(
        status,
      )
      if (
        fastArrivalOrdersByStatus?.status === 200 &&
        fastArrivalOrdersByStatus?.data?.length > 0
      ) {
        if (status === 'canceled-shipping') {
          setCanceledShippingOrders(fastArrivalOrdersByStatus.data)
        }
        if (status === 'refunding') {
          setRefundingOrders(fastArrivalOrdersByStatus.data)
        }
        if (status === 'refunded') {
          setRefundedOrders(fastArrivalOrdersByStatus.data)
        }
      }
    }

    const fetchSegmentedArrivedOrders = async (startDate, endDate) => {
      const segmentedArrivedOrdersData =
        await loadSegmentedFastArrivalArrivedOrders(startDate, endDate)
      if (
        segmentedArrivedOrdersData?.status === 200 &&
        segmentedArrivedOrdersData?.data?.length > 0
      ) {
        setArrivedOrders(segmentedArrivedOrdersData.data)
      } else {
        setArrivedOrders([])
      }
    }

    const fetchCompensationCouponRegisteredOrders = async () => {
      const couponRegisteredOrders =
        await loadCompensationCouponRegisteredOrders()
      if (
        couponRegisteredOrders?.status === 200 &&
        couponRegisteredOrders?.data?.length > 0
      ) {
        setCouponOrders(couponRegisteredOrders.data)
      }
    }

    if (currentOrderStatus === 'arrived') {
      if (dates[0] && dates[1]) {
        fetchSegmentedArrivedOrders(dates[0], dates[1])
      }
    }
    if (currentOrderStatus === 'compensation') {
      fetchCompensationCouponRegisteredOrders()
    } else if (
      ![
        'pre-shipping',
        'shipping',
        'arrived',
        'combinedShipping',
        'compensation',
      ].includes(currentOrderStatus)
    ) {
      fetchFastArrivalOrdersByStatus(currentOrderStatus)
    }
  }, [currentOrderStatus, dates])

  useEffect(() => {
    if (
      preShippingOrders?.length > 0 &&
      preShippingOrdersPostCode?.length > 0
    ) {
      classifyOrders(preShippingOrders)
    }
  }, [preShippingOrders, preShippingOrdersPostCode])

  const columns = useMemo(() => {
    setDates([null, null])
    return createColumnsTable(currentOrderStatus)
  }, [currentOrderStatus])

  const ExplainSection = ({ type = 'todayDelivery', data = {} }) => {
    const showTimer = [
      'todayDelivery',
      'tomorrowDelivery',
      'tomorrowDeliveryTomorrowDeparture',
      'todayDeliveryTomorrowDeparture',
    ].includes(type)
    const { shippingTime, cutOffTime, cutOffTimer, day, nextDay } =
      explainTextMap[type] || {}
    const delivusOrderCount =
      data.filter((e) => e?.shippingInfo?.shippingCompanyName === '딜리래빗')
        ?.length || 0
    const cjLogisticsOrderCount =
      data.filter((e) => e?.shippingInfo?.shippingCompanyName === 'CJ대한통운')
        ?.length || 0

    const topTextForType =
      type === 'shipping'
        ? '현재 발송 중 주문 '
        : type === 'arrived'
        ? '바로도착 배송완료 주문 '
        : type === 'canceled-shipping'
        ? '취소된 주문 '
        : type === 'refunding'
        ? '반품 접수된 주문 '
        : type === 'refunded'
        ? '반품 완료된 주문 '
        : type === 'compensation'
        ? '보상쿠폰 발급 주문 '
        : `${day} ${shippingTime} 집하예정 주문 `
    const orderCountText = `${data?.length || 0}건 입니다.`
    const bottomText =
      type === 'arrived'
        ? `당일도착 총 ${delivusOrderCount}건 / 익일도착 총 ${cjLogisticsOrderCount}건`
        : ['canceled-shipping', 'refunding', 'refunded'].includes(type)
        ? ''
        : type === 'compensation'
        ? '정시 미출고/미집화 사유로 발급된 쿠폰비용은 매 월 첫번째 금요일에 정산대금에서 차감됩니다. (전월 쿠폰 발급총액 -> 익월 첫 금요일 일괄 차감)'
        : type !== 'shipping'
        ? `${day} ${cutOffTime} 전까지 모두 출고지시 처리해 주세요.`
        : ''

    const timerDate = nextDay
      ? moment().add(1, 'day').format(`YYYY-MM-DD ${cutOffTimer}`)
      : moment().format(`YYYY-MM-DD ${cutOffTimer}`)
    const endTime = new Date(timerDate)

    return (
      <>
        <div className='font-bold text-slate-500 text-3xl mt-4 mb-2'>
          {topTextForType}
          <span className='underline underline-offset-8 text-red-400'>
            {orderCountText}
          </span>
        </div>
        {bottomText && (
          <div className='font-bold text-slate-500 text-xl mb-4'>
            {bottomText}
          </div>
        )}
        {showTimer && (
          <div className='mb-4 text-red-500 text-bs'>
            <CountdownTimer endTime={endTime} maxUnit='day' />
          </div>
        )}
      </>
    )
  }

  const tabContArr = [
    {
      title: `팀 모집 완료 (${(
        preShippingOrders?.length || 0
      ).toLocaleString()})`,
      content: (
        <>
          <OrderSection // 당일배송 당일출발
            columns={columns}
            orderData={finalTodayDeliveryOrders}
            currentOrderStatus={currentOrderStatus}
            headerSection={({ selectedOrderIds, setSelectedOrderIds }) => {
              const handleShipping = async () => {
                if (selectedOrderIds?.length === 0) {
                  window.alert('선택된 주문이 없습니다.')
                  return
                }
                const confirmation = window.confirm(
                  '선택한 주문의 출고를 진행하시겠습니까?\n\n출고지시 요청 시 아래의 작업이 자동으로 진행됩니다.\n(1) 송장번호 자동 생성\n(2) 택배사 자동 출고접수 / 집하요청\n',
                )

                if (confirmation) {
                  await createOrderDataForDelivus(selectedOrderIds)
                  setSelectedOrderIds([]) // 비동기 작업이 완료된 후 선택된 주문 ID 초기화
                }
              }
              return (
                <div>
                  <ExplainSection
                    type='todayDelivery'
                    data={finalTodayDeliveryOrders}
                  />
                  <div className='flex flex-row items-center mb-4'>
                    <div className='mr-4'>
                      <Button
                        appearance='positive'
                        size='md'
                        onClick={handleShipping}
                      >
                        선택상품 출고지시
                      </Button>
                    </div>
                    <div className='mr-4'>
                      <Button
                        appearance='positive'
                        size='md'
                        onClick={() => {
                          setDeliveryType('today')
                          setCombinedIds(selectedOrderIds)
                          setCombinedModal(true)
                        }}
                      >
                        선택상품 합포장 출고
                      </Button>
                    </div>
                  </div>
                </div>
              )
            }}
          />
          <OrderSection // 익일배송 당일출발
            columns={columns}
            orderData={finalTomorrowDeliveryOrders}
            currentOrderStatus={currentOrderStatus}
            headerSection={({ selectedOrderIds, setSelectedOrderIds }) => {
              const handleShipping = async () => {
                if (selectedOrderIds?.length === 0) {
                  window.alert('선택된 주문이 없습니다.')
                  return
                }
                const confirmation = window.confirm(
                  '선택한 주문의 출고를 진행하시겠습니까?\n\n출고지시 요청 시 아래의 작업이 자동으로 진행됩니다.\n(1) 송장번호 자동 생성\n(2) 택배사 자동 출고접수 / 집하요청\n',
                )

                if (confirmation) {
                  await createOrderDataForCjLogistics(selectedOrderIds)
                  setSelectedOrderIds([])
                }
              }
              return (
                <div className='flex flex-1 flex-col'>
                  <ExplainSection
                    type='tomorrowDelivery'
                    data={finalTomorrowDeliveryOrders}
                  />
                  <div className='flex flex-row items-center mb-4'>
                    <div className='mr-4'>
                      <Button
                        appearance='positive'
                        size='md'
                        onClick={handleShipping}
                      >
                        선택상품 출고지시
                      </Button>
                    </div>
                    <div className='mr-4'>
                      <Button
                        appearance='positive'
                        size='md'
                        onClick={() => {
                          setDeliveryType('tomorrow')
                          setCombinedIds(selectedOrderIds)
                          setCombinedModal(true)
                        }}
                      >
                        선택상품 합포장 출고
                      </Button>
                    </div>
                  </div>
                </div>
              )
            }}
          />
          <OrderSection // 당일배송 익일출발
            columns={columns}
            orderData={finalTodayDeliveryTomorrowDepartureOrders}
            currentOrderStatus={currentOrderStatus}
            headerSection={({ selectedOrderIds, setSelectedOrderIds }) => {
              const handleShipping = async () => {
                if (selectedOrderIds?.length === 0) {
                  window.alert('선택된 주문이 없습니다.')
                  return
                }
                const confirmation = window.confirm(
                  '선택한 주문의 출고를 진행하시겠습니까?\n\n출고지시 요청 시 아래의 작업이 자동으로 진행됩니다.\n(1) 송장번호 자동 생성\n(2) 택배사 자동 출고접수 / 집하요청\n',
                )

                if (confirmation) {
                  await createOrderDataForDelivus(selectedOrderIds)
                  setSelectedOrderIds([]) // 비동기 작업이 완료된 후 선택된 주문 ID 초기화
                }
              }
              return (
                <div>
                  <ExplainSection
                    type='todayDeliveryTomorrowDeparture'
                    data={finalTodayDeliveryTomorrowDepartureOrders}
                  />
                  <div className='flex flex-row items-center mb-4'>
                    <div className='mr-4'>
                      <Button
                        appearance='positive'
                        size='md'
                        onClick={handleShipping}
                      >
                        선택상품 출고지시
                      </Button>
                    </div>
                    <div className='mr-4'>
                      <Button
                        appearance='positive'
                        size='md'
                        onClick={() => {
                          setDeliveryType('today')
                          setCombinedIds(selectedOrderIds)
                          setCombinedModal(true)
                        }}
                      >
                        선택상품 합포장 출고
                      </Button>
                    </div>
                  </div>
                </div>
              )
            }}
          />
          <OrderSection // 익일배송 익일출발
            columns={columns}
            orderData={finalTomorrowDeliveryTomorrowDepartureOrders}
            currentOrderStatus={currentOrderStatus}
            headerSection={({ selectedOrderIds, setSelectedOrderIds }) => {
              const handleShipping = async () => {
                if (selectedOrderIds?.length === 0) {
                  window.alert('선택된 주문이 없습니다.')
                  return
                }
                const confirmation = window.confirm(
                  '선택한 주문의 출고를 진행하시겠습니까?\n\n출고지시 요청 시 아래의 작업이 자동으로 진행됩니다.\n(1) 송장번호 자동 생성\n(2) 택배사 자동 출고접수 / 집하요청\n',
                )

                if (confirmation) {
                  await createOrderDataForCjLogistics(selectedOrderIds)
                  setSelectedOrderIds([]) // 비동기 작업이 완료된 후 선택된 주문 ID 초기화
                }
              }
              return (
                <div>
                  <ExplainSection
                    type='tomorrowDeliveryTomorrowDeparture'
                    data={finalTomorrowDeliveryTomorrowDepartureOrders}
                  />
                  <div className='flex flex-row items-center mb-4'>
                    <div className='mr-4'>
                      <Button
                        appearance='positive'
                        size='md'
                        onClick={handleShipping}
                      >
                        선택상품 출고지시
                      </Button>
                    </div>
                    <div className='mr-4'>
                      <Button
                        appearance='positive'
                        size='md'
                        onClick={() => {
                          setDeliveryType('tomorrow')
                          setCombinedIds(selectedOrderIds)
                          setCombinedModal(true)
                        }}
                      >
                        선택상품 합포장 출고
                      </Button>
                    </div>
                  </div>
                </div>
              )
            }}
          />
        </>
      ),
    },
    {
      title: `발송 중 (${(shippingOrders?.length || 0).toLocaleString()})`,
      content: (
        <>
          <OrderSection
            columns={columns}
            orderData={shippingOrders}
            currentOrderStatus={currentOrderStatus}
            headerSection={({ selectedOrderIds, setSelectedOrderIds }) => {
              return (
                <div>
                  <ExplainSection type='shipping' data={shippingOrders} />
                  <div className='flex flex-row items-center mb-4'>
                    <div className='mr-4'>
                      <Button
                        appearance='positive'
                        size='md'
                        onClick={async () => {
                          if (selectedOrderIds?.length === 0) {
                            window.alert('선택된 내역이 없습니다.')
                          } else {
                            const exportResult = await handleExcelExport(
                              selectedOrderIds,
                            )
                            if (exportResult.success) {
                              window.alert(
                                '다운된 엑셀파일 확인해주세요.\n\n예정된 시간에 택배사에서 방문할 예정입니다. ',
                              )
                            } else {
                              window.alert(
                                '엑셀 다운로드 중 오류가 발생했습니다.',
                              )
                            }
                          }
                        }}
                      >
                        선택주문내역 엑셀 다운로드
                      </Button>
                    </div>
                  </div>
                </div>
              )
            }}
          />
        </>
      ),
    },
    {
      title: `배송완료`,
      content: (
        <OrderSection
          columns={columns}
          orderData={arrivedOrders}
          currentOrderStatus={currentOrderStatus}
          headerSection={({ selectedOrderIds, setSelectedOrderIds }) => {
            return (
              <div>
                <ExplainSection type='arrived' data={arrivedOrders} />
                <div className='flex flex-row items-center mb-4'>
                  <a
                    href='https://levitinc.notion.site/24-03-0d284cccb5c64816bca2b9c5de908c57?pvs=4'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-600 hover:text-blue-800 visited:text-purple-600'
                  >
                    [바로도착 택배운임]
                  </a>
                </div>
                <div className='mb-4'>
                  <div className='text-sm'>
                    조회기간:{' '}
                    {dates[0] && dates[1]
                      ? `${dates[0].format('YYYY-MM-DD')} ~ ${dates[1].format(
                          'YYYY-MM-DD',
                        )}`
                      : '(조회할 날짜를 선택해주세요)'}
                  </div>
                  <RangePicker onChange={onChange} />
                </div>
                <div className='mr-4 mb-4'>
                  <Button
                    appearance='positive'
                    size='md'
                    onClick={async () => {
                      if (selectedOrderIds?.length === 0) {
                        window.alert('선택된 내역이 없습니다.')
                      } else {
                        const exportResult = await handleExcelExport(
                          selectedOrderIds,
                        )
                        if (exportResult.success) {
                          window.alert('다운된 엑셀파일 확인해주세요.')
                        } else {
                          window.alert('엑셀 다운로드 중 오류가 발생했습니다.')
                        }
                      }
                    }}
                  >
                    선택주문내역 엑셀 다운로드
                  </Button>
                </div>
              </div>
            )
          }}
        />
      ),
    },
    {
      title: `취소된 주문`,
      content: (
        <OrderSection
          columns={columns}
          orderData={canceledShippingOrders}
          currentOrderStatus={currentOrderStatus}
          headerSection={() => {
            return (
              <div>
                <ExplainSection
                  type='canceled-shipping'
                  data={canceledShippingOrders}
                />
              </div>
            )
          }}
        />
      ),
    },
    {
      title: `반품접수`,
      content: (
        <OrderSection
          columns={columns}
          orderData={refundingOrders}
          currentOrderStatus={currentOrderStatus}
          headerSection={({ selectedOrderIds, setSelectedOrderIds }) => {
            const handleRefundingWithPickup = async () => {
              const confirmation = window.confirm(
                '선택한 상품을 수거하시겠습니까?\n\n승인 시 원 배송사에 자동으로 수거접수가 진행됩니다.\n\n반품지는 바로도착 신청 시 기입한 장소로 지정되며, 수거운임은 판매대금 정산 시 차감됩니다.',
              )
              if (!confirmation) {
                setSelectedOrderIds([])
                window.alert('수거처리가 취소되었습니다.')
              }
              if (confirmation) {
                const approveRefund = await registerRefundPickUp(
                  selectedOrderIds,
                )
                if (approveRefund) {
                  setCurrentOrderStatus('refunding')
                  setSelectedOrderIds([])
                  window.alert(
                    '수거접수가 완료되었습니다.\n반품수거가 진행됩니다.',
                  )
                }
                if (!approveRefund) {
                  setSelectedOrderIds([])
                  window.alert(
                    '수거접수 처리 중 오류가 발생했습니다.\n다시 시도해주세요.',
                  )
                }
              }
            }

            const handleRefundingCancelation = async () => {
              const confirmation = window.confirm(
                '반품 요청 거부(반품 철회)를 진행하시겠습니까?\n정당한 사유 없이 반품 요청을 거부할 경우, 판매자에게 패널티가 부여될 수 있습니다.',
              )
              if (!confirmation) {
                setSelectedOrderIds([])
                window.alert('반품철회가 취소되었습니다.')
              }
              if (confirmation) {
                const selectedData = refundingOrders.filter(
                  (e) => e?._id === selectedOrderIds[0],
                )
                setSelectedOrderIds([])
                SellerStore.setIsLoading(true)
                setSelectedOrderData(selectedData[0])
                setRevokeModalOpen(true)
                SellerStore.setIsLoading(false)
              }
            }

            const handleRefundingWithOutPickup = async () => {
              const confirmation = window.confirm(
                '선택한 주문의 반품 승인 처리하시겠습니까?\n\n승인 시 주문취소 처리가 진행되며, 구매자에게 미수거 반품 안내문자가 발송됩니다.',
              )
              if (!confirmation) {
                setSelectedOrderIds([])
                window.alert('반품승인이 취소되었습니다.')
              }
              if (confirmation) {
                const approveRefund = await approvingRefundingWithOutPickup(
                  selectedOrderIds,
                )
                if (approveRefund) {
                  setSelectedOrderIds([])
                  window.alert('반품승인이 완료되었습니다.')
                }
                if (!approveRefund) {
                  setSelectedOrderIds([])
                  window.alert(
                    '반품처리 중 오류가 발생했습니다.\n다시 시도해주세요.',
                  )
                }
              }
            }

            const handleRefundingProcessed = async () => {
              const confirmation = window.confirm(
                '선택한 주문의 반품 승인 처리하시겠습니까?',
              )
              if (!confirmation) {
                setSelectedOrderIds([])
                window.alert('반품승인이 취소되었습니다.')
              }
              if (confirmation) {
                const approveRefund = await approvingRefunding(selectedOrderIds)
                if (approveRefund) {
                  setSelectedOrderIds([])
                  window.alert('반품승인이 완료되었습니다.')
                }
                if (!approveRefund) {
                  setSelectedOrderIds([])
                  window.alert(
                    '반품처리 중 오류가 발생했습니다.\n다시 시도해주세요.',
                  )
                }
              }
            }
            return (
              <div>
                <ExplainSection type='refunding' data={refundingOrders} />
                <div className='flex flex-row items-center mb-4'>
                  <div className='mr-4 mt-2'>
                    <Button
                      appearance='positive'
                      size='md'
                      onClick={() => {
                        if (selectedOrderIds?.length === 0) {
                          window.alert('선택된 주문이 없습니다.')
                        }
                        if (selectedOrderIds?.length > 1) {
                          window.alert('수거처리는 1개 주문씩 처리 가능합니다.')
                        }
                        if (selectedOrderIds?.length === 1) {
                          handleRefundingWithPickup()
                        }
                      }}
                    >
                      선택상품 수거 진행
                    </Button>
                  </div>
                  <div className='mr-4 mt-2'>
                    <Button
                      appearance='positive'
                      size='md'
                      onClick={() => {
                        if (selectedOrderIds?.length === 0) {
                          window.alert('선택된 주문이 없습니다.')
                        }
                        if (selectedOrderIds?.length > 1) {
                          window.alert('반품처리는 1개 주문씩 처리 가능합니다.')
                        }
                        if (selectedOrderIds?.length === 1) {
                          handleRefundingCancelation()
                        }
                      }}
                    >
                      선택상품 반품 거부
                    </Button>
                  </div>
                  <div className='mr-4 mt-2'>
                    <Button
                      appearance='positive'
                      size='md'
                      onClick={() => {
                        if (selectedOrderIds?.length === 0) {
                          window.alert('선택된 주문이 없습니다.')
                        }
                        if (selectedOrderIds?.length > 1) {
                          window.alert('반품처리는 1개 주문씩 처리 가능합니다.')
                        }
                        if (selectedOrderIds?.length === 1) {
                          handleRefundingProcessed()
                        }
                      }}
                    >
                      선택상품 반품승인
                    </Button>
                  </div>
                  <div className='mr-4 mt-2'>
                    <Button
                      appearance='positive'
                      size='md'
                      onClick={() => {
                        if (selectedOrderIds?.length === 0) {
                          window.alert('선택된 주문이 없습니다.')
                        }
                        if (selectedOrderIds?.length > 1) {
                          window.alert('반품처리는 1개 주문씩 처리 가능합니다.')
                        }
                        if (selectedOrderIds?.length === 1) {
                          handleRefundingWithOutPickup()
                        }
                      }}
                    >
                      선택상품 반품승인 및 미수거
                    </Button>
                  </div>
                </div>
              </div>
            )
          }}
        />
      ),
    },
    {
      title: `반품완료`,
      content: (
        <OrderSection
          columns={columns}
          orderData={refundedOrders}
          currentOrderStatus={currentOrderStatus}
          headerSection={() => {
            return (
              <div>
                <ExplainSection type='refunded' data={refundedOrders} />
              </div>
            )
          }}
        />
      ),
    },
    {
      title: `보상쿠폰 발급내역`,
      content: (
        <OrderSection
          columns={columns}
          orderData={couponOrders}
          currentOrderStatus={currentOrderStatus}
          headerSection={() => {
            return (
              <div>
                <ExplainSection type='compensation' data={couponOrders} />
              </div>
            )
          }}
        />
      ),
    },
  ]

  return (
    <>
      <Title text='바로도착 배송관리' />
      {loading && (
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            padding: '25vw 25vw',
            paddingTop: '30vh',
            width: '80%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(128, 128, 128, 0.7)',
            zIndex: 30,
          }}
        >
          <div className='flex flex-1 flex-col items-center bg-white rounded-md'>
            <div className='mt-6 font-bold text-blue-500 text-xl'>
              {loadingTextByProgress[loadingProgress]}
            </div>
            <div className='flex flex-1 flex-col items-center mt-4 mb-4 font-bold text-blue-500 text-bs'>
              <div>잠시만 기다려주세요</div>
              <div>전체 완료까지 5분 가량 소요될 수 있습니다</div>
            </div>
            <span className='mb-4'>
              <LoadingStatusModal />
            </span>
          </div>
        </div>
      )}
      {combinedIds?.length > 0 && (
        <Modal
          isOpen={combinedModal}
          style={{
            overlay: {
              zIndex: 20,
            },
            content: {
              left: '20%',
              right: '20%',
              top: '20%',
              bottom: '20%',
              padding: 0,
              zIndex: 10,
            },
          }}
        >
          <CombinedShippingModal
            showModal={combinedModal}
            setShowModal={setCombinedModal}
            setLoading={setLoading}
            setLoadingProgress={setLoadingProgress}
            setPreShippingOrders={setPreShippingOrders}
            setPreShippingOrdersPostCode={setPreShippingOrdersPostCode}
            setShippingOrders={setShippingOrders}
            setCurrentOrderStatus={setCurrentOrderStatus}
            selectedOrderData={preShippingOrders?.filter((e) => {
              return combinedIds.includes(e?._id)
            })}
            deliveryType={deliveryType}
          />
        </Modal>
      )}
      {revokeModalOpen && (
        <RevokeRefundRequestModal
          order={selectedOrderData}
          // setOrders={setOrders}
          status='refunding'
          closeModal={closeModal}
        />
      )}
      <Tabs
        tabContArr={tabContArr}
        callbackFunction={(index) => {
          setCurrentOrderStatus(orderStatusArr[index])
        }}
      />
    </>
  )
})

export default BaroArriveOrdersShippingScreen

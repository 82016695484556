import React from 'react'

import Button from '../../atoms/button'
import SubTitle from '../../atoms/subTitle'
import Card from '../../atoms/card'
import YouTube from 'react-youtube'

const RegisterItemGuideCard = () => {
  return (
    <Card title='상품 등록 가이드' className='mt-10' open={false}>
      <div className='mb-5'>
        <SubTitle>상품 등록 가이드</SubTitle>
      </div>
      <div>
        <YouTube
          videoId='W_8-3CXYTe0'
          opts={{
            playerVars: {
              rel: 0,
              modestbranding: 1,
            },
          }}
        />
      </div>

      <Button
        size='xl'
        appearance='neutralSub'
        onClick={() => {
          window.open(
            'https://levitinc.notion.site/93e5c5fd492a4b7289faa4efc4a6bf4f',
            '_blank',
          )
        }}
      >
        1분 투자해서 매출 50배 올리기
      </Button>
    </Card>
  )
}

export default RegisterItemGuideCard

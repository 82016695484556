import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
// import OrderStore from '../../stores/OrderStore'
// import { useHistory, useLocation, useParams } from 'react-router-dom'

const SelectOptionButton = observer(
  ({
    index,
    item,
    optionsInfo,
    optionIndices,
    setOptionIndices,
    checked,
    optionLargeIndex,
    correspondingOptionsLength,
  }) => {
    const [soldOutOption, setSoldOutOption] = useState(false)
    useEffect(() => {
      console.log(`selectoption useeffect activated!`)
      //   const optionsInfo =
      //     OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]?.itemInfo
      //       .optionsInfo
      if (optionsInfo) {
        // const optionIndices =
        //   OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
        //     ?.optionIndices
        const tempOptionIndices = optionIndices ? { ...optionIndices } : {}
        tempOptionIndices[optionLargeIndex] = index
        // console.log(
        //   `${JSON.stringify([
        //     ...Object.keys(tempOptionIndices).filter(
        //       (largeIndex) => largeIndex !== optionLargeIndex.toString(),
        //     ),
        //     optionLargeIndex,
        //   ])}`,
        // )
        if (
          Object.keys(tempOptionIndices).length ===
          optionsInfo.optionNames.length
        ) {
          let selectedPriceOption = optionsInfo.optionPrices
          Object.values(tempOptionIndices).forEach((optionIndex, indexTemp) => {
            if (selectedPriceOption[optionIndex]) {
              selectedPriceOption = selectedPriceOption[optionIndex]
            } else {
              selectedPriceOption = selectedPriceOption[0]
              const optionIndicesTemp = { ...optionIndices }
              optionIndicesTemp[indexTemp] = 0
              setOptionIndices(optionIndicesTemp)
            }
          })
          if (selectedPriceOption?.sold_out) {
            setSoldOutOption(true)
          } else {
            setSoldOutOption(false)
          }
        }
      }
    }, [
      //   OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
      //     ?.optionIndices,
      optionsInfo,
      optionIndices,
    ])

    useEffect(() => {
      if (correspondingOptionsLength === 1) {
        // const optionIndices =
        //   OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
        //     ?.optionIndices
        console.log(`setting option indices`)
        const tempOptionIndices = optionIndices ? { ...optionIndices } : {}
        tempOptionIndices[optionLargeIndex] = index
        // console.log(`${ItemStore.targetItemInfo.optionsInfo}`)
        // setOptionIndices(tempOptionIndices)
        // OrderStore.setItemOptionIndicesByIndex(
        //   tempOptionIndices,
        //   OrderStore.currentItemIndex,
        // )
        setOptionIndices(tempOptionIndices)
      }
    }, [correspondingOptionsLength])
    if (checked) {
      return (
        <button
          type='button'
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: '#EA3F49',
            borderRadius: 4,
            marginLeft: 4,
            marginRight: 4,
            marginTop: 4,
            marginBottom: 4,
            fontSize: 14,
            color: '#FFF',
            border: 'none',
          }}
          onClick={() => {
            // const optionIndices =
            //   OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //     ?.optionIndices
            // console.log(`setting option indices`)
            const tempOptionIndices = optionIndices ? { ...optionIndices } : {}
            tempOptionIndices[optionLargeIndex] = index
            // console.log(`${ItemStore.targetItemInfo.optionsInfo}`)
            // setOptionIndices(tempOptionIndices)
            // OrderStore.setItemOptionIndicesByIndex(
            //   tempOptionIndices,
            //   OrderStore.currentItemIndex,
            // )
            setOptionIndices(tempOptionIndices)
          }}
        >
          {item.name || `${index + 1}번째 세부 옵션을 입력해주세요.`}
        </button>
      )
    }
    return (
      <button
        type='button'
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: soldOutOption ? '#F8F8F8' : '#F5F5F5',
          borderRadius: 4,
          marginLeft: 4,
          marginRight: 4,
          marginTop: 4,
          marginBottom: 4,
          fontSize: 14,
          color: soldOutOption ? '#BDBDBD' : '#333333',
          border: 'none',
        }}
        onClick={() => {
          if (soldOutOption) {
            return
          }
          //   const optionIndices =
          //     OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
          //       ?.optionIndices
          //   console.log(`setting option indices`)
          const tempOptionIndices = optionIndices ? { ...optionIndices } : {}
          tempOptionIndices[optionLargeIndex] = index
          // console.log(`${ItemStore.targetItemInfo.optionsInfo}`)
          // setOptionIndices(tempOptionIndices)
          //   OrderStore.setItemOptionIndicesByIndex(
          //     tempOptionIndices,
          //     OrderStore.currentItemIndex,
          //   )
          setOptionIndices(tempOptionIndices)
        }}
      >
        {item.name || `${index + 1}번째 세부 옵션을 입력해주세요.`}
      </button>
    )
  },
)

export default SelectOptionButton

import React from 'react'

const AlfarmAdsProgressBadgeSetter = ({ status, adEndsAt, exist }) => {
  const statusStyles = {
    complete: 'bg-blue-500', // 승인 상태
    pending: 'bg-orange-500', // 검수 중 상태
    rejected: 'bg-red-500', // 반려 상태
    canceled: 'bg-gray-500', // 취소 상태
    ended: 'bg-gray-500', // 종료 상태
  }

  const textMapping = {
    pending: '검수 중',
    complete: '승인',
    rejected: '반려',
    canceled: '취소',
    ended: '종료',
  }

  const formatEndDate = (date) =>
    new Date(date).toLocaleString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })

  if (status === 'complete' && new Date(adEndsAt) <= new Date()) {
    status = 'ended'
  }

  const badgeClass = `p-2 text-sm font-bold text-white ${statusStyles[status]} rounded-lg`
  return (
    <div className='flex flex-row items-start'>
      <div className={badgeClass}>{textMapping[status]}</div>
      {status === 'ended' && (
        <div className='mt-1' style={{ marginLeft: '0.5vw' }}>
          {' '}
          종료 시간: {formatEndDate(adEndsAt)}
        </div>
      )}
      {status === 'canceled' && exist && (
        <div className='mt-1' style={{ marginLeft: '0.5vw' }}>
          {' '}
          취소 시간: {formatEndDate(adEndsAt)}
        </div>
      )}
    </div>
  )
}

export default AlfarmAdsProgressBadgeSetter

import React, { useState } from 'react'
import {
  MdOutlineKeyboardArrowDown as ArrowDown,
  MdKeyboardArrowUp as ArrowUp,
} from 'react-icons/md'
import AlwayzQuestion from '../../images/alwayzQuestion.svg'

const Card = ({
  title,
  id = '',
  children,
  open = true,
  necessary = false,
  size = 'xxl',
  info = false,
  className = '',
  appearance = 'bordered',
}) => {
  const [isOpen, setIsOpen] = useState(open)
  const styleByType = {
    bordered:
      'border-2 divide-y rounded-lg border-sub-200 divide-solid divide-sub-200 text-black',
    shaded: 'rounded-lg shadow-md text-black divide-y',
    tableStyle: 'text-black',
  }

  const sizeByType = {
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    xxl: 'text-2xl',
  }

  const bodyPaddingByType = {
    bordered: 'p-10',
    shaded: 'p-10',
    tableStyle: '',
  }

  return (
    <div
      id={id}
      className={`flex flex-col flex-1 mb-5 bg-white ${styleByType[appearance]} ${className}`}
    >
      <button
        type='button'
        className={`flex items-center justify-between px-5 py-4 font-bold text-black select-none ${
          appearance === 'tableStyle'
            ? 'bg-slate-100 border border-black border-solid'
            : ''
        } ${isOpen ? 'border-b-0' : ''}`}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className='flex flex-row items-cente'>
          <div className={`mr-3 ${sizeByType[size]} `}>{title}</div>
          {necessary && (
            <div className='px-2 py-1 text-sm text-white rounded-md select-none bg-positive-500'>
              필수
            </div>
          )}
          {info ? (
            <img
              alt='tuto'
              src={AlwayzQuestion}
              className='flex w-4 h-4 mx-1.5 mb-2'
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          {isOpen ? (
            <ArrowUp className='w-9 h-9 ' />
          ) : (
            <ArrowDown className='w-9 h-9' />
          )}
        </div>
      </button>
      {isOpen && (
        <div className={bodyPaddingByType[appearance]}>
          <div>{children}</div>
        </div>
      )}
    </div>
  )
}

export default Card

import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import { FcSalesPerformance as TreasureIcon } from 'react-icons/fc'
import Button from '../atoms/button'
import OptionTable from './optionTable'
import SellerQuest from '../../utils/sellerQuest'
import { Radio } from 'antd'

const RadioButton = ({ value, setValue, valueSet }) => {
  // const [value, setValue] = useState(1)
  const onChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <Radio.Group onChange={onChange} value={value}>
      {valueSet.map((item) => (
        <Radio key={item.value} value={item.value}>
          {/* radio 항목 표시 */}
          {item.text}
        </Radio>
      ))}
    </Radio.Group>
  )
}

const DealApplicationModal = ({
  showModal,
  setShowModal,
  dealType,
  itemInfo,
  minPriceRatio = 0.85,
  applied = false,
}) => {
  const nonEditableDealTypeList = ['newYear24DealFinal']
  const oldOptionsInfo = { ...itemInfo?.optionsInfo } // dealOptionsInfo.optionsPrice에 oldTeamPurchasePrice 필드 있는 경우
  const [dealOptionsInfo, setDealOptionsInfo] = useState({
    ...itemInfo?.optionsInfo,
  })
  const [itemValue, setItemValue] = useState()
  const [packagingValue, setPackagingValue] = useState()
  const [dealItemInfo, setDealItemInfo] = useState()
  const [dealItemInfoSetFlag, setDealItemInfoSetFlag] = useState(false)

  const dealOptionsInfoChecker = (
    oldOptionsInfo,
    dealOptionsInfo,
    minPriceRatio,
  ) => {
    const oldOptionsPricesAll = [...oldOptionsInfo?.optionPrices?.flat()]
    const dealOptionsPricesAll = [...dealOptionsInfo?.optionPrices?.flat()]
    const checkedArray = oldOptionsPricesAll.map((oldInfo, index) => {
      const oldPrice = oldInfo?.teamPurchasePrice
      const dealPrice = dealOptionsPricesAll[index]?.teamPurchasePrice
      const validPrice = parseInt(oldPrice * minPriceRatio, 10)
      if (validPrice >= dealPrice) {
        return true
      }
      return false
    })
    return checkedArray.every((checked) => checked)
  }

  useEffect(() => {
    if (applied) {
      const dealOptionsInfoSetter = async () => {
        const appliedDealOptionsInfo = await backendApis.getDealOptionsInfo(
          itemInfo?._id,
          dealType,
        )
        if (appliedDealOptionsInfo?.status === 200) {
          setDealOptionsInfo(appliedDealOptionsInfo?.data?.dealOptionsInfo)
          setDealItemInfo(appliedDealOptionsInfo?.data)
          setDealItemInfoSetFlag(true)
        }
      }
      dealOptionsInfoSetter(setDealOptionsInfo)
    }
  }, [applied])

  const itemTitle = itemInfo?.itemTitle || ''
  const validTypeList = [
    'treasureDeal',
    'tenMinDeal',
    'timeDeal',
    'newYear24Deal',
    'newYear24DealFinal',
    'tripleSevenDeal',
  ]
  const optionHeader =
    oldOptionsInfo?.totalOptions?.length >= 2
      ? [
          { Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 },
          { Header: '옵션2 / 상세옵션', accessor: 'option2', index: 2 },
        ]
      : [{ Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 }]

  let columns = [
    ...optionHeader,
    {
      Header: '기존 팀구매가',
      accessor: 'oldTeamPurchasePrice',
      copyField: 'teamPurchasePrice',
      index: 3,
    },
    {
      Header: '딜 신청가',
      accessor: 'teamPurchasePrice',
      isEditableData: !nonEditableDealTypeList.includes(dealType),
      index: 4,
    },
    {
      Header: '재고량',
      accessor: 'stockNumber',
      isEditableData: !nonEditableDealTypeList.includes(dealType),
      index: 5,
    },
  ]
  if (dealType === 'tenMinDeal') {
    columns = [
      ...optionHeader,
      // {
      //   Header: '기존 팀구매가',
      //   accessor: 'oldTeamPurchasePrice',
      //   copyField: 'teamPurchasePrice',
      //   index: 3,
      // },
      {
        Header: '딜 신청가',
        accessor: 'teamPurchasePrice',
        // isEditableData: dealType !== 'tenMinDeal',
        isEditableData: false,
        index: 4,
      },
      {
        Header: '재고량',
        accessor: 'stockNumber',
        isEditableData: true,
        index: 5,
      },
    ]
  }

  const Header = ({ type }) => {
    let textType = ''
    if (type === 'treasureDeal') {
      textType = '보물특가 신청하기'
    }
    if (type === 'tenMinDeal') {
      textType = '10분 핫딜 확인하기'
    }
    if (type === 'newYear24Deal') {
      textType = '설 특가 기획전 신청하기'
    }
    if (type === 'newYear24DealFinal') {
      textType = '설 특가 파이널 확인'
    }
    if (type === 'tripleSevenDeal') {
      textType = '777 페스티벌 확인'
    }
    return (
      <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200'>
        <div className='flex flex-row items-center flex-1'>
          <TreasureIcon className='w-8 h-8 mr-2' />
          <h3 className='text-xl font-semibold text-black'>{textType}</h3>
        </div>
        <button
          className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
          type='button'
          onClick={() => {
            setShowModal(false)
          }}
        >
          X
        </button>
      </div>
    )
  }
  const Body = ({ type }) => {
    if (type === 'tenMinDeal') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 pt-10'>
          <div>상품명</div>
          <div>
            <span className='font-bold'>{itemTitle}</span>
          </div>
          <div className='mt-5'>
            10분 핫딜은 오전/오후 8시 단 10분만 진행하는 완전 폐쇄 매대입니다.
          </div>
          <div>
            <span className='font-bold text-blue-500'>
              딜 가격 어뷰징 의심시 자동으로 행사가 취소됩니다.
            </span>
          </div>
        </div>
      )
    }
    if (type === 'timeDeal') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 pt-10'>
          <div>상품명</div>
          <div>
            <span className='font-bold'>{itemTitle}</span>
          </div>
          <div className='mt-5'>
            타임특가는 매일 아침 7시 부터 저녁 11시까지 1시간 단위로 상품이
            변경되어 노출되는 시간 한정 딜 매대입니다.
          </div>
          <div>
            <span className='font-bold text-blue-500'>
              기존 팀구매가 대비 10% 이상 할인된 가격만 신청이 가능합니다
            </span>
          </div>
        </div>
      )
    }
    if (type === 'treasureDeal') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 pt-10'>
          <div>상품명</div>
          <div>
            <span className='font-bold'>{itemTitle}</span>
          </div>
          <div className='mt-5'>
            *보물특가는 앱 내 특정 맥락에서만 출현하는 완전 폐쇄 매대입니다.
          </div>
          <div>
            기존 팀구매가 대비{' '}
            <span className='font-bold text-blue-500'>최소 15% 이상</span>의
            할인율을 적용해주세요. 권장 할인율은{' '}
            <span className='font-bold text-blue-500'>20%</span>입니다.
          </div>
        </div>
      )
    }

    if (type === 'newYear24Deal') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 pt-10'>
          <div>상품명</div>
          <div>
            <span className='font-bold'>{itemTitle}</span>
          </div>
          <div className='mt-5'>
            설날 특가 기획전은 인기 상품 구성 여부와 최저가를 꼼꼼히 검수하여
            선정됩니다.
          </div>
          <div>
            권장 할인율은 <span className='font-bold text-blue-500'>10%</span>
            입니다.
          </div>
        </div>
      )
    }

    if (type === 'newYear24DealFinal') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 pt-10'>
          <div>상품명</div>
          <div>
            <span className='font-bold'>{itemTitle}</span>
          </div>
          <div className='mt-5'>
            설날 파이널 특가 기획전은 다음 가격으로 신청되었습니다.
          </div>
        </div>
      )
    }

    if (type === 'tripleSevenDeal') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 pt-10'>
          <div>상품명</div>
          <div>
            <span className='font-bold'>{itemTitle}</span>
          </div>
          <div className='mt-5'>
            설날 파이널 특가 기획전은 다음 가격으로 신청되었습니다.
          </div>
        </div>
      )
    }
    return <></>
  }

  const valueSet = {
    newYear24Deal_item: [
      { value: 'household', text: '생활용품' },
      { value: 'kitchen', text: '주방용품' },
      { value: 'Toiletries', text: '샤워/세면용품' },
      { value: 'redGinsengAndHealthFood', text: '홍삼 및 건강기능식품' },
      { value: 'fruit', text: '과일' },
      { value: 'meat', text: '육류' },
      { value: 'fishery', text: '수산/건어' },
      { value: 'canned', text: '통조림' },
      { value: 'handAndSideDish', text: '수제햄/반찬' },
      { value: 'drink', text: '커피/차/음료' },
      { value: 'oil', text: '식용유/참기름' },
      { value: 'source', text: '장/소스' },
      { value: 'riceCakeAndTraditionalSweats', text: '한과/떡' },
      { value: 'snack', text: '과자/간식' },
      { value: 'generalFood', text: '기타 식품' },
      { value: 'furnitureAndAppliance', text: '가구/가전' },
      { value: 'fashion', text: '의류/잡화' },
      { value: 'etc', text: '기타' },
    ],
    newYear24Deal_packaging: [
      { value: 'giftWrap', text: '선물포장' },
      { value: 'normalWrap', text: '일반포장' },
    ],
  }

  const Footer = ({ type }) => {
    if (type === 'tenMinDeal') {
      return (
        <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
          <Button
            size='xl'
            appearance={applied ? 'positiveSub' : 'positive'}
            onClick={async () => {
              const oldDealOptionsInfo = dealOptionsInfo
              if (applied) {
                const result = await backendApis.updateDealOptionsInfo(
                  itemInfo?._id,
                  type,
                  dealOptionsInfo,
                )
                if (result?.status === 200) {
                  alert('정상적으로 수정되었습니다.')
                  setShowModal(false)
                }
              } else {
                const result = await backendApis.registerDealOptionsInfo(
                  itemInfo?._id,
                  itemInfo?.itemTitle,
                  type,
                  dealOptionsInfo,
                )
                if (result?.status === 200) {
                  alert('신청하신 10분 핫딜 상품이 등록되었습니다.')
                  setShowModal(false)
                }
              }
            }}
          >
            {applied ? '수정하기' : '신청하기'}
          </Button>
          <button
            className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
            type='button'
            onClick={() => {
              setShowModal(false)
            }}
          >
            닫기
          </button>
        </div>
      )
    }

    if (type === 'timeDeal') {
      return (
        <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
          <Button
            size='xl'
            appearance={applied ? 'positiveSub' : 'positive'}
            onClick={async () => {
              if (
                !dealOptionsInfoChecker(
                  oldOptionsInfo,
                  dealOptionsInfo,
                  minPriceRatio,
                )
              ) {
                alert(
                  `기존 팀구매가 대비 최소 10% 이상의 할인율을 적용해주세요.`,
                )
                return
              }
              if (applied) {
                const result = await backendApis.updateDealOptionsInfo(
                  itemInfo?._id,
                  type,
                  dealOptionsInfo,
                )
                if (result?.status === 200) {
                  alert('정상적으로 수정되었습니다.')
                  setShowModal(false)
                }
              } else {
                const result = await backendApis.registerDealOptionsInfo(
                  itemInfo?._id,
                  itemInfo?.itemTitle,
                  type,
                  dealOptionsInfo,
                )
                if (
                  !SellerQuest.questChecker(
                    'timedealQuest',
                    'registerTimedealItem',
                  )
                ) {
                  await SellerQuest.questClearer(
                    'timedealQuest',
                    'registerTimedealItem',
                  )
                }
                if (result?.status === 200) {
                  alert('신청하신 상품이 타임특가에 등록되었습니다.')
                  setShowModal(false)
                }
              }
            }}
          >
            {applied ? '수정하기' : '신청하기'}
          </Button>
          <button
            className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
            type='button'
            onClick={() => {
              setShowModal(false)
            }}
          >
            닫기
          </button>
        </div>
      )
    }

    if (type === 'treasureDeal') {
      return (
        <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
          <Button
            size='xl'
            appearance={applied ? 'positiveSub' : 'positive'}
            onClick={async () => {
              if (
                !dealOptionsInfoChecker(
                  oldOptionsInfo,
                  dealOptionsInfo,
                  minPriceRatio,
                )
              ) {
                alert(
                  `기존 팀구매가 대비 최소 ${parseInt(
                    (1 - minPriceRatio) * 100,
                    10,
                  )}% 이상의 할인율을 적용해주세요.`,
                )
                return
              }
              const result = await backendApis.registerDealOptionsInfo(
                itemInfo?._id,
                itemInfo?.itemTitle,
                type,
                dealOptionsInfo,
              )
              if (result?.status === 200) {
                alert('신청하신 보물특가가 등록되었습니다.')
                setShowModal(false)
              }
            }}
          >
            {applied ? '수정하기' : '보물특가 신청하기'}
          </Button>
          <button
            className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
            type='button'
            onClick={() => {
              setShowModal(false)
            }}
          >
            닫기
          </button>
        </div>
      )
    }

    if (type === 'newYear24Deal') {
      const newYear24DealMetaData = {
        itemGroup: itemValue,
        packagingType: packagingValue,
      }
      return (
        <div className='flex items-center justify-end p-8 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
          {/* <div className='flex-1 '>
            상품 종류 선택
            <br />
            <RadioButton
              value={itemValue}
              setValue={setItemValue}
              valueSet={valueSet?.newYear24Deal_item}
            />
            <div className='mt-4' />
            포장 방식 선택
            <br />
            <RadioButton
              value={packagingValue}
              setValue={setPackagingValue}
              valueSet={valueSet?.newYear24Deal_packaging}
            />
          </div> */}
          <Button
            size='xl'
            appearance={applied ? 'positiveSub' : 'positive'}
            onClick={async () => {
              const alertMessage = `모든 옵션에 대해 기존 팀구매가 대비 최소 10% 이상의 할인율을 적용해주세요.
              `

              const salesSource =
                dealItemInfo?.newYear24DealMetaData?.salesSource
              const reviewsCount =
                dealItemInfo?.newYear24DealMetaData?.reviewsCount
              const weeklySalesCount =
                dealItemInfo?.newYear24DealMetaData?.weeklySalesCount
              const monthlySalesCount =
                dealItemInfo?.newYear24DealMetaData?.monthlySalesCount

              const discount5Target =
                (salesSource === 'winner' && reviewsCount < 1000) ||
                (salesSource === 'alwayzTop' && monthlySalesCount < 600)
              const discount10Target =
                salesSource === 'naverBest' && weeklySalesCount < 10

              const isSpecialTarget =
                (salesSource === 'winner' && reviewsCount < 1000) ||
                salesSource === 'naverBest' ||
                salesSource === 'MD' ||
                salesSource === 'alwayzTop'

              if (isSpecialTarget) {
                if (discount5Target) {
                  if (
                    !dealOptionsInfoChecker(
                      oldOptionsInfo,
                      dealOptionsInfo,
                      0.951,
                    )
                  ) {
                    alert(
                      `모든 옵션에 대해 기존 팀구매가 대비 최소 5% 이상의 할인율을 적용해주세요.`,
                    )
                    return
                  }
                }
                if (discount10Target) {
                  if (
                    !dealOptionsInfoChecker(
                      oldOptionsInfo,
                      dealOptionsInfo,
                      0.901,
                    )
                  ) {
                    alert(alertMessage)
                    return
                  }
                }
              }
              if (
                !dealOptionsInfoChecker(
                  oldOptionsInfo,
                  dealOptionsInfo,
                  minPriceRatio,
                ) &&
                !isSpecialTarget
              ) {
                alert(alertMessage)
                return
              }

              // if (!itemValue) {
              //   alert('상품 종류를 선택해주세요.')
              //   return
              // }
              // if (!packagingValue) {
              //   alert('포장 방식을 선택해주세요.')
              //   return
              // }
              if (applied) {
                const result = await backendApis.updateDealOptionsInfo(
                  itemInfo?._id,
                  type,
                  dealOptionsInfo,
                )
                if (result?.status === 200) {
                  alert('정상적으로 수정되었습니다.')
                  setShowModal(false)
                } else {
                  alert(
                    '설 행사 상품 등록에 실패했습니다. 문제가 반복될 경우 판매자 지원센터로 문의주세요.',
                  )
                }
              } else {
                const result =
                  await backendApis.registerNewYear24DealOptionsInfo(
                    itemInfo?._id,
                    itemInfo?.itemTitle,
                    type,
                    dealOptionsInfo,
                    newYear24DealMetaData,
                  )
                if (result?.status === 200) {
                  alert(
                    '정상적으로 신청되었습니다. 가격/품질 등 검수 통과될 경우 노출됩니다.',
                  )
                  setShowModal(false)
                } else {
                  alert(
                    '설 행사 상품 등록에 실패했습니다. 문제가 반복될 경우 판매자 지원센터로 문의주세요.',
                  )
                }
              }
            }}
          >
            {applied ? '수정하기' : '행사 신청하기'}
          </Button>
          <button
            className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
            type='button'
            onClick={() => {
              setShowModal(false)
            }}
          >
            닫기
          </button>
        </div>
      )
    }
    if (type === 'newYear24DealFinal') {
      return (
        <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
          <button
            className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
            type='button'
            onClick={() => {
              setShowModal(false)
            }}
          >
            닫기
          </button>
        </div>
      )
    }
    if (type === 'tripleSevenDeal') {
      return (
        <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
          <button
            className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
            type='button'
            onClick={() => {
              setShowModal(false)
            }}
          >
            닫기
          </button>
        </div>
      )
    }

    return <></>
  }

  const shouldLoadComponent = applied ? dealItemInfoSetFlag : true

  return (
    <>
      {showModal &&
        dealType &&
        shouldLoadComponent &&
        validTypeList.includes(dealType) && (
          <>
            <div className='fixed inset-0 z-50 flex items-center justify-center px-2 py-10 overflow-x-hidden overflow-y-scroll outline-none'>
              <div className='relative w-full max-w-4xl mx-auto my-auto shadow-lg'>
                <div className='relative flex flex-col w-full bg-white border-0 rounded-lg outline-none'>
                  <Header type={dealType} />
                  <Body type={dealType} />
                  <div className='relative flex flex-col items-center justify-center max-h-full px-10 py-10 overflow-y-scroll'>
                    <OptionTable
                      optionsInfo={dealOptionsInfo}
                      setOptionsInfo={setDealOptionsInfo}
                      oldOptionsInfo={oldOptionsInfo}
                      columns={columns}
                      dealType={dealType}
                    />
                  </div>
                  <Footer type={dealType} />
                </div>
              </div>
            </div>
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
          </>
        )}
    </>
  )
}

export default DealApplicationModal

const AlfarmFreeAdEventNoticeComponent = () => {
  return (
    <div className='flex flex-col items-start flex-1 p-8 mb-5 bg-white shadow-lg rounded-xl'>
      <div className='flex flex-row items-center mb-5 text-xl font-bold'>
        <div className='mr-3 text-2xl text-red-500'>이벤트</div>{' '}
        <div className='mr-3 text-2xl text-amber-500'>
          올팜 무료 광고 신청 이벤트 대상입니다.{' '}
        </div>
      </div>
      <div>
        <span className='font-bold'>
          [오늘의특가, 낙엽, 미니게임, 퀴즈, 포춘쿠키]
        </span>{' '}
        광고 중 원하시는 한 곳에서 무료로 광고를 진행 하고 광고 효율을 미리
        확인해볼 수 있습니다.
      </div>
      <div className='font-bold mb-5'>
        모든 광고 영역 중 한 곳에서 한 번만 신청이 가능하며, 무료 광고 수량은
        1만 건입니다.
      </div>
      <div>[유의 사항]</div>
      <div className=''>
        - 하단에서 광고를 진행할 제품을 선택하신 후,{' '}
        <span className='font-bold'> 우측의 '무료 광고 신청(이벤트)' </span>{' '}
        버튼을 클릭하여 무료 광고를 신청할 수 있습니다.
      </div>
      <div>
        - 당일 광고 신청 인원이 많거나, 광고 기준에 맞지 않는 경우 반려될 수
        있습니다. 이 경우 재신청이 가능합니다.
      </div>
      <div className='font-bold'>
        - 검수 완료 후 광고를 취소하시면 재신청이 불가능합니다.
      </div>
      <div className='font-bold'>
        - 광고 진행 상황 및 성과는 하단의 '신청 완료' 탭을 통해 확인할 수
        있습니다.
      </div>
    </div>
  )
}

export default AlfarmFreeAdEventNoticeComponent

import React, { useState, useEffect, useMemo } from 'react'
import Page from '../components/atoms/page'
import { FcRatings } from 'react-icons/fc'
import backendApis from '../utils/backendApis'
import ItemTable from '../components/ADS/molecules/itemTable'
import { Pagination } from 'antd'
import CheapabilityNudgeComponent from '../components/ItemComponents/CheapabilityNudgeComponent'

const CheapabilityNudgeScreen = () => {
  const chunkSize = 5
  const [itemData, setItemData] = useState([])
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [page, setPage] = useState(1)
  const [selectedItemId, setSelectedItemId] = useState(null)
  const loadNum = parseInt(chunkSize * (page - 1), 10) || 0
  const [condition, setCondition] = useState({
    cheapabilityCap: 0,
  })
  const selectedItemInfo = itemData?.find(
    (e) => e?._id?.toString() === selectedItemId?.toString(),
  )

  const columns = useMemo(
    () => [
      { Header: '상품정보', accessor: 'itemTitle' },
      { Header: '팀구매가', accessor: 'teamPurchasePrice' },
      {
        Header: '등급',
        accessor: 'rankInfo.itemRank',
      },
    ],
    [],
  )

  const fetchData = async (
    condition,
    loadNum,
    chunkSize,
    pageChange = true,
  ) => {
    const result = await backendApis.getCheapabilityNudgeItems(
      condition,
      loadNum,
      chunkSize,
    )
    if (result?.status === 200) {
      setItemData(result?.data)
      if (pageChange) setSelectedItemId(result?.data?.[0]?._id)
    }
  }

  const checkTotalItemCount = async (condition) => {
    const result = await backendApis.getTotalLengthofCheapabilityNudgeItems(
      condition,
    )
    if (result?.status === 200) setTotalItemCount(result?.data)
  }

  useEffect(() => {
    fetchData(condition, loadNum, chunkSize)
    if (totalItemCount === 0) checkTotalItemCount(condition)
  }, [page, condition])

  return (
    <Page title='초저가 관리 beta' Icon={FcRatings}>
      <div className='mb-10'>
        상품 초저가 관리 페이지입니다.
        <br /> 초저가 심사가 순차적으로 이뤄지고 있어 아직 판매자님의 상품정보가
        보이지 않을 수 있습니다.
        <br /> 빠른 업데이트를 위해 노력하겠습니다.
      </div>

      <div>
        <Pagination
          pageSize={chunkSize}
          total={totalItemCount}
          current={page}
          onChange={(e) => setPage(e)}
        />
        <div className='my-5'>
          <ItemTable
            columns={columns}
            data={itemData}
            onRowClick={(item) => {
              setSelectedItemId(item?._id)
            }}
            selectedRowItemId={selectedItemId}
          />
        </div>
      </div>
      <div>
        {selectedItemInfo && (
          <CheapabilityNudgeComponent
            itemInfo={selectedItemInfo}
            refetch={async () => {
              await fetchData(condition, loadNum, chunkSize, false)
            }}
          />
        )}
      </div>
    </Page>
  )
}
export default CheapabilityNudgeScreen

import React, { useState } from 'react'
import { Modal, Button, List } from 'antd'
import SelectedItemsModal from './SelectedItemsModal'

const ConfirmCpmAdModal = ({
  visible,
  onCancel,
  onConfirm,
  adDetails,
  onItemRemove,
  isRegistering,
}) => {
  const [isSelectedItemsModalVisible, setIsSelectedItemsModalVisible] =
    useState(false)

  const {
    dateRange,
    gender,
    ageRange,
    selectedItems,
    adQuantity,
    bidPrice,
    adPaymentAmount,
  } = adDetails

  const getGenderText = (gender) => {
    if (gender === 'all') return '전체'
    if (gender === 'male') return '남성'
    return '여성'
  }

  const adInfo = [
    { label: '광고 기간', value: `${dateRange[0]} ~ ${dateRange[1]}` },
    {
      label: '성별',
      value: getGenderText(gender),
    },
    { label: '연령대', value: `${ageRange[0]}세 ~ ${ageRange[1]}세` },
    {
      label: '선택된 상품 수',
      value: (
        <div className='flex flex-row flex-1 w-full justify-between'>
          {selectedItems.length}개
          <Button
            type='link'
            onClick={() => setIsSelectedItemsModalVisible(true)}
            style={{ marginLeft: '10px' }}
          >
            자세히 보기
          </Button>
        </div>
      ),
    },
    { label: '광고 수량', value: `${adQuantity.toLocaleString()}회` },
    { label: '입찰가', value: `${bidPrice}원` },
    { label: '총 광고 비용', value: `${adPaymentAmount.toLocaleString()}원` },
  ]

  return (
    <>
      <Modal
        title='광고 등록 확인'
        open={visible}
        onCancel={onCancel}
        footer={[
          <Button key='back' onClick={onCancel} disabled={isRegistering}>
            취소
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={onConfirm}
            disabled={isRegistering}
            loading={isRegistering}
          >
            {isRegistering ? '등록 중...' : '확인'}
          </Button>,
        ]}
      >
        <List
          itemLayout='horizontal'
          dataSource={adInfo}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={item.label} description={item.value} />
            </List.Item>
          )}
        />
      </Modal>
      <SelectedItemsModal
        visible={isSelectedItemsModalVisible}
        onCancel={() => setIsSelectedItemsModalVisible(false)}
        selectedItems={selectedItems}
        onItemRemove={onItemRemove}
      />
    </>
  )
}

export default ConfirmCpmAdModal

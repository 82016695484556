import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import moment from 'moment'
import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'

const ShippingDaysChangeToTwoDaysAlert = observer(() => {
  const [showAlertModal, setShowAlertModal] = useState(false)

  useEffect(() => {
    // const opendAt = window.localStorage.getItem('Shipping2DaysOpenedAt')
    // const now = moment()
    // if (now.isBefore(moment('2024-01-01')) && !opendAt) {
    //   window.localStorage.setItem(
    //     'Shipping2DaysOpenedAt',
    //     new Date().toISOString(),
    //   )
    //   if (
    //     SellerStore.sellerScores?.shippingDetailStats?.totalOrderNum >= 20 &&
    //     (SellerStore.sellerScores?.shippingDetailStats?.fastShippingNum || 0) /
    //       SellerStore.sellerScores?.shippingDetailStats?.totalOrderNum >=
    //       0.99 &&
    //     SellerStore.sellerScores?.shippingDetailStats?.before2daysItemNum /
    //       SellerStore.sellerScores?.shippingDetailStats?.validTotalItems <=
    //       0.5 &&
    //     SellerStore.sellerScores?.shippingDetailStats?.validTotalItems <= 10000
    //   ) {
    //     setShowAlertModal(true)
    //   }
    // }
  }, [
    SellerStore?.sellerInfo?._id,
    SellerStore.sellerScores?.shippingDetailStats?.sellerId,
  ])

  return (
    <>
      {showAlertModal && (
        <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
          <div
            className='relative z-50'
            aria-labelledby='alert-modal-title'
            role='dialog'
            aria-modal='true'
          >
            <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
                <div className='relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg'>
                  <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                    <div className='sm:flex sm:items-start'>
                      {/* Alert Title */}
                      <div className='flex-1 mt-3 sm:ml-4 sm:mt-0 sm:text-left'>
                        <div className='flex flex-row items-center justify-between h-10'>
                          <div
                            className='text-xl font-semibold leading-6 text-gray-900'
                            id='alert-modal-title'
                          >
                            배송 소요일 변경 제안 안내
                          </div>
                        </div>
                        {/* Alert Body(Children) */}

                        <div className='pr-3 mt-2 mb-8 text-gray-500 text-md'>
                          고객들에게 좋은 배송 서비스를 제공해주셔서 감사합니다.
                          <br />
                          배송 소요일을 2일로 설정하고 고객들의 구매를 유도해
                          많은 매출을 일으켜보세요.
                        </div>
                        <img
                          alt=''
                          src='https://assets.ilevit.com/81066753-08b2-45dd-87af-ff33bbce6ae3.png'
                        />
                        <div className='pr-3 text-gray-500 text-md'>
                          (예시) 배송 소요일 2일 변경 후 판매량 추이
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='px-4 py-3 bg-gray-50 sm:flex sm:px-6 sm:justify-between'>
                    <div>
                      <Button
                        type='button'
                        appearance='outline'
                        onClick={() => {
                          setShowAlertModal(false)
                        }}
                      >
                        <div className='px-4 py-1 text-slate-500'>닫기</div>
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={async () => {
                          const confirmMessage = window.confirm(
                            `모든 상품의 배송 소요일을 2일로 변경하시겠습니까?`,
                          )
                          if (confirmMessage) {
                            await backendApis.updateAllSellerItemShippingDaysToTwoDays()
                            alert('완료 되었습니다. 감사합니다.')
                            setShowAlertModal(false)
                          }
                        }}
                      >
                        <div className='px-4 py-1'>
                          모든 상품 배송 소요일 2일로 일괄변경하기
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
})

export default ShippingDaysChangeToTwoDaysAlert

import ButtonCard from '../atoms/buttonCard'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import SellerStore from '../../stores/SellerStore'

const ShippingTypeList = ({ tab, setTab }) => {
  const history = useHistory()
  const fastArrivalCandidate =
    SellerStore?.sellerInfo?.permanentMarks?.fastArrivalCandidate

  useEffect(() => {
    if (!fastArrivalCandidate) {
      history.push('/shippings')
    }
  }, [fastArrivalCandidate, history])

  return (
    <>
      <ButtonCard
        href='/shippings'
        image='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/520ab1b9-4c72-402b-b2be-802b465b6a7e.png'
        text='기본배송'
        subtext1='일반 주문'
        subtext2='배송관리'
        width={60}
        height={70}
        idx={1}
        tab={tab}
        setTab={setTab}
      />
      {fastArrivalCandidate && (
        <ButtonCard
          href='/baro-arrive-shippings'
          image='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/520ab1b9-4c72-402b-b2be-802b465b6a7e.png'
          text='바로도착'
          subtext1='바로도착 주문'
          subtext2='배송관리'
          width={60}
          height={70}
          idx={2}
          tab={tab}
          setTab={setTab}
        />
      )}
    </>
  )
}
export default ShippingTypeList

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ImageUploading from 'react-images-uploading'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import ItemStore from '../stores/ItemStore'
import Page from '../components/atoms/page'
import { FcPackage as PackageIcon } from 'react-icons/fc'
import Card from '../components/atoms/card'
import SubTitle from '../components/atoms/subTitle'
import Button from '../components/atoms/button'
import Tag from '../components/atoms/tag'
import Input from '../components/atoms/input'
import Log from '../utils/log'

const BrandSellerApplication = observer(() => {
  const [mainImagesData, setMainImagesData] = useState([])
  const [tradeMarkRightImage, setTradeMarkRightImage] = useState('')
  const [croppedMainImagesData, setCroppedMainImagesData] = useState([])
  const [route, setRoute] = useState(false)
  const [urlLink, setUrlLink] = useState('')
  const [subUrlLink, setSubUrlLink] = useState('')
  const [sellerInfo, setSellerInfo] = useState([])
  const [headTitle, setHeadTitle] = useState('')
  const [sellerType, setSellerType] = useState('headOffice')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [brands, setBrands] = useState([{ largeCategoryId: '', brandName: '' }])

  useEffect(async () => {
    if (ItemStore.categories.length === 0) {
      await ItemStore.loadCategories()
    }
    headTitleSetter()
  }, [])

  const headTitleSetter = () => {
    if (sellerInfo?.brandInfo?.awaitingApproval) {
      return setHeadTitle('승인 대기중')
    }
    return setHeadTitle('브랜드 판매자 등록')
  }

  return (
    <div className='max-w-7xl'>
      <Page title={headTitle} Icon={PackageIcon}>
        <Card title='브랜드 판매자 등록'>
          <div className='mb-5 '>
            <div className='flex items-center mb-5 '>
              <SubTitle>판매자 정보를 입력해주세요 </SubTitle>
            </div>

            <div className='mt-5'>
              판매자 유형을 선택해주세요
              <span className='text-red-500'>
                {' '}
                (본사이면서 타 상품의 벤더사 역할도 하는 판매자는 본사로
                선택해주세요)
              </span>
            </div>
            <div className='flex flex-row items-center mb-5'>
              <input
                className='mr-3'
                type='radio'
                name='route'
                value='headOffice'
                onChange={(e) => {
                  setSellerType(e.target.value)
                }}
              />
              본사
              <input
                className='mx-3'
                type='radio'
                name='route'
                value='vendor'
                onChange={(e) => {
                  setSellerType(e.target.value)
                }}
              />
              벤더사
            </div>
            {sellerType === 'headOffice' ? (
              <div>
                {brands?.map((brand, index) => {
                  return (
                    <div className='flex flex-row items-center mb-2'>
                      <div className='flex flex-row items-center mr-3'>
                        <h2 className='mr-3'>카테고리</h2>
                        <select
                          id='large_category'
                          name='large_category'
                          value={brand?.largeCategoryId}
                          onChange={(e) => {
                            setBrands(
                              brands.map((brandTemp, indexTemp) => {
                                if (brands.length > 1) {
                                  return
                                }
                                if (indexTemp === index) {
                                  return {
                                    ...brandTemp,
                                    largeCategoryId: parseInt(
                                      e.target.value,
                                      10,
                                    ),
                                  }
                                }
                                return brandTemp
                              }),
                            )
                          }}
                        >
                          <option value=''>===</option>
                          {ItemStore.categories.map((category) => (
                            <option key={category._id} value={category._id}>
                              {category.largeCategoryNames.kr}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='flex flex-row items-center justify-center mr-3'>
                        <h2 className='mr-3'>브랜드 이름</h2>
                        <Input
                          className='w-2/3'
                          type='text'
                          placeholder='브랜드 이름을 입력해주세요'
                          value={brand?.brandName}
                          onChange={(e) => {
                            setBrands(
                              brands.map((brandTemp, indexTemp) => {
                                if (indexTemp === index) {
                                  return {
                                    ...brandTemp,
                                    brandName: e.target.value,
                                  }
                                }
                                return brandTemp
                              }),
                            )
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
                <div className='mt-5'>
                  <ImageUploading
                    multiple
                    value={tradeMarkRightImage}
                    onChange={(imageList, addUpdateIndex) => {
                      setTradeMarkRightImage(imageList)
                      const croppedMainImagesDataTemp = JSON.parse(
                        JSON.stringify(croppedMainImagesData),
                      )
                      if (addUpdateIndex > croppedMainImagesData.length) {
                        croppedMainImagesDataTemp.push({ sourceUri: '' })
                        setCroppedMainImagesData(croppedMainImagesDataTemp)
                      } else {
                        croppedMainImagesDataTemp[addUpdateIndex] = {
                          sourceUri: '',
                        }
                        setCroppedMainImagesData(croppedMainImagesDataTemp)
                      }
                    }}
                    maxFileSize={5e6}
                    acceptType={['jpg', 'png', 'jpeg']}
                    resolutionType='more'
                    resolutionWidth={800}
                    resolutionHeight={800}
                    onError={(e) => {
                      if (e.maxFileSize) {
                        alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
                      } else if (e.acceptType) {
                        alert(
                          '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/ jpeg/ png)',
                        )
                      } else if (e.resolution) {
                        alert(
                          '고화질 대표이미지로 더 많은 상품 노출을 획득하세요!\n(최소 800px*800px 이상, 권장 이미지 1200px*1200px)',
                        )
                      }
                    }}
                    allowNonImageType='false'
                    maxNumber={10}
                    dataURLKey='sourceUri'
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      dragProps,
                    }) => (
                      <div {...dragProps}>
                        <Button
                          type='button'
                          onClick={onImageUpload}
                          appearance='neutralSub'
                        >
                          상표권 등록하기
                        </Button>
                        <div>
                          <div className='flex flex-row overflow-x-auto '>
                            {imageList.map((image, index) => (
                              <div key={JSON.stringify(image)}>
                                <div className='flex mr-10 w-80 h-80'>
                                  <img
                                    alt='img'
                                    src={image.sourceUri}
                                    className='object-cover object-center border-2 shadow-lg h-80 w-80 border-sub-300'
                                  />
                                </div>
                                <div>
                                  <button
                                    className='px-2 py-2 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                    type='button'
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    사진변경
                                  </button>
                                  <button
                                    className='px-2 py-2 mx-10 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                    type='button'
                                    onClick={() => {
                                      onImageRemove(index)
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            ) : (
              <>
                {brands?.map((brand, index) => {
                  return (
                    <div className='flex flex-row items-center justify-center mb-2 mr-5'>
                      <div className='flex flex-row items-center justify-center mr-3'>
                        <h2 className='mr-3'>카테고리</h2>
                        <select
                          id='large_category'
                          name='large_category'
                          value={brand?.largeCategoryId}
                          onChange={(e) => {
                            setBrands(
                              brands.map((brandTemp, indexTemp) => {
                                if (indexTemp === index) {
                                  return {
                                    ...brandTemp,
                                    largeCategoryId: parseInt(
                                      e.target.value,
                                      10,
                                    ),
                                  }
                                }
                                return brandTemp
                              }),
                            )
                          }}
                        >
                          <option value=''>===</option>
                          {ItemStore.categories.map((category) => (
                            <option key={category._id} value={category._id}>
                              {category.largeCategoryNames.kr}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='flex flex-row items-center justify-center mr-3'>
                        <h2 className='mr-3'>브랜드 이름</h2>
                        <Input
                          className='w-2/3'
                          type='text'
                          placeholder='브랜드 이름을 입력해주세요'
                          value={brand?.brandName}
                          onChange={(e) => {
                            setBrands(
                              brands.map((brandTemp, indexTemp) => {
                                if (indexTemp === index) {
                                  return {
                                    ...brandTemp,
                                    brandName: e.target.value,
                                  }
                                }
                                return brandTemp
                              }),
                            )
                          }}
                        />
                      </div>
                      <Button
                        className='items-center justify-center'
                        appearance='neutralSub'
                        type='button'
                        onClick={() => {
                          setBrands([
                            ...brands,
                            { brandName: '', largeCategoryId: '' },
                          ])
                        }}
                      >
                        옵션 추가하기
                      </Button>
                    </div>
                  )
                })}
              </>
            )}
            <div>
              <h2 className='mt-5 '>행사 담당자 연락처</h2>
              <Input
                className='w-1/2'
                type='text'
                placeholder='행사 담당자 연락처'
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value)
                }}
              />
            </div>
            <div>
              <h2 className='mt-5 '>공식 홈페이지 URL</h2>
              <Input
                className='w-1/2'
                type='text'
                placeholder='공식 홈페이지 URL을 입력해주세요'
                value={urlLink}
                onChange={(e) => {
                  setUrlLink(e.target.value)
                }}
              />
            </div>
            <h2 className='mt-5 '>자사몰 제외 대표 판매 채널</h2>
            <Input
              className='w-1/2'
              type='text'
              placeholder='자사몰 제외 대표 판매 채널의 URL을 입력해주세요'
              value={subUrlLink}
              onChange={(e) => {
                setSubUrlLink(e.target.value)
              }}
            />
          </div>
          <span className='text-red-500'>
            * 브랜드 등록을 하지 않을 시 브랜드 관련하여 행사 신청이 불가합니다{' '}
            <br />* 판매자 유형을 본사로 선택한 판매자는 승인이 필요합니다
          </span>

          <Tag text={ItemStore?.itemInfoOnEdit?.itemTitle} />
        </Card>

        <div className='flex flex-row items-center justify-center flex-1 mb-20'>
          <Button
            size='xxl'
            className='mt-5'
            type='button'
            onClick={async () => {
              if (sellerInfo?.brandInfo?.awaitingApproval) {
                alert(
                  '승인 대기 중입니다. 너무 오래 걸릴 시 판매자 지원센터에 문의해주세요',
                )
                return
              }
              if (SellerStore.loadSellerInfo())
                Log.event(
                  'BrandSellerApplicationScreen',
                  'BrandSellerApplicationScreen',
                  {
                    click: 'BrandSellerApplicationScreen',
                  },
                )
              if (brands.map((e) => e.brandName).includes('')) {
                alert('브랜드 이름을 입력해주세요.')
                return
              }
              if (brands.map((e) => e.largeCategoryId).includes('')) {
                alert('카테고리를 입력해주세요')
                return
              }
              if (tradeMarkRightImage?.length > 1 && route) {
                alert('상표권 등록은 한가지 브랜드만 가능합니다')
                return
              }
              if (!urlLink) {
                alert('사이트 URL을 입력하세요')
                return
              }
              if (phoneNumber.length > 11) {
                alert(
                  '핸드폰 번호를 하이푼을 제외하고 11자 이하로 입력해주세요',
                )
                return
              }
              if (phoneNumber.includes('-')) {
                alert('핸드폰 번호는 하이푼을 제외하고 입력해주세요')
                return
              }
              if (sellerType === 'headOffice' && brands.length > 1) {
                alert(
                  '본사는 하나의 브랜드만 등록이 가능합니다. 새로고침 후 다시 시도해주세요',
                )
                return
              }
              if (!subUrlLink) {
                alert('사이트 URL을 입력하세요')
                return
              }
              if (!sellerType) {
                alert('판매 상품 유형을 선택해주세요')
                return
              }
              if (!phoneNumber) {
                alert('행사 담당자의 연락처를 하이푼을 제외하고 입력해주세요.')
                return
              }
              if (sellerType === 'headOffice' && !tradeMarkRightImage) {
                alert('브랜드 본사는 상표권을 필수로 입력해야합니다')
                return
              }

              const result = await backendApis.uploadBrandSeller(
                urlLink,
                subUrlLink,
                sellerType,
                brands,
                phoneNumber,
              )
              if (tradeMarkRightImage) {
                await backendApis.uploadBrandHeadOfficeTradeMarkImages(
                  SellerStore.sellerInfo._id,
                  tradeMarkRightImage,
                )
              }
              if (result?.status === 2000) {
                setBrands([{ largeCategoryId: '', brandName: '' }])
                setMainImagesData([])
                setTradeMarkRightImage('')
                setCroppedMainImagesData([])
                setPhoneNumber('')
                setUrlLink('')
                setSubUrlLink('')
                if (sellerType === 'vendor') {
                  alert(`브랜드 신청 등록이 완료되었습니다`)
                } else if (sellerType === 'headOffice') {
                  alert(
                    `브랜드 신청 등록이 완료되었습니다. 결과는 문자와 메일을 통해 전달됩니다`,
                  )
                }
              } else {
                alert(`문제가 발생했습니다. 판매가 지원센터에 문의해주세요.`)
              }
            }}
          >
            브랜드 판매자 등록 완료하기
          </Button>
        </div>
      </Page>
    </div>
  )
})

export default BrandSellerApplication

import React from 'react'
import Tooltip from './tooltip'

const Badge = ({
  text = '',
  size = 'md',
  className,
  color = 'blue',
  isTooltip = false,
  tooltipText = '',
}) => {
  const styleByType = {
    none: '',
    blue: 'bg-blue-100 text-blue-800',
    gray: 'bg-gray-100 text-gray-800',
    red: 'bg-red-100 text-red-800',
    green: 'bg-green-100 text-green-800',
    yellow: 'bg-yellow-100 text-yellow-800',
    indigo: 'bg-indigo-100 text-indigo-800',
    purple: 'bg-purple-100 text-purple-800',
    pink: 'bg-pink-100 text-pink-800',
    darkBlue: 'bg-blue-900 text-blue-300',
    darkGreen: 'bg-green-900 text-green-300',
    darkGray: 'bg-gray-900 text-gray-300',
    darkRed: 'bg-red-900 text-red-300',
    darkYellow: 'bg-yellow-900 text-yellow-300',
    darkIndigo: 'bg-indigo-900 text-indigo-300',
    darkPurple: 'bg-purple-900 text-purple-300',
    darkPink: 'bg-pink-900 text-pink-300',
  }
  const sizeByType = {
    sm: 'text-xs font-medium',
    md: 'text-sm font-medium',
    none: '',
  }

  return (
    <div
      className={`${className} ${sizeByType[size]} ${
        styleByType[color]
      } select-none mr-2 px-2.5 py-1 rounded inline-block
      ${isTooltip && 'pr-1.5'}
      `}
    >
      {text}
      {isTooltip && <Tooltip text={tooltipText} />}
    </div>
  )
}

export default Badge

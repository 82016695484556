import configs from './configs'
import SellerStore from '../stores/SellerStore'

const endpoint = {
  event: {
    dev: 'https://alwayz.ent.us-central1.gcp.cloud.es.io/api/as/v1/engines/alwayz-seller-dev/documents',
    prod: 'https://alwayz.ent.us-central1.gcp.cloud.es.io/api/as/v1/engines/alwayz-seller-prod/documents',
  },
}

const Authorization = 'Bearer private-8ve8qh4i23kcy2ik7tydzjxv'
const lastActiveTime = Date.now()
const sessionTime = lastActiveTime
const lastSentLog = ''
const { nodeEnv } = configs
const serverEnv = nodeEnv === 'production' ? 'prod' : 'dev'

const availableLogtype = ['event']
const availableDataScheme = [
  // 행동추적
  'click',
  'action',
  'screen',
  'component',
  'entering_component',
  'entering_screen',
  'event_value',
  'index',
]

const camelToSnake = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

const sendLog = async (params = {}) => {
  // const {
  //   _id,
  //   sellerName,
  //   sellerDisplayName,
  //   phoneNumber,
  //   contactNumber,
  //   isTestingSeller,
  //   sellerType,
  //   mdName,
  //   createdAt,
  // } = SellerStore.sellerInfo
  // const logtype = params?.logtype
  // const headers = new Headers({
  //   'Content-Type': 'application/json',
  //   Authorization,
  // })
  // if (!logtype || !availableLogtype.includes(logtype)) return
  // const refineParams = {}
  // for (const key in params) {
  //   if (availableDataScheme.includes(key)) {
  //     refineParams[key] = params[key]
  //   }
  // }
  // const now = Date.now()
  // // 로그 중복 전송 방지
  // if (
  //   lastSentLog === JSON.stringify(refineParams) &&
  //   now - lastActiveTime < 1000
  // ) {
  //   return
  // }
  // if (now - lastActiveTime > 1000 * 60 * 10) {
  //   sessionTime = now
  // }
  // lastActiveTime = now
  // lastSentLog = JSON.stringify(refineParams)
  // const data = {
  //   ...refineParams,
  //   seller_id: _id,
  //   seller_name: sellerName,
  //   seller_display_name: sellerDisplayName,
  //   seller_phone_number: phoneNumber,
  //   seller_contact_number: contactNumber,
  //   seller_is_testing: isTestingSeller,
  //   seller_type: sellerType,
  //   seller_md_name: mdName,
  //   seller_registered_at: createdAt,
  //   session_id: `${_id}_${sessionTime}`,
  //   session_duration: now - sessionTime,
  //   logged_at: new Date(),
  // }
  // const res = await fetch(endpoint[logtype][serverEnv], {
  //   method: 'POST',
  //   headers,
  //   body: JSON.stringify(data),
  // })
  // const json = await res.json()
}

const Log = {
  event: (action, component = '', params = {}) => {
    const refineParams = {}
    if (params) {
      Object.entries(params).forEach(([k, v]) => {
        refineParams[camelToSnake(k)] = v
      })
    }
    sendLog({
      logtype: 'event',
      action,
      component,
      ...refineParams,
    })
  },
  progress: () => {},
}

export default Log

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import SKURegister from '../images/SKURegister.png'
import Log from '../utils/log'
import Page from '../components/atoms/page'
import { FcAddDatabase as TitleIcon } from 'react-icons/fc'
import Button from '../components/atoms/button'
import SellerQuest from '../utils/sellerQuest'

const PlayAutoScreen = observer(() => {
  const history = useHistory()
  return (
    <div className='max-w-7xl'>
      <Page title='상품 대량 등록' Icon={TitleIcon}>
        <div className='grid flex-col items-start justify-start flex-1 grid-cols-2 px-10 py-10 mt-5 bg-white shadow-lg rounded-xl'>
          <div>
            <div className='flex flex-col items-start justify-center flex-1 mb-10'>
              <div className='mb-3'>
                <div className='flex flex-row flex-1 mb-5 text-xl font-bold '>
                  비용0원, 클릭 한 번으로{' '}
                  <span className='mx-1 font-bold text-positive-500'>
                    5,000개 상품
                  </span>{' '}
                  대량 등록
                </div>
                <div className='text-gray-500'>
                  <div>
                    플레이오토와의 제휴를 통해 제공드리고 있는 기능입니다.
                  </div>
                  <div>
                    <span className='font-bold text-positive-500'>
                      무료로 올웨이즈 대량 등록 기능만 따로 이용 가능
                    </span>
                    합니다.
                  </div>
                </div>
              </div>
              <Button
                appearance='positive'
                type='button'
                onClick={async () => {
                  if (SellerStore.loadSellerInfo())
                    Log.event(
                      'PlayAutoScreenMainButton',
                      'PlayAutoScreenMainButton',
                      {
                        click: 'PlayAutoScreenMainButton',
                      },
                    )
                  if (
                    !SellerQuest.questChecker('priceQuest', 'playAutoCheck')
                  ) {
                    await SellerQuest.questClearer(
                      'priceQuest',
                      'playAutoCheck',
                    )
                  }
                  window.open(
                    'https://wakeup.plto.com/alwayz/member/create-account',
                  )
                }}
              >
                대량 등록 바로가기
              </Button>
            </div>

            <div className='text-lg font-bold text-sub-500'>상세정보</div>
            <ol className='mb-3 ml-5 text-gray-500 list-disc'>
              <li>
                올웨이즈는 무료배송 플랫폼이라서 배송비는 판매가에 포함되어 옵션
                가격이 정해져요.
              </li>
              <li>
                연동 시 팀구매 가격과 혼자 구매하는 가격이 차이가 10% 이상 나야
                하기에 올웨이즈의 팀구매 가격을 기존 타플랫폼 판매가의 10%
                할인된 가격으로 등록해요.
              </li>
              <li>
                문의사항이 생기실 경우 판매자 지원센터로 문의해주세요. (
                <button
                  type='button'
                  className='underline '
                  onClick={() => {
                    history.push('/seller-support')
                    if (SellerStore.loadSellerInfo())
                      Log.event(
                        'PlayAutoToSellerSupportButton',
                        'PlayAutoToSellerSupportButton',
                        {
                          click: 'PlayAutoToSellerSupportButton',
                        },
                      )
                  }}
                >
                  판매자 지원센터 바로가기
                </button>
                )
              </li>
            </ol>
            <div>
              <Button
                className='mr-3'
                appearance='positiveSub'
                type='button'
                onClick={() => {
                  window.open('https://wakeup.plto.com/guide/wakeup_alwayz')
                  if (SellerStore.loadSellerInfo())
                    Log.event(
                      'PlayAutoTotalRegisterButton',
                      'PlayAutoTotalRegisterButton',
                      {
                        click: 'PlayAutoTotalRegisterButton',
                      },
                    )
                }}
              >
                상품 전체 등록 가이드
              </Button>
              <Button
                appearance='positiveSub'
                type='button'
                onClick={() => {
                  window.open('https://wakeup.plto.com/guide/scrap_prod_alwayz')
                  if (SellerStore.loadSellerInfo())
                    Log.event(
                      'PlayAutoSelectRegisterButton',
                      'PlayAutoSelectRegisterButton',
                      {
                        click: 'PlayAutoSelectRegisterButton',
                      },
                    )
                }}
              >
                상품 선택 등록 가이드
              </Button>
            </div>
          </div>
          <div className='flex flex-col justify-end flex-1 h-full'>
            <img
              className='pl-10'
              alt='banner'
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/14b18664-15cd-4021-afa8-b51f718618f2.png'
            />
          </div>
        </div>
      </Page>
    </div>
  )
})

export default PlayAutoScreen

import OptionTableV2 from '../../molecules/optionTableV2'
import { Tooltip } from 'react-tippy'
import React, { useEffect } from 'react'

const OptionsInfoModal = ({
  title = '다음 옵션으로 행사가 신청됩니다',
  optionsInfo,
  discountRate,
  columns = [
    { Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 },
    { Header: '옵션2 / 상세옵션', accessor: 'option2', index: 2 },
    { Header: '딜 진행가', accessor: 'teamPurchasePrice' },
    { Header: '재고량', accessor: 'stockNumber' },
  ],
  closeCallback = () => {},
}) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        closeCallback()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [closeCallback])

  return (
    <>
      <div className='fixed inset-0 z-50 flex px-2 py-4 overflow-y-scroll outline-none'>
        <div className='relative w-full max-w-5xl mx-auto my-auto shadow-lg'>
          <div className='relative flex flex-col flex-1 w-full bg-white border-0 rounded-lg outline-none h-max-80'>
            <div className='flex items-center justify-between flex-1 w-full px-8 py-4 border-b border-solid border-slate-200'>
              <h3 className='text-lg font-semibold text-black'>{title}</h3>
              <Tooltip
                arrow='true'
                theme='dark'
                title='❎ 키보드 esc로도 종료하실 수 있어요'
                animation='fade'
                position='top'
              >
                <button
                  type='button'
                  onClick={closeCallback}
                  className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
                >
                  esc
                </button>
              </Tooltip>
            </div>
            <div className='relative flex flex-col items-center justify-center max-h-full px-10 py-10 overflow-y-scroll'>
              <OptionTableV2
                columns={columns}
                optionsInfo={optionsInfo}
                discountRate={discountRate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='fixed inset-0 z-40 bg-black opacity-25' />
    </>
  )
}
export default OptionsInfoModal

import Page from '../components/atoms/page'
import React, { useState, useEffect } from 'react'
import { FcBookmark as TitleIcon } from 'react-icons/fc'
import { observer } from 'mobx-react-lite'
import ShippingTypeList from '../components/molecules/shippingTypeList'

const OrdersShippingTypeScreen = observer(() => {
  return (
    <Page title='배송 유형 선택' Icon={TitleIcon}>
      <div className='flex flex-col flex-1 w-full'>
        <div className='flex flex-row flex-1 w-full'>
          <ShippingTypeList />
        </div>
      </div>
    </Page>
  )
})

export default OrdersShippingTypeScreen

import BarLoader from 'react-spinners/BarLoader'

const DealApplicationStatusMini = ({ itemInfo }) => {
  return (
    <>
      <div>
        <BarLoader
          speedMultiplier={0.3}
          width={200}
          color='#0284c7'
          className='mb-5'
        />
        <div className='mb-2'>
          실시간 <span className='font-bold text-blue-500'>행사 신청</span>{' '}
          중입니다
        </div>
        <div className='text-sm text-gray-500'>
          <div>일반매대에 노출되지 않는 딜 전용상품입니다</div>
          <div>행사 선정시 진행 동의 후 진행됩니다</div>
        </div>
      </div>
    </>
  )
}
export default DealApplicationStatusMini

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import SellerStore from '../stores/SellerStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'
import socket from '../utils/socket'

const WithdrawCancelingPreShippingOrderModal = observer(
  // order 단위 옆에서 바로 문의할 것 같으면(주문문의) itemInfo - 이름, 옵션, 수량, orderid, userInfo - 고객명 등의 내용들을 보내야 함
  ({ order }) => {
    const [text, setText] = useState('')
    const [shippingNumber, setShippingNumber] = useState('')
    //   useEffect(() => {}, [orderIdForSearch])
    // const orderQuestionLists = searchedQuestions.map((question, index) => {
    //   return (
    //     //   <div
    //     //     key={users?._id}
    //     //     style={{
    //     //       height: 100,
    //     //       marginTop: 20,
    //     //       marginLeft: 40,
    //     //       marginRight: 40,
    //     //       flexDirection: 'column',
    //     //     }}
    //     //   >
    //     //     <div style={{ width: '100%' }}>
    //     //       {/* <h2>{ItemStore.itemQuestions.userName}</h2> */}
    //     //       <table style={{ width: '100%' }}>
    //     //         <thead>
    //     //           <tr style={{ fontSize: 15 }}>
    //     //             {/* <th>질문자</th> */}
    //     //             <th style={{ width: '7%' }}>등록일시</th>
    //     //             <th style={{ width: '7%' }}>고객명</th>
    //     //             <th style={{ width: '15%' }}>문의내용</th>
    //     //             <th style={{ width: '5%' }}>문의유형</th>
    //     //             <th style={{ width: '20%' }}>답변내용</th>
    //     //             <th style={{ width: '10%' }}>답변여부</th>
    //     //           </tr>
    //     //         </thead>
    //     //         <tbody style={{ fontSize: 15 }}>
    //     //           {users?.questionsInfo.map((questionInfo, index) => {
    //     //             return (
    //     <tr
    //       key={question._id + index.toString()}
    //       style={{ width: '100%', fontsize: 16 }}
    //     >
    //       <td>
    //         <div>
    //           {moment(question.questionInfo?.createdAt).format('YYYY.MM.DD')}
    //         </div>
    //         <div>
    //           {moment(question.questionInfo?.createdAt).format('HH:mm')}
    //         </div>
    //       </td>
    //       <td>
    //         <div>{question?.questionInfo?.userName}</div>
    //       </td>

    //       <td>
    //         <div>{question?.itemTitle}</div>
    //         <div>{question?.questionInfo?.text}</div>
    //       </td>
    //       <td>
    //         <div>{question?.type === 'item' ? '상품문의' : '주문문의'}</div>
    //         <div>{question?.orderId}</div>
    //       </td>
    //       {/* <td>{quesionInfo?.orderId}</td> */}
    //       <QuestionComponent
    //         // style={{ color: 'red' }}
    //         key={question?._id}
    //         questionInfo={question?.questionInfo}
    //         index={index}
    //         answerInfo={question?.answerInfo}
    //         answerText={question?.answerInfo?.text}
    //       />
    //       {console.log('here', orderIdForSearch)}
    //       {/* {!questionInfo?.answerInfo ? (
    //                   <QuestionComponent
    //                     // style={{ color: 'red' }}
    //                     key={ItemStore.itemQuestions.question?.questionId}
    //                     question={ItemStore.itemQuestions.question}
    //                     index={index}
    //                     text
    //                   />
    //                 ) : (
    //                   <div>
    //                     <td style={{ width: '90%' }}>
    //                       <div>{questionInfo.answerInfo?.text}</div>
    //                     </td>
    //                     <td>답변완료</td>
    //                   </div>
    //                 )} */}
    //     </tr>
    //     //             )
    //     //           })}
    //     //         </tbody>
    //     //         {/* <tbody>{itemQuestionLists}</tbody> */}
    //     //       </table>
    //     //     </div>
    //     //     {/* {itemQuestionLists} */}
    //     //     {/* <h1>고객문의(답변완료)</h1> */}
    //     //     {/* {AnsweredQuestions} */}
    //     //   </div>
    //   )
    // })
    return (
      <div
        style={{
          height: 100,
          marginTop: 20,
          marginLeft: 40,
          marginRight: 40,
          flexDirection: 'column',
        }}
      >
        <div style={{ width: '100%' }}>
          <h2>취소 거부처리</h2>
        </div>
        <hr />
        <div
          style={{
            fontSize: 16,
            height: 120,
            paddingTop: 0,
            color: 'black',
          }}
        >
          - 이미 구매자에게 상품을 발송한 경우에만 취소 거부처리 가능합니다.
          <br />- 발송한 상품의 배송정보를 입력하시고 (취소 거부처리) 버튼을
          클릭하시면 취소거부 및 상품발송이 한번에 처리됩니다. (입력된 정보가
          해당 주문건 배송정보로 자동 등록처리)
          <br />- 취소 거부를 위하여 가송장을 우선적으로 등록하는 등 부정행위를
          방지하기 위하여 취소 거부처리 과정에서 등록된 운송장은{' '}
          <font color='#ff0000'>
            <b>변경 불가</b>
          </font>
          한점 참고 부탁드립니다.
        </div>
        <div
          style={{
            // width: '100%',
            height: 100,
            // backgroundColor: 'red',
            // justifyContent: 'center',
            // alignItems: 'center',
          }}
        >
          <div
            style={{
              // width: '100%',
              // height: 80,
              // backgroundColor: 'red',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <table
              style={{
                height: 80,
                borderWidth: 1.5,
                borderColor: '#edf0f5',
                // borderCollapse: 'collapse',
              }}
            >
              <tr>
                <th
                  style={{
                    backgroundColor: '#f0f0f0',
                    borderWidth: 1.5,
                    borderColor: '#edf0f5',
                  }}
                >
                  배송방법 선택
                </th>
                <td>택배,등기,소포</td>
              </tr>
              <tr>
                <th
                  style={{
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  배송정보 입력
                </th>
                <td>
                  <select
                    style={{ width: 80 }}
                    id={`shipping_company_${OrderStore?.withdrawingOrderInfo?.orderId}`}
                    name={`shipping_company_${OrderStore?.withdrawingOrderInfo?.orderId}`}
                    onChange={(e) => {
                      // console.log(`wowwow ${JSON.stringify(e.target.value)}`)
                      const correspondingShippingCompanyInfo =
                        OrderStore?.shippingCompaniesInfo?.find(
                          (shippingCompanyInfo) =>
                            shippingCompanyInfo?.shippingCompanyCode ===
                            e.target.value,
                        )
                      // console.log(
                      //   `shipping company info: ${JSON.stringify(
                      //     correspondingShippingCompanyInfo,
                      //   )}`,
                      // )
                      if (correspondingShippingCompanyInfo) {
                        // console.log(
                        //   `shipping info: ${JSON.stringify({
                        //     ...shippingInfo,
                        //     ...correspondingShippingCompanyInfo,
                        //   })}`,
                        // )
                        // setShippingInfo({
                        //   ...shippingInfo,
                        //   ...correspondingShippingCompanyInfo,
                        // })
                        OrderStore?.setWithdrawingOrderInfo({
                          ...OrderStore?.withdrawingOrderInfo,
                          shippingInfo: correspondingShippingCompanyInfo,
                        })
                        // console.log(
                        //   'hih',
                        //   JSON.stringify(OrderStore?.withdrawingOrderInfo),
                        // )
                        // ])
                      } else {
                        // setShippingInfo(null)
                        OrderStore?.setDisplayedOrders(
                          OrderStore?.displayedOrders?.map((orderTemp) => {
                            if (
                              orderTemp?._id ===
                              OrderStore?.withdrawingOrderInfo?.orderId
                            ) {
                              return {
                                ...orderTemp,
                                shippingInfo: null,
                              }
                            }
                            return orderTemp
                          }),
                        )
                      }
                    }}
                    value={
                      OrderStore?.withdrawingOrderInfo?.shippingInfo
                        ?.shippingCompanyCode || ''
                    }
                  >
                    <option value=''>===</option>
                    {OrderStore?.shippingCompaniesInfo?.map(
                      (shippingCompanyInfo) => (
                        <option
                          key={shippingCompanyInfo?.shippingCompanyCode}
                          value={shippingCompanyInfo?.shippingCompanyCode}
                          //   onChange={(e) => {
                          //     console.log(`wowwow${JSON.stringify(e.nativeEvent)}`)
                          //   }}
                        >
                          {shippingCompanyInfo?.shippingCompanyName}
                        </option>
                      ),
                    )}
                  </select>
                  <input
                    type='text'
                    value={shippingNumber}
                    onChange={(e) => {
                      setShippingNumber(e.target.value)
                    }}
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          style={{
            // width: '24%',
            // borderColor: '#adadad',
            // border: 'solid',
            display: 'flex',
            // borderWidth: 1,
            // borderRadius: 5,
            // marginRight: 11,
            // paddingLeft: 10,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            // backgroundColor: 'red',
          }}
        >
          <div
            style={{
              // position: 'absolute',
              // left: '30%',
              // height: 40,
              // width: 160,
              // justifyContent: 'center',
              // alignItems: 'center',
              // backgroundColor: 'red',
              // backgroundColor: 'red',
              marginRight: 16,
            }}
          >
            <button
              type='button'
              style={{
                // position: 'absolute',
                // left: '50%',
                height: 40,
                width: 160,
                // backgroundColor: 'red',backgroundColor: 'red'
                // justifyContent: 'center',
                // alignItems: 'center',
                // backgroundColor: 'red',
              }}
              onClick={async () => {
                const shippingInfos = [
                  {
                    orderId: OrderStore?.withdrawingOrderInfo?.orderId,
                    shippingInfo: {
                      ...OrderStore?.withdrawingOrderInfo?.shippingInfo,
                      shippingNumber: shippingNumber.replace(/-/g, '').trim(),
                    },
                  },
                ]
                const result =
                  await backendApis.withdrawCancelingPreshippingOrder(
                    shippingInfos,
                  )
                // console.log('here you are', OrderStore.questionBasicInfo?.sellerId)
                if (result?.status === 2000) {
                  OrderStore?.changeOrdersNumPerStatus(
                    'canceling-pre-shipping',
                    -1,
                  )
                  OrderStore?.setDisplayedOrders(
                    OrderStore?.displayedOrders.map((orderTemp) => {
                      if (
                        orderTemp._id ===
                        OrderStore?.withdrawingOrderInfo?.orderId
                      ) {
                        return {
                          ...orderTemp,
                          status: 'shipping',
                          shippingInfo: {
                            ...orderTemp.shippingInfo,
                            shippingNumber: shippingNumber
                              .replace(/-/g, '')
                              .trim(),
                          },
                        }
                      }
                      return orderTemp
                    }),
                  )
                  OrderStore.changeOrdersNumPerStatus(
                    'canceling-pre-shipping',
                    -1,
                  )
                  OrderStore.changeOrdersNumPerStatus('shipping', 1)
                  // 여기
                  alert('취소 거부가 접수되었습니다.')
                  setText('')
                  OrderStore?.setWithdrawingOrderInfo([])
                  OrderStore?.setOpenWithdrawCancelingPreShippingOrderModal(
                    false,
                  )
                } else {
                  alert(
                    '취소 거부에 실패하였습니다. 등록하시고자 하는 택배사 및 운송장번호가 맞는지 확인해 주세요.',
                  )
                }
              }}
            >
              취소거부 및 발송처리
            </button>
          </div>
          <div
            style={{
              // position: 'absolute',
              // left: '60%',
              // height: 40,
              // width: 160,
              // justifyContent: 'center',
              // alignItems: 'center',
              // backgroundColor: 'red',
              marginleft: 10,
              paddingleft: 10,
            }}
          >
            <button
              type='button'
              style={{
                // position: 'absolute',
                // left: '50%',
                height: 40,
                width: 60,
                // justifyContent: 'center',
                // alignItems: 'center',
                // backgroundColor: 'red',
              }}
              onClick={async () => {
                OrderStore?.setOpenWithdrawCancelingPreShippingOrderModal(false)
              }}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    )
  },
)

export default WithdrawCancelingPreShippingOrderModal

import { RankS, RankA, RankB, RankC } from '../../images/itemRankIcon'

const DealRankNotice = ({ minRank }) => {
  const commonStyle =
    'flex flex-row items-center flex-1 px-5 py-5 my-5 text-lg shadow-md bg-white rounded-xl w-max'
  if (minRank === 'S') {
    return (
      <div className={commonStyle}>
        <RankS className='mr-3 w-7 h-7' />
        <div>
          <b>S등급 이상 아이템</b>만 신청 가능한 프로모션입니다.
        </div>
      </div>
    )
  }
  if (minRank === 'A') {
    return (
      <div className={commonStyle}>
        <RankA className='mr-3 w-7 h-7' />
        <div>
          <b>A등급 이상 아이템</b>만 신청 가능한 프로모션입니다.
        </div>
      </div>
    )
  }
  if (minRank === 'B') {
    return (
      <div className={commonStyle}>
        <RankB className='mr-3 w-7 h-7' />
        <div>
          <b>B등급 이상 아이템</b>만 신청 가능한 프로모션입니다.
        </div>
      </div>
    )
  }
  return (
    <div className={commonStyle}>
      <RankC className='mr-3 w-7 h-7' />
      <div>
        <b>C등급 이상 아이템</b>만 신청 가능한 프로모션입니다.
      </div>
    </div>
  )
}
export default DealRankNotice

import React, { useEffect, useMemo, useState } from 'react'
import ItemStore from '../stores/ItemStore'
import SellerStore from '../stores/SellerStore'
import Page from '../components/atoms/page'
import { FcHighPriority, FcIdea, FcExpand as TitleIcon } from 'react-icons/fc'
import backendApis from '../utils/backendApis'
import ItemWinnerSearchModal from './ItemWinnerSearchModal'
import resize from '../utils/resize'
import Table from '../components/ADS/molecules/table'
import Button from '../components/atoms/button'
import ItemWinnerApplicationModal from './ItemWinnerApplicationModal'
import ApplyTabHandler from '../components/molecules/applyTabHandler'
import { Badge } from '../components/ADS/atoms'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ItemWinnerLinkApplicationModal from './ItemWinnerLinkApplicationModal'
import Pagination from '../components/molecules/pagination'
import ItemSearchComponent from '../components/ItemComponents/ItemSearchComponent'
import ListLimitSelector from '../components/atoms/listLimitSelector'

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const ItemWinnerScreen = () => {
  const [selectedItem, setSelectedItem] = useState()
  const [applyStatusTab, setApplyStatusTab] = useState('candidate')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const offset = (page - 1) * limit
  const [openTimeDealApplicationModal, setOpenTimeDealApplicationModal] =
    useState(false)
  const [selectedItemWinnerInfo, setSelectedItemWinnerInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [itemsCount, setItemsCount] = useState(0)
  const [itemsInfo, setItemsInfo] = useState([])
  const [targetItemInfo, setTargetItemInfo] = useState({})
  const [openItemWinnerOptionModal, setOpenItemWinnerOptionModal] =
    useState(false)
  const [itemInfo, setItemInfo] = useState({})
  const [showLinkModal, setShowLinkModal] = useState(false)
  const history = useHistory()
  const rejectCountStandard = 20

  let entranceCondition = true
  if (
    SellerStore.sellerInfo?.itemWinnerInfo?.rejectCount > rejectCountStandard
  ) {
    entranceCondition = false
  }
  if (!entranceCondition) {
    history.goBack()
    alert(
      `이번 한달간 반려 횟수가 ${rejectCountStandard}회 초과하여 이번 달은 신청이 불가합니다`,
    )
    return null
  }

  const handleSearch = (selectedSearchField, itemQuery) => {
    // 검색 정보 넘겨주기
    fetchSegmentedItemsInfo(true, '', selectedSearchField, itemQuery)
  }

  const findOptionInfo = (optionsInfo, itemWinnerId) => {
    const { optionPrices, optionNames, totalOptions } = optionsInfo
    // optionPrices의 구조를 검사
    const isArrayofArray = Array.isArray(optionPrices[0])

    // 주어진 구조에 따라 올바른 반복문을 사용
    if (isArrayofArray) {
      for (let i = 0; i < optionPrices.length; i++) {
        for (let j = 0; j < optionPrices[i].length; j++) {
          if (
            optionPrices[i][j].itemWinnerId &&
            optionPrices[i][j].itemWinnerId === itemWinnerId
          ) {
            return {
              optionInfo: optionPrices[i][j],
              name: `${optionNames[0]}: ${totalOptions[0][i].name}, ${optionNames[1]}: ${totalOptions[1][j].name}`,
            }
          }
        }
      }
    } else {
      for (let i = 0; i < optionPrices.length; i++) {
        if (
          optionPrices[i].itemWinnerId &&
          optionPrices[i].itemWinnerId === itemWinnerId
        ) {
          return {
            optionInfo: optionPrices[i],
            name: `${optionNames[0]}: ${totalOptions[0][i].name}`,
          }
        }
      }
    }

    // 일치하는 itemWinnerId가 없을 경우
    return null
  }

  const getItemDetails = (itemsInfo) => {
    const result = []

    // itemsInfo 배열을 순회
    for (const item of itemsInfo) {
      const {
        _id,
        optionsInfo,
        itemWinnerInfo,
        itemTitle,
        teamPurchasePrice,
        individualPurchasePrice,
      } = item

      // itemWinnerInfo 배열을 순회
      for (const winnerInfo of itemWinnerInfo) {
        const itemWinnerId = winnerInfo.itemWinnerId
        const targetOptionInfo = findOptionInfo(optionsInfo, itemWinnerId)
        const optionName = targetOptionInfo?.name
        const optionInfo = targetOptionInfo?.optionInfo
        const itemWinnerPrice = winnerInfo.itemWinnerPrice
        const isItemWinnerApproved = winnerInfo?.isApproved
        const rejectCount = winnerInfo?.rejectCount
        const rejectReason = winnerInfo?.reason
        const isCooperativeItem = winnerInfo?.isCooperativeItem

        if (
          _id &&
          itemWinnerId &&
          optionName &&
          optionsInfo &&
          itemTitle &&
          optionInfo &&
          itemWinnerPrice !== undefined &&
          teamPurchasePrice !== undefined &&
          individualPurchasePrice !== undefined &&
          isItemWinnerApproved !== undefined
        ) {
          // 최종 객체를 생성하여 결과 배열에 추가
          result.push({
            _id,
            itemWinnerId,
            optionName,
            optionsInfo,
            itemTitle,
            optionInfo,
            itemWinnerPrice,
            teamPurchasePrice,
            individualPurchasePrice,
            isItemWinnerApproved,
            rejectCount,
            rejectReason,
            isCooperativeItem,
          })
        }
      }
    }

    return result
  }

  const data = itemsInfo?.filter(
    (item) => item?.itemWinnerInfo?.length > 0 && item.soldOut === false,
  )
  const optionData = getItemDetails(data)
  const initialPrices = optionData.reduce((prices, item, index) => {
    prices[index] = item?.optionInfo?.teamPurchasePrice
    return prices
  }, {})
  const [editTeamPurchasePrice, setEditTeamPurchasePrice] =
    useState(initialPrices) // 편집 중인 항목을 추적하는 상태

  const columns = useMemo(() => {
    let result
    if (applyStatusTab === 'candidate') {
      result = [
        { Header: '상태', accessor: 'status' },
        { Header: '선택 상품', accessor: 'itemInfo' },
        { Header: '매칭 상품', accessor: 'matchingItemInfo' },
        { Header: '요청하기', accessor: 'buttonStatus' },
      ]
    } else {
      result = [
        { Header: '상태', accessor: 'status' },
        { Header: '협업제품', accessor: 'cooperativeItem' },
        { Header: '등록상품명, 옵션명', accessor: 'itemTitle' },
        { Header: '내 팀구매가', accessor: 'teamPurchasePrice' },
        { Header: '위너 상품 가격', accessor: 'itemWinnerPrice' }, // 오타가 있을 수 있으니 'itemWinnerPrice'로 수정을 고려하세요.
        { Header: '요청하기', accessor: 'editPrice' },
        { Header: '거절사유', accessor: 'reason' },
      ]
    }

    return result
  }, [applyStatusTab])

  const itemWinnerData = useMemo(() => {
    if (applyStatusTab === 'candidate') {
      return itemsInfo
        ?.filter((item) => {
          // Check if the item is not sold out
          if (item?.soldOut === false) {
            let optionPricesCount = 0
            if (item?.optionsInfo?.optionPrices) {
              if (Array.isArray(item.optionsInfo.optionPrices[0])) {
                optionPricesCount = item.optionsInfo.optionPrices.reduce(
                  (count, arr) => count + arr.length,
                  0,
                )
              } else {
                optionPricesCount = item.optionsInfo.optionPrices.length
              }
            }
            let itemWinnerInfoCount = 0
            const winnerInfos = Array.isArray(item?.itemWinnerInfo)
              ? item.itemWinnerInfo
              : []

            if (Array.isArray(winnerInfos)) {
              itemWinnerInfoCount = winnerInfos?.reduce((count, winnerInfo) => {
                if (
                  (winnerInfo.rejectCount === 1 && winnerInfo.reoffer) ||
                  winnerInfo.rejectCount !== 1
                ) {
                  return count + 1
                }
                return count
              }, 0)
            }

            // Return only the items where the two counts are not equal and the item is not sold out
            return optionPricesCount !== itemWinnerInfoCount
          }
          return false // This ensures sold out items are filtered out
        })
        ?.map((item, index) => {
          let statusText = '미신청'
          const winnerInfos = Array.isArray(item?.itemWinnerInfo)
            ? item.itemWinnerInfo
            : []

          if (winnerInfos) {
            if (winnerInfos?.some((winner) => winner.rejectCount === 1)) {
              statusText = '일부옵션 반려'
            } else if (winnerInfos?.some((winner) => winner.itemWinnerId)) {
              statusText = '일부옵션 신청'
            }
          }

          return {
            status: <Badge text={statusText} color='purple' />,
            itemInfo: (
              <div
                key={item?._id}
                style={{ width: '400px', height: '150px', overflow: 'auto' }}
              >
                <div className='flex flex-row justify-between flex-1'>
                  <div className='flex flex-row flex-1 w-max'>
                    <img
                      alt='mainImage'
                      src={
                        item?.mainImageUris
                          ? resize(item?.mainImageUris[0])
                          : ''
                      }
                      className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
                    />
                    <div className='mr-3'>
                      <div className='mb-1 text-lg font-bold whitespace-normal'>
                        {item?.itemTitle}
                      </div>

                      <div className='flex flex-row items-center mb-1'>
                        <div className='flex flex-row items-center mb-1'>
                          {targetItemInfo?._id === item?._id ? (
                            <div className='text-base text-start text-bold'>
                              선택 옵션 :
                              <span className='text-base'>
                                {targetItemInfo.optionName}
                              </span>
                              <br />
                              가격 :
                              <span className='text-base'>
                                {
                                  targetItemInfo?.targetOptionsInfo
                                    ?.teamPurchasePrice
                                }
                              </span>
                              <br />
                              <div className='flex items-start mt-2'>
                                <Button
                                  onClick={async () => {
                                    if (
                                      !selectedItemWinnerInfo?.imageUri ||
                                      selectedItem !== item?._id
                                    ) {
                                      alert('매칭 상품을 먼저 선택해주세요')
                                    } else {
                                      setOpenItemWinnerOptionModal(true)
                                      setItemInfo(item)
                                    }
                                  }}
                                  size='md'
                                >
                                  옵션 수정하기
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className='flex items-start mt-2'>
                                <Button
                                  onClick={async () => {
                                    if (
                                      !selectedItemWinnerInfo?.imageUri ||
                                      selectedItem !== item?._id
                                    ) {
                                      alert('매칭 상품을 먼저 선택해주세요')
                                    } else {
                                      setOpenItemWinnerOptionModal(true)
                                      setItemInfo(item)
                                    }
                                  }}
                                >
                                  옵션 선택하기
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ),
            matchingItemInfo: (
              <div
                key={item?._id}
                style={{ width: '400px', height: '150px', overflow: 'auto' }}
              >
                {selectedItemWinnerInfo?.imageUri &&
                selectedItem === item?._id ? (
                  <div className='flex flex-row flex-1 w-max'>
                    <div className='flex flex-row flex-1 w-max'>
                      <div>
                        <img
                          alt='mainImage'
                          src={resize(selectedItemWinnerInfo?.imageUri)}
                          className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
                        />
                      </div>
                    </div>
                    <div className='flex flex-col flex-1 px-2'>
                      <div className='text-base text-start text-bold'>
                        <span className='text-lg font-bold'>
                          {selectedItemWinnerInfo?.title?.length > 38
                            ? `${selectedItemWinnerInfo?.title.substring(
                                0,
                                38,
                              )}...`
                            : selectedItemWinnerInfo?.title}
                        </span>
                        <br />
                        선택 옵션 :
                        <span className='text-base'>
                          {selectedItemWinnerInfo?.title}
                        </span>
                        <br />
                        위너 상품 가격 :
                        <span className='text-base'>
                          {selectedItemWinnerInfo?.itemWinnerPrice ||
                            selectedItemWinnerInfo?.price}
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <span className='text-base'>
                      현재 매칭된 상품이 없습니다.
                    </span>
                    <br />
                    <span className='text-base'>
                      매칭상품 검색을 활용해서 상품 매칭을 신청해주세요.
                      <br /> 선택 상품과 매칭 상품은 동일해야 승인이 됩니다.
                    </span>
                    <br />
                    <button
                      type='button'
                      className='inline-block px-4 py-2 mt-5 mr-5 text-sm bg-white border-2 border-red-500 rounded-lg w-fit hover:bg-gray-100 text-bold'
                      onClick={() => setShowLinkModal(true)}
                    >
                      ❗ 매칭상품이 없다면? ↗
                    </button>
                  </>
                )}
              </div>
            ),
            buttonStatus: (
              <div className='flex flex-row justify-between flex-1 max-w-1xl'>
                {item?._id === targetItemInfo?._id &&
                selectedItemWinnerInfo &&
                selectedItem === item?._id ? (
                  <Button
                    appearance='positiveSub'
                    size='md'
                    onClick={async () => {
                      if (
                        !targetItemInfo?.teamPurchasePrice ||
                        !selectedItemWinnerInfo?.price ||
                        !selectedItemWinnerInfo?._id
                      ) {
                        alert(
                          '정보가 정확하지 않습니다. 옵션과 매칭 상품을 다시 입력해주세요',
                        )
                        return
                      }
                      if (
                        window.confirm(
                          '매칭 상품과 똑같은 상품만 신청이 가능합니다. 3회 연속으로 잘못 매핑 시 불이익이 있습니다. 신청하시겠습니까? ',
                        )
                      ) {
                        const result =
                          await backendApis.itemWinnerMappingApplication(
                            targetItemInfo,
                            selectedItemWinnerInfo?._id,
                          )
                        if (result?.status === 200) {
                          setItemsInfo(
                            itemsInfo.map((item) => {
                              if (item?._id === itemInfo?._id) {
                                return {
                                  ...item,
                                  itemWinnerInfo: item.itemWinnerInfo
                                    ? item.itemWinnerInfo.map((winnerInfo) => {
                                        // itemWinnerId가 동일한 경우
                                        if (
                                          winnerInfo.itemWinnerId ===
                                          selectedItemWinnerInfo?._id
                                        ) {
                                          return {
                                            ...winnerInfo,
                                            // itemWinnerPrice와 isItemWinner만 변경
                                            itemWinnerPrice:
                                              selectedItemWinnerInfo?.price,
                                            isItemWinner: false,
                                          }
                                        }
                                        return winnerInfo
                                      })
                                    : [
                                        {
                                          itemWinnerId:
                                            selectedItemWinnerInfo?._id,
                                          isApproved: false,
                                          itemWinnerPrice:
                                            selectedItemWinnerInfo?.price,
                                          isItemWinner: false,
                                        },
                                      ],
                                }
                              }
                              return item
                            }),
                          )
                          setTargetItemInfo({})
                          setSelectedItemWinnerInfo({})
                          alert(
                            '매칭 신청이 완료되었습니다. 검수 후 매칭이 최종 완료됩니다',
                          )
                        } else {
                          alert(
                            '매칭 중 문제가 발생했습니다. 판매자 지원센터로 문의해주세요',
                          )
                        }
                      }
                    }}
                  >
                    상품 매칭하기
                  </Button>
                ) : (
                  <Button
                    size='md'
                    onClick={() => {
                      setOpenTimeDealApplicationModal(true)
                      setSelectedItem(item?._id)
                    }}
                  >
                    매칭상품 검색
                  </Button>
                )}
              </div>
            ),
          }
        })
    }
    return optionData?.map((item, index) => {
      const isItemWinnerApproved = item?.isItemWinnerApproved
      const compareValue =
        editTeamPurchasePrice[index] !== undefined
          ? editTeamPurchasePrice[index]
          : item?.optionInfo?.teamPurchasePrice

      let statusText
      let color
      if (item?.rejectCount) {
        statusText = `반려 ${item?.rejectCount}회`
        color = 'darkGray'
      } else if (!isItemWinnerApproved) {
        statusText = '검수중'
        color = 'gray'
      } else if (compareValue <= item?.itemWinnerPrice) {
        statusText = '노출량 파워 UP'
        color = 'red'
      } else {
        statusText = '매칭중'
        color = 'indigo'
      }

      let cooperativeStatusText
      let cooperatvieColor
      if (item?.isCooperativeItem) {
        cooperativeStatusText = `협업 제품`
        cooperatvieColor = 'darkGray'
      } else {
        cooperativeStatusText = ``
      }

      return {
        status: <Badge text={statusText} color={color} />,
        cooperativeItem: (
          <Badge text={cooperativeStatusText} color={cooperatvieColor} />
        ),
        itemTitle: (
          <div key={item?._id} style={{ width: '400px', height: '50px' }}>
            <div className='flex flex-row items-start justify-between flex-1 max-w-4xl overflow-auto'>
              <div className='flex flex-row items-start flex-1 w-max'>
                <div>
                  <div className='mb-1 text-lg font-bold'>
                    {item?.itemTitle}
                  </div>
                  <div className='mb-1 font-bold text-ml'>
                    선택 옵션: {item?.optionName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
        teamPurchasePrice: (
          <input
            type='number'
            min={0}
            value={
              editTeamPurchasePrice[index] !== undefined
                ? editTeamPurchasePrice[index]
                : item?.optionInfo?.teamPurchasePrice
            }
            style={{ width: '100px', height: '50px', overflow: 'auto' }}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10)
              setEditTeamPurchasePrice({
                ...editTeamPurchasePrice,
                [index]: value,
              })
            }}
          />
        ),
        itemWinnerPrice: (
          <div
            key={item?._id}
            style={{ width: '100px', height: '50px', overflow: 'auto' }}
            className='flex items-center justify-center'
          >
            <div className='text-lg'>
              {editTeamPurchasePrice[index] < item?.itemWinnerPrice
                ? editTeamPurchasePrice[index]
                : item?.itemWinnerPrice}
            </div>
          </div>
        ),
        editPrice: (
          <Button
            size='md'
            onClick={async () => {
              console.log(item, 'item')
              if (!isItemWinnerApproved) {
                alert('검수중에는 가격을 변경할 수 없습니다')
                return
              }
              const editPrice = editTeamPurchasePrice[index]
              if (item?.optionInfo?.sold_out === true) {
                alert('옵션이 품절되어있습니다. 재고량을 0 초과로 만들어주세요')
                return
              }
              if (item?.optionInfo?.stockNumber <= 0) {
                alert('재고량을 0 초과로 만들어주세요')
                return
              }
              if (item?.optionInfo?.isItemWinnerApproved === false) {
                alert('검수 중에는 가격을 변경할 수 없습니다')
                return
              }
              if (editPrice > item?.optionInfo?.individualPurchasePrice * 0.9) {
                alert('팀 구매가는 개인구매가보다 10% 이상 저렴해야 합니다')
                return
              }
              if (
                !item?._id ||
                !item?.itemWinnerId ||
                !item?.optionInfo ||
                !item?.optionsInfo ||
                !editPrice
              ) {
                alert(
                  '중요 상품 정보가 제대로 등록되지 않았습니다. 다시한번 확인 부탁드립니다',
                )
                return
              }
              const result = await backendApis.itemWinnerPriceChange(
                item?._id,
                item?.itemWinnerId,
                item?.optionInfo,
                item?.optionsInfo,
                editPrice,
                item?.individualPurchasePrice,
              )
              if (result?.status === 200) {
                let isItemWinner = false
                isItemWinner = result?.isItemWinner
                item.itemWinnerPrice = editPrice
                if (isItemWinner) {
                  alert(
                    '가격 변경이 완료되었습니다. 축하해요! 위너 상품이 되셨어요. 곧 노출량이 올라갑니다',
                  )
                } else {
                  alert(
                    '가격 변경이 완료되었습니다. 아쉽지만 위너 상품이 아직 아니에요.',
                  )
                }
              } else {
                alert(
                  '가격 변경에 실패하였습니다. 판매자지원센터로 문의 바랍니다',
                )
              }
            }}
          >
            가격 변경하기
          </Button>
        ),
        reason: (
          <div
            key={item?._id}
            style={{ width: '1000px', height: '50px', overflow: 'auto' }}
            className='flex items-center justify-center'
          >
            <div className='text-lg'>{item?.rejectReason}</div>
          </div>
        ),
      }
    })
  }, [
    itemsInfo,
    selectedItemWinnerInfo,
    targetItemInfo,
    applyStatusTab,
    selectedItem,
    editTeamPurchasePrice,
  ])

  const fetchSegmentedItemsInfo = async (
    isNewCondition,
    type = 'candidate',
    selectedSearchField,
    itemQuery,
  ) => {
    if (isLoading) return
    setIsLoading(true)
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    const itemSortCondition = { totalOrderQuantity: -1 }
    if (itemQuery) {
      if (selectedSearchField === 'itemTitle') {
        itemCondition.itemTitle = { $regex: escapeRegExp(itemQuery) }
      } else if (selectedSearchField === 'itemId') {
        itemCondition._id = {
          $in: [itemQuery],
        }
      }
    } else if (type === 'complete') {
      itemCondition.itemWinnerInfo = {
        $ne: null,
      }
    }

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
          itemSortCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        ItemStore?.setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        SellerStore.setIsLoading(false)
        return
      }
    }
    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
        itemSortCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
            itemSortCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
        ItemStore?.setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    setIsLoading(false)
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    fetchSegmentedItemsInfo(true, applyStatusTab)
  }, [applyStatusTab])

  useEffect(() => {
    fetchSegmentedItemsInfo(true, applyStatusTab)
  }, [limit])

  useEffect(() => {
    if (ItemStore.categories.length === 0) {
      ItemStore.loadCategories()
    }
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false, applyStatusTab)
    }
  }, [page])

  const handleItemInfoChange = (selectedItemInfo) => {
    setSelectedItemWinnerInfo(selectedItemInfo)
  }

  return (
    <div className='max-w-7xl'>
      <Page title='위너 상품 관리' Icon={TitleIcon}>
        <div className='flex flex-wrap justify-between'>
          <div className='shadow-lg rounded-2xl p-8 bg-white min-w-[405px] flex-auto mr-4 mb-4'>
            <div>
              이 페이지에서는 올웨이즈가 선정한 위너 상품과 매핑(결합)을
              요청하고 관리할 수 있습니다. <br />
              위너 상품으로 선정되면 노출량을 독점 합니다.
            </div>
            <div className='flex flex-col flex-1 p-5 mt-4 bg-gray-100 rounded-xl'>
              <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
                <FcIdea className='mr-2' />
                위너 상품이란?
              </span>
              <ul className='mx-4 list-disc'>
                <li>
                  매핑된 상품 중{' '}
                  <span className='font-bold text-blue-500'>최저가</span>에
                  해당하는 상품입니다.
                </li>
                <li>
                  올웨이즈가 선정한 상품만이 우선 매핑 대상이며 상품은 추가될
                  예정입니다.
                </li>
                <li>
                  품질과 배송 점수가 좋지 않으면 위너 상품에 해당되지 않을 수
                  있습니다.
                </li>
                <li>
                  상품의 옵션별로 매핑이 되며,{' '}
                  <span className='font-bold text-blue-500'>
                    옵션명이 변경되거나 재고가 0이되면{' '}
                  </span>
                  자동으로 위너 상품에서 제외됩니다.
                </li>
              </ul>
              <span className='flex flex-row items-center flex-1 mt-6 mb-1 font-bold'>
                <FcHighPriority className='mr-2' />
                어떻게 노출량을 독점하나요?
              </span>
              <ul className='mx-4 list-disc'>
                <li>
                  검색, 연관 상품 추천 등 핵심 매대에서{' '}
                  <span className='font-bold text-blue-500'>
                    비슷한 상품 중 최상위로 노출됩니다
                  </span>
                </li>
                <li>
                  <span className='font-bold text-blue-500'>
                    오픈 마켓 최저가보다 비싼 상품
                  </span>
                  은 위너 상품이더라도 노출량이 올라가지 않습니다
                </li>
              </ul>
            </div>
            <div className='flex justify-start mt-6'>
              <div className='flex flex-row'>
                <button
                  type='button'
                  className='inline-block px-4 py-2 mr-5 border rounded-lg w-fit hover:bg-gray-100'
                  onClick={() =>
                    window.open(
                      'https://levitinc.notion.site/bf251915d6cf4b078f87cc8e7c2b061f?pvs=4',
                      '_blank',
                    )
                  }
                >
                  매칭 방법 및 혜택 ↗
                </button>
                <button
                  type='button'
                  className='inline-block px-4 py-2 mr-5 border rounded-lg w-fit hover:bg-gray-100'
                  onClick={() => setShowLinkModal(true)}
                >
                  매칭상품이 없다면? ↗
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row items-center justify-between flex-1 mt-10 mb-5'>
          <div className='mr-10'>
            <ApplyTabHandler
              applyStatusTab={applyStatusTab}
              setApplyStatusTab={setApplyStatusTab}
              setPage={setPage}
            />
          </div>
          <div className='flex justify-between'>
            <ItemSearchComponent onSearch={handleSearch} />
            <ListLimitSelector limit={limit} setLimit={setLimit} />
          </div>
        </div>
        <Table columns={columns} data={itemWinnerData} />
        <div>
          <Pagination
            total={itemsCount}
            limit={limit}
            page={page}
            setPage={setPage}
            size='lg'
          />
        </div>
        {openTimeDealApplicationModal && (
          <ItemWinnerSearchModal
            showModal={openTimeDealApplicationModal}
            setShowModal={(bool) => {
              setOpenTimeDealApplicationModal(bool)
            }}
            selectedItemInfo={handleItemInfoChange}
            selectedItem={selectedItem}
          />
        )}
        {openItemWinnerOptionModal && (
          <ItemWinnerApplicationModal
            setTargetItemInfo={(targetItem) => {
              setTargetItemInfo(targetItem)
            }}
            showModal={openItemWinnerOptionModal}
            setShowModal={(bool) => {
              setOpenItemWinnerOptionModal(bool)
            }}
            itemWinnerId={selectedItemWinnerInfo?._id}
            itemWinnerPrice={
              selectedItemWinnerInfo?.itemWinnerPrice ||
              selectedItemWinnerInfo?.price
            }
            minimumMappingPrice={selectedItemWinnerInfo?.price}
            itemInfo={itemInfo}
          />
        )}
        {showLinkModal && (
          <ItemWinnerLinkApplicationModal
            showModal={showLinkModal}
            setShowModal={(bool) => {
              setShowLinkModal(bool)
            }}
          />
        )}
      </Page>
    </div>
  )
}

export default ItemWinnerScreen

import properPriceSetter from '../../utils/properPriceSetter'
import { Button } from 'antd'
import commaNumber from 'comma-number'

const OneClickItemComponent = ({
  itemInfo,
  selected,
  checkBox = false,
  onChecked = () => {},
  salesRatio = 0.9,
  isDealRegister = false,
}) => {
  const {
    bestItemId,
    detailHTML = '',
    detailImageUris = [],
    externalCategoryInfo = {},
    externalLink = '',
    externalDeliveryFee = 0,
    externalMainPrice = null,
    externalReviewContents = [],
    mainImageUris = [],
    shippingInfo = {},
    itemTitle = '',
    optionsInfo = {},
  } = itemInfo || {}

  const getMinValue = (array, valueName) => {
    const parsedArray = array?.flat()?.map((option) => {
      return option?.[valueName]
    })
    const minValue = Math.min(...parsedArray)
    return parseInt(minValue, 10)
  }
  const selectedStyle = selected
    ? 'flex flex-1 flex-row bg-slate-500 text-white shadow-lg rounded-xl whitespace-normal px-5'
    : 'flex flex-1 flex-row bg-white shadow-lg rounded-xl whitespace-normal px-5'
  const externalTotalPrice = externalMainPrice + externalDeliveryFee
  const teamPurchasePrice = getMinValue(
    optionsInfo?.optionPrices,
    'teamPurchasePrice',
  )
  const externalDealPrice = getMinValue(
    optionsInfo?.optionPrices,
    'externalDealPrice',
  )
  const registerPrice = commaNumber(
    properPriceSetter(
      isDealRegister
        ? externalDealPrice + externalDeliveryFee
        : teamPurchasePrice + externalDeliveryFee,
      salesRatio,
    ),
  )

  return (
    <div className={selectedStyle}>
      <div className='flex items-center flex-1 h-40 text-sm'>
        {checkBox && (
          <input
            type='checkbox'
            className='mr-5 cursor-none'
            onChange={(e) => {
              onChecked(e.target.checked)
            }}
          />
        )}
        <>
          <img
            src={mainImageUris?.[0]}
            alt=''
            className='mr-5 w-28 h-28 rounded-xl'
          />
        </>
        <div>
          <div className='flex flex-row mb-2 text-sm'>
            <span>{itemTitle}</span>
          </div>
          <div>
            외부 가격:{' '}
            <span className='font-bold'>{commaNumber(externalTotalPrice)}</span>
            원 (배송비 포함)
          </div>
          <div className='flex flex-row items-center justify-start'>
            <div className='mr-1'>
              {isDealRegister ? '딜 진행가:' : '등록가격:'}{' '}
              <span className='font-bold'>{registerPrice}</span>원 (무료배송)
            </div>
            {isDealRegister && (
              <span className='relative flex items-center justify-center py-[1px] px-2 mr-2 text-xs bg-red-500 rounded-lg whitespace-nowrap text-white'>
                딜 전용
              </span>
            )}
          </div>
          <div className='flex flex-row flex-1'>
            {!!externalDeliveryFee && (
              <div className='mr-5'>
                배송비: {externalDeliveryFee}
                {shippingInfo?.shippingCompany}
              </div>
            )}
            <div>연동 리뷰: {externalReviewContents.length}개</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default OneClickItemComponent

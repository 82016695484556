import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../utils/backendApis'
import { useHistory, useLocation } from 'react-router-dom'
import AuthStore from '../stores/AuthStore'
import Button from '../components/atoms/button'

const ItemPenaltyExcuseScreen = observer(() => {
  const history = useHistory()
  const location = useLocation()
  const penaltyInfo = location?.state?.penaltyInfo
  const [excuse, setExcuse] = useState(
    penaltyInfo?.sellerExcuseInfo?.excuse || '',
  )
  const [afterService, setAfterService] = useState(
    penaltyInfo?.sellerExcuseInfo?.afterService || '',
  )
  const [preventPlan, setPreventPlan] = useState(
    penaltyInfo?.sellerExcuseInfo?.preventPlan || '',
  )
  const [penaltyId, setPenaltyId] = useState(penaltyInfo?._id || '')
  return (
    <>
      <div className='flex flex-col items-center justify-center flex-1 w-full px-10 py-10 mt-10 w-min-7xl'>
        <div className='flex flex-row items-center flex-1 mb-5'>
          <div className='text-2xl font-bold select-none'>
            페널티 발생 건 소명서 작성
          </div>
        </div>
        <div className='overflow-hidden shadow sm:rounded-md my-6 mx-10'>
          <div className='bg-white p-6'>
            <div className='mt-4 mb-10 p-4 rounded-lg bg-gray-100 text-gray-700'>
              * 부당한 사유로 페널티가 부여되었다고 생각되셨을 시 소명서를
              제출해주세요
              <br />
              고객센터 검토 이후 부당한 사유로 페널티가 부여되었다고 판단될 시
              페널티 점수가 부과되지 않습니다.
            </div>
            <div className='grid grid-cols-6 gap-12'>
              <div className='col-span-6'>
                <span className='block font-medium text-gray-800'>
                  1. 해당 문제가 발생한 이유
                </span>
                <span className='text-sm text-gray-500'>
                  어떠한 문제에 대해 패널티를 받으셨으며, 어떠한 경위로 해당
                  문제가 발생하게 되었는지 상세하게 작성해 주세요.
                </span>
                <textarea
                  type='text'
                  placeholder={excuse}
                  disabled={
                    !AuthStore.isMasterAccount && penaltyInfo?.adminCheckedInfo
                  }
                  value={excuse}
                  onChange={(e) => {
                    setExcuse(e.target.value)
                  }}
                  className='mt-1 block w-full h-28 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6'>
                <span className='block  font-medium text-gray-800'>
                  2. 문제 건에 대한 처리/대처 내용
                </span>
                <span className='text-sm text-gray-500'>
                  발생한 문제를 해결하기 위해 어떠한 조치를 취했는지, 그리고
                  고객분들께 어떤 방식으로 a/s를 해주셨는지 상세히 기입해주세요.
                </span>
                <textarea
                  type='text'
                  placeholder={afterService}
                  disabled={
                    !AuthStore.isMasterAccount && penaltyInfo?.adminCheckedInfo
                  }
                  value={afterService}
                  onChange={(e) => {
                    setAfterService(e.target.value)
                  }}
                  className='mt-1 block w-full h-28 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <div className='col-span-6'>
                <span className='block font-medium text-gray-800'>
                  3. 해당 문제 발생 예방을 위해 조치한 내용 및 계획
                </span>
                <span className='text-sm text-gray-500'>
                  향후 동일한 문제가 발생하지 않기 위해서 어떤 조치를 취하셨는지
                  혹은 어떻게 하실 계획인지 상세하게 작성해 주세요.
                </span>
                <textarea
                  type='text'
                  placeholder={preventPlan}
                  disabled={
                    !AuthStore.isMasterAccount && penaltyInfo?.adminCheckedInfo
                  }
                  value={preventPlan}
                  onChange={(e) => {
                    setPreventPlan(e.target.value)
                  }}
                  className='mt-1 block w-full h-28 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              {!penaltyInfo && (
                <div className='col-span-6'>
                  <span className='block font-medium text-gray-800'>
                    4. 페널티 아이디
                  </span>

                  <textarea
                    type='text'
                    disabled={
                      !AuthStore.isMasterAccount &&
                      penaltyInfo?.adminCheckedInfo
                    }
                    placeholder={penaltyId}
                    value={penaltyId}
                    onChange={(e) => {
                      setPenaltyId(e.target.value)
                    }}
                    className='mt-1 block w-full h-28 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
              )}
            </div>
          </div>
          <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
            <Button
              type='submit'
              size='lg'
              onClick={async () => {
                if (!excuse || !afterService || !preventPlan) {
                  alert(
                    '아직 기입하지 않은 내용이 있습니다. 모든 항목을 채워주세요.',
                  )
                }
                if (excuse && afterService && preventPlan && penaltyInfo) {
                  const result = await backendApis.submitPenaltyExcuse(
                    penaltyInfo._id,
                    excuse,
                    afterService,
                    preventPlan,
                  )
                  if (result.status === 200) {
                    alert('소명서 제출이 완료되었습니다.')
                    history.push('/item-penalty')
                  }
                } else if (
                  excuse &&
                  afterService &&
                  preventPlan &&
                  !!penaltyId
                ) {
                  const result = await backendApis.submitPenaltyExcuse(
                    penaltyId || '',
                    excuse,
                    afterService,
                    preventPlan,
                  )
                  if (result.status === 200) {
                    alert('소명서 제출이 완료되었습니다.')
                    history.push('/item-penalty')
                  } else {
                    alert(
                      '페널티 아이디가 정확한지 확인해주세요. 빈 내용을 마저 채워주세요.',
                    )
                  }
                } else {
                  alert(
                    '페널티 아이디가 정확한지 확인해주세요. 빈 내용을 마저 채워주세요.',
                  )
                }
              }}
            >
              {penaltyInfo?.adminCheckedInfo ? (
                '이미 관리자 확인이 완료되었어요'
              ) : (
                <>
                  {penaltyInfo?.sellerExcuseInfo
                    ? '소명서 수정'
                    : '소명서 제출'}
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
})

export default ItemPenaltyExcuseScreen

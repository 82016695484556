import React from 'react'
import { Tooltip } from 'react-tippy'
import { FcInfo } from 'react-icons/fc'

const tooltip = ({ text, position = 'bottom' }) => {
  return (
    <Tooltip
      arrow='true'
      theme='light'
      title={text}
      animation='fade'
      position={position}
    >
      <FcInfo className='inline-block ml-1 mb-0.5 grayscale' />
    </Tooltip>
  )
}

export default tooltip

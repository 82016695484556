import React from 'react'
import { Button, message } from 'antd'
import { CheckCircleOutlined, LeftOutlined } from '@ant-design/icons'
import CalendarPicker from '../../../../components/molecules/calendarPicker'
import styled, { keyframes } from 'styled-components'
import moment from 'moment'

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`

const BlinkingText = styled.div`
  animation: ${blink} 3s linear infinite;
  display: flex;
  align-items: center;
  color: #1890ff;
  font-weight: bold;
`

const Stage1DateSelection = ({
  dispatch,
  dateRange,
  handleNextStage,
  handlePrevStage,
}) => {
  const startDate = dateRange[0]
    ? moment(dateRange[0]).format('YYYY년 MM월 DD일')
    : null
  const endDate = dateRange[1]
    ? moment(dateRange[1]).format('YYYY년 MM월 DD일')
    : null

  return (
    <div className='mb-20 text-slate-700'>
      <div className='flex flex-col items-start flex-1 p-12 mb-5 bg-white shadow-lg rounded-xl'>
        <h2 className='text-lg font-bold mb-4'>광고 기간 선택</h2>
        <div className='mb-12'>
          광고를 진행할 기간을 선택해주세요
          <div className='text-sm text-slate-600 mt-2'>
            * 광고 신청은 다음 날부터 7일 이내로 가능해요. 광고 기간은 5일로
            고정되어 있어요.
          </div>
        </div>
        <div className='grid grid-cols-5 gap-12'>
          <div className='col-start-1 col-end-3'>
            <CalendarPicker
              setDateRange={(range) =>
                dispatch({ type: 'SET_DATE_RANGE', payload: range })
              }
              startDateOffset={1} // 임시로 3일, 추후 1일로 변경
              endDateOffset={7} // 임시로 9일, 추후 7일로 변경
              disablePastDates
            />
          </div>
          <div className='col-start-3 col-end-6'>
            <div className='bg-slate-100 p-8 rounded-xl'>
              <div className='font-bold mb-2'>🚨신청 후 검수 안내</div>
              <ul className='list-none p-0 '>
                <li className='flex items-start'>
                  <CheckCircleOutlined className='text-blue-500 mr-2 mt-1' />
                  <span>CPM 광고는 신청 후 검수를 통해 진행돼요.</span>
                </li>
                <li className='flex items-start'>
                  <CheckCircleOutlined className='text-blue-500 mr-2 mt-1' />
                  <span>
                    검수 과정에는 상품의 매력도, 상품의 적합성, 상품의 가격 등이
                    포함돼요.
                  </span>
                </li>
                <li className='flex items-start'>
                  <CheckCircleOutlined className='text-blue-500 mr-2 mt-1' />
                  <span>
                    광고 신청 후 검수가 완료되면 상태 관리 탭에서 확인할 수
                    있어요.
                  </span>
                </li>
              </ul>
            </div>
            <div style={{ marginTop: '20px' }}>
              {startDate && endDate ? (
                <>
                  <div className='flex items-end gap-2'>
                    <div className='font-bold text-3xl text-blue-500'>
                      {startDate}
                    </div>
                    <div>부터</div>
                  </div>
                  <div className='flex items-end gap-2 mb-2'>
                    <div className='font-bold text-3xl text-blue-500'>
                      {endDate}
                    </div>
                    <div>까지</div>
                  </div>
                  <div>광고가 노출돼요</div>
                </>
              ) : (
                <div className='mt-20'>
                  <BlinkingText>
                    <div className='flex flex-row items-center justify-start flex-1 w-full'>
                      <LeftOutlined style={{ marginRight: '8px' }} />
                      광고 시작일을 선택해주세요
                    </div>
                  </BlinkingText>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='mt-4 gap-5 flex justify-end w-full'>
        <Button
          className='bg-white w-[150px]'
          size='large'
          onClick={() => handlePrevStage(0)}
        >
          이전
        </Button>
        <Button
          type='primary'
          size='large'
          className='w-[150px]'
          disabled={!dateRange || !dateRange[0] || !dateRange[1]}
          onClick={() => {
            if (!dateRange || !dateRange[0] || !dateRange[1]) {
              return message.warning('광고 시작일을 선택해주세요')
            }
            handleNextStage(2)
          }}
        >
          다음
        </Button>
      </div>
    </div>
  )
}

export default Stage1DateSelection

import { Line } from 'react-chartjs-2'

const ItemQualityDetailGraph = ({ data, type }) => {
  const chartData = {
    labels: data.map((item) => new Date(item.createdAt).toLocaleDateString()),
    datasets: [
      {
        label: '주간 부정후기 수',
        data: data.map((item) => item.weeklyNegReviewCounts),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.4)',
        tension: 0.1,
        hidden: false,
      },
      {
        label: '주간 품질반품 수',
        data: data.map((item) => item.weeklyRefundCounts),
        fill: false,
        borderColor: 'rgba(255,99,132,1)',
        backgroundColor: 'rgba(255,99,132,0.4)',
        tension: 0.1,
        hidden: false,
      },
      {
        label: '일간 품질이슈 문의량',
        data: data.map((item) => item.dailyQuestionCounts),
        fill: false,
        borderColor: 'rgba(54,162,235,1)',
        backgroundColor: 'rgba(54,162,235,0.4)',
        tension: 0.1,
        hidden: false,
      },
    ],
  }

  const weeklyOrdersChartData = {
    labels: data.map((item) => new Date(item.createdAt).toLocaleDateString()),
    datasets: [
      {
        label: '주간 판매량',
        data: data.map((item) => item.weeklyOrdersCount),
        fill: false,
        borderColor: 'rgba(153,102,255,1)',
        backgroundColor: 'rgba(153,102,255,0.4)',
        tension: 0.1,
      },
    ],
  }

  const selectedChartData = type === 'order' ? weeklyOrdersChartData : chartData

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
      },
    },
    hover: {
      mode: 'dataset',
      intersect: false,
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: '날짜',
        },
      },
      y: {
        title: {
          display: true,
          text: '건 수',
        },
        beginAtZero: true,
      },
    },
  }

  return <Line data={selectedChartData} options={chartOptions} />
}

export default ItemQualityDetailGraph

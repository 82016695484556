import React, { useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../stores/ItemStore'
import moment from 'moment'
import backendApis from '../utils/backendApis'
import { useHistory } from 'react-router-dom'
import Title from './ADS/atoms/title'
import Table from './ADS/molecules/table'
import Pagination from './ADS/atoms/pagination'
import Button from './atoms/button'
import Statistics from './ADS/molecules/statistics'
import ButtonCard from './ADS/molecules/buttonCard'

const PAGE_PENALTY_NUM = 10

const ItemPenalty = observer(() => {
  const history = useHistory()
  const [pageIndex, setPageIndex] = useState(0)
  const [loadedPenaltyInfo, setLoadedPenaltyInfo] = useState(false)
  const [focusItemId, setFocusItemId] = useState('')
  const [sortedPenaltyInfo, setSortedPenaltyInfo] = useState([])
  const [sortType, setSortType] = useState('recent')
  const [penaltyPointInfo, setPenaltyPointInfo] = useState([])
  const [penaltyStatus, setPenaltyStatus] = useState('')
  const [questionAndOrderDataTotal, setQuestionAndOrderDataTotal] = useState([])
  const SELLER_PENALTY_URL =
    'https://levitinc.notion.site/54e4c8088c644b39b491502aebc450c8'

  const SELLER_PENALTY_QUESTION_ANSWER =
    'https://levitinc.notion.site/714ed891d63b43fcbc098a6ec812ff89'
  const penalty = {
    delivery: {
      name: '배송',
      shippingDeadlineAfter1day: '발송처리기한까지 미발송',
      shippingDeadlineAfter4day: '발송처리기한 4영업일 경과',
      nonShippingAfterDepartureDeadline: '발송처리기한까지 미발송',
      nonShippingAfterDepartureDeadlineAfter4Days: '발송처리기한 4영업일 경과',
      nonShippingAfterDelayedDepartureDeadline: '지연발송 처리기한까지 미발송',
      nonShippingAfterDelayedDepartureDeadlineAfter4Days:
        '지연발송처리기한 4영업일 경과',
      shippingAfterDepartureDeadline: '발송처리기한까지 미발송',
      shippingAfterDepartureDeadlineAfter4Days: '발송처리기한 4영업일 경과',
      shippingAfterDelayedDepartureDeadline: '지연발송 처리기한까지 미발송',
      shippingAfterDelayedDepartureDeadlineAfter4Days:
        '지연발송처리기한 4영업일 경과',
      nonShippingAfterTodayDepartureDeadline: '오늘출발 처리기한까지 미발송',
      nonShippingAfterTodayDepartureDeadlineAfter4Days:
        '오늘출발 처리기한 4영업일 경과',
      shippingAfterTodayDepartureDeadline: '오늘출발 처리기한까지 미발송',
      shippingAfterTodayDepartureDeadlineAfter4Days:
        '오늘출발 처리기한 4영업일 경과',
      nonShippingAfterDelayedTodayDepartureDeadline:
        '오늘출발 지연 기한까지 미발송',
      nonShippingAfterDelayedTodayDepartureDeadlineAfter4Days:
        '오늘출발 지연 기한 4영업일 경과',
      shippingAfterDelayedTodayDepartureDeadline:
        '오늘출발 지연 기한까지 미발송',
      shippingAfterDelayedTodayDepartureDeadlineAfter4Days:
        '오늘출발 지연 기한 4영업일 경과',
    },
    refundAndExchange: {
      name: '교환반품',
      refundRequestAfter3day: '반품 요청 후 3영업일 내 접수 처리 안됨',
      retrievedAfter2day:
        '수거 완료일로부터 2영업일 이상 경과 후 환불/반품 처리 or 거부 처리 안됨',
      refundRequestAfter10day: '반품 요청 후 10영업일 내 처리 안됨',
    },
    cancellation: {
      name: '취소',
      cancellationItemSoldout: '상품품절으로 인한 취소',
      cancellationShippingDealy: '배송지연으로 인한 취소',
      cancellationServiceDissatisfaction: '서비스 불만족으로 인한 취소',
      cancellationItemInfoDifference: '상품정보 상이로 인한 취소',
    },
    itemQuestion: {
      name: '고객/고객센터 문의',
      answerDelayFromCustomer: '고객문의 답변 1영업일 이상 지연',
      answerDelayFromAdmin: '고객센터문의 답변 1영업일 이상 지연',
    },
    illegalAct: {
      name: '고의적 부당행위',
      intentionalIllegalAct: '고의적 부당행위',
    },
  }

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (isNewCondition) {
      const result = await backendApis.getItemPenaltyInfo(
        0,
        50,
        sortType,
        focusItemId,
      )
      if (result?.status === 200) {
        setSortedPenaltyInfo(result?.data)
        setLoadedPenaltyInfo(true)
      } else {
        setPageIndex(0)
        setSortedPenaltyInfo([])
        setLoadedPenaltyInfo(true)
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        return
      }
    }
    const result = await backendApis.getItemPenaltyInfo(
      0,
      50 + pageIndex * 10,
      sortType,
      focusItemId,
    )
    if (result?.status === 200) {
      setSortedPenaltyInfo(result?.data)
      setLoadedPenaltyInfo(true)
    } else {
      setPageIndex(0)
      setSortedPenaltyInfo([])
      setLoadedPenaltyInfo(true)
      window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
    }
  }

  useEffect(() => {
    if (sortedPenaltyInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [pageIndex])

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [PAGE_PENALTY_NUM])

  useEffect(async () => {
    const result = await backendApis.getSellerPenaltyPoint()
    if (result?.status === 200) {
      setPenaltyPointInfo(result?.data?.penaltyPoint)
      setQuestionAndOrderDataTotal(result?.data?.questionAndOrderDataTotal)
      const penaltyStatus = await backendApis.getSellerPenaltyStatus()
      if (
        !penaltyStatus?.data?.penaltyStatus ||
        penaltyStatus?.data?.penaltyStatus === 'normal'
      ) {
        setPenaltyStatus('정상')
      } else if (penaltyStatus?.data?.penaltyStatus === 'warning') {
        setPenaltyStatus('경고')
      } else if (penaltyStatus?.data?.penaltyStatus === 'restraint') {
        setPenaltyStatus('페널티 점수 초과 이용제한')
      } else if (
        penaltyStatus?.data?.policyStatus === 'banned' ||
        penaltyStatus?.data?.policyStatus === 'temp-banned'
      ) {
        setPenaltyStatus('정책 위반으로 이용제한')
      } else {
        setPenaltyStatus('정상')
      }
    }
  }, [])

  const penaltyColumn = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '페널티 아이디', accessor: 'id' },
      { Header: '주문 아이디', accessor: 'orderId' },
      { Header: '페널티 일시', accessor: 'date' },
      { Header: '유형', accessor: 'penaltyType' },
      { Header: '상세 유형', accessor: 'penaltyReason' },
      { Header: '상품 정보(아이디, 상품명)', accessor: 'itemInfo' },
      { Header: '페널티 점수', accessor: 'penaltyPoint' },
      { Header: '소명서 제출 여부', accessor: 'sellerExcuseInfo' },
      { Header: '소명서 승인/반려', accessor: 'adminCheckedInfo' },
      { Header: '승인/반려 상세 이유', accessor: 'adminCheckedInfoText' },
    ],
    [],
  )

  const cancellationPenaltyPoint =
    penaltyPointInfo?.filter((e) => e?._id?.penaltyType === 'cancellation')?.[0]
      ?.penaltyPoint || 0
  const deliveryPenaltyPoint =
    penaltyPointInfo?.filter((e) => e?._id?.penaltyType === 'delivery')?.[0]
      ?.penaltyPoint || 0
  const refundAndExchangePenaltyPoint =
    penaltyPointInfo?.filter(
      (e) => e?._id?.penaltyType === 'refundAndExchange',
    )?.[0]?.penaltyPoint || 0
  const itemQuestionPenaltyPoint =
    penaltyPointInfo?.filter((e) => e?._id?.penaltyType === 'itemQuestion')?.[0]
      ?.penaltyPoint || 0
  const illegalActPenaltyPoint =
    penaltyPointInfo?.filter((e) => e?._id?.penaltyType === 'illegalAct')?.[0]
      ?.penaltyPoint || 0

  const penaltyPointTotal =
    cancellationPenaltyPoint +
      deliveryPenaltyPoint +
      refundAndExchangePenaltyPoint +
      itemQuestionPenaltyPoint +
      illegalActPenaltyPoint || 0

  const penaltyPercent = Math.round(
    (penaltyPointTotal / (questionAndOrderDataTotal || 0.00001)) * 100,
  )

  const penaltyData = useMemo(() => {
    return sortedPenaltyInfo?.length > 0
      ? sortedPenaltyInfo
          ?.slice(
            pageIndex * PAGE_PENALTY_NUM,
            (pageIndex + 1) * PAGE_PENALTY_NUM,
          )
          .map((item, index) => {
            return {
              index: <div className='text-right'>{index + 1}</div>,
              id: item?._id,
              orderId: item?.orderId || '',
              date:
                moment(item?.penaltyInfo?.createdAt).format(
                  'YYYY-MM-DD HH:mm',
                ) || '',
              penaltyType:
                penalty?.[item?.penaltyInfo?.penaltyType]?.name || '기타',
              penaltyReason:
                penalty?.[item?.penaltyInfo?.penaltyType][
                  item?.penaltyInfo?.penaltyReason
                ] || '플랫폼 기준 미달',
              itemInfo: (
                <>
                  {item?.itemId}
                  <br />
                  <span className='text-gray-400'>{item?.itemTitle}</span>
                </>
              ),
              penaltyPoint: (
                <div className='text-center'>
                  {item?.penaltyInfo?.penaltyPoint || 0}점
                </div>
              ),
              sellerExcuseInfo: item?.sellerExcuseInfo ? (
                <div className='text-center'>
                  제출 완료 (
                  {moment(item?.sellerExcuseInfo?.createdAt).format(
                    'YYYY-MM-DD HH:mm',
                  ) || ''}
                  )<br />
                  <Button
                    type='button'
                    className='mt-1'
                    appearance='gray'
                    onClick={async () => {
                      history.push('/penalty-excuse-write', {
                        penaltyInfo: item,
                      })
                    }}
                  >
                    소명서 상세 보기
                  </Button>
                </div>
              ) : (
                <div className='text-center'>
                  페널티가 올웨이즈 귀책으로 부과된 경우에만 소명서를
                  제출해주세요 <br />
                </div>
              ),
              adminCheckedInfo: item?.adminCheckedInfo ? (
                <div className='text-center'>
                  <span
                    className={
                      item?.adminCheckedInfo?.type !== 'accepted' &&
                      'text-red-600'
                    }
                  >
                    {item?.adminCheckedInfo?.type === 'accepted'
                      ? '승인 '
                      : '반려 '}
                  </span>
                  <br />(
                  {moment(item?.createdAt).format('YYYY-MM-DD HH:mm') || ''}){' '}
                  <br />
                  <span className='text-xs'>
                    * 질문/추가 요청사항이 있으실 경우 <br />
                    올웨이즈 판매자지원센터 카톡으로 문의 남겨주세요.
                  </span>
                </div>
              ) : (
                <div className='text-center'>
                  {item?.sellerExcuseInfo ? '관리자 확인 전입니다.' : '_'}
                </div>
              ),
              adminCheckedInfoText: item?.adminCheckedInfo ? (
                <div className='whitespace-normal min-w-[300px]'>
                  {item?.adminCheckedInfo?.text || '_'}
                </div>
              ) : (
                <>{item?.sellerExcuseInfo ? '관리자 확인 전입니다.' : '_'}</>
              ),
            }
          })
      : []
  })

  return (
    <>
      <div className='flex flex-row'>
        <Title text='페널티 내역 확인' />
        <div className='text-center mt-10 ml-3'>
          <Button
            type='button'
            // appearance='red'
            onClick={async () => {
              history.push('/penalty-excuse-write')
            }}
          >
            소명서 제출하기
          </Button>
        </div>
      </div>
      {!loadedPenaltyInfo ? (
        <div className='text-lg'>페널티 로드 중입니다</div>
      ) : (
        <>
          <div className='flex flex-col items-center w-full pb-5 space-y-4 md:space-x-4 md:space-y-0 md:flex-row'>
            <Statistics index='제재 단계' description={penaltyStatus} />
            <ButtonCard
              index1='페널티 합계'
              value1={penaltyPointTotal}
              index2='페널티 비율'
              value2={penaltyPercent}
              indexCount={2}
              buttonText='페널티 정책 다운로드'
              href={SELLER_PENALTY_URL}
              buttonText2='페널티 질의응답 정리'
              href2={SELLER_PENALTY_QUESTION_ANSWER}
            />
            <ButtonCard
              index1='배송'
              value1={deliveryPenaltyPoint}
              index2='교환&반품'
              value2={refundAndExchangePenaltyPoint}
              index3='취소'
              value3={cancellationPenaltyPoint}
              index4='통합문의'
              value4={itemQuestionPenaltyPoint}
              index5='부당행위'
              value5={illegalActPenaltyPoint}
              indexCount={5}
            />
          </div>
          <Table columns={penaltyColumn} data={penaltyData} />
          <Pagination
            page={pageIndex}
            setPage={setPageIndex}
            limit={PAGE_PENALTY_NUM}
            total={sortedPenaltyInfo?.length}
          />
        </>
      )}
    </>
  )
})

export default ItemPenalty

import React from 'react'
import { Link } from 'react-router-dom'

const ButtonCard = ({
  href = '',
  image = '',
  text = '',
  subtext1 = '',
  subtext2 = '',
  width = 40,
  height = 30,
  idx,
  tab,
  setTab = () => {},
}) => {
  const BtnCard = () => (
    <button
      type='button'
      className={`flex flex-col justify-between items-center ${
        tab === idx ? 'bg-slate-200' : 'bg-white'
      } hover:bg-positive-100 px-6 py-6 select-none shadow-md rounded-xl mx-3 my-2 w-${width} h-${height}`}
      onClick={() => setTab(idx)}
    >
      {!!image && (
        <div className='flex items-center justify-center w-40 h-24 mb-5'>
          <img src={image} alt='buttonCard' className='object-scale-down' />
        </div>
      )}
      <div className='text-lg font-bold'>{text}</div>
      {!!subtext1 && <div className=' text-md'>{subtext1}</div>}
      {!!subtext2 && <div className='text-md'>{subtext2}</div>}
    </button>
  )
  if (!href) return <BtnCard />
  return (
    <Link to={href}>
      <BtnCard />
    </Link>
  )
}

export default ButtonCard

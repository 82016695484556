const QuestionType = Object.freeze({
  ITEM_QUESTION: Symbol('ITEM_QUESTION'),
  QUESTION_FROM_ADMIN: Symbol('QUESTION_FROM_ADMIN'),
})

const QuestionContentType = Object.freeze({
  ORDER: Symbol('ORDER'),
  ITEM: Symbol('ITEM'),
  ALL: Symbol('ALL'),
})

const QuestionStatus = Object.freeze({
  ANSWERED: Symbol('ANSWERED'),
  NOT_ANSWERED: Symbol('NOT_ANSWERED'),
  ALL: Symbol('ALL'),
})

export { QuestionType, QuestionContentType, QuestionStatus }

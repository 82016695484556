import React from 'react'

const LoadingStatusModal = () => {
  return (
    <div>
      <div className=''>
        <img
          src='https://assets.ilevit.com/cf24d995-2e6c-4848-b33e-b986969366de.gif'
          alt='loading'
          width='80'
        />
      </div>
    </div>
  )
}

export default LoadingStatusModal

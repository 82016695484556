import React from 'react'
import { observer } from 'mobx-react-lite'
import ItemPenalty from '../components/ItemPenalty'

const ItemPenaltyScreen = observer(() => {
  return (
    <div className='container flex flex-col w-full items-start'>
      <ItemPenalty />
    </div>
  )
})

export default ItemPenaltyScreen

import Log from '../../utils/log'
import { useState } from 'react'
import Button from '../atoms/button'
import { read, utils, writeFileXLSX } from 'xlsx-js-style'
import backendApis from '../../utils/backendApis'
import {
  shippingExcelFileDefaultData,
  itemsExcelFileDefaultData,
  timeDealItemsExcelFileDefaultData,
} from '../../data/excelDefaultData'
import useKeyEscClose from '../../hooks/useKeyEscClose'
import SellerStore from '../../stores/SellerStore'

const ItemSellingStatusToKR = Object.freeze({
  all: '전체',
  onSale: '판매 중',
  soldOut: '품절',
})

const DateTimeSortingTypeToKR = Object.freeze({
  newestCreatedAt: '생성일시 최신 순',
  oldestCreatedAt: '생성일시 오래된 순',
  newestUpdatedAt: '수정일시 최신 순',
  oldestUpdatedAt: '수정일시 오래된 순',
})

const colsConfig = [
  { wch: 24 },
  { wch: 20 },
  { wch: 20 },
  { wch: 16 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 16 },
  { wch: 16 },
  { wch: 16 },
]

const rowsConfig = [{ hpt: 32 }, { hpt: 32 }, { hpt: 32 }]

const getComparatorByDateTimeSortingType = (dateTimeSortingType) => {
  return (a, b) => {
    if (!a.createdAt || !a.updatedAt || !b.createdAt || !b.updatedAt)
      return b._id - a._id
    switch (dateTimeSortingType) {
      case 'oldestCreatedAt':
        return new Date(a.createdAt) - new Date(b.createdAt)
      case 'newestUpdatedAt':
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      case 'oldestUpdatedAt':
        return new Date(a.updatedAt) - new Date(b.updatedAt)
      default:
        return new Date(b.createdAt) - new Date(a.createdAt)
    }
  }
}

// 대량 조회 수정용 엑셀 파일 병합 설정이 유저 요구사항으로 나올 경우 아래 코드 참조
// const getMergeConfigFromItemsInfoAOA = (arraysOfArray, type) => {
//   if (arraysOfArray.length === itemsExcelFileDefaultData.length) return
//   let numOfCommonDataCol
//   let firstOptionNameColNum
//   let firstOptionValueColNum
//   let secondOptionameColNum
//   if (type === 'timeDeal') {
//     firstOptionNameColNum = TimeDealMergeColNums.FIRST_OPTION_NAME
//     firstOptionValueColNum = TimeDealMergeColNums.FIRST_OPTION_VALUE
//     secondOptionameColNum = TimeDealMergeColNums.SECOND_OPTION_NAME
//     numOfCommonDataCol = 3 // (_id, itemTitle, soldOut)
//   } else {
//     firstOptionNameColNum = ItemModificationMergeColNums.FIRST_OPTION_NAME
//     firstOptionValueColNum = ItemModificationMergeColNums.FIRST_OPTION_VALUE
//     secondOptionameColNum = ItemModificationMergeColNums.SECOND_OPTION_NAME
//     numOfCommonDataCol = 4 // (_id, itemTitle, smallCategoryId, soldOut)
//   }
//   const config = []
//   let prevId = null
//   let start = null
//   let end = null
//   let preFirstOptionValue = null
//   let optionStart = null
//   let optionEnd = null
//   arraysOfArray.forEach((a, idx) => {
//     if (prevId === null) {
//       prevId = a[0]
//       start = idx
//       end = idx
//       preFirstOptionValue = a[firstOptionValueColNum]
//       optionStart = idx
//       optionEnd = idx
//       return
//     }
//     if (a[0] === prevId || a[0] === '') {
//       end += 1
//       if (a[firstOptionValueColNum] === preFirstOptionValue) {
//         optionEnd += 1
//       } else {
//         config.push({
//           s: { c: firstOptionNameColNum, r: optionStart },
//           e: { c: firstOptionNameColNum, r: optionEnd },
//         })
//         config.push({
//           s: { c: firstOptionValueColNum, r: optionStart },
//           e: { c: firstOptionValueColNum, r: optionEnd },
//         })
//         config.push({
//           s: { c: secondOptionameColNum, r: optionStart },
//           e: { c: secondOptionameColNum, r: optionEnd },
//         })
//         preFirstOptionValue = a[firstOptionValueColNum]
//         optionEnd += 1
//         optionStart = optionEnd
//       }
//     } else {
//       for (let i = 0; i < numOfCommonDataCol; i += 1) {
//         config.push({ s: { c: i, r: start }, e: { c: i, r: end } })
//       }
//       prevId = a[0]
//       end += 1
//       start = end
//       config.push({
//         s: { c: firstOptionNameColNum, r: optionStart },
//         e: { c: firstOptionNameColNum, r: optionEnd },
//       })
//       config.push({
//         s: { c: firstOptionValueColNum, r: optionStart },
//         e: { c: firstOptionValueColNum, r: optionEnd },
//       })
//       config.push({
//         s: { c: secondOptionameColNum, r: optionStart },
//         e: { c: secondOptionameColNum, r: optionEnd },
//       })
//       preFirstOptionValue = a[firstOptionValueColNum]
//       optionEnd += 1
//       optionStart = optionEnd
//     }
//   })
//   if (start !== end) {
//     for (let i = 0; i < numOfCommonDataCol; i += 1) {
//       config.push({ s: { c: i, r: start }, e: { c: i, r: end } })
//     }
//     if (optionStart !== optionEnd) {
//       config.push({
//         s: { c: firstOptionNameColNum, r: optionStart },
//         e: { c: firstOptionNameColNum, r: optionEnd },
//       })
//       config.push({
//         s: { c: firstOptionValueColNum, r: optionStart },
//         e: { c: firstOptionValueColNum, r: optionEnd },
//       })
//       config.push({
//         s: { c: secondOptionameColNum, r: optionStart },
//         e: { c: secondOptionameColNum, r: optionEnd },
//       })
//     }
//   }
//   return config
// }

const ItemSearchResultDownloadModal = ({
  itemCondition,
  itemsCount,
  dateTimeSortingType,
  itemSellingStatus,
  appearance = 'positive',
  disabled = false,
  size = 'md',
  children,
  itemQuery,
  modalOn = false,
  modalLog = 'ItemSearchResultDownloadModal',
  className = '',
  type = '',
}) => {
  const [showModal, setShowModal] = useState(modalOn)
  const fetchTotalItemsInfo = async (applyStatus) => {
    let dealApplyStatus = 'apply'
    SellerStore.setIsLoading(true)
    if (applyStatus === 'timedeal-modification') {
      dealApplyStatus = 'complete'
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        {
          itemFilters: {
            $elemMatch: {
              $and: [{ key: 'deal', value: type }],
            },
          },
        },
      ]
    } else if (applyStatus === 'timedeal-apply') {
      dealApplyStatus = 'apply'
      itemCondition.soldOut = false
      itemCondition.outOfStock = { $ne: null }
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: {
                $and: [{ key: 'deal', value: type }],
              },
            },
          },
        },
      ]
    } else if (Object.keys(itemCondition)?.length === 0) {
      itemCondition.itemTitle = {
        $regex: itemQuery?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      }
    }

    const resultPromise = backendApis.getItemsInfoForExcelByCondition({
      type: 'item',
      itemCondition,
      dealType: type,
      dealApplyStatus,
    })

    const timeoutMs = 1000000 // 타임아웃 설정: 10초
    const timeoutPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(null) // 타임아웃 시 null 반환
      }, timeoutMs)
    })
    // Promise.race를 사용하여 결과 또는 타임아웃 중 먼저 발생하는 것을 선택합니다.
    const result = await Promise.race([resultPromise, timeoutPromise])
    SellerStore.setIsLoading(false)

    if (result === null) {
      // 타임아웃 발생
      alert(
        '작업이 시간 내에 완료되지 않았습니다. 다운로드 가능한 최대 개수는 약 3,000개입니다',
      )

      return null
    }

    if (result?.status === 2000) {
      return result?.data?.itemsInfo
    }

    if (result?.status === 400) {
      alert('전체 검색 결과를 가져오는 도중 문제가 생겼습니다.')
      return null
    }
  }

  const convertItemsInfoToAOA = (itemsInfo, hasOptionInfo = false) => {
    const result = hasOptionInfo
      ? [...itemsExcelFileDefaultData]
      : [[...itemsExcelFileDefaultData[0].slice(0, 4)]]
    itemsInfo
      .sort(getComparatorByDateTimeSortingType(dateTimeSortingType))
      .forEach((i) => {
        const commonData = [
          i._id,
          i.itemTitle,
          i.categoryInfo.minimumCategoryId,
          i.soldOut ? '품절' : '판매중',
        ]
        let row = [...commonData]
        if (hasOptionInfo) {
          const info = i.optionsInfo
          const numOfOptionNames = info.optionNames.length
          if (numOfOptionNames === 1) {
            const numOfFirstOptions = info.optionPrices.length
            for (let i = 0; i < numOfFirstOptions; i += 1) {
              const optionCode = info.optionPrices[i].optionCode
                ? info.optionPrices[i].optionCode
                : ''
              const firstOptionName = info.optionNames[0]
              const firstOptionValue = info.totalOptions[0][i].name
              const secondOptionName = ''
              const secondOptionValue = ''
              const individualPurchasePrice =
                info.optionPrices[i].individualPurchasePrice
              const teamPurchasePrice = info.optionPrices[i].teamPurchasePrice
              const stockNumber = info.optionPrices[i].stockNumber
              row = [
                ...commonData.slice(0, 4),
                optionCode,
                firstOptionName,
                firstOptionValue,
                secondOptionName,
                secondOptionValue,
                individualPurchasePrice,
                teamPurchasePrice,
                stockNumber,
              ]
              result.push(row)
            }
          } else if (numOfOptionNames === 2) {
            const numOfFirstOptions = info.optionPrices.length
            const numOfSecondOptions = info.optionPrices[0].length
            for (let i = 0; i < numOfFirstOptions; i += 1) {
              for (let j = 0; j < numOfSecondOptions; j += 1) {
                const optionCode = info.optionPrices[i][j].optionCode
                  ? info.optionPrices[i][j].optionCode
                  : ''
                const firstOptionName = info.optionNames[0]
                const firstOptionValue = info.totalOptions[0][i].name
                const secondOptionName = info.optionNames[1]
                const secondOptionValue = info.totalOptions[1][j].name
                const individualPurchasePrice =
                  info.optionPrices[i][j].individualPurchasePrice
                const teamPurchasePrice =
                  info.optionPrices[i][j].teamPurchasePrice
                const stockNumber = info.optionPrices[i][j].stockNumber
                row = [
                  ...commonData.slice(0, 4),
                  optionCode,
                  firstOptionName,
                  firstOptionValue,
                  secondOptionName,
                  secondOptionValue,
                  individualPurchasePrice,
                  teamPurchasePrice,
                  stockNumber,
                ]
                result.push(row)
              }
            }
          }
        } else {
          result.push(row)
        }
      })
    return result
  }

  const convertTimeDealItemsInfoToAOA = (
    dealItemsInfo,
    hasOptionInfo = false,
    applyStatus,
  ) => {
    const result = hasOptionInfo
      ? [...timeDealItemsExcelFileDefaultData]
      : [[...timeDealItemsExcelFileDefaultData[0].slice(0, 4)]]
    dealItemsInfo
      .sort(getComparatorByDateTimeSortingType(dateTimeSortingType))
      .forEach((i) => {
        const commonData = [i._id, i.itemTitle, i.soldOut ? '품절' : '판매중']
        let row = [...commonData]
        if (hasOptionInfo) {
          const info = i
          const numOfOptionNames = info.optionsInfo.optionNames.length
          if (numOfOptionNames === 1) {
            const numOfFirstOptions = info.optionsInfo.optionPrices.length
            for (let i = 0; i < numOfFirstOptions; i += 1) {
              const firstOptionName = info.optionsInfo.optionNames[0]
              const firstOptionValue = info.optionsInfo.totalOptions[0][i].name
              const secondOptionName = ''
              const secondOptionValue = ''
              const individualPrice =
                info.optionsInfo.optionPrices[i].individualPurchasePrice
              const teamPurchasePrice =
                info.optionsInfo.optionPrices[i].teamPurchasePrice
              const timeDealPurchasePrice =
                applyStatus === 'timedeal-modification'
                  ? info?.dealOptionsInfo?.optionPrices?.[i]?.teamPurchasePrice
                  : ''
              const stockNumber = info.optionsInfo.optionPrices[i].stockNumber
              row = [
                // i === 0 ? commonData[0] : '',
                ...commonData.slice(0, 4),
                firstOptionName,
                firstOptionValue,
                secondOptionName,
                secondOptionValue,
                individualPrice,
                teamPurchasePrice,
                timeDealPurchasePrice,
                stockNumber,
              ]
              result.push(row)
            }
          } else if (numOfOptionNames === 2) {
            const numOfFirstOptions = info.optionsInfo.optionPrices.length
            const numOfSecondOptions = info.optionsInfo.optionPrices[0].length
            for (let i = 0; i < numOfFirstOptions; i += 1) {
              for (let j = 0; j < numOfSecondOptions; j += 1) {
                const firstOptionName = info.optionsInfo.optionNames[0]
                const firstOptionValue =
                  info.optionsInfo.totalOptions[0][i].name
                const secondOptionName = info.optionsInfo.optionNames[1]
                const secondOptionValue =
                  info.optionsInfo.totalOptions[1][j].name
                const individualPurchasePrice =
                  info.optionsInfo.optionPrices[i][j].individualPurchasePrice
                const teamPurchasePrice =
                  info.optionsInfo.optionPrices[i][j].teamPurchasePrice
                const timeDealPurchasePrice =
                  applyStatus === 'timedeal-modification'
                    ? info?.dealOptionsInfo?.optionPrices?.[i][j]
                        ?.teamPurchasePrice
                    : ''
                const stockNumber =
                  info.optionsInfo.optionPrices[i][j].stockNumber
                row = [
                  // i === 0 && j === 0 ? commonData[0] : '',
                  ...commonData.slice(0, 4),
                  firstOptionName,
                  firstOptionValue,
                  secondOptionName,
                  secondOptionValue,
                  individualPurchasePrice,
                  teamPurchasePrice,
                  timeDealPurchasePrice,
                  stockNumber,
                ]
                result.push(row)
              }
            }
          }
        } else {
          result.push(row)
        }
      })
    return result
  }

  const exportSimpleItemsInfo = async () => {
    const totalItemsInfo = await fetchTotalItemsInfo()
    if (totalItemsInfo === null) {
      alert('상품 정보를 가져오는 도중 문제가 생겼습니다.')
      return
    }
    const rows = convertItemsInfoToAOA(totalItemsInfo, false)
    const worksheet = utils.aoa_to_sheet(rows)
    worksheet['!cols'] = colsConfig
    worksheet['!rows'] = rowsConfig
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, '올웨이즈 검색 결과 상품 목록')
    writeFileXLSX(workbook, '상품 목록.xlsx')
  }

  const exportItemsInfo = async (applyStatus) => {
    if (type === 'timeDeal') {
      exportTimeDealItemsInfoForModification(applyStatus)
    } else {
      exportItemsInfoForModification()
    }
  }

  const exportItemsInfoForModification = async () => {
    const totalItemsInfo = await fetchTotalItemsInfo()
    if (totalItemsInfo === null) {
      alert('상품 정보를 가져오는 도중 문제가 생겼습니다.')
      return
    }
    const rows = convertItemsInfoToAOA(totalItemsInfo, true)
    const worksheet = utils.aoa_to_sheet(rows)
    worksheet['!cols'] = colsConfig
    worksheet['!rows'] = rowsConfig
    // const mergesConfig = getMergeConfigFromItemsInfoAOA(rows, type)
    // worksheet['!merges'] = mergesConfig
    // mergesConfig.forEach((config) => {
    //   if (config.s.r === config.e.r) return
    //   if (config.s.c !== config.e.c) {
    //     alert('셀 병합 에러')
    //     return
    //   }
    //   for (let R = config.s.r + 1; R <= config.e.r; R += 1) {
    //     for (let C = config.s.c; C <= config.e.c; C += 1) {
    //       worksheet[utils.encode_cell({ c: C, r: R })].v = ''
    //     }
    //   }
    // })
    const workbook = utils.book_new()
    utils.book_append_sheet(
      workbook,
      worksheet,
      '올웨이즈 검색 결과 상품 정보 수정용',
    )
    writeFileXLSX(workbook, '올웨이즈 상품 정보 수정용.xlsx')
  }

  const exportTimeDealItemsInfoForModification = async (applyStatus) => {
    const totalItemsInfo = await fetchTotalItemsInfo(applyStatus)
    if (totalItemsInfo === null) {
      alert('상품 정보를 가져오는 도중 문제가 생겼습니다.')
      return
    }
    const rows = convertTimeDealItemsInfoToAOA(
      totalItemsInfo,
      true,
      applyStatus,
    )
    const worksheet = utils.aoa_to_sheet(rows)
    // 상품 전체 판매 상태 컬럼 설정 변경
    colsConfig[2].wch = 50
    worksheet['!cols'] = colsConfig
    worksheet['!rows'] = rowsConfig
    // const mergesConfig = getMergeConfigFromItemsInfoAOA(rows, type)
    // worksheet['!merges'] = mergesConfig
    // mergesConfig.forEach((config) => {
    //   if (config.s.r === config.e.r) return
    //   if (config.s.c !== config.e.c) {
    //     alert('셀 병합 에러')
    //     return
    //   }
    //   for (let R = config.s.r + 1; R <= config.e.r; R += 1) {
    //     for (let C = config.s.c; C <= config.e.c; C += 1) {
    //       worksheet[utils.encode_cell({ c: C, r: R })].v = ''
    //     }
    //   }
    // })
    const workbook = utils.book_new()
    utils.book_append_sheet(
      workbook,
      worksheet,
      applyStatus === 'timedeal-apply'
        ? '올웨이즈 타임 특가 신청용'
        : '올웨이즈 타임 특가 수정용',
    )
    writeFileXLSX(
      workbook,
      applyStatus === 'timedeal-apply'
        ? '올웨이즈 타임 특가 신청용.xlsx'
        : '올웨이즈 타임 특가 수정용.xlsx',
    )
  }

  const exportShippingInfoForModification = async () => {
    const totalItemsInfo = await fetchTotalItemsInfo()
    if (totalItemsInfo === null) {
      alert('배송 정보를 가져오는 도중 문제가 생겼습니다.')
      return
    }
    const rows = [...shippingExcelFileDefaultData]
    totalItemsInfo
      .sort(getComparatorByDateTimeSortingType(dateTimeSortingType))
      .forEach((i) => {
        const s = i.shippingInfo
        let jejuFee = ''
        let nonJejuFee = ''
        if (s.ruralAreaShippingFeeInfo?.length) {
          jejuFee = s.ruralAreaShippingFeeInfo[34].shippingFee
          nonJejuFee = s.ruralAreaShippingFeeInfo[0].shippingFee
        }
        rows.push([
          i._id,
          i.itemTitle,
          s.shippingCompany,
          s.shippingDays,
          s.shippingFeeInfo,
          s.shippingFee,
          nonJejuFee,
          jejuFee,
          s.returnFee,
          s.returnFeeInfo,
        ])
      })
    const worksheet = utils.aoa_to_sheet(rows)
    worksheet['!cols'] = colsConfig
    worksheet['!rows'] = rowsConfig
    const workbook = utils.book_new()
    utils.book_append_sheet(
      workbook,
      worksheet,
      '올웨이즈 검색 결과 배송 정보 수정용',
    )
    writeFileXLSX(workbook, '올웨이즈 배송 정보 수정용.xlsx')
  }

  const exportCategoryInfo = async () => {
    const url =
      'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/category_info_v2.xlsx'
    try {
      const res = await fetch(url)
      if (res.status === 200) {
        const csvArrayBuffer = await res.arrayBuffer()
        const data = new Uint8Array(csvArrayBuffer)
        const arr = []
        for (let i = 0; i < data.length; i += 1)
          arr[i] = String.fromCharCode(data[i])
        const bstr = arr.join('')
        const workbook = read(bstr, {
          type: 'binary',
        })
        utils.writeFile(workbook, 'CategoryInfo.xlsx')
      }
    } catch (error) {
      window.location.href = url
    }
  }

  useKeyEscClose(() => {
    setShowModal(false)
  })

  return (
    <>
      <>
        <Button
          appearance={appearance}
          type='button'
          size={size}
          disabled={disabled}
          onClick={() => {
            Log.event(modalLog, modalLog, {
              click: modalLog,
            })
            setShowModal(true)
          }}
          className={className}
        >
          {children}
        </Button>
      </>
      {showModal ? (
        <>
          {type === 'timeDeal' && (
            <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
              <div className='relative w-full max-w-2xl mx-auto my-6'>
                {/* content */}
                <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                  {/* header */}
                  <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                    <h3 className='text-xl font-semibold text-black'>
                      검색 결과 다운로드
                    </h3>
                  </div>
                  {/* body */}
                  <div className='relative flex-auto px-10 py-10'>
                    <div className='text-base leading-relaxed text-sub-500'>
                      <div className='flex flex-col space-y-5'>
                        <div className='flex flex-col'>
                          <div className='text-base mb-4'>
                            타임 특가 신청 및 수정이 가능한 B등급 이상 아이템을
                            엑셀로 다운로드 합니다
                          </div>
                        </div>
                        <div className='flex flex-col bg-slate-100 p-4 rounded'>
                          <div className='font-semibold text-lg '>
                            상품 정보 수정 및 등록용 엑셀 파일 다운로드
                          </div>
                          <div className='mb-4'>
                            타임 특가 신청 및 수정 포맷 엑셀 파일(.xlsx)을
                            다운로드할 수 있습니다. 타임 특가 희망가와 재고
                            수량만 수정할 수 있습니다.
                          </div>
                          <div className='flex justify-start space-x-5'>
                            <Button
                              onClick={() => {
                                console.log('it works')
                                exportItemsInfo('timedeal-apply')
                              }}
                            >
                              타임 특가 신청용
                            </Button>
                            <Button
                              onClick={() => {
                                exportItemsInfo('timedeal-modification')
                              }}
                            >
                              타임 특가 수정용
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* footer */}
                  <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                    <button
                      className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                      type='button'
                      onClick={() => setShowModal(false)}
                    >
                      닫기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {type !== 'timeDeal' && (
            <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none '>
              <div className='relative w-full max-w-2xl mx-auto my-6'>
                {/* content */}
                <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none '>
                  {/* header */}
                  <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                    <h3 className='text-xl font-semibold text-black'>
                      검색 결과 다운로드
                    </h3>
                  </div>
                  {/* body */}
                  <div className='relative flex-auto px-10 py-10'>
                    <div className='text-base leading-relaxed text-sub-500'>
                      <div className='flex flex-col space-y-5'>
                        <div className='flex flex-col'>
                          <div className='text-base mb-4'>
                            [상품 판매 상태:{' '}
                            {ItemSellingStatusToKR[itemSellingStatus]}]로 검색된
                            총 {itemsCount}건(정렬 기준:{' '}
                            {DateTimeSortingTypeToKR[dateTimeSortingType]}
                            )의 상품 정보를 다운로드 하실 수 있습니다. 아래 상품
                            목록은 상품 정보의 일부만 간략하게 확인 가능합니다.
                          </div>
                          <div>
                            <Button onClick={exportSimpleItemsInfo}>
                              상품 목록(.xlsx)
                            </Button>
                          </div>
                        </div>
                        <div className='flex flex-col bg-slate-100 p-4 rounded'>
                          <div className='font-semibold text-lg '>
                            상품 수정용 엑셀 파일 다운로드
                          </div>
                          <div className='mb-4'>
                            상품 정보 수정/배송 정보 수정 포맷 엑셀
                            파일(.xlsx)을 다운로드할 수 있습니다. 카테고리
                            소분류 코드를 확인하려면 카테고리 정보 파일을
                            다운로드하여 확인해주세요.
                          </div>
                          <div className='flex justify-start space-x-5'>
                            <Button onClick={exportItemsInfo}>
                              상품 정보 수정용
                            </Button>
                            <Button onClick={exportShippingInfoForModification}>
                              배송 정보 수정용
                            </Button>
                            <Button onClick={exportCategoryInfo}>
                              카테고리 정보
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* footer */}
                  <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                    <button
                      className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                      type='button'
                      onClick={() => setShowModal(false)}
                    >
                      닫기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      ) : null}
    </>
  )
}

export default ItemSearchResultDownloadModal

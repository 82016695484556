const Ping = () => {
  return (
    <div className='relative flex ml-2'>
      <span className='absolute w-2 h-2 rounded-full opacity-75 animate-ping bg-theme-400' />
      <span className='relative inline-flex w-2 h-2 rounded-full bg-theme-500' />
    </div>
  )
}

export default Ping
